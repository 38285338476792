import React from 'react'
import Form from '@components/UserForm/Form'
import { Formik } from 'formik'
import { func, object } from 'prop-types'

const UserForm = ({ onSubmit, initialValues = {} }) => (
  <Formik onSubmit={onSubmit} initialValues={initialValues}>
    {props => <Form {...props} isNew={!initialValues.id} />}
  </Formik>
)

UserForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default UserForm
