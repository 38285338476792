import { Classes, NavbarGroup } from '@blueprintjs/core'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import CustomerCrumbs from '@components/Header/Breadcrumbs/CustomerCrumbs'
import DeliveryZonesCrumbs from '@components/Header/Breadcrumbs/DeliveryZonesCrumbs'
import MarketingCrumbs from '@components/Header/Breadcrumbs/MarketingCrumbs'
import MarketplaceCrumbs from '@components/Header/Breadcrumbs/MarketplaceCrumbs'
import OrderCrumbs from '@components/Header/Breadcrumbs/OrderCrumbs'
import OutletCrumbs from '@components/Header/Breadcrumbs/OutletCrumbs'
import PartnerCrumbs from '@components/Header/Breadcrumbs/PartnerCrumbs'
import RestaurantCrumbs from '@components/Header/Breadcrumbs/RestaurantCrumbs'
import TerminalCrumbs from '@components/Header/Breadcrumbs/TerminalCrumbs'
import { useBreadcrumb } from '@stores/routerStore'
import { ProductType } from '@utils/types'
import { replace, startCase } from 'lodash'
import { ReportCrumbs } from './ReportCrumbs'
import Helmet from 'react-helmet'

const DEFAULT_BREADCRUMB = 'Redbox'

const shouldTruncate = crumbs => {
  return crumbs.length >= 3
}

const isSecondPart = (i: number) => i === 1
const isThirdPart = (i: number) => i === 2
const excludeThirdPartIfSecond = ['inbox', 'sent']

const DefaultCrumbs = ({ crumbs }) => {
  const titleText = `${startCase(crumbs[0])}${
    crumbs[1] ? ' - ' + startCase(crumbs[1]) : ''
  } | Redbox Platform`
  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      {crumbs.map((crumb: string, i: number) => {
        if (isSecondPart(i)) {
          return (
            <Crumb key={crumb} text={crumb} link={`/${crumbs[0]}/${crumb}`} />
          )
        }
        if (isThirdPart(i) && excludeThirdPartIfSecond.includes(crumbs[1])) {
          return
        } else if (isThirdPart(i)) {
          return (
            <Crumb
              key={crumb}
              text={replace(crumb, '-', ' ')}
              link={`/${crumbs[0]}/${crumbs[1]}/${crumb}`}
            />
          )
        }
        return <Crumb key={crumb} text={crumb} />
      })}
    </>
  )
}

const DynamicCrumbs = ({ crumbs, product }) => {
  let Crumbs
  switch (product.name) {
    case 'restaurant':
      Crumbs = RestaurantCrumbs
      break
    case 'partner':
      Crumbs = PartnerCrumbs
      break
    case 'marketplace':
      Crumbs = MarketplaceCrumbs
      break
    case 'delivery-zones':
      Crumbs = DeliveryZonesCrumbs
      break
    case 'outlet':
      Crumbs = OutletCrumbs
      break
    case 'terminal':
      Crumbs = TerminalCrumbs
      break
    case 'order':
      Crumbs = OrderCrumbs
      break
    case 'customer':
      Crumbs = CustomerCrumbs
      break
    case 'marketing':
      Crumbs = MarketingCrumbs
      break
    case 'report':
      Crumbs = ReportCrumbs
      break

    default:
      Crumbs = DefaultCrumbs
  }

  return <Crumbs crumbs={crumbs} />
}

const Breadcrumbs: React.FC<{
  product: ProductType
}> = ({ product }) => {
  const crumbs = useBreadcrumb(product.crumbNo)

  return (
    <NavbarGroup>
      <ul className={Classes.BREADCRUMBS}>
        {/* Default Breadcrumb is always present */}
        <Crumb
          collapsed={shouldTruncate(crumbs)}
          text={DEFAULT_BREADCRUMB}
          link="/"
        />
        <DynamicCrumbs product={product} crumbs={crumbs} />
      </ul>
    </NavbarGroup>
  )
}

export default Breadcrumbs
