import React, { useState } from 'react'
import BlacklistedAddressesPageContext from './BlacklistedAddressesPageContext'

const BlacklistedAddressPageWrapper = ({ children }) => {
  const [
    blacklistedAddressModalOpen,
    setBlacklistedAddressModalOpen,
  ] = useState(false)

  return (
    <BlacklistedAddressesPageContext.Provider
      value={{
        blacklistedAddressModalOpen,
        setBlacklistedAddressModalOpen,
      }}
    >
      {children}
    </BlacklistedAddressesPageContext.Provider>
  )
}

export default BlacklistedAddressPageWrapper
