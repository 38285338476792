import gql from 'graphql-tag'

const GET_EMAIL_THEME = gql`
  query getEmailTheme($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        cname
        emailBackgroundColor
        emailBodyColor
        emailButtonColor
        emailFontColor
        emailFooter
        emailFooterColor
        emailImageUrl
        emailShowImage
        emailShowCustomLogo
        uploadInProgress
        brandColor
        logoImage
        name
        emailSenderUsername
      }
    }

    getMyDetails {
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export default GET_EMAIL_THEME
