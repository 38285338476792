import gql from 'graphql-tag'

const EDIT_MENU_ITEMS_GROUP_PARENT_ID = gql`
  mutation editMenuItemsGroupParentId($id: String!, $parentMenuId: String) {
    editMenuItemsGroup(id: $id, parentMenuId: $parentMenuId) {
      message
      menuItemsGroup {
        id
        name
        position
        parentMenu {
          id
        }
      }
    }
  }
`

export default EDIT_MENU_ITEMS_GROUP_PARENT_ID
