import React, { useEffect, useState, useContext, Fragment } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Query from '@components/Query/Query'
import {
  Card,
  HTMLTable,
  Button,
  Divider,
  NonIdealState,
  ControlGroup,
  ButtonGroup,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { matchType } from '@utils/types'
import GET_REVENUE from './queries/getRevenue.query'
import Currency from '@components/Currency/Currency'
import { sumBy, orderBy, get, startCase, union, without } from 'lodash'
import { client } from '@services/client'
import { toast } from '@utils/toast'
import { penceToPounds } from '@utils/helpers'
import FilterRow from '@components/FilterRow/FilterRow'
import { canView } from '@stores/userStore'
import { PartnerFilter } from '@components/Toolbar'
import { format, startOfDay } from 'date-fns'
import { DATE_FILTER_TYPES } from '../../../utils/datetime'
import { shiftToRedboxPreMigration } from '../../../utils/dateTimeRangeShifters'
import { useDateRangeQueryParams } from '../../../components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '../../../components/Toolbar/DateRangeFilter/DateRangeFilter'
import { ArrayParam, useQueryParam } from 'use-query-params'

const Revenue = ({ product }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [{ to: '/reports/revenue', name: 'Revenue' }],
    })
  }, [configurePageLayout, product])

  const [partnerFilter = [], onChangePartnerFilter] = useQueryParam(
    'partnerFilter',
    ArrayParam
  )
  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.DAY.key,
    defaultStartOfRangeDate: startOfDay(new Date()),
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.MONTH,
      DATE_FILTER_TYPES.CUSTOM,
    ],
    shifter: shiftToRedboxPreMigration,
  }
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams(dateRangeFilterArgs)

  const [downloading, setDownloading] = useState(false)

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    onChangePartnerFilter(
      checked ? union(partnerFilter, [id]) : without(partnerFilter, id)
    )
  }

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'marketplace',
      'totalOrders',
      'totalOrdersValue',
      'totalDiscountValue',
      'totalNetworkValue',
      'totalPlatformValue',
      'totalPaymentProcessorValue',
      'totalCommissionValue',
      'totalPartnerValue',
      'totalRedboxValue',
      'totalPaidCashValue',
      'totalCashCommission',
      'totalRevenue',
      'totalRefunds',
      'totalRefundsValue',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_REVENUE,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
        },
      })
      .then(data => {
        orderBy(data.data.revenue, 'totalRevenue', 'desc').map(report => {
          csvData.push(
            csvHeader
              .map(column => {
                return {
                  marketplace: `"${report.marketplace.name}"`,
                  totalOrders: report.totalOrders,
                  totalOrdersValue: penceToPounds(report.totalOrdersValue),
                  totalDiscountValue: penceToPounds(report.totalDiscountValue),
                  totalNetworkValue: penceToPounds(report.totalNetworkValue),
                  totalPlatformValue: penceToPounds(report.totalPlatformValue),
                  totalPaymentProcessorValue: penceToPounds(
                    report.totalPaymentProcessorValue
                  ),
                  totalCommissionValue: penceToPounds(
                    report.totalCommissionValue
                  ),
                  totalPartnerValue: canView('partners')
                    ? penceToPounds(report.totalPartnerValue)
                    : '-',
                  totalRedboxValue: canView('partners')
                    ? penceToPounds(report.totalRedboxValue)
                    : '-',
                  totalPaidCashValue: penceToPounds(report.totalPaidCashValue),
                  totalCashCommission: penceToPounds(
                    report.totalCashCommission
                  ),
                  totalRevenue: penceToPounds(report.totalRevenue),
                  totalRefunds: penceToPounds(report.totalRefunds),
                  totalRefundsValue: penceToPounds(report.totalRefundsValue),
                }[column]
              })
              .join(',')
          )
        })
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `revenue-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  const resetFilters = () => {
    onChangePartnerFilter([])
  }

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <div>
          <ButtonGroup>
            <ControlGroup>
              <PartnerFilter
                partnerFilter={partnerFilter}
                onChange={handlePartnerFilter}
              />
              <Button
                icon="filter-remove"
                onClick={resetFilters}
                disabled={partnerFilter.length === 0}
              />
            </ControlGroup>
          </ButtonGroup>
          <Divider />
          <DateRangeFilter {...dateRangeFilterArgs} />
        </div>
        <Button
          loading={downloading}
          icon={'cloud-download'}
          style={{
            marginLeft: 'auto',
          }}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </FilterRow>

      <Query
        query={GET_REVENUE}
        variables={{
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
        }}
        loaderTitle="Loading Revenue Reports"
      >
        {({ revenue: marketplaces }) => {
          if (marketplaces.length > 0) {
            return (
              <div className="bp5-table-container bp5-scrollable">
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th colSpan={canView('partners') ? 7 : 6}></th>
                        <th
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          colSpan={canView('partners') ? 3 : 1}
                        >
                          Platform
                        </th>
                        <th
                          colSpan={2}
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                        >
                          Cash
                        </th>
                        <th
                          colSpan={3}
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                        >
                          Revenue
                        </th>
                      </tr>
                      <tr>
                        <th />
                        <th colSpan={2}>Orders</th>
                        <th>Discounts</th>
                        <th>Network</th>
                        <th>Marketplace</th>
                        {canView('partners') && <th>Partner</th>}
                        <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                          Total
                        </th>
                        {canView('partners') && <th>Redbox</th>}
                        {canView('partners') && <th>Paybox</th>}
                        <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                          Total
                        </th>
                        <th>Commission&nbsp;Due</th>
                        <th
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          colSpan={1}
                        >
                          Businesses
                        </th>
                        <th>Refunds</th>
                      </tr>
                      <tr>
                        <th style={{ width: '200px' }}>Marketplace</th>
                        <th style={{ width: '50px' }}>
                          {sumBy(marketplaces, report => {
                            return report.totalOrders
                          })}
                        </th>
                        <th style={{ width: '150px' }}>
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalOrdersValue
                            })}
                          />
                        </th>
                        <th style={{ width: '150px' }}>
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalDiscountValue
                            })}
                          />
                        </th>
                        <th style={{ width: '150px' }}>
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalNetworkValue
                            })}
                          />
                        </th>
                        <th style={{ width: '150px' }}>
                          (
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalCommissionValue
                            })}
                          />
                          )
                        </th>
                        {canView('partners') && (
                          <th
                            style={{
                              width: '150px',
                            }}
                          >
                            <Currency
                              amount={sumBy(marketplaces, report => {
                                return report.totalPartnerValue
                              })}
                            />
                          </th>
                        )}
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          (
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalPlatformValue
                            })}
                          />
                          )
                        </th>
                        {canView('partners') && (
                          <th style={{ width: '150px' }}>
                            <Currency
                              amount={sumBy(marketplaces, report => {
                                return report.totalRedboxValue
                              })}
                            />
                          </th>
                        )}
                        {canView('partners') && (
                          <th
                            style={{
                              width: '150px',
                            }}
                          >
                            <Currency
                              amount={sumBy(marketplaces, report => {
                                return report.totalPaymentProcessorValue
                              })}
                            />
                          </th>
                        )}
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          (
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalPaidCashValue
                            })}
                          />
                          )
                        </th>
                        <th style={{ width: '150px' }}>
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalCashCommission
                            })}
                          />
                        </th>
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalRevenue
                            })}
                          />
                        </th>
                        <th style={{ width: '50px', color: 'red' }}>
                          {sumBy(marketplaces, report => {
                            return report.totalRefunds
                          })}
                        </th>
                        <th style={{ width: '150px', color: 'red' }}>
                          <Currency
                            amount={sumBy(marketplaces, report => {
                              return report.totalRefundsValue
                            })}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(marketplaces, 'totalRevenue', 'desc').map(
                        report => (
                          <tr key={report.marketplace.id}>
                            <td>
                              <Link
                                to={`/marketplaces/${report.marketplace.id}`}
                              >
                                {report.marketplace.name}
                              </Link>
                            </td>
                            <td style={{ width: '50px' }}>
                              {report.totalOrders ? (
                                <Link
                                  to={`/orders/?dateFilterTypeKey=CUSTOM&startOfRangeDate=${format(
                                    shiftedStartOfRangeDateTime,
                                    'yyyy-MM-dd'
                                  )}&endOfRangeDate=${format(
                                    shiftedEndOfRangeDateTime,
                                    'yyyy-MM-dd'
                                  )}&marketplaceIds=${report.marketplace.id}`}
                                >
                                  {get(report, 'totalOrders', 0)}
                                </Link>
                              ) : (
                                <span className="bp5-text-muted">0</span>
                              )}
                            </td>
                            <td>
                              <Currency amount={report.totalOrdersValue} />
                            </td>
                            <td>
                              <Currency amount={report.totalDiscountValue} />
                            </td>
                            <td>
                              <Currency amount={report.totalNetworkValue} />
                            </td>
                            <td>
                              (
                              <Currency amount={report.totalCommissionValue} />)
                            </td>
                            {canView('partners') && (
                              <td>
                                <Currency amount={report.totalPartnerValue} />
                              </td>
                            )}
                            <td style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                              (
                              <Currency amount={report.totalPlatformValue} />)
                            </td>
                            {canView('partners') && (
                              <Fragment>
                                <td>
                                  <Currency amount={report.totalRedboxValue} />
                                </td>
                                <td>
                                  <Currency
                                    amount={report.totalPaymentProcessorValue}
                                  />
                                </td>
                              </Fragment>
                            )}
                            <td style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                              (
                              <Currency amount={report.totalPaidCashValue} />)
                            </td>
                            <td>
                              <Currency amount={report.totalCashCommission} />
                            </td>
                            <td style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                              <strong>
                                <Currency amount={report.totalRevenue} />
                              </strong>
                            </td>
                            <td style={{ width: '50px', color: 'red' }}>
                              {get(report, 'totalRefunds', 0)}
                            </td>
                            <td style={{ color: 'red' }}>
                              <Currency amount={report.totalRefundsValue} />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          } else {
            return (
              <NonIdealState
                icon="list"
                title="No Revenue Reports"
                description={`There are no revenue reports for this filter setting.`}
                action={
                  partnerFilter ? (
                    <Button
                      onClick={() => resetFilters()}
                      minimal
                      intent="primary"
                    >
                      Reset Filters
                    </Button>
                  ) : null
                }
              />
            )
          }
        }}
      </Query>
    </div>
  )
}

Revenue.propTypes = {
  match: matchType,
}

export default Revenue
