import React from 'react'
import { DataKeyMap } from '../util/maps'

export const OutletsOrCustomersContainer = ({
  records: { value, dataKey, color },
}) => {
  return value > 0 ? (
    <div>
      <small style={{ color }}>
        {DataKeyMap[dataKey]} <span style={{ color: 'black' }}>{value}</span>
      </small>
    </div>
  ) : null
}
