import GENERATE_PREVIEW_HTML_FOR_EMAIL from '@components/Marketplace/EmailTheme/queries/generatePreviewHtmlForEmail.query'
import { Row, Col } from '@components/_FlexGrid'
import { Spinner, Card, Elevation } from '@blueprintjs/core'
import HTMLEmailPreview from '@components/EmailLivePreview/HTMLEmailPreview'
import GET_EMAIL_THEME from '@components/Marketplace/EmailTheme/queries/getEmailTheme.query'
import InnerFormFragment, {
  EmailFormValues,
} from '@components/EmailEditor/InnerFormFragment'
import moment from 'moment'
import debouncedPropsHoc from '@utils/debouncedPropsHoc'
import Query from '@components/Query/Query'
import { Fragment } from 'react'
import TemplateVariableValidator from './TemplateVariableValidator'
import {
  GetEmailThemeQuery,
  GetEmailThemeQueryVariables,
} from '../Marketplace/EmailTheme/queries/getEmailTheme.query.generated'
import {
  GeneratePreviewHtmlForEmailQuery,
  GeneratePreviewHtmlForEmailQueryVariables,
} from '../Marketplace/EmailTheme/queries/generatePreviewHtmlForEmail.query.generated'
import { FormikProps } from 'formik'

const DebouncedPreviewQuery = debouncedPropsHoc(
  Query<
    GeneratePreviewHtmlForEmailQuery,
    GeneratePreviewHtmlForEmailQueryVariables
  >
)

const EmailEditor: React.FC<
  {
    marketplaceId: string
    disabled?: boolean
    setTemplateVarErrors?: (errors: string[]) => void
    templateVarErrors?: string[]
  } & Omit<FormikProps<EmailFormValues>, 'handleSubmit'>
> = ({
  marketplaceId,
  disabled = false,
  setTemplateVarErrors,
  templateVarErrors,
  ...formikBag
}) => {
  return (
    <Query<GetEmailThemeQuery, GetEmailThemeQueryVariables>
      query={GET_EMAIL_THEME}
      variables={{ marketplaceId }}
      loaderTitle={'Loading Email Theme'}
    >
      {data => {
        const {
          emailBackgroundColor,
          emailBodyColor,
          emailButtonColor,
          emailFontColor,
          emailFooter,
          emailFooterColor,
          emailImageUrl,
          emailShowImage,
          brandColor,
          logoImage,
          name,
          cname,
        } = data.getMarketplaces.regions[0]

        const { firstName, lastName, email } = data.getMyDetails.user

        // Build example data that works for all template types
        const marketplace = {
          name,
          cname,
        }

        const customer = {
          firstName,
          lastName,
          email,
          marketplace,
        }

        const contactAddress = {
          firstLine: 'First line of address',
          secondLine: 'Second line of address',
          city: 'City',
          postcode: 'Postcode',
        }

        const outlet = {
          name: 'Outlet Name',
          restaurant: {
            name: 'Business Name',
            contactAddress,
            contactPhone: 'Phone Number',
          },
          contactAddress,
          marketplace,
          contactPhone: 'Phone Number',
        }

        const order = {
          createdAt: moment().format('h:mma [on] MMMM Do'),
          estimatedCompletionTime: moment()
            .add(30, 'minutes')
            .format('h:mma [on] MMMM Do'),
          estimatedDeliveryDate: `${moment()
            .add(1, 'hour')
            .format('MMMM Do h:mma')} - ${moment()
            .add(2, 'hour')
            .format('h:mma')}`,
          orderType: 'delivery',
          dateToShowUser: `${moment()
            .add(1, 'hour')
            .format('MMMM Do h:mma')} - ${moment()
            .add(2, 'hour')
            .format('h:mma')}`,
          orderNumber: '123456-789-987-654',
          grossTotal: '43.21',
          cancellationNotes:
            'We are unfortunately unable to accept your order. If you have paid for your order online we will not be taking any payment.',
          customer,
          outlet,
        }
        const inviteToDiscountExampleData = {
          customerFirstName: firstName,
          customerLastName: lastName,
          businessName: 'Business Name',
          discountPercentage: 10,
          enrolmentKey: 'one-use-key',
          marketplaceCNAME: 'businessWebAddress',
        }

        const exampleData = {
          customer,
          order,
          outlet,
          ...inviteToDiscountExampleData,
        }

        const emailVars = {
          marketplaceId,
          emailBackgroundColor,
          emailBodyColor,
          emailButtonColor: emailButtonColor || brandColor,
          emailFontColor,
          emailFooter,
          emailFooterColor,
          emailImageUrl: emailImageUrl || logoImage,
          emailShowImage,
          content: formikBag.values.emailContent,
        }

        return (
          <Card>
            <Row style={{ paddingBottom: '20px' }}>
              <Col md={6}>
                <InnerFormFragment {...formikBag} disabled={disabled} />
              </Col>
              <Col md={6}>
                <Card
                  className="bp5-nopad"
                  style={{ overflow: 'hidden' }}
                  elevation={Elevation.TWO}
                >
                  <Card style={{ marginBottom: 0 }}>
                    To:{' '}
                    <strong className="bp5-skeleton">customer@gmail.com</strong>
                    <br />
                    From:{' '}
                    <strong>
                      {formikBag.values.marketplaceEmail
                        .trim()
                        .replace(/^www\./, '')}
                    </strong>
                    <br />
                    Subject: <strong>{formikBag.values.emailSubject}</strong>
                    <br />
                  </Card>

                  <DebouncedPreviewQuery
                    query={GENERATE_PREVIEW_HTML_FOR_EMAIL}
                    variables={{ ...emailVars }}
                    showLoader={false}
                    usePreviousData
                  >
                    {data => {
                      return !data ? (
                        <Spinner />
                      ) : (
                        <Fragment>
                          <TemplateVariableValidator
                            emailContent={formikBag.values.emailContent}
                            setTemplateVarErrors={setTemplateVarErrors}
                            templateVarErrors={templateVarErrors}
                          />
                          <HTMLEmailPreview
                            html={data.generatePreviewHtmlForEmail.html}
                            exampleData={exampleData}
                          />
                        </Fragment>
                      )
                    }}
                  </DebouncedPreviewQuery>
                </Card>
              </Col>
            </Row>
          </Card>
        )
      }}
    </Query>
  )
}

export default EmailEditor
