import React from 'react'
import { arrayOf, bool, number, object, shape, string } from 'prop-types'
import {
  ComposedChart,
  Line,
  Bar,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { get, indexOf, maxBy, meanBy } from 'lodash'
import CustomTooltip from './CustomTooltip'
import { renderCustomLabel } from './CustomLabel'
import { ANALYTICS_COLORS } from '../styles'

const defaultColors = {
  RED: ANALYTICS_COLORS.redBar,
  WHITE: ANALYTICS_COLORS.white,
  GREY: ANALYTICS_COLORS.greyLine,
  GREY_DASH: ANALYTICS_COLORS.greyForGraphs,
  BLUE: ANALYTICS_COLORS.blueLine,
  GREEN_DOT: ANALYTICS_COLORS.greenDot,
}

const LineGraph = ({
  data,
  tooltipDateFormat,
  period,
  displayBands = false,
  colors = defaultColors,
  currency = false,
  symbol = {},
  responsive = false,
}) => {
  const mean = meanBy(data, ({ y }) => y) || 0
  const max = maxBy(data, ({ y }) => y) || 0

  const generateBand = (start, end) => (
    <ReferenceArea
      key={`${start}-${end}`}
      x1={start}
      x2={end}
      y1={0}
      y2={max}
      fill={colors.GREY}
      mask="url(#mask-stripe)"
    />
  )

  const renderChart = () => (
    <ComposedChart
      width={500}
      height={180}
      data={data}
      margin={{
        top: 20,
        right: 0,
        left: 0,
        bottom: 20,
      }}
    >
      <defs>
        <pattern
          id="pattern-stripe"
          width="6"
          height="6"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="0.8"
            height="6"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
        <mask id="mask-stripe">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-stripe)"
          />
        </mask>
      </defs>

      {displayBands && renderBands()}
      <Bar
        dataKey="y3"
        connectNulls
        barSize={10}
        fill={colors.RED}
        yAxisId="right"
        maxBarSize={10}
        dot={false}
      />
      <Bar
        dataKey="y4"
        connectNulls
        barSize={10}
        fill={colors.GREY}
        yAxisId="right"
        maxBarSize={10}
        dot={false}
      />

      <Line
        type="monotone"
        dataKey="y2"
        connectNulls
        strokeWidth={2}
        stroke={colors.GREY}
        dot={false}
      />
      <Line
        type="monotone"
        connectNulls
        dataKey="y"
        stroke={colors.BLUE}
        strokeWidth={2}
        activeDot={{ r: 4 }}
        dot={false}
      />

      {mean && (
        // Mean Value
        <ReferenceLine
          y={mean}
          stroke={colors.GREY_DASH}
          strokeWidth={1}
          strokeDasharray="4 4"
          label={props =>
            renderCustomLabel({
              ...props,
              value: currency ? mean : mean.toFixed(0) + get(symbol, 'y', ''),
              positionRight: true,
              currency,
            })
          }
        />
      )}
      {max && (
        // Highest Value
        <ReferenceDot
          alwaysShow
          fill={colors.GREEN_DOT}
          r={4}
          stroke={colors.WHITE}
          y={max.y}
          x={indexOf(data, max)}
          label={props =>
            renderCustomLabel({
              ...props,
              value: currency ? max.y : max.y.toFixed(0) + get(symbol, 'y', ''),
              currency,
            })
          }
        />
      )}

      <Tooltip
        content={({ payload }) => (
          <CustomTooltip
            tooltipDateFormat={tooltipDateFormat}
            payload={payload}
            currency={currency}
            symbol={symbol}
          />
        )}
      />
    </ComposedChart>
  )

  const renderBands = () => {
    switch (period) {
      case 'day':
        return [generateBand(0, 6), generateBand(12, 18)]
      case 'week':
        return [generateBand(5, 7)]
      case 'month':
        return [generateBand(0, 7), generateBand(14, 21), generateBand(28, 35)]
    }
  }

  return responsive ? (
    <ResponsiveContainer width="100%" height="70%">
      {renderChart()}
    </ResponsiveContainer>
  ) : (
    renderChart()
  )
}

LineGraph.propTypes = {
  data: arrayOf(
    shape({
      x: string,
      y: number,
    })
  ),
  displayBands: bool,
  responsive: bool,
  colors: object,
  tooltipDateFormat: string,
  period: string,
  currency: bool,
  symbol: object,
}

export default LineGraph
