import gql from 'graphql-tag'

const LOYALTY_CARDS_BY_MARKETPLACE = gql`
  query loyaltyCardsByMarketplace(
    $marketplaceId: String!
    $searchText: String
    $orderBy: DiscountOrderByInput
    $queryFilterVars: [LoyaltyCardWhereInput!]
    $skip: Int
    $first: Int
  ) {
    discountsWithLoyaltyCardByMarketplace(
      marketplaceId: $marketplaceId
      where: { name_contains: $searchText }
      queryFilterVars: $queryFilterVars
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      count
      totalCount
      discounts {
        id
        name
        discountAmount
        uniqueCustomerCount
        cardsRedeemed
        discountPercentage
        minimumSubtotalGross
        allowedOutlets {
          id
          name
        }
        createdAt
        startDate
        endDate
        loyaltyCard {
          id
          requiredStamps
          loyaltyCardIcon
          loyaltyCardColor
          termsAndConditions
          active
          suspended
        }
      }
    }
  }
`

export default LOYALTY_CARDS_BY_MARKETPLACE
