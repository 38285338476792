import React, { Fragment } from 'react'
import {
  Card,
  Classes,
  H5,
  Icon,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Position,
  Spinner,
} from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import LOYALTY_CARD_LIABILITY_BREAKDOWN from './Queries/loyaltyCardLiabilityAnalytics'
import NonIdealStateSmaller from '../Analytics/NonIdealStateSmaller'
import Query from '../Query/Query'
import { first } from 'lodash'
import Row from '../_FlexGrid/Row'
import Col from '../_FlexGrid/Col'

const LoyaltyCardLiability = ({ discountId }) => {
  return (
    <Query
      query={LOYALTY_CARD_LIABILITY_BREAKDOWN}
      variables={{
        discountId,
      }}
      loaderTitle="Loading Analytics"
    >
      {({ loyaltyCardLiabilityForAnalytics: data, loading, error }) => {
        if (loading && !data) {
          return <Spinner />
        }

        if (error) {
          return (
            <NonIdealState icon="error" title="Unable to load liability data" />
          )
        }

        if (!data.length) return <NonIdealStateSmaller isLoyaltyCard={true} />

        const { liabilityBreakdown, totalDiscountEarned } = first(data)

        return (
          <Card>
            <Row>
              <Col span={10}>
                <H5>Liability Breakdown</H5>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <p className={'dashboard-data-value-small'}>
                  <Currency amount={totalDiscountEarned} />
                </p>
                <p className="dashboard-data-label">Completed Cards Value</p>
              </Col>

              <Col span={4}>
                <p className={'dashboard-data-value-small'}>
                  <Currency amount={liabilityBreakdown} />
                </p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p
                    style={{ marginRight: 8 }}
                    className="dashboard-data-label"
                  >
                    Outstanding Redeemable Value
                  </p>
                  <Popover
                    interactionKind={PopoverInteractionKind.HOVER}
                    position={Position.RIGHT_TOP}
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    content={
                      <Fragment>
                        <H5>Outstanding Redeemable Value</H5>
                        <p>
                          The total amount held by all customers on unredeemed
                          cards.
                        </p>
                      </Fragment>
                    }
                  >
                    <Icon icon="info-sign" color="#ced9e0" />
                  </Popover>
                </div>
              </Col>
            </Row>
          </Card>
        )
      }}
    </Query>
  )
}

export default LoyaltyCardLiability
