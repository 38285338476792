import gql from 'graphql-tag'

const GET_ROLE_PERMISSIONS = gql`
  query getRolePermissions {
    getAllRoles {
      roles {
        id
        key
        title
        permissionCategories {
          title
          queries
          mutations
        }
      }
    }
  }
`
export default GET_ROLE_PERMISSIONS
