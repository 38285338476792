import gql from 'graphql-tag'

const GET_ONBOARDING_COUNT = gql`
  query getOboardingCount {
    # SIGNUP is businesses waiting for approval
    getRestaurants(statusOnboarding: [SIGNUP]) {
      count
    }
  }
`

export default GET_ONBOARDING_COUNT
