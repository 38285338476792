import gql from 'graphql-tag'

const EDIT_TABLE_ACTIVATION_STATUS = gql`
  mutation editTableActivation($tableId: String!, $isActive: Boolean!) {
    editTableActivation(tableId: $tableId, isActive: $isActive) {
      id
      active
    }
  }
`
export default EDIT_TABLE_ACTIVATION_STATUS
