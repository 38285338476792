import {
  AnchorButton,
  Classes,
  H5,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const LinkTag = ({ type, message }) => {
  switch (type) {
    case 'deprecated':
      return (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.RIGHT_TOP}
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          content={
            <Fragment>
              <H5>Deprecated</H5>
              {message}
              <AnchorButton
                href="https://support.redbox.systems/docs/service-maintenance-release-notes"
                rightIcon="share"
                target="_blank"
                rel="noopener noreferrer"
                fill
              >
                Release Notes
              </AnchorButton>
            </Fragment>
          }
        >
          <a
            href="https://support.redbox.systems/docs/website2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tag minimal interactive icon="flow-end" intent={Intent.DANGER} />
          </a>
        </Popover>
      )
    case 'web-v2':
      return (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.RIGHT_TOP}
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          content={
            <Fragment>
              <H5>Web 2.0 / App 5.0+</H5>
              <p>This feature is only available in compatible clients.</p>
              <AnchorButton
                href="https://support.redbox.systems/docs/website2"
                rightIcon="share"
                target="_blank"
                rel="noopener noreferrer"
                fill
              >
                Website 2.0 Documentation
              </AnchorButton>
            </Fragment>
          }
        >
          <a
            href="https://support.redbox.systems/docs/website2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tag
              minimal
              interactive
              icon="flow-linear"
              intent={Intent.WARNING}
            />
          </a>
        </Popover>
      )
    default:
      return <Tag intent={Intent.DANGER}>*</Tag>
  }
}

LinkTag.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element, // Accept React components
  ]),
}

export default LinkTag
