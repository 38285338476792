import gql from 'graphql-tag'
import { OPTION_ITEM_FRAGMENT } from '../fragments/optionItemFragment.fragment'

export const EDIT_OPTION_ITEM = gql`
  ${OPTION_ITEM_FRAGMENT}
  mutation optionImportEditOptionItem(
    $id: String!
    $name: String
    $price: Int
    $description: String
    $isVegan: Boolean
    $isVegetarian: Boolean
    $isDairyFree: Boolean
    $isGlutenFree: Boolean
    $ageRestricted: Boolean
  ) {
    editOptionItem(
      id: $id
      name: $name
      price: $price
      description: $description
      isVegan: $isVegan
      isVegetarian: $isVegetarian
      isDairyFree: $isDairyFree
      isGlutenFree: $isGlutenFree
      ageRestricted: $ageRestricted
    ) {
      message
      optionItem {
        ...optionItemFieldsAndParentOption
      }
    }
  }
`
