import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowOnboardingForm = ({ updateStatus, allowOnboarding }) => {
  return (
    <Formik initialValues={{ allowOnboarding }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Business Onboarding'}
            checked={values.allowOnboarding}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowOnboarding: e.currentTarget.checked,
              })
            }}
            name="allowOnboarding"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowOnboardingForm.propTypes = {
  allowOnboarding: bool,
  errors: object,
}

export default MarketplaceAllowOnboardingForm
