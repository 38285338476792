import React from 'react'
import {
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Tooltip,
  Position,
  ControlGroup,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import { deliveryZoneType } from '@utils/constants'

const Form = ({ values, handleSubmit, handleChange, errors }) => (
  <form onSubmit={handleSubmit}>
    <ControlGroup>
      <FormGroup
        label="Name"
        labelFor="name"
        helperText={errors.name ? errors.name : ''}
        intent={Intent.DANGER}
        inline
      >
        <InputGroup
          type="text"
          name="name"
          id="name"
          value={values.name}
          onChange={handleChange}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>

      {values.deliveryZoneType === deliveryZoneType.COORDINATE_POLYGON &&
      !values.geoFence ? (
        <Tooltip
          content="Draw a delivery zone on the map"
          position={Position.LEFT}
        >
          <Button disabled type="submit" text="Save" />
        </Tooltip>
      ) : (
        <Button type="submit" text="Save" />
      )}
    </ControlGroup>
  </form>
)

Form.propTypes = {
  values: object,
  handleSubmit: func,
  handleChange: func,
  setFieldValue: func,
}

export default Form
