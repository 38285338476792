import * as yup from 'yup'

export const newDeliveryZone = {
  name: yup
    .string()
    .required('The delivery zone should have a name')
    .min(4, 'The delivery zone name should be longer than 4 chars'),
  marketplaceId: yup
    .string()
    .required('A marketplace id is required')
    .matches(/\w{25}/, 'The marketplace id should be 25 chars long'),
  deliveryZoneType: yup
    .string()
    .oneOf([
      'STUART',
      'COORDINATE_POLYGON',
      'RADIUS_AROUND_OUTLET',
      'WAYBOX',
      'UBER_DIRECT',
    ]),
  geoFence: yup
    .object()
    .when('deliveryZoneType', (deliveryZoneType, schema) => {
      if (
        typeof deliveryZoneType === 'string' &&
        deliveryZoneType === 'COORDINATE_POLYGON'
      ) {
        return schema.required('Draw a delivery zone') // geoFence required
      } else {
        return schema.nullable() // geoFence is nullable
      }
    }),
}

export const editDeliveryZone = {
  name: yup
    .string()
    .min(4, 'The delivery zone name should be longer than 4 chars'),
  geofence: yup.array(),
}
