import gql from 'graphql-tag'

const EDIT_HEADER_LINKS_ORDER = gql`
  mutation editHeaderLinksOrder(
    $input: [IPositionArgs!]!
    $marketplaceId: String!
  ) {
    editHeaderLinksOrder(input: $input, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export default EDIT_HEADER_LINKS_ORDER
