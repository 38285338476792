import gql from 'graphql-tag'

const GENERATE_PREVIEW_HTML_FOR_EMAIL = gql`
  query generatePreviewHtmlForEmail(
    $emailBackgroundColor: String!
    $emailBodyColor: String!
    $emailButtonColor: String!
    $emailFontColor: String!
    $emailFooter: String!
    $emailFooterColor: String!
    $emailShowImage: Boolean!
    $uploadInProgress: Boolean
    $emailImageUrl: String!
    $content: [Json!]!
    $marketplaceId: String!
  ) {
    generatePreviewHtmlForEmail(
      content: $content
      marketplaceId: $marketplaceId
      theme: {
        emailBackgroundColor: $emailBackgroundColor
        emailBodyColor: $emailBodyColor
        emailButtonColor: $emailButtonColor
        emailFontColor: $emailFontColor
        emailFooter: $emailFooter
        emailFooterColor: $emailFooterColor
        emailShowImage: $emailShowImage
        emailImageUrl: $emailImageUrl
        uploadInProgress: $uploadInProgress
      }
    ) {
      html
    }
  }
`

export default GENERATE_PREVIEW_HTML_FOR_EMAIL
