import gql from 'graphql-tag'

const CREATE_SEGMENT = gql`
  mutation addMarketingSegment($name: String!, $marketplaceId: ID!) {
    addSegment(name: $name, marketplaceId: $marketplaceId) {
      segment {
        id
        name
        estimate
        updatedAt
        filterGroupsType
        filterGroups {
          filterGroupType
          filters {
            attribute
            comparator
            value
          }
        }
      }
    }
  }
`

export default CREATE_SEGMENT
