import gql from 'graphql-tag'

const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatusOrderPage(
    $id: String!
    $orderStatus: OrderStatus!
    $estimatedDeliveryDate: String
    $estimatedCompletionTime: String
    $cancellationNotes: String
  ) {
    updateOrderStatus(
      id: $id
      orderStatus: $orderStatus
      estimatedDeliveryDate: $estimatedDeliveryDate
      estimatedCompletionTime: $estimatedCompletionTime
      cancellationNotes: $cancellationNotes
    ) {
      message
      order {
        orderStatus
        estimatedDeliveryDate
        estimatedCompletionTime
      }
    }
  }
`

export default UPDATE_ORDER_STATUS
