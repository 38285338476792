import gql from 'graphql-tag'

export const GET_ALLOW_ADD_ON_ITEM_MENUS = gql`
  query restaurantAllowAddOnItemsFlag($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        allowAddOnItems
      }
    }
  }
`
