import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_NAME from './queries/getMarketplaceName.query'
import replace from 'lodash/replace'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const MarketplaceCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_MARKETPLACE_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getMarketplaces }) => {
            if (!getMarketplaces) {
              return null
            }
            const { regions } = getMarketplaces
            return (
              <Crumb
                key={crumb}
                text={regions.length ? regions[0].name : crumb}
                link={`/marketplaces/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={replace(crumb, '-', ' ')}
          link={`/marketplaces/${crumbs[1]}/${crumb}`}
        />
      )
    }

    return <Crumb key={crumb} text={crumb} />
  })

MarketplaceCrumbs.propTypes = {
  crumbs: (props, propName, componentName) => {
    const crumbs = props[propName]
    if (!Array.isArray(crumbs)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array recived ${typeof crumbs}`
      )
    }
    if (crumbs.length !== 3) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array of length 2`
      )
    }
  },
}

export default MarketplaceCrumbs
