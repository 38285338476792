import React, { Fragment } from 'react'
import CostInput from './CostInput'
import RadiusInput from './RadiusInput'
import PickupNotesInput from './PickupNotesInput'
import { UndeliverableAction } from './UndeliverableAction'

const UberDirectFormGroup = formikProps => {
  return (
    <Fragment>
      <RadiusInput {...formikProps} />
      <PickupNotesInput {...formikProps} />
      <CostInput {...formikProps} />
      <UndeliverableAction {...formikProps} />
    </Fragment>
  )
}

export default UberDirectFormGroup
