import gql from 'graphql-tag'

const RESET_TERMINAL_PIN = gql`
  mutation resetTerminalPin($id: String!, $pin: String!) {
    editTerminalPin(id: $id, pin: $pin) {
      message
      terminal {
        id
      }
    }
  }
`

export default RESET_TERMINAL_PIN
