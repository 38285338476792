import gql from 'graphql-tag'

const GET_MARKETPLACE_WEBSITE = gql`
  query getMarketplaceWebsite($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        title
        metaDescription
        metaKeywords
        brandColor
        brandSecondaryColor
        brandTertiaryColor
        heroImage
        heroText
        heroTextSecondary
        faviconImage
        logoImage
        appImage
        appText
        appTextSecondary
        storeURLApple
        storeURLGooglePlay
        socialURLFacebook
        socialURLTwitter
        socialURLTikTok
        headerLinks {
          id
          title
          link
          position
        }
        footerLinks {
          id
          title
          link
          position
        }
        socialURLInstagram
        featureFAQ
        featureRegister
        featureLogin
        featureOrdering
        featureRecruit
        featurePromotions
        defaultSearch
        stripeId
        allowLocationSearch
        allOutletsText
        defaultFulfilmentOption
        defaultDatepickerOption
        defaultLocationOption
        enableRedirectFromLandingPage
        facebookPixelId
        enableCustomerV2Client
        nowFilter
      }
    }
  }
`

export default GET_MARKETPLACE_WEBSITE
