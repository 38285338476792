import gql from 'graphql-tag'

const ADD_OPTION_ITEM = gql`
  mutation addOptionItem(
    $name: String!
    $price: Int!
    $parentId: String
    $restaurantId: String!
    $vatRate: Float
    $VATinclusive: Boolean
    $soldOut: Boolean
    $image: String
    $position: Int
  ) {
    addOptionItem(
      name: $name
      price: $price
      parentId: $parentId
      restaurantId: $restaurantId
      vatRate: $vatRate
      VATinclusive: $VATinclusive
      soldOut: $soldOut
      image: $image
      position: $position
    ) {
      message
      optionItem {
        id
        name
        price
        vatRate
        VATinclusive
        soldOut
        position
        parentOption {
          id
          name
        }
        imageImplementer {
          id
          src
        }
      }
    }
  }
`

export default ADD_OPTION_ITEM
