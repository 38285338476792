import React from 'react'
import styled from 'styled-components'
import { string, bool, object, number, shape } from 'prop-types'
import moment from 'moment'
import RestaurantInfo from './sections/RestaurantInfo.tsx'
import Fulfilment from './sections/Fulfilment'
import CustomerInfo from './sections/CustomerInfo'
import DeliveryNotes from './sections/DeliveryNotes'
import OrderItems from './sections/OrderItems'
import Totals from './sections/Totals'
import OrderNotes from './sections/OrderNotes'
import PaidStatus from './sections/PaidStatus'
import OrderInfo from './sections/OrderInfo'
import AgeRestriction from './sections/AgeRestriction'

export const Container = styled.main`
  display: block;
  width: 100%;
  max-width: 300px;
  background: #fff;
  color: #000;
  padding: 10px;
  font-family: 'Roboto Mono', monospace;

  h1 {
    font-size: 16px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 12px;
  }
`

export const Image = styled.img`
  width: 150px;
  max-width: 30%;
  margin-bottom: 10px;
  filter: grayscale(100%);
`

export const Title = styled.h1`
  line-height: 1;
  padding: 0;
  margin: 0;
`

export const Section = styled.section`
  border-bottom: 1px dashed #000;
  padding: 6px 0;
  page-break-before: auto;
  break-inside: avoid;

  &:last-child {
    border-bottom: 0;
  }
`

export const CenterSection = styled(Section)`
  text-align: center;
`

export const BoldSection = styled(Section)`
  font-weight: 500;
`

export const Text = styled.p`
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 10px;
`

export const MenuItem = styled.h4`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 1;
  padding: 0;
  margin: 0;
`

export const OptionItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const OptionItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 10px;
`

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Bold = styled.span`
  font-weight: 500;
`

const formatDateString = value => moment(value).format('HH:mm D-MMM')

const OrderPrintout = ({ order }) => {
  if (!order) return null
  return (
    <Container>
      <RestaurantInfo order={order} />

      <Fulfilment order={order} formatDateString={formatDateString} />

      <CustomerInfo order={order} />

      {order.orderItems &&
        order.orderItems.some(item => !!item.menuItem.ageRestricted) && (
          <AgeRestriction order={order} />
        )}

      <DeliveryNotes order={order} />

      <OrderItems order={order} />

      <Totals order={order} />

      <OrderNotes order={order} />

      <PaidStatus order={order} />

      <OrderInfo order={order} formatDateString={formatDateString} />
    </Container>
  )
}

OrderPrintout.propTypes = {
  order: shape({
    id: string,
    orderStatus: string,
    orderNumber: string,
    asap: bool,
    paid: bool,
    grossTotal: number,
    estimatedCompletionTime: string,
    estimatedDeliveryDate: string,
    fulfillmentMethod: string,
    paymentMethod: string,
    createdAt: string,
    acceptedAt: string,
    updatedAt: string,
    isAddOnItem: bool,
    outlet: shape({
      name: string,
      restaurant: shape({
        name,
      }),
      outletPhone: string,
    }),
    customer: {
      phoneNumber: string,
      firstName: string,
      lastName: string,
    },
  }).isRequired,
}

export default OrderPrintout
