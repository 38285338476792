import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import { BlueprintProvider } from '@blueprintjs/core'
import { ModalProvider } from './providers/ModalProvider'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import Helmet from 'react-helmet'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Helmet>
      <title>Redbox Platform</title>
    </Helmet>
    <BlueprintProvider>
      <DndProvider backend={HTML5Backend}>
        <ModalProvider>
          <App />
        </ModalProvider>
      </DndProvider>
    </BlueprintProvider>
  </React.StrictMode>
)
