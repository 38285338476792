import React, { Fragment } from 'react'
import Filter from '@components/Toolbar/Filter'

import GET_PARTNER_IDS from './queries/getPartnerIds.query'
import { isPartner, isPlatformLevelUser } from '../../stores/userStore'
import { Divider, IconName } from '@blueprintjs/core'

export const PartnerFilter: React.FC<{
  disabled?: boolean
  icon: IconName
  partnerFilter: string[]
  onChange: (filter: string[]) => void
  divider?: boolean
}> = ({ disabled = false, icon, partnerFilter, onChange, divider = false }) =>
  (isPartner() || isPlatformLevelUser()) && (
    <Fragment>
      <Filter
        name="Partner"
        icon={icon}
        disabled={disabled}
        filter={partnerFilter}
        onChange={onChange}
        query={GET_PARTNER_IDS}
        dataPath="getPartners.partners"
      />
      {divider && <Divider />}
    </Fragment>
  )
