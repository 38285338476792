import gql from 'graphql-tag'

const CANCEL_NETWORK_ORDER = gql`
  mutation cancelNetworkOrder($id: String!, $reason: String!) {
    cancelNetworkOrder(orderId: $id, reason: $reason) {
      message
      order {
        id
        orderStatus
        estimatedDeliveryDate
        estimatedCompletionTime
      }
    }
  }
`

export default CANCEL_NETWORK_ORDER
