import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryNetworkProvidersForPartnerQueryVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
}>;


export type GetDeliveryNetworkProvidersForPartnerQuery = { __typename?: 'Query', getPartners: { __typename?: 'GetPartnersResponse', partners?: Array<{ __typename?: 'Partner', id: string, deliveryNetworkProviders: Array<string> } | null> | null } };


export const GetDeliveryNetworkProvidersForPartnerDocument = gql`
    query getDeliveryNetworkProvidersForPartner($partnerId: String!) {
  getPartners(id: $partnerId) {
    partners {
      id
      deliveryNetworkProviders
    }
  }
}
    `;

/**
 * __useGetDeliveryNetworkProvidersForPartnerQuery__
 *
 * To run a query within a React component, call `useGetDeliveryNetworkProvidersForPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryNetworkProvidersForPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryNetworkProvidersForPartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetDeliveryNetworkProvidersForPartnerQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables> & ({ variables: GetDeliveryNetworkProvidersForPartnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>(GetDeliveryNetworkProvidersForPartnerDocument, options);
      }
export function useGetDeliveryNetworkProvidersForPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>(GetDeliveryNetworkProvidersForPartnerDocument, options);
        }
export function useGetDeliveryNetworkProvidersForPartnerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>(GetDeliveryNetworkProvidersForPartnerDocument, options);
        }
export type GetDeliveryNetworkProvidersForPartnerQueryHookResult = ReturnType<typeof useGetDeliveryNetworkProvidersForPartnerQuery>;
export type GetDeliveryNetworkProvidersForPartnerLazyQueryHookResult = ReturnType<typeof useGetDeliveryNetworkProvidersForPartnerLazyQuery>;
export type GetDeliveryNetworkProvidersForPartnerSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryNetworkProvidersForPartnerSuspenseQuery>;
export type GetDeliveryNetworkProvidersForPartnerQueryResult = Apollo.QueryResult<GetDeliveryNetworkProvidersForPartnerQuery, GetDeliveryNetworkProvidersForPartnerQueryVariables>;