import React from 'react'
import Currency from '@components/Currency/Currency'
import { Section, MenuItem, OptionItems, OptionItem } from '../OrderPrintout'

const buildItem = (item, amount) => ({ ...item, amount })

const OrderItems = ({ order }) => {
  const orderItems = order.orderItems.reduce((acc, item) => {
    const hasOptions = !!item.optionItems.length
    if (hasOptions) {
      acc = [...acc, buildItem(item, 1)]
      return acc
    }

    const existsWithNoOptions = acc.find(findItem => {
      const hasNoOptions = findItem.optionItems.length === 0
      const exists = findItem.menuItem.id === item.menuItem.id
      return hasNoOptions && exists
    })

    if (existsWithNoOptions) {
      existsWithNoOptions.amount = existsWithNoOptions.amount + 1
    } else {
      acc = [...acc, buildItem(item, 1)]
    }

    return acc
  }, [])

  return (
    <Section>
      {orderItems.map(
        ({
          id,
          menuItem,
          optionItems,
          amount,
          isAddOnItem,
          singleItemNotes,
        }) => (
          <article key={id}>
            <MenuItem>
              <span>
                {amount}x {menuItem.name}
              </span>{' '}
              <Currency amount={menuItem.price * amount} />
            </MenuItem>

            {isAddOnItem ? (
              <OptionItem>
                <span>- OFFER</span>
              </OptionItem>
            ) : null}

            {!!optionItems.length && (
              <OptionItems>
                {optionItems.map(optionItem => (
                  <OptionItem key={optionItem.id}>
                    <span>- {optionItem.name}</span>
                    {!!optionItem.price && (
                      <Currency amount={optionItem.price} />
                    )}
                  </OptionItem>
                ))}
              </OptionItems>
            )}
            {singleItemNotes ? (
              <OptionItem>
                <span>{singleItemNotes}</span>
              </OptionItem>
            ) : null}
          </article>
        )
      )}
    </Section>
  )
}

export default OrderItems
