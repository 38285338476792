import { Button, Card, HTMLTable, Icon, Intent } from '@blueprintjs/core'
import { formatEnum } from '@utils/helpers'
import { find, get } from 'lodash'
import { Fragment } from 'react'
import MessageDrawer from './MessageDrawer'

import { MessageTemplateSettings } from './MessageTemplateSettings'
import GET_MARKETPLACE_MESSAGE_TEMPLATES from './queries/getMarketplaceMessageTemplates.query'
import { StringParam, useQueryParam } from 'use-query-params'
import { isPlatformLevelUser } from '@stores/userStore'
import { TemplateTypesEnum } from '../../../types.generated'
import { useParams } from 'react-router-dom'
import Query from '@src/components/Query/Query'

const MessageTemplates = () => {
  // specific order from RED-8664
  const orderedTemplateKeys = [
    'REGISTRATION',
    'RESET',
    'FAIL',
    'ACCEPT_COLLECTION',
    'ACCEPT_DELIVERY',
    'ACCEPT_TABLE',
    'REJECT',
    'CANCEL',
    'READY_COLLECTION',
    'READY_DELIVERY',
    'COMPLETE',
    'UPDATE_TIME',
    'INVITE_TO_DISCOUNT',
    'CAMPAIGN',
  ]
  const [viewTemplate, onChangeViewTemplate] = useQueryParam(
    'viewTemplate',
    StringParam
  )

  const { marketplace: marketplaceId } = useParams()
  const templatesEnums = Object.values(TemplateTypesEnum)
    .filter(key => isNaN(Number(key)))
    .sort(
      (a, b) => orderedTemplateKeys.indexOf(a) - orderedTemplateKeys.indexOf(b)
    )

  return (
    <div className="bp5-layout-row">
      <Card className="bp5-nopad bp5-layout-col">
        <Query
          query={GET_MARKETPLACE_MESSAGE_TEMPLATES}
          variables={{ marketplaceId: marketplaceId }}
          showLoader={true}
          loaderTitle="Loading Message Templates"
          loaderSubTitle="Please wait..."
        >
          {({ getMarketplaceMessageTemplates, getMarketplaces }) => {
            return (
              <Fragment>
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>Template</th>
                      <th
                        colSpan={2}
                        style={{ width: '80%', textAlign: 'right' }}
                      >
                        {getMarketplaces.regions[0].emailSenderUsername ||
                          'Not configured'}{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {templatesEnums.map((templateKey, key) => {
                      const template = find(
                        getMarketplaceMessageTemplates.messageTemplates,
                        {
                          templateType: templateKey,
                        }
                      )
                      const templateName = formatEnum(templateKey)

                      return (
                        <tr key={key}>
                          <td>
                            {template ? (
                              <a
                                onClick={() =>
                                  onChangeViewTemplate(templateKey)
                                }
                              >
                                {templateName}
                              </a>
                            ) : (
                              templateName
                            )}
                          </td>
                          <td>{get(template, 'emailSubject')}</td>
                          <td style={{ textAlign: 'right' }}>
                            {template ? (
                              <Icon icon={'tick'} intent={Intent.SUCCESS} />
                            ) : (
                              <Button
                                minimal={true}
                                icon={'add'}
                                onClick={() =>
                                  onChangeViewTemplate(templateKey)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </HTMLTable>
                <MessageDrawer
                  isOpen={Boolean(viewTemplate)}
                  marketplaceId={marketplaceId}
                  templateType={viewTemplate}
                  drawerClose={() => {
                    onChangeViewTemplate(undefined)
                  }}
                />
              </Fragment>
            )
          }}
        </Query>
      </Card>
      {isPlatformLevelUser() && (
        <div className="bp5-layout-col-side">
          <MessageTemplateSettings marketplaceId={marketplaceId} />
        </div>
      )}
    </div>
  )
}

export default MessageTemplates
