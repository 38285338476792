import React, { useState } from 'react'
import UserPageContext from './usersPageContext'

const UsersPageWrapper = ({ entityName, children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState(null)

  return (
    <UserPageContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        user,
        setUser,
        entityName,
      }}
    >
      {children}
    </UserPageContext.Provider>
  )
}

export default UsersPageWrapper
