import { useContext } from 'react'
import { Classes, EditableText } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { successToast } from '@utils/toast'
import { modalType } from '@utils/types'
import { object } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import APPROVE_REVIEW from '../mutations/approveReview.mutation'
import REMOVE_REVIEW from '../mutations/removeReview.mutation'
import REPLY from '../mutations/reply.mutation'
import Review from '@components/Review/Review'
import GET_REVIEWS from '@components/Reviews/queries/getReviews.query'
import { canPerformAction } from '@stores/userStore'
import { ModalContext } from '@src/providers/ModalProvider'
import { useMutation } from '@apollo/client'

const EditReviewModal = ({
  modalOpen,
  modalClose,
  review,
  review: { approved, reply },
  variables,
}) => {
  let replyText = ''
  const canModerate = canPerformAction('moderateReview')
  const { setState: toggleModal } = useContext(ModalContext)

  const [removeReview] = useMutation(REMOVE_REVIEW, {
    onCompleted: ({ removeReview }) => {
      successToast(removeReview.message)
      toggleModal({
        editReviewModal: false,
      })
    },
    refetchQueries: [
      {
        query: GET_REVIEWS,
        variables,
      },
    ],
    onError: defaultErrorHandler,
  })

  const [replyToReview] = useMutation(REPLY, {
    onCompleted: ({ replyToReview }) => {
      successToast(replyToReview.message)
      toggleModal({
        editReviewModal: false,
      })
      replyText = ''
    },
    refetchQueries: [
      {
        query: GET_REVIEWS,
        variables,
      },
    ],
    onError: defaultErrorHandler,
  })

  const [approveReview] = useMutation(APPROVE_REVIEW, {
    onCompleted: ({ approveReview }) => {
      successToast(approveReview.message)
      toggleModal({
        editReviewModal: false,
      })
    },
    refetchQueries: [
      {
        query: GET_REVIEWS,
        variables,
      },
    ],
    onError: defaultErrorHandler,
  })

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Edit Review">
      <div className={Classes.DIALOG_BODY}>
        <Review review={review} shouldDisplayHeader={false} />
        {approved && !reply && (
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={10}
            placeholder="Reply here..."
            onChange={value => (replyText = value)}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="bp5-dialog-footer-actions">
          {canModerate && (
            <button
              type="button"
              className="bp5-button bp5-intent-danger"
              onClick={() => {
                removeReview({
                  variables: { id: review.id },
                })
              }}
            >
              Delete
            </button>
          )}
          {approved && !reply && (
            <button
              type="button"
              className="bp5-button bp5-intent-success"
              onClick={() => {
                replyToReview({
                  variables: {
                    id: review.id,
                    reply: replyText,
                  },
                })
              }}
            >
              Reply
            </button>
          )}
          {canModerate && !approved && (
            <button
              type="button"
              className="bp5-button bp5-intent-primary"
              onClick={() => {
                approveReview({
                  variables: { id: review.id },
                })
              }}
            >
              Approve
            </button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

EditReviewModal.propTypes = {
  review: object,
  ...modalType,
}

export default EditReviewModal
