import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryNetworkFailureReportHeatmapQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']['input'];
}>;


export type GetDeliveryNetworkFailureReportHeatmapQuery = { __typename?: 'Query', getDeliveryNetworkFailureReportHeatmap?: Array<{ __typename?: 'DeliveryNetworkFailureReportHeatmap', id: string, hour: number, day: number, totalOrders: number, totalFailedOrders: number, totalFailedValue: number, failureRate: number }> | null };


export const GetDeliveryNetworkFailureReportHeatmapDocument = gql`
    query getDeliveryNetworkFailureReportHeatmap($marketplaceId: String!) {
  getDeliveryNetworkFailureReportHeatmap(marketplaceId: $marketplaceId) {
    id
    hour
    day
    totalOrders
    totalFailedOrders
    totalFailedValue
    failureRate
  }
}
    `;

/**
 * __useGetDeliveryNetworkFailureReportHeatmapQuery__
 *
 * To run a query within a React component, call `useGetDeliveryNetworkFailureReportHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryNetworkFailureReportHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryNetworkFailureReportHeatmapQuery({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *   },
 * });
 */
export function useGetDeliveryNetworkFailureReportHeatmapQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables> & ({ variables: GetDeliveryNetworkFailureReportHeatmapQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>(GetDeliveryNetworkFailureReportHeatmapDocument, options);
      }
export function useGetDeliveryNetworkFailureReportHeatmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>(GetDeliveryNetworkFailureReportHeatmapDocument, options);
        }
export function useGetDeliveryNetworkFailureReportHeatmapSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>(GetDeliveryNetworkFailureReportHeatmapDocument, options);
        }
export type GetDeliveryNetworkFailureReportHeatmapQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportHeatmapQuery>;
export type GetDeliveryNetworkFailureReportHeatmapLazyQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportHeatmapLazyQuery>;
export type GetDeliveryNetworkFailureReportHeatmapSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportHeatmapSuspenseQuery>;
export type GetDeliveryNetworkFailureReportHeatmapQueryResult = Apollo.QueryResult<GetDeliveryNetworkFailureReportHeatmapQuery, GetDeliveryNetworkFailureReportHeatmapQueryVariables>;