import gql from 'graphql-tag'

const ENABLE_CUSTOMER_V2_CLIENT = gql`
  mutation enableCustomerV2Client(
    $id: String!
    $enableCustomerV2Client: Boolean!
  ) {
    editMarketplace(id: $id, enableCustomerV2Client: $enableCustomerV2Client) {
      message
      marketplace {
        id
        enableCustomerV2Client
      }
    }
  }
`

export default ENABLE_CUSTOMER_V2_CLIENT
