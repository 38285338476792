import styled from 'styled-components'
import { Button, Navbar, NavbarGroup, Icon, IconName } from '@blueprintjs/core'
import topLeftLogo from '@assets/topLeftLogo.svg'
import Breadcrumbs from '@components/Header/Breadcrumbs/Breadcrumbs'
import TabBar, { TabType } from '@src/components/Header/TabBar/TabBar'
import { ProductType } from '@src/utils/types'
import { useNavigate } from 'react-router-dom'
import config from '../../config'

interface HeaderProps {
  product: ProductType
  button?: {
    text: string
    icon: IconName
    action: () => void
  }
  permissions?: boolean
  tabs?: Array<TabType>
  persistQueryParamsBetweenTabs?: boolean
}

const Header: React.FC<HeaderProps> = ({
  product,
  button,
  permissions = true,
  tabs,
  persistQueryParamsBetweenTabs,
}) => {
  const navigate = useNavigate()
  return (
    <header>
      <TopHeader
        className={!config.isProd ? 'devBanner' : ''}
        fixedToTop={true}
      >
        <Breadcrumbs product={product} />
        <NavbarGroup>
          {button && permissions && (
            <ButtonStyled
              {...button}
              icon={
                <Icon
                  icon={(button && button.icon) || 'add'}
                  color={
                    button.icon === 'add' || !button.icon
                      ? '#1ac57e'
                      : '#5c7080'
                  }
                />
              }
            />
          )}
        </NavbarGroup>
      </TopHeader>
      <TabBar
        tabs={tabs}
        persistQueryParamsBetweenTabs={persistQueryParamsBetweenTabs}
      />
      <div>
        <TopLeftLogo
          src={topLeftLogo}
          alt="logo"
          onClick={() => navigate('/')}
        />
      </div>
    </header>
  )
}
const TopHeader = styled(Navbar)`
  width: 100%;
  height: 48px;
  z-index: 2;
  padding-left: 64px;
  padding-right: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonStyled = styled(Button)`
  background-image: none !important;
  color: ${({ theme: { colors } }) => colors.primaryButtonText} !important;
  box-shadow: ${({ theme: { colors } }) =>
    colors.primaryButtonShadow} !important;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryButtonColor} !important;
`

const TopLeftLogo = styled.img`
  top: 0;
  z-index: 20;
  position: fixed;
`

export default Header
