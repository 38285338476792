import * as yup from 'yup'

// TODO many custom error messages maybe not here though maybe as part of menu definition??
export const importItemSchema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  description: yup.string().nullable(),
  price: yup.number().required(),
  isVegan: yup.boolean().nullable(),
  isVegetarian: yup.boolean().nullable(),
  isDairyFree: yup.boolean().nullable(),
  isGlutenFree: yup.boolean().nullable(),
  spiceLevel: yup.boolean().nullable(),
  ageRestricted: yup.boolean().nullable(),
  optionId: yup.string().required().typeError('Option is a required field'),
})
