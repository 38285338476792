import { Classes, Icon, Tooltip } from '@blueprintjs/core'
import { format, getDate, getMonth, getYear, isValid, parseISO } from 'date-fns'
import { bool, string } from 'prop-types'
import React from 'react'
import TimeAgo from 'react-timeago'

const DateTime = ({
  dateTime,
  leftIcon = null,
  timeAgo = false,
  longFormat = 'eeee, MMMM do yyyy HH:mm:ss',
  showTime = true,
  showDay = true,
  showYear = true,
  showSeconds = false,
  hideDayIfSame = true,
  hideYearIfSame = true,
  displayFormat = null,
  className = null,
  description = null,
}) => {
  if (!dateTime) return null

  // make format string
  const date = parseISO(dateTime)
  if (!isValid(date)) return null

  let dateFormatTokens = []
  const isSameYear = getYear(date) === getYear(new Date())
  const isSameDay =
    getDate(date) === getDate(new Date()) &&
    getMonth(date) === getMonth(new Date())

  if (showDay && (!hideDayIfSame || isSameDay === false)) {
    dateFormatTokens.push('d MMM')
  }

  if (showYear && (!hideYearIfSame || isSameYear === false)) {
    dateFormatTokens.push(' yyyy')
  }
  if (dateFormatTokens.length > 0) dateFormatTokens.push(', ')
  if (showTime) dateFormatTokens.push('HH:mm')
  if (showSeconds) dateFormatTokens.push(':ss')

  const displayFormatString = displayFormat
    ? displayFormat
    : dateFormatTokens.join('')

  const timeElement = (
    <span className={className}>
      {leftIcon && <Icon icon={leftIcon} size={16} />}
      {timeAgo ? (
        <TimeAgo date={date} live title={''} />
      ) : (
        format(date, displayFormatString)
      )}
    </span>
  )

  return timeAgo || description ? (
    <Tooltip
      content={
        description
          ? `${description}: ${format(date, longFormat)}`
          : format(date, longFormat)
      }
      hoverOpenDelay={500}
      className={Classes.TOOLTIP_INDICATOR}
    >
      {timeElement}
    </Tooltip>
  ) : (
    timeElement
  )
}

DateTime.propTypes = {
  dateTime: string,
  leftIcon: string,
  timeAgo: bool,
  longFormat: string,
  displayFormat: string,
  showTime: bool,
  showDay: bool,
  showYear: bool,
  hideDayIfSame: bool,
  hideYearIfSame: bool,
  className: string,
}

export default DateTime
