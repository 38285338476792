import React, { useContext } from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { errorToast, successToast } from '@utils/toast'
import AppBuildForm from '@components/Apps/AppBuildForm'
import { appBuildValidation } from './validation'
import config from '@src/config'
import ls from '@utils/localStorage'
import appPageContext from './appPageContext'

const StartAppBuild = ({ modalOpen, modalClose }) => {
  const { appId } = useContext(appPageContext)
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Mobile App Build">
      <div className={Classes.DIALOG_BODY}>
        <Formik
          validationSchema={appBuildValidation}
          initialValues={{
            appId,
            device: '',
            version: '',
            action: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            const environmentDomain = config.environmentDomain
            fetch(
              `https://app-build.${environmentDomain}/app/${appId}/build/device/${
                values.device
              }/version/${values.version}/release_to_appcenter/${
                values.action === 'release_to_appcenter' ? '1' : '0'
              }/release_to_store/${
                values.action === 'release_to_store' ? '1' : '0'
              }`,
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${ls.get('jwt')}`,
                  'Content-Type': 'application/json',
                },
              }
            )
              .then(res => res.json())
              .then(
                () => {
                  successToast('Submitted')
                  modalClose()
                },
                () => {
                  errorToast('Error starting the build')
                  actions.setSubmitting(false)
                }
              )
          }}
        >
          {props => <AppBuildForm {...props} appId={appId} />}
        </Formik>
      </div>
    </Dialog>
  )
}

StartAppBuild.propTypes = {
  ...modalType,
  appId: string,
  device: string,
  version: string,
}

export default StartAppBuild
