import gql from 'graphql-tag'

const ADD_OUTLET = gql`
  mutation addOutlet(
    $name: String!
    $restaurantId: String!
    $marketplaceId: String!
  ) {
    addOutlet(
      name: $name
      restaurantId: $restaurantId
      marketplaceId: $marketplaceId
    ) {
      message
      outlet {
        id
        name
        marketplace {
          id
        }
      }
    }
  }
`
export default ADD_OUTLET
