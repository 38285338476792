import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client'
import { Spinner, NonIdealState } from '@blueprintjs/core'

export type QueryProps<
  T = Record<string, any>,
  V = Record<string, any>
> = QueryHookOptions<T, V> & {
  /** The GraphQL query document */
  query: DocumentNode
  /** When true, spinner is only shown on the first load */
  usePreviousData?: boolean
  /** Defaults to true. Renders null for loading when set to false */
  showLoader?: boolean
  /** Title for the loader's non-ideal state */
  loaderTitle?: string
  /** Subtitle for the loader's non-ideal state */
  loaderSubTitle?: string
  /** Icon to display in the loader */
  loaderIcon?: JSX.Element
  /** Component to render in case of an error */
  ErrorComponent?: JSX.Element
  /**
   * Function that renders the child component.
   * @param data - The query result data
   * @param refetch - Function to refetch the query
   */
  children: (data: T, refetch: () => void) => JSX.Element | string
}

/**
 * Wrapper around Apollo's useQuery hook that provides a loading spinner and error handling.
 *
 * @template T - The type of data returned by the query.
 * @param {QueryProps<T>} props - useQuery options and additional props.
 * @returns {React.ReactNode} - The rendered component.
 * @example 
 *  <Query<GetEmailThemeQuery, GetEmailThemeQueryVariables>
      query={GET_EMAIL_THEME}
      variables={{ marketplaceId }}
      loaderTitle={'Loading Email Theme'}
    >
      {data => {
 */
const Query = <T, V = any>({
  query,
  variables = {} as V,
  children,
  usePreviousData = false,
  showLoader = true,
  loaderTitle = '',
  loaderSubTitle = 'Please wait...',
  loaderIcon = <Spinner size={60} />,
  ErrorComponent = null,
  ...props
}: QueryProps<T, V>) => {
  const {
    loading,
    error,
    data: _data,
    refetch,
    previousData,
  } = useQuery(query, {
    variables,
    ...props,
  })

  const data = usePreviousData ? _data || previousData : _data

  if (loading && (!usePreviousData || !data)) {
    return showLoader ? (
      loaderTitle ? (
        <NonIdealState
          icon={loaderIcon}
          title={loaderTitle}
          description={loaderSubTitle}
        />
      ) : (
        loaderIcon
      )
    ) : null
  }

  if (error) {
    return ErrorComponent ? ErrorComponent : null
  }

  return children(data, refetch)
}

export default Query
