import { useState } from 'react'
import {
  Intent,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Popover,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import ls from '@utils/localStorage'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import DELETE_CUISINE from '../mutations/deleteCuisine.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import { useMutation } from '@apollo/client'
import Picker from '@emoji-mart/react'

const CategoryForm = ({
  initialName = '',
  initialEmoji = '',
  initialImage = '',
  onSubmit,
  validationSchema,
  categoryId,
  closeDrawer,
  refetch,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [deleteCuisine] = useMutation(DELETE_CUISINE, {
    onError: defaultErrorHandler,
    onCompleted: ({ deleteCuisine }) => {
      successToast(deleteCuisine.message)
      closeDrawer(null)
      refetch()
    },
  })

  return (
    <Formik
      initialValues={{
        name: initialName,
        emoji: initialEmoji,
        image: initialImage,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        setFieldValue,
        setStatus,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                label="Name"
                labelFor="name"
                helperText={errors.name ? errors.name : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="name"
                  large
                  value={values.name}
                  onChange={handleChange}
                  autoFocus
                />
              </FormGroup>

              <FormGroup
                label="Emoji"
                labelFor="emoji"
                helperText={errors.emoji ? errors.emoji : ''}
                intent={errors.emoji ? Intent.DANGER : Intent.NONE}
              >
                <InputGroup
                  name="emoji"
                  id="emoji"
                  value={values.emoji}
                  onChange={handleChange}
                  rightElement={
                    <Popover
                      minimal={true}
                      isOpen={isPopoverOpen}
                      content={
                        <div>
                          <Picker
                            theme={ls.get('darkMode') ? 'dark' : 'light'}
                            autoFocus
                            title="Pick an Emoji"
                            emoji=""
                            onEmojiSelect={emoji => {
                              void setFieldValue('emoji', emoji.native, false)
                              setIsPopoverOpen(false)
                            }}
                          />
                        </div>
                      }
                    >
                      <Button
                        text="Select an emoji"
                        rightIcon="emoji"
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                      />
                    </Popover>
                  }
                />
              </FormGroup>

              <ImageUploadDirectSingle
                imageLabel="Image"
                setFieldValue={setFieldValue}
                values={values}
                helperText="Please use a .png format at 512 x 512 pixels."
                setStatus={setStatus}
                sizeLimit={1000000}
                imageName="image"
                replace={true}
                showImagePickerDialog={false}
                imageType="image/png"
                optionItemId="categoryImage"
              />
            </div>
            <div className="bp5-drawer-footer-actions">
              <ConfirmationPopover
                remove={async () => {
                  await deleteCuisine({
                    variables: {
                      id: categoryId,
                    },
                  })
                }}
                confirmationText="Are you sure you want to delete this category?"
              >
                <Button
                  text="Delete"
                  type="button"
                  intent={Intent.DANGER}
                  minimal
                />
              </ConfirmationPopover>

              <Button type="submit" text="Save" />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default CategoryForm
