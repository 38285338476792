import { Card } from '@blueprintjs/core'
import React from 'react'
import { capitalize } from 'lodash'
import { groupData } from '@components/Analytics/util/groupData'
import {
  AvgValuesContainer,
  DiscountedValuesContainer,
  NumberOfOrdersContainer,
  OrdersStatusContainer,
  OutletsOrCustomersContainer,
  PaymentsOrFulfilmentContainer,
  UserAgentsContainer,
  TotalValuesContainer,
} from '@components/Analytics/sharedTooltipComponents'

export const renderTooltip = ({
  resolution,
  tooltipData: { active, payload, label },
}) => {
  if (active && payload && payload.length) {
    const {
      outlets,
      paymentMethods,
      fulfillmentMethods,
      statuses,
      numberOfOrders,
      totalValues,
      avgValue,
      discountedValues,
      userAgents,
    } = groupData(payload)

    return (
      <Card
        style={{
          backgroundColor: 'white',
          padding: '1rem',
        }}
      >
        <h3>{`${capitalize(resolution)} : ${label}`}</h3>
        <OrdersStatusContainer statuses={statuses} title="Statuses" />
        <TotalValuesContainer
          totalValues={totalValues}
          title="Aggregated Totals"
          resolution={resolution}
        />
        <AvgValuesContainer avgValue={avgValue} />
        <DiscountedValuesContainer
          discountedValues={discountedValues}
          title="Aggregated Total Discounted Values"
          resolution={resolution}
        />
        <NumberOfOrdersContainer
          numberOfOrders={numberOfOrders}
          title={`Orders per ${resolution ? capitalize(resolution) : 'Day'}`}
          resolution={resolution}
        />
        <PaymentsOrFulfilmentContainer
          records={paymentMethods}
          title="Payments"
        />
        <PaymentsOrFulfilmentContainer
          records={fulfillmentMethods}
          title="Fulfillments"
        />
        <UserAgentsContainer records={userAgents} title="User Agents" />
        <OutletsOrCustomersContainer records={outlets} />
      </Card>
    )
  }

  return null
}
