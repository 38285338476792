import gql from 'graphql-tag'

export const EDIT_OUTLET_OTTER_STORE_ID = gql`
  mutation editOutletOtterStoreId($id: String!, $otterStoreId: String!) {
    editOutletOtterStoreId(id: $id, otterStoreId: $otterStoreId) {
      id
      otterStoreId
    }
  }
`
