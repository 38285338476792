import React from 'react'
import { func, bool } from 'prop-types'

import GET_RESTAURANT_IDS from './queries/getRestaurantsByIds.query'
import { array } from 'yup'
import TypeaheadSearch from '@components/Typeahead/TypeaheadSearch'
import GET_RESTAURANTS_BY_NAME from './queries/getRestaurantsByName.query'
import { Query } from '@apollo/client/react/components'
import { errorToast } from '@utils/toast'
import { debounce } from 'lodash'
import { Spinner } from '@blueprintjs/core'

const formatRestaurantData = ({ id, name, outlets }) => ({
  id,
  name,
  label: outlets.length ? `${outlets.length} outlets` : '',
})

const RestaurantMultipleSelect = ({ restaurantIds, onChange, disabled }) => (
  <Query query={GET_RESTAURANT_IDS} variables={{ ids: restaurantIds }}>
    {({ data: outerData, error = null }) => {
      if (error) {
        errorToast(error.message)
        return null
      }

      if (!outerData || !outerData.getRestaurantsByIds) {
        return <Spinner size={20} />
      }

      const {
        getRestaurantsByIds: { restaurants: initialRestaurants },
      } = outerData

      return (
        <Query query={GET_RESTAURANTS_BY_NAME}>
          {({ data, error = null, loading, refetch }) => {
            const debouncedRefetch = debounce(refetch)

            if (error) {
              errorToast(error.message)
              return null
            }

            const usingLiveData = !loading

            if (!data || !data.getRestaurants) {
              return <Spinner size={20} />
            }

            const {
              getRestaurants: { restaurants },
            } = data

            return (
              <TypeaheadSearch
                initialSelectedItems={initialRestaurants.map(
                  formatRestaurantData
                )}
                items={restaurants.map(formatRestaurantData)}
                selected={restaurantIds}
                setSelected={onChange}
                selectedIcon="unlock"
                unSelectedIcon="lock"
                placeholder="Select businesses..."
                onQueryChange={searchName => {
                  debouncedRefetch({
                    searchName,
                  })
                }}
                usingLiveData={usingLiveData}
                disabled={disabled}
              />
            )
          }}
        </Query>
      )
    }}
  </Query>
)

RestaurantMultipleSelect.propTypes = {
  restaurantIds: array,
  onChange: func,
  disabled: bool,
}

export default RestaurantMultipleSelect
