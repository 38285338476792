import gql from 'graphql-tag'

const GET_RESTAURANT_OUTLETS_BY_ID = gql`
  query getRestaurantOutlets($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        outlets {
          id
          name
          active
        }
      }
    }
  }
`

export default GET_RESTAURANT_OUTLETS_BY_ID
