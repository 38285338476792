import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Classes,
  ControlGroup,
  H5,
  HTMLTable,
  Icon,
  Intent,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import Favicon from '@components/Favicon/Favicon'
import MarketplaceModal from '@components/MarketplaceModal/Marketplace.modal'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import {
  canPerformAction,
  canView,
  isAtLeastPartner,
  isPartner,
} from '@stores/userStore'
import { union, without } from 'lodash'
import map from 'lodash/map'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GET_MARKETPLACES from './queries/getMarketplaces.query'

import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import FilterRow from '@components/FilterRow/FilterRow'
import { Pager, PartnerFilter, Search } from '@components/Toolbar'
import ls from '@utils/localStorage'
import {
  BooleanParam,
  DelimitedArrayParam,
  useQueryParams,
} from 'use-query-params'
import { formatAddressObj } from '../../utils/helpers'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import productDictionary from '@src/utils/productDictionary'
import { useModal } from '@src/providers/ModalProvider'
import ConnectStatus from '@components/ConnectStatus/ConnectStatus'

const DEFAULT_RECORDS = 50
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  orderBy: 'name_ASC',
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const PageLayoutWrapper = ({ children }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  const product = productDictionary.MARKETPLACE
  const { toggleModal } = useModal('marketplaceModal')

  const button = canPerformAction('addMarketplace')
    ? {
        text: 'Add Marketplace',
        onClick: () => toggleModal(true),
      }
    : null

  useEffect(() => {
    configurePageLayout({
      product,
      button,
      permissions: canPerformAction('addMarketplace'),
      tabs: [
        { to: '/marketplaces', name: 'List' },
        { to: '/marketplaces/map', name: 'Map' },
      ],
    })
  }, [configurePageLayout, product])

  return children
}

const Marketplaces = () => {
  const [
    { partnerFilter = [], marketplaceIds = [], showDeactivated = false },
    setQueryParams,
  ] = useQueryParams({
    partnerFilter: DelimitedArrayParam,
    marketplaceIds: DelimitedArrayParam,
    showDeactivated: BooleanParam,
  })
  const [marketplacePagination, setMarketplacePagination] = useState(
    DEFAULT_PAGINATION_STATE
  )
  const { searchValue, resetSearch } = useSearchQueryParam()
  const navigate = useNavigate()

  useEffect(() => {
    if (searchValue) {
      setMarketplacePagination(DEFAULT_PAGINATION_STATE)
    }
  }, [searchValue, setMarketplacePagination])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplacePagination.skip + marketplacePagination.first <
      marketplacePagination.total
    ) {
      setMarketplacePagination({
        ...marketplacePagination,
        skip: limitNext(
          marketplacePagination.skip,
          marketplacePagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    const skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplacePagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMarketplacePagination({
      ...marketplacePagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplacePagination({
      ...marketplacePagination,
      skip: limitPrevious(
        marketplacePagination.skip,
        marketplacePagination.first
      ),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    const skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      partnerFilter: checked
        ? union(partnerFilter, [id])
        : without(partnerFilter, id),
    })
  }

  const setFilterState = (count, totalCount) => {
    if (marketplacePagination.total !== totalCount) {
      setMarketplacePagination({
        ...marketplacePagination,
        total: totalCount,
      })
    }
    if (marketplacePagination.outcomeLength !== count) {
      setMarketplacePagination({
        ...marketplacePagination,
        outcomeLength: count,
      })
    }
  }

  const clearAllFilters = () => {
    setQueryParams({
      partnerFilter: undefined,
      marketplaceIds: undefined,
    })
    resetSearch()
  }

  const renderFilterBar = () => {
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <Search autoFocus placeholder="Marketplace..." />
            <PartnerFilter
              partnerFilter={partnerFilter}
              onChange={handlePartnerFilter}
              divider={false}
            />

            <Button
              icon="filter-remove"
              onClick={clearAllFilters}
              disabled={
                !marketplaceIds?.length &&
                !partnerFilter?.length &&
                !searchValue
              }
            />
          </ControlGroup>
        </ButtonGroup>

        {isAtLeastPartner() && (
          <div>
            <Checkbox
              label="Show Deactivated"
              checked={showDeactivated}
              onChange={e =>
                setQueryParams({
                  showDeactivated: e.currentTarget.checked,
                })
              }
              style={{ margin: '0' }}
            />
          </div>
        )}

        <Pager
          goToPrevious={e => goToPrevious(e)}
          goToNext={e => goToNext(e)}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={marketplacePagination.skip}
          total={marketplacePagination.total}
          outcomeLength={marketplacePagination.outcomeLength}
          totalCount={marketplacePagination.total}
          dataName="Marketplaces"
        />
      </FilterRow>
    )
  }

  return (
    <PageLayoutWrapper>
      <div className="bp5-table-frame">
        {renderFilterBar()}
        <DebouncedQuery
          query={GET_MARKETPLACES}
          loaderTitle={'Loading Marketplaces'}
          variables={{
            orderBy: 'name_DESC',
            ...marketplacePagination,
            name_contains: searchValue.length > 2 ? searchValue : '',
            partnerIds: partnerFilter,
            marketplaceIds: marketplaceIds,
            includeDeactivated: showDeactivated,
          }}
        >
          {data => {
            if (
              !data ||
              !data.getMarketplaces ||
              !data.getMarketplaces.regions.length
            )
              return (
                <NonIdealState
                  icon="th-list"
                  title="No Marketplaces Found"
                  description="There are no marketplaces that meet your search criteria."
                  action={
                    <Button
                      onClick={() => {
                        clearAllFilters()
                      }}
                      minimal
                      intent="primary"
                    >
                      Clear Filters
                    </Button>
                  }
                />
              )
            const {
              getMarketplaces: { regions, count, totalCount },
            } = data

            const border = ls.get('darkMode')
              ? { borderLeft: '1px solid #5C7080' }
              : { borderLeft: '1px solid #DCDCDD' }

            setFilterState(count, totalCount)
            return (
              <div className="bp5-table-container bp5-scrollable">
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th colSpan={canView('partners') ? 7 : 7} />
                        {canView('partners') && (
                          <Fragment>
                            <th colSpan={1} style={{ ...border, width: 60 }}>
                              Partner
                            </th>
                            <th colSpan={2} style={border}>
                              Platform
                            </th>
                            <th colSpan={2} style={border}>
                              Marketplace
                            </th>
                          </Fragment>
                        )}

                        <th colSpan={2} style={border}>
                          App Versions
                        </th>
                      </tr>
                      <tr>
                        <th>Marketplace</th>
                        <th>Partner</th>
                        <th>Company</th>
                        <th style={{ width: 60 }}>Outlets</th>
                        <th style={{ width: 60 }}>Zones</th>
                        <th style={{ width: 60 }} colSpan={2}>
                          Payments
                        </th>
                        {canView('partners') && (
                          <Fragment>
                            <th style={{ ...border, width: 60 }}>Commission</th>
                            <th style={border}>Fee</th>
                            <th>Charge</th>
                            <th style={border}>Fee</th>
                            <th>Charge</th>
                          </Fragment>
                        )}
                        <th style={border}>iOS</th>
                        <th>Android</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(regions, region => (
                        <tr key={region.id}>
                          <td>
                            <Popover
                              interactionKind={PopoverInteractionKind.HOVER}
                              position={Position.RIGHT_TOP}
                              content={
                                <HTMLTable compact>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Icon icon="link" size={12} />
                                      </td>
                                      <td>
                                        <a
                                          className="bp5-text-overflow-ellipsis"
                                          href={`https://${region.cname}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {region.cname}
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Icon icon="tag" size={12} />
                                      </td>
                                      <td>
                                        <Tag minimal>{region.key}</Tag>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Icon icon="inheritance" size={12} />
                                      </td>
                                      <td>
                                        <Tag minimal>{region.orderMode}</Tag>
                                      </td>
                                    </tr>
                                  </tbody>
                                </HTMLTable>
                              }
                            >
                              <Fragment>
                                <Favicon src={region.faviconImage} /> &nbsp;
                                {isPartner() && region.isDeactivated ? (
                                  <p>{region.name}</p>
                                ) : (
                                  <Link to={`/marketplaces/${region.id}`}>
                                    {region.name}
                                  </Link>
                                )}{' '}
                                {region.enableCustomerV2Client && (
                                  <Tag minimal>V2</Tag>
                                )}
                              </Fragment>
                            </Popover>
                          </td>
                          <td>
                            {region && region.partner && region.partner.id && (
                              <Link to={`/partners/${region.partner.id}`}>
                                {region.partner.name}
                              </Link>
                            )}
                          </td>
                          <td>
                            <Popover
                              interactionKind={PopoverInteractionKind.HOVER}
                              position={Position.RIGHT_TOP}
                              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                              content={
                                <Fragment>
                                  <H5>{region.contactName}</H5>
                                  <p>
                                    {region.contactPhone}
                                    <br />
                                    {region.contactEmail && (
                                      <a href={`mailto:${region.contactEmail}`}>
                                        {region.contactEmail}
                                      </a>
                                    )}
                                  </p>
                                  <p>
                                    {formatAddressObj(region.contactAddress)}
                                  </p>
                                </Fragment>
                              }
                              className={Classes.TOOLTIP_INDICATOR}
                            >
                              {region.companyLegalName || ''}
                            </Popover>
                          </td>
                          <td>
                            {region.outlets.length ? (
                              <Link
                                to={`/outlets/?marketplaceIds=${region.id}`}
                              >
                                {region.outlets.length}
                              </Link>
                            ) : (
                              <span className="bp5-text-muted">0</span>
                            )}
                          </td>
                          <td>
                            {region.deliveryZones.length ? (
                              <Link
                                to={`/marketplaces/${region.id}/delivery-zones`}
                              >
                                {region.deliveryZones.length}
                              </Link>
                            ) : (
                              <span className="bp5-text-muted">0</span>
                            )}
                          </td>{' '}
                          <td>
                            <Tag
                              onClick={() => {
                                navigate(`/marketplaces/${region.id}/payments`)
                              }}
                              minimal
                              interactive
                              intent={
                                region.stripeId || region.stripeEnterpriseId
                                  ? region.stripeDirectPayment
                                    ? Intent.SUCCESS
                                    : region.stripeOnboarding
                                    ? Intent.WARNING
                                    : Intent.NONE
                                  : Intent.DANGER
                              }
                            >
                              {region.stripeId || region.stripeEnterpriseId
                                ? region.stripeDirectPayment
                                  ? `${
                                      region.stripeEnterpriseId
                                        ? 'PAYBOX-E'
                                        : 'PAYBOX-S'
                                    }`
                                  : region.stripeOnboarding
                                  ? 'ONBOARDING'
                                  : 'MANUAL'
                                : 'OFFLINE'}
                            </Tag>
                          </td>
                          <td>
                            <ConnectStatus
                              stripeConnect={region.stripeConnect}
                              stripeOnboarding={region.stripeOnboarding}
                              stripeId={region.stripeId}
                              link={`/marketplaces/${region.id}/payments`}
                            />
                          </td>
                          {canView('partners') && (
                            <Fragment>
                              {region && region.partnerCommissionFee ? (
                                <td style={border}>
                                  {region.partnerCommissionFee ||
                                    region.partner.partnerCommissionFee ||
                                    0}{' '}
                                  %
                                </td>
                              ) : (
                                <td style={border}>0 %</td>
                              )}
                              <td style={border}>
                                {region.platformFee || 0} %
                              </td>
                              <td>
                                <Currency amount={region.platformCharge || 0} />
                              </td>

                              <td style={border}>{region.partnerFee || 0} %</td>
                              <td>
                                <Currency amount={region.partnerCharge || 0} />
                              </td>
                            </Fragment>
                          )}
                          <td style={border}>{region.iosVersion}</td>
                          <td>{region.androidVersion}</td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          }}
        </DebouncedQuery>
      </div>
      <MarketplaceModal />
    </PageLayoutWrapper>
  )
}

export default Marketplaces
