import { Tag, Icon } from '@blueprintjs/core'
import React from 'react'
import { getIntent } from './Apps'
const getLastAppBuildData = (appBuilds, requiredField, device, target) => {
  const appBuildsCloneReverseFiltered = [...appBuilds]
    .reverse()
    .find(ab => ab.device === device && ab[target])

  if (appBuildsCloneReverseFiltered) {
    return appBuildsCloneReverseFiltered[requiredField]
  } else {
    return 'N/A'
  }
}
const getLastAppBuildLastStatus = (appBuilds, device, target) => {
  const appBuildsCloneReverseFiltered = [...appBuilds]
    .reverse()
    .find(abs => abs.device === device && abs[target])
  if (
    appBuildsCloneReverseFiltered &&
    appBuildsCloneReverseFiltered.appBuildStatuses.length > 0
  ) {
    return appBuildsCloneReverseFiltered.appBuildStatuses.slice(-1)[0].status
  } else {
    return 'N/A'
  }
}

const AppInfoBar = ({ data, device, target }) => {
  const lastBuildLastStatus = getLastAppBuildLastStatus(
    data,
    device,
    target
  ).toUpperCase()

  return (
    <div>
      <Tag minimal round>
        {getLastAppBuildData(data, 'version', device, target)}
      </Tag>{' '}
      <Icon icon="caret-right" color={'#e8ebee'} />{' '}
      <Tag minimal intent={getIntent(lastBuildLastStatus)}>
        {lastBuildLastStatus}
      </Tag>
    </div>
  )
}

export default AppInfoBar
