import React, { Fragment, useEffect, useState } from 'react'
import GET_MARKETPLACES from './queries/getMarketplaces.query'
import {
  HTMLTable,
  Card,
  Tag,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
  ButtonGroup,
  ControlGroup,
  NonIdealState,
  Button,
} from '@blueprintjs/core'
import map from 'lodash/map'
import { Link } from 'react-router-dom'
import Favicon from '@components/Favicon/Favicon'
import { canView } from '@stores/userStore'
import { union, without } from 'lodash'

import { Search, Pager } from '@components/Toolbar'
import FilterRow from '@components/FilterRow/FilterRow'
import { PartnerFilter } from '@components/Toolbar'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import ls from '@utils/localStorage'
import { useQueryParams, StringParam } from 'use-query-params'
import { useSearchQueryParam } from '../../components/Toolbar/Search/useSearchQueryParam'

const DEFAULT_RECORDS = 50
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  orderBy: 'name_ASC',
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const Marketplaces = () => {
  const [{ partnerFilter = [], marketplaceIds = [] }, setQueryParams] =
    useQueryParams({
      partnerFilter: StringParam,
      marketplaceIds: StringParam,
    })
  const [marketplacePagination, setMarketplacePagination] = useState(
    DEFAULT_PAGINATION_STATE
  )
  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      setMarketplacePagination(DEFAULT_PAGINATION_STATE)
    }
  }, [searchValue, setMarketplacePagination])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplacePagination.skip + marketplacePagination.first <
      marketplacePagination.total
    ) {
      setMarketplacePagination({
        ...marketplacePagination,
        skip: limitNext(
          marketplacePagination.skip,
          marketplacePagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplacePagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMarketplacePagination({
      ...marketplacePagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplacePagination({
      ...marketplacePagination,
      skip: limitPrevious(
        marketplacePagination.skip,
        marketplacePagination.first
      ),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      partnerFilter: checked
        ? union(partnerFilter, [id])
        : without(partnerFilter, id),
    })
  }

  const setFilterState = (count, totalCount) => {
    if (marketplacePagination.total !== totalCount) {
      setMarketplacePagination({
        ...marketplacePagination,
        total: totalCount,
      })
    }
    if (marketplacePagination.outcomeLength !== count) {
      setMarketplacePagination({
        ...marketplacePagination,
        outcomeLength: count,
      })
    }
  }

  const clearAllFilters = () => {
    setQueryParams({
      partnerFilter: undefined,
      marketplaceIds: undefined,
    })
    resetSearch()
  }

  const renderFilterBar = () => {
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <Search autoFocus placeholder="Marketplace, partner..." />
            <PartnerFilter
              partnerFilter={partnerFilter}
              onChange={handlePartnerFilter}
            />
            <Button
              icon="filter-remove"
              onClick={clearAllFilters}
              disabled={
                !marketplaceIds.length && !partnerFilter.length && !searchValue
              }
            />
          </ControlGroup>
        </ButtonGroup>

        <Pager
          goToPrevious={e => goToPrevious(e)}
          goToNext={e => goToNext(e)}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={marketplacePagination.skip}
          total={marketplacePagination.total}
          outcomeLength={marketplacePagination.outcomeLength}
          totalCount={marketplacePagination.total}
          dataName="Marketplaces"
        />
      </FilterRow>
    )
  }

  return (
    <Fragment>
      <div className="bp5-table-frame">
        {renderFilterBar()}
        <DebouncedQuery
          query={GET_MARKETPLACES}
          loaderTitle={'Loading Marketplaces'}
          variables={{
            orderBy: 'name_DESC',
            ...marketplacePagination,
            name_contains: searchValue.length > 2 ? searchValue : '',
            partnerIds: partnerFilter,
            marketplaceIds: marketplaceIds,
            sesMessagingRequired: true,
          }}
        >
          {data => {
            if (
              !data ||
              !data.getMarketplaces ||
              !data.getMarketplaces.regions.length
            )
              return (
                <NonIdealState
                  icon="th-list"
                  title="No Marketplaces Found"
                  description="There are no marketplaces that meet your search criteria."
                  action={
                    <Button
                      onClick={() => {
                        setQueryParams({
                          partnerFilter: undefined,
                        })
                        resetSearch()
                      }}
                      minimal
                      intent="primary"
                    >
                      Clear Filters
                    </Button>
                  }
                />
              )
            const {
              getMarketplaces: { regions, count, totalCount },
            } = data

            if (regions.length === 1) {
              // return <Redirect to={`/messaging/inbox/${regions[0].id}`} />
            }

            const border = ls.get('darkMode')
              ? { borderLeft: '1px solid #5C7080' }
              : { borderLeft: '1px solid #DCDCDD' }

            setFilterState(count, totalCount)
            return (
              <div className="bp5-table-container bp5-scrollable">
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        {canView('partners') && (
                          <Fragment>
                            <th colSpan={4}>
                              Only marketplaces that have messaging set up show
                              up in this list
                            </th>
                          </Fragment>
                        )}

                        <th colSpan={2} style={border}>
                          Messages
                        </th>
                      </tr>
                      <tr>
                        <th>Marketplace</th>
                        <th>Partner</th>
                        <th>Company</th>
                        <th style={{ width: 60 }}>Outlets</th>
                        <th style={border}>Total</th>
                        <th>Unread</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(regions, region => (
                        <tr key={region.id}>
                          <td>
                            <Popover
                              interactionKind={PopoverInteractionKind.HOVER}
                              position={Position.RIGHT_TOP}
                              content={
                                <HTMLTable condensed>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Icon icon="link" size="10" />
                                      </td>
                                      <td>
                                        <a
                                          className="bp5-text-overflow-ellipsis"
                                          href={`https://${region.cname}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {region.cname}
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Icon icon="tag" size="10" />
                                      </td>
                                      <td>
                                        <Tag minimal>{region.key}</Tag>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Icon icon="inheritance" size="10" />
                                      </td>
                                      <td>
                                        <Tag minimal>{region.orderMode}</Tag>
                                      </td>
                                    </tr>
                                  </tbody>
                                </HTMLTable>
                              }
                            >
                              <Fragment>
                                <Favicon src={region.faviconImage} /> &nbsp;
                                <Link to={`/messaging/inbox/${region.id}`}>
                                  {region.name}
                                </Link>
                              </Fragment>
                            </Popover>
                          </td>
                          <td>
                            {region && region.partner && region.partner.id && (
                              <Link to={`/partners/${region.partner.id}`}>
                                {region.partner.name}
                              </Link>
                            )}
                          </td>
                          <td>{region.companyLegalName || ''}</td>
                          <td>
                            {region.outlets.length ? (
                              <Link
                                to={`/outlets/?marketplaceFilter=${region.id}`}
                              >
                                {region.outlets.length}
                              </Link>
                            ) : (
                              <span className="bp5-text-muted">0</span>
                            )}
                          </td>
                          <td style={border}>{region.messages.length}</td>
                          <td>
                            {
                              region.messages.filter(message => !message.readAt)
                                .length
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          }}
        </DebouncedQuery>
      </div>
    </Fragment>
  )
}

export default Marketplaces
