import React from 'react'
import { CenterSection, Image, Text, Title } from '../OrderPrintout'
import config from '../../../../../config'

const AWS_CDN_URL =
  config.awsCDNUri ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

const RestaurantInfo = ({ order }) => (
  <CenterSection>
    <Image src={AWS_CDN_URL + order.outlet.marketplace.image} />

    <Title as="h2">{order.outlet.name}</Title>
    <Title as="h3">{order.outlet.restaurant.name}</Title>
    <Text>Tel: {order.outlet.outletPhone}</Text>
    <Text>#{order.orderNumber}</Text>
  </CenterSection>
)

export default RestaurantInfo
