import gql from 'graphql-tag'

const BUSINESS_ANALYTICS_DATA = gql`
  query businessOrdersForAnalyticsWithDate(
    $businessId: String!
    $startDate: String!
    $endDate: String!
  ) {
    businessOrdersForAnalyticsWithDate(
      businessId: $businessId
      startDate: $startDate
      endDate: $endDate
    ) {
      discountJson
      outletId
      discountAmount
      fulfillmentMethod
      subtotalGross
      customerId
      paymentMethod
      voucherKey
      orderStatus
      userAgent
      createdAt
    }
  }
`

export default BUSINESS_ANALYTICS_DATA
