import React, { Fragment } from 'react'
import {
  Checkbox,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Slider,
  TextArea,
} from '@blueprintjs/core'
import { FieldArray } from 'formik'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { DateInput3 } from '@blueprintjs/datetime2'
import { func, object } from 'prop-types'
import moment from 'moment'
import OutletTypeahead from '@components/OutletTypeahead/OutletTypeahead'
import WeekDaysSelect from '../Discount/WeekDaysSelect'
import ImageUpload from '../ImageUpload/ImageUpload'
import ColorPicker from '../ColorPicker/ColorPicker'

const LoyaltyCardInnerForm = ({
  errors,
  values,
  handleChange,
  setFieldValue,
  marketplaceId,
  isDisabled = false,
  allowOutletSelection = true,
  restaurantId = null,
  status,
  setStatus,
  editing = false,
}) => {
  return (
    <Fragment>
      <FormGroup
        label="Loyalty Card Name"
        labelInfo="(required)"
        labelFor="name"
        helperText={errors.name || 'A friendly name for your loyalty card.'}
        intent={errors.name ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          name="name"
          id="name"
          type="text"
          onChange={handleChange}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
          value={values.name}
          disabled={isDisabled || editing}
        />
      </FormGroup>
      <FormGroup
        label="Terms and Conditions"
        labelInfo="(required)"
        labelFor="termsAndConditions"
        helperText={
          errors.termsAndConditions ||
          'Terms and conditions for your loyalty card.'
        }
        intent={errors.termsAndConditions ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          name="termsAndConditions"
          id="termsAndConditions"
          fill={true}
          onChange={handleChange}
          intent={errors.termsAndConditions ? Intent.DANGER : Intent.NONE}
          value={values.termsAndConditions}
          disabled={isDisabled}
        />
      </FormGroup>

      {allowOutletSelection && (
        <FormGroup
          helperText={errors.allowedOutletIds}
          intent={errors.allowedOutletIds ? Intent.DANGER : Intent.NONE}
          label="Boundry"
        >
          <RadioGroup
            inline={true}
            disabled={isDisabled || editing}
            onChange={e => {
              e.target.value === 'outlet'
                ? setFieldValue('outletSelected', true)
                : setFieldValue('outletSelected', false)
            }}
            selectedValue={values.outletSelected ? 'outlet' : 'marketplaceWide'}
          >
            <Radio
              label={restaurantId ? 'Business Wide' : 'Marketplace'}
              value="marketplaceWide"
            />
            <Radio label={`Outlet`} value="outlet" />
            {values.outletSelected && (
              <OutletTypeahead
                outletIds={values.allowedOutletIds}
                onChange={value => {
                  setFieldValue('allowedOutletIds', value)
                }}
                showMarketplaceName={false}
                showRestaurantName
                alwaysMultiSelect={true}
                marketplaceId={marketplaceId}
                disabled={isDisabled}
                intent={errors.allowedOutletIds ? Intent.DANGER : Intent.NONE}
                restaurantId={restaurantId}
              />
            )}
          </RadioGroup>
        </FormGroup>
      )}
      <FormGroup
        style={{ width: '40%' }}
        label="Required Stamps"
        helperText="Number of stamps to appear on the loyalty card."
      >
        <Slider
          name="requiredStamps"
          min={3}
          max={10}
          value={values.requiredStamps}
          disabled={isDisabled || editing}
          onChange={value => setFieldValue('requiredStamps', value)}
        />
      </FormGroup>

      <ImageUpload
        values={values}
        setFieldValue={setFieldValue}
        imageName="loyaltyCardIcon"
        imageLabel="Loyalty Card Icon"
        status={status}
        setStatus={setStatus}
        disabled={isDisabled}
        helperText={errors.loyaltyCardIcon}
        intent={errors.loyaltyCardIcon ? Intent.DANGER : Intent.NONE}
      />

      <FormGroup
        label="Stamp Colour"
        labelInfo="(hex)"
        labelFor="loyaltyCardColor"
        helperText={'The colour of the stamps.'}
        intent={Intent.NONE}
      >
        <ColorPicker
          name="loyaltyCardColor"
          id="loyaltyCardColor"
          disabled={isDisabled}
          type="text"
          fill={false}
          onChange={handleChange}
          value={values.loyaltyCardColor || null}
          error={errors.brandColor}
        />
      </FormGroup>
      <FormGroup
        disabled={isDisabled}
        label="Percentage Reward"
        labelFor="discountPercentage"
        helperText={
          errors.discountPercentage ||
          'Earns the customer a percentage of their order for every stamp'
        }
        intent={errors.discountPercentage ? Intent.DANGER : Intent.NONE}
      >
        <PercentageInput
          value={values.discountPercentage}
          disabled={isDisabled || editing}
          handleChange={values =>
            setFieldValue('discountPercentage', values.floatValue)
          }
          fill
          intent={errors.discountPercentage ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      {values.discountPercentage ? (
        <FormGroup
          helperText={
            errors.discountStrategy ||
            'Choose if customers will earn a percentage of the basket subtotal, or the whole order (including service charge and delivery fees).'
          }
          intent={errors.discountStrategy ? Intent.DANGER : Intent.WARNING}
          label="Discount Strategy"
        >
          <RadioGroup
            inline={true}
            disabled={isDisabled}
            onChange={e => {
              e.target.value === 'basketSubtotal'
                ? setFieldValue('discountStrategy', 'BASKET_SUBTOTAL_DISCOUNT')
                : setFieldValue('discountStrategy', 'WHOLE_ORDER_DISCOUNT')
            }}
            selectedValue={
              values.discountStrategy === 'BASKET_SUBTOTAL_DISCOUNT'
                ? 'basketSubtotal'
                : 'wholeOrder'
            }
          >
            <Radio label={'Subtotal'} value="basketSubtotal" />
            <Radio label={`Total`} value="wholeOrder" />
          </RadioGroup>
        </FormGroup>
      ) : null}

      <FormGroup
        disabled={isDisabled}
        label="Fixed Reward"
        labelFor="discountAmount"
        helperText={
          errors.discountAmount ||
          'Earns the customer a fixed amount for every stamp'
        }
        intent={errors.discountAmount ? Intent.DANGER : Intent.NONE}
      >
        <CurrencyInput
          name="discountAmount"
          id="discountAmount"
          disabled={isDisabled || editing}
          intent={errors.discountAmount ? Intent.DANGER : Intent.NONE}
          defaultValue={values.discountAmount}
          onUpdate={amount => {
            setFieldValue('discountAmount', parseFloat(amount))
          }}
          fill={true}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Minimum Qualifying Order"
        labelFor="minimumSubtotalGross"
        helperText={
          errors.minimumSubtotalGross ||
          'Basket subtotal must be greater than this amount for the stamp to be earned.'
        }
        intent={errors.minimumSubtotalGross ? Intent.DANGER : Intent.NONE}
      >
        <CurrencyInput
          name="minimumSubtotalGross"
          id="minimumSubtotalGross"
          disabled={isDisabled}
          intent={errors.minimumSubtotalGross ? Intent.DANGER : Intent.NONE}
          defaultValue={values.minimumSubtotalGross}
          onUpdate={amount => {
            setFieldValue('minimumSubtotalGross', amount)
          }}
          fill={true}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="allowCashOrders"
          label="Allow cash orders"
          id="allowCashOrders"
          checked={values.allowCashOrders}
          onChange={handleChange}
          inline
        />
      </FormGroup>
      <FormGroup
        disabled={isDisabled || editing}
        label="Start Date"
        labelFor="startDate"
        helperText={
          errors.startDate || 'Stamp Card will not start before this date.'
        }
        intent={errors.startDate ? Intent.DANGER : Intent.NONE}
      >
        <DateInput3
          highlightCurrentDay
          value={values.startDate}
          dateFnsFormat="MM/dd/yyyy"
          disabled={isDisabled || editing}
          onChange={date => setFieldValue('startDate', date)}
          className={errors.startDate && 'bp5-input-group bp5-intent-danger'}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="End Date"
        labelFor="endDate"
        helperText={errors.endDate || 'Stamp Card will expire after this date.'}
        intent={errors.endDate ? Intent.DANGER : Intent.NONE}
      >
        <DateInput3
          highlightCurrentDay
          className={errors.endDate && 'bp5-input-group bp5-intent-danger'}
          disabled={isDisabled}
          value={values.endDate}
          dateFnsFormat="MM/dd/yyyy"
          onChange={date => {
            setFieldValue('endDate', date)
          }}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Qualifying Days"
        labelFor="daysOfWeek"
        style={{ marginBottom: 5 }}
        helperText={
          errors.daysOfWeek || 'Select the days the customer can earn a stamp.'
        }
        intent={errors.daysOfWeek ? Intent.DANGER : Intent.NONE}
      >
        <FieldArray
          name="daysOfWeek"
          render={({
            form: {
              values: { daysOfWeek },
            },
            push,
            remove,
          }) => (
            <WeekDaysSelect
              intent={errors.daysOfWeek ? Intent.DANGER : Intent.NONE}
              selectedDays={daysOfWeek}
              push={push}
              remove={remove}
              disabled={isDisabled}
            />
          )}
        />
      </FormGroup>
    </Fragment>
  )
}

LoyaltyCardInnerForm.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
}

export default LoyaltyCardInnerForm
