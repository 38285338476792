import gql from 'graphql-tag'

const EDIT_APP_UPDATE_PROMPT = gql`
  mutation editAppUpdatePrompt(
    $id: String!
    $promptForUpdates: Boolean
    $androidVersion: String
    $iosVersion: String
  ) {
    editMarketplace(
      id: $id
      promptForUpdates: $promptForUpdates
      androidVersion: $androidVersion
      iosVersion: $iosVersion
    ) {
      message
      marketplace {
        id
        promptForUpdates
        androidVersion
        iosVersion
      }
    }
  }
`

export default EDIT_APP_UPDATE_PROMPT
