import defaultErrorHandler from '@utils/defaultErrorHandler'
import { errorToast, successToast } from '@utils/toast'
import { Formik } from 'formik'
import { split } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import SEND_MESSAGE_TO_OUTLETS from './mutations/sendMessageToOutlets.mutation'
import SendEmailToOutletsForm from './SendEmailToOutletsForm'
import newMessageValidation from './validation'
import { useMutation } from '@apollo/client'

const SendNewMessageToOutlets = ({
  messageId = undefined,
  setShowNewMessageDrawer,
}) => {
  const [selectedMarketplace, setSelectedMarketplace] = useState(null)
  const marketplaceId = split(location.pathname, '/')[3]
  useEffect(() => {
    setSelectedMarketplace(marketplaceId)
  }, [marketplaceId])

  const [sendMessageToOutlets] = useMutation(SEND_MESSAGE_TO_OUTLETS, {
    onCompleted: ({ sendMessageToOutlets }) => {
      if (sendMessageToOutlets.message) {
        successToast(sendMessageToOutlets.message)
        setShowNewMessageDrawer(false)
      }
      sendMessageToOutlets.error ? errorToast(sendMessageToOutlets.error) : null
    },
    onError: defaultErrorHandler,
  })

  return (
    <Fragment>
      {selectedMarketplace && (
        <Formik
          initialValues={{
            content: '',
            subject: '',
            marketplaceId: selectedMarketplace,
            restaurantIds: [],
            addMarketplaceTheme: false,
          }}
          onSubmit={values =>
            sendMessageToOutlets({
              variables: {
                content: values.content,
                subject: values.subject,
                marketplaceId: selectedMarketplace,
                restaurantIds: values.restaurantIds,
                messageId: messageId,
                addMarketplaceTheme: values.addMarketplaceTheme,
              },
            })
          }
          validationSchema={newMessageValidation}
        >
          {props => <SendEmailToOutletsForm {...props} />}
        </Formik>
      )}
    </Fragment>
  )
}

export default SendNewMessageToOutlets
