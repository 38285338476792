import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Icon, Switch, Tooltip } from '@blueprintjs/core'

const MarketplaceAllowSMSMarketing = ({
  updateStatus,
  allowSMSMarketing,
  disabled = false,
}) => {
  return (
    <Formik initialValues={{ allowSMSMarketing }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={
              <Fragment>
                SMS Marketing{' '}
                {disabled && (
                  <Tooltip content={'Requires Marketplace Billing'}>
                    <Icon icon="warning-sign" />
                  </Tooltip>
                )}
              </Fragment>
            }
            checked={values.allowSMSMarketing}
            disabled={disabled}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowSMSMarketing: e.currentTarget.checked,
              })
            }}
            name="allowSMSMarketing"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowSMSMarketing.propTypes = {
  allowSMSMarketing: bool,
  disabled: bool,
  errors: object,
}

export default MarketplaceAllowSMSMarketing
