import { Fragment, useState } from 'react'
import { Formik } from 'formik'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import RESET_KIOSK_PIN from './mutations/resetKioskPIN'
import {
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { editPOSDevicePinStructure } from '@src/validation/pos-device'
import * as yup from 'yup'
import { func, string } from 'prop-types'
import { useMutation } from '@apollo/client'

const validationSchema = yup.object().shape(editPOSDevicePinStructure)

const ResetKioskPINButton = ({ kioskId, onClose }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [resetKioskPIN] = useMutation(RESET_KIOSK_PIN, {
    onCompleted: () => {
      successToast('Kiosk PIN successfully reset')
      onClose()
    },
    onError: defaultErrorHandler,
  })

  return (
    <Fragment>
      <Button
        intent={Intent.PRIMARY}
        minimal
        text="Reset PIN"
        onClick={() => {
          setModalOpen(true)
        }}
      />
      {modalOpen && (
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            pin: '',
          }}
          enableReinitialize={true}
          onSubmit={async ({ pin }, { resetForm }) => {
            await resetKioskPIN({
              variables: {
                kioskId,
                pin,
              },
            })
            resetForm()
          }}
          validateOnBlur={true}
        >
          {({ handleChange, handleSubmit, errors, values }) => (
            <Dialog
              isOpen={modalOpen}
              title="Reset Kiosk PIN"
              onClose={() => {
                setModalOpen(false)
              }}
            >
              <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>
                <Callout intent={Intent.DANGER}>
                  Warning, resetting the PIN will logout this kiosk and it will
                  be unavailable for orders until reauthorised.
                </Callout>
                <FormGroup
                  label="PIN"
                  labelFor="pin"
                  labelInfo="(4 digits long)"
                  helperText={errors.pin ? errors.pin : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    type="text"
                    id="pin"
                    name="pin"
                    value={values.pin}
                    intent={errors.pin ? Intent.DANGER : Intent.NONE}
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    text="Reset Pin"
                    type="submit"
                    intent={Intent.DANGER}
                  />
                </div>
              </form>
            </Dialog>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

ResetKioskPINButton.propTypes = {
  kioskId: string.isRequired,
  onClose: func.isRequired,
}

export default ResetKioskPINButton
