import gql from 'graphql-tag'

const GET_ALL_ROLES = gql`
  query getAllRoles($roleTitle: [String!]) {
    getAllRoles(roleTitle: $roleTitle) {
      roles {
        id
        key
        title
        canCreateRoles {
          id
        }
      }
    }
  }
`

export default GET_ALL_ROLES
