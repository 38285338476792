import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const newPOSDeviceStructure: IIterableInterface = {
  friendlyName: yup
    .string()
    .required('Please provide a friendly name for the POS device')
    .min(4, 'Device name should be longer than 4 chars'),
  serialNumber: yup
    .string()
    .required('Please provide a serial number for the terminal')
    .min(4, 'Please use a serial number longer than 4 chars'),
  pin: yup
    .string()
    .matches(/^[0-9]{4}$/, 'The pin should be 4 digit long and only numbers')
    .required('A pin is required'),
}

export const editPOSDeviceStructure: IIterableInterface = {
  friendlyName: yup
    .string()
    .min(4, 'Device name should be longer than 4 chars'),
}

export const editPOSDevicePinStructure: IIterableInterface = {
  pin: yup
    .string()
    .matches(/^[0-9]{4}$/, 'The pin should be 4 digit long and only numbers')
    .required('A pin is required'),
}
