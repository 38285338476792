import { Tag } from '@blueprintjs/core'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'

export interface FulfillmentColumn<X extends string>
  extends BaseColumn<'fulfillment', X> {}

export interface FulfillmentColumnData {
  method: string
  provider?: string | null
}

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export const sortFulfillmentColumn: ColumnSorter<'fulfillment'> = (
  a,
  b,
  order
) => {
  const negator = order === 'DESC' ? 1 : -1

  return (
    negator *
    collator.compare(
      `${a.method} ${a.provider}`,
      `${b.method} ${b.provider ?? ''}`
    )
  )
}

export const FulfillmentColumnContent: ColumnRenderer<
  'fulfillment',
  string
> = ({ columnData: { method, provider } }) => {
  return (
    <Tag minimal intent="none">
      {method}
      {provider ? ' - ' + provider : ''}
    </Tag>
  )
}
