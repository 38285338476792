import gql from 'graphql-tag'

const GET_MARKETPLACE_MESSAGE_TEMPLATES = gql`
  query getMarketplaceMessageTemplates($marketplaceId: ID!) {
    getMarketplaceMessageTemplates(marketplaceId: $marketplaceId) {
      messageTemplates {
        id
        templateType
        emailSubject
        emailPreviewText
        emailContent
        pushTitle
        pushBody
      }
    }
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        name
        cname
        faviconImage
        emailSenderUsername
      }
    }
  }
`
export default GET_MARKETPLACE_MESSAGE_TEMPLATES
