import { FormGroup, InputGroup, Button } from '@blueprintjs/core'

import { ButtonContainer } from './Login.styles'
import { MutationLoginUserArgs } from '../../types.generated'

const LoginForm: React.FC<LoginFormProps> = ({
  handleChange,
  values,
  isSubmitting,
  mutation,
}) => (
  <form
    onSubmit={e => {
      e.preventDefault()
      mutation({
        variables: { ...values },
      })
    }}
  >
    <FormGroup>
      <InputGroup
        placeholder={'Email'}
        value={values.email}
        onChange={handleChange}
        type="email"
        name="email"
        large
        data-test-id="sign-in-email-input"
      />
    </FormGroup>
    <FormGroup>
      <InputGroup
        placeholder={'Password'}
        value={values.password}
        onChange={handleChange}
        type="password"
        name="password"
        large
        data-test-id="sign-in-password-input"
      />
    </FormGroup>
    <ButtonContainer>
      <FormGroup>
        <Button
          type="submit"
          disabled={isSubmitting}
          text="Login"
          data-test-id="sign-in-submit-button"
        />
      </FormGroup>
    </ButtonContainer>
  </form>
)

interface LoginFormProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  values: MutationLoginUserArgs
  isSubmitting: boolean
  mutation: (args: { variables: Record<string, string> }) => void
}

export default LoginForm
