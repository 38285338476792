import { Intent } from '@blueprintjs/core'
import { WrapInLink } from '@src/utils/wrapInLink'
import { WrapInTag } from '@src/utils/wrapInTag'
import { CSSProperties } from 'react'
import {
  BaseColumn,
  ColumnRenderer,
  ColumnSorter,
  FALLBACK_PLACEHOLDER,
} from './column.types'

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export interface TextColumn<X extends string> extends BaseColumn<'text', X> {}

export interface TextColumnData {
  text: string | null | undefined
  link?: string
  /** if specified, wraps the text in a tag with this intent */
  tagIntent?: Intent
  style?: CSSProperties
}

export const sortTextColumn: ColumnSorter<'text'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * collator.compare(b.text ?? '', a.text ?? '')
}

export const TextColumnContent: ColumnRenderer<'text', string> = ({
  columnDefinition: { placeholder },
  columnData: { style, link, text, tagIntent },
}) => {
  if (text === null) return placeholder ?? FALLBACK_PLACEHOLDER
  return (
    <span style={style}>
      <WrapInLink to={link}>
        <WrapInTag intent={tagIntent}>{text}</WrapInTag>
      </WrapInLink>
    </span>
  )
}
