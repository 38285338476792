import gql from 'graphql-tag'

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      partialToken
      phoneNumber
      canUse2FA
      bypass {
        token
        ownsPartners {
          id
        }
        role {
          id
          title
          permissionCategories {
            id
            title
            queries
            mutations
            subscriptions
          }
        }
      }
    }
  }
`

export default LOGIN
