import { FetchResult, useMutation } from '@apollo/client'
import { Button, Card, H5, Intent, Spinner, Tag } from '@blueprintjs/core'
import { Col, Row } from '@components/_FlexGrid'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import config from '@src/config'
import { Partner } from '@src/types.generated'
import { isPlatformLevelUser } from '@stores/userStore'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import React from 'react'
import ls from '../../../../utils/localStorage'
import EDIT_APPLE_KEY_DETAILS from '../mutations/editAppleKeyDetails.mutation'
import EDIT_GOOGLE_JSON_S3_KEY_PATH from '../mutations/editGoogleJsonS3KeyPath.mutation'
import PartnerAppAppleKeyUpload from './PartnerAppAppleKeyUpload'
import PartnerAppGoogleKeyJsonUpload from './PartnerAppGoogleKeyJsonUpload'
import ShortPartnerForm from './PartnerShortForm'

const environmentDomain = config.environmentDomain

const PartnerForm: React.FC<{
  partner: Partner
  editable: boolean
  onSubmit: (values: Record<string, string>) => Promise<FetchResult<any>>
  initialValues: Record<string, string>
}> = ({ partner, onSubmit, initialValues = {} }) => {
  const [editPartner] = useMutation(EDIT_GOOGLE_JSON_S3_KEY_PATH, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Google Json key submitted'),
  })

  const [editAppleDetailsForPartner] = useMutation(EDIT_APPLE_KEY_DETAILS, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Apple key submitted'),
  })

  return (
    <Row gutter={48}>
      <Col xs={12} lg={8}>
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {props => {
            const { handleSubmit, isSubmitting } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card>
                  <ShortPartnerForm
                    {...props}
                    editable={isPlatformLevelUser()}
                    disabled={!isPlatformLevelUser()}
                  />
                  {isPlatformLevelUser() && (
                    <div className="bp-card-footer-actions">
                      <Button
                        text="Save"
                        type="submit"
                        loading={isSubmitting}
                      />
                    </div>
                  )}
                </Card>
              </form>
            )
          }}
        </Formik>
      </Col>
      {isPlatformLevelUser() && (
        <FetchQuery
          domain={`app-build.${environmentDomain}`}
          path={`app/auth/management`}
          fetchOptions={{
            method: 'POST',
            headers: { Authorization: `Bearer ${ls.get('jwt')}` },
          }}
        >
          {({ loading, error, data: authManagementAWS }) => {
            if (error) {
              return <div>Error connecting to backend service</div>
            }
            if (loading) {
              return (
                <Col xs={12} lg={4}>
                  <Card>
                    <Spinner size={64} />
                  </Card>
                </Col>
              )
            }
            return (
              <Col xs={12} lg={4}>
                <Card>
                  <H5>
                    Play Store{' '}
                    {!config.isProd && (
                      <Tag intent={Intent.WARNING} minimal>
                        PRODUCTION ONLY
                      </Tag>
                    )}
                    {partner.googleJsonS3KeyPathDefault && (
                      <Tag intent={Intent.SUCCESS}>UPLOADED</Tag>
                    )}
                  </H5>

                  <PartnerAppGoogleKeyJsonUpload
                    onSubmit={values =>
                      editPartner({
                        variables: {
                          id: partner.id,
                          googleJsonS3KeyPathDefault:
                            values.googleJsonS3KeyPathDefault,
                        },
                      })
                    }
                    partnerId={partner.id}
                    credentials={authManagementAWS}
                  />
                </Card>
                <Card>
                  <H5>
                    App Store{' '}
                    {!config.isProd && (
                      <Tag intent={Intent.WARNING} minimal>
                        PRODUCTION ONLY
                      </Tag>
                    )}
                    {partner.appStoreConnectApiKeyPath && (
                      <Tag intent={Intent.SUCCESS}>UPLOADED</Tag>
                    )}
                  </H5>

                  <PartnerAppAppleKeyUpload
                    onSubmit={values => {
                      return editAppleDetailsForPartner({
                        variables: {
                          id: partner.id,
                          appStoreConnectApiKeyId:
                            values.appStoreConnectApiKeyId,
                          appStoreConnectApiIssuerId:
                            values.appStoreConnectApiIssuerId,
                          appStoreConnectApiKeyPath:
                            values.appStoreConnectApiKeyPath,
                          appleTeamIdDefault: values.appleTeamIdDefault,
                        },
                      })
                    }}
                    partnerId={partner.id}
                    credentials={authManagementAWS}
                    initialValues={initialValues}
                  />
                </Card>
              </Col>
            )
          }}
        </FetchQuery>
      )}
    </Row>
  )
}

export default PartnerForm
