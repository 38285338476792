import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_OUTLET_NAME from './queries/getOutletName.query'
import GET_RESTAURANT_NAME from './queries/getRestaurantName.query'

const isFirstPart = i => i === 0
const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

let restaurantId

const OutletCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isFirstPart(i)) {
      restaurantId = crumb
      return (
        <Query
          key={crumb}
          query={GET_RESTAURANT_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getRestaurants }) => {
            if (!getRestaurants) {
              return null
            }
            const { restaurants } = getRestaurants
            return (
              <Crumb
                key={crumb}
                text={restaurants.length ? restaurants[0].name : crumb}
                link={`/business/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isSecondPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/business/${restaurantId}/${crumb}`}
        />
      )
    }

    if (isThirdPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_OUTLET_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getOutlets }) => {
            if (!getOutlets) {
              return null
            }
            const { outlets } = getOutlets

            return (
              <Crumb
                key={crumb}
                text={outlets.length ? outlets[0].name : crumb}
                link={`/business/${restaurantId}/outlets/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }
  })

export default OutletCrumbs
