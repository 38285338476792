import { Button, Checkbox, Popover, Position, Tag } from '@blueprintjs/core'
import { union, without } from 'lodash'
import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { ArrayParam, useQueryParam } from 'use-query-params'

export const Popup = styled.div`
  max-height: 500px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`

export const CheckBox = styled(Checkbox)`
  margin: 0px 16px 10px;
`

interface Props {
  disabled?: boolean
  options: { name: string; key: string }[]
  /**
   * the name referenced in the URL query string */
  name: string
  displayName: string
}

export const StaticFilter: React.FC<Props> = ({
  disabled,
  options,
  name,
  displayName,
}) => {
  const [selectedOptionsMaybeNull, setSelectedOptions] = useQueryParam(
    name,
    ArrayParam
  )

  const selectedOptions = selectedOptionsMaybeNull ?? []
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      disabled={disabled}
      content={
        <Popup>
          {options.map(({ name, key }) => (
            <CheckBox
              key={key}
              id={key}
              label={name}
              alignIndicator="left"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const checked = event.currentTarget.checked
                const newFilter = checked
                  ? union(selectedOptions, [key])
                  : without(selectedOptions, key)
                setSelectedOptions(newFilter)
              }}
              checked={selectedOptions.includes(key)}
            />
          ))}
        </Popup>
      }
    >
      <Button
        rightIcon={
          selectedOptions.length > 0 ? (
            <Tag intent="primary">{selectedOptions.length}</Tag>
          ) : (
            'double-caret-vertical'
          )
        }
        disabled={disabled}
      >
        {displayName}
      </Button>
    </Popover>
  )
}

export default StaticFilter
