import gql from 'graphql-tag'

const EDIT_PARTNER = gql`
  mutation editPartner(
    $id: ID!
    $name: String
    $contactAddress: AddressUpdateDataInput
    $contactPhone: String
    $contactEmail: String
    $companyLegalName: String
    $companyNumber: String
    $contactName: String
    $orderHoldInterval: Int
    $cname: String
    $googleJsonS3KeyPathDefault: String
    $appleTeamIdDefault: String
    $brandImage: Boolean
    $brandColor: String
    $allowSubBrands: Boolean
    $supportUrl: String
    $supportEmail: String
    $supportTitle: String
  ) {
    editPartner(
      id: $id
      name: $name
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      companyLegalName: $companyLegalName
      companyNumber: $companyNumber
      contactName: $contactName
      orderHoldInterval: $orderHoldInterval
      cname: $cname
      brandImage: $brandImage
      brandColor: $brandColor
      allowSubBrands: $allowSubBrands
      supportUrl: $supportUrl
      supportEmail: $supportEmail
      supportTitle: $supportTitle
      googleJsonS3KeyPathDefault: $googleJsonS3KeyPathDefault
      appleTeamIdDefault: $appleTeamIdDefault
    ) {
      message
      partner {
        id
        name
        companyLegalName
        companyNumber
        googleJsonS3KeyPathDefault
        appleTeamIdDefault
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          postcode
          city
          country
        }
        contactPhone
        contactEmail
        contactName
        orderHoldInterval
        platform {
          id
          name
        }
        cname
        brandImage
        brandColor
        allowSubBrands
        supportUrl
        supportEmail
        supportTitle
      }
    }
  }
`

export default EDIT_PARTNER
