import gql from 'graphql-tag'

const EDIT_PARTNER_PAYMENTS = gql`
  mutation editPartnerPayments(
    $id: ID!
    $stripeEnterprisePublicId: String
    $stripeEnterpriseAccessId: String
    $stripeEnterprisePayoutDay: String!
    $stripeShowDashboardMarketplace: Boolean!
    $stripeShowDashboardOutlet: Boolean!
  ) {
    editPartnerPayments(
      id: $id
      stripeEnterprisePublicId: $stripeEnterprisePublicId
      stripeEnterpriseAccessId: $stripeEnterpriseAccessId
      stripeEnterprisePayoutDay: $stripeEnterprisePayoutDay
      stripeShowDashboardMarketplace: $stripeShowDashboardMarketplace
      stripeShowDashboardOutlet: $stripeShowDashboardOutlet
    ) {
      message
      partner {
        id
        stripeEnterpriseId
        stripeEnterprisePublicId
        stripeEnterpriseAccessId
        stripeEnterprisePayoutDay
        stripeShowDashboardMarketplace
        stripeShowDashboardOutlet
      }
    }
  }
`

export default EDIT_PARTNER_PAYMENTS
