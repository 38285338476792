import React from 'react'
import get from 'lodash/get'
import moment from 'moment-timezone'
import { Title, CenterSection, Text } from '../OrderPrintout'

const Fulfilment = ({ order, formatDateString }) => {
  let fulfilmentMethodString = order.fulfillmentMethod
  if (order.fulfillmentMethod === 'NETWORK') {
    fulfilmentMethodString = 'DELIVERY'
  } else if (order.fulfillmentMethod === 'TABLE') {
    fulfilmentMethodString = `TABLE ${get(
      order,
      'tableSnapshot.friendlyName',
      'unknown'
    ).replace(/^table\s*/i, '')}`
  }
  return (
    <CenterSection>
      <Title as="h2">{fulfilmentMethodString}</Title>

      <div>
        {order.fulfillmentMethod === 'NETWORK' && (
          <Text>Pickup: {formatDateString(order.estimatedCompletionTime)}</Text>
        )}

        {order.fulfillmentMethod === 'TABLE' ? (
          <Text>Ordered: {formatDateString(order.createdAt)}</Text>
        ) : (
          <Text>
            Due:{' '}
            {order.fulfillmentMethod === 'COLLECTION'
              ? `${moment(order.estimatedCompletionTime).format('HH:mm D-MMM')}`
              : `${moment(order.selectedDeliveryWindow.start).format(
                  `HH:mm ${
                    moment(order.selectedDeliveryWindow.start).day() !==
                    moment(order.selectedDeliveryWindow.end).day()
                      ? 'D-MMM'
                      : ''
                  }`
                )} - ${moment(order.selectedDeliveryWindow.end).format(
                  'HH:mm D-MMM'
                )}`}
            {order.asap ? ' (ASAP)' : ' (REQUESTED)'}
          </Text>
        )}
      </div>
    </CenterSection>
  )
}

export default Fulfilment
