import { IIterableInterface } from './generic-validator'
import * as yup from 'yup'

const pushNotificationCharacterLimit = { title: 40, body: 160 }

const emailContentSchema = yup.object().shape({
  type: yup.string().required(),
  text: yup.string(),
  buttonText: yup.string(),
  buttonLink: yup.string().url('Button link must be a valid URL'),
  image: yup.string(),
})

export const addCampaignStructure: IIterableInterface = {
  name: yup.string().required('Campaign name is required'),
  segment: yup.string().required('Segment is required'),
  marketplaceId: yup.string().required('marketplaceId is required'),
  type: yup.string().min(1).required('Campaign type is required'),
  timeConfig: yup.string().required('timeConfig is required'),
  startDateTime: yup.date().when('timeConfig', ([timeConfig], schema) => {
    if (timeConfig === 'DATETIME') {
      return schema
        .typeError('Send time is required')
        .min(new Date(), 'Send time cannot be in the past')
        .required('Send time is required')
    } else {
      return schema.nullable()
    }
  }),
  smsChannel: yup.bool(),
  pushChannel: yup.bool(),
  emailChannel: yup.bool().test({
    name: 'channelCheckbox',
    exclusive: false,
    test: (emailChannel, TestContext) => {
      const { parent } = TestContext
      if (parent.pushChannel || parent.smsChannel || emailChannel) {
        return true
      }
      return new yup.ValidationError(
        'At least one channel must be selected',
        null,
        'emailChannel'
      )
    },
  }),
  emailSubject: yup.string().when('emailChannel', (emailChannel, schema) => {
    if (emailChannel) {
      return schema.required('Email subject is required')
    }
  }),
  emailPreviewText: yup
    .string()
    .when('emailChannel', (emailChannel, schema) => {
      if (emailChannel) {
        return schema.required('Preview text is required')
      }
    }),
  emailContent: yup.array().when('emailChannel', ([emailChannel], schema) => {
    if (emailChannel) {
      return schema
        .of(emailContentSchema)
        .min(1, 'Message body is required')
        .required('Message body is required')
    }
  }),
  push: yup.object().when('pushChannel', ([pushChannel], schema) => {
    if (pushChannel) {
      return yup.object().shape({
        action: yup.string().required('Push action is required'),
        outletId: yup.string().when('action', ([action], schema) => {
          if (action === 'OUTLET') {
            return schema.required('Outlet is required')
          } else {
            return schema.nullable()
          }
        }),
        title: yup
          .string()
          .max(pushNotificationCharacterLimit.title)
          .required('Push title is required'),
        body: yup
          .string()
          .max(pushNotificationCharacterLimit.body)
          .required('Push body is required'),
      })
    } else {
      return schema.nullable()
    }
  }),
}

export const editCampaignStructure: IIterableInterface = {
  id: yup.string().required('id is required'),
  name: yup.string().required('Campaign name is required'),
  segment: yup.string().required('Segment is required'),
  marketplaceId: yup.string().required('marketplaceId is required'),
  type: yup.string().min(1).required('Campaign type is required'),
  timeConfig: yup.string().required('timeConfig is required'),
  startDateTime: yup.string(),
  smsChannel: yup.boolean(),
  pushChannel: yup.boolean(),
  emailChannel: yup.boolean().test({
    name: 'channelCheckbox',
    exclusive: false,
    test: (emailChannel, TestContext) => {
      const { parent } = TestContext
      if (parent.pushChannel || parent.smsChannel || emailChannel) {
        return true
      }
      return new yup.ValidationError(
        'At least one channel must be selected',
        null,
        'emailChannel'
      )
    },
  }),
  emailSubject: yup.string().when('emailChannel', (emailChannel, schema) => {
    if (emailChannel) {
      return schema.required('Email subject is required')
    }
  }),
  emailPreviewText: yup
    .string()
    .when('emailChannel', (emailChannel, schema) => {
      if (emailChannel) {
        return schema.required('Preview text is required')
      }
    }),
  emailContent: yup.array().when('emailChannel', ([emailChannel], schema) => {
    if (emailChannel) {
      return schema
        .of(emailContentSchema)
        .min(1, 'Message body is required')
        .required('Message body is required')
    }
  }),
  push: yup.object().when('pushChannel', ([pushChannel], schema) => {
    if (pushChannel) {
      return yup.object().shape({
        action: yup.string().required('Push action is required'),
        outletId: yup.string().when('action', ([action], schema) => {
          if (action === 'OUTLET') {
            return schema.required('Outlet is required')
          } else {
            return schema.nullable()
          }
        }),
        title: yup
          .string()
          .max(pushNotificationCharacterLimit.title)
          .required('Push title is required'),
        body: yup
          .string()
          .max(pushNotificationCharacterLimit.body)
          .required('Push body is required'),
      })
    } else {
      return schema.nullable()
    }
  }),
}
