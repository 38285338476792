import gql from 'graphql-tag'

const EDIT_MARKETPLACE_FACEBOOK = gql`
  mutation setMarketplaceFacebook($id: String!, $facebookAudienceId: String!) {
    setMarketplaceFacebook(id: $id, facebookAudienceId: $facebookAudienceId) {
      marketplace {
        id
      }
    }
  }
`

export default EDIT_MARKETPLACE_FACEBOOK
