import { penceToPounds } from '@utils/helpers'

const fields = {
  id: {
    id: 'id',
    label: 'ID',
    type: 'text',
    visible: false,
    export: true,
    width: 100,
    drawer: false,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  price: {
    id: 'price',
    label: 'Price',
    type: 'currency',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
    defaultValue: 0,
  },
  optionId: {
    id: 'optionId',
    label: 'Option',
    type: 'selectSingle',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'text',
    visible: true,
    export: true,
    width: 300,
    drawer: true,
  },
  isVegan: {
    id: 'isVegan',
    label: 'Vegan',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isVegetarian: {
    id: 'isVegetarian',
    label: 'Vegetarian',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isGlutenFree: {
    id: 'isGlutenFree',
    label: 'Gluten Free',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isDairyFree: {
    id: 'isDairyFree',
    label: 'Dairy Free',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isAgeRestricted: {
    id: 'isAgeRestricted',
    label: 'Age Restricted',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
}

const getHeader = () => Object.keys(fields)

const menuOptionItemsToCSV = options => {
  const columns = getHeader()
  const columnHeadingsAsCSV = columns.join(',')

  const nonNullBooleanToString = value =>
    value !== null ? value.toString() : 'false'

  const optionItemsAsCSV = options.flatMap(option =>
    option.optionItems.flatMap(optionItem => {
      const formatedOptionItem = {
        id: `${optionItem.id}`,
        name: `"${optionItem.name}"`,
        description: `"${optionItem.description || ''}"`,
        price: `${penceToPounds(optionItem.price || 0)}`,
        isVegan: nonNullBooleanToString(optionItem.isVegan),
        isVegetarian: nonNullBooleanToString(optionItem.isVegetarian),
        isGlutenFree: nonNullBooleanToString(optionItem.isGlutenFree),
        isDairyFree: nonNullBooleanToString(optionItem.isDairyFree),
        isAgeRestricted: nonNullBooleanToString(optionItem.ageRestricted),
        optionId: `${option.id || ''}`,
      }
      return columns.map(column => formatedOptionItem[column]).join(',')
    })
  )

  return [[columnHeadingsAsCSV, ...optionItemsAsCSV].join('\n')]
}

export const MenuOptionItemDefinition = {
  fields,
  getHeader,
  menuOptionItemsToCSV,
}
