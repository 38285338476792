import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import React from 'react'

const InnerForm = ({ errors, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label="First Line / Number "
          labelFor="firstLine"
          labelInfo="(required)"
          helperText={errors.firstLine || ''}
          intent={errors.firstLine ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            id="firstLine"
            onChange={handleChange}
            intent={errors.firstLine ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <FormGroup label="Second Line" labelFor="secondLine">
          <InputGroup id="secondLine" onChange={handleChange} />
        </FormGroup>
        <FormGroup label="Third Line" labelFor="thirdLine">
          <InputGroup id="thirdLine" onChange={handleChange} />
        </FormGroup>
        <FormGroup
          label="City"
          labelFor="city"
          labelInfo="(required)"
          helperText={errors.city || ''}
          intent={errors.city ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            id="city"
            onChange={handleChange}
            intent={errors.city ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <FormGroup
          label="Postcode"
          labelFor="thirdLine"
          labelInfo="(required)"
          helperText={errors.postcode || ''}
          intent={errors.postcode ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            id="postcode"
            onChange={handleChange}
            intent={errors.postcode ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button type="submit">Save</Button>
        </div>
      </div>
    </form>
  )
}

export default InnerForm
