import gql from 'graphql-tag'

const TOGGLE_OUTLET_ACTIVATION = gql`
  mutation toggleOutletActivation($id: ID!, $active: Boolean!) {
    toggleOutletActivation(outletId: $id, active: $active) {
      message
      outlet {
        id
        active
      }
    }
  }
`
export default TOGGLE_OUTLET_ACTIVATION
