import { Tag } from '@blueprintjs/core'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'

export interface PaymentMethodColumn<X extends string>
  extends BaseColumn<'paymentMethod', X> {}

export interface PaymentMethodColumnData {
  paymentMethod?: string | null
}

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export const sortPaymentMethod: ColumnSorter<'paymentMethod'> = (
  a,
  b,
  order
) => {
  const negator = order === 'DESC' ? 1 : -1

  return (
    negator * collator.compare(String(a.paymentMethod), String(b.paymentMethod))
  )
}

export const PaymentMethodColumnContent: ColumnRenderer<
  'paymentMethod',
  string
> = ({ columnData: { paymentMethod }, columnDefinition: { placeholder } }) => {
  if (!paymentMethod) return placeholder
  return (
    <Tag minimal intent="none">
      {paymentMethod.replaceAll('_', ' ')}
    </Tag>
  )
}
