import React, { Fragment, useState } from 'react'
import {
  Classes,
  Icon,
  Popover,
  Button,
  Position,
  Checkbox,
  Tag,
  Spinner,
} from '@blueprintjs/core'
import { object, func, bool, arrayOf, string } from 'prop-types'
import { indexOf, orderBy } from 'lodash'
import Query from '@components/Query/Query'
import get from 'lodash/get'
import find from 'lodash/find'
import lodashFilter from 'lodash/filter'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import EmojiImageLabel from '@components/EmojiImageLabel'
import styled from 'styled-components'

const Items = ({ onChange, filter, items, showEmojis = false }) => {
  const [search, setSearch] = useState('')

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <Fragment>
      <div className="bp5-input-group" style={{ margin: '12px' }}>
        <Icon icon="search" />
        <input
          autoFocus
          className={Classes.INPUT}
          type="search"
          placeholder="Search"
          value={search}
          onChange={onSearchChange}
        />
      </div>

      <PerfectScrollBar>
        {lodashFilter(items, ({ name }) =>
          search === ''
            ? true
            : name.toLowerCase().includes(search.toLowerCase())
        ).map(({ id, name, emoji, imageSrc }) =>
          showEmojis ? (
            <CheckBoxWithEmojis
              id={id}
              labelElement={
                <EmojiImageLabel
                  label={name}
                  emoji={emoji}
                  imageSrc={imageSrc}
                />
              }
              checked={indexOf(filter, id) >= 0}
              alignIndicator="left"
              onChange={onChange}
            />
          ) : (
            <CheckboxStyled
              key={id}
              id={id}
              label={name}
              checked={indexOf(filter, id) >= 0}
              alignIndicator="left"
              onChange={onChange}
            />
          )
        )}
      </PerfectScrollBar>
    </Fragment>
  )
}

const Filter = ({
  name,
  icon,
  disabled,
  filter,
  onChange,
  query,
  variables,
  dataPath,
  itemPath,
  defaultIsOpen,
  showEmojis,
  mapItems = items => items,
}) => {
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      defaultIsOpen={defaultIsOpen}
      content={
        <Popup>
          <Query
            showLoader={true}
            loaderIcon={
              <div style={{ padding: 12 }}>
                <Spinner size={24} value={null} />
              </div>
            }
            query={query}
            variables={variables}
          >
            {data => {
              const dataItems = get(data, dataPath, [])
              if (!data || !dataItems.length) {
                return <Error>None Available</Error>
              }

              const items = dataItems.reduce((acc, dataItem) => {
                const item = itemPath
                  ? get(dataItem, itemPath, false)
                  : dataItem
                if (!find(acc, { id: item.id })) {
                  acc = [...acc, item]
                }
                return acc
              }, [])

              const mappedItems = mapItems(items)

              const sortedItems = orderBy(
                mappedItems,
                [item => item.name.toLowerCase()],
                ['asc']
              )

              return (
                <Items
                  items={sortedItems}
                  onChange={onChange}
                  filter={filter}
                  showEmojis={showEmojis}
                />
              )
            }}
          </Query>
        </Popup>
      }
    >
      <Button
        icon={icon}
        text={name}
        disabled={disabled}
        rightIcon={
          filter.length === 0 ? (
            'double-caret-vertical'
          ) : (
            <Tag intent="primary">{filter.length}</Tag>
          )
        }
      />
    </Popover>
  )
}

Filter.propTypes = {
  name: string,
  icon: string,
  disabled: bool,
  filter: arrayOf(string),
  onChange: func,
  query: object,
  variables: object,
  dataPath: string,
  itemPath: string,
  defaultIsOpen: bool,
  showEmojis: bool,
  mapItems: func,
}

export const Popup = styled.div`
  min-width: 262px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
`

export const CheckboxStyled = styled(Checkbox)`
  margin: 0 16px 10px;
`

export const CheckBoxWithEmojis = styled(CheckboxStyled)`
  display: flex;
  align-items: flex-end;
`

export const Error = styled.p`
  padding: 12px;
  text-align: center;
`

export default Filter
