import gql from 'graphql-tag'

const GET_TERMINAL_ORDERS = gql`
  query getTerminalOrders(
    $outletId: ID
    $orderBy: OrdersOrderBy
    $afterDate: DateTime
    $orderStatus: [OrderStatus!]
    $skip: Int
    $first: Int
    $last: Int
  ) {
    orders(
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
      outletId: $outletId
      createdAt_gte: $afterDate
      orderStatus_in: $orderStatus
    ) {
      count
      totalCount
      totalOrders
      totalPending
      totalPreparing
      totalReady
      orders {
        id
        orderNumber
        grossTotal
        netTotal
        subtotalNet
        subtotalGross
        createdAt
        updatedAt
        orderStatus
        fulfillmentMethod
        estimatedDeliveryDate
        estimatedCompletionTime
        selectedDeliveryWindow
        deliveryNetworkProvider
        paymentMethod
        asap
        deliveryNetworkBookingStatus
        acceptedAt
        customer {
          id
          firstName
          lastName
        }
        tableSnapshot
        autoRejectAt
      }
    }
  }
`

export default GET_TERMINAL_ORDERS
