import gql from 'graphql-tag'

const GET_DELIVERY_NETWORKS = gql`
  query getDeliveryNetworks($marketplaceId: String!, $id: String) {
    getDeliveryNetworks(marketplaceId: $marketplaceId, id: $id) {
      deliveryNetworks {
        id
        name
        key
        deliveryProvider
        deliveryNetworkType
        bookingTrigger
        apiCredentials
        helpPhone
        namePrefix
        tariff
        fixedSubsidy
        deliveryChargeRecipient
        webhookStatus
        uberDirectTestScenario
      }
    }
  }
`

export default GET_DELIVERY_NETWORKS
