import gql from 'graphql-tag'

const CUSTOMER_ORDERS_FOR_ANALYTICS = gql`
  query customerOrdersForAnalytics($customerId: String!) {
    customerOrdersForAnalytics(customerId: $customerId) {
      discountJson
      outletId
      discountAmount
      subtotalGross
      fulfillmentMethod
      paymentMethod
      voucherKey
      orderStatus
      userAgent
      createdAt
      acceptedAt
    }
  }
`

export default CUSTOMER_ORDERS_FOR_ANALYTICS
