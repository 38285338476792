import gql from 'graphql-tag'

const EDIT_FOOTER_LINKS_ORDER = gql`
  mutation editFooterLinksOrder(
    $input: [IPositionArgs!]!
    $marketplaceId: String!
  ) {
    editFooterLinksOrder(input: $input, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export default EDIT_FOOTER_LINKS_ORDER
