import gql from 'graphql-tag'

const CUSTOMER_NAMES = gql`
  query customerName($customerIds: [ID!]) {
    customers(where: { id_in: $customerIds }) {
      customers {
        id
        firstName
        lastName
      }
    }
  }
`

export default CUSTOMER_NAMES
