import * as yup from 'yup'
import moment from 'moment'

export const discountShape = {
  name: yup
    .string()
    .required('Discount name is required')
    .min(3, 'Discount name too short'),

  allowedOutletIds: yup
    .array()
    .test(
      'outlet-selection',
      'At least one outlet needs to be selected',
      function (value) {
        const { outletSelected } = this.parent

        if (outletSelected === true) {
          return Array.isArray(value) && value.length > 0
        }
        return true // If `outletSelected` is false or undefined, no validation required.
      }
    ),

  discountPercentage: yup
    .number()
    .nullable()
    .notRequired()
    .test(
      'no-both-discounts',
      "Can't have a flat discount and a percentage one at the same time.",
      function (value) {
        const { discountAmount } = this.parent
        return !(value && discountAmount) // Both cannot exist.
      }
    )
    .test(
      'valid-percentage',
      "The discount can't be less than 0% or greater than 100%",
      value => value === null || (value >= 0 && value <= 100)
    )
    .test(
      'not-both-zero',
      "Percentage discount and fixed discount can't both be zero",
      function (value) {
        const { discountAmount } = this.parent
        return !(
          value === 0 &&
          (discountAmount === 0 || discountAmount === undefined)
        )
      }
    ),

  discountAmount: yup
    .number()
    .nullable()
    .notRequired()
    .test(
      'no-both-discounts',
      "Can't have a flat discount and a percentage one at the same time.",
      function (value) {
        const { discountPercentage } = this.parent
        return !(value && discountPercentage) // Both cannot exist.
      }
    )
    .test(
      'not-both-zero',
      "Percentage discount and fixed discount can't both be zero",
      function (value) {
        const { discountPercentage } = this.parent
        return !(
          value === 0 &&
          (discountPercentage === 0 || discountPercentage === undefined)
        )
      }
    )
    .test(
      'valid-amount',
      "The discount amount can't be less than 0.",
      value => value === null || value >= 0
    ),

  startDate: yup
    .date()
    .nullable()
    .test(
      'start-before-end',
      'The start date should be before the end date.',
      function (value) {
        const { endDate } = this.parent
        if (value && endDate) {
          return moment(value).isBefore(moment(endDate))
        }
        return true
      }
    )
    .test(
      'start-yesterday-or-after',
      'The start date should be more recent than yesterday.',
      value =>
        !value ||
        moment(value).isSameOrAfter(moment().subtract(1, 'day'), 'day')
    ),

  endDate: yup
    .date()
    .nullable()
    .test(
      'end-after-start',
      'The end date should be after the start date.',
      function (value) {
        const { startDate } = this.parent
        if (value && startDate) {
          return moment(value).isAfter(moment(startDate), 'day')
        }
        return true
      }
    )
    .test(
      'end-in-future',
      'The end date should be in the future.',
      value => !value || moment(value).isAfter(moment())
    ),

  daysOfWeek: yup
    .array()
    .of(yup.number().min(1).max(7))
    .required('At least one day needs to be selected')
    .min(1, 'At least one day needs to be selected'),

  minimumSubtotalGross: yup
    .number()
    .nullable()
    .notRequired()
    .test(
      'valid-minimum',
      "The minimum can't be less than 0",
      value => value === null || value >= 0
    ),
}

export const discountValidation = yup.object().shape(discountShape)
