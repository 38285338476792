import gql from 'graphql-tag'

const GET_CUISINE = gql`
  query getCuisine($id: String!) {
    getCuisines(id: $id) {
      cuisines {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`

export default GET_CUISINE
