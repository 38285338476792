import gql from 'graphql-tag'

const GET_MARKETPLACE_IDS = gql`
  query getMarketplaceToolbar {
    getMarketplaces {
      regions {
        id
        name
      }
    }
  }
`

export default GET_MARKETPLACE_IDS
