import gql from 'graphql-tag'

const EDIT_ALLOWED_CATEGORIES = gql`
  mutation editAllowedCategories(
    $marketplaceId: String!
    $allowedCategories: [String!]
    $optInAllowedCategories: Boolean!
    $categoryEmojiOrImage: CategoryEmojiOrImage!
  ) {
    editAllowedCategories(
      marketplaceId: $marketplaceId
      allowedCategories: $allowedCategories
      optInAllowedCategories: $optInAllowedCategories
      categoryEmojiOrImage: $categoryEmojiOrImage
    ) {
      message
      marketplace {
        id
        allowedCuisines
        optInAllowedCuisines
        categoryEmojiOrImage
      }
    }
  }
`

export default EDIT_ALLOWED_CATEGORIES
