import {
  Card,
  Button,
  Checkbox,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_OUTLET_SPECIAL_OFFER_FLAGS from './mutations/editOutletSpecialOfferFlags.mutation'
import { get } from 'lodash'
import { useMutation } from '@apollo/client'

const SpecialOfferFlags = ({ outlet }) => {
  const [editSpecialOfferFlags] = useMutation(EDIT_OUTLET_SPECIAL_OFFER_FLAGS, {
    onError: defaultErrorHandler,
    onCompleted: () =>
      successToast('Successfully edited outlet special offer flags'),
  })

  return outlet && outlet.restaurant.outletOfferFlagOverride ? (
    <Card>
      <h5 className="bp5-heading">Special Offer Flags</h5>
      <Formik
        initialValues={{
          deliveryDeal: Boolean(outlet.deliveryDeal),
          collectionDeal: Boolean(outlet.collectionDeal),
          tableDeal: Boolean(outlet.tableDeal),
          deliveryDealText: outlet.deliveryDeal || '',
          collectionDealText: outlet.collectionDeal || '',
          tableDealText: outlet.tableDeal || '',
        }}
        onSubmit={values =>
          editSpecialOfferFlags({
            variables: {
              outletId: outlet.id,
              deliveryDeal: values.deliveryDealText,
              collectionDeal: values.collectionDealText,
              tableDeal: values.tableDealText,
            },
          })
        }
      >
        {({ values, setFieldValue, handleChange, handleSubmit, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup subLabel="Overrides the business level special offer flags.">
                <Checkbox
                  defaultChecked={values.deliveryDeal}
                  onChange={e => {
                    handleChange(e)

                    setFieldValue('deliveryDealText', '')
                  }}
                  name="deliveryDeal"
                >
                  Delivery
                </Checkbox>
                {values.deliveryDeal && (
                  <div
                    style={{
                      marginLeft: 24,
                      marginRight: 48,
                      marginBottom: 10,
                    }}
                  >
                    <InputGroup
                      name="deliveryDealText"
                      id="deliveryDealText"
                      type="text"
                      value={values.deliveryDealText}
                      onChange={handleChange}
                      maxLength={85}
                      intent={
                        get(errors, 'deliveryDealText')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      required={values.deliveryDeal}
                    />
                  </div>
                )}

                <Checkbox
                  defaultChecked={values.collectionDeal}
                  onChange={e => {
                    handleChange(e)

                    setFieldValue('collectionDealText', '')
                  }}
                  name="collectionDeal"
                >
                  Collection
                </Checkbox>
                {values.collectionDeal && (
                  <div
                    style={{
                      marginLeft: 24,
                      marginRight: 48,
                      marginBottom: 10,
                    }}
                  >
                    <InputGroup
                      name="collectionDealText"
                      id="collectionDealText"
                      type="text"
                      value={values.collectionDealText}
                      onChange={handleChange}
                      maxLength={85}
                      intent={
                        get(errors, 'collectionDealText')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      required={values.collectionDeal}
                    />
                  </div>
                )}
                <Checkbox
                  defaultChecked={values.tableDeal}
                  onChange={e => {
                    handleChange(e)

                    setFieldValue('tableDealText', '')
                  }}
                  name="tableDeal"
                >
                  Table Service
                </Checkbox>
                {values.tableDeal && (
                  <div
                    style={{
                      marginLeft: 24,
                      marginRight: 48,
                      marginBottom: 10,
                    }}
                  >
                    <InputGroup
                      name="tableDealText"
                      id="tableDealText"
                      type="text"
                      value={values.tableDealText}
                      onChange={handleChange}
                      maxLength={85}
                      intent={
                        get(errors, 'tableDealText')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      required={values.tableDeal}
                    />
                  </div>
                )}
              </FormGroup>
              <div className="bp-card-footer-actions">
                <Button text="Save" type="submit" />
              </div>
            </form>
          )
        }}
      </Formik>
    </Card>
  ) : null
}
export default SpecialOfferFlags
