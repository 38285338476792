import { HTMLTable, Icon, Tooltip } from '@blueprintjs/core'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import GET_ROLE_PERMISSIONS from '@components/Permissions/queries/getRolePermissions.query'
import Query from '@components/Query/Query'
import { isPlatformLevelUser } from '@stores/userStore'
import startCase from 'lodash/startCase'
import React, { useContext, useEffect } from 'react'
import { Fragment } from 'react'

// from the top
const roleKeys = [
  'SUPERADMIN',
  'DEVELOPER',
  'PARTNER',
  'MARKETPLACE_ADMIN',
  'MARKETPLACE_OWNER',
  'MARKETPLACE_USER',
  'MARKETING_USER',
  'RESTAURANT_USER',
  'MENU_EDITOR',
  'OUTLET_USER',
  'OUTLET_FINANCIAL_USER',
]

// reduce the list of permissions to a list of unique permissions
/**
 *
 * @param {object[]} roles
 * @param {string} roles[].id
 * @param {string} roles[].key
 * @param {string} roles[].title
 * @param {object[]} roles[].permissionCategories
 * @param {string} roles[].permissionCategories[].title
 * @param {string[]} roles[].permissionCategories[].queries
 * @param {string[]} roles[].permissionCategories[].mutations
 * @param {string[]} roles[].permissionCategories[].subscriptions
 * @returns {object}
 */
const reduceRoles = roles =>
  roles.reduce((acc, role) => {
    const { permissionCategories, key: roleKey } = role

    permissionCategories.forEach(
      ({
        queries = [],
        mutations = [],
        subscriptions = [],
        title: permTitle,
      }) => {
        if (acc[permTitle]) {
          acc[permTitle].roles.push(roleKey)
        } else {
          acc[permTitle] = {
            roles: [roleKey],
            queries,
            mutations,
            subscriptions,
          }
        }
      }
    )
    return acc
  }, {})

const Permissions = () => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      tabs: [
        { to: '/admin/users', name: 'Users' },
        {
          to: '/admin/permissions',
          name: 'Permissions',
          hide: !isPlatformLevelUser(),
        },
      ],
    })
  }, [configurePageLayout])

  return (
    <Query query={GET_ROLE_PERMISSIONS}>
      {({ getAllRoles }) => {
        const permissionToRoleMap = reduceRoles(getAllRoles.roles)
        const roleKeysUserCanAccess = getAllRoles.roles.map(({ key }) => key)
        const orderedRoleKeys = roleKeys.filter(roleKey =>
          roleKeysUserCanAccess.includes(roleKey)
        )

        return (
          <div className="bp5-table-frame">
            <div className="bp5-table-container bp5-scrollable">
              <HTMLTable compact striped interactive>
                <thead>
                  <tr>
                    <th>Permission</th>
                    {orderedRoleKeys.map(roleKey => (
                      <th key={roleKey} width="10%">
                        {startCase(roleKey.toLowerCase())}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(permissionToRoleMap).map(
                    ([
                      permission,
                      { roles, queries, mutations, subscriptions },
                    ]) => (
                      <tr key={permission}>
                        <td>
                          <Tooltip
                            content={
                              <Fragment>
                                {queries.length > 0 &&
                                  queries.map(query => (
                                    <div key={query}>
                                      <Icon icon="search" iconSize={12} />{' '}
                                      {query}
                                    </div>
                                  ))}
                                {mutations.length > 0 &&
                                  mutations.map(mutation => (
                                    <div key={mutation}>
                                      <Icon icon="edit" iconSize={12} />{' '}
                                      {mutation}
                                    </div>
                                  ))}
                                {subscriptions.length > 0 &&
                                  subscriptions.map(subscription => (
                                    <div key={subscription}>
                                      <Icon icon="refresh" iconSize={12} />{' '}
                                      {subscription}
                                    </div>
                                  ))}
                              </Fragment>
                            }
                          >
                            <span>{permission}</span>
                          </Tooltip>
                        </td>
                        {orderedRoleKeys.map(name => (
                          <td key={name}>
                            <Icon
                              icon={roles.includes(name) ? 'tick' : 'cross'}
                              intent={
                                roles.includes(name) ? 'success' : 'danger'
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </HTMLTable>
            </div>
          </div>
        )
      }}
    </Query>
  )
}

export default Permissions
