import { Card } from '@blueprintjs/core'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import React, { useContext } from 'react'

export const CheckerBackgroundImgPreview = ({ imageSrc }) => {
  const { dark } = useContext(PageLayoutContext)
  const checkerColor = dark ? '#394B59' : '#E8EBEE'

  return (
    <Card
      style={{
        marginBottom: '8px',
        backgroundImage: `linear-gradient(45deg, ${checkerColor} 25%, transparent 25%), linear-gradient(-45deg, ${checkerColor} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${checkerColor} 75%), linear-gradient(-45deg, transparent 75%, ${checkerColor} 75%)`,
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
      }}
    >
      <img src={imageSrc} style={{ maxHeight: '100px' }} />
    </Card>
  )
}
