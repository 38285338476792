import gql from 'graphql-tag'

const MANY_OUTLETS = gql`
  query getOutletsByIds($ids: [String!]!) {
    getOutletsByIds(ids: $ids) {
      outlets {
        id
        name
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default MANY_OUTLETS
