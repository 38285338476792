import gql from 'graphql-tag'

const EDIT_OUTLET_CONTACT_DETAILS = gql`
  mutation editOutletContactDetails(
    $id: String!
    $name: String
    $companyType: CompanyType
    $companyLegalName: String
    $companyNumber: String
    $taxReference: String
    $otterStoreId: String
    $description: String
    $contactName: String
    $contactAddress: AddressCreateInput
    $contactPhone: String
    $contactEmail: String
    $contactDoB: DateTime
    $outletAddress: AddressCreateInput
    $outletPhone: String
    $outletEmail: String
    $marketplaceId: String
    $isOnlineOverride: Boolean
    $isAutoAccept: Boolean
    $emailOrderReceipt: Boolean
    $emailInvoices: Boolean
    $bankAccount: String
    $bankSort: String
    $coverImage: String
    $outletLogoOverride: String
    $escalationMobile: String
  ) {
    editOutlet(
      id: $id
      name: $name
      companyType: $companyType
      companyLegalName: $companyLegalName
      companyNumber: $companyNumber
      taxReference: $taxReference
      otterStoreId: $otterStoreId
      description: $description
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      contactDoB: $contactDoB
      outletAddress: $outletAddress
      outletPhone: $outletPhone
      outletEmail: $outletEmail
      escalationMobile: $escalationMobile
      marketplaceId: $marketplaceId
      isOnlineOverride: $isOnlineOverride
      isAutoAccept: $isAutoAccept
      emailOrderReceipt: $emailOrderReceipt
      emailInvoices: $emailInvoices
      bankAccount: $bankAccount
      bankSort: $bankSort
      coverImage: $coverImage
      outletLogoOverride: $outletLogoOverride
    ) {
      message
      outlet {
        id
        name
        companyType
        companyLegalName
        companyNumber
        taxReference
        otterStoreId
        description
        contactName
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
          geo
        }
        contactPhone
        contactEmail
        contactDoB
        outletAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
          geo
        }
        outletPhone
        outletEmail
        escalationMobile
        isOnlineOverride
        isAutoAccept
        emailOrderReceipt
        emailInvoices
        bankSort
        bankAccount
        coverImage
        outletLogoOverride
      }
    }
  }
`

export default EDIT_OUTLET_CONTACT_DETAILS
