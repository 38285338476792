import { Link } from 'react-router-dom'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export interface MarketplaceColumn<X extends string>
  extends BaseColumn<'marketplace', X> {}

export interface MarketplaceColumnData {
  marketplaceId: string
  marketplaceName: string
  onClick?: () => void
}

export const sortMarketplaceColumn: ColumnSorter<'marketplace'> = (
  a,
  b,
  order
) => {
  const negator = order === 'DESC' ? 1 : -1
  return (
    negator * collator.compare(b.marketplaceName ?? '', a.marketplaceName ?? '')
  )
}

export const MarketplaceColumnContent: ColumnRenderer<
  'marketplace',
  string
> = ({ columnData: { onClick, marketplaceName, marketplaceId } }) => {
  if (onClick)
    return (
      <Link
        to="#"
        onClick={event => {
          event.preventDefault()
          onClick()
        }}
      >
        {marketplaceName}
      </Link>
    )
  return <Link to={`/marketplaces/${marketplaceId}`}>{marketplaceName}</Link>
}
