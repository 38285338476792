import { Fragment, useState } from 'react'
import { FormikProps, withFormik } from 'formik'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'

import LoginForm from './LoginForm'
import LOGIN from './mutations/login.mutation'
import logo from '@assets/logo.svg'
import loginIcon from '@assets/icons/login.svg'
import ForgotPasswordModal from '@components/ForgotPassword/ForgotPassword.modal'
import ResetPassword from '@components/ResetPassword/ResetPassword'
import {
  Container,
  Icon,
  LoginContainer,
  LoginFormCard,
  LogoContainer,
} from './Login.styles'
import { StringParam, useQueryParams } from 'use-query-params'
import { MutationLoginUserArgs } from '../../types.generated'
import { navigate } from '../../utils/navigate'
import { useMutation } from '@apollo/client'

const handleLogin = data => {
  if (data.bypass) {
    ls.set('jwt', data.bypass.token)
    ls.set(
      'permissionCategories',
      get(data.bypass.role, 'permissionCategories')
    )
    ls.set('role', get(data.bypass.role, 'title'))
    ls.set('ownsPartners', data.bypass.ownsPartners)
    navigate('/')
  } else {
    ls.set('partialJwt', data.partialToken)
    ls.set('canUse2FA', data.canUse2FA)
    ls.set('phoneNumber', data.phoneNumber)
    if (data.canUse2FA) {
      navigate('/2fa')
    } else {
      navigate('/validate-phone')
    }
  }
}

const LoginWrapper: React.FC<FormikProps<MutationLoginUserArgs>> = props => {
  const [{ email, token }] = useQueryParams({
    email: StringParam,
    token: StringParam,
  })
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const [loginUser] = useMutation(LOGIN, {
    onError: error => {
      const serverErrors = get(error, 'graphQLErrors', [])

      // fallback for errors that weren't returned by the server
      if (!serverErrors.length) {
        return errorToast(error.message)
      }

      // loop through custom server errors displaying error toast
      for (const serverError of serverErrors) {
        errorToast(serverError.message)
      }

      props.setSubmitting(false)
    },
    onCompleted: ({ loginUser }) => {
      handleLogin(loginUser)
    },
  })

  return (
    <Container>
      <LoginContainer>
        <LogoContainer src={logo} alt={'logo'} />
        {!token && (
          <Fragment>
            <LoginFormCard elevation={2}>
              <Icon src={loginIcon} />
              <LoginForm mutation={loginUser} {...props} />
            </LoginFormCard>
            <Fragment>
              <a onClick={() => setShowForgotPassword(true)} tabIndex={0}>
                Forgotten Password?
              </a>
              <ForgotPasswordModal
                closeModal={() => setShowForgotPassword(false)}
                modalOpen={showForgotPassword}
              />
            </Fragment>
          </Fragment>
        )}
        {token && <ResetPassword email={email} token={token} />}
      </LoginContainer>
    </Container>
  )
}

// TODO switch to formik component
const Login = withFormik<Record<string, never>, MutationLoginUserArgs>({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  // handled in mutation
  handleSubmit: () => {},
})(LoginWrapper as any)

export default Login
export { Login }
