import { useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Query from '@components/Query/Query'
import GET_PARTNERS from './queries/getPartners.query'
import { HTMLTable, Card, Icon } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

import PartnerModal from './Partner.modal/Partner.modal'

import Currency from '@components/Currency/Currency'
import { isPlatformLevelUser } from '@stores/userStore'
import { useModal } from '@src/providers/ModalProvider'
import productDictionary from '@src/utils/productDictionary'

const PageLayoutWrapper = ({ product, children }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  const { toggleModal } = useModal('partnerModal')

  useEffect(() => {
    configurePageLayout({
      product,
      button: isPlatformLevelUser() && {
        text: 'Add Partner',
        onClick: () => toggleModal(true),
      },
      tabs: [{ to: '/partners', name: 'List' }],
    })
  }, [configurePageLayout, product])

  return children
}

const Partners = () => {
  const { isOpen, toggleModal } = useModal('partnerModal')

  return (
    <PageLayoutWrapper product={productDictionary.PARTNER}>
      <Query query={GET_PARTNERS} loaderTitle={'Loading Partners'}>
        {({ getPartners: { partners } }) => (
          <div className="bp5-table-frame">
            <div className="bp5-table-container bp5-scrollable">
              <Card className={'bp5-nopad'}>
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      {[
                        { key: 'name', content: 'Name' },
                        { key: 'contactName', content: 'Contact' },
                        {
                          key: 'platformCommission',
                          content: 'Commission Fee',
                        },
                        { key: 'platformFee', content: 'Platform Fee' },
                        {
                          key: 'platformCharge',
                          content: 'Platform Charge',
                        },
                        { key: 'marketplaces', content: 'Marketplaces' },
                        { key: 'businesses', content: 'Businesses' },
                        { key: 'vat', content: 'VAT' },
                      ].map(({ key, content }) => (
                        <th key={key}>{content}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {partners.map(
                      ({
                        id,
                        name,
                        marketplaces,
                        restaurants,
                        contactName,
                        platformFee,
                        platformCharge,
                        isVATregistered,
                        partnerCommissionFee,
                      }) => (
                        <tr key={id}>
                          <td>
                            <Link to={`/partners/${id}`}>{name}</Link>
                          </td>
                          <td>{contactName}</td>
                          <td>{partnerCommissionFee || 0} %</td>
                          <td>{platformFee} %</td>
                          <td>
                            <Currency amount={platformCharge} />
                          </td>
                          <td>
                            {marketplaces.length ? (
                              <Link to={`/marketplaces?partnerFilter=${id}`}>
                                {marketplaces.length}
                              </Link>
                            ) : (
                              <span className="bp5-text-muted">0</span>
                            )}
                          </td>
                          <td>
                            {restaurants.length ? (
                              <Link to={`/business?partnerFilter=${id}`}>
                                {restaurants.length}
                              </Link>
                            ) : (
                              <span className="bp5-text-muted">0</span>
                            )}
                          </td>
                          <td>
                            <Icon
                              icon={
                                isVATregistered ? 'small-tick' : 'small-cross'
                              }
                              color={isVATregistered ? '#5BB70D' : '#CDD6DD'}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HTMLTable>
              </Card>
            </div>
          </div>
        )}
      </Query>
      <PartnerModal modalOpen={isOpen} modalClose={() => toggleModal(false)} />
    </PageLayoutWrapper>
  )
}

export default Partners
