import { HTMLTable, NonIdealState, Spinner } from '@blueprintjs/core'
import { ReactNode } from 'react'
import {
  Column,
  ColumnDataType,
  ColumnType,
  ColumnTypeToColumnData,
  ColumnTypeToColumnDefinition,
  RowData,
} from './Columns/column.types'
import {
  CurrencyColumnContent,
  CurrencyColumnData,
} from './Columns/CurrencyColumn'
import {
  MarketplaceColumnContent,
  MarketplaceColumnData,
} from './Columns/MarketplaceColumn'
import { MonthColumnContent, MonthColumnData } from './Columns/MonthColumn'
import {
  NumericColumnContent,
  NumericColumnData,
} from './Columns/NumericColumn'
import { OutletColumnContent, OutletColumnData } from './Columns/OutletColumn'
import { PercentageColumnContent } from './Columns/PercentageColumn'
import { TextColumnContent, TextColumnData } from './Columns/TextColumn'
import { MegaSortBy, useSortedData } from './SortBy'
import {
  DateTimeColumnContent,
  DateTimeColumnData,
} from './Columns/DateTimeColumn'
import { OrderColumnContent, OrderColumnData } from './Columns/OrderColumn'
import {
  CustomerColumnContent,
  CustomerColumnData,
} from './Columns/CustomerColumn'
import {
  FulfillmentColumnContent,
  FulfillmentColumnData,
} from './Columns/FulfillmentColumn'
import { PaymentMethodColumnContent } from './Columns/PaymentMethodColumn'

export type Sorting = 'CLIENT' | 'SERVER' | 'NONE'

export interface MegaTableProps<
  Columns extends readonly Column<U>[],
  U extends string,
> {
  columnGroupHeaders?: Record<U, ReactNode>
  columns: Columns
  data: RowData<Columns, U>[]
  sorting: Sorting
  isLoading: boolean
  isError: boolean
}

/**
 * A react component that provides nice, sane defaults for Redbox Management
 *
 * To get nice types you MUST use the tableProps helper,
 * AND define the `columns` array using `as const`
 *
 * then typescript will be your friend and warn you if you have the wrong number of columns
 * or the wrong types in the `data` prop
 * 
 * e.g.
 * <MegaTable
          {...tableProps({
            columns: [
              { type: 'outlet', header: 'Outlet', isSortable: true },
              {
                type: 'currency',
                header: 'Total Rejected Value',
                isSortable: true,
              },
            ] as const,
            data: [
              {
                id: 'an string or it errors because outlet columns are defined as having an id',
                name: 'same here',
                thisWillError: 'because outlet columns don't have this field defined'
              }
            
            ]
            
            data.map(
              ({ outlet_id, outlet_name, total_rejected_value }) => [
                { id: outlet_id, name: outlet_name },
                total_rejected_value,
                'this errors because there are 3 values
              ]
            ),
          })}
        />
 */

const groupKeysToColSpans = <T extends string>(
  arr: readonly { groupKey?: T }[],
  columnGroupHeaders: Record<T, ReactNode>
): { span: number; content: ReactNode }[] => {
  if (arr.length === 0) return []

  const result: { span: number; content: ReactNode }[] = []
  let currentSpan = 1

  for (let i = 1; i <= arr.length; i++) {
    if (i < arr.length && arr[i].groupKey === arr[i - 1].groupKey) {
      currentSpan++
    } else {
      result.push({
        span: currentSpan,
        content: columnGroupHeaders[arr[i - 1].groupKey],
      })
      currentSpan = 1
    }
  }
  return result
}

const groupKeyChangeIndices = <T extends string>(
  arr: readonly { groupKey?: T }[]
): number[] => {
  const changeIndices: number[] = []

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].groupKey !== arr[i - 1].groupKey) {
      changeIndices.push(i)
    }
  }

  return changeIndices
}

export const MegaTable = <
  U extends string,
  Columns extends readonly Column<U>[],
>({
  columns,
  data,
  sorting = 'NONE',
  isLoading,
  isError,
  columnGroupHeaders,
}: MegaTableProps<Columns, U>): ReactNode => {
  const sortedData = useSortedData({ data, sorting, columns })
  if (isError) return 'ERROR'
  const changeIndices = groupKeyChangeIndices(columns)
  return (
    <>
      <HTMLTable interactive>
        <thead>
          {columnGroupHeaders && (
            <tr>
              {groupKeysToColSpans(columns, columnGroupHeaders).map(
                (x, index) => (
                  <th
                    key={index}
                    style={{
                      borderLeft: x.content ? ' 1px solid #DCDCDD' : 'unset',
                    }}
                    colSpan={x.span}
                  >
                    {x.content}
                  </th>
                )
              )}
            </tr>
          )}
          <tr>
            {columns.map((column, index) => {
              return (
                <th
                  key={index}
                  style={
                    changeIndices.includes(index)
                      ? { borderLeft: ' 1px solid #DCDCDD' }
                      : {}
                  }
                >
                  {column.header}

                  {column.isSortable && sorting !== 'NONE' && (
                    <MegaSortBy {...column} />
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length + 1} />
            </tr>
          ) : (
            sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, cellIndex) => (
                  <td
                    style={
                      changeIndices.includes(cellIndex)
                        ? { borderLeft: ' 1px solid #DCDCDD' }
                        : {}
                    }
                    key={cellIndex}
                  >
                    {ColumnContent(column, row[cellIndex])}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </HTMLTable>
      {isLoading && <NonIdealState icon={<Spinner />} title="Loading" />}
    </>
  )
}

function ColumnContent<T extends ColumnType, U extends string>(
  column: ColumnTypeToColumnDefinition<U>[T],
  value: ColumnTypeToColumnData[T] | null | undefined
): ReactNode {
  const placeholder = column.placeholder ?? '-'

  if (value === null || value === undefined) return placeholder

  switch (column.type) {
    case 'text': {
      return (
        <TextColumnContent
          columnDefinition={column}
          columnData={value as TextColumnData}
        />
      )
    }

    case 'numeric': {
      return (
        <NumericColumnContent
          columnDefinition={column}
          columnData={value as NumericColumnData}
        />
      )
    }

    case 'currency': {
      return (
        <CurrencyColumnContent
          columnDefinition={column}
          columnData={value as CurrencyColumnData}
        />
      )
    }

    case 'paymentMethod': {
      return (
        <PaymentMethodColumnContent
          columnDefinition={column}
          columnData={value as ColumnDataType<'paymentMethod'>}
        />
      )
    }

    case 'percentage': {
      return (
        <PercentageColumnContent
          columnDefinition={column}
          columnData={value as ColumnDataType<'percentage'>}
        />
      )
    }

    case 'outlet': {
      return (
        <OutletColumnContent
          columnDefinition={column}
          columnData={value as OutletColumnData}
        />
      )
    }

    case 'marketplace': {
      return (
        <MarketplaceColumnContent
          columnDefinition={column}
          columnData={value as MarketplaceColumnData}
        />
      )
    }

    case 'month': {
      return (
        <MonthColumnContent
          columnDefinition={column}
          columnData={value as MonthColumnData}
        />
      )
    }

    case 'datetime':
      return (
        <DateTimeColumnContent
          columnDefinition={column}
          columnData={value as DateTimeColumnData}
        />
      )

    case 'order':
      return (
        <OrderColumnContent
          columnDefinition={column}
          columnData={value as OrderColumnData}
        />
      )
    case 'customer':
      return (
        <CustomerColumnContent
          columnDefinition={column}
          columnData={value as CustomerColumnData}
        />
      )

    case 'fulfillment':
      return (
        <FulfillmentColumnContent
          columnDefinition={column}
          columnData={value as FulfillmentColumnData}
        />
      )

    default:
      return null
  }
}
