import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import ReviewStatus from '@components/Review/ReviewStatus'
import StarsRating from '@components/Stars/Stars'
import moment from 'moment'
import { object, array } from 'prop-types'
import get from 'lodash/get'
import EditReviewModal from '@components/Review/modals/editReviewModal'
import { Query as CacheQuery } from '@apollo/client/react/components'
import OPEN_MODAL from '@components/Review/queries/openModal.clientQuery'
import styled from 'styled-components'
import { ModalContext } from '@src/providers/ModalProvider'

export const ReviewNumber = styled.td`
  width: 4rem;
  max-width: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ReviewText = styled.td`
  width: 20rem;
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ReviewsTableBody = ({ reviews, queryVars }) => {
  let expandedReview = {}

  const hideCustomer = get(queryVars, 'hideCustomer')
  const hideOutlet = get(queryVars, 'hideOutlet')
  const { setState: toggleModal } = useContext(ModalContext)

  return (
    <CacheQuery query={OPEN_MODAL}>
      {({ data: { editReviewModal } }) => (
        <Fragment>
          <tbody>
            {reviews.map(review => {
              const {
                id,
                createdAt,
                customer,
                order,
                outlet,
                foodQuality,
                restaurantService,
                deliveryTime,
                reviewText,
                approved,
              } = review
              return (
                <tr key={id}>
                  <ReviewNumber>{id}</ReviewNumber>
                  <td>{moment(createdAt).format('HH:mm D/MM/YYYY')}</td>
                  {!hideCustomer && (
                    <td>
                      <Link to={`/customer/${customer.id}`}>
                        {customer.firstName}
                      </Link>
                    </td>
                  )}
                  <td>
                    <Link to={`/orders?id=${order.id}`}>
                      {order.orderNumber}
                    </Link>
                  </td>
                  {!hideOutlet && (
                    <td>
                      <Link
                        to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}`}
                      >
                        {outlet.name}
                      </Link>
                    </td>
                  )}
                  <td>
                    <StarsRating starsNumber={foodQuality} />
                  </td>
                  <td>
                    <StarsRating starsNumber={restaurantService} />
                  </td>
                  <td>
                    <StarsRating starsNumber={deliveryTime} />
                  </td>
                  <ReviewText>{reviewText}</ReviewText>
                  <td
                    onClick={() => {
                      toggleModal({
                        editReviewModal: true,
                      })
                      expandedReview = review
                    }}
                  >
                    <ReviewStatus approved={approved} />
                  </td>
                </tr>
              )
            })}
          </tbody>
          <EditReviewModal
            review={expandedReview}
            modalOpen={editReviewModal}
            modalClose={() => {
              toggleModal({
                editReviewModal: false,
              })
            }}
          />
        </Fragment>
      )}
    </CacheQuery>
  )
}

ReviewsTableBody.propTypes = {
  reviews: array,
  queryVars: object,
}

export default ReviewsTableBody
