import { HTMLSelect } from '@blueprintjs/core'
import { differenceInDays, subDays } from 'date-fns'
import { useEffect } from 'react'
import { DATE_FILTER_TYPES } from '../../../utils/datetime'
import { useDateRangeQueryParams } from './useDateRangeQueryParams'

interface Props {
  date: Date
  dateToDisplay: Date
  onChangeDate: (date: Date) => void
  minimal?: boolean
  disabled?: boolean
}

const DAYS_AGO_OPTIONS: { label: string; value: number }[] = [
  { label: 'Last 30 Days', value: 30 },
  { label: 'Last 60 Days', value: 60 },
  { label: 'Last 90 Days', value: 90 },
]

const DEFAULT_DAYS_AGO = 30

export const DaysAgoSelector: React.FC<Props> = ({
  minimal = false,
  disabled = false,
}) => {
  const { startOfRangeDateTime, setRange } = useDateRangeQueryParams()
  const daysAgo = differenceInDays(new Date(), startOfRangeDateTime)

  useEffect(() => {
    // if the dates have somehow already been set, force it to the default one
    if (!DAYS_AGO_OPTIONS.find(x => x.value === daysAgo)) {
      const defaultDate = subDays(new Date(), DEFAULT_DAYS_AGO - 1)

      setRange({
        startOfRangeDate: defaultDate,
        dateFilterTypeKey: DATE_FILTER_TYPES.DAYS_AGO.key,
      })
    }
  }, [daysAgo])

  return (
    <span>
      <HTMLSelect
        minimal={minimal}
        disabled={disabled}
        value={daysAgo}
        onChange={event => {
          const newStart = subDays(
            new Date(),
            Number(event.currentTarget.value)
          )
          setRange({
            startOfRangeDate: newStart,
            dateFilterTypeKey: DATE_FILTER_TYPES.DAYS_AGO.key,
          })
        }}
      >
        {DAYS_AGO_OPTIONS.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </HTMLSelect>
    </span>
  )
}
