import gql from 'graphql-tag'

const DELETE_TERMINAL = gql`
  mutation deleteTerminal($id: String!) {
    deleteTerminal(id: $id) {
      message
    }
  }
`

export default DELETE_TERMINAL
