import gql from 'graphql-tag'

const CREATE_DISCOUNT = gql`
  mutation createDiscount(
    $name: String!
    $discountPercentage: Float
    $discountAmount: Int
    $startDate: DateTime
    $endDate: DateTime
    $daysOfWeek: [Int!]
    $minimumSubtotalGross: Int
    $restaurantId: String
    $marketplaceId: String
    $allowedOutlets: [String!]!
  ) {
    createDiscount(
      name: $name
      discountPercentage: $discountPercentage
      discountAmount: $discountAmount
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
      minimumSubtotalGross: $minimumSubtotalGross
      restaurantId: $restaurantId
      marketplaceId: $marketplaceId
      allowedOutlets: $allowedOutlets
    ) {
      message
      discount {
        id
        name
        discountPercentage
        discountAmount
        startDate
        endDate
        daysOfWeek
        minimumSubtotalGross
      }
    }
  }
`

export default CREATE_DISCOUNT
