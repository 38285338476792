import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ACMClient, DescribeCertificateCommand } from '@aws-sdk/client-acm'

const ACMDescribeCertificate = ({
  children,
  certificateArn,
  credentials = {},
  handleLoading = false,
  onCompleted = () => {},
  onError = () => {},
}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(!data)
  const [error, setError] = useState(null)

  const ACMDescribeCertificate = async (credentials, certificateArn) => {
    setError(false)
    setLoading(true)

    const acm = new ACMClient({
      region: 'us-east-1',
      credentials,
    })
    try {
      const command = new DescribeCertificateCommand({
        CertificateArn: certificateArn,
      })
      const data = await acm.send(command)
      setData(data)
      onCompleted()
    } catch (e) {
      onError()
      setError(e)
      setData(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    ACMDescribeCertificate(credentials, certificateArn)
  }, [certificateArn, credentials])

  if (handleLoading && loading) {
    return null
  }

  return children({ loading, error, data })
}

ACMDescribeCertificate.propTypes = {
  children: PropTypes.func.isRequired,
  certificateArn: PropTypes.string,
  credentials: PropTypes.object,
  handleLoading: PropTypes.bool,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
}

export default ACMDescribeCertificate
