import gql from 'graphql-tag'

const EDIT_APPLE_KEY_DETAILS = gql`
  mutation editAppleKeyDetails(
    $id: ID!
    $appleTeamIdDefault: String
    $appStoreConnectApiKeyId: String
    $appStoreConnectApiIssuerId: String
    $appStoreConnectApiKeyPath: String
  ) {
    editPartner(
      id: $id
      appleTeamIdDefault: $appleTeamIdDefault
      appStoreConnectApiKeyId: $appStoreConnectApiKeyId
      appStoreConnectApiIssuerId: $appStoreConnectApiIssuerId
      appStoreConnectApiKeyPath: $appStoreConnectApiKeyPath
    ) {
      partner {
        id
        name
        appStoreConnectApiKeyId
        appStoreConnectApiIssuerId
        appStoreConnectApiKeyPath
      }
    }
  }
`

export default EDIT_APPLE_KEY_DETAILS
