import { NonIdealState, Button } from '@blueprintjs/core'
import { useModal } from '@src/providers/ModalProvider'

const NoParentMenus = () => {
  const { toggleModal } = useModal('parentMenuModal')

  return (
    <NonIdealState
      icon="clipboard"
      title="No Menus"
      description="Start building a menu for this business."
      action={
        <Button text="Add Menu" icon="plus" onClick={() => toggleModal(true)} />
      }
      className="bp5-surface-card-non-ideal-state"
    />
  )
}

export default NoParentMenus
