import gql from 'graphql-tag'

const ALLOW_SINGLE_ITEM_ORDER_NOTES = gql`
  mutation allowSingleItemOrderNotes(
    $id: String!
    $allowSingleItemOrderNotes: Boolean!
  ) {
    editMarketplace(
      id: $id
      allowSingleItemOrderNotes: $allowSingleItemOrderNotes
    ) {
      message
      marketplace {
        id
        allowSingleItemOrderNotes
      }
    }
  }
`

export default ALLOW_SINGLE_ITEM_ORDER_NOTES
