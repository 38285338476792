// error handler which
//   1. logs user out if an AUTHENTICATION_ERROR is returned,
//   2. displays errors using toast
import get from 'lodash/get'
import { logout } from '@stores/userStore'
import { errorToast } from '@utils/toast'
import { ApolloError } from '@apollo/client'

export default (error: ApolloError) => {
  const serverErrors = error.graphQLErrors

  // fallback for errors that weren't returned by the server
  if (!serverErrors.length) {
    return errorToast(error.message)
  }

  // if any of the errors are because of failed authentication, then log the user out
  if (
    serverErrors.some(
      serverError =>
        'code' in serverError && serverError.code === 'AUTHENTICATION_ERROR'
    )
  ) {
    return logout()
  }

  // loop through custom server errors displaying error toast
  for (const serverError of serverErrors) {
    if ('code' in serverError && serverError.code === 'VALIDATION_ERROR') {
      const validationErrorMessages = get(serverError, 'data.errors', [])

      // log each child validation error
      for (const validationErrorMessage of validationErrorMessages) {
        errorToast(validationErrorMessage)
      }
    } else {
      // log the root error
      errorToast(serverError.message)
    }
  }
}
