import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceEnablePayboxEnterprise = ({
  updateStatus,
  enablePayboxEnterprise,
  disabled = false,
}) => {
  return (
    <Formik initialValues={{ enablePayboxEnterprise }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            disabled={disabled}
            label={'Paybox Enterprise'}
            checked={values.enablePayboxEnterprise}
            onChange={e => {
              handleChange(e)
              updateStatus({
                enablePayboxEnterprise: e.currentTarget.checked,
              })
            }}
            name="enablePayboxEnterprise"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceEnablePayboxEnterprise.propTypes = {
  enablePayboxEnterprise: bool,
  errors: object,
}

export default MarketplaceEnablePayboxEnterprise
