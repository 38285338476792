import React from 'react'

import MenuItems from './MenuItems'
import ParentMenuPane from './ParentMenuPane'
import SubMenuPane from './SubMenuPane'
import { Card } from '@blueprintjs/core'
import { Container } from './Menu.styles'
import { useMenuState } from './MenuStateProvider'

// This is used for column view
const MenuTree: React.FC<{
  outlet: boolean
  queryId: string
  deepestMenuId: string
  setViewMenuItem: ({
    menuId,
    menuItemId,
  }: {
    menuItemId: string
    menuId: string
  }) => void
}> = ({ outlet, queryId, deepestMenuId, setViewMenuItem }) => {
  const { data } = useMenuState()

  return (
    <Card
      className="bp5-nopad"
      style={{ overflowY: 'auto', minWidth: '100%', maxWidth: '100%' }}
    >
      <Container>
        <ParentMenuPane
          queryId={queryId}
          menuId={data.menuId}
          outlet={outlet}
        />

        {data.menuId && (
          <SubMenuPane
            outlet={outlet}
            queryId={queryId}
            menuId={data.menuId}
            subMenuId={data.subMenuId}
          />
        )}
        {data.subMenuId && (
          <SubMenuPane
            outlet={outlet}
            queryId={queryId}
            menuId={data.subMenuId}
          />
        )}

        {data.itemMenuOpen && (
          <MenuItems
            outlet={outlet}
            itemId={data.itemId}
            menuId={deepestMenuId}
            setViewMenuItem={setViewMenuItem}
          />
        )}
      </Container>
    </Card>
  )
}

export default MenuTree
