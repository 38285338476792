import { string } from 'prop-types'
import { Tooltip } from '@blueprintjs/core'
import config from '@src/config'

const AWS_CDN_URL =
  config.awsCDNUri ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

const Favicon = ({ src, label }) => {
  return (
    <Tooltip content={label}>
      <span className="bp5-icon">
        <img src={`${AWS_CDN_URL}${src}`} height="16" width="16" />
      </span>
    </Tooltip>
  )
}

Favicon.propTypes = {
  src: string,
  label: string,
}

export default Favicon
