import { Button, Card, Classes, HTMLTable, Icon } from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import Dialog from '@components/Dialog/Dialog'
import AddOptionItems from '@components/Restaurant/Menu/modals/AddOptionItems'
import EditOptionItems from '@components/Restaurant/Menu/modals/EditOptionItems'
import OptionForm from '@components/Restaurant/Menu/modals/OptionForm'
import ADD_OPTION_ITEM from '@components/Restaurant/Menu/mutations/addOptionItem.mutation'
import ARCHIVE_OPTION from '@components/Restaurant/Menu/mutations/archiveOption.mutation'
import EDIT_OPTION from '@components/Restaurant/Menu/mutations/editOption.mutation'
import EDIT_OPTION_ITEM from '@components/Restaurant/Menu/mutations/editOptionItem.mutation'
import GET_OPTIONS from '@components/Restaurant/Menu/queries/getOptions.query'
import { validation } from '@components/Restaurant/Menu/validation/optionFormValidation'
import { isPlatformLevelUser } from '@stores/userStore'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { numberToPence, penceToPounds } from '@utils/helpers'
import { modalType } from '@utils/types'
import { FieldArray, Formik } from 'formik'
import { isEqual, size } from 'lodash'
import { arrayOf, bool, shape, string } from 'prop-types'
import { successToast } from '../../../../utils/toast'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'
import { useMutation } from '@apollo/client'

const optionHasMinRequiredItems = values => {
  if (values.optionItems && !values.newOptionItems.length) {
    const availableOptions = values.optionItems.filter(
      option => !option.soldOut
    )
    return availableOptions.length < values.minOptions
  }
}

const EditOptionModal = ({
  isOpen,
  onClose,
  option,
  restaurant,
  menuItemId,
}) => {
  const restaurantId = restaurant.id

  const [editOption] = useMutation(EDIT_OPTION, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Option successfully edited')
    },
  })

  const [addOptionItem] = useMutation(ADD_OPTION_ITEM, {
    onError: defaultErrorHandler,
    onCompleted: ({ addOptionItem }) => successToast(addOptionItem.message),
    refetchQueries: [{ query: GET_OPTIONS, variables: { restaurantId } }],
  })

  const [editOptionItem] = useMutation(EDIT_OPTION_ITEM, {
    onError: defaultErrorHandler,
    refetchQueries: [
      { query: GET_OPTIONS, variables: { restaurantId } },
      ...(menuItemId
        ? [
            {
              query: GET_ITEM_OPTIONS,
              variables: { id: menuItemId },
            },
          ]
        : []),
    ],
  })

  const [archiveOption] = useMutation(ARCHIVE_OPTION, {
    onError: defaultErrorHandler,
    onCompleted: ({ archiveOption }) => {
      successToast(archiveOption.message)
      onClose()
    },
    refetchQueries: [
      {
        query: GET_OPTIONS,
        variables: { restaurantId },
      },
      ...(menuItemId
        ? [
            {
              query: GET_ITEM_OPTIONS,
              variables: { id: menuItemId },
            },
          ]
        : []),
    ],
  })

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Option'}
      style={{ width: '80%' }}
    >
      <div className={Classes.DIALOG_BODY}>
        <Formik
          namespace="editOption"
          enableReinitialize={true}
          initialValues={{
            ...option,
            optionItems: option.optionItems.map(optionItem => ({
              ...optionItem,
              soldOut: optionItem.soldOut || false,
              price: penceToPounds(optionItem.price),
            })),
            required: option.minOptions >= 1 ? 'required' : 'optional',
            newOptionItems: [],
          }}
          validationSchema={validation}
          render={props => {
            const { values, touched, initialValues, validateForm } = props
            const { isVATregistered } = restaurant

            return (
              <form
                onSubmit={async event => {
                  event.preventDefault()
                  values.optionItems = values.optionItems.map(optionItem => {
                    if (
                      optionItem.imageImplementer &&
                      optionItem.imageImplementer.src
                    ) {
                      optionItem.image = optionItem.imageImplementer.src
                    }
                    return optionItem
                  })

                  const errors = await validateForm()
                  if (!size(errors)) {
                    const optionItems = values.optionItems.map(
                      option => option.id
                    )
                    // edits option.
                    editOption({
                      variables: {
                        ...values,
                        optionItems,
                      },
                    })

                    // checks if the options form section has been edited,
                    // if so checks which option items are different from the initial values and sends a mutation.
                    if (touched.options) {
                      for (const optionItem of values.optionItems) {
                        const initialOptionItem =
                          initialValues.optionItems.find(
                            item => item.id === optionItem.id
                          )
                        if (!isEqual(initialOptionItem, optionItem)) {
                          editOptionItem({
                            variables: {
                              ...optionItem,
                              price: numberToPence(optionItem.price),
                              vatRate: parseFloat(optionItem.vatRate) || 0,
                            },
                          })
                        }
                      }
                    }

                    // checks if there are any new options and creates them.
                    if (values.newOptionItems) {
                      for (const newOptionItem of values.newOptionItems) {
                        addOptionItem({
                          variables: {
                            ...newOptionItem,
                            price: numberToPence(newOptionItem.price),
                            vatRate: parseFloat(newOptionItem.vatRate) || 0,
                            parentId: values.id,
                            restaurantId,
                          },
                        })
                      }
                    }
                  }
                }}
              >
                <OptionForm {...props} />
                <Card className="bp5-nopad">
                  <HTMLTable className="bp5-dialog-table" interactive={true}>
                    <thead>
                      <tr>
                        <th>Option Item</th>
                        <th>Price</th>
                        <th>Sold Out</th>
                        {isPlatformLevelUser() ? <th>Photo</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="optionItems"
                        render={({ remove }) => (
                          <EditOptionItems
                            optionId={option.id}
                            items={values.optionItems}
                            remove={remove}
                            {...props}
                            isVATregistered={isVATregistered}
                            restaurantId={restaurantId}
                            menuItemId={menuItemId}
                          />
                        )}
                      />
                      <FieldArray
                        name="newOptionItems"
                        render={({ remove }) => (
                          <AddOptionItems
                            items={values.newOptionItems}
                            key={values.id}
                            id={values.id}
                            name="newOptionItems"
                            remove={remove}
                            {...props}
                            isVATregistered={isVATregistered}
                          />
                        )}
                      />
                    </tbody>
                    <tfoot>
                      <tr>
                        <FieldArray
                          name="newOptionItems"
                          render={({ push }) => (
                            <Button
                              text="Add Option Item"
                              minimal={true}
                              icon="plus"
                              intent={'primary'}
                              onClick={() =>
                                push({
                                  name: '',
                                  price: '',
                                  vatRate: 0,
                                })
                              }
                              style={{
                                margin: '6px 0 10px 10px',
                              }}
                            />
                          )}
                        />
                      </tr>
                    </tfoot>
                  </HTMLTable>
                </Card>
                {optionHasMinRequiredItems(values) ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#EC615B1A',
                      color: '#EC615B',
                      margin: '5px',
                      marginBottom: '10px',
                      padding: ' 10px 0px 0px 15px',
                      alignContent: 'center',
                    }}
                  >
                    <Icon icon="warning-sign" />
                    <p>
                      This option requires {values.minOptions} selection
                      {values.minOptions > 1 && 's'} to be available. Consider
                      marking items using this option as sold out on the main
                      menu.
                    </p>
                  </div>
                ) : null}
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <ConfirmationPopover
                    confirmationText="Are you sure you want to delete this option?"
                    remove={() =>
                      archiveOption({
                        variables: {
                          id: values.id,
                        },
                      })
                    }
                  >
                    <Button
                      text="Delete"
                      type="button"
                      minimal={true}
                      intent={'danger'}
                    />
                  </ConfirmationPopover>

                  <Button text="Save" type="submit" />
                </div>
              </form>
            )
          }}
        />
      </div>
    </Dialog>
  )
}

EditOptionModal.propTypes = {
  ...modalType,
  restaurant: shape({
    id: string.isRequired,
    isVATregistered: bool.isRequired,
  }).isRequired,
  option: shape({
    id: string.isRequired,
    name: string.isRequired,
    optionItems: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

export default EditOptionModal
