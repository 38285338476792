import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceEnableConfirmAddressAtPaymentForm = ({
  updateStatus,
  confirmAddressAtPayment,
}) => {
  return (
    <Formik initialValues={{ confirmAddressAtPayment }}>
      {({ values, handleChange }) => (
        <Switch
          label={`Confirm Address at Checkout`}
          checked={values.confirmAddressAtPayment}
          onChange={e => {
            handleChange(e)
            updateStatus({
              confirmAddressAtPayment: e.currentTarget.checked,
            })
          }}
          name="confirmAddressAtPayment"
        />
      )}
    </Formik>
  )
}

MarketplaceEnableConfirmAddressAtPaymentForm.propTypes = {
  confirmAddressAtPayment: bool,
  errors: object,
}

export default MarketplaceEnableConfirmAddressAtPaymentForm
