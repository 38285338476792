import gql from 'graphql-tag'

const GET_DELIVERY_ZONES = gql`
  query getOutletFulfilmentData($outletId: String!) {
    getOutlets(id: $outletId) {
      outlets {
        id
        active
        outletAddress {
          id
          geo
        }
        restaurant {
          id
          name
        }
        deliveryZoneCosts {
          id
          radiusMiles
          pickupNotes
          uberDirectUndeliverableAction
          deliveryZone {
            id
            name
            deliveryZoneType
            geoFence
          }
          deliveryNetwork {
            id
            name
            fixedSubsidy
            webhookStatus
            deliveryProvider
          }
          deliveryCost
        }
        marketplace {
          id
        }
      }
    }
  }
`

export default GET_DELIVERY_ZONES
