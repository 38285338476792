import { useContext } from 'react'
import ADD_CUISINE from '../mutations/addCuisine.mutation'
import Dialog from '@components/Dialog/Dialog'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { PlatformContext } from '@components/Platform/platform-context'
import { addCuisineValidation } from './validation'
import { Formik } from 'formik'
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Classes,
} from '@blueprintjs/core'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const AddCategoryModal = () => {
  const { addCategoryModalOpen, setAddCategoryModalOpen } =
    useContext(PlatformContext)

  const navigate = useNavigate()

  const [addCuisine] = useMutation(ADD_CUISINE, {
    onCompleted: ({
      addCuisine: {
        cuisine: { id, name },
      },
    }) => {
      successToast(`Successfully Added ${name}`)
      setAddCategoryModalOpen(false)
      navigate(`/platform/categories?categoryId=${id}`)
    },
    onError: defaultErrorHandler,
  })

  return (
    <Dialog
      title="Add Category"
      isOpen={addCategoryModalOpen}
      onClose={() => setAddCategoryModalOpen(false)}
    >
      <Formik
        initialValues={{ name: '' }}
        onSubmit={values => {
          addCuisine({ variables: values })
        }}
        validateOnBlur
        validationSchema={addCuisineValidation}
      >
        {({ values, handleChange, handleSubmit, errors }) => {
          return (
            <form
              onSubmit={handleSubmit}
              style={{
                padding: '20px',
                paddingBottom: '10px',
              }}
            >
              <FormGroup
                label="Name"
                labelFor="name"
                helperText={errors.name ? errors.name : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  autoFocus
                />
              </FormGroup>

              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button intent={Intent.PRIMARY} type="submit">
                  Add Category
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddCategoryModal
