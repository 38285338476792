export const ANALYTICS_COLORS = {
  blue: '#1968f6',
  grey: '#859eb2',
  white: '#FFF',
  greyForGraphs: '#D8D9DA',
  greyLine: '#D8D9DA',
  blueLine: '#0165FF',
  greenDot: '#96C31E',
  redBar: '#FFD1D5',
  redDot: '#EE0015',
  noDiscountFill: '#20698F',
  regularDiscountFill: '#2E98D1',
  memberDiscountFill: '#123B52',
  vouchersDiscountFill: '#2885B8',
}

export const PaymentMethodsMap = {
  CASH_ON_DELIVERY: '#6B0041',
  CARD_ON_DELIVERY: '#EE47AB',
  CASH_ON_COLLECTION: '#EB008D',
  CARD_ON_COLLECTION: '#6B204D',
  CARD_ON_TABLE: '#B8006E',
}

export const OrderStatusesMap = {
  PENDING: '#182026',
  'IN PROGRESS': '#1968F6',
  COMPLETE: '#0D8050',
  REJECTED: '#5E7282',
  REFUNDED: '#DB3737',
  UNAUTHORIZED: 'orange',
}

export const FulfillmentMethodsMap = {
  COLLECTION: '#006B49',
  DELIVERY: '#47EEB9',
  NETWORK: '#00EBA0',
  TABLE: '#206B53',
  COURIER: '#00B87F',
}
