import React, { createContext, useState } from 'react'

export const MembersDiscountContext = createContext({
  modalInviteMemberToDiscountOpen: false,
  setModalInviteMemberToDiscountOpen: () => {},
})

const MembersDiscountProvider = ({ children }) => {
  const [modalInviteMemberToDiscountOpen, setModalInviteMemberToDiscountOpen] =
    useState(false)

  return (
    <MembersDiscountContext.Provider
      value={{
        modalInviteMemberToDiscountOpen,
        setModalInviteMemberToDiscountOpen,
      }}
    >
      {children}
    </MembersDiscountContext.Provider>
  )
}
export default MembersDiscountProvider
