import gql from 'graphql-tag'

const ADD_CUISINE = gql`
  mutation addCuisine($name: String!) {
    addCuisine(name: $name) {
      cuisine {
        id
        name
      }
    }
  }
`

export default ADD_CUISINE
