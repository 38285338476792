import gql from 'graphql-tag'

const ADD_PAYMENT_METHOD = gql`
  mutation addBillingPaymentMethod(
    $id: ID!
    $level: BillingCustomerLevel!
    $product: BillingProductType
  ) {
    addBillingPaymentMethod(id: $id, level: $level, product: $product) {
      message
      paymentRedirectUrl
    }
  }
`

export default ADD_PAYMENT_METHOD
