import React from 'react'
import { InputGroup, Button } from '@blueprintjs/core'
import { useSearchQueryParam } from './useSearchQueryParam'

/**
 * Search - Returns an input and reset button, storing the entered search value in query params
 *
 * @param {Pick<import('@blueprintjs/core').InputGroupProps2, 'autoFocus' | 'placeholder' | 'disabled' | 'style'>} props
 */
export const Search = ({ autoFocus, placeholder, disabled, ...otherProps }) => {
  const { searchValue, onChangeSearch, resetSearch } = useSearchQueryParam()

  return (
    <InputGroup
      autoFocus={autoFocus}
      leftIcon="search"
      placeholder={placeholder}
      disabled={disabled}
      onChange={e =>
        onChangeSearch(e.target.value ? e.target.value : undefined, 'replaceIn')
      }
      value={searchValue}
      rightElement={
        <Button
          style={
            searchValue.length
              ? {}
              : {
                  visibility: 'hidden',
                }
          }
          disabled={disabled || searchValue === ''}
          icon="small-cross"
          minimal
          onClick={resetSearch}
        />
      }
      {...otherProps}
    />
  )
}
