/**
 * Returns an array of objects representing the last `months` number of months.
 * Each object contains the `month` (0-11) and the `year`.
 *
 * @param {number} months - The number of months to go back.
 * @returns {{ month: number, year: number }[]} An array of objects, each containing the `month` and `year`.
 *
 * @example
 * // Get the last 12 months
 * const last12Months = getLastNMonths(12);
 * // Output: [{ month: 8, year: 2024 }, { month: 7, year: 2024 }, ...]
 */
export function getLastNMonths(
  months: number
): { month: number; year: number }[] {
  const result: { month: number; year: number }[] = []
  const currentDate = new Date()

  for (let i = 0; i < months; i++) {
    const month = currentDate.getMonth() // JavaScript months (0-11)
    const year = currentDate.getFullYear()
    result.push({ month, year })

    // Move to the previous month
    currentDate.setMonth(currentDate.getMonth() - 1)
  }

  return result
}
