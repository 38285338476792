import React, { Fragment } from 'react'
import APICredentialsFormGroups from './APICredentialsFormGroups'
import CostInput from './CostInput'
import RadiusInput from './RadiusInput'
import PickupNotesInput from './PickupNotesInput'
import SubsidyInput from './SubsidyInput'

const StuartFormGroup = formikProps => {
  const isOutletStuartAccountRequired =
    formikProps.values.deliveryZone.deliveryNetworkType === 'MAPPING'
  return (
    <Fragment>
      <RadiusInput {...formikProps} />
      <PickupNotesInput {...formikProps} />
      {isOutletStuartAccountRequired && (
        <Fragment>
          <SubsidyInput {...formikProps} />
          <APICredentialsFormGroups {...formikProps} />
        </Fragment>
      )}
      <CostInput {...formikProps} />
    </Fragment>
  )
}
StuartFormGroup.propTypes = {}

export default StuartFormGroup
