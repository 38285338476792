import gql from 'graphql-tag'
import { MENU_ITEM_FIELDS_AND_OPTION_ID_FRAGMENT } from '../fragments/menuItem.fragment'

export const ADD_MENU_ITEM = gql`
  ${MENU_ITEM_FIELDS_AND_OPTION_ID_FRAGMENT}

  mutation menuImportAddMenuItem(
    $name: String!
    $description: String
    $price: Int!
    $menuItemGroupIds: [String]!
    $restaurantId: String!
    $minimumPurchasePrice: Int
    $costPrice: Int
    $isVegan: Boolean
    $isVegetarian: Boolean
    $isDairyFree: Boolean
    $isGlutenFree: Boolean
    $isKeto: Boolean!
    $spiceLevel: Int!
    $ageRestricted: Boolean
    $optionIds: [String]
    $SKU: String
    $UPC: String
  ) {
    addMenuItem(
      name: $name
      description: $description
      price: $price
      menuItemGroupIds: $menuItemGroupIds
      restaurantId: $restaurantId
      minimumPurchasePrice: $minimumPurchasePrice
      costPrice: $costPrice
      isVegan: $isVegan
      isVegetarian: $isVegetarian
      isDairyFree: $isDairyFree
      isGlutenFree: $isGlutenFree
      isKeto: $isKeto
      spiceLevel: $spiceLevel
      ageRestricted: $ageRestricted
      optionIds: $optionIds
      SKU: $SKU
      UPC: $UPC
    ) {
      message
      menuItem {
        ...menuItemFieldsAndOptionId
      }
    }
  }
`
