import { Button, Callout, Card, H4 } from '@blueprintjs/core'
import { Fragment, useCallback, useContext } from 'react'
// import * as Sentry from '@sentry/react'
import { PageLayoutContext } from '../PageLayout/PageLayout'
import Header from '../Header/Header'
import Nav from '../Nav/Nav'
import { useNavigate } from 'react-router-dom'
import config from '@src/config'

const FallbackComponent = ({ error, componentStack, resetError }) => {
  const { product, button, permissions, tabs, dark } =
    useContext(PageLayoutContext)
  const navigate = useNavigate()

  const backToHome = useCallback(() => {
    resetError()

    navigate('/')
  }, [resetError])

  const reloadPage = useCallback(() => {
    resetError()
  }, [resetError])

  return (
    <Fragment>
      <Header
        product={product}
        button={button}
        permissions={permissions}
        tabs={tabs}
      />
      <Nav dark={dark} />

      <main>
        <section className="mainBodyContainer">
          <Callout icon="error" intent="danger" title="Something went wrong...">
            <p>
              Unfortunately an application error has occurred, this issue has
              been logged for investigation.
            </p>
            <p>
              If prompted, please add more information to help us recreate the
              issue.
            </p>
            <Button icon="refresh" onClick={reloadPage}>
              Retry
            </Button>{' '}
            <Button icon="refresh" intent="danger" minimal onClick={backToHome}>
              Reload Application
            </Button>
          </Callout>

          <br />

          {!config.isProd ? (
            <Card>
              <H4>Message</H4>
              <pre>{error.message}</pre>
              <br />
              <H4>Stacktrace</H4>
              <pre className="bp5-monospace-text bp5-running-text">
                {error.stack}
              </pre>
              <br />
              <H4>Component Stack</H4>
              <pre className="bp5-monospace-text bp5-running-text">
                {componentStack}
              </pre>
            </Card>
          ) : null}
        </section>
      </main>
    </Fragment>
  )
}

export const ErrorBoundary = ({ children }) => (
  // <Sentry.ErrorBoundary
  //   showDialog
  //   onError={console.error}
  //   fallback={props => <FallbackComponent {...props} />}
  // >
  <>{children}</>
)
// </Sentry.ErrorBoundary>

export default ErrorBoundary
