import { useLocation } from 'react-router-dom'
import split from 'lodash/split'
import takeRight from 'lodash/takeRight'
import { without } from 'lodash'
import { useEffect, useState } from 'react'

const useBreadcrumb = (crumbNo = 3) => {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  const storePath = path => {
    // certain paths go too deep and maintaining the breadcrumb is too
    // complicated / not useful at that depth. Adding a path to the
    // bellow array will stop the breadcrumb at that path.
    // eg 'restaurants/xxxxxxIDxxxxx/menu/tree' and
    // 'restaurants/xxxxxxIDxxxxx/menu/list'
    // the extra breadcrumb of list / tree is not useful to the user
    // and it only changes the view visually.
    // made this accept multiple paths as it may happen again
    const limitDepthPaths = ['/menu', '/stock']
    const containsLimit = limitDepthPaths.find(limitDepthPath =>
      path.includes(limitDepthPath)
    )

    if (containsLimit) {
      setCurrentPath(path.split(containsLimit)[0] + containsLimit)
    } else {
      setCurrentPath(path)
    }
  }

  const returnCrumbArray = () => {
    // Split on /, remove empty results and return.
    return without(takeRight(split(currentPath, '/'), crumbNo), '')
  }

  useEffect(() => {
    storePath(location.pathname)
  }, [location.pathname])

  return returnCrumbArray()
}

export { useBreadcrumb }
