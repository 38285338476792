import gql from 'graphql-tag'

export const GET_OUTLET_BY_OTTER_STORE_ID = gql`
  query getOutletByOtterStoreId($storeId: String!) {
    getOutletByOtterStoreId(storeId: $storeId) {
      id
      name
    }
  }
`
