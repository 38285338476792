import gql from 'graphql-tag'

const GET_OUTLET_IDS = gql`
  query getOutletsByPinpointApplication($marketplaceId: ID!) {
    getOutlets(marketplaceIds: [$marketplaceId]) {
      outlets {
        id
        name
        isOpen
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLET_IDS
