import gql from 'graphql-tag'

const EDIT_OPTIONS_ORDER = gql`
  mutation editOptionsOrder($input: [IPositionArgs!]!) {
    editOptionsOrder(input: $input) {
      options {
        id
        position
      }
    }
  }
`

export default EDIT_OPTIONS_ORDER
