import gql from 'graphql-tag'

const GET_CAMPAIGNS = gql`
  query getCampaigns($marketplaceId: ID!, $skip: Int, $first: Int) {
    getCampaigns(marketplaceId: $marketplaceId, skip: $skip, first: $first) {
      count
      totalCount
      campaigns {
        id
        status
        type
        name
        marketplaceId
        timeConfig
        startDateTime
        emailChannel
        deliveries
        email {
          id
          templateType
          emailSubject
          emailPreviewText
          emailContent
        }
        pushChannel
        push {
          action
          outletId
          title
          body
          menuItemId
        }
        smsChannel
        sms {
          body
        }
        createdAt
        updatedAt
        description
        emailOpenRate
        pushOpenRate
        clicks
        monetisation {
          total
        }
        bounceRate
      }
    }
  }
`

export default GET_CAMPAIGNS
