import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { string, shape } from 'prop-types'
import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { validation } from '@components/TableForm/validation'
import TableForm from '@components/TableForm/TableForm'
import EDIT_TABLE_FRIENDLY_NAME from '@components/TableModal/mutations/editTableFriendlyName.mutation'
import GET_OUTLET_TABLES from '../Outlet/Tables/queries/getOutletTables.query'
import { useMutation } from '@apollo/client'

const EditTableFriendlyNameModal = ({
  modalOpen,
  modalClose,
  table,
  outletId,
}) => {
  const [editTableFriendlyName] = useMutation(EDIT_TABLE_FRIENDLY_NAME, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Table name / number updated')
      modalClose()
    },
    refetchQueries: [{ query: GET_OUTLET_TABLES, variables: { outletId } }],
  })

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Edit table name / number"
    >
      <div className={Classes.DIALOG_BODY}>
        <TableForm
          onSubmit={values =>
            editTableFriendlyName({
              variables: {
                ...values,
              },
            })
          }
          initialValues={{
            tableId: table.id,
            friendlyName: table.friendlyName,
          }}
          validationSchema={validation}
        />
      </div>
    </Dialog>
  )
}
EditTableFriendlyNameModal.propTypes = {
  ...modalType,
  outletId: string.isRequired,
  table: shape({
    id: string.isRequired,
    friendlyName: string.isRequired,
  }),
}

export default EditTableFriendlyNameModal
