import * as yup from 'yup'

export const outletDeliveryValidation = yup.object().shape({
  id: yup.string(),
  pickupNotes: yup.string().nullable(),
  uberDirectUndeliverableAction: yup
    .string()
    .nullable()
    .test(
      'uberDirectUndeliverableAction-required',
      'Uber Direct Undeliverable Action is required',
      (value, context) => {
        const { deliveryZone } = context.parent
        return deliveryZone?.deliveryZoneType === 'UBER_DIRECT' ? !!value : true
      }
    ),
  radiusMiles: yup
    .number()
    .nullable()
    .test(
      'radiusMiles-range',
      'Radius Miles must be between 0.1 and 100',
      (value, context) => {
        const { deliveryZone } = context.parent
        if (deliveryZone?.deliveryZoneType === 'UBER_DIRECT') {
          return value >= 0.1 && value <= 100
        }
        return true
      }
    ),
  deliveryZone: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
      deliveryZoneType: yup.string().required(),
      geoFence: yup
        .object()
        .shape({
          coordinates: yup.array(),
          type: yup.string(),
          center: yup.mixed(),
          zoom: yup.number(),
        })
        .nullable(),
    })
    .nullable(),
  cost: yup
    .string()
    .nullable()
    .test('cost-required', 'Cost is required', (value, context) => {
      const { deliveryZone } = context.parent
      return deliveryZone?.deliveryZoneType === 'UBER_DIRECT' ? !!value : true
    }),
})
