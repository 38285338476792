import { Button, Popover, Position } from '@blueprintjs/core'
import { add, isFuture, sub, format } from 'date-fns'
import React, { Fragment } from 'react'
import { YEAR_FORMAT } from '../../../utils/datetime'
import { min } from 'lodash'
import { DateRangePicker } from '@components/Toolbar/DateRangeFilter/DateRangePicker'

const QuarterSelector = ({
  startOfQuarterDate,
  startOfQuarterDateToDisplay,
  onChangeStartOfQuarterDate,
  endOfQuarterDate,
  endOfQuarterDateToDisplay,
  minimal = false,
  disabled = false,
}) => (
  <Fragment>
    <Button
      rightIcon="caret-left"
      disabled={disabled}
      minimal={minimal}
      onClick={() => {
        onChangeStartOfQuarterDate(sub(startOfQuarterDate, { months: 3 }))
      }}
    />
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <DateRangePicker
          value={[startOfQuarterDate, endOfQuarterDate]}
          singleMonthOnly
          onChange={([selectedDate]) => {
            if (selectedDate) {
              onChangeStartOfQuarterDate(selectedDate)
            }
          }}
        />
      }
    >
      <Button
        disabled={disabled}
        minimal={minimal}
        rightIcon="double-caret-vertical"
      >
        {format(startOfQuarterDateToDisplay, YEAR_FORMAT)}
        {'  '}
        <span className="bp5-text-disabled">to</span>
        {'  '}
        {format(endOfQuarterDateToDisplay, YEAR_FORMAT)}
      </Button>
    </Popover>
    <Button
      rightIcon="caret-right"
      minimal={minimal}
      onClick={() => {
        onChangeStartOfQuarterDate(
          min([add(startOfQuarterDate, { months: 3 }), new Date()])
        )
      }}
      disabled={disabled || isFuture(add(startOfQuarterDate, { months: 3 }))}
    />
  </Fragment>
)

export default QuarterSelector
