import gql from 'graphql-tag'

const ALLOW_EXTENDED_PREORDERS = gql`
  mutation allowExtendedPreorders(
    $id: String!
    $allowExtendedPreorders: Boolean!
  ) {
    editMarketplace(id: $id, allowExtendedPreorders: $allowExtendedPreorders) {
      message
      marketplace {
        id
        allowExtendedPreorders
      }
    }
  }
`

export default ALLOW_EXTENDED_PREORDERS
