import gql from 'graphql-tag'

const CREATE_CAMPAIGN = gql`
  mutation addCampaign(
    $name: String!
    $type: String!
    $segment: String!
    $marketplaceId: ID!
    $timeConfig: String!
    $startDateTime: String
    $emailChannel: Boolean!
    $emailSubject: String
    $emailPreviewText: String
    $emailContent: [Json!]!
    $pushChannel: Boolean!
    $push: CampaignPushInput
    $smsChannel: Boolean!
    $sms: CampaignSMSInput
  ) {
    addCampaign(
      name: $name
      type: $type
      segment: $segment
      marketplaceId: $marketplaceId
      timeConfig: $timeConfig
      startDateTime: $startDateTime
      emailChannel: $emailChannel
      emailSubject: $emailSubject
      emailPreviewText: $emailPreviewText
      emailContent: $emailContent
      pushChannel: $pushChannel
      push: $push
      smsChannel: $smsChannel
      sms: $sms
    ) {
      campaign {
        id
        status
        type
        name
        segment {
          id
          name
        }
        marketplaceId
        timeConfig
        startDateTime
        emailChannel
        email {
          id
          templateType
          emailSubject
          emailPreviewText
          emailContent
        }
        pushChannel
        push {
          action
          outletId
          title
          body
        }
        smsChannel
        sms {
          body
        }
        createdAt
        updatedAt
        description
      }
    }
  }
`

export default CREATE_CAMPAIGN
