import gql from 'graphql-tag'

const EDIT_OUTLET_ENABLE_ORDER_TO_TABLE = gql`
  mutation editOutletEnableOrderToTable(
    $outletId: String!
    $isOrderToTableEnabled: Boolean!
  ) {
    editOutlet(id: $outletId, isOrderToTableEnabled: $isOrderToTableEnabled) {
      message
      outlet {
        id
        isOrderToTableEnabled
      }
    }
  }
`
export default EDIT_OUTLET_ENABLE_ORDER_TO_TABLE
