import React, { Fragment } from 'react'
import {
  Card,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  PopoverInteractionKind,
  Position,
  Classes,
  H5,
  Popover,
  Icon,
  Switch,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { canPerformAction } from '@stores/userStore'
import TOGGLE_HYGIENE_RATING from './mutations/editHygieneRating'
import { errorToast, successToast } from '@utils/toast'
import LinkTag from '@components/LinkTag/LinkTag'
import { useMutation } from '@apollo/client'

export const HygieneRating = ({ outlet }) => {
  const [toggleHygieneRating] = useMutation(TOGGLE_HYGIENE_RATING, {
    onError: defaultErrorHandler,
    onCompleted: () =>
      successToast('Successfully saved hygiene rating information'),
  })

  return Boolean(outlet) && canPerformAction('toggleHygieneRating') ? (
    <Card>
      <H5>
        Hygiene Ratings <LinkTag type="web-v2" />
      </H5>

      <Formik
        initialValues={{
          enableHygieneRating: !!outlet.enableHygieneRating,
          hygieneRatingId: outlet.hygieneRatingId,
        }}
        onSubmit={values => {
          if (values.enableHygieneRating && !values.hygieneRatingId.length) {
            errorToast('Cannot save without a Hygiene Rating Business ID')
            return
          }
          toggleHygieneRating({
            variables: {
              outletId: outlet.id,
              enableHygieneRating: Boolean(
                !!values.enableHygieneRating ||
                  values.enableHygieneRating[0] === 'on'
              ),
              hygieneRatingId: values.hygieneRatingId
                ? parseInt(values.hygieneRatingId)
                : undefined,
            },
          })
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Switch
              checked={values.enableHygieneRating}
              onChange={handleChange}
              name="enableHygieneRating"
            >
              Enable Hygiene Rating{' '}
              <Popover
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.RIGHT}
                popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} wider-popover`}
                className="inline-block"
                content={
                  <div>
                    <H5>Business Hygiene Ratings</H5>
                    <p>
                      Companies that have registered a food business will be
                      provided with a{' '}
                      <strong>Food Hygiene Rating Business ID</strong>, which is
                      used on the Food Hygiene Rating website to navigate
                      directly to the outlet's Hygiene Rating. <br />
                    </p>
                    <p>
                      Providing this ID will allow your customers to see your
                      current Hygiene Rating.
                    </p>
                    <p>
                      To find your Food Hygiene Rating Business ID, navigate to{' '}
                      <a
                        href="https://ratings.food.gov.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ratings.food.gov.uk
                      </a>
                      , and enter your business name and address information.
                    </p>
                    <p>
                      Select your business from the list of results. On the URL,
                      please copy the numbers in the URL i.e. the URL is
                      <i>
                        ratings.food.gov.uk/business/
                        <strong>987654</strong>/a-example-business
                      </i>
                      copy the ‘987654’ paste this into the box and click save.
                    </p>
                  </div>
                }
              >
                <Icon icon="help" color="#ced9e0" />
              </Popover>
            </Switch>

            {values.enableHygieneRating && (
              <Fragment>
                <FormGroup
                  label={<Fragment>Hygiene Rating Business ID</Fragment>}
                  labelFor="hygieneRatingId"
                  helperText={
                    errors.hygieneRatingId ? errors.hygieneRatingId : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="hygieneRatingId"
                    id="hygieneRatingId"
                    type="text"
                    onChange={handleChange}
                    value={values.hygieneRatingId}
                  />
                </FormGroup>
              </Fragment>
            )}

            <br />

            <div className="bp-card-footer-actions">
              <Button text="Save" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </Card>
  ) : null
}
