import { Fragment } from 'react'
import { successToast } from '@utils/toast'
import {
  Card,
  Button,
  Classes,
  Switch,
  HTMLSelect,
  H5,
  FormGroup,
  Intent,
  PopoverInteractionKind,
  Position,
  Icon,
  Popover,
  Tag,
  Label,
  NonIdealState,
  AnchorButton,
} from '@blueprintjs/core'
import { Row } from '@components/_FlexGrid'

import allowSearchPreview from '@assets/app-previews/allow-search-preview.png'
import allowReorderPreview from '@assets/app-previews/allow-reorder-preview.png'
import before from '@assets/app-previews/before.png'
import after from '@assets/app-previews/after.png'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import first from 'lodash/first'
import { Formik } from 'formik'
// Mutations & Queries
import GET_MARKETPLACE_APP_CONFIG from './queries/getMarketplaceAppConfig.query'
import EDIT_MARKETPLACE_APP_FEATURES from './mutations/editMarketplaceAppFeatures.mutation'
import EDIT_MIN_APP_VERSION from './mutations/editMinAppVersion.mutation'
import EDIT_APP_UPDATE_PROMPT from '@components/Marketplace/Details/mutations/enableUpdatePrompt.mutation'
import MarketplaceForceAppUpdatesForm from '@components/MarketplaceForm/MarketplaceForceAppUpdatesForm'

import { isPlatformLevelUser } from '@stores/userStore'
import { useContext } from 'react'
import appPageContext from './appPageContext'
import DisplayKioskConfig from './DisplayKioskConfig'
import AppVersionDropdownContainer from '../Toolbar/AppVersionDropdownContainer'
import ImageUpload from '../ImageUpload/ImageUpload'
import { useMutation } from '@apollo/client'
import { GetMarketplaceAppConfigQuery } from './queries/getMarketplaceAppConfig.query.generated'

const DisplayAppConfig = ({ marketplaceId }) => {
  const { setMarketplaceId } = useContext(appPageContext)

  const [editMinAppVersionSupported] = useMutation(EDIT_MIN_APP_VERSION, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMinAppVersionSupported }) =>
      successToast(editMinAppVersionSupported.message),
  })

  const [editMarketplaceAppFeatures] = useMutation(
    EDIT_MARKETPLACE_APP_FEATURES,
    {
      onError: defaultErrorHandler,
      onCompleted: ({ editMarketplaceAppFeatures }) =>
        successToast(editMarketplaceAppFeatures.message),
    }
  )

  const [editAppUpdatePrompt] = useMutation(EDIT_APP_UPDATE_PROMPT, {
    onError: defaultErrorHandler,
    onCompleted: ({ editAppUpdatePrompt }) =>
      successToast(editAppUpdatePrompt.message),
  })

  return (
    <Query<GetMarketplaceAppConfigQuery>
      query={GET_MARKETPLACE_APP_CONFIG}
      variables={{ marketplaceId }}
      fetchPolicy={'network-only'}
      loaderTitle={'Loading App Config'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return <NonIdealState title="No Marketplace Found" />
        }

        setMarketplaceId(marketplace.id)

        return (
          <Fragment>
            <Card>
              <Row>
                <H5>Minimum App Version</H5>
                <Popover
                  interactionKind={PopoverInteractionKind.HOVER}
                  position={Position.RIGHT_TOP}
                  popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                  content={
                    <Fragment>
                      <H5>Minimum App Version</H5>
                      <p>
                        This is the earliest app build that is compatible with
                        current Apple Store and Google Play requirements.
                      </p>
                    </Fragment>
                  }
                >
                  <Icon
                    icon="help"
                    color="#ced9e0"
                    style={{ marginLeft: '8px', paddingTop: '1px' }}
                  />
                </Popover>
              </Row>

              <Formik
                initialValues={marketplace}
                onSubmit={values =>
                  editMinAppVersionSupported({
                    variables: values,
                  })
                }
              >
                {({ values, handleChange, handleSubmit }) => (
                  <section>
                    <FormGroup>
                      <AppVersionDropdownContainer
                        onChange={handleChange}
                        name={'minAppVersionSupported'}
                        disabled={!isPlatformLevelUser()}
                        value={values.minAppVersionSupported}
                      />
                    </FormGroup>
                    {isPlatformLevelUser() && (
                      <div className="bp-card-footer-actions">
                        <Button text="Save" onClick={() => handleSubmit()} />
                      </div>
                    )}
                  </section>
                )}
              </Formik>
            </Card>
            <Card>
              <H5>Settings</H5>

              <Formik
                initialValues={marketplace}
                onSubmit={values =>
                  editMarketplaceAppFeatures({
                    variables: values,
                  })
                }
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  status,
                  setStatus,
                }) => (
                  <section>
                    <Row>
                      <Switch
                        label={'Enable Reorder'}
                        checked={values.allowReorder}
                        onChange={handleChange}
                        style={{ width: '90%' }}
                        name="allowReorder"
                      />
                      <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.RIGHT_TOP}
                        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                        content={
                          <Fragment>
                            <H5>Reorder Enabled Preview</H5>
                            <p>
                              Display the Reorder button. Can be found on the
                              details screen of an order.
                            </p>
                            <div>
                              <img
                                style={{ padding: 24, height: 600 }}
                                src={allowReorderPreview}
                              />
                            </div>
                          </Fragment>
                        }
                      >
                        <Icon icon="help" color="#ced9e0" />
                      </Popover>
                    </Row>
                    <Row>
                      <Switch
                        label={'Enable Search'}
                        checked={values.allowSearch}
                        onChange={handleChange}
                        style={{ width: '90%' }}
                        name="allowSearch"
                      />
                      <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.RIGHT_TOP}
                        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                        content={
                          <Fragment>
                            <H5>Search Enabled Preview</H5>
                            <p>
                              <b>Search cannot be disabled on v5.0+</b>
                            </p>
                            <p>
                              Display the Search bar. Allowing searching of
                              products, categories and businesses.
                            </p>
                            <div>
                              <img
                                style={{ padding: 24, height: 600 }}
                                src={allowSearchPreview}
                              />
                            </div>
                          </Fragment>
                        }
                      >
                        <Icon icon="help" color="#ced9e0" />
                      </Popover>
                    </Row>

                    <Row>
                      <Switch
                        label={'Hide Categories Bar'}
                        style={{ width: '90%' }}
                        checked={values.hideCategoriesListOnMobile}
                        onChange={handleChange}
                        name="hideCategoriesListOnMobile"
                      />
                      <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.RIGHT_TOP}
                        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                        content={
                          <Fragment>
                            <H5>
                              Categories Bar{' '}
                              <Tag minimal round>
                                V3.12.0+
                              </Tag>
                            </H5>

                            <p>
                              This will hide the categories list component on
                              the mobile app. The categories will still be
                              searchable.
                            </p>
                            <div>
                              <b>Visible </b>
                              <img style={{ width: '100%' }} src={before} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                              <b>Hidden</b>
                              <img style={{ width: '100%' }} src={after} />
                            </div>
                          </Fragment>
                        }
                      >
                        <Icon icon="help" color="#ced9e0" />
                      </Popover>
                    </Row>
                    <br />
                    <Row>
                      <Label>Menu Item Sheet Display (5.0+)</Label>
                      <Switch
                        label={'Option Image'}
                        checked={values.allowMenuItemOptionImageV5}
                        onChange={handleChange}
                        style={{ width: '90%' }}
                        name="allowMenuItemOptionImageV5"
                      />
                    </Row>
                    <Row>
                      <Switch
                        label={'Main Image'}
                        checked={values.allowMenuItemMainImageV5}
                        onChange={handleChange}
                        style={{ width: '90%' }}
                        name="allowMenuItemMainImageV5"
                      />
                    </Row>
                    <br />
                    <FormGroup
                      label="Menu Item Option Picker Style"
                      labelFor="optionsListStyle"
                      intent={Intent.DANGER}
                    >
                      <HTMLSelect
                        style={{ width: '300px' }}
                        name="optionsListStyle"
                        options={[
                          { label: 'List', value: 'LIST' },
                          { label: 'Paginated', value: 'WIZARD' },
                        ]}
                        value={values.optionsListStyle}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Menu Group Display (5.0+)"
                      labelFor="menuListGroupStyle"
                      intent={Intent.DANGER}
                    >
                      <HTMLSelect
                        style={{ width: '300px' }}
                        name="menuListGroupStyle"
                        options={[
                          { label: 'List', value: 'LIST' },
                          {
                            label: 'Image Grid (v5+)',
                            value: 'IMAGE_GRID',
                          },
                        ]}
                        value={values.menuListGroupStyle}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Menu Items Display"
                      labelFor="menuListItemStyle"
                      intent={Intent.DANGER}
                    >
                      <HTMLSelect
                        style={{ width: '300px' }}
                        name="menuListItemStyle"
                        options={[
                          { label: 'List', value: 'LIST' },
                          { label: 'Image List', value: 'IMAGE_LIST' },
                          {
                            label: 'Image Grid (v5+)',
                            value: 'IMAGE_GRID',
                          },
                        ]}
                        value={values.menuListItemStyle}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <ImageUpload
                      values={values}
                      setFieldValue={setFieldValue}
                      imageName="appServiceComponentIcon"
                      imageLabel="Service Component Icon"
                      status={status}
                      setStatus={setStatus}
                    />
                    <div className="bp-card-footer-actions">
                      <Button text="Save" onClick={() => handleSubmit()} />
                      <AnchorButton
                        text="Help"
                        href="https://support.redbox.systems/docs/configuring-apps-mobile-v5-0"
                        target="_blank"
                        rel="noopener noreferrer"
                        rightIcon="help"
                        intent={Intent.PRIMARY}
                        minimal
                      />
                    </div>
                  </section>
                )}
              </Formik>
            </Card>
            <DisplayKioskConfig marketplace={marketplace} />
            <Card>
              <H5>Update Prompt</H5>

              <MarketplaceForceAppUpdatesForm
                initialValues={{
                  id: marketplace.id,
                  promptForUpdates: marketplace.promptForUpdates,
                  androidVersion: marketplace.androidVersion,
                  iosVersion: marketplace.iosVersion,
                }}
                onSubmit={values =>
                  editAppUpdatePrompt({
                    variables: values,
                  })
                }
              />
            </Card>
          </Fragment>
        )
      }}
    </Query>
  )
}

export default DisplayAppConfig
