import { Fragment } from 'react'
import Query from '@components/Query/Query'
import { Button, H4, RadioGroup } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { string, func, bool } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { times } from 'lodash'

import DELAY_NETWORK_ORDER from './mutations/delayNetworkOrder.mutation'
import GET_ORDER from './Order/queries/getOrder.query'

import moment from 'moment'
import { useMutation } from '@apollo/client'

const QUARTER_OF_HOUR = 15

const DelayNetworkOrderModal = ({
  modalOpen = false,
  modalClose,
  orderId,
  refetchQueries = [],
}) => {
  const [delayNetworkOrder] = useMutation(DELAY_NETWORK_ORDER, {
    refetchQueries: [
      ...refetchQueries,
      {
        query: GET_ORDER,
        variables: {
          id: orderId,
        },
      },
    ],
    onError: error => {
      defaultErrorHandler(error)
      modalClose()
    },
    onCompleted: ({ delayNetworkOrder: { message } }) => {
      successToast(message)
      modalClose()
    },
  })

  return (
    <Dialog
      title="Add Time"
      isCloseButtonShown={false}
      isOpen={modalOpen}
      data-test-id="delay-order-modal"
    >
      <Query query={GET_ORDER} variables={{ id: orderId }}>
        {({ order }) => {
          let initialValues = {
            id: orderId,
            newCompletionDateTime: order.estimatedCompletionTime,
            selectedDeliveryWindow: order.selectedDeliveryWindow,
          }

          return (
            <Formik
              initialValues={initialValues}
              onSubmit={values => {
                delayNetworkOrder({
                  variables: values,
                })
              }}
            >
              {props => {
                const { values, handleSubmit, handleChange, isSubmitting } =
                  props

                const radioTimeSlots = []

                const orderTime = moment(order.estimatedCompletionTime)
                const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

                radioTimeSlots.push({
                  label: `No Change`,
                  value: order.estimatedCompletionTime,
                })
                const windowLength = moment(
                  order.selectedDeliveryWindow.end
                ).diff(moment(order.selectedDeliveryWindow.start), 'minutes')
                times(4, index => {
                  const addMinutes = (index + 1) * QUARTER_OF_HOUR
                  const newOrderTime = orderTime
                    .clone()
                    .add(addMinutes, 'minutes')
                  radioTimeSlots.push({
                    label: `+${addMinutes} Minutes (${newOrderTime
                      .clone()
                      .add(windowLength, 'minutes')
                      .format('HH:mm')})`,
                    value: newOrderTime.utc().format(formatString),
                  })
                })

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="bp5-dialog-body">
                      {order.fulfillmentMethod !== 'TABLE' ? (
                        <Fragment>
                          <H4>Need more time?</H4>
                          <RadioGroup
                            label="You can move this order forwards, we'll let the customer know."
                            name="newCompletionDateTime"
                            onChange={handleChange}
                            options={radioTimeSlots}
                            selectedValue={values.newCompletionDateTime}
                          />
                        </Fragment>
                      ) : null}
                    </div>
                    <div className="bp5-dialog-footer">
                      <div className="bp5-dialog-footer-actions">
                        <Button
                          minimal
                          onClick={() => modalClose(false)}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          data-test-id="delay-order-confirm-button"
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
          )
        }}
      </Query>
    </Dialog>
  )
}
DelayNetworkOrderModal.propTypes = {
  modalClose: func,
  modalOpen: bool,
  orderId: string,
}

export default DelayNetworkOrderModal
