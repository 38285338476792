import gql from 'graphql-tag'

const ADD_MARKETPLACE_MESSAGE_TEMPLATE = gql`
  mutation addMarketplaceMessageTemplate(
    $marketplaceId: String!
    $templateType: String!
    $emailSubject: String!
    $emailPreviewText: String!
    $emailContent: [Json!]!
    $pushTitle: String!
    $pushBody: String!
  ) {
    addMarketplaceMessageTemplate(
      marketplaceId: $marketplaceId
      templateType: $templateType
      emailSubject: $emailSubject
      emailPreviewText: $emailPreviewText
      emailContent: $emailContent
      pushBody: $pushBody
      pushTitle: $pushTitle
    ) {
      message
      messageTemplate {
        id
        templateType
        emailSubject
        emailPreviewText
        emailContent
        pushTitle
        pushBody
      }
    }
  }
`

export default ADD_MARKETPLACE_MESSAGE_TEMPLATE
