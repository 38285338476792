import React, { Fragment, useContext, useMemo, useState } from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { BusinessAnalyticsContext } from './BusinessAnalyticsContext'
import Query from '@components/Query/Query'
import CUSTOMER_NAMES from './queries/customerNames.query'
import get from 'lodash/get'
import { StringParam, useQueryParam } from 'use-query-params'

const CustomersFilter = ({ minimal }) => {
  const [customerId = 'All', onChangeCustomerId] = useQueryParam(
    'customerId',
    StringParam
  )
  const { customers } = useContext(BusinessAnalyticsContext)
  const [customersWithNames, setCustomersWithNames] = useState(null)

  const customersMap = useMemo(() => {
    return get(customersWithNames, 'customers')
      ? customersWithNames.customers.reduce((acc, customer) => {
          acc[customer.id] = `${customer.firstName} ${customer.lastName}`
          return acc
        }, {})
      : []
  }, [customersWithNames])

  const customerRenderer = (customer, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        key={customer}
        onClick={handleClick}
        multiline={true}
        text={get(customersMap, customer, customer)}
        icon={customer === customerId ? 'tick' : 'blank'}
      />
    )
  }

  return (
    <Fragment>
      <Query query={CUSTOMER_NAMES} variables={{ customerIds: customers }}>
        {({ customers }) => {
          if (customers) {
            setCustomersWithNames(customers)
          }
          return null
        }}
      </Query>
      <Select
        filterable
        activeItem={customerId}
        items={['All', ...customers]}
        itemRenderer={customerRenderer}
        onItemSelect={item => {
          onChangeCustomerId(item)
        }}
        itemListPredicate={(query, items) =>
          items.filter(item =>
            get(customersMap, item, 'All').toLowerCase().includes(query)
          )
        }
      >
        <Button text={'Filter by Customer'} icon="filter" minimal={minimal} />
      </Select>
    </Fragment>
  )
}

export default CustomersFilter
