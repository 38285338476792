import { NonIdealState } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'

const FORMAT_TIME = 'HH:mm'
const ReportsRestricted = () => (
  <NonIdealState
    icon="warning-sign"
    title="Reports Currently Restricted"
    description={`During peak times restrictions apply to running reports, please retry ${moment().to(
      moment('20:00', FORMAT_TIME)
    )}.`}
  />
)

export default ReportsRestricted
