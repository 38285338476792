import gql from 'graphql-tag'

const GET_ALLOWED_CATEGORIES = gql`
  query getAllowedCategories($marketplaceId: ID!) {
    getAllowedCategories(marketplaceId: $marketplaceId) {
      id
      name
      emoji
      imageSrc
    }
  }
`

export default GET_ALLOWED_CATEGORIES
