import gql from 'graphql-tag'

const DELETE_SEGMENT = gql`
  mutation deleteBusinessSegment($id: String!) {
    deleteBusinessSegment(id: $id) {
      message
    }
  }
`

export default DELETE_SEGMENT
