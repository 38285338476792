import gql from 'graphql-tag'
import { MENU_ITEM_GROUP_FRAGMENT } from '../fragments/menuItemGroup.fragment'

export const ADD_MENU_ITEM_GROUP = gql`
  mutation menuImportAddMenuItemGroup(
    $restaurantId: String!
    $name: String!
    $description: String
    $fulfillmentMethods: [MenuItemGroupFulfillmentMethod!]!
    $addOnItemsMenu: Boolean!
  ) {
    addMenuItemsGroup(
      restaurantId: $restaurantId
      name: $name
      description: $description
      fulfillmentMethods: $fulfillmentMethods
      addOnItemsMenu: $addOnItemsMenu
    ) {
      menuItemsGroup {
        ...menuItemGroupFields
      }
    }
  }

  ${MENU_ITEM_GROUP_FRAGMENT}
`
