import { Button } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import PrintContent from '@components/PrintContent/PrintContent'
import Query from '@components/Query/Query'
import { Fragment } from 'react'
import { useModal } from '../../../providers/ModalProvider'
import OrderPrintout from './OrderPrintout/OrderPrintout'
import GET_ORDER from './queries/getOrder.query'

const PrintOrderModal = ({ open }) => {
  const { isOpen, toggleModal } = useModal('printOrderModal')

  return (
    <Dialog title="Order Is Preparing" isCloseButtonShown={false} isOpen={open}>
      <Query query={GET_ORDER} variables={{ id: isOpen }} showLoader={false}>
        {({ order }) => {
          return (
            <Fragment>
              <div className="bp5-dialog-body">
                <p>Ready to print a receipt for this order?</p>
              </div>
              <div className="bp5-dialog-footer">
                <div className="bp5-dialog-footer-actions">
                  <Button
                    minimal
                    onClick={() => {
                      toggleModal(false)
                    }}
                    data-test-id="print-order-modal-close-button"
                  >
                    No, Close
                  </Button>
                  <PrintContent
                    minimal={false}
                    buttonName="Print"
                    afterPrint={() => {
                      toggleModal(false)
                    }}
                  >
                    <OrderPrintout order={order} />
                  </PrintContent>
                </div>
              </div>
            </Fragment>
          )
        }}
      </Query>
    </Dialog>
  )
}

export default PrintOrderModal
