import { Fragment } from 'react'
import { Formik } from 'formik'
import { first, map } from 'lodash'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Button, Card, Intent, NonIdealState } from '@blueprintjs/core'
import GeoFence from '@components/GeoJSON/GeoFence'
import GeoOutletRadii from '@components/GeoJSON/GeoOutletRadii'

import GET_DELIVERY_ZONE from '@components/DeliveryZone/queries/getDeliveryZone.query'
import EDIT_DELIVERY_ZONE from '@components/DeliveryZone/mutations/editDeliveryZone.mutation'
import EDIT_DELIVERY_ZONE_GEOFENCE from '@components/DeliveryZone/mutations/editDZGeoFence.mutation'
import GET_DELIVERY_ZONES from '@components/Marketplace/DeliveryZones/queries/getDeliveryZones.query'

import Query from '@components/Query/Query'
import Form from '@components/DeliveryZone/Form'
import { editDZvalidation } from './validation'
import DELETE_DELIVERY_ZONE from './mutations/deleteDeliveryZone.mutation'
import { Link, useParams } from 'react-router-dom'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { useMutation } from '@apollo/client'
import { StringParam, useQueryParams } from 'use-query-params'
import { GetDeliveryZoneQuery } from './queries/getDeliveryZone.query.generated'

const DeliveryZone = ({ onChangeViewZone }) => {
  const { marketplace } = useParams()
  const [{ viewZone }] = useQueryParams({
    viewZone: StringParam,
  })

  const [editDeliveryZoneGeo] = useMutation(EDIT_DELIVERY_ZONE_GEOFENCE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editDeliveryZone }) => {
      successToast(editDeliveryZone.message)
    },
  })

  const [deleteDeliveryZone] = useMutation(DELETE_DELIVERY_ZONE, {
    onError: defaultErrorHandler,
    onCompleted: ({ deleteDeliveryZone }) => {
      onChangeViewZone(null)
      successToast(deleteDeliveryZone.message)
    },
    refetchQueries: [
      { query: GET_DELIVERY_ZONES, variables: { marketplaceId: marketplace } },
    ],
  })

  const [editDeliveryZone] = useMutation(EDIT_DELIVERY_ZONE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editDeliveryZone }) => {
      successToast(editDeliveryZone.message)
    },
  })

  return (
    <Query<GetDeliveryZoneQuery>
      query={GET_DELIVERY_ZONE}
      variables={{ id: viewZone }}
      loaderTitle="Loading Delivery Zone"
    >
      {({ getDeliveryZones: { deliveryZones } }) => {
        const deliveryZone = first(deliveryZones)

        if (!deliveryZone) {
          return (
            <NonIdealState icon="error" title="Unable to Find Delivery Zone" />
          )
        }

        const { marketplace, outletDeliveryZoneCosts } = deliveryZone
        const outlets = map(outletDeliveryZoneCosts, 'outlet')

        let { geoFence } = deliveryZone as {
          geoFence?: { coordinates?: [] } | null
        }
        const { deliveryZoneType } = deliveryZone

        if (geoFence && !geoFence.coordinates) {
          geoFence = null // safety net
        }
        return (
          <div>
            <Card className="bp5-nopad">
              <>
                {deliveryZoneType === 'RADIUS_AROUND_OUTLET' && (
                  <GeoOutletRadii
                    outlets={outlets}
                    center={
                      (marketplace.geoFence as { center?: number })?.center
                    }
                  />
                )}
              </>
              <GeoFence
                geoFence={geoFence}
                handleUpdate={() => null}
                setFieldValue={async (_, { geoFence }) => {
                  await editDeliveryZoneGeo({
                    variables: {
                      geoFence,
                      id: viewZone,
                    },
                  })
                }}
                showDelete={false}
                features={outlets}
              />
            </Card>
            <div className="bp5-drawer-footer-actions">
              <div>
                <ConfirmationPopover
                  remove={() =>
                    deleteDeliveryZone({ variables: { id: viewZone } })
                  }
                  disabled={
                    deliveryZone.outletDeliveryZoneCosts
                      ? deliveryZone.outletDeliveryZoneCosts.length > 0
                      : true
                  }
                  confirmationText={
                    deliveryZone.outletDeliveryZoneCosts &&
                    deliveryZone.outletDeliveryZoneCosts.length > 0 ? (
                      <Fragment>
                        <p>
                          This delivery zone is still attached to the following
                          outlets:
                        </p>
                        <ul>
                          {deliveryZone.outletDeliveryZoneCosts.map(
                            ({ outlet }) => (
                              <li key={outlet.id}>
                                <Link
                                  to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`}
                                >
                                  {outlet.name}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                        You must detach this zone from these outlets before you
                        can delete it.
                      </Fragment>
                    ) : (
                      `Are you sure you want to delete this delivery zone?`
                    )
                  }
                >
                  <Button
                    intent={Intent.DANGER}
                    minimal
                    icon={
                      deliveryZone.outletDeliveryZoneCosts &&
                      deliveryZone.outletDeliveryZoneCosts.length > 0
                        ? 'warning-sign'
                        : null
                    }
                  >
                    Delete
                  </Button>
                </ConfirmationPopover>
              </div>
              <Formik
                initialValues={{ ...deliveryZone }}
                validationSchema={editDZvalidation}
                onSubmit={async values => {
                  await editDeliveryZone({
                    variables: {
                      ...values,
                    },
                  })
                }}
              >
                {props => <Form {...props} />}
              </Formik>
            </div>
          </div>
        )
      }}
    </Query>
  )
}

export default DeliveryZone
