// navigation.js
export const navigate = (url: string) => {
  window.history.pushState({}, '', url)
  // You may need to trigger a navigation event for React Router to handle the URL change
  window.dispatchEvent(new PopStateEvent('popstate'))
}

export const replace = (url: string) => {
  window.history.replaceState({}, '', url)
  window.dispatchEvent(new PopStateEvent('popstate'))
}
