import first from 'lodash/first'
import { Fragment } from 'react'

import Query from '@components/Query/Query'
import GET_PARTNER from './queries/getPartnerBilling.query'

import {
  Callout,
  Card,
  FormGroup,
  H4,
  H5,
  HTMLTable,
  Icon,
  Intent,
  NonIdealState,
  Tag,
} from '@blueprintjs/core'
import { Col, Row } from '@components/_FlexGrid'
import AddPaymentMethodButton from '@components/Billing/AddBillingPaymentMethodButton'
import DeletePaymentMethodButton from '@components/Billing/DeleteBillingPaymentMethodButton'
import CopyText from '@components/CopyText/CopyText'
import Currency from '@components/Currency/Currency'
import { isPlatformLevelUser, isSuperUser } from '@stores/userStore'
import { getInvoiceIntent } from '@utils/helpers'
import startCase from 'lodash/startCase'
import moment from 'moment'
import { useParams } from 'react-router-dom'

const Billing = () => {
  const { partner } = useParams()

  return (
    <Query
      query={GET_PARTNER}
      variables={{ id: partner }}
      loaderTitle={'Loading Billing'}
    >
      {({ getPartners: { partners } }) => {
        const partner = first(partners)

        if (!partner) {
          return 'Unable to find partner'
        }

        const paymentMethods = partner.billing.paymentMethods
        const isPaymentSetup = paymentMethods.length > 0
        const invoices = partner.invoices || []

        return (
          <Fragment>
            <Fragment>
              {partner.billing && partner.billing.delinquent && (
                <Callout
                  intent="danger"
                  title="Suspension Warning"
                  style={{ marginBottom: 24 }}
                >
                  We have been unable to collect payment for Redbox add-ons and
                  services, please settle all open invoices before reactivating
                  features. Failure to make payment may result in services being
                  suspended.
                </Callout>
              )}

              {!isPaymentSetup && (
                <Callout intent="warning" style={{ marginBottom: 24 }}>
                  A payment method has not been configured on this partner,{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.redbox.systems/docs/redbox-billing-setting-up-your-direct-debit"
                  >
                    learn more about billing
                  </a>
                  .
                  <AddPaymentMethodButton
                    id={partner}
                    minimal
                    intent="warning"
                    style={{ position: 'absolute', right: 5, top: 5 }}
                  />
                </Callout>
              )}
            </Fragment>

            <Row gutter={24}>
              <Col xs={12} sm={12} md={6} lg={8} order={2}>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    {''}
                  </Col>
                </Row>
                <Card className="bp5-nopad">
                  {invoices !== null && invoices.length > 0 ? (
                    <HTMLTable bordered={false} interactive={false}>
                      <thead>
                        <tr>
                          <th>Invoices</th>
                          <th>Date</th>
                          <th>Net</th>
                          <th>Gross</th>
                          <th>Status</th>
                          <th>Due</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices.map(invoice => (
                          <tr key={invoice.id}>
                            <td>
                              <a
                                href={invoice.invoicePdf}
                                rel="noopener noreferrer"
                              >
                                <code>{invoice.invoiceNumber}</code>
                              </a>
                            </td>
                            <td>
                              {moment.unix(invoice.dueDate).format('DD MMM')}
                            </td>
                            <td>
                              <Currency amount={invoice.subtotal} />
                            </td>
                            <td>
                              <Currency amount={invoice.amountDue} />
                            </td>
                            <td>
                              <Tag
                                minimal
                                intent={getInvoiceIntent(invoice.status)}
                              >
                                {invoice.status.toUpperCase()}
                              </Tag>
                            </td>
                            <td>
                              <span
                                className={
                                  invoice.amountRemaining === 0 &&
                                  'bp5-text-muted'
                                }
                              >
                                <Currency amount={invoice.amountRemaining} />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  ) : (
                    <div style={{ padding: '100px' }}>
                      <NonIdealState
                        icon="th-list"
                        title="No Partner Invoices"
                        description="Please check back at the end of your billing period."
                      />
                    </div>
                  )}
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} order={1}>
                <Card>
                  <H4>
                    Payment{' '}
                    {partner.billing.livemode === false && (
                      <Tag intent={Intent.WARNING}>TEST</Tag>
                    )}
                  </H4>
                  {isPlatformLevelUser() && (
                    <Fragment>
                      <FormGroup label="Stripe Customer">
                        <CopyText
                          leftIcon={
                            <Icon icon="tick-circle" intent="success" />
                          }
                          text={partner.billing.id}
                          mono
                        />
                      </FormGroup>
                    </Fragment>
                  )}
                  <p>
                    Monthly billing for add-on features, services and overages.
                  </p>

                  {paymentMethods.length > 0 ? (
                    paymentMethods.map(method => (
                      <Fragment key={method.id}>
                        <Callout key={`${method.id}_${method.account}`}>
                          <strong>{startCase(method.type)}</strong>
                          {paymentMethods.length > 1 && method.default && (
                            <Tag
                              minimal
                              style={{
                                position: 'absolute',
                                right: 6,
                                top: 6,
                              }}
                            >
                              DEFAULT
                            </Tag>
                          )}
                          <br />
                          <code>
                            {method.account} - {method.sort}
                          </code>

                          {isSuperUser() && (
                            <DeletePaymentMethodButton
                              id={partner}
                              level="PARTNER"
                              paymentMethodId={method.id}
                              style={{
                                position: 'absolute',
                                right: 6,
                                bottom: 6,
                              }}
                            />
                          )}
                        </Callout>

                        {method.type === 'bacs_debit' && (
                          <div style={{ marginTop: 8 }}>
                            <a
                              className="bp5-text-small"
                              href="https://www.directdebit.co.uk/direct-debit-explained/what-is-direct-debit/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              How do Direct Debits work?
                            </a>
                            <br />
                          </div>
                        )}
                        <br />
                      </Fragment>
                    ))
                  ) : (
                    <AddPaymentMethodButton id={partner} level="PARTNER" fill />
                  )}

                  {partner.billing.upcomingInvoice && (
                    <Fragment>
                      <br />
                      <br />
                      <H5>Billing Estimate</H5>
                      <p>
                        <Icon icon="time" /> Period started{' '}
                        <strong>
                          {moment
                            .unix(partner.billing.upcomingInvoice.periodStart)
                            .format('DD MMM')}{' '}
                        </strong>
                        ending{' '}
                        <strong>
                          {moment
                            .unix(partner.billing.upcomingInvoice.periodEnd)
                            .format('DD MMM')}
                        </strong>
                        .
                      </p>

                      {partner.billing.upcomingInvoice.items &&
                        partner.billing.upcomingInvoice.items.length > 0 && (
                          <Card className="bp5-nopad">
                            <HTMLTable interactive={false}>
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th style={{ textAlign: 'right' }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {partner.billing.upcomingInvoice.items.map(
                                  item => (
                                    <tr key={item.id}>
                                      <td className="bp5-text-small">
                                        {item.description}
                                      </td>
                                      <td
                                        className="bp5-text-small"
                                        style={{ textAlign: 'right' }}
                                      >
                                        <Currency amount={item.amount} />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th className="bp5-text-small">Subtotal</th>
                                  <th
                                    className="bp5-text-small"
                                    style={{ textAlign: 'right' }}
                                  >
                                    <Currency
                                      amount={
                                        partner.billing.upcomingInvoice.subtotal
                                      }
                                    />
                                  </th>
                                </tr>
                                <tr>
                                  <th className="bp5-text-small">VAT</th>
                                  <th
                                    className="bp5-text-small"
                                    style={{ textAlign: 'right' }}
                                  >
                                    <Currency
                                      amount={
                                        partner.billing.upcomingInvoice.tax
                                      }
                                    />
                                  </th>
                                </tr>
                                <tr>
                                  <th>Total</th>
                                  <th style={{ textAlign: 'right' }}>
                                    <Currency
                                      amount={
                                        partner.billing.upcomingInvoice.total
                                      }
                                    />
                                  </th>
                                </tr>
                              </tfoot>
                            </HTMLTable>
                          </Card>
                        )}
                    </Fragment>
                  )}
                </Card>
                <Card>
                  {partner.billing.products.map(product => {
                    return (
                      product.featured &&
                      product.service && (
                        <Callout
                          key={product.id}
                          icon={
                            product.icon && (
                              <img
                                className="bp5-icon"
                                src={product.icon}
                                height="22"
                                width="22"
                              />
                            )
                          }
                          title={product.name}
                          style={{ background: 'transparent' }}
                        >
                          <p>
                            {product.description}
                            <br />
                            <a>Learn More</a>
                          </p>
                          {product.subscribed ? (
                            <Tag
                              icon="small-tick"
                              intent="success"
                              style={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                              }}
                            >
                              ACTIVE
                            </Tag>
                          ) : product.label ? (
                            <Fragment>
                              <Tag
                                intent="primary"
                                minimal
                                style={{
                                  position: 'absolute',
                                  right: 12,
                                  top: 12,
                                }}
                              >
                                {product.label}
                              </Tag>
                            </Fragment>
                          ) : (
                            <Tag
                              rightIcon="plus"
                              intent="success"
                              minimal
                              style={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                              }}
                            >
                              ADD
                            </Tag>
                          )}
                        </Callout>
                      )
                    )
                  })}
                </Card>
              </Col>
            </Row>
          </Fragment>
        )
      }}
    </Query>
  )
}

export default Billing
