import colors from './colors'
import darkColors from './darkColors'

const theme = {
  colors,
  darkColors,
  padding: {
    small: '10px',
    medium: '15px',
    large: '30px',
  },
  fontSize: {
    small: '12px',
    medium: '16px',
    large: '25px',
  },
  border: `1px solid ${colors.borderColor}`,
  transition: 'all 0.3s ease-in-out',
}

export default theme
