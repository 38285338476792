import { Colors } from '@blueprintjs/core'

// should be enough for 10 random userAgents
export const userAgentColorGenerator = userAgentEntries =>
  userAgentEntries.reduce((acc, entry, index) => {
    acc[entry] =
      Colors[(index < 5 ? 'VIOLET' : 'ORANGE') + (index + 1).toString()]

    return acc
  }, {})
