import gql from 'graphql-tag'

const GET_PARTNERS = gql`
  query getPartners {
    getPartners {
      partners {
        id
        name
        contactName
        platformFee
        platformCharge
        orderHoldInterval
        isVATregistered
        partnerCommissionFee
        partnerTableCommissionFee
        marketplaces {
          id
          name
        }
        restaurants {
          id
          name
        }
      }
    }
  }
`

export default GET_PARTNERS
