import { Classes, Drawer, HTMLSelect } from '@blueprintjs/core'
import { createEnumParam, StringParam, useQueryParam } from 'use-query-params'
import { useGetDeliveryNetworkFailureReportHeatmapQuery } from './queries/deliveryNetworkFailuresHeatmap.query.generated'

import { DayHourHeatmap } from '@src/components/DayHourHeatmap/DayHourHeatmap'
import { useGetMarketplaceNameQuery } from '@src/components/Header/Breadcrumbs/queries/getMarketplaceName.query.generated'

interface Props {}

const fieldDataTypeMapping: Record<
  'totalOrders' | 'totalFailedOrders' | 'totalFailedValue' | 'failureRate',
  'number' | 'currency' | 'percentage'
> = {
  totalOrders: 'number',
  totalFailedOrders: 'number',
  totalFailedValue: 'currency',
  failureRate: 'percentage',
}

export const DeliveryNetworkFailuresHeatmap: React.FC<Props> = () => {
  const [showingMarketplace, setShowingMarketplace] = useQueryParam(
    'showingMarketplace',
    StringParam
  )
  const [heatmapField, setHeatmapField] = useQueryParam(
    'heatmapField',
    createEnumParam([
      'totalOrders',
      'totalFailedOrders',
      'totalFailedValue',
      'failureRate',
    ] as const)
  )

  const { data } = useGetDeliveryNetworkFailureReportHeatmapQuery({
    variables: { marketplaceId: String(showingMarketplace) },
    skip: !showingMarketplace,
  })

  const { data: marketplaceNameData } = useGetMarketplaceNameQuery({
    variables: { id: String(showingMarketplace) },
    skip: !showingMarketplace,
  })

  if (!data) return 'Loading'

  return (
    <Drawer
      title={`Delivery Network Failure for ${
        marketplaceNameData
          ? marketplaceNameData?.getMarketplaces.regions[0].name
          : ''
      }`}
      isOpen={Boolean(showingMarketplace)}
      onClose={() => {
        setShowingMarketplace(undefined)
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <HTMLSelect
            value={heatmapField ?? ''}
            onChange={event => {
              // @ts-expect-error this could be any string, but createEnum param will protect us by casting to null/undefined if we don't want it
              setHeatmapField(event.currentTarget.value)
            }}
          >
            <option disabled value="">
              Choose
            </option>
            <option value="totalOrders">Total Orders</option>
            <option value="totalFailedOrders">Total Failed Orders</option>
            <option value="totalFailedValue">Total Failed Value</option>
            <option value="failureRate">Failure Rate</option>
          </HTMLSelect>
          {heatmapField && (
            <DayHourHeatmap
              data={(data.getDeliveryNetworkFailureReportHeatmap ?? []).map(
                data => ({
                  hour: data.hour,
                  day: data.day,
                  value: data[heatmapField],
                })
              )}
              dataType={fieldDataTypeMapping[heatmapField]}
            />
          )}
        </div>
      </div>
    </Drawer>
  )
}
