import {
  Button,
  Card,
  Classes,
  FormGroup,
  HTMLSelect,
  Icon,
  Intent,
  TextArea,
} from '@blueprintjs/core'

import { useState } from 'react'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import { string, number } from 'prop-types'
import MUTATION_REFUND_ORDER from '../mutations/refundOrder.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import Currency from '@components/Currency/Currency'
import { Formik } from 'formik'
import { refundValidation } from './validation/validation'
import { useMutation } from '@apollo/client'

const RefundOrderModal = ({ modalOpen, modalClose, orderId, grossTotal }) => {
  const [loading, setLoading] = useState(false)

  const [refundOrder] = useMutation(MUTATION_REFUND_ORDER, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: ({ refundOrder }) => {
      modalClose()
      successToast(refundOrder.message)
    },
  })

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      isCloseButtonShown={!loading}
      title={`Order Refund`}
    >
      <Formik
        validationSchema={refundValidation}
        validateOnChange={true}
        onSubmit={values => {
          setLoading(true)
          refundOrder({
            variables: {
              id: orderId,
              reason: values.refundReason,
              description: values.refundDescription,
            },
          })
        }}
        initialValues={{
          refundReason: 'REQUESTED_BY_CUSTOMER',
          refundDescription: '',
        }}
      >
        {({ errors, handleChange, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <p>
                Return the full order value to the card used for payment. A fee
                for the refund will be charged to the marketplace.
              </p>
              <Card>
                <p>
                  Refund Amount:{' '}
                  <strong>
                    <Currency amount={grossTotal} />
                  </strong>
                </p>
              </Card>
              <FormGroup
                label="Refund Reason"
                labelFor="refundReason"
                helperText={errors.refundReason || ''}
                intent={errors.refundReason ? Intent.DANGER : Intent.NONE}
              >
                <HTMLSelect
                  name="refundReason"
                  options={[
                    {
                      label: 'Customer Requested',
                      value: 'REQUESTED_BY_CUSTOMER',
                    },
                    { label: 'Duplicate', value: 'DUPLICATE' },
                    { label: 'Fraud', value: 'FRAUDULENT' },
                  ]}
                  value={values.refundReason}
                  onChange={handleChange}
                  intent={errors.refundReason ? Intent.DANGER : Intent.NONE}
                />
              </FormGroup>
              <FormGroup
                label="Description"
                labelFor="refundDescription"
                helperText={errors.refundDescription || ''}
                intent={errors.refundDescription ? Intent.DANGER : Intent.NONE}
              >
                <TextArea
                  name="refundDescription"
                  intent={
                    errors.refundDescription ? Intent.DANGER : Intent.NONE
                  }
                  onChange={handleChange}
                  value={values.refundDescription}
                  fill={true}
                  placeholder="Describe the reason for refunding this order."
                />
              </FormGroup>
              <p className="bp5-text-small bp5-text-muted">
                <Icon icon="warning-sign" /> It may take 5–10 business days for
                funds to settle.
              </p>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal onClick={modalClose} disabled={loading}>
                  Cancel
                </Button>
                <Button text="Confirm Refund" loading={loading} type="submit" />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

RefundOrderModal.propTypes = {
  ...modalType,
  orderId: string,
  grossTotal: number,
}

export default RefundOrderModal
