import React from 'react'
import Query from '@components/Query/Query'
import { GET_OTTER_OAUTH_URL } from './queries/getOtterOAuthUrl.query'
import { Button, Callout } from '@blueprintjs/core'
import { OtterFetch } from './OtterFetch'
import { useOtterModal } from './useOtterQueryParam'

// attempt an otter fetch first to see if the user is already connected
export const OtterOAuthButton = () => {
  const { open } = useOtterModal()

  return (
    <OtterFetch path="/organization">
      {organisation =>
        organisation ? (
          <Callout title="Otter Connected" intent="success" icon={null}>
            <p>
              You are already connected to Otter.{' '}
              <strong>{organisation.name}</strong>
              <Button
                rightIcon="arrow-right"
                intent="primary"
                fill
                alignText="left"
                minimal
                outlined
                text="Continue Onboarding"
                onClick={open}
              />
            </p>
          </Callout>
        ) : (
          <Query
            query={GET_OTTER_OAUTH_URL}
            variables={{
              redirectPath: location.pathname + '?otter=onboarding',
            }}
          >
            {data =>
              data && data.getOtterOAuthUrl ? (
                <Callout title="Connect Otter" intent="primary" icon={null}>
                  <p>
                    Connect your Otter account to receive orders directly into
                    your POS.
                  </p>
                  <Button
                    icon="cloud-upload"
                    intent="primary"
                    text="Connect Otter"
                    onClick={() => {
                      alert(`Redirecting to ${data.getOtterOAuthUrl}`)
                      window.location.href = data.getOtterOAuthUrl
                    }}
                  />
                </Callout>
              ) : null
            }
          </Query>
        )
      }
    </OtterFetch>
  )
}
