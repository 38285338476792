import gql from 'graphql-tag'

const ARCHIVE_OUTLET_DELIVERY_ZONE = gql`
  mutation archiveOutletDeliveryZone($id: String!) {
    archiveOutletDeliveryZone(id: $id) {
      id
    }
  }
`

export default ARCHIVE_OUTLET_DELIVERY_ZONE
