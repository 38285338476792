import { Fragment, useContext } from 'react'
import { Button, Card, HTMLTable, NonIdealState } from '@blueprintjs/core'
import BLACKLISTED_ADDRESSES from './queries/MarketplaceBlacklistedAddresses.query'
import DELETE_BLACKLISTED_ADDRESS from './mutations/DeleteBlacklistedAddress.mutation'
import { successToast, errorToast } from '@utils/toast'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import BlacklistAddressModal from './modals/BlacklistAddressModal'
import blacklistedAddressesPageContext from './BlacklistedAddressesPageContext'
import { useParams } from 'react-router-dom'
import Query from '../Query/Query'
import { useMutation } from '@apollo/client'
import defaultErrorHandler from '@src/utils/defaultErrorHandler'

const tableHead = [
  {
    key: 'adress',
    content: 'Address',
  },
  {
    key: 'delete',
    content: '',
  },
]

const BlacklistedAddresses = () => {
  const { marketplace: marketplaceId } = useParams()
  const { blacklistedAddressModalOpen, setBlacklistedAddressModalOpen } =
    useContext(blacklistedAddressesPageContext)
  const handleSubmit = (vars, addRequest) => {
    try {
      const blacklistMutation = addRequest({
        variables: vars,
      })
      blacklistMutation.then(response => {
        setBlacklistedAddressModalOpen(false)
        successToast(response.data.blacklistAddress.message)
      })
    } catch (error) {
      error.graphQLErrors.map(error => {
        errorToast(error.message)
      })
    }
  }

  const [deleteRequest] = useMutation(DELETE_BLACKLISTED_ADDRESS, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Blacklisted address deleted successfully.')
    },
    refetchQueries: [
      {
        query: BLACKLISTED_ADDRESSES,
        variables: { marketplaceId },
      },
    ],
  })

  return (
    <Query query={BLACKLISTED_ADDRESSES} variables={{ marketplaceId }}>
      {({ data }) => {
        const blacklistedAddresses =
          data && data.blacklistedAddresses
            ? data.blacklistedAddresses.blacklistedAddresses
            : null
        return (
          <Fragment>
            {!data ||
            !data.blacklistedAddresses ||
            !data.blacklistedAddresses.blacklistedAddresses.length ? (
              <NonIdealState
                icon="th-list"
                title="No Blacklisted Addresses"
                description="There are no blacklisted addresses."
                action={
                  <Button
                    icon="plus"
                    onClick={() => {
                      setBlacklistedAddressModalOpen(true)
                    }}
                  >
                    Blacklist Address
                  </Button>
                }
              />
            ) : (
              <Fragment>
                {blacklistedAddresses.length > 0 && (
                  <Card className={'bp5-nopad'}>
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          {tableHead.map(({ key, content }) => (
                            <th key={key}>{content}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {blacklistedAddresses.map(address => (
                          <tr key={address.id}>
                            <td>
                              {decodeURIComponent(
                                address.googleFormattedAddress
                              )}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <ConfirmationPopover
                                remove={() =>
                                  deleteRequest({
                                    variables: { id: address.id },
                                  })
                                }
                                confirmationText={`Are you sure you want to delete this address from the blacklisted list?`}
                              >
                                <Button
                                  text=""
                                  type="button"
                                  minimal={true}
                                  icon="trash"
                                />
                              </ConfirmationPopover>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Card>
                )}
              </Fragment>
            )}
            <BlacklistAddressModal
              marketplaceId={marketplaceId}
              blacklistedAddressModalOpen={blacklistedAddressModalOpen}
              setBlacklistedAddressModalOpen={setBlacklistedAddressModalOpen}
              handleSubmit={handleSubmit}
            />
          </Fragment>
        )
      }}
    </Query>
  )
}

export default BlacklistedAddresses
