import gql from 'graphql-tag'

const GET_STRIPECONNECTACCOUNTS = gql`
  query getPlatformStripeSettings {
    getPlatform {
      platform {
        id
        stripePlatformWebhooks {
          id
          description
          url
          status
        }
        stripePayboxWebhooks {
          id
          description
          url
          status
        }
      }
    }
    getStripeConnectAccounts {
      accounts {
        id
        chargeEnabled
        transferEnabled
        country
        displayName
        defaultCurrency
        marketplace {
          id
          name
        }
      }
    }
  }
`

export default GET_STRIPECONNECTACCOUNTS
