import { Spinner } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Fragment, useState } from 'react'
import GET_LINK_CARDS from '@components/Marketplace/LinkCards/queries/getLinkCards.query'
import EditLinkCardForm from '@components/Marketplace/LinkCards/EditLinkCardForm'
import GET_LINK_CARD from '@components/Marketplace/LinkCards/queries/getLinkCard.query'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_LINK_CARD from '@components/Marketplace/LinkCards/mutations/editLinkCard.mutation'
import { useMutation } from '@apollo/client'
import Query from '@src/components/Query/Query'

const EditLinkCardModal = ({ id, isOpen, marketplaceId, onClose }) => {
  const [loading, setLoading] = useState(false)
  if (loading) return <Spinner />

  const [editLinkCard] = useMutation(EDIT_LINK_CARD, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: () => {
      setLoading(false)
      onClose()
      successToast(`Segment Updated Successfully`)
    },
    refetchQueries: [
      {
        query: GET_LINK_CARD,
        variables: {
          id,
        },
      },
      {
        query: GET_LINK_CARDS,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Link Card"
      style={{
        width: 740,
      }}
      styles={{ overflowX: 'hidden' }}
    >
      <Fragment>
        <Query query={GET_LINK_CARD} variables={{ id }}>
          {({ getLinkCard }) => {
            return (
              <Fragment>
                {getLinkCard && (
                  <EditLinkCardForm
                    marketplaceId={marketplaceId}
                    linkCard={getLinkCard}
                    editRequest={editLinkCard}
                    close={onClose}
                    isDialog={true}
                  />
                )}
              </Fragment>
            )
          }}
        </Query>
      </Fragment>
    </Dialog>
  )
}

export default EditLinkCardModal
