import gql from 'graphql-tag'

const GET_ITEM_PARENT = gql`
  query getMenuItems($id: String!) {
    getMenuItems(id: $id) {
      menuItems {
        id
        parentMenus {
          id
        }
      }
    }
  }
`

export default GET_ITEM_PARENT
