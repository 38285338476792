import React, { useState, useEffect } from 'react'
import { errorToast } from '@utils/toast'
import PropTypes from 'prop-types'
import { NonIdealState, Spinner } from '@blueprintjs/core'

const buildUrl = (path, domain) => new URL(`https://${domain}/${path}`)

const FetchQuery = ({
  children,
  path,
  domain,
  fetchOptions = {},
  handleLoading = false,
  onCompleted = () => {},
  onError = () => {},
}) => {
  const url = buildUrl(path, domain)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(!data)
  const [error, setError] = useState(false)

  const fetchData = async () => {
    setError(false)
    setLoading(true)

    try {
      const request = await fetch(url, fetchOptions)
      const requestData = await request.json()
      setData(requestData)
      onCompleted()
    } catch (e) {
      onError()
      setError(e)
      setData(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (handleLoading && loading) {
    return (
      <NonIdealState
        icon={<Spinner size={60} />}
        title={'Loading'}
        description={'Please wait...'}
      />
    )
  }

  if (error) {
    errorToast(error.message)
  }

  return children({ loading, error, data })
}

FetchQuery.propTypes = {
  children: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  variables: PropTypes.object,
  handleLoading: PropTypes.bool,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
}

export default FetchQuery
