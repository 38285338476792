import gql from 'graphql-tag'

const ADD_OUTLET_DELIVERY_ZONE = gql`
  mutation addDeliveryZoneForOutlet(
    $outletId: String!
    $deliveryZoneId: String!
    $outletDeliveryZoneType: OutletDeliveryZoneType!
    $cost: Int!
    $radiusMiles: Float
    $apiClientId: String
    $apiSecret: String
    $fixedSubsidy: Int
    $pickupNotes: String
    $uberDirectUndeliverableAction: UberDirectUndeliverableAction
  ) {
    addOutletDeliveryZone(
      outletId: $outletId
      deliveryZoneId: $deliveryZoneId
      outletDeliveryZoneType: $outletDeliveryZoneType
      cost: $cost
      radiusMiles: $radiusMiles
      apiClientId: $apiClientId
      apiSecret: $apiSecret
      fixedSubsidy: $fixedSubsidy
      pickupNotes: $pickupNotes
      uberDirectUndeliverableAction: $uberDirectUndeliverableAction
    ) {
      id
      deliveryZone {
        id
        name
      }
      deliveryCost
    }
  }
`

export default ADD_OUTLET_DELIVERY_ZONE
