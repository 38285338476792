import * as yup from 'yup'

const FulfilmentTimesSetupValidattionSchema = yup.object().shape({
  allowPreorders: yup.boolean().test({
    name: 'channelCheckbox',
    exclusive: false,
    test: (allowPreorders, TestContext) => {
      const { parent } = TestContext
      if (parent.asapAllowed || allowPreorders) {
        return true
      }
      return new yup.ValidationError(
        'You must enable either Allow ASAP or Allow Preorder.',
        null,
        'allowPreorders'
      )
    },
  }),

  asapAllowed: yup.boolean().test({
    name: 'asapAllowed',
    exclusive: false,
    test: (asapAllowed, TestContext) => {
      const { parent } = TestContext
      if (parent.allowPreorders || asapAllowed) {
        return true
      }
      return new yup.ValidationError(
        'You must enable either Allow ASAP or Allow Preorder.',
        null,
        'asapAllowed'
      )
    },
  }),
})

export default FulfilmentTimesSetupValidattionSchema
