import React from 'react'
import { arrayOf, number, object, shape, string } from 'prop-types'
import {
  Bar,
  ComposedChart,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { indexOf, maxBy, meanBy } from 'lodash'
import CustomTooltip from './CustomTooltip'
import { renderCustomLabel } from './CustomLabel'
import { ANALYTICS_COLORS } from '../styles'

const defaultColors = {
  WHITE: ANALYTICS_COLORS.white,
  GREY: ANALYTICS_COLORS.greyForGraphs,
  RED: ANALYTICS_COLORS.redBar,
  RED_DOT: ANALYTICS_COLORS.redDot,
}

const BarGraph = ({ data, tooltipDateFormat, colors = defaultColors }) => {
  const mean = meanBy(data, ({ y }) => y) || 0
  const max = maxBy(data, ({ y }) => y) || 0

  return (
    <ResponsiveContainer width="100%" height="70%">
      <ComposedChart
        width={500}
        height={440}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 20,
        }}
      >
        <Bar dataKey={'y'} fill={colors.RED} />
        {mean && (
          <ReferenceLine
            y={mean}
            stroke={colors.GREY}
            strokeWidth={1}
            strokeDasharray="4 4"
            label={props =>
              renderCustomLabel({
                ...props,
                value: max.y,
                positionRight: true,
                currency: true,
              })
            }
          />
        )}
        {max && (
          <ReferenceDot
            alwaysShow
            fill={colors.RED_DOT}
            r={4}
            stroke={colors.WHITE}
            y={max.y}
            x={indexOf(data, max)}
            label={props =>
              renderCustomLabel({ ...props, value: max.y, currency: true })
            }
          />
        )}

        <Tooltip
          content={({ payload }) => (
            <CustomTooltip
              tooltipDateFormat={tooltipDateFormat}
              payload={payload}
              currency
            />
          )}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

BarGraph.propTypes = {
  data: arrayOf(
    shape({
      x: string,
      y: number,
    })
  ),
  tooltipDateFormat: string,
  colors: object,
}

export default BarGraph
