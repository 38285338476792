import gql from 'graphql-tag'

const GET_MARKETPLACE_PAYMENTS = gql`
  query getMarketplacePayments($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        stripeCustomerPayboxId
        enablePayboxEnterprise
        partner {
          id
          name
          stripeEnterpriseAccessId
          stripeShowDashboardMarketplace
        }
        companyType
        companyNumber
        taxReference
        companyLegalName
        contactName
        contactEmail
        contactDoB
        contactAddress {
          id
          country
          firstLine
          city
          postcode
        }
        stripeId
        stripeEnterpriseId
        stripePublicId
        stripeLivemode
        stripeOnboarding
        stripeDirectPayment
        stripeAccountSession {
          clientSecret
          clientPublicKey
        }
        stripeConnect {
          detailsSubmitted
          payoutsEnabled
          chargesEnabled
          accountType
          balance
          balancePending
          balanceCurrency
          dashboardLink
          enterprise
          payoutSchedule
        }
      }
    }
  }
`

export default GET_MARKETPLACE_PAYMENTS
