import React, { createContext, useState } from 'react'

export const OpenMenuContext = createContext({
  openMenuGroups: [],
  setOpenMenuGroups: _ => {},
  currentMenuGroups: [],
  setCurrentMenuGroups: _ => {},
})

const OpenMenuTreeProvider = ({ children }) => {
  const [openMenuGroups, setOpenMenuGroups] = useState([])
  const [currentMenuGroups, setCurrentMenuGroups] = useState([])

  return (
    <OpenMenuContext.Provider
      value={{
        openMenuGroups,
        setOpenMenuGroups,
        currentMenuGroups,
        setCurrentMenuGroups,
      }}
    >
      {children}
    </OpenMenuContext.Provider>
  )
}
export default OpenMenuTreeProvider
