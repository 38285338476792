import { Fragment } from 'react'
import Query from '@components/Query/Query'
import {
  Card,
  HTMLTable,
  Tag,
  Icon,
  Drawer,
  NonIdealState,
  Button,
  DrawerSize,
} from '@blueprintjs/core'
import moment from 'moment'

import { matchType } from '@utils/types'
import GET_OUTLET_TERMINALS from '@components/Outlet/Terminals/queries/getTerminals.query'
import TerminalModal from '@src/components/TerminalModal/Terminal.modal'
import TerminalDetails from '@components/Terminal/Details'
import colors from '@styles/colors'
import CopyText from '@components/CopyText/CopyText'
import { StringParam, useQueryParam } from 'use-query-params'
import { useModal } from '../../../providers/ModalProvider'
import { useParams } from 'react-router-dom'
import { isAtLeastOutletUser } from '../../../stores/userStore'
import { NoPermissions } from '../../UI/Permission/Permission'

const tableHead = [
  { key: 'name', content: 'Name' },
  { key: 'lastOnline', content: 'Last Online' },
  { key: 'serialNumber', content: 'Serial' },
  {
    key: 'description',
    content: 'Description',
    width: '70%',
  },
  { key: 'alarm', content: 'Order Alert Sound' },
]

const Terminals = () => {
  const [viewTerminal, onChangeViewTerminal] = useQueryParam(
    'viewTerminal',
    StringParam
  )
  const { outlet: outletId } = useParams()
  const { isOpen, toggleModal } = useModal('terminalModal')

  if (!isAtLeastOutletUser()) return <NoPermissions />

  return (
    <Fragment>
      <Fragment>
        <Query
          query={GET_OUTLET_TERMINALS}
          variables={{ outletId }}
          loaderTitle={'Loading Terminals'}
          fetchPolicy="network-only"
        >
          {({ getTerminals }) => {
            if (
              getTerminals &&
              getTerminals.terminals &&
              getTerminals.terminals.length > 0
            ) {
              const { terminals } = getTerminals
              return (
                <Card className="bp5-nopad bp5-scrollable">
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        {tableHead.map(({ key, content }) => (
                          <th key={key}>{content}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {terminals.map(
                        ({
                          id,
                          friendlyName,
                          serialNumber,
                          softwareVersion,
                          slave,
                          alarm,
                          deviceDescription,
                          outlets,
                          lastOnline,
                          isOnline,
                        }) => {
                          const outlet = outlets.find(
                            ({ id }) => id === outletId
                          )

                          return (
                            <tr key={id}>
                              <td>
                                <div className="bp5-text-overflow-ellipsis">
                                  <Icon
                                    icon="symbol-circle"
                                    color={
                                      outlet &&
                                      outlet.restaurant.active &&
                                      outlet.active &&
                                      outlet.isOpen
                                        ? isOnline
                                          ? colors.onlineGreen
                                          : colors.offlineRed
                                        : colors.inactiveGray
                                    }
                                  />{' '}
                                  <a
                                    className={'bp5-text-overflow-ellipsis'}
                                    onClick={() => {
                                      onChangeViewTerminal(id)
                                    }}
                                  >
                                    {friendlyName}
                                  </a>
                                </div>
                              </td>
                              <td>
                                {lastOnline ? (
                                  moment(lastOnline).fromNow()
                                ) : (
                                  <i className="bp5-text-muted ">Never</i>
                                )}
                              </td>
                              <td>
                                <CopyText text={serialNumber} mono minimal />
                              </td>
                              <td>
                                {deviceDescription}{' '}
                                {softwareVersion ? (
                                  <Tag minimal={true}>{softwareVersion}</Tag>
                                ) : null}{' '}
                                {slave ? (
                                  <Tag minimal={true} intent={'warning'}>
                                    {' '}
                                    SLAVE{' '}
                                  </Tag>
                                ) : null}
                              </td>
                              <td>
                                {alarm ? (
                                  <Tag minimal={true}> {alarm} </Tag>
                                ) : (
                                  <Tag minimal={true}>ALARM1</Tag>
                                )}
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </HTMLTable>
                </Card>
              )
            } else {
              return (
                <NonIdealState
                  icon="mobile-phone"
                  title="No Terminals"
                  description="Assign a terminal to this outlet."
                  action={
                    <Button icon="plus" onClick={() => toggleModal(true)}>
                      Add Terminal
                    </Button>
                  }
                />
              )
            }
          }}
        </Query>

        <TerminalModal
          modalOpen={isOpen}
          modalClose={() => toggleModal(false)}
          outlet={outletId}
        />
      </Fragment>
      <Drawer
        title="Terminal Details"
        isOpen={Boolean(viewTerminal)}
        onClose={() => {
          onChangeViewTerminal(undefined)
        }}
        size={DrawerSize.SMALL}
      >
        <TerminalDetails id={viewTerminal} queryVariables={{ outletId }} />
      </Drawer>
    </Fragment>
  )
}

Terminals.propTypes = {
  match: matchType,
}

export default Terminals
