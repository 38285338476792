import React, { Fragment } from 'react'

import { Classes } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { last } from 'lodash'
import SEND_MESSAGE_REPLY from './mutations/sendMessageReply.mutation'
import { ReplyMessageForm } from './ReplyMessageForm'
import { replyMessageValidation } from './validation'
import { useMutation } from '@apollo/client'

export const SendMessageReply = ({
  messages,
  customersList,
  isInbox,
  setEmailSent,
  isReplying,
}) => {
  const lastMessage = last(messages)

  const [sendReply] = useMutation(SEND_MESSAGE_REPLY, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Message was successfully sent.')
      setEmailSent(true)
    },
  })

  return (
    <div className={Classes.DIALOG_BODY}>
      <Formik
        initialValues={{
          content: '',
          subject: `RE:${lastMessage.subject}`,
          addMarketplaceTheme: false,
        }}
        onSubmit={values => {
          sendReply({
            variables: {
              content: values.content,
              subject: values.subject,
              messageId: lastMessage.id,
              marketplaceId: lastMessage.marketplace.id,
              addMarketplaceTheme: values.addMarketplaceTheme,
            },
          })
        }}
        validationSchema={replyMessageValidation}
      >
        {props => (
          <Fragment>
            <ReplyMessageForm
              isReplying={isReplying}
              {...props}
              values={lastMessage}
              customersList={customersList}
              isInbox={isInbox}
            />
          </Fragment>
        )}
      </Formik>
    </div>
  )
}
