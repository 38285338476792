import gql from 'graphql-tag'

const DELETE_LINK_CARD = gql`
  mutation deleteLinkCard($id: String!) {
    deleteLinkCard(id: $id) {
      message
    }
  }
`

export default DELETE_LINK_CARD
