import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  H5,
  HTMLSelect,
} from '@blueprintjs/core'
import { FieldArray } from 'formik'
import React, { Fragment } from 'react'
import SegmentAttributeFilter from './SegmentAttributeFilter'

export const OutletFilterSection = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  attributes,
  marketplaceId,
}) => {
  const segmentOpenStatusOptions = [
    { label: 'All', value: 'ALL' },
    { label: 'Open', value: 'OPEN' },
    { label: 'Closed', value: 'CLOSED' },
  ]
  const defaultFilter = {
    attribute: '',
    values: [],
  }

  const defaultGroup = {
    filterGroupType: 'ALL',
    filters: [defaultFilter],
  }
  let segmentCurrentOpenStatusCount = 0

  return (
    <Fragment>
      <H5>Filters</H5>
      <FormGroup label="Open / Closed Status">
        <HTMLSelect
          name={`openStatusFilter`}
          options={segmentOpenStatusOptions.map(attribute => ({
            ...attribute,
            key: attribute.value,
          }))}
          value={values.openStatusFilter}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup label="Attributes">
        <FieldArray
          name="groups"
          render={groupsArrayHelper => (
            <Card>
              <div>
                {values &&
                  values.groups.map((filterGroup, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          borderLeft: 'solid 3px #E6ECEF',
                          padding: 6,
                          borderRadius: 0,
                          margin: 0,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <strong>Group {i + 1}</strong>
                          <ControlGroup>
                            {
                              <Button
                                icon="trash"
                                minimal
                                onClick={() => groupsArrayHelper.remove(i)}
                              />
                            }
                          </ControlGroup>
                        </div>
                        <FormGroup>
                          <FieldArray
                            name={`groups[${i}].filters`}
                            render={arrayHelpers => {
                              return (
                                <div>
                                  {filterGroup.filters.map(
                                    (filter, filterIndex) => {
                                      const isLastFilter =
                                        filterGroup.filters.length - 1 ===
                                        filterIndex

                                      let thisFilterAttributes = [...attributes]
                                      if (!segmentCurrentOpenStatusCount) {
                                        segmentCurrentOpenStatusCount++
                                      } else {
                                        thisFilterAttributes =
                                          thisFilterAttributes.filter(
                                            attribute =>
                                              attribute.value !== 'open_status'
                                          )
                                      }

                                      return (
                                        <div key={filterIndex}>
                                          <SegmentAttributeFilter
                                            attributes={thisFilterAttributes}
                                            key={filterIndex}
                                            filter={filter}
                                            index={filterIndex}
                                            onChange={handleChange}
                                            arrayHelpers={arrayHelpers}
                                            setFieldValue={setFieldValue}
                                            marketplaceId={marketplaceId}
                                            formNamePrefix={`groups[${i}].filters[${filterIndex}]`}
                                            currentValues={{ ...values }}
                                            errors={
                                              errors.groups &&
                                              errors.groups[i] &&
                                              errors.groups[i].filters &&
                                              errors.groups[i].filters[
                                                filterIndex
                                              ]
                                                ? errors.groups[i].filters[
                                                    filterIndex
                                                  ]
                                                : undefined
                                            }
                                          />

                                          {isLastFilter ? null : (
                                            <HTMLSelect
                                              minimal
                                              name={`groups[${i}].filterGroupType`}
                                              options={[
                                                {
                                                  value: 'ALL',
                                                  label: 'AND',
                                                },
                                                {
                                                  value: 'ANY',
                                                  label: 'OR',
                                                },
                                              ]}
                                              value={
                                                filterGroup.filterGroupType
                                              }
                                              onChange={handleChange}
                                            />
                                          )}
                                        </div>
                                      )
                                    }
                                  )}
                                  <Button
                                    style={{
                                      borderLeft: 'solid 3px #1968f6',
                                      padding: 6,
                                      borderRadius: 0,
                                    }}
                                    intent="primary"
                                    icon="plus"
                                    type="button"
                                    minimal
                                    onClick={() =>
                                      arrayHelpers.push(defaultFilter)
                                    }
                                  >
                                    Add Filter
                                  </Button>
                                </div>
                              )
                            }}
                          />
                        </FormGroup>
                      </div>
                    )
                  })}
              </div>
              <Button
                style={{
                  borderLeft: 'solid 3px #1968f6',
                  padding: 6,
                  borderRadius: 0,
                }}
                intent="primary"
                icon="plus"
                type="button"
                minimal
                onClick={() => groupsArrayHelper.push(defaultGroup)}
              >
                Add Group
              </Button>
            </Card>
          )}
        ></FieldArray>
      </FormGroup>
    </Fragment>
  )
}
