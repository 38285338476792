import gql from 'graphql-tag'

const UPDATE_AND_SYNCHRONISE_EMAIL_THEME = gql`
  mutation updateAndSynchroniseEmailTheme(
    $theme: EmailTheme!
    $marketplaceId: String!
  ) {
    updateAndSynchroniseEmailTheme(
      theme: $theme
      marketplaceId: $marketplaceId
    ) {
      message
      marketplace {
        id
        emailBackgroundColor
        emailBodyColor
        emailButtonColor
        emailFontColor
        emailFooter
        emailFooterColor
        emailImageUrl
        emailShowImage
        emailShowCustomLogo
        uploadInProgress
        brandColor
        logoImage
        name
      }
    }
  }
`

export default UPDATE_AND_SYNCHRONISE_EMAIL_THEME
