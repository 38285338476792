import gql from 'graphql-tag'

const GET_ALLOW_REVIEW = gql`
  query getAllowReviewFlagForOutlet($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        marketplace {
          id
          allowReviews
        }
      }
    }
  }
`

export default GET_ALLOW_REVIEW
