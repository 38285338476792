import gql from 'graphql-tag'

const GET_MARKETPLACE_BILLING_FEATURES = gql`
  query getMarketplaceFeatures($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        billing {
          id
          livemode
          currency
          paymentMethods
          products {
            id
            service
            subscribed
          }
          subscription {
            id
            services
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE_BILLING_FEATURES
