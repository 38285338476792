import alarmSound from '@assets/sounds/alarm.mp3'
import {
  Button,
  ButtonGroup,
  Card,
  ControlGroup,
  Divider,
  HTMLTable,
  Icon,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import DateTime from '@components/DateTime/DateTime'
import OrderStatusEnum from '@components/Enum/OrderStatusEnum'
import Favicon from '@components/Favicon/Favicon'
import FilterRow from '@components/FilterRow/FilterRow'
import OutletLink from '@components/OpenStatus/OutletLink'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'
import PrintOrderModal from '@components/Orders/Order/PrintOrder.modal'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Sound, { TWO_MINUTES_AS_MILISECONDS } from '@components/Sound/Sound'
import { Pager, Search, StaticFilter } from '@components/Toolbar'
import Dropdown from '@components/Toolbar/RadioFilter'
import SettingsToggle from '@components/Toolbar/SettingsToggle'
import { canView } from '@stores/userStore'
import { removeUnderscores } from '@utils/helpers'
import ls from '@utils/localStorage'
import { isInAlarm } from '@utils/order/isInAlarm'
import { format, isSameDay, startOfDay } from 'date-fns'
import { upperFirst } from 'lodash'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  ArrayParam,
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import StuartIcon from '../../assets/icons/delivery-network-providers/stuart.png'
import WayboxIcon from '../../assets/icons/delivery-network-providers/waybox.png'
import { useModal } from '../../providers/ModalProvider'
import { DATE_FILTER_TYPES } from '../../utils/datetime'
import { PaymentMethod } from '../PaymentMethod/PaymentMethod'
import DateRangeFilter from '../Toolbar/DateRangeFilter/DateRangeFilter'
import { useDateRangeQueryParams } from '../Toolbar/DateRangeFilter/useDateRangeQueryParams'
import RoleAwareBusinessFilter, {
  RoleAwareBusinessFilterField,
} from '../Toolbar/RoleAwareBusinessFilter/RoleAwareBusinessFilter'
import { useRoleAwareBusinessFilterQueryParams } from '../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import { getOrderStatusMapping } from './helpers/helpers'
import OrderStatusInfo from './Order/OrderStatusInfo'
import GET_ORDERS from './queries/getOrders.query'
import debouncedPropsHoc from '../../utils/debouncedPropsHoc'
import Query from '../Query/Query'
import {
  GetOrdersQuery,
  GetOrdersQueryVariables,
} from './queries/getOrders.query.generated'

const DebouncedQuery = debouncedPropsHoc(
  Query<GetOrdersQuery, GetOrdersQueryVariables>
)

const DELIVERY_PROVIDER_TO_TAG_ICON = {
  ICABBI: 'taxi',
  STUART: <img src={StuartIcon} height="16" width="16" />,
  WAYBOX: <img src={WayboxIcon} height="16" width="16" />,
  UBER_DIRECT: (
    <svg
      style={{ backgroundColor: 'black' }}
      height="16"
      width="16"
      stroke="white"
      fill="white"
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2104.51 1280.94"
    >
      <title>Uber_Logo_Black_RGB</title>
      <path d="M595.67,803.49c59.13,0,104.85-45.73,104.85-113.4V426.72h64V853.48H701.13V813.85c-28.65,29.87-68.27,46.94-112.78,46.94-91.45,0-161.56-66.45-161.56-167v-267h64V690.09c0,68.89,45.11,113.4,104.86,113.4" />
      <path d="M812.09,426.73h61.57V582.19A153.73,153.73,0,0,1,984,535.85c91.45,0,163.38,72.55,163.38,162.78,0,89.62-71.93,162.16-163.38,162.16a155.13,155.13,0,0,1-111-46.33v39h-61ZM979.74,806.54c58.53,0,106.69-48.17,106.69-107.91,0-60.36-48.16-107.91-106.69-107.91-59.14,0-107.3,47.55-107.3,107.91,0,59.74,47.55,107.91,107.3,107.91" />
      <path d="M1331.51,536.46c89.61,0,155.46,68.89,155.46,161.56v20.12H1234c8.54,50.6,50.6,88.4,103,88.4,36,0,66.46-14.64,89.62-45.73l44.51,32.92c-31.1,41.46-77.43,66.45-134.13,66.45-93.27,0-165.21-69.5-165.21-162.16,0-87.79,68.89-161.56,159.73-161.56m-96.33,132.91h189.6c-10.36-47.56-48.77-79.26-94.49-79.26s-84.13,31.7-95.11,79.26" />
      <path d="M1652.18,597.43c-40.24,0-69.5,31.09-69.5,79.25v176.8H1521.1V542.56h61V581c15.24-25,40.24-40.85,74.38-40.85h21.33v57.31Z" />
    </svg>
  ),
}
const DEFAULT_RECORDS = 25
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
  orderBy: 'createdAt_DESC',
}

const PageLayoutWrapper = ({
  product,
  totalCount,
  totalPending,
  totalInprogress,
  totalCompleted,
  totalRejected,
  totalRefund,
  totalDeliveryNetworkFailure,
  children,
}) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: '/orders/', name: 'All', count: totalCount },
        {
          to: '/orders/pending',
          name: 'Pending',
          count: totalPending,
          intent: 'danger',
        },
        {
          to: '/orders/preparing',
          name: 'In Progress',
          count: totalInprogress,
        },
        { to: '/orders/complete', name: 'Complete', count: totalCompleted },
        { to: '/orders/rejected', name: 'Rejected', count: totalRejected },
        { to: '/orders/refund', name: 'Refund', count: totalRefund },
        {
          to: '/orders/failed',
          name: 'Failed',
          count: totalDeliveryNetworkFailure,
        },
      ],
      persistQueryParamsBetweenTabs: true,
    })
  }, [
    configurePageLayout,
    product,
    totalCount,
    totalPending,
    totalInprogress,
    totalCompleted,
    totalRejected,
    totalRefund,
  ])

  return children
}

const Orders = props => {
  useRoleAwareBusinessFilterQueryParams()
  const {
    marketplaceIds: marketplaceFilter,
    marketplaceBusinessIds,
    outletIds,
    onChangeMarketplaceIds: onChangeMarketplaceFilter,
  } = useRoleAwareBusinessFilterQueryParams()

  const [
    {
      restaurantFilter = [],
      paymentFilter = null,
      fulfilment = [],
      deliveryNetworkProvider = [],
    },
    setQueryParams,
  ] = useQueryParams({
    viewOrder: StringParam,
    restaurantFilter: DelimitedArrayParam,
    paymentFilter: StringParam,
    fulfilment: ArrayParam,
    deliveryNetworkProvider: ArrayParam,
  })

  const [state, setState] = useState({
    ...DEFAULT_PAGINATION_STATE,
    selectedId: null,
    showAwaitingPayment: ls.get('showAwaitingPayment'),
    useExactTime: ls.get('useExactTime'),
    showDeliveryWindow: ls.get('showDeliveryWindow'),
    muteAlarms: ls.get('muteAlarms'),
    pollInterval: ls.get('pollInterval'),
  })
  const resetPaginationState = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      ...DEFAULT_PAGINATION_STATE,
    }))
  }, [])

  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      resetPaginationState()
    }
  }, [searchValue, resetPaginationState])

  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.DAY.key,
    defaultStartOfRangeDate: startOfDay(new Date()),
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.CUSTOM,
    ],
  }
  const {
    shiftedStartOfRangeDateTime: startOfRangeDateTime,
    shiftedEndOfRangeDateTime: endOfRangeDateTime,
  } = useDateRangeQueryParams(dateRangeFilterArgs)

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({ ...prevState, total: totalCount }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({ ...prevState, outcomeLength: returnCount }))
    }
  }

  const goToNext = (e, limit = 0) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    const skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    const skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const handlePaymentFilter = ({ id }) => {
    setQueryParams({ paymentFilter: paymentFilter === id ? undefined : id })
    resetPaginationState()
    resetSearch()
  }

  const resetFilters = () => {
    setQueryParams({
      restaurantFilter: undefined,
      fulfilment: undefined,
      paymentFilter: undefined,
    })
    onChangeMarketplaceFilter([])
    resetPaginationState()
    resetSearch()
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  const [id] = useQueryParams({ id: StringParam })

  const { statusFilter } = useParams()

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      ...getOrderStatusMapping(statusFilter),
      selectedId: id,
    }))
  }, [statusFilter, id])

  useEffect(() => {
    if (state.showDeliveryWindow !== ls.get('showDeliveryWindow')) {
      ls.set('showDeliveryWindow', state.showDeliveryWindow)
    }
    if (state.useExactTime !== ls.get('useExactTime')) {
      ls.set('useExactTime', state.useExactTime)
    }
    if (state.muteAlarms !== ls.get('muteAlarms')) {
      ls.set('muteAlarms', state.muteAlarms)
    }
    if (state.showAwaitingPayment !== ls.get('showAwaitingPayment')) {
      ls.set('showAwaitingPayment', state.showAwaitingPayment)
    }
    if (state.pollInterval !== ls.get('pollInterval')) {
      ls.set('pollInterval', state.pollInterval)
    }
  }, [
    state.showDeliveryWindow,
    state.useExactTime,
    state.muteAlarms,
    state.showAwaitingPayment,
    state.pollInterval,
  ])

  const disableFilters = Boolean(searchValue)
  const isOrderSearchLongerThanMinLength = searchValue.length > 7

  const { isOpen: printOrderModal } = useModal('printOrderModal')

  return (
    <Fragment>
      <PrintOrderModal open={printOrderModal} />
      <div className="bp5-table-frame">
        <FilterRow>
          <ButtonGroup>
            <DateRangeFilter {...dateRangeFilterArgs} />
            <Divider />
            <ControlGroup>
              <Search placeholder="Order number..." />
              <RoleAwareBusinessFilter
                fields={[
                  RoleAwareBusinessFilterField.marketplace,
                  RoleAwareBusinessFilterField.business,
                  RoleAwareBusinessFilterField.outlet,
                ]}
              />
              {/* <FulfilmentFilter
                fulfilmentFilter={fulfilmentFilter}
                onChange={e => handleFulfilmentFilter(e)}
                disabled={disableFilters}
              /> */}
              <StaticFilter
                options={[
                  { name: 'Network Delivery', key: 'NETWORK' },
                  { name: 'Delivery', key: 'DELIVERY' },
                  { name: 'Collection', key: 'COLLECTION' },
                  { name: 'Table Service', key: 'TABLE' },
                ]}
                name="fulfilment"
                displayName="Fulfilment"
              />

              {fulfilment?.includes('NETWORK') && (
                <StaticFilter
                  options={[
                    { name: 'Stuart', key: 'STUART' },
                    { name: 'Uber Direct', key: 'UBER_DIRECT' },
                    { name: 'Waybox', key: 'WAYBOX' },
                  ]}
                  name="deliveryNetworkProvider"
                  displayName="Provider"
                />
              )}
              <Dropdown
                disabled={state.total === null || disableFilters}
                selected={paymentFilter}
                setSelected={handlePaymentFilter}
                placeholder="Payment"
                items={[
                  { name: 'All', id: null },
                  { name: 'Card', id: 'CARD' },
                  { name: 'Cash', id: 'CASH' },
                ]}
              />
              <Button
                icon="filter-remove"
                onClick={resetFilters}
                disabled={
                  paymentFilter === null &&
                  marketplaceFilter.length === 0 &&
                  restaurantFilter.length === 0 &&
                  searchValue.length === 0
                }
              />
            </ControlGroup>
          </ButtonGroup>

          <ButtonGroup>
            <SettingsToggle
              useExactTime={state.useExactTime}
              showAwaitingPayment={state.showAwaitingPayment}
              showDeliveryWindow={state.showDeliveryWindow}
              pollInterval={state.pollInterval}
              isMuted={state.muteAlarms}
              updateState={(setting, value = null) => {
                switch (setting) {
                  case 'muteAlarms':
                    setState(prevState => ({
                      ...prevState,
                      muteAlarms: !prevState.muteAlarms,
                    }))
                    break
                  case 'useExactTime':
                    setState(prevState => ({
                      ...prevState,
                      useExactTime: !prevState.useExactTime,
                    }))
                    break
                  case 'showDeliveryWindow':
                    setState(prevState => ({
                      ...prevState,
                      showDeliveryWindow: !prevState.showDeliveryWindow,
                    }))
                    break
                  case 'showAwaitingPayment':
                    setState(prevState => ({
                      ...prevState,
                      showAwaitingPayment: !prevState.showAwaitingPayment,
                    }))
                    break
                  case 'pollInterval':
                    setState(prevState => ({
                      ...prevState,
                      pollInterval: value,
                    }))
                    break
                }
              }}
            />
            <Pager
              goToPrevious={e => goToPrevious(e)}
              goToNext={e => goToNext(e)}
              goToPage={goToPage}
              defaultNmbRecords={DEFAULT_RECORDS}
              skip={state.skip}
              total={state.total}
              outcomeLength={state.outcomeLength}
              totalCount={state.total}
              dataName="Orders"
            />
          </ButtonGroup>
        </FilterRow>
        <DebouncedQuery
          query={GET_ORDERS}
          variables={{
            ...(isOrderSearchLongerThanMinLength
              ? { orderNumber: searchValue }
              : {}),
            marketplaceIds: marketplaceFilter,
            outletIds,
            restaurantIds: marketplaceBusinessIds,
            ...state,
            afterDate: isOrderSearchLongerThanMinLength
              ? null
              : startOfRangeDateTime,
            beforeDate: isOrderSearchLongerThanMinLength
              ? null
              : endOfRangeDateTime,
            fulfilmentMethod:
              fulfilment && fulfilment.length > 0 ? fulfilment : undefined,
            deliveryNetworkProvider:
              fulfilment &&
              fulfilment.includes('NETWORK') &&
              deliveryNetworkProvider &&
              deliveryNetworkProvider.length > 0
                ? deliveryNetworkProvider
                : undefined,
            paymentMethod:
              paymentFilter === 'CARD'
                ? ['CARD_ON_DELIVERY', 'CARD_ON_COLLECTION', 'CARD_ON_TABLE']
                : paymentFilter === 'CASH'
                ? ['CASH_ON_DELIVERY', 'CASH_ON_COLLECTION']
                : undefined,

            ...getOrderStatusMapping(statusFilter, state.showAwaitingPayment),
          }}
          onCompleted={data => {
            if (data.orders) {
              setTotalCount(data.orders.totalCount, data.orders.count)
            }
          }}
          loaderTitle="Loading Orders"
          ErrorComponent={
            <div style={{ padding: 100 }}>
              <NonIdealState
                icon="th-list"
                title="Loading Error"
                description="Unable to load order data, please retry."
              />
            </div>
          }
          pollInterval={state.pollInterval || undefined}
        >
          {data => {
            const {
              totalCount = 0,
              totalPending = 0,
              totalInprogress = 0,
              totalCompleted = 0,
              totalRejected = 0,
              totalRefund = 0,
              totalDeliveryNetworkFailure = 0,
            } = data.orders || {}

            return (
              <PageLayoutWrapper
                product={props.product}
                totalCount={totalCount}
                totalPending={totalPending}
                totalInprogress={totalInprogress}
                totalCompleted={totalCompleted}
                totalRejected={totalRejected}
                totalRefund={totalRefund}
                totalDeliveryNetworkFailure={totalDeliveryNetworkFailure}
              >
                {!data || !data.orders || !data.orders.orders.length ? (
                  <div style={{ padding: 100 }}>
                    <NonIdealState
                      icon="th-list"
                      title="No Orders Found"
                      description={
                        searchValue
                          ? 'There are no orders that meet your search criteria.'
                          : 'There are no orders to display for the chosen interval.'
                      }
                      action={
                        searchValue || marketplaceFilter.length > 0 ? (
                          <Button
                            onClick={resetFilters}
                            minimal
                            intent="primary"
                          >
                            Clear Filters
                          </Button>
                        ) : null
                      }
                    />
                  </div>
                ) : (
                  <div className="bp5-table-container bp5-scrollable">
                    <Card className="bp5-nopad">
                      <HTMLTable bordered={false} interactive={true}>
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Customer</th>
                            {canView('customers') && <th>Value</th>}
                            <th>Outlet</th>
                            <th>Total</th>
                            <th>Creation</th>
                            <th colSpan={2}>Status</th>
                            <th>Last Update</th>
                            <th>Fulfilment</th>
                            {state.showDeliveryWindow && <th>Window</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {data.orders.orders.map(order => {
                            const {
                              id,
                              orderNumber,
                              outlet,
                              customer,
                              grossTotal,
                              createdAt,
                              updatedAt,
                              orderStatus,
                              fulfillmentMethod,
                              estimatedDeliveryDate,
                              estimatedCompletionTime,
                              paymentMethod,
                              asap,
                              deliveryNetworkBookingStatus,
                              deliveryNetworkProvider,
                              tableSnapshot,
                              selectedDeliveryWindow,
                              autoRejectAt,
                              autoAcceptedAt,
                              isAutoRejected,
                              cancellationNotes,
                            } = order

                            const isAlarm = isInAlarm(orderStatus, autoRejectAt)

                            const customerName = `${upperFirst(
                              customer.firstName
                            )} ${upperFirst(customer.lastName.charAt(0))}`

                            return (
                              <tr key={id}>
                                <td>
                                  <a
                                    onClick={() => {
                                      setQueryParams({
                                        viewOrder: id,
                                      })
                                    }}
                                  >
                                    #{orderNumber}
                                  </a>
                                </td>
                                <td>
                                  {canView('customers') ? (
                                    <Fragment>
                                      <Favicon
                                        src={outlet.marketplace.faviconImage}
                                        label={outlet.marketplace.name}
                                      />
                                      &nbsp;
                                      <Link to={`/customer/${customer.id}`}>
                                        {customerName}
                                      </Link>
                                    </Fragment>
                                  ) : (
                                    customerName
                                  )}
                                </td>
                                {canView('customers') && (
                                  <td>
                                    <Popover
                                      interactionKind={
                                        PopoverInteractionKind.HOVER
                                      }
                                      position={Position.RIGHT_TOP}
                                      content={
                                        <HTMLTable>
                                          <thead>
                                            <tr>
                                              <th>Total Orders</th>
                                              <td>
                                                <Link
                                                  to={`/customer/${customer.id}/orders`}
                                                >
                                                  {
                                                    customer.totalCompletedOrders
                                                  }
                                                </Link>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>Total Value</th>
                                              <td>
                                                <Currency
                                                  amount={customer.totalValue}
                                                />
                                              </td>
                                            </tr>
                                          </thead>
                                        </HTMLTable>
                                      }
                                    >
                                      {customer.totalCompletedOrders > 1 ? (
                                        <Currency
                                          amount={customer.totalValue}
                                        />
                                      ) : (
                                        <Icon
                                          icon={'star'}
                                          color={'#FFC12F'}
                                          iconSize={14}
                                        />
                                      )}
                                    </Popover>
                                  </td>
                                )}
                                <td>
                                  <OutletLink
                                    outlet={outlet}
                                    displayBusinessName
                                  />
                                </td>
                                <td>
                                  <PaymentMethod
                                    grossTotal={grossTotal}
                                    paymentMethod={paymentMethod}
                                    orderStatus={orderStatus}
                                  />
                                </td>
                                <td>
                                  <span style={isAlarm ? { color: 'red' } : {}}>
                                    {format(new Date(createdAt), 'HH:mm')}
                                  </span>
                                  &nbsp;
                                  {!isSameDay(
                                    new Date(),
                                    new Date(createdAt)
                                  ) && format(new Date(createdAt), 'd/MM')}
                                </td>
                                <td>
                                  <OrderStatusEnum
                                    orderStatus={orderStatus}
                                    autoRejectAt={autoRejectAt}
                                    autoAcceptedAt={autoAcceptedAt}
                                    isAutoRejected={isAutoRejected}
                                    asap={asap}
                                    onClick={() => {
                                      setQueryParams({
                                        viewOrder: id,
                                      })
                                    }}
                                    details={
                                      isAutoRejected && cancellationNotes
                                    }
                                    actions={
                                      <OrderStatusInfo order={order} minimal />
                                    }
                                  />
                                  {/* TODO: Fix <Sound> so it loads only once and is not a react component. */}
                                  {!state.muteAlarms && isAlarm && (
                                    <Sound
                                      src={alarmSound}
                                      repeatInterval={
                                        TWO_MINUTES_AS_MILISECONDS
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {fulfillmentMethod === 'NETWORK' && (
                                    <Tag
                                      icon={
                                        DELIVERY_PROVIDER_TO_TAG_ICON[
                                          deliveryNetworkProvider
                                        ] || 'taxi'
                                      }
                                      intent={'primary'}
                                      minimal
                                    >
                                      {removeUnderscores(
                                        deliveryNetworkBookingStatus
                                      )}
                                    </Tag>
                                  )}
                                  {fulfillmentMethod === 'TABLE' && (
                                    <Tag
                                      icon={'locate'}
                                      intent={'primary'}
                                      minimal
                                    >
                                      {`Table ${(
                                        (tableSnapshot?.friendlyName as string) ||
                                        'unknown'
                                      ).replace(/^table\s/i, '')}`}
                                    </Tag>
                                  )}
                                </td>
                                <td>
                                  <DateTime
                                    dateTime={updatedAt}
                                    timeAgo={!state.useExactTime}
                                  />
                                </td>
                                <td>
                                  <FulfilmentStatus
                                    orderStatus={orderStatus}
                                    useExactTime={state.useExactTime}
                                    fulfillmentMethod={fulfillmentMethod}
                                    createdAt={createdAt}
                                    updatedAt={updatedAt}
                                    estimatedDeliveryDate={
                                      deliveryNetworkProvider === 'WAYBOX'
                                        ? estimatedDeliveryDate
                                        : selectedDeliveryWindow
                                        ? (selectedDeliveryWindow.end as string)
                                        : estimatedDeliveryDate
                                    }
                                    estimatedCompletionTime={
                                      selectedDeliveryWindow
                                        ? (selectedDeliveryWindow.start as string)
                                        : estimatedCompletionTime
                                    }
                                    asap={asap}
                                    tableFriendlyName={(
                                      (tableSnapshot?.friendlyName as string) ||
                                      'unknown'
                                    ).replace(/^table\s/i, '')}
                                    selectedDeliveryWindow={
                                      selectedDeliveryWindow
                                    }
                                  />
                                </td>
                                {state.showDeliveryWindow && (
                                  <td>
                                    {selectedDeliveryWindow ? (
                                      <Fragment>
                                        {selectedDeliveryWindow.start
                                          ? format(
                                              new Date(
                                                selectedDeliveryWindow.start as string
                                              ),
                                              'HH:mm'
                                            )
                                          : estimatedCompletionTime
                                          ? format(
                                              new Date(estimatedCompletionTime),
                                              'HH:mm'
                                            )
                                          : null}
                                        {' - '}
                                        {selectedDeliveryWindow.end &&
                                          format(
                                            new Date(
                                              selectedDeliveryWindow.end as string
                                            ),
                                            'HH:mm'
                                          )}
                                      </Fragment>
                                    ) : estimatedDeliveryDate ? (
                                      format(
                                        new Date(estimatedDeliveryDate),
                                        'HH:mm'
                                      )
                                    ) : null}
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </div>
                )}
              </PageLayoutWrapper>
            )
          }}
        </DebouncedQuery>
      </div>
    </Fragment>
  )
}

export default Orders
