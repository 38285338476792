import React from 'react'
import {
  FormGroup,
  InputGroup,
  Classes,
  Button,
  RadioGroup,
  Radio,
  Intent,
  Card,
  Drawer,
  Tooltip,
  Position,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import { deliveryZoneType } from '@utils/constants'
import GET_MARKETPLACE_ZONE from '@components/DeliveryZone/queries/getMarketplaceZone.query'
import Query from '@components/Query/Query'
import GeoFence from '@components/GeoJSON/GeoFence'

const AddForm = ({
  values,
  handleSubmit,
  handleChange,
  setFieldValue,
  errors,
  marketplaceId,
  modalClose,
  modalOpen,
}) => (
  <Drawer
    isOpen={modalOpen}
    onClose={modalClose}
    title="Delivery Zone"
    size={
      values.deliveryZoneType === deliveryZoneType.COORDINATE_POLYGON
        ? Drawer.SIZE_LARGE
        : Drawer.SIZE_SMALL
    }
  >
    <div className={Classes.DIALOG_BODY}>
      <Query query={GET_MARKETPLACE_ZONE} variables={{ id: marketplaceId }}>
        {({ getMarketplaces: { regions } }) => {
          const [marketplace] = regions

          return (
            <form onSubmit={handleSubmit}>
              <div className="bp5-layout-row" style={{ maxHeight: '80%' }}>
                {values.deliveryZoneType ===
                deliveryZoneType.COORDINATE_POLYGON ? (
                  <Card className="bp5-layout-col bp5-nopad">
                    <GeoFence
                      features={[marketplace, ...marketplace.outlets]}
                      handleUpdate={() => null}
                      setFieldValue={setFieldValue}
                    />
                  </Card>
                ) : // show nothing for stuart and radius
                null}

                <Card
                  className={
                    values.deliveryZoneType ===
                    deliveryZoneType.COORDINATE_POLYGON
                      ? 'bp5-layout-col-side'
                      : 'bp5-layout-col'
                  }
                >
                  <FormGroup
                    label="Name"
                    labelFor="name"
                    helperText={errors.name ? errors.name : ''}
                    intent={Intent.DANGER}
                  >
                    <InputGroup
                      type="text"
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      intent={errors.name ? Intent.DANGER : Intent.NONE}
                    />
                  </FormGroup>

                  <FormGroup
                    label="Type"
                    labelFor="deliveryZoneType"
                    helperText={
                      errors.deliveryZoneType ? errors.deliveryZoneType : ''
                    }
                    intent={Intent.DANGER}
                  >
                    <RadioGroup
                      name="deliveryZoneType"
                      selectedValue={values.deliveryZoneType}
                      onChange={handleChange}
                    >
                      <Radio
                        label="Polygon"
                        value={deliveryZoneType.COORDINATE_POLYGON}
                      />
                      <Radio
                        label="Radius"
                        value={deliveryZoneType.RADIUS_AROUND_OUTLET}
                      />
                      <Radio
                        label="Uber Direct"
                        value={deliveryZoneType.UBER_DIRECT}
                      />
                      <Radio label="Stuart" value={deliveryZoneType.STUART} />
                      <Radio label="Waybox" value={deliveryZoneType.WAYBOX} />
                    </RadioGroup>
                  </FormGroup>
                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {values.deliveryZoneType ===
                      deliveryZoneType.COORDINATE_POLYGON &&
                    !values.geoFence ? (
                      <Tooltip
                        content="Draw a delivery zone on the map"
                        position={Position.LEFT}
                      >
                        <Button disabled type="submit" text="Save" />
                      </Tooltip>
                    ) : (
                      <Button type="submit" text="Save" />
                    )}
                  </div>
                </Card>
              </div>
            </form>
          )
        }}
      </Query>
    </div>
  </Drawer>
)

AddForm.propTypes = {
  values: object,
  handleSubmit: func,
  handleChange: func,
  setFieldValue: func,
}

export default AddForm
