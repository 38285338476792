import { Callout } from '@blueprintjs/core'
import split from 'lodash/split'
import { object } from 'prop-types'
import { Fragment, useContext, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'

import Discounts from '@components/Discount/Discounts'
import Users from '@components/Users/Users'
import {
  canPerformAction,
  isMenuEditor,
  isOutletFinancialUser,
  isOutletUser,
} from '@stores/userStore'
import Dashboard from './Dashboard/Dashboard'
import Details from './Details/Details'
import Financials from './Financials/Financials'
import Menu from './Menu/Menu'
import Options from './Options/Options'
import Outlets from './Outlets/Outlets'
import Outlet from '@components/Outlet/Outlet'

import MemberDiscounts from '@components/MemberDiscounts/MemberDiscounts'
import MembersDiscountProvider, {
  MembersDiscountContext,
} from '@components/MemberDiscounts/MembersDiscountProvider'
import usersPageContext from '@components/Users/usersPageContext'
import { isPeakTimeIncludingFridayAndSaturday } from '@utils/isPeakTime'
import { NoPermissions } from '@components/UI/Permission/Permission'
import { ModalContext } from '../../providers/ModalProvider'
import productDictionary from '../../utils/productDictionary'
import UsersPageWrapper from '../Users/UsersPageWrapper'
import BusinessAnalyticsContextProvider from './Analytics/BusinessAnalyticsContext'

const showCacheWarning = isPeakTimeIncludingFridayAndSaturday()

const Restaurant = () => {
  const { restaurants: restaurantId } = useParams()
  const userIsMenuEditor = isMenuEditor()
  const userIsOutletFinancialUser = isOutletFinancialUser()
  const { setState: toggleModal } = useContext(ModalContext)
  const { pathname } = useLocation()

  const tabButton = (
    openInviteUserModal,
    setModalInviteMemberToDiscountOpen
  ) => {
    const path = split(pathname, '/')[3]

    let button
    switch (path) {
      case 'menu':
        button = {
          text: 'Add Menu',
          onClick: () =>
            toggleModal({
              parentMenuModal: true,
            }),
        }
        break
      case 'outlets':
        if (!userIsMenuEditor && !userIsOutletFinancialUser) {
          button = {
            text: 'Add Outlet',
            onClick: () =>
              toggleModal({
                outletModal: true,
              }),
          }
        }
        break
      case 'options':
        button = {
          text: 'Add Option',
          onClick: () =>
            toggleModal({
              optionModal: true,
            }),
        }
        break
      case 'discounts':
        button = canPerformAction('addDiscount')
          ? {
              text: 'Add Discount',
              onClick: () =>
                toggleModal({
                  addDiscountModal: true,
                }),
            }
          : null
        break
      case 'users':
        button = canPerformAction('inviteUser')
          ? {
              text: 'Invite User',
              onClick: () => openInviteUserModal(true),
            }
          : null
        break
      case 'members':
        button = canPerformAction('enrollMemberToDiscount')
          ? {
              text: 'Invite Member',
              onClick: () => setModalInviteMemberToDiscountOpen(true),
            }
          : null
        break
      default:
        button = null
    }

    return button
  }

  const { setModalOpen: openInviteUserModal } = useContext(usersPageContext)
  const {
    setModalInviteMemberToDiscountOpen: setModalInviteMemberToDiscountOpen,
  } = useContext(MembersDiscountContext)
  const { configurePageLayout } = useContext(PageLayoutContext)

  const routePrefix = pathname.split('/').slice(0, 3).join('/')
  const isOnAnOutletsPage = pathname.includes('outlets/')

  useEffect(() => {
    const tabs = [
      {
        to: `${routePrefix}/overview`,
        name: 'Overview',
      },
      {
        to: `${routePrefix}/details`,
        name: 'Details',
        hide: !canPerformAction('editRestaurant') || userIsMenuEditor,
      },
      {
        to: `${routePrefix}/financial`,
        name: 'Financial',
        hide: userIsMenuEditor,
      },
      {
        to: `${routePrefix}/menu`,
        name: 'Menu',
      },
      {
        to: `${routePrefix}/options`,
        name: 'Options',
      },
      {
        to: `${routePrefix}/outlets`,
        name: 'Outlets',
      },
      {
        to: `${routePrefix}/discounts`,
        name: 'Discounts',
        hide: userIsMenuEditor,
      },
      {
        to: `${routePrefix}/users`,
        name: 'Users',
        hide: userIsMenuEditor,
      },
      {
        to: `${routePrefix}/members`,
        name: 'Members',
        hide: userIsMenuEditor,
      },
    ]
    if (!isOnAnOutletsPage) {
      configurePageLayout({
        product: productDictionary.RESTAURANT,
        tabs,
        button: tabButton(
          openInviteUserModal,
          setModalInviteMemberToDiscountOpen
        ),
      })
    }
  }, [
    location.pathname,
    configurePageLayout,
    openInviteUserModal,
    isOnAnOutletsPage,
  ])

  if (isOutletFinancialUser() || isOutletUser()) {
    return <NoPermissions />
  }

  return (
    <Fragment>
      {showCacheWarning && (
        <Fragment>
          <Callout intent="warning">
            We are currently in a peak order period, during this time it may
            take up to 60 minutes to reflect updates to your menu or restaurant
            settings.
          </Callout>
          <br />
        </Fragment>
      )}
      <section>
        <Routes>
          <Route path="/" element={<Navigate to="overview" replace />} />
          {/*

              TODO fix These redirects handle legacy menu routing as the view has been
              changed to use a querystring rather then absolute url path.
          */}
          {/* <Redirect
            from={`menu/tree`}
            to={`menu?view=tree`}
            exact
          />
          <Redirect
            from={`menu/list`}
            to={`menu`}
            exact
          /> */}
          <Route path={`/outlets`} element={<Outlets />} />
          <Route path={`/outlets/:outlet/*`} element={<Outlet />} />
          <Route path="/overview" element={<Dashboard />} />
          <Route path={`details`} element={<Details />} />
          <Route path={`financial`} element={<Financials />} />
          <Route path={`menu`} element={<Menu />} />
          <Route path={`options`} element={<Options />} />
          <Route path={`discounts`} element={<Discounts />} />
          <Route
            path={`users`}
            element={<Users restaurantId={restaurantId} />}
          />
          <Route
            path={`members`}
            element={<MemberDiscounts restaurantId={restaurantId} />}
          />
        </Routes>
      </section>
    </Fragment>
  )
}

Restaurant.propTypes = {
  location: object,
}

const RestaurantPageWrapper = props => (
  <UsersPageWrapper entityName="restaurant">
    <MembersDiscountProvider>
      <BusinessAnalyticsContextProvider>
        <Restaurant {...props} />
      </BusinessAnalyticsContextProvider>
    </MembersDiscountProvider>
  </UsersPageWrapper>
)

export default RestaurantPageWrapper
