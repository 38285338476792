import { set, get } from 'lodash'
import moment from 'moment'
import { getUserAgentFromOrder } from './getUserAgentFromOrder'
import { statusAttributeMap } from './maps'

const resolutionFormatMap = {
  day: 'DD-MM-YYYY',
  week: 'W-YYYY',
  month: 'MMM-YYYY',
  year: 'YYYY',
}

const defaultValues = {
  name: '',
  numberOfOrders: 0,

  numberOfOrdersWithNoDiscount: 0,
  numberOfOrdersWithRegularDiscount: 0,
  numberOfOrdersWithMemberDiscount: 0,
  numberOfOrdersWithVoucherDiscount: 0,

  subTotalWithNoDiscounts: 0,
  subTotalWithRegularDiscounts: 0,
  subTotalWithMemberDiscounts: 0,
  subTotalWithVoucherDiscounts: 0,

  subTotalDiscountedRegularDiscounts: 0,
  subTotalDiscountedMemberDiscounts: 0,
  subTotalDiscountedVoucherDiscounts: 0,

  outlets: [],
  discountIds: [],

  statuses: {},
  paymentMethods: {},
  fulfillmentMethods: {},
  userAgents: {},
}

/**
 * @param {object[]} rawOrdersData - an array of partial orders with discount data
 * @param {string} rawOrdersData[].outletId
 * @param {number} rawOrdersData[].discountAmount
 * @param {number} rawOrdersData[].subtotalGross
 * @param {string} rawOrdersData[].fulfillmentMethod
 * @param {string} rawOrdersData[].paymentMethod
 * @param {?string} rawOrdersData[].voucherKey
 * @param {string} rawOrdersData[].orderStatus
 * @param {object} rawOrdersData[].userAgent
 * @param {object} rawOrdersData[].userAgent.browser
 * @param {object} rawOrdersData[].userAgent.device
 * @param {object} rawOrdersData[].userAgent.os
 * @param {string} rawOrdersData[].userAgent.raw
 * @param {object} rawOrdersData[].userAgent.client
 * @param {string} rawOrdersData[].createdAt
 * @param {?string} rawOrdersData[].acceptedAt
 * @param {object} rawOrdersData[].discountJson
 * @param {string} createdAt
 * @enum {string} resolution
 * @param {boolean} showBlankEntries
 * @returns {object}
 */
export const transformCustomerDiscountsRawData = (
  rawOrdersData,
  resolution,
  createdAt,
  showBlankEntries
) => {
  const userAgentEntries = new Set()
  const accumulator = {}

  if (rawOrdersData && showBlankEntries) {
    const keyMoment = moment(createdAt)
    const end = moment()

    for (
      keyMoment;
      keyMoment.isSameOrBefore(end, resolution);
      keyMoment.add(1, resolution)
    ) {
      set(accumulator, keyMoment.format(resolutionFormatMap[resolution]), {
        ...defaultValues,
        numberOfOrders: 0,

        name: keyMoment.format(resolutionFormatMap[resolution]),
        paymentMethods: {},
        fulfillmentMethods: {},
        statuses: {},
        userAgents: {},
      })
    }
  }

  const formattedData = rawOrdersData
    ? rawOrdersData.reduce((acc, rawOrder) => {
        // group orders by resolution (ie day / week / month / year)
        const key = moment(rawOrder.createdAt).format(
          resolutionFormatMap[resolution]
        )

        if (!get(acc, key)) {
          set(acc, key, {
            ...defaultValues,
            name: key,
            paymentMethods: {},
            fulfillmentMethods: {},
            statuses: {},
            userAgents: {},
          })
        }
        const previousValues = get(acc, key)

        if (rawOrder.discountJson) {
          const appliedDiscount =
            typeof rawOrder.discountJson === 'string'
              ? JSON.parse(rawOrder.discountJson)
              : rawOrder.discountJson

          // set discount ids
          acc[key].discountIds = new Set([
            ...previousValues.discountIds,
            appliedDiscount.id,
          ])

          if (rawOrder.voucherKey) {
            // voucher discount
            acc[key].subTotalWithVoucherDiscounts =
              previousValues.subTotalWithVoucherDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedVoucherDiscounts =
              previousValues.subTotalDiscountedVoucherDiscounts +
              rawOrder.discountAmount
            rawOrder.discountAmount
            acc[key].numberOfOrdersWithVoucherDiscount =
              previousValues.numberOfOrdersWithVoucherDiscount + 1
          } else if (appliedDiscount.customerEnrolled) {
            // member discount
            acc[key].subTotalWithMemberDiscounts =
              previousValues.subTotalWithMemberDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedMemberDiscounts =
              previousValues.subTotalDiscountedMemberDiscounts +
              rawOrder.discountAmount
            acc[key].numberOfOrdersWithMemberDiscount =
              previousValues.numberOfOrdersWithMemberDiscount + 1
          } else {
            // regular discount
            acc[key].subTotalWithRegularDiscounts =
              previousValues.subTotalWithRegularDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedRegularDiscounts =
              previousValues.subTotalDiscountedRegularDiscounts +
              rawOrder.discountAmount
            acc[key].numberOfOrdersWithRegularDiscount =
              previousValues.numberOfOrdersWithRegularDiscount + 1
          }
        } else {
          // no discount
          acc[key].subTotalWithNoDiscounts =
            previousValues.subTotalWithNoDiscounts + rawOrder.subtotalGross
          acc[key].numberOfOrdersWithNoDiscount =
            previousValues.numberOfOrdersWithNoDiscount + 1
        }

        // count orders
        acc[key].numberOfOrders = previousValues.numberOfOrders + 1

        // set outlets
        acc[key].outlets = new Set([
          ...previousValues.outlets,
          rawOrder.outletId,
        ])

        // set order status
        const attribute = get(
          statusAttributeMap,
          rawOrder.orderStatus,
          'UNCATEGORIZED'
        )
        set(
          acc[key].statuses,
          attribute,
          get(acc[key].statuses, attribute, 0) + 1
        )

        // fulfillment
        set(
          acc[key].fulfillmentMethods,
          rawOrder.fulfillmentMethod,
          get(acc[key].fulfillmentMethods, rawOrder.fulfillmentMethod, 0) + 1
        )

        // payment
        set(
          acc[key].paymentMethods,
          rawOrder.paymentMethod,
          get(acc[key].paymentMethods, rawOrder.paymentMethod, 0) + 1
        )

        // user agent
        if (rawOrder.userAgent) {
          const userAgentKey = getUserAgentFromOrder(rawOrder.userAgent)

          acc[key].userAgents[`${userAgentKey}`] =
            get(acc[key].userAgents, `${userAgentKey}`, 0) + 1

          // update the set
          userAgentEntries.add(userAgentKey)
        }

        return acc
      }, accumulator)
    : []

  // transform obj into array
  const formattedDataArray = Object.values(formattedData)

  // order by date
  formattedDataArray.sort((currentEntry, nextEntry) =>
    moment(currentEntry.name, resolutionFormatMap[resolution]).diff(
      nextEntry.name,
      resolution
    )
  )

  // transform sets into arrays and calculate averages
  const transformedData = formattedDataArray.map(entry => {
    return {
      ...entry,

      numberOfOutlets: entry.outlets.size || 0,
      outlets: Array.from(entry.outlets),
      discountIds: Array.from(entry.discountIds),

      subTotalWithNoDiscounts: entry.subTotalWithNoDiscounts / 100,
      subTotalWithRegularDiscounts: entry.subTotalWithRegularDiscounts / 100,
      subTotalWithMemberDiscounts: entry.subTotalWithMemberDiscounts / 100,
      subTotalWithVoucherDiscounts: entry.subTotalWithVoucherDiscounts / 100,

      subTotalDiscountedRegularDiscounts:
        entry.subTotalDiscountedRegularDiscounts / 100,
      subTotalDiscountedMemberDiscounts:
        entry.subTotalDiscountedMemberDiscounts / 100,
      subTotalDiscountedVoucherDiscounts:
        entry.subTotalDiscountedVoucherDiscounts / 100,

      intervalOrderValueAverage:
        (entry.subTotalWithNoDiscounts +
          entry.subTotalWithRegularDiscounts +
          entry.subTotalWithMemberDiscounts +
          entry.subTotalWithVoucherDiscounts) /
          (entry.numberOfOrders * 100) || 0,
    }
  })

  return {
    transformedData,
    userAgentEntries: Array.from(userAgentEntries),
  }
}
