import React, { useMemo } from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import ADD_MARKETPLACE from './mutations/addMarketplace.mutation'
import GET_MARKETPLACES from '@components/Marketplaces/queries/getMarketplaces.query'
import MarketplaceForm from '@components/MarketplaceForm/MarketplaceForm'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { validation } from './validation'
import { createAwsOptions } from '@utils/createAwsOptions'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../providers/ModalProvider'
import { useMutation } from '@apollo/client'

const MarketplaceModal = () => {
  const { toggleModal, isOpen } = useModal('marketplaceModal')
  const navigate = useNavigate()
  const awsOptions = useMemo(createAwsOptions, [])

  const [addMarketplace] = useMutation(ADD_MARKETPLACE, {
    onError: defaultErrorHandler,
    onCompleted: ({ addMarketplace }) => {
      toggleModal(false)
      navigate(`/marketplaces/${addMarketplace.id}`)
    },
    refetchQueries: [{ query: GET_MARKETPLACES }],
  })

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => toggleModal(false)}
      title="Add Marketplace"
    >
      <div className={Classes.DIALOG_BODY}>
        <MarketplaceForm
          onSubmit={async values => {
            await addMarketplace({
              variables: {
                ...values,
              },
            })
          }}
          isCreate
          initialValues={{
            name: '',
            companyType: 'COMPANY',
            contactEmail: '',
            contactAddress: {
              firstLine: '',
              secondLine: '',
              thirdLine: '',
              city: '',
              country: { code: 'GB', name: 'United Kingdom' },
              postcode: '',
            },
            contactPhone: '',
            contactName: '',
            partnerId: '',
            ageRestrictionText: '',
            pinpointAwsAccountId: awsOptions[0].value,
            defaultCancelText:
              'We are sorry that there has been an issue with your order. If you have paid for your order online we will refund you automatically, there is no need to be in touch. Please allow 2-3 working days for your refund to be processed by your bank.',
            defaultRejectText:
              'We are unfortunately unable to accept your order. If you have paid for your order online we will not be taking any payment.',
          }}
          validationSchema={validation}
          awsOptions={awsOptions}
        />
      </div>
    </Dialog>
  )
}

export default MarketplaceModal
