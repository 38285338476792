import { Formik } from 'formik'
import { first, omit } from 'lodash'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import {
  isAtLeastMarketplaceUser,
  isOutletFinancialUser,
} from '@stores/userStore'
import Query from '@components/Query/Query'
import OutletDetailsForm from './OutletDetailsForm'
import ActivationForm from './ActivationForm'
import GET_OUTLET from './queries/getOutlet.query'
import EDIT_OUTLET_CONTACT_DETAILS from './mutations/editOutletContactDetails.mutation'
import TOGGLE_OUTLET_ACTIVATION from './mutations/toggleOutletActivation.mutation'
import Collection from './Collection'
import Promote from './Promote'
import { Row, Col } from '@components/_FlexGrid'
import { validation } from './validation'
import { HygieneRating } from './HygieneRating'
import SpecialOfferFlags from './SpecialOfferFlags'
import OutletCategories from './OutletCategories'
import { KycCallout } from '../shared/KycCallout'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { EditOutletContactDetailsMutation } from './mutations/editOutletContactDetails.mutation.generated'
import { GetOutletForDetailsTabQuery } from './queries/getOutlet.query.generated'
import { CompanyType } from '../../../types.generated'

const BLANK_ADDRESS = {
  firstLine: '',
  secondLine: '',
  thirdLine: '',
  city: '',
  postcode: '',
  country: { code: 'GB', name: 'United Kingdom' },
}

const isOnlineOverrideValues = [null, true, false]

const Details = () => {
  const userIsOutletFinancialUser = isOutletFinancialUser()
  const { outlet } = useParams()

  const [editOutlet] = useMutation<EditOutletContactDetailsMutation>(
    EDIT_OUTLET_CONTACT_DETAILS,
    {
      onError: defaultErrorHandler,
      onCompleted: () => successToast('Successfully Saved Outlet Details'),
      refetchQueries: [
        {
          query: GET_OUTLET,
          variables: { id: outlet },
        },
      ],
    }
  )

  const [toggleOutletActivation] = useMutation(TOGGLE_OUTLET_ACTIVATION, {
    onError: defaultErrorHandler,
    onCompleted: data => {
      successToast(data.toggleOutletActivation.message)
    },
  })

  return (
    <Query<GetOutletForDetailsTabQuery>
      query={GET_OUTLET}
      variables={{ id: outlet }}
      loaderTitle={'Loading Outlet...'}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = first(outlets)

        return (
          <Row gutter={24}>
            <Col sm={12} lg={8}>
              <Formik
                initialValues={{
                  ...first(outlets),
                  outletAddress: outlet.outletAddress
                    ? outlet.outletAddress
                    : BLANK_ADDRESS,

                  contactAddress: outlet.contactAddress
                    ? outlet.contactAddress
                    : BLANK_ADDRESS,
                  isOnlineOverride:
                    isOnlineOverrideValues.findIndex(
                      value => value === outlet.isOnlineOverride
                    ) || 0,
                  // Default to COMPANY if no value is selected
                  companyType: outlet.companyType || CompanyType.Company,
                }}
                validationSchema={validation}
                initialStatus={{ previewImageValue: null }}
                onSubmit={values =>
                  editOutlet({
                    variables: {
                      ...omit(values, [
                        'contactAddress.id',
                        'outletAddress.id',
                      ]),
                      // only set marketplaceId if it has been changed
                      marketplaceId:
                        values.marketplace.id === outlets[0].marketplace.id
                          ? undefined
                          : values.marketplace.id,
                      isOnlineOverride:
                        isOnlineOverrideValues[values.isOnlineOverride],
                      isDirectPayment: outlet.marketplace.stripeDirectPayment,
                      isAutoAccept:
                        values.isAutoAccept &&
                        // we only want to allow auto accept to be set if this is set to 'Online' which is represented here as 1
                        // (see client/management/src/components/Outlet/Details/OutletDetailsForm.jsx)
                        // the server will give an error if we try and set this
                        values.isOnlineOverride === 1,
                    },
                  })
                }
              >
                {props => (
                  <OutletDetailsForm
                    {...props}
                    isDirectPayment={outlet.marketplace.stripeDirectPayment}
                    showEscalationContact={
                      outlet.marketplace.allowPendingOrderAlert
                    }
                    marketplace={outlet.marketplace}
                  />
                )}
              </Formik>
            </Col>
            {userIsOutletFinancialUser ? (
              <Col lg={4} md={5} sm={12} xs={12}>
                <KycCallout />
              </Col>
            ) : (
              <Col sm={12} lg={4}>
                <Formik
                  initialValues={outlet}
                  onSubmit={({ active }) =>
                    toggleOutletActivation({
                      variables: {
                        id: outlet.id,
                        active,
                      },
                    })
                  }
                >
                  {props => (
                    <ActivationForm
                      {...props}
                      restaurantName={outlet.restaurant.name}
                    />
                  )}
                </Formik>

                {/* TODO RED-7828 revisit oauth if we can fetch stores from otter for a user - then remove storeId input in ./OutletDetailsForm.jsx
                <br />
                 <OtterOAuthButton /> */}
                <br />
                <Collection />
                {isAtLeastMarketplaceUser() && (
                  <OutletCategories outlet={outlet} />
                )}
                <Promote outlet={outlet} />
                <SpecialOfferFlags outlet={outlet} />
                <HygieneRating outlet={outlet} />
              </Col>
            )}
          </Row>
        )
      }}
    </Query>
  )
}

export default Details
