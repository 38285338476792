import { Card } from '@blueprintjs/core'
import React from 'react'

export const UserAgentsContainer = ({ records, title }) => {
  return records.length > 0 ? (
    <Card>
      <p>{title}</p>
      {records.map(({ value, color, dataKey }) => (
        <div key={dataKey}>
          <small style={{ color }}>
            {dataKey.split('"')[1]}{' '}
            <span style={{ color: 'black' }}>
              {value} order{value > 1 ? 's' : ''}
            </span>
          </small>
        </div>
      ))}
    </Card>
  ) : null
}
