import {
  Button,
  Checkbox,
  Divider,
  FormGroup,
  HTMLTable,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
  Radio,
  RadioGroup,
  Spinner,
} from '@blueprintjs/core'
import { Fragment, useMemo, useState } from 'react'
import Query from '@components/Query/Query'
import GET_DISCOUNT from './queries/getDiscount.query'
import EDIT_DISCOUNT from './mutations/editDiscount.mutation'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { numberToPence, penceToPounds } from '@utils/helpers'
import { Formik, FieldArray } from 'formik'
import { discountValidation } from './validation/validation'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { DateInput3 } from '@blueprintjs/datetime2'
import moment from 'moment'
import WeekDaysSelect from './WeekDaysSelect'
import DELETE_DISCOUNT from './mutations/deleteDiscount.mutation'
import { canPerformAction } from '@stores/userStore'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import DISCOUNT_ANALYTICS from '@components/Analytics/queries/discountAnalytics.query'
import DiscountAnalytics from '@components/Analytics/DiscountAnalytics'
import { transformDiscountsRawData } from '@components/Analytics/util/transformDiscountsRawData'
import NonIdealStateSmaller from '@components/Analytics/NonIdealStateSmaller'
import LinkTag from '../LinkTag/LinkTag'
import { get } from 'lodash'
import { useMutation } from '@apollo/client'
import TOGGLE_DISCOUNT_ACTIVATION from './mutations/toggle-discount-activattion'

const DiscountDetails = ({ businessId, discountId, closeDrawer, outlets }) => {
  let warnOnFixedDiscountAndZeroMinimumSubtotal = ''
  const [rawData, setRawData] = useState([])
  const memoizedTransformedData = useMemo(
    () => transformDiscountsRawData(rawData),
    [rawData]
  )

  const [editDiscount] = useMutation(EDIT_DISCOUNT, {
    onCompleted: ({ editDiscount }) => {
      successToast(editDiscount.message)
      closeDrawer()
    },
    onError: defaultErrorHandler,
    refetchQueries: [{ query: GET_DISCOUNT, variables: { id: discountId } }],
  })

  const [deleteDiscount] = useMutation(DELETE_DISCOUNT, {
    onCompleted: () => {
      successToast('Successfully removed the discount')
      closeDrawer()
    },
    onError: defaultErrorHandler,
    refetchQueries: [{ query: GET_DISCOUNT, variables: { id: discountId } }],
  })

  const [toggleDiscountActivation] = useMutation(TOGGLE_DISCOUNT_ACTIVATION, {
    onCompleted: ({ toggleDiscountActivation }) => {
      successToast(toggleDiscountActivation.message)
    },
    onError: defaultErrorHandler,
    refetchQueries: [{ query: GET_DISCOUNT, variables: { id: discountId } }],
  })

  return (
    <div className="bp5-drawer-form">
      <Query query={GET_DISCOUNT} variables={{ id: discountId }}>
        {({ getDiscount, loading, error }) => {
          if (loading) {
            return <Spinner size={60} />
          }
          if (error) {
            return (
              <NonIdealState
                icon="error"
                title="Discount Unavailable"
                description="There was an error trying to access the discount."
              />
            )
          }
          if (!getDiscount) {
            return (
              <NonIdealState
                icon="outdated"
                description="The discount doesn't exist or it was archived."
              />
            )
          }

          const { discount } = getDiscount

          const allowOutletSelection =
            get(discount, 'restaurant.outlets', []).some(
              outlet => outlet.marketplace.enableCustomerV2Client
            ) || get(discount, 'marketplace.enableCustomerV2Client')

          const initialValues = {
            ...discount,
            discountAmount: penceToPounds(discount.discountAmount),
            minimumSubtotalGross: penceToPounds(discount.minimumSubtotalGross),
            allowedOutletIds: discount.allowedOutlets.map(({ id }) => id),
            outletSelected: discount.allowedOutlets.length > 0,
          }

          return (
            <Formik
              validationSchema={discountValidation}
              validateOnChange={true}
              onSubmit={values => {
                editDiscount({
                  variables: {
                    ...values,
                    startDate: moment(values.startDate).toISOString(true),
                    endDate: moment(values.endDate).toISOString(true),
                    discountAmount: numberToPence(values.discountAmount),
                    minimumSubtotalGross: numberToPence(
                      values.minimumSubtotalGross
                    ),
                    allowedOutletIds: values.outletSelected
                      ? values.allowedOutletIds
                      : [],
                  },
                })
              }}
              initialValues={initialValues}
              enableReinitialize
            >
              {({
                errors,
                handleChange,
                values,
                handleSubmit,
                setFieldValue,
              }) => {
                if (
                  parseFloat(values.discountAmount) > 0 &&
                  parseFloat(values.minimumSubtotalGross) <=
                    parseFloat(values.discountAmount)
                ) {
                  warnOnFixedDiscountAndZeroMinimumSubtotal =
                    'Note that if the fixed discount is greater than the minimum subtotal, the remaining discount difference will be lost at checkout.'
                }

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="bp5-drawer-content">
                      <Query
                        query={DISCOUNT_ANALYTICS}
                        variables={{ discountId, businessId }}
                        loaderTitle="Loading Analytics"
                      >
                        {({
                          businessOrdersForAnalyticsByDiscount: data,
                          loading,
                          error,
                        }) => {
                          if (loading) {
                            return <Spinner />
                          }
                          if (error) {
                            return (
                              <NonIdealState
                                icon="error"
                                title="Unable to load analytics data"
                              />
                            )
                          }

                          if (!data.length) return <NonIdealStateSmaller />

                          setRawData(data)
                          return (
                            <DiscountAnalytics
                              {...memoizedTransformedData}
                              outlets={outlets}
                              discountId={discountId}
                              isLoyaltyCardDrawer={false}
                            />
                          )
                        }}
                      </Query>
                      <Checkbox
                        name="active"
                        label="Activate Discount"
                        id="active"
                        checked={values.active}
                        onChange={() =>
                          toggleDiscountActivation({
                            variables: {
                              id: discountId,
                              active: !values.active,
                            },
                          })
                        }
                      />
                      <Divider />
                      <br />
                      <FormGroup
                        label="Discount Name"
                        labelInfo="(required)"
                        labelFor="name"
                        helperText={errors.name || ''}
                        intent={Intent.DANGER}
                      >
                        <InputGroup
                          name="name"
                          id="name"
                          type="text"
                          onChange={handleChange}
                          intent={errors.name ? Intent.DANGER : Intent.NONE}
                          value={values.name}
                        />
                      </FormGroup>

                      <FormGroup
                        label="Percentage Discount"
                        labelFor="discountPercentage"
                        helperText={errors.discountPercentage || ''}
                        intent={
                          errors.discountPercentage
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                      >
                        <PercentageInput
                          value={values.discountPercentage}
                          handleChange={values =>
                            setFieldValue(
                              'discountPercentage',
                              values.floatValue
                            )
                          }
                          fill
                          intent={
                            errors.discountPercentage
                              ? Intent.DANGER
                              : Intent.NONE
                          }
                        />
                      </FormGroup>

                      <FormGroup
                        label="Fixed Discount"
                        labelFor="discountAmount"
                        helperText={errors.discountAmount || ''}
                        intent={Intent.DANGER}
                      >
                        <CurrencyInput
                          name="discountAmount"
                          id="discountAmount"
                          intent={
                            errors.discountAmount ? Intent.DANGER : Intent.NONE
                          }
                          defaultValue={values.discountAmount}
                          onUpdate={amount =>
                            setFieldValue('discountAmount', amount)
                          }
                          full={true}
                        />
                      </FormGroup>

                      <FormGroup
                        label="Minimum Subtotal"
                        labelFor="minimumSubtotalGross"
                        helperText={
                          errors.minimumSubtotalGross ||
                          warnOnFixedDiscountAndZeroMinimumSubtotal
                        }
                        intent={
                          warnOnFixedDiscountAndZeroMinimumSubtotal
                            ? Intent.WARNING
                            : Intent.DANGER
                        }
                      >
                        <CurrencyInput
                          name="minimumSubtotalGross"
                          id="minimumSubtotalGross"
                          intent={
                            errors.minimumSubtotalGross
                              ? Intent.DANGER
                              : Intent.NONE
                          }
                          defaultValue={values.minimumSubtotalGross}
                          onUpdate={amount =>
                            setFieldValue('minimumSubtotalGross', amount)
                          }
                          full={true}
                        />
                      </FormGroup>

                      <FormGroup
                        label="Start Date"
                        labelFor="startDate"
                        helperText={errors.startDate || ''}
                        intent={Intent.DANGER}
                      >
                        <DateInput3
                          parseDate={str => new Date(str)}
                          id="startDate"
                          formatDate={date => date.toLocaleDateString()}
                          value={values.startDate}
                          onChange={date => setFieldValue('startDate', date)}
                          intent={
                            errors.startDate ? Intent.DANGER : Intent.NONE
                          }
                        />
                      </FormGroup>

                      <FormGroup
                        label="End Date"
                        labelFor="endDate"
                        helperText={errors.endDate || ''}
                        intent={Intent.DANGER}
                      >
                        <DateInput3
                          parseDate={str => new Date(str)}
                          id="endDate"
                          formatDate={date => moment(date).format('DD/MM/YYYY')}
                          value={values.endDate}
                          onChange={date => setFieldValue('endDate', date)}
                          intent={errors.endDate ? Intent.DANGER : Intent.NONE}
                        />
                      </FormGroup>

                      <FormGroup
                        label="Week Days"
                        labelFor="daysOfWeek"
                        style={{ marginBottom: 5 }}
                        helperText={errors.daysOfWeek || ''}
                        intent={Intent.DANGER}
                      >
                        <FieldArray
                          name="daysOfWeek"
                          render={({
                            form: {
                              values: { daysOfWeek },
                            },
                            push,
                            remove,
                          }) => (
                            <WeekDaysSelect
                              selectedDays={daysOfWeek}
                              push={push}
                              remove={remove}
                            />
                          )}
                        />
                      </FormGroup>
                      <br />

                      {allowOutletSelection && (
                        <FormGroup
                          helperText={errors.allowedOutletIds}
                          intent={
                            errors.allowedOutletIds
                              ? Intent.DANGER
                              : Intent.NONE
                          }
                          label={
                            <Fragment>
                              Boundary <LinkTag type="web-v2" />
                            </Fragment>
                          }
                        >
                          <RadioGroup
                            inline={true}
                            onChange={e =>
                              setFieldValue(
                                'outletSelected',
                                e.target.value === 'outlet'
                              )
                            }
                            selectedValue={
                              values.outletSelected ? 'outlet' : 'businessWide'
                            }
                          >
                            <Radio label="Business Wide" value="businessWide" />
                            <Radio label={`Outlet`} value="outlet" />
                          </RadioGroup>
                        </FormGroup>
                      )}

                      {values.outletSelected && (
                        <HTMLTable interactive={true}>
                          <thead>
                            <tr>
                              <th>Outlet</th>
                              <th>Allow Discount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {outlets.map(outlet => (
                              <tr
                                key={outlet.id}
                                onClick={() => {
                                  if (
                                    values.allowedOutletIds.includes(outlet.id)
                                  ) {
                                    const updatedOutletSelection =
                                      values.allowedOutletIds.filter(
                                        id => id !== outlet.id
                                      )
                                    return setFieldValue(
                                      'allowedOutletIds',
                                      updatedOutletSelection
                                    )
                                  } else {
                                    const newOutletSelection = [
                                      ...values.allowedOutletIds,
                                      outlet.id,
                                    ]
                                    return setFieldValue(
                                      'allowedOutletIds',
                                      newOutletSelection
                                    )
                                  }
                                }}
                              >
                                <td className="bp5-link-text">{outlet.name}</td>
                                <td>
                                  <Icon
                                    icon={
                                      values.allowedOutletIds.includes(
                                        outlet.id
                                      )
                                        ? 'selection'
                                        : 'circle'
                                    }
                                    style={{
                                      paddingLeft: '12%',
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </HTMLTable>
                      )}
                    </div>
                    <div className="bp5-drawer-footer-actions">
                      {canPerformAction('deleteDiscount') && (
                        <ConfirmationPopover
                          remove={async () => {
                            await deleteDiscount({
                              variables: { id: discountId },
                            })
                          }}
                          confirmationText="Are you sure you want to delete this discount?"
                        >
                          <Button
                            text="Delete"
                            intent={Intent.DANGER}
                            minimal
                          />
                        </ConfirmationPopover>
                      )}
                      <Button text="Save" type="submit" />
                    </div>
                  </form>
                )
              }}
            </Formik>
          )
        }}
      </Query>
    </div>
  )
}

export default DiscountDetails
