import gql from 'graphql-tag'

const ADD_DELIVERY_ZONE = gql`
  mutation addDeliveryZone(
    $name: String!
    $marketplaceId: String!
    $deliveryZoneType: DeliveryZoneType!
    $geoFence: Json
  ) {
    addDeliveryZone(
      name: $name
      marketplaceId: $marketplaceId
      deliveryZoneType: $deliveryZoneType
      geoFence: $geoFence
    ) {
      message
      deliveryZone {
        id
        name
        deliveryZoneType
      }
    }
  }
`

export default ADD_DELIVERY_ZONE
