import React from 'react'
import { func, bool, string } from 'prop-types'
import Filter from '@components/Toolbar/Filter'
import GET_OUTLETS from './queries/getOutlets.query'
import split from 'lodash/split'
import { errorToast } from '@utils/toast'

export const OutletsFilterByMarketplace = ({
  disabled = false,
  outletsFilter,
  onChange,
  marketplaceId = null,
}) => {
  if (!marketplaceId) {
    marketplaceId = split(location.pathname, '/')[2]
  }

  if (!marketplaceId) {
    errorToast('No markeplace id found for the Outlets filter')
  }

  return (
    <Filter
      name="Outlets"
      disabled={disabled}
      filter={outletsFilter}
      onChange={onChange}
      query={GET_OUTLETS}
      variables={{ marketplaceIds: [marketplaceId] }}
      dataPath="getOutlets.outlets"
      itemPath=""
    />
  )
}

OutletsFilterByMarketplace.propTypes = {
  onChange: func,
  marketplaceId: string,
  disabled: bool,
}
