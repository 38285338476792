import gql from 'graphql-tag'

const EDIT_MENU_ITEMS_ORDER = gql`
  mutation editMenuItemsOrder($input: [IPositionArgs!]!) {
    editMenuItemsOrder(input: $input) {
      menuItems {
        id
        name
        position
      }
    }
  }
`

export default EDIT_MENU_ITEMS_ORDER
