import { useContext, Fragment, useState } from 'react'
import {
  Drawer,
  ButtonGroup,
  Button,
  AnchorButton,
  Divider,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'

import MenuTree from '@src/components/Restaurant/Menu/MenuTree/MenuTree'
import MenuList from '@components/Restaurant/Menu/MenuList/MenuList'
import FilterRow from '@components/FilterRow/FilterRow'
import ItemDetails from '@components/Restaurant/Menu/MenuTree/ItemDetails'

import ParentMenuModal from '@components/Restaurant/Menu/modals/AddNewParentMenu.modal'
import AddSubMenuModal from '@components/Restaurant/Menu/modals/AddSubMenu.modal'
import AddItemModal from '@components/Restaurant/Menu/modals/AddItem.modal'
import EditMenuModal from '@components/Restaurant/Menu/modals/EditMenu.modal'
import AddOptionModal from '@components/Restaurant/Menu/modals/AddOption.modal'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import PrintContent from '@components/PrintContent/PrintContent'
import MenuPrintout from './MenuPrintout'
import GET_OUTLET from '@components/Outlet/Details/queries/getOutlet.query'
import { first, orderBy, toLower } from 'lodash'
import GET_RESTAURANT_OUTLETS from './queries/getRestaurantOutlets.query'
import { client } from '@src/services/client'
import GET_MENU_FOR_DOWNLOAD from './queries/getMenuForDownload.query'
import { kebabCase } from 'lodash'
import { MenuImport } from './MenuImport/MenuImport'
import { MenuDefinition } from '@components/Restaurant/Menu/schema/menu'
import { MenuGroupDefinition } from '@components/Restaurant/Menu/schema/menu-group'
import { ExportPopover } from '../ExportPopover/ExportPopover'
import { ImportPopover } from '../ImportPopover/ImportPopover'
import OpenMenuTreeProvider from './MenuList/OpenMenuTreeProvider'
import { StringParam, useQueryParam } from 'use-query-params'
import { useParams } from 'react-router-dom'
import { RestaurantFinancialsQuery } from './queries/getRestaurantOutlets.query.generated'
import { GetOutletForDetailsTabQuery } from '../../Outlet/Details/queries/getOutlet.query.generated'
import { GetMenuForDownloadQuery } from './queries/getMenuForDownload.query.generated'
import { MenuStateProvider, useMenuState } from './MenuTree/MenuStateProvider'
import { ModalContext } from '../../../providers/ModalProvider'
import config from '../../../config'

const generateMenuItemsCSV = async (queryId, includeIds) => {
  const response = await client.query<GetMenuForDownloadQuery>({
    query: GET_MENU_FOR_DOWNLOAD,
    variables: {
      id: queryId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants?.[0]?.name
  )}-menu-items.csv`
  let menuItemsGroups =
    response.data.getRestaurants.restaurants?.[0]?.menuItemsGroups

  if (!includeIds) {
    menuItemsGroups = menuItemsGroups.map(({ id: _, ...menuItemsGroup }) => ({
      ...menuItemsGroup,
      menuItems: menuItemsGroup.menuItems.map(
        ({ id: __, options, ...menuItem }) => ({
          ...menuItem,
          options: [],
        })
      ),
    }))
  }

  const sortedMenuItemGroups = orderBy(menuItemsGroups, 'name', 'desc')

  const csvData = MenuDefinition.menuItemGroupsToCSV(sortedMenuItemGroups)
  return { fileName, csvData }
}

const generateMenuItemGroupsCSV = async (queryId, includeIds) => {
  const response = await client.query<GetMenuForDownloadQuery>({
    query: GET_MENU_FOR_DOWNLOAD,
    variables: {
      id: queryId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants?.[0]?.name
  )}-menu.csv`

  let menuItemsGroups =
    response.data.getRestaurants.restaurants?.[0]?.menuItemsGroups

  if (!includeIds) {
    menuItemsGroups = menuItemsGroups.map(({ id: _, ...menuItemsGroup }) => ({
      ...menuItemsGroup,
      menuItems: menuItemsGroup.menuItems.map(
        ({ id: __, options, ...menuItem }) => ({
          ...menuItem,
          options: options.map(({ id: ___, ...option }) => option),
        })
      ),
    }))
  }

  const sortedMenuItemGroups = orderBy(menuItemsGroups, 'name', 'desc')

  const csvData = MenuGroupDefinition.menuItemGroupsToCSV(sortedMenuItemGroups)
  return { fileName, csvData }
}

const Menu = ({ outlet }) => {
  const {
    data: { menuId, itemId, subMenuId },
    setValue,
  } = useMenuState()
  const { dark } = useContext(PageLayoutContext)
  const [importFileData, setImportFileData] = useState(null)
  const [showImport, setShowImport] = useState(false)

  const { outlet: outletId, restaurants: restaurantId } = useParams()

  const deepestMenuId = subMenuId || menuId

  const queryId = outlet ? outletId : restaurantId
  const menuQuery = outlet ? GET_OUTLET : GET_RESTAURANT_OUTLETS
  const [view = 'list', setView] = useQueryParam('view', StringParam)

  const closeMenuItem = () => {
    setValue({ itemId: undefined })
  }

  const { state: modals, setState: toggleModal } = useContext(ModalContext)

  return (
    <OpenMenuTreeProvider>
      <div className="bp5-table-frame">
        <FilterRow>
          <div>
            <ButtonGroup>
              <Button
                icon="list-columns"
                text="Column"
                active={view === 'list'}
                onClick={() => setView(undefined)}
              />

              <Button
                icon="list"
                text="Tree"
                active={view === 'tree'}
                onClick={() => setView('tree')}
              />
            </ButtonGroup>
            <Divider />

            {!outlet && (
              <Fragment>
                <ButtonGroup>
                  <ImportPopover
                    startImport={(data: any) => {
                      setImportFileData(data)
                      setShowImport(true)
                    }}
                  />
                  <ExportPopover
                    options={[
                      {
                        label: 'Items',
                        value: 'menu-items',
                        generateCSV: includeIds =>
                          generateMenuItemsCSV(queryId, includeIds),
                      },
                      {
                        label: 'Menu',
                        value: 'menu-item-groups',
                        generateCSV: includeIds =>
                          generateMenuItemGroupsCSV(queryId, includeIds),
                      },
                    ]}
                  />
                </ButtonGroup>
                <Divider />
              </Fragment>
            )}

            <ButtonGroup>
              <PrintContent buttonName="Print">
                <MenuPrintout restaurantId={restaurantId} />
              </PrintContent>
              <Query
                query={menuQuery}
                variables={{ id: queryId }}
                showLoader={false}
              >
                {(
                  data: RestaurantFinancialsQuery | GetOutletForDetailsTabQuery
                ) => {
                  let name: string | null = null
                  let foundOutlet

                  if (outlet && 'getOutlets' in data) {
                    foundOutlet = first(data.getOutlets.outlets)
                    name = foundOutlet.name
                      ? foundOutlet.name.replace(/[^A-Z0-9]+/gi, '-')
                      : null
                  } else if (
                    'getRestaurants' in data &&
                    (data.getRestaurants.restaurants?.length ?? 0) > 0
                  ) {
                    const restaurant = first(data.getRestaurants.restaurants)!
                    foundOutlet = first(restaurant.outlets)
                    name = restaurant.name
                      ? restaurant.name.replace(/[^A-Z0-9]+/gi, '-')
                      : null
                  }

                  let loc = `https://${foundOutlet.marketplace.cname}/${
                    name ? name + '/' : ''
                  }${foundOutlet.id}/menu?showInactive=true`

                  if (
                    foundOutlet.marketplace &&
                    foundOutlet.marketplace.enableCustomerV2Client &&
                    foundOutlet.id &&
                    foundOutlet.name
                  ) {
                    const restaurantName = outlet
                      ? foundOutlet.restaurant.name
                      : first(data.getRestaurants.restaurants).name

                    const cnameAndProtocol = config.isLocalDev
                      ? `http://${foundOutlet.marketplace.cname}:8990`
                      : `https://${foundOutlet.marketplace.cname}`

                    // structure is the same for list and postcode marketplaces
                    loc = `${cnameAndProtocol}/${
                      foundOutlet.marketplace.urlPath
                    }/${foundOutlet.id}/${kebabCase(
                      toLower(restaurantName)
                    )}/menu?showInactive=1`
                  } else {
                    // default is takeaway
                    if (foundOutlet.marketplace.orderMode === 'LIST') {
                      // list marketplaces
                      const deliveryZoneCosts = first(
                        foundOutlet.deliveryZoneCosts
                      )

                      if (deliveryZoneCosts) {
                        const deliveryZone = deliveryZoneCosts.deliveryZone
                        loc = `https://${foundOutlet.marketplace.cname}/${deliveryZone.name}-${foundOutlet.marketplace.urlpath}/${name}/${foundOutlet.id}/menu?showInactive=true`
                      } else {
                        // no delivery zones - disable web preview
                        loc = null
                      }
                    } else if (foundOutlet.marketplace.orderMode === 'SINGLE') {
                      // single outlet marketplaces
                      const merchantCategory = foundOutlet.marketplace.urlPath
                        ? foundOutlet.marketplace.urlPath
                        : 'takeaways'

                      loc = `https://${foundOutlet.marketplace.cname}/${merchantCategory}/${foundOutlet.id}/menu?showInactive=true`
                    } else {
                      // postcode marketplaces
                      loc = `https://${foundOutlet.marketplace.cname}/${name}/${foundOutlet.id}/menu?showInactive=true`
                    }
                  }

                  return (
                    <Fragment>
                      {foundOutlet && foundOutlet.marketplace.cname && (
                        <AnchorButton
                          icon="eye-open"
                          rightIcon="share"
                          text="Web Preview"
                          href={loc}
                          target="_blank"
                          rel="noopener noreferrer"
                          disabled={!foundOutlet.menuItemsGroups.length || !loc}
                        />
                      )}
                    </Fragment>
                  )
                }}
              </Query>
            </ButtonGroup>
          </div>
        </FilterRow>
        {view === 'tree' ? (
          // TREE view
          <MenuList
            outlet={outlet}
            queryId={queryId}
            onChangeMenuId={menuId => {
              setValue({ menuId })
            }}
            onChangeMenuItemId={menuItemId => setValue({ itemId: menuItemId })}
            setViewMenuItem={setValue}
            selectedMenuItemId={undefined}
          />
        ) : (
          // LIST VIEW
          <MenuTree
            outlet={outlet}
            deepestMenuId={deepestMenuId || ''}
            queryId={queryId}
            setViewMenuItem={setValue}
          />
        )}
        <MenuImport
          show={showImport}
          importData={importFileData}
          restaurantId={restaurantId}
          onClose={() => {
            setShowImport(false)

            // sneaky: switch the view to tree and back to list to force a refresh
            // const currentView = view || 'list'
            // dont use push cause it will add to the history stack
            // history.replace(
            //   `${match.url}?view=${view === 'tree' ? 'list' : 'tree'}`
            // )
            // history.replace(`${match.url}?view=${currentView}`)
          }}
        />

        <Drawer
          isOpen={itemId}
          onClose={closeMenuItem}
          title="Edit Menu Item"
          size={`${window.innerWidth > 1100 ? '1000px' : '95%'}`}
          className={dark ? 'bp5-dark' : ''}
        >
          <ItemDetails
            menuId={menuId}
            menuItemId={itemId}
            restaurantId={restaurantId}
            outletId={outletId}
            outlet={outlet}
            closeMenuItem={closeMenuItem}
            openingTimes={modals.openingTimes}
            soldOutUntilItems={modals.soldOutUntilItems}
            nextOpen={modals.nextOpen}
          />
        </Drawer>
        <ParentMenuModal
          modalOpen={modals.parentMenuModal}
          modalClose={() =>
            toggleModal({
              parentMenuModal: false,
            })
          }
          restaurantId={restaurantId}
          parentMenuId={modals.parentMenuId}
        />
        <AddSubMenuModal
          modalOpen={modals.subMenuModal}
          modalClose={() => {
            toggleModal({
              subMenuModal: false,
            })
          }}
          restaurantId={restaurantId}
          parentMenuId={modals.parentMenuId}
        />
        <AddItemModal
          modalOpen={modals.itemModal}
          modalClose={() => toggleModal({ itemModal: false })}
          menuId={deepestMenuId}
          queryId={queryId}
          restaurantId={restaurantId}
        />
        <EditMenuModal
          modalOpen={modals.editMenuModal}
          modalClose={() => {
            toggleModal({
              editMenuModal: false,
            })
            setValue({ editMenu: undefined })
          }}
          restaurantId={restaurantId}
          menuId={menuId}
        />
        <AddOptionModal
          modalOpen={modals.optionModal}
          modalClose={() => {
            toggleModal({ optionModal: false })
            setValue({ optionId: '' })
          }}
          optionId={modals.optionId}
          restaurantId={restaurantId}
        />
      </div>
    </OpenMenuTreeProvider>
  )
}

const MenuContextWrapper = props => (
  <MenuStateProvider>
    <Menu {...props} />
  </MenuStateProvider>
)

export default MenuContextWrapper
