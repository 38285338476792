import gql from 'graphql-tag'

const GET_PARTNER = gql`
  query getPartner($id: String!) {
    getPartners(id: $id) {
      partners {
        id
        name
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        contactPhone
        contactEmail
        contactName
        companyLegalName
        companyNumber
        platform {
          id
          name
        }
        orderHoldInterval
        marketplaces {
          id
          name
        }
        restaurants {
          id
          name
        }
        cname
        googleJsonS3KeyPathDefault
        appleTeamIdDefault
        appStoreConnectApiKeyId
        appStoreConnectApiIssuerId
        appStoreConnectApiKeyPath
        brandImage
        brandColor
        allowSubBrands
        supportUrl
        supportEmail
        supportTitle
        minAppVersionOverride
      }
    }
  }
`

export default GET_PARTNER
