import { Icon, IconName, Menu, MenuItem, Popover } from '@blueprintjs/core'
import React from 'react'
import { Column, ColumnDataType } from './Columns/column.types'
import { MegaTableProps } from './MegaTable'
import { useMegaTableSort } from './useMegaTableSort'

import {
  CurrencyColumnData,
  sortCurrencyColumn,
} from './Columns/CurrencyColumn'
import {
  MarketplaceColumnData,
  sortMarketplaceColumn,
} from './Columns/MarketplaceColumn'
import { sortMonthColumn } from './Columns/MonthColumn'
import { NumericColumnData, sortNumericColumn } from './Columns/NumericColumn'
import { OutletColumnData, sortOutletColumn } from './Columns/OutletColumn'
import { sortPercentageColumn } from './Columns/PercentageColumn'
import { sortTextColumn } from './Columns/TextColumn'
import {
  DateTimeColumnData,
  sortDateTimeColumn,
} from './Columns/DateTimeColumn'

interface SortByProps {
  name: string
  type: Column<string>['type']
}

type Ordering = 'ASC' | 'DESC' | 'NONE'

/**
 * If the table is set to CLIENT side sorting returns sorted data.
 * If it is not, returns it unchanged.
 */
export const useSortedData = <Columns extends readonly Column<string>[]>({
  columns,
  data,
  sorting,
}: Pick<
  MegaTableProps<Columns, string>,
  'columns' | 'data' | 'sorting'
>): MegaTableProps<Columns, string>['data'] => {
  if (sorting !== 'CLIENT') return data

  const { field, order } = useMegaTableSort()

  if (order !== 'ASC' && order !== 'DESC') return data

  const sortIndex = columns.findIndex(x => x.name === field)
  if (sortIndex === -1) return data

  const sortColumn = columns[sortIndex]

  // ChatGPT assures me that these casts are necessary!
  // I think it is okay, because you would get an error when using `tableProps` in the first place
  // I'd really do without them, but not sure how.

  switch (sortColumn.type) {
    case 'currency': {
      return data.toSorted((a, b) =>
        sortCurrencyColumn(
          a[sortIndex] as CurrencyColumnData,
          b[sortIndex] as CurrencyColumnData,
          order
        )
      )
    }

    case 'numeric': {
      // Here we assert that the data at sortIndex is a number
      return data.toSorted((a, b) =>
        sortNumericColumn(
          a[sortIndex] as NumericColumnData,
          b[sortIndex] as NumericColumnData,
          order
        )
      )
    }

    case 'percentage': {
      // Assuming 'percentage' works the same as 'number'
      return data.toSorted((a, b) =>
        sortPercentageColumn(
          a[sortIndex] as ColumnDataType<'percentage'>,
          b[sortIndex] as ColumnDataType<'percentage'>,
          order
        )
      )
    }

    case 'text': {
      return data.toSorted((a, b) =>
        sortTextColumn(
          b[sortIndex] as ColumnDataType<'text'>,
          a[sortIndex] as ColumnDataType<'text'>,
          order
        )
      )
    }

    case 'outlet': {
      return data.toSorted((a, b) =>
        sortOutletColumn(
          b[sortIndex] as OutletColumnData,
          a[sortIndex] as OutletColumnData,
          order
        )
      )
    }

    case 'month': {
      return data.toSorted((a, b) => {
        return sortMonthColumn(
          a[sortIndex] as ColumnDataType<'month'>,
          b[sortIndex] as ColumnDataType<'month'>,
          order
        )
      })
    }

    case 'marketplace': {
      return data.toSorted((a, b) =>
        sortMarketplaceColumn(
          b[sortIndex] as MarketplaceColumnData,
          a[sortIndex] as MarketplaceColumnData,
          order
        )
      )
    }

    case 'datetime': {
      return data.toSorted((a, b) =>
        sortDateTimeColumn(
          b[sortIndex] as DateTimeColumnData,
          a[sortIndex] as DateTimeColumnData,
          order
        )
      )
    }

    default: {
      return data
    }
  }
}

const sortIcons = (
  columnType: Column<string>['type']
): { NONE: IconName; ASC: IconName; DESC: IconName } => {
  switch (columnType) {
    case 'numeric':
    case 'currency':
    case 'percentage':
      return {
        NONE: 'double-caret-vertical',
        ASC: 'sort-numerical',
        DESC: 'sort-numerical-desc',
      }

    case 'outlet':
    case 'text':
    case 'customer':
    case 'fulfillment':
      return {
        NONE: 'double-caret-vertical',
        ASC: 'sort-alphabetical',
        DESC: 'sort-alphabetical-desc',
      }

    case 'month':
    case 'datetime':
    default:
      return { NONE: 'double-caret-vertical', ASC: 'sort', DESC: 'sort-desc' }
  }
}

export const MegaSortBy: React.FC<SortByProps> = ({ name, type }) => {
  const { field, order, setSort } = useMegaTableSort()
  const searchingOnThisField = field === name

  const icons = sortIcons(type)
  let icon
  if (searchingOnThisField) {
    switch (order) {
      case 'ASC':
        icon = icons.ASC
        break
      case 'DESC':
        icon = icons.DESC
        break
    }
  } else {
    icon = icons.NONE
  }

  const setSearch = (newOrder: Ordering): void => {
    if (newOrder === 'NONE') {
      setSort('NONE')
    } else {
      setSort({ field: name, order: newOrder })
    }
  }

  return (
    <Popover
      content={
        <div>
          <Menu>
            <MenuItem
              icon={icons.NONE}
              text={'None'}
              onClick={() => setSearch('NONE')}
            />
            <MenuItem
              icon={icons.ASC}
              text={'Ascending'}
              onClick={() => setSearch('ASC')}
            />
            <MenuItem
              icon={icons.DESC}
              text={'Descending'}
              onClick={() => setSearch('DESC')}
            />
          </Menu>
        </div>
      }
    >
      <div>
        <Icon icon={icon} style={{ paddingLeft: '5px' }} />
      </div>
    </Popover>
  )
}
