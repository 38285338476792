import { Card } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import React from 'react'
import { DataKeyMap } from '../util/maps'

export const AvgValuesContainer = ({
  avgValue: { value, dataKey, color },
  isSingleDayShiftSelection = false,
}) => {
  return value > 0 ? (
    <Card>
      <small style={{ color }}>
        {
          DataKeyMap[
            isSingleDayShiftSelection ? 'intervalOrderValueAverage' : dataKey
          ]
        }{' '}
        <span style={{ color: 'black' }}>
          <Currency amount={value.toFixed(2)} amountInPence={false} />
        </span>
      </small>
    </Card>
  ) : null
}
