import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Intent, Tooltip } from '@blueprintjs/core'
import { string, bool, shape, number } from 'prop-types'
import Currency from '@components/Currency/Currency'
import colors from '@styles/colors'

const ConnectStatus = ({ stripeId, stripeConnect, link, stripeOnboarding }) => {
  const {
    payoutsEnabled = false,
    chargesEnabled = false,
    detailsSubmitted = false,
    balance = 0,
    balanceCurrency = 'GBP',
  } = stripeConnect || {}

  if (stripeId && stripeConnect) {
    return (
      <Fragment>
        {payoutsEnabled && chargesEnabled && detailsSubmitted ? (
          <Tooltip content="Paybox is setup successfully.">
            <Icon icon="tick-circle" color={colors.onlineGreen} />
          </Tooltip>
        ) : (
          <Fragment>
            {!chargesEnabled && (
              <Tooltip content="Charges are disabled. Outlet was automatically deactivated.">
                <Icon icon="error" intent={Intent.DANGER} />
              </Tooltip>
            )}
            {!payoutsEnabled && chargesEnabled && (
              <Tooltip content="Payouts are suspended, requires action.">
                <Icon icon="ban-circle" intent={Intent.WARNING} />
              </Tooltip>
            )}
            {!detailsSubmitted && (
              <Tooltip content="Information required or missing documentation, act quickly to avoid business disruption.">
                <Icon icon="issue" intent={Intent.WARNING} />
              </Tooltip>
            )}
          </Fragment>
        )}{' '}
        <Link to={link}>
          <Currency amount={balance} currency={balanceCurrency} />
        </Link>
      </Fragment>
    )
  } else if (stripeOnboarding) {
    return (
      <span className="bp5-text-overflow-ellipsis">
        <Link to={link}>
          <Tooltip content="Paybox onboarding has not been completed.">
            <Icon icon="add" intent={Intent.PRIMARY} />
          </Tooltip>
          {' Setup'}
        </Link>
      </span>
    )
  } else {
    return <span className="bp5-text-disabled">-</span> // Payment not available
  }
}

ConnectStatus.propTypes = {
  stripeId: string,
  stripeConnect: shape({
    payoutsEnabled: bool,
    chargesEnabled: bool,
    detailsSubmitted: bool,
    balance: number,
    balanceCurrency: string,
  }),
  link: string,
  stripeOnboarding: bool,
}

export default ConnectStatus
