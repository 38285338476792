import { Card, Button, FormGroup, Intent, H5 } from '@blueprintjs/core'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_OUTLET_CATEGORIES from './mutations/editOutletCategories.mutation'
import { Categories } from '../../Restaurant/Details/Categories'
import GET_CATEGORIES from '../../Restaurant/Details/queries/getCategories.query'
import GET_ALLOWED_CATEGORIES_FOR_RESTAURANT from '../../Restaurant/Details/queries/getAllowedCategories.query'
import Query from '../../Query/Query'
import { some } from 'lodash'
import { useMutation } from '@apollo/client'

const OutletCategories = ({ outlet }) => {
  const [editOutletCategories] = useMutation(EDIT_OUTLET_CATEGORIES, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Successfully edited outlet categories'),
  })

  return outlet ? (
    <Card>
      <Formik
        initialValues={{
          categories: outlet.outletCategories.map(({ category }) => ({
            id: category.id,
            name: category.name,
            emoji: category.emoji,
            imageSrc: category.imageSrc,
          })),
        }}
        onSubmit={values =>
          editOutletCategories({
            variables: {
              outletId: outlet.id,
              categories: values.categories.map(category => category.id), // the order of the array is what will tell the server how to assign priorities
            },
          })
        }
      >
        {({ values, handleSubmit, errors, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <H5>Outlet Category Tags</H5>
              <FormGroup
                labelFor="cuisines"
                helperText={
                  errors.categories
                    ? errors.categories
                    : 'Tags allows grouping similar outlets in filters and views.'
                }
                intent={errors.categories ? Intent.DANGER : null}
              >
                <Query
                  query={GET_ALLOWED_CATEGORIES_FOR_RESTAURANT}
                  variables={{ restaurantId: outlet.restaurant.id }}
                >
                  {({
                    categoriesForRestaurant: {
                      cuisines,
                      optInAllowedCategories,
                      categoryEmojiOrImage,
                    },
                  }) => {
                    if (optInAllowedCategories) {
                      outlet.restaurant.cuisines.filter(
                        cuisineAtRestaurantLevel =>
                          some(cuisines, cuisineAtRestaurantLevel)
                      )

                      return (
                        <Categories
                          setFieldValue={setFieldValue}
                          allCategories={cuisines}
                          categories={values.categories}
                          preferEmoji={categoryEmojiOrImage === 'EMOJI'}
                        />
                      )
                    } else
                      return (
                        <Query query={GET_CATEGORIES}>
                          {({ getCuisines: { cuisines } }) => (
                            <Categories
                              setFieldValue={setFieldValue}
                              allCategories={cuisines}
                              categories={values.categories}
                              preferEmoji={categoryEmojiOrImage === 'EMOJI'}
                            />
                          )}
                        </Query>
                      )
                  }}
                </Query>
              </FormGroup>
              <br />

              <div className="bp-card-footer-actions">
                <Button text="Save" type="submit" />
              </div>
            </form>
          )
        }}
      </Formik>
    </Card>
  ) : null
}
export default OutletCategories
