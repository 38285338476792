import { useCallback } from 'react'
import { useQueryParam, BooleanParam, StringParam } from 'use-query-params'

export const useTerminalQueryParams = () => {
  const [offlineOpenOnly, onChangeOfflineOpenOnly] = useQueryParam(
    'offlineOpenOnly',
    BooleanParam
  )
  const [softwareVersion, onChangeSoftwareVersion] = useQueryParam(
    'softwareVersion',
    StringParam
  )
  const [viewTerminal, onChangeViewTerminal] = useQueryParam(
    'viewTerminal',
    StringParam
  )
  const [orderBy, onChangeOrderBy] = useQueryParam('orderBy', StringParam)

  const resetTerminalFilters = useCallback(() => {
    onChangeOfflineOpenOnly(undefined, 'replaceIn')
    onChangeSoftwareVersion(undefined, 'replaceIn')
    onChangeViewTerminal(undefined, 'replaceIn')
    onChangeOrderBy(undefined, 'replaceIn')
  }, [
    onChangeOfflineOpenOnly,
    onChangeSoftwareVersion,
    onChangeViewTerminal,
    onChangeOrderBy,
  ])

  return {
    offlineOpenOnly,
    softwareVersion,
    viewTerminal,
    orderBy,
    onChangeOfflineOpenOnly,
    onChangeSoftwareVersion,
    onChangeViewTerminal,
    onChangeOrderBy,
    resetTerminalFilters,
  }
}
