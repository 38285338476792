import gql from 'graphql-tag'

// note: in order for the cache updates to work properly,
// this mutation must return all fields which will have been updated by the mutation
const EDIT_TERMINAL = gql`
  mutation editTerminal(
    $id: String!
    $friendlyName: String
    $serialNumber: String!
    $alarm: Alarm
    $slave: Boolean
    $editableMessages: Boolean
    $receiptGroupItems: Boolean
    $outletIds: [String!]!
  ) {
    editTerminal(
      id: $id
      friendlyName: $friendlyName
      serialNumber: $serialNumber
      slave: $slave
      alarm: $alarm
      editableMessages: $editableMessages
      receiptGroupItems: $receiptGroupItems
      outletIds: $outletIds
    ) {
      terminal {
        id
        friendlyName
        serialNumber
        alarm
        slave
        editableMessages
        receiptGroupItems
        outlets {
          id
        }
      }
    }
  }
`

export default EDIT_TERMINAL
