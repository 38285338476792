import React, { useRef } from 'react'
import PhoneInputLib from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import get from 'lodash/get'

const Container = styled.div`
  position: relative;

  .inputClass {
    &.bp5-dark {
      background: ${props => props.theme.darkColors.inputBg};
      color: #fff;
      border: none;
    }
    width: 100% !important;
    font-size: 14px !important;
  }

  .searchClass {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 0px !important;
    font-size: 14px !important;

    &.bp5-dark {
      background: ${props => props.theme.darkColors.headerBgColor};
      border: none;
    }

    &.bp5-dark .search-box {
      background: ${props => props.theme.darkColors.inputBg};
      border: none;
      color: #fff;
    }

    & input {
      flex-grow: 1;
      border-top-width: 0 !important;
      border-right-width: 0 !important;
      border-left-width: 0 !important;
      border-bottom-width: 1px !important;
      padding: 7px 8px 6px !important;
      line-height: 18px !important;
      border-radius: 0 !important;
      margin: 0 !important;
    }
  }

  .dropdownClass {
    &.bp5-dark {
      background: ${props => props.theme.darkColors.headerBgColor};
      border: none;
    }

    &.bp5-dark .country:hover,
    &.bp5-dark .country.highlight {
      background: ${props => props.theme.darkColors.menuItemColor};
      border: none;
    }
  }

  .buttonClass {
    &.bp5-dark,
    &.bp5-dark .selected-flag:hover,
    &.bp5-dark .open,
    &.bp5-dark .selected-flag:focus {
      background: ${props => props.theme.darkColors.inputBg};
      border: none;
    }
    border: none;
    font-size: 14px !important;
  }

  .clearButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const PhoneInputInner = ({ onChange, ...rest }) => {
  const ref = useRef(null)

  const getCountryCode = () =>
    get(ref, 'current.state.selectedCountry.countryCode', '')
  const clear = () => {
    const countryCode = getCountryCode()
    onChange(countryCode)
  }

  return (
    <Container>
      <PhoneInputLib
        disableSearchIcon={true}
        country={'gb'}
        countryCodeEditable={false}
        enableSearch
        copyNumbersOnly={false}
        inputClass="inputClass"
        searchClass="searchClass"
        buttonClass="buttonClass"
        dropdownClass="dropdownClass"
        onChange={onChange}
        onKeyDown={e => {
          const countryCode = getCountryCode()
          const isAltBackspace = e.altKey === true && e.charCode === 0
          if (
            isAltBackspace &&
            countryCode &&
            `+${countryCode}` === e.target.value
          ) {
            e.preventDefault()
          }
        }}
        ref={ref}
        {...rest}
      />

      <button onClick={clear} className="bp5-button clearButton" type="button">
        <Icon icon="cross" />
      </button>
    </Container>
  )
}

export default PhoneInputInner
