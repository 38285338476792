import React from 'react'
import {
  Popover,
  PopoverInteractionKind,
  Button,
  Classes,
  Callout,
  Intent,
  InputGroup,
} from '@blueprintjs/core'
import { func, arrayOf, oneOfType, node, string, bool } from 'prop-types'

/**
 *  ConfirmationPopover - displays a tooltip over a button that asks the user to confirm an action.
 * @param {object} props - props passed from parent component
 * @param {object} props.confirmationText - element to display in the tooltip
 * @param {object} props.children - child element, when clicked, will display the tooltip
 * @param {function} props.remove - callback called after user confirms action
 * @param {string=} props.buttonTitle - text to display on the button
 * @param {boolean=} props.requiresWrittenConfirmation - if true, the user must write out the button title to confirm
 * @param {boolean=} props.disabled - if true, the button will be disabled
 * @returns
 */
const ConfirmationPopover = ({
  children,
  remove,
  confirmationText,
  intent = 'danger',
  buttonTitle = 'Delete',
  requiresWrittenConfirmation = false,
  disabled = false,
}) => {
  const [writtenConfirmation, setWrittenConfirmation] = React.useState('')
  const canSubmit =
    !requiresWrittenConfirmation ||
    writtenConfirmation.toLowerCase() === buttonTitle.toLowerCase()

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      onClose={() => setWrittenConfirmation('')}
      content={
        <div>
          <h5 className={Classes.HEADING}>Confirmation</h5>
          <p>{confirmationText}</p>
          {requiresWrittenConfirmation && (
            <Callout intent={Intent.WARNING} style={{ marginBottom: '10px' }}>
              <p>
                Please type <strong>{buttonTitle.toLowerCase()}</strong> to
                confirm.
              </p>
              <InputGroup
                value={writtenConfirmation}
                onChange={e => setWrittenConfirmation(e.target.value)}
              />
            </Callout>
          )}

          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button className={Classes.POPOVER_DISMISS} text="Cancel" minimal />

            <Button
              className={Classes.POPOVER_DISMISS}
              disabled={disabled || !canSubmit}
              intent={intent}
              onClick={remove}
              text={buttonTitle}
            />
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

ConfirmationPopover.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  remove: func,
  intent: string,
  confirmationText: oneOfType([node, string]),
  disabled: bool,
  buttonTitle: string,
  requiresWrittenConfirmation: bool,
}

export default ConfirmationPopover
