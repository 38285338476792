import { useCallback } from 'react'
import { DelimitedArrayParam, useQueryParam } from 'use-query-params'

const castToList = <T>(list: (T | null)[] | null): T[] => {
  if (list === null) return []
  return list.filter(x => x !== null)
}

export const useRoleAwareBusinessFilterQueryParams = () => {
  const [marketplaceIdsFromQuery = [], onChangeMarketplaceIds] = useQueryParam(
    'marketplaceIds',
    DelimitedArrayParam
  )

  const marketplaceIds = castToList(marketplaceIdsFromQuery)

  const [marketplaceOutletIdsFromQuery = [], onChangeMarketplaceOutletIds] =
    useQueryParam('marketplaceOutletIds', DelimitedArrayParam)

  const marketplaceOutletIds = castToList(marketplaceOutletIdsFromQuery)
  const outletIds = marketplaceOutletIds.map(x => x.split(':')[1])

  const [marketplaceBusinessIdsFromQuery = [], onChangeMarketplaceBusinessIds] =
    useQueryParam('marketplaceBusinessIds', DelimitedArrayParam)

  const marketplaceBusinessIds = castToList(marketplaceBusinessIdsFromQuery)

  const resetMarketplaceFilters = useCallback(() => {
    onChangeMarketplaceIds(undefined, 'replaceIn')
    onChangeMarketplaceOutletIds(undefined, 'replaceIn')
    onChangeMarketplaceBusinessIds(undefined, 'replaceIn')
  }, [
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
    onChangeMarketplaceBusinessIds,
  ])

  return {
    outletIds,
    marketplaceIds,
    marketplaceOutletIds,
    marketplaceBusinessIds,
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
    onChangeMarketplaceBusinessIds,
    resetMarketplaceFilters,
  }
}
