import React, { Fragment, useState } from 'react'
import {
  Checkbox,
  FormGroup,
  InputGroup,
  Radio,
  RadioGroup,
  Callout,
  Intent,
  HTMLSelect,
} from '@blueprintjs/core'
import { DateInput3, TimePrecision } from '@blueprintjs/datetime2'
import moment from 'moment'
import SegmentTypeahead from '../../shared/SegmentTypeahead'
import EmailEditor from '@components/EmailEditor/EmailEditor'
import ConfirmationModal from '@src/pages/Marketing/Campaigns/Form/ConfirmationModal'
import { InnerFormPushSection } from './InnerFormPushSection'
import { InnerFormSMSSection } from './InnerFormSMSSection'
import { InnerFormFooter } from './InnerFormFooter'
import { EditCampaignMutationVariables } from '../../mutations/editCampaign.mutation.generated'
import { FormikProps } from 'formik'

const DATE_FORMAT = 'DD/MM/YYYY, HH:mm'

type InnerFormProps = FormikProps<
  Required<EditCampaignMutationVariables> & {
    status: string
    emailContent: Array<any>
    marketplaceEmail: string
  }
> & {
  marketplaceId: string
  disabled: boolean
  marketplaceCNAME: string
  allowSMSMarketing: boolean
  isValid: boolean
}

const InnerForm: React.FC<InnerFormProps> = ({
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  marketplaceId,
  marketplaceCNAME,
  allowSMSMarketing = false,
  disabled,
  errors,
  isValid,
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <form>
      {disabled ? (
        <Callout
          intent={Intent.SUCCESS}
          style={{ margin: '0 0 20px' }}
          title={`Campaign is ${values.status.toLowerCase()}.`}
        >
          {values.status === 'COMPLETED'
            ? 'This campaign has been sent to your segment.'
            : 'This campaign cannot be edited.'}
        </Callout>
      ) : null}
      <FormGroup
        label="Name"
        labelInfo="(required)"
        helperText={errors.name || ''}
        intent={errors.name ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          name="name"
          type="text"
          disabled={disabled}
          value={values.name}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup
        label="Target Segment"
        labelInfo="(required)"
        helperText={errors.segment || ''}
        intent={errors.segment ? Intent.DANGER : Intent.NONE}
      >
        <SegmentTypeahead
          segment={values.segment}
          disabled={disabled}
          onChange={val => setFieldValue('segment', val)}
          marketplaceId={marketplaceId}
        />
      </FormGroup>
      <FormGroup
        label="Campaign Type"
        labelInfo="(required)"
        intent={errors.type ? Intent.DANGER : Intent.NONE}
        helperText={
          errors.type
            ? errors.type
            : 'Customers maybe have opted out of certain types of campaigns.'
        }
      >
        <HTMLSelect
          options={[
            { value: '', label: 'Select a type...' },
            { value: 'NEW_RESTAURANT', label: 'New Businesses' },
            { value: 'MENU', label: 'Product Updates' },
            { value: 'PROMOTION', label: 'Promotions' },
          ]}
          disabled={disabled}
          value={values.type}
          name="type"
          onChange={handleChange as any}
        />
      </FormGroup>

      <FormGroup label="Schedule" helperText={errors.timeConfig}>
        <RadioGroup
          onChange={handleChange}
          disabled={disabled}
          selectedValue={values.timeConfig}
          name={'timeConfig'}
        >
          <Radio label="Immediately" value={'IMMEDIATELY'} />
          <Radio label="Scheduled" value={'DATETIME'} />
        </RadioGroup>
      </FormGroup>

      {values.timeConfig === 'DATETIME' ? (
        <FormGroup
          style={{ marginLeft: 26 }}
          helperText={
            errors.startDateTime ||
            (values.startDateTime
              ? `Campaign will begin rolling out at ${
                  typeof values.startDateTime === 'string' &&
                  moment(values.startDateTime).format(`DD/MM/YYYY [at] h:mma`)
                } `
              : `Select a start time`)
          }
          intent={errors.startDateTime ? Intent.DANGER : Intent.NONE}
        >
          <DateInput3
            closeOnSelection={false}
            disabled={disabled}
            formatDate={date => moment(date).format(DATE_FORMAT)}
            parseDate={str => moment(str, DATE_FORMAT).toDate()}
            onChange={date => setFieldValue('startDateTime', date)}
            minDate={disabled ? new Date(0) : moment().toDate()}
            value={values.startDateTime}
            placeholder={'Select a date and time'}
            inputProps={{ leftIcon: 'calendar' }}
            timePickerProps={{ showArrowButtons: true }}
            timePrecision={TimePrecision.MINUTE}
            highlightCurrentDay
          />
        </FormGroup>
      ) : null}

      <FormGroup
        label="Messaging Channel"
        labelInfo="(required)"
        helperText={errors.emailChannel || ''}
        intent={errors.emailChannel ? Intent.DANGER : Intent.NONE}
      >
        <Checkbox
          disabled={disabled}
          checked={values.pushChannel}
          name="pushChannel"
          label="Push"
          onChange={handleChange}
        />
        {values.pushChannel ? (
          <InnerFormPushSection
            {...rest}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
            errors={errors}
            marketplaceId={marketplaceId}
            setFieldValue={setFieldValue}
          />
        ) : (
          <Fragment>
            <Callout icon="issue">Push messages will not be sent.</Callout>
            <br />
          </Fragment>
        )}
        {allowSMSMarketing ? (
          <InnerFormSMSSection
            {...rest}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
            errors={errors}
            marketplaceId={marketplaceId}
            marketplaceCNAME={marketplaceCNAME}
            allowSMSMarketing={allowSMSMarketing}
          />
        ) : null}
        <Checkbox
          disabled={disabled}
          checked={values.emailChannel}
          name="emailChannel"
          label="Email"
          onChange={handleChange}
        />
        {values.emailChannel ? (
          <EmailEditor
            {...rest}
            isValid={isValid}
            marketplaceId={marketplaceId}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
            setFieldValue={setFieldValue}
            errors={errors}
          />
        ) : (
          <Fragment>
            <Callout icon="issue">Email will not be sent.</Callout>
            <br />
          </Fragment>
        )}
      </FormGroup>
      {!disabled && (
        <InnerFormFooter
          marketplaceId={marketplaceId}
          values={values}
          allowSMSMarketing={allowSMSMarketing}
          setModalOpen={setModalOpen}
        />
      )}
      <ConfirmationModal
        modalOpen={modalOpen}
        message={
          <div>
            Are you sure you want to{' '}
            {values.timeConfig === 'DATETIME' ? 'schedule' : 'send'} this
            campaign?
            {values.emailChannel &&
            (values.emailContent.find(element => element.type === 'BUTTON') ===
              undefined ||
              values.emailContent.find(
                element =>
                  element.type === 'BUTTON' &&
                  element.buttonLink !== undefined &&
                  element.buttonLink.match(
                    /(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm
                  )
              ) === undefined) ? (
              <div>
                <br />
                There is no action for your customers to take after reading your
                message, consider adding a button with a link
                <br />
                <br />
              </div>
            ) : (
              ''
            )}
          </div>
        }
        actionName={values.timeConfig === 'DATETIME' ? 'Schedule' : 'Send'}
        cancel={() => setModalOpen(false)}
        confirm={handleSubmit}
      />
    </form>
  )
}

export default InnerForm
