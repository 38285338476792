import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core'
import React from 'react'

export const SmallDropdown = ({ options, value, onChange }) => {
  return (
    <Popover
      content={
        <Menu>
          {options.map(option => (
            <MenuItem
              key={option.value}
              text={option.label}
              icon={option.icon}
              onClick={() => {
                onChange(option.value)
              }}
            />
          ))}
        </Menu>
      }
    >
      <Button
        minimal
        icon={(options.find(x => x.value === value) || {}).icon}
      />
    </Popover>
  )
}
