import gql from 'graphql-tag'

const EDIT_LINK_CARD = gql`
  mutation editLinkCard(
    $id: String!
    $name: String
    $description: String
    $cardImage: String
    $url: String
    $type: String
    $outletId: String
    $menuItemId: String
    $buttonText: String
    $showOnWeb: Boolean
    $showOnApp: Boolean
    $availabilityStartDate: String
    $availabilityEndDate: String
    $availabilityTimes: Json
  ) {
    editLinkCard(
      id: $id
      name: $name
      description: $description
      cardImage: $cardImage
      url: $url
      type: $type
      outletId: $outletId
      menuItemId: $menuItemId
      buttonText: $buttonText
      showOnWeb: $showOnWeb
      showOnApp: $showOnApp
      availabilityStartDate: $availabilityStartDate
      availabilityEndDate: $availabilityEndDate
      availabilityTimes: $availabilityTimes
    ) {
      id
      name
      description
      url
      type
    }
  }
`

export default EDIT_LINK_CARD
