import { Button, Classes, Dialog, FormGroup, H5 } from '@blueprintjs/core'
import EDIT_MIN_APP_VERSION_OVERRIDE from './mutations/editMinAppVersionOverride.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import AppVersionDropdownContainer from '../Toolbar/AppVersionDropdownContainer'
import { isPlatformLevelUser } from '@stores/userStore'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

export const MinVersionOverrideModal = ({ partner, isOpen, setIsOpen }) => {
  const { partner: partnerId } = useParams()

  const [editVersionOverride] = useMutation(EDIT_MIN_APP_VERSION_OVERRIDE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMinAppVersionOverride }) => {
      successToast(
        editMinAppVersionOverride.count === 0
          ? 'All marketplaces are set to this or newer version'
          : `Successfully updated ${editMinAppVersionOverride.count} marketplaces`
      )
    },
  })

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Minimum Version"
    >
      <Formik
        initialValues={{
          id: partnerId,
          minAppVersionOverride: partner.minAppVersionOverride,
        }}
        onSubmit={values => {
          editVersionOverride({
            variables: {
              ...values,
            },
          })
        }}
      >
        {({ handleChange, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <div>
                <H5>Override All Marketplaces Minimum App Version</H5>
                <p>
                  This will update all marketplaces earliest app build that is
                  compatible with current Apple Store and Google Play
                  requirements. Only marketplaces using older minimum App
                  version will be affected.
                </p>
              </div>

              <FormGroup>
                <AppVersionDropdownContainer
                  onChange={handleChange}
                  name={'minAppVersionOverride'}
                  disabled={!isPlatformLevelUser()}
                  value={values.minAppVersionOverride}
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              {isPlatformLevelUser() && (
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Update" type="submit" />
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
