import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'
import { nutritionStructure } from './nutrition'

export const addMenuItemStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Please enter the name of the item')
    .min(2, 'Menu item name should be longer than 2 characters'),
  price: yup.number().positive().required('Please enter the item price'),
  costPrice: yup.number(),
  minimumPurchasePrice: yup.number(),

  SKU: yup.string().nullable(),
  UPC: yup
    .string()
    .nullable()
    .matches(/^[0-9]{12}$/, 'UPC must be exactly 12 numbers.'),
  bulkyItem: yup.bool(),
  menuItemGroupIds: yup
    .array()
    .of(
      yup
        .string()
        .min(25, 'A menu id should have 25 characters')
        .max(25, 'A menu id should have 25 characters')
    ),
}

export const editMenuItemStructure: IIterableInterface = {
  name: yup
    .string()
    .min(2, 'Menu item name should be longer than 2 characters'),
  price: yup.number().positive(),
  costPrice: yup.number(),
  minimumPurchasePrice: yup.number().max(yup.ref('price')),
  maxPurchaseQuantity: yup.number().positive().nullable(),
  SKU: yup.string().nullable(),
  UPC: yup
    .string()
    .nullable()
    .matches(/^[0-9]{12}$/, 'UPC must be exactly 12 numbers.'),
  bulkyItem: yup.bool(),
  menuItemGroupIds: yup
    .array()
    .of(
      yup
        .string()
        .min(25, 'A menu id should have 25 characters')
        .max(25, 'A menu id should have 25 characters')
    ),
  categoryTag: yup
    .string()
    .min(3, 'Category tag should be longer than 3 chars')
    .nullable(),
  isVegan: yup.boolean(),
  isVegetarian: yup.boolean(),
  isDairyFree: yup.boolean(),
  isGlutenFree: yup.boolean(),
  isKeto: yup.boolean(),
  spiceLevel: yup.number(),
  isPromoted: yup.boolean(),
  ageRestricted: yup.boolean(),
  vatRate: yup.number(),
  hidden: yup.boolean(),
  options: yup
    .array()
    .of(
      yup
        .string()
        .min(25, 'Option id should be 25 chars long')
        .max(25, 'Option id should be 25 chars long')
    )
    .nullable(),
  nutrition: yup.object().shape(nutritionStructure).nullable(),
  outletId: yup
    .string()
    .min(25, 'An outlet id should have 25 characters')
    .max(25, 'An outlet id should have 25 characters'),
  restaurantId: yup
    .string()
    .min(25, 'A restaurant id should have 25 characters')
    .max(25, 'A restaurant id should have 25 characters'),
  inheritedFrom: yup
    .string()
    .min(25, 'A parent id should have 25 characters')
    .max(25, 'A parent id should have 25 characters'),
  ingredients: yup
    .array()
    .of(
      yup
        .string()
        .min(25, 'Ingredient ids should have 25 characters')
        .max(25, 'Ingredient ids should have 25 characters')
    ),
  inheritants: yup
    .array()
    .of(
      yup
        .string()
        .min(25, 'Inheritant ids should have 25 characters')
        .max(25, 'Inheritant ids should have 25 characters')
    ),
  VATinclusive: yup.boolean(),
}
