import { useContext } from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { validation } from '@components/TableForm/validation'
import TableForm from '@components/TableForm/TableForm'
import ADD_TABLE from '@components/TableModal/mutations/addTable.mutation'
import GET_OUTLET_TABLES from '../Outlet/Tables/queries/getOutletTables.query'
import { ModalContext } from '@src/providers/ModalProvider'
import { useMutation } from '@apollo/client'

const AddTableModal = ({ modalOpen, modalClose, outletId }) => {
  const { setState: toggleModal } = useContext(ModalContext)

  const [addTable] = useMutation(ADD_TABLE, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Table successfully added')
      toggleModal({
        addTableModal: false,
      })
    },
    refetchQueries: [{ query: GET_OUTLET_TABLES, variables: { outletId } }],
  })

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add a table">
      <div className={Classes.DIALOG_BODY}>
        <TableForm
          onSubmit={values =>
            addTable({
              variables: {
                ...values,
              },
            })
          }
          initialValues={{
            outletId,
            friendlyName: '',
          }}
          validationSchema={validation}
        />
      </div>
    </Dialog>
  )
}

AddTableModal.propTypes = {
  ...modalType,
}

export default AddTableModal
