import { object, func } from 'prop-types'
import { ReactSVG } from 'react-svg'
import { withFormik } from 'formik'
import { Card } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'
import styled from 'styled-components'

import TwoFactorAuthForm from './TwoFactorAuthForm'
import logo from '@assets/logo.svg'
import TWO_FACTOR_CHECK from './mutations/twoFactorCheck.mutation'

import { Container, LoginContainer } from '../Login/Login.styles'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const handleLogin = (data, navigate) => {
  ls.set('jwt', data.token)
  ls.set('permissionCategories', get(data.role, 'permissionCategories'))
  ls.set('role', get(data.role, 'title'))
  ls.set('ownsPartners', data.ownsPartners)
  navigate('/')
}

const Logo = styled(ReactSVG)`
  height: 111px;
  width: 89px;
`

const TwoFactorAuthWrapper = props => {
  const navigate = useNavigate()

  const [twoFactorCheck] = useMutation(TWO_FACTOR_CHECK, {
    onError: error => {
      const serverErrors = get(error, 'graphQLErrors', [])

      // fallback for errors that weren't returned by the server
      if (!serverErrors.length) {
        return errorToast(error.message)
      }

      // loop through custom server errors displaying error toast
      for (const serverError of serverErrors) {
        errorToast(serverError.message)
      }

      props.setSubmitting(false)
    },
    onCompleted: ({ twoFactorCheck }) => {
      handleLogin(twoFactorCheck, navigate)
    },
  })

  return (
    <Container>
      <LoginContainer>
        <Logo src={logo} alt={'logo'} />
        <Card elevation="2">
          <TwoFactorAuthForm mutation={twoFactorCheck} {...props} />
        </Card>
      </LoginContainer>
    </Container>
  )
}

const TwoFactorAuth = withFormik({
  mapPropsToValues: () => ({
    code: '',
  }),
})(TwoFactorAuthWrapper)

TwoFactorAuth.propTypes = {
  history: object,
  setSubmitting: func,
}

export default TwoFactorAuth
export { TwoFactorAuth }
