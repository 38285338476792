import gql from 'graphql-tag'

const GET_REVIEWS = gql`
  query getReviews(
    $skip: Int
    $first: Int
    $last: Int
    $where: ReviewWhereInput
  ) {
    getReviews(
      skip: $skip
      first: $first
      last: $last
      where: $where
      orderBy: createdAt_DESC
    ) {
      reviews {
        id
        createdAt
        updatedAt
        foodQuality
        restaurantService
        deliveryTime
        reviewText
        order {
          id
          orderNumber
        }
        outlet {
          id
          name
          restaurant {
            id
          }
        }
        customer {
          id
          firstName
        }
        approved
        reply
        replyDate
      }
    }
  }
`

export default GET_REVIEWS
