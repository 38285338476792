import * as yup from 'yup'

export const addTableStructure = {
  friendlyName: yup
    .string()
    .required('Please provide a name or number for the table'),
  outletId: yup
    .string()
    .matches(/\w{25}/, 'The outlet id should have 25 chars')
    .required('Outlet id is required'),
}

export const editTableStructure = {
  friendlyName: yup
    .string()
    .required('Please provide a name or number for the table'),
}
