import gql from 'graphql-tag'

const GET_OUTLETS = gql`
  query getOutletNamesByMarketplaceId($marketplaceIds: [ID!]) {
    getOutlets(marketplaceIds: $marketplaceIds) {
      outlets {
        id
        name
      }
    }
  }
`

export default GET_OUTLETS
