import gql from 'graphql-tag'

const GET_OUTLET_DELIVERY_WINDOWS = gql`
  query getOutletDeliveryWindows($outletId: ID!) {
    getOutletDeliveryWindows(outletId: $outletId) {
      outletDeliveryWindows {
        id
        start_day
        start_hour
        start_minute
        end_day
        end_hour
        end_minute
        limit
      }
    }
  }
`

export default GET_OUTLET_DELIVERY_WINDOWS
