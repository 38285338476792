import gql from 'graphql-tag'

const ONBOARDING_APPROVE = gql`
  mutation onboardingApprove($id: String!) {
    onboardingApprove(id: $id) {
      message
      restaurant {
        id
        name
      }
    }
  }
`

export default ONBOARDING_APPROVE
