import gql from 'graphql-tag'

const EDIT_MARKETPLACE_MESSAGE_TEMPLATE = gql`
  mutation editMarketplaceMessageTemplate(
    $id: ID!
    $marketplaceId: String!
    $templateType: String!
    $emailSubject: String!
    $emailPreviewText: String!
    $emailContent: [Json!]!
    $pushTitle: String!
    $pushBody: String!
  ) {
    editMarketplaceMessageTemplate(
      id: $id
      marketplaceId: $marketplaceId
      templateType: $templateType
      emailSubject: $emailSubject
      emailPreviewText: $emailPreviewText
      emailContent: $emailContent
      pushBody: $pushBody
      pushTitle: $pushTitle
    ) {
      message
      messageTemplate {
        id
        templateType
        emailSubject
        emailPreviewText
        emailContent
        pushTitle
        pushBody
      }
    }
  }
`

export default EDIT_MARKETPLACE_MESSAGE_TEMPLATE
