import React, { Fragment, useContext, useMemo } from 'react'
import { renderTooltip } from './renderTooltip'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from 'recharts'
import { userAgentColorGenerator } from '@components/Analytics/util/userAgentColorGenerator'
import { BusinessAnalyticsContext } from './BusinessAnalyticsContext'
import { find } from 'lodash'
import moment from 'moment'
import {
  FulfillmentMethodsMap,
  OrderStatusesMap,
  PaymentMethodsMap,
  ANALYTICS_COLORS,
} from '../../Analytics/styles'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import Currency from '@components/Currency/Currency'

const Chart = ({
  data: { transformedData, userAgentEntries },
  isSingleDayShiftSelection,
}) => {
  const [
    {
      displayBy = 'byValueOfOrders',
      showOutlets = false,
      showCustomers = false,
      showPaymentMethods = false,
      showFulfillmentMethods = false,
      showUserAgents = false,
      urlSignificantDates,
    },
  ] = useQueryParams({
    displayBy: StringParam,
    showOutlets: BooleanParam,
    showCustomers: BooleanParam,
    showPaymentMethods: BooleanParam,
    showFulfillmentMethods: BooleanParam,
    showUserAgents: BooleanParam,
    urlSignificantDates: StringParam,
  })
  const { significantDates, activationDates } = useContext(
    BusinessAnalyticsContext
  )

  const userAgentColorMap = useMemo(
    () => userAgentColorGenerator(userAgentEntries),
    [userAgentEntries]
  )

  const BarOptions = {
    // default
    byValueOfOrders: (
      <Fragment>
        <Bar
          dataKey="subTotalWithNoDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.noDiscountFill}
          yAxisId="left"
        />
        <Bar
          dataKey="subTotalWithRegularDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.regularDiscountFill}
          yAxisId="left"
        />
        <Bar
          dataKey="subTotalWithMemberDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.memberDiscountFill}
          yAxisId="left"
        />
        <Bar
          dataKey="subTotalWithVoucherDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.vouchersDiscountFill}
          yAxisId="left"
        />
      </Fragment>
    ),
    byNumberOfOrders: (
      <Fragment>
        <Bar
          dataKey="numberOfOrdersWithNoDiscount"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.noDiscountFill}
          yAxisId="right"
        />
        <Bar
          dataKey="numberOfOrdersWithRegularDiscount"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.regularDiscountFill}
          yAxisId="right"
        />
        <Bar
          dataKey="numberOfOrdersWithMemberDiscount"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.memberDiscountFill}
          yAxisId="right"
        />
        <Bar
          dataKey="numberOfOrdersWithVoucherDiscount"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.vouchersDiscountFill}
          yAxisId="right"
        />
      </Fragment>
    ),
    byDiscountedValueOfOrders: (
      <Fragment>
        <Bar
          dataKey="subTotalDiscountedRegularDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.regularDiscountFill}
          yAxisId="left"
        />
        <Bar
          dataKey="subTotalDiscountedMemberDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.memberDiscountFill}
          yAxisId="left"
        />
        <Bar
          dataKey="subTotalDiscountedVoucherDiscounts"
          stackId="totalsBar"
          fill={ANALYTICS_COLORS.vouchersDiscountFill}
          yAxisId="left"
        />
      </Fragment>
    ),
    byOrderStatus: (
      <Fragment>
        {Object.keys(OrderStatusesMap).map(status => (
          <Bar
            key={status}
            dataKey={`statuses.${status}`}
            stackId="statusBar"
            fill={OrderStatusesMap[status]}
            yAxisId="right"
          />
        ))}
      </Fragment>
    ),
    byAverageValueOfOrders: (
      <Line
        type="monotone"
        connectNulls
        dataKey="dailyAverage"
        stroke="blue"
        strokeWidth={3}
        activeDot={{ r: 10 }}
        yAxisId="left"
      />
    ),
  }

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart
        data={transformedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis
          yAxisId="left"
          tickFormatter={val => <Currency amount={val} />}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          allowDecimals={false}
          label={
            showOutlets ||
            showCustomers ||
            showFulfillmentMethods ||
            showPaymentMethods ||
            displayBy === 'byOrderStatus' ||
            displayBy === 'byNumberOfOrders'
              ? {
                  value: 'Number of Orders',
                  angle: -90,
                  position: 'right',
                }
              : null
          }
        />
        {BarOptions[displayBy]}

        {showPaymentMethods && (
          <Fragment>
            {Object.keys(PaymentMethodsMap).map(payment => (
              <Bar
                key={payment}
                dataKey={`paymentMethods.${payment}`}
                stackId="paymentBar"
                fill={PaymentMethodsMap[payment]}
                yAxisId="right"
              />
            ))}
          </Fragment>
        )}

        {showFulfillmentMethods && (
          <Fragment>
            {Object.keys(FulfillmentMethodsMap).map(fulfillment => (
              <Bar
                key={fulfillment}
                dataKey={`fulfillmentMethods.${fulfillment}`}
                stackId="fulfillmentBar"
                fill={FulfillmentMethodsMap[fulfillment]}
                yAxisId="right"
              />
            ))}
          </Fragment>
        )}

        {showUserAgents && (
          <Fragment>
            {userAgentEntries.map(userAgent => (
              <Bar
                key={userAgent}
                dataKey={`userAgents["${userAgent}"]`}
                stackId="userAgentsBar"
                fill={userAgentColorMap[userAgent]}
                yAxisId="right"
              />
            ))}
          </Fragment>
        )}

        {showOutlets && (
          <Line
            type="monotone"
            connectNulls
            dataKey="numberOfOutlets"
            stroke="red"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            yAxisId="right"
          />
        )}

        {showCustomers && (
          <Line
            dataKey="numberOfCustomers"
            connectNulls
            stroke="green"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            yAxisId="right"
          />
        )}

        {urlSignificantDates &&
          !isSingleDayShiftSelection &&
          urlSignificantDates.split('-').map(urlDate => {
            const data = find(
              significantDates.concat(activationDates),
              ({ formattedDate }) => formattedDate === urlDate
            )
            return data ? (
              <ReferenceLine
                key={urlDate}
                stroke="red"
                strokeDasharray="3 3"
                strokeWidth={3}
                label={data.name}
                yAxisId="left"
                x={moment(data.formattedDate, 'YYYYMMDD').format('DD-MM-YYYY')}
              />
            ) : null
          })}
        <Tooltip
          content={tooltipData =>
            renderTooltip({
              tooltipData,
              isSingleDayShiftSelection,
            })
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default Chart
