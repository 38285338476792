import gql from 'graphql-tag'

const GET_RESTAURANT_OUTLETS_WITH_CATEGORIES = gql`
  query getRestaurantOutletsWithCategories($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        outlets {
          id
          name
          active
          outletCategories {
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default GET_RESTAURANT_OUTLETS_WITH_CATEGORIES
