import gql from 'graphql-tag'

const EDIT_RESTAURANT_FIANCIALS = gql`
  mutation editRestaurantFinancials(
    $id: String!
    $partnerFee: Float
    $partnerTableFee: Float
    $partnerCharge: Int
    $partnerTableCharge: Int
    $merchantCommissionCharge: Int
    $merchantTableCommissionCharge: Int 
  ) {
    editRestaurantFinancials(
      id: $id
      partnerFee: $partnerFee
      partnerTableFee: $partnerTableFee
      partnerCharge: $partnerCharge
      partnerTableCharge: $partnerTableCharge
      merchantCommissionCharge: $merchantCommissionCharge
      merchantTableCommissionCharge: $merchantTableCommissionCharge
    ) {
      message
      restaurant {
        id
        name
        partnerFee
        partnerTableFee
        partnerCharge
        partnerTableCharge
        merchantCommissionCharge
        merchantTableCommissionCharge
        stripeId
        partner {
          id
        }
        active
      }
    }
  }
`
export default EDIT_RESTAURANT_FIANCIALS
