import gql from 'graphql-tag'

const DELETE_PLATFORM_WEBHOOKS = gql`
  mutation deletePlatformStripeWebhooks($paybox: Boolean!) {
    deletePlatformStripeWebhooks(paybox: $paybox) {
      id
      message
    }
  }
`

export default DELETE_PLATFORM_WEBHOOKS
