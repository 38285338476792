import React from 'react'
import { Cell } from '@blueprintjs/table'
import Currency from '@components/Currency/Currency'
import { get } from 'lodash'

const threeDashes = '-' // used in place of NaN, undefined, null, etc

export const RowRenderers = transactions => ({
  responseLength: transactions.length,
  // non-monetary values
  renderTransactionId: row => <Cell>{transactions[row].id}</Cell>,
  renderCreatedAt: row => (
    <Cell>{new Date(transactions[row].createdAt).toLocaleString()}</Cell>
  ),
  renderAcceptedAt: row => (
    <Cell>{new Date(transactions[row].acceptedAt).toLocaleString()}</Cell>
  ),
  renderRefundedAt: row => (
    <Cell>
      {transactions[row].refundedAt
        ? new Date(transactions[row].refundedAt).toLocaleString()
        : threeDashes}
    </Cell>
  ),
  renderPartner: row => <Cell>{transactions[row].partner.name}</Cell>,
  renderMarketplace: row => (
    <Cell>
      {transactions[row].marketplace
        ? transactions[row].marketplace.name
        : threeDashes}
    </Cell>
  ),
  renderStatus: row => (
    <Cell>
      {transactions[row].orderStatus == 'PREPARING'
        ? 'ACCEPTED'
        : transactions[row].orderStatus}
    </Cell>
  ),
  renderOrder: row => (
    <Cell>
      {transactions[row].order
        ? transactions[row].order.orderNumber
        : threeDashes}
    </Cell>
  ),
  renderCustomer: row => (
    <Cell>
      {transactions[row].customer &&
      transactions[row].customer.firstName &&
      transactions[row].customer.lastName
        ? transactions[row].customer.firstName +
          ' ' +
          transactions[row].customer.lastName[0]
        : threeDashes}
    </Cell>
  ),
  renderFulfillment: row => (
    <Cell>{transactions[row].fulfillmentType || threeDashes}</Cell>
  ),
  renderTransactionType: row => (
    <Cell>{transactions[row].transactionType || threeDashes}</Cell>
  ),
  renderPayment: row => (
    <Cell>{transactions[row].paymentType || threeDashes}</Cell>
  ),
  renderCustomerStripeId: row => (
    <Cell>{transactions[row].customerStripeId || threeDashes}</Cell>
  ),
  renderTransactionStripeId: row => (
    <Cell>{transactions[row].transactionStripeId || threeDashes}</Cell>
  ),
  renderRestaurant: row => (
    <Cell>{get(transactions[row].outlet.restaurant, 'name', threeDashes)}</Cell>
  ),
  renderOutlet: row => (
    <Cell>{get(transactions[row].outlet, 'name', threeDashes)}</Cell>
  ),
  // monetary values
  renderOrderNetTotal: row => (
    <Cell>
      <Currency amount={transactions[row].orderNetTotal || 0} />
    </Cell>
  ),
  renderOrderGrossTotal: row => (
    <Cell>
      <Currency amount={transactions[row].orderGrossTotal || 0} />
    </Cell>
  ),
  renderAddOnItemsNetTotal: row => (
    <Cell>
      <Currency amount={transactions[row].addOnItemsNetTotal || 0} />
    </Cell>
  ),
  renderAddOnItemsGrossTotal: row => (
    <Cell>
      <Currency amount={transactions[row].addOnItemsGrossTotal || 0} />
    </Cell>
  ),
  renderDiscountAmount: row => (
    <Cell>
      <Currency amount={transactions[row].discountAmount || 0} />
    </Cell>
  ),
  renderDiscount: row => (
    <Cell>{get(transactions[row].discount, 'name', threeDashes)}</Cell>
  ),
  renderCustomerDeliveryCharge: row => (
    <Cell>
      <Currency amount={transactions[row].customerDeliveryCharge || 0} />
    </Cell>
  ),
  renderRestaurantDeliveryCharge: row => (
    <Cell>
      <Currency amount={transactions[row].restaurantDeliveryCharge || 0} />
    </Cell>
  ),
  renderNonNetworkFulfilmentChargeGross: row => (
    <Cell>
      <Currency
        amount={transactions[row].nonNetworkFulfilmentChargeGross || 0}
      />
    </Cell>
  ),
  renderNonNetworkFulfilmentChargeNet: row => (
    <Cell>
      <Currency amount={transactions[row].nonNetworkFulfilmentChargeNet || 0} />
    </Cell>
  ),
  renderNonNetworkFulfilmentChargeVAT: row => (
    <Cell>
      <Currency amount={transactions[row].nonNetworkFulfilmentChargeVAT || 0} />
    </Cell>
  ),
  renderNetworkDeliveryCharge: row => (
    <Cell>
      <Currency amount={transactions[row].networkDeliveryCharge || 0} />
    </Cell>
  ),
  renderMarketplaceDeliverySubsidy: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceDeliverySubsidy || 0} />
    </Cell>
  ),
  renderMerchantDeliverySubsidy: row => (
    <Cell>
      <Currency amount={transactions[row].merchantDeliverySubsidy || 0} />
    </Cell>
  ),
  renderDeliveryChargeRecipient: row => (
    <Cell>{transactions[row].deliveryChargeRecipient || threeDashes}</Cell>
  ),
  renderMarketplaceServiceCharge: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceServiceCharge || 0} />
    </Cell>
  ),
  renderMerchantCommissionCharge: row => (
    <Cell>
      <Currency amount={transactions[row].merchantCommissionCharge || 0} />
    </Cell>
  ),
  renderMerchantCommissionChargeWithVat: row => (
    <Cell>
      <Currency
        amount={transactions[row].merchantCommissionChargeWithVat || 0}
      />
    </Cell>
  ),
  renderPartnerCommissionCharge: row => (
    <Cell>
      <Currency amount={transactions[row].partnerCommissionCharge || 0} />
    </Cell>
  ),
  renderCustomerTaxes: row => (
    <Cell>
      <Currency amount={transactions[row].customerTaxes || 0} />
    </Cell>
  ),
  renderMerchantNetworkDeliveryAllocation: row => (
    <Cell>
      <Currency
        amount={transactions[row].merchantNetworkDeliveryAllocation || 0}
      />
    </Cell>
  ),
  renderMarketplaceNetworkDeliveryAllocation: row => (
    <Cell>
      <Currency
        amount={transactions[row].marketplaceNetworkDeliveryAllocation || 0}
      />
    </Cell>
  ),
  renderMarketplaceChargeVATPaidByMerchant: row => (
    <Cell>
      <Currency
        amount={transactions[row].marketplaceChargeVATPaidByMerchant || 0}
      />
    </Cell>
  ),
  renderMarketplaceChargeVATPaidByCustomer: row => (
    <Cell>
      <Currency
        amount={transactions[row].marketplaceChargeVATPaidByCustomer || 0}
      />
    </Cell>
  ),
  renderMarketplaceChargeVATPayer: row => (
    <Cell>{transactions[row].marketplaceChargeVATPayer}</Cell>
  ),
  renderSubtotalNetAfterDiscount: row => (
    <Cell>
      <Currency amount={transactions[row].subtotalNetAfterDiscount || 0} />
    </Cell>
  ),
  renderSubtotalGrossAfterDiscount: row => (
    <Cell>
      <Currency amount={transactions[row].subtotalGrossAfterDiscount || 0} />
    </Cell>
  ),
  renderSubtotalVATAfterDiscount: row => (
    <Cell>
      <Currency amount={transactions[row].subtotalVATAfterDiscount || 0} />
    </Cell>
  ),
  renderSubtotalMinusMarketplaceCharge: row => (
    <Cell>
      <Currency
        amount={transactions[row].subtotalMinusMarketplaceCharge || 0}
      />
    </Cell>
  ),
  renderSubtotalMinusNetworkDeliveryCharge: row => (
    <Cell>
      <Currency
        amount={transactions[row].subtotalMinusNetworkDeliveryCharge || 0}
      />
    </Cell>
  ),
  renderSubtotalMinusNetworkDeliveryMarketplaceCharge: row => (
    <Cell>
      <Currency
        amount={
          transactions[row].subtotalMinusNetworkDeliveryMarketplaceCharge || 0
        }
      />
    </Cell>
  ),
  renderTotalOrderValue: row => (
    <Cell>
      <Currency amount={transactions[row].totalOrderValue || 0} />
    </Cell>
  ),
  renderTotalPaidCash: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidCash || 0} />
    </Cell>
  ),
  renderMarketplaceFeePercentage: row => (
    <Cell>{transactions[row].marketplaceFeePercentage || 0}%</Cell>
  ),
  renderMarketplaceFeeNet: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceFeeNet || 0} />
    </Cell>
  ),
  renderMarketplaceFee: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceFee || 0} />
    </Cell>
  ),
  renderPartnerFeePercentageWithVat: row => (
    <Cell>{transactions[row].partnerFeePercentageWithVat || 0}%</Cell>
  ),
  renderPartnerFeeNet: row => (
    <Cell>
      <Currency amount={transactions[row].partnerFeeNet || 0} />
    </Cell>
  ),
  renderPartnerFee: row => (
    <Cell>
      <Currency amount={transactions[row].partnerFee || 0} />
    </Cell>
  ),
  renderPlatformCardProcessingFee: row => (
    <Cell>
      <Currency amount={transactions[row].platformCardProcessingFee || 0} />
    </Cell>
  ),
  renderPlatformCardProcessingCharge: row => (
    <Cell>
      <Currency amount={transactions[row].platformCardProcessingCharge || 0} />
    </Cell>
  ),
  renderPlatformCardProcessingCostCharge: row => (
    <Cell>
      <Currency amount={transactions[row].platformCardProcessingCharge || 0} />
    </Cell>
  ),
  renderRedboxCardProcessingCostFee: row => (
    <Cell>
      <Currency amount={transactions[row].redboxCardProcessingCostFee || 0} />
    </Cell>
  ),
  renderRedboxCardProcessingCostCharge: row => (
    <Cell>
      <Currency
        amount={transactions[row].redboxCardProcessingCostCharge || 0}
      />
    </Cell>
  ),
  renderRedboxFeePercentageWithVat: row => (
    <Cell>{transactions[row].redboxFeePercentageWithVat || 0}%</Cell>
  ),
  renderRedboxFeeNet: row => (
    <Cell>
      <Currency amount={transactions[row].redboxFeeNet || 0} />
    </Cell>
  ),
  renderRedboxFee: row => (
    <Cell>
      <Currency amount={transactions[row].redboxFee || 0} />
    </Cell>
  ),
  renderPlatformFeePercentageWithVat: row => (
    <Cell>{transactions[row].platformFeePercentageWithVat || 0}%</Cell>
  ),
  renderPlatformFeeNet: row => (
    <Cell>
      <Currency amount={transactions[row].platformFeeNet || 0} />
    </Cell>
  ),
  renderPlatformFee: row => (
    <Cell>
      <Currency amount={transactions[row].platformFee || 0} />
    </Cell>
  ),
  renderRedboxChargeNet: row => (
    <Cell>
      <Currency amount={transactions[row].redboxChargeNet || 0} />
    </Cell>
  ),
  renderRedboxCharge: row => (
    <Cell>
      <Currency amount={transactions[row].redboxCharge || 0} />
    </Cell>
  ),
  renderMarketplaceChargeNet: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceChargeNet || 0} />
    </Cell>
  ),
  renderMarketplaceChargeGross: row => (
    <Cell>
      <Currency amount={transactions[row].marketplaceChargeGross || 0} />
    </Cell>
  ),
  renderTotalMarketplaceCharge: row => (
    <Cell>
      <Currency amount={transactions[row].totalMarketplaceCharge || 0} />
    </Cell>
  ),
  renderMarketplaceChargeFromMerchantNet: row => (
    <Cell>
      <Currency
        amount={transactions[row].marketplaceChargeFromMerchantNet || 0}
      />
    </Cell>
  ),
  renderMarketplaceChargeFromMerchantGross: row => (
    <Cell>
      <Currency
        amount={transactions[row].marketplaceChargeFromMerchantGross || 0}
      />
    </Cell>
  ),
  renderTotalToPlatform: row => (
    <Cell>
      <Currency amount={transactions[row].totalToPlatform || 0} />
    </Cell>
  ),
  renderTotalPaidToPlatform: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToPlatform || 0} />
    </Cell>
  ),
  renderTotalToRedbox: row => (
    <Cell>
      <Currency amount={transactions[row].totalToRedbox || 0} />
    </Cell>
  ),
  renderTotalPaidToRedbox: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToRedbox || 0} />
    </Cell>
  ),
  renderTotalToPaybox: row => (
    <Cell>
      <Currency amount={transactions[row].totalToPaybox || 0} />
    </Cell>
  ),
  renderTotalPaidToPaybox: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToPaybox || 0} />
    </Cell>
  ),
  renderTotalToPartner: row => (
    <Cell>
      <Currency amount={transactions[row].totalToPartner || 0} />
    </Cell>
  ),
  renderTotalPaidToPartner: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToPartner || 0} />
    </Cell>
  ),
  renderTotalToMarketplace: row => (
    <Cell>
      <Currency amount={transactions[row].totalToMarketplace || 0} />
    </Cell>
  ),
  renderTotalPaidToMarketplace: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToMarketplace || 0} />
    </Cell>
  ),
  renderMarketplaceTransferStripeId: row => (
    <Cell>{transactions[row].marketplaceTransferStripeId || threeDashes}</Cell>
  ),
  renderTotalToMarketplaceAfterFees: row => (
    <Cell>
      <Currency amount={transactions[row].totalToMarketplaceAfterFees || 0} />
    </Cell>
  ),
  renderTotalToDeliveryNetwork: row => (
    <Cell>
      <Currency amount={transactions[row].totalToDeliveryNetwork || 0} />
    </Cell>
  ),
  renderTotalToOutlet: row => (
    <Cell>
      <Currency amount={transactions[row].totalToOutlet || 0} />
    </Cell>
  ),
  renderTotalToOutletPreRefund: row => (
    <Cell>
      <Currency amount={transactions[row].totalToOutletPreRefund || 0} />
    </Cell>
  ),
  renderTotalPaidToOutlet: row => (
    <Cell>
      <Currency amount={transactions[row].totalPaidToOutlet || 0} />
    </Cell>
  ),
  renderOutletTransferStripeId: row => (
    <Cell>{transactions[row].outletTransferStripeId || threeDashes}</Cell>
  ),
  renderTotalRefund: row => (
    <Cell>
      <Currency amount={transactions[row].totalRefund || 0} />
    </Cell>
  ),
  renderMerchantAndMarketplaceImprecision: row => (
    <Cell>
      <Currency
        amount={transactions[row].merchantAndMarketplaceImprecision || 0}
      />
    </Cell>
  ),
  renderPartnerAndRedboxImprecision: row => (
    <Cell>
      <Currency amount={transactions[row].partnerAndRedboxImprecision || 0} />
    </Cell>
  ),
})
