import gql from 'graphql-tag'

const GET_MARKETPLACE_GEOFENCE = gql`
  query getMarketplaceGeofence($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        geoFence
        deliveryZones {
          id
          name
          geoFence
        }
        outlets {
          id
          name
          outletAddress {
            geo
            firstLine
            secondLine
            city
            postcode
          }
          restaurant {
            id
            name
            description
          }
          contactName
          contactEmail
          contactPhone
        }
      }
    }
  }
`

export default GET_MARKETPLACE_GEOFENCE
