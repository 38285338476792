import { Card } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import React from 'react'
import { DataKeyMap } from '../util/maps'

export const TotalValuesContainer = ({
  totalValues: { total, orders },
  title,
  resolution = false,
}) => {
  return total > 0 ? (
    <Card>
      <p>{title}</p>
      <p>
        Total&nbsp;
        <Currency amount={total.toFixed(2)} amountInPence={false} />
        {resolution ? ` per ${resolution}` : ''}
      </p>
      {orders.map(({ value, color, dataKey }) => {
        return value ? (
          <div key={dataKey}>
            <small style={{ color }}>
              {DataKeyMap[dataKey]}{' '}
              <span style={{ color: 'black' }}>
                <Currency amount={value.toFixed(2)} amountInPence={false} />
              </span>
            </small>
          </div>
        ) : null
      })}
    </Card>
  ) : null
}
