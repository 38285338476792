import { bool, func, string } from 'prop-types'

import Query from '@components/Query/Query'
import TypeaheadSingle from '../Typeahead/TypeaheadSingle'
import GET_MARKETPLACE_IDS from './queries/getMarketplaceIds.query.js'

// TODO refactor to use useQuery hook
const MarketplaceSelect = ({ marketplaceId, disabled, onChange }) => (
  <Query query={GET_MARKETPLACE_IDS}>
    {data => (
      <TypeaheadSingle
        items={
          // !loading &&
          // !error &&
          data.getMarketplaces.regions.map(({ id, name, partner }) => ({
            id,
            name,
            label: partner.name,
          }))
        }
        // intent={error ? Intent.DANGER : Intent.NONE}
        disabled={disabled} //|| loading || error}
        placeholder={
          // loading ? 'Loading Marketplaces...' : 'Select Marketplace...'
          'Select Marketplace...'
        }
        // selected={!loading && marketplaceId}
        selected={marketplaceId}
        setSelected={value => onChange(value)}
        // rightElement={loading ? <Spinner size={Spinner.SIZE_SMALL} /> : null}
      />
    )}
  </Query>
)

MarketplaceSelect.propTypes = {
  marketplaceId: string,
  disabled: bool,
  onChange: func,
}

export default MarketplaceSelect
