import gql from 'graphql-tag'

const GET_MARKETPLACE_IDS = gql`
  query getMarketPlaceIdsInMarketplace {
    getMarketplaces {
      regions {
        id
        name
        partner {
          id
          name
        }
      }
    }
  }
`

export default GET_MARKETPLACE_IDS
