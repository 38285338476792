import gql from 'graphql-tag'

const GET_ORDERS = gql`
  query getOrders(
    $orderBy: OrdersOrderBy
    $afterDate: DateTime
    $beforeDate: DateTime
    $orderNumber: String
    $orderStatus: [OrderStatus!]
    $marketplaceIds: [ID!]
    $restaurantIds: [ID!]
    $outletIds: [ID!]
    $fulfilmentMethod: [OrderFulfillmentMethods!]
    $paymentMethod: [PaymentMethod!]
    $deliveryNetworkProvider: [DeliveryProvidersEnum!]
    $skip: Int
    $first: Int
    $last: Int
  ) {
    orders(
      marketplaceId_in: $marketplaceIds
      outletId_in: $outletIds
      restaurantId_in: $restaurantIds
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
      orderStatus_in: $orderStatus
      fulfillmentMethod_in: $fulfilmentMethod
      paymentMethod_in: $paymentMethod
      orderNumber_starts_with: $orderNumber
      deliveryNetworkProvider_in: $deliveryNetworkProvider
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      count
      totalCount
      totalPending
      totalInprogress
      totalCompleted
      totalRejected
      totalRefund
      totalDeliveryNetworkFailure
      orders {
        id
        orderNumber
        customer {
          id
          firstName
          lastName
          totalValue
          totalCompletedOrders
        }
        outlet {
          id
          name
          active
          contactPhone
          contactEmail
          isOnline
          restaurant {
            id
            name
            active
          }
          marketplace {
            id
            name
            faviconImage
          }
        }
        grossTotal
        createdAt
        updatedAt
        orderStatus
        fulfillmentMethod
        estimatedDeliveryDate
        selectedDeliverySlot
        selectedDeliveryWindow
        estimatedCompletionTime
        paymentMethod
        asap
        deliveryNetworkBookingStatus
        tableSnapshot
        deliveryNetworkProvider
        autoRejectAt
        autoAcceptedAt
        isAutoRejected
        cancellationNotes
      }
    }
  }
`

export default GET_ORDERS
