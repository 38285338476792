import { useEffect, useState } from 'react'
import { unsubscribe } from '@services/client'
import { func } from 'prop-types'
import { withFormik } from 'formik'
import { Spinner } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'
import { Link, useNavigate } from 'react-router-dom'

import RegisterForm from './RegisterForm'

import Query from '@components/Query/Query'
import REGISTER from './mutations/register.mutation'
import logo from '@assets/logo.svg'
import loginIcon from '@assets/icons/login.svg'
import CHECK_USER_CAN_REGISTER from './queries/checkUserCanRegister.query'
import { newUserRegisterStructure } from '../../validation/user'
import * as yup from 'yup'
import {
  Container,
  Icon,
  LoginContainer,
  LoginFormCard,
  LogoContainer,
} from '../Login/Login.styles'
import { useQueryParams } from 'use-query-params'
import { useMutation } from '@apollo/client'
// import * as Sentry from '@sentry/react'

const handleRegister = (data, navigate) => {
  ls.set('canUse2FA', data.canUse2FA)
  ls.set('phoneNumber', data.phoneNumber)
  if (data.canUse2FA) {
    navigate('/2fa')
  } else {
    navigate('/validate-phone')
  }
}

const RegisterWrapper = props => {
  const navigate = useNavigate()
  const [{ uid, token }] = useQueryParams(['uid', 'token'])
  const [isInitialised, setIsInitialised] = useState(false)

  useEffect(() => {
    if (!uid || !token) {
      navigate('/')
    }
    ls.set('partialJwt', token)

    // if user is already logged in, force them to logout
    if (ls.get('jwt')) {
      localStorage.clear()
      void unsubscribe() // clear cache and reset link state defaults
      // Sentry.setUser(null)

      location.reload()
    }

    setIsInitialised(true)
  }, [token, uid])

  const [register] = useMutation(REGISTER, {
    onError: error => {
      const serverErrors = get(error, 'graphQLErrors', [])

      // fallback for errors that weren't returned by the server
      if (!serverErrors.length) {
        return errorToast(error.message)
      }

      // loop through custom server errors displaying error toast
      for (const serverError of serverErrors) {
        errorToast(serverError.message)
      }

      props.setSubmitting(false)
    },
    onCompleted: ({ register }) => {
      handleRegister(register.user, navigate)
    },
  })
  return isInitialised ? (
    <Query query={CHECK_USER_CAN_REGISTER}>
      {({ checkUserCanRegister }) => {
        return (
          <Container>
            <LoginContainer>
              <LogoContainer src={logo} alt={'logo'} />
              <LoginFormCard elevation={2}>
                <Icon src={loginIcon} />
                {checkUserCanRegister.canRegister ? (
                  <RegisterForm
                    mutation={register}
                    data={checkUserCanRegister}
                    {...props}
                  />
                ) : (
                  <p>
                    This account has already been registered please{' '}
                    <Link to="/login">login</Link>
                  </p>
                )}
              </LoginFormCard>
            </LoginContainer>
          </Container>
        )
      }}
    </Query>
  ) : (
    <Spinner />
  )
}

const Register = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
  }),
  validationSchema: yup.object(newUserRegisterStructure),
})(RegisterWrapper)

RegisterWrapper.propTypes = {
  setSubmitting: func,
}

export default Register
export { Register }
