import { Fragment } from 'react'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import GET_OUTLET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getOutletParentMenus.query'
// import { togglePane, setValue } from '@utils/cacheHelpers'
// TODO: fix pane

import {
  Classes,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
  TreeNodeInfo,
} from '@blueprintjs/core'
import { get, countBy, sumBy } from 'lodash'
import Query from '@components/Query/Query'
import SubMenu from './SubMenu'
import MenusList from './MenusList'
import NoParentMenus from '@components/Restaurant/Menu/components/NoParentMenus'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'
import { Link } from 'react-router-dom'
import OutletSubMenu from '@components/Restaurant/Menu/MenuTree/OutletSubMenu'
import { useMenuState } from './MenuStateProvider'

type FormatMenusArgs = {
  menus: Array<any>
  queryId: string
  menuId: string
  outlet: boolean
  openingTimes: Array<any>
  soldOutUntilItems: Array<any>
  outletSoldOut: Array<any>
  allowAddOnItems: boolean
  restaurantId: string
  hiddenMenuItemGroupIds: Array<any>
  outletId: string
  setValue: (value: any) => void
}

type FormatMenusReturn = Array<
  TreeNodeInfo<{
    id: string
    key: string
    hidden: boolean
    icon: string
    label: any
    position: number
    className: string
    onClick: () => void
    secondaryLabel: any
  }>
>

const formatMenus = ({
  menus,
  queryId,
  menuId,
  outlet,
  outletSoldOut,
  allowAddOnItems,
  restaurantId,
  hiddenMenuItemGroupIds,
  outletId,
  setValue,
}: FormatMenusArgs): FormatMenusReturn =>
  menus.map((menu, index, menus) => {
    // Count Sold Out Items
    const outletsSoldOutArray = removeExpiredSoldOut(outletSoldOut)
    const soldCount = sumBy([menu, ...menu.childrenMenus], menu => {
      return (
        countBy(menu.menuItems, item => {
          return outlet
            ? item.outletSoldOut
            : outletsSoldOutArray.includes(item.id)
        }).true || 0
      )
    })

    return {
      id: menu.id,
      key: menu.id,
      hidden: hiddenMenuItemGroupIds.includes(menu.id),
      icon: menu.addOnItemsMenu ? 'shopping-cart' : 'clipboard',
      label: (
        <Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {menu.name}

            {soldCount > 0 ? (
              <Tag intent={'danger'} style={{ marginLeft: '5px' }}>
                {soldCount}
              </Tag>
            ) : null}
            {!outlet && !allowAddOnItems && menu.addOnItemsMenu ? (
              <Popover
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.RIGHT_TOP}
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                content={
                  <Fragment>
                    <p>
                      Add on sale items are disabled. This menu will not be
                      offered to customers at checkout.
                    </p>
                    <p>
                      Enable this feature in{' '}
                      <Link to={`/business/${restaurantId}/details`}>
                        Business Details
                      </Link>
                      .
                    </p>
                  </Fragment>
                }
              >
                <div style={{ marginLeft: '5px' }}>
                  <Icon icon="warning-sign" intent={Intent.DANGER} />
                </div>
              </Popover>
            ) : null}
          </div>
        </Fragment>
      ),
      position: menu.position,
      className: Object.entries({
        selected: menuId === menu.id,
      })
        .filter(([_, value]) => value)
        .map(([key]) => key)
        .join(' '),
      onClickItem: () => {
        if (menuId === menu.id) {
          setValue({
            menuId: undefined,
            subMenuId: undefined,
            menuItemId: undefined,
          })
        } else {
          setValue({
            subMenuId: undefined,
            menuItemId: undefined,
            menuId: menu.id,
            itemMenuOpen: false,
          })
        }
        // togglePane({
        //   itemMenuOpen: false,
        //   itemDetailsOpen: false,
        //   itemQueryId: menu.id,
        //   subMenuId: '',
        //   subSubMenuId: '',
        //   openingTimes: JSON.stringify(openingTimes),
        //   soldOutUntilItems: JSON.stringify(soldOutUntilItems),
        // })
      },
      secondaryLabel: outlet ? (
        <div
          onClick={e => {
            e.stopPropagation()
            setValue({
              menuId: menu.id,
              paneNumber: 1,
            })
          }}
        >
          <Popover
            content={
              <OutletSubMenu
                id={menu.id}
                outletId={outletId}
                hidden={
                  !!hiddenMenuItemGroupIds.find(
                    hiddenMenuId => hiddenMenuId === menu.id
                  )
                }
              ></OutletSubMenu>
            }
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.LEFT}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ) : (
        // stops pane from opening if user just clicks on submenu
        <div
          onClick={e => {
            e.stopPropagation()
            setValue({
              menuId: menu.id,
              itemMenuOpen: false,
            })
          }}
        >
          <Popover
            content={
              <SubMenu
                id={menu.id}
                queryId={queryId}
                parentMenu
                menus={menus}
                index={index}
              />
            }
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.LEFT}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ),
    }
  })

const ParentMenuPane = ({ outlet, queryId, menuId }) => {
  const { setValue } = useMenuState()
  return (
    <Query
      query={outlet ? GET_OUTLET_PARENT_MENUS : GET_PARENT_MENUS}
      variables={{ id: queryId }}
      fetchPolicy="network-only"
    >
      {data => {
        const menuItemsGroups = get(
          data,
          outlet
            ? 'getOutlets.outlets[0].menuItemsGroups'
            : 'getRestaurants.restaurants[0].menuItemsGroups',
          []
        )
        const openingTimes = get(
          data,
          outlet ? 'getOutlets.outlets[0].openingTimes' : [],
          []
        )
        const soldOutUntilItems = get(
          data,
          outlet ? 'getOutlets.outlets[0].soldOutUntilItems' : [],
          []
        )
        const outletSoldOut = get(
          data,
          outlet ? [] : 'getRestaurants.restaurants[0].outlets',
          []
        )
        // const nextOpen = get(
        //   data,
        //   outlet ? 'getOutlets.outlets[0].nextOpen' : '',
        //   []
        // )
        const allowAddOnItems = get(
          data,
          outlet ? '' : 'getRestaurants.restaurants[0].allowAddOnItems',
          false
        )
        const restaurantId = get(
          data,
          outlet ? '' : 'getRestaurants.restaurants[0].id',
          ''
        )
        const hiddenMenuItemGroupIds = get(
          data,
          outlet ? 'getOutlets.outlets[0].hiddenMenuItemGroupIds' : [],
          []
        )

        const outletId = get(
          data,
          outlet
            ? 'getOutlets.outlets[0].id'
            : 'getRestaurants.restaurants[0].outlets[0].id',
          []
        )

        // if (nextOpen) setValue({ nextOpen })

        const menus = formatMenus({
          menus: menuItemsGroups,
          queryId,
          menuId,
          outlet,
          openingTimes,
          soldOutUntilItems,
          outletSoldOut,
          allowAddOnItems,
          restaurantId,
          hiddenMenuItemGroupIds,
          outletId,
          setValue,
        })

        if (menus.length > 0) {
          return (
            <MenusList
              menus={menus}
              openingTimes={openingTimes}
              soldOutUntilItems={soldOutUntilItems}
            />
          )
        } else {
          return <NoParentMenus />
        }
      }}
    </Query>
  )
}

export default ParentMenuPane
