import React from 'react'
import {
  Column,
  CopyCellsMenuItem,
  SelectionModes,
  Table,
  RenderMode,
} from '@blueprintjs/table'
import { Menu } from '@blueprintjs/core'
import { startCase, upperFirst } from 'lodash'
import { penceToPounds } from '@utils/helpers'

export const TableWithTransaction = ({
  response,
  visibleColumns,
  data,
  onShow,
}) => {
  // create a list of Columns, mapping existing Columns to corresponding cell renderers functions
  const columns = visibleColumns.map(column => (
    <Column
      key={column}
      name={startCase(column)} // transform the camel case into title
      cellRenderer={response['render'.concat(upperFirst(column))]} // call the cell's renderer function
    />
  ))

  return (
    <Table
      bodyContextMenuRenderer={context => {
        return (
          <Menu>
            <CopyCellsMenuItem
              context={context}
              getCellData={rowIndex => {
                const row = data[rowIndex]
                onShow(row)
                return false
              }}
              text="Show Record"
            />
            <CopyCellsMenuItem
              context={context}
              getCellData={(rowIndex, columnIndex) => {
                const cell = data[rowIndex][columns[columnIndex].key]
                return typeof cell === 'object'
                  ? cell.name || cell.orderNumber || cell.email
                  : typeof cell === 'number'
                  ? penceToPounds(cell)
                  : cell
              }}
              text="Copy Cell"
            />
          </Menu>
        )
      }}
      numRows={response.responseLength}
      selectionModes={SelectionModes.ROWS_AND_CELLS}
      renderMode={RenderMode.BATCH_ON_UPDATE}
      numFrozenRows={0}
      enableFocusedCell={true}
      enableGhostCells={false}
    >
      {columns}
    </Table>
  )
}
