import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'
import { isAfter, isBefore, isEqual, startOfDay, startOfToday } from 'date-fns'

export const createLoyaltyCardStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Loyalty Card name is required')
    .min(3, 'Loyalty Card name must be longer than 3 characters')
    .max(26, 'Loyalty Card name cannot be longer than 26 characters.'),
  termsAndConditions: yup
    .string()
    .required('Terms and Conditions are required'),
  requiredStamps: yup
    .number()
    .required('You must choose how many stamps on the Loyalty Card')
    .min(3, 'You need at least 3 stamps on a loyalty card')
    .max(10, `Can't have more than 10 stamps`),
  startDate: yup
    .date()
    .typeError('A start date is required.')
    .required('A start date is required.')
    .test({
      name: 'startDate-today-or-later',
      test: startDate =>
        !startDate ||
        isAfter(startOfDay(startDate), startOfToday()) ||
        isEqual(startOfDay(startDate), startOfToday()),
      message: 'The start date cannot be in the past.',
    })
    .when('endDate', ([endDate], schema) => {
      if (endDate) {
        return schema.test({
          name: 'Start-before-end',
          test: (startDate, context) => {
            const endDate = context.parent.endDate
            return (
              !startDate ||
              !endDate ||
              isBefore(startDate, endDate) ||
              isEqual(startDate, endDate)
            )
          },
          message: 'The start date should be before the end date.',
        })
      }
      return schema
    }),
  endDate: yup
    .date()
    .typeError('An end date is required.')
    .required('An end date is required.')
    .test({
      name: 'End-Date-in-future',
      test: endDate => isAfter(new Date(endDate), new Date()),
      message: 'The end date should be in the future.',
    })
    .test({
      name: 'End-after-start',
      test: function (endDate) {
        const startDate = this.parent.startDate
        return !startDate || isBefore(startDate, endDate)
      },
      message: 'The end date should be after the start date.',
    }),
  loyaltyCardIcon: yup.string().required('Loyalty Card Icon is required'),
  discountPercentage: yup
    .number()
    .nullable()
    .min(0, "The value of the stamp can't be less than 0% of the order")
    .max(100, "The value of the stamp can't be greater than 100% of the order")
    .test({
      name: 'not-both-filled',
      exclusive: true,
      message:
        "Can't have a Fixed Loyalty Card reward and a percentage Loyalty Card reward at the same time",
      test: function (value) {
        return !(value && this.parent.discountAmount)
      },
    })
    .test({
      name: 'either-filled',
      exclusive: true,
      message: 'Discount percentage or amount is required',
      test: function (value) {
        return value || this.parent.discountAmount
      },
    }),
  discountAmount: yup
    .number()
    .nullable()
    .min(0, "The discount amount can't be less than 0")
    .test({
      name: 'not-both-filled',
      exclusive: true,
      message:
        "Can't have a Fixed Loyalty Card reward and a percentage Loyalty Card reward at the same time",
      test: function (value) {
        return !(value && this.parent.discountPercentage)
      },
    })
    .test({
      name: 'either-filled',
      exclusive: true,
      message: 'Discount percentage or amount is required',
      test: function (value) {
        return value || this.parent.discountPercentage
      },
    }),
  loyaltyCardColor: yup.string().required('Loyalty card color is required'),
  allowedOutletIds: yup
    .array()
    .when('outletSelected', ([outletSelected], schema) => {
      return outletSelected === true
        ? schema.min(1, 'At least one outlet needs to be selected')
        : schema.nullable()
    }),
  daysOfWeek: yup
    .array()
    .of(yup.number().required())
    .min(1, 'At least one day must be selected')
    .required('Days of the week are required'),
}

export const editLoyaltyCardStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Loyalty Card name is required')
    .min(3, 'Loyalty Card name must be longer than 3 characters')
    .max(26, 'Loyalty Card name cannot be longer than 26 characters.'),
  termsAndConditions: yup
    .string()
    .required('Terms and Conditions are required'),
  requiredStamps: yup
    .number()
    .required('You must choose how many stamps on the Loyalty Card')
    .min(3, 'You need at least 3 stamps on a loyalty card')
    .max(10, `Can't have more than 10 stamps`),
  startDate: yup
    .date()
    .required('A start date is required.')
    .typeError('A start date is required.')
    .when('endDate', ([endDate], schema) => {
      if (endDate) {
        return schema.test({
          name: 'Start-before-end',
          test: (startDate, context) => {
            const endDate = context.parent.endDate
            return (
              !startDate ||
              !endDate ||
              isBefore(startDate, endDate) ||
              isEqual(startDate, endDate)
            )
          },
          message: 'The start date should be before the end date.',
        })
      }
      return schema
    }),
  endDate: yup
    .date()
    .required('An end date is required.')
    .typeError('An end date is required.')
    .test({
      name: 'End-Date-in-future',
      test: endDate => isAfter(new Date(endDate), new Date()),
      message: 'The end date should be in the future.',
    })
    .test({
      name: 'End-after-start',
      test: function (endDate) {
        const startDate = this.parent.startDate
        return !startDate || isBefore(startDate, endDate)
      },
      message: 'The end date should be after the start date.',
    }),
  loyaltyCardIcon: yup.string().required('Loyalty Card Icon is required'),
  discountPercentage: yup
    .number()
    .nullable()
    .min(0, "The value of the stamp can't be less than 0% of the order")
    .max(100, "The value of the stamp can't be greater than 100% of the order")
    .test({
      name: 'not-both-filled',
      exclusive: true,
      message:
        "Can't have a Fixed Loyalty Card reward and a percentage Loyalty Card reward at the same time",
      test: function (value) {
        return !(value && this.parent.discountAmount)
      },
    })
    .test({
      name: 'either-filled',
      exclusive: true,
      message: 'Discount percentage or amount is required',
      test: function (value) {
        return value || this.parent.discountAmount
      },
    }),
  discountAmount: yup
    .number()
    .nullable()
    .min(0, "The discount amount can't be less than 0")
    .test({
      name: 'not-both-filled',
      exclusive: true,
      message:
        "Can't have a Fixed Loyalty Card reward and a percentage Loyalty Card reward at the same time",
      test: function (value) {
        return !(value && this.parent.discountPercentage)
      },
    })
    .test({
      name: 'either-filled',
      exclusive: true,
      message: 'Discount percentage or amount is required',
      test: function (value) {
        return value || this.parent.discountPercentage
      },
    }),
  loyaltyCardColor: yup.string().required('Loyalty card color is required'),
  allowedOutletIds: yup
    .array()
    .when('outletSelected', ([outletSelected], schema) => {
      return outletSelected === true
        ? schema.min(1, 'At least one outlet needs to be selected')
        : schema.nullable()
    }),
  daysOfWeek: yup
    .array()
    .of(yup.number().required())
    .min(1, 'At least one day must be selected')
    .required('Days of the week are required'),
}
