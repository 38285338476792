import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRejectionsHeatmapQueryVariables = Types.Exact<{
  outletId: Types.Scalars['String']['input'];
  weeks?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRejectionsHeatmapQuery = { __typename?: 'Query', getRejectionsReportHeatmap?: Array<{ __typename?: 'RejectionsReportHeatmap', id: string, day: number, hour: number, totalOrders?: number | null, totalValue?: number | null, totalRejectionRate?: number | null, totalRejectedOrders?: number | null, totalRejectedValue?: number | null, manualRejectionRate?: number | null, manualRejectedOrders?: number | null, manualRejectedValue?: number | null, autoRejectionRate?: number | null, autoRejectedOrders?: number | null, autoRejectedValue?: number | null }> | null };


export const GetRejectionsHeatmapDocument = gql`
    query getRejectionsHeatmap($outletId: String!, $weeks: Int) {
  getRejectionsReportHeatmap(outletId: $outletId, weeks: $weeks) {
    id
    day
    hour
    totalOrders
    totalValue
    totalRejectionRate
    totalRejectedOrders
    totalRejectedValue
    manualRejectionRate
    manualRejectedOrders
    manualRejectedValue
    autoRejectionRate
    autoRejectedOrders
    autoRejectedValue
  }
}
    `;

/**
 * __useGetRejectionsHeatmapQuery__
 *
 * To run a query within a React component, call `useGetRejectionsHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRejectionsHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRejectionsHeatmapQuery({
 *   variables: {
 *      outletId: // value for 'outletId'
 *      weeks: // value for 'weeks'
 *   },
 * });
 */
export function useGetRejectionsHeatmapQuery(baseOptions: Apollo.QueryHookOptions<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables> & ({ variables: GetRejectionsHeatmapQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>(GetRejectionsHeatmapDocument, options);
      }
export function useGetRejectionsHeatmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>(GetRejectionsHeatmapDocument, options);
        }
export function useGetRejectionsHeatmapSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>(GetRejectionsHeatmapDocument, options);
        }
export type GetRejectionsHeatmapQueryHookResult = ReturnType<typeof useGetRejectionsHeatmapQuery>;
export type GetRejectionsHeatmapLazyQueryHookResult = ReturnType<typeof useGetRejectionsHeatmapLazyQuery>;
export type GetRejectionsHeatmapSuspenseQueryHookResult = ReturnType<typeof useGetRejectionsHeatmapSuspenseQuery>;
export type GetRejectionsHeatmapQueryResult = Apollo.QueryResult<GetRejectionsHeatmapQuery, GetRejectionsHeatmapQueryVariables>;