import { Formik } from 'formik'
import {
  Card,
  FormGroup,
  Button,
  NonIdealState,
  Slider,
  RangeSlider,
  Checkbox,
  Callout,
  Intent,
  AnchorButton,
} from '@blueprintjs/core'
import first from 'lodash/first'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'
import EDIT_FULFILMENT_TIMES from './mutations/editFulfilmentTimes.mutation'
import GET_FULFILMENT_TIMES from './queries/getFulfilmentTimes.query'
import GET_OUTLET_DELIVERY_WINDOWS from './queries/deliveryWindows.query'
import { Fragment } from 'react'
import FulfilmentTimesSetupValidattionSchema from './fulfulmentTimesValidation'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

const FulfilmentTimes = () => {
  const { outlet: outletId } = useParams()

  const [editFulfilmentTimes] = useMutation(EDIT_FULFILMENT_TIMES, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Successfully edited fulfilment times'),
    refetchQueries: [
      {
        query: GET_OUTLET_DELIVERY_WINDOWS,
        variables: { outletId },
      },
    ],
  })
  return (
    <Card>
      <Query
        query={GET_FULFILMENT_TIMES}
        variables={{ id: outletId }}
        loaderTitle={'Loading Fulfilment Times'}
      >
        {({ getOutlets: { outlets } }) => {
          const outlet = first(outlets)

          const {
            defaultCollectionTime = 0,
            defaultDeliveryTime = 0,
            daysOfferedInAdvanceMin = 0,
            daysOfferedInAdvanceMax = 1,
            daysOfferedInAdvanceMinDelivery = 0,
            daysOfferedInAdvanceMaxDelivery = 1,
            daysOfferedInAdvanceMinCollection = 0,
            daysOfferedInAdvanceMaxCollection = 1,
            autoCompleteAfter,
            noPreordersBeforeOpening,
            allowPreorders,
            marketplace,
            asapAllowed,
            asapDeliveryAllowed,
            asapCollectionAllowed,
            preorderDeliveryAllowed,
            preorderCollectionAllowed,
          } = outlet

          return outlet ? (
            <Formik
              initialValues={{
                defaultCollectionTime,
                defaultDeliveryTime,
                daysOfferedInAdvanceMin,
                daysOfferedInAdvanceMax,
                daysOfferedInAdvanceMinDelivery,
                daysOfferedInAdvanceMaxDelivery,
                daysOfferedInAdvanceMinCollection,
                daysOfferedInAdvanceMaxCollection,
                asapAllowed: Boolean(asapAllowed),
                noPreordersBeforeOpening: Boolean(noPreordersBeforeOpening),
                allowPreorders: Boolean(allowPreorders),
                asapDeliveryAllowed: Boolean(asapDeliveryAllowed),
                asapCollectionAllowed: Boolean(asapCollectionAllowed),
                preorderDeliveryAllowed: Boolean(preorderDeliveryAllowed),
                preorderCollectionAllowed: Boolean(preorderCollectionAllowed),
                autoCompleteAfter:
                  autoCompleteAfter === null ? -10 : autoCompleteAfter, // -10 is used as the equivalent of null for the slider
                allowedAsapFulfilments: [],
                allowedPreorderFulfilments: [],
              }}
              validationSchema={FulfilmentTimesSetupValidattionSchema}
              onSubmit={({ autoCompleteAfter, ...values }) => {
                const variables = {
                  id: outlet.id,
                  ...values,
                }
                if (outlet.isOrderToTableEnabled) {
                  variables.autoCompleteAfter =
                    autoCompleteAfter && autoCompleteAfter < 0
                      ? null
                      : autoCompleteAfter
                }
                return editFulfilmentTimes({
                  variables,
                })
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                handleChange,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h5 className="bp5-heading">Fulfilment Times</h5>
                  <FormGroup
                    helperText={errors.asapAllowed || ''}
                    intent={Intent.DANGER}
                  >
                    <Checkbox
                      id="asapAllowed"
                      name="asapAllowed"
                      checked={values.asapAllowed}
                      onChange={() => {
                        setFieldValue('asapAllowed', !values.asapAllowed)
                        setFieldValue(
                          'asapCollectionAllowed',
                          !values.asapAllowed
                        )
                        setFieldValue(
                          'asapDeliveryAllowed',
                          !values.asapAllowed
                        )
                      }}
                      label="Allow ASAP"
                    />
                  </FormGroup>
                  <FormGroup intent={Intent.DANGER}>
                    <Checkbox
                      id="asapDeliveryAllowed"
                      name="asapDeliveryAllowed"
                      checked={values.asapDeliveryAllowed}
                      onChange={handleChange}
                      label="Allow ASAP Delivery"
                      disabled={!values.asapAllowed}
                    />
                    <Checkbox
                      id="asapCollectionAllowed"
                      name="asapCollectionAllowed"
                      checked={values.asapCollectionAllowed}
                      onChange={handleChange}
                      label="Allow ASAP Collection"
                      disabled={!values.asapAllowed}
                    />
                  </FormGroup>
                  <FormGroup
                    label="Standard Delivery Time"
                    labelInfo="(minutes)"
                    helperText="Delivery times now use windows and are no longer required."
                  >
                    <Slider
                      name="defaultDeliveryTime"
                      max={120}
                      labelStepSize={20}
                      stepSize={5}
                      value={values.defaultDeliveryTime}
                      disabled={true}
                    />
                  </FormGroup>

                  <br />
                  <h5 className="bp5-heading">Preordering</h5>
                  <FormGroup helperText={errors.allowPreorders || ''}>
                    <Checkbox
                      id="allowPreorders"
                      name="allowPreorders"
                      checked={values.allowPreorders}
                      onChange={() => {
                        setFieldValue('allowPreorders', !values.allowPreorders)
                        setFieldValue(
                          'preorderDeliveryAllowed',
                          !values.allowPreorders
                        )
                        setFieldValue(
                          'preorderCollectionAllowed',
                          !values.allowPreorders
                        )
                      }}
                      label="Allow Preorders"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Days Offered (Old Apps Support)"
                    helperText="How many days in advance a customer can preorder"
                    disabled={!values.allowPreorders}
                    intent={Intent.NONE}
                  >
                    <RangeSlider
                      name="daysOfferedInAdvance"
                      min={1}
                      max={marketplace.allowExtendedPreorders ? 31 : 5}
                      labelValues={
                        marketplace.allowExtendedPreorders
                          ? [1, 5, 10, 15, 20, 25, 31]
                          : [1, 2, 3, 4, 5]
                      }
                      stepSize={1}
                      value={[
                        // add one to each value as having today as a 0 index was confusing to users
                        values.daysOfferedInAdvanceMin + 1,
                        values.daysOfferedInAdvanceMax + 1,
                      ]}
                      onChange={([min, max]) => {
                        // remove one so that when we consume the info in the client today is 0 based index
                        setFieldValue('daysOfferedInAdvanceMin', min - 1)
                        setFieldValue('daysOfferedInAdvanceMax', max - 1)
                      }}
                      disabled={!values.allowPreorders}
                    />
                  </FormGroup>

                  <FormGroup intent={Intent.DANGER}>
                    <Checkbox
                      id="preorderDeliveryAllowed"
                      name="preorderDeliveryAllowed"
                      checked={values.preorderDeliveryAllowed}
                      onChange={handleChange}
                      disabled={!values.allowPreorders}
                      label="Allow Delivery Preorders"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Delivery Days Offered"
                    helperText="How many days in advance a customer can preorder delivery"
                    disabled={
                      !values.allowPreorders || !values.preorderDeliveryAllowed
                    }
                  >
                    <RangeSlider
                      name="daysOfferedInAdvance"
                      min={1}
                      max={marketplace.allowExtendedPreorders ? 31 : 5}
                      labelValues={
                        marketplace.allowExtendedPreorders
                          ? [1, 5, 10, 15, 20, 25, 31]
                          : [1, 2, 3, 4, 5]
                      }
                      stepSize={1}
                      value={[
                        // add one to each value as having today as a 0 index was confusing to users
                        values.daysOfferedInAdvanceMinDelivery + 1,
                        values.daysOfferedInAdvanceMaxDelivery + 1,
                      ]}
                      onChange={([min, max]) => {
                        // remove one so that when we consume the info in the client today is 0 based index
                        setFieldValue(
                          'daysOfferedInAdvanceMinDelivery',
                          min - 1
                        )
                        setFieldValue(
                          'daysOfferedInAdvanceMaxDelivery',
                          max - 1
                        )
                      }}
                      disabled={
                        !values.preorderDeliveryAllowed ||
                        !values.allowPreorders
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox
                      id="preorderCollectionAllowed"
                      name="preorderCollectionAllowed"
                      checked={values.preorderCollectionAllowed}
                      onChange={handleChange}
                      disabled={!values.allowPreorders}
                      label="Allow Collection Preorders"
                    />
                    <FormGroup
                      label="Collection Days Offered"
                      helperText="How many days in advance a customer can preorder collection"
                      disabled={
                        !values.preorderCollectionAllowed ||
                        !values.allowPreorders
                      }
                    >
                      <RangeSlider
                        name="daysOfferedInAdvanceCollection"
                        min={1}
                        max={marketplace.allowExtendedPreorders ? 31 : 5}
                        labelValues={
                          marketplace.allowExtendedPreorders
                            ? [1, 5, 10, 15, 20, 25, 31]
                            : [1, 2, 3, 4, 5]
                        }
                        stepSize={1}
                        value={[
                          // add one to each value as having today as a 0 index was confusing to users
                          values.daysOfferedInAdvanceMinCollection + 1,
                          values.daysOfferedInAdvanceMaxCollection + 1,
                        ]}
                        onChange={([min, max]) => {
                          // remove one so that when we consume the info in the client today is 0 based index
                          setFieldValue(
                            'daysOfferedInAdvanceMinCollection',
                            min - 1
                          )
                          setFieldValue(
                            'daysOfferedInAdvanceMaxCollection',
                            max - 1
                          )
                        }}
                        disabled={
                          !values.allowPreorders ||
                          !values.preorderCollectionAllowed
                        }
                      />
                    </FormGroup>
                  </FormGroup>
                  {outlet.isOrderToTableEnabled && (
                    <Fragment>
                      <br />
                      <h5 className="bp5-heading">Table Ordering</h5>
                      <FormGroup
                        label="Autocomplete Table Orders"
                        helperText="After how many minutes should a table order be automatically completed"
                      >
                        <Slider
                          min={-10}
                          max={60}
                          showTrackFill={false}
                          labelStepSize={10}
                          stepSize={10}
                          labelRenderer={val =>
                            val < 0 ? 'Off' : val.toString()
                          }
                          value={values.autoCompleteAfter}
                          onChange={val =>
                            setFieldValue('autoCompleteAfter', val)
                          }
                        />
                      </FormGroup>
                    </Fragment>
                  )}
                  <div className="bp-card-footer-actions">
                    <Button
                      text="Save"
                      type="submit"
                      loading={isSubmitting}
                      disabled={!values.asapAllowed && !values.allowPreorders}
                    />
                    <AnchorButton
                      text="Ordering Guide"
                      href="https://support.redbox.systems/docs/outlet-ordering-settings"
                      target="_blank"
                      rel="noopener noreferrer"
                      rightIcon="help"
                      intent={Intent.PRIMARY}
                      minimal
                    />
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <NonIdealState
              title="No fulfilment times found"
              icon="time"
              className="bp5-surface-card-non-ideal-state"
            />
          )
        }}
      </Query>
    </Card>
  )
}

export default FulfilmentTimes
