import gql from 'graphql-tag'

const GET_OPTIONS = gql`
  query getOptionsForMenu($restaurantId: String) {
    getRestaurants(id: $restaurantId) {
      restaurants {
        id
        isVATregistered
      }
    }
    getOptions(restaurantId: $restaurantId) {
      options {
        id
        name
        position
        minOptions
        maxOptions
        parentMenu {
          id
          name
        }
        optionItems {
          id
          name
          price
          vatRate
          VATinclusive
          position
          soldOut
          imageImplementer {
            id
            src
          }
        }
      }
    }
  }
`
export default GET_OPTIONS
