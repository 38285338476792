import gql from 'graphql-tag'

const ALLOW_SMS_MARKETING = gql`
  mutation allowSMSMarketing($id: String!, $allowSMSMarketing: Boolean!) {
    editMarketplace(id: $id, allowSMSMarketing: $allowSMSMarketing) {
      message
      marketplace {
        id
        allowSMSMarketing
      }
    }
  }
`

export default ALLOW_SMS_MARKETING
