import gql from 'graphql-tag'

const GET_RESTAURANT = gql`
  query restaurantForDashboard($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        image
        stripeId
        updatedAt
        significantDates
        isVATregistered
        contactName
        contactEmail
        contactPhone
        contactAddress {
          id
          firstLine
          city
          postcode
        }
        active
        partner {
          id
          name
          companyLegalName
          contactAddress {
            id
            city
            postcode
          }
        }
        outlets {
          id
          active
          name
          activationDate
          terminals {
            id
            isOnline
          }
          marketplace {
            id
            enableAnalytics
          }
        }
        menuItems {
          id
          outletSoldOut
        }
        discounts {
          id
          endDate
          startDate
          enrolmentKey
          customer {
            id
          }
        }
      }
    }
  }
`
export default GET_RESTAURANT
