import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import EDIT_MENU_ITEM from '../mutations/editMenuItem.mutation'
import { poundsToPence } from '@utils/helpers'
import { useMutation } from '@apollo/client'

const QuickEdit = ({ item }) => {
  const [editMenuItem] = useMutation(EDIT_MENU_ITEM, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Successfully updated menu item')
    },
  })

  return (
    <Formik
      initialValues={{
        id: item.id,
        price: item.price,
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        return (
          <CurrencyInput
            minimal
            name="price"
            id="price"
            defaultValue={values.price / 100}
            onUpdate={amount => {
              if (values.price !== parseInt(poundsToPence(amount)))
                editMenuItem({
                  variables: {
                    id: values.id,
                    price: parseInt(poundsToPence(amount)),
                  },
                })
            }}
          />
        )
      }}
    </Formik>
  )
}

export default QuickEdit
