import { Fragment } from 'react'
import { Formik } from 'formik'
import {
  Card,
  HTMLTable,
  Switch,
  Icon,
  AnchorButton,
  NonIdealState,
  Button,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { string, arrayOf, bool, shape } from 'prop-types'

import EDIT_TABLE_ACTIVATION_STATUS from '@components/Outlet/Tables/mutations/editTableActivationStatus.mutation'
import EditTableFriendlyNameModal from '@components/TableModal/EditTableFriendlyName.modal'
import config from '@src/config'
import colors from '@styles/colors'
import { useModal } from '../../../providers/ModalProvider'
import { useMutation } from '@apollo/client'

const tableHead = [
  { key: 'friendlyName', content: 'Table Name / Number' },
  { key: 'active', content: 'Active' },
  { key: 'qrCode', content: 'Table Service QR Code' },
]

const TableList = ({ tables, outletId }) => {
  const { isOpen, toggleModal } = useModal('editTableFriendlyNameModal')
  const { toggleModal: toggleAddModal } = useModal('addTableModal')

  let tableBeingEdited = {}

  const [editTableActivationStatus] = useMutation(
    EDIT_TABLE_ACTIVATION_STATUS,
    {
      onError: defaultErrorHandler,
      onCompleted: ({ editTableActivation: { active: isActive } }) =>
        successToast(
          `Table ${tableBeingEdited.friendlyName} ${
            isActive ? 'Activated' : 'Deactivated'
          }.`
        ),
    }
  )

  if (tables.length > 0) {
    return (
      <Fragment>
        <Card className={'bp5-nopad'}>
          <HTMLTable bordered={false} interactive={true}>
            <thead>
              <tr>
                {tableHead.map(({ key, content }) => (
                  <th key={key}>{content}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tables.map(table => (
                <tr key={table.id}>
                  <td>
                    <Icon
                      icon="symbol-circle"
                      color={
                        table.active ? colors.onlineGreen : colors.offlineRed
                      }
                    />
                    &nbsp;
                    <a
                      onClick={() => {
                        tableBeingEdited = table
                        toggleModal(true)
                      }}
                    >
                      {table.friendlyName}
                    </a>
                  </td>
                  <td>
                    <Formik initialValues={{ active: table.active }}>
                      {({ values, handleChange }) => (
                        <Switch
                          label={'Active'}
                          checked={values.active}
                          onChange={e => {
                            handleChange(e)
                            editTableActivationStatus({
                              variables: {
                                tableId: table.id,
                                isActive: e.currentTarget.checked,
                              },
                            })
                          }}
                          name="active"
                        />
                      )}
                    </Formik>
                  </td>
                  <td>
                    <AnchorButton
                      href={`${config.apiUrl}/api/${outletId}/table/generate-qr?table=${table.id}&friendly=${table.friendlyName}`}
                      target="_blank"
                      icon="print"
                      minimal
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </Card>

        <EditTableFriendlyNameModal
          modalOpen={isOpen}
          modalClose={() => toggleModal(false)}
          table={tableBeingEdited}
          outletId={outletId}
        />
      </Fragment>
    )
  } else {
    return (
      <Card>
        <div style={{ padding: '100px' }}>
          <NonIdealState
            icon="list"
            title="No Tables"
            description="There are no available tables for this outlet."
            action={
              <Button icon="plus" onClick={() => toggleAddModal(true)}>
                Add Table
              </Button>
            }
          />
        </div>
      </Card>
    )
  }
}

TableList.propTypes = {
  outletId: string.isRequired,
  tables: arrayOf(
    shape({
      id: string.isRequired,
      friendlyName: string.isRequired,
      active: bool.isRequired,
    })
  ),
}

export default TableList
