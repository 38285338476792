import { Icon, Menu, MenuItem, Popover } from '@blueprintjs/core'
import React from 'react'

const SortBy = ({ title, orderBy, selected, setSelected }) => {
  const icon =
    selected && selected.split('_')[0] === orderBy
      ? selected.split('_')[1] === 'ASC'
        ? 'sort-asc'
        : 'sort-desc'
      : 'double-caret-vertical'

  return (
    <Popover
      content={
        <div>
          <Menu>
            <MenuItem
              id={null}
              icon="sort"
              key={'double-caret-vertical'}
              text={'None'}
              onClick={() => setSelected(orderBy, 'NONE')}
            />
            <MenuItem
              id={'ASC'}
              icon="sort-asc"
              key={'sort-asc'}
              text={'Ascending'}
              onClick={() => setSelected(orderBy, 'ASC')}
            />
            <MenuItem
              id={'DESC'}
              icon="sort-desc"
              key={'sort-desc'}
              text={'Descending'}
              onClick={() => setSelected(orderBy, 'DESC')}
            />
          </Menu>
        </div>
      }
    >
      <div>
        {title}
        <Icon icon={icon} style={{ paddingLeft: '5px' }} />
      </div>
    </Popover>
  )
}

export default SortBy
