import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const addLinkCardStructure: IIterableInterface = {
  marketplaceId: yup.string().required(),
  title: yup.string().nullable(),
  description: yup.string().max(400).nullable(),
  image: yup.string().required('Image is required').nullable(),
  url: yup.string().when('type', (val, schema) => {
    if (typeof val === 'string' && val === 'LINK') {
      return schema.required('Link is required').nullable()
    } else {
      return schema.nullable()
    }
  }),
  outletId: yup
    .string()
    .nullable()
    .when('type', ([type], schema) => {
      if (type === 'MENU_ITEM' || type === 'OUTLET') {
        return schema.required('Outlet is required').nullable()
      } else {
        return schema.nullable()
      }
    }),
  menuItemId: yup
    .string()
    .nullable()
    .when('type', ([type], schema) => {
      if (type === 'MENU_ITEM') {
        return yup
          .string()
          .required('Menu item is required')
          .min(3, 'Menu item is required')
          .nullable()
      } else {
        return schema.nullable()
      }
    }),
  buttonText: yup.string().nullable(),
  type: yup.string().required('Type is required').nullable(),
}

export const editLinkCardStructure: IIterableInterface = {
  id: yup.string().required('id is required'),
  title: yup.string().nullable(),
  description: yup.string().max(400).nullable(),
  cardImage: yup.string().required('Image is required').nullable(),
  url: yup.string().when('type', ([type], schema) => {
    if (type === 'LINK') {
      return schema.required('Link is required')
    } else {
      return schema.nullable()
    }
  }),
  outletId: yup.string().when('type', ([type], schema) => {
    if (type === 'MENU_ITEM' || type === 'OUTLET') {
      return schema.required('Outlet is required').min(3, 'Outlet is required')
    } else {
      return schema.nullable()
    }
  }),
  menuItemId: yup.string().when('type', ([type], schema) => {
    if (type === 'MENU_ITEM') {
      return schema
        .required('Menu item is required')
        .min(3, 'Menu item is required')
        .nullable()
    } else {
      return schema.nullable()
    }
  }),
  buttonText: yup.string().nullable(),
  type: yup.string().required('Type is required'),
}
