import React, { Fragment, useState } from 'react'
import Query from '@components/Query/Query'
import {
  Card,
  HTMLTable,
  Button,
  Divider,
  NonIdealState,
  ControlGroup,
  ButtonGroup,
  Icon,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { matchType } from '@utils/types'
import GET_RECONCILIATION from './queries/getReconciliation.query'
import Currency from '@components/Currency/Currency'
import { startCase, union, without } from 'lodash'
import FilterRow from '@components/FilterRow/FilterRow'
import { PartnerFilter } from '@components/Toolbar'
import { DATE_FILTER_TYPES } from '../../../utils/datetime'
import { shiftToRedboxPreMigration } from '../../../utils/dateTimeRangeShifters'
import { useDateRangeQueryParams } from '../../Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '../../Toolbar/DateRangeFilter/DateRangeFilter'
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params'
import OutletLink from '@components/OpenStatus/OutletLink'
import DateTime from '@components/DateTime/DateTime'
import Enum from '@components/Enum/Enum'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import { format } from 'date-fns'
import toast from '@utils/toast'

const TransactionReconciliation = () => {
  const [partnerFilter = [], onChangePartnerFilter] = useQueryParam(
    'partnerFilter',
    ArrayParam
  )
  const [, setQueryParams] = useQueryParams({
    viewOrder: StringParam,
  })
  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.WEEK.key,
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.MONTH,
      DATE_FILTER_TYPES.CUSTOM,
    ],
    shifter: shiftToRedboxPreMigration,
  }
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams(dateRangeFilterArgs)

  const [downloading, setDownloading] = useState(false)

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    onChangePartnerFilter(
      checked ? union(partnerFilter, [id]) : without(partnerFilter, id)
    )
  }

  const resetFilters = () => {
    onChangePartnerFilter([])
  }

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'orderId',
      'orderNumber',
      'transactionStripeId',
      'outlet',
      'business',
      'marketplace',
      'refundedAt',
      'acceptedAt',
      'type',
      'totalToOutlet',
      'totalPaidToOutlet',
      'totalToMarketplace',
      'totalPaidToMarketplace',
      'totalToPartner',
      'totalPaidToPartner',
      'totalToPaybox',
      'totalPaidToPaybox',
      'totalToRedbox',
      'totalPaidToRedbox',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_RECONCILIATION,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
        },
      })
      .then(data => {
        data.data.reconcile.map(report => {
          csvData.push(
            csvHeader
              .map(column => {
                return {
                  orderId: `"${report.orderId}"`,
                  orderNumber: `"${report.orderNumber}"`,
                  transactionStripeId: `"${report.transactionStripeId}"`,
                  outlet: `"${(report.outlet && report.outlet.name) || '-'}"`,
                  business: `"${
                    (report.restaurant && report.restaurant.name) || '-'
                  }"`,
                  marketplace: `"${
                    (report.marketplace && report.marketplace.name) || '-'
                  }"`,
                  refundedAt: `"${report.refundedAt}"`,
                  acceptedAt: `"${report.acceptedAt}"`,
                  type: `"${report.type}"`,
                  totalToOutlet: penceToPounds(report.totalToOutlet),
                  totalPaidToOutlet: penceToPounds(report.totalPaidToOutlet),
                  totalToMarketplace: penceToPounds(report.totalToMarketplace),
                  totalPaidToMarketplace: penceToPounds(
                    report.totalPaidToMarketplace
                  ),
                  totalToPartner: penceToPounds(report.totalToPartner),
                  totalPaidToPartner: penceToPounds(report.totalPaidToPartner),
                  totalToPaybox: penceToPounds(report.totalToPaybox),
                  totalPaidToPaybox: penceToPounds(report.totalPaidToPaybox),
                  totalToRedbox: penceToPounds(report.totalToRedbox),
                  totalPaidToRedbox: penceToPounds(report.totalPaidToRedbox),
                }[column]
              })
              .join(',')
          )
        })
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `reconcile-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download Complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <div>
          <ButtonGroup>
            <ControlGroup>
              <PartnerFilter
                partnerFilter={partnerFilter}
                onChange={handlePartnerFilter}
              />
              <Button
                icon="filter-remove"
                onClick={resetFilters}
                disabled={partnerFilter.length === 0}
              />
            </ControlGroup>
          </ButtonGroup>
          <Divider />
          <DateRangeFilter {...dateRangeFilterArgs} />
        </div>
        <Button
          loading={downloading}
          icon={'cloud-download'}
          style={{
            marginLeft: 'auto',
          }}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </FilterRow>

      <Query
        query={GET_RECONCILIATION}
        variables={{
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
        }}
        loaderTitle="Loading Reconciliation Report"
      >
        {({ reconcile: reconciledTransactions }) => {
          if (reconciledTransactions.length > 0) {
            return (
              <div className="bp5-table-container bp5-scrollable">
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th colSpan={5}></th>
                        <th
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          colSpan={3}
                        >
                          Transfers
                        </th>
                        <th
                          colSpan={2}
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                        >
                          Fees
                        </th>
                      </tr>
                      <tr>
                        <th>Order</th>
                        <th>Outlet</th>
                        <th>Marketplace</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          Outlet
                        </th>
                        <th style={{ width: '150px' }}>Marketplace</th>
                        <th style={{ width: '150px' }}>Partner</th>
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          Paybox
                        </th>
                        <th style={{ width: '150px' }}>Redbox</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reconciledTransactions.map(row => {
                        const outlet = {
                          ...row.outlet,
                          restaurant: row.restaurant,
                        }

                        return (
                          <tr key={row.id}>
                            <td>
                              <a
                                onClick={() => {
                                  setQueryParams({
                                    viewOrder: row.orderId,
                                  })
                                }}
                              >
                                #{row.orderNumber || row.Id}
                              </a>
                            </td>
                            <td>
                              <OutletLink outlet={outlet} />
                            </td>
                            <td>
                              <Link to={`/marketplaces/${row.marketplace.id}`}>
                                {row.marketplace.name}
                              </Link>
                            </td>
                            <td>
                              {row.refundedAt ? (
                                <DateTime
                                  dateTime={row.refundedAt}
                                  description={'Refund'}
                                />
                              ) : (
                                <DateTime
                                  dateTime={row.acceptedAt}
                                  description={'Accepted'}
                                />
                              )}
                            </td>
                            <td>
                              <Enum
                                tagName={row.type}
                                minimal={true}
                                rightIcon={
                                  row.refundedAt && (
                                    <Icon icon="undo" color="#30404d" />
                                  )
                                }
                              />
                            </td>
                            <td
                              style={{
                                borderLeft: ' 1px solid #DCDCDD',
                              }}
                            >
                              <Currency amount={row.totalToOutlet} />
                              {row.totalPaidToOutlet != row.totalToOutlet && (
                                <Fragment>
                                  &nbsp;(
                                  <Currency
                                    amount={row.totalPaidToOutlet}
                                    style={{ color: 'red' }}
                                  />
                                  )
                                </Fragment>
                              )}
                            </td>
                            <td>
                              <Currency amount={row.totalToMarketplace} />
                              {row.totalPaidToMarketplace !=
                                row.totalToMarketplace && (
                                <Fragment>
                                  &nbsp;(
                                  <Currency
                                    amount={row.totalPaidToMarketplace}
                                    style={{ color: 'red' }}
                                  />
                                  )
                                </Fragment>
                              )}
                            </td>
                            <td>
                              <Currency amount={row.totalToPartner} />
                              {row.totalPaidToPartner != row.totalToPartner && (
                                <Fragment>
                                  &nbsp;(
                                  <Currency
                                    amount={row.totalPaidToPartner}
                                    style={{ color: 'red' }}
                                  />
                                  )
                                </Fragment>
                              )}
                            </td>
                            <td
                              style={{
                                borderLeft: '1px solid #DCDCDD',
                              }}
                            >
                              <Currency amount={row.totalToPaybox} />
                              {row.totalPaidToPaybox != row.totalToPaybox && (
                                <Fragment>
                                  &nbsp;(
                                  <Currency
                                    amount={row.totalPaidToPaybox}
                                    style={{ color: 'red' }}
                                  />
                                  )
                                </Fragment>
                              )}
                            </td>
                            <td>
                              <Currency amount={row.totalToRedbox} />
                              {row.totalPaidToRedbox != row.totalToRedbox && (
                                <Fragment>
                                  &nbsp;(
                                  <Currency
                                    amount={row.totalPaidToRedbox}
                                    style={{ color: 'red' }}
                                  />
                                  )
                                </Fragment>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          } else {
            return (
              <NonIdealState
                icon="tick-circle"
                title="Successfully Reconciled"
                description={`All transactions in this period have reconciled successfully.`}
                action={
                  partnerFilter ? (
                    <Button
                      onClick={() => resetFilters()}
                      minimal
                      intent="primary"
                    >
                      Reset Filters
                    </Button>
                  ) : null
                }
              />
            )
          }
        }}
      </Query>
    </div>
  )
}

TransactionReconciliation.propTypes = {
  match: matchType,
}

export default TransactionReconciliation
