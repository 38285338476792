import { Icon } from '@blueprintjs/core'

interface Props {
  value?: number | null
  priorValue?: number | null
  biggerIsWorse?: boolean
  asPercentage?: boolean
  numberStyle: 'numeric' | 'currency' | 'percentage'
  hide?: boolean
}

export const Change: React.FC<Props> = ({
  value: valueMaybeNull,
  priorValue,
  biggerIsWorse = false,
  asPercentage = false,
  numberStyle,
  hide = false,
}) => {
  const value = valueMaybeNull ?? 0
  if (hide || priorValue === undefined || priorValue === null) return null
  let shouldShowChange = priorValue !== undefined && priorValue !== value
  let change

  if (shouldShowChange) {
    if (asPercentage) {
      if (priorValue === 0) {
        shouldShowChange = false
      } else {
        change = Math.round((100 * (value - priorValue)) / priorValue)
      }
    } else {
      change = Math.round(value - priorValue)
    }
  }
  if (!shouldShowChange || change === 0) return null
  return (
    <div
      style={{
        color: biggerIsWorse
          ? change > 0
            ? 'red'
            : 'green'
          : change > 0
          ? 'green'
          : 'red',
      }}
    >
      <Icon icon={change > 0 ? 'arrow-up' : 'arrow-down'} />

      {asPercentage
        ? +Math.abs(change) + '%'
        : { numeric: change, percentage: Math.round(Math.abs(change)) + '%' }[
            numberStyle
          ]}
    </div>
  )
}
