import Dialog from '@components/Dialog/Dialog'
import React from 'react'
import { useContext } from 'react'
import { MembersDiscountContext } from './MembersDiscountProvider'
import EnrollToDiscountForm from './EnrollToDiscountForm'

const InviteCustomerDiscountModal = ({ restaurantId, refetchVars }) => {
  const {
    modalInviteMemberToDiscountOpen,
    setModalInviteMemberToDiscountOpen,
  } = useContext(MembersDiscountContext)
  return (
    <Dialog
      isOpen={modalInviteMemberToDiscountOpen}
      onClose={() => setModalInviteMemberToDiscountOpen(false)}
      title="Invite Member and Create Discount"
    >
      <EnrollToDiscountForm
        restaurantId={restaurantId}
        refetchVars={refetchVars}
      />
    </Dialog>
  )
}

export default InviteCustomerDiscountModal
