import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const address: IIterableInterface = {
  firstLine: yup
    .string()
    .required("First line can't be empty")
    .matches(/\w+/, 'The first must only contain alphanumeric characters'),
  secondLine: yup.string().nullable(),
  thirdLine: yup.string().nullable(),
  city: yup
    .string()
    .required('City name is required')
    .min(2, 'City name must have at least two characters'),
  postcode: yup.string().required('The postcode is required'),
  default: yup.boolean(),
}

export const optionalAddress: IIterableInterface = {
  firstLine: yup
    .string()
    .notRequired()
    .matches(/\w{2,}/, {
      message: 'The first must only contain alphanumeric characters',
      excludeEmptyString: true,
    }),
  secondLine: yup.string().nullable(),
  thirdLine: yup.string().nullable(),
  city: yup.string().matches(/\w{2,}/, {
    message: 'City name must have at least two characters',
    excludeEmptyString: true,
  }),
  postcode: yup.string(),
  default: yup.boolean(),
  country: yup.object().shape({
    code: yup
      .string()
      .matches(/\w+/)
      .min(2, 'Country code needs to be 2 characters')
      .max(2, 'Country code needs to be 2 characters'),
    name: yup.string().matches(/\w+/).min(4, 'The country name is too short'),
  }),
}
