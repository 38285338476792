import React from 'react'
import { HTMLSelect, Icon, Intent } from '@blueprintjs/core'
import {
  func,
  number,
  arrayOf,
  shape,
  oneOfType,
  string,
  object,
} from 'prop-types'
import get from 'lodash/get'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import TimeInput from '@components/TimeInput/TimeInput'

const DAYS = [
  { label: 'Day', value: null },
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 7 },
]

const AvailabilityTimeSelect = ({
  onChange,
  index,
  availabilityTimes,
  errors,
  remove,
  fieldName,
  setFieldValue,
}) => (
  <tr>
    <td>
      <HTMLSelect
        options={DAYS}
        value={availabilityTimes[index].start.day}
        onChange={onChange}
        name={`${fieldName}[${index}].start.day`}
      />
    </td>
    <td>
      <TimeInput
        value={availabilityTimes[index].start.time}
        handleChange={value => {
          setFieldValue(`${fieldName}[${index}].start.time`, value)
        }}
        intent={
          get(errors.openingTimes, `[${index}].start.time`)
            ? Intent.DANGER
            : Intent.NONE
        }
      />
    </td>
    <td>
      <HTMLSelect
        options={DAYS}
        value={availabilityTimes[index].end.day}
        onChange={onChange}
        name={`${fieldName}[${index}].end.day`}
      />
    </td>
    <td>
      <TimeInput
        value={availabilityTimes[index].end.time}
        handleChange={value => {
          setFieldValue(`${fieldName}[${index}].end.time`, value)
        }}
        intent={
          get(errors.openingTimes, `[${index}].end.time`)
            ? Intent.DANGER
            : Intent.NONE
        }
      />
    </td>
    <td className="bp5-action-cell">
      {availabilityTimes.length > 1 && (
        <ConfirmationPopover
          remove={() => remove(index)}
          confirmationText="Are you sure you want to delete this opening time?"
        >
          <Icon icon="trash" />
        </ConfirmationPopover>
      )}
    </td>
  </tr>
)

AvailabilityTimeSelect.propTypes = {
  onChange: func,
  index: number,
  availabilityTimes: arrayOf(
    shape({
      start: shape({
        day: oneOfType[(string, number)],
        time: oneOfType[(string, number)],
      }),
      end: shape({
        day: oneOfType[(string, number)],
        time: oneOfType[(string, number)],
      }),
    })
  ),
  errors: object,
  remove: func,
  fieldName: string,
  setFieldValue: func,
}

export default AvailabilityTimeSelect
