import React from 'react'
import { FormGroup, Intent } from '@blueprintjs/core'
import NumericInput from '../../NumericInput/NumericInput'

const RadiusInput = ({ values, setFieldValue, errors }) => (
  <FormGroup
    label="Delivery Radius (miles)"
    labelFor="radiusMiles"
    helperText={errors.radiusMiles}
    intent={errors.radiusMiles ? Intent.DANGER : Intent.NONE}
  >
    <NumericInput
      name="radiusMiles"
      id="radiusMiles"
      fill
      min={0}
      step={1}
      precision={1}
      defaultValue={values.radiusMiles}
      onUpdate={valueAsString => {
        setFieldValue('radiusMiles', valueAsString)
      }}
    />
  </FormGroup>
)

export default RadiusInput
