import styled from 'styled-components'
import { array, bool } from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Tabs, Tab, Tag, Intent } from '@blueprintjs/core'
import find from 'lodash/find'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import { useNavigate } from 'react-router-dom'

export type TabType = {
  to: string
  name: string
  count: number
  intent: Intent
  hide: boolean
}

const Container = styled.div`
  top: 48px;
  height: 48px;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.tabBarBG};
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 20;

  .scrollbar {
    display: flex;
    width: 100%;
  }

  // can't style Tabs directly with styled(Tabs)
  // so cheat and use the class
  .bp5-tabs {
    display: flex;
    align-items: center;
    background: ${({ theme: { colors } }) => colors.tabBarBG};
    min-width: max-content;
    flex-grow: 1;
    padding: 0 60px;
    margin-top: 10px;

    & .bp5-tab-indicator {
      bottom: -8px;
      background-color: ${({ theme: { colors } }) =>
        `${colors.primaryNavActiveColor} !important`};
    }

    .bp5-tab {
      color: ${({ theme: { colors } }) => `${colors.tabBarTabText} !important`};
      padding: 0 5px;

      &:hover {
        color: ${({ theme: { colors } }) => colors.tabBarTabHoverText};
      }

      &[aria-selected='true'] {
        color: ${({ theme: { colors } }) =>
          `${colors.tabBarTabActiveText} !important`};
      }
    }
  }
`

const getId = (tabs: Array<TabType>) => {
  const selectedTab = find(tabs, tab =>
    window.location.href.split(/[?#]/)[0].endsWith(tab.to)
  )
  return selectedTab && selectedTab.to
}

const TabBar = ({
  tabs,
  persistQueryParamsBetweenTabs,
}: {
  tabs: Array<TabType>
  persistQueryParamsBetweenTabs: boolean
}) => {
  const navigate = useNavigate()

  return (
    <Container>
      <PerfectScrollBar
        options={{
          className: 'scrollBar',
          options: { suppressScrollY: true },
        }}
      >
        <Tabs
          onChange={(route: string) => {
            const routeMaybeWithParams = persistQueryParamsBetweenTabs
              ? route + location.search
              : route
            navigate(routeMaybeWithParams.toString())
          }}
          selectedTabId={getId(tabs)}
        >
          {map(
            filter(tabs, tab => !tab.hide),
            ({ to, name, count, intent }) => (
              <Tab key={to} id={to}>
                {name}
                {count > 0 && (
                  <Tag style={{ marginLeft: 8 }} intent={intent || null}>
                    {count}
                  </Tag>
                )}
              </Tab>
            )
          )}
        </Tabs>
      </PerfectScrollBar>
    </Container>
  )
}

TabBar.propTypes = {
  tabs: array,
  persistQueryParamsBetweenTabs: bool,
}

export default TabBar
