class Ls {
  set = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
    return true
  }

  get = id => {
    const data = localStorage.getItem(id)
    try {
      return JSON.parse(data) || ''
    } catch {
      return data
    }
  }

  remove = id => {
    localStorage.removeItem(id)
  }
}

const ls = new Ls()

export default ls
