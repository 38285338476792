import gql from 'graphql-tag'

const EDIT_SEGMENT = gql`
  mutation editSegment(
    $id: String!
    $name: String!
    $marketplaceId: ID!
    $filterGroupsType: String!
    $filterGroups: [FilterGroupInput!]!
  ) {
    editSegment(
      id: $id
      name: $name
      marketplaceId: $marketplaceId
      filterGroupsType: $filterGroupsType
      filterGroups: $filterGroups
    ) {
      segment {
        id
        name
        updatedAt
        estimate
        filterGroupsType
        filterGroups {
          filterGroupType
          filters {
            attribute
            comparator
            value
          }
        }
      }
    }
  }
`

export default EDIT_SEGMENT
