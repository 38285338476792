import gql from 'graphql-tag'

const ENABLE_ANALYTICS = gql`
  mutation enableAnalytics($id: String!, $enableAnalytics: Boolean!) {
    editMarketplace(id: $id, enableAnalytics: $enableAnalytics) {
      message
      marketplace {
        id
        enableAnalytics
      }
    }
  }
`

export default ENABLE_ANALYTICS
