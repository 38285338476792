import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
// import { addAppValidation } from './validation'
import AppImportForm from './AppImportForm'
import ADD_APP from './mutations/appApp.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_MARKETPLACE_APPS_BY_PARTNER_ID from './queries/getMarketplaceAppsByPartnerId.query'
import { appValidation } from './validation'
import { useMutation } from '@apollo/client'

const AppExistingImport = ({ modalOpen, modalClose, location }) => {
  const [addApp] = useMutation(ADD_APP, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Existing app was imported')
      modalClose()
    },
    refetchQueries: [
      {
        query: GET_MARKETPLACE_APPS_BY_PARTNER_ID,
        variables: {
          partnerId: location.split('/')[2],
        },
      },
    ],
  })

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Import App">
      <Formik
        validationSchema={appValidation}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{}}
        onSubmit={async (values, actions) => {
          await addApp({
            variables: {
              ...values,
            },
          })
          actions.setSubmitting(false)
        }}
      >
        {props => <AppImportForm location={location} {...props} />}
      </Formik>
    </Dialog>
  )
}

AppExistingImport.propTypes = {
  ...modalType,
  appId: string,
  device: string,
  version: string,
}

export default AppExistingImport
