import * as yup from 'yup'
import { discountShape } from '@components/Discount/validation/validation'

const MAX_VOUCHERS = 2000 // take ~10s to create them
export const createDiscountVouchersValidationSchema = yup.object().shape(
  {
    ...discountShape,
    key: yup.string().notRequired().nullable(),
    howMany: yup
      .number()
      .required('You must choose how many vouchers you need to create')
      .test(
        'howMany-custom-code',
        'Only one voucher can be created with a custom code',
        (value, context) => {
          const { key } = context.parent
          return key ? value === 1 : true
        }
      )
      .test(
        'howMany-range',
        `At least one voucher needs to be created and no more than ${MAX_VOUCHERS} distinct vouchers can be created`,
        (value, context) => {
          const { key } = context.parent
          return key || (value >= 1 && value <= MAX_VOUCHERS)
        }
      ),
    usageLimit: yup
      .number()
      .required()
      .test(
        'usageLimit-distinct-vouchers',
        'When creating distinct vouchers, each can only be used once',
        (value, context) => {
          const { howMany } = context.parent
          return howMany > 1 ? value === 1 : true
        }
      ),
    onePerCustomer: yup.bool().required(),
    active: yup.bool().required(),
  },
  [
    ['discountAmount', 'discountPercentage'],
    ['startDate', 'endDate'],
    ['howMany', 'key'],
    ['usageLimit', 'howMany'],
  ]
)
