import gql from 'graphql-tag'

const UPLOAD_IMAGES = gql`
  mutation uploadImages($images: [String!]) {
    uploadImages(images: $images) {
      images
    }
  }
`

export default UPLOAD_IMAGES
