import { Intent, Position, Toaster } from '@blueprintjs/core'
import config from '@src/config'
import { setContext } from '@apollo/client/link/context'
import gql from 'graphql-tag'
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import ls from '@utils/localStorage'
import { onError } from '@apollo/client/link/error'
import { logout } from '../stores/userStore'

const cache = new InMemoryCache()

const unsubscribe = async () => {
  await client.clearStore()
}

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    const url = `${config.apiUrl}/gql`
    if (config.isLocalDev) {
      return `${url}?op=${operationName}`
    }
    return url
  },
  fetch,
})

const maxOneTopPositionToaster = Toaster.create({
  position: Position.TOP,
  maxToasts: 1,
})
const toastMaxOneToTopPosition = bread =>
  maxOneTopPositionToaster.show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    timeout: 5000,
    ...bread,
  })
const unlimitedTopPositionToaster = Toaster.create({
  position: Position.TOP,
})
const toastUnlimitedToTopPosition = bread =>
  unlimitedTopPositionToaster.show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    timeout: 5000,
    ...bread,
  })
const errorLink = onError(({ networkError, graphQLErrors = [] }) => {
  console.log({ networkError, graphQLErrors }, 'errors')
  if (networkError) {
    if (!networkError.statusCode) {
      toastMaxOneToTopPosition({
        message: 'Please verify your internet connection and try again.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    } else if (networkError.statusCode === 401) {
      logout()
      toastMaxOneToTopPosition({
        message: 'An authentication error occurred. Please log in again.',
      })
    } else if (
      networkError.statusCode >= 500 &&
      networkError.statusCode < 600
    ) {
      toastMaxOneToTopPosition({
        message: 'Service temporarily unavailable, please try again shortly.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    } else {
      toastMaxOneToTopPosition({
        message: 'An unexpected error occurred.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    }
  }
  if (graphQLErrors.length) {
    for (const graphQLError of graphQLErrors) {
      const message = graphQLError.message || 'An unexpected error occurred.'
      console.error(new Error(message), { graphQLError })
      if (graphQLError.code === 'SERVER_ERROR') {
        toastMaxOneToTopPosition({
          message,
        })
      } else {
        toastUnlimitedToTopPosition({
          message,
        })
      }
    }
  }
})

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation)
})

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}

const authLink = setContext((_, { headers }) => {
  const token = ls.get('jwt')
  const partialToken = ls.get('partialJwt')
  return {
    headers: {
      ...headers,
      Authorization:
        token || partialToken ? `Bearer ${token || partialToken}` : '',
    },
  }
})

const link = ApolloLink.from([
  omitTypenameLink,
  authLink,
  // stateLink,
  errorLink,
  httpLink,
])

const client = new ApolloClient({
  link,
  cache,
  name: 'management-web',
})
client.disableNetworkFetches = false
// the above enables fetchPolicy="network-only"
// this is undocumented in apollo and took some finding linking to the issue for future reference
// https://github.com/apollographql/react-apollo/issues/3084#issuecomment-501856583

export { client, gql, unsubscribe }
