import React, { useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { canPerformAction, isPlatformLevelUser } from '@stores/userStore'
import Users from './Users'
import usersPageContext from './usersPageContext'
import UsersPageWrapper from './UsersPageWrapper'

const UsersPage = ({ product }) => {
  const { setModalOpen, setUser } = useContext(usersPageContext)
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: '/admin/users', name: 'Users' },
        {
          to: '/admin/permissions',
          name: 'Permissions',
          hide: !isPlatformLevelUser(),
        },
      ],
      permissions: canPerformAction('inviteUser'),
      button: {
        text: 'Invite User',
        onClick: () => setModalOpen(true),
      },
    })
  }, [configurePageLayout, product, setModalOpen, setUser])

  return <Users />
}

const UserPageContextWrapper = props => (
  <UsersPageWrapper entityName="user">
    <UsersPage {...props} />
  </UsersPageWrapper>
)

export default UserPageContextWrapper
