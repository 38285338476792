import { Classes, Button, Intent } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import omit from 'lodash/omit'

import { discountValidation } from '../validation/validation'
import CREATE_DISCOUNT from '../mutations/createDiscount.mutation'
import GET_STANDARD_RESTAURANT_DISCOUNTS from '../queries/getStandardRestaurantDiscounts.query'
import { successToast } from '@utils/toast'
import { numberToPence } from '@utils/helpers'
import CreateDiscountInnerFormStruct from './CreateDiscountInnerFormStruct'
import { useMutation } from '@apollo/client'

const AddDiscountModal = ({
  allowOutletSelection,
  modalOpen,
  modalClose,
  restaurantId,
}) => {
  const [createDiscount] = useMutation(CREATE_DISCOUNT, {
    onError: defaultErrorHandler,
    onCompleted: ({ createDiscount }) => {
      successToast(createDiscount.message)
      modalClose()
    },
    refetchQueries: [
      {
        query: GET_STANDARD_RESTAURANT_DISCOUNTS,
        variables: { id: restaurantId },
      },
    ],
  })

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Create Discount">
      <div className={Classes.DIALOG_BODY}>
        <Formik
          validationSchema={discountValidation}
          validateOnChange={true}
          onSubmit={async values => {
            await createDiscount({
              variables: {
                ...omit(values, 'discountAmount', 'minimumSubtotalGross'),
                discountAmount: numberToPence(values.discountAmount),
                minimumSubtotalGross: numberToPence(
                  values.minimumSubtotalGross
                ),
                allowedOutlets: values.allowedOutletIds,
                restaurantId,
                startDate:
                  values.startDate && new Date(values.startDate).toISOString(),
                endDate:
                  values.endDate && new Date(values.endDate).toISOString(),
              },
            })
          }}
          initialValues={{
            name: '',
            discountPercentage: 0,
            discountAmount: 0,
            startDate: null,
            endDate: null,
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            minimumSubtotalGross: 0,
            allowedOutletIds: [],
          }}
        >
          {({ errors, handleChange, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <CreateDiscountInnerFormStruct
                errors={errors}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                allowOutletSelection={allowOutletSelection}
                restaurantId={restaurantId}
                marketplaceId={undefined}
              />
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button text="Save" intent={Intent.PRIMARY} type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

AddDiscountModal.propTypes = {
  ...modalType,
}

export default AddDiscountModal
