// note vite replaces these statically at build time - ie s/import.meta.env.VITE_ENVIRONMENT_NAME/DEV/g
export default {
  apiUrl: import.meta.env.VITE_API_URL || 'http://localhost:3002',
  pinpointAuthUrl:
    import.meta.env.VITE_PINPOINT_AUTH_URL ||
    'https://pinpoint.staging.redbox.systems/auth',
  marketingAccounts: import.meta.env.VITE_MARKETING_ACCOUNTS,
  environmentName: import.meta.env.VITE_ENVIRONMENT_NAME,
  environmentDomain:
    import.meta.env.VITE_ENVIRONMENT_DOMAIN || 'test.redbox.systems',
  awsPinpointAccountId: import.meta.env.VITE_AWS_PINPOINT_ACCOUNT_ID,
  awsAccountId: import.meta.env.VITE_AWS_ACCOUNT_ID || '855720269092',
  environmentDomainUrlshortener:
    import.meta.env.VITE_ENVIRONMENT_DOMAIN_URLSHORTENER || 'test.msgs.top',
  midnightReportingMigrationDate:
    import.meta.env.VITE_MIDNIGHT_REPORTING_MIGRATION_DATE &&
    new Date(import.meta.env.VITE_MIDNIGHT_REPORTING_MIGRATION_DATE),
  isProd: import.meta.env.VITE_ENVIRONMENT_NAME === 'PROD',
  isLocalDev: import.meta.env.DEV,
  awsCDNUri: import.meta.env.VITE_AWS_CDN_URL,
  mapboxKey: import.meta.env.VITE_MAPBOX_KEY,
  awsDefaultRegion: import.meta.env.VITE_AWS_DEFAULT_REGION || 'eu-west-1',
}
