import * as yup from 'yup'
import { phoneRegExp } from './phone-regex'
import { IIterableInterface } from './generic-validator'
import { paymentMethodSchema } from './payment-method'

export const addMarketplaceStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Marketplace name is required.')
    .min(3, 'Marketplace name too short.'),
  contactName: yup
    .string()
    .required('Contact Name is required.')
    .min(3, 'Contact name too short.'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required.')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid phone number.'),
  contactEmail: yup
    .string()
    .required('Contact Email is required')
    .email('This is not a valid email.'),
  partnerId: yup
    .string()
    .required('Partner is required.')
    .min(25, 'Partner too short.')
    .max(25, 'Partner too long.'),
  defaultRejectText: yup
    .string()
    .required('Please provide a default rejection message.')
    .min(
      10,
      'A default rejection message should be longer then 10 characters.'
    ),
  defaultCancelText: yup
    .string()
    .required('Please provide a default cancellation message.')
    .min(
      10,
      'A default cancellation message should be longer then 10 characters.'
    ),
  ageRestrictionText: yup.string().nullable(),
  supportPhone: yup.string().nullable(),
  cname: yup
    .string()
    .nullable()
    .matches(
      import.meta.env.REQUIRE_REDBOX_DOT_SYSTEMS_CNAME === 'TRUE'
        ? /.+\.redbox\.systems$/gi
        : new RegExp(''),
      {
        message:
          'cname for non-production environments should be under redbox.systems domain',
        excludeEmptyString: true,
      }
    ),
  orderMode: yup
    .string()
    .required('Ordering Mode is required.')
    .oneOf(['SINGLE', 'LIST', 'POSTCODE']),
  merchantType: yup
    .string()
    .required('Business Type is required.')
    .oneOf(['RESTAURANT', 'RETAIL']),
  urlPath: yup.string().required('URL Path is required.'),
}

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const editMarketplaceStructure: IIterableInterface = {
  logoImage: yup.string().nullable(),
  heroImage: yup.string().nullable(),
  appImage: yup.string().nullable(),
  name: yup.string().min(3, 'Marketplace name too short.'),
  contactName: yup.string().min(3, 'Contact name too short.'),
  contactPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid phone number'),
  contactEmail: yup.string().email('This is not a valid email.'),
  supportEmail: yup.string().email('This is not a valid email.').nullable(),
  platformFee: yup.number().nullable(),
  platformCharge: yup.number().nullable(),
  partnerFee: yup.number(),
  partnerCharge: yup.number(),
  partnerChargeDescription: yup.string().nullable(),
  partnerCommissionFee: yup.number().nullable(),
  partnerChargeMerchantVat: yup.boolean(),
  merchantComparisonDescription: yup.string().nullable(),
  merchantComparisonPercentage: yup.number().nullable(),
  invoiceEnabled: yup.boolean(),
  invoiceTextFooter: yup.string().nullable(),
  invoiceEmailCustomText: yup.string().nullable(),
  invoiceBcc: yup.string().nullable(),
  invoiceEmail: yup.string().nullable(),
  // geoFence: yup.array().of(yup.array().of(yup.number())),// TODO - update geofence validation
  stripeId: yup.string().min(5, 'Stripe id too short.'),
  stripePublicId: yup.string().min(5, 'Stripe public id too short.'),
  stripeAccessId: yup.string().min(5, 'Stripe access id too short.'),
  stripeRefreshToken: yup.string().min(5, 'Stripe refresh token too short.'),
  stripeLivemode: yup.boolean(),
  orderHoldInterval: yup.number().positive(),
  deliveryZones: yup.array().of(yup.string()),
  cuisines: yup.array().of(yup.string()),
  partnerId: yup
    .string()
    .min(25, 'Partner id too short.')
    .max(25, 'Partner id too long.'),
  deliveryMinimumOrderValue: yup.number().positive(),
  collectionMinimumOrderValue: yup.number().positive(),
  cname: yup
    .string()
    .nullable()
    .matches(
      import.meta.env.REQUIRE_REDBOX_DOT_SYSTEMS_CNAME === 'TRUE'
        ? /.+\.redbox\.systems$/gi
        : new RegExp(''),
      {
        message:
          'Cname for non-production environments should be under redbox.systems domain.',
        excludeEmptyString: true,
      }
    ),
  storeURLApple: yup
    .string()
    .url('App Store link must be a valid url.')
    .nullable(),
  storeURLGooglePlay: yup
    .string()
    .url('Google Store link must be a valid url.')
    .nullable(),
  socialURLFacebook: yup
    .string()
    .url('Facebook link must be a valid url.')
    .nullable(),
  socialURLTwitter: yup
    .string()
    .url('Twitter link must be a valid url.')
    .nullable(),
  socialURLInstagram: yup
    .string()
    .url('Instagram link must be a valid url.')
    .nullable(),
  socialURLTikTok: yup
    .string()
    .url('TikTok link must be a valid url.')
    .nullable(),
  brandImage: yup.boolean(),
  brandColor: yup
    .string()
    .nullable()
    .matches(
      hexColorRegex,
      'Brand primary color must be a HEX value (3 or 6 characters).'
    ),
  brandSecondaryColor: yup
    .string()
    .nullable()
    .matches(
      hexColorRegex,
      'Brand secondary color must be a HEX value (3 or 6 characters).'
    ),
  brandTertiaryColor: yup
    .string()
    .nullable()
    .matches(
      hexColorRegex,
      'Brand tertiary color must be a HEX value (3 or 6 characters).'
    ),
  active: yup.boolean(),
  defaultRejectText: yup
    .string()
    .min(
      10,
      'A default rejection message should be longer then 10 characters.'
    ),
  ageRestrictionText: yup.string().nullable(),
  checkoutText: yup
    .string()
    .nullable()
    .max(80, 'Checkout information should not be longer then 80 characters.'),
  orderNoteText: yup
    .string()
    .nullable()
    .max(80, 'Order note information should not be longer then 80 characters.'),
  deliveryNoteText: yup
    .string()
    .nullable()
    .max(
      80,
      'Delivery note information should not be longer then 80 characters.'
    ),
  specialInstructionsText: yup
    .string()
    .nullable()
    .max(
      80,
      'Special instructions information should not be longer then 80 characters.'
    ),
  collectionNoteText: yup
    .string()
    .nullable()
    .max(
      80,
      'Collection note information should not be longer then 80 characters.'
    ),
  tableNoteText: yup
    .string()
    .nullable()
    .max(80, 'Table note information should not be longer then 80 characters.'),
  cashText: yup
    .string()
    .max(60, 'Description should not be longer then 60 characters.'),
  cashCollectionText: yup
    .string()
    .max(60, 'Description should not be longer then 60 characters.'),
  defaultCancelText: yup
    .string()
    .min(
      10,
      'A default cancellation message should be longer then 10 characters.'
    ),
  title: yup
    .string()
    .nullable()
    .min(4, 'Page title should be longer then 4 characters.')
    .max(70, 'Page title should not be longer then 70 characters.'),
  metaDescription: yup
    .string()
    .nullable()
    .min(10, 'Meta descriptions should be longer then 10 characters.')
    .max(160, 'Meta descriptions are limited to 160 characters.'),
  metaKeywords: yup
    .string()
    .nullable()
    .min(10, 'Meta keywords should be longer then 10 characters.')
    .max(160, 'Meta keywords are limited to 160 characters.'),
  paymentMethods: paymentMethodSchema,
  allowReviews: yup.boolean().nullable(),
  isVatRegistered: yup.boolean(),
  vatNumber: yup
    .string()
    .matches(
      /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i,
      'Not a valid VATnumber'
    )
    .nullable(),
  allowOrderToTable: yup.boolean(),
  allowMarketing: yup.boolean(),
  allowSingleItemOrderNotes: yup.boolean(),
  allowLocationSearch: yup.boolean(),
  allowSMSMarketing: yup.boolean(),
  allowBilling: yup.boolean(),
  allowExtendedPreorders: yup.boolean(),
  androidVersion: yup.string(),
  iosVersion: yup.string(),
  headerLinks: yup.array().of(
    yup.object().shape({
      title: yup.string().required('Please add a header link title'),
      link: yup
        .string()
        .matches(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
          'Please enter a valid URL'
        ),
    })
  ),
  footerLinks: yup.array().of(
    yup.object().shape({
      title: yup.string().required('Please add a footer link title'),
      link: yup
        .string()
        .matches(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
          'Please enter a valid URL'
        ),
    })
  ),
  allowedCategories: yup.array().of(yup.string()).nullable(),
  collectionRadiusKM: yup.number().nullable(),
  defaultFulfilmentOption: yup
    .string()
    .oneOf(['ALL', 'COLLECTION', 'DELIVERY', 'TABLE']),
  defaultDatepickerOption: yup.string().oneOf(['ANYTIME', 'ASAP', 'PREORDER']),
  defaultLocationOption: yup
    .string()
    .oneOf(['EVERYWHERE', 'POSTCODE', 'NEARME']),
  enableRedirectFromLandingPage: yup.boolean().nullable(),
  // validate only numbers and no spaces
  facebookPixelId: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Meta Pixel ID must be a number without spaces',
      excludeEmptyString: true,
    })
    .nullable(),
  trackingId: yup
    .string()
    .matches(/^UA-\d+-\d+$/, {
      message: 'Invalid UA Tracking ID format. Expected format: UA-XXXX-Y.',
      excludeEmptyString: true,
    })
    .nullable(),
  ga4Id: yup
    .string()
    .matches(/^G-[A-Z0-9]+$/, {
      message: 'Invalid GA4 Tracking ID format. Expected format: G-XXXXXXXXXX.',
      excludeEmptyString: true,
    })
    .nullable(),
  outletOpenStatus: yup
    .string()
    .oneOf(['OPEN_PREORDER_CLOSED', 'ORDER_PREORDER_VIEW']),
}

export const editMarketplaceMessageSettingsStructure: IIterableInterface = {
  id: yup.string(),
  emailSenderUsername: yup.string().nullable(),
  pinpointNotificationStrategy: yup.string().nullable(),
  pinpointAwsAccountId: yup.string().nullable(),
  sesMessaging: yup.string().nullable(),
  smsSenderId: yup
    .string()
    .matches(
      /^(?!.*-$)[a-zA-Z0-9 -]{1,11}$/,
      'Sender ID must be up to 11 alphanumeric characters.'
    )
    .nullable(),
}
