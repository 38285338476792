import { Fragment, useContext, useEffect } from 'react'
import { split, first } from 'lodash'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import {
  canPerformAction,
  canPerformMutation,
  getRole,
  isPlatformLevelUser,
  isAtLeastPartner,
  isAtLeastMarketplaceOwner,
  isAtLeastMarketplaceAdmin,
} from '@stores/userStore'
import Dashboard from '@components/Marketplace/Dashboard/Dashboard'
import Details from '@components/Marketplace/Details/Details'
import Financials from '@components/Marketplace/Financials/Financials'
import Billing from '@components/Marketplace/Billing/Billing'
import DeliveryZones from '@components/Marketplace/DeliveryZones/DeliveryZones'
import DeliveryNetworks from '@components/Marketplace/DeliveryNetworks/DeliveryNetworks'
import MarketplaceMap from '@components/Marketplace/MarketplaceMap/MapMarketplace'
import MessageTemplates from '@components/Marketplace/MessageTemplates/MessageTemplates'
import EmailTheme from '@components/Marketplace/EmailTheme/EmailTheme'
import Website from '@components/Marketplace/Website/Website'
import Segments from '@components/Marketplace/Segments/Segments'
import Legal from '@components/Marketplace/Legal/Legal'
import Support from '@components/Marketplace/Support/Support'
import Users from '@components/Users/Users'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'
import blacklistedAddressesPageContext from '@components/BlacklistedAddresses/BlacklistedAddressesPageContext'
import BlacklistedAddresses from '../BlacklistedAddresses/BlacklistedAddresses'
import BlacklistedAddressesPageWrapper from '../BlacklistedAddresses/BlacklistedAddressesPageWrapper'
import DisplayApp from '../Apps/DisplayApp'
import AppPageWrapper from '@components/Apps/AppPageWrapper'
import AppPageContext from '../Apps/appPageContext'
import MarketplaceVouchers from './MarketplaceVouchers/MarketplaceVouchers'
import DNS from './DNS/DNS'
import config from '@src/config'
import POS from './POS/POS'
import { WebsiteTheme } from './WebsiteTheme/WebsiteTheme'
import Payments from './Payments/Payments'
import LinkCards from '@components/Marketplace/LinkCards/LinkCards'
import {
  OVERLAYS,
  OuterLayoutOverlayContext,
  OuterLayoutOverlayContextProvider,
} from './OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import LoyaltyCards from '../Loyalty/LoyaltyCards'
import GET_ENABLE_LOYALTY_ON_MARKETPLACE from './getEnableLoyalty.query'
import Query from '../Query/Query'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import productDictionary from '@src/utils/productDictionary'
import RoleProtectedRoute from '../RoleProtectedRoute/RoleProtectedRoute'

const IS_PROD_OR_STAGING = ['PROD', 'STAGING'].includes(config.environmentName)

const getButton = ({
  pathname,
  openOverlay,
  setAppModalOpen,
  setInviteUserModalOpen,
  setBlacklistedAddressModalOpen,
  appId,
}) => {
  switch (split(pathname, '/')[3]) {
    case 'delivery-zones':
      return {
        text: 'Add Delivery Zone',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_DELIVERY_ZONE)
        },
      }
    case 'delivery-networks':
      return {
        text: 'Add Delivery Network',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_DELIVERY_NETWORK)
        },
      }
    case 'segments':
      return {
        text: 'Create Segment',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_SEGMENT)
        },
      }
    case 'link-cards':
      return {
        text: 'Add Link Card',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_LINK_CARD)
        },
      }
    case 'support':
      return {
        text: 'Add FAQ',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_FAQ)
        },
      }
    case 'apps': {
      return appId && canPerformAction('appBuilds')
        ? {
            text: 'Start Build',
            onClick: () => {
              setAppModalOpen(true)
            },
          }
        : null
    }
    case 'users':
      return canPerformAction('inviteUser')
        ? {
            text: 'Invite user',
            onClick: () => setInviteUserModalOpen(true),
          }
        : null
    case 'blacklisted-addresses':
      return canPerformAction('blacklistAddress')
        ? {
            text: 'Blacklist Address',
            onClick: () => setBlacklistedAddressModalOpen(true),
          }
        : null
    case 'discount-codes':
      return canPerformAction('editDiscount')
        ? {
            text: 'Create Discount Code',
            onClick: () => {
              openOverlay(OVERLAYS.CREATE_VOUCHER)
            },
          }
        : null
    case 'loyalty-cards':
      return canPerformAction('editDiscount')
        ? {
            text: 'Create Loyalty Card',
            onClick: () => {
              openOverlay(OVERLAYS.CREATE_LOYALTY_CARD)
            },
          }
        : null
    case 'pos':
      return canPerformMutation('addPOSDevice')
        ? {
            text: 'Add POS Device',
            onClick: () => {
              openOverlay(OVERLAYS.ADD_POS_DEVICE)
            },
          }
        : null
    default:
      return null
  }
}

const Marketplace = ({ hideLoyalty }) => {
  const { setModalOpen: setInviteUserModalOpen } = useContext(usersPageContext)
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  const { setBlacklistedAddressModalOpen } = useContext(
    blacklistedAddressesPageContext
  )

  const { appId, setAppModalOpen } = useContext(AppPageContext)

  const userRole = getRole()

  const { configurePageLayout } = useContext(PageLayoutContext)
  const location = useLocation()
  const product = productDictionary.MARKETPLACE
  const { marketplace } = useParams()

  useEffect(() => {
    const button = getButton({
      pathname: location.pathname,
      openOverlay,
      setAppModalOpen,
      setInviteUserModalOpen,
      setBlacklistedAddressModalOpen,
      appId,
    })

    configurePageLayout({
      product,
      tabs: [
        {
          to: `/marketplaces/${marketplace}/overview`,
          name: 'Overview',
          hide: ['MARKETPLACE_USER'].includes(userRole),
        },
        {
          to: `/marketplaces/${marketplace}/details`,
          name: 'Details',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/payments`,
          name: 'Payments',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/financials`,
          name: 'Financials',
          hide: !isAtLeastPartner(),
        },
        {
          to: `/marketplaces/${marketplace}/billing`,
          name: 'Billing',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/delivery-networks`,
          name: 'Delivery Networks',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/delivery-zones`,
          name: 'Delivery Zones',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/map`,
          name: 'Map',
          hide: !isAtLeastPartner(),
        },
        {
          to: `/marketplaces/${marketplace}/discount-codes`,
          name: 'Discount Codes',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/loyalty-cards`,
          name: 'Loyalty',
          hide: hideLoyalty,
        },
        {
          to: `/marketplaces/${marketplace}/message-templates`,
          name: 'Messaging',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/email-theme`,
          name: 'Email Theme',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/website`,
          name: 'Website',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/branding`,
          name: 'Branding',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/segments`,
          name: 'Segments',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/link-cards`,
          name: 'Link Cards',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/support`,
          name: 'Support',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/legal`,
          name: 'Legal',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/apps`,
          name: 'Apps',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/users`,
          name: 'Users',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `/marketplaces/${marketplace}/blacklisted-addresses`,
          name: 'Blacklist',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `/marketplaces/${marketplace}/pos`,
          name: 'POS',
          // TODO: Remove the platform level user assertion once ready for prod. RED-4726
          hide: IS_PROD_OR_STAGING && !isPlatformLevelUser(),
        },
        {
          to: `/marketplaces/${marketplace}/DNS`,

          name: 'DNS',
          hide: !isAtLeastPartner(),
        },
      ],
      button,
    })
  }, [
    configurePageLayout,
    location.pathname,
    product,
    setInviteUserModalOpen,
    setAppModalOpen,
    openOverlay,
    setBlacklistedAddressModalOpen,
    appId,
    userRole,
  ])

  return (
    <Fragment>
      <section>
        <Routes>
          <Route path="/" element={<Navigate to="overview" />} />

          <Route path={`overview`} element={<Dashboard />} />

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route path={`details`} element={<Details />} />
          </Route>
          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route path={`payments`} element={<Payments />} />
          </Route>
          <Route
            element={<RoleProtectedRoute hasPermission={isAtLeastPartner()} />}
          >
            <Route path={`financials`} element={<Financials />} />
          </Route>
          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route path={`billing`} element={<Billing />} />
          </Route>
          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`delivery-networks`} element={<DeliveryNetworks />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route path={`delivery-zones`} element={<DeliveryZones />} />
          </Route>

          <Route
            element={<RoleProtectedRoute hasPermission={isAtLeastPartner()} />}
          >
            <Route path={`map`} element={<MarketplaceMap />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`message-templates`} element={<MessageTemplates />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`email-theme`} element={<EmailTheme />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`website`} element={<Website />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`branding`} element={<WebsiteTheme />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            {/* ROSS test this and if it works do them all */}
            <Route path={`link-cards`} element={<LinkCards />} />
            <Route path={`segments`} element={<Segments />} />
            <Route path={`legal`} element={<Legal />} />
            <Route path={`support`} element={<Support />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`users`} element={<Users />} />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route
              path={`blacklisted-addresses`}
              element={<BlacklistedAddresses />}
            />
          </Route>

          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceAdmin()} />
            }
          >
            <Route path={`apps`} element={<DisplayApp />} />
          </Route>
          <Route
            element={
              <RoleProtectedRoute hasPermission={isAtLeastMarketplaceOwner()} />
            }
          >
            <Route path={`discount-codes`} element={<MarketplaceVouchers />} />
          </Route>

          <Route path={`loyalty-cards`} element={<LoyaltyCards />} />

          <Route
            element={<RoleProtectedRoute hasPermission={isAtLeastPartner()} />}
          >
            <Route path={`DNS`} element={<DNS />} />
          </Route>
          <Route
            element={
              <RoleProtectedRoute hasPermission={isPlatformLevelUser()} />
            }
          >
            <Route path={`pos`} element={<POS />} />
          </Route>
        </Routes>
      </section>
    </Fragment>
  )
}

const MarketplacePageWrapper = props => {
  const { marketplace: id } = useParams()

  return (
    <BlacklistedAddressesPageWrapper>
      <UsersPageWrapper entityName="marketplace">
        <AppPageWrapper entityName="marketplace" location={location.pathname}>
          <OuterLayoutOverlayContextProvider>
            <Query
              query={GET_ENABLE_LOYALTY_ON_MARKETPLACE}
              variables={{ id }}
              loaderTitle={'Loading Marketplace'}
            >
              {({ getMarketplaces: { regions } }) => {
                const marketplace = first(regions)
                if (!marketplace) {
                  return 'Unable to find marketplace'
                }
                return (
                  <Marketplace
                    {...props}
                    hideLoyalty={!marketplace.enableLoyalty}
                  />
                )
              }}
            </Query>
          </OuterLayoutOverlayContextProvider>
        </AppPageWrapper>
      </UsersPageWrapper>
    </BlacklistedAddressesPageWrapper>
  )
}

export default MarketplacePageWrapper
