import { useQuery } from '@apollo/client'
import { HTMLSelect } from '@blueprintjs/core'
import GET_DELIVERY_NETWORKS from '@src/components/Marketplace/DeliveryNetworks/queries/getDeliveryNetworks.query'
import { tableProps } from '@src/components/MegaTable/Columns/column.types'
import { getLastNMonths } from '@src/utils/getLastNMonths'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { MegaTable } from '../../../components/MegaTable/MegaTable'
import { useGetDeliveryNetworkFailureReportByMarketplaceQuery } from './queries/deliveryNetworkFailuresByMarketplace.query.generated'

export const DeliveryNetworkFailuresByMarketplace: React.FC = () => {
  const [deliveryNetworkProvider, setDeliveryNetworkProvider] = useQueryParam(
    'deliveryNetworkProvider',
    StringParam
  )
  const { marketplaceId } = useParams()

  const { data: deliveryNetworkData } = useQuery(GET_DELIVERY_NETWORKS, {
    variables: { marketplaceId },
  })

  const { loading, error, data } =
    useGetDeliveryNetworkFailureReportByMarketplaceQuery({
      variables: {
        marketplaceId: String(marketplaceId),
        deliveryNetworkProvider: String(deliveryNetworkProvider),
      },
      skip: !marketplaceId || !deliveryNetworkProvider,
    })

  const months = getLastNMonths(12)

  const displayData: {
    totalOrders: number
    totalOrderValue: number
    totalDeliveryNetworkFailureOrders: number | null
    totalDeliveryNetworkFailureRate: number | null
    totalDeliveryNetworkFailureValue: number | null
    year: number
    month: number
  }[] = []
  if (data?.getDeliveryNetworkFailureReportByMarketplace) {
    for (const { month, year } of months) {
      // do we have data for this month/year?
      const existingData =
        data.getDeliveryNetworkFailureReportByMarketplace.find(
          ({ month: existingMonth, year: existingYear }) =>
            month === existingMonth && year === existingYear
        )
      if (existingData) {
        displayData.push(existingData)
      } else {
        displayData.push({
          year,
          month,
          totalOrders: 0,
          totalOrderValue: 0,
          totalDeliveryNetworkFailureOrders: null,
          totalDeliveryNetworkFailureRate: null,
          totalDeliveryNetworkFailureValue: null,
        })
      }
    }
  }

  return (
    <div>
      <HTMLSelect
        value={deliveryNetworkProvider ?? ''}
        onChange={event => {
          setDeliveryNetworkProvider(event.currentTarget.value)
        }}
      >
        <option disabled value="">
          Please Choose
        </option>
        {(deliveryNetworkData
          ? deliveryNetworkData.getDeliveryNetworks.deliveryNetworks
          : []
        ).map(({ deliveryProvider, name }) => (
          <option value={deliveryProvider}>{name}</option>
        ))}
      </HTMLSelect>

      <MegaTable
        {...tableProps({
          isError: !!error,
          isLoading: loading,
          sorting: 'CLIENT',
          columns: [
            {
              type: 'month',
              header: 'Month',
              isSortable: true,
              name: 'month',
            },
            {
              type: 'numeric',
              isSortable: true,
              header: 'Orders',
              name: 'orderCount',
            },
            {
              type: 'currency',
              isSortable: true,
              header: 'Total Order Value',
              name: 'totalOrderValue',
            },
            {
              type: 'numeric',
              isSortable: true,
              header: 'Total Failures',
              name: 'totalDeliveryNetworkFailure',
            },
            {
              type: 'percentage',
              isSortable: true,
              header: 'Failure Rate',
              name: 'totalDeliveryNetworkFailureRate',
            },
            {
              type: 'currency',
              isSortable: true,
              header: 'Failure Cost',
              name: 'totalDeliveryNetworkFailureValue',
            },
          ] as const,
          data: displayData.map(
            ({
              month,
              year,
              totalOrders,
              totalOrderValue,
              totalDeliveryNetworkFailureOrders,
              totalDeliveryNetworkFailureRate,
              totalDeliveryNetworkFailureValue,
            }) => [
              { month, year },
              { value: totalOrders },
              { amount: totalOrderValue },
              { value: totalDeliveryNetworkFailureOrders },
              { value: totalDeliveryNetworkFailureRate },
              { amount: totalDeliveryNetworkFailureValue },
            ]
          ),
        })}
      />
    </div>
  )
}
