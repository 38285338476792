import gql from 'graphql-tag'

const ADD_POS_DEVICE = gql`
  mutation addPOSDevice(
    $marketplaceId: String!
    $outletIds: [String!]
    $kioskData: KioskCreationInput
  ) {
    addPOSDevice(
      marketplaceId: $marketplaceId
      outletIds: $outletIds
      kioskData: $kioskData
    ) {
      id
      friendlyName
      serialNumber
    }
  }
`

export default ADD_POS_DEVICE
