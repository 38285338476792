import { Colors, ControlGroup } from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import MarketplaceSelect from '@src/components/MarketplaceSelect/MarketplaceSelect'
import DateRangeFilter from '@src/components/Toolbar/DateRangeFilter/DateRangeFilter'
import { DATE_FILTER_TYPES } from '@src/utils/datetime'
import { StringParam, useQueryParam } from 'use-query-params'
import { useGetRefundsReportQuery } from './queries/refundsReport.query.generated'
import { useDateRangeQueryParams } from '@src/components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import { shiftToRedboxPreMigration } from '@src/utils/dateTimeRangeShifters'
import { MegaTable } from '@src/components/MegaTable/MegaTable'
import { tableProps } from '@src/components/MegaTable/Columns/column.types'

export const RefundsReport = () => {
  const [marketplaceId, setMarketplaceId] = useQueryParam(
    'marketplaceId',
    StringParam
  )

  const {
    shiftedEndOfRangeDateTime: dateTo,
    shiftedStartOfRangeDateTime: dateFrom,
  } = useDateRangeQueryParams({ shifter: shiftToRedboxPreMigration })

  const { data, loading, error } = useGetRefundsReportQuery({
    variables: { marketplaceId: String(marketplaceId), dateTo, dateFrom },
    skip: !marketplaceId || !dateTo || !dateFrom,
  })

  return (
    <div>
      <FilterRow>
        <ControlGroup>
          <MarketplaceSelect
            marketplaceId={marketplaceId}
            onChange={setMarketplaceId}
          />
          <DateRangeFilter filterTypes={[DATE_FILTER_TYPES.MONTH]} />
        </ControlGroup>
      </FilterRow>
      {data?.getRefundReport && (
        <MegaTable
          {...tableProps({
            isLoading: loading,
            isError: !!error,
            sorting: 'CLIENT',
            columns: [
              { type: 'order', name: 'order', header: 'Order' },
              {
                type: 'customer',
                isSortable: true,
                name: 'customer',
                header: 'Customer',
              },
              {
                type: 'outlet',
                name: 'outlet',
                header: 'Outlet',
                isSortable: true,
              },
              {
                type: 'datetime',
                name: 'acceptedAt',
                header: 'Accepted',
                isSortable: true,
              },
              {
                type: 'datetime',
                name: 'refundedAt',
                header: 'Refunded',
                isSortable: true,
              },
              {
                type: 'paymentMethod',
                name: 'paymentMethod',
                header: 'Method',
              },
              {
                type: 'fulfillment',
                name: 'fulfillment',
                header: 'Fulfillment',
              },
              {
                type: 'currency',
                name: 'refundAmount',
                header: 'Amount',
                isSortable: true,
              },
              {
                type: 'currency',
                name: 'totalRefundsForOutlet',
                header: 'Total Outlet Refunded',
                isSortable: true,
              },
            ],
            data: data.getRefundReport.map(
              ({
                outlet,
                refundedAt,
                order,
                refundAmount,
                totalRefundsForOutlet,
                refundedInDifferentBillingPeriod,
                acceptedAt,
              }) => {
                return [
                  { id: order.id, orderNumber: order.orderNumber },
                  {
                    id: order.customer.id,
                    firstName: order.customer.firstName,
                    lastName: order.customer.lastName,
                  },
                  { outlet },
                  {
                    date: new Date(acceptedAt),
                  },
                  {
                    date: new Date(refundedAt),
                    style: {
                      color: refundedInDifferentBillingPeriod
                        ? Colors.RED3
                        : undefined,
                    },
                  },
                  {
                    paymentMethod: order.paymentMethod,
                  },
                  {
                    method: order.fulfillmentMethod,
                    provider: order.deliveryNetworkProvider,
                  },
                  { amount: refundAmount },
                  { amount: totalRefundsForOutlet },
                ]
              }
            ),
          } as const)}
        />
      )}
    </div>
  )
}
