import gql from 'graphql-tag'

const GET_MESSAGES = gql`
  query getMessages($messageType: MessageType, $marketplaceId: ID) {
    getMessages(messageType: $messageType, marketplaceId: $marketplaceId) {
      messages {
        id
        date
        from
        to
        subject
        text
        textAsHtml
        html
        to
        readAt
        marketplace {
          id
          name
        }
      }
    }
  }
`

export default GET_MESSAGES
