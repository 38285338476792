import gql from 'graphql-tag'

const GET_OUTLETS = gql`
  query getOutletsByMarketplace(
    $search: String
    $skip: Int
    $first: Int
    $last: Int
    $marketplaceIds: [ID]
    $restaurantIds: [ID]
    $orderBy: OutletOrderByInput
    $isActive: Boolean
    $isSubsidised: Boolean
    $isDirectPayment: Boolean
  ) {
    getOutlets(
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
      marketplaceIds: $marketplaceIds
      restaurantIds: $restaurantIds
      nameContains: $search
      contactNameContains: $search
      contactEmailContains: $search
      restaurantNameContains: $search
      marketplaceNameContains: $search
      isActive: $isActive
      isSubsidised: $isSubsidised
      isDirectPayment: $isDirectPayment
    ) {
      count
      totalCount
      outlets {
        id
        name
        isOpen
        isOnline
        isOnlineOverride
        active
        openingTimes
        defaultDeliveryTime
        defaultCollectionTime
        isVATregistered
        contactName
        contactPhone
        contactEmail
        stripeId
        contactAddress {
          id
          firstLine
          secondLine
          city
          postcode
        }
        outletAddress {
          id
          city
          postcode
        }
        restaurant {
          id
          name
          active
        }
        terminals {
          id
        }
        deliveryZoneCosts {
          id
        }
        marketplace {
          id
          name
          cname
          stripeDirectPayment
          stripeOnboarding
        }
        stripeConnect {
          balance
          balanceCurrency
          detailsSubmitted
          chargesEnabled
          payoutsEnabled
        }
      }
    }
  }
`

export default GET_OUTLETS
