import gql from 'graphql-tag'

const EDIT_OPTION = gql`
  mutation editOption(
    $id: String!
    $name: String
    $maxOptions: Int
    $minOptions: Int
    $optionItemIds: [String]
  ) {
    editOption(
      id: $id
      name: $name
      maxOptions: $maxOptions
      minOptions: $minOptions
      optionItemIds: $optionItemIds
    ) {
      message
      option {
        id
        name
        optionItems {
          id
          name
          price
          VATinclusive
        }
        maxOptions
        minOptions
      }
    }
  }
`

export default EDIT_OPTION
