import SortBy from '@components/Toolbar/SortBy'
import React from 'react'

const cells = [
  {
    key: 'name',
    content: 'Outlet',
    sortable: true,
  },
  {
    key: 'openingTimes',
    content: 'Ordering',
  },
  {
    key: 'balance',
    content: 'Paybox',
    width: 80,
  },
  {
    key: 'businessName',
    content: 'Business',
    width: 80,
  },
  {
    key: 'marketplace',
    content: 'Marketplace',
  },
  {
    key: 'contactName',
    content: 'Contact',
  },
  {
    key: 'delivery',
    content: 'Delivery',
    width: 80,
  },
  {
    key: 'collectionTime',
    content: 'Collection',
    width: 80,
  },
  {
    key: 'terminals',
    content: 'Terminals',
    width: 80,
  },
  {
    key: 'deliveryZones',
    content: 'Zones',
    width: 80,
  },
]

const TableHeader = ({ setSelected, selected }) => (
  <thead>
    <tr>
      {cells.map(({ key, width, sortable, content }) => (
        <th key={key} width={width}>
          {sortable ? (
            <SortBy
              title={content}
              orderBy={key}
              setSelected={setSelected}
              selected={selected}
            />
          ) : (
            content
          )}
        </th>
      ))}
    </tr>
  </thead>
)

export default TableHeader
