import gql from 'graphql-tag'

const INVITE_USER = gql`
  mutation inviteUser(
    $email: String!
    $roleKey: String!
    $ownsPlatformIds: [String]
    $ownsPartnerIds: [String]
    $ownsRestaurantIds: [String]
    $ownsOutletIds: [String]
    $ownsMarketplaceIds: [String]
  ) {
    inviteUser(
      email: $email
      roleKey: $roleKey
      ownsPlatformIds: $ownsPlatformIds
      ownsPartnerIds: $ownsPartnerIds
      ownsRestaurantIds: $ownsRestaurantIds
      ownsOutletIds: $ownsOutletIds
      ownsMarketplaceIds: $ownsMarketplaceIds
    ) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        role {
          id
          title
        }
        ownsPlatforms {
          id
        }
        ownsPartners {
          id
        }
        ownsRestaurants {
          id
        }
        ownsOutlets {
          id
        }
        ownsMarketplaces {
          id
        }
      }
    }
  }
`

export default INVITE_USER
