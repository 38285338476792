import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { GET_ITEM_OPTIONS } from '@components/Restaurant/Menu/queries/getItemDetails.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { without } from 'lodash'
import EDIT_MENU_ITEM_OPTIONS from '../mutations/editMenuItemOptions.mutation'
import { IconContainer, IconStyled } from './ItemDetails.styles'
import { useMutation } from '@apollo/client'

const OptionItemSecondaryLabel = ({
  menuItemId,
  optionIds,
  optionId,
  openEditOptionModal,
}) => {
  const [editMenuItemOptions] = useMutation(EDIT_MENU_ITEM_OPTIONS, {
    onCompleted: () => {
      successToast('Successfully removed item option')
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ],
    variables: {
      id: menuItemId,
      optionIds: without(optionIds, optionId),
    },
  })

  return (
    <IconContainer>
      <IconStyled
        icon="edit"
        onClick={() => {
          openEditOptionModal()
        }}
      />

      <ConfirmationPopover
        remove={() => editMenuItemOptions()}
        confirmationText="Are you sure you want to delete this option?"
      >
        <IconStyled icon="trash" />
      </ConfirmationPopover>
    </IconContainer>
  )
}
export default OptionItemSecondaryLabel
