import gql from 'graphql-tag'

const REPLY = gql`
  mutation replyToReview(
    $id: String!
    $reply: String!
  ) {
    replyToReview(
      id: $id
      reply: $reply
    ) {
      message
    }
  }
`

export default REPLY
