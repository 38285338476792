import { Fragment, useContext } from 'react'
import { first, get } from 'lodash'
import {
  FormGroup,
  TextArea,
  Drawer,
  Button,
  InputGroup,
  DrawerSize,
} from '@blueprintjs/core'

import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Formik } from 'formik'
import Query from '@components/Query/Query'

import GET_MARKETPLACE_FAQS from './queries/getMarketplaceFAQs.query'
import GET_FAQ from './queries/getFAQ.query'
import EDIT_FAQ from './mutations/editFAQ.mutation'
import ADD_FAQ from './mutations/addFAQ.mutation'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { useMutation } from '@apollo/client'

const FAQDrawer = ({ isOpen, drawerClose, selectedId, marketplaceId }) => {
  const { dark } = useContext(PageLayoutContext)
  const isEditing = selectedId != null

  const getForm = ({ handleSubmit, handleChange, values, isEditing }) => (
    <form onSubmit={handleSubmit}>
      <div className={'bp5-drawer-content'}>
        <FormGroup label="Question" labelFor="question" labelInfo="(required)">
          <InputGroup
            id="question"
            value={get(values, 'question', '')}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup
          label="Answer"
          labelFor="answer"
          labelInfo="(required)"
          fill={true}
          helperText={
            <Fragment>
              Formatted with{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.redbox.systems/docs/markdown-guide"
              >
                Markdown (Guide)
              </a>
              .
            </Fragment>
          }
        >
          <TextArea
            id="answer"
            fill={true}
            onChange={handleChange}
            style={{ height: '300px' }}
            value={get(values, 'answer', '')}
          />
        </FormGroup>
      </div>
      <div className="bp5-drawer-footer-actions">
        <Button type="submit">{isEditing ? 'Save' : 'Create'}</Button>
      </div>
    </form>
  )

  const [addFAQ] = useMutation(ADD_FAQ, {
    onCompleted: ({ addFAQ }) => {
      drawerClose()
      successToast(`Saved "${get(addFAQ, 'question', '')}"`)
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      { query: GET_MARKETPLACE_FAQS, variables: { marketplaceId } },
    ],
  })

  const [editFAQ] = useMutation(EDIT_FAQ, {
    onCompleted: ({ editFAQ }) => {
      drawerClose()
      successToast(`Saved "${get(editFAQ, 'question', '')}"`)
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      { query: GET_MARKETPLACE_FAQS, variables: { marketplaceId } },
    ],
  })

  return (
    <Drawer
      isOpen={isOpen}
      onClose={drawerClose}
      title={isEditing ? `Edit FAQ` : 'New FAQ'}
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      className={dark ? 'bp5-dark' : ''}
      size={DrawerSize.LARGE}
    >
      <Fragment>
        {isEditing ? (
          <Query
            query={GET_FAQ}
            variables={{
              id: selectedId,
            }}
          >
            {({ getFAQs }) => {
              const faq = first(getFAQs)
              return (
                <Formik
                  onSubmit={values => {
                    editFAQ({
                      variables: {
                        id: values.id,
                        ...values,
                      },
                    })
                  }}
                  initialValues={{
                    ...faq,
                  }}
                >
                  {props => getForm({ ...props, isEditing })}
                </Formik>
              )
            }}
          </Query>
        ) : (
          <Formik
            onSubmit={values => {
              addFAQ({
                variables: {
                  marketplaceId: marketplaceId,
                  ...values,
                },
              })
            }}
            initialValues={{
              question: '',
              answer: '',
            }}
            refetchQueries={[
              {
                query: GET_MARKETPLACE_FAQS,
                variables: {
                  marketplaceId: marketplaceId,
                },
              },
            ]}
          >
            {props => getForm({ ...props, isEditing })}
          </Formik>
        )}
      </Fragment>
    </Drawer>
  )
}

export default FAQDrawer
