import gql from 'graphql-tag'

const UPDATE_VOUCHERS_ACTIVE_STATE = gql`
  mutation updateVouchersActiveState(
    $voucherIds: [String!]!
    $active: Boolean!
  ) {
    updateVouchersActiveState(voucherIds: $voucherIds, active: $active)
  }
`

export default UPDATE_VOUCHERS_ACTIVE_STATE
