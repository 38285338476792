import * as yup from 'yup'
import { phoneRegExp } from './phone-regex'
import { IIterableInterface } from './generic-validator'
import { defaultToEmptyString } from './utils'

export const addRestaurantStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Business Name is required')
    .min(3, 'Business name too short'),
  contactEmail: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  contactName: yup.string().required('Contact Name is required'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Must be a valid phone number'),
  partnerId: yup.string().required('A partner is required'),
  outletMarketplaceId: yup
    .string()
    .required('A marketplace for the outlet is required'),
}

export const addOnboardingRestaurantStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Business Name is required')
    .min(3, 'Business name too short'),
  contactEmail: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  contactName: yup.string().required('Contact Name is required'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Must be a valid phone number'),
}

export const editRestaurantStructure: IIterableInterface = {
  name: yup.string().min(3, 'Business name too short'),
  contactEmail: yup.string().email('Must be a valid email'),
  contactName: yup.string(),
  contactPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Must be a valid phone number'),
  partnerId: yup.string(),
  description: yup
    .string()
    .min(10, 'Business Description must be more than 50 characters.')
    .nullable(),
  partnerFee: yup.number().nullable(),
  stripeId: yup.string().nullable(),
  orderHoldInterval: yup.number().integer(),
  acceptPreorders: yup.boolean(),
  cuisineIds: yup.array().of(yup.string()),
  menuItemsIds: yup.array().of(yup.string()),
  menuItemGroupIds: yup.array().of(yup.string()),
  outlets: yup.array().of(yup.string()),
  active: yup.boolean(),
  promoted: yup.boolean().nullable(),
  featured: yup.boolean().nullable(),
  metaDescription: yup
    .string()
    .max(155, "SEO Description can't be more than 155 characters")
    .nullable(),
}

// schema which validates all fields required for activation
export const activateRestaurantStructure = yup.object().shape({
  name: yup.string().required('A name is required to Activate Business.'),
  description: yup
    .string()
    .transform(defaultToEmptyString)
    .required('A description / profile is required ')
    .min(50, 'Business Description must be more than 50 characters.'),
  contactName: yup
    .string()
    .transform(defaultToEmptyString)
    .min(1, 'Owner Name is required to Activate Business.'),
  contactEmail: yup
    .string()
    .transform(defaultToEmptyString)
    .min(1, 'A Owner Email address is required to Activate Business.'),
  contactPhone: yup
    .string()
    .transform(defaultToEmptyString)
    .min(1, 'Owner Phone Number is required to Activate Business.'),
  cuisines: yup
    .array()
    .min(1, 'At least one Category is required to Activate Business.'),
  businessCategories: yup
    .array(
      yup.object().shape({ category: yup.object().shape({ id: yup.string() }) })
    )
    .min(1, 'At least one Category is required to Activate Business.'),

  contactAddress: yup
    .mixed()
    .required('Owner Address is required to Activate Business.'),
  outlets: yup
    .array()
    .min(1, 'At least one Outlet is required to Activate Business.'),
  menuItems: yup
    .array()
    .min(1, 'At least one Menu Item is required to Activate Business.'),
  partner: yup
    .mixed()
    .required('Partner association is required to Activate Business.'),
})
