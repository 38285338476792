import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { matchType } from '@utils/types'
import split from 'lodash/split'
import { object } from 'prop-types'
import { Fragment, useContext, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'

import Menu from '@components/Restaurant/Menu/Menu'
import {
  canPerformAction,
  isMenuEditor,
  canView,
  isAtLeastMenuEditor,
  isAtLeastOutletUser,
  isAtLeastOutletFinancialUser,
  isAtLeastBusinessUser,
} from '@stores/userStore'
import Dashboard from './Dashboard/Dashboard'
import Details from './Details/Details'
import Financials from './Financials/Financials'
import OpeningTimes from './OpeningTimes/OpeningTimes'
import OutletReviews from './OutletReviews/OutletReviews'
import Tables from './Tables/Tables'
import Terminals from './Terminals/Terminals'

import { Callout } from '@blueprintjs/core'
import Users from '@components/Users/Users'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'

import productDictionary from '@src/utils/productDictionary'
import { isPeakTimeIncludingFridayAndSaturday } from '@utils/isPeakTime'
import { ModalContext } from '../../providers/ModalProvider'
import DeliveryZones from './Fulfilment/DeliveryZones'
import RoleProtectedRoute from '../RoleProtectedRoute/RoleProtectedRoute'

const showCacheWarning = isPeakTimeIncludingFridayAndSaturday()

const tabButton = ({
  pathname,
  setUserModalOpen,
  toggleModal,
}: {
  pathname: string
  setUserModalOpen: (_: boolean) => void
  toggleModal: (_) => void
}) => {
  const tabName = split(pathname, '/')[5]

  if (tabName === 'tables' && isAtLeastOutletUser()) {
    return {
      text: 'Add Table',
      onClick: () =>
        toggleModal({
          addTableModal: true,
        }),
    }
  }

  if (tabName === 'terminals' && isAtLeastOutletUser()) {
    return {
      text: 'Add Terminal',
      onClick: () =>
        toggleModal({
          terminalModal: true,
        }),
    }
  }

  if (tabName === 'fulfilment' && isAtLeastBusinessUser()) {
    return {
      text: 'Add Delivery Zone',
      onClick: () =>
        toggleModal({
          addDeliveryZoneModal: true,
        }),
    }
  }

  if (tabName === 'users' && canPerformAction('inviteUser')) {
    return {
      text: 'Invite user',
      onClick: () => setUserModalOpen(true),
    }
  }

  return null
}

const Outlet = () => {
  const { restaurants, outlet } = useParams()
  const { setModalOpen: setUserModalOpen } = useContext(usersPageContext)
  const { configurePageLayout } = useContext(PageLayoutContext)
  const { setState } = useContext(ModalContext)

  const tabs = [
    {
      to: `/business/${restaurants}/outlets/${outlet}/overview`,
      name: 'Overview',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/details`,
      name: 'Details',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/financial`,
      name: 'Payments',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/terminals`,
      name: 'Terminals',
      hide: !canView('virtualTerminals'),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/fulfilment`,
      name: 'Delivery',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/opening-times`,
      name: 'Ordering',
      hide: !isAtLeastMenuEditor(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/reviews`,
      name: 'Reviews',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/stock`,
      name: 'Stock',
      hide: !isAtLeastMenuEditor(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/users`,
      name: 'Users',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `/business/${restaurants}/outlets/${outlet}/tables`,
      name: 'Tables',
      hide: !isAtLeastBusinessUser(),
    },
  ]

  const location = useLocation()

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.OUTLET,
      tabs,
      button: tabButton({
        pathname: location.pathname,
        setUserModalOpen,
        toggleModal: setState,
      }),
    })
  }, [location.pathname, configurePageLayout, setUserModalOpen])
  return (
    <Fragment>
      {showCacheWarning && (
        <Fragment>
          <Callout intent="warning">
            We are currently in a peak order period, during this time it may
            take up to 60 minutes to reflect updates to your menu or outlet
            settings.
          </Callout>
          <br />
        </Fragment>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                isMenuEditor()
                  ? `/business/${restaurants}/outlets/${outlet}/opening-times`
                  : `/business/${restaurants}/outlets/${outlet}/overview`
              }
            />
          }
        />

        <Route
          element={
            <RoleProtectedRoute
              hasPermission={isAtLeastOutletFinancialUser()}
            />
          }
        >
          <Route path={`/overview`} element={<Dashboard />} />
          <Route path={`/details`} element={<Details />} />
          <Route path={`/financial`} element={<Financials />} />
        </Route>

        <Route
          element={
            <RoleProtectedRoute hasPermission={canView('virtualTerminals')} />
          }
        >
          <Route path={`/terminals`} element={<Terminals />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={isAtLeastOutletUser()} />}
        >
          <Route path={`/fulfilment`} element={<DeliveryZones />} />
          <Route path={`/reviews`} element={<OutletReviews />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={isAtLeastMenuEditor()} />}
        >
          <Route path={`/opening-times`} element={<OpeningTimes />} />
          <Route path={`/stock/*`} element={<Menu outlet />} />
        </Route>

        <Route
          element={
            <RoleProtectedRoute hasPermission={isAtLeastBusinessUser()} />
          }
        >
          <Route path={`/users`} element={<Users outletId={outlet} />} />
        </Route>

        <Route path={`tables`} element={<Tables />} />
      </Routes>
    </Fragment>
  )
}

Outlet.propTypes = {
  location: object,
  match: matchType,
}

const OutletPageWrapper = props => (
  <UsersPageWrapper entityName="outlet">
    <Outlet {...props} />
  </UsersPageWrapper>
)

export default OutletPageWrapper
