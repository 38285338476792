import gql from 'graphql-tag'

const DISCOUNT_ANALYTICS = gql`
  query discountAnalytics($businessId: String!, $discountId: String!) {
    businessOrdersForAnalyticsByDiscount(
      businessId: $businessId
      discountId: $discountId
    ) {
      discountId
      outletId
      discountAmount
      subtotalGross
      fulfillmentMethod
      customerId
      paymentMethod
    }
  }
`

export default DISCOUNT_ANALYTICS
