export const PaymentRisk = {
  // Stripe riskLevel
  normal: {
    title: 'Normal Risk',
    intent: 'success',
    icon: 'small-tick',
    details:
      'The charge is considered safe. No elevated risk factors were detected in the transaction.',
  },
  elevated: {
    title: 'Elevated Risk',
    intent: 'warning',
    icon: 'warning-sign',
    details:
      'The charge is slightly risky. Some risk factors were detected, such as mismatched billing and shipping addresses or unusual purchase patterns.',
  },
  highest: {
    title: 'High Risk',
    intent: 'danger',
    icon: 'error',
    details:
      'The charge is very likely fraudulent. Significant risk factors were detected, including high-risk IP addresses or known fraudulent card numbers.',
  },
  default: {
    title: 'Unknown',
    intent: 'none',
    details:
      'The risk level is unknown or not specified. This could be due to a lack of sufficient information to determine the risk level.',
  },
}
