import gql from 'graphql-tag'

const SEND_MESSAGE_TO_OUTLETS = gql`
  mutation sendMessageToOutlets(
    $content: String!
    $subject: String!
    $marketplaceId: String!
    $restaurantIds: [String]
    $messageId: String
    $addMarketplaceTheme: Boolean
  ) {
    sendMessageToOutlets(
      content: $content
      subject: $subject
      marketplaceId: $marketplaceId
      restaurantIds: $restaurantIds
      messageId: $messageId
      addMarketplaceTheme: $addMarketplaceTheme
    ) {
      message
      error
    }
  }
`

export default SEND_MESSAGE_TO_OUTLETS
