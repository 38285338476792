import React from 'react'
import { Spinner } from '@blueprintjs/core'

import FetchQuery from '../FetchQuery/FetchQuery'
import config from '@src/config'
import ls from '../../utils/localStorage'
import AppVersionDropdown from './AppVersionDropdown'

const environmentDomain = config.environmentDomain

const AppVersionDropdownContainer = ({
  value,
  onChange,
  name,
  disabled = false,
}) => (
  <FetchQuery
    domain={`app-build.${environmentDomain}`}
    path={`git/refs`}
    fetchOptions={{
      headers: {
        Authorization: `Bearer ${ls.get('jwt')}`,
      },
    }}
  >
    {({ data, loading }) => {
      if (loading) {
        return <Spinner size={20} />
      }

      if (!data || !data.tags) {
        return null
      }

      return (
        <AppVersionDropdown
          name={name}
          appVersions={data.tags}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )
    }}
  </FetchQuery>
)

export default AppVersionDropdownContainer
