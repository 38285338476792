import { set, get, orderBy } from 'lodash'

const defaultValues = {
  outletId: '',
  numberOfOrdersPerOutlet: 0,
  totalSpentPerOutlet: 0,
}

/**
 * @param {*} rawOrdersData an array of partial orders with discount data,
 * coming from the query "customerOrdersForAnalytics"
 * @returns an object with the customer favourite outlets
 * note that the total s are returned in pennies
 */
export const customerFavourites = (rawOrdersData, topThree = false) => {
  let grandTotal = 0
  let totalNumberOfOrders = 0

  const formattedData = rawOrdersData
    ? rawOrdersData
        .filter(
          // this mimics the filter in the details section so that the totals are consistent
          order =>
            order.acceptedAt &&
            ['COMPLETE', 'READY', 'PREPARING'].includes(order.orderStatus)
        )
        .reduce((acc, rawOrder) => {
          // use outletId to group orders
          const key = rawOrder.outletId

          if (!get(acc, key)) {
            set(acc, key, {
              ...defaultValues,
            })
          }
          const previousValues = get(acc, key)

          if (!acc[key].outletId) {
            acc[key].outletId = rawOrder.outletId
          }

          // calc totals
          acc[key].totalSpentPerOutlet =
            previousValues.totalSpentPerOutlet + rawOrder.subtotalGross

          // count orders
          acc[key].numberOfOrdersPerOutlet =
            previousValues.numberOfOrdersPerOutlet + 1

          // update grand totals
          grandTotal += rawOrder.subtotalGross
          totalNumberOfOrders += 1

          return acc
        }, {})
    : []

  const formattedDataArray = orderBy(
    Object.values(formattedData),
    'totalSpentPerOutlet',
    'desc'
  )

  if (topThree) {
    formattedDataArray.splice(3)
  }

  // transform calculate averages
  return formattedDataArray.map(entry => {
    return {
      ...entry,
      avgSpentPerOutlet: entry.totalSpentPerOutlet / grandTotal,
      avgNumberOfOrdersPerOtlet:
        entry.numberOfOrdersPerOutlet / totalNumberOfOrders,
    }
  })
}
