import React, { useState, Fragment, useEffect } from 'react'
import Query from '@components/Query/Query'
import GET_TRANSACTIONS from './queries/getTransactions.query'
import GET_TRANSACTIONS_ADVANCED from './queries/getTransactionsAdvanced.query'
import {
  Button,
  NonIdealState,
  Alert,
  ProgressBar,
  ControlGroup,
  Drawer,
  HTMLTable,
} from '@blueprintjs/core'
import moment from 'moment'
import {
  canView,
  isPartner,
  ownsPartners,
  isPlatformLevelUser,
} from '@stores/userStore'
import { toast } from '@utils/toast'
import { Search, Pager, MarketplaceFilter } from '@components/Toolbar'
import { TableWithTransaction } from './TableWithTransaction'
import { RowRenderers } from './RowRenderers'
import { omit, startCase, get } from 'lodash'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import FilterRow from '@components/FilterRow/FilterRow'
import Currency from '@components/Currency/Currency'
import { format } from 'date-fns'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import { useSearchQueryParam } from '../../../components/Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../../../components/Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const DEFAULT_RECORDS = 50
const DEFAULT_PAGINATION_STATE = {
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  total: 0,
}

const SORTED_REPORT_FIELDS = [
  'transactionId',
  'transactionType',
  'createdAt',
  'acceptedAt',
  'refundedAt',
  'partner',
  'marketplace',
  'status',
  'order',
  'discount',
  'customer',
  'customerStripeId',
  'fulfillment',
  'payment',
  'transactionStripeId',
  'restaurant',
  'outlet',
  'orderNetTotal',
  'orderGrossTotal',
  'addOnItemsNetTotal',
  'addOnItemsGrossTotal',
  'discountAmount',
  'restaurantDeliveryCharge',
  'nonNetworkFulfilmentChargeGross',
  'nonNetworkFulfilmentChargeNet',
  'nonNetworkFulfilmentChargeVAT',
  'networkDeliveryCharge',
  'customerDeliveryCharge',
  'deliveryChargeRecipient',
  'marketplaceDeliverySubsidy',
  'merchantDeliverySubsidy',
  'marketplaceServiceCharge',
  'merchantCommissionCharge',
  'merchantCommissionChargeWithVat',
  'partnerCommissionCharge',
  'customerTaxes',
  'merchantNetworkDeliveryAllocation',
  'marketplaceNetworkDeliveryAllocation',
  'marketplaceChargeVATPaidByMerchant',
  'marketplaceChargeVATPaidByCustomer',
  'marketplaceChargeVATPayer',
  'subtotalNetAfterDiscount',
  'subtotalGrossAfterDiscount',
  'subtotalVATAfterDiscount',
  'subtotalMinusMarketplaceCharge',
  'subtotalMinusNetworkDeliveryCharge',
  'subtotalMinusNetworkDeliveryMarketplaceCharge',
  'totalOrderValue',
  'totalPaidCash',
  'marketplaceFeePercentage',
  'marketplaceFeeNet',
  'marketplaceFee',
  'platformCardProcessingFee',
  'platformCardProcessingCharge',
  'platformFeePercentageWithVat',
  'platformFeeNet',
  'platformFee',
  'redboxFeePercentageWithVat',
  'redboxFeeNet',
  'redboxFee',
  'partnerFeePercentageWithVat',
  'partnerFeeNet',
  'partnerFee',
  'marketplaceChargeNet',
  'marketplaceChargeGross',
  'marketplaceChargeFromMerchantNet',
  'marketplaceChargeFromMerchantGross',
  'redboxCardProcessingCostFee',
  'redboxCardProcessingCostCharge',
  'redboxChargeNet',
  'redboxCharge',
  'totalMarketplaceCharge',
  'totalToPlatform',
  'totalPaidToPlatform',
  'totalToPaybox',
  'totalPaidToPaybox',
  'totalToRedbox',
  'totalPaidToRedbox',
  'totalToPartner',
  'totalPaidToPartner',
  'totalToMarketplace',
  'totalToMarketplaceAfterFees',
  'totalPaidToMarketplace',
  'marketplaceTransferStripeId',
  'totalToDeliveryNetwork',
  'totalToOutlet',
  'totalToOutletPreRefund',
  'totalPaidToOutlet',
  'outletTransferStripeId',
  'totalRefund',
  'merchantAndMarketplaceImprecision',
  'partnerAndRedboxImprecision',
]

const ADVANCED_REPORT_FIELDS = [
  'transactionId',
  'transactionType',
  'partner',
  'marketplace',
  'discount',
  'customerStripeId',
  'nonNetworkFulfilmentChargeGross',
  'nonNetworkFulfilmentChargeNet',
  'nonNetworkFulfilmentChargeVAT',
  'networkDeliveryCharge',
  'marketplaceServiceCharge',
  'customerTaxes',
  'merchantNetworkDeliveryAllocation',
  'marketplaceNetworkDeliveryAllocation',
  'marketplaceChargeVATPaidByMerchant',
  'marketplaceChargeVATPaidByCustomer',
  'marketplaceChargeVATPayer',
  'subtotalNetAfterDiscount',
  'subtotalGrossAfterDiscount',
  'subtotalVATAfterDiscount',
  'subtotalMinusMarketplaceCharge',
  'subtotalMinusNetworkDeliveryCharge',
  'totalPaidCash',
  'marketplaceFeePercentage',
  'marketplaceFeeNet',
  'marketplaceFee',
  'platformCardProcessingFee',
  'platformCardProcessingCharge',
  'platformFeePercentageWithVat',
  'redboxFeePercentageWithVat',
  'redboxFeeNet',
  'redboxFee',
  'partnerFeePercentageWithVat',
  'partnerFeeNet',
  'partnerFee',
  'marketplaceChargeNet',
  'marketplaceChargeGross',
  'marketplaceChargeFromMerchantNet',
  'marketplaceChargeFromMerchantGross',
  'redboxCardProcessingCostFee',
  'redboxCardProcessingCostCharge',
  'redboxChargeNet',
  'redboxCharge',
  'totalMarketplaceCharge',
  'totalToPlatform',
  'totalPaidToPlatform',
  'totalToPaybox',
  'totalPaidToPaybox',
  'totalToRedbox',
  'totalPaidToRedbox',
  'totalToPartner',
  'totalPaidToPartner',
  'totalToMarketplace',
  'totalPaidToMarketplace',
  'marketplaceTransferStripeId',
  'totalToOutlet',
  'totalToOutletPreRefund',
  'totalPaidToOutlet',
  'outletTransferStripeId',
  'merchantAndMarketplaceImprecision',
  'partnerAndRedboxImprecision',
]

const Transactions = () => {
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams()

  const [state, setState] = useState({
    ...DEFAULT_PAGINATION_STATE,
    navigationDisabled: false,
    advancedReport: false,
    downloading: false,
    downloadingProgress: 0,
    showRowObject: null,
  })
  const { searchValue } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      setState(prevState => ({
        ...prevState,
        ...DEFAULT_PAGINATION_STATE,
      }))
    }
  }, [searchValue])

  const visibleColumns = state.advancedReport
    ? SORTED_REPORT_FIELDS
    : SORTED_REPORT_FIELDS.filter(
        field => !ADVANCED_REPORT_FIELDS.includes(field)
      )

  const downloadCSV = async e => {
    e.preventDefault()
    setState(prevState => ({ ...prevState, downloading: true }))

    const totalPages = state.total / state.defaultNmbRecords

    let csvData = [
      visibleColumns
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    for (let i = 0; i <= totalPages; i++) {
      const progress = ((i / totalPages) * 100) / 100
      setState(prevState => ({ ...prevState, downloadingProgress: progress }))
      await client
        .query({
          query: state.advancedReport
            ? GET_TRANSACTIONS_ADVANCED
            : GET_TRANSACTIONS,
          variables: {
            afterDate: shiftedStartOfRangeDateTime,
            beforeDate: shiftedEndOfRangeDateTime,
            skip: state.defaultNmbRecords * i,
            first: state.defaultNmbRecords,
            last: null,
            marketplaceIds:
              marketplaceFilter.length > 0 ? marketplaceFilter : undefined,
          },
        })
        .then(data => {
          data.data.getTransactions.transactions.map(record => {
            csvData.push(
              visibleColumns
                .map(column => {
                  return {
                    transactionId: `"${get(record, 'id')}"`,
                    transactionType: `"${get(record, 'transactionType')}"`,
                    partner: `"${get(record, 'partner.name', '')}"`,
                    marketplace: `"${get(record, 'marketplace.name', '')}"`,
                    createdAt: moment(get(record, 'createdAt')).format(
                      'DD/MM/YYYY HH:mm:ss'
                    ),
                    acceptedAt: moment(get(record, 'acceptedAt')).format(
                      'DD/MM/YYYY HH:mm:ss'
                    ),
                    refundedAt: get(record, 'refundedAt')
                      ? moment(get(record, 'refundedAt')).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )
                      : '',
                    status:
                      get(record, 'orderStatus') == 'PREPARING'
                        ? 'ACCEPTED'
                        : get(record, 'orderStatus'),
                    order: get(record, 'order.orderNumber'),
                    discount: get(record, 'discount.name', '-'),
                    customer:
                      get(record, 'customer.firstName') +
                      ' ' +
                      get(record, 'customer.lastName')[0],
                    customerStripeId: `"${get(record, 'customerStripeId')}"`,
                    fulfillment: get(record, 'fulfillmentType'),
                    payment: get(record, 'paymentType'),
                    transactionStripeId: get(record, 'transactionStripeId'),
                    restaurant: `"${get(record, 'outlet.restaurant.name')}"`,
                    outlet: `"${get(record, 'outlet.name')}"`,
                    orderNetTotal: penceToPounds(
                      get(record, 'orderNetTotal', 0)
                    ),
                    orderGrossTotal: penceToPounds(
                      get(record, 'orderGrossTotal', 0)
                    ),
                    addOnItemsNetTotal: penceToPounds(
                      get(record, 'addOnItemsNetTotal', 0)
                    ),
                    addOnItemsGrossTotal: penceToPounds(
                      get(record, 'addOnItemsGrossTotal', 0)
                    ),
                    customerDeliveryCharge: penceToPounds(
                      get(record, 'customerDeliveryCharge', 0)
                    ),
                    marketplaceDeliverySubsidy: penceToPounds(
                      get(record, 'marketplaceDeliverySubsidy', 0)
                    ),
                    merchantDeliverySubsidy: penceToPounds(
                      get(record, 'merchantDeliverySubsidy', 0)
                    ),
                    deliveryChargeRecipient: `"${get(
                      record,
                      'deliveryChargeRecipient',
                      ''
                    )}"`,
                    subtotalMinusMarketplaceCharge: penceToPounds(
                      get(record, 'subtotalMinusMarketplaceCharge', 0)
                    ),
                    subtotalMinusNetworkDeliveryCharge: penceToPounds(
                      get(record, 'subtotalMinusNetworkDeliveryCharge', 0)
                    ),
                    subtotalMinusNetworkDeliveryMarketplaceCharge:
                      penceToPounds(
                        get(
                          record,
                          'subtotalMinusNetworkDeliveryMarketplaceCharge',
                          0
                        )
                      ),
                    marketplaceFeePercentage: get(
                      record,
                      'marketplaceFeePercentage',
                      0
                    ),
                    marketplaceFeeNet: penceToPounds(
                      get(record, 'marketplaceFeeNet', 0)
                    ),
                    marketplaceFee: penceToPounds(
                      get(record, 'marketplaceFee', 0)
                    ),
                    platformFeePercentageWithVat: get(
                      record,
                      'platformFeePercentageWithVat',
                      0
                    ),
                    platformCardProcessingFee: penceToPounds(
                      get(record, 'platformCardProcessingFee', 0)
                    ),
                    platformCardProcessingCharge: penceToPounds(
                      get(record, 'platformCardProcessingCharge', 0)
                    ),
                    redboxCardProcessingCostFee: penceToPounds(
                      get(record, 'redboxCardProcessingCostFee', 0)
                    ),
                    redboxCardProcessingCostCharge: penceToPounds(
                      get(record, 'redboxCardProcessingCostCharge', 0)
                    ),
                    redboxFeeNet: penceToPounds(get(record, 'redboxFeeNet')),
                    redboxFee: penceToPounds(get(record, 'redboxFee')),
                    redboxFeePercentageWithVat: get(
                      record,
                      'redboxFeePercentageWithVat',
                      0
                    ),
                    partnerFeeNet: penceToPounds(
                      get(record, 'partnerFeeNet', 0)
                    ),
                    partnerFee: penceToPounds(get(record, 'partnerFee', 0)),
                    partnerFeePercentageWithVat: get(
                      record,
                      'partnerFeePercentageWithVat',
                      0
                    ),
                    marketplaceChargeNet: penceToPounds(
                      get(record, 'marketplaceChargeNet', 0)
                    ),
                    marketplaceChargeGross: penceToPounds(
                      get(record, 'marketplaceChargeGross', 0)
                    ),
                    marketplaceChargeFromMerchantNet: penceToPounds(
                      get(record, 'marketplaceChargeFromMerchantNet', 0)
                    ),
                    marketplaceChargeFromMerchantGross: penceToPounds(
                      get(record, 'marketplaceChargeFromMerchantGross', 0)
                    ),
                    redboxCharge: penceToPounds(get(record, 'redboxCharge', 0)),
                    redboxChargeNet: penceToPounds(
                      get(record, 'redboxChargeNet', 0)
                    ),
                    totalMarketplaceCharge: penceToPounds(
                      get(record, 'totalMarketplaceCharge', 0)
                    ),
                    discountAmount: penceToPounds(
                      get(record, 'discountAmount', 0)
                    ),
                    restaurantDeliveryCharge: penceToPounds(
                      get(record, 'restaurantDeliveryCharge', 0)
                    ),
                    nonNetworkFulfilmentChargeGross: penceToPounds(
                      get(record, 'nonNetworkFulfilmentChargeGross', 0)
                    ),
                    nonNetworkFulfilmentChargeNet: penceToPounds(
                      get(record, 'nonNetworkFulfilmentChargeNet', 0)
                    ),
                    nonNetworkFulfilmentChargeVAT: penceToPounds(
                      get(record, 'nonNetworkFulfilmentChargeVAT', 0)
                    ),
                    networkDeliveryCharge: penceToPounds(
                      get(record, 'networkDeliveryCharge', 0)
                    ),
                    marketplaceServiceCharge: penceToPounds(
                      get(record, 'marketplaceServiceCharge', 0)
                    ),
                    merchantCommissionCharge: penceToPounds(
                      get(record, 'merchantCommissionCharge', 0)
                    ),
                    merchantCommissionChargeWithVat: penceToPounds(
                      get(record, 'merchantCommissionChargeWithVat', 0)
                    ),
                    partnerCommissionCharge: penceToPounds(
                      get(record, 'partnerCommissionCharge', 0)
                    ),
                    customerTaxes: penceToPounds(
                      get(record, 'customerTaxes', 0)
                    ),
                    merchantNetworkDeliveryAllocation: penceToPounds(
                      get(record, 'merchantNetworkDeliveryAllocation', 0)
                    ),
                    marketplaceNetworkDeliveryAllocation: penceToPounds(
                      get(record, 'marketplaceNetworkDeliveryAllocation', 0)
                    ),
                    marketplaceChargeVATPayer: get(
                      record,
                      'marketplaceChargeVATPayer',
                      ''
                    ),
                    subtotalNetAfterDiscount: penceToPounds(
                      get(record, 'subtotalNetAfterDiscount', 0)
                    ),
                    subtotalGrossAfterDiscount: penceToPounds(
                      get(record, 'subtotalGrossAfterDiscount', 0)
                    ),
                    subtotalVATAfterDiscount: penceToPounds(
                      get(record, 'subtotalVATAfterDiscount', 0)
                    ),
                    marketplaceChargeVATPaidByMerchant: penceToPounds(
                      get(record, 'marketplaceChargeVATPaidByMerchant', 0)
                    ),
                    marketplaceChargeVATPaidByCustomer: penceToPounds(
                      get(record, 'marketplaceChargeVATPaidByCustomer', 0)
                    ),
                    platformFeeNet: penceToPounds(
                      get(record, 'platformFeeNet', 0)
                    ),
                    platformFee: penceToPounds(get(record, 'platformFee', 0)),
                    totalToPlatform: penceToPounds(
                      get(record, 'totalToPlatform', 0)
                    ),
                    totalPaidToPlatform: penceToPounds(
                      get(record, 'totalPaidToPlatform', 0)
                    ),
                    totalToPaybox: penceToPounds(
                      get(record, 'totalToPaybox', 0)
                    ),
                    totalPaidToPaybox: penceToPounds(
                      get(record, 'totalPaidToPaybox', 0)
                    ),
                    totalToRedbox: penceToPounds(
                      get(record, 'totalToRedbox', 0)
                    ),
                    totalPaidToRedbox: penceToPounds(
                      get(record, 'totalPaidToRedbox', 0)
                    ),
                    totalToPartner: penceToPounds(
                      get(record, 'totalToPartner', 0)
                    ),
                    totalPaidToPartner: penceToPounds(
                      get(record, 'totalPaidToPartner', 0)
                    ),
                    totalOrderValue: penceToPounds(
                      get(record, 'totalOrderValue', 0)
                    ),
                    totalToMarketplace: penceToPounds(
                      get(record, 'totalToMarketplace', 0)
                    ),
                    totalToMarketplaceAfterFees: penceToPounds(
                      get(record, 'totalToMarketplaceAfterFees', 0)
                    ),
                    totalPaidToMarketplace: penceToPounds(
                      get(record, 'totalPaidToMarketplace', 0)
                    ),
                    marketplaceTransferStripeId: `"${get(
                      record,
                      'marketplaceTransferStripeId'
                    )}"`,
                    totalPaidCash: penceToPounds(
                      get(record, 'totalPaidCash', 0)
                    ),
                    totalToOutlet: penceToPounds(
                      get(record, 'totalToOutlet', 0)
                    ),
                    totalToOutletPreRefund: penceToPounds(
                      get(record, 'totalToOutletPreRefund', 0)
                    ),
                    totalPaidToOutlet: penceToPounds(
                      get(record, 'totalPaidToOutlet', 0)
                    ),
                    outletTransferStripeId: `"${get(
                      record,
                      'outletTransferStripeId'
                    )}"`,
                    totalToDeliveryNetwork: penceToPounds(
                      get(record, 'totalToDeliveryNetwork', 0)
                    ),
                    totalRefund: penceToPounds(get(record, 'totalRefund', 0)),
                    merchantAndMarketplaceImprecision: penceToPounds(
                      get(record, 'merchantAndMarketplaceImprecision', 0)
                    ),
                    partnerAndRedboxImprecision: penceToPounds(
                      get(record, 'partnerAndRedboxImprecision', 0)
                    ),
                  }[column]
                })
                .join(',')
            )
          })
        })
    }

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `transactions-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setState(prevState => ({
      ...prevState,
      downloadingProgress: 0,
      downloading: false,
    }))

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  const setTotalCount = (totalCount, returnCount) => {
    setState(prevState => ({
      ...prevState,
      total: totalCount,
      outcomeLength: returnCount,
    }))
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitNext(prevState.skip, prevState.first, limit),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  return canView('reports') ? (
    <Fragment>
      <div className="bp5-table-frame">
        <FilterRow>
          <DateRangeFilter
            filterTypes={[
              DATE_FILTER_TYPES.DAY,
              DATE_FILTER_TYPES.WEEK,
              DATE_FILTER_TYPES.CUSTOM,
            ]}
          />
          <ControlGroup>
            <Search
              placeholder="Order number, stripe id..."
              disabled={!isPlatformLevelUser()}
            />
            <MarketplaceFilter disabled={searchValue} />
          </ControlGroup>
          {isPlatformLevelUser() && (
            <Button
              onClick={() => {
                setState(prevState => ({
                  ...prevState,
                  advancedReport: true,
                }))
              }}
              active={state.advancedReport}
              disabled={state.advancedReport}
              icon="filter-open"
            >
              Advanced
            </Button>
          )}
          <Pager
            goToPrevious={goToPrevious}
            goToNext={goToNext}
            goToPage={goToPage}
            defaultNmbRecords={DEFAULT_RECORDS}
            skip={state.skip}
            total={state.total}
            outcomeLength={state.outcomeLength}
            totalCount={state.total}
          />
          <Button
            disabled={!state.total || searchValue}
            loading={state.downloading}
            icon={'cloud-download'}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        </FilterRow>

        <Query
          query={
            state.advancedReport ? GET_TRANSACTIONS_ADVANCED : GET_TRANSACTIONS
          }
          variables={{
            afterDate: shiftedStartOfRangeDateTime,
            beforeDate: shiftedEndOfRangeDateTime,
            ...(isPartner() && { partnerIds: ownsPartners() }),
            marketplaceIds:
              marketplaceFilter.length > 0 ? marketplaceFilter : undefined,
            ...state,
          }}
          loaderTitle="Loading Transactions"
          onCompleted={data => {
            setTotalCount(
              data.getTransactions.totalCount,
              data.getTransactions.count
            )
          }}
        >
          {({ getTransactions: { transactions } }) => (
            <TableWithTransaction
              response={RowRenderers(transactions)}
              visibleColumns={visibleColumns}
              data={transactions}
              onShow={e =>
                setState(prevState => ({ ...prevState, showRowObject: e }))
              }
            />
          )}
        </Query>
        <Alert
          isOpen={state.downloadingProgress > 0}
          icon={'cloud-download'}
          canEscapeKeyCancel={false}
          canOutsideClickCancel={false}
          confirmButtonText="Cancel"
          onConfirm={() => {
            location.reload()
          }}
        >
          <p>Downloading transactions, please wait...</p>
          <ProgressBar intent={'primary'} value={state.downloadingProgress} />
        </Alert>
      </div>
      <Drawer
        title="Transaction Detail"
        isOpen={state.showRowObject}
        onClose={() =>
          setState(prevState => ({ ...prevState, showRowObject: null }))
        }
        size={`${window.innerWidth > 1100 ? '1000px' : '80%'}`}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal
      >
        <div style={{ overflow: 'auto' }}>
          {state.showRowObject && (
            <HTMLTable condensed striped>
              <tbody>
                {Object.keys(omit(state.showRowObject, ['__typename'])).map(
                  column => {
                    const value = state.showRowObject[column]
                    return (
                      <tr key={column}>
                        <td>{startCase(column)}</td>
                        <td>
                          {typeof value === 'object' ? (
                            value ? (
                              value.name ||
                              value.orderNumber ||
                              value.email ||
                              value.id
                            ) : (
                              value
                            )
                          ) : typeof value === 'number' ? (
                            column.indexOf('Percentage') === -1 ? (
                              <Currency amount={value} />
                            ) : (
                              value + '%'
                            )
                          ) : (
                            value
                          )}
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </HTMLTable>
          )}
        </div>
      </Drawer>
    </Fragment>
  ) : (
    <NonIdealState
      icon="timeline-bar-chart"
      title="No Transactions Available"
      description="Once transactions are available they will be displayed here."
    />
  )
}

export default Transactions
