import { Button, MenuItem, Position } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { format, getYear, setMonth } from 'date-fns'
import React, { Fragment } from 'react'

const getMonthNameFromIndex = index =>
  format(setMonth(new Date(), index), 'MMMM')

//  [
//    { key: 'JANUARY', name: 'January', index: 0 },
//    { key: 'FEBRUARY', name: 'February', index: 1 },
//    ...,
//  ]
const MONTHS = []
while (Object.values(MONTHS).length < 12) {
  const index = Object.values(MONTHS).length
  const name = getMonthNameFromIndex(index)
  const key = name.toUpperCase()
  MONTHS.push({
    key,
    name,
    index,
  })
}

const MonthAndYearSelector = ({
  monthIndex,
  onChangeMonth,
  year,
  onChangeYear,
  minimal = false,
  disabled = false,
}) => {
  const currentSelectedMonth = MONTHS[monthIndex]
  const now = new Date()
  const lastFiveYears = [
    getYear(now),
    getYear(now) - 1,
    getYear(now) - 2,
    getYear(now) - 3,
    getYear(now) - 4,
  ]
  const currentSelectedYear = year

  return (
    <Fragment>
      <Select
        icon={'calendar'}
        filterable={false}
        items={MONTHS}
        itemRenderer={month => {
          return (
            <MenuItem
              key={month.key}
              onClick={() => {
                onChangeMonth(month.index)
              }}
              text={month.name}
              icon={
                currentSelectedMonth.index === month.index ? 'tick' : 'blank'
              }
              shouldDismissPopover={false}
            />
          )
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: 'window',
        }}
      >
        <Button
          rightIcon="double-caret-vertical"
          disabled={disabled}
          minimal={minimal}
        >
          {currentSelectedMonth.name}
        </Button>
      </Select>
      <Select
        icon={'calendar'}
        filterable={false}
        items={lastFiveYears}
        itemRenderer={year => {
          return (
            <MenuItem
              key={year}
              onClick={() => {
                onChangeYear(year)
              }}
              text={year}
              icon={currentSelectedYear === year ? 'tick' : 'blank'}
              shouldDismissPopover={false}
            />
          )
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: 'window',
        }}
      >
        <Button
          rightIcon="double-caret-vertical"
          disabled={disabled}
          minimal={minimal}
        >
          {currentSelectedYear}
        </Button>
      </Select>
    </Fragment>
  )
}

export default MonthAndYearSelector
