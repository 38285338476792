import { Drawer, DrawerSize, Spinner } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { Fragment, useState } from 'react'
import { editBusinessSegmentStructure } from '../../../validation/business-segment'
import EditSegmentForm from './EditSegmentForm'
import EDIT_SEGMENT from './mutations/editSegment.mutation'
import GET_SEGMENT from './queries/getSegment.query'
import * as yup from 'yup'
import { setKeys } from '../../../utils/helpers'
import shortid from '@utils/shortid'
import GET_SEGMENTS from './queries/getSegments.query'
import EditCategorySegmentForm from './EditCategorySegmentForm'
import { BusinessSegmentType } from '../../../types.generated'
import { useMutation } from '@apollo/client'
import { omit } from 'lodash'

const BLANK_INTERVAL = {
  start: { day: '1', time: '00:00' },
  end: { day: '7', time: '23:59' },
  key: shortid.generate(),
}

const EditSegmentDrawer = ({ id, isOpen, marketplaceId, onClose }) => {
  const [loading, setLoading] = useState(false)

  const [editSegment] = useMutation(EDIT_SEGMENT, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: () => {
      setLoading(false)
      onClose()
      successToast(`Segment Updated Successfully`)
    },
    refetchQueries: [
      {
        query: GET_SEGMENT,
        variables: {
          id,
        },
      },
      {
        query: GET_SEGMENTS,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  if (loading) return <Spinner />

  return (
    <Drawer
      title="Edit Business Segment"
      isOpen={isOpen}
      size={DrawerSize.STANDARD}
      onClose={() => onClose()}
    >
      <Query query={GET_SEGMENT} variables={{ id }}>
        {({ getBusinessSegment: segment }) => {
          if (!segment) {
            return null
          }

          return (
            <Fragment>
              {segment && (
                <Formik
                  initialValues={{
                    ...segment,
                    limitedAvailability:
                      segment.availabilityEndDate &&
                      segment.availabilityStartDate
                        ? 'SCHEDULED'
                        : 'ALWAYS_AVAILABLE',
                    availabilityTimes:
                      segment.availabilityTimes &&
                      segment.availabilityTimes.length
                        ? setKeys(segment.availabilityTimes)
                        : [BLANK_INTERVAL],
                    availabilityStartDate: segment.availabilityStartDate
                      ? new Date(segment.availabilityStartDate)
                      : null,
                    availabilityEndDate: segment.availabilityEndDate
                      ? new Date(segment.availabilityEndDate)
                      : null,
                    showFeaturedFlags: segment.showFeaturedFlags,
                    showDiscountLabels: segment.showDiscountLabels,
                    openStatusFilter: segment.openStatusFilter
                      ? segment.openStatusFilter
                      : 'ALL',
                    outletLimit: segment.outletLimit ? segment.outletLimit : 20,
                    // LINK CARDS
                    linkCards: segment.businessSegmentLinkCards.map(
                      segmentCard => {
                        const card = {
                          ...segmentCard.linkCard,
                          position: segmentCard.position,
                        }

                        return card
                      }
                    ),
                    categories:
                      segment.type === BusinessSegmentType.Categories
                        ? segment.businessSegmentCategories.map(
                            segmentCat => segmentCat.category
                          )
                        : [],
                    groups:
                      segment.type === BusinessSegmentType.Outlets
                        ? segment.groups
                        : [],
                  }}
                  onSubmit={async values => {
                    const linkCards = values.linkCards.map(linkCard =>
                      omit(linkCard, ['createdAt', 'menuItem', 'outlet'])
                    )
                    values.categories = values.categories.map(category => ({
                      id: category.id,
                    }))

                    if (values.limitedAvailability === 'ALWAYS_AVAILABLE') {
                      values.availabilityEndDate = null
                      values.availabilityStartDate = null
                    }
                    if (values.orderBy === '') {
                      values.orderBy = null
                    }
                    if (values.linkCardOrderBy === '') {
                      values.linkCardOrderBy = null
                    }

                    values.groups.map(group =>
                      group.filters.map(filter => {
                        delete filter.value
                        return filter
                      })
                    )

                    values.showFeaturedFlags =
                      typeof values.showFeaturedFlags === 'boolean'
                        ? values.showFeaturedFlags
                        : values.showFeaturedFlags[0] === 'on'

                    values.showDiscountLabels =
                      typeof values.showDiscountLabels === 'boolean'
                        ? values.showDiscountLabels
                        : values.showDiscountLabels[0] === 'on'

                    values.openStatusFilter =
                      values.openStatusFilter === 'ALL'
                        ? null
                        : values.openStatusFilter

                    await editSegment({
                      variables: {
                        ...values,
                        linkCards: linkCards
                          .filter(card => !card.disabled) // remove outlet placeholders
                          .map(card => {
                            // need to remove cause errors after adding a new card directly in the form
                            delete card.segments
                            return card
                          }),
                      },
                    })
                  }}
                  validationSchema={yup
                    .object()
                    .shape(editBusinessSegmentStructure)}
                >
                  {props => {
                    return (
                      <Fragment>
                        {segment.type === BusinessSegmentType.Outlets && (
                          <EditSegmentForm
                            {...props}
                            marketplaceId={marketplaceId}
                          />
                        )}
                        {segment.type === BusinessSegmentType.Categories && (
                          <EditCategorySegmentForm
                            {...props}
                            marketplaceId={marketplaceId}
                            categoryEmojiOrImage={
                              segment.marketplace.categoryEmojiOrImage
                            }
                          />
                        )}
                      </Fragment>
                    )
                  }}
                </Formik>
              )}
            </Fragment>
          )
        }}
      </Query>
    </Drawer>
  )
}

export default EditSegmentDrawer
