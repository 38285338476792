import { useQuery } from '@apollo/client'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import { startCase } from 'lodash'
import Helmet from 'react-helmet'
import GET_RESTAURANT_NAME from './queries/getRestaurantName.query'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const RestaurantCrumbs = ({ crumbs }) => {
  const { data } = useQuery(GET_RESTAURANT_NAME, {
    skip: !crumbs[1],
    variables: { id: crumbs[1] },
  })
  const businessName = data?.getRestaurants.restaurants[0]?.name
  return (
    <>
      <Helmet>
        <title>
          {`${businessName ? businessName : 'Business'}${
            crumbs[2] ? ` - ${startCase(crumbs[2])} ` : ''
          } | Redbox Platform`}
        </title>
      </Helmet>
      {crumbs.map((crumb, i) => {
        if (isSecondPart(i)) {
          if (!data) return null
          return (
            <>
              <Crumb
                key={crumb}
                text={businessName ? businessName : crumb}
                link={`/business/${crumb}`}
              />
            </>
          )
        }

        return (
          <Crumb
            key={crumb}
            text={crumb}
            link={
              isThirdPart(i) ? `/business/${crumbs[1]}/${crumb}` : `/${crumb}`
            }
          />
        )
      })}
    </>
  )
}

RestaurantCrumbs.propTypes = {
  crumbs: (props, propName, componentName) => {
    const crumbs = props[propName]
    if (!Array.isArray(crumbs)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array recived ${typeof crumbs}`
      )
    }
    if (crumbs.length !== 3) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array of length 3`
      )
    }
  },
}

export default RestaurantCrumbs
