import gql from 'graphql-tag'

const GET_PAYMENT_METHODS = gql`
  query getOutletsAllowedPaymentMethods($id: String!) {
    allowedPaymentMethodsOnOutlet(id: $id) {
      paymentMethods
    }
  }
`
export default GET_PAYMENT_METHODS
