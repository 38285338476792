import { Spinner, Button, MenuItem } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'

const TypeaheadSearchSingle = ({
  initialSelectedItems,
  items,
  selected,
  setSelected,
  usingLiveData,
  onQueryChange,
  disabled = false,
  placeholder = 'Search...',
  noResults = 'No matches',
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    usingLiveData && setLoading(false)
  }, [usingLiveData])

  const isSelected = item => selected.includes(item.id)
  const ItemRenderer = useCallback(
    (item, { modifiers, handleClick }) => {
      if (!modifiers.matchesPredicate) {
        return null
      }

      return (
        <MenuItem
          active={modifiers.active}
          icon={isSelected(item) ? 'tick' : 'blank'}
          key={item.id}
          label={item.label || ''}
          onClick={handleClick}
          text={item.name}
          shouldDismissPopover={false}
        />
      )
    },
    [isSelected, selected]
  )

  return (
    <Suggest
      onQueryChange={query => {
        !loading && setLoading(true)
        onQueryChange(query)
      }}
      items={items}
      disabled={disabled}
      itemRenderer={ItemRenderer}
      itemPredicate={(query, item) =>
        // this allows us to filter on cached data while refetching
        item.name.toLowerCase().includes(query.toLowerCase())
      }
      onItemSelect={item => {
        setSelected([isSelected(item) ? null : item.id])
      }}
      inputValueRenderer={itemId => {
        const item = initialSelectedItems.find(({ id }) => id === itemId)

        return item ? item.name : ''
      }}
      itemsEqual={(a, b) => a.id === b.id}
      inputProps={{
        placeholder,
        rightElement: loading ? (
          <Spinner size={20} />
        ) : (
          <Button
            disabled={disabled}
            minimal
            icon="cross"
            onClick={() => setSelected([])}
          />
        ),
        leftIcon: 'search',
      }}
      selectedItem={selected[0]}
      noResults={
        <MenuItem
          icon="warning-sign"
          label={loading ? 'Loading...' : noResults}
          disabled
        />
      }
      resetOnClose
    />
  )
}

export default TypeaheadSearchSingle
