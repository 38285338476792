import gql from 'graphql-tag'

const ALLOW_FACEBOOK_MARKETING = gql`
  mutation allowFacebookMarketing(
    $id: String!
    $allowFacebookMarketing: Boolean!
  ) {
    editMarketplace(id: $id, allowFacebookMarketing: $allowFacebookMarketing) {
      message
      marketplace {
        id
        allowFacebookMarketing
      }
    }
  }
`

export default ALLOW_FACEBOOK_MARKETING
