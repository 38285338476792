import { createContext, useState } from 'react'
import AddDeliveryNetworkModal from '@components/DeliveryNetwork/AddDeliveryNetwork.modal'
import AddPOSDeviceModal from '@components/Marketplace/POS/AddPOSDevice.modal'
import AddDeliveryZoneDrawer from '@components/DeliveryZone/AddDeliveryZone.drawer'
import AddFAQDrawer from '@components/Marketplace/Support/FAQDrawer'
import CreateVoucherDrawer from '@components/Marketplace/MarketplaceVouchers/CreateVoucherDrawer'
import { mapValues, invert } from 'lodash'
import AddSegmentModal from '../Segments/AddSegmentModal'
import AddLinkCardModal from '../LinkCards/AddLinkCardModal'
import CreateLoyaltyCardDrawer from '../../Loyalty/CreateLoyaltyCardDrawer'
import { useParams } from 'react-router-dom'

export const OVERLAYS = {
  ADD_DELIVERY_NETWORK: 'addDeliveryNetwork',
  ADD_POS_DEVICE: 'addPOSDevice',
  ADD_DELIVERY_ZONE: 'addDeliveryZone',
  ADD_FAQ: 'addFAQ',
  CREATE_VOUCHER: 'createVoucher',
  ADD_SEGMENT: 'addSegment',
  ADD_LINK_CARD: 'addLinkCard',
  CREATE_LOYALTY_CARD: 'createLoyaltyCard',
}

// creates an object with all the overlays set to false
const DEFAULT_OVERLAYS_STATE = mapValues(invert(OVERLAYS), () => false)

export const OuterLayoutOverlayContext = createContext({
  overlays: {},
  openOverlay: _overlayName => {},
  closeOverlay: _overlayName => {},
})

// provides context which can be used to open and close modals / drawers using the button at the
// top right out the page layout
export const OuterLayoutOverlayContextProvider = ({ children }) => {
  const [overlays, setOverlays] = useState(DEFAULT_OVERLAYS_STATE)

  const openOverlay = overlayName => {
    setOverlays({
      ...overlays,
      [overlayName]: true,
    })
  }

  const closeOverlay = overlayName => {
    setOverlays({
      ...overlays,
      [overlayName]: false,
    })
  }

  const { marketplace } = useParams()

  return (
    <OuterLayoutOverlayContext.Provider
      value={{ overlays, openOverlay, closeOverlay }}
    >
      {children}
      <AddDeliveryNetworkModal
        modalOpen={overlays[OVERLAYS.ADD_DELIVERY_NETWORK]}
        modalClose={() => {
          closeOverlay(OVERLAYS.ADD_DELIVERY_NETWORK)
        }}
        marketplaceId={marketplace}
      />
      <AddPOSDeviceModal
        modalOpen={overlays[OVERLAYS.ADD_POS_DEVICE]}
        modalClose={() => {
          closeOverlay(OVERLAYS.ADD_POS_DEVICE)
        }}
        marketplaceId={marketplace}
      />
      <AddDeliveryZoneDrawer
        modalOpen={overlays[OVERLAYS.ADD_DELIVERY_ZONE]}
        modalClose={() => {
          closeOverlay(OVERLAYS.ADD_DELIVERY_ZONE)
        }}
        marketplaceId={marketplace}
      />
      <AddFAQDrawer
        isOpen={overlays[OVERLAYS.ADD_FAQ]}
        marketplaceId={marketplace}
        drawerClose={() => {
          closeOverlay(OVERLAYS.ADD_FAQ)
        }}
      />
      <CreateVoucherDrawer
        isOpen={overlays[OVERLAYS.CREATE_VOUCHER]}
        marketplaceId={marketplace}
        drawerClose={() => {
          closeOverlay(OVERLAYS.CREATE_VOUCHER)
        }}
      />
      <AddSegmentModal
        modalOpen={overlays[OVERLAYS.ADD_SEGMENT]}
        marketplaceId={marketplace}
        modalClose={() => {
          closeOverlay(OVERLAYS.ADD_SEGMENT)
        }}
      />
      <AddLinkCardModal
        modalOpen={overlays[OVERLAYS.ADD_LINK_CARD]}
        marketplaceId={marketplace}
        modalClose={() => {
          closeOverlay(OVERLAYS.ADD_LINK_CARD)
        }}
        onSuccess={undefined}
      />
      <CreateLoyaltyCardDrawer
        isOpen={overlays[OVERLAYS.CREATE_LOYALTY_CARD]}
        marketplaceId={marketplace}
        drawerClose={() => {
          closeOverlay(OVERLAYS.CREATE_LOYALTY_CARD)
        }}
      />
    </OuterLayoutOverlayContext.Provider>
  )
}
