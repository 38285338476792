import gql from 'graphql-tag'

const ONBOARDING_DENY = gql`
  mutation onboardingDeny($id: String!, $denyReason: String) {
    onboardingDeny(id: $id, denyReason: $denyReason) {
      message
      restaurant {
        id
        name
      }
    }
  }
`

export default ONBOARDING_DENY
