import { Fragment } from 'react'
import {
  Callout,
  Card,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'

import { useTheme } from './hooks/useTheme'
import { NoTheme } from './NoTheme'
import { ThemeBuilder } from './ThemeBuilder/ThemeBuilder'
import { first } from 'lodash'
import GET_MARKETPLACE_BRAND_COLOURS from './queries/getMarketplaceBrandColors'
import Query from '@components/Query/Query'
import { isAtLeastPartner } from '@stores/userStore'
import { useParams } from 'react-router-dom'

const HandleThemeState = ({
  state: { theme, loading, error, newTheme = false },
  actions: { createNewTheme },
  marketplaceId,
}) => {
  if (loading) {
    return <NonIdealState icon={<Spinner size={60} />} />
  }

  if (error) {
    return <NonIdealState icon="issue" description="Error loading theme" />
  }

  if (theme) {
    return (
      <ThemeBuilder
        theme={theme}
        marketplaceId={marketplaceId}
        newTheme={newTheme}
        onResetTheme={() => createNewTheme()}
      />
    )
  }

  return (
    <Query
      query={GET_MARKETPLACE_BRAND_COLOURS}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Theme'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        const brandColor = marketplace.brandColor
        const brandSecondaryColor = marketplace.brandSecondaryColor

        return (
          <NoTheme
            createNewTheme={() =>
              createNewTheme(brandColor, brandSecondaryColor)
            }
          />
        )
      }}
    </Query>
  )
}

export const WebsiteTheme = () => {
  const { marketplace: marketplaceId } = useParams()
  const [state, actions] = useTheme({
    marketplaceId,
  })

  return (
    <Fragment>
      {!isAtLeastPartner() && (
        <Callout
          style={{ margin: '0 0 20px 0' }}
          icon="blocked-person"
          intent={Intent.PRIMARY}
        >
          You do not have permission to change these settings.
        </Callout>
      )}

      <Card>
        <HandleThemeState
          state={state}
          actions={actions}
          marketplaceId={marketplaceId}
        />
      </Card>
    </Fragment>
  )
}
