import {
  Button,
  ControlGroup,
  FormGroup,
  InputGroup,
  Intent,
  H5,
  Divider,
  TextArea,
} from '@blueprintjs/core'
import ColorPicker from '@components/ColorPicker/ColorPicker'
import React, { useEffect } from 'react'
import ImageUpload from '../../ImageUpload/ImageUpload'

const InnerForm = ({
  values,
  handleChange,
  errors,
  setFieldValue,
  handleSubmit,
  refetch,
  status,
  setStatus,
}) => {
  useEffect(() => {
    refetch({ ...values })
  }, [refetch, values])

  const submitTriggered = () => {
    values.uploadInProgress = false
    handleSubmit()
  }

  return (
    <form onSubmit={submitTriggered}>
      <H5>Theme Settings</H5>
      <FormGroup
        label="Header Image"
        labelInfo="(required)"
        helperText={errors.emailImageUrl}
        intent={Intent.DANGER}
      >
        <ControlGroup>
          <Button
            disabled={!values.emailShowImage && !values.emailShowCustomLogo}
            onClick={() => {
              setFieldValue('emailUploadImage', false)
              setFieldValue('emailShowImage', false)
              setFieldValue('emailShowCustomLogo', false)
              setFieldValue('uploadInProgress', false)
            }}
          >
            No image
          </Button>
          <Button
            disabled={values.emailShowImage && !values.emailShowCustomLogo}
            onClick={() => {
              setFieldValue('emailUploadImage', false)
              setFieldValue('emailShowImage', true)
              setFieldValue('emailShowCustomLogo', false)
              setFieldValue('emailImageUrl', values.logoImage)
              setFieldValue('uploadInProgress', false)
            }}
          >
            Marketplace Logo
          </Button>
          <Button
            disabled={values.emailShowCustomLogo}
            onClick={() => {
              setFieldValue('uploadInProgress', true)
              setFieldValue('emailImageUrl', '')
              setFieldValue('emailUploadImage', false)
              setFieldValue('emailShowImage', true)
              setFieldValue('emailShowCustomLogo', true)
            }}
          >
            Custom Logo
          </Button>
        </ControlGroup>
        {values.emailUploadImage ? (
          <InputGroup
            name="emailImageUrl"
            type="text"
            value={values.emailImageUrl}
            onChange={handleChange}
          />
        ) : null}
      </FormGroup>
      {values.emailShowCustomLogo && (
        <FormGroup labelFor="emailCustomImage">
          <ImageUpload
            values={values}
            setFieldValue={setFieldValue}
            imageName="emailImageUrl"
            imageLabel="Custom Logo"
            status={status}
            setStatus={setStatus}
          />
        </FormGroup>
      )}
      <FormGroup
        label="Background Colour"
        labelInfo="(required)"
        helperText={errors.emailBackgroundColor}
        intent={Intent.DANGER}
      >
        <ColorPicker
          name="emailBackgroundColor"
          type="text"
          value={values.emailBackgroundColor}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup
        label="Button Colour"
        labelInfo="(required)"
        helperText={errors.emailButtonColor}
        intent={Intent.DANGER}
      >
        <ColorPicker
          name="emailButtonColor"
          type="text"
          value={values.emailButtonColor}
          onChange={handleChange}
        />
      </FormGroup>
      <Divider />
      <FormGroup
        label="Body Colour"
        labelInfo="(required)"
        helperText={errors.emailBodyColor}
        intent={Intent.DANGER}
      >
        <ColorPicker
          name="emailBodyColor"
          type="text"
          value={values.emailBodyColor}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup
        label="Body Font Colour"
        labelInfo="(required)"
        helperText={errors.emailFontColor}
        intent={Intent.DANGER}
      >
        <ColorPicker
          name="emailFontColor"
          type="text"
          value={values.emailFontColor}
          onChange={handleChange}
        />
      </FormGroup>
      <Divider />

      <FormGroup
        label="Footer Text"
        labelInfo="(required)"
        helperText={errors.emailFooter}
        intent={Intent.DANGER}
      >
        <TextArea
          fill
          value={values.emailFooter}
          growVertically
          name={`emailFooter`}
          onChange={handleChange}
          style={{ padding: 6, height: 30 }}
        />
      </FormGroup>
      <FormGroup
        label="Footer Colour"
        labelInfo="(required)"
        helperText={errors.emailFooterColor}
        intent={Intent.DANGER}
      >
        <ColorPicker
          name="emailFooterColor"
          type="text"
          value={values.emailFooterColor}
          onChange={handleChange}
        />
      </FormGroup>
      <br />
      <div className="bp-card-footer-actions">
        <Button type="submit">Save</Button>
      </div>
    </form>
  )
}

export default InnerForm
