import { Button, Popover, Position } from '@blueprintjs/core'
import { DateRangePicker } from '@components/Toolbar/DateRangeFilter/DateRangePicker' // Replace the import statement
import {
  add,
  isFuture,
  sub,
  endOfDay,
  startOfWeek,
  endOfWeek,
  format,
  getISOWeek,
  min,
} from 'date-fns'
import { Fragment } from 'react'
import { ISO_WEEK_DAY, YEARLESS_FORMAT } from '../../../utils/datetime'

const WeekSelector = ({
  startOfWeekDate,
  startOfWeekDateToDisplay,
  onChangeStartOfWeekDate,
  endOfWeekDateToDisplay,
  minimal = false,
  disabled = false,
}) => (
  <Fragment>
    <Button
      rightIcon="caret-left"
      disabled={disabled}
      minimal={minimal}
      onClick={() => {
        onChangeStartOfWeekDate(sub(startOfWeekDate, { weeks: 1 }))
      }}
    />
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <DateRangePicker
          value={[
            startOfWeekDate,
            min([
              endOfWeek(startOfWeekDate, { weekStartsOn: ISO_WEEK_DAY.MONDAY }),
              endOfDay(new Date()),
            ]),
          ]}
          onChange={([selectedDate]) => {
            if (selectedDate) {
              onChangeStartOfWeekDate(
                startOfWeek(selectedDate, { weekStartsOn: ISO_WEEK_DAY.MONDAY })
              )
            }
          }}
        />
      }
    >
      <Button
        disabled={disabled}
        minimal={minimal}
        rightIcon="double-caret-vertical"
      >
        {format(startOfWeekDateToDisplay, YEARLESS_FORMAT)}
        {'  '}
        <span className="bp5-text-disabled">to</span>
        {'  '}
        {format(endOfWeekDateToDisplay, YEARLESS_FORMAT)}
        {' - '}
        <span className="bp5-text-disabled">
          Week {getISOWeek(startOfWeekDate)}
        </span>
      </Button>
    </Popover>
    <Button
      rightIcon="caret-right"
      minimal={minimal}
      onClick={() => {
        onChangeStartOfWeekDate(
          min([add(startOfWeekDate, { weeks: 1 }), new Date()])
        )
      }}
      disabled={disabled || isFuture(add(startOfWeekDate, { weeks: 1 }))}
    />
  </Fragment>
)

export default WeekSelector
