import React, { useEffect, useRef } from 'react'

interface AudioPlayerProps {
  src: string
  repeatInterval: number // Interval in milliseconds
}

export const TWO_MINUTES_AS_MILISECONDS = 1000 * 120

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, repeatInterval }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const intervalRef = useRef<number | null>(null)

  useEffect(() => {
    const playSound = () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0 // Rewind to the beginning
        void audioRef.current.play().catch(console.error)
      }
    }

    // Set the interval to play the sound repeatedly
    intervalRef.current = window.setInterval(playSound, repeatInterval)

    // Cleanup interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [repeatInterval])

  return <audio ref={audioRef} src={src} preload="auto" />
}

export default AudioPlayer
