import gql from 'graphql-tag'

// adds user cards
export const MUTATION_RELOCATE = gql`
  mutation relocateAddress($id: String!) {
    relocateAddress(addressId: $id) {
      message
      address {
        id
        firstLine
        secondLine
        thirdLine
        city
        country
        postcode
      }
    }
  }
`
