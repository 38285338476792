import React from 'react'
import Dialog from '@components/Dialog/Dialog'
import {
  DIALOG_BODY,
  DIALOG_FOOTER_ACTIONS,
} from '@blueprintjs/core/lib/esm/common/classes'
import { Button, Intent } from '@blueprintjs/core'

const ConfirmationModal = ({
  modalOpen,
  confirm,
  cancel,
  message = <p>Are you sure you want to send this campaign?</p>,
  actionName = 'Send',
}) => (
  <Dialog isOpen={modalOpen} onClose={cancel} title={`${actionName} Campaign`}>
    <div className={DIALOG_BODY}>
      {message}
      <div className={DIALOG_FOOTER_ACTIONS}>
        <Button onClick={cancel}>Cancel</Button>
        <Button intent={Intent.PRIMARY} onClick={confirm}>
          {actionName}
        </Button>
      </div>
    </div>
  </Dialog>
)

export default ConfirmationModal
