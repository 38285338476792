import gql from 'graphql-tag'

const ARCHIVE_MENU_ITEM = gql`
  mutation archiveMenuItem($id: String!) {
    archiveMenuItem(id: $id) {
      message
      menuItem {
        id
      }
    }
  }
`

export default ARCHIVE_MENU_ITEM
