import GET_CURRENCY from '@components/Currency/queries/getCurrency.query'
import Query from '@components/Query/Query'
import currencySymbols from '@utils/currencyMap'
import { penceToPounds } from '@utils/helpers'
import { Fragment, memo } from 'react'

interface CurrencyProps {
  amount?: number | null
  currency?: string
  amountInPence?: boolean
  className?: string
  style?: React.CSSProperties
}

const Currency: React.FC<CurrencyProps> = ({
  amount,
  currency,
  amountInPence = true,
  className,
  style,
}) => {
  const formatCurrency = (
    currencyObj = {
      thousandsSeparator: ',',
      decimalSeparator: '.',
      prefix: '',
      suffix: '',
    }
  ) => {
    const numberValue = amountInPence ? penceToPounds(amount || 0) : amount || 0
    const [integerPart, decimalPart = '00'] = numberValue.toString().split('.')

    const formattedInteger = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      currencyObj.thousandsSeparator || ','
    )

    return `${formattedInteger}${
      currencyObj.decimalSeparator || '.'
    }${decimalPart}`
  }

  return !currency ? (
    <span className={className} style={style}>
      <Query query={GET_CURRENCY} showLoader={false}>
        {({ getCurrency: { currency: queryCurrency } }) => (
          <>
            {/* Used for display of currency in text input fields ect. */}
            {amount != undefined
              ? `${queryCurrency.prefix}${formatCurrency(queryCurrency)}${
                  queryCurrency.suffix
                }`
              : `${queryCurrency.symbol}`}
          </>
        )}
      </Query>
    </span>
  ) : (
    <span className={className} style={style}>
      {currencySymbols[currency.toString().toUpperCase()] || ''}
      {formatCurrency()}
    </span>
  )
}

export default memo(Currency)
