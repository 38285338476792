import { Button, Popover, Position } from '@blueprintjs/core'
import { DatePicker3 } from '@blueprintjs/datetime2'
import { add, endOfDay, format, sub } from 'date-fns'
import { isFuture } from 'date-fns/esm'
import { Fragment } from 'react'
import { YEARLESS_TIMELESS_FORMAT } from '../../../utils/datetime'

const DaySelector = ({
  date,
  dateToDisplay,
  onChangeDate,
  minimal = false,
  disabled = false,
}) => (
  <Fragment>
    <Button
      rightIcon="caret-left"
      disabled={disabled}
      minimal={minimal}
      onClick={() => {
        onChangeDate(sub(date, { days: 1 }))
      }}
    />
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <DatePicker3
          value={date}
          maxDate={endOfDay(new Date())}
          highlightCurrentDay
          onChange={(selectedDate, isUserChange) => {
            if (selectedDate && isUserChange) {
              onChangeDate(selectedDate)
            }
          }}
        />
      }
    >
      <Button
        disabled={disabled}
        minimal={minimal}
        rightIcon="double-caret-vertical"
      >
        {format(dateToDisplay, YEARLESS_TIMELESS_FORMAT)}
      </Button>
    </Popover>

    <Button
      rightIcon="caret-right"
      minimal={minimal}
      onClick={() => {
        onChangeDate(add(date, { days: 1 }))
      }}
      disabled={disabled || isFuture(add(date, { days: 1 }))}
    />
  </Fragment>
)

export default DaySelector
