import React, { Fragment, useState } from 'react'
import { Formik, FieldArray } from 'formik'
import {
  Button,
  InputGroup,
  FormGroup,
  Radio,
  RadioGroup,
  HTMLTable,
  Classes,
  Intent,
  TextArea,
  Checkbox,
  Card,
  MenuItem,
  Switch,
  Alert,
} from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'
import { DateRangeInput3 } from '@blueprintjs/datetime2'
import moment from 'moment'
import { object, func, string } from 'prop-types'
import shortid from '@utils/shortid'
import words from 'lodash/words'
import capitalize from 'lodash/capitalize'
import { checkForBlankInterval, setKeys } from '@utils/helpers'
import { validation } from '@components/Restaurant/Menu/validation/menuFormValidation'
import AvailabilityTimeSelect from './AvailabilityTimeSelect'
import Query from '@components/Query/Query'
import { GET_ALLOW_ADD_ON_ITEM_MENUS } from '../queries/getAllowAddOnItemMenus.query'
import { get } from 'lodash'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import Currency from '@components/Currency/Currency'

const capitalizeFirstLetters = wordsStr =>
  words(wordsStr).map(capitalize).join(' ')

const fulfillmentMethods = [
  { id: 'COLLECTION', label: 'Collection' },
  { id: 'DELIVERY', label: 'Delivery' },
  { id: 'TABLE', label: 'Table' },
]
const renderFulfillmentMethodMenuItem = (
  fulfillmentMethod,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      key={fulfillmentMethod.id}
      label={fulfillmentMethod.label}
      text={fulfillmentMethod.label}
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
    />
  )
}

const updateFulfillmentMethods =
  (setFieldValue, preselectedValues) => selectedFulfillmentMethod => {
    const updatedValues = preselectedValues.find(
      preselectedValue => preselectedValue === selectedFulfillmentMethod.id
    )
      ? preselectedValues.filter(
          preselectedValue => preselectedValue !== selectedFulfillmentMethod.id
        )
      : [...preselectedValues, selectedFulfillmentMethod.id]
    return setFieldValue('fulfillmentMethods', updatedValues)
  }

const BLANK_INTERVAL = {
  start: { day: '1', time: '00:00' },
  end: { day: '7', time: '23:59' },
  key: shortid.generate(),
}

const BLANK_MENU = {
  name: '',
  description: '',
  image: '',
  fulfillmentMethods: fulfillmentMethods.map(({ id }) => id),
  availabilityTimes: [BLANK_INTERVAL],
  availabilityStartDate: null,
  availabilityEndDate: null,
}

const MenuForm = ({
  menu = BLANK_MENU,
  mutation,
  restaurantId,
  menuId,
  position,
}) => {
  const [showAddOnPopover, setShowAddOnPopover] = useState(false)

  return (
    <Formik
      initialValues={{
        ...menu,
        limitedAvailability:
          menu.availabilityEndDate && menu.availabilityStartDate
            ? 'true'
            : 'false',
        availabilityTimes: menu.availabilityTimes
          ? setKeys(menu.availabilityTimes)
          : [BLANK_INTERVAL],
        availabilityStartDate: menu.availabilityStartDate
          ? new Date(menu.availabilityStartDate)
          : null,
        availabilityEndDate: menu.availabilityEndDate
          ? new Date(menu.availabilityEndDate)
          : null,
        addOnItemsMenu: menu.addOnItemsMenu,
      }}
      onSubmit={values => {
        if (values.limitedAvailability === 'false') {
          values.availabilityStartDate = null
          values.availabilityEndDate = null
        }
        mutation({
          variables: {
            ...values,
            restaurantId,
            parentMenuId: menuId,
            position,
            addOnItemsMenu: !!values.addOnItemsMenu,
          },
        })
      }}
      validationSchema={validation}
      validateOnBlur={true}
    >
      {props => {
        const {
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setStatus,
          errors,
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup
              label="Name"
              labelFor="name"
              helperText={errors.name ? errors.name : ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                name="name"
                onChange={handleChange}
                value={values.name}
                autoFocus
              />
            </FormGroup>
            <FormGroup
              label="Description"
              labelFor="description"
              helperText={errors.description ? errors.description : ''}
              intent={Intent.DANGER}
            >
              <TextArea
                name="description"
                onChange={handleChange}
                value={values.description}
                fill={true}
              />
            </FormGroup>
            <Checkbox
              label={'Show Menu Item Thumbnails'}
              checked={values.showMenuThumbnails}
              onChange={handleChange}
              name="showMenuThumbnails"
            />
            <Query
              query={GET_ALLOW_ADD_ON_ITEM_MENUS}
              variables={{ id: restaurantId }}
            >
              {data => {
                const allowAddOnItems = get(
                  data,
                  'getRestaurants.restaurants[0].allowAddOnItems'
                )
                return allowAddOnItems ? (
                  <FormGroup helperText="Items on an ‘add-on sale’ menu will not show alongside the normal menus in the app and web. Customers will be offered items on this menu at checkout.">
                    <Switch
                      label={"'Add-on Sale' Menu"}
                      defaultChecked={values.addOnItemsMenu}
                      onChange={e => {
                        if (e.target.checked) setShowAddOnPopover(true)
                        handleChange(e)
                      }}
                      name="addOnItemsMenu"
                    />
                    <Alert
                      isOpen={showAddOnPopover}
                      onClose={() => setShowAddOnPopover(false)}
                    >
                      Some items may have minimum purchase price set to{' '}
                      <Currency amount={0} />. Make sure to check prices of
                      items in this menu after switching it to 'add-on sale'.
                    </Alert>
                  </FormGroup>
                ) : null
              }}
            </Query>
            <br />
            <ImageUploadDirectSingle
              imageName={'image'}
              imageLabel={'Menu Cover Image'}
              values={values}
              setFieldValue={(field, value) =>
                setFieldValue(field, value ? value : '')
              }
              setStatus={setStatus}
              sizeLimit={1000000}
              replace={true}
              showImagePickerDialog={false}
            />
            <FormGroup
              label="Fulfillment"
              labelFor="fulfillmentMethods"
              helperText={
                errors.fulfillmentMethods ? errors.fulfillmentMethods : ''
              }
              intent={Intent.DANGER}
            >
              <MultiSelect
                name="fulfillmentMethods"
                items={fulfillmentMethods}
                tagRenderer={capitalizeFirstLetters}
                selectedItems={values.fulfillmentMethods}
                itemRenderer={renderFulfillmentMethodMenuItem}
                onItemSelect={updateFulfillmentMethods(
                  setFieldValue,
                  values.fulfillmentMethods
                )}
                tagInputProps={{
                  placeholder: 'Select at least one fulfillment method',
                  onRemove: fulfillmentMethodLabel => {
                    const fulfillmentMethodToBeRemoved =
                      fulfillmentMethods.find(
                        ({ label }) => label === fulfillmentMethodLabel
                      )
                    return updateFulfillmentMethods(
                      setFieldValue,
                      values.fulfillmentMethods
                    )(fulfillmentMethodToBeRemoved)
                  },
                }}
                fill={true}
              />
            </FormGroup>
            <RadioGroup
              name="limitedAvailability"
              label="Menu Availability Date Range"
              inline={false}
              onChange={handleChange}
              selectedValue={values.limitedAvailability}
            >
              <Radio label="Always Available" value="false" />
              <Radio label="Scheduled" value="true" />
            </RadioGroup>
            {values.limitedAvailability === 'true' && (
              <FormGroup
                labelFor="availabilityDates"
                helperText={
                  errors.availabilityStartDate || errors.availabilityEndDate
                    ? errors.availabilityStartDate || errors.availabilityEndDate
                    : 'Only show this menu between certain dates; useful for a special promotions or events.'
                }
                intent={
                  errors.availabilityStartDate || errors.availabilityEndDate
                    ? Intent.DANGER
                    : Intent.NONE
                }
              >
                <DateRangeInput3
                  id="availabilityDates"
                  formatDate={date => moment(date).format('DD/MM/YYYY HH:mm')}
                  allowSingleDayRange={true}
                  closeOnSelection={true}
                  shortcuts={false}
                  onChange={dates => {
                    void setFieldValue(
                      'availabilityStartDate',
                      moment(dates[0]).startOf('day').toDate()
                    )
                    void setFieldValue(
                      'availabilityEndDate',
                      moment(dates[1]).endOf('day').toDate()
                    )
                  }}
                  value={[
                    values.availabilityStartDate,
                    values.availabilityEndDate,
                  ]}
                  parseDate={str => new Date(str)}
                />
              </FormGroup>
            )}
            <br />
            <FormGroup
              labelFor="availabilityTimes"
              label="Please select the availability times for this menu:"
              helperText="Schedule your menu to be available between certain times; useful for mornings, evenings or sunday lunch."
            />
            <FieldArray
              name="availabilityTimes"
              render={({ push, remove }) => (
                <Fragment>
                  <FormGroup>
                    <Card className="bp5-nopad">
                      <HTMLTable bordered={false} interactive={true}>
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>From</th>
                            <th>Day</th>
                            <th>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.availabilityTimes.map((time, index) => (
                            <AvailabilityTimeSelect
                              key={time.key}
                              onChange={handleChange}
                              index={index}
                              availabilityTimes={values.availabilityTimes}
                              errors={errors}
                              remove={remove}
                              fieldName="availabilityTimes"
                              setFieldValue={setFieldValue}
                            />
                          ))}
                        </tbody>
                      </HTMLTable>
                    </Card>
                    <Button
                      text="Add New Ordering Time"
                      minimal={true}
                      icon="plus"
                      intent={Intent.SUCCESS}
                      onClick={() =>
                        checkForBlankInterval(
                          values.availabilityTimes,
                          setFieldError,
                          push,
                          BLANK_INTERVAL,
                          'availabilityTimes'
                        )
                      }
                    />
                  </FormGroup>
                </Fragment>
              )}
            />
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Save" type="submit" />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

MenuForm.propTypes = {
  menu: object,
  values: object,
  handleChange: func,
  setFieldValue: func,
  setFieldError: func,
  errors: object,
  handleSubmit: func,
  mutation: func,
  restaurantId: string,
  menuId: string,
  setStatus: func,
  status: object,
}

export default MenuForm
