export const defaultColors = {
  siteBackground: '#FFFFFF',
  brand: '#434343',
  brandDark: '#F4F4F4',
  brandLight: '#F4F4F4',

  brandHighlight: '#F4F4F4',
  grey: '#C4C4C4',
  darkGrey: '#2F2F2F',
  mainText: '#2E3333',
  darkText: '#2E3333',
  brandForeground: '#7D7D7D',
  danger: '#D51515',
  flag: '#434343',
  discount: '#434343',
  openStatus: '#3EBB42',
  preorderStatus: '#F18432',
}
