import gql from 'graphql-tag'

const ALLOW_BILLING = gql`
  mutation allowBilling($id: String!, $allowBilling: Boolean!) {
    editMarketplace(id: $id, allowBilling: $allowBilling) {
      message
      marketplace {
        id
        allowBilling
      }
    }
  }
`

export default ALLOW_BILLING
