import gql from 'graphql-tag'

export const OPTION_ITEM_FRAGMENT = gql`
  fragment optionItemFieldsAndParentOption on OptionItem {
    id
    name
    price
    description
    isVegan
    isVegetarian
    isGlutenFree
    isDairyFree
    ageRestricted
    parentOption {
      id
      name
    }
  }
`
