import { Component } from 'react'
import isEqual from 'lodash/isEqual'
import { array } from 'prop-types'

import { SecondaryTree } from './Menu.styles'

class ItemMenus extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nodes: this.props.menus,
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.menus, prevProps.menus)) {
      this.setState({
        nodes: this.props.menus,
      })
    }
  }

  handleNodeClick = node => {
    // node.isSelected = true

    if (node.onClick) {
      node.onClickItem()
    }
  }

  render() {
    return (
      <SecondaryTree
        onNodeClick={(node, path) => this.handleNodeClick(node, path)}
        contents={this.state.nodes}
      />
    )
  }
}

ItemMenus.propTypes = {
  menus: array,
}
export default ItemMenus
