import gql from 'graphql-tag'

const GET_ITEM_SALES = gql`
  query getItemSales(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $marketplaceIds: [ID!]
    $partnerIds: [ID!]
    $outletIds: [ID!]
  ) {
    salesItems(
      startDate: $afterDate
      endDate: $beforeDate
      marketplaceIds: $marketplaceIds
      partnerIds: $partnerIds
      outletIds: $outletIds
    ) {
      totalSales
      totalNet
      totalGross
      items {
        outletMenuItemId
        menuItemId
        menuItem
        outlet {
          id
          name
          restaurant {
            id
            name
          }
          marketplace {
            id
            name
          }
        }
        sales
        grossTotal
        netTotal
      }
    }
  }
`

export default GET_ITEM_SALES
