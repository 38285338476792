import { Fragment, useState } from 'react'
import React from 'react'
import { Button, Classes, TextArea, Intent, FormGroup } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'

const EditAsJSON = ({ emailContent, setFieldValue, disabled }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [valid, setValid] = useState(true)
  const [inputValue, setInputValue] = useState('')

  return (
    <Fragment>
      <Button
        icon="code-block"
        onClick={() => {
          setModalOpen(true)
          setInputValue(JSON.stringify(emailContent, null, 2))
        }}
      >
        {disabled ? 'View' : 'Edit'} as JSON
      </Button>
      <Dialog
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={`${disabled ? 'View' : 'Edit'} email as JSON`}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label={'Email content'}
            helperText={valid ? null : 'JSON is invalid'}
            intent={Intent.DANGER}
          >
            <TextArea
              disabled={disabled}
              value={inputValue}
              fill
              growVertically
              onChange={e => {
                const { value } = e.target
                setInputValue(value)

                try {
                  JSON.parse(value)
                  setValid(true)
                } catch (e) {
                  setValid(false)
                }
              }}
            />
          </FormGroup>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button
              disabled={disabled || !valid}
              onClick={() => {
                setFieldValue('emailContent', JSON.parse(inputValue))

                setModalOpen(false)
              }}
            >
              Update Form
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default EditAsJSON
