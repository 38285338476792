import * as yup from 'yup'

export const refundShape = {
  refundReason: yup.string().required('Refund reason is required'),
  refundDescription: yup
    .string()
    .required('Description is required')
    .min(
      20,
      'Must be at least 20 characters, please give a reason for this refund. '
    ),
}

export const refundValidation = yup.object().shape(refundShape)
