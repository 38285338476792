import React, { Fragment, useState, useCallback } from 'react'
import {
  Tooltip,
  Popover,
  Position,
  Button,
  AnchorButton,
  PopoverInteractionKind,
  Classes,
  H5,
  FileInput,
  FormGroup,
  Icon,
  Callout,
  Tag,
} from '@blueprintjs/core'
import { errorToast } from '@utils/toast'
import Papa from 'papaparse'
import { isPeakTime } from '@utils/isPeakTime'

const SIZELIMIT = 1048576

export const ImportPopover = ({ startImport: startImportCallBack }) => {
  const [importFileName, setImportFileName] = useState(null)
  const [importFileData, setImportFileData] = useState(null)

  const handleFileChange = e => {
    const file = e.target.files[0]

    if (file.size > SIZELIMIT) {
      errorToast(
        `File is bigger than ${
          SIZELIMIT / 1000
        }kB. Please choose a smaller file.`
      )
      return false
    }

    setImportFileName(file.name)

    if (!window.FileReader) {
      errorToast(
        `Your browser does not support the HTML5 FileReader API. Please use a different browser.`
      )
      return false
    }

    const reader = new FileReader()

    reader.onload = evt => {
      if (evt.target.readyState != 2) return
      if (evt.target.error) {
        errorToast(`Error while reading file ${importFileName}`)
        return
      }

      const data = Papa.parse(evt.target.result, {
        header: true,
        dynamicTyping: true,
      })

      setImportFileData(data)
    }

    reader.onerror = () => {
      errorToast("Can't upload file, please try again.")
    }

    reader.readAsText(file)
  }

  const startImport = useCallback(() => {
    const data = importFileData
    setImportFileName(null)
    setImportFileData(null)
    startImportCallBack(data)
  }, [startImportCallBack, importFileData])

  const peakTime = isPeakTime()

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_LEFT}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      disabled={peakTime}
      content={
        <Fragment>
          <H5>
            Import Data <Icon icon="data-lineage" />{' '}
            <Tag
              minimal
              intent="success"
              rightIcon={<Icon icon="clean" size={12} />}
            >
              NEW
            </Tag>
          </H5>
          <Callout intent="primary">
            <p>
              Prior to using the import feature, it's important to{' '}
              <a
                href="https://support.redbox.systems/docs/menu-import-and-export"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read the Documentation
              </a>
              .
            </p>

            <p className="bp5-text-small">
              Data type will be detected automatically. You will be asked to
              confirm all the changes.
            </p>
          </Callout>
          <br />
          <FormGroup
            label="Data File"
            helperText="Upload a valid (.csv), export data to see the correct format."
            labelFor="text-input"
          >
            <FileInput
              text={importFileName || 'Choose file...'}
              inputProps={{
                name: 'csvFile',
                accept: '.csv',
              }}
              hasSelection={importFileName}
              onInputChange={e => handleFileChange(e)}
              fill
            />
          </FormGroup>
          <Button
            fill
            rightIcon="play"
            disabled={!importFileName}
            className={Classes.POPOVER_DISMISS}
            onClick={startImport}
          >
            Start Import
          </Button>
        </Fragment>
      }
    >
      <Tooltip
        disabled={!peakTime}
        content="Not available during peak times"
        position={Position.BOTTOM}
      >
        <AnchorButton icon="import" disabled={peakTime}>
          Import
        </AnchorButton>
      </Tooltip>
    </Popover>
  )
}
