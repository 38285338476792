import gql from 'graphql-tag'

const GET_MARKETPLACE_APP_CONFIG = gql`
  query GetMarketplaceAppConfig($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        allowReorder
        allowSearch
        optionsListStyle
        menuListItemStyle
        menuListGroupStyle
        confirmAddressAtPayment
        promptForUpdates
        androidVersion
        iosVersion
        hideCategoriesListOnMobile
        allowMenuItemOptionImageV5
        allowMenuItemMainImageV5
        appServiceComponentIcon
        minAppVersionSupported
        kioskOptionsListStyle
        kioskMenuListItemStyle
        kioskAllowSearch
      }
    }
  }
`

export default GET_MARKETPLACE_APP_CONFIG
