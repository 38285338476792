import { Fragment } from 'react'
import Query from '@components/Query/Query'
import {
  InputGroup,
  Intent,
  Icon,
  Popover,
  Menu,
  MenuItem,
  PopoverInteractionKind,
  MenuDivider,
  HTMLSelect,
  Tag,
  Switch,
} from '@blueprintjs/core'
import get from 'lodash/get'
import { func, object, shape, number, string, bool, array } from 'prop-types'
import EDIT_OPTION_ITEMS_ORDER from '@components/Restaurant/Menu/mutations/editOptionItemsOrder.mutation'
import { successToast } from '@utils/toast'
import ARCHIVE_OPTION_ITEM from '@components/Restaurant/Menu/mutations/archiveOptionItem.mutation'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import Currency from '@components/Currency/Currency'
import MoveUpDown from '@components/MoveUpDown/MoveUpDown'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import GET_OPTIONS_DETAILS from '@components/Restaurant/Menu/queries/getOptionsDetails.query'
import GET_VATRATE from '../queries/getVatRate.query'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import { isPlatformLevelUser } from '../../../../stores/userStore'
import GET_OPTIONS from '../queries/getOptions.query'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'

import { PriceRow } from './AddOption.modal.styles'
import { useMutation } from '@apollo/client'

const OptionRow = ({
  setFieldValue,
  values,
  setStatus,
  item,
  index,
  setFieldTouched,
  handleChange,
  errors,
  remove,
  isVATregistered,
  optionId,
  initialOptionItems,
  restaurantId,
  menuItemId,
}) => {
  const [archiveOptionItem] = useMutation(ARCHIVE_OPTION_ITEM, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Option item deleted'),
    refetchQueries: [
      {
        query: GET_OPTIONS,
        variables: { restaurantId },
      },
      menuItemId && {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ].filter(Boolean),
  })

  return (
    <tr>
      <td>
        <InputGroup
          type="text"
          placeholder="Name"
          name={`optionItems.${index}.name`}
          onChange={(...args) => {
            setFieldTouched('options')
            handleChange(...args)
          }}
          value={item.name}
          intent={
            get(errors.optionItems, `[${index}].name`)
              ? Intent.DANGER
              : Intent.NONE
          }
        />
      </td>
      <PriceRow>
        <CurrencyInput
          name={`optionItems.${index}.price`}
          fill={true}
          defaultValue={item.price}
          onUpdate={amount => {
            setFieldTouched('options')
            setFieldValue(`optionItems.${index}.price`, amount)
          }}
          intent={
            get(errors.optionItems, `[${index}].price`)
              ? Intent.DANGER
              : Intent.NONE
          }
          rightElement={
            item.vatRate > 0 && (
              <Tag minimal>
                <Currency />
                {parseFloat(
                  item.price - item.price / (1 + 1 / (item.vatRate / 100))
                ).toFixed(2)}{' '}
                Net
              </Tag>
            )
          }
        >
          {isVATregistered && (
            <Query query={GET_VATRATE} showLoader={false}>
              {({ getPlatformVAT: platformVATRate }) => {
                return (
                  <HTMLSelect
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    key={`optionItems-${index}-vatRate`}
                    id={`optionItems-${index}-vatRate`}
                    name={`optionItems.${index}.vatRate`}
                    value={item.vatRate}
                    onChange={(...args) => {
                      setFieldTouched('options')
                      handleChange(...args)
                    }}
                    onBlur={event => {
                      const vatRate = event.currentTarget.value
                      setFieldValue(`${name}.${index}.vatRate`, vatRate)
                      setFieldValue(
                        `${name}.${index}.VATinclusive`,
                        event.currentTarget.value > 0
                      )
                    }}
                    options={[
                      { value: '0', label: 'VAT 0%' },
                      { value: '5', label: 'VAT 5%' },
                      {
                        value: '12.5',
                        label: 'VAT 12.5%',
                      },
                      {
                        value: platformVATRate,
                        label: `VAT ${platformVATRate}%`,
                      },
                    ]}
                  />
                )
              }}
            </Query>
          )}
        </CurrencyInput>
      </PriceRow>
      <td>
        <Switch
          name={`optionItems.${index}.soldOut`}
          defaultChecked={item.soldOut}
          onChange={(...args) => {
            setFieldTouched('options')
            handleChange(...args)
          }}
        />
      </td>
      {isPlatformLevelUser() ? (
        <td>
          {values.optionItems[index] && (
            <ImageUploadDirectSingle
              imageName={
                typeof values.optionItems[index]?.imageImplementer === 'object'
                  ? `optionItems[` + index + `].imageImplementer.src`
                  : `optionItems[` + index + `].imageImplementer`
              }
              values={values}
              setFieldValue={(field, value) => {
                setFieldValue(field, value ? value : '')
                setFieldTouched('options')
              }}
              imageLabel="Photo"
              setStatus={setStatus}
              sizeLimit={1000000}
              replace={true}
              optionItemId={values.optionItems[index].id}
            />
          )}
        </td>
      ) : null}
      <td className="bp5-action-cell">
        <Popover
          content={
            <Fragment>
              <Menu>
                <MoveUpDown
                  mutationToExecute={EDIT_OPTION_ITEMS_ORDER}
                  refetchQueries={[
                    {
                      query: GET_OPTIONS_DETAILS,
                      variables: { id: optionId },
                    },
                  ]}
                  index={index}
                  initialList={initialOptionItems}
                />
              </Menu>

              <ConfirmationPopover
                remove={async () => {
                  await archiveOptionItem({ variables: { id: item.id } })
                  remove(index)
                }}
                confirmationText="Are you sure you want to delete this option item?"
              >
                <Menu>
                  <MenuDivider />
                  <MenuItem
                    text="Delete"
                    icon="trash"
                    shouldDismissPopover={false}
                  />
                </Menu>
              </ConfirmationPopover>
            </Fragment>
          }
          interactionKind={PopoverInteractionKind.CLICK}
        >
          <Icon icon="more" />
        </Popover>
      </td>
    </tr>
  )
}

OptionRow.propTypes = {
  setFieldValue: func,
  item: shape({
    name: string,
    price: string,
    vatRate: number,
    VATinclusive: bool,
  }),
  index: number,
  setFieldTouched: func,
  handleChange: func,
  errors: object,
  remove: func,
  optionId: string,
  initialOptionItems: array,
  restaurantId: string,
  menuItemId: string,
}

export default OptionRow
