import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Icon, Switch, Tooltip } from '@blueprintjs/core'

const MarketplaceAllowFacebookMarketing = ({
  updateStatus,
  allowFacebookMarketing,
  disabled = false,
}) => {
  return (
    <Formik initialValues={{ allowFacebookMarketing }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={
              <Fragment>
                Meta Business Suite Sync{' '}
                {disabled && (
                  <Tooltip content={'Requires Marketplace Billing'}>
                    <Icon icon="warning-sign" />
                  </Tooltip>
                )}
              </Fragment>
            }
            checked={values.allowFacebookMarketing}
            disabled={disabled}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowFacebookMarketing: e.currentTarget.checked,
              })
            }}
            name="allowFacebookMarketing"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowFacebookMarketing.propTypes = {
  allowFacebookMarketing: bool,
  disabled: bool,
  errors: object,
}

export default MarketplaceAllowFacebookMarketing
