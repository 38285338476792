import * as yup from 'yup'
import crypto from 'crypto'
import { phoneRegExp } from './phone-regex'
const IS_SERVER = typeof window === 'undefined'

export const myDetailsGeneralStructure = {
  email: yup.string().email('The email is not in a valid format'),
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long'),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long'),
  phoneNumber: yup
    .string()
    .min(8, 'The password should be at least 8 characters long'),
}

export const myDetailsPasswordStructure = {
  currentPassword: yup.string(),
  newPassword: yup
    .string()
    .required('A password is required')
    .min(8, 'The password should be at least 8 characters long'),
  passwordConfirm: yup
    .string()
    .required('Please confirm your password')
    .test('passwordMatch', 'Passwords should match.', function () {
      const { newPassword, passwordConfirm } = this.parent

      return newPassword === passwordConfirm
    }),
}

export const newUserInviteStructure = {
  email: yup.string().email('the email is not in a valid format').required(),
  roleKey: yup.string().required('roleKey is a required field'),
}

export const newUserRegisterStructure = {
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long'),
  password: yup
    .string()
    .required('A password is required')
    .min(8, 'The password should be at least 8 characters long'),
  passwordConfirm: yup
    .string()
    .required('Please confirm your password')
    .test('passwordMatch', 'Passwords should match.', function () {
      const { password, passwordConfirm } = this.parent

      if (IS_SERVER) {
        const passwordBuffer = Buffer.from(password)
        const confirmPasswordBuffer = Buffer.from(passwordConfirm)

        // Use timingSafeEqual to prevent timing attacks
        return (
          passwordBuffer.length === confirmPasswordBuffer.length &&
          crypto.timingSafeEqual(passwordBuffer, confirmPasswordBuffer)
        )
      } else {
        // dont use crypto on client side
        return password === passwordConfirm
      }
    }),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long'),
  phoneNumber: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid phone number'),
}

export const editUserStructure = {
  email: yup.string().email('The email is not in a valid format'),
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long'),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long'),
  phoneNumber: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid phone number'),
  roleKey: yup.string(),
  roleTitle: yup.string().required('Please select a role'),
  ownsPartnerIds: yup.array().when('roleKey', ([roleKey], schema) => {
    if (['PARTNER', 'PARTNER_USER'].includes(roleKey)) {
      return schema
        .of(yup.string())
        .min(1, 'Please select a partner')
        .required('Please select a partner')
    } else {
      return schema.max(0)
    }
  }),
  ownsPlatformIds: yup.array().when('roleKey', ([roleKey], schema) => {
    if (['SUPERADMIN', 'DEVELOPER'].includes(roleKey)) {
      return schema
        .of(yup.string())
        .min(1, 'Please select a platform')
        .required('Please select a platform')
    } else {
      return yup.array().max(0)
    }
  }),
  ownsRestaurantIds: yup.array().when('roleKey', ([roleKey], schema) => {
    if (['RESTAURANT_USER', 'MENU_EDITOR'].includes(roleKey)) {
      return schema
        .of(yup.string())
        .min(1, 'Please select a business')
        .required('Please select a business')
    } else {
      return schema.max(0)
    }
  }),
  ownsOutletIds: yup.array().when('roleKey', ([roleKey], schema) => {
    if (['OUTLET_USER', 'OUTLET_FINANCIAL_USER'].includes(roleKey)) {
      return schema
        .of(yup.string())
        .min(1, 'Please select an outlet')
        .required('Please select an outlet')
    } else {
      return schema.max(0)
    }
  }),
  ownsMarketplaceIds: yup.array().when('roleKey', ([roleKey], schema) => {
    if (
      [
        'MARKETPLACE_ADMIN',
        'MARKETPLACE_OWNER',
        'MARKETPLACE_USER',
        'MARKETING_USER',
      ].includes(roleKey)
    ) {
      return schema
        .of(yup.string())
        .min(1, 'Please select a marketplace')
        .required('Please select a marketplace')
    } else {
      return schema.max(0)
    }
  }),
}
