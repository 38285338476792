import gql from 'graphql-tag'

const GET_RESTAURANTS_BY_NAME = gql`
  query getRestaurantsByName($searchName: String) {
    getRestaurants(first: 10, nameContains: $searchName) {
      restaurants {
        id
        name
        outlets {
          id
        }
      }
    }
  }
`

export default GET_RESTAURANTS_BY_NAME
