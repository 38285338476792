import { Fragment } from 'react'
import Query from '@components/Query/Query'
import EnableOrderToTableForm from './EnableOrderToTableForm'
import TableList from './TableList'
import GET_OUTLET_TABLES from '@components/Outlet/Tables/queries/getOutletTables.query'
import AddTableModal from '@components/TableModal/AddTable.modal'
import { useModal } from '@src/providers/ModalProvider'
import { useParams } from 'react-router-dom'
import { isAtLeastOutletUser } from '../../../stores/userStore'
import { NoPermissions } from '../../UI/Permission/Permission'

const Tables = () => {
  const { isOpen, toggleModal } = useModal('addTableModal')
  const { outlet: outletId } = useParams()
  return isAtLeastOutletUser() ? (
    <Query
      query={GET_OUTLET_TABLES}
      variables={{
        outletId,
      }}
      loaderTitle={'Loading Tables'}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = outlets[0]
        return (
          <Fragment>
            <div className="bp5-layout-row">
              <div className="bp5-layout-col">
                <TableList
                  tables={outlet.tables}
                  outletId={outlet.id}
                ></TableList>
              </div>
              <div className="bp5-layout-col-side">
                <EnableOrderToTableForm
                  outlet={outlet}
                ></EnableOrderToTableForm>
              </div>
            </div>

            <AddTableModal
              modalOpen={isOpen}
              modalClose={() => toggleModal(false)}
              outletId={outlet.id}
            />
          </Fragment>
        )
      }}
    </Query>
  ) : (
    <NoPermissions />
  )
}

export default Tables
