export const orderList = (arr, indexA, direction) => {
  const arrLength = arr.length

  // Make a copy of the array to avoid mutating the input
  const _arr = [...arr]
  let indexB

  // Determine the swap index
  if (direction === 'up') {
    indexB = indexA > 0 ? indexA - 1 : 0
  } else {
    indexB = indexA < arrLength - 1 ? indexA + 1 : arrLength - 1
  }

  // Swap the elements
  ;[_arr[indexA], _arr[indexB]] = [_arr[indexB], _arr[indexA]]

  // Return new array with modified positions
  return _arr.map((arrItem, index) => {
    return { id: arrItem.id, position: index }
  })
}
