import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import DELETE_CAMPAIGN from '../mutations/deleteCampaign.mutation'
import { useMutation } from '@apollo/client'

const DeleteCampaignButton = ({
  campaignId,
  marketplaceId,
  title = null,
  intent = null,
  onCompleted,
}) => {
  const [deleteRequest] = useMutation(DELETE_CAMPAIGN, {
    onCompleted: () => {
      successToast('Campaign deleted successfully')
      onCompleted()
    },
  })

  return (
    <ConfirmationPopover
      remove={() =>
        deleteRequest({
          variables: { id: campaignId, marketplaceId },
        })
      }
      confirmationText={`Are you sure you want to delete this campaign?`}
    >
      <Button text={title} icon="trash" intent={intent} minimal />
    </ConfirmationPopover>
  )
}

export default DeleteCampaignButton
