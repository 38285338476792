import React from 'react'
import styled from 'styled-components'

import V2 from '@assets/terminals/V2.png'
import TN from '@assets/terminals/T2MINI.png'
import P2 from '@assets/terminals/P2PRO.png'
import L2 from '@assets/terminals/L2.png'
import DM from '@assets/terminals/D2MINI.png'
import D2 from '@assets/terminals/D2S-2.png'
import T2 from '@assets/terminals/T2.png'

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: #eff1f3;
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
`

const TerminalIconInner = ({ serial = null, description = null }) => {
  if (!serial || serial.length <= 2) return
  let icon = null
  switch (serial.substr(0, 2).toUpperCase()) {
    case 'NE':
    case 'VB':
    case 'VS':
    case 'VN':
    case 'V2':
      icon = V2
      break
    case 'TS':
    case 'T2':
      icon = T2
      break
    case 'TN':
      icon = TN
      break
    case 'P2':
      icon = P2
      break
    case 'L2':
      icon = L2
      break
    case 'DM':
      icon = DM
      break
    case 'TC':
      icon = D2
      break
  }

  return (
    icon && (
      <Container>
        <div>
          <img src={icon} height="140" />
        </div>
        {description && <div>{description}</div>}
      </Container>
    )
  )
}

const TerminalIcon = TerminalIconInner

export default TerminalIcon
