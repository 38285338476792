import React, { useState } from 'react'
import {
  FormGroup,
  Classes,
  Button,
  RadioGroup,
  Radio,
  Intent,
} from '@blueprintjs/core'
import { func, object, string } from 'prop-types'
import { device } from '@utils/constants'
import GitTagSelect from '@components/GitTagSelect/GitTagSelect'
import AppBuildConfirmation from './AppBuildConfirmation'

const AppBuildForm = ({
  values,
  handleSubmit,
  handleChange,
  errors,
  isSubmitting,
}) => {
  const [openModal, setModalOpen] = useState(false)

  const onBuildClick = () => {
    if (values.action === 'release_to_store') {
      setModalOpen(true)
    } else {
      handleSubmit()
    }
  }

  return (
    <div>
      <form>
        <FormGroup
          label="Device"
          labelFor="device"
          helperText={errors.device ? errors.device : ''}
          intent={Intent.DANGER}
        >
          <RadioGroup
            name="device"
            selectedValue={values.device}
            onChange={handleChange}
          >
            <Radio label="iOS" value={device.IOS} />
            <Radio label="Android" value={device.ANDROID} />
          </RadioGroup>
        </FormGroup>
        <FormGroup
          label="Version"
          labelFor="version"
          helperText={errors.version ? errors.version : ''}
          intent={Intent.DANGER}
        >
          <GitTagSelect
            selectedValue={values.version}
            onChange={handleChange}
          ></GitTagSelect>
        </FormGroup>
        <FormGroup
          label="Action"
          labelFor="action"
          helperText={errors.action ? errors.action : ''}
          intent={Intent.DANGER}
        >
          <RadioGroup
            name="action"
            selectedValue={values.action}
            onChange={handleChange}
          >
            <Radio
              label="Build and invite to App Center for review"
              value="release_to_appcenter"
            />
            <Radio
              label="Build and upload app to Stores for manual release"
              value="release_to_store"
            />
          </RadioGroup>
        </FormGroup>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Build and deploy"
            onClick={onBuildClick}
            loading={isSubmitting}
          />
        </div>
      </form>
      <AppBuildConfirmation
        modalOpen={openModal}
        modalClose={() => setModalOpen(false)}
        handleSubmit={handleSubmit}
        version={values.version}
        loading={isSubmitting}
      />
    </div>
  )
}

AppBuildForm.propTypes = {
  values: object,
  handleSubmit: func,
  handleChange: func,
  setFieldValue: func,
  appID: string,
}

export default AppBuildForm
