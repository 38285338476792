import gql from 'graphql-tag'

const GET_MARKETPLACE_APPS = gql`
  query GetMarketplaceApps($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        iosVersion
        id
        name
        partner {
          id
        }
        app {
          id
          appBuilds {
            id
            device
            version
            releaseToAppcenter
            releaseToStore
            createdAt
            appBuildStatuses {
              id
              status
              createdAt
            }
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE_APPS
