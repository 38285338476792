import React, { Fragment } from 'react'
import { Intent, AnchorButton, Callout, Card, Tag } from '@blueprintjs/core'
import { get } from 'lodash'
import config from '@src/config'

const StripeConnectForm = ({ partner }) => {
  const partnerId = partner.id
  const stripeClientId = get(partner, 'platform.stripeClientId', null)

  if (!partner.contactAddress)
    return (
      <Callout
        intent={Intent.WARNING}
        title={'Information Request'}
        style={{ marginBottom: '24px' }}
      >
        <p>
          Missing <strong>Company Address</strong>, please setup your business
          contact details and location.
        </p>

        <AnchorButton
          outlined
          text="Update Information"
          intent={Intent.WARNING}
          href={`/partners/${partnerId}/details`}
        />
      </Callout>
    )
  if (!partner.companyLegalName)
    return (
      <Callout
        intent={Intent.WARNING}
        title={'Information Request'}
        style={{ marginBottom: '24px' }}
      >
        <p>
          Missing <strong>Legal Company Name</strong>. please setup your
          business contact details and location.
        </p>

        <AnchorButton
          outlined
          text="Update Information"
          intent={Intent.WARNING}
          href={`/partners/${partnerId}/details`}
        />
      </Callout>
    )

  if (!partner.contactEmail)
    return (
      <Callout
        intent={Intent.WARNING}
        title={'Information Request'}
        style={{ marginBottom: '24px' }}
      >
        <p>
          Missing <strong>Company Email</strong>, please setup your business
          contact details and location.
        </p>

        <AnchorButton
          outlined
          text="Update Information"
          intent={Intent.WARNING}
          href={`/partners/${partnerId}/details`}
        />
      </Callout>
    )

  return (
    <Card>
      {partner.stripeId && partner.stripeConnect.accountType === 'express' ? (
        <Fragment>
          <h5 className="bp5-heading">Stripe Standard</h5>

          {partner.stripeConnect.dashboardLink && (
            <Fragment>
              <AnchorButton
                text="Account Dashboard"
                rightIcon="share"
                href={partner.stripeConnect.dashboardLink}
              />
              <br />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <h5 className="bp5-heading">
            Paybox Enterprise{' '}
            {partner.stripeId && <Tag intent={Intent.SUCCESS}>CONNECTED</Tag>}
          </h5>
          <p>
            {!partner.stripeId &&
              'Connect your stripe account process payments through Paybox directly on your stripe account.'}
          </p>
          <p>Partner commission will remain in the primary Stripe account.</p>
          {!partner.stripeId && (
            <AnchorButton
              disabled={!partner.stripeEnterpriseAllowed}
              text="Connect Primary Stripe Account"
              rightIcon="share"
              href={`https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${stripeClientId}&redirect_uri=${config.apiUrl}/api/stripe/connect&state=partners_${partnerId}`}
            />
          )}
        </Fragment>
      )}
    </Card>
  )
}

export default StripeConnectForm
