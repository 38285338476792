import React, { Fragment, useEffect, useState } from 'react'
import {
  HTMLTable,
  Tag,
  Card,
  Icon,
  Tooltip,
  Popover,
  Position,
  PopoverInteractionKind,
  H5,
  NonIdealState,
  Intent,
  Classes,
  Spinner,
  ButtonGroup,
  ControlGroup,
} from '@blueprintjs/core'

import { Query } from '@apollo/client/react/components'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { formatAddressObj } from '../../../utils/helpers'
import GET_RESTAURANTS from '../List/queries/getRestaurants.query'
import DenyOnboardingButton from './DenyOnboardingButton'
import ApproveOnboardingButton from './ApproveOnboardingButton'
import FilterRow from '@components/FilterRow/FilterRow'
import { Search, Pager, MarketplaceFilter } from '@components/Toolbar'
import TableHeader from './TableHeader'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import { useBusinessPageLayout } from '../useBusinessPageLayout'
import { StringParam, useQueryParams } from 'use-query-params'
import {
  isAtLeastBusinessUser,
  isAtLeastMarketplaceUser,
  isAtLeastOutletUser,
  isAtLeastPartner,
} from '@stores/userStore'

const PAGE_SIZE = 50

const OnboardingList = () => {
  useBusinessPageLayout()
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const [pagination, setPagination] = useState({
    skip: 0,
    first: PAGE_SIZE,
  })
  const { searchValue } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      setPagination(prevValue => ({ ...prevValue, skip: 0 }))
    }
  }, [searchValue, setPagination])

  const [totalCount, setTotalCount] = useState(null)

  const [{ orderBy = null }, setQueryParams] = useQueryParams({
    orderBy: StringParam,
  })

  const setOrderBySelected = (key, order) => {
    setQueryParams({
      orderBy: order === 'NONE' ? undefined : `${key}_${order}`,
    })
  }

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <Search autoFocus placeholder="Name, email..." />
          </ControlGroup>
          <MarketplaceFilter icon="filter" />
        </ButtonGroup>
        <Pager
          goToPrevious={() =>
            setPagination({ ...pagination, skip: pagination.skip - PAGE_SIZE })
          }
          goToNext={() =>
            setPagination({ ...pagination, skip: pagination.skip + PAGE_SIZE })
          }
          goToPage={v =>
            setPagination({ ...pagination, skip: (v - 1) * PAGE_SIZE })
          }
          defaultNmbRecords={PAGE_SIZE}
          skip={pagination.skip}
          total={totalCount}
          outcomeLength={0} // i dont know what this is
          totalCount={totalCount}
          dataName="Businesses"
        />
      </FilterRow>
      <Query
        query={GET_RESTAURANTS}
        variables={{
          ...pagination,
          search: searchValue,
          marketplaceIds: marketplaceFilter,
          statusOnboarding: ['SIGNUP', 'ONBOARDING'],
          orderBy: orderBy || 'name_ASC',
        }}
        onCompleted={data => {
          if (data && data.getRestaurants) {
            setTotalCount(data.getRestaurants.totalCount)
          }
        }}
      >
        {({ data, loading }) => {
          if (loading) {
            return (
              <NonIdealState
                icon={<Spinner size={60} value={null} />}
                title="Loading Applications"
                description="Please wait..."
              />
            )
          }

          return !data ||
            !data.getRestaurants ||
            !data.getRestaurants.restaurants.length ? (
            <Fragment>
              <NonIdealState
                icon="th-list"
                title="No Applications Found"
                description="Businesses can sign up to your marketplace through the onboarding flow, making setup much easier."
              />
            </Fragment>
          ) : (
            <div className="bp5-table-container bp5-scrollable">
              <Card className={'bp5-nopad'}>
                <HTMLTable bordered={false} interactive={true}>
                  <TableHeader
                    setSelected={setOrderBySelected}
                    selected={orderBy}
                  />
                  <tbody>
                    {data &&
                      data.getRestaurants &&
                      data.getRestaurants.restaurants.map(restaurant => {
                        const fee = get(
                          restaurant,
                          'outlets[0].marketplace.partnerFee',
                          0
                        )

                        const terminals = (restaurant.outlets || []).flatMap(
                          ({ terminals }) => terminals || []
                        )

                        const { tableDeal, collectionDeal, deliveryDeal } =
                          restaurant

                        const getPromotedFor = promotedForFulfilmentMethods =>
                          promotedForFulfilmentMethods.length === 3
                            ? 'Special offer flags active for all fulfilments'
                            : `Special offer flags active for ${promotedForFulfilmentMethods.join(
                                ' and '
                              )}`

                        return (
                          <tr key={restaurant.id}>
                            <td>
                              <Tooltip
                                content={
                                  restaurant.active ? (
                                    <strong>Active</strong>
                                  ) : (
                                    <Fragment>
                                      <strong>Deactivated Business</strong> -{' '}
                                      <em>Not visible to customers</em>
                                    </Fragment>
                                  )
                                }
                              >
                                <Icon
                                  icon="small-tick"
                                  color={
                                    restaurant.active ? '#3DCC91' : '#CDD6DD'
                                  }
                                />
                              </Tooltip>
                              &nbsp;
                              {restaurant.statusOnboarding === 'SIGNUP' ? (
                                restaurant.name
                              ) : (
                                <Link to={`/business/${restaurant.id}`}>
                                  {restaurant.name}
                                </Link>
                              )}
                              &nbsp;
                              {restaurant.promoted && (
                                <Tooltip
                                  content={
                                    'This business is promoted and will appear at the top of the search listings.'
                                  }
                                >
                                  <Icon
                                    icon={'endorsed'}
                                    intent={Intent.SUCCESS}
                                  />
                                </Tooltip>
                              )}
                            </td>
                            <td>
                              <Popover
                                interactionKind={PopoverInteractionKind.HOVER}
                                position={Position.RIGHT_TOP}
                                popoverClassName={
                                  Classes.POPOVER_CONTENT_SIZING
                                }
                                content={
                                  <Fragment>
                                    <H5>{restaurant.contactName}</H5>
                                    <p>
                                      {restaurant.contactPhone}
                                      <br />
                                      {restaurant.contactEmail && (
                                        <a
                                          href={`mailto:${restaurant.contactEmail}`}
                                        >
                                          {restaurant.contactEmail}
                                        </a>
                                      )}
                                    </p>
                                    <p>
                                      {formatAddressObj(
                                        restaurant.contactAddress
                                      )}
                                    </p>
                                  </Fragment>
                                }
                                className={Classes.TOOLTIP_INDICATOR}
                              >
                                {restaurant.contactName}
                              </Popover>
                            </td>
                            <td>
                              {restaurant.partner ? (
                                isAtLeastPartner() ? (
                                  <Link
                                    to={`/partners/${restaurant.partner.id}`}
                                  >
                                    {restaurant.partner.name}
                                  </Link>
                                ) : (
                                  restaurant.partner.name
                                )
                              ) : (
                                <em className="bp5-text-disabled">Unknown</em>
                              )}
                            </td>
                            <td>
                              {restaurant.outlets.length ? (
                                isAtLeastMarketplaceUser() ? (
                                  <Link
                                    to={`/marketplaces/${restaurant.outlets[0].marketplace.id}`}
                                  >
                                    {restaurant.outlets[0].marketplace.name}
                                  </Link>
                                ) : (
                                  restaurant.outlets[0].marketplace.name
                                )
                              ) : (
                                <em className="bp5-text-disabled">Unknown</em>
                              )}
                            </td>
                            <td>
                              {restaurant.cuisines.map(cuisine => (
                                <Tag
                                  minimal={true}
                                  key={`${cuisine.name}${restaurant.id}`}
                                  style={{ marginRight: 5 }}
                                >
                                  {cuisine.name}
                                </Tag>
                              ))}
                            </td>
                            <td>
                              {restaurant.partnerFee ? (
                                <span className={'bp5-text-disabled'}>
                                  {restaurant.partnerFee} %
                                </span>
                              ) : (
                                <span>{fee} %</span>
                              )}
                            </td>
                            <td>
                              {isAtLeastOutletUser() ? (
                                <Link to={`/business/${restaurant.id}/outlets`}>
                                  {restaurant.outlets.length}
                                </Link>
                              ) : (
                                restaurant.outlets.length
                              )}
                            </td>
                            <td>{terminals.length}</td>
                            <td>
                              {isAtLeastBusinessUser() ? (
                                <Link
                                  to={`/business/${restaurant.id}/discounts`}
                                >
                                  {restaurant.discounts.length}
                                </Link>
                              ) : (
                                restaurant.discounts.length
                              )}
                              &nbsp;&nbsp;
                              {tableDeal || collectionDeal || deliveryDeal ? (
                                <Tooltip
                                  content={getPromotedFor(
                                    [
                                      tableDeal && 'table service',
                                      collectionDeal && 'collection',
                                      deliveryDeal && 'delivery',
                                    ].filter(Boolean)
                                  )}
                                >
                                  <Tag minimal={true} intent="success">
                                    SPECIAL OFFER
                                  </Tag>
                                </Tooltip>
                              ) : null}
                            </td>
                            {restaurant.statusOnboarding === 'ONBOARDING' ? (
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: 'right',
                                  fontStyle: 'italic',
                                }}
                              >
                                <Icon icon="send-message" color="#5c7080" />{' '}
                                Invitation Sent
                              </td>
                            ) : (
                              <Fragment>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  <ApproveOnboardingButton
                                    restaurant={restaurant}
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  <DenyOnboardingButton
                                    restaurant={restaurant}
                                  />
                                </td>
                              </Fragment>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </HTMLTable>
              </Card>
            </div>
          )
        }}
      </Query>
    </div>
  )
}

export default OnboardingList
