import { useEffect, useContext, Fragment, useState } from 'react'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import {
  AnchorButton,
  Button,
  Callout,
  Card,
  Divider,
  FormGroup,
  HTMLSelect,
  Icon,
  Intent,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'
import { Formik } from 'formik'
import EDIT_MARKETPLACE_FACEBOOK from '../mutations/editMarketplaceFacebook.mutation'
import { errorToast, successToast } from '@utils/toast'
import AudienceModal from './AudienceModal'
import GET_MARKETPLACE_META_BY_ID from '../queries/getMarketplaceById.query'
import MarketingDialog from '@components/Billing/dialogs/Marketing.dialog'
import { handleFacebookErrors } from './helpers/helpers'
import moment from 'moment'
import config from '@src/config'
import { useParams } from 'react-router-dom'
import useFetch from '../../../utils/useFetch'
import productDictionary from '../../../utils/productDictionary'
import { useMutation } from '@apollo/client'

const getAccessTokenStatus = get => {
  if (get.data && get.data.status === 'ok') {
    return 'OK'
  }
  if (get.error && get.error.data && get.error.data.status === 'expired') {
    return 'EXPIRED'
  }
  if (get.error && get.error.data && get.error.data.status === 'not-found') {
    return 'NOT_FOUND'
  }
  return 'ERROR'
}

const GetAccessTokenExpiry = ({ id, children }) => {
  const environmentDomain = config.environmentDomain
  const get = useFetch(
    `https://manage.${environmentDomain}/api/facebook/marketplaceId/${id}/token/expiry`
  )
  return children({
    loading: get.loading,
    accessTokenStatus: getAccessTokenStatus(get),
  })
}

const FacebookContainer = () => {
  const { id: marketplaceId } = useParams()
  const { configurePageLayout } = useContext(PageLayoutContext)

  const [adAccounts, setAdAccounts] = useState([{ label: '---', value: '' }])
  const [adAccount, setAdAccount] = useState(null)
  const [isFacebookConnected, setIsFacebookConnected] = useState(false)
  const [enableAdAccount, setEnableAdAccount] = useState(false)
  const [enableAudienceList, setEnableAudienceList] = useState(false)
  const [paywallModalOpen, setPaywallModalOpen] = useState(false)
  const [facebookAudienceIds, setFacebookAudienceIds] = useState([
    { label: '---', value: '' },
  ])

  const [openModal, setModalOpen] = useState(false)

  const onLoginClick = () => {
    // @ts-ignore
    window.FB.login(
      async function (response) {
        if (response.authResponse) {
          try {
            if (response.error) {
              handleFacebookErrors(response.error)
            } else {
              const apiResp = await fetch(
                `/api/facebook/marketplaceId/${marketplaceId}`,
                {
                  body: JSON.stringify({ response }),
                  method: 'POST',
                }
              )
              if (!apiResp.ok) {
                errorToast(
                  'Internal server error, retry in an hour. If the issue persists please contact support'
                )
              } else {
                setEnableAdAccount(true)
                successToast('Connected to Facebook')
                // change disconnected to connected. And change toast colours
                // @ts-ignore
                window.FB.api(
                  '/me?fields=adaccounts{business_name,end_advertiser_name}',
                  function (response) {
                    const adAccounts = response.adaccounts.data.map(v => {
                      return {
                        label: `${v.business_name} ${
                          v.end_advertiser_name ? v.end_advertiser_name : ''
                        } ${v.id}`.trim(),
                        value: v.id,
                      }
                    })
                    setAdAccounts(
                      [{ label: '---', value: '' }].concat(adAccounts)
                    )
                  }
                )
              }
            }
          } catch (e) {
            errorToast('Internal server error, retry in an hour')
          }
        } else {
          errorToast('Connection to Facebook was not successful')
        }
      },
      { scope: 'ads_management,ads_read' }
    )
  }

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.MARKETING,
      tabs: [
        { to: `/marketing/${marketplaceId}/dashboard`, name: 'Dashboard' },
        { to: `/marketing/${marketplaceId}/segments`, name: 'Segments' },
        { to: `/marketing/${marketplaceId}/campaigns`, name: 'Campaigns' },
        {
          to: `/marketing/${marketplaceId}/meta-business`,
          name: 'Meta Business',
        },
      ],
    })
  }, [configurePageLayout, history])

  useEffect(() => {
    // @ts-ignore
    window.fbAsyncInit = () => {
      // @ts-ignore
      window.FB.init({
        appId: '713676039726076',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0',
      })
    }
    // Adapted from https://stackoverflow.com/questions/68857535/how-can-i-use-facebook-sdk-in-react
    const s = 'script'
    const id = 'facebook-jssdk'
    var js,
      fjs = document.getElementsByTagName(s)[0]
    if (document.getElementById(id)) {
      return
    }
    js = document.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  }, [])

  const [editMarketplaceFacebook] = useMutation(EDIT_MARKETPLACE_FACEBOOK, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Meta Business Connection Saved')
    },
  })

  return (
    <Fragment>
      <MarketingDialog
        marketplaceId={marketplaceId}
        isOpen={paywallModalOpen}
        paywall
        onClose={() => setPaywallModalOpen(false)}
      />
      <GetAccessTokenExpiry id={marketplaceId}>
        {({ accessTokenStatus, loading }) => {
          if (loading) {
            return <Spinner size={30} value={null} />
          }

          return (
            <Query
              query={GET_MARKETPLACE_META_BY_ID}
              variables={{ marketplaceId }}
              showLoader={false}
            >
              {({ getMarketplaces: { regions } }) => {
                const marketplace = regions[0]
                return (
                  <Fragment>
                    {marketplace.billingStatus &&
                      !marketplace.billingStatus.services.includes(
                        'MARKETING'
                      ) && (
                        <Fragment>
                          <Callout icon="crown">
                            Premium feature requiring active billing and a
                            Marketing Plan.
                            <Button
                              style={{ position: 'absolute', top: 5, right: 5 }}
                              intent="success"
                              minimal
                              icon="cube-add"
                              onClick={() => setPaywallModalOpen(true)}
                            >
                              Add Marketing Plan
                            </Button>
                          </Callout>
                          <br />
                        </Fragment>
                      )}
                    <Card>
                      <Formik
                        onSubmit={values => {
                          editMarketplaceFacebook({
                            variables: {
                              ...values,
                              id: marketplaceId,
                            },
                          })
                        }}
                        initialValues={{
                          facebookAudienceId: '',
                          facebookAdAccountName: '',
                          facebookAudienceName: '',
                        }}
                      >
                        {({
                          handleSubmit,
                          values,
                          handleChange,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <h4 className="bp5-heading">
                              Synchronise Customers to Meta Business Suite
                              Audiences{' '}
                              {!marketplace.allowFacebookMarketing ? (
                                <Tag minimal intent="danger">
                                  Disabled
                                </Tag>
                              ) : marketplace.facebookAudienceId &&
                                marketplace.facebookAudienceId.length > 5 ? (
                                <Fragment>
                                  {setIsFacebookConnected(true)}

                                  {(() => {
                                    switch (accessTokenStatus) {
                                      case 'OK':
                                        return (
                                          <Tag minimal intent="success">
                                            Connected
                                          </Tag>
                                        )
                                      case 'ERROR':
                                        return (
                                          <Tag minimal intent="error">
                                            Unknown - Error
                                          </Tag>
                                        )
                                      case 'EXPIRED':
                                        return (
                                          <Tag minimal intent="warning">
                                            Token expired
                                          </Tag>
                                        )
                                      default:
                                        return (
                                          <Tag minimal intent="warning">
                                            Unknown
                                          </Tag>
                                        )
                                    }
                                  })()}

                                  <br />
                                  {moment(
                                    marketplace.facebookLastSyncDate
                                  ).isValid() &&
                                  marketplace.facebookLastSyncTotalCustomers >
                                    0 ? (
                                    <small className="bp5-text-muted">
                                      Last synchronization of{' '}
                                      <b>
                                        {marketplace.facebookLastSyncTotalCustomers ||
                                          0}
                                      </b>{' '}
                                      customers was{' '}
                                      <b>
                                        {moment(
                                          marketplace.facebookLastSyncDate
                                        ).format('DD/MM/YYYY [at] HH:mm')}
                                      </b>
                                      .
                                    </small>
                                  ) : (
                                    <small className="bp5-text-muted">
                                      Synchronisation is in progress
                                    </small>
                                  )}
                                </Fragment>
                              ) : (
                                <Tag
                                  minimal
                                  intent={
                                    enableAdAccount
                                      ? Intent.SUCCESS
                                      : Intent.WARNING
                                  }
                                >
                                  {enableAdAccount
                                    ? 'Connected'
                                    : 'Disconnected'}
                                </Tag>
                              )}
                            </h4>
                            <p className="bp5-running-text">
                              Securely allow{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/business/tools/meta-business-suite"
                              >
                                Meta Business Suite
                              </a>{' '}
                              to receive Redbox customer and tracking data,
                              synchronized daily. This allows re-targeting of
                              your existing customers using Facebook/Instagram
                              Ad campaigns.
                            </p>
                            <p>
                              {accessTokenStatus === 'EXPIRED'
                                ? 'Meta Access Token has expired. You need to reauthenticate to continue using audience syncronisation. As per current Meta API security, the token is only valid for 60 days. '
                                : ''}
                            </p>
                            <AnchorButton
                              disabled={!marketplace.allowFacebookMarketing}
                              text={`${
                                isFacebookConnected
                                  ? 'Reauthenticate'
                                  : 'Connect'
                              } Meta Business Suite`}
                              rightIcon="share"
                              onClick={onLoginClick}
                            />
                            <Fragment>
                              <br />
                              <br />
                              <Divider />
                              <br />
                              {isFacebookConnected && !enableAdAccount && (
                                <Fragment>
                                  <Callout intent={Intent.PRIMARY}>
                                    Reauthenticate to make changes to audiences.
                                  </Callout>
                                  <br />
                                </Fragment>
                              )}
                              <FormGroup
                                label="Ad Account"
                                labelFor="facebookAdAccount"
                                intent={Intent.DANGER}
                              >
                                <HTMLSelect
                                  style={{ width: '300px' }}
                                  name="facebookAdAccount"
                                  options={adAccounts}
                                  disabled={!enableAdAccount}
                                  onChange={e => {
                                    setAdAccount(e.target.value)
                                    window.FB.api(
                                      `/${e.target.value}/customaudiences?fields=["account_id","data_source","name","description","datafile_custom_audience_uploading_status"]`,
                                      function (response) {
                                        if (response.error) {
                                          handleFacebookErrors(response.error)
                                        } else {
                                          const audiences = response.data.map(
                                            v => {
                                              return {
                                                label:
                                                  `${v.name} ${v.description}`.trim(),
                                                value: v.id,
                                              }
                                            }
                                          )
                                          setFacebookAudienceIds(
                                            [
                                              { label: '---', value: '' },
                                            ].concat(audiences)
                                          )
                                          setEnableAudienceList(true)
                                        }
                                      }
                                    )
                                    setFieldValue(
                                      'facebookAdAccountName',
                                      e.target.name
                                    )
                                  }}
                                />
                              </FormGroup>

                              <AudienceModal
                                modalOpen={openModal}
                                modalClose={() => setModalOpen(false)}
                                adAccountId={adAccount}
                                setFacebookAudienceIds={setFacebookAudienceIds}
                              />
                              <FormGroup
                                label="Audience"
                                labelFor="facebookAudienceId"
                                intent={Intent.DANGER}
                              >
                                <HTMLSelect
                                  style={{ width: '300px' }}
                                  name="facebookAudienceId"
                                  disabled={!enableAudienceList}
                                  options={facebookAudienceIds}
                                  onChange={handleChange}
                                  value={values.facebookAudienceId}
                                />{' '}
                                <Button
                                  minimal
                                  disabled={
                                    !enableAudienceList && !enableAdAccount
                                  }
                                  icon={<Icon icon={'add'} color={'#1ac57e'} />}
                                  text={'New Audience'}
                                  onClick={() => setModalOpen(true)}
                                />
                              </FormGroup>
                              <br />
                              <div className="bp-card-footer-actions">
                                <Button
                                  text="Save"
                                  type="submit"
                                  disabled={
                                    !enableAudienceList && !enableAdAccount
                                  }
                                />
                              </div>
                            </Fragment>
                          </form>
                        )}
                      </Formik>
                    </Card>
                  </Fragment>
                )
              }}
            </Query>
          )
        }}
      </GetAccessTokenExpiry>
    </Fragment>
  )
}

export default FacebookContainer
