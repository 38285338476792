import * as yup from 'yup'
import { phoneRegExp } from './phone-regex'
import { IIterableInterface } from './generic-validator'

export const addPartnerStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Platform Name is required.')
    .min(3, 'Platform name too short.'),
  contactName: yup
    .string()
    .required('Contact Name is required.')
    .min(3, 'Contact Name too short.'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required.')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid Phone Number.'),
  contactEmail: yup
    .string()
    .required('Contact Email is required.')
    .email('This is not a valid email.'),
  companyLegalName: yup.string(),
  cname: yup
    .string()
    .min(2, 'Canonical Name (CNAME) for Partner is too short.')
    .nullable(),
  brandColor: yup
    .string()
    .matches(
      /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/,
      'Brand color must be a HEX value.'
    ),
  supportUrl: yup
    .string()
    .url('Support Desk URL must is not valid.')
    .nullable(),
  supportEmail: yup.string().email('Support Email is not a valid.').nullable(),
  supportTitle: yup.string().nullable(),
}

export const editPartnerStructure: IIterableInterface = {
  name: yup.string().min(3, 'Platform Name too short. (min 3 characters).'),
  contactName: yup
    .string()
    .min(3, 'Contact Name too short. (min 3 characters).'),
  contactPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid Phone Number'),
  contactEmail: yup.string().email('Contact Email is not valid.'),
  stripeId: yup.string().nullable(),
  stripePublicId: yup.string(),
  stripeClientId: yup.string(),
  orderHoldInterval: yup.number(),
  platformId: yup
    .string()
    .min(25, 'Platform id must have 25 characters.')
    .max(25, 'Platform id must have 25 characters.'),
  platformFee: yup.number(),
  platformCharge: yup.number(),
  partnerCommissionFee: yup.number(),
  partnerCommissionCharge: yup.number(),
  partnerVat: yup.number().min(0).max(100).nullable(),
  marketplaceIds: yup.array().of(yup.string().min(25).max(25)),
  restaurantIds: yup.array().of(yup.string().min(25).max(25)),
  cname: yup
    .string()
    .min(2, 'Canonical name for partner is too short. (min 2 characters.')
    .nullable(),
  brandImage: yup.boolean(),
  brandColor: yup
    .string()
    .matches(
      /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/,
      'Brand color must be a HEX value'
    ),
  allowSubBrands: yup.boolean(),
  vatNumber: yup.string().nullable(),
  supportUrl: yup.string().url('Support Desk URL is not a valid.').nullable(),
  supportEmail: yup.string().email('Support Email is not a valid.').nullable(),
  supportTitle: yup.string().nullable(),
  companyLegalName: yup.string().nullable(),
}

export const editPartnerPaymentsStructure: IIterableInterface = {
  stripeEnterpriseId: yup.string().nullable(),
  stripeEnterprisePublicId: yup.string().nullable(),
  stripeEnterpriseAccessId: yup.string().nullable(),
  stripeEnterprisePayoutDay: yup.string(),
}
