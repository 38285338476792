import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowSingleItemOrderNotesForm = ({
  updateStatus,
  allowSingleItemOrderNotes,
}) => {
  return (
    <Formik initialValues={{ allowSingleItemOrderNotes }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Allow Single Item Order Notes'}
            checked={values.allowSingleItemOrderNotes}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowSingleItemOrderNotes: e.currentTarget.checked,
              })
            }}
            name="allowSingleItemOrderNotes"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowSingleItemOrderNotesForm.propTypes = {
  allowSingleItemOrderNotes: bool,
  errors: object,
}

export default MarketplaceAllowSingleItemOrderNotesForm
