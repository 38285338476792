import gql from 'graphql-tag'

const ONBOARDING_APPLY = gql`
  mutation onboardingApply(
    $name: String!
    $contactName: String!
    $contactAddress: AddressCreateInput!
    $contactPhone: String!
    $contactEmail: String!
    $marketplaceKey: String!
  ) {
    onboardingApply(
      name: $name
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      marketplaceKey: $marketplaceKey
    ) {
      message
    }
  }
`

export default ONBOARDING_APPLY
