import gql from 'graphql-tag'

const DELETE_PAYMENT_METHOD = gql`
  mutation deleteBillingPaymentMethod(
    $id: ID!
    $level: BillingCustomerLevel!
    $paymentMethodId: String!
  ) {
    deleteBillingPaymentMethod(
      id: $id
      level: $level
      paymentMethodId: $paymentMethodId
    ) {
      message
    }
  }
`

export default DELETE_PAYMENT_METHOD
