import gql from 'graphql-tag'

const EDIT_DELIVERY_ZONE_GEOFENCE = gql`
  mutation editDeliveryZoneGeofence($id: String!, $geoFence: Json) {
    editDeliveryZone(id: $id, geoFence: $geoFence) {
      message
      deliveryZone {
        id
        geoFence
      }
    }
  }
`

export default EDIT_DELIVERY_ZONE_GEOFENCE
