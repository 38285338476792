import { Icon } from '@blueprintjs/core'
import styled from 'styled-components'

export const Container = styled.div`
  border-left: 0;
  flex-grow: 1;
  overflow-y: auto;
`

export const TopDetails = styled.div`
  padding: 24px;
`

export const Heading = styled.h1`
  margin-top: 0;
`

export const BottomDetails = styled.div`
  margin-bottom: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
`

export const SaveButton = styled.button`
  background: ${({ theme }) => theme.colors.primaryButtonColor} !important;
  color: ${({ theme }) => theme.colors.primaryButtonText} !important;
  padding: 0px 10px;
  margin-right: 5px;
`

export const Price = styled.div`
  margin: 20px 0px;
`

export const AddButton = styled.button`
  padding: 30px 0px;
  color: ${({ theme }) => theme.colors.highlight} !important;

  & .bp5-icon {
    color: ${({ theme }) => theme.colors.highlight};
  }

  &:hover {
    background-color: transparent !important;
  }
`

export const CurrencyContainer = styled.div`
  display: flex;
  position: relative;
`

export const Currency = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  bottom: 6px;
  left: 10px;
`

export const IconStyled = styled(Icon)`
  padding-right: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.highlight};
  }
`

export const IconContainer = styled.div`
  display: flex;
`
