import gql from 'graphql-tag'

const EDIT_OUTLET_DELIVERY_ZONE = gql`
  mutation editOutletDeliveryZone(
    $id: String!
    $cost: Int
    $radiusMiles: Float
    $fixedSubsidy: Int
    $pickupNotes: String
    $uberDirectUndeliverableAction: UberDirectUndeliverableAction
  ) {
    editOutletDeliveryZone(
      id: $id
      cost: $cost
      radiusMiles: $radiusMiles
      fixedSubsidy: $fixedSubsidy
      pickupNotes: $pickupNotes
      uberDirectUndeliverableAction: $uberDirectUndeliverableAction
    ) {
      id
    }
  }
`

export default EDIT_OUTLET_DELIVERY_ZONE
