import * as yup from 'yup'
import { phoneRegExp } from './phone-regex'
import { IIterableInterface } from './generic-validator'
import { paymentMethodSchema } from './payment-method'
import { orderingTimeBracketsSchema } from './time-brackets'

export const addOutletStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Outlet requires a name')
    .min(3, 'Outlet name too short'),
  restaurantId: yup
    .string()
    .required('A restaurant id is required')
    .min(25, 'Restaurant id should have 25 characters'),
}

export const editOutletStructure: IIterableInterface = {
  name: yup.string().min(3, 'Outlet name too short'),
  featured: yup.boolean(),
  restaurantId: yup.string().min(25, 'Restaurant id should have 25 characters'),
  contactEmail: yup.string().email('Must be a valid email'),
  contactName: yup.string(),
  contactPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, {
      message: 'Please enter a valid contact phone number',
      excludeEmptyString: true,
    }),
  outletPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Please enter a valid outlet phone number'),
  outletEmail: yup.string().email('Must be a valid email'),
  activationDate: yup.date().nullable(),
  closeDate: yup.date().nullable(),
  closedUntil: yup.date().nullable(),
  openingTimes: orderingTimeBracketsSchema.nullable(),
  stripeId: yup.string().nullable(),
  defaultDeliveryTime: yup.number().nullable(),
  defaultCollectionTime: yup.number().nullable(),
  daysOfferedInAdvanceMin: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  daysOfferedInAdvanceMax: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  daysOfferedInAdvanceMinDelivery: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  daysOfferedInAdvanceMaxDelivery: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  daysOfferedInAdvanceMinCollection: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  daysOfferedInAdvanceMaxCollection: yup
    .number()
    .min(0)
    .max(31, 'Maximum days in advance is 31'),
  collectionCharge: yup.number().integer(),
  active: yup.boolean(),
  deliveryZoneIds: yup.array().of(yup.string()),
  terminalIds: yup.array().of(yup.string()),
  menuItemIds: yup.array().of(yup.string()),
  menuItemsGroupIds: yup.array().of(yup.string()),
  marketplaceId: yup.string(),
  paymentMethods: paymentMethodSchema,
  isOnlineOverride: yup.boolean().nullable(),
  isAutoAccept: yup.boolean().nullable(),
  emailOrderReceipt: yup.boolean().nullable(),
  emailInvoices: yup.boolean().nullable(),
  bankAccount: yup
    .string()
    .transform(v => (v === '' ? void 0 : v))
    .matches(/^\w{1,17}$/, {
      message: 'Please enter a valid contact Bank Account number',
      excludeEmptyString: true,
    })
    .nullable(),
  bankSort: yup
    .string()
    .transform(v => (v === '' ? void 0 : v))
    .matches(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/, {
      message: 'Please enter a valid contact bank sort code XX-XX-XX',
      excludeEmptyString: true,
    })
    .nullable(),
}
