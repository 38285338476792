import gql from 'graphql-tag'

const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    archiveUser(id: $id) {
      user {
        id
      }
    }
  }
`

export default DELETE_USER
