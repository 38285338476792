import React, { useEffect } from 'react'

import EditOutletDeliveryZoneForm from './EditOutletDeliveryZoneForm'
import AddOutletDeliveryZoneForm from './AddOutletDeliveryZoneForm'
import { Drawer, DrawerSize } from '@blueprintjs/core'

const OutletDeliveryZoneModal: React.FC<{
  modalOpen: boolean
  modalClose: () => void
  activeOutletDeliveryZone: any
  outletId: string
  clearActiveZone: () => void
}> = ({
  modalOpen,
  modalClose,
  activeOutletDeliveryZone,
  outletId,
  clearActiveZone,
}) => {
  useEffect(() => {
    if (!modalOpen) {
      clearActiveZone()
    }
  }, [modalOpen])

  return (
    <Drawer
      isOpen={modalOpen}
      onClose={modalClose}
      size={DrawerSize.SMALL}
      title={
        activeOutletDeliveryZone.id
          ? activeOutletDeliveryZone.deliveryZone.name
          : 'Add Delivery Zone'
      }
    >
      {activeOutletDeliveryZone.id ? (
        <EditOutletDeliveryZoneForm
          activeOutletDeliveryZone={activeOutletDeliveryZone}
          outletId={outletId}
        />
      ) : (
        <AddOutletDeliveryZoneForm outletId={outletId} />
      )}
    </Drawer>
  )
}

export default OutletDeliveryZoneModal
