import gql from 'graphql-tag'

const EDIT_BUSINESS_SEGMENT_ORDER = gql`
  mutation editBusinessSegmentOrder($input: [IPositionArgs!]!) {
    editBusinessSegmentOrder(input: $input) {
      message
    }
  }
`

export default EDIT_BUSINESS_SEGMENT_ORDER
