import React, { Component } from 'react'
import GET_REVIEWS from './queries/getReviews.query'
import { ButtonGroup, Button, NonIdealState } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import ReviewsTableData from './ReviewsTableData'
import { whereConstructor } from './helpers/whereConstructor'
import FilterRow from '@components/FilterRow/FilterRow'
import { Pager } from '@components/Toolbar/Pager'

const DEFAULT_RECORDS = 25

class ReviewsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      awaitingModeration: true,
      allReviews: false,
      skip: 0,
      first: DEFAULT_RECORDS,
      outcomeLength: null,
      defaultNmbRecords: DEFAULT_RECORDS,
      total: 0,
      where: {
        approved: false,
      },
    }
  }

  selectUnmoderated = () => {
    this.setState({
      awaitingModeration: true,
      allReviews: false,
      where: {
        approved: false,
      },
    })
  }

  selectAll = () => {
    this.setState({
      awaitingModeration: false,
      allReviews: true,
      where: {},
    })
  }

  setTotalCount = (totalCount, returnCount) => {
    if (this.state.total !== totalCount) {
      this.setState({
        total: totalCount,
      })
    }
    if (this.state.outcomeLength !== returnCount) {
      this.setState({
        outcomeLength: returnCount,
      })
    }
  }

  goToNext = (e, limit) => {
    e.preventDefault()
    this.setState({
      skip: this.limitNext(this.state.skip, this.state.first, limit),
      first: this.state.defaultNmbRecords,
      last: null,
    })
  }

  limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < this.state.defaultNmbRecords ? currentCursor : skip
  }

  goToPrevious = e => {
    e.preventDefault()
    this.setState({
      skip: this.limitPrevious(this.state.skip, this.state.first),
      first: this.state.defaultNmbRecords,
      last: null,
    })
  }

  goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    this.setState({
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  render() {
    const { awaitingModeration, allReviews, where } = this.state

    // this allows us to return reviews for
    // one particular customer/ outlet
    const { queryVars } = this.props
    whereConstructor(queryVars, where)

    return (
      <div className="bp5-table-frame">
        <FilterRow>
          <ButtonGroup>
            <Button
              active={awaitingModeration}
              onClick={this.selectUnmoderated}
            >
              Awaiting Moderation
            </Button>
            <Button active={allReviews} onClick={this.selectAll}>
              All
            </Button>
          </ButtonGroup>

          <Pager
            goToPrevious={e => this.goToPrevious(e)}
            goToNext={e => this.goToNext(e)}
            goToPage={this.goToPage}
            defaultNmbRecords={DEFAULT_RECORDS}
            skip={this.state.skip}
            total={this.state.total}
            outcomeLength={this.state.outcomeLength}
            totalCount={this.state.total}
            dataName="Reviews"
          />
        </FilterRow>
        <Query
          query={GET_REVIEWS}
          loaderTitle="Loading Reviews"
          variables={{
            where,
          }}
        >
          {({ getReviews: { reviews } }) => {
            if (reviews.length > 0) {
              this.setTotalCount(reviews.length)
              return (
                <ReviewsTableData reviews={reviews} queryVars={queryVars} />
              )
            } else {
              return (
                <NonIdealState
                  icon="search"
                  title="No Reviews found."
                  description="There are no reviews at this moment. Please try again later."
                />
              )
            }
          }}
        </Query>
      </div>
    )
  }
}

export default ReviewsTable
