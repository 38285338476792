import { Fragment, useState } from 'react'
import { get } from 'lodash'
import {
  Card,
  Button,
  Popover,
  Position,
  HTMLTable,
  Switch,
  PopoverInteractionKind,
  NonIdealState,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_FAQS from './queries/getMarketplaceFAQs.query'
import DELETE_FAQ from './mutations/deleteFAQ.mutation'
import EDIT_FAQ from './mutations/editFAQ.mutation'
import FAQDrawer from './FAQDrawer'
import { successToast } from '@utils/toast'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

const Support = () => {
  const { marketplace: marketplaceId } = useParams()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const [editFAQ] = useMutation(EDIT_FAQ, {
    onCompleted: ({ editFAQ }) => {
      successToast(
        `${
          get(editFAQ, 'published', false) ? 'Published' : 'Unpublished'
        } "${get(editFAQ, 'question', '')}"`
      )
    },
    onError: defaultErrorHandler,
  })

  const [deleteFAQ] = useMutation(DELETE_FAQ, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('FAQ Deleted'),
    refetchQueries: [
      {
        query: GET_MARKETPLACE_FAQS,
        variables: { marketplaceId },
      },
    ],
  })

  return (
    <Fragment>
      <Query
        query={GET_MARKETPLACE_FAQS}
        variables={{ marketplaceId }}
        loaderTitle={'Loading FAQs'}
      >
        {({ getFAQs }) => {
          return getFAQs.length ? (
            <Card className={'bp5-nopad'}>
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    <th style={{ width: '90%' }}>FAQs</th>
                    <th>Publish</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {getFAQs.map(faq => (
                    <tr key={faq.id}>
                      <td>
                        <a
                          onClick={() => {
                            setDrawerOpen(true)
                            setSelectedId(faq.id)
                          }}
                        >
                          {faq.question}
                        </a>
                      </td>
                      <td>
                        <Switch
                          checked={faq.published}
                          onChange={e => {
                            editFAQ({
                              variables: {
                                id: faq.id,
                                published: e.currentTarget.checked,
                              },
                            })
                          }}
                        />
                      </td>
                      <td className="bp5-action-cell">
                        <Popover
                          interactionKind={PopoverInteractionKind.CLICK}
                          popoverClassName="bp5-tooltip-body-sizing"
                          position={Position.LEFT}
                          content={
                            <div>
                              <h5 className="bp5-heading">Delete FAQ</h5>
                              <p>
                                Are you sure you want to permanently delete "
                                {faq.question}"?
                              </p>
                              <div className="bp5-dialog-footer-actions">
                                <Button className="bp5-button bp5-popover-dismiss">
                                  Cancel
                                </Button>
                                <Button
                                  className="bp5-button bp5-intent-danger bp5-popover-dismiss"
                                  onClick={() =>
                                    deleteFAQ({ variables: { id: faq.id } })
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          }
                        >
                          <Button icon="trash" minimal={true} />
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>
          ) : (
            <NonIdealState
              icon="th-list"
              title="No Frequently Asked Questions"
              description="FAQ's are visible to your customers for self service support."
              action={
                <Button icon="plus" onClick={() => setDrawerOpen(true)}>
                  Add FAQ
                </Button>
              }
            />
          )
        }}
      </Query>
      <FAQDrawer
        isOpen={drawerOpen}
        selectedId={selectedId}
        marketplaceId={marketplaceId}
        drawerClose={() => {
          setDrawerOpen(false)
          setSelectedId(null)
        }}
      />
    </Fragment>
  )
}

export default Support
