import gql from 'graphql-tag'

const GET_VOUCHER_ORDERS = gql`
  query getVoucherOrders(
    $orderIds: [ID!]
    $outletIds: [ID!]
    $afterDate: DateTime
    $beforeDate: DateTime
    $orderStatus_not_in: [OrderStatus!]
    $first: Int
    $skip: Int
  ) {
    orders(
      id_in: $orderIds
      outletId_in: $outletIds
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
      orderStatus_not_in: $orderStatus_not_in
      orderBy: createdAt_DESC
      first: $first
      skip: $skip
    ) {
      totalCount

      orders {
        id
        orderNumber
        orderStatus
        createdAt
        acceptedAt
        voucherKey

        outlet {
          id
          name
          restaurant {
            id
            name
          }
        }
        discountAmount
        grossTotal
        netTotal
        subtotalNet
        subtotalGross
        customer {
          id
          firstName
          lastName
        }
        loyaltyCardInfo {
          rewardValue
          cardHasBeenClaimed
          discountId
          loyaltyCardName
        }
      }
    }
  }
`

export default GET_VOUCHER_ORDERS
