import React from 'react'
import Currency from '@components/Currency/Currency'
import { Section, Text, Total } from '../OrderPrintout'

const FulfillmentMethodToDisplayName = {
  TABLE: 'Table Service',
  DELIVERY: 'Delivery',
  NETWORK: 'Delivery',
  COLLECTION: 'Collection',
}

const Totals = ({ order }) => (
  <Section>
    <Text>
      <Total>
        <span>Subtotal</span>
        <Currency amount={order.subtotalGross} />
      </Total>

      {!!order.discountAmount && (
        <Total>
          <span>{order.discount.name}</span>
          -<Currency amount={order.discountAmount} />
        </Total>
      )}

      <Total>
        <span>{FulfillmentMethodToDisplayName[order.fulfillmentMethod]}</span>
        <Currency amount={order.fulfillmentCharge} />
      </Total>

      {!!order.partnerCharge && (
        <Total>
          <span>{order.outlet.marketplace.partnerChargeDescription}</span>
          <Currency amount={order.partnerCharge} />
        </Total>
      )}

      <Total>
        <span>Total</span>
        <Currency amount={order.grossTotal} />
      </Total>
    </Text>
  </Section>
)

export default Totals
