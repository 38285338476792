import gql from 'graphql-tag'

const APPROVE_REVIEW = gql`
  mutation approveReview(
    $id: String!
  ) {
    approveReview(
      id: $id
    ) {
      message
    }
  }
`

export default APPROVE_REVIEW
