import React, { useEffect, useRef, useState } from 'react'
import first from 'lodash/first'
import Query from '@components/Query/Query'
import GeoFence from '@components/GeoJSON/GeoFence'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_MARKETPLACE_GEOFENCE from './getMarketplaceGeofence.query'
import EDIT_MARKETPLACE_GEOFENCE from './editMarketplaceGeoFence.mutation'
import { isAtLeastPartner } from '@stores/userStore'
import { Link, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { RegionalMarketplace } from '@src/types.generated'
import { get } from 'lodash'
import mapboxgl from 'mapbox-gl'

const MarketplaceMap: React.FC = () => {
  const { marketplace: marketplaceId } = useParams<{ marketplace: string }>()

  const popoverRef = useRef(null)
  const [popoverInfo, setPopoverInfo] = useState(null)

  const [editMarketplaceGeoFence] = useMutation(EDIT_MARKETPLACE_GEOFENCE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => {
      successToast(editMarketplace.message)
    },
  })
  const bounds = new mapboxgl.LngLatBounds()
  const handleGeoFenceUpdate = async (geoFence: any) => {
    await editMarketplaceGeoFence({
      variables: {
        geoFence: geoFence,
        id: marketplaceId,
      },
    })
  }

  const handleMouseLeave = (event: MouseEvent) => {
    if (!popoverRef.current?.contains(event.relatedTarget)) {
      setPopoverInfo(null)
    }
  }

  useEffect(() => {
    if (popoverInfo && popoverRef.current) {
      popoverRef.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      popoverRef.current?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [popoverInfo])

  const PopoverContent = ({ info, map }) => (
    <div
      ref={popoverRef}
      style={{
        position: 'absolute',
        left: `${info.pixelX}px`,
        top: `${info.pixelY}px`,
        transform: 'translate(-50%, -100%)',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        zIndex: 1000,
        pointerEvents: 'auto',
        width: '250px',
        fontSize: '14px',
      }}
      onMouseEnter={() => {
        if (map && map.current) {
          map.current.getCanvas().style.cursor = 'pointer'
        }
      }}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`/business/${info.businessId}/outlets/${info.outletId}/overview`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <h3 style={{ margin: '0 0 10px', color: '#2c3e50', fontSize: '18px' }}>
          {info.outletName}
        </h3>
      </Link>
      <p style={{ margin: '0 0 10px' }}>
        <strong>Business:</strong> {info.businessName}
      </p>
      <div style={{ margin: '0 0 15px' }}>
        <strong>Address:</strong>
        <address
          style={{ fontStyle: 'normal', marginTop: '5px', lineHeight: '1.4' }}
        >
          {info.address.split(', ').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </address>
      </div>
      <div>
        <strong>Management Contact:</strong>
        <p style={{ margin: '5px 0 0', lineHeight: '1.4' }}>
          {info.contactName}
          <br />
          {info.contactPhone}
          <br />
          <a href={`mailto:${info.contactEmail}`} style={{ color: '#3498db' }}>
            {info.contactEmail}
          </a>
        </p>
      </div>
    </div>
  )

  return (
    <Query
      query={GET_MARKETPLACE_GEOFENCE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Map'}
    >
      {({
        getMarketplaces: { regions },
      }: {
        getMarketplaces: { regions: RegionalMarketplace[] }
      }) => {
        const marketplace: RegionalMarketplace | undefined = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        const { geoFence, outlets } = marketplace
        if (!outlets) {
          return 'Unable to find outlets'
        }

        const outletFeatures = outlets
          .filter(outlet => {
            const coordinates = get(outlet, 'outletAddress.geo.coordinates')
            const isValidCoordinates =
              coordinates &&
              Array.isArray(coordinates) &&
              coordinates.length === 2
            return isValidCoordinates
          })
          .map(outlet => {
            const coordinates = outlet.outletAddress.geo.coordinates
            bounds.extend(coordinates)
            return {
              type: 'Feature',
              geometry: { type: 'Point', coordinates: coordinates },
              properties: {
                ...outlet,
                outletName: outlet.name,
                outletId: outlet.id,
                businessName: get(outlet, 'restaurant.name', 'Unknown'),
                businessId: get(outlet, 'restaurant.id', 'Unknown'),
                businessDescription: get(outlet, 'restaurant.description', ''),
                firstLine: get(outlet, 'outletAddress.firstLine', ''),
                secondLine: get(outlet, 'outletAddress.secondLine', ''),
                city: get(outlet, 'outletAddress.city', ''),
                postcode: get(outlet, 'outletAddress.postcode', ''),
              },
            }
          })
        return (
          <div className="bp5-table-frame">
            <GeoFence
              geoFence={geoFence}
              handleUpdate={() => null}
              disableEdit={!isAtLeastPartner()}
              showDelete={isAtLeastPartner()}
              setFieldValue={async (_e: string, geoFence: any) => {
                await handleGeoFenceUpdate(geoFence)
              }}
              features={outletFeatures}
              PopoverContent={PopoverContent}
              popoverInfo={popoverInfo}
              setPopoverInfo={setPopoverInfo}
            />
          </div>
        )
      }}
    </Query>
  )
}

export default MarketplaceMap
