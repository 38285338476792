export const Business = {
  business_active: {
    intent: 'success',
    tag: 'ACTIVE',
    title: 'Business Active',
    minimal: true,
    details:
      'The business is operational and available for customers to place orders.',
  },
  business_deactivated: {
    intent: 'danger',
    tag: 'DEACTIVATED',
    title: 'Business Deactivated',
    minimal: true,
    details:
      'The business is currently not operational and unavailable for customers to place orders.',
  },
}
