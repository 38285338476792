import React from 'react'
import { Intent, Button, NonIdealState } from '@blueprintjs/core'

export const NoTheme = ({ createNewTheme }) => {
  return (
    <NonIdealState
      icon="issue"
      description="Custom theming is not enabled for this marketplace."
      action={
        <Button
          intent={Intent.SUCCESS}
          onClick={createNewTheme}
          text="Enable Custom Theme"
        />
      }
    />
  )
}
