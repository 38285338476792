import gql from 'graphql-tag'

const ADD_FAQ = gql`
  mutation addFAQ(
    $question: String!
    $answer: String!
    $marketplaceId: String!
  ) {
    addFAQ(
      marketplaceId: $marketplaceId
      question: $question
      answer: $answer
    ) {
      id
      question
      answer
      published
    }
  }
`

export default ADD_FAQ
