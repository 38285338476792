import React from 'react'
import { string, func } from 'prop-types'

import { selectionUpdate } from '@utils/helpers'
import { FormGroup, HTMLSelect } from '@blueprintjs/core'

const EntitySelectGroup = ({ name, selectedValue, onChange }) => (
  <FormGroup
    label="Entity"
    labelInfo="(required)"
    helperText="Legal status of this business."
  >
    <HTMLSelect
      name={name}
      value={selectedValue}
      onChange={event => {
        const selection = selectionUpdate(event)
        onChange(selection.value)
      }}
    >
      <option value="COMPANY">Limited Company</option>
      <option value="PARTNERSHIP">Partnership</option>
      <option value="INDIVIDUAL">Sole Trader / Individual</option>
      <option value="NON_PROFIT">Non Profit Organisation</option>
    </HTMLSelect>
  </FormGroup>
)

EntitySelectGroup.propTypes = {
  name: string,
  selectedValue: string,
  onChange: func,
}

export default EntitySelectGroup
