import React from 'react'
import moment from 'moment'

const dateFormatString = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
const urlDateFormat = 'YYYYMMDDTHHmm'
const initialAfterDateMoment = moment().startOf('day')
const initialBeforeDateMoment = moment().endOf('day')

const outlets = [
  {
    id: '',
    name: '',
    active: false,
  },
]

export const BusinessAnalyticsContext = React.createContext({
  initialAfterDateMoment,
  initialBeforeDateMoment,
  dateFormatString,
  urlDateFormat,
  outlets,
  setOutlets: _outlets => {},
  customers: [],
  setCustomers: _customers => {},
  significantDates: [],
  setSignificantDates: _dates => {},
  activationDates: [],
  setActivationDates: _dates => {},
})

const BusinessAnalyticsContextProvider = ({ children }) => {
  const [outlets, setOutlets] = React.useState([])
  const [customers, setCustomers] = React.useState([])
  const [significantDates, setSignificantDates] = React.useState([])
  const [activationDates, setActivationDates] = React.useState([])

  return (
    <BusinessAnalyticsContext.Provider
      value={{
        initialAfterDateMoment,
        initialBeforeDateMoment,
        dateFormatString,
        urlDateFormat,
        outlets,
        setOutlets,
        customers,
        setCustomers,
        significantDates,
        setSignificantDates,
        activationDates,
        setActivationDates,
      }}
    >
      {children}
    </BusinessAnalyticsContext.Provider>
  )
}

export default BusinessAnalyticsContextProvider
