// swaps 2 adjacent items in an array - faster to swap than splice - O(1) vs O(n)
export const swapAdjacentArrayItems = (arr, index, direction) => {
  const arrLength = arr.length
  const clonedArr = [...arr]

  // find the index of the adjacent item, accounting for array boundaries
  let adjacentIndex
  if (direction === 'up') {
    adjacentIndex =
      index === 0
        ? 0 // safety net
        : index - 1
  } else {
    adjacentIndex =
      index === arrLength - 1
        ? arrLength - 1 // safety net
        : index + 1
  }

  // temporarily store item that was at [index]
  const itemAtIndex = clonedArr[index]

  // override item at [index] with item at [adjacentIndex]
  clonedArr[index] = clonedArr[adjacentIndex]

  // override item at [adjacentIndex] with item that was at [index]
  clonedArr[adjacentIndex] = itemAtIndex

  return clonedArr
}
