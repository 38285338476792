import Currency from '@src/components/Currency/Currency'
import { CSSProperties } from 'react'
import {
  BaseColumn,
  ColumnRenderer,
  ColumnSorter,
  FALLBACK_PLACEHOLDER,
} from './column.types'

export interface CurrencyColumn<X extends string>
  extends BaseColumn<'currency', X> {}

export interface CurrencyColumnData {
  amount: number | null | undefined
  amountInPence?: boolean
  style?: CSSProperties
}

export const sortCurrencyColumn: ColumnSorter<'currency'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * ((b.amount ?? -Infinity) - (a.amount ?? -Infinity))
}

export const CurrencyColumnContent: ColumnRenderer<'currency', string> = ({
  columnDefinition,
  columnData,
}) => {
  if (columnData.amount === null)
    return columnDefinition.placeholder ?? FALLBACK_PLACEHOLDER
  return (
    <div style={columnData.style}>
      <Currency {...columnData} />
    </div>
  )
}
