import { useState, useEffect } from 'react'
import { InputGroup, Intent } from '@blueprintjs/core'
import styled from 'styled-components'

const ColorPicker: React.FC<{
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error: boolean
  name: string
  disabled?: boolean
}> = ({ value, onChange, error, name, disabled = false, ...rest }) => {
  const [color, setColor] = useState('')

  useEffect(() => {
    setColor(value ? value.replace(/#/g, '') : '')
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'text' && e.target.value) {
      const value = e.target.value.includes('#')
        ? e.target.value
        : '#' + e.target.value
      e.target.value = value
    } else {
      setColor(e.target.value.replace(/#/g, '').toUpperCase())
    }
    onChange(e)
  }

  return (
    <InputGroup
      value={color}
      leftIcon={<Hash>#</Hash>}
      rightElement={
        !error ? (
          <input
            type="color"
            name={name}
            value={`#${color}`}
            onChange={handleChange}
            disabled={disabled}
            style={{
              height: '30',
              background: 'none',
              borderColor: 'transparent',
            }}
          />
        ) : undefined
      }
      onChange={handleChange}
      intent={error ? Intent.DANGER : Intent.NONE}
      name={name}
      disabled={disabled}
      {...rest}
    />
  )
}

const Hash = styled.span`
  width: 25px;
  height: 30px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`

export default ColorPicker
