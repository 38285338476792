import gql from 'graphql-tag'

const ADD_MENU_ITEM = gql`
  mutation addMenuItem(
    $name: String!
    $position: Int!
    $price: Int!
    $menuItemGroupIds: [String]
    $restaurantId: String!
    $minimumPurchasePrice: Int
  ) {
    addMenuItem(
      name: $name
      position: $position
      price: $price
      menuItemGroupIds: $menuItemGroupIds
      restaurantId: $restaurantId
      minimumPurchasePrice: $minimumPurchasePrice
    ) {
      message
      menuItem {
        id
        name
        parentMenus {
          id
          name
        }
      }
    }
  }
`

export const CREATE_ADD_ON_MENU_ITEM = gql`
  mutation createAddOnMenuItem(
    $name: String!
    $position: Int!
    $price: Int!
    $menuItemGroupIds: [String]
    $restaurantId: String!
    $minimumPurchasePrice: Int!
  ) {
    addMenuItem(
      name: $name
      position: $position
      price: $price
      menuItemGroupIds: $menuItemGroupIds
      restaurantId: $restaurantId
      minimumPurchasePrice: $minimumPurchasePrice
    ) {
      message
      menuItem {
        id
        name
        parentMenus {
          id
          name
        }
      }
    }
  }
`

export default ADD_MENU_ITEM
