import gql from 'graphql-tag'

const EDIT_DELIVERY_NETWORK = gql`
  mutation editDeliveryNetwork(
    $id: String!
    $name: String
    $helpPhone: String
    $namePrefix: String
    $fixedSubsidy: Int
    $apiClientId: String
    $apiSecret: String
    $apiURL: String
    $apiKey: String
    $deliveryChargeRecipient: DeliveryChargeRecipient
    $deliveryNetworkType: DeliveryNetworkType
    $uberDirectCustomerId: String
    $uberDirectClientId: String
    $uberDirectClientSecret: String
    $uberDirectSigningKey: String
    $uberDirectTestScenario: String
  ) {
    editDeliveryNetwork(
      id: $id
      name: $name
      helpPhone: $helpPhone
      namePrefix: $namePrefix
      fixedSubsidy: $fixedSubsidy
      apiClientId: $apiClientId
      apiSecret: $apiSecret
      apiURL: $apiURL
      apiKey: $apiKey
      deliveryChargeRecipient: $deliveryChargeRecipient
      deliveryNetworkType: $deliveryNetworkType
      uberDirectCustomerId: $uberDirectCustomerId
      uberDirectClientId: $uberDirectClientId
      uberDirectClientSecret: $uberDirectClientSecret
      uberDirectSigningKey: $uberDirectSigningKey
      uberDirectTestScenario: $uberDirectTestScenario
    ) {
      message
    }
  }
`

export default EDIT_DELIVERY_NETWORK
