import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const addBusinessSegmentStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Segment name is required')
    .min(3, 'Segment name must be longer than 3 characters'),
  type: yup.string().required('Segment type is required'),
}

export const editBusinessSegmentStructure: IIterableInterface = {
  id: yup.string().required('segment id is required'),
  name: yup
    .string()
    .required('Segment name is required')
    .min(3, 'Segment name must be longer than 3 characters'),
  showName: yup.boolean(),
  outletLimit: yup.number(),
  filterSegment: yup.boolean(),
  linkToSegmentIndex: yup.boolean(),
  description: yup
    .string()
    .max(200, "Description can't be more than 200 characters long"),
  groups: yup.array().of(
    yup.object({
      id: yup.string(),
      filterGroupType: yup.string(),
      filters: yup.array().of(
        yup.object({
          id: yup.string(),
          comparator: yup.string().required('Please choose a comparator'),
          attribute: yup.string(),
          values: yup.array().of(yup.string()),
        })
      ),
    })
  ),
  limitedAvailability: yup.string(),
  availabilityTimes: yup.array().of(
    yup.object({
      start: yup.object({ day: yup.string(), time: yup.string() }),
      end: yup.object({ day: yup.string(), time: yup.string() }),
      key: yup.string(),
    })
  ),
  availabiltyEndDate: yup.date(),
  availabiltyStartDate: yup.date(),
  openStatusFilter: yup.string().nullable(),
  linkCards: yup.array().of(
    yup.object({
      id: yup.string(),
      description: yup.string().nullable(),
      menuItemId: yup.string().nullable(),
      name: yup.string().nullable(),
      outletId: yup.string().nullable(),
      type: yup.string().nullable(),
      position: yup.number().nullable(),
      cardImage: yup
        .object({
          id: yup.string(),
          image: yup
            .object({
              id: yup.string(),
              src: yup.string(),
            })
            .nullable(),
        })
        .nullable(),
    })
  ),
}
