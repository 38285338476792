import React from 'react'

type ObjectPropPram = {
  span: number
  offset?: number
  order?: number
}

const TOTAL_COLUMNS = 12

export interface ColProps {
  span?: number
  offset?: number | string
  className?: string
  children: React.ReactNode
  prefix?: string
  gutter?: number
  order?: number | string
  xs?: number | ObjectPropPram
  sm?: number | ObjectPropPram
  md?: number | ObjectPropPram
  lg?: number | ObjectPropPram
  xl?: number | ObjectPropPram
  style?: React.CSSProperties
}

const Col: React.FC<ColProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  span,
  gutter = 0,
  className,
  style,
  children,
}) => {
  const baseClasses = `px-${gutter / 8}`

  // Map breakpoints to Tailwind width classes
  const getWidthClass = (size, prefix) => {
    prefix &&= prefix + ':'
    if (!size) return ''
    if (size === TOTAL_COLUMNS) return `${prefix}w-full`

    return `${prefix}w-${size}/${TOTAL_COLUMNS}`
  }

  const classNameString = [
    'col',
    baseClasses,
    // tailwind is mobile first so xs should have no prefix
    getWidthClass(xs, ''),
    getWidthClass(sm, 'sm'),
    getWidthClass(md, 'md'),
    getWidthClass(lg, 'lg'),
    getWidthClass(xl, 'xl'),
    getWidthClass(span, ''),
    className,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <div className={classNameString} style={style}>
      {children}
    </div>
  )
}

export default Col
