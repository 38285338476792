import { Button, Intent } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { Fragment, useState } from 'react'
import ConfirmationPopover from '../ConfirmationPopover/ConfirmationPopover'
import SUSPEND_LOYALTY_CARD from './Mutations/suspendLoyaltyCard'
import { useMutation } from '@apollo/client'

const SuspendLoyaltyCard = ({ suspended, loyaltyCardId, marketplaceId }) => {
  const [loyaltyCardSuspended, setLoyaltyCardSuspended] = useState(suspended)

  const [suspendLoyaltyCard, { loading }] = useMutation(SUSPEND_LOYALTY_CARD, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Successfully updated the loyalty cards suspended state.')
      setLoyaltyCardSuspended(!loyaltyCardSuspended)
    },
  })

  return (
    <div>
      {!loyaltyCardSuspended ? (
        <ConfirmationPopover
          buttonTitle="Suspend"
          confirmationText={
            <Fragment>
              {'When suspending a loyalty card the following will occur:\n\n'}
              <ul>
                <li>
                  {
                    'The customers who have earned any stamps will be able to see this card until the end date has been reached.'
                  }
                </li>
                <li>
                  {
                    'The customers who have not earned any stamps for the current loyalty card will no longer see the card available.'
                  }
                </li>
                <li>
                  {
                    'When the end date is reached, the card automatically becomes deactivated.'
                  }
                </li>
              </ul>
              {
                'We suggest you contact your customers before suspending this loyalty card.'
              }
            </Fragment>
          }
          remove={() => {
            suspendLoyaltyCard({
              variables: {
                loyaltyCardId: loyaltyCardId,
                marketplaceId: marketplaceId,
                suspended: true,
              },
            })
          }}
        >
          <Button
            text="Suspend"
            type="button"
            disabled={loading}
            intent={Intent.WARNING}
            minimal
          />
        </ConfirmationPopover>
      ) : (
        <Button
          text="Unsuspend"
          type="button"
          intent={Intent.NONE}
          disabled={loading}
          onClick={() => {
            suspendLoyaltyCard({
              variables: {
                loyaltyCardId: loyaltyCardId,
                marketplaceId: marketplaceId,
                suspended: false,
              },
            })
          }}
          minimal
        />
      )}
    </div>
  )
}

export default SuspendLoyaltyCard
