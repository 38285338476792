import gql from 'graphql-tag'

export const SEND_TEST_NOTIFICATION = gql`
  mutation sendTestNotification(
    $marketplaceId: String!
    $emailAddresses: [String!]!
    $email: ITestNotification
    $push: ITestNotification
  ) {
    sendTestNotification(
      marketplaceId: $marketplaceId
      emailAddresses: $emailAddresses
      email: $email
      push: $push
    ) {
      messageDeliveryStatuses {
        customerId
        type
        status
        failureReason
      }
    }
  }
`
