import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { string, func, bool } from 'prop-types'

import Query from '@components/Query/Query'
import GET_PLATFORM_IDS from './queries/getPlatformIds.query.js'

const PlatformSelect = ({ platformId, disabled, onChange }) => (
  <Query query={GET_PLATFORM_IDS} showLoader={false}>
    {({ getPlatform: { platform } }) => (
      <HTMLSelect
        name="platformId"
        fill={true}
        options={[
          { value: '', label: 'Select platform...' },
          ...map([platform], ({ id, name }) => ({
            value: id,
            label: name,
          })),
        ]}
        value={platformId}
        disabled={disabled}
        onChange={event => onChange(event)}
      />
    )}
  </Query>
)

PlatformSelect.propTypes = {
  platformId: string,
  disabled: bool,
  onChange: func,
}

export default PlatformSelect
