export const downloadCSV = (csvContent, filename) => {
  let a = document.createElement('a')
  if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([csvContent], {
        type: 'text/csv;encoding:utf-8',
      })
    )
    a.setAttribute('download', filename)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href =
      'data:application/octet-stream,' + encodeURIComponent(csvContent)
  }
}
