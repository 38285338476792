import { Icon, Tooltip } from '@blueprintjs/core'
import Currency from '../Currency/Currency'

interface Props {
  orderStatus: string
  paymentMethod: string
  grossTotal: number
}

export const PaymentMethod: React.FC<Props> = ({
  orderStatus,
  paymentMethod,
  grossTotal,
}) => {
  return (
    <>
      {orderStatus == 'AUTH_ACTION_FAILURE' ? (
        <Tooltip
          content={'The customer failed to authorise payment with 3DS/PSD2.'}
        >
          <Icon icon="credit-card" color={'red'} />
        </Tooltip>
      ) : (
        <Icon
          icon="credit-card"
          color={paymentMethod.split('_')[0] === 'CARD' ? '#30404d' : '#CDD6DD'}
        />
      )}{' '}
      <Currency amount={grossTotal} />
    </>
  )
}
