import gql from 'graphql-tag'

const EDIT_TABLE_FRIENDLY_NAME = gql`
  mutation editTableFriendlyName($tableId: String!, $friendlyName: String!) {
    editTableFriendlyName(tableId: $tableId, friendlyName: $friendlyName) {
      id
      friendlyName
    }
  }
`

export default EDIT_TABLE_FRIENDLY_NAME
