import React, { useContext, useEffect, useState } from 'react'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import GET_PINPOINT_MARKETPLACES from './queries/getPinpointMarketplaces.query'
import {
  Card,
  HTMLTable,
  NonIdealState,
  ButtonGroup,
  ControlGroup,
  Button,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import Favicon from '@components/Favicon/Favicon'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { Search, Pager } from '@components/Toolbar'
import FilterRow from '@components/FilterRow/FilterRow'
import { PartnerFilter } from '@components/Toolbar'

import { union, without } from 'lodash'
import { DelimitedArrayParam, useQueryParams } from 'use-query-params'
import { useSearchQueryParam } from '../../components/Toolbar/Search/useSearchQueryParam'
import productDictionary from '../../utils/productDictionary'
import { isAtLeastPartner } from '@src/stores/userStore'

const DEFAULT_RECORDS = 20
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  orderBy: 'name_ASC',
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const ListMarketplaces = () => {
  const [{ partnerFilter = [] }, setQueryParams] = useQueryParams({
    partnerFilter: DelimitedArrayParam,
  })
  const { configurePageLayout } = useContext(PageLayoutContext)
  const [marketplacePagination, setMarketplacePagination] = useState(
    DEFAULT_PAGINATION_STATE
  )
  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue.length) {
      setMarketplacePagination(DEFAULT_PAGINATION_STATE)
    }
  }, [searchValue, setMarketplacePagination])

  useEffect(
    () =>
      configurePageLayout({
        product: productDictionary.MARKETING,
        tabs: [{ to: `/marketing/list`, name: 'List' }],
      }),
    [configurePageLayout]
  )

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplacePagination.skip + marketplacePagination.first <
      marketplacePagination.total
    ) {
      setMarketplacePagination({
        ...marketplacePagination,
        skip: limitNext(
          marketplacePagination.skip,
          marketplacePagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMarketplacePagination({
      ...marketplacePagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplacePagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplacePagination({
      ...marketplacePagination,
      skip: limitPrevious(
        marketplacePagination.skip,
        marketplacePagination.first
      ),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      partnerFilter: checked
        ? union(partnerFilter, [id])
        : without(partnerFilter, id),
    })
  }

  const setFilterState = (count, totalCount) => {
    if (marketplacePagination.total !== totalCount) {
      setMarketplacePagination({
        ...marketplacePagination,
        total: totalCount,
      })
    }
    if (marketplacePagination.outcomeLength !== count) {
      setMarketplacePagination({
        ...marketplacePagination,
        outcomeLength: count,
      })
    }
  }

  const renderFilterBar = () => {
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <Search autoFocus placeholder="Marketplace..." />
            <PartnerFilter
              partnerFilter={partnerFilter}
              onChange={handlePartnerFilter}
            />
          </ControlGroup>
        </ButtonGroup>

        <Pager
          goToPrevious={e => goToPrevious(e)}
          goToNext={e => goToNext(e)}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={marketplacePagination.skip}
          total={marketplacePagination.total}
          outcomeLength={marketplacePagination.outcomeLength}
          totalCount={marketplacePagination.total}
          dataName="Marketplaces"
        />
      </FilterRow>
    )
  }

  return (
    <div className="bp5-table-frame">
      {renderFilterBar()}
      <DebouncedQuery
        query={GET_PINPOINT_MARKETPLACES}
        variables={{
          orderBy: 'name_DESC',
          ...marketplacePagination,
          search: searchValue.length > 2 ? searchValue : '',
          partnerIds: partnerFilter,
        }}
        loaderTitle={'Loading Marketplaces'}
      >
        {data => {
          const {
            getPinpointMarketplaces: { regions, count, totalCount },
          } = data

          if (
            !data ||
            !data.getPinpointMarketplaces ||
            !data.getPinpointMarketplaces.regions.length
          )
            return searchValue || partnerFilter ? (
              <NonIdealState
                icon="th-list"
                title="No Marketplaces Found"
                description="There are no marketplaces that match your search criteria."
                action={
                  <Button
                    onClick={() => {
                      setQueryParams({
                        partnerFilter: undefined,
                      })
                      resetSearch()
                    }}
                    minimal
                    intent="primary"
                  >
                    Clear Filters
                  </Button>
                }
              />
            ) : (
              <NonIdealState
                icon="th-list"
                title="Marketing Disabled"
                description="Marketing has not been enabled for your marketplaces."
              />
            )
          return (
            <div className="bp5-table-container bp5-scrollable">
              {setFilterState(count, totalCount)}
              <Card className={'bp5-nopad'}>
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      <th>Marketplace</th>
                      <th>Partner</th>
                      <th>Company</th>
                      {/* <th>Segments</th> */}
                      <th>Customers</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {regions.map(region => (
                      <tr key={region.pinpointApplicationId}>
                        <td>
                          <Favicon src={region.faviconImage} /> &nbsp;
                          <Link to={`/marketing/${region.id}`}>
                            {region.name}
                          </Link>
                        </td>
                        <td>
                          {isAtLeastPartner() ? (
                            <Link to={`/partners/${region.partner.id}`}>
                              {region.partner.name}
                            </Link>
                          ) : (
                            region.partner.name
                          )}
                        </td>
                        <td>{region.companyLegalName}</td>

                        {/* Performance issues as getting estimate for every segment on every marketplace
                        
                        <td>{region.segments.length}</td>
                        <td>
                          {get(
                            region.segments.find(
                              segment => segment.name === 'AllCustomers'
                            ),
                            'estimate'
                          )}
                        </td> */}
                        <td>
                          <Link
                            to={`/marketplaces/${region.id}/message-templates`}
                          >
                            Edit Template
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </Card>
            </div>
          )
        }}
      </DebouncedQuery>
    </div>
  )
}

export default ListMarketplaces
