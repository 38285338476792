import * as yup from 'yup'
import { addMenuItemsGroupStructure } from '../../../../validation/menu-item-group'
const { name, parentMenuId, availabilityTimes } = addMenuItemsGroupStructure

export const validation = yup.object().shape({
  name,
  limitedAvailability: yup.boolean(),
  availabilityStartDate: yup
    .mixed()
    .nullable()
    .test(
      'availabilityStartDate-condition',
      'Availability Start Date is required when Limited Availability is true',
      (value, context) => {
        const { limitedAvailability } = context.parent
        return limitedAvailability === 'true' ? !!value : true
      }
    ),
  availabilityEndDate: yup
    .mixed()
    .nullable()
    .test(
      'availabilityEndDate-condition',
      'Availability End Date is required when Limited Availability is true',
      (value, context) => {
        const { limitedAvailability } = context.parent
        return limitedAvailability === 'true' ? !!value : true
      }
    ),
  parentMenuId,
  availabilityTimes,
})
