import { format } from 'date-fns'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'
import { CSSProperties } from 'react'

export interface MonthColumn<X extends string> extends BaseColumn<'month', X> {}

export interface MonthColumnData {
  month: number
  year: number
  style?: CSSProperties
}

export const sortMonthColumn: ColumnSorter<'month'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * (b.year * 12 + b.month) - (a.year * 12 + a.month)
}

export const MonthColumnContent: ColumnRenderer<'month', string> = ({
  columnData,
}) => {
  const { month, year } = columnData
  return (
    <span style={columnData.style}>
      {format(new Date(year, month), 'MMM yyyy')}
    </span>
  )
}
