import gql from 'graphql-tag'

const EDIT_COLLECTION_DETAILS = gql`
  mutation editCollectionDetails(
    $id: String!
    $collectionCharge: Int
    $collectionMinimumOrderValue: Int
    $deliveryMinimumOrderValue: Int
    $paymentMethods: [PaymentMethod]
  ) {
    editOutlet(
      id: $id
      collectionCharge: $collectionCharge
      collectionMinimumOrderValue: $collectionMinimumOrderValue
      deliveryMinimumOrderValue: $deliveryMinimumOrderValue
      paymentMethods: $paymentMethods
    ) {
      message
      outlet {
        id
        name
        collectionCharge
        collectionMinimumOrderValue
        deliveryMinimumOrderValue
        paymentMethods
      }
    }
  }
`

export default EDIT_COLLECTION_DETAILS
