import { createContext } from 'react'

const usersPageContext = createContext({
  modalOpen: false,
  setModalOpen: () => {},
  setUser: () => {},
  user: null,
})

export default usersPageContext
