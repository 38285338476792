import gql from 'graphql-tag'

const TWO_FACTOR_CHECK = gql`
  mutation twoFactorCheck($code: String!) {
    twoFactorCheck(code: $code) {
      token
      ownsPartners {
        id
      }
      role {
        id
        title
        permissionCategories {
          id
          title
          queries
          mutations
          subscriptions
        }
      }
    }
  }
`

export default TWO_FACTOR_CHECK
