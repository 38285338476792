import gql from 'graphql-tag'

const EDIT_FAQ = gql`
  mutation editFAQ(
    $id: String!
    $question: String
    $answer: String
    $published: Boolean
  ) {
    editFAQ(
      id: $id
      question: $question
      answer: $answer
      published: $published
    ) {
      id
      question
      answer
      published
    }
  }
`

export default EDIT_FAQ
