import {
  Button,
  Card,
  Switch,
  FormGroup,
  H5,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from '@blueprintjs/core'
import { DateRangeInput3 } from '@blueprintjs/datetime2'
import { FieldArray } from 'formik'
import moment from 'moment'
import { Fragment } from 'react'
import shortid from '@utils/shortid'
import { checkForBlankInterval } from '../../../utils/helpers'
import AvailabilityTimeSelect from '../../Restaurant/Menu/modals/AvailabilityTimeSelect'
import { Categories } from '@components/Restaurant/Details/Categories'
import { Query } from '@apollo/client/react/components'
import { get } from 'lodash'
import GET_ALLOWED_CATEGORIES from './queries/getAllowedCategories.query'
import { SegmentOrderBy } from '../../../types.generated'

const EditCategorySegmentForm = ({
  values,
  errors,
  isValid,
  dirty,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldError,
  marketplaceId,
  isSubmitting,
  categoryEmojiOrImage,
}) => {
  const BLANK_INTERVAL = {
    start: { day: '1', time: '00:00' },
    end: { day: '7', time: '23:59' },
    key: shortid.generate(),
  }

  const sortByAttributes = [
    { label: 'Random', value: SegmentOrderBy.RANDOM },
    { label: 'Alphabetical', value: SegmentOrderBy.ALPHABETICAL },
  ]

  return (
    <form onSubmit={handleSubmit} className="bp5-drawer-form">
      <div className={'bp5-drawer-content'}>
        <FormGroup
          label="Name / Title"
          labelInfo="(required)"
          helperText={errors && errors.name}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="name"
            type="text"
            value={values && values.name}
            onChange={handleChange}
            large
          />
        </FormGroup>
        <FormGroup helperText={'Should the title be displayed on the website?'}>
          <Switch
            name="showName"
            label="Show Header Title"
            checked={values.showName}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup
          label="Description"
          helperText={
            (errors && errors.description) ||
            "Description shows underneath your segment's title."
          }
          labelInfo="200 Character Limit (Optional)"
          intent={errors && errors.description ? Intent.DANGER : Intent.NONE}
        >
          <TextArea
            onChange={handleChange}
            name="description"
            style={{ resize: 'none', height: '60px' }}
            fill={true}
            id="description"
            value={values.description || ''}
          />
        </FormGroup>

        <FormGroup
          label="Sorting"
          helperText={'Controls the order of the Categories in the segment.'}
        >
          <HTMLSelect
            name={`orderBy`}
            options={[
              ...sortByAttributes.map(attribute => ({
                ...attribute,
                key: attribute.value,
              })),
            ]}
            value={values.orderBy}
            onChange={handleChange}
          />
        </FormGroup>
        <br />
        <FormGroup
          labelFor="cuisines"
          label="Categories"
          style={{ marginBottom: 5 }}
          helperText={
            errors.cuisines
              ? errors.cuisines
              : 'Tags allows grouping similar businesses in filters and views.'
          }
          intent={errors.cuisines ? Intent.DANGER : null}
        >
          <Query query={GET_ALLOWED_CATEGORIES} variables={{ marketplaceId }}>
            {({ data }) => {
              const cuisines = get(data, 'getAllowedCategories', [])
              return cuisines ? (
                <Categories
                  setFieldValue={setFieldValue}
                  allCategories={cuisines}
                  categories={values.categories ? values.categories : []}
                  preferEmoji={categoryEmojiOrImage === 'EMOJI'}
                />
              ) : null
            }}
          </Query>
        </FormGroup>
        <br />
        <H5>Schedule</H5>
        <RadioGroup
          name="limitedAvailability"
          label="Segment Availability Date Range"
          inline={false}
          onChange={e => {
            setFieldValue('limitedAvailability', e.target.value)
          }}
          selectedValue={values.limitedAvailability}
        >
          <Radio label="Always Available" value="ALWAYS_AVAILABLE" />
          <Radio label="Scheduled" value="SCHEDULED" />
        </RadioGroup>
        {values.limitedAvailability === 'SCHEDULED' && (
          <FormGroup
            labelFor="availabilityDates"
            helperText={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? errors.availabilityStartDate || errors.availabilityEndDate
                : 'Only show this segment between certain dates.'
            }
            intent={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? Intent.DANGER
                : Intent.NONE
            }
          >
            <DateRangeInput3
              id="availabilityDates"
              formatDate={date => moment(date).format('DD/MM/YYYY HH:mm')}
              allowSingleDayRange={true}
              closeOnSelection={true}
              shortcuts={false}
              onChange={dates => {
                setFieldValue(
                  'availabilityStartDate',
                  moment(dates[0]).startOf('day').toDate()
                )
                setFieldValue(
                  'availabilityEndDate',
                  moment(dates[1]).endOf('day').toDate()
                )
              }}
              value={[values.availabilityStartDate, values.availabilityEndDate]}
              parseDate={str => new Date(str)}
            />
          </FormGroup>
        )}
        <br />
        <FormGroup
          labelFor="availabilityTimes"
          label="Please select the availability times for this segment"
          helperText="Schedule your segment to be shown between certain times."
        />
        <FieldArray
          name="availabilityTimes"
          render={({ push, remove }) => (
            <Fragment>
              <FormGroup>
                <Card className="bp5-nopad">
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>Day</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.availabilityTimes &&
                        values.availabilityTimes.map((time, index) => (
                          <AvailabilityTimeSelect
                            key={time.key}
                            onChange={handleChange}
                            index={index}
                            availabilityTimes={values.availabilityTimes}
                            errors={errors}
                            remove={remove}
                            fieldName="availabilityTimes"
                            setFieldValue={setFieldValue}
                          />
                        ))}
                    </tbody>
                  </HTMLTable>
                </Card>
                <Button
                  text="Add New Time"
                  minimal={true}
                  icon="plus"
                  intent={Intent.SUCCESS}
                  onClick={() =>
                    checkForBlankInterval(
                      values.availabilityTimes,
                      setFieldError,
                      push,
                      BLANK_INTERVAL,
                      'availabilityTimes'
                    )
                  }
                />
              </FormGroup>
            </Fragment>
          )}
        />
      </div>
      <div className="bp5-drawer-footer-actions">
        <Button
          intent={Intent.NONE}
          text="Save"
          loading={isSubmitting}
          disabled={!isValid && dirty}
          type="submit"
        />
      </div>
    </form>
  )
}

export default EditCategorySegmentForm
