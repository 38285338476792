import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomersQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  postcodePartial?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stripeCustomerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  marketplaceIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  orderBy?: Types.InputMaybe<Types.CustomerOrderByInput>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers: { __typename?: 'CustomersResponse', count?: number | null, totalCount?: number | null, customers?: Array<{ __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, failedAttempts?: number | null, email: string, createdAt: string, updatedAt: string, stripeCustomerId?: string | null, totalValue?: number | null, lastOrderAt?: string | null, totalCompletedOrders?: number | null, marketplace: { __typename?: 'RegionalMarketplace', id: string, name: string }, deliveryAddress?: Array<{ __typename?: 'Address', id: string, city: string, postcode: string }> | null, reviews?: Array<{ __typename?: 'Review', id: string }> | null }> | null } };


export const CustomersDocument = gql`
    query customers($skip: Int, $first: Int, $last: Int, $email: String, $name: String, $phoneNumber: String, $postcodePartial: String, $stripeCustomerId: String, $marketplaceIds: [ID!], $orderBy: CustomerOrderByInput) {
  customers(
    skip: $skip
    first: $first
    last: $last
    orderBy: $orderBy
    marketplaceIds: $marketplaceIds
    postcodePartial: $postcodePartial
    email: $email
    name: $name
    phoneNumber: $phoneNumber
    stripeCustomerId: $stripeCustomerId
  ) {
    count
    totalCount
    customers {
      id
      firstName
      lastName
      failedAttempts
      email
      createdAt
      updatedAt
      marketplace {
        id
        name
      }
      deliveryAddress {
        id
        city
        postcode
      }
      stripeCustomerId
      totalValue
      lastOrderAt
      totalCompletedOrders
      reviews {
        id
      }
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      postcodePartial: // value for 'postcodePartial'
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *      marketplaceIds: // value for 'marketplaceIds'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export function useCustomersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersSuspenseQueryHookResult = ReturnType<typeof useCustomersSuspenseQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;