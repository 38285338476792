const buildIndexFromPath = path => {
  const pathValue = {
    0: 10000,
    1: 100,
    2: 1,
  }
  const pathIndex = path.reduce((acc, pathPosition, index) => {
    const incrementPathPositonOne = pathPosition + 1
    acc = acc + incrementPathPositonOne * pathValue[index]
    return acc
  }, 0)
  return pathIndex
}

export default buildIndexFromPath
