import gql from 'graphql-tag'

const GET_MENU_ITEMS = gql`
  query getMenuItems($id: String!, $restaurantId: String!) {
    getMenuItemsGroups(id: $id) {
      menuItemsGroups {
        id
        name
        addOnItemsMenu
        menuItems {
          id
          name
          position
          optionPositions
        }
      }
    }

    getRestaurants(id: $restaurantId) {
      restaurants {
        id
        allowDefaultMinimumPurchasePercentage
        defaultMinimumPurchasePricePercentage
        outlets {
          id
          soldOutMenuItemIds
          soldOutUntilItems
        }
      }
    }
  }
`

export default GET_MENU_ITEMS
