import gql from 'graphql-tag'

const EDIT_USER = gql`
  mutation editUser(
    $id: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $roleKey: String
    $roleTitle: String
    $ownsPlatformIds: [String]
    $ownsPartnerIds: [String]
    $ownsRestaurantIds: [String]
    $ownsOutletIds: [String]
    $ownsMarketplaceIds: [String]
  ) {
    editUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      roleKey: $roleKey
      roleTitle: $roleTitle
      ownsPlatformIds: $ownsPlatformIds
      ownsPartnerIds: $ownsPartnerIds
      ownsRestaurantIds: $ownsRestaurantIds
      ownsOutletIds: $ownsOutletIds
      ownsMarketplaceIds: $ownsMarketplaceIds
    ) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        role {
          id
          title
        }
        ownsPlatforms {
          id
        }
        ownsPartners {
          id
        }
        ownsRestaurants {
          id
        }
        ownsOutlets {
          id
        }
        ownsMarketplaces {
          id
        }
      }
    }
  }
`

export default EDIT_USER
