import set from 'lodash/set'

export const whereConstructor = (queryVars, where) => {
  if (queryVars) {
    const { customerId, outletId } = queryVars

    if (customerId) {
      set(where, 'customer', { id: customerId })
    } else if (outletId) {
      set(where, 'outlet', { id: outletId })
    }
  }
}
