import gql from 'graphql-tag'

const EDIT_LOYALTY_CARD = gql`
  mutation editLoyaltyCard(
    $discountId: String!
    $loyaltyCardId: String!
    $name: String
    $discountPercentage: Float
    $discountAmount: Int
    $allowedOutletIds: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $daysOfWeek: [Int!]
    $minimumSubtotalGross: Int
    $requiredStamps: Int!
    $active: Boolean
    $discountStrategy: LoyaltyCardDiscountStrategy!
    $loyaltyCardIcon: String
    $loyaltyCardColor: String
    $termsAndConditions: String
    $allowCashOrders: Boolean!
  ) {
    editLoyaltyCard(
      discountId: $discountId
      loyaltyCardId: $loyaltyCardId
      name: $name
      discountPercentage: $discountPercentage
      discountAmount: $discountAmount
      allowedOutletIds: $allowedOutletIds
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
      minimumSubtotalGross: $minimumSubtotalGross
      requiredStamps: $requiredStamps
      active: $active
      discountStrategy: $discountStrategy
      loyaltyCardIcon: $loyaltyCardIcon
      loyaltyCardColor: $loyaltyCardColor
      termsAndConditions: $termsAndConditions
      allowCashOrders: $allowCashOrders
    ) {
      message
    }
  }
`

export default EDIT_LOYALTY_CARD
