import {
  AnchorButton,
  Button,
  Callout,
  Card,
  FormGroup,
  Intent,
  Switch,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Formik } from 'formik'
import first from 'lodash/first'
import { Fragment } from 'react'

import PercentageInput from '@components/PercentageInput/PercentageInput'
import Query from '@components/Query/Query'
import { VATFormCore } from '@components/Vat/VATFormCore'
import { canView, isAtLeastMarketplaceUser } from '@stores/userStore'
import { numberToPence, penceToPounds } from '@utils/helpers'
import { successToast } from '@utils/toast'
import { matchType } from '@utils/types'
import CurrencyInput from '../../CurrencyInput/CurrencyInput'
import FinancialsForm from './RestaurantFinancialsForm/RestaurantFinancialsForm'
import EDIT_RESTAURANT_ADD_ON_MENUS from './mutations/editRestaurantAddOnMenus.mutation'
import EDIT_RESTAURANT_FINANCIALS from './mutations/editRestaurantFinancials.mutation'
import EDIT_RESTAURANT_VAT from './mutations/editRestaurantVAT.mutation'
import GET_RESTAURANT_FINANCIALS from './queries/getRestaurantFinancials.query'
import { useParams } from 'react-router-dom'
import { Row, Col } from '@components/_FlexGrid'
import { useMutation } from '@apollo/client'

const Financials = () => {
  const { restaurants: id } = useParams()

  const [editRestaurantFinancials] = useMutation(EDIT_RESTAURANT_FINANCIALS, {
    onCompleted: ({ editRestaurantFinancials }) => {
      successToast(editRestaurantFinancials.message)
    },
    onError: defaultErrorHandler,
  })

  const [editRestaurantVAT] = useMutation(EDIT_RESTAURANT_VAT, {
    onCompleted: () => successToast('VAT Updated'),
    onError: defaultErrorHandler,
  })

  const [editRestaurantAddOnMenus] = useMutation(EDIT_RESTAURANT_ADD_ON_MENUS, {
    onCompleted: () => successToast('Financial Settings Updated'),
    onError: defaultErrorHandler,
  })

  return (
    <Query
      query={GET_RESTAURANT_FINANCIALS}
      variables={{ id }}
      loaderTitle={'Loading Financials'}
    >
      {({ getRestaurants: { restaurants } }) => {
        const business = first(restaurants)

        if (!business) return 'Business Not Found.'

        const initialValues = {
          id: business.id,
          partnerFee: business.partnerFee,
          partnerTableFee: business.partnerTableFee,
          partnerCharge:
            business.partnerCharge !== null
              ? penceToPounds(business.partnerCharge)
              : null,
          partnerTableCharge:
            business.partnerTableCharge !== null
              ? penceToPounds(business.partnerTableCharge)
              : null,
          merchantCommissionCharge:
            business.merchantCommissionCharge !== null
              ? penceToPounds(business.merchantCommissionCharge)
              : null,
          merchantTableCommissionCharge:
            business.merchantTableCommissionCharge !== null
              ? penceToPounds(business.merchantTableCommissionCharge)
              : null,
        }

        return (
          <Fragment>
            {!business.active && (
              <Fragment>
                <Callout intent={Intent.DANGER} icon="ban-circle">
                  Deactivated Business, Outlets will not be visible to
                  Customers.
                </Callout>
                <br />
              </Fragment>
            )}
            <Row gutter={24}>
              <Col sm={12} lg={8}>
                {canView('marketplaces') && (
                  <Card>
                    <FinancialsForm
                      onSubmit={values => {
                        editRestaurantFinancials({
                          variables: {
                            ...values,
                            partnerFee:
                              values.partnerFee !== null
                                ? values.partnerFee
                                : null,
                            partnerTableFee:
                              values.partnerTableFee !== null
                                ? values.partnerTableFee
                                : null,
                            partnerCharge:
                              values.partnerCharge !== null
                                ? numberToPence(values.partnerCharge)
                                : null,
                            partnerTableCharge:
                              values.partnerTableCharge !== null
                                ? numberToPence(values.partnerTableCharge)
                                : null,
                            merchantCommissionCharge:
                              values.merchantCommissionCharge !== null
                                ? numberToPence(values.merchantCommissionCharge)
                                : null,
                            merchantTableCommissionCharge:
                              values.merchantTableCommissionCharge !== null
                                ? numberToPence(
                                    values.merchantTableCommissionCharge
                                  )
                                : null,
                          },
                        })
                      }}
                      initialValues={initialValues}
                    />
                  </Card>
                )}
                <Card>
                  <h5 className="bp5-heading">Taxes</h5>
                  <Formik
                    onSubmit={values => {
                      editRestaurantVAT({
                        variables: {
                          ...values,
                        },
                      })
                    }}
                    initialValues={{
                      id: business.id,
                      isVATregistered: business.isVATregistered,
                      vatNumber: business.vatNumber,
                    }}
                  >
                    {({
                      errors,
                      handleChange,
                      values,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <VATFormCore
                          errors={errors}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                        />
                        <div className="bp-card-footer-actions">
                          <Button text="Save" type="submit" />
                          <AnchorButton
                            text="Tax & VAT"
                            href="https://support.redbox.systems/docs/vat-handling"
                            target="_blank"
                            rel="noopener noreferrer"
                            rightIcon="help"
                            intent={Intent.PRIMARY}
                            minimal
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </Card>

                <Fragment>
                  <Card>
                    <Formik
                      enableReinitialize={true}
                      onSubmit={values => {
                        editRestaurantAddOnMenus({
                          variables: {
                            ...values,
                            allowDefaultMinimumPurchasePercentage: Boolean(
                              values.allowDefaultMinimumPurchasePercentage ||
                                values.allowDefaultMinimumPurchasePercentage ===
                                  ['on']
                            ),

                            minimumBasketTotalForAddOns:
                              values.minimumBasketTotalForAddOns !== null
                                ? numberToPence(
                                    values.minimumBasketTotalForAddOns
                                  )
                                : null,
                          },
                        })
                      }}
                      initialValues={{
                        id: business.id,
                        allowDefaultMinimumPurchasePercentage:
                          business.allowDefaultMinimumPurchasePercentage,
                        defaultMinimumPurchasePricePercentage:
                          business.defaultMinimumPurchasePricePercentage || 10,
                        minimumBasketTotalForAddOns: penceToPounds(
                          business.minimumBasketTotalForAddOns || 0
                        ),
                      }}
                    >
                      {({
                        handleChange,
                        values,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <h5 className="bp5-heading">
                            Minimum Purchase Price
                          </h5>
                          <Switch
                            defaultChecked={
                              values.allowDefaultMinimumPurchasePercentage
                            }
                            name="allowDefaultMinimumPurchasePercentage"
                            label="Set Default Minimum Purchase Price Percentage"
                            onChange={handleChange}
                          />
                          <FormGroup
                            style={{ marginLeft: '38px' }}
                            label=""
                            labelFor="defaultMinimumPurchasePricePercentage"
                            helperText="Default minimum purchase price (percentage below gross price)"
                          >
                            <PercentageInput
                              value={
                                values.defaultMinimumPurchasePricePercentage
                              }
                              handleChange={values =>
                                setFieldValue(
                                  'defaultMinimumPurchasePricePercentage',
                                  values.floatValue
                                )
                              }
                              fill
                              disabled={
                                !values.allowDefaultMinimumPurchasePercentage
                              }
                            />
                          </FormGroup>
                          <FormGroup
                            label="Minimum Basket Total to Display Add On Items"
                            labelFor="minimumBasketTotalForAddOns"
                            helperText="The minimum amount a customer must have in their basket to be offered add on purchases"
                          >
                            <CurrencyInput
                              defaultValue={values.minimumBasketTotalForAddOns}
                              onUpdate={value =>
                                setFieldValue(
                                  'minimumBasketTotalForAddOns',
                                  value
                                )
                              }
                              fill
                            />
                          </FormGroup>
                          <div className="bp-card-footer-actions">
                            <Button
                              text="Save"
                              intent={Intent.NONE}
                              type="Save"
                            />
                            {isAtLeastMarketplaceUser() && (
                              <AnchorButton
                                text="Add-On Items"
                                href="https://support.redbox.systems/docs/add-on-sale-menu-and-check-out-feature"
                                target="_blank"
                                rel="noopener noreferrer"
                                rightIcon="help"
                                intent={Intent.PRIMARY}
                                minimal
                              />
                            )}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Card>
                </Fragment>
              </Col>
              <Col sm={12} lg={4}>
                <Callout title="Payment Processing" icon="credit-card">
                  <p>
                    Setup your merchant account on each outlet. Funds can be
                    directly deposited into your account.
                  </p>
                  <AnchorButton
                    alignText="left"
                    href={`/business/${business.id}/outlets`}
                    rightIcon="arrow-right"
                    fill
                    outlined
                    intent={Intent.NONE}
                    style={{ margin: '4px 0px 4px 0px' }}
                  >
                    View Outlets
                  </AnchorButton>
                </Callout>
                <br />
                <Callout
                  title="Business Set Up Guides"
                  icon="help"
                  intent={Intent.PRIMARY}
                >
                  <p>
                    Discover our step-by-step guides on setting up your business
                    the right way and begin taking orders with confidence.
                  </p>
                  <AnchorButton
                    alignText="left"
                    href={`https://support.redbox.systems/docs/business-financial-settings`}
                    rightIcon="share"
                    style={{ margin: '4px 0px 4px 0px' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    intent={Intent.PRIMARY}
                  >
                    View Documentation & Guide
                  </AnchorButton>
                </Callout>
              </Col>
            </Row>
          </Fragment>
        )
      }}
    </Query>
  )
}

Financials.propTypes = {
  match: matchType,
}

export default Financials
