import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryNetworkFailureReportByPartnerQueryVariables = Types.Exact<{
  partnerId: Types.Scalars['String']['input'];
  deliveryNetworkProvider: Types.Scalars['String']['input'];
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetDeliveryNetworkFailureReportByPartnerQuery = { __typename?: 'Query', getDeliveryNetworkFailureReportByPartner?: Array<{ __typename?: 'DeliveryNetworkFailureReportByPartner', id: string, totalOrders30Days?: number | null, totalValue30Days?: number | null, totalFailedOrders30Days?: number | null, totalFailedValue30Days?: number | null, failureRate30Days?: number | null, totalOrders60Days?: number | null, totalValue60Days?: number | null, totalFailedOrders60Days?: number | null, totalFailedValue60Days?: number | null, failureRate60Days?: number | null, totalOrders90Days?: number | null, totalValue90Days?: number | null, totalFailedOrders90Days?: number | null, totalFailedValue90Days?: number | null, failureRate90Days?: number | null, marketplace: { __typename?: 'RegionalMarketplace', id: string, name: string }, deliveryNetwork?: { __typename?: 'DeliveryNetwork', id: string, name: string, deliveryProvider: Types.DeliveryProvidersEnum } | null }> | null };


export const GetDeliveryNetworkFailureReportByPartnerDocument = gql`
    query getDeliveryNetworkFailureReportByPartner($partnerId: String!, $deliveryNetworkProvider: String!, $orderBy: String) {
  getDeliveryNetworkFailureReportByPartner(
    partnerId: $partnerId
    deliveryNetworkProvider: $deliveryNetworkProvider
    orderBy: $orderBy
  ) {
    id
    marketplace {
      id
      name
    }
    deliveryNetwork {
      id
      name
      deliveryProvider
    }
    totalOrders30Days
    totalValue30Days
    totalFailedOrders30Days
    totalFailedValue30Days
    failureRate30Days
    totalOrders60Days
    totalValue60Days
    totalFailedOrders60Days
    totalFailedValue60Days
    failureRate60Days
    totalOrders90Days
    totalValue90Days
    totalFailedOrders90Days
    totalFailedValue90Days
    failureRate90Days
  }
}
    `;

/**
 * __useGetDeliveryNetworkFailureReportByPartnerQuery__
 *
 * To run a query within a React component, call `useGetDeliveryNetworkFailureReportByPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryNetworkFailureReportByPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryNetworkFailureReportByPartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      deliveryNetworkProvider: // value for 'deliveryNetworkProvider'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetDeliveryNetworkFailureReportByPartnerQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables> & ({ variables: GetDeliveryNetworkFailureReportByPartnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>(GetDeliveryNetworkFailureReportByPartnerDocument, options);
      }
export function useGetDeliveryNetworkFailureReportByPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>(GetDeliveryNetworkFailureReportByPartnerDocument, options);
        }
export function useGetDeliveryNetworkFailureReportByPartnerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>(GetDeliveryNetworkFailureReportByPartnerDocument, options);
        }
export type GetDeliveryNetworkFailureReportByPartnerQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByPartnerQuery>;
export type GetDeliveryNetworkFailureReportByPartnerLazyQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByPartnerLazyQuery>;
export type GetDeliveryNetworkFailureReportByPartnerSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByPartnerSuspenseQuery>;
export type GetDeliveryNetworkFailureReportByPartnerQueryResult = Apollo.QueryResult<GetDeliveryNetworkFailureReportByPartnerQuery, GetDeliveryNetworkFailureReportByPartnerQueryVariables>;