import React, { useEffect, useContext, useState } from 'react'
import { NonIdealState, Card, Spinner, Drawer, Button } from '@blueprintjs/core'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Query } from '@apollo/client/react/components'
import CustomerSegmentsTable from './CustomerSegmentsTable'
import EditSegment from './Form/EditSegment'
import CreateSegmentModal from './Form/CreateSegmentModal'
import GET_SEGMENTS_FOR_MARKETPLACE from '../queries/getSegmentsForMarketplace.query'

import MarketingDialog from '@components/Billing/dialogs/Marketing.dialog'
import FilterRow from '@components/FilterRow/FilterRow'
import { Pager } from '@components/Toolbar'
import GET_MARKETPLACE_BILLING_STATUS_BY_ID from '../queries/getMarketplaceBilling.query'
import { StringParam, useQueryParam } from 'use-query-params'
import productDictionary from '../../../utils/productDictionary'
import { useParams } from 'react-router-dom'

const DEFAULT_RECORDS = 20

const CustomerSegmentsContainer = () => {
  const [viewSegment, onChangeViewSegment] = useQueryParam(
    'viewSegment',
    StringParam
  )
  const { id } = useParams()
  const { configurePageLayout, dark } = useContext(PageLayoutContext)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [forceRefetchCounter, setForceRefetchCounter] = useState(0)
  const [segmentsPagination, setSegmentsPagination] = useState<{
    total: number | null
    skip: number
    first: number
    last: number | null
    defaultNmbRecords: number
    outcomeLength: number | null
    navigationDisabled: boolean
  }>({
    total: null,
    skip: 0,
    first: DEFAULT_RECORDS,
    last: null,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
  })
  const { id: marketplaceId } = useParams()

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.MARKETING,
      tabs: [
        { to: `/marketing/${marketplaceId}/dashboard`, name: 'Dashboard' },
        { to: `/marketing/${marketplaceId}/segments`, name: 'Segments' },
        { to: `/marketing/${marketplaceId}/campaigns`, name: 'Campaigns' },
        {
          to: `/marketing/${marketplaceId}/meta-business`,
          name: 'Meta Business',
        },
      ],
      button: {
        text: 'Create Segment',
        onClick: () => setCreateModalOpen(true),
      },
    })
  }, [configurePageLayout, history])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      segmentsPagination.skip + segmentsPagination.first <
      (segmentsPagination.total ?? 0)
    ) {
      setSegmentsPagination({
        ...segmentsPagination,
        skip: limitNext(
          segmentsPagination.skip,
          segmentsPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor: number, amount: number, limit) => {
    const skip = currentCursor + amount
    return limit < segmentsPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setSegmentsPagination({
      ...segmentsPagination,
      skip: limitPrevious(segmentsPagination.skip, segmentsPagination.first),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    const skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const setFilterState = (count, totalCount) => {
    if (segmentsPagination.total !== totalCount) {
      setSegmentsPagination({
        ...segmentsPagination,
        total: totalCount,
      })
    }
    if (segmentsPagination.outcomeLength !== count) {
      setSegmentsPagination({
        ...segmentsPagination,
        outcomeLength: count,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setSegmentsPagination({
      ...segmentsPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <Pager
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={segmentsPagination.skip}
          total={segmentsPagination.total}
          outcomeLength={segmentsPagination.outcomeLength}
          totalCount={segmentsPagination.total}
          dataName="Segments"
        />
      </FilterRow>
      <Query
        query={GET_SEGMENTS_FOR_MARKETPLACE}
        variables={{
          marketplaceId: id,
          skip: segmentsPagination.skip,
          first: segmentsPagination.first,
        }}
      >
        {({ data, loading, error = null, refetch }) => {
          if (loading) {
            return (
              <NonIdealState
                title="Loading Segments"
                description="Please wait..."
                icon={<Spinner size={60} />}
              />
            )
          }

          if (error) {
            return (
              <NonIdealState
                title="Failed to connect to notification service"
                icon="error"
              />
            )
          }

          const {
            getSegments: { segments, count, totalCount },
          } = data

          setFilterState(count, totalCount)

          if (!segments || !segments.length) {
            return (
              <NonIdealState
                title="No Segments Found"
                description="This marketplace has no customer segments."
                icon="intersection"
                action={
                  <Button onClick={() => setCreateModalOpen(true)} icon="plus">
                    Create Segment
                  </Button>
                }
              />
            )
          }

          return (
            <div className="bp5-table-container bp5-scrollable">
              <Card className="bp5-nopad">
                <CustomerSegmentsTable
                  data={segments}
                  setEditSegmentId={onChangeViewSegment}
                  marketplaceId={id}
                  refetchSegments={refetch}
                />
              </Card>
            </div>
          )
        }}
      </Query>
      <Drawer
        title="Edit Segment"
        isOpen={viewSegment}
        onClose={() => onChangeViewSegment(undefined)}
        className={dark ? 'bp5-dark' : ''}
      >
        <EditSegment
          dark={dark}
          marketplaceId={id}
          closeDrawer={() => {
            onChangeViewSegment(undefined)
            setForceRefetchCounter(forceRefetchCounter + 1)
          }}
          id={viewSegment}
        />
      </Drawer>
      <Query
        query={GET_MARKETPLACE_BILLING_STATUS_BY_ID}
        variables={{
          marketplaceId: id,
        }}
      >
        {({ data, error, loading }) => {
          // not sure about just returning null here if there is an error but it was already there
          // added loading as it was crashing on my machine. A loading spinner still shows elsewhere
          if (error || loading) {
            return null
          }

          if (data.getMarketplaces) {
            const marketplace = data.getMarketplaces.regions[0]

            if (
              !marketplace.allowBilling ||
              (marketplace.allowBilling &&
                marketplace.billingStatus &&
                marketplace.billingStatus.services.includes('MARKETING'))
            ) {
              return (
                <CreateSegmentModal
                  marketplaceId={id}
                  modalOpen={createModalOpen}
                  closeModal={id => {
                    setCreateModalOpen(false)
                    if (id.length != undefined) {
                      setForceRefetchCounter(forceRefetchCounter + 1)
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                      onChangeViewSegment(id)
                    }
                  }}
                />
              )
            } else {
              return (
                <MarketingDialog
                  marketplaceId={id}
                  isOpen={createModalOpen}
                  paywall
                  onClose={() => setCreateModalOpen(false)}
                />
              )
            }
          } else {
            return null
          }
        }}
      </Query>
    </div>
  )
}

export default CustomerSegmentsContainer
