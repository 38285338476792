import gql from 'graphql-tag'

const REINVITE_USER = gql`
  mutation reinviteUser($id: String!) {
    reinviteUser(id: $id) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        role {
          id
          title
        }
        ownsPlatforms {
          id
        }
        ownsPartners {
          id
        }
        ownsRestaurants {
          id
        }
        ownsOutlets {
          id
        }
        ownsMarketplaces {
          id
        }
      }
    }
  }
`

export default REINVITE_USER
