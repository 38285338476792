import gql from 'graphql-tag'

const GET_RESTAURANT_NAMES_WITH_CONTACTS_FOR_MARKETPLACE = gql`
  query getRestaurantNamesWithContactsForMarketplace(
    $marketplaceIds: [String!]
  ) {
    getRestaurantNamesWithContactsForMarketplace(
      marketplaceIds: $marketplaceIds
    ) {
      id
      name
    }
  }
`

export default GET_RESTAURANT_NAMES_WITH_CONTACTS_FOR_MARKETPLACE
