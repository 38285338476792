import React from 'react'
import { Title, CenterSection } from '../OrderPrintout'

const AgeRestriction = ({ order: { customerOrderNotes } }) => (
  <CenterSection>
    <Title as="h2">Age Verification Required</Title>
  </CenterSection>
)

export default AgeRestriction
