export const PaymentNetwork = {
  // Stripe networkStatus
  approved_by_network: {
    intent: 'success',
    title: 'Approved by Network',
    icon: 'small-tick',
    details: 'The transaction has been approved by the payment network.',
  },
  declined_by_network: {
    intent: 'danger',
    title: 'Declined by Network',
    icon: 'cross',
    details:
      'The transaction has been declined by the payment network due to insufficient funds or other reasons.',
  },
  pending_network_response: {
    intent: 'warning',
    title: 'Pending Network Response',
    icon: 'small-tick',
    details: 'The transaction is awaiting a response from the payment network.',
  },
  reversed_by_network: {
    intent: 'danger',
    title: 'Reversed by Network',
    icon: 'refresh',
    details: 'The transaction has been reversed by the payment network.',
  },
  not_sent_to_network: {
    intent: 'none',
    title: 'Not Sent to Network',
    icon: 'offline',
    details:
      'The transaction has not been sent to the payment network for processing.',
  },
  authorized_but_pending_capture: {
    intent: 'success',
    title: 'Authorized but Pending Capture',
    icon: 'small-tick',
    details:
      'The transaction has been authorized by the payment network but is pending capture.',
  },
  captured_by_network: {
    intent: 'success',
    title: 'Captured by Network',
    icon: 'small-tick',
    details: 'The transaction has been captured by the payment network.',
  },
  network_error: {
    intent: 'danger',
    title: 'Network Error',
    icon: 'error',
    details:
      'There was an error processing the transaction with the payment network.',
  },
  unknown_network_status: {
    intent: 'none',
    title: 'Unknown Network Status',
    icon: 'help',
    details: 'The network status of the transaction is unknown.',
  },
  // Card Failures
  card_declined: {
    minimal: false,
    intent: 'danger',
    title: 'Card Declined',
    icon: 'cross',
    details:
      'The card was declined by the issuer. This could be due to insufficient funds, card restrictions, or other issues. Advise the customer to use a different payment method or contact their card issuer.',
  },
  expired_card: {
    minimal: false,
    intent: 'danger',
    title: 'Expired Card',
    icon: 'cross',
    details:
      'The card has expired. Request the customer to use a different card with a valid expiration date.',
  },
  incorrect_cvc: {
    minimal: false,
    intent: 'danger',
    title: 'Incorrect CVC',
    icon: 'cross',
    details:
      'The CVC code entered is incorrect. Verify the customer provides the correct CVC associated with the card.',
  },
  incorrect_zip: {
    minimal: false,
    intent: 'danger',
    title: 'Incorrect ZIP',
    icon: 'cross',
    details:
      'The ZIP code entered does not match the card issuer’s records. Verify the customer’s billing address and update if necessary.',
  },
  insufficient_funds: {
    minimal: false,
    intent: 'danger',
    title: 'Insufficient Funds',
    icon: 'cross',
    details:
      'The card has insufficient funds to complete the transaction. Ask the customer to use a different payment method or add funds to their account.',
  },
  invalid_card_type: {
    minimal: false,
    intent: 'danger',
    title: 'Invalid Card Type',
    icon: 'cross',
    details:
      'The card type is not supported for this transaction. Request the customer to use a different card type.',
  },
  processing_error: {
    minimal: false,
    intent: 'danger',
    title: 'Processing Error',
    icon: 'error',
    details:
      'An error occurred while processing the card. This may be a temporary issue. Please retry the transaction.',
  },
  incorrect_number: {
    minimal: false,
    intent: 'danger',
    title: 'Incorrect Number',
    icon: 'cross',
    details:
      'The card number entered is incorrect. Verify the card number and ensure it is entered correctly.',
  },
  invalid_expiry_month: {
    minimal: false,
    intent: 'danger',
    title: 'Invalid Expiry Month',
    icon: 'cross',
    details:
      'The expiry month entered is invalid. Verify the customer card’s expiration date and try again.',
  },
  invalid_expiry_year: {
    minimal: false,
    intent: 'danger',
    title: 'Invalid Expiry Year',
    icon: 'cross',
    details:
      'The expiry year entered is invalid. Verify the customer card’s expiration date and try again.',
  },
  invalid_cvc: {
    minimal: false,
    intent: 'danger',
    title: 'Invalid CVC',
    icon: 'cross',
    details:
      'The CVC code entered is invalid. Verify the customer card’s CVC code and ensure it is entered correctly.',
  },
  incorrect_pin: {
    minimal: false,
    intent: 'danger',
    title: 'Incorrect PIN',
    icon: 'cross',
    details:
      'The PIN entered is incorrect. Verify the customer card’s PIN and ensure it is entered correctly.',
  },
  card_not_supported: {
    minimal: false,
    intent: 'danger',
    title: 'Card Not Supported',
    icon: 'cross',
    details:
      'The card is not supported for this type of transaction. Request the customer to use a different card.',
  },
  authentication_failure: {
    minimal: false,
    intent: 'danger',
    title: 'Authentication Failure',
    icon: 'lock',
    details:
      'The authentication for the payment intent failed. This could be due to issues with 3D Secure authentication or other authentication methods required by the card issuer. Please advise the customer to complete the authentication process or use a different payment method.',
  },
}
