import React from 'react'
import { RadioGroup, Spinner } from '@blueprintjs/core'
import { string, func } from 'prop-types'

import config from '@src/config'
import ls from '@utils/localStorage'
import Query from '@components/Query/Query'
import { useContext } from 'react'
import appPageContext from '@components/Apps/appPageContext'
import GET_MARKETPLACE_APP_CONFIG from '@components/Apps/queries/getMarketplaceAppConfig.query'
import first from 'lodash/first'
import useFetch from '../../utils/useFetch'

const isVersionValid = (minAppVersion, currentAppVersion) => {
  // E.g. 3.5.7 - Major = 3, Minor = 5, Patch = 7
  // As the version number is a string, we want to split it down to each one individually
  const [minAppMajor, minAppMinor, minAppPatch] = minAppVersion
    .split('.')
    .map(value => parseInt(value))
  const currentAppVersionSplit =
    !!currentAppVersion && currentAppVersion.split('.')

  if (parseInt(currentAppVersionSplit[0]) > minAppMajor) {
    // Major version is greater than min. Allow it
    return true
  } else if (parseInt(currentAppVersionSplit[1]) > minAppMinor) {
    // Major version is same or less. The Minor version greater than the min. Allow it
    return parseInt(currentAppVersionSplit[0]) === minAppMajor
  } else if (parseInt(currentAppVersionSplit[2]) > minAppPatch) {
    // Major & Minor version is same or less. The Patch version greater than the min. Allow it
    return (
      parseInt(currentAppVersionSplit[0]) === minAppMajor &&
      parseInt(currentAppVersionSplit[1]) === minAppMinor
    )
  } else if (
    parseInt(currentAppVersionSplit[0]) === minAppMajor &&
    parseInt(currentAppVersionSplit[1]) === minAppMinor &&
    parseInt(currentAppVersionSplit[2]) === minAppPatch
  ) {
    // It's the same version
    return true
  }
  return false
}

const GitTagSelect = ({ onChange, selectedValue }) => {
  const environmentDomain = config.environmentDomain
  const { marketplaceId } = useContext(appPageContext)
  const { data, loading } = useFetch(
    `https://app-build.${environmentDomain}/git/refs`,
    {
      headers: { Authorization: `Bearer ${ls.get('jwt')}` },
    }
  )
  return (
    <Query
      query={GET_MARKETPLACE_APP_CONFIG}
      variables={{ marketplaceId }}
      fetchPolicy={'network-only'}
      loaderTitle={'Getting App Config'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)

        return loading ? (
          <Spinner size={20} />
        ) : (
          <RadioGroup
            name="version"
            onChange={onChange}
            selectedValue={selectedValue}
            options={data.tags
              .filter(tag => {
                return isVersionValid(marketplace.minAppVersionSupported, tag)
              })
              .map(tag => ({ label: tag, value: tag }))}
          />
        )
      }}
    </Query>
  )
}

GitTagSelect.propTypes = {
  onChange: func,
  selectedValue: string,
}

export default GitTagSelect
