import gql from 'graphql-tag'

const LOYALTY_CARD_LIABILITY_BREAKDOWN = gql`
  query loyaltyCardLiabilityForAnalytics($discountId: String!) {
    loyaltyCardLiabilityForAnalytics(discountId: $discountId) {
      liabilityBreakdown
      totalDiscountEarned
    }
  }
`

export default LOYALTY_CARD_LIABILITY_BREAKDOWN
