import {
  Button,
  ButtonGroup,
  Card,
  ControlGroup,
  HTMLTable,
  Icon,
  NonIdealState,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import { Fragment, useContext, useEffect, useState } from 'react'

import Currency from '@components/Currency/Currency'
import OrderStatusInfo from '@components/Orders/Order/OrderStatusInfo'
import OutletSelect from '@components/OutletSelect/OutletSelect'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { canView } from '@stores/userStore'
import { removeUnderscores } from '@utils/helpers'
import { errorToast } from '@utils/toast'
import { get, upperFirst } from 'lodash'
import moment from 'moment'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import orderSound from '@assets/sounds/notification.mp3'
import {
  getHumanReadableStatus,
  getIntent,
} from '@components/Orders/helpers/helpers'
import Sound, { TWO_MINUTES_AS_MILISECONDS } from '@components/Sound/Sound'
import { Pager } from '@components/Toolbar'
import GET_TERMINAL_ORDERS from './queries/getTerminalOrders.query'

import FilterRow from '@components/FilterRow/FilterRow'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'
import PrintOrderModal from '@components/Orders/Order/PrintOrder.modal'
import SettingsToggle from '@components/Toolbar/SettingsToggle'
import { isInAlarm } from '@utils/order/isInAlarm'
import { StringParam, useQueryParams } from 'use-query-params'
import { useLocalStorage } from 'usehooks-ts'
import CloseOrReopen from '../../components/CloseOrReopen/CloseOrReopen'
import { useModal } from '../../providers/ModalProvider'
import productDictionary from '../../utils/productDictionary'
import { Query } from '@apollo/client/react/components'

const DELIVERY_PROVIDER_TO_TAG_ICON = {
  ICABBI: 'taxi',
  STUART: 'cycle',
  UBER_DIRECT: 'taxi',
}
const DEFAULT_RECORDS = 25

const PageLayoutWrapper = ({
  totalPending,
  totalPreparing,
  totalReady,
  totalCompleted,
  totalOrders,
  children,
  outletId,
}) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    const search = outletId ? `?outletId=${outletId}` : ''
    configurePageLayout({
      product: productDictionary.VIRTUAL_TERMINAL,
      tabs: [
        {
          to: '/virtual-terminal/pending' + search,
          /* + location.search*/ name: 'Pending',
          count: totalPending,
          intent: 'danger',
        },
        {
          to: '/virtual-terminal/preparing' + search /* + location.search*/,
          name: 'In Kitchen',
          count: totalPreparing,
        },
        {
          to: '/virtual-terminal/ready' + search /* + location.search*/,
          name: 'Ready',
          count: totalReady,
        },
        {
          to: '/virtual-terminal/complete' + search /* + location.search*/,
          name: 'Complete',
          count: totalCompleted,
        },
        {
          to: '/virtual-terminal/all' + search /* + location.search*/,
          name: 'All',
          count: totalOrders,
        },
      ],
    })
  }, [
    configurePageLayout,
    totalPending,
    totalPreparing,
    totalReady,
    totalCompleted,
    totalOrders,
  ])

  return children
}

const Orders = () => {
  const { statusFilter } = useParams()
  const [{ id = null }, setQueryParams] = useQueryParams({
    id: StringParam,
    viewOrder: StringParam,
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const outletId = searchParams.get('outletId')

  const [muteAlarms, setMuteAlarms] = useLocalStorage<boolean>(
    'muteAlarms',
    false
  )

  const [showDeliveryWindow, setShowDeliveryWindow] = useLocalStorage<boolean>(
    'showDeliveryWindow',
    false,
    {
      serializer: value => String(value),
      deserializer: str => str === 'true',
    }
  )

  const [state, setState] = useState<{
    total: number
    skip: number
    first: number
    last: number | null
    defaultNmbRecords: number
    outcomeLength: null | number
    navigationDisabled: boolean
    selectedId: string | null
    orderBy: string
    orderStatus: string[]
    after: string
  }>({
    total: 0,
    skip: 0,
    first: DEFAULT_RECORDS,
    last: null,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
    selectedId: id,
    orderBy: 'createdAt_ASC',
    orderStatus: ['PENDING'],
    after: moment()
      .subtract(1, 'day')
      .endOf('day')
      .subtract(7, 'days')
      .format('YYYYMMDDTHHmm'),
  })

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({
        ...prevState,
        total: totalCount,
      }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({
        ...prevState,
        outcomeLength: returnCount,
      }))
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor: number, amount: number, limit) => {
    const skip = currentCursor + amount
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    const skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  const filterOrderStatus = (
    filter: string
  ): { orderStatus: string[]; orderBy: string } => {
    let orderStatus = ['PENDING']

    let orderBy = 'createdAt_DESC'

    switch (filter) {
      case 'pending':
        orderStatus = ['AWAITING_PAYMENT', 'AUTH_ACTION_PENDING', 'PENDING']
        orderBy = 'createdAt_ASC'
        break
      case 'preparing':
        orderStatus = ['PREPARING']
        orderBy = 'createdAt_DESC'
        break
      case 'ready':
        orderStatus = ['READY']
        orderBy = 'updatedAt_ASC'
        break
      case 'complete':
        orderStatus = ['COMPLETE']
        orderBy = 'updatedAt_ASC'
        break
      case 'all':
        orderStatus = [
          'PENDING',
          'PREPARING',
          'READY',
          'AWAITING_DELIVERY_NETWORK',
          'DELIVERY_NETWORK_FAILURE',
          'COMPLETE',
          'REJECTED',
          'DELIVERY_FAILURE_REFUND',
          'ORDER_FAILURE_REFUND',
          'REFUND_REQUESTED',
          'REFUND_CANCELLED',
          'REFUND_RELEASED',
          'REFUND_FAILED',
        ]
        orderBy = 'createdAt_DESC'
        break
    }

    return {
      orderStatus,
      orderBy,
    }
  }

  useEffect(() => {
    const filteredOrderStatus = filterOrderStatus(statusFilter)
    if (filteredOrderStatus) {
      setState(state => ({ ...state, ...filteredOrderStatus }))
    }
  }, [statusFilter])

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Button
            icon={
              <Icon
                icon={'symbol-circle'}
                color={outletId ? '#5bb70d' : '#CDD6DD'}
              />
            }
            disabled
          >
            {outletId ? 'ONLINE' : 'OFFLINE'}
          </Button>
          <OutletSelect
            showMarketplaceName
            showRestaurantName
            isOnlineOverride
            placeholder="Outlet  [ Select to Connect ]"
            outletId={outletId}
            onChange={outletId => {
              if (outletId === null) {
                setSearchParams()
              } else {
                setSearchParams({ outletId })
              }
            }}
          />
          {outletId && <CloseOrReopen outletId={outletId} />}
        </ControlGroup>
      </ButtonGroup>

      <ButtonGroup>
        <SettingsToggle
          simpleView
          isMuted={muteAlarms}
          showDeliveryWindow={showDeliveryWindow}
          updateState={setting => {
            switch (setting) {
              case 'muteAlarms':
                setMuteAlarms(!muteAlarms)
                break
              case 'showDeliveryWindow':
                setShowDeliveryWindow(!showDeliveryWindow)
                break
            }
          }}
        />
        <Pager
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={state.skip}
          total={state.total}
          outcomeLength={state.outcomeLength}
          dataName="Orders"
        />
      </ButtonGroup>
    </FilterRow>
  )

  const afterDate = moment(state.after, 'YYYYMMDDTHHmm')

  const { isOpen } = useModal('printOrderModal')

  return (
    <>
      <PrintOrderModal open={isOpen} />
      <Query
        pollInterval={60000}
        query={GET_TERMINAL_ORDERS}
        variables={{
          outletId: outletId,
          afterDate: afterDate,
          orderStatuses: Array.isArray(state.orderStatus)
            ? state.orderStatus
            : [state.orderStatus],
          ...state,
        }}
        fetchPolicy="network-only"
        skip={!outletId}
        onCompleted={data => {
          if (data.orders) {
            setTotalCount(data.orders.totalCount, data.orders.count)
          }
        }}
      >
        {({ loading, error = undefined, data, networkStatus = 0 }) => {
          const refetchQueries = [
            {
              query: GET_TERMINAL_ORDERS,
              variables: {
                outletId: outletId,
                afterDate: afterDate,
                ...state,
              },
            },
          ]

          if (loading) {
            return (
              <NonIdealState
                icon={<Spinner size={60} />}
                title="Loading Orders"
                description="Please wait..."
              />
            )
          }

          const {
            totalOrders = 0,
            totalPending = 0,
            totalPreparing = 0,
            totalReady = 0,
            totalCompleted = 0,
          } = (data && data.orders) || {}

          let orders = []
          if (!error && data && data.orders) {
            orders = data.orders.orders.filter(order => order.orderStatus)
          }

          return (
            <PageLayoutWrapper
              totalPending={totalPending}
              totalPreparing={totalPreparing}
              totalReady={totalReady}
              totalCompleted={totalCompleted}
              totalOrders={totalOrders}
              outletId={outletId}
            >
              {error &&
                errorToast(
                  networkStatus === 8
                    ? 'Cannot connect to server, please retry.'
                    : error.message
                )}

              {!muteAlarms && !!totalPending && (
                <Sound
                  src={orderSound}
                  repeatInterval={TWO_MINUTES_AS_MILISECONDS}
                />
              )}

              <div className="bp5-table-frame">
                {renderFilterBar()}
                {!error && !outletId ? (
                  <NonIdealState
                    icon="offline"
                    title="Virtual Terminal - Offline"
                    description="Select an outlet and receive orders to your browser."
                  />
                ) : !error && orders.length === 0 ? (
                  <Fragment>
                    <NonIdealState
                      icon="stopwatch"
                      title="Waiting for Orders"
                      description="When you receive an order it will appear here."
                    />
                  </Fragment>
                ) : (
                  <div className="bp5-table-container bp5-scrollable">
                    <Card className="bp5-nopad">
                      <HTMLTable bordered={false} interactive={true}>
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Customer</th>
                            <th>Total</th>
                            <th>Creation</th>
                            <th colSpan={2}>Status</th>
                            <th>Last Update</th>
                            <th>Fulfilment</th>
                            {showDeliveryWindow && <td>Delivery Window</td>}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!error &&
                            orders.map(order => {
                              const isAlarm = isInAlarm(
                                order.orderStatus,
                                order.autoRejectAt
                              )

                              const customerName = `${upperFirst(
                                order.customer.firstName
                              )} ${upperFirst(
                                order.customer.lastName.charAt(0)
                              )}`

                              return (
                                <tr key={order.id}>
                                  <td>
                                    <a
                                      onClick={() => {
                                        setQueryParams({
                                          viewOrder: order.id,
                                        })
                                      }}
                                    >
                                      #{order.orderNumber}
                                    </a>
                                  </td>
                                  <td>
                                    {canView('customers') ? (
                                      <Link
                                        to={`/customer/${order.customer.id}`}
                                      >
                                        {customerName}
                                      </Link>
                                    ) : (
                                      customerName
                                    )}
                                  </td>
                                  <td>
                                    <Icon
                                      icon="credit-card"
                                      color={
                                        order.paymentMethod.split('_')[0] ===
                                        'CARD'
                                          ? '#30404d'
                                          : '#CDD6DD'
                                      }
                                    />{' '}
                                    <Currency amount={order.grossTotal} />
                                  </td>
                                  <td>
                                    <span
                                      style={isAlarm ? { color: 'red' } : {}}
                                    >
                                      {moment(order.createdAt).format('HH:mm')}
                                    </span>
                                    &nbsp;
                                    {moment().isSame(order.createdAt, 'day')
                                      ? ''
                                      : moment(order.createdAt).format('D/MM')}
                                  </td>
                                  <td>
                                    {isAlarm ? (
                                      <Tag minimal={false} intent={'danger'}>
                                        {getHumanReadableStatus(
                                          order.orderStatus,
                                          order.createdAt,
                                          order.asap
                                        )}
                                      </Tag>
                                    ) : (
                                      <Tag
                                        minimal={true}
                                        intent={getIntent(
                                          getHumanReadableStatus(
                                            order.orderStatus,
                                            order.createdAt,
                                            order.asap
                                          )
                                        )}
                                      >
                                        {getHumanReadableStatus(
                                          order.orderStatus,
                                          order.createdAt,
                                          order.asap
                                        )}
                                      </Tag>
                                    )}
                                  </td>
                                  <td>
                                    {order.fulfillmentMethod === 'NETWORK' && (
                                      <Tag
                                        icon={
                                          DELIVERY_PROVIDER_TO_TAG_ICON[
                                            order.deliveryNetworkProvider
                                          ] || 'taxi'
                                        }
                                        intent={'primary'}
                                        minimal
                                      >
                                        {removeUnderscores(
                                          order.deliveryNetworkBookingStatus
                                        )}
                                      </Tag>
                                    )}
                                    {order.fulfillmentMethod === 'TABLE' && (
                                      <Tag
                                        icon={'locate'}
                                        intent={'primary'}
                                        minimal
                                      >
                                        {`Table ${get(
                                          order.tableSnapshot,
                                          'friendlyName',
                                          'unknown'
                                        ).replace(/^table\s/i, '')}`}
                                      </Tag>
                                    )}
                                  </td>
                                  <td>{moment(order.updatedAt).fromNow()}</td>
                                  <td>
                                    <FulfilmentStatus
                                      orderStatus={order.orderStatus}
                                      fulfillmentMethod={
                                        order.fulfillmentMethod
                                      }
                                      createdAt={order.createdAt}
                                      updatedAt={order.updatedAt}
                                      selectedDeliveryWindow={
                                        order.selectedDeliveryWindow
                                      }
                                      estimatedDeliveryDate={
                                        order.selectedDeliveryWindow
                                          ? order.selectedDeliveryWindow.end
                                          : order.estimatedDeliveryDate
                                      }
                                      estimatedCompletionTime={
                                        order.selectedDeliveryWindow
                                          ? order.selectedDeliveryWindow.start
                                          : order.estimatedCompletionTime
                                      }
                                      asap={order.asap}
                                      tableFriendlyName={get(
                                        order.tableSnapshot,
                                        'friendlyName',
                                        'unknown'
                                      )}
                                    />
                                  </td>
                                  {showDeliveryWindow && (
                                    <td>
                                      {order.selectedDeliveryWindow ? (
                                        <Fragment>
                                          {order.selectedDeliveryWindow.start
                                            ? moment(
                                                order.selectedDeliveryWindow
                                                  .start
                                              ).format('HH:mm')
                                            : order.estimatedCompletionTime
                                            ? moment(
                                                order.estimatedCompletionTime
                                              ).format('HH:mm')
                                            : null}
                                          {' - '}
                                          {moment(
                                            order.selectedDeliveryWindow.end
                                          ).format('HH:mm')}
                                        </Fragment>
                                      ) : order.estimatedDeliveryDate ? (
                                        moment(
                                          order.estimatedDeliveryDate
                                        ).format('HH:mm')
                                      ) : null}
                                    </td>
                                  )}
                                  <td>
                                    <OrderStatusInfo
                                      review={state.orderStatus.includes(
                                        'PENDING'
                                      )}
                                      onReview={() => {
                                        setQueryParams({
                                          viewOrder: order.id,
                                        })
                                      }}
                                      order={order}
                                      minimal={true}
                                      refetchQueries={refetchQueries}
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </div>
                )}
              </div>
            </PageLayoutWrapper>
          )
        }}
      </Query>
    </>
  )
}

export default Orders
