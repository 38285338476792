import React, { createContext, useEffect, useState } from 'react'

// Helper functions

function move(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    newIndex = array.length - 1
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return array
}

function moveElement(array, index, offset) {
  const newIndex = index + offset

  return move(array, index, newIndex)
}

// Context

const GridContext = createContext({ items: [], moveItem: () => {} })

export const GridProvider = props => {
  const [items, setItems] = useState(props.items)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const moveItem = (sourceFrom, sourceTo) => {
    const sourceIndex = items.findIndex(item => item.src === sourceFrom)
    const destinationIndex = items.findIndex(item => item.src === sourceTo)

    // If source/destination is unknown, do nothing.
    if (sourceFrom === -1 || sourceTo === -1) {
      return
    }

    const offset = destinationIndex - sourceIndex

    const movedArray = moveElement(items, sourceIndex, offset)

    props.setImages([...movedArray])
  }

  return (
    <GridContext.Provider value={{ items, moveItem }}>
      {props.children}
    </GridContext.Provider>
  )
}

export default GridContext
