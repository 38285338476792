import gql from 'graphql-tag'

const ONBOARDING_INFO = gql`
  query onboardingInfo($marketplaceKey: String!) {
    onboardingInfo(marketplaceKey: $marketplaceKey) {
      onboardingDescription
      logoImage
      heroImage
      cname
      enableCustomerV2Client
    }
  }
`

export default ONBOARDING_INFO
