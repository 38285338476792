import gql from 'graphql-tag'

const DISCONNECT_STRIPE = gql`
  mutation disconnectStripe($stripeId: String!, $marketplaceId: ID!) {
    disconnectStripe(stripeId: $stripeId, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export default DISCONNECT_STRIPE
