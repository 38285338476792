import { Button, Popover, Position } from '@blueprintjs/core'
import { DatePicker3 } from '@blueprintjs/datetime2'
import {
  add,
  differenceInDays,
  endOfDay,
  format,
  min,
  addDays,
  subDays,
} from 'date-fns'
import { isFuture } from 'date-fns'
import React, { Fragment } from 'react'
import DatePickerButton from './DatePickerButton'
import { YEARLESS_FORMAT } from '../../../utils/datetime'

const CustomRangeSelector = ({
  startOfRangeDate,
  startOfRangeDateToDisplay,
  endOfRangeDate,
  endOfRangeDateToDisplay,
  onChangeRange,
  maxCustomRangeInterval,
  minimal = false,
  disabled = false,
}) => (
  <Fragment>
    {
      // startOfRange selector
    }
    <Button
      rightIcon="caret-left"
      disabled={disabled}
      minimal={minimal}
      onClick={() => {
        onChangeRange({
          start: subDays(startOfRangeDate, 1),
          end: subDays(endOfRangeDate, 1),
        })
      }}
    />
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <DatePicker3
          value={startOfRangeDate}
          maxDate={endOfDay(new Date())}
          highlightCurrentDay
          onChange={(selectedDate, isUserChange) => {
            if (selectedDate && isUserChange) {
              // move startOfRangeDate to start of selectedDate and endOfRangeDate by the same amount of days
              onChangeRange({
                start: selectedDate,
                end: addDays(
                  endOfRangeDate,
                  differenceInDays(selectedDate, startOfRangeDate)
                ),
              })
            }
          }}
        />
      }
    >
      <DatePickerButton minimal={minimal} disabled={disabled}>
        {format(startOfRangeDateToDisplay, YEARLESS_FORMAT)}
      </DatePickerButton>
    </Popover>

    <Button disabled={true} minimal={minimal}>
      to
    </Button>

    {
      // endOfRange selector
    }
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <DatePicker3
          value={endOfRangeDate}
          minDate={endOfDay(startOfRangeDate)}
          highlightCurrentDay
          maxDate={min([
            add(endOfDay(startOfRangeDate), maxCustomRangeInterval),
            endOfDay(new Date()),
          ])}
          onChange={(selectedDate, isUserChange) => {
            if (selectedDate && isUserChange) {
              onChangeRange({
                start: startOfRangeDate,
                end: endOfDay(selectedDate),
              })
            }
          }}
        />
      }
    >
      <DatePickerButton minimal={minimal} disabled={disabled}>
        {format(endOfRangeDateToDisplay, YEARLESS_FORMAT)}
      </DatePickerButton>
    </Popover>

    <Button
      rightIcon="caret-right"
      minimal={minimal}
      onClick={() => {
        onChangeRange({
          start: addDays(startOfRangeDate, 1),
          end: addDays(endOfRangeDate, 1),
        })
      }}
      disabled={disabled || isFuture(add(startOfRangeDate, { days: 1 }))}
    />
  </Fragment>
)

export default CustomRangeSelector
