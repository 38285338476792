import config from '@src/config'
import { omit, startCase } from 'lodash'

export const createAwsOptions = () => {
  let marketingAccounts
  try {
    marketingAccounts = JSON.parse(config.marketingAccounts)
  } catch (error) {
    console.error(
      'Failed to parse marketing accounts from environment variable',
      error
    )
  }
  if (!config.awsPinpointAccountId) {
    console.error('AWS_PINPOINT_ACCOUNT_ID not found')

    return [{ value: '', label: 'Default' }]
  }

  const marketingAccount = marketingAccounts[config.awsPinpointAccountId]

  const AWSdefault = {
    value: marketingAccount,

    label: `${marketingAccount} (${startCase(config.awsPinpointAccountId)})`,
  }

  return [
    AWSdefault,
    { value: '', label: 'Default' },
    ...Object.entries(omit(marketingAccounts, config.awsPinpointAccountId)).map(
      ([key, value]) => ({
        value,
        label: `${key} (${startCase(key)})`,
      })
    ),
  ]
}
