import { MenuDivider, MenuItem } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Fragment } from 'react'
import ADD_OPTION from '../Menu/mutations/addOption.mutation'
import GET_OPTIONS from '../Menu/queries/getOptions.query'
import { useMutation } from '@apollo/client'

const DuplicateMenuOption = ({ restaurantId, option }) => {
  const [addOption] = useMutation(ADD_OPTION, {
    onError: defaultErrorHandler,
    onCompleted: ({ addOption }) => successToast(addOption.message),
    refetchQueries: [
      {
        query: GET_OPTIONS,
        variables: {
          restaurantId,
        },
      },
    ],
  })

  return (
    <Fragment>
      <MenuDivider />
      <MenuItem
        text="Duplicate Option"
        icon="duplicate"
        onClick={() => {
          const { name, minOptions, maxOptions, optionItems } = option
          const newOptionItems = optionItems.map(optionItem => {
            return {
              name: optionItem.name,
              price: optionItem.price,
              soldOut: optionItem.soldOut || false,
            }
          })
          const newOption = {
            name,
            minOptions,
            maxOptions,
            restaurantId,
            optionItems: newOptionItems,
          }
          addOption({ variables: newOption })
        }}
      />
    </Fragment>
  )
}

export default DuplicateMenuOption
