import gql from 'graphql-tag'

const ANALYTICS_ENABLED = gql`
  query getAnalyticsFlag($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        enableAnalytics
      }
    }
  }
`

export default ANALYTICS_ENABLED
