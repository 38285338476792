import React from 'react'
import { Icon } from '@blueprintjs/core'
import { bool } from 'prop-types'

const OnlineStatusFlower = ({ isOnlineOverride }) => {
  let intent = 'none'
  if (isOnlineOverride === true) {
    intent = 'success'
  } else if (isOnlineOverride === false) {
    intent = 'none'
  }

  return <Icon icon="flash" intent={intent} />
}

OnlineStatusFlower.propTypes = {
  isOnlineOverride: bool,
}

export default OnlineStatusFlower
