import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowOrderToTableForm = ({
  updateStatus,
  allowOrderToTable,
  allowedToChange = true,
}) => {
  return (
    <Formik initialValues={{ allowOrderToTable }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Table Ordering'}
            checked={values.allowOrderToTable}
            onChange={e => {
              allowedToChange && handleChange(e)
              updateStatus({
                allowOrderToTable: e.currentTarget.checked,
              })
            }}
            name="allowOrderToTable"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowOrderToTableForm.propTypes = {
  allowOrderToTable: bool,
  allowedToChange: bool,
  errors: object,
}

export default MarketplaceAllowOrderToTableForm
