import gql from 'graphql-tag'

const EDIT_MENU_ITEM_OPTIONS = gql`
  mutation editMenuItemOptions($id: String!, $optionIds: [String]) {
    editMenuItem(id: $id, optionIds: $optionIds) {
      message
      menuItem {
        id
        options {
          name
          id
          optionItems {
            id
            name
          }
        }
      }
    }
  }
`
export default EDIT_MENU_ITEM_OPTIONS
