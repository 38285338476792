import React, { useState } from 'react'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import { Switch, Classes, Button } from '@blueprintjs/core'
import { func, string, bool } from 'prop-types'

const AppBuildConfirmation = ({
  modalOpen,
  modalClose,
  handleSubmit,
  version,
  loading,
}) => {
  const [checkOptions, updateCheckOptions] = useState({
    applePay: false,
    placeOrder: false,
    register: false,
    login: false,
    logout: false,
    addAddress: false,
    useLocation: false,
  })

  const Check = ({ title, prop }) => {
    return (
      <Switch
        style={{
          marginTop: '0.5rem',
        }}
        label={title}
        name="active"
        defaultChecked={checkOptions[prop]}
        large
        onChange={() => {
          updateCheckOptions({ ...checkOptions, [prop]: !checkOptions[prop] })
        }}
      />
    )
  }

  const enableButton = () => Object.values(checkOptions).every(Boolean)

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title={`${version} Play/App Store Upload Prerequisite`}
    >
      <div style={{ marginLeft: 12, marginRight: 12, marginTop: 24 }}>
        <p style={{ marginBottom: 24 }}>
          Please confirm the below functionality has been tested in {version}{' '}
          prior to this release. This list covers the MVP functionality of the
          app.
        </p>
        <Check title={'Can create an account'} prop={'register'} />
        <Check title={'Can login'} prop={'login'} />
        <Check title={'Can logout'} prop={'logout'} />
        <Check title={'Can add an address to account'} prop={'addAddress'} />
        <Check title={'Can search via devices location'} prop={'useLocation'} />
        <Check title={'Can place an order'} prop={'placeOrder'} />
        <Check title={'Can place an order via Apple Pay'} prop={'applePay'} />
        <p style={{ fontSize: 10, fontStyle: 'italic', marginTop: 24 }}>
          NOTE: Confirming will create a new build and upload it to the App/Play
          Store. If an issue is found after the upload of this version an
          increment to the version number is required to upload a new version.
        </p>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Confirm"
            disabled={!enableButton()}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  )
}

AppBuildConfirmation.propTypes = {
  ...modalType,
  handleSubmit: func,
  version: string,
  loading: bool,
}

export default AppBuildConfirmation
