import * as yup from 'yup'
import { editOutletStructure } from '../../../validation/outlet'
import { address, optionalAddress } from '../../../validation/address'
import { vatNumberStructure } from '../../../validation/vat-number'
import omit from 'lodash/omit'

export const validation = yup
  .object()
  .shape(omit(editOutletStructure, 'isOnlineOverride'))
  .concat(
    yup.object().shape({
      contactAddress: yup.object().shape(optionalAddress),
      outletAddress: yup.object().shape(address),
    })
  )

export const vatValidation = yup.object().shape(vatNumberStructure)
