import gql from 'graphql-tag'

const EDIT_TERMINAL = gql`
  mutation muteTerminal($id: String!, $mutedUntil: DateTime) {
    muteTerminal(id: $id, mutedUntil: $mutedUntil) {
      terminal {
        id
        isMuted
      }
    }
  }
`

export default EDIT_TERMINAL
