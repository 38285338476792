import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_OUTLET_NAME from './queries/getOutletName.query'
import GET_RESTAURANT_NAME from './queries/getRestaurantName.query'
import GET_TERMINAL_NAME from './queries/getTerminalName.query'

const isFirstPart = i => i === 0
const isSecondPart = i => i === 1
const isThirdPart = i => i === 2
const isFourthPart = i => i === 3
const isFifthPart = i => i === 4

const extractCrumbs = crumbs => {
  const [restaurantId, outletId, terminalId] = crumbs
  return { restaurantId, outletId, terminalId }
}

export const TerminalCrumbs = ({ crumbs }) => {
  const { restaurantId, outletId, terminalId } = extractCrumbs(crumbs)

  return crumbs.map((crumb, i) => {
    if (isFirstPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_RESTAURANT_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getRestaurants }) => {
            if (!getRestaurants) {
              return null
            }
            const { restaurants } = getRestaurants
            return (
              <Crumb
                key={crumb}
                text={restaurants.length ? restaurants[0].name : crumb}
                link={`/business/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isSecondPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/business/${restaurantId}/${crumb}`}
        />
      )
    }

    if (isThirdPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_OUTLET_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getOutlets }) => {
            if (!getOutlets) {
              return null
            }
            const { outlets } = getOutlets

            return (
              <Crumb
                key={crumb}
                text={outlets.length ? outlets[0].name : crumb}
                link={`/business/${restaurantId}/outlets/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isFourthPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/business/${restaurantId}/outlets/${outletId}/terminals`}
        />
      )
    }
    if (isFifthPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_TERMINAL_NAME}
          variables={{ ids: crumb }}
          showLoader={false}
        >
          {({ getTerminals }) => {
            if (!getTerminals) {
              return null
            }

            const { terminals } = getTerminals

            return (
              <Crumb
                key={crumb}
                text={terminals.length ? terminals[0].friendlyName : crumb}
                link={`/business/${restaurantId}/outlets/${outletId}/terminals/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    return (
      <Crumb
        key={crumb}
        text={crumb}
        link={`/business/${restaurantId}/outlets/${outletId}/terminals/${terminalId}`}
      />
    )
  })
}

export default TerminalCrumbs
