import { object, func } from 'prop-types'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { withFormik } from 'formik'
import { Card, Button } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'

import logo from '@assets/logo.svg'
import background from '@assets/background.jpg'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { ValidatePhoneMutation } from './mutations/validatePhone.mutation.generated'
import VALIDATE_PHONE from './mutations/validatePhone.mutation'

const Container = styled.div`
  display: flex;
  background-image: url(${background});
  background-size: cover;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-position: center;
`

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 340px;
  align-items: center;
  flex-direction: column;
`

const LogoContainer: React.FC<{ src: string; alt: string }> = styled(ReactSVG)`
  height: 111px;
  width: 89px;
`

const ValidatePhoneWrapper = props => {
  const navigate = useNavigate()

  const [validatePhone] = useMutation<ValidatePhoneMutation>(VALIDATE_PHONE, {
    onError: error => {
      const serverErrors = get(error, 'graphQLErrors', [])

      // fallback for errors that weren't returned by the server
      if (!serverErrors.length) {
        return errorToast(error.message)
      }

      // loop through custom server errors displaying error toast
      for (const serverError of serverErrors) {
        errorToast(serverError.message)
      }

      props.setSubmitting(false)
    },
    onCompleted: ({ validatePhone }) => {
      validatePhone && navigate('/2fa')
    },
  })

  return (
    <Container>
      <LoginContainer>
        <LogoContainer src={logo} alt={'logo'} />
        <Card elevation={2}>
          <div>
            <p>Your phone number needs validating before you can continue</p>
            <h3>{ls.get('phoneNumber')}</h3>
            <p>
              By continuing you confirm this is your phone number and you
              consent to receive SMS messages for two factor authentication
            </p>
            <Button
              type="submit"
              onClick={() => validatePhone()}
              text="Accept & Send Code"
            />
          </div>
        </Card>
      </LoginContainer>
    </Container>
  )
}

const ValidatePhone = withFormik({
  mapPropsToValues: () => ({
    code: '',
  }),
  handleSubmit: () => {},
})(ValidatePhoneWrapper)

ValidatePhone.propTypes = {
  history: object,
  setSubmitting: func,
}

export default ValidatePhone
export { ValidatePhone }
