import gql from 'graphql-tag'

const REMOVE_REVIEW = gql`
  mutation removeReview(
    $id: String!
  ) {
    removeReview(
      id: $id
    ) {
      message
    }
  }
`

export default REMOVE_REVIEW
