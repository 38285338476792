import gql from 'graphql-tag'

const GET_CUSTOMERS = gql`
  query getCustomerDetails($id: ID!) {
    customers(where: { id: $id }) {
      customers {
        id
        firstName
        lastName
        phoneNumber
        email
        stripeCustomerId
        totalValue
        totalCompletedOrders
        totalOrders
        createdAt
        lastOrderAt
        updatedAt
        invalidateToken
        marketplace {
          id
          name
          enableAnalytics
          deliveryZones {
            id
            geoFence
          }
        }
        paymentMethods
        deliveryAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          default
          geo
        }
        discounts {
          id
          name
          discountPercentage
          restaurant {
            id
          }
        }
      }
    }
  }
`

export default GET_CUSTOMERS
