import gql from 'graphql-tag'

const ADD_MENU_ITEMS_GROUP = gql`
  mutation addMenuItemsGroup(
    $name: String!
    $position: Int!
    $description: String!
    $restaurantId: String!
    $parentMenuId: String
    $image: String
    $availabilityStartDate: String
    $availabilityEndDate: String
    $availabilityTimes: Json
    $fulfillmentMethods: [MenuItemGroupFulfillmentMethod!]!
    $addOnItemsMenu: Boolean!
  ) {
    addMenuItemsGroup(
      name: $name
      position: $position
      description: $description
      restaurantId: $restaurantId
      parentMenuId: $parentMenuId
      image: $image
      availabilityStartDate: $availabilityStartDate
      availabilityEndDate: $availabilityEndDate
      availabilityTimes: $availabilityTimes
      fulfillmentMethods: $fulfillmentMethods
      addOnItemsMenu: $addOnItemsMenu
    ) {
      message
      menuItemsGroup {
        id
        name
        position
        description
        image
        fulfillmentMethods
        availabilityStartDate
        availabilityEndDate
        availabilityTimes
        addOnItemsMenu
        parentMenu {
          id
        }
      }
    }
  }
`

export default ADD_MENU_ITEMS_GROUP
