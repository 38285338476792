import { createContext, useContext, useState } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

interface MenuState {
  paneNumber: number
  itemMenuOpen: boolean
  // url state
  menuId?: string
  subMenuId?: string
  itemId?: string
  editMenu?: string
}

interface MenuStateContext {
  data: MenuState
  setValue: React.Dispatch<Partial<MenuState>>
}

const defaultContext: MenuStateContext = {
  setValue: () => {},
  data: {
    paneNumber: 0,
    itemMenuOpen: false,
  },
}

const MenuStateContext = createContext<MenuStateContext>(defaultContext)

export const MenuStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, _setValue] = useState<MenuState>(defaultContext.data)
  const [{ menuId, menuItemId, subMenuId, editMenu }, setQueryParams] =
    useQueryParams({
      menuId: StringParam,
      subMenuId: StringParam,
      menuItemId: StringParam,
      editMenu: StringParam,
    })

  const setValue = (value: Partial<MenuState>) => {
    const newValue = {
      ...data,
      menuId,
      itemId: menuItemId,
      subMenuId,
      editMenu,
      ...value,
    }
    _setValue(newValue)
    setQueryParams({
      subMenuId: newValue.subMenuId,
      menuId: newValue.menuId,
      menuItemId: newValue.itemId,
      editMenu: newValue.editMenu,
    })
  }

  return (
    <MenuStateContext.Provider
      value={{
        data: {
          ...data,
          menuId: menuId || '',
          itemId: menuItemId || '',
          subMenuId: subMenuId || '',
          editMenu: editMenu || '',
        },
        setValue,
      }}
    >
      {children}
    </MenuStateContext.Provider>
  )
}

export const useMenuState = () => useContext(MenuStateContext)
