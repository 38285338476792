import { WrapInLink } from '@src/utils/wrapInLink'
import { Change } from './Change'
import {
  BaseColumn,
  ColumnRenderer,
  ColumnSorter,
  FALLBACK_PLACEHOLDER,
} from './column.types'
import { CSSProperties } from 'react'

export interface NumericColumn<X extends string>
  extends BaseColumn<'numeric', X> {
  showChanges?: 'NONE' | 'PERCENTAGE' | 'ACTUAL'
  biggerIsWorse?: boolean
}

export interface NumericColumnData {
  value: number | null | undefined
  priorValue?: number | null
  link?: string
  style?: CSSProperties
}

export const sortNumericColumn: ColumnSorter<'numeric'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * ((b.value ?? -Infinity) - (a.value ?? -Infinity))
}

export const NumericColumnContent: ColumnRenderer<'numeric', string> = ({
  columnDefinition: {
    placeholder,
    showChanges = 'ACTUAL',
    biggerIsWorse = false,
  },
  columnData: { value, priorValue, link, style },
}) => {
  if (value === null || value === undefined)
    return placeholder ?? FALLBACK_PLACEHOLDER

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span style={style}>
        <WrapInLink to={link}>{value}</WrapInLink>
      </span>
      <Change
        value={value}
        priorValue={priorValue}
        numberStyle="numeric"
        biggerIsWorse={biggerIsWorse}
        asPercentage={showChanges === 'PERCENTAGE'}
        hide={!showChanges}
      />
    </div>
  )
}
