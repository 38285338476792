import React, { useEffect, useState } from 'react'
import { MenuItem, Position, Spinner, Intent } from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'

const TypeaheadSearch = ({
  items,
  initialSelectedItems,
  selected,
  setSelected,
  usingLiveData,
  onQueryChange,
  placeholder = 'Search...',
  noResults = 'No matches',
  selectedIcon = 'tick',
  unSelectedIcon = 'blank',
  disabled = false,
  intent,
}) => {
  // a 'fake' loading state that starts on keypress not query start
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    usingLiveData && setLoading(false)
  }, [usingLiveData])

  const isSelected = item => selected.includes(item.id)

  const ItemRenderer = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected(item) ? selectedIcon : unSelectedIcon}
        key={item.id}
        label={item.label || ''}
        onClick={handleClick}
        text={item.name}
        shouldDismissPopover={false}
        disabled={disabled}
      />
    )
  }

  return (
    <MultiSelect
      items={items}
      className={
        intent &&
        intent === Intent.DANGER &&
        'bp5-input-group bp5-intent-danger'
      }
      itemRenderer={ItemRenderer}
      itemPredicate={(query, item) =>
        // this allows us to filter on cached data while refetching
        // adding the label allows filtering on restaurant names
        `${item.name} ${item.label}`.toLowerCase().includes(query.toLowerCase())
      }
      itemsEqual={(a, b) => a.id === b.id}
      onItemSelect={item =>
        setSelected(
          isSelected(item)
            ? selected.filter(selItem => selItem !== item.id)
            : [...selected, item.id]
        )
      }
      tagRenderer={itemId => {
        const item = initialSelectedItems.find(({ id }) => id === itemId)
        return item ? item.name : ''
      }}
      tagInputProps={{
        rightElement: loading ? <Spinner size={20} /> : null,
        tagProps: {
          minimal: true,
          style: { opacity: disabled && 0.4 },
        },
        onRemove: itemName => {
          !disabled &&
            setSelected(
              selected.filter(id => {
                const item = initialSelectedItems.find(
                  ({ name }) => name === itemName
                )

                return item && id !== item.id
              })
            )
        },
      }}
      selectedItems={selected}
      popoverProps={{ position: Position.BOTTOM_LEFT, minimal: true }}
      resetOnSelect={true}
      placeholder={placeholder}
      onQueryChange={query => {
        !loading && setLoading(true)
        onQueryChange(query)
      }}
      noResults={
        <MenuItem
          icon="warning-sign"
          label={loading ? 'Loading' : noResults}
          disabled={true}
        />
      }
    />
  )
}

export default TypeaheadSearch
