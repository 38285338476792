import gql from 'graphql-tag'

const GET_CUSTOMER_NAME = gql`
  query getCustomerName($id: ID!) {
    customers(where: { id: $id }) {
      customers {
        id
        firstName
        lastName
      }
    }
  }
`

export default GET_CUSTOMER_NAME
