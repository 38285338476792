import gql from 'graphql-tag'

const GET_PINPOINT_MARKETPLACES = gql`
  query getPinpointMarketplaces(
    $search: String
    $partnerIds: [ID]
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: RegionalMarketplaceOrderByInput
  ) {
    getPinpointMarketplaces(
      search: $search
      partnerIds: $partnerIds
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      count
      totalCount
      regions {
        id
        name
        companyLegalName
        partner {
          id
          name
          partnerCommissionFee
        }
        pinpointApplicationId
        faviconImage
      }
    }
  }
`

export default GET_PINPOINT_MARKETPLACES
