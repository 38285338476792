import { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
  Position,
  Navbar,
  Tag,
  Icon,
} from '@blueprintjs/core'
import { get, upperCase } from 'lodash'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
// import * as Sentry from '@sentry/react'

import LOGOUT from './mutations/logoutUser.mutation'
import GET_USER_INFO from '@components/User/Details/queries/getUserInfo.query'

import {
  logout,
  canView,
  isOutletUser,
  isMenuEditor,
  isOutletFinancialUser,
  isMarketingUser,
} from '@stores/userStore'

import NavIcon from '@components/NavIcon/NavIcon'
import userIcon from '@assets/icons/user.svg'
import dashboardIcon from '@assets/icons/dashboard.svg'
import settingsIcon from '@assets/icons/settings.svg'
import businessesIcon from '@assets/icons/businesses.svg'
import marketplacesIcon from '@assets/icons/marketplaces.svg'
import partnersIcon from '@assets/icons/partners.svg'
import platformIcon from '@assets/icons/platform.svg'
import ordersIcon from '@assets/icons/bill.svg'
import customersIcon from '@assets/icons/customers.svg'
import reportsIcon from '@assets/icons/reports.svg'
import supportIcon from '@assets/icons/support.svg'
import terminalIcon from '@assets/icons/cash-register.svg'
import marketingIcon from '@assets/icons/marketing.svg'
import messagingIcon from '@assets/icons/messaging.svg'

import UserModal from '@components/User/User.modal'
import { useModal } from '../../providers/ModalProvider'
import { StatusPageTag } from './StatusPageTag'
import Query from '../Query/Query'
import { useMutation } from '@apollo/client'
import config from '../../config'

const SlimContainer = styled(Navbar)`
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.secondaryBg};
  padding-top: 12px;
  padding-bottom: 12px;
`

const SlimContainerTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const SlimContainerBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const NavContainer = styled.nav`
  display: flex;
  position: fixed;
  left: 0;
  top: 96px;
  height: calc(100% - 96px);
  z-index: 1;
`

const StyledNavLink = styled<typeof NavLink, { dark: boolean }>(NavLink)`
  color: ${({ theme, dark }) =>
    dark ? theme.darkColors.primaryNavColor : theme.colors.primaryNavColor};
  &:hover {
    color: ${({ theme, dark }) =>
      dark
        ? theme.darkColors.primaryNavHoverColor
        : theme.colors.primaryNavHoverColor};
  }
`

export const Nav = ({ dark }) => {
  const { switchDarkMode } = useContext(PageLayoutContext)
  const { isOpen, toggleModal } = useModal('profileModal')

  const userIsOutletUser = isOutletUser()
  const userIsMenuEditor = isMenuEditor()
  const userIsOutletFinancialUser = isOutletFinancialUser()
  const userIsMarketingUser = isMarketingUser()
  const userIsNotMenuEditorOrFinancialUser =
    !userIsMenuEditor && !userIsOutletFinancialUser

  const [logoutUser] = useMutation(LOGOUT, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      logout()
    },
  })

  return (
    <NavContainer>
      <Query query={GET_USER_INFO} showLoader={false}>
        {({ data }) => {
          const user = get(data, 'getMyDetails.user', null)

          let fullName =
            get(user, 'firstName', '') + ' ' + get(user, 'lastName', '')
          fullName = fullName.length > 0 ? fullName : 'Unknown'
          const roleName =
            get(user, 'role.title', '').replace(/Restaurant/i, 'Business') ||
            'Unknown Role'

          // Sentry.setUser({
          //   email: get(user, 'email', null),
          //   id: get(user, 'id', null),
          // })
          // Sentry.setContext('user', {
          //   role: get(user, 'role.title', null),
          //   name: fullName,
          //   phone: get(user, 'phoneNumber', null),
          // })

          return (
            <SlimContainer>
              <div>
                <SlimContainerTop>
                  {canView('restaurants') && !userIsOutletFinancialUser && (
                    <StyledNavLink to={'/dashboard'} dark={dark}>
                      <NavIcon
                        imagePath={dashboardIcon}
                        tooltipContent="Dashboard"
                        linkUrl="/dashboard/*"
                        dark={dark}
                        dataTestId="slim-container-dashboard-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('platform') && (
                    <StyledNavLink to="/platform" dark={dark}>
                      <NavIcon
                        imagePath={platformIcon}
                        tooltipContent="Platform"
                        linkUrl="/platform/*"
                        dark={dark}
                        dataTestId="slim-container-platform-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('partners') && (
                    <StyledNavLink to="/partners" dark={dark}>
                      <NavIcon
                        imagePath={partnersIcon}
                        tooltipContent="Partners"
                        linkUrl="/partners/*"
                        dark={dark}
                        dataTestId="slim-container-partners-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('marketplaces') && (
                    <StyledNavLink to="/marketplaces" dark={dark}>
                      <NavIcon
                        imagePath={marketplacesIcon}
                        tooltipContent="Marketplaces"
                        linkUrl="/marketplaces/*"
                        dark={dark}
                        dataTestId="slim-container-marketplaces-icon"
                      />
                    </StyledNavLink>
                  )}

                  {(canView('restaurants') ||
                    userIsOutletUser ||
                    userIsMenuEditor) && (
                    <StyledNavLink
                      to={
                        userIsOutletUser || userIsOutletFinancialUser
                          ? '/outlets'
                          : '/business'
                      }
                      dark={dark}
                      isActive={(match, location) => {
                        return [
                          'business',
                          'outlets',
                          'terminals',
                          'onboardingList',
                        ].includes(location.pathname.split('/')[1])
                      }}
                    >
                      <NavIcon
                        imagePath={businessesIcon}
                        tooltipContent="Businesses"
                        linkUrl={
                          userIsOutletUser || userIsOutletFinancialUser
                            ? '/outlets/*'
                            : '/business/*'
                        }
                        dark={dark}
                        dataTestId="slim-container-business-icon"
                      />
                    </StyledNavLink>
                  )}

                  {canView('orders') &&
                    userIsNotMenuEditorOrFinancialUser &&
                    !userIsMarketingUser && (
                      <StyledNavLink to="/orders">
                        <NavIcon
                          imagePath={ordersIcon}
                          tooltipContent="Orders"
                          linkUrl="/orders/*"
                          dark={dark}
                          dataTestId="slim-container-orders-icon"
                        />
                      </StyledNavLink>
                    )}
                  {canView('virtualTerminals') && (
                    <StyledNavLink to="/virtual-terminal" dark={dark}>
                      <NavIcon
                        imagePath={terminalIcon}
                        tooltipContent="Virtual Terminal"
                        linkUrl="/virtual-terminal/*"
                        dark={dark}
                        dataTestId="slim-container-virtual-terminal-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('messaging') && (
                    <StyledNavLink to="/messaging" dark={dark}>
                      <NavIcon
                        imagePath={messagingIcon}
                        tooltipContent="Messaging"
                        linkUrl="/messaging/*"
                        dark={dark}
                        dataTestId="slim-container-messaging-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('marketing') && (
                    <NavLink to="/marketing">
                      <NavIcon
                        imagePath={marketingIcon}
                        tooltipContent="Marketing"
                        linkUrl="/marketing/*"
                        dark={dark}
                        dataTestId="slim-container-marketing-icon"
                      />
                    </NavLink>
                  )}
                  {canView('customers') && (
                    <NavLink to="/customers">
                      <NavIcon
                        imagePath={customersIcon}
                        tooltipContent="Customers"
                        linkUrl="/customer*" // note the different format, this is because we have `/customer/:id` AND `/customers`
                        dark={dark}
                        dataTestId="slim-container-customer-icon"
                      />
                    </NavLink>
                  )}
                  {(canView('reports') || canView('sales')) && (
                    <StyledNavLink to="/reports" dark={dark}>
                      <NavIcon
                        imagePath={reportsIcon}
                        tooltipContent="Reports"
                        linkUrl="/reports/*"
                        dark={dark}
                        dataTestId="slim-container-reports-icon"
                      />
                    </StyledNavLink>
                  )}
                  {canView('admin') && (
                    <StyledNavLink to="/admin" dark={dark}>
                      <NavIcon
                        imagePath={settingsIcon}
                        tooltipContent="Administrator Settings"
                        linkUrl="/admin/*"
                        dark={dark}
                        dataTestId="slim-container-admin-icon"
                      />
                    </StyledNavLink>
                  )}
                </SlimContainerTop>
              </div>
              <div>
                <SlimContainerBottom>
                  <Popover
                    content={
                      <Menu>
                        <MenuItem
                          text="Release Notes"
                          target="_blank"
                          icon="clean"
                          rel="noopener noreferrer"
                          href="https://support.redbox.systems/docs/service-maintenance-release-notes"
                        />
                        <MenuDivider />
                        {user && (
                          <MenuItem text={'Support'} icon="help">
                            <MenuItem
                              icon="book"
                              text="Documentation"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://support.redbox.systems"
                            />

                            {user.supportUrl && (
                              <MenuItem
                                icon="lifesaver"
                                text={user.supportTitle}
                                href={user.supportUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                              />
                            )}
                            {user.supportEmail && (
                              <MenuItem
                                icon="envelope"
                                text="Email Support"
                                href={`mailto:${user.supportEmail}`}
                              />
                            )}
                          </MenuItem>
                        )}
                        <MenuItem
                          text="Redbox News"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://redbox.systems/blog"
                        />
                        <MenuDivider />
                        {!config.isProd && (
                          <MenuItem
                            text="Environment"
                            labelElement={
                              <Tag intent="warning">
                                {upperCase(config.environmentName || 'DEV')}
                              </Tag>
                            }
                            onClick={() =>
                              window.open(
                                'https://status.redbox.systems',
                                '_blank'
                              )
                            }
                          />
                        )}
                        <MenuItem
                          text="Status"
                          labelElement={<StatusPageTag />}
                          onClick={() =>
                            window.open(
                              'https://status.redbox.systems',
                              '_blank'
                            )
                          }
                        />
                      </Menu>
                    }
                    position={Position.RIGHT}
                  >
                    <NavIcon
                      className={'supportIcon'}
                      imagePath={supportIcon}
                      tooltipContent="Support"
                    />
                  </Popover>

                  <Popover
                    content={
                      <Menu>
                        {user && (
                          <Fragment>
                            <MenuItem
                              icon={'person'}
                              text={fullName}
                              labelElement={
                                <Tag minimal>{upperCase(roleName)}</Tag>
                              }
                              onClick={() => toggleModal(true)}
                            />
                            <MenuDivider />
                          </Fragment>
                        )}
                        <MenuItem
                          text={dark ? 'Light Mode' : 'Dark Mode'}
                          label={<Icon icon={dark ? 'flash' : 'moon'} />}
                          onClick={() => switchDarkMode(!dark)}
                        />
                        <MenuItem
                          text="Edit Profile"
                          onClick={() => toggleModal(true)}
                        />

                        <MenuItem text="Logout" onClick={() => logoutUser()} />
                      </Menu>
                    }
                    position={Position.RIGHT}
                  >
                    <NavIcon
                      pathNameToMatch="profile"
                      imagePath={userIcon}
                      tooltipContent="Profile"
                    />
                  </Popover>
                </SlimContainerBottom>
              </div>
            </SlimContainer>
          )
        }}
      </Query>
      <UserModal modalOpen={isOpen} modalClose={() => toggleModal(false)} />
    </NavContainer>
  )
}

Nav.propTypes = {
  children: object,
  location: object,
}

export default Nav
