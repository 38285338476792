import gql from 'graphql-tag'

const EDIT_OPENING_TIMES = gql`
  mutation editOpeningTimes($id: String!, $openingTimes: Json) {
    editOutlet(id: $id, openingTimes: $openingTimes) {
      outlet {
        id
        openingTimes
      }
    }
  }
`

export default EDIT_OPENING_TIMES
