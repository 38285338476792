import gql from 'graphql-tag'

const GET_OUTLET_PARENT_MENUS = gql`
  query getOutletParentMenus($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        openingTimes
        soldOutUntilItems
        hiddenMenuItemGroupIds
        nextOpen
        menuItemsGroups(where: { parentMenu: null }, orderBy: position_ASC) {
          id
          name
          position
          addOnItemsMenu
          menuItems {
            id
            outletSoldOut
            outletHidden
          }
          childrenMenus {
            id
            name
            menuItems {
              id
              outletSoldOut
              outletHidden
            }
          }
        }
      }
    }
  }
`

export default GET_OUTLET_PARENT_MENUS
