import gql from 'graphql-tag'

const GET_POS_DEVICES = gql`
  query getPOSDevices($marketplaceIds: [String!]) {
    getPOSDevices(marketplaceIds: $marketplaceIds) {
      count
      totalCount
      posDevices {
        id
        friendlyName
        serialNumber
        locationId
        outlets {
          id
          name
          restaurant {
            id
            name
          }
        }
      }
    }
  }
`

export default GET_POS_DEVICES
