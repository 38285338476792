import type { ReactNode } from 'react'
import { Sorting } from '../MegaTable'
import { CurrencyColumn, CurrencyColumnData } from './CurrencyColumn'
import { CustomerColumn, CustomerColumnData } from './CustomerColumn'
import { DateTimeColumn, DateTimeColumnData } from './DateTimeColumn'
import { MarketplaceColumn, MarketplaceColumnData } from './MarketplaceColumn'
import { MonthColumn, MonthColumnData } from './MonthColumn'
import { NumericColumn, NumericColumnData } from './NumericColumn'
import { OrderColumn, OrderColumnData } from './OrderColumn'
import { OutletColumn, OutletColumnData } from './OutletColumn'
import { PercentageColumn, PercentageColumnData } from './PercentageColumn'
import { TextColumn, TextColumnData } from './TextColumn'
import { FulfillmentColumn, FulfillmentColumnData } from './FulfillmentColumn'
import {
  PaymentMethodColumn,
  PaymentMethodColumnData,
} from './PaymentMethodColumn'

export type ColumnSorter<T extends ColumnType> = (
  a: ColumnDataType<T>,
  b: ColumnDataType<T>,
  order: 'ASC' | 'DESC'
) => number

export type ColumnRenderer<T extends ColumnType, X extends string> = React.FC<{
  columnDefinition: ColumnTypeToColumnDefinition<X>[T]
  columnData: ColumnDataType<T>
}>

// Supported column types
export type ColumnType =
  | 'currency'
  | 'customer'
  | 'datetime'
  | 'fulfillment'
  | 'marketplace'
  | 'month'
  | 'numeric'
  | 'order'
  | 'outlet'
  | 'paymentMethod'
  | 'percentage'
  | 'text'

export interface BaseColumn<T extends ColumnType, U extends string> {
  /**
   * This is what is shown at the top of the column.
   */
  header: ReactNode
  /**
   * This is what is used in the URL bar `orderBy` param to do searching.
   */
  name: string
  type: T
  /**
   * If this is true, UI for sorting will be shown.
   * How the sorting is done can be switched between NONE, CLIENT and SERVER in a table level prop.
   */
  isSortable?: boolean
  /**
   * Placeholder for null or undefined values. Falls back to FALLBACK_PLACEHOLDER
   */
  placeholder?: ReactNode // Placeholder for null values
  tooltipContent?: ReactNode
  groupKey?: U
}

// Union type for all possible column configurations
export type Column<X extends string> =
  | CurrencyColumn<X>
  | CustomerColumn<X>
  | DateTimeColumn<X>
  | FulfillmentColumn<X>
  | MarketplaceColumn<X>
  | MonthColumn<X>
  | NumericColumn<X>
  | OrderColumn<X>
  | OutletColumn<X>
  | PaymentMethodColumn<X>
  | PercentageColumn<X>
  | TextColumn<X>

export interface ColumnTypeToColumnDefinition<T extends string> {
  currency: CurrencyColumn<T>
  customer: CustomerColumn<T>
  datetime: DateTimeColumn<T>
  fulfillment: FulfillmentColumn<T>
  marketplace: MarketplaceColumn<T>
  month: MonthColumn<T>
  numeric: NumericColumn<T>
  order: OrderColumn<T>
  outlet: OutletColumn<T>
  paymentMethod: PaymentMethodColumn<T>
  percentage: PercentageColumn<T>
  text: TextColumn<T>
}

export interface ColumnTypeToColumnData {
  currency: CurrencyColumnData
  customer: CustomerColumnData
  datetime: DateTimeColumnData
  fulfillment: FulfillmentColumnData
  marketplace: MarketplaceColumnData
  month: MonthColumnData
  numeric: NumericColumnData
  order: OrderColumnData
  outlet: OutletColumnData
  paymentMethod: PaymentMethodColumnData
  percentage: PercentageColumnData
  text: TextColumnData
}

// Utility type to map column types to their corresponding data types
export type ColumnDataType<T extends keyof ColumnTypeToColumnData> =
  ColumnTypeToColumnData[T]

// Type for the data of a single row based on the columns array
export type RowData<Columns extends readonly Column<T>[], T extends string> = {
  [K in keyof Columns]: ColumnDataType<Columns[K]['type']> | null
}

// Helper function to infer column types and row data
export function tableProps<
  Columns extends readonly Column<U>[],
  U extends string,
>(props: {
  sorting: Sorting
  columns: Columns
  data: RowData<Columns, U>[]
  isLoading: boolean
  isError: boolean
  columnGroupHeaders?: Record<U, ReactNode>
}) {
  return props
}

export const FALLBACK_PLACEHOLDER = '-'
