import gql from 'graphql-tag'

const ADD_SEGMENT = gql`
  mutation addMarketplaceSegment(
    $name: String!
    $marketplaceId: String!
    $type: BusinessSegmentType!
    $filters: [BusinessSegmentFilterInput]
  ) {
    addBusinessSegment(
      name: $name
      marketplaceId: $marketplaceId
      filters: $filters
      type: $type
    ) {
      id
      name
      marketplace {
        id
      }
      name
      description
      type
    }
  }
`

export default ADD_SEGMENT
