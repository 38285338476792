import React, { Fragment, useContext, useMemo } from 'react'
import { CustomerAnalyticsContext } from './CustomerAnalyticsContext'
import { customerFavourites } from '@components/Analytics/util/customerFavourites'
import {
  Colors,
  HTMLTable,
  Icon,
  NonIdealState,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core'
import MANY_OUTLETS from './queries/manyOutlets.query'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { Link } from 'react-router-dom'
import Currency from '@components/Currency/Currency'
import moment from 'moment'

// urlDateFormat has to be the same as in @Restaurant/Analytics/BusinessAnalyticsContext.jsx
// and the overhead of moving everything above in a context provider is not worth atm
// Note that this will only be used by the deep link to the business overview
const urlDateFormat = 'YYYYMMDDTHHmm'
const afterDate = moment()
  .subtract(1, 'month')
  .endOf('day')
  .format(urlDateFormat)

const beforeDate = moment().add(1, 'day').startOf('day').format(urlDateFormat)

const Favourites = ({ customerId }) => {
  const { rawCustomerData } = useContext(CustomerAnalyticsContext)

  const favouritesData = useMemo(
    () => customerFavourites(rawCustomerData, true),
    [rawCustomerData]
  )
  let outletIds = favouritesData.map(item => item.outletId)

  return (
    <Fragment>
      {favouritesData.length > 0 ? (
        <DebouncedQuery
          query={MANY_OUTLETS}
          variables={{ ids: outletIds || [] }}
        >
          {({ getOutletsByIds, error, loading }) => {
            if (error) {
              return (
                <NonIdealState
                  title="Error"
                  description="There was a problem loading the data"
                />
              )
            }
            if (loading) {
              return <NonIdealState title="Loading" description="Loading..." />
            }
            if (!getOutletsByIds || !getOutletsByIds.outlets) {
              return (
                <NonIdealState
                  title="No data"
                  description="No data coming through"
                />
              )
            }
            return (
              <HTMLTable bordered>
                <thead>
                  <tr>
                    <th>
                      Top Outlets{' '}
                      <Tooltip
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.RIGHT_TOP}
                        content="Favorite first three outlets by amount spent."
                      >
                        <Icon icon="info-sign" color={Colors.GRAY4} />
                      </Tooltip>
                    </th>
                    <th>Value / Avg</th>
                    <th>Orders / Avg</th>
                  </tr>
                </thead>
                <tbody>
                  {favouritesData.map(outletFinancialData => {
                    const outletMetadata = getOutletsByIds.outlets.find(
                      outlet => outlet.id === outletFinancialData.outletId
                    )
                    return (
                      <tr key={outletMetadata.id}>
                        <td>
                          <Link
                            to={`/business/${outletMetadata.restaurant.id}/overview?afterDate=${afterDate}&beforeDate=${beforeDate}&outletId=${outletMetadata.id}&customerId=${customerId}`}
                          >
                            {outletMetadata.restaurant.name}
                          </Link>
                        </td>
                        <td>
                          <Currency
                            amount={outletFinancialData.totalSpentPerOutlet}
                          />{' '}
                          (
                          {(
                            outletFinancialData.avgSpentPerOutlet * 100
                          ).toFixed(2)}{' '}
                          %)
                        </td>
                        <td>
                          {outletFinancialData.numberOfOrdersPerOutlet} (
                          {(
                            outletFinancialData.avgNumberOfOrdersPerOtlet * 100
                          ).toFixed(2)}{' '}
                          %)
                        </td>
                      </tr>
                    )
                  })}
                  {/* {getOutletsByIds.outlets.map(outlet => {
                    return (
                      <tr key={outlet.id}>
                        
                      </tr>
                    )
                  })} */}
                </tbody>
              </HTMLTable>
            )
          }}
        </DebouncedQuery>
      ) : (
        <div style={{ padding: 24 }}>
          <NonIdealState
            icon="list"
            description="No orders from the customer."
          />
        </div>
      )}
    </Fragment>
  )
}

export default Favourites
