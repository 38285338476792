import gql from 'graphql-tag'

const DELETE_MESSAGE_HISTORY = gql`
  mutation deleteMessageHistory($messageId: ID!) {
    deleteMessageHistory(messageId: $messageId) {
      message
    }
  }
`

export default DELETE_MESSAGE_HISTORY
