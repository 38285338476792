import React, { Fragment } from 'react'
import { canPerformAction } from '@stores/userStore'
import Filter from '@components/Toolbar/Filter'
import { Divider } from '@blueprintjs/core'
import { union, without } from 'lodash'

import GET_MARKETPLACE_IDS from './queries/getMarketplaceIds.query'
import { useRoleAwareBusinessFilterQueryParams } from './RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

/**
 * Filter which allows selection of 0 or more marketplaces
 * Can either be controlled or uncontrolled, depending on the presence of the `onChange` & `marketplaceFilter` props
 * If uncontrolled, the filter state will be managed by query params.
 *
 * @param {Object} props
 * @param {boolean} [props.disabled=false] - Whether the filter is disabled
 * @param {string} [props.icon] - Icon to display next to the filter name
 * @param {boolean} [props.divider=false] - Whether to display a divider after the filter
 * @param {boolean} [props.defaultIsOpen] - Callback to updated state (if controlled only)
 * @param {import('react').ChangeEventHandler<HTMLInputElement>} [props.onChange] - Callback to updated state (if controlled only)
 * @param {Array<string>} [props.marketplaceFilter] - Array of marketplace IDs to filter by (if controlled only)
 */
export const MarketplaceFilter = ({
  disabled = false,
  icon,
  divider = false,
  marketplaceFilter,
  onChange,
  defaultIsOpen,
}) => {
  const { marketplaceIds: marketplaceIdsFromQParams, onChangeMarketplaceIds } =
    useRoleAwareBusinessFilterQueryParams()

  const marketplaceIds = marketplaceFilter || marketplaceIdsFromQParams

  return (
    canPerformAction('filterByMarketplace') && (
      <Fragment>
        <Filter
          name="Marketplaces"
          icon={icon}
          disabled={disabled}
          filter={marketplaceIds}
          onChange={e => {
            if (onChange) {
              onChange(e)
            }
            const { id, checked } = e.currentTarget
            onChangeMarketplaceIds(
              checked
                ? union(marketplaceIds, [id])
                : without(marketplaceIds, id)
            )
          }}
          query={GET_MARKETPLACE_IDS}
          dataPath="getMarketplaces.regions"
          defaultIsOpen={defaultIsOpen}
        />
        {divider && <Divider />}
      </Fragment>
    )
  )
}
