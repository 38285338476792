import * as yup from 'yup'
import { addOptionStructure } from '../../../../validation/option'

const newOptionValidation = yup.object().shape({
  newOptionItems: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter an option item'),
      price: yup.string().required('Please enter the price of the option item'),
    })
  ),
})

export const validation = yup
  .object()
  .shape(addOptionStructure)
  .concat(newOptionValidation)
