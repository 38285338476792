import React from 'react'
import { Select } from '@blueprintjs/select'
import {
  Button,
  ButtonGroup,
  Icon,
  MenuItem,
  Position,
} from '@blueprintjs/core'
import theme from '@styles/theme'

const RadioFilter = ({
  disabled,
  selected,
  setSelected,
  items,
  placeholder,
}) => {
  const ItemRenderer = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const isSelected = selected === item.id || (!item.id && !selected)
    return (
      <MenuItem
        active={false}
        icon={
          isSelected ? (
            <Icon icon="selection" color={theme.colors.menuSelect} />
          ) : (
            'circle'
          )
        }
        key={item.id}
        onClick={handleClick}
        text={item.name}
      />
    )
  }

  return (
    <ButtonGroup>
      <Select
        id="dropdownSearch"
        filterable={false}
        items={items}
        itemRenderer={ItemRenderer}
        onItemSelect={setSelected}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: 'window',
        }}
      >
        <Button
          rightIcon={
            selected ? (
              <Icon icon="symbol-circle" color={theme.colors.menuSelect} />
            ) : (
              'double-caret-vertical'
            )
          }
          disabled={disabled}
        >
          {placeholder}
        </Button>
      </Select>
    </ButtonGroup>
  )
}

export default RadioFilter
