import gql from 'graphql-tag'

const OUTLET_CLOSE_EARLY = gql`
  mutation closeOutletEarly($outletId: String!, $reopenAt: DateTime) {
    closeEarly(outletId: $outletId, reopenDateTime: $reopenAt) {
      outlet {
        closedUntil
      }
    }
  }
`

export default OUTLET_CLOSE_EARLY
