import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { string, func, bool } from 'prop-types'

import Query from '@components/Query/Query'
import GET_MARKETPLACE_IDS_BY_PARTNERID from './queries/getMarketplaceIdsByPartnerid.query.js'

const appMarketplaceSelectByPartner = ({
  marketplaceId,
  partnerId,
  disabled,
  onChange,
}) => (
  <Query
    query={GET_MARKETPLACE_IDS_BY_PARTNERID}
    variables={{ partnerId }}
    showLoader={false}
  >
    {({ getMarketplaces: { regions } }) => (
      <HTMLSelect
        name="marketplaceId"
        fill={true}
        options={[
          { value: '', label: 'Select marketplace...' },
          ...map(regions, ({ id, name, customerAppBrandFolder }) => ({
            value: id,
            label: name,
            disabled: !customerAppBrandFolder,
          })),
        ]}
        value={marketplaceId}
        disabled={disabled}
        onChange={event => onChange(event)}
      />
    )}
  </Query>
)

appMarketplaceSelectByPartner.propTypes = {
  marketplaceId: string,
  disabled: bool,
  onChange: func,
}

export default appMarketplaceSelectByPartner
