import React from 'react'
import {
  FormGroup,
  Classes,
  Button,
  Intent,
  InputGroup,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import { get } from 'lodash'
import { selectionUpdate } from '@utils/helpers'
import MarketplaceSelectByPartner from '@components/MarketplaceSelectByPartner/MarketplaceSelectByPartner'
import { Fragment } from 'react'

const AppImportForm = ({
  values,
  handleSubmit,
  handleChange,
  setFieldValue,
  errors,
  location,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <div className={Classes.DIALOG_BODY}>
      <FormGroup
        label="Marketplace"
        helperText={errors.marketplaceId ? errors.marketplaceId : ''}
        intent={Intent.DANGER}
      >
        <MarketplaceSelectByPartner
          marketplaceId={get(values.marketplace, 'id')}
          partnerId={location.split('/')[2]}
          setFieldValue={setFieldValue}
          onChange={event => {
            const selection = selectionUpdate(event)
            setFieldValue('marketplaceId', selection.value, false)
          }}
        />
      </FormGroup>

      <FormGroup
        label="Appcenter App name (app_name): Android"
        labelFor="appcenterAppNameAndroid"
        intent={errors.appcenterAppNameAndroid ? Intent.DANGER : Intent.NONE}
        helperText={
          <Fragment>
            {errors.appcenterAppNameAndroid && (
              <p>{`${errors.appcenterAppNameAndroid}.`}</p>
            )}
            <p>
              {errors.appcenterAppNameAndroid
                ? errors.appcenterAppNameAndroid + '. '
                : ''}
            </p>
            <p>
              App name appears in the url in AppCenter. For more details follow
              the&nbsp;
              <a
                href={
                  'https://docs.microsoft.com/en-us/appcenter/api-docs/#find-owner_name-and-app_name-from-an-app-center-url'
                }
              >
                guide
              </a>
            </p>
          </Fragment>
        }
      >
        <InputGroup
          name="appcenterAppNameAndroid"
          type="text"
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup
        label="Appcenter App name (app_name): iOS"
        labelFor="appcenterAppNameiOS"
        intent={errors.appcenterAppNameiOS ? Intent.DANGER : Intent.NONE}
        helperText={
          <Fragment>
            {errors.appcenterAppNameiOS && (
              <p>{`${errors.appcenterAppNameiOS}.`}</p>
            )}

            <p>
              App name appears in the url in AppCenter. For more details follow
              the&nbsp;
              <a
                href={
                  'https://docs.microsoft.com/en-us/appcenter/api-docs/#find-owner_name-and-app_name-from-an-app-center-url'
                }
              >
                guide
              </a>
            </p>
          </Fragment>
        }
      >
        <InputGroup
          name="appcenterAppNameiOS"
          type="text"
          onChange={handleChange}
        />
      </FormGroup>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button type="submit" text="Import App" loading={isSubmitting} />
      </div>
    </div>
  </form>
)

AppImportForm.propTypes = {
  values: object,
  handleSubmit: func,
  handleChange: func,
  setFieldValue: func,
}

export default AppImportForm
