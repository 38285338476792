import gql from 'graphql-tag'

const GET_MARKETPLACE_FAQS = gql`
  query getFAQs($marketplaceId: String!) {
    getFAQs(marketplaceId: $marketplaceId) {
      id
      answer
      question
      published
    }
  }
`

export default GET_MARKETPLACE_FAQS
