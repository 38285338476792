import gql from 'graphql-tag'

const GET_OUTLET = gql`
  query getOutletForDashboard($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        name
        active
        openingTimes
        isOpen
        isOnline
        isOnlineOverride
        isAutoAccept
        formattedCurrentOpeningTimeBracket
        stripeId
        marketplace {
          id
          name
          stripeDirectPayment
          stripeOnboarding
          companyLegalName
          contactAddress {
            id
            firstLine
            city
            postcode
          }
        }
        contactAddress {
          id
          firstLine
          city
          postcode
          geo
        }
        outletAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
          geo
        }
        terminals {
          id
          isOnline
        }
        deliveryZoneCosts {
          id
        }
        restaurant {
          id
          name
          active
          menuItems {
            id
            outletSoldOut
          }
          contactAddress {
            id
            firstLine
            city
            postcode
          }
        }
        isVATregistered
        stripeConnect {
          accounts
          accountType
          payoutsEnabled
          balance
          balancePending
          balanceCurrency
          dashboardLink
          detailsSubmitted
          payoutsEnabled
          chargesEnabled
        }
        escalationMobile
      }
    }
  }
`

export default GET_OUTLET
