import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import { Divider, Tag } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import Currency from '@components/Currency/Currency'
import { Fragment } from 'react'
import get from 'lodash/get'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`

const Container = styled.div`
  padding: 0;
  margin-top: 12px;
`

const Total = styled(Row)`
  font-weight: bold;
`

const Charge = styled(Row)`
  color: ${({ theme }) => theme.colors.orderHighlight};
`

const Totals: React.FC<TotalsProps> = ({
  fulfillmentCharge,
  fulfillmentMethod,
  partnerCharge,
  outlet,
  grossTotal,
  subtotalGross,
  addOnItemsGross,
  discountAmount,
  discount,
  voucherKey,
  customerTaxes,
  marketplaceId,
}) => {
  let discountLink = '#'
  if (discount) {
    if (discount.loyaltyCard) {
      // discount is a loyalty card
      discountLink = `/marketplaces/${marketplaceId}/loyalty-cards?discountId=${discount.id}`
    } else if (voucherKey) {
      // discount comes from a voucher
      discountLink = `/marketplaces/${
        voucherKey.split('-')[1]
      }/discount-codes?discountId=${discount.id}`
    } else if (discount.customerEnrolled) {
      // discount comes from a member discount
      discountLink = `/business/${get(
        outlet,
        'restaurant.id',
        '#'
      )}/members?discountId=${discount.id}`
    } else {
      // a regular discount
      discountLink = `/business/${get(
        outlet,
        'restaurant.id',
        '#'
      )}/discounts?discountId=${discount.id}`
    }
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <p>Subtotal</p>
          <p>
            <Currency amount={subtotalGross} />
          </p>
        </Row>
        {addOnItemsGross > 0 && (
          <Charge>
            <p>Add-On</p>
            <p>
              (<Currency amount={addOnItemsGross} />)
            </p>
          </Charge>
        )}
        {discount && (
          <Charge>
            <p>
              {discount.loyaltyCard ? 'Loyalty Card' : 'Discount'}{' '}
              {voucherKey && <Tag minimal>{voucherKey.split('-')[0]}</Tag>}{' '}
              <Link to={discountLink}>{discount.name}</Link>
            </p>

            <p>
              - <Currency amount={discountAmount} />
            </p>
          </Charge>
        )}
        {fulfillmentMethod !== 'TABLE' && (
          <Charge>
            <p>{`${capitalize(fulfillmentMethod)}`}</p>
            <p>
              <Currency amount={fulfillmentCharge} />
            </p>
          </Charge>
        )}
        <Charge>
          <p>
            {fulfillmentMethod === 'TABLE'
              ? outlet.marketplace.partnerTableChargeDescription
              : outlet.marketplace.partnerChargeDescription || 'Partner Charge'}
          </p>
          <p>
            <Currency amount={partnerCharge} />
          </p>
        </Charge>
        <Charge>
          <p>VAT</p>
          <p>
            (<Currency amount={customerTaxes} />)
          </p>
        </Charge>
      </Container>
      <Divider />
      <Container>
        <Total>
          <p>Total</p>
          <p>
            <Currency amount={grossTotal} />
          </p>
        </Total>
      </Container>
    </Fragment>
  )
}

// TODO GEN TYPES
interface TotalsProps {
  fulfillmentCharge: number
  fulfillmentMethod: string
  outlet: Record<string, any> // Adjust the type as needed
  partnerCharge: number
  addOnItemsGross: number
  subtotalGross: number
  grossTotal: number
  discountAmount: number
  discount: Record<string, any> // Adjust the type as needed
  voucherKey: string
  customerTaxes: number
}

export default Totals
