import gql from 'graphql-tag'

const GET_PARTNER_NAME = gql`
  query getPartnerName($id: String) {
    getPartners(id: $id) {
      partners {
        id
        name
      }
    }
  }
`

export default GET_PARTNER_NAME
