import App from './Dnd/App'
import { Fragment } from 'react'
import { GridProvider } from './Dnd/GridContext'

const UploadedImages = ({ images, setImages }) => {
  if (images.uploadImages && images.uploadImages.images) {
    images = images.uploadImages.images
  }

  const deleteItem = src => {
    const updatedArray = images.filter(img => {
      return img.src !== src
    })
    setImages(updatedArray)
  }

  const updateCaption = (src, newCaption) => {
    setImages(items =>
      items.map(i => {
        if (i.src === src) {
          return { ...i, caption: newCaption }
        }
        return i
      })
    )
  }

  return (
    <Fragment>
      {images[0] && (
        <GridProvider items={images} setImages={setImages}>
          <App deleteItem={deleteItem} updateCaption={updateCaption} />
        </GridProvider>
      )}
    </Fragment>
  )
}

export default UploadedImages
