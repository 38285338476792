import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import ONBOARDING_APPROVE from './mutations/approve.mutation'
import GET_RESTAURANTS from '../List/queries/getRestaurants.query'
import {
  Button,
  Classes,
  Intent,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { useMutation } from '@apollo/client'

const ApproveOnboardingButton = ({ restaurant }) => {
  const [approveApplication] = useMutation(ONBOARDING_APPROVE, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Application Approved'),
    refetchQueries: [
      {
        query: GET_RESTAURANTS,
        variables: {
          statusOnboarding: ['SIGNUP', 'ONBOARDING'],
        },
      },
    ],
  })
  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      content={
        <div>
          <h5 className={Classes.HEADING}>Confirmation</h5>
          <p>
            Are you sure you want to approve{' '}
            <strong>"{restaurant.name}"</strong>?
          </p>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button className={Classes.POPOVER_DISMISS} text="Cancel" minimal />

            <Button
              className={Classes.INTENT_SUCCESS}
              onClick={_ =>
                approveApplication({
                  variables: {
                    id: restaurant.id,
                  },
                })
              }
              text="Approve"
            />
          </div>
        </div>
      }
    >
      <Button intent={Intent.SUCCESS} minimal icon="tick">
        Approve
      </Button>
    </Popover>
  )
}

export default ApproveOnboardingButton
