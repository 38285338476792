import { Navigate, Route, Routes, useParams } from 'react-router-dom'

export const MarketingIdRedirect = () => {
  const { id } = useParams()
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={`/marketing/${id}/dashboard`} replace />}
      />
    </Routes>
  )
}
