import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const app: IIterableInterface = {
  marketplaceId: yup
    .string()
    .required('Marketplace is required')
    .min(25, 'Marketplace too short')
    .max(25, 'Marketplace too long'),
  appcenterAppNameAndroid: yup
    .string()
    .required("Appcenter App Name Android can't be empty")
    .min(3, 'Appcenter App Name Android must have more than two characters'),
  appcenterAppNameiOS: yup
    .string()
    .required("Appcenter App Name iOS can't be empty")
    .min(3, 'Appcenter App Name iOS must have more than two characters'),
}
