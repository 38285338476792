import gql from 'graphql-tag'

const GET_PARENT_MENUS = gql`
  query getParentMenus($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        allowAddOnItems
        menuItemsGroups(where: { parentMenu: null }, orderBy: position_ASC) {
          id
          name
          position
          fulfillmentMethods
          addOnItemsMenu
          menuItems {
            id
          }
          childrenMenus {
            id
            name
            menuItems {
              id
            }
          }
        }
        outlets {
          id
          soldOutMenuItemIds
          soldOutUntilItems
          hiddenMenuItemGroupIds
        }
      }
    }
  }
`

export default GET_PARENT_MENUS
