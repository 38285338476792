import gql from 'graphql-tag'

const DELETE_BLACKLISTED_ADDRESS = gql`
  mutation deleteBlacklistedAddress($id: String!) {
    deleteBlacklistedAddress(id: $id) {
      message
    }
  }
`

export default DELETE_BLACKLISTED_ADDRESS
