import gql from 'graphql-tag'

const GET_PAYOUT = gql`
  query payout(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $marketplaceIds: [ID!]
    $partnerIds: [ID!]
  ) {
    payout(
      marketplaceIds: $marketplaceIds
      partnerIds: $partnerIds
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
    ) {
      outlets {
        outlet {
          id
          name
          isOnline
          bankAccount
          bankSort
          restaurant {
            id
            name
          }
        }
        totalRefunds
        orderNetTotal
        orderGrossTotal
        totalOrders
        totalOrdersValue
        totalCustomerDeliveryValue
        totalDeliveryValue
        totalNetworkValue
        totalSubsidyValue
        totalDiscountValue
        totalMarketplaceFee
        totalMarketplaceCharge
        totalMerchantCharge
        totalPaidCashValue
        totalRefundsValue
        totalPayout
      }
    }
  }
`

export default GET_PAYOUT
