import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { array, func, bool } from 'prop-types'
import { Button, Switch, Icon } from '@blueprintjs/core'
import DisconnectStripeButton from './DisconnectStripeButton'
import { isAtLeastPartner } from '@stores/userStore'

const MarketplaceStripeForm = ({
  initialValues,
  onSubmit,
  stripeExpressConnected,
}) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ handleSubmit, values, setFieldValue }) => (
      <form onSubmit={handleSubmit}>
        <Switch
          id="stripeOnboarding"
          disabled={values.stripeDirectPayment}
          checked={values.stripeOnboarding}
          labelElement={
            <Fragment>
              Onboarding <br />
              <span className="bp5-text-small bp5-text-muted">
                Allow outlets to onboard their accounts.
              </span>
            </Fragment>
          }
          onChange={event => {
            setFieldValue('stripeOnboarding', event.target.checked)
          }}
        />
        <Switch
          id="stripeDirectPayment"
          disabled={!stripeExpressConnected || !isAtLeastPartner()}
          checked={values.stripeDirectPayment}
          labelElement={
            <Fragment>
              Paybox Payment Flow <br />
              <p className="bp5-text-small bp5-text-muted">
                Split commission and pay outlets automatically.
              </p>
              {!stripeExpressConnected && (
                <p className="bp5-text-small bp5-text-muted">
                  <Icon icon="warning-sign" /> Paybox setup not complete!
                </p>
              )}
            </Fragment>
          }
          onChange={event => {
            setFieldValue('stripeDirectPayment', event.target.checked)
          }}
        />

        <br />
        <div className="bp-card-footer-actions">
          <Button text="Save" type="submit" />
          {stripeExpressConnected && (
            <DisconnectStripeButton
              stripeId={values.stripeId}
              marketplaceId={values.id}
              onCompleted={() => {}}
            />
          )}
        </div>
      </form>
    )}
  </Formik>
)

MarketplaceStripeForm.propTypes = {
  stripeExpressConnected: bool,
  paymentMethods: array,
  onSubmit: func,
}

export default MarketplaceStripeForm
