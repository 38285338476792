import {
  Button,
  Callout,
  Card,
  HTMLTable,
  NonIdealState,
  Tag,
  Tooltip,
} from '@blueprintjs/core'
import get from 'lodash/get'
import React, { Fragment, useState } from 'react'

import Currency from '@components/Currency/Currency'
import GeoDeliveryZones from '@components/GeoJSON/GeoDeliveryZones'
import GET_DELIVERY_ZONES from '@components/Outlet/Fulfilment/queries/getDeliveryZones.query'
import OutletDeliveryZoneModal from '@components/OutletDeliveryZoneModal/OutletDeliveryZone.modal'
import Query from '@components/Query/Query'
import { isOutletUser } from '@stores/userStore'
import { useParams } from 'react-router-dom'
import { useModal } from '../../../providers/ModalProvider'
import AddressNonIdealState from './AddressNonIdealState'
import { GetOutletFulfilmentDataQuery } from './queries/getDeliveryZones.query.generated'

const WEBHOOK_STATUS_TO_INTENT_MAP = {
  NOT_APPLICABLE: 'none',
  SUCCESS: 'success',
  FAILURE: 'danger',
  ERROR: 'danger',
}

const tableHead = [
  { key: 'deliveryZone', content: 'Delivery Zone' },
  { key: 'fulfilment', content: 'Fulfilment' },
  { key: 'deliveryCost', content: 'Delivery Cost' },
  { key: 'radiusMiles', content: 'Delivery Radius (miles)' },
  { key: 'subsidy', content: 'Subsidy' },
  { key: 'webhookStatus', content: 'Webhook' },
]

const displayFixedSubsidy = deliveryNetwork =>
  deliveryNetwork &&
  ['STUART', 'WAYBOX'].includes(deliveryNetwork.deliveryProvider) &&
  deliveryNetwork.fixedSubsidy !== null ? (
    <Currency amount={deliveryNetwork.fixedSubsidy} />
  ) : (
    <Currency amount={0} className="bp5-text-muted" />
  )

const DeliveryZones: React.FC = () => {
  const { outlet: outletId } = useParams()
  const userIsOutletUser = isOutletUser()

  const [activeZone, setActiveZone] = useState({})
  const clearActiveZone = () => setActiveZone({})

  const { isOpen, toggleModal } = useModal('addDeliveryZoneModal')

  return (
    <Query<GetOutletFulfilmentDataQuery>
      query={GET_DELIVERY_ZONES}
      variables={{ outletId }}
      loaderTitle={'Loading Delivery Zones'}
    >
      {({ getOutlets: { outlets } }) => {
        const [outlet] = outlets
        const outletDeliveryZoneCosts = outlet?.deliveryZoneCosts || []

        return (
          <Fragment>
            {!outletDeliveryZoneCosts.length ? (
              <NonIdealState
                icon="drive-time"
                title="No Delivery Zones"
                description={
                  !userIsOutletUser
                    ? 'Create delivery zones for this outlet.'
                    : 'A business or marketplace owner can create delivery zones for this outlet.'
                }
                action={
                  !userIsOutletUser && (
                    <Button
                      text="Add Delivery Zone"
                      icon="plus"
                      onClick={() => toggleModal(true)}
                    />
                  )
                }
                className="bp5-surface-card-non-ideal-state"
              />
            ) : (
              <Fragment>
                {!outlet.active && (
                  <Callout
                    style={{ marginBottom: 24 }}
                    intent="danger"
                    icon="ban-circle"
                  >
                    This Outlet is Deactivated and will not allow orders.
                  </Callout>
                )}
                <Card className="bp5-nopad">
                  {!outlet.outletAddress || !outlet.outletAddress.geo ? (
                    <AddressNonIdealState
                      description={
                        outlet.outletAddress
                          ? "We couldn't find the outlet address on the map, please validate the address and try again."
                          : 'Outlet must have a pickup address before the delivery zone map can be viewed.'
                      }
                    />
                  ) : (
                    <GeoDeliveryZones
                      center={outlet.outletAddress.geo.coordinates}
                      deliveryZones={outletDeliveryZoneCosts}
                    />
                  )}
                </Card>
                <Card className="bp5-nopad bp5-scrollable">
                  <HTMLTable interactive={true} bordered={false}>
                    <thead>
                      <tr>
                        {tableHead.map(({ key, content }) => (
                          <th key={key}>{content}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {outletDeliveryZoneCosts.map(zone => {
                        const {
                          id,
                          deliveryCost,
                          deliveryZone,
                          deliveryNetwork,
                          radiusMiles,
                        } = zone
                        const webhookStatus = get(
                          deliveryNetwork,
                          'webhookStatus',
                          'NOT APPLICABLE'
                        )
                        return (
                          <tr key={id}>
                            <td>
                              {userIsOutletUser ? (
                                deliveryZone.name
                              ) : (
                                <a
                                  onClick={() => {
                                    toggleModal(true)
                                    setActiveZone(zone)
                                  }}
                                >
                                  {deliveryZone.name}
                                </a>
                              )}
                            </td>
                            <td>
                              <Tag minimal>
                                {get(
                                  deliveryZone,
                                  'deliveryZoneType',
                                  'Restaurant'
                                ).replace(/_/g, ' ')}
                              </Tag>
                            </td>
                            <td>
                              <Currency amount={deliveryCost} />
                            </td>
                            <td>{radiusMiles || 'N/A'}</td>
                            <td>{displayFixedSubsidy(deliveryNetwork)}</td>
                            <td>
                              <Tooltip
                                content={'An unexpected error occurred'}
                                disabled={webhookStatus !== 'ERROR'}
                              >
                                <Tag
                                  minimal
                                  intent={
                                    WEBHOOK_STATUS_TO_INTENT_MAP[webhookStatus]
                                  }
                                >
                                  {webhookStatus}
                                </Tag>
                              </Tooltip>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </HTMLTable>
                </Card>
              </Fragment>
            )}

            <OutletDeliveryZoneModal
              modalOpen={isOpen}
              modalClose={() => {
                toggleModal(false)
              }}
              activeOutletDeliveryZone={activeZone}
              clearActiveZone={clearActiveZone}
              outletId={outlet.id}
            />
          </Fragment>
        )
      }}
    </Query>
  )
}

export default DeliveryZones
