import * as yup from 'yup'

const timeEntry = yup.object().shape({
  day: yup
    .number()
    .transform(
      // cast to integer or NaN
      (value, originalValue) => parseInt(value) || parseInt(originalValue)
    )
    .integer()
    .min(1, "Day can't be less than 1")
    .max(7, "Day can't be more than 7"),
  time: yup
    .string()
    .matches(
      /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
      'Time must be of the format "17:59"'
    ),
})

export const orderingTimeBracketsSchema = yup.array().of(
  yup.object().shape({
    start: timeEntry,
    end: timeEntry,
    timeSlot: yup.number().integer().positive(),
  })
)

export const genericTimeBracketsSchema = yup.array().of(
  yup.object().shape({
    start: timeEntry,
    end: timeEntry,
  })
)
