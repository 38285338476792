import { StringParam, useQueryParams } from 'use-query-params'

export const useOtterModal = () => {
  const [{ otter }, setQueryParams] = useQueryParams({
    otter: StringParam,
  })

  return {
    isOpen: !!otter,
    open: () => setQueryParams({ otter: 'onboarding' }),
    close: () => setQueryParams({ otter: undefined }),
  }
}
