import gql from 'graphql-tag'

const GET_APP_BY_ID = gql`
  query getApp($id: ID!) {
    getApp(id: $id) {
      app {
        id
        appBuilds {
          id
          device
          version
          releaseToAppcenter
          releaseToStore
          createdAt
          appBuildStatuses {
            id
            status
            circleCiBuildNum
            createdAt
          }
        }
        marketplace {
          id
          name
          partner {
            id
            name
          }
        }
      }
    }
  }
`

export default GET_APP_BY_ID
