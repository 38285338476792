import React, { useState, Fragment, useContext, useEffect } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  HTMLTable,
  Icon,
  Intent,
  NonIdealState,
  ProgressBar,
  Tooltip,
} from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import { Search, Pager } from '@components/Toolbar'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import DiscountCodesDetailsDrawer from './DiscountCodesDetailsDrawer'
import DISCOUNT_VOUCHERS_BY_MARKETPLACE from './queries/discountVouchersByMarketplace.query'
import colors from '@styles/colors'
import ANALYTICS_ENABLED from './queries/analyticsEnabled.query'
import Query from '../../Query/Query'
import { get } from 'lodash'
import { Spinner } from '@blueprintjs/core'
import { errorToast } from '@utils/toast'
import { OuterLayoutOverlayContext } from '../OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'
import { useParams } from 'react-router-dom'
import Currency from '@components/Currency/Currency'

const DEFAULT_RECORDS = 20
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const MarketplaceVouchers = () => {
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  const [discountId, onChangeDiscountId] = useQueryParam(
    'discountId',
    StringParam
  )
  const [showArchivedVouchers = false, onChangeShowArchivedVouchers] =
    useQueryParam('showArchivedVouchers', BooleanParam)

  const { marketplace: marketplaceId } = useParams()
  const [marketplaceVouchersPagination, setMarketplaceVouchersPagination] =
    useState(DEFAULT_PAGINATION_STATE)
  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      setMarketplaceVouchersPagination(DEFAULT_PAGINATION_STATE)
    }
  }, [searchValue, setMarketplaceVouchersPagination])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplaceVouchersPagination.skip + marketplaceVouchersPagination.first <
      marketplaceVouchersPagination.total
    ) {
      setMarketplaceVouchersPagination({
        ...marketplaceVouchersPagination,
        skip: limitNext(
          marketplaceVouchersPagination.skip,
          marketplaceVouchersPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplaceVouchersPagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplaceVouchersPagination({
      ...marketplaceVouchersPagination,
      skip: limitPrevious(
        marketplaceVouchersPagination.skip,
        marketplaceVouchersPagination.first
      ),
      first: DEFAULT_RECORDS,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const setFilterState = (count, totalCount) => {
    const totalCountChanged = marketplaceVouchersPagination.total !== totalCount
    const countChanged = marketplaceVouchersPagination.outcomeLength !== count
    if (totalCountChanged || countChanged) {
      setMarketplaceVouchersPagination({
        ...marketplaceVouchersPagination,
        total: totalCountChanged
          ? totalCount
          : marketplaceVouchersPagination.total,
        outcomeLength: countChanged
          ? count
          : marketplaceVouchersPagination.outcomeLength,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMarketplaceVouchersPagination({
      ...marketplaceVouchersPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
    })
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <Search autoFocus placeholder="Name" />
        <Checkbox
          label="Show Deactivated"
          checked={showArchivedVouchers}
          onChange={e => {
            e.preventDefault()
            onChangeShowArchivedVouchers(e.target.checked)
          }}
          style={{
            margin: '5px 0 0 10px',
          }}
        />
      </ButtonGroup>
      <Pager
        defaultNmbRecords={DEFAULT_RECORDS}
        goToPrevious={goToPrevious}
        goToNext={goToNext}
        goToPage={goToPage}
        skip={marketplaceVouchersPagination.skip}
        total={marketplaceVouchersPagination.total}
        outcomeLength={marketplaceVouchersPagination.outcomeLength}
        totalCount={marketplaceVouchersPagination.total}
        dataName={`Discount${
          marketplaceVouchersPagination.total !== 1 ? 's' : ''
        }`}
      />
    </FilterRow>
  )

  return (
    <div className="bp5-table-frame">
      {renderFilterBar()}
      <div className="bp5-table-container bp5-scrollable">
        <DebouncedQuery
          query={DISCOUNT_VOUCHERS_BY_MARKETPLACE}
          variables={{
            marketplaceId,
            skip: marketplaceVouchersPagination.skip,
            first: marketplaceVouchersPagination.first,
            searchText: searchValue,
            active: showArchivedVouchers ? undefined : true,
            orderBy: 'createdAt_DESC',
          }}
          loaderTitle={'Loading Discounts'}
          onCompleted={({
            discountVouchersByMarketplace: { count, totalCount },
          }) => {
            setFilterState(count, totalCount)
          }}
        >
          {({ discountVouchersByMarketplace: { discounts } }) => {
            if (discounts.length) {
              return (
                <Fragment>
                  <Card className={'bp5-nopad'}>
                    <HTMLTable interactive={true} bordered={false}>
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Codes</td>
                          <td>Discount</td>
                          <td>Minimum to spend</td>
                          <td>Discount Amount / %</td>
                        </tr>
                      </thead>
                      <tbody>
                        {discounts.map(discount => {
                          const {
                            id,
                            name,
                            discountPercentage,
                            discountAmount,
                            minimumSubtotalGross,
                            vouchers,
                          } = discount

                          const usedVouchers =
                            vouchers && vouchers.length > 1
                              ? vouchers.reduce((acc, { usageCount }) => {
                                  if (usageCount >= 1) acc += 1

                                  return acc
                                }, 0)
                              : 0

                          return (
                            <tr key={id}>
                              <td
                                onClick={() => {
                                  onChangeDiscountId(id)
                                }}
                              >
                                <Tooltip
                                  content={`${
                                    vouchers.length && vouchers[0].active
                                      ? 'Active'
                                      : 'Deactivated'
                                  } `}
                                >
                                  <Icon
                                    icon={'small-tick'}
                                    color={
                                      vouchers.length &&
                                      vouchers[0].active &&
                                      colors.activeGreen
                                    }
                                    style={{
                                      opacity:
                                        vouchers.length && vouchers[0].active
                                          ? 1
                                          : 0.2,
                                    }}
                                  />
                                </Tooltip>
                                <a>&nbsp;&nbsp;{name}</a>
                              </td>
                              <td>{vouchers.length}</td>
                              <td>
                                {vouchers.length > 1 ? (
                                  <Tooltip
                                    content={`${usedVouchers} used out of ${vouchers.length}`}
                                  >
                                    <div style={{ minWidth: '200px' }}>
                                      <ProgressBar
                                        animate={false}
                                        stripes={false}
                                        intent={Intent.PRIMARY}
                                        value={usedVouchers / vouchers.length}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  'n.a'
                                )}
                              </td>

                              <td>
                                {minimumSubtotalGross &&
                                minimumSubtotalGross !== 0 ? (
                                  <Currency amount={minimumSubtotalGross} />
                                ) : (
                                  '-'
                                )}
                              </td>

                              <td>
                                {discountPercentage ? (
                                  `${discountPercentage}%`
                                ) : (
                                  <Currency amount={discountAmount} />
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </Fragment>
              )
            } else {
              return showArchivedVouchers || searchValue ? (
                <NonIdealState
                  icon="panel-table"
                  title="No Discounts Found"
                  description="No discounts match your search criteria."
                  action={
                    <Button
                      text="Clear Filters"
                      intent="primary"
                      minimal
                      onClick={() => {
                        resetSearch()
                        onChangeDiscountId(undefined)
                        onChangeShowArchivedVouchers(false)
                      }}
                    />
                  }
                />
              ) : (
                <NonIdealState
                  icon="add"
                  title="Discount Codes"
                  description="Discount and special offers using a code at checkout."
                  action={
                    <Button
                      icon="plus"
                      text="Create Discount Code"
                      onClick={() => {
                        openOverlay('createVoucher')
                      }}
                    />
                  }
                />
              )
            }
          }}
        </DebouncedQuery>
      </div>
      <Query
        query={ANALYTICS_ENABLED}
        variables={{ marketplaceId }}
        loaderTitle="Loading Additional Data"
      >
        {({ getMarketplaces: { regions }, loading, error }) => {
          if (loading && !regions) return <Spinner size={60} />
          if (error) {
            errorToast('Failed to load additional marketplace data')
            return null
          }
          return (
            <DiscountCodesDetailsDrawer
              isOpen={!!discountId}
              drawerClose={() => {
                onChangeDiscountId(undefined)
              }}
              discountId={discountId}
              marketplaceId={marketplaceId}
              enableAnalytics={Boolean(get(regions, '[0].enableAnalytics'))}
              refetchVars={{
                marketplaceId,
                skip: marketplaceVouchersPagination.skip,
                first: marketplaceVouchersPagination.first,
                searchText: '',
                active: !showArchivedVouchers,
                orderBy: 'createdAt_DESC',
              }}
            />
          )
        }}
      </Query>
    </div>
  )
}

export default MarketplaceVouchers
