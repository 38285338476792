import gql from 'graphql-tag'

const ADD_RESTAURANT = gql`
  mutation addRestaurant(
    $name: String!
    $contactName: String!
    $contactAddress: AddressCreateInput!
    $contactPhone: String!
    $contactEmail: String!
    $partnerId: String!
    $outletMarketplaceId: String
    $outletName: String
  ) {
    addRestaurant(
      name: $name
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      partnerId: $partnerId
      outletMarketplaceId: $outletMarketplaceId
      outletName: $outletName
    ) {
      message
      restaurant {
        id
        partner {
          id
        }
        outlets {
          id
          name
        }
      }
    }
  }
`

export default ADD_RESTAURANT
