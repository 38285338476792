import React from 'react'
import { Intent, Switch, Callout } from '@blueprintjs/core'
import { func, object, string } from 'prop-types'

const ActivationForm = ({
  values,
  handleSubmit,
  handleChange,
  restaurantName,
}) => (
  <form className="bp5-layout-col">
    <Callout
      intent={values.active ? Intent.SUCCESS : Intent.NONE}
      icon={null}
      title={
        <Switch
          name="active"
          alignIndicator="right"
          label="Activate Outlet"
          checked={values.active}
          onChange={e => {
            handleChange(e)
            handleSubmit(e)
          }}
          large
        />
      }
    >
      <span className="bp5-text-muted">
        {`Activate the Outlet to allow it to take orders from "${restaurantName}".`}
      </span>
    </Callout>
  </form>
)

ActivationForm.propTypes = {
  values: object,
  restaurantName: string,
  setFieldValue: func,
  handleSubmit: func,
  handleChange: func,
}

export default ActivationForm
