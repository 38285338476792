import { Fragment, useState, useContext, useEffect, useCallback } from 'react'
import UserModal from './Users.modal'
import FilterRow from '@components/FilterRow/FilterRow'
import {
  HTMLTable,
  Card,
  Tag,
  Button,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip,
  Icon,
  NonIdealState,
  ButtonGroup,
  ControlGroup,
} from '@blueprintjs/core'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'
import ARCHIVE_USER from '@components/UserForm/mutations/deleteUser.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Pager, Search } from '@components/Toolbar'
import ReinviteModal from './Reinvite/Reinvite.modal'
import { string } from 'prop-types'
import usersPageContext from './usersPageContext'
import { get } from 'lodash'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import { useMutation } from '@apollo/client'

const tableHead = [
  { key: 'email', content: 'Email' },
  { key: 'name', content: 'Name' },
  { key: 'phoneNumber', content: 'Phone Number' },
  { key: 'role', content: 'Role' },
  { key: 'permission', content: 'Permission' },
  { key: 'delete', content: '' },
]

const DEFAULT_RECORDS = 20
const DEFAULT_PAGINATION_STATE = {
  total: null,
  outcomeLength: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  first: DEFAULT_RECORDS,
  last: null,
  skip: 0,
  orderBy: 'createdAt_ASC',
}

const Users = props => {
  const context = useContext(usersPageContext)

  let where = {}

  if (props.platformId) {
    where.ownsPlatforms_some = { id: props.platformId }
  }

  if (props.partnerId) {
    where = {
      OR: [
        { ownsPartners_some: { id: props.partnerId } },
        { ownsRestaurants_some: { partner: { id: props.partnerId } } },
        { ownsMarketplaces_some: { partner: { id: props.partnerId } } },
        {
          ownsOutlets_some: {
            marketplace: { partner: { id: props.partnerId } },
          },
        },
      ],
    }
  }

  if (props.restaurantId) {
    where.ownsRestaurants_some = { id: props.restaurantId }
  }

  if (props.outletId) {
    where.ownsOutlets_some = { id: props.outletId }
  }

  const marketplaceId = get(props, 'match.params.marketplace')
  if (marketplaceId) {
    where = {
      OR: [
        { ownsMarketplaces_some: { id: marketplaceId } },
        {
          ownsRestaurants_some: {
            outlets_some: { marketplace: { id: marketplaceId } },
          },
        },
        { ownsOutlets_some: { marketplace: { id: marketplaceId } } },
      ],
    }
  }

  const [state, setState] = useState({
    ...DEFAULT_PAGINATION_STATE,
    where,
    reinviteUser: { modalOpen: false, id: null },
  })

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({ ...prevState, total: totalCount }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({ ...prevState, outcomeLength: returnCount }))
    }
  }

  const goToFirst = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      skip: 0,
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }, [])

  const { searchValue: emailSearch } = useSearchQueryParam()
  useEffect(() => {
    goToFirst()
  }, [emailSearch, goToFirst])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(state.skip, state.first, limit),
        first: state.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(state.skip, state.first),
      first: state.defaultNmbRecords,
      last: null,
    }))
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const { first, last, skip } = state
  const currentWhere =
    emailSearch.length > 2
      ? { ...state.where, email_contains: emailSearch }
      : state.where

  const [archiveUser] = useMutation(ARCHIVE_USER, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('User deleted'),
    refetchQueries: [
      {
        query: GET_ALL_USERS,
        variables: {
          first,
          last,
          where: currentWhere,
          skip,
        },
      },
    ],
  })

  return (
    <Fragment>
      <div className="bp5-table-frame">
        <FilterRow>
          <ButtonGroup>
            <ControlGroup>
              <Search autoFocus placeholder="Name, email..." />
            </ControlGroup>
          </ButtonGroup>
          <Pager
            goToPrevious={goToPrevious}
            goToNext={goToNext}
            goToPage={goToPage}
            defaultNmbRecords={DEFAULT_RECORDS}
            skip={state.skip}
            total={state.total}
            outcomeLength={state.outcomeLength}
            totalCount={state.total}
            dataName="Users"
          />
        </FilterRow>
        <DebouncedQuery
          query={GET_ALL_USERS}
          variables={{
            first,
            last,
            where: currentWhere,
            skip,
          }}
          loaderTitle={'Loading Users'}
          onCompleted={({ getUsers: { users, totalCount } }) => {
            setTotalCount(totalCount, users.length)
          }}
        >
          {({ getUsers: { users, totalCount } }) => {
            return users && totalCount ? (
              <div className="bp5-table-container bp5-scrollable">
                <ReinviteModal
                  {...state.reinviteUser}
                  modalClose={() =>
                    setState(prevState => ({
                      ...prevState,
                      reinviteUser: { modalOpen: false, id: null },
                    }))
                  }
                />
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        {tableHead.map(({ key, content }) => (
                          <th key={key}>{content}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => (
                        <tr key={user.id}>
                          <td>
                            <a
                              onClick={() => {
                                context.setModalOpen(true)
                                context.setUser(user)
                              }}
                            >
                              {user.email}
                            </a>{' '}
                            {!user.hasRegistered ? (
                              <Tooltip content="User has not yet registered">
                                <Icon
                                  icon="warning-sign"
                                  intent="warning"
                                  onClick={() =>
                                    setState(prevState => ({
                                      ...prevState,
                                      reinviteUser: {
                                        modalOpen: true,
                                        id: user.id,
                                      },
                                    }))
                                  }
                                />
                              </Tooltip>
                            ) : null}
                          </td>
                          <td>
                            {user.firstName || (
                              <em className="bp5-text-disabled">Unknown</em>
                            )}{' '}
                            {user.lastName}
                          </td>
                          <td>
                            {user.phoneNumber || (
                              <em className="bp5-text-disabled">Unknown</em>
                            )}
                          </td>
                          <td>
                            {user.role.title.replace(
                              /Restaurant/i,
                              'Business'
                            ) || <em className="bp5-text-disabled">Unknown</em>}
                          </td>
                          <td>
                            <div className="bp5-tag-input">
                              <div className="bp5-tag-input-values">
                                {user.ownsPlatforms.map(({ id, name }) => (
                                  <Tag minimal={true} key={id}>
                                    {name}
                                  </Tag>
                                ))}
                                {user.ownsPartners.map(({ id, name }) => (
                                  <Tag minimal={true} key={id}>
                                    {name}
                                  </Tag>
                                ))}
                                {user.ownsRestaurants.map(({ id, name }) => (
                                  <Tag minimal={true} key={id}>
                                    {name}
                                  </Tag>
                                ))}
                                {user.ownsOutlets.map(({ id, name }) => (
                                  <Tag minimal={true} key={id}>
                                    {name}
                                  </Tag>
                                ))}
                                {user.ownsMarketplaces.map(({ id, name }) => (
                                  <Tag minimal={true} key={id}>
                                    {name}
                                  </Tag>
                                ))}
                              </div>
                            </div>
                          </td>
                          <td className="bp5-action-cell">
                            <Popover
                              interactionKind={PopoverInteractionKind.CLICK}
                              popoverClassName="bp5-tooltip-body-sizing"
                              position={Position.LEFT}
                              content={
                                <div>
                                  <h5 className={'bp5-heading'}>Delete User</h5>
                                  <p>
                                    Are you sure you want to permanently delete{' '}
                                    {user.email}?
                                  </p>
                                  <div className="bp-popover-footer-actions">
                                    <Button
                                      className={
                                        'bp5-button bp5-popover-dismiss'
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className={
                                        'bp5-button bp5-intent-danger bp5-popover-dismiss'
                                      }
                                      onClick={() =>
                                        archiveUser({
                                          variables: { id: user.id },
                                        })
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              }
                            >
                              <Button icon={'trash'} minimal={true} />
                            </Popover>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            ) : (
              <NonIdealState
                icon="user"
                title="No Users"
                description={
                  context.entityName === 'user'
                    ? 'Invite users by email.'
                    : `Invite users by email to help you manage this ${context.entityName}.`
                }
                action={
                  <Button
                    icon="plus"
                    text="Invite User"
                    onClick={() => context.setModalOpen(true)}
                  />
                }
              />
            )
          }}
        </DebouncedQuery>
      </div>
      <UserModal
        modalOpen={context.modalOpen}
        modalClose={() => {
          context.setModalOpen(false)
          context.setUser(null)
        }}
        user={context.user}
      />
    </Fragment>
  )
}

Users.propTypes = {
  outletId: string,
  partnerId: string,
  platformId: string,
  restaurantId: string,
  entityName: string,
}

export default Users
