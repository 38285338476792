import gql from 'graphql-tag'

const GET_DELIVERY_ZONES = gql`
  query getDeliveryZones($marketplaceId: String!) {
    getDeliveryZones(marketplaceId: $marketplaceId) {
      deliveryZones {
        id
        name
        deliveryZoneType
        outletDeliveryZoneCosts {
          id
          outlet {
            id
            name
          }
        }
      }
    }
  }
`

export default GET_DELIVERY_ZONES
