import React, { useContext } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Drawer, NonIdealState } from '@blueprintjs/core'
import { OptionItemImport } from './OptionItemImport'
import { OptionImport } from './OptionImport'
import { MenuOptionItemDefinition } from '../definitions/MenuOptionItemDefinition'
import { MenuOptionDefinition } from '../definitions/MenuOptionDefinition'

const ImportContent = ({ importData, restaurantId, handleClose }) => {
  if (restaurantId && importData && importData.data.length) {
    const keys = Object.keys(importData.data[0]).join('-')
    if (keys === MenuOptionItemDefinition.getHeader().join('-')) {
      return (
        <OptionItemImport
          restaurantId={restaurantId}
          importData={importData}
          handleClose={handleClose}
        />
      )
    }
    if (keys === MenuOptionDefinition.getHeader().join('-')) {
      return (
        <OptionImport
          restaurantId={restaurantId}
          importData={importData}
          handleClose={handleClose}
        />
      )
    }
  }

  return (
    <NonIdealState
      icon="error"
      title="The imported file does not match any of the known formats"
    />
  )
}

export function OptionsImport({
  show = false,
  onClose = () => {},
  importData,
  restaurantId,
}) {
  const { dark } = useContext(PageLayoutContext)
  return (
    <Drawer
      isOpen={show}
      onClose={e => onClose(e)}
      title="Import Data"
      size={'100%'}
      className={dark ? 'bp5-dark' : ''}
      position="bottom"
    >
      <ImportContent
        restaurantId={restaurantId}
        importData={importData}
        handleClose={onClose}
      />
    </Drawer>
  )
}
