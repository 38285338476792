const dragHalfway = ({ item, index, ref, monitor, callback }) => {
  if (!ref.current) {
    return
  }
  const dragIndex = item.index
  const hoverIndex = index
  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return
  }
  // Determine rectangle on screen
  const hoverBoundingRect = ref.current.getBoundingClientRect()
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  // Determine mouse position
  const clientOffset = monitor.getClientOffset()
  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top
  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return
  }
  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return
  }
  // Time to actually perform the action
  callback(dragIndex, hoverIndex, item)
  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  item.index = hoverIndex
}

export default dragHalfway
