import gql from 'graphql-tag'

const GET_ENABLE_LOYALTY_ON_MARKETPLACE = gql`
  query getEnableLoyaltyOnMarketplace($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        enableLoyalty
      }
    }
  }
`

export default GET_ENABLE_LOYALTY_ON_MARKETPLACE
