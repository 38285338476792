import ReactECharts from 'echarts-for-react'
import { useCurrencyFormatter } from '../Currency/useCurrencyFormatter'
import { NonIdealState } from '@blueprintjs/core'

interface Props {
  data: {
    /**
     * Monday is 0, Tuesday is 1 ... Sunday is 6
     */
    day: number

    /** Midnight is 0, 11pm is 23 */
    hour: number
    value: number | null | undefined
  }[]
  dataType: 'currency' | 'number' | 'percentage'
}

const HOURS = [
  '00:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const DayHourHeatmap: React.FC<Props> = ({
  data: dataIncludingNullish,
  dataType,
}) => {
  const data: { value: number; day: number; hour: number }[] = []

  for (const item of dataIncludingNullish) {
    const { value, day, hour } = item
    if (value !== undefined && value !== null) data.push({ value, day, hour })
  }

  if (data.length === 0)
    return (
      <div style={{ padding: '50px' }}>
        <NonIdealState
          title="No Data"
          description="No data found - try expanding the range."
        />
      </div>
    )
  const { formatPence } = useCurrencyFormatter()

  let formatter
  let maxValue
  switch (dataType) {
    case 'currency':
      formatter = formatPence
      maxValue = Math.max(...data.map(x => x.value))
      break
    case 'percentage':
      formatter = value => Math.round(100 * value) + '%'
      maxValue = 1
      break
    case 'number':
    default:
      if (!dataType)
        console.error(
          'DayHourHeatmap expected a `dataType` but did not receive it, defaulting to numeric values'
        )
      formatter = value => value
      maxValue = Math.max(...data.map(x => x.value))
      break
  }

  return (
    <ReactECharts
      option={{
        tooltip: {
          position: 'top',
          formatter: ({ data: [hour, day, value] }) =>
            `${DAYS[day]} at ${HOURS[hour]}: <b>${formatter(value)}</b>`,
        },
        grid: {
          height: '50%',
          top: '10%',
        },
        series: [
          {
            type: 'heatmap',
            label: { show: false },
            data: data.map(({ day, hour, value }) => {
              const output = [hour, day, value]
              return output
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
        visualMap: {
          min: 0,
          max: maxValue,
          formatter,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '15%',
          precision: 0,
        },
        xAxis: {
          type: 'category',
          data: HOURS,
          splitArea: {
            show: true,
          },
          position: 'top',
        },
        yAxis: {
          type: 'category',
          data: DAYS,
          axisLine: { onZero: false },
          inverse: true,
          splitArea: {
            show: true,
          },
        },
      }}
    />
  )
}
