import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRejectionsReportByOutletQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']['input'];
  dateFrom: Types.Scalars['DateTime']['input'];
  dateTo: Types.Scalars['DateTime']['input'];
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetRejectionsReportByOutletQuery = { __typename?: 'Query', getRejectionsReportByOutlet?: Array<{ __typename?: 'RejectionsReportByOutlet', id: string, totalOrders?: number | null, totalValue?: number | null, totalRejectionRate?: number | null, totalRejectedOrders?: number | null, totalRejectedValue?: number | null, manualRejectionRate?: number | null, manualRejectedOrders?: number | null, manualRejectedValue?: number | null, autoRejectionRate?: number | null, autoRejectedOrders?: number | null, autoRejectedValue?: number | null, outlet: { __typename?: 'Outlet', id: string, name: string, contactName?: string | null, contactEmail?: string | null, contactPhone?: string | null, isOpen?: boolean | null, isOnline?: boolean | null, active?: boolean | null, promoted?: boolean | null, contactAddress?: { __typename?: 'Address', city: string, postcode: string } | null, restaurant: { __typename?: 'Restaurant', id: string, name: string, active?: boolean | null }, marketplace?: { __typename?: 'RegionalMarketplace', id: string, name: string } | null } }> | null };


export const GetRejectionsReportByOutletDocument = gql`
    query GetRejectionsReportByOutlet($marketplaceId: String!, $dateFrom: DateTime!, $dateTo: DateTime!, $orderBy: String) {
  getRejectionsReportByOutlet(
    marketplaceId: $marketplaceId
    dateFrom: $dateFrom
    dateTo: $dateTo
    orderBy: $orderBy
  ) {
    id
    outlet {
      id
      name
      contactName
      contactEmail
      contactPhone
      contactAddress {
        city
        postcode
      }
      promoted: promote
      isOpen
      isOnline
      active
      restaurant {
        id
        name
        active
      }
      marketplace {
        id
        name
      }
    }
    totalOrders
    totalValue
    totalRejectionRate
    totalRejectedOrders
    totalRejectedValue
    manualRejectionRate
    manualRejectedOrders
    manualRejectedValue
    autoRejectionRate
    autoRejectedOrders
    autoRejectedValue
  }
}
    `;

/**
 * __useGetRejectionsReportByOutletQuery__
 *
 * To run a query within a React component, call `useGetRejectionsReportByOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRejectionsReportByOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRejectionsReportByOutletQuery({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetRejectionsReportByOutletQuery(baseOptions: Apollo.QueryHookOptions<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables> & ({ variables: GetRejectionsReportByOutletQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>(GetRejectionsReportByOutletDocument, options);
      }
export function useGetRejectionsReportByOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>(GetRejectionsReportByOutletDocument, options);
        }
export function useGetRejectionsReportByOutletSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>(GetRejectionsReportByOutletDocument, options);
        }
export type GetRejectionsReportByOutletQueryHookResult = ReturnType<typeof useGetRejectionsReportByOutletQuery>;
export type GetRejectionsReportByOutletLazyQueryHookResult = ReturnType<typeof useGetRejectionsReportByOutletLazyQuery>;
export type GetRejectionsReportByOutletSuspenseQueryHookResult = ReturnType<typeof useGetRejectionsReportByOutletSuspenseQuery>;
export type GetRejectionsReportByOutletQueryResult = Apollo.QueryResult<GetRejectionsReportByOutletQuery, GetRejectionsReportByOutletQueryVariables>;