import gql from 'graphql-tag'

const ENABLE_PAYBOX_ENTERPRISE = gql`
  mutation enablePayboxEnterprise(
    $id: String!
    $enablePayboxEnterprise: Boolean!
  ) {
    editMarketplace(id: $id, enablePayboxEnterprise: $enablePayboxEnterprise) {
      message
      marketplace {
        id
        enablePayboxEnterprise
      }
    }
  }
`

export default ENABLE_PAYBOX_ENTERPRISE
