import gql from 'graphql-tag'

const EDIT_OPTION_ITEM = gql`
  mutation editOptionItem(
    $id: String!
    $name: String
    $price: Int
    $soldOut: Boolean
    $vatRate: Float
    $VATinclusive: Boolean
    $image: String
  ) {
    editOptionItem(
      id: $id
      name: $name
      price: $price
      soldOut: $soldOut
      vatRate: $vatRate
      VATinclusive: $VATinclusive
      image: $image
    ) {
      message
      optionItem {
        id
        name
        price
        soldOut
        vatRate
        VATinclusive
        imageImplementer {
          id
          src
        }
      }
    }
  }
`

export default EDIT_OPTION_ITEM
