import { Card, Drawer, HTMLTable, Icon, NonIdealState } from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import moment from 'moment'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import productDictionary from '../../utils/productDictionary'
import EmailDialog from './EmailDialog'
import DELETE_MESSAGE_HISTORY from './mutations/deleteMessageHistory.mutation'
import GET_MESSAGES from './queries/getMessages.query'
import SendNewMessageToOutlets from './SendNewMessageToOutlets'
import { useMutation } from '@apollo/client'

const Sent = ({ product, url }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const { configurePageLayout } = useContext(PageLayoutContext)
  const [showNewMessageDrawer, setShowNewMessageDrawer] = useState(false)
  const [messageId, setMessageId] = useQueryParam(
    'message',
    withDefault(StringParam, null)
  )

  const showMessage = message => {
    setMessageId(message.id)
  }

  const { marketplaceId } = useParams()

  useEffect(() => {
    const tabs = [
      { to: `/messaging/inbox/${selectedMarketplace}`, name: 'Inbox' },
      { to: `/messaging/sent/${selectedMarketplace}`, name: 'Sent' },
    ]

    configurePageLayout({
      product: productDictionary.MESSAGING,
      tabs,
      button: {
        text: 'New Message',
        onClick: () => setShowNewMessageDrawer(true),
      },
    })
  }, [configurePageLayout])

  const [deleteMessageHistory] = useMutation(DELETE_MESSAGE_HISTORY, {
    onError: defaultErrorHandler,
    onCompleted: ({ deleteMessageHistory }) => {
      successToast(deleteMessageHistory.message)
    },
    refetchQueries: [
      {
        query: GET_MESSAGES,
        variables: {
          marketplaceId: marketplaceId,
          messageType: 'OUTGOING',
        },
      },
    ],
  })

  return (
    <Fragment>
      <div className="bp5-table-frame">
        {!marketplaceId ? (
          <NonIdealState
            icon="list"
            title="No marketplace selected"
            description="Select marketplace to fetch messages."
          />
        ) : (
          <Fragment>
            <DebouncedQuery
              query={GET_MESSAGES}
              variables={{
                marketplaceId: marketplaceId,
                messageType: 'OUTGOING',
              }}
              loaderTitle={'Loading Messages'}
            >
              {data => {
                if (
                  !data ||
                  !data.getMessages ||
                  !data.getMessages.messages.length
                ) {
                  return (
                    <NonIdealState
                      icon="th-list"
                      title="No Messages Found"
                      description="There are no messages sent."
                    />
                  )
                }

                const {
                  getMessages: { messages },
                } = data

                const selectedMessage = messageId
                  ? messages.find(msg => msg.id === messageId)
                  : null

                return (
                  <Fragment>
                    {!!messages.length && (
                      <div className="bp5-table-container bp5-scrollable">
                        <Card className={'bp5-nopad'}>
                          <HTMLTable bordered={false} interactive={true}>
                            <thead>
                              <tr>
                                <th style={{ width: '70%' }}>Subject</th>
                                <th>To</th>
                                <th style={{ minWidth: '150px' }}>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {messages.map(message => (
                                <tr key={message.id}>
                                  <td onClick={() => showMessage(message)}>
                                    {message.subject}
                                  </td>
                                  <td onClick={() => showMessage(message)}>
                                    {message.to.length > 5
                                      ? `${message.to.length} receivers`
                                      : message.to.toString()}
                                  </td>
                                  <td onClick={() => showMessage(message)}>
                                    {moment(message.date).format(
                                      'HH:mm DD/MM/YY '
                                    )}
                                  </td>
                                  <td>
                                    <ConfirmationPopover
                                      confirmationText={`Delete message?`}
                                      remove={() => {
                                        deleteMessageHistory({
                                          variables: {
                                            messageId: message.id,
                                          },
                                        })
                                      }}
                                    >
                                      <div style={{ margin: '10px 10px' }}>
                                        <Icon icon="trash" />
                                      </div>
                                    </ConfirmationPopover>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </HTMLTable>
                        </Card>
                        {messageId && (
                          <EmailDialog
                            selectedMessage={selectedMessage}
                            isInbox={false}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                )
              }}
            </DebouncedQuery>
            <Drawer
              isOpen={showNewMessageDrawer}
              onClose={() => setShowNewMessageDrawer(false)}
              title="Send New Message"
            >
              <SendNewMessageToOutlets
                setShowNewMessageDrawer={setShowNewMessageDrawer}
              />
            </Drawer>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sent
