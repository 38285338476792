import { Query } from '@apollo/client/react/components'
import { Button, Classes, Drawer, Intent } from '@blueprintjs/core'
import { Fragment, useEffect, useRef, useState } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import GET_MESSAGE_HISTORY from './queries/getMessageHistory.query'
import { SendMessageReply } from './SendMessageReply'
import { sanitizedHtmlWithoutSubstitute } from '@components/EmailLivePreview/HTMLEmailPreview'

const EmailThread = ({ messages }) => {
  const [expandedEmails, setExpandedEmails] = useState(() => {
    const lastMessageId = messages[messages.length - 1]?.id
    return { [lastMessageId]: true }
  })

  const latestEmailRef = useRef(null)
  const toggleEmail = messageId => {
    setExpandedEmails(prev => ({
      ...prev,
      [messageId]: !prev[messageId],
    }))
  }

  return messages.map((message, index) => {
    const isLatest = index === 0
    return (
      <div
        key={message.id}
        ref={isLatest ? latestEmailRef : null}
        style={{ marginBottom: '20px' }}
      >
        <div
          onClick={() => toggleEmail(message.id)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#f5f5f5',
            borderRadius: '3px',
            cursor: 'pointer',
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <span style={{ fontWeight: 600 }}>
                From: <span style={{ fontWeight: 400 }}>{message.from}</span>
              </span>
              <span style={{ color: '#666' }}>
                {new Date(message.date).toLocaleString()}
              </span>
            </div>
            <div style={{ marginBottom: '5px', fontWeight: 600 }}>
              To: <span style={{ fontWeight: 400 }}>{message.to}</span>
            </div>
            <div style={{ fontWeight: 600 }}>
              Subject:{' '}
              <span style={{ fontWeight: 400 }}>{message.subject}</span>
            </div>
          </div>

          <Button
            minimal
            icon={expandedEmails[message.id] ? 'chevron-up' : 'chevron-down'}
            onClick={() => {
              toggleEmail(message.id)
            }}
          />
        </div>
        {expandedEmails[message.id] && (
          <div
            style={{
              padding: '15px',
              whiteSpace: 'pre-wrap',
              backgroundColor: '#fff',
              border: '1px solid #e1e1e1',
              borderRadius: '3px',
              marginTop: '5px',
            }}
          >
            {sanitizedHtmlWithoutSubstitute(message.html)}
          </div>
        )}
      </div>
    )
  })
}

const EmailDialog = ({ isInbox, selectedMessage }) => {
  const [messageId, setMessageId] = useQueryParam(
    'message',
    withDefault(StringParam, null)
  )
  const [isReplying, setIsReplying] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const drawerContentRef = useRef(null)

  useEffect(() => {
    if (isReplying && drawerContentRef.current) {
      setTimeout(() => {
        drawerContentRef.current.scrollTop =
          drawerContentRef.current.scrollHeight
      }, 100)
    }
  }, [isReplying])

  if (!selectedMessage) {
    return null
  }
  return (
    <Query
      query={GET_MESSAGE_HISTORY}
      variables={{
        marketplaceId: selectedMessage.marketplace.id,
        messageSubject: selectedMessage.subject,
        emailsFrom:
          typeof selectedMessage.from === 'string'
            ? [selectedMessage.from]
            : selectedMessage.from,
        emailsTo:
          typeof selectedMessage.to === 'string'
            ? [selectedMessage.to]
            : selectedMessage.to,
      }}
    >
      {({ data }) => {
        if (!data || !data.getMessageHistory) {
          return null
        }

        const { messages } = data.getMessageHistory

        return (
          <Fragment>
            {messages.length > 0 && (
              <Drawer
                isOpen={!!messageId}
                onClose={() => setMessageId(undefined)}
                title={selectedMessage.subject}
                className="replyMessageDrawer"
                onOpened={() => {
                  if (drawerContentRef.current) {
                    drawerContentRef.current.scrollTop =
                      drawerContentRef.current.scrollHeight
                  }
                }}
                canOutsideClickClose={!isReplying}
              >
                <div ref={drawerContentRef} className={Classes.DRAWER_BODY}>
                  <EmailThread messages={messages} />
                  <SendMessageReply
                    messages={messages}
                    customersList={
                      data.customers ? data.customers.customers : []
                    }
                    isInbox={isInbox}
                    setEmailSent={setEmailSent}
                    isReplying={isReplying}
                  />
                </div>
                <div className={Classes.DRAWER_FOOTER}>
                  {!isReplying ? (
                    <Button
                      text="Reply"
                      intent={Intent.NONE}
                      type="button"
                      disabled={emailSent}
                      onClick={() => {
                        setIsReplying(true)
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        text="Send"
                        intent={Intent.NONE}
                        type="submit"
                        disabled={emailSent}
                      />
                      <Button
                        text="Cancel"
                        intent={Intent.NONE}
                        type="button"
                        onClick={() => setIsReplying(false)}
                      />
                    </div>
                  )}
                </div>
              </Drawer>
            )}
          </Fragment>
        )
      }}
    </Query>
  )
}

export default EmailDialog
