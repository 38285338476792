import gql from 'graphql-tag'

const EDIT_MIN_APP_VERSION = gql`
  mutation editMinAppVersionSupported(
    $id: String!
    $minAppVersionSupported: String!
  ) {
    editMinAppVersionSupported(
      id: $id
      minAppVersionSupported: $minAppVersionSupported
    ) {
      message
      marketplace {
        id
        minAppVersionSupported
      }
    }
  }
`

export default EDIT_MIN_APP_VERSION
