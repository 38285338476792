import React from 'react'
import { Formik } from 'formik'
import { Switch } from '@blueprintjs/core'
import { isAtLeastMarketplaceAdmin } from '@stores/userStore'

const MarketplaceAllowOtterIntegrationSwtich = ({
  updateStatus,
  allowOtterIntegration,
}) => {
  return (
    <Formik initialValues={{ allowOtterIntegration }}>
      {({ values, setFieldValue }) => (
        <section>
          <Switch
            label={'Allow Otter Integration'}
            checked={values.allowOtterIntegration}
            onChange={e => {
              const newValue = e.currentTarget.checked
              updateStatus({
                allowOtterIntegration: e.currentTarget.checked,
              })
              setFieldValue('allowOtterIntegration', newValue)
            }}
            name="allowOtterIntegration"
            disabled={!isAtLeastMarketplaceAdmin()}
          />
        </section>
      )}
    </Formik>
  )
}

export default MarketplaceAllowOtterIntegrationSwtich
