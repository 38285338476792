import { useContext, useEffect } from 'react'
import {
  isOutletUser,
  canView,
  canPerformAction,
  isAtLeastMenuEditor,
  isAtLeastOutletFinancialUser,
} from '../../stores/userStore'
import products from '../../utils/productDictionary'
import { PageLayoutContext } from '../PageLayout/PageLayout'
import { useQuery } from '@apollo/client'
import GET_ONBOARDING_COUNT from './List/queries/getOboardingCount.query'
import { useModal } from '../../providers/ModalProvider'

export const useBusinessPageLayout = () => {
  const { toggleModal } = useModal('restaurantModal')
  const userCanViewOnboarding = canView('onboarding')
  const { data } = useQuery(GET_ONBOARDING_COUNT, {
    skip: !userCanViewOnboarding,
  })

  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    /** @type {Array<{to: string, name: string, count?: string, visible: boolean}>} */
    const tabs = [
      {
        to: '/business',
        name: 'Businesses',
        visible: isAtLeastMenuEditor() && !isOutletUser(),
      },
      {
        to: '/outlets',
        name: 'Outlets',
        visible: isAtLeastOutletFinancialUser(),
      },
      {
        to: '/terminals',
        name: 'Terminals',
        visible: canView('virtualTerminals'),
      },
      {
        to: '/onboardingList',
        name: 'Onboarding',
        count: data?.getRestaurants?.count,
        visible: canView('onboarding'),
      },
    ].filter(tab => tab.visible)

    configurePageLayout({
      product: products.RESTAURANT,
      button: {
        text: 'Add Business',
        onClick: () => toggleModal(true),
      },
      tabs,
      permissions: canPerformAction('addRestaurant'),
    })
  }, [configurePageLayout])
}
