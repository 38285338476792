import gql from 'graphql-tag'

const GET_MARKETPLACE_APPS_BY_PARTNER_ID = gql`
  query GetMarketplaceAppsByPartnerId(
    $partnerId: ID!
    $skip: Int!
    $first: Int!
  ) {
    getMarketplaces(
      partnerIds: [$partnerId]
      orderBy: name_ASC
      skip: $skip
      first: $first
    ) {
      count
      totalCount
      regions {
        id
        name
        app {
          id
          appBuilds {
            id
            device
            version
            releaseToAppcenter
            releaseToStore
            createdAt
            appBuildStatuses {
              id
              status
              createdAt
            }
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE_APPS_BY_PARTNER_ID
