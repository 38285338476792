import { Drawer, DrawerSize, Spinner } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { Fragment, useState } from 'react'
import GET_LINK_CARD from './queries/getLinkCard.query'
import GET_LINK_CARDS from './queries/getLinkCards.query'
import EDIT_LINK_CARD from './mutations/editLinkCard.mutation'
import EditLinkCardForm from './EditLinkCardForm'
import { useMutation } from '@apollo/client'

const EditLinkCardDrawer = ({ id, isOpen, marketplaceId, onClose }) => {
  const [loading, setLoading] = useState(false)

  const [editLinkCard] = useMutation(EDIT_LINK_CARD, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: () => {
      setLoading(false)
      onClose()
      successToast(`Link Card Updated Successfully`)
    },
    refetchQueries: [
      {
        query: GET_LINK_CARD,
        variables: {
          id,
        },
      },
      {
        query: GET_LINK_CARDS,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  if (loading) return <Spinner />
  return (
    <Drawer
      title="Edit Link Card"
      isOpen={isOpen}
      size={DrawerSize.STANDARD}
      onClose={() => onClose()}
    >
      <Query query={GET_LINK_CARD} variables={{ id }}>
        {({ getLinkCard: linkCard }) => {
          if (!linkCard) {
            return null
          }
          return (
            <Fragment>
              {linkCard && (
                <EditLinkCardForm
                  marketplaceId={marketplaceId}
                  linkCard={linkCard}
                  editRequest={editLinkCard}
                  close={onClose}
                />
              )}
            </Fragment>
          )
        }}
      </Query>
    </Drawer>
  )
}

export default EditLinkCardDrawer
