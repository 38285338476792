import React, { Fragment } from 'react'
import {
  FormGroup,
  HTMLSelect,
  InputGroup,
  Classes,
  Button,
  Intent,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core'
import { func, object, bool } from 'prop-types'
import { selectionUpdate } from '@utils/helpers'
import Query from '@components/Query/Query'
import GET_ROLES from '@components/UserForm/queries/getAllRoles.query'
import PartnerMultipleSelect from '@components/PartnerSelect/PartnerMultipleSelect'
import PlatformSelect from '@components/PlatformSelect/PlatformSelect'
import RestaurantMultipleSelect from '@components/RestaurantSelect/RestaurantMultipleSelect'
import GET_MARKETPLACE_IDS from '@components/MarketplaceSelect/queries/getMarketplaceIds.query.js'
import PhoneInput from '@components/PhoneInput/PhoneInput'
import TypeaheadMulti from '../Typeahead/TypeaheadMulti'
import OutletTypeahead from '@components/OutletTypeahead/OutletTypeahead'
import { castArray, get } from 'lodash'

const Form = ({
  values,
  handleChange,
  isNew,
  setFieldValue,
  handleSubmit,
  errors,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <FormGroup
      label="Email"
      helperText={errors.email ? errors.email : ''}
      intent={Intent.DANGER}
    >
      <InputGroup
        value={values.email}
        name="email"
        type="email"
        onChange={handleChange}
        intent={errors.email ? Intent.DANGER : Intent.NONE}
        disabled={!isNew}
      />
    </FormGroup>
    {isNew ? null : (
      <Fragment>
        <FormGroup
          label="First Name"
          helperText={errors.firstName ? errors.firstName : ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            value={values.firstName}
            name="firstName"
            type="text"
            onChange={handleChange}
            intent={errors.firstName ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>

        <FormGroup
          label="Last Name"
          helperText={errors.lastName ? errors.lastName : ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            value={values.lastName}
            name="lastName"
            type="text"
            onChange={handleChange}
            intent={errors.lastName ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>

        <FormGroup
          label="Phone Number"
          helperText={errors.phoneNumber ? errors.phoneNumber : ''}
          intent={Intent.DANGER}
        >
          <PhoneInput
            value={values.phoneNumber}
            inputProps={{ name: 'phoneNumber' }}
            onChange={phone => {
              setFieldValue('phoneNumber', phone)
            }}
          />
        </FormGroup>
      </Fragment>
    )}
    <Query query={GET_ROLES}>
      {({ getAllRoles: { roles } }) => (
        <FormGroup
          label="Role"
          labelFor="role"
          helperText={errors.roleKey ? errors.roleKey : ''}
          intent={Intent.DANGER}
        >
          <HTMLSelect
            name="role"
            options={[
              { value: '', label: 'Select role ...' },
              ...(roles || [])
                .map(({ key, title, canCreateRoles }) => ({
                  value: key,
                  label: title.replace(/Restaurant/i, 'Business'),
                  // order desc based on user power
                  position: Math.abs(canCreateRoles.length - roles.length),
                }))
                .sort((a, b) => a.position - b.position),
            ]}
            onChange={event => {
              setFieldValue('ownsPartnerIds', [], false)
              setFieldValue('ownsMarketplaceIds', [], false)
              setFieldValue('ownsOutletIds', [], false)
              setFieldValue('ownsRestaurantIds', [], false)

              const selection = selectionUpdate(event)

              setFieldValue(
                'ownsPlatformIds',
                ['SUPERADMIN', 'DEVELOPER'].includes(selection.value)
                  ? castArray(get(values.ownsPlatforms, '[0].id', []))
                  : [],
                false
              )
              setFieldValue('role.title', selection.label)
              setFieldValue('role.key', selection.value)
              setFieldValue('roleKey', selection.value)
            }}
            fill={true}
            value={values.role.key}
            intent={errors.roleKey ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
      )}
    </Query>
    {values.role && ['SUPERADMIN', 'DEVELOPER'].includes(values.role.key) && (
      <FormGroup label="Platform" labelFor="platform" labelInfo={'(required)'}>
        <PlatformSelect
          name="platform"
          platformId={values.ownsPlatformIds[0] || ''}
          onChange={event => {
            const selection = selectionUpdate(event)
            setFieldValue('ownsPlatformIds[0]', selection.value, false)
          }}
        />
      </FormGroup>
    )}
    {values.role && ['PARTNER', 'PARTNER_USER'].includes(values.role.key) && (
      <FormGroup label="Partner" labelFor="partner" labelInfo={'(required)'}>
        <PartnerMultipleSelect
          selected={values.ownsPartnerIds}
          setSelected={ids => {
            setFieldValue('ownsPartnerIds', ids, false)
          }}
        />
      </FormGroup>
    )}
    {values.role &&
      ['RESTAURANT_USER', 'MENU_EDITOR'].includes(values.role.key) && (
        <FormGroup
          label="Business"
          labelFor="restaurant"
          labelInfo={'(required)'}
        >
          <RestaurantMultipleSelect
            restaurantIds={values.ownsRestaurantIds}
            onChange={ids => setFieldValue('ownsRestaurantIds', ids, false)}
          />
        </FormGroup>
      )}
    {values.role &&
      ['OUTLET_USER', 'OUTLET_FINANCIAL_USER'].includes(values.role.key) && (
        <FormGroup label="Outlet" labelFor="outlet" labelInfo={'(required)'}>
          <OutletTypeahead
            outletIds={values.ownsOutletIds}
            onChange={val => setFieldValue('ownsOutletIds', val)}
            showRestaurantName
            showMarketplaceName
            alwaysMultiSelect
          />
        </FormGroup>
      )}
    {values.role &&
      [
        'MARKETPLACE_ADMIN',
        'MARKETPLACE_OWNER',
        'MARKETPLACE_USER',
        'MARKETING_USER',
      ].includes(values.role.key) && (
        <FormGroup
          label="Marketplace"
          labelFor="marketplace"
          labelInfo={'(required)'}
        >
          <Query query={GET_MARKETPLACE_IDS}>
            {({ getMarketplaces: { regions: marketplaces } }) => (
              <TypeaheadMulti
                items={marketplaces.map(({ id, name, partner }) => ({
                  id,
                  name,
                  label: partner.name,
                }))}
                placeholder="Select marketplaces..."
                selected={values.ownsMarketplaceIds}
                setSelected={selectedMarketplaceIds =>
                  setFieldValue('ownsMarketplaceIds', selectedMarketplaceIds)
                }
              />
            )}
          </Query>
        </FormGroup>
      )}
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button
        icon={isSubmitting ? <Spinner size={SpinnerSize.SMALL} /> : null}
        text={isNew ? 'Invite' : 'Save'}
        type="submit"
      />
    </div>
  </form>
)

Form.propTypes = {
  values: object,
  handleChange: func,
  isNew: bool,
  setFieldValue: func,
  handleSubmit: func,
  errors: object,
}
export default Form
