import gql from 'graphql-tag'

const TOGGLE_DISCOUNT_ACTIVATION = gql`
  mutation toggleDiscountActivation($id: ID!, $active: Boolean!) {
    toggleDiscountActivation(id: $id, active: $active) {
      message
    }
  }
`

export default TOGGLE_DISCOUNT_ACTIVATION
