import gql from 'graphql-tag'

const GET_CURRENCY = gql`
  query getCurrency {
    getCurrency {
      currency
    }
  }
`

export default GET_CURRENCY
