export const groupData = (payload, addCustomersData = false) => {
  const accumulator = {
    outlets: {
      value: 0,
      color: '',
      dataKey: '',
    },
    paymentMethods: [],
    fulfillmentMethods: [],
    userAgents: [],
    statuses: [],
    numberOfOrders: {
      total: 0,
      orders: [],
    },
    totalValues: {
      total: 0,
      orders: [],
    },
    avgValue: {
      value: 0,
      color: '',
      dataKey: '',
    },
    discountedValues: {
      total: 0,
      orders: [],
    },
  }
  if (addCustomersData) {
    accumulator.customers = {
      value: 0,
      color: '',
      dataKey: '',
    }
  }

  return payload.reduce((acc, entry) => {
    if (entry.name === 'numberOfOutlets') {
      acc.outlets = {
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      }
    } else if (entry.name.includes('paymentMethods')) {
      acc.paymentMethods.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (entry.name.includes('fulfillmentMethods')) {
      acc.fulfillmentMethods.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (entry.name.includes('statuses')) {
      acc.statuses.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (entry.name.includes('numberOfOrders')) {
      acc.numberOfOrders.total += entry.value
      acc.numberOfOrders.orders.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (entry.name.includes('subTotalWith')) {
      acc.totalValues.total += entry.value
      acc.totalValues.orders.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (
      entry.name === 'intervalOrderValueAverage' ||
      entry.name === 'dailyAverage'
    ) {
      acc.avgValue = {
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      }
    } else if (entry.name.includes('subTotalDiscounted')) {
      acc.discountedValues.total += entry.value
      acc.discountedValues.orders.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (entry.name.includes('userAgents')) {
      acc.userAgents.push({
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      })
    } else if (addCustomersData && entry.name === 'numberOfCustomers') {
      acc.customers = {
        value: entry.value,
        color: entry.color,
        dataKey: entry.dataKey,
      }
    }

    return acc
  }, accumulator)
}
