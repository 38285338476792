import gql from 'graphql-tag'

const GET_OUTLET_IDS = gql`
  query details($isOnlineOverride: Boolean, $restaurantId: String) {
    getOutlets(
      isOnlineOverride: $isOnlineOverride
      restaurantId: $restaurantId
      orderBy: name_ASC
    ) {
      outlets {
        id
        name
        marketplace {
          id
          name
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLET_IDS
