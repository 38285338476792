import 'setimmediate' // polyfill for generators

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { FocusStyleManager } from '@blueprintjs/core'
import { client } from '@services/client'

import BlankPage from '@components/BlankPage/BlankPage'
import Dashboard from '@components/Dashboard/Dashboard'
import Login from '@components/Login/Login'
import Register from '@components/Register/Register'
import TwoFactorAuth from '@components/TwoFactorAuth/TwoFactorAuth'
import ValidatePhone from '@components/ValidatePhone/ValidatePhone'

import Marketplace from '@components/Marketplace/Marketplace'
import Marketplaces from '@components/Marketplaces/Marketplaces'
import Onboarding from '@components/Onboarding/Onboarding'
import Orders from '@components/Orders/Orders'
import Outlet from '@components/Outlet/Outlet'
import Partner from '@components/Partner/Partner'
import Partners from '@components/Partners/Partners'
import Platform from '@components/Platform/Platform'
import Restaurant from '@components/Restaurant/Restaurant'
import VirtualTerminal from '@src/pages/VirtualTerminal/VirtualTerminal'
import Customer from '@src/pages/Customer/Customer'
import Customers from '@src/pages/Customers/Customers'
import Marketing from '@src/pages/Marketing/Marketing'
import Reports from '@src/pages/Reports/Reports'

import {
  canView,
  isOutletFinancialUser,
  isOutletUser,
  isPlatformLevelUser,
  jwtLogin,
  isMarketingUser,
} from '@stores/userStore'
import theme from '@styles/theme'

import { ApolloProvider } from '@apollo/client'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'
import { PageLayout } from '@components/PageLayout/PageLayout'
import { Admin } from '@src/pages/Admin/Admin'
import Messaging from '@src/pages/Messaging/Messaging'
import { isPeakTime } from '@utils/isPeakTime'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import Restaurants from '../Restaurants/List/Restaurants'
import OnboardingList from '../Restaurants/Onboarding/List'
import Outlets from '../Restaurants/Outlets/Outlets'
import Terminals from '../Restaurants/Terminals/Terminals'
import { NoPermissions } from '../UI/Permission/Permission'
import RoleProtectedRoute from '../RoleProtectedRoute/RoleProtectedRoute'
import config from '../../config'

// gets JWT from local storage
jwtLogin()

const restrictReports = isPeakTime() && config.isProd && !isPlatformLevelUser()

// focus styles are hidden for users interacting with a mouse, but will appear if they use tab
FocusStyleManager.onlyShowFocusOnTabs()

const App = () => {
  const getHomeRouteByRole = () => {
    if (canView('reports') && !isOutletFinancialUser()) return '/dashboard/day'
    if (isOutletUser() || isOutletFinancialUser()) return '/outlets'
    if (isMarketingUser()) return '/marketing'

    return '/business'
  }

  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        // options={{
        //   searchStringToObject: parse,
        //   objectToSearchString: stringify,
        // }}
      >
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/2fa" element={<TwoFactorAuth />} />
              <Route path="/validate-phone" element={<ValidatePhone />} />
              <Route path="/register" element={<Register />} />
              <Route path="/onboarding" element={<Onboarding />} />

              <Route path="/unauthorised" element={<NoPermissions />} />

              <Route element={<PageLayout />}>
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/dashboard/:period" element={<Dashboard />} />
                <Route
                  element={
                    <RoleProtectedRoute
                      hasPermission={canView('restaurants')}
                    />
                  }
                >
                  <Route
                    path="/dashboard"
                    element={<Navigate to="/dashboard/day" />}
                  />
                </Route>
                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('platform')} />
                  }
                >
                  <Route path="/platform/*" element={<Platform />} />
                </Route>
                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('partners')} />
                  }
                >
                  <Route path="/partners" element={<Partners />} />
                </Route>

                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('partners')} />
                  }
                >
                  <Route path="/partners/:partner/*" element={<Partner />} />
                </Route>

                <Route
                  element={
                    <RoleProtectedRoute
                      hasPermission={canView('marketplaces')}
                    />
                  }
                >
                  <Route path="/marketplaces" element={<Marketplaces />} />
                </Route>

                <Route
                  element={
                    <RoleProtectedRoute
                      hasPermission={canView('marketplaces')}
                    />
                  }
                >
                  <Route
                    path="/marketplaces/map"
                    element={<MarketplaceMap />}
                  />
                </Route>

                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('messaging')} />
                  }
                >
                  <Route path="/messaging/*" element={<Messaging />} />
                </Route>

                <Route
                  element={
                    <RoleProtectedRoute
                      hasPermission={canView('marketplaces')}
                    />
                  }
                >
                  <Route
                    path="/marketplaces/:marketplace/*"
                    element={<Marketplace />}
                  />
                </Route>

                <Route path="/orders/:statusFilter" element={<Orders />} />
                <Route path="/orders" element={<Orders />} />
                <Route
                  path="/virtual-terminal/*"
                  element={<VirtualTerminal />}
                />
                {/* <Route
                  path="/virtual-terminal/:statusFilter"
                  product={productDictionary.VIRTUALTERMINAL}
                  isPermissionAllowed={() => canView('virtualTerminals')}
                />
                <Route
                  path="/virtual-terminal"
                  exact={true}
                  product={productDictionary.VIRTUALTERMINAL}
                  element={<Navigate to="/virtual-terminal/active" />}
                /> */}
                <Route // TODO permission 'customers' canView
                  path="/customers/*"
                  element={<Customers />}
                />
                {/* <Redirect from="/customer" to="/customers" exact={true} /> */}

                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('customers')} />
                  }
                >
                  <Route // TODO permission 'customers' canView
                    path="/customer/:customer/*"
                    element={<Customer />}
                  />
                </Route>

                {!restrictReports && (
                  <Route
                    element={
                      <RoleProtectedRoute
                        hasPermission={canView('reports') || canView('sales')}
                      />
                    }
                  >
                    <Route path="/reports/*" element={<Reports />} />
                  </Route>
                )}

                <Route path="/outlets" element={<Outlets />} />
                <Route path="/terminals" element={<Terminals />} />
                <Route path="/onboardingList" element={<OnboardingList />} />
                <Route path="/business" element={<Restaurants />} />
                <Route
                  path="/business/:restaurants/outlets/:outlet"
                  element={<Outlet />}
                />
                <Route
                  path="/business/:restaurants/*"
                  element={<Restaurant />}
                />
                <Route
                  path="/"
                  element={<Navigate to={getHomeRouteByRole()} />}
                />

                <Route
                  element={
                    <RoleProtectedRoute hasPermission={canView('marketing')} />
                  }
                >
                  <Route path="/marketing/*" element={<Marketing />} />
                </Route>
                <Route element={<BlankPage />} />
              </Route>
            </Routes>
          </ThemeProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

// export default injectSheet(global)(App)
export default App
