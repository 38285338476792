import gql from 'graphql-tag'

const GET_MENU_FOR_DOWNLOAD = gql`
  query getMenuForDownload($id: String!) {
    getOptions(restaurantId: $id) {
      options {
        id
        name
      }
    }
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        image
        outlets {
          id
          marketplace {
            id
            name
          }
        }
        menuItemsGroups {
          id
          name
          description
          fulfillmentMethods
          addOnItemsMenu
          menuItems {
            id
            SKU
            UPC
            name
            isVegan
            isVegetarian
            isDairyFree
            isGlutenFree
            spiceLevel
            isKeto
            ageRestricted
            price
            costPrice
            minimumPurchasePrice
            options {
              id
            }
            archived
            description
          }
        }
      }
    }
  }
`

export default GET_MENU_FOR_DOWNLOAD
