import gql from 'graphql-tag'

const DELETE_DELIVERY_ZONE = gql`
  mutation deleteDeliveryZone($id: String!) {
    deleteDeliveryZone(id: $id) {
      message
    }
  }
`

export default DELETE_DELIVERY_ZONE
