import { Fragment } from 'react'
import { Formik } from 'formik'
import { SEND_TEST_NOTIFICATION } from '@components/Platform/TestNotification/mutations/sendTestNotification.mutation'
import MarketplaceSelect from '@components/MarketplaceSelect/MarketplaceSelect'
import {
  FormGroup,
  Button,
  HTMLSelect,
  TagInput,
  RadioGroup,
  Radio,
} from '@blueprintjs/core'
import EmailEditor from '@components/EmailEditor/EmailEditor'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import startCase from 'lodash/startCase'
import { groupBy } from 'lodash'
import { successToast, errorToast } from '@utils/toast'
import { TemplateTypesEnum } from '../../../types.generated'
import { useMutation } from '@apollo/client'

const TestNotification = () => {
  const [sendTestNotification] = useMutation(SEND_TEST_NOTIFICATION, {
    onCompleted: ({ sendTestNotification: { messageDeliveryStatuses } }) => {
      Object.entries(groupBy(messageDeliveryStatuses, 'status')).map(
        ([status, groupedStatuses]) => {
          const success = status === 'SUCCESSFUL'
          const customerIds = groupedStatuses
            .map(({ customerId }) => customerId)
            .join()

          if (!customerIds.length) {
            return errorToast(`Failed to send email`)
          }

          success
            ? successToast(`Email for ${customerIds} sent`)
            : errorToast(`Email for ${customerIds} failed to send`)
        }
      )
    },
    onError: error => {
      defaultErrorHandler(error)
    },
  })

  return (
    <Formik
      initialValues={{
        marketplaceId: '',
        emailAddresses: [],
        email: {
          type: 'TEMPLATE',
          templateType: 'REGISTRATION',
        },
        emailSubject: '',
        emailContent: [],
        emailPreviewText: '',
        marketplaceEmail: '',
      }}
      onSubmit={variables =>
        sendTestNotification({
          variables: {
            ...variables,
            email: {
              ...variables.email,
              subject: variables.emailSubject,
              emailContent: variables.emailContent,
              previewText: variables.emailPreviewText,
            },
            emailAddresses: variables.emailAddresses,
          },
        })
      }
    >
      {({ values, handleChange, handleSubmit, setFieldValue, ...rest }) => (
        <form onSubmit={e => e.preventDefault()}>
          <FormGroup label="Marketplace">
            <MarketplaceSelect
              marketplaceId={values.marketplaceId}
              onChange={value => setFieldValue('marketplaceId', value)}
            />
          </FormGroup>
          {!values.marketplaceId ? null : (
            <Fragment>
              <FormGroup label="Email Addresses">
                <TagInput
                  values={values.emailAddresses}
                  separator=" "
                  onChange={emailAddresses =>
                    void setFieldValue('emailAddresses', emailAddresses)
                  }
                  addOnBlur
                  leftIcon="envelope"
                />
              </FormGroup>

              <FormGroup>
                <RadioGroup
                  onChange={handleChange}
                  selectedValue={values.email.type}
                  name="email.type"
                >
                  <Radio label="Use existing template" value="TEMPLATE" />
                  <Radio label="Create a new Email" value="RAW" />
                </RadioGroup>
              </FormGroup>
              {values.email.type === 'RAW' ? (
                <EmailEditor
                  {...rest}
                  marketplaceId={values.marketplaceId}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <Fragment>
                  <FormGroup
                    label="Template type"
                    helperText="Template will be populated with dummy data"
                  >
                    <HTMLSelect
                      options={Object.values(TemplateTypesEnum)
                        .filter(key => isNaN(Number(key)))
                        .map(value => ({
                          value,
                          label: startCase(value.toLowerCase()),
                        }))}
                      value={values.email.templateType}
                      name={'email.templateType'}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Fragment>
              )}
            </Fragment>
          )}
          <Button
            onClick={handleSubmit as () => void}
            disabled={!values.marketplaceId || !values.emailAddresses.length}
          >
            Send
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default TestNotification
