import { Button, Classes } from '@blueprintjs/core'
import React from 'react'
import { OtterFetch } from './OtterFetch'
import { OtterStore } from './OtterStore'

export const OtterOrganisations = ({ openPanel, restaurantId }) => {
  return (
    <div className={Classes.DIALOG_BODY}>
      <OtterFetch path="/organization/brands?limit=10">
        {brands =>
          brands &&
          brands.items.map(brand => (
            <div key={brand.id}>
              <h3>{brand.name}</h3>
              <p>{brand.id}</p>

              <Button
                onClick={() =>
                  openPanel({
                    renderPanel: OtterStores,
                    props: { brandId: brand.id, restaurantId },
                    title: 'Otter Stores',
                  })
                }
                text="View Stores"
              />
            </div>
          ))
        }
      </OtterFetch>
    </div>
  )
}

const OtterStores = ({ brandId, openPanel, restaurantId }) => {
  return (
    <div className={Classes.DIALOG_BODY}>
      <OtterFetch path={`/organization/brands/${brandId}/stores?limit=10`}>
        {stores =>
          stores.items.map(store => (
            <div key={store.id}>
              <h3>{store.name}</h3>
              <p>{store.id}</p>

              <Button
                onClick={() =>
                  openPanel({
                    renderPanel: OtterStore,
                    props: { store, brandId, restaurantId },
                    title: store.name,
                  })
                }
                text="View"
              />
            </div>
          ))
        }
      </OtterFetch>
    </div>
  )
}
