import { useState } from 'react'
import { Classes, Spinner } from '@blueprintjs/core'
import { Formik } from 'formik'
import { successToast } from '@utils/toast'

import { string, func } from 'prop-types'
import InnerForm from './InnerForm'
import CREATE_CAMPAIGN from '@src/pages/Marketing/mutations/createCampaign.mutation'
import GET_CAMPAIGNS from '@src/pages/Marketing/queries/getCampaigns.query'
import GET_MARKETPLACE_BY_ID from '@src/pages/Marketing/queries/getMarketplaceById.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import moment from 'moment'
import * as yup from 'yup'
import { addCampaignStructure } from '@validation/campaign'
import { useMutation } from '@apollo/client'
import Query from '@src/components/Query/Query'

const CreateCampaign = ({ marketplaceId, closeDraw }) => {
  const [loading, setLoading] = useState(false)

  if (loading) return <Spinner />

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: ({ addCampaigns: { campaign } }) => {
      setLoading(false)
      if (campaign.id)
        successToast(
          campaign.timeConfig === 'DATETIME'
            ? `Saved ${campaign.name}`
            : `Sent notifications`
        )
      closeDraw()
    },
    refetchQueries: [
      {
        query: GET_CAMPAIGNS,
        variables: {
          marketplaceId,
          first: 20,
          skip: 0,
        },
      },
    ],
  })

  return (
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>
        <Query query={GET_MARKETPLACE_BY_ID} variables={{ marketplaceId }}>
          {({ data, loading }) => {
            if (loading || !data || !data.getMarketplaces) {
              return <Spinner />
            }
            const marketplace = data.getMarketplaces.regions[0]
            return (
              <Formik
                initialValues={{
                  name: '',
                  pushChannel: false,
                  emailChannel: false,
                  smsChannel: false,
                  segment: '',
                  push: {
                    action: '',
                    outletId: '',
                    menuItemId: '',
                    title: '',
                    body: '',
                  },
                  sms: {
                    body: '',
                  },
                  marketplaceName: marketplace.name,
                  marketplaceIcon: marketplace.faviconImage,
                  smsSenderId: marketplace.smsSenderId,
                  marketplaceEmail: `${
                    marketplace.emailSenderUsername
                  }@${marketplace.cname.trim().replace(/^www\./, '')}}`,
                  emailSubject: '',
                  emailPreviewText: '',
                  emailContent: [],
                  timeConfig: 'IMMEDIATELY',
                  startDateTime: moment().add(1, 'hour').toDate(),
                  marketplaceId,
                }}
                onSubmit={({
                  emailSubject,
                  emailPreviewText,
                  emailContent,
                  ...values
                }) => {
                  setLoading(true)
                  createCampaign({
                    variables: {
                      ...values,
                      emailSubject,
                      emailPreviewText,
                      emailContent,
                      marketplaceId,
                    },
                  })
                }}
                validationSchema={yup.object().shape(addCampaignStructure)}
                validateOnChange={true}
              >
                {props => (
                  <InnerForm
                    {...props}
                    disabled={false}
                    marketplaceId={marketplaceId}
                    marketplaceCNAME={marketplace.cname}
                    allowSMSMarketing={marketplace.allowSMSMarketing}
                  />
                )}
              </Formik>
            )
          }}
        </Query>
      </div>
    </div>
  )
}

CreateCampaign.propTypes = {
  marketplaceId: string.isRequired,
  closeDraw: func.isRequired,
}

export default CreateCampaign
