import { createContext } from 'react'

const appPageContext = createContext({
  modalOpen: false,
  setAppModalOpen: _isOpen => {},
  modalAppImportOpen: false,
  setAppImportModalOpen: _isOpen => {},
  entityName: '',
  appId: null,
  setAppId: _appId => {},
  marketplaceId: null,
  setMarketplaceId: _ => {},
})

export default appPageContext
