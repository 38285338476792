import { Button, Intent } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { useState } from 'react'
import ADD_BILLING_SUBSCRIPTION from './mutations/addBillingSubscription.mutation'
import GET_MARKETPLACE_BILLING from '../Marketplace/Billing/queries/getMarketplaceBilling.query'
import { bool, func, object, string } from 'prop-types'
import { useMutation } from '@apollo/client'

const AddBillingSubscriptionButton = ({
  marketplaceId,
  minimal = false,
  fill = false,
  style = {},
  intent = Intent.NONE,
  icon = 'cube-add',
  disabled = false,
  text = 'Add',
  product,
  onCompleted = () => {},
}) => {
  const [loading, setLoading] = useState(false)

  const [addBillingPaymentMethod] = useMutation(ADD_BILLING_SUBSCRIPTION, {
    onCompleted: () => {
      setLoading(false)
      onCompleted()
    },
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    refetchQueries: [
      { query: GET_MARKETPLACE_BILLING, variables: { id: marketplaceId } },
    ],
  })

  return (
    <Button
      text={text}
      loading={loading}
      // @ts-ignore
      rightIcon={icon}
      minimal={minimal}
      fill={fill}
      style={style}
      intent={intent}
      disabled={disabled}
      onClick={() => {
        setLoading(true)
        addBillingPaymentMethod({
          variables: {
            product,
            marketplaceId,
          },
        })
      }}
    />
  )
}

AddBillingSubscriptionButton.propTypes = {
  marketplaceId: string,
  minimal: bool,
  fill: bool,
  style: object,
  intent: Intent,
  icon: string,
  disabled: bool,
  text: string,
  onCompleted: func,
  product: string,
}

export default AddBillingSubscriptionButton
