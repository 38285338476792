import React from 'react'
import { Tag, Icon } from '@blueprintjs/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 30px;
  align-items: center;
  padding: 0 8px;
  position: relative;
  cursor: ${props => props.cursor || 'default'};

  &:hover {
    background: rgba(191, 204, 214, 0.4);

    ${props =>
      props.dragHandle &&
      css`
        opacity: 1;
      `}
  }

  ${props =>
    props.active &&
    css`
      background: #137cbd;
      color: #fff;

      svg {
        fill: #fff;
      }
    `}

  ${props =>
    props.hidden &&
    css`
      opacity: 0.4;
    `}
`

export const LeftIcon = styled(Icon)`
  margin-right: 7px;
`

export const Label = styled.div`
  margin-right: 7px;
`

export const SecondaryLabel = styled.div`
  margin-left: auto;
`

export const DragHandle = styled(Icon)`
  opacity: 0;
  cursor: move;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
`

const Row = ({
  onClick,
  icon,
  iconColor = '#5c7080',
  label,
  soldCount,
  secondaryLabel,
  cursor = 'pointer',
  isOpen = null,
  canDrag,
  active,
  hidden,
}) => (
  <Container
    cursor={cursor}
    active={active}
    hidden={hidden}
    dragHandle={canDrag}
    onClick={onClick}
  >
    {isOpen !== null && (
      <LeftIcon
        icon={isOpen ? 'chevron-down' : 'chevron-right'}
        color={iconColor}
      />
    )}

    {icon && <LeftIcon icon={icon} color={iconColor} />}

    {label && <Label>{label}</Label>}

    {!!soldCount && <Tag intent={'danger'}>{soldCount}</Tag>}

    {canDrag && <DragHandle icon="drag-handle-vertical" color="#5c7080" />}

    {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
  </Container>
)

export default Row
