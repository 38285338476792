import React, { Fragment, useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import {
  Card,
  Elevation,
  NonIdealState,
  Spinner,
  Icon,
  Callout,
  Button,
} from '@blueprintjs/core'
import GET_STATS from './queries/getDashboardStats.query'
import Currency from '@components/Currency/Currency'
import { Row, Col } from '@components/_FlexGrid'
import moment from 'moment'
import LineGraph from '@components/Analytics/Graphs/LineGraph'
import BarGraph from '@components/Analytics/Graphs/BarGraph'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import productDictionary from '@utils/productDictionary'

const Dashboard: React.FC = () => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.DASHBOARD,
      tabs: [
        { to: '/dashboard/day', name: 'Day' },
        { to: '/dashboard/week', name: 'Week' },
      ],
    })
  }, [configurePageLayout])

  const { period } = useParams<{ period }>()

  let filterDays = 1
  let filterPeriod = 'DAY'
  let tooltipDateFormat = 'HH:00 ddd Do'

  switch (period) {
    case 'day':
      filterDays = 1
      filterPeriod = 'HOUR'
      tooltipDateFormat = 'ddd HH:mm'
      break
    case 'week':
      filterDays = 7
      filterPeriod = 'DAY'
      tooltipDateFormat = 'ddd Do - MMM'
      break
    case 'month':
      filterDays = 35
      filterPeriod = 'DAY'
      tooltipDateFormat = 'ddd Do - MMM'
      break
  }

  const nextDay = dayNumber => {
    // 1 for Monday
    if (moment().isoWeekday() <= dayNumber) {
      // next instance of that day this week
      return moment().isoWeekday(dayNumber)
    } else {
      // if past next weeks instance
      return moment().add(1, 'weeks').isoWeekday(dayNumber)
    }
  }

  const endDate =
    filterDays > 1
      ? nextDay(0).endOf('day').subtract(1, 'second')
      : moment().endOf('day').subtract(1, 'second')
  const startDate = moment(endDate).subtract(filterDays, 'days')

  let totalRefundsValue = 0
  let totalRefunds = 0

  const defaultData = {
    refundData: [],
    salesData: [],
  }

  const { loading, error, data, networkStatus } = useQuery(GET_STATS, {
    variables: {
      startDate: startDate.utc().toISOString(),
      endDate: endDate.utc().toISOString(),
      period: filterPeriod,
    },
  })

  if (loading) {
    return (
      <NonIdealState
        icon={<Spinner size={60} />}
        title={'Loading Dashboard'}
        description={'Please wait...'}
      />
    )
  }

  if (error) {
    return (
      <Callout
        icon="error"
        intent="danger"
        title="Unable to load dashboard, please try again."
      >
        <p>Please verify your internet connection and try again.</p>
        <Button icon="refresh" onClick={() => location.reload()}>
          Reload Application
        </Button>
      </Callout>
    )
  }

  const { totalOrders, averageOrdersValue, totalOrdersValue, sales } =
    data.salesBy

  const { refundData, salesData } =
    sales && sales.length > 0
      ? sales.reduce((acc, sale) => {
          totalRefundsValue += sale.totalRefundsValue
          totalRefunds += sale.totalRefunds

          acc.refundData.push({
            x: sale.date,
            y: sale.totalRefundsValue || 0,
          })
          acc.salesData.push({
            x: sale.date,
            y: sale.totalOrdersValue || 0,
          })

          return acc
        }, defaultData)
      : defaultData

  return (
    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <Card
          elevation={Elevation.ZERO}
          style={{
            height: 308,
            width: 'max-content',
            minWidth: '100%',
          }}
        >
          {loading || error || networkStatus === 4 || !data ? (
            <NonIdealState
              icon={
                loading || networkStatus === 4 ? (
                  <Spinner size={40} />
                ) : (
                  <Icon icon="timeline-line-chart" />
                )
              }
              title={'Sales Overview'}
              description={
                loading
                  ? 'Please wait...'
                  : `No data between ${startDate.format(
                      'Do MMM'
                    )} - ${endDate.format('Do MMM')}`
              }
            />
          ) : (
            <Fragment data-test-id="sales-overview-fragment">
              <Row>
                <Col span={6}>
                  <h5 className={'bp5-heading'}>Sales Overview</h5>
                </Col>
                <Col span={6}>
                  <p
                    className={'bp5-text-muted'}
                    style={{ textAlign: 'right' }}
                  >
                    {startDate.format('Do MMM')} - {endDate.format('Do MMM')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <h1 style={{ color: '#1968F6' }} className="bp5-heading">
                    <Currency amount={totalOrdersValue} />
                  </h1>
                  <p>Gross Total</p>
                </Col>
                <Col span={3}>
                  <h1 style={{ color: '#1968F6' }} className="bp5-heading">
                    {totalOrders}
                  </h1>
                  Total Orders
                </Col>
                <Col span={3}>
                  <h1 style={{ color: '#1968F6' }} className="bp5-heading">
                    <Currency amount={averageOrdersValue} />
                  </h1>
                  Average Order
                </Col>
              </Row>

              {totalOrders > 0 && (
                <LineGraph
                  tooltipDateFormat={tooltipDateFormat}
                  data={salesData}
                  period={period}
                  displayBands
                  currency
                  responsive
                />
              )}
            </Fragment>
          )}
        </Card>
      </Col>

      <Col xs={12} lg={6}>
        <Card
          elevation={Elevation.ZERO}
          style={{
            height: 308,
            width: 'max-content',
            minWidth: '100%',
          }}
        >
          {loading || error || networkStatus === 4 || !data ? (
            <NonIdealState
              icon={
                loading || networkStatus === 4 ? (
                  <Spinner size={40} />
                ) : (
                  <Icon icon="grouped-bar-chart" />
                )
              }
              title={'Refunds Overview'}
              description={
                loading
                  ? 'Please wait...'
                  : `No data between ${startDate.format(
                      'Do MMM'
                    )} - ${endDate.format('Do MMM')}`
              }
            />
          ) : (
            <Fragment>
              <Row>
                <Col span={6}>
                  <h5 className={'bp5-heading'}>Refunds Overview</h5>
                </Col>
                <Col span={6}>
                  <p
                    className={'bp5-text-muted'}
                    style={{ textAlign: 'right' }}
                  >
                    {startDate.format('Do MMM')} - {endDate.format('Do MMM')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <h1 style={{ color: '#1968F6' }} className="bp5-heading">
                    <Currency amount={totalRefundsValue} />
                  </h1>
                  <p>Total Refund Value</p>
                </Col>
                <Col span={6}>
                  <h1 style={{ color: '#1968F6' }} className="bp5-heading">
                    {totalRefunds}
                  </h1>
                  Total Refunds
                </Col>
              </Row>

              {totalRefunds > 0 && (
                <BarGraph
                  data={refundData}
                  tooltipDateFormat={tooltipDateFormat}
                />
              )}
            </Fragment>
          )}
        </Card>
      </Col>
    </Row>
  )
}

export default Dashboard
