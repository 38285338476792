import gql from 'graphql-tag'

const DELETE_RESTAURANT = gql`
  mutation archiveRestaurant($id: String!) {
    archiveRestaurant(id: $id) {
      message
      restaurant {
        id
        name
      }
    }
  }
`

export default DELETE_RESTAURANT
