import { debounce, DebouncedFunc, isEqual, omit } from 'lodash'
import { Component, ComponentType } from 'react'

interface Props {
  wait?: number
  children?: unknown
}

const debouncedPropsHoc = <P extends object>(
  ChildComponent: ComponentType<P>
) =>
  class extends Component<Props & P, Omit<P, 'children' | 'wait'>> {
    private debounceState: DebouncedFunc<
      (newState: Omit<P, 'children' | 'wait'>) => void
    >

    public static displayName = `DebouncedPropsHoc(${
      ChildComponent.displayName || ChildComponent.name
    })`

    public constructor(props: Props & P) {
      super(props)

      this.debounceState = debounce(newState => {
        this.setState(() => newState)
      }, props.wait)

      this.state = omit(props, ['children', 'wait']) as Omit<
        P,
        'children' | 'wait'
      >
    }

    public componentDidUpdate() {
      const propsToDebounce = omit(this.props, ['children', 'wait']) as Omit<
        P,
        'children' | 'wait'
      >

      if (!isEqual(propsToDebounce, this.state)) {
        this.debounceState(propsToDebounce)
      }
    }

    public componentWillUnmount() {
      this.debounceState.cancel()
    }

    public render() {
      return (
        <ChildComponent {...(this.state as P)}>
          {this.props.children}
        </ChildComponent>
      )
    }
  }

export default debouncedPropsHoc
