import first from 'lodash/first'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { penceToPounds, numberToPence } from '@utils/helpers'

import { successToast } from '@utils/toast'
import GET_PARTNER from './queries/getPartner.query'
import EDIT_PARTNER from './mutations/editPartner.mutation'
import PartnerForm from './PartnerForm/PartnerForm'

import { omit } from 'lodash'
import { isPlatformLevelUser } from '@stores/userStore'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Partner } from '@src/types.generated'

const Details = () => {
  const { partner } = useParams()

  const [editPartner] = useMutation(EDIT_PARTNER, {
    onError: defaultErrorHandler,
    onCompleted: () => successToast('Partner successfully updated.'),
  })

  return (
    <Query
      query={GET_PARTNER}
      variables={{ id: partner }}
      loaderTitle={'Loading Partner'}
    >
      {({ getPartners: { partners } }) => {
        const partner: Partner | undefined = first(partners)
        if (!partner) {
          return 'Unable to find partner.'
        }

        const initialValues = {
          ...omit(partner, ['contactAddress.id']),
          platformCharge: penceToPounds(partner.platformCharge),
          partnerCommissionCharge: numberToPence(
            partner.partnerCommissionCharge || 0
          ),
          partnerVat: partner.partnerVat || 0,
          partnerCommissionFee: partner.partnerCommissionFee || 0,
        }

        return (
          <PartnerForm
            partner={partner}
            editable={isPlatformLevelUser()}
            onSubmit={values =>
              editPartner({
                variables: {
                  ...values,
                  platformCharge: numberToPence(values.platformCharge),
                  partnerCommissionCharge: numberToPence(
                    values.partnerCommissionCharge
                  ),
                },
              })
            }
            initialValues={initialValues}
          />
        )
      }}
    </Query>
  )
}

export default Details
