import { Fragment } from 'react'
import {
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
// import { togglePane, setValue } from '@utils/cacheHelpers'
// TODO: fix pane
import SubMenu from './SubMenu'
import { get, countBy, sumBy } from 'lodash'
import { string, bool } from 'prop-types'
import cx from 'classnames'

import Query from '@components/Query/Query'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import GET_OUTLET_SUB_MENUS from '@components/Restaurant/Menu/queries/getOutletSubMenus.query'
import MenusList from './MenusList'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'
import OutletSubMenu from '@components/Restaurant/Menu/MenuTree/OutletSubMenu'
import { useMenuState } from './MenuStateProvider'

const formatMenus = ({
  outlet,
  menus,
  queryId,
  subMenuId,
  menuId,
  outletSoldOut,
  hiddenMenuItemGroupIds,
  outletId,
  setValue,
}) =>
  menus.map((menu, index, menus) => {
    const outletsSoldOutArray = removeExpiredSoldOut(outletSoldOut)
    const childrenMenus = menu.childrenMenus || []
    const soldCount = sumBy([menu, ...childrenMenus], menu => {
      return (
        countBy(menu.menuItems, item => {
          return outlet
            ? item.outletSoldOut
            : outletsSoldOutArray.includes(item.id)
        }).true || 0
      )
    })

    return {
      id: menu.id,
      key: menu.id,
      icon: 'clipboard',
      label: (
        <Fragment>
          {menu.name}{' '}
          {soldCount > 0 ? <Tag intent={'danger'}>{soldCount}</Tag> : null}
        </Fragment>
      ),
      className: cx({
        selected: subMenuId === menu.id,
      }),
      onClickItem: () => {
        setValue({
          // itemMenuOpen: false, // dont opne menu items possibly more sub menus
          subMenuId: menu.id,
          // itemQueryId: menu.id,
          // itemDetailsOpen: false,
        })
      },
      secondaryLabel: outlet ? (
        <div
          onClick={e => {
            e.stopPropagation()
            // setValue({
            //   subMenuId: menu.id,
            //   paneNumber: 2,
            // })
          }}
        >
          <Popover
            content={
              <OutletSubMenu
                id={menu.id}
                outletId={outletId}
                hidden={hiddenMenuItemGroupIds.includes(menu.id)}
              ></OutletSubMenu>
            }
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.LEFT}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ) : (
        <div
          onClick={e => {
            e.stopPropagation()
            // setValue({
            //   subMenuId: menu.id,
            // })
          }}
        >
          <Popover
            content={
              <SubMenu
                id={menu.id}
                menuId={menuId}
                queryId={queryId}
                menus={menus}
                index={index}
                outlet={outlet}
              />
            }
            interactionKind={PopoverInteractionKind.CLICK}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ),
    }
  })

const SubMenuPane = ({ queryId, menuId, subMenuId, outlet }) => {
  const { data: menuData, setValue } = useMenuState()

  return (
    <Query
      query={outlet ? GET_OUTLET_SUB_MENUS : GET_SUB_MENUS}
      variables={{ id: queryId, menuId }}
      fetchPolicy="network-only"
      showLoader={false}
    >
      {data => {
        const menus = get(
          data,
          outlet
            ? 'getOutlets.outlets[0].menuItemsGroups[0].childrenMenus'
            : 'getRestaurants.restaurants[0].menuItemsGroups[0].childrenMenus',
          []
        )

        const outletSoldOut = get(
          data,
          outlet ? [] : 'getRestaurants.restaurants[0].outlets',
          []
        )
        const hiddenMenuItemGroupIds = get(
          data,
          outlet ? 'getOutlets.outlets[0].hiddenMenuItemGroupIds' : [],
          []
        )

        const outletId = get(
          data,
          outlet
            ? 'getOutlets.outlets[0].id'
            : 'getRestaurants.restaurants[0].outlets[0].id',
          []
        )

        const menusFormatted = formatMenus({
          outlet,
          menus,
          queryId,
          subMenuId,
          menuId,
          outletSoldOut,
          hiddenMenuItemGroupIds,
          outletId,
          setValue,
        })

        if (menus.length > 0) {
          return <MenusList menus={menusFormatted} />
        } else if (!menuData.itemMenuOpen) {
          // ew: when the sub menu is empty, we set the state to open the item menu
          setValue({
            itemMenuOpen: true,
          })
          return null
        }
      }}
    </Query>
  )
}

SubMenuPane.propTypes = {
  queryId: string.isRequired,
  menuId: string.isRequired,
  subMenuId: string,
  firstSub: bool,
  outlet: bool,
}
export default SubMenuPane
