import gql from 'graphql-tag'

const EDIT_SIGNIFICANT_DATES = gql`
  mutation editBusinessSignificantDates(
    $businessId: String!
    $significantDates: Json!
  ) {
    editBusinessSignificantDates(
      businessId: $businessId
      significantDates: $significantDates
    ) {
      significantDates
    }
  }
`

export default EDIT_SIGNIFICANT_DATES
