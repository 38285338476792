import { useReducer, useEffect } from 'react'
import config from '@src/config'
import { generateNewTheme } from '../utils/generateNewTheme'

const actions = {
  SET_THEME: 'set_theme',
  LOAD_THEME: 'load_theme',
  ERROR_LOADING_THEME: 'error_loading_theme',
  NEW_THEME: 'new_theme',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_THEME: {
      return {
        theme: action.nextTheme,
        loading: false,
        error: null,
      }
    }
    case actions.LOAD_THEME: {
      return {
        theme: null,
        loading: true,
        error: null,
      }
    }
    case actions.ERROR_LOADING_THEME: {
      return {
        theme: null,
        loading: false,
        error: action.nextError,
      }
    }
    case actions.NEW_THEME: {
      return {
        theme: generateNewTheme(action.brandColor, action.brandSecondaryColor),
        loading: false,
        error: null,
        newTheme: true,
      }
    }
  }
  throw Error('Unknown action: ' + action.type)
}

const fetchTheme = async ({ marketplaceId }) => {
  const response = await fetch(
    `${config.apiUrl}/api/marketplace/${marketplaceId}/theme`
  )
  if (response.status === 200) {
    return response.json()
  }
  if (response.status === 404) {
    return null
  }
  throw Error('Theme failed to load')
}

export const useTheme = ({ marketplaceId }) => {
  const [state, dispatch] = useReducer(reducer, {
    theme: null,
    loading: true,
    error: null,
  })

  useEffect(() => {
    let isSubscribed = true
    fetchTheme({ marketplaceId })
      .then(theme => {
        if (isSubscribed) {
          dispatch({
            type: 'set_theme',
            nextTheme: theme,
          })
        }
      })
      .catch(error => {
        if (isSubscribed) {
          dispatch({
            type: 'error_loading_theme',
            nextError: error,
          })
        }
      })

    return () => {
      isSubscribed = false
      dispatch({ type: 'load_theme' })
    }
  }, [marketplaceId])

  return [
    state,
    {
      createNewTheme: (brandColor, brandSecondaryColor) =>
        dispatch({ type: actions.NEW_THEME, brandColor, brandSecondaryColor }),
    },
  ]
}
