import React from 'react'
import { func, bool } from 'prop-types'

import Query from '@components/Query/Query'
import GET_PARTNER_IDS from './queries/getPartnerIds.query'
import { array } from 'yup'
import TypeaheadMulti from '../Typeahead/TypeaheadMulti'

const PartnerMultipleSelect = ({ selected, setSelected, disabled }) => (
  <Query query={GET_PARTNER_IDS} showLoader={false}>
    {({ getPartners: { partners } }) => (
      <TypeaheadMulti
        items={partners.map(({ id, name }) => ({
          id,
          name,
        }))}
        placeholder="Select Partners..."
        selected={selected}
        setSelected={setSelected}
        disabled={disabled}
        selectedIcon="unlock"
        unSelectedIcon="lock"
      />
    )}
  </Query>
)

PartnerMultipleSelect.propTypes = {
  selected: array,
  setSelected: func,
  disabled: bool,
}

export default PartnerMultipleSelect
