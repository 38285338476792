import * as yup from 'yup'
import { currencySchema } from './currency'
import { phoneRegExp } from './phone-regex'
import { IIterableInterface } from './generic-validator'

export const addPlatformStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Platform name is required.')
    .min(3, 'Platform name too short.'),
  contactName: yup
    .string()
    .required('Contact Name is required.')
    .min(3, 'Contact name too short.'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required.')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid phone number'),
  contactEmail: yup
    .string()
    .required('Contact Email is required.')
    .email('Email is not valid.'),
  orderHoldIntervalOverride: yup.number().integer(),
  platformVat: yup.number(),
  platformCurrency: currencySchema,
}

export const generalPlatformEditStructure: IIterableInterface = {
  name: yup.string().min(3, 'Platform Name too short.'),
  contactName: yup.string().min(3, 'Contact Name too short.'),
  contactPhone: yup
    .string()
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Invalid Phone Number.'),
  contactEmail: yup.string().email('This is not a valid email.'),
  orderHoldIntervalOverride: yup.number(),
  partnerIds: yup.array().of(yup.string()),
  stripeAccessId: yup
    .string()
    .trim()
    .required('Stripe Private Key is required.'),
  stripePublicId: yup.string().trim().required('Stripe Public ID is required'),
  stripeClientId: yup.string().trim().required('A stripe client is required'),
  payboxAccessId: yup
    .string()
    .trim()
    .required('PayboxStripe Private Key is required.'),
  payboxPublicId: yup
    .string()
    .trim()
    .required('Paybox Stripe Public ID is required.'),
  payboxClientId: yup
    .string()
    .trim()
    .required('Paybox Stripe Client ID is required.'),
  vatNumber: yup
    .string()
    .matches(
      /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i,
      'Not a valid VAT Number.'
    )
    .nullable(),
}

export const financialPlatformEditStructure: IIterableInterface = {
  stripeId: yup.string().min(5, 'Stripe ID too short.'),
  stripePublicId: yup.string().min(5, 'Stripe Public ID too short.'),
  stripeClientId: yup.string().min(5, 'Stripe Client ID too short.'),
  platformVat: yup.number(),
  vatNumber: yup
    .string()
    .matches(
      /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i,
      'Not a valid VAT Number.'
    )
    .nullable(),
}
