const getDragIcon = ({ isDragging, isSelf, isAbove, canDrop, defaultIcon }) => {
  if (isDragging || isSelf) {
    return 'move'
  }
  if (canDrop) {
    return isAbove ? 'arrow-up' : 'arrow-down'
  }
  return defaultIcon
}

export default getDragIcon
