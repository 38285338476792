import gql from 'graphql-tag'

const DELETE_DISCOUNT_WITH_VOUCHERS = gql`
  mutation deleteDiscountForVoucher($discountId: String!) {
    deleteDiscount(id: $discountId) {
      message
    }
  }
`

export default DELETE_DISCOUNT_WITH_VOUCHERS
