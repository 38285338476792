import React, { Fragment } from 'react'
import { array, string, func, object, bool } from 'prop-types'
import NewOptionRow from './NewOptionRow'

const AddOptionItems = ({
  items,
  setFieldValue,
  handleChange,
  name,
  errors,
  remove,
  isVATregistered,
}) => (
  <Fragment>
    {items.map((item, index) => (
      <NewOptionRow
        name={name}
        item={item}
        index={index}
        key={item.id}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        errors={errors}
        remove={remove}
        isVATregistered={isVATregistered}
      />
    ))}
  </Fragment>
)

AddOptionItems.propTypes = {
  items: array.isRequired,
  setFieldValue: func,
  handleChange: func,
  setFieldTouched: func,
  name: string,
  errors: object,
  remove: func,
  isVATregistered: bool,
}

export default AddOptionItems
