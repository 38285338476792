import React from 'react'

const ReviewStatus = ({ approved }) => (
  <p
    style={{
      marginBottom: 'auto',
      marginTop: 'auto',
    }}
    className={'bp5-tag bp5-minimal '.concat(
      approved ? 'bp5-intent-success' : 'bp5-intent-danger'
    )}
  >
    {approved ? 'Approved' : 'Awaiting approval'}
  </p>
)

export default ReviewStatus
