import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { get } from 'lodash'

import config from '@src/config'

mapboxgl.accessToken = config.mapboxKey

const GeoFence: React.FC<{
  geoFence: any
  height?: string
  width?: string
  disableEdit?: boolean
  features?: any[]
  setFieldValue: (field: string, value: any) => void
  handleUpdate: () => void
  showDelete?: boolean
  popoverInfo?: any
  PopoverContent?: any
  setPopoverInfo?: (info: any) => void
}> = ({
  geoFence: initialGeoFence,
  height = '100vh',
  width = '100%',
  disableEdit = false,
  features = [],
  setFieldValue,
  handleUpdate,
  showDelete = true,
  popoverInfo,
  PopoverContent,
  setPopoverInfo,
}) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const draw = useRef(null)
  const [geoFence, setGeoFence] = useState(initialGeoFence)
  const [mapLoaded, setMapLoaded] = useState(false)

  const center = geoFence?.center || { lng: -1.146159, lat: 52.809403 }
  const zoom = geoFence?.zoom || 7

  const updateArea = drawing => {
    if (!map.current || !mapLoaded) return

    const newGeoFence = {
      coordinates: get(drawing, 'features[0].geometry.coordinates', null),
      type: get(drawing, 'features[0].geometry.type', null),
      center: map.current.getCenter(),
      zoom: map.current.getZoom(),
    }
    setGeoFence(newGeoFence)
    setFieldValue('geoFence', newGeoFence)
    handleUpdate()
  }

  const deleteArea = () => {
    setGeoFence(null)
    setFieldValue('geoFence', null)
    handleUpdate()
  }

  useEffect(() => {
    if (!mapContainer.current) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [center.lng, center.lat],
      zoom: zoom,
      maxZoom: 12,
      attributionControl: false,
    })

    map.current.on('load', () => {
      setMapLoaded(true)

      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: !disableEdit,
          trash: showDelete,
          save: !disableEdit,
        },
      })

      map.current?.addControl(draw.current, 'top-left')
      map.current?.addControl(new mapboxgl.NavigationControl())

      if (!disableEdit) {
        map.current?.on('draw.create', updateArea)
        map.current?.on('draw.update', updateArea)
        map.current?.on('draw.delete', deleteArea)
      }

      if (initialGeoFence?.type) {
        draw.current?.add({
          type: 'Feature',
          properties: {},
          geometry: {
            type: initialGeoFence.type,
            coordinates: initialGeoFence.coordinates,
          },
        })
      }

      if (features.length > 0) {
        const bounds = new mapboxgl.LngLatBounds()

        features.forEach(feature => {
          if (feature.geometry?.coordinates) {
            bounds.extend(feature.geometry.coordinates)
          }
        })

        map.current?.addSource('outletSource', {
          type: 'geojson',
          data: { type: 'FeatureCollection', features },
        })

        const circleLayerId = 'outlet-circles'

        map.current?.addLayer({
          id: circleLayerId,
          type: 'circle',
          source: 'outletSource',
          paint: {
            'circle-radius': 6,
            'circle-color': 'red',
            'circle-opacity': 1,
          },
        })

        map.current?.on('mouseenter', circleLayerId, e => {
          if (e.features && e.features.length > 0) {
            const feature = e.features[0]
            if (map.current) {
              map.current.getCanvas().style.cursor = 'pointer'
              const coordinates = feature.geometry.coordinates.slice()
              const properties = feature.properties
              const pixelCoordinates = map.current.project(coordinates)

              setPopoverInfo({
                pixelX: pixelCoordinates.x,
                pixelY: pixelCoordinates.y,
                outletName: properties.outletName || 'Unknown Outlet',
                businessName: properties.businessName || 'Unknown Business',
                address: [
                  properties.firstLine,
                  properties.secondLine,
                  properties.city,
                  properties.postcode,
                ]
                  .filter(Boolean)
                  .join(', '),
                outletId: properties.outletId,
                businessId: properties.businessId,
                contactName: properties.contactName,
                contactEmail: properties.contactEmail,
                contactPhone: properties.contactPhone,
              })
            }
          }
        })

        map.current?.on('mouseleave', circleLayerId, () => {
          if (map.current) {
            map.current.getCanvas().style.cursor = ''
          }
        })

        if (!bounds.isEmpty()) {
          map.current?.fitBounds(bounds, { padding: 50, maxZoom: 12 })
        }
      }
    })

    return () => map.current?.remove()
  }, [initialGeoFence, disableEdit, showDelete])

  return (
    <div style={{ position: 'relative', width, height }}>
      <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
      {popoverInfo && <PopoverContent info={popoverInfo} map={map} />}
    </div>
  )
}

export default GeoFence
