import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'

const AppVersionDropdown = ({
  value,
  onChange,
  name,
  disabled,
  appVersions,
  label = 'Select a version...',
}) => (
  <HTMLSelect
    name={name}
    options={[
      {
        label: label,
        value: '',
      },
      ...appVersions.map(appVersion => {
        const [version] = appVersion.split('-')

        return {
          label: version,
          value: version,
        }
      }),
    ]}
    value={value}
    onChange={onChange}
    disabled={disabled}
  />
)

export default AppVersionDropdown
