import { Button, Popover } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import { Picker } from 'emoji-mart'
import { get } from 'lodash'
import React from 'react'
// import 'emoji-mart/css/emoji-mart.css'

const EmojiPicker = ({ setFieldValue, values, name }) => {
  return (
    <Popover
      content={
        <Picker
          theme={ls.get('darkMode') ? 'dark' : 'light'}
          title="Pick an Emoji"
          onClick={emoji => {
            setFieldValue(name, get(values, name) + emoji.native + ' ')
          }}
        />
      }
    >
      <Button icon="emoji" />
    </Popover>
  )
}

export default EmojiPicker
