import gql from 'graphql-tag'

const EDIT_DISCOUNT_VOUCHERS = gql`
  mutation editDiscount(
    $id: String!
    $name: String
    $discountPercentage: Float
    $discountAmount: Int
    $allowedOutletIds: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $daysOfWeek: [Int!]
    $minimumSubtotalGross: Int
  ) {
    editDiscount(
      id: $id
      name: $name
      discountPercentage: $discountPercentage
      discountAmount: $discountAmount
      allowedOutletIds: $allowedOutletIds
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
      minimumSubtotalGross: $minimumSubtotalGross
    ) {
      message
    }
  }
`

export default EDIT_DISCOUNT_VOUCHERS
