import gql from 'graphql-tag'

const GET_ALLOWED_CATEGORIES_FOR_RESTAURANT = gql`
  query AllowedCategoriesForRestaurant($restaurantId: String!) {
    categoriesForRestaurant(restaurantId: $restaurantId) {
      optInAllowedCategories
      categoryEmojiOrImage
      cuisines {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`
export default GET_ALLOWED_CATEGORIES_FOR_RESTAURANT
