import SortBy from '@components/Toolbar/SortBy'
import React from 'react'

const cells = [
  {
    key: 'name',
    content: 'Name',
    sortable: true,
  },
  {
    key: 'contactName',
    content: 'Contact',
    sortable: true,
  },
  {
    key: 'partner',
    content: 'Partner',
  },
  {
    key: 'marketplace',
    content: 'Marketplace',
  },
  {
    key: 'cuisine',
    content: 'Categories',
  },
  {
    key: 'fee',
    content: 'Fee',
    width: 80,
  },
  {
    key: 'outlets',
    content: 'Outlets',
    width: 100,
  },
  {
    key: 'terminals',
    content: 'Terminals',
    width: 110,
  },
  {
    key: 'promote',
    content: 'Promote',
    width: 170,
  },
  {
    key: 'delete',
    content: '',
    width: 60,
  },
]

const TableHeader = ({ setSelected, selected }) => (
  <thead>
    <tr>
      {cells.map(({ key, width, sortable, content }) => (
        <th key={key} width={width}>
          {sortable ? (
            <SortBy
              title={content}
              orderBy={key}
              setSelected={setSelected}
              selected={selected}
            />
          ) : (
            content
          )}
        </th>
      ))}
    </tr>
  </thead>
)
export default TableHeader
