import { Fragment, useContext, useState } from 'react'
import Query from '@components/Query/Query'
import {
  Card,
  Button,
  Tag,
  Icon,
  RadioGroup,
  Callout,
  Intent,
  Spinner,
  Classes,
  H4,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { string, func, bool } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { times } from 'lodash'
import { removeUnderscores } from '@utils/helpers'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'

import ACCEPT_NETWORK_ORDER from './mutations/acceptNetworkOrder.mutation'

import { getIntent } from './helpers/helpers'
import moment from 'moment'

import GET_SAME_WINDOW_ORDERS_COUNT from './queries/getSameWindowOrdersCount.query'
import { ModalContext } from '../../providers/ModalProvider'
import { useMutation } from '@apollo/client'
import { useGetOrderQuery } from './Order/queries/getOrder.query.generated'

const QUARTER_OF_HOUR = 15

const AcceptNetworkOrderModal = ({
  modalOpen = false,
  modalClose,
  orderId,
  refetchQueries = [],
}) => {
  const { data: { order } = {}, refetch } = useGetOrderQuery({
    variables: {
      id: orderId,
    },
  })

  const [isBookingDelivery, updateIsBookingDelivery] = useState(false)
  const { setState: toggleModal } = useContext(ModalContext)

  const [acceptNetworkOrder] = useMutation(ACCEPT_NETWORK_ORDER, {
    onError: error => {
      defaultErrorHandler(error)
      setTimeout(() => {
        modalClose()
      }, 2000)
      return refetch()
    },
    onCompleted: ({ acceptNetworkOrder: { message } }) => {
      successToast(message)
      toggleModal({ printOrderModal: orderId })
      modalClose()
      return refetch()
    },
    refetchQueries,
  })
  const initialValues = {
    id: orderId,
    newCompletionDateTime: order.estimatedCompletionTime,
  }

  return (
    <Dialog
      title={isBookingDelivery ? 'Booking Delivery' : 'Accept Order'}
      isCloseButtonShown={false}
      isOpen={modalOpen}
      data-test-id="accept-order-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          if (values.newCompletionDateTime === order.estimatedCompletionTime) {
            delete values.newCompletionDateTime
          }
          await acceptNetworkOrder({
            variables: values,
          })
          updateIsBookingDelivery(true)
        }}
      >
        {props => {
          const { values, handleSubmit, handleChange, isSubmitting } = props

          const radioTimeSlots = []

          const orderTime = moment(order.estimatedCompletionTime)
          const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

          radioTimeSlots.push({
            label: `No Change`,
            value: order.estimatedCompletionTime,
          })

          const windowLength = moment(order.selectedDeliveryWindow.end).diff(
            moment(order.selectedDeliveryWindow.start),
            'minutes'
          )
          times(4, index => {
            const addMinutes = (index + 1) * QUARTER_OF_HOUR
            const newOrderTime = orderTime.clone().add(addMinutes, 'minutes')
            radioTimeSlots.push({
              label: `+${addMinutes} Minutes (${newOrderTime
                .clone()
                .add(windowLength, 'minutes')
                .format('HH:mm')})`,
              value: newOrderTime.utc().format(formatString),
            })
          })

          return (
            <Fragment>
              {isBookingDelivery ? (
                <div className={Classes.DIALOG_BODY}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <Spinner size={40} />
                    <div>
                      <p>
                        <p>
                          <strong>We won't be a minute.</strong>
                        </p>
                        <p>
                          We're currently arranging collection and dropoff of
                          the order with your delivery network.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="bp5-dialog-body">
                    <Fragment>
                      {!order.asap && (
                        <Fragment>
                          <Callout
                            intent="warning"
                            title={'Are you ready to prepare this preorder?'}
                          >
                            <FulfilmentStatus
                              orderStatus={order.orderStatus}
                              fulfillmentMethod={order.fulfillmentMethod}
                              updatedAt={order.updatedAt}
                              estimatedDeliveryDate={
                                order.estimatedDeliveryDate
                              }
                              estimatedCompletionTime={
                                order.estimatedCompletionTime
                              }
                              asap={order.asap}
                            />
                          </Callout>
                          <br />
                        </Fragment>
                      )}
                      <p>Are you sure you want to update the order status?</p>
                      <Card>
                        <center>
                          <Tag
                            large={true}
                            intent={getIntent(order.orderStatus)}
                          >
                            {removeUnderscores(order.orderStatus)}
                          </Tag>
                          &nbsp;&nbsp;
                          <Icon icon={'arrow-right'} />
                          &nbsp;&nbsp;
                          <Tag large={true} intent={getIntent('PREPARING')}>
                            PREPARING
                          </Tag>
                        </center>
                      </Card>
                    </Fragment>

                    {order.fulfillmentMethod !== 'TABLE' ? (
                      <Fragment>
                        {!order.asap &&
                          order.fulfillmentMethod !== 'COLLECTION' && (
                            <Query
                              query={GET_SAME_WINDOW_ORDERS_COUNT}
                              variables={{
                                orderId: order.id,
                              }}
                            >
                              {data => {
                                if (!data.getSameWindowOrdersCount) {
                                  return null
                                }

                                const {
                                  getSameWindowOrdersCount: { count, limit },
                                } = data

                                return (
                                  <div>
                                    {count >= limit && (
                                      <Callout
                                        icon="warning-sign"
                                        intent={Intent.WARNING}
                                      >
                                        {`This delivery window limit is ${limit} and there are currently ${count} ${
                                          count > 1 ? 'orders' : 'order'
                                        } with this window.`}
                                      </Callout>
                                    )}
                                  </div>
                                )
                              }}
                            </Query>
                          )}
                        <H4>Need more time?</H4>
                        <RadioGroup
                          label="You can move this order forwards, we'll let the customer know."
                          name="newCompletionDateTime"
                          onChange={handleChange}
                          options={radioTimeSlots}
                          selectedValue={values.newCompletionDateTime}
                        />
                      </Fragment>
                    ) : null}
                  </div>
                  <div className="bp5-dialog-footer">
                    <div className="bp5-dialog-footer-actions">
                      <Button
                        minimal
                        onClick={() => modalClose(false)}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        loading={isSubmitting}
                        data-test-id="order-accept-confirm-button"
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Fragment>
          )
        }}
      </Formik>
    </Dialog>
  )
}

AcceptNetworkOrderModal.propTypes = {
  modalClose: func,
  modalOpen: bool,
  orderId: string,
}

export default AcceptNetworkOrderModal
