import Query from '@components/Query/Query'
import React, { Fragment } from 'react'
import GET_MENU_FOR_PRINTING from './queries/getMenuForPrinting.query'
import veganImage from './images/vegan.png'
import glutenFreeImage from './images/glutenfree.png'
import vegetarianImage from './images/vegetarian.png'
import options from './images/add.svg'
import {
  FooterContent,
  GroupContainer,
  HeaderContainer,
  ItemContainer,
  ItemDescription,
  ItemHeader,
  ItemName,
  ItemPrice,
  ItemsContainer,
  KeyContainer,
  KeyP,
  MenuGroupHeader,
  OptionsSvg,
  RestaurantLogo,
  RestaurantName,
  SmlIcon,
  SvgContainer,
} from './MenuPrintout.styles'
import { canView } from '@stores/userStore'
import config from '../../../config'
import { GetMenuForPrintingQuery } from './queries/getMenuForPrinting.query.generated'
import Currency from '@components/Currency/Currency'
const AWS_CDN_URL =
  config.awsCDNUri ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

const MenuPrintout: React.FC<{ restaurantId: string }> = ({ restaurantId }) => {
  if (!canView('restaurants')) return false

  return (
    <Query<GetMenuForPrintingQuery>
      query={GET_MENU_FOR_PRINTING}
      variables={{
        id: restaurantId,
      }}
    >
      {data => {
        if (
          data &&
          data.getRestaurants &&
          data.getRestaurants.restaurants.length > 0
        ) {
          const { image, menuItemsGroups, name, outlets } =
            data.getRestaurants.restaurants[0]
          return (
            <Fragment>
              {outlets.length > 0 && (
                <Fragment>
                  <footer className="page-footer">
                    <FooterContent>
                      <KeyContainer>
                        <SvgContainer>
                          <OptionsSvg src={options} />
                        </SvgContainer>
                        <KeyP style={{ textAlign: 'left' }}>
                          Options available, see online menu for details
                        </KeyP>
                      </KeyContainer>
                      <KeyContainer>
                        <SmlIcon src={glutenFreeImage} alt="Gluten free" />
                        <KeyP>Gluten Free</KeyP>
                      </KeyContainer>
                      <KeyContainer>
                        <SmlIcon src={vegetarianImage} alt="Vegetarian" />
                        <KeyP>Vegetarian</KeyP>
                      </KeyContainer>
                      <KeyContainer>
                        <SmlIcon src={veganImage} alt="Vegan" />
                        <KeyP>Vegan</KeyP>
                      </KeyContainer>
                    </FooterContent>
                    <FooterContent>
                      <KeyP>
                        Please inform {name} of any dietary requirements before
                        ordering. We cannot guarantee dishes are 100% free of
                        allergens or contaminants.
                      </KeyP>
                    </FooterContent>
                  </footer>
                  <table>
                    <tr>
                      <td>
                        <div className="page">
                          <HeaderContainer>
                            <RestaurantLogo
                              src={`${AWS_CDN_URL}${image}`}
                              alt={`${name} logo`}
                            />
                            <RestaurantName>{name}</RestaurantName>
                            <p>
                              Available through {outlets[0].marketplace.name}
                            </p>
                          </HeaderContainer>
                          {menuItemsGroups.map((group, groupIndex) => {
                            if (group.menuItems.length) {
                              return (
                                <GroupContainer
                                  first={groupIndex === 0}
                                  key={group.id}
                                >
                                  <MenuGroupHeader>
                                    {group.name.toUpperCase()}
                                  </MenuGroupHeader>
                                  <p style={{ padding: '7px' }}>
                                    {group.description}
                                  </p>
                                  <ItemsContainer>
                                    {group.menuItems.map(item => {
                                      return (
                                        <Fragment key={item.id}>
                                          <ItemContainer>
                                            <ItemHeader>
                                              <ItemName>
                                                {item.name}

                                                {item.isGlutenFree ? (
                                                  <SmlIcon
                                                    src={glutenFreeImage}
                                                    alt="Gluten free"
                                                    title={`${item.name} is gluten free`}
                                                  />
                                                ) : null}
                                                {item.isVegan ? (
                                                  <SmlIcon
                                                    src={veganImage}
                                                    alt="Vegan"
                                                    title={`${item.name} is vegan`}
                                                  />
                                                ) : null}
                                                {item.isVegetarian ? (
                                                  <SmlIcon
                                                    src={vegetarianImage}
                                                    alt="Vegetarian"
                                                    title={`${item.name} is vegetarian`}
                                                  />
                                                ) : null}
                                                {item.options.length > 0 ? (
                                                  <OptionsSvg src={options} />
                                                ) : null}
                                              </ItemName>
                                              <ItemPrice>
                                                <Currency amount={item.price} />
                                              </ItemPrice>
                                            </ItemHeader>
                                            <ItemDescription>
                                              {item.description}
                                            </ItemDescription>
                                            {/* QR code is in RED-2878 still waiting to be merged. Just need to put a couple of lines of code in here when available. */}
                                            {/* {groupIndex === menuItemsGroups.length - 1 &&
                              itemIndex === group.menuItems.length - 1 ? (
                                <div>QR code here</div>
                              ) : null} */}
                                          </ItemContainer>
                                        </Fragment>
                                      )
                                    })}
                                  </ItemsContainer>
                                </GroupContainer>
                              )
                            }
                          })}
                        </div>
                      </td>
                    </tr>
                    <tfoot>
                      <tr>
                        <td>
                          <div className="page-footer-space"></div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Fragment>
              )}
            </Fragment>
          )
        } else {
          return null
        }
      }}
    </Query>
  )
}

export default MenuPrintout
