import React from 'react'
import { string, func, bool } from 'prop-types'

import TypeaheadSingle from '@components/Typeahead/TypeaheadSingle'
import Query from '@components/Query/Query'
import GET_OUTLET_IDS from './queries/getOutletIds.query'

const OutletSelect = ({
  outletId,
  disabled = false,
  onChange,
  placeholder = '',
  showRestaurantName = false,
  showMarketplaceName = false,
  isOnlineOverride,
  restaurantId,
}) => (
  <Query
    query={GET_OUTLET_IDS}
    showLoader={false}
    variables={{
      isOnlineOverride: isOnlineOverride || undefined,
      restaurantId: restaurantId || undefined,
    }}
  >
    {({ getOutlets: { outlets } }) => (
      <TypeaheadSingle
        items={outlets.map(({ id, name, restaurant, marketplace }) => ({
          id,
          name,
          label: [
            showRestaurantName && restaurant ? restaurant.name : '',
            showMarketplaceName && marketplace ? marketplace.name : '',
          ]
            .filter(val => val)
            .join(' - '),
        }))}
        selected={outletId}
        setSelected={onChange}
        disabled={disabled}
        placeholder={placeholder}
        isOpenFilter={false}
      />
    )}
  </Query>
)

OutletSelect.propTypes = {
  outletId: string,
  disabled: bool,
  showRestaurantName: bool,
  showMarketplaceName: bool,
  isOnlineOverride: bool,
  placeholder: string,
  onChange: func,
}

export default OutletSelect
