import ReviewsTable from '@components/Reviews/ReviewsTable'
import { useParams } from 'react-router-dom'

const CustomerReviews = () => {
  const params = useParams()
  const queryVars = {
    customerId: params.customer,
    hideCustomer: true,
  }
  return <ReviewsTable queryVars={queryVars} />
}

export default CustomerReviews
