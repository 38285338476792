import { useState, useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { locationType, matchType } from '@utils/types'
import GET_PLATFORM from './queries/getPlatform.query'
import EDIT_PLATFORM from './mutations/editPlatform.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import split from 'lodash/split'
import FinancialSettings from '@components/Platform/FinancialSettings/FinancialSettings'
import GeneralSettings from '@components/Platform/GeneralSettings/GeneralSettings'
import CategoriesWrapper from '@components/Platform/Categories/CategoriesWrapper'
import { successToast } from '@utils/toast'
import { PlatformContext } from './platform-context'
import TestNotification from '@components/Platform/TestNotification/TestNotification'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'
import { StringParam, useQueryParam } from 'use-query-params'
import { PlatformIntegrations } from './Intergrations/Intergrations'
import { useMutation, useQuery } from '@apollo/client'
import { NonIdealState } from '@blueprintjs/core'
import productDictionary from '@src/utils/productDictionary'

const Platform = () => {
  const [editCategoryId, onChangeEditCategoryId] = useQueryParam(
    'editCategoryId',
    StringParam
  )
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false)
  const { configurePageLayout } = useContext(PageLayoutContext)
  const location = useLocation()

  const tabButton = pathname => {
    const path = split(pathname, '/')[2]
    let button
    switch (path) {
      case 'categories':
        button = {
          text: 'Add Category',
          onClick: () => setAddCategoryModalOpen(true),
        }
        break
      default:
        button = null
    }

    return button
  }

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.PLATFORM,
      tabs: [
        { to: '/platform/general', name: 'General' },
        { to: '/platform/financial', name: 'Connect' },
        { to: '/platform/categories', name: 'Categories' },
        { to: '/platform/test-notification', name: 'Test Notification' },
        { to: '/platform/map', name: 'Map' },
        { to: '/platform/integrations', name: 'Integrations' },
      ],
      button: tabButton(location.pathname),
    })
  }, [configurePageLayout, location])

  const { data, loading } = useQuery(GET_PLATFORM)

  const [editPlatform] = useMutation(EDIT_PLATFORM, {
    onError: defaultErrorHandler,
    onCompleted: ({ editPlatform }) =>
      successToast(editPlatform.message || 'Platform successfully edited'),
    refetchQueries: [{ query: GET_PLATFORM }],
  })

  if (loading) {
    return <NonIdealState title={'Loading platform'} />
  }

  const platform = data.getPlatform.platform

  return (
    <PlatformContext.Provider
      value={{
        editCategoryId,
        onChangeEditCategoryId,
        addCategoryModalOpen,
        setAddCategoryModalOpen,
      }}
    >
      <Routes>
        <Route path="/" element={<Navigate to="general" />} />
        <Route
          path="general"
          element={
            <GeneralSettings platform={platform} editPlatform={editPlatform} />
          }
        />
        <Route
          path="financial"
          element={
            <FinancialSettings
              platform={platform}
              editPlatform={editPlatform}
            />
          }
        />
        <Route path="map" element={<MarketplaceMap />} />
        <Route
          path="integrations"
          element={
            <PlatformIntegrations
              platform={platform}
              editPlatform={editPlatform}
            />
          }
        />
        <Route path="categories" element={<CategoriesWrapper />} />
        <Route path="test-notification" element={<TestNotification />} />
      </Routes>
    </PlatformContext.Provider>
  )
}

Platform.propTypes = {
  match: matchType,
  location: locationType,
}

export default Platform
