import styled from 'styled-components'
import OnboardingForm from './OnboardingForm'
import { Card } from '@blueprintjs/core'
import { Query } from '@apollo/client/react/components'
import ONBOARDING_INFO from './queries/onboardingInfo.query'
import BlankPage from '@components/BlankPage/BlankPage'
import config from '../../config'

const OnboardingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

const LogoContainer = styled.div`
  text-align: center;
  padding: 20px 0;
`

const OnboardingFormStyled = styled(Card)`
  width: 60%;
  min-width: 300px;
  max-width: 800px;
  margin-bottom: 20px;
`

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const getMarketplaceKey = () => {
  if (location.search) {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('onboarding_marketplace')
  }
  return null
}

const Onboarding = () => {
  const marketplaceKey = getMarketplaceKey()

  if (!marketplaceKey) {
    return <BlankPage />
  }

  return (
    <Query query={ONBOARDING_INFO} variables={{ marketplaceKey }}>
      {({ data: { onboardingInfo } }) => {
        if (!onboardingInfo) {
          return null
        }
        // onboarding for web v2 is handled on that client
        if (onboardingInfo.enableCustomerV2Client) {
          window.location.href = `https://${onboardingInfo.cname}/add-business`
          return null
        }
        return (
          <Container>
            <OnboardingContainer>
              <OnboardingFormStyled>
                <LogoContainer
                  style={{
                    background: onboardingInfo.heroImage
                      ? `url(https://${config.environmentDomain}/${onboardingInfo.heroImage})`
                      : 'none',
                    backgroundSize: 'cover',
                  }}
                >
                  {onboardingInfo.logoImage && (
                    <img
                      src={`https://${config.environmentDomain}/${onboardingInfo.logoImage}`}
                      alt={`logo`}
                      style={{ maxWidth: '50%' }}
                    />
                  )}
                </LogoContainer>
                {onboardingInfo.onboardingDescription && (
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      lineHeight: '10px',
                    }}
                  >
                    <p
                      style={{
                        font: 'inherit',
                        lineHeight: '20px',
                      }}
                    >
                      {onboardingInfo.onboardingDescription}
                    </p>
                  </div>
                )}
                <OnboardingForm marketplaceKey={marketplaceKey} />
              </OnboardingFormStyled>
            </OnboardingContainer>
          </Container>
        )
      }}
    </Query>
  )
}

export default Onboarding
