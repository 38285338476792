import { Drawer } from '@blueprintjs/core'
import TerminalsFilterBar from './TerminalsFilterBar'
import React from 'react'
import Details from '../../Terminal/Details'
import PropTypes from 'prop-types'
import { useTerminalQueryParams } from './useTerminalQueryParams'

const TerminalsContentWrapper = ({
  page,
  setPage,
  resetFilters,
  someFiltersSelected,
  queryVariables,
  terminalsQueryData,
  children,
}) => {
  const { viewTerminal, onChangeViewTerminal } = useTerminalQueryParams()
  return (
    <div className="bp5-table-frame">
      <TerminalsFilterBar
        counts={{
          totalRecords: terminalsQueryData ? terminalsQueryData.totalCount : 0,
          currentPageRecords: terminalsQueryData ? terminalsQueryData.count : 0,
        }}
        page={page}
        setPage={setPage}
        resetFilters={resetFilters}
        someFiltersSelected={someFiltersSelected}
        queryVariables={queryVariables}
      />
      {children}
      <Drawer
        isOpen={viewTerminal}
        title="Terminal Details"
        onClose={() => {
          onChangeViewTerminal(null)
        }}
        size={Drawer.SIZE_SMALL}
      >
        <Details id={viewTerminal} queryVariables={queryVariables} />
      </Drawer>
    </div>
  )
}

TerminalsContentWrapper.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  someFiltersSelected: PropTypes.bool,
  queryVariables: PropTypes.object.isRequired,
  terminalsQueryData: PropTypes.shape({
    totalCount: PropTypes.number,
    count: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
}

export default TerminalsContentWrapper
