import gql from 'graphql-tag'

const ALLOW_ORDER_TO_TABLE = gql`
  mutation allowOrderToTable($id: String!, $allowOrderToTable: Boolean!) {
    editMarketplace(id: $id, allowOrderToTable: $allowOrderToTable) {
      message
      marketplace {
        id
        allowOrderToTable
      }
    }
  }
`

export default ALLOW_ORDER_TO_TABLE
