import gql from 'graphql-tag'

const EDIT_MARKETPLACE_KIOSK_FEATURES = gql`
  mutation editMarketplaceKioskFeatures(
    $id: String!
    $kioskAllowSearch: Boolean!
    $kioskOptionsListStyle: OptionsListStyle!
    $kioskMenuListItemStyle: MenuListItemStyle!
  ) {
    editMarketplaceKioskFeatures(
      id: $id
      kioskAllowSearch: $kioskAllowSearch
      kioskOptionsListStyle: $kioskOptionsListStyle
      kioskMenuListItemStyle: $kioskMenuListItemStyle
    ) {
      message
      marketplace {
        id
        kioskAllowSearch
        kioskOptionsListStyle
        kioskMenuListItemStyle
      }
    }
  }
`

export default EDIT_MARKETPLACE_KIOSK_FEATURES
