import gql from 'graphql-tag'

const GET_BUSINESS_OUTLETS = gql`
  query getBusinessOutlets($marketplaceIds: [ID!], $restaurantIds: [ID!]) {
    getOutlets(marketplaceIds: $marketplaceIds, restaurantIds: $restaurantIds) {
      outlets {
        id
        name
        marketplace {
          id
          name
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_BUSINESS_OUTLETS
