import gql from 'graphql-tag'

const DELETE_MEMBER_DISCOUNT = gql`
  mutation deleteMemberDiscount($id: String!) {
    deleteMemberDiscount(id: $id) {
      message
      discount {
        id
        name
      }
    }
  }
`

export default DELETE_MEMBER_DISCOUNT
