import { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import createGeoJSONCircle from './createGeoJSONCircle'

import config from '@src/config'

mapboxgl.accessToken = config.mapboxKey

const GeoOutletRadii = ({ center, outlets }) => {
  const sourceID = 'incomingFeatures'
  const clusterSourceId = 'clusterSource'
  const config = {
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v10',
    zoom: 8,
    maxZoom: 20,
    center: center || {
      lng: -1.146159,
      lat: 52.809403,
    },
  }

  const [map, setMap] = useState(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const newMap = new mapboxgl.Map(config)
    newMap.on('load', () => {
      setReady(true)
    })
    setMap(newMap)

    return () => {
      map && map.remove()
    }
  }, [])

  const buildSource = () => {
    const featuresToDraw = []

    outlets.forEach(outlet => {
      outlet.deliveryZoneCosts.forEach(({ radiusMiles }) => {
        if (radiusMiles) {
          const outletCenter = outlet.outletAddress.geo.coordinates
          featuresToDraw.push(createGeoJSONCircle(outletCenter, radiusMiles))
          featuresToDraw.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: outletCenter,
            },
            properties: {
              restaurant: outlet.restaurant.name,
              name: outlet.name,
              radius: radiusMiles,
            },
          })
        }
      })
    })

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: featuresToDraw,
      },
    }
  }

  useEffect(() => {
    if (map && ready) {
      const source = buildSource()
      map.addSource(sourceID, source)

      map.addSource(clusterSourceId, {
        ...source,
        cluster: true,
        clusterMaxZoom: 20,
        clusterRadius: 50,
      })

      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: sourceID,
        paint: {
          'fill-color': 'blue',
          'fill-opacity': 0.1,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: clusterSourceId,
        filter: ['all', ['has', 'point_count'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': 'red',
          'circle-radius': 20,
        },
      })

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: clusterSourceId,
        filter: ['all', ['has', 'point_count'], ['==', '$type', 'Point']],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })

      map.addLayer({
        id: 'radius',
        type: 'circle',
        source: clusterSourceId,
        paint: {
          'circle-radius': 6,
          'circle-color': 'red',
        },
        filter: ['all', ['!has', 'point_count'], ['==', '$type', 'Point']],
      })

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      map.on('mouseenter', 'radius', function (e) {
        map.getCanvas().style.cursor = 'pointer'

        const coordinates = e.features[0].geometry.coordinates.slice()

        const description = ReactDOMServer.renderToString(
          <div>
            <h2
              style={{ fontSize: '1rem', padding: 0, margin: '0 0 0.3rem 0' }}
            >
              {e.features[0].properties.restaurant}
            </h2>
            <div>{e.features[0].properties.name}</div>
            <div>{`Radius: ${e.features[0].properties.radius} (miles)`}</div>
          </div>
        )

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      })

      map.on('mouseleave', 'radius', function () {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })

      map.on('click', 'clusters', function (e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        var clusterId = features[0].properties.cluster_id
        map
          .getSource(clusterSourceId)
          .getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            })
          })
      })

      map.on('mouseenter', 'clusters', function () {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', 'clusters', function () {
        map.getCanvas().style.cursor = ''
      })
    }
  }, [map, ready])

  useEffect(() => {
    if (map && ready) {
      const source = buildSource()

      const activeSource = map.getSource(sourceID)
      activeSource.setData(source.data)
    }
  }, [outlets])

  return (
    <div
      style={{
        height: '80vh',
        width: '100%',
      }}
      id="map"
    />
  )
}

export default GeoOutletRadii
