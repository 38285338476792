import gql from 'graphql-tag'

export const GET_MENU_ITEMS = gql`
  # Write your query or mutation here
  query getOutletMenuItemsForCampaign($outletId: String!) {
    getOutlets(id: $outletId) {
      outlets {
        id
        menuItemsGroups(where: { archived_not: true }) {
          id
          name
          addOnItemsMenu
          menuItems {
            id
            name
          }
        }
      }
    }
  }
`
