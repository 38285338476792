import { Intent } from '@blueprintjs/core'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import productDictionary from '@src/utils/productDictionary'
import { object } from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import ArchiveCustomerModal from './ArchiveCustomerModal/ArchiveCustomerModal'
import CustomerReviews from './CustomerReviews'
import Details from './Details'
import Orders from './Orders'

const Customer = () => {
  const params = useParams()
  const [isArchiveCustomerModalOpen, setIsArchiveCustomerModalOpen] =
    useState(false)

  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.CUSTOMER,
      button: {
        text: 'Archive Customer',
        intent: Intent.DANGER,
        icon: 'delete',
        onClick: () => {
          return setIsArchiveCustomerModalOpen(true)
        },
      },
      tabs: [
        { to: `customer/${params.customer}/details`, name: 'Details' },
        { to: `customer/${params.customer}/orders`, name: 'Orders' },
        { to: `customer/${params.customer}/reviews`, name: 'Reviews' },
      ],
    })
  }, [configurePageLayout, setIsArchiveCustomerModalOpen])

  return (
    <section>
      <Routes>
        <Route path="details" element={<Details />} />
        <Route path="orders" element={<Orders />} />
        <Route path="reviews" element={<CustomerReviews />} />
        <Route path="/" element={<Navigate to="details" />} />
      </Routes>
      {params.customer && (
        <ArchiveCustomerModal
          isModalOpen={isArchiveCustomerModalOpen}
          customerId={params.customer}
          closeModal={() => setIsArchiveCustomerModalOpen(false)}
        ></ArchiveCustomerModal>
      )}
    </section>
  )
}

Customer.propTypes = {
  location: object,
  history: object,
}
export default Customer
