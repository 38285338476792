import SortBy from '@components/Toolbar/SortBy'
import React from 'react'

const cells = [
  { key: 'friendlyName', content: 'Name', sortable: true },
  { key: 'lastOnline', content: 'Last Online' },
  { key: 'muteIndicator', content: '' },
  { key: 'openStatus', content: '' },
  { key: 'businessName', content: 'Business' },
  { key: 'outletName', content: 'Outlet' },
  { key: 'marketplaceName', content: 'Marketplace' },
  { key: 'serialNumber', content: 'Serial Number' },
  { key: 'softwareVersion', content: 'Software Version' },
]

const TableHeader = ({ onChangeOrderBy, orderBy, canSort }) => (
  <thead>
    <tr>
      {cells.map(({ key, width, sortable, content }) => (
        <th key={key} width={width}>
          {canSort && sortable ? (
            <SortBy
              title={content}
              orderBy={key}
              setSelected={onChangeOrderBy}
              selected={orderBy}
            />
          ) : (
            content
          )}
        </th>
      ))}
    </tr>
  </thead>
)

export default TableHeader
