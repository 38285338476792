import React, { useEffect, useState, Fragment, useContext } from 'react'
import { Query } from '@apollo/client/react/components'
import {
  Card,
  HTMLTable,
  Icon,
  Button,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'

import { matchType } from '@utils/types'
import { canPerformAction, canView } from '@stores/userStore'
import GET_SALES from './queries/getSales.query'
import Currency from '@components/Currency/Currency'
import { sumBy, orderBy, startCase } from 'lodash'
import { MarketplaceFilter } from '@components/Toolbar'
import { toast } from '@utils/toast'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import FilterRow from '@components/FilterRow/FilterRow'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { format } from 'date-fns'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '../../../components/Toolbar/DateRangeFilter/DateRangeFilter'
import { useRoleAwareBusinessFilterQueryParams } from '../../../components/Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const PageLayoutWrapper = ({ product, children }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    const tabs = []

    if (canView('sales')) {
      tabs.push(
        { to: '/reports/products', name: 'Products' },
        { to: '/reports/sales', name: 'Sales' },
        { to: '/reports/taxes', name: 'Taxes' }
      )
    }

    configurePageLayout({
      product,
      tabs,
    })
  }, [configurePageLayout, product])

  return children
}

const Sales = ({ product }) => {
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams()
  const [downloading, setDownloading] = useState(false)

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'business',
      'outlet',
      'marketplace',
      'totalOrders',
      'totalOrdersValue',
      'totalCardOrders',
      'totalCardOrdersValue',
      'totalCashOrders',
      'totalCashOrdersValue',
      'totalRefunds',
      'totalRefundsValue',
      'totalDiscountOrders',
      'totalDiscountOrdersValue',
      'totalAddOnItemsOrders',
      'totalAddOnItemsOrdersValue',
      'totalCollectionOrders',
      'totalCollectionOrdersValue',
      'totalTableOrders',
      'totalTableOrdersValue',
      'totalDeliveryOrders',
      'totalDeliveryOrdersValue',
      'totalNetworkOrders',
      'totalNetworkOrdersValue',
      'totalCourierOrders',
      'totalCourierOrdersValue',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_SALES,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          marketplaceIds: marketplaceFilter,
        },
      })
      .then(data => {
        orderBy(data.data.sales, 'totalOrdersValue', 'desc').map(report => {
          csvData.push(
            csvHeader
              .map(column => {
                return {
                  business: `"${report.outlet.restaurant.name}"`,
                  outlet: `"${report.outlet.name}"`,
                  marketplace: `"${report.outlet.marketplace.name}"`,
                  totalOrders: parseInt(report.totalOrders),
                  totalOrdersValue: penceToPounds(report.totalOrdersValue),
                  totalCardOrders: parseInt(report.totalCardOrders),
                  totalCardOrdersValue: penceToPounds(
                    report.totalCardOrdersValue
                  ),
                  totalCashOrders: parseInt(report.totalCashOrders),
                  totalCashOrdersValue: penceToPounds(
                    report.totalCashOrdersValue
                  ),
                  totalRefunds: parseInt(report.totalRefunds),
                  totalRefundsValue: penceToPounds(report.totalRefundsValue),
                  totalDiscountOrders: parseInt(report.totalDiscountOrders),
                  totalDiscountOrdersValue: penceToPounds(
                    report.totalDiscountOrdersValue
                  ),
                  totalAddOnItemsOrders: parseInt(report.totalAddOnItemsOrders),
                  totalAddOnItemsOrdersValue: penceToPounds(
                    report.totalAddOnItemsOrdersValue
                  ),
                  totalCollectionOrders: parseInt(report.totalCollectionOrders),
                  totalCollectionOrdersValue: penceToPounds(
                    report.totalCollectionOrdersValue
                  ),
                  totalTableOrders: parseInt(report.totalTableOrders),
                  totalTableOrdersValue: penceToPounds(
                    report.totalTableOrdersValue
                  ),
                  totalDeliveryOrders: parseInt(report.totalDeliveryOrders),
                  totalDeliveryOrdersValue: penceToPounds(
                    report.totalDeliveryOrdersValue
                  ),
                  totalNetworkOrders: parseInt(report.totalNetworkOrders),
                  totalNetworkOrdersValue: penceToPounds(
                    report.totalNetworkOrdersValue
                  ),
                  totalCourierOrders: parseInt(report.totalCourierOrders),
                  totalCourierOrdersValue: penceToPounds(
                    report.totalCourierOrdersValue
                  ),
                }[column]
              })
              .join(',')
          )
        })
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `sales-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  return (
    <PageLayoutWrapper product={product}>
      <Query
        query={GET_SALES}
        variables={{
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          marketplaceIds: marketplaceFilter,
        }}
      >
        {({ loading, error = null, data }) => {
          return (
            <Fragment>
              {error ? (
                <NonIdealState
                  icon="error"
                  title="Failed to load sales report"
                  description="Please try again"
                />
              ) : (
                <div className="bp5-table-frame">
                  <FilterRow>
                    <div>
                      <MarketplaceFilter disabled={loading} divider />
                      <DateRangeFilter
                        filterTypes={[
                          DATE_FILTER_TYPES.DAY,
                          DATE_FILTER_TYPES.WEEK,
                          DATE_FILTER_TYPES.CUSTOM,
                        ]}
                        disabled={
                          loading ||
                          (canPerformAction('filterByMarketplace') &&
                            marketplaceFilter.length === 0)
                        }
                      />
                    </div>
                    <Button
                      disabled={loading}
                      loading={downloading}
                      icon={'cloud-download'}
                      onClick={downloadCSV}
                    >
                      Download CSV
                    </Button>
                  </FilterRow>
                  {!error && loading ? (
                    <NonIdealState
                      icon={<Spinner size={60} />}
                      title="Loading Sales Report"
                      description="Please wait..."
                    />
                  ) : !error && data.sales && data.sales.length === 0 ? (
                    canPerformAction('filterByMarketplace') &&
                    marketplaceFilter.length === 0 ? (
                      <NonIdealState
                        icon="property"
                        title="Select Marketplace"
                        description="Please select a marketplace to load this report."
                      />
                    ) : (
                      <NonIdealState
                        icon="th-list"
                        title="No Sales Data"
                        description="There is no data for the selected filter."
                      />
                    )
                  ) : (
                    <div className="bp5-table-container bp5-scrollable">
                      <Card className={'bp5-nopad'}>
                        <HTMLTable bordered={false} interactive={true}>
                          <thead>
                            <tr>
                              <th colSpan={3} />
                              <th
                                colSpan={8}
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                              >
                                Payment
                              </th>
                              <th
                                colSpan={4}
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                              >
                                Promotions
                              </th>
                              <th
                                colSpan={10}
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                              >
                                Fulfillment
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={3} />
                              <th
                                colSpan={2}
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                              >
                                Total
                              </th>
                              <th colSpan={2}>Card</th>
                              <th colSpan={2}>Cash</th>
                              <th colSpan={2}>Refunds</th>
                              <th
                                colSpan={2}
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                              >
                                Discounts
                              </th>
                              <th colSpan={2}>AddOns</th>
                              <th
                                style={{ borderLeft: ' 1px solid #DCDCDD' }}
                                colSpan={2}
                              >
                                Collection
                              </th>
                              <th colSpan={2}>Table</th>
                              <th colSpan={2}>Delivery</th>
                              <th colSpan={2}>Network</th>
                              <th colSpan={2}>Courier</th>
                            </tr>
                            <tr>
                              <th style={{ width: '200px' }}>Business</th>
                              <th style={{ width: '200px' }}>Outlet</th>
                              <th style={{ width: '200px' }}>Marketplace</th>
                              <th
                                style={{
                                  width: '50px',
                                  borderLeft: ' 1px solid #DCDCDD',
                                }}
                              >
                                {sumBy(data.sales, report => {
                                  return report.totalOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalCardOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalCardOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalCashOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalCashOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px', color: 'red' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalRefunds
                                })}
                              </th>
                              <th style={{ width: '100px', color: 'red' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalRefundsValue
                                  })}
                                />
                              </th>
                              <th
                                style={{
                                  width: '50px',
                                  borderLeft: ' 1px solid #DCDCDD',
                                }}
                              >
                                {sumBy(data.sales, report => {
                                  return report.totalDiscountOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalDiscountOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalAddOnItemsOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalAddOnItemsOrdersValue
                                  })}
                                />
                              </th>
                              <th
                                style={{
                                  width: '50px',
                                  borderLeft: ' 1px solid #DCDCDD',
                                }}
                              >
                                {sumBy(data.sales, report => {
                                  return report.totalCollectionOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalCollectionOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalTableOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalTableOrdersValue
                                  })}
                                />
                              </th>

                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalDeliveryOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalDeliveryOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalNetworkOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalNetworkOrdersValue
                                  })}
                                />
                              </th>
                              <th style={{ width: '50px' }}>
                                {sumBy(data.sales, report => {
                                  return report.totalCourierOrders
                                })}
                              </th>
                              <th style={{ width: '100px' }}>
                                <Currency
                                  amount={sumBy(data.sales, report => {
                                    return report.totalCourierOrdersValue
                                  })}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.sales.map(report => (
                              <tr key={report.outlet.id}>
                                <td>
                                  {canView('restaurants') ? (
                                    <Link
                                      className="bp5-text-overflow-ellipsis"
                                      to={`/business/${report.outlet.restaurant.id}`}
                                    >
                                      {report.outlet.restaurant.name}
                                    </Link>
                                  ) : (
                                    report.outlet.restaurant.name
                                  )}
                                </td>
                                <td>
                                  <span className="bp5-text-overflow-ellipsis">
                                    <Icon
                                      icon="symbol-circle"
                                      color={
                                        report.outlet.isOnline
                                          ? '#5bb70d'
                                          : '#CDD6DD'
                                      }
                                    />
                                    &nbsp;
                                    {canView('restaurants') ? (
                                      <Link
                                        to={`/business/${report.outlet.restaurant.id}/outlets/${report.outlet.id}`}
                                      >
                                        {report.outlet.name}
                                      </Link>
                                    ) : (
                                      report.outlet.name
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {canView('marketplaces') ? (
                                    <Link
                                      to={`/marketplaces/${report.outlet.marketplace.id}`}
                                    >
                                      {report.outlet.marketplace.name}
                                    </Link>
                                  ) : (
                                    report.outlet.marketplace.name
                                  )}
                                </td>
                                <td
                                  style={{ borderLeft: ' 1px solid #DCDCDD' }}
                                >
                                  {report.totalOrders}
                                </td>
                                <td>
                                  <Currency amount={report.totalOrdersValue} />
                                </td>
                                <td>{report.totalCardOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalCardOrdersValue}
                                  />
                                </td>
                                <td>{report.totalCashOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalCashOrdersValue}
                                  />
                                </td>
                                <td style={{ color: 'red' }}>
                                  {report.totalRefunds}
                                </td>
                                <td style={{ color: 'red' }}>
                                  <Currency amount={report.totalRefundsValue} />
                                </td>
                                <td
                                  style={{ borderLeft: ' 1px solid #DCDCDD' }}
                                >
                                  {report.totalDiscountOrders}
                                </td>
                                <td>
                                  <Currency
                                    amount={report.totalDiscountOrdersValue}
                                  />
                                </td>
                                <td>{report.totalAddOnItemsOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalAddOnItemsOrdersValue}
                                  />
                                </td>
                                <td
                                  style={{ borderLeft: ' 1px solid #DCDCDD' }}
                                >
                                  {report.totalCollectionOrders}
                                </td>
                                <td>
                                  <Currency
                                    amount={report.totalCollectionOrdersValue}
                                  />
                                </td>
                                <td>{report.totalTableOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalTableOrdersValue}
                                  />
                                </td>

                                <td>{report.totalDeliveryOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalDeliveryOrdersValue}
                                  />
                                </td>
                                <td>{report.totalNetworkOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalNetworkOrdersValue}
                                  />
                                </td>
                                <td>{report.totalCourierOrders}</td>
                                <td>
                                  <Currency
                                    amount={report.totalCourierOrdersValue}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </HTMLTable>
                      </Card>
                    </div>
                  )}
                </div>
              )}
            </Fragment>
          )
        }}
      </Query>
    </PageLayoutWrapper>
  )
}

Sales.propTypes = {
  match: matchType,
}

export default Sales
