import gql from 'graphql-tag'

const GET_RESTAURANT_VAT_STATUS = gql`
  query getRestaurants($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        isVATregistered
      }
    }
  }
`

export default GET_RESTAURANT_VAT_STATUS
