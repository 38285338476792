import { Card } from '@blueprintjs/core'
import React from 'react'
import { groupData } from '@components/Analytics/util/groupData'
import {
  AvgValuesContainer,
  DiscountedValuesContainer,
  NumberOfOrdersContainer,
  OrdersStatusContainer,
  OutletsOrCustomersContainer,
  PaymentsOrFulfilmentContainer,
  UserAgentsContainer,
  TotalValuesContainer,
} from '@components/Analytics/sharedTooltipComponents'

export const renderTooltip = ({
  tooltipData: { active, payload, label },
  isSingleDayShiftSelection,
}) => {
  if (active && payload && payload.length) {
    const {
      outlets,
      customers,
      paymentMethods,
      fulfillmentMethods,
      statuses,
      numberOfOrders,
      totalValues,
      avgValue,
      discountedValues,
      userAgents,
    } = groupData(payload, true)

    return (
      <Card
        style={{
          backgroundColor: 'white',
          padding: '1rem',
        }}
      >
        <h3>
          {isSingleDayShiftSelection ? 'Interval' : 'Day'}: {label}
        </h3>
        <OrdersStatusContainer statuses={statuses} title="Status" />
        <TotalValuesContainer
          totalValues={totalValues}
          title="Aggregated Totals"
        />
        <DiscountedValuesContainer
          discountedValues={discountedValues}
          title="Aggregated Total Discounted Values"
        />
        <NumberOfOrdersContainer
          numberOfOrders={numberOfOrders}
          title="Orders"
        />
        <PaymentsOrFulfilmentContainer
          records={paymentMethods}
          title="Payments"
        />
        <PaymentsOrFulfilmentContainer
          records={fulfillmentMethods}
          title="Fulfilments"
        />
        <UserAgentsContainer records={userAgents} title="User Agents" />
        <OutletsOrCustomersContainer records={customers} />
        <OutletsOrCustomersContainer records={outlets} />
        <AvgValuesContainer
          avgValue={avgValue}
          isSingleDayShiftSelection={isSingleDayShiftSelection}
        />
      </Card>
    )
  }

  return null
}
