import gql from 'graphql-tag'

const GET_SEGMENT = gql`
  query getSegment($marketplaceId: ID!, $segmentId: String!) {
    getSegment(marketplaceId: $marketplaceId, segmentId: $segmentId) {
      segment {
        id
        name
        estimate
        marketplaceId
        filterGroupsType
        filterGroups {
          filterGroupType
          filters {
            attribute
            comparator
            value
          }
        }
      }
    }
  }
`

export default GET_SEGMENT
