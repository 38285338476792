import gql from 'graphql-tag'

const GET_TABLE_SERVICE_OUTLETS = gql`
  query getTableServiceOutlets($marketplaceIds: [ID!]) {
    getOutlets(marketplaceIds: $marketplaceIds, isOrderToTableEnabled: true) {
      outlets {
        id
        name
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_TABLE_SERVICE_OUTLETS
