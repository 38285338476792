import { errorToast } from '@utils/toast'
import React from 'react'

export const handleFacebookErrors = error => {
  errorToast(
    <div>
      <strong>
        Error: {error.error_user_title} ({error.error_subcode})
      </strong>
      <div>{error.error_user_msg}</div>
    </div>
  )
}
