import gql from 'graphql-tag'

const EDIT_MENU_ITEM = gql`
  mutation editMenuItem(
    $id: String!
    $name: String
    $price: Int
    $minimumPurchasePrice: Int
    $maxPurchaseQuantity: Int
    $costPrice: Int
    $description: String
    $optionIds: [String]
    $isVegan: Boolean
    $isVegetarian: Boolean
    $isDairyFree: Boolean
    $isGlutenFree: Boolean
    $spiceLevel: Int
    $isKeto: Boolean
    $ageRestricted: Boolean
    $thumbnail: String
    $image: String
    $images: [ImageInput!]
    $vatRate: Float
    $VATinclusive: Boolean
    $bulkyItem: Boolean
    $SKU: String
    $UPC: String
  ) {
    editMenuItem(
      id: $id
      name: $name
      price: $price
      minimumPurchasePrice: $minimumPurchasePrice
      maxPurchaseQuantity: $maxPurchaseQuantity
      costPrice: $costPrice
      description: $description
      optionIds: $optionIds
      isVegan: $isVegan
      isVegetarian: $isVegetarian
      isDairyFree: $isDairyFree
      spiceLevel: $spiceLevel
      isKeto: $isKeto
      isGlutenFree: $isGlutenFree
      ageRestricted: $ageRestricted
      thumbnail: $thumbnail
      image: $image
      images: $images
      vatRate: $vatRate
      VATinclusive: $VATinclusive
      bulkyItem: $bulkyItem
      SKU: $SKU
      UPC: $UPC
    ) {
      message
      menuItem {
        id
        name
        thumbnail {
          id
          image {
            id
            src
          }
        }
        images {
          id
          src
          caption
          position
        }
        description
        price
        minimumPurchasePrice
        maxPurchaseQuantity
        costPrice
        vatRate
        VATinclusive
        isVegan
        isVegetarian
        isDairyFree
        isGlutenFree
        spiceLevel
        ageRestricted
        bulkyItem
        SKU
        UPC
        options {
          name
          id
          optionItems {
            id
            name
          }
        }
      }
    }
  }
`

export default EDIT_MENU_ITEM
