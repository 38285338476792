import styled from 'styled-components'
import { bool, node } from 'prop-types'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'

const FilterWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;

  .inner-scroll {
    width: 100%;
    height: auto;
  }
`
const Filters = styled.div<{ minimal: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: ${props => (props.minimal ? '0' : '8px 12px')};
  margin-bottom: ${props => (props.minimal ? '12px' : '0')};
  background: ${props => (props.minimal ? 'transparent' : '#eef0f2')};
  border-bottom: ${props =>
    props.minimal ? '1px solid transparent' : '1px solid #CDD3D6'};

  & > * {
    display: inline-flex;
    margin-right: 12px;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .bp5-dark & {
    background: #394b59;
    border-bottom: 1px solid #26323e;
  }
`

const FilterRow = ({ children, minimal = false }) => (
  <FilterWrapper>
    <PerfectScrollBar
      options={{
        className: 'inner-scroll',
        options: { suppressScrollY: true },
      }}
    >
      <Filters minimal={minimal}>{children}</Filters>
    </PerfectScrollBar>
  </FilterWrapper>
)

FilterRow.propTypes = {
  children: node,
  minimal: bool,
}

const StyledFilterRow = FilterRow

export default StyledFilterRow
