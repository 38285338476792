import gql from 'graphql-tag'

const EDIT_CUISINE = gql`
  mutation editCuisine(
    $id: String!
    $name: String!
    $emoji: String
    $image: String
  ) {
    editCuisine(id: $id, name: $name, emoji: $emoji, image: $image) {
      message
      cuisine {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`

export default EDIT_CUISINE
