import { useContext, useEffect } from 'react'
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useParams,
} from 'react-router-dom'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'

import Billing from './Billing/Billing'
import Payments from './Payments/Payments'
import Financials from './Financials/Financials'
import Details from './Details/Details'
import Users from '@components/Users/Users'
import split from 'lodash/split'
import {
  canPerformAction,
  canView,
  isPlatformLevelUser,
} from '@stores/userStore'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'
import appPageContext from '@components/Apps/appPageContext'
import Apps from '../Apps/Apps'
import ListApps from '../Apps/ListApps'
import AppPageWrapper from '@components/Apps/AppPageWrapper'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'
import productDictionary from '@src/utils/productDictionary'
import RoleProtectedRoute from '../RoleProtectedRoute/RoleProtectedRoute'

const tabButton = (
  pathname,
  setUserModalOpen,
  setAppModalOpen,
  setAppImportModalOpen
) => {
  const path = split(pathname, '/')[3]
  const pathId = split(pathname, '/')[4]

  if (path === 'users' && canPerformAction('inviteUser')) {
    return {
      text: 'Invite user',
      onClick: () => setUserModalOpen(true),
    }
  } else if (
    path === 'apps' &&
    typeof pathId !== 'undefined' &&
    canPerformAction('appBuild')
  ) {
    return {
      text: 'Start Build',
      onClick: () => setAppModalOpen(true),
    }
  } else if (path === 'apps' && isPlatformLevelUser()) {
    return {
      text: 'Import App',
      onClick: () => setAppImportModalOpen(true),
    }
  } else {
    return null
  }
}

const Partner = () => {
  const { setModalOpen } = useContext(usersPageContext)
  const { setAppModalOpen } = useContext(appPageContext)
  const { setAppImportModalOpen } = useContext(appPageContext)
  const { configurePageLayout } = useContext(PageLayoutContext)
  const location = useLocation()

  const { partner } = useParams()

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.PARTNER,
      tabs: [
        { to: `/partners/${partner}/details`, name: 'Details' },
        {
          to: `/partners/${partner}/financials`,
          name: 'Financials',
          hide: !isPlatformLevelUser(),
        },
        { to: `/partners/${partner}/billing`, name: 'Billing' },
        { to: `/partners/${partner}/payments`, name: 'Payments' },
        { to: `/partners/${partner}/apps`, name: 'Apps' },
        { to: `/partners/${partner}/map`, name: 'Map' },
        { to: `/partners/${partner}/users`, name: 'Users' },
      ],
      button: tabButton(
        location.pathname,
        setModalOpen,
        setAppModalOpen,
        setAppImportModalOpen
      ),
    })
  }, [
    configurePageLayout,
    location.pathname,
    setModalOpen,
    setAppModalOpen,
    setAppImportModalOpen,
  ])

  return (
    <section>
      <Routes>
        <Route path="/" element={<Navigate to={`details`} />} />
        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={`details`} element={<Details />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={'billing'} element={<Billing />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={'payments'} element={<Payments />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={'financials'} element={<Financials />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={'apps'} element={<ListApps />} />
        </Route>

        <Route
          element={<RoleProtectedRoute hasPermission={canView('partners')} />}
        >
          <Route path={'apps/:app'} element={<Apps />} />
        </Route>

        <Route path={'map'} element={<MarketplaceMap />} />

        <Route path={'users'} element={<Users partnerId={partner} />} />
      </Routes>
    </section>
  )
}

const PartnerPageWrapper = props => (
  <UsersPageWrapper entityName="partner">
    <AppPageWrapper entityName="partner" location={location.pathname}>
      <Partner {...props} />
    </AppPageWrapper>
  </UsersPageWrapper>
)

export default PartnerPageWrapper
