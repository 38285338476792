import gql from 'graphql-tag'

const GET_PARTNER = gql`
  query getPartnerBilling($id: String!) {
    getPartners(id: $id) {
      partners {
        id
        name
        companyLegalName
        contactName
        contactEmail
        contactAddress {
          id
          country
          firstLine
          city
          postcode
        }
        platform {
          id
          name
          stripeClientId
        }
        platformFee
        invoices {
          id
          invoiceNumber
          invoicePdf
          invoiceUrl
          dueDate
          subtotal
          amountDue
          amountRemaining
          status
        }
        billing {
          id
          livemode
          currency
          paymentMethods
          products {
            id
            name
            description
            service
            featured
            subscribed
            label
            icon
          }
          upcomingInvoice {
            periodStart
            periodEnd
            subtotal
            total
            tax
            items {
              description
              amount
            }
          }
          subscription {
            id
            active
            services
          }
        }
      }
    }
  }
`

export default GET_PARTNER
