import { Card } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import React, { Fragment } from 'react'
import ADD_LINK_CARD from '@components/Marketplace/LinkCards/mutations/addLinkCard.mutation'
import GET_LINK_CARDS from '@components/Marketplace/LinkCards/queries/getLinkCards.query'
import AddLinkCardForm from '@components/Marketplace/LinkCards/AddLinkCardForm'
import { useMutation } from '@apollo/client'

const AddLinkCardModal = ({
  modalOpen,
  modalClose,
  marketplaceId,
  onSuccess,
}) => {
  const [createRequest] = useMutation(ADD_LINK_CARD, {
    refetchQueries: [
      {
        query: GET_LINK_CARDS,
        variables: {
          marketplaceId,
        },
      },
    ],
    onCompleted: data => {
      onSuccess && onSuccess(data)
      modalClose()
    },
  })

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={() => {
        modalClose()
      }}
      title="Create Link Card"
      style={{
        width: 740,
      }}
    >
      <Fragment>
        <Card>
          <AddLinkCardForm
            marketplaceId={marketplaceId}
            createRequest={createRequest}
          />
        </Card>
      </Fragment>
    </Dialog>
  )
}

export default AddLinkCardModal
