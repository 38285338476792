import gql from 'graphql-tag'

const GET_CAMPAIGN_BY_ID = gql`
  query getCampaign($marketplaceId: ID!, $campaignId: String!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        faviconImage
        emailSenderUsername
        cname
        name
        allowSMSMarketing
        partner {
          id
          name
          marketingSMSFee
        }
      }
    }

    getCampaignById(marketplaceId: $marketplaceId, campaignId: $campaignId) {
      campaign {
        # TODO marketplace resolver
        id
        name
        type
        segment {
          id
          name
        }
        marketplaceId
        timeConfig
        status
        startDateTime
        emailChannel
        email {
          id
          templateType
          emailSubject
          emailPreviewText
          emailContent
        }
        pushChannel
        push {
          action
          outletId
          title
          body
          menuItemId
        }
        smsChannel
        sms {
          body
        }
      }
    }
  }
`

export default GET_CAMPAIGN_BY_ID
