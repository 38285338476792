import gql from 'graphql-tag'

const CLEAR_REDIS_CACHE = gql`
  mutation clearRedisCache {
    clearRedisCache {
      message
    }
  }
`
export default CLEAR_REDIS_CACHE
