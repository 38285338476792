import gql from 'graphql-tag'

const GET_PARTNER = gql`
  query getPartnerFinancials($id: String!) {
    getPartners(id: $id) {
      partners {
        id
        name
        companyLegalName
        contactName
        contactEmail
        contactAddress {
          id
          country
          firstLine
          city
          postcode
        }
        platform {
          id
          name
          stripeClientId
          payboxClientId
        }
        platformFee
        platformCharge
        partnerCommissionFee
        partnerCommissionCharge
        platformTableFee
        platformTableCharge
        partnerTableCommissionFee
        partnerTableCommissionCharge
        stripeId
        stripePublicId
        stripeLivemode
        orderHoldInterval
        vatNumber
        isVATregistered
        stripeFee
        stripeCharge
        stripePresentFee
        stripePresentCharge
        stripeConnectCharge
        stripeDebitFee
        stripeInstantPayoutFee
        stripePayoutFee
        stripePayoutCharge
        stripeChargebackCharge
        stripeEnterpriseId
        stripeEnterpriseAllowed
        billingAllowed
        billingProductMarketingId
        billingProductSMSId
        billingProductOutletId
        billingProductChargebackId
        billingProductAPIId
        billingProductWayboxId
        marketingSMSFee
        stripeConnect {
          payoutsEnabled
          accountType
          balancePending
          balancePending
          balanceCurrency
          dashboardLink
          enterprise
        }
      }
    }
  }
`

export default GET_PARTNER
