import gql from 'graphql-tag'

const EDIT_PARTNER_FINANCIALS = gql`
  mutation editPartnerFinancials(
    $id: ID!
    $stripeId: String
    $orderHoldInterval: Int
    $platformFee: Float
    $platformCharge: Int
    $partnerCommissionFee: Float
    $partnerCommissionCharge: Int
    $platformTableFee: Float
    $platformTableCharge: Int
    $partnerTableCommissionFee: Float
    $partnerTableCommissionCharge: Int
    $vatNumber: String
    $isVATregistered: Boolean
    $stripeFee: Float
    $stripeCharge: Int
    $stripePresentFee: Float
    $stripePresentCharge: Int
    $stripeConnectCharge: Int
    $stripeDebitFee: Float
    $stripeInstantPayoutFee: Float
    $stripePayoutFee: Float
    $stripePayoutCharge: Int
    $stripeChargebackCharge: Int
    $stripeEnterpriseAllowed: Boolean
    $marketingSMSFee: Float
    $billingAllowed: Boolean
    $billingProductMarketingId: String
    $billingProductSMSId: String
    $billingProductOutletId: String
    $billingProductChargebackId: String
    $billingProductAPIId: String
    $billingProductWayboxId: String
  ) {
    editPartner(
      id: $id
      stripeId: $stripeId
      orderHoldInterval: $orderHoldInterval
      platformFee: $platformFee
      platformCharge: $platformCharge
      partnerCommissionFee: $partnerCommissionFee
      partnerCommissionCharge: $partnerCommissionCharge
      platformTableFee: $platformTableFee
      platformTableCharge: $platformTableCharge
      partnerTableCommissionFee: $partnerTableCommissionFee
      partnerTableCommissionCharge: $partnerTableCommissionCharge
      vatNumber: $vatNumber
      isVATregistered: $isVATregistered
      stripeFee: $stripeFee
      stripeCharge: $stripeCharge
      stripePresentFee: $stripePresentFee
      stripePresentCharge: $stripePresentCharge
      stripeConnectCharge: $stripeConnectCharge
      stripeDebitFee: $stripeDebitFee
      stripeInstantPayoutFee: $stripeInstantPayoutFee
      stripePayoutFee: $stripePayoutFee
      stripePayoutCharge: $stripePayoutCharge
      stripeChargebackCharge: $stripeChargebackCharge
      stripeEnterpriseAllowed: $stripeEnterpriseAllowed
      marketingSMSFee: $marketingSMSFee
      billingAllowed: $billingAllowed
      billingProductMarketingId: $billingProductMarketingId
      billingProductSMSId: $billingProductSMSId
      billingProductOutletId: $billingProductOutletId
      billingProductChargebackId: $billingProductChargebackId
      billingProductAPIId: $billingProductAPIId
      billingProductWayboxId: $billingProductWayboxId
    ) {
      message
      partner {
        id
        name
        stripeId
        orderHoldInterval
        platform {
          id
          name
          stripeClientId
        }
        platformFee
        platformCharge
        partnerCommissionFee
        partnerCommissionCharge
        platformTableFee
        platformTableCharge
        partnerTableCommissionFee
        partnerTableCommissionCharge
        vatNumber
        isVATregistered
        stripeFee
        stripeCharge
        stripePresentFee
        stripePresentCharge
        stripeConnectCharge
        stripeDebitFee
        stripeInstantPayoutFee
        stripePayoutFee
        stripePayoutCharge
        stripeChargebackCharge
        stripeEnterpriseAllowed
        marketingSMSFee
        billingAllowed
        billingProductMarketingId
        billingProductSMSId
        billingProductOutletId
        billingProductChargebackId
        billingProductAPIId
        billingProductWayboxId
      }
    }
  }
`

export default EDIT_PARTNER_FINANCIALS
