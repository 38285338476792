const fields = {
  id: {
    id: 'id',
    label: 'ID',
    type: 'text',
    visible: false,
    export: true,
    width: 100,
    drawer: false,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'text',
    visible: true,
    export: true,
    width: 300,
    drawer: true,
  },
  fulfillmentMethods: {
    id: 'fulfillmentMethods',
    label: 'Fulfillment Methods',
    type: 'selectMultiple',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
    defaultValue: 'COLLECTION|DELIVERY|TABLE',
  },
  addOnItemsMenu: {
    id: 'addOnItemsMenu',
    label: 'Add on items menu',
    type: 'bool',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
    defaultValue: false,
  },
}
const getHeader = () => Object.keys(fields)

const menuItemGroupsToCSV = menuGroups => {
  const columns = getHeader()
  const columnHeadingsAsCSV = columns.join(',')

  const nonNullBooleanToString = value =>
    value !== null ? value.toString() : 'false'

  const menuItemsAsCSV = menuGroups.map(menuItemGroup => {
    const formatedMenuItemGroup = {
      id: `${menuItemGroup.id || ''}`,
      name: `"${menuItemGroup.name}"`,
      description: `"${menuItemGroup.description || ''}"`,
      fulfillmentMethods: `${menuItemGroup.fulfillmentMethods.join('|')}`,
      addOnItemsMenu: nonNullBooleanToString(menuItemGroup.addOnItemsMenu),
    }
    return columns.map(column => formatedMenuItemGroup[column]).join(',')
  })

  return [[columnHeadingsAsCSV, ...menuItemsAsCSV].join('\n')]
}

export const MenuGroupDefinition = {
  fields,
  getHeader,
  menuItemGroupsToCSV,
}
