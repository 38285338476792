import { penceToPounds } from '@utils/helpers'

const fields = {
  id: {
    id: 'id',
    label: 'ID',
    type: 'text',
    visible: false,
    export: true,
    width: 100,
    drawer: false,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  price: {
    id: 'price',
    label: 'Price',
    type: 'currency',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
    defaultValue: 0,
  },
  costPrice: {
    id: 'costPrice',
    label: 'Cost Price',
    type: 'currency',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
  },
  minPrice: {
    id: 'minPrice',
    label: 'Minimum Price',
    type: 'currency',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
  },
  menuId: {
    id: 'menuId',
    label: 'Menu',
    type: 'selectSingle',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  optionIds: {
    id: 'optionIds',
    label: 'Options',
    type: 'selectMultiple',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'text',
    visible: true,
    export: true,
    width: 300,
    drawer: true,
  },
  isVegan: {
    id: 'isVegan',
    label: 'Vegan',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isVegetarian: {
    id: 'isVegetarian',
    label: 'Vegetarian',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isGlutenFree: {
    id: 'isGlutenFree',
    label: 'Gluten Free',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isDairyFree: {
    id: 'isDairyFree',
    label: 'Dairy Free',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isAgeRestricted: {
    id: 'isAgeRestricted',
    label: 'Age Restricted',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  isKeto: {
    id: 'isKeto',
    label: 'Keto',
    type: 'bool',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: false,
  },
  spiceLevel: {
    id: 'spiceLevel',
    label: 'Spice Level',
    type: 'text',
    visible: true,
    export: true,
    width: 40,
    drawer: false,
    defaultValue: 0,
  },
  SKU: {
    id: 'SKU',
    label: 'SKU',
    type: 'text',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
  },
  UPC: {
    id: 'UPC',
    label: 'UPC',
    type: 'text',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
  },
}
const getHeader = () => Object.keys(fields)

const menuItemGroupsToCSV = menuGroups => {
  const columns = getHeader()
  const columnHeadingsAsCSV = columns.join(',')

  const escapeCSVField = field => {
    if (field == null) return ''

    const stringField = String(field)

    // seems unconventional but after a long process this regex formatts CSVs correctly
    return /["\n,]/.test(stringField)
      ? `"${stringField.replace(/"/g, '""')}"`
      : stringField
  }

  const menuItemsAsCSV = menuGroups.flatMap(menuItemGroup =>
    menuItemGroup.menuItems.flatMap(menuItem => {
      const formatedMenuItem = {
        id: menuItem.id || '',
        SKU: menuItem.SKU || '',
        UPC: menuItem.UPC || '',
        name: escapeCSVField(menuItem.name),
        price: penceToPounds(menuItem.price || 0),
        costPrice: penceToPounds(menuItem.costPrice || 0),
        minPrice: penceToPounds(menuItem.minimumPurchasePrice || 0),
        isVegan: menuItem.isVegan.toString() || 'false',
        isVegetarian: menuItem.isVegetarian.toString() || 'false',
        isGlutenFree: menuItem.isGlutenFree.toString() || 'false',
        isDairyFree: menuItem.isDairyFree.toString() || 'false',
        isAgeRestricted: menuItem.ageRestricted.toString() || 'false',
        isKeto: menuItem.isKeto.toString() || 'false',
        spiceLevel: menuItem.spiceLevel || 0,
        description: escapeCSVField(menuItem.description || ''),
        menuId: menuItemGroup.id || '',
        optionIds: menuItem.options.map(option => option.id).join('|') || '',
      }
      return columns.map(column => formatedMenuItem[column]).join(',')
    })
  )

  return [[columnHeadingsAsCSV, ...menuItemsAsCSV].join('\n')]
}

export const MenuDefinition = {
  fields,
  getHeader,
  menuItemGroupsToCSV,
}
