import { Fragment } from 'react'
import { Card, Button, Switch } from '@blueprintjs/core'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { isAtLeastMarketplaceUser } from '@stores/userStore'
import EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS from './mutations/editOutletFeatureOrPromotionStatus.mutation'
import { useMutation } from '@apollo/client'

const Promote = ({ outlet }) => {
  if (Boolean(outlet) && !isAtLeastMarketplaceUser()) {
    return null
  }

  const [editPromotionStatus] = useMutation(
    EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS,
    {
      onError: defaultErrorHandler,
      onCompleted: () => successToast('Successfully edited outlet promotion'),
    }
  )

  return (
    <Card>
      <h5 className="bp5-heading">Promote</h5>

      <Formik
        initialValues={{
          featured: outlet.featured,
          promote: outlet.promote || false,
        }}
        onSubmit={values =>
          editPromotionStatus({
            variables: {
              outletId: outlet.id,
              feature: values.featured,
              promote: values.promote,
            },
          })
        }
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Switch
              checked={values.featured}
              onChange={handleChange}
              name="featured"
            >
              Feature on Homepage
              <br />
              <span className={'bp5-text-small bp5-text-muted'}>
                3 outlets chosen at random from featured
              </span>
            </Switch>

            {outlet.restaurant.outletPromoteOverride && (
              <Fragment>
                <br />
                <Switch
                  checked={values.promote}
                  onChange={handleChange}
                  name="promote"
                >
                  Promotion Override
                  <br />
                  <span className={'bp5-text-small bp5-text-muted'}>
                    Move the outlet to the top of the outlet list within their
                    opening status section
                  </span>
                </Switch>
              </Fragment>
            )}
            <br />
            <div className="bp-card-footer-actions">
              <Button text="Save" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default Promote
