import React, { Fragment } from 'react'
import { func, object } from 'prop-types'
import {
  Button,
  Intent,
  FormGroup,
  Switch,
  H5,
  Divider,
  AnchorButton,
} from '@blueprintjs/core'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import { Row, Col } from '@components/_FlexGrid'

import { canPerformAction, isAtLeastMarketplaceUser } from '@stores/userStore'

const editableField = () => {
  return !canPerformAction('editMarketplace')
}

const Form = ({ handleSubmit, errors, values, setFieldValue }) => (
  <form onSubmit={handleSubmit}>
    <Fragment>
      <H5>Marketplace Overrides</H5>
      <p>Set custom charges and fees for this business.</p>

      <Row>
        <Col span={12}>
          <FormGroup
            helperText={
              'Percentage of total basket charged to business as commission.'
            }
          >
            <Switch
              checked={
                values.partnerFee !== null || values.partnerTableFee !== null
              }
              label="Basket Fees"
              onChange={event => {
                setFieldValue('partnerFee', event.target.checked ? 0 : null)
                setFieldValue(
                  'partnerTableFee',
                  event.target.checked ? 0 : null
                )
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {(values.partnerFee !== null || values.partnerTableFee !== null) && (
        <Fragment>
          <Row gutter={24}>
            <Col span={6}>
              <FormGroup
                label="Delivery / Collection"
                labelFor="partnerFee"
                helperText={errors.partnerFee ? errors.partnerFee : null}
                intent={errors.partnerFee ? Intent.DANGER : Intent.NONE}
              >
                <PercentageInput
                  id="partnerFee"
                  style={{ marginLeft: '26px' }}
                  value={values.partnerFee || 0}
                  handleChange={values =>
                    setFieldValue('partnerFee', values.floatValue)
                  }
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                label="Table"
                labelFor="partnerTableFee"
                helperText={
                  errors.partnerTableFee ? errors.partnerTableFee : null
                }
                intent={errors.partnerTableFee ? Intent.DANGER : Intent.NONE}
              >
                <PercentageInput
                  id="partnerTableFee"
                  style={{ marginLeft: '26px' }}
                  value={values.partnerTableFee || 0}
                  handleChange={values =>
                    setFieldValue('partnerTableFee', values.floatValue)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Divider />
        </Fragment>
      )}
      <Row>
        <Col span={12}>
          <FormGroup
            helperText={
              'Additional fixed charge made to the business, taken from payout.'
            }
          >
            <Switch
              checked={
                values.merchantCommissionCharge !== null ||
                values.merchantTableCommissionCharge !== null
              }
              label="Business Charge"
              onChange={event => {
                setFieldValue(
                  'merchantCommissionCharge',
                  event.target.checked ? 0 : null
                )
                setFieldValue(
                  'merchantTableCommissionCharge',
                  event.target.checked ? 0 : null
                )
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {(values.merchantCommissionCharge !== null ||
        values.merchantTableCommissionCharge !== null) && (
        <Fragment>
          <Row gutter={24}>
            <Col span={6}>
              <FormGroup
                label="Delivery / Collection"
                labelFor="merchantCommissionCharge"
                helperText={
                  errors.merchantCommissionCharge
                    ? errors.merchantCommissionCharge
                    : null
                }
              >
                <CurrencyInput
                  name="merchantCommissionCharge"
                  id="merchantCommissionCharge"
                  defaultValue={values.merchantCommissionCharge}
                  full={true}
                  intent={
                    errors.merchantCommissionCharge
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                  onUpdate={amount => {
                    setFieldValue('merchantCommissionCharge', amount)
                  }}
                  disabled={editableField()}
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                label="Table"
                labelFor="merchantTableCommissionCharge"
                helperText={
                  errors.merchantTableCommissionCharge
                    ? errors.merchantTableCommissionCharge
                    : null
                }
              >
                <CurrencyInput
                  name="merchantTableCommissionCharge"
                  id="merchantTableCommissionCharge"
                  defaultValue={values.merchantTableCommissionCharge}
                  full={true}
                  intent={
                    errors.merchantTableCommissionCharge
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                  onUpdate={amount => {
                    setFieldValue('merchantTableCommissionCharge', amount)
                  }}
                  disabled={editableField()}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
        </Fragment>
      )}
      <Row>
        <Col span={12}>
          <FormGroup
            helperText={
              'Additional fixed charge made to the customer, added to order total.'
            }
          >
            <Switch
              checked={
                values.partnerCharge !== null ||
                values.partnerTableCharge !== null
              }
              label="Customer Service Charge"
              onChange={event => {
                setFieldValue('partnerCharge', event.target.checked ? 0 : null)
                setFieldValue(
                  'partnerTableCharge',
                  event.target.checked ? 0 : null
                )
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {(values.partnerCharge !== null ||
        values.partnerTableCharge !== null) && (
        <Fragment>
          <Row gutter={24}>
            <Col span={6}>
              <FormGroup
                label="Delivery / Collection"
                labelFor="partnerCharge"
                helperText={errors.partnerCharge ? errors.partnerCharge : null}
              >
                <CurrencyInput
                  name="partnerCharge"
                  id="partnerCharge"
                  defaultValue={values.partnerCharge || 0}
                  full={true}
                  intent={errors.partnerCharge ? Intent.DANGER : Intent.NONE}
                  onUpdate={amount => {
                    setFieldValue('partnerCharge', amount)
                  }}
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                label="Table"
                labelFor="partnerTableCharge"
                helperText={errors.partnerCharge ? errors.partnerCharge : null}
              >
                <CurrencyInput
                  name="partnerTableCharge"
                  id="partnerTableCharge"
                  defaultValue={values.partnerTableCharge || 0}
                  full={true}
                  intent={
                    errors.partnerTableCharge ? Intent.DANGER : Intent.NONE
                  }
                  onUpdate={amount => {
                    setFieldValue('partnerTableCharge', amount)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Fragment>
      )}

      <br />
      <div className="bp-card-footer-actions">
        <Button text="Save" type="submit" disabled={editableField()} />
        {isAtLeastMarketplaceUser() && (
          <AnchorButton
            text="Fees & Charges"
            href="https://support.redbox.systems/docs/accounting-fees-and-charges-paybox-payouts"
            target="_blank"
            rel="noopener noreferrer"
            rightIcon="help"
            intent={Intent.PRIMARY}
            minimal
          />
        )}
      </div>
    </Fragment>
  </form>
)

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
}

export default Form
