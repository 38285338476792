import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'
import {
  HTMLTable,
  Tag,
  Popover,
  Position,
  PopoverInteractionKind,
  Icon,
  Intent,
  Callout,
} from '@blueprintjs/core'
import { formatOpenTimes } from '../../utils/helpers'
import { array, string, bool } from 'prop-types'
import OnlineStatusFlower from '../OnlineStatusFlower/OnlineStatusFlower'

const OpenStatus = ({
  openingTimes,
  restaurantId,
  outletId,
  restaurantIsActive,
  outletIsOpen,
  outletIsActive,
  outletIsOnline,
  outletIsOnlineOverride,
  large = false,
  minimal = true,
  round = false,
}) => {
  const isActive = restaurantIsActive && outletIsActive

  return openingTimes ? (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.RIGHT_BOTTOM}
      content={
        <Fragment>
          <HTMLTable>
            <thead>
              <tr>
                <th>Ordering Times</th>
                <td>
                  <Link
                    style={{ float: 'right' }}
                    to={`/business/${restaurantId}/outlets/${outletId}/opening-times`}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            </thead>
            <tbody>
              {openingTimes &&
                formatOpenTimes(openingTimes).map((bracket, i) => (
                  <tr key={i}>
                    <td>{bracket.day}</td>
                    <td>{bracket.timeBracket}</td>
                  </tr>
                ))}
            </tbody>
          </HTMLTable>

          {outletIsOnlineOverride === true && (
            <Callout icon="flash" intent={Intent.SUCCESS}>
              Online Override
            </Callout>
          )}

          {outletIsOnlineOverride === false && (
            <Callout icon="flash" intent={Intent.NONE}>
              'Offline Override'
            </Callout>
          )}

          {!restaurantIsActive && (
            <Callout icon="ban-circle" intent={Intent.DANGER}>
              Business is Deactivated.
            </Callout>
          )}

          {!outletIsActive && (
            <Callout icon="ban-circle" intent={Intent.DANGER}>
              Outlet is Deactivated.
            </Callout>
          )}

          {outletIsOpen && outletIsOnline ? null : (
            <Callout icon="warning-sign" intent={Intent.WARNING}>
              Terminal may be offline causing this outlet to remain closed.
            </Callout>
          )}
        </Fragment>
      }
    >
      {outletIsOpen ? (
        <Fragment>
          <Tag
            round={round}
            large={large}
            minimal={
              !isActive
                ? minimal
                : outletIsOpen && outletIsOnline
                  ? minimal
                  : false
            }
            intent={
              !isActive
                ? null
                : outletIsOpen && outletIsOnline
                  ? 'success'
                  : 'danger'
            }
            rightIcon={
              outletIsOnlineOverride ? (
                <OnlineStatusFlower isOnlineOverride={outletIsOnlineOverride} />
              ) : null
            }
          >
            {outletIsOpen && outletIsOnline ? (
              <Fragment>
                <span className={!isActive && 'bp5-text-disabled'}>OPEN</span>
              </Fragment>
            ) : (
              <Fragment>
                {isActive && <Icon icon={'warning-sign'} />}{' '}
                <span className={!isActive && 'bp5-text-disabled'}>OPEN</span>
              </Fragment>
            )}
          </Tag>
        </Fragment>
      ) : (
        <Tag round={round} large={large} minimal={minimal}>
          <span className={!isActive && 'bp5-text-disabled'}>CLOSED</span>
        </Tag>
      )}
    </Popover>
  ) : (
    <Link to={`/business/${restaurantId}/outlets/${outletId}/opening-times`}>
      <Icon icon="small-plus" /> Add
    </Link>
  )
}

OpenStatus.propTypes = {
  openingTimes: array,
  restaurantId: string,
  restaurantIsActive: bool,
  outletId: string,
  outletIsActive: bool,
  outletIsOpen: bool,
  outletIsOnline: bool,
  outletIsOnlineOverride: bool,
}

export default OpenStatus
