import gql from 'graphql-tag'

const GET_OUTLETS_BY_IDS = gql`
  query getOutletsByIdsWithMarketplace($ids: [String!]!) {
    getOutletsByIds(ids: $ids) {
      outlets {
        id
        name
        marketplace {
          id
          name
          allowMultiOutletTerminal
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLETS_BY_IDS
