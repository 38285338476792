import gql from 'graphql-tag'

const GET_TRANSACTIONS = gql`
  query getTransactions(
    $afterDate: DateTime
    $beforeDate: DateTime
    $skip: Int
    $first: Int
    $last: Int
    $marketplaceIds: [ID!]
  ) {
    getTransactions(
      skip: $skip
      first: $first
      last: $last
      where: {
        createdAt_gte: $afterDate
        createdAt_lte: $beforeDate
        marketplace_id_in: $marketplaceIds
      }
    ) {
      totalCount
      count
      transactions {
        id
        createdAt
        acceptedAt
        refundedAt
        partner {
          id
          name
        }
        marketplace {
          id
          name
        }
        order {
          id
          orderNumber
        }
        customer {
          id
          firstName
          lastName
        }
        outlet {
          id
          name
          restaurant {
            id
            name
          }
        }
        orderStatus
        transactionStripeId
        restaurantDeliveryCharge
        nonNetworkFulfilmentChargeGross
        nonNetworkFulfilmentChargeNet
        nonNetworkFulfilmentChargeVAT
        merchantCommissionCharge
        merchantCommissionChargeWithVat
        partnerCommissionCharge
        fulfillmentType
        paymentType
        orderNetTotal
        orderGrossTotal
        addOnItemsNetTotal
        addOnItemsGrossTotal
        subtotalMinusNetworkDeliveryMarketplaceCharge
        customerDeliveryCharge
        marketplaceDeliverySubsidy
        merchantDeliverySubsidy
        totalOrderValue
        discountAmount
        totalToPlatform
        totalToOutlet
        totalRefund
        totalToMarketplace
        totalToDeliveryNetwork
        platformFeeNet
        platformFee
        deliveryChargeRecipient
        merchantNetworkDeliveryAllocation
        marketplaceNetworkDeliveryAllocation
        marketplaceChargeVATPaidByMerchant
        marketplaceChargeVATPayer
        subtotalNetAfterDiscount
        subtotalGrossAfterDiscount
        subtotalVATAfterDiscount
      }
    }
  }
`

export default GET_TRANSACTIONS
