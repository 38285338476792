import gql from 'graphql-tag'

const GET_OUTLET = gql`
  query getOutletForDetailsTab($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        otterStoreId
        name
        companyType
        companyLegalName
        companyNumber
        taxReference
        companyType
        featured
        enableHygieneRating
        hygieneRatingId
        contactName
        isOnlineOverride
        isAutoAccept
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
          geo
        }
        contactPhone
        contactEmail
        contactDoB
        outletAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
          geo
        }
        restaurant {
          id
          name
          active
          outletOfferFlagOverride
          outletPromoteOverride
          outletCategoriesOverride
          cuisines {
            id
            name
          }
        }
        outletPhone
        outletEmail
        escalationMobile
        activationDate
        closeDate
        stripeId
        active
        marketplace {
          id
          name
          cname
          invoiceEnabled
          stripeDirectPayment
          enablePayboxEnterprise
          enableCustomerV2Client
          orderMode
          urlPath
          allowPendingOrderAlert
        }
        vatNumber
        isVATregistered
        description
        emailOrderReceipt
        emailInvoices
        bankAccount
        bankSort
        coverImage
        outletLogoOverride
        asapAllowed
        deliveryZoneCosts {
          id
          deliveryZone {
            id
            name
          }
        }
        menuItemsGroups {
          id
        }
        deliveryDeal
        collectionDeal
        tableDeal
        promote
        outletCategories {
          category {
            id
            name
            emoji
            imageSrc
          }
        }
      }
    }
  }
`

export default GET_OUTLET
