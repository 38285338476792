import gql from 'graphql-tag'

const GET_MARKETING_METRICS = gql`
  query getMarketingMetrics(
    $marketplaceId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        billingStatus {
          id
          active
          services
        }
      }
    }
    getMarketingMetrics(
      marketplaceId: $marketplaceId
      startDate: $startDate
      endDate: $endDate
    ) {
      metrics {
        allEndpoints {
          ALL
          EMAIL
          PUSH
          SMS
        }
        activeUsers {
          date
          value
        }
        newUsers {
          date
          value
        }
        monthlyActiveUsers {
          date
          value
        }
        retentionRate {
          date
          value
        }
        emailMetrics {
          date
          sent
          delivered
          opened
          clicked
        }
        segmentsCount
        campaignsCount
      }
    }
  }
`

export default GET_MARKETING_METRICS
