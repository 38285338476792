import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@blueprintjs/core'
import { SESv2Client, GetEmailIdentityCommand } from '@aws-sdk/client-sesv2'

const SESSendCommand = ({
  children,
  emailIdentity,
  credentials = {},
  handleLoading = false,
  onCompleted = () => { },
  onError = () => { },
}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(!data)
  const [error, setError] = useState(false)

  const getEmailIdentity = async (credentials, emailIdentity) => {
    setError(false)
    setLoading(true)
    const sesv2 = new SESv2Client({
      region: process.env.AWS_DEFAULT_REGION,
      credentials,
    })
    try {
      const params = {
        EmailIdentity: emailIdentity,
      }
      const command = new GetEmailIdentityCommand(params)
      const data = await sesv2.send(command)
      setData(data)
      onCompleted()
    } catch (e) {
      onError()
      setError(e)
      setData(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    getEmailIdentity(credentials, emailIdentity)
  }, [credentials, emailIdentity])

  if (handleLoading && loading) {
    return (
      <div style={{ position: 'relative', height: '100%', minHeight: '6rem' }}>
        <Spinner show={true} />
      </div>
    )
  }

  return children({ loading, error, data })
}

SESSendCommand.propTypes = {
  children: PropTypes.func.isRequired,
  variables: PropTypes.object,
  emailIdentity: PropTypes.string,
  credentials: PropTypes.object,
  handleLoading: PropTypes.bool,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
}

export default SESSendCommand
