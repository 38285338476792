import gql from 'graphql-tag'

const GET_TRANSACTIONS_ADVANCED = gql`
  query getTransactionsAdvanced(
    $afterDate: DateTime
    $beforeDate: DateTime
    $skip: Int
    $first: Int
    $last: Int
    $partnerIds: [ID!]
  ) {
    getTransactions(
      skip: $skip
      first: $first
      last: $last
      where: {
        createdAt_gte: $afterDate
        createdAt_lte: $beforeDate
        partner_id_in: $partnerIds
      }
    ) {
      totalCount
      count
      transactions {
        id
        createdAt
        acceptedAt
        refundedAt
        partner {
          id
          name
        }
        marketplace {
          id
          name
        }
        order {
          id
          orderNumber
        }
        customer {
          id
          firstName
          lastName
        }
        outlet {
          id
          name
          restaurant {
            id
            name
          }
        }
        orderStatus
        fulfillmentType
        paymentType
        transactionType
        customerStripeId
        transactionStripeId
        orderNetTotal
        orderGrossTotal
        addOnItemsNetTotal
        addOnItemsGrossTotal
        restaurantDeliveryCharge
        nonNetworkFulfilmentChargeGross
        nonNetworkFulfilmentChargeNet
        nonNetworkFulfilmentChargeVAT
        merchantCommissionCharge
        merchantCommissionChargeWithVat
        partnerCommissionCharge
        networkDeliveryCharge
        marketplaceServiceCharge
        customerTaxes
        subtotalMinusMarketplaceCharge
        subtotalMinusNetworkDeliveryCharge
        subtotalMinusNetworkDeliveryMarketplaceCharge
        totalOrderValue
        totalPaidCash
        marketplaceFeePercentage
        marketplaceFeeNet
        marketplaceFee
        platformCardProcessingFee
        platformCardProcessingCharge
        platformFeePercentageWithVat
        platformFeeNet
        platformFee
        partnerFeePercentageWithVat
        partnerFeeNet
        partnerFee
        redboxCardProcessingCostFee
        redboxCardProcessingCostCharge
        redboxFeePercentageWithVat
        redboxFeeNet
        redboxFee
        marketplaceChargeNet
        marketplaceChargeGross
        marketplaceChargeFromMerchantNet
        marketplaceChargeFromMerchantGross
        totalMarketplaceCharge
        redboxCharge
        redboxChargeNet
        totalToPlatform
        totalPaidToPlatform
        totalToRedbox
        totalPaidToRedbox
        totalToPaybox
        totalPaidToPaybox
        totalToPartner
        totalPaidToPartner
        totalToMarketplace
        totalToMarketplaceAfterFees
        totalPaidToMarketplace
        marketplaceTransferStripeId
        totalToOutlet
        totalToOutletPreRefund
        totalPaidToOutlet
        outletTransferStripeId
        totalToDeliveryNetwork
        totalRefund
        discountAmount
        discount
        customerDeliveryCharge
        marketplaceDeliverySubsidy
        merchantDeliverySubsidy
        deliveryChargeRecipient
        merchantNetworkDeliveryAllocation
        marketplaceNetworkDeliveryAllocation
        marketplaceChargeVATPaidByMerchant
        marketplaceChargeVATPaidByCustomer
        marketplaceChargeVATPayer
        subtotalNetAfterDiscount
        subtotalGrossAfterDiscount
        subtotalVATAfterDiscount
        merchantAndMarketplaceImprecision
        partnerAndRedboxImprecision
      }
    }
  }
`

export default GET_TRANSACTIONS_ADVANCED
