import gql from 'graphql-tag'

const GET_ITEM_DETAILS = gql`
  query getItemDetails($id: String!, $menuId: String!) {
    getMenuItems(id: $id) {
      menuItems {
        id
        name
        thumbnail {
          id
          image {
            id
            src
          }
        }
        images {
          id
          caption
          position
          src
        }
        description
        price
        minimumPurchasePrice
        maxPurchaseQuantity
        costPrice
        vatRate
        VATinclusive
        isVegan
        isVegetarian
        isDairyFree
        isKeto
        isGlutenFree
        spiceLevel
        ageRestricted
        bulkyItem
        SKU
        UPC
        restaurant {
          id
          isVATregistered
        }
        optionPositions
      }
    }

    getMenuItemsGroups(id: $menuId) {
      menuItemsGroups {
        id
        addOnItemsMenu
      }
    }
  }
`

export const GET_ITEM_OPTIONS = gql`
  query getItemOptions($id: String!) {
    getMenuItems(id: $id) {
      menuItems {
        id
        optionPositions
        options {
          id
          name
          minOptions
          optionItems {
            id
            name
            price
            soldOut
            position
          }
        }
      }
    }
  }
`

export default GET_ITEM_DETAILS
