export const MapDisplayBy = {
  byNumberOfOrders: 'Total Orders',
  byValueOfOrders: 'Orders by Total Value',
  byAverageValueOfOrders: 'Orders by Average Value',
  byDiscountedValueOfOrders: 'Orders by Discount',
  byOrderStatus: 'Orders by Status',
}

export const PaymentMethodsMap = {
  CASH_ON_DELIVERY: 'Cash on Delivery',
  CARD_ON_DELIVERY: 'Card on Delivery',
  CASH_ON_COLLECTION: 'Cash on Collection',
  CARD_ON_COLLECTION: 'Card on Collection',
  CARD_ON_TABLE: 'Card on Table',
}

export const DataKeyMap = {
  subTotalWithNoDiscounts: 'Totals With No Discounts',
  subTotalWithRegularDiscounts: 'Totals With Regular Discounts',
  subTotalWithMemberDiscounts: 'Totals With Member Discounts',
  subTotalWithVoucherDiscounts: 'Totals With Voucher Discounts',
  numberOfOrdersWithNoDiscount: 'Orders With No Discounts',
  numberOfOrdersWithRegularDiscount: 'Orders With Regular Discounts',
  numberOfOrdersWithMemberDiscount: 'Orders With Member Discounts',
  numberOfOrdersWithVoucherDiscount: 'Orders With Voucher Discounts',
  dailyAverage: 'Average Daily Order Value',
  intervalOrderValueAverage: 'Average Order Value',
  subTotalDiscountedRegularDiscounts: 'Discounted Value for Regular Discounts',
  subTotalDiscountedMemberDiscounts: 'Discounted Value for Member Discounts',
  subTotalDiscountedVoucherDiscounts: 'Discounted Value for Voucher Discounts',
  completedOrders: 'Completed',
  rejectedOrders: 'Rejected',
  cancelledOrders: 'Cancelled',
  numberOfOutlets: 'Number Of Outlets',
  numberOfCustomers: 'Number Of Customers',
  'paymentMethods.CASH_ON_DELIVERY': 'Cash on Delivery',
  'paymentMethods.CARD_ON_DELIVERY': 'Card on Delivery',
  'paymentMethods.CASH_ON_COLLECTION': 'Cash on Collection',
  'paymentMethods.CARD_ON_COLLECTION': 'Card on Collection',
  'paymentMethods.CARD_ON_TABLE': 'Card on Table',
  'fulfillmentMethods.COLLECTION': 'Collection',
  'fulfillmentMethods.DELIVERY': 'Delivery',
  'fulfillmentMethods.NETWORK': 'Network',
  'fulfillmentMethods.TABLE': 'Table',
  'fulfillmentMethods.COURIER': 'Courier',
}

export const ResolutionMap = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
}

export const statusAttributeMap = {
  PENDING: 'PENDING',
  AUTH_ACTION_PENDING: 'PENDING',
  PREPARING: 'IN PROGRESS',
  READY: 'IN PROGRESS',
  AWAITING_DELIVERY_NETWORK: 'IN PROGRESS',
  COMPLETE: 'COMPLETE',
  REJECTED: 'REJECTED',
  DELIVERY_NETWORK_FAILURE: 'REJECTED',
  DELIVERY_FAILURE_REFUND: 'REFUNDED',
  ORDER_FAILURE_REFUND: 'REFUNDED',
  REFUND_REQUESTED: 'REFUNDED',
  REFUND_CANCELLED: 'REFUNDED',
  REFUND_FAILED: 'REFUNDED',
  REFUND_RELEASED: 'REFUNDED',
  AUTH_ACTION_FAILURE: 'UNAUTHORIZED',
}
