import gql from 'graphql-tag'

const EDIT_MENU_ITEMS_GROUP = gql`
  mutation editMenuItemsGroup(
    $id: String!
    $name: String
    $description: String
    $image: String
    $fulfillmentMethods: [MenuItemGroupFulfillmentMethod!]!
    $availabilityTimes: Json
    $availabilityStartDate: DateTime
    $availabilityEndDate: DateTime
    $showMenuThumbnails: Boolean
    $addOnItemsMenu: Boolean
  ) {
    editMenuItemsGroup(
      id: $id
      name: $name
      description: $description
      image: $image
      fulfillmentMethods: $fulfillmentMethods
      availabilityTimes: $availabilityTimes
      availabilityStartDate: $availabilityStartDate
      availabilityEndDate: $availabilityEndDate
      showMenuThumbnails: $showMenuThumbnails
      addOnItemsMenu: $addOnItemsMenu
    ) {
      message
      menuItemsGroup {
        id
        name
        description
        image
        fulfillmentMethods
        availabilityStartDate
        availabilityEndDate
        availabilityTimes
        showMenuThumbnails
        addOnItemsMenu
      }
    }
  }
`

export default EDIT_MENU_ITEMS_GROUP
