import gql from 'graphql-tag'

const UPDATE_DELIVERY_WINDOWS_LIMIT = gql`
  mutation updateDeliveryWindowsLimit($ids: [String!], $limit: Int) {
    updateDeliveryWindowsLimit(ids: $ids, limit: $limit) {
      message
    }
  }
`

export default UPDATE_DELIVERY_WINDOWS_LIMIT
