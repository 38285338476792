import {
  Button,
  Card,
  Classes,
  H5,
  HTMLTable,
  Icon,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip,
  Intent,
} from '@blueprintjs/core'
import { object } from 'prop-types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import OpenStatus from '@components/OpenStatus/OpenStatus'
import GET_OUTLETS from './queries/getOutlets.query'
import { matchType } from '@utils/types'
import OutletLink from '@components/OpenStatus/OutletLink'
import Query from '@components/Query/Query'
import { isAtLeastBusinessUser } from '@stores/userStore'
import { useModal } from '@src/providers/ModalProvider'
import colors from '@styles/colors'
import { useParams } from 'react-router-dom'
import ConnectStatus from '@components/ConnectStatus/ConnectStatus'
import OutletModal from '@src/components/OutletModal/Outlet.modal'

const Outlets = () => {
  const optionModal = useModal('optionModal')
  const { restaurants } = useParams()
  return (
    <Card className="bp5-nopad bp5-scrollable">
      <Fragment>
        <Query
          query={GET_OUTLETS}
          variables={{ restaurantId: restaurants }}
          loaderTitle={'Loading Outlets'}
        >
          {({ getOutlets }) => {
            const { outlets = [] } = getOutlets

            if (outlets.length) {
              return (
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width={20}>Ordering</th>
                      <th>Contact</th>
                      <th>Business</th>
                      <th>Delivery</th>
                      <th>Collection</th>
                      {isAtLeastBusinessUser() && (
                        <Fragment>
                          <th>Terminals</th>
                          <th>Zones</th>
                          <th>Payments</th>
                        </Fragment>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {getOutlets.outlets.map(outlet => {
                      return (
                        <tr key={outlet.id}>
                          <td>
                            <OutletLink outlet={outlet} />
                          </td>
                          <td width={20}>
                            <OpenStatus
                              openingTimes={outlet.openingTimes}
                              restaurantId={outlet.restaurant.id}
                              restaurantIsActive={outlet.restaurant.active}
                              outletIsActive={outlet.active}
                              outletId={outlet.id}
                              outletIsOpen={outlet.isOpen}
                              outletIsOnline={outlet.isOnline}
                              outletIsOnlineOverride={outlet.isOnlineOverride}
                            />
                          </td>
                          <td>
                            <Popover
                              interactionKind={PopoverInteractionKind.HOVER}
                              position={Position.RIGHT_BOTTOM}
                              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                              content={
                                <Fragment>
                                  <H5>{outlet.contactName}</H5>
                                  <p>
                                    {outlet.contactPhone}
                                    <br />
                                    {outlet.contactEmail && (
                                      <a href={`mailto:${outlet.contactEmail}`}>
                                        {outlet.contactEmail}
                                      </a>
                                    )}
                                  </p>
                                  <p />
                                </Fragment>
                              }
                              className={Classes.TOOLTIP_INDICATOR}
                            >
                              {outlet.contactName || <em>Unknown</em>}
                            </Popover>
                          </td>
                          <td>
                            <div className="bp5-text-overflow-ellipsis">
                              <Tooltip
                                content={
                                  outlet.restaurant.active ? (
                                    <strong>Active</strong>
                                  ) : (
                                    <Fragment>
                                      <strong>Deactivated Business</strong> -{' '}
                                      <em>Not visible to customers</em>
                                    </Fragment>
                                  )
                                }
                              >
                                <Icon
                                  icon="small-tick"
                                  color={
                                    outlet.restaurant.active
                                      ? colors.onlineGreen
                                      : colors.inactiveGray
                                  }
                                />
                              </Tooltip>
                              &nbsp;
                              <Link to={`/business/${outlet.restaurant.id}`}>
                                {outlet.restaurant.name}
                              </Link>
                            </div>
                          </td>
                          <td>
                            {outlet.defaultDeliveryTime}{' '}
                            <span className="bp5-text-muted bp5-text-small">
                              min
                            </span>
                          </td>
                          <td>
                            {outlet.defaultCollectionTime}{' '}
                            <span className="bp5-text-muted bp5-text-small">
                              min
                            </span>
                          </td>
                          {!isAtLeastBusinessUser() && (
                            <Fragment>
                              <td>
                                {outlet.terminals.length > 0 ? (
                                  <Link
                                    to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/terminals`}
                                  >
                                    {outlet.terminals.length}
                                  </Link>
                                ) : (
                                  <span className="bp5-text-muted">0</span>
                                )}
                              </td>
                              <td>
                                {outlet.deliveryZoneCosts.length > 0 ? (
                                  <Link
                                    to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`}
                                  >
                                    {outlet.deliveryZoneCosts.length}
                                  </Link>
                                ) : (
                                  <span className="bp5-text-muted">0</span>
                                )}
                              </td>
                              <td>
                                <ConnectStatus
                                  stripeConnect={outlet.stripeConnect}
                                  stripeOnboarding={
                                    outlet.marketplace.stripeOnboarding
                                  }
                                  stripeId={outlet.stripeId}
                                  link={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                                />
                              </td>
                            </Fragment>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </HTMLTable>
              )
            } else {
              return (
                <NonIdealState
                  icon="shop"
                  title="No Outlets"
                  description="Outlets are places of business where you receive and fulfil orders. You can have many outlets that share a common product menu."
                  action={
                    <Button
                      icon="plus"
                      onClick={() => {
                        optionModal.toggleModal(true)
                      }}
                    >
                      Add Outlet
                    </Button>
                  }
                />
              )
            }
          }}
        </Query>
        <OutletModal
          modalOpen={optionModal.isOpen}
          modalClose={() => {
            return optionModal.toggleModal(false)
          }}
          restaurantId={restaurants}
        />
      </Fragment>
    </Card>
  )
}

Outlets.propTypes = {
  match: matchType,
  history: object,
}

export default Outlets
