import React from 'react'
import { CenterSection, Title } from '../OrderPrintout'

const PaidStatus = ({ order: { paid } }) => (
  <CenterSection>
    <Title as="h2">{paid ? 'PAID' : 'UNPAID'}</Title>
  </CenterSection>
)

export default PaidStatus
