import { FormGroup, HTMLSelect, Intent } from '@blueprintjs/core'
import React from 'react'

export const UndeliverableAction = ({ values, setFieldValue, errors }) => {
  return (
    <FormGroup
      label="Undeliverable Action"
      labelFor="uberDirectUndeliverableAction"
      helperText={errors.uberDirectUndeliverableAction}
      intent={
        errors.uberDirectUndeliverableAction ? Intent.DANGER : Intent.NONE
      }
    >
      <HTMLSelect
        name="uberDirectUndeliverableAction"
        fill
        options={[
          { label: 'Select an action...', value: '' },
          { label: 'Leave at Door', value: 'LEAVE_AT_DOOR' },
          { label: 'Discard', value: 'DISCARD' },
          { label: 'Return', value: 'RETURN' },
        ]}
        value={values.uberDirectUndeliverableAction}
        onChange={event => {
          setFieldValue(
            'uberDirectUndeliverableAction',
            event.currentTarget.value
          )
        }}
      />
    </FormGroup>
  )
}
