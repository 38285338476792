import {
  useContext,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react'
import {
  ButtonGroup,
  Card,
  HTMLTable,
  NonIdealState,
  Button,
} from '@blueprintjs/core'
import {
  StringParam,
  DelimitedArrayParam,
  useQueryParams,
} from 'use-query-params'
import Query from '@components/Query/Query'
import FilterRow from '../FilterRow/FilterRow'
import { OuterLayoutOverlayContext } from '../Marketplace/OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import { Search, Pager } from '../Toolbar'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import EditLoyaltyCardDrawer from './EditLoyaltyCardDrawer'
import LOYALTY_CARDS_BY_MARKETPLACE from './Queries/loyaltyCardsByMarketplace'
import Currency from '../Currency/Currency'
import { isAfter, isBefore } from 'date-fns'
import LoyaltyStatusFilter from './LoyaltyStatusFilter'
import { union, without } from 'lodash'
import { useParams } from 'react-router-dom'

const DEFAULT_RECORDS = 20
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const LoyaltyCards = () => {
  const { openOverlay } = useContext(OuterLayoutOverlayContext)

  const [{ discountId = undefined, statusFilter = [] }, setQueryParams] =
    useQueryParams({
      discountId: StringParam,
      statusFilter: DelimitedArrayParam,
    })

  const filteredSearchVars = newFilters => {
    const queryFilterVars = []
    if (newFilters.includes('ACTIVE')) {
      queryFilterVars.push({ active: true, suspended: false })
    }
    if (newFilters.includes('DEACTIVATED')) {
      queryFilterVars.push({ active: false })
    }
    if (newFilters.includes('SUSPENDED')) {
      queryFilterVars.push({ suspended: true })
    }

    return queryFilterVars
  }

  const [queryFilterVars, setQueryFilterVars] = useState(
    filteredSearchVars(statusFilter)
  )
  const refetchRef = useRef(null)

  const { marketplace: marketplaceId } = useParams()

  const [
    marketplaceLoyaltyCardPagination,
    setMarketplaceLoyaltyCardPagination,
  ] = useState(DEFAULT_PAGINATION_STATE)

  const resetPaginationState = useCallback(() => {
    setMarketplaceLoyaltyCardPagination(prevState => ({
      ...prevState,
      ...DEFAULT_PAGINATION_STATE,
    }))
  }, [])

  useEffect(() => {
    // fiterVars changed refetch
    if (refetchRef.current) {
      refetchRef.current()
    }
  }, [queryFilterVars])

  const { searchValue, resetSearch } = useSearchQueryParam()

  useEffect(() => {
    setMarketplaceLoyaltyCardPagination(DEFAULT_PAGINATION_STATE)
  }, [searchValue, setMarketplaceLoyaltyCardPagination])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplaceLoyaltyCardPagination.skip +
        marketplaceLoyaltyCardPagination.first <
      marketplaceLoyaltyCardPagination.total
    ) {
      setMarketplaceLoyaltyCardPagination({
        ...marketplaceLoyaltyCardPagination,
        skip: limitNext(
          marketplaceLoyaltyCardPagination.skip,
          marketplaceLoyaltyCardPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplaceLoyaltyCardPagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplaceLoyaltyCardPagination({
      ...marketplaceLoyaltyCardPagination,
      skip: limitPrevious(
        marketplaceLoyaltyCardPagination.skip,
        marketplaceLoyaltyCardPagination.first
      ),
      first: DEFAULT_RECORDS,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const setFilterState = (count, totalCount) => {
    const totalCountChanged =
      marketplaceLoyaltyCardPagination.total !== totalCount
    const countChanged =
      marketplaceLoyaltyCardPagination.outcomeLength !== count
    if (totalCountChanged || countChanged) {
      setMarketplaceLoyaltyCardPagination({
        ...marketplaceLoyaltyCardPagination,
        total: totalCountChanged
          ? totalCount
          : marketplaceLoyaltyCardPagination.total,
        outcomeLength: countChanged
          ? count
          : marketplaceLoyaltyCardPagination.outcomeLength,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMarketplaceLoyaltyCardPagination({
      ...marketplaceLoyaltyCardPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
    })
  }

  const handleLoyaltyStatusFilter = currentTarget => {
    const { id, checked } = currentTarget
    const newFilters = checked
      ? union(statusFilter, [id])
      : without(statusFilter, id)

    setQueryParams({
      statusFilter: newFilters,
    })

    setQueryFilterVars(filteredSearchVars(newFilters))
    resetPaginationState()
    resetSearch()
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <Search autoFocus placeholder="Name" />
      </ButtonGroup>
      <LoyaltyStatusFilter
        disabled={false}
        statusFilter={statusFilter}
        onChange={e => {
          handleLoyaltyStatusFilter(e.target)
        }}
      />
      <Pager
        defaultNmbRecords={DEFAULT_RECORDS}
        goToPrevious={goToPrevious}
        goToNext={goToNext}
        goToPage={goToPage}
        skip={marketplaceLoyaltyCardPagination.skip}
        total={marketplaceLoyaltyCardPagination.total}
        outcomeLength={marketplaceLoyaltyCardPagination.outcomeLength}
        totalCount={marketplaceLoyaltyCardPagination.total}
        dataName={`Loyalty Card${
          marketplaceLoyaltyCardPagination.total !== 1 ? 's' : ''
        }`}
      />
    </FilterRow>
  )

  const loyaltyStatusLabel = (loyaltyCard, endDate) => {
    if (!loyaltyCard.active && loyaltyCard.suspended)
      return 'Deactivated & Suspended'
    if (!loyaltyCard.active) return 'Deactivated'
    if (loyaltyCard.suspended) return 'Suspended'
    if (loyaltyCard.active && isAfter(new Date(endDate), new Date())) {
      return 'Active'
    }
    if (loyaltyCard.active && isBefore(new Date(endDate), new Date())) {
      return 'Expired'
    }
  }

  return (
    <div className="bp5-table-frame">
      {renderFilterBar()}
      <div className="bp5-table-container bp5-scrollable">
        <Query
          query={LOYALTY_CARDS_BY_MARKETPLACE}
          variables={{
            marketplaceId,
            skip: marketplaceLoyaltyCardPagination.skip,
            first: marketplaceLoyaltyCardPagination.first,
            searchText: searchValue,
            orderBy: 'endDate_DESC',
            queryFilterVars,
          }}
          loaderTitle={'Loading Loyalty Cards'}
          onCompleted={({
            discountsWithLoyaltyCardByMarketplace: { count, totalCount },
          }) => {
            setFilterState(count, totalCount)
          }}
        >
          {(data, refetch) => {
            refetchRef.current = refetch
            const {
              discountsWithLoyaltyCardByMarketplace: { discounts },
            } = data

            if (discounts.length) {
              return (
                <Fragment>
                  <Card className={'bp5-nopad'}>
                    <HTMLTable interactive={true} bordered={false}>
                      <thead>
                        <tr>
                          <td>Status</td>
                          <td>Name</td>
                          <td>Cards in use</td>
                          <td>Cards Redeemed</td>
                          <td>Minimum Qualifying Order</td>
                          <td>Stamp Count</td>
                          <td>Stamp Value</td>
                        </tr>
                      </thead>
                      <tbody>
                        {discounts.map(discount => {
                          const {
                            id,
                            name,
                            discountPercentage,
                            discountAmount,
                            minimumSubtotalGross,
                            loyaltyCard,
                            endDate,
                          } = discount

                          return (
                            <tr key={id}>
                              <td>
                                {loyaltyStatusLabel(loyaltyCard, endDate)}
                              </td>
                              <td
                                onClick={() => {
                                  setQueryParams({ discountId: id })
                                }}
                              >
                                <a>&nbsp;&nbsp;{name}</a>
                              </td>
                              <td>{discount.uniqueCustomerCount || 0}</td>
                              <td>{discount.cardsRedeemed || 0}</td>
                              <td>
                                {minimumSubtotalGross &&
                                minimumSubtotalGross !== 0 ? (
                                  <Currency amount={minimumSubtotalGross} />
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>{loyaltyCard.requiredStamps}</td>
                              <td>
                                {discountPercentage ? (
                                  `${discountPercentage}%`
                                ) : (
                                  <Currency amount={discountAmount} />
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </Fragment>
              )
            } else {
              return searchValue ? (
                <NonIdealState
                  icon="panel-table"
                  title="No Loyalty Cards Found"
                  description="No loyalty cards match your search criteria."
                  action={
                    <Button
                      text="Clear Filters"
                      intent="primary"
                      minimal
                      onClick={() => {
                        resetSearch()
                        setQueryParams({ discountId: undefined })
                        setQueryParams({ statusFilter: [] })
                      }}
                    />
                  }
                />
              ) : (
                <NonIdealState
                  icon="add"
                  title="Loyalty Cards"
                  description="Create a loyalty card that allows a customer to earn stamps after a qualifying order."
                  action={
                    <Button
                      icon="plus"
                      text="Create Loyalty Card"
                      onClick={() => {
                        openOverlay('createLoyaltyCard')
                      }}
                    />
                  }
                />
              )
            }
          }}
        </Query>
      </div>

      {discountId && (
        <EditLoyaltyCardDrawer
          isOpen={!!discountId}
          marketplaceId={marketplaceId}
          drawerClose={() => {
            setQueryParams({ discountId: undefined })
          }}
          discountId={discountId}
          refetchVars={{
            marketplaceId,
          }}
        />
      )}
    </div>
  )
}

export default LoyaltyCards
