import * as yup from 'yup'
// validation specific to the FE
const blacklistedAddressValidation = yup.object().shape({
  firstLine: yup.string().required('First address line is required.'),
  secondLine: yup.string(),
  thirdLine: yup.string(),
  city: yup.string().required('Town is required.'),
  postcode: yup.string().required('Postcode is required.'),
})

export default blacklistedAddressValidation
