import {
  Button,
  Callout,
  Card,
  Drawer,
  DrawerSize,
  HTMLTable,
  NonIdealState,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'
import { canPerformAction } from '@stores/userStore'
import { penceToPounds } from '@utils/helpers'
import { get } from 'lodash'
import moment from 'moment'
import { Fragment, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useModal } from '../../providers/ModalProvider'
import DiscountDetails from './DiscountDetails'
import AddDiscountModal from './modals/AddDiscount.modal'
import GET_STANDARD_RESTAURANT_DISCOUNTS from './queries/getStandardRestaurantDiscounts.query'
import { useParams } from 'react-router-dom'
import Currency from '../Currency/Currency'

const tableHead = [
  {
    key: 'name',
    content: 'Name',
  },
  {
    key: 'discountPercentage',
    content: 'Discount %',
  },
  {
    key: 'discountAmount',
    content: 'Fixed Discount',
  },
  {
    key: 'startDate',
    content: 'Availability',
  },

  {
    key: 'daysOfWeek',
    content: 'Days Allowed',
  },
  {
    key: 'minimumSubtotalGross',
    content: 'Minimum Subtotal',
  },
]

const Discounts = () => {
  const [discountId, onChangeDiscountId] = useQueryParam(
    'discountId',
    StringParam
  )
  const { restaurants: businessId } = useParams()
  const [outlets, setOutlets] = useState([])
  const { toggleModal, isOpen } = useModal('addDiscountModal')

  return (
    <Fragment>
      {!canPerformAction('editDiscount') && (
        <Callout intent="warning" style={{ marginBottom: 24 }}>
          Please contact your marketplace administrator to modify restaurant
          discounts.
        </Callout>
      )}
      <Card className="bp5-nopad bp5-scrollable">
        <Query
          query={GET_STANDARD_RESTAURANT_DISCOUNTS}
          variables={{ id: businessId }}
          loaderTitle={'Loading Discounts'}
        >
          {({ getRestaurants: { restaurants } }) => {
            const discounts = get(restaurants, '[0].discounts', [])
            setOutlets(get(restaurants, '[0].outlets', []))

            const allowOutletSelection = restaurants
              .flatMap(restaurant =>
                restaurant.outlets.map(
                  outlet => outlet.marketplace.enableCustomerV2Client
                )
              )
              .some(Boolean)
            const isVatRegistered = get(
              restaurants,
              '[0].isVATregistered',
              false
            )

            return (
              <Fragment>
                {isVatRegistered ? (
                  <Callout intent="warning">
                    Discounts are not available for this business configuration,
                    please contact support for assistance.
                  </Callout>
                ) : (
                  <Fragment>
                    {discounts.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <HTMLTable interactive={true} bordered={false}>
                          <thead>
                            <tr>
                              {tableHead.map(({ key, content }) => (
                                <th key={key}>{content}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {discounts.map(discount => {
                              const {
                                id,
                                name,
                                discountPercentage,
                                discountAmount,
                                startDate,
                                endDate,
                                daysOfWeek,
                                minimumSubtotalGross,
                              } = discount
                              return (
                                <tr key={id}>
                                  <td>
                                    {canPerformAction('editDiscount') ? (
                                      <a onClick={() => onChangeDiscountId(id)}>
                                        {name}
                                      </a>
                                    ) : (
                                      name
                                    )}
                                  </td>
                                  <td>
                                    {discountPercentage ? (
                                      `${discountPercentage}%`
                                    ) : (
                                      <span className="bp5-text-disabled">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {discountAmount ? (
                                      <Currency amount={discountAmount} />
                                    ) : (
                                      <span className="bp5-text-disabled">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {startDate ? (
                                      `${moment(startDate).format(
                                        'DD.MM.YYYY'
                                      )} - ${moment(endDate).format(
                                        'DD.MM.YYYY'
                                      )}`
                                    ) : (
                                      <span className="bp5-text-disabled">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {daysOfWeek.length ? (
                                      `${daysOfWeek.reduce(
                                        (acc, day) =>
                                          acc.concat(
                                            moment()
                                              .isoWeekday(day)
                                              .format('ddd')
                                          ),
                                        []
                                      )}`
                                    ) : (
                                      <span className="bp5-text-disabled">
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Currency amount={minimumSubtotalGross} />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </HTMLTable>
                      </div>
                    ) : (
                      <NonIdealState
                        icon="add"
                        title="No Discounts"
                        description="You can setup time based fixed or percentage discounts for your customers basket. Great for promotions."
                        action={
                          <Button
                            text="Add Discount"
                            icon="plus"
                            onClick={() => toggleModal(true)}
                          />
                        }
                      />
                    )}
                    <AddDiscountModal
                      modalOpen={isOpen}
                      modalClose={() => {
                        toggleModal(false)
                      }}
                      restaurantId={businessId}
                      allowOutletSelection={allowOutletSelection}
                    />
                  </Fragment>
                )}
              </Fragment>
            )
          }}
        </Query>
      </Card>
      <Drawer
        title="Discount Details"
        isOpen={!!discountId}
        onClose={() => onChangeDiscountId(undefined)}
        size={DrawerSize.LARGE}
      >
        <DiscountDetails
          discountId={discountId}
          businessId={businessId}
          outlets={outlets}
          closeDrawer={() => onChangeDiscountId(undefined)}
        />
      </Drawer>
    </Fragment>
  )
}

export default Discounts
