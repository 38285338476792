import gql from 'graphql-tag'

const GET_LINK_CARDS = gql`
  query getLinkCards($marketplaceId: String!) {
    getLinkCards(marketplaceId: $marketplaceId) {
      id
      name
      description
      type
      url
      buttonText
      showOnApp
      showOnWeb
      availabilityStartDate
      availabilityEndDate
      availabilityTimes
      outlet {
        id
        name
      }
      menuItem {
        id
        name
      }
      segments {
        id
        name
      }
      createdAt
      cardImage {
        id
        caption
        position
        image {
          id
          src
        }
      }
    }
  }
`

export default GET_LINK_CARDS
