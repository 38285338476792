import { Icon } from '@blueprintjs/core'
import { Fragment } from 'react'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import MUTE_TERMINAL from '@components/Terminal/mutations/muteTerminal.mutation'
import moment from 'moment'
import { useMutation } from '@apollo/client'

const MutedStatus = ({ terminalId, isMuted, friendlyName }) => {
  const [muteTerminal] = useMutation(MUTE_TERMINAL, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      isMuted
        ? successToast(`${friendlyName} has been muted for 24 hours`)
        : successToast(`${friendlyName} has been unmuted`)
    },
  })

  return (
    <Fragment>
      {isMuted ? (
        <Icon
          icon="volume-off"
          onClick={() =>
            muteTerminal({
              variables: { id: terminalId, mutedUntil: null },
            })
          }
          className={'bp5-mute-toggle'}
        />
      ) : (
        <Icon
          icon="volume-up"
          onClick={() =>
            muteTerminal({
              variables: {
                id: terminalId,
                mutedUntil: moment().add(1, 'day').format(),
              },
            })
          }
          className={'bp5-mute-toggle'}
        />
      )}
    </Fragment>
  )
}

export default MutedStatus
