import React from 'react'
import { Button, Classes, FormGroup, InputGroup } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { bool, func } from 'prop-types'
import { Formik } from 'formik'
import { successToast } from '@utils/toast'
import { handleFacebookErrors } from './helpers/helpers'

const AudienceModal = ({
  modalOpen,
  modalClose,
  adAccountId,
  setFacebookAudienceIds,
}) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Audience">
      <div className={Classes.DIALOG_BODY}>
        <Formik
          onSubmit={e => {
            window.FB.api(
              `/${e.adAccountId}/customaudiences`,
              'post',
              {
                name: e.name,
                subtype: 'CUSTOM',
                description: e.description,
                is_value_based: '1',
                customer_file_source: 'USER_PROVIDED_ONLY',
              },
              function (addResponse) {
                if (addResponse.error) {
                  handleFacebookErrors(addResponse.error)
                } else {
                  successToast('Added')

                  window.FB.api(
                    `/${e.adAccountId}/customaudiences?fields=["account_id","data_source","name","description","datafile_custom_audience_uploading_status"]`,
                    function (response) {
                      if (response.error) {
                        handleFacebookErrors(response.error)
                      } else {
                        const audiences = response.data.map(v => {
                          return {
                            label: `${v.name} ${v.description}`.trim(),
                            value: v.id,
                          }
                        })
                        setFacebookAudienceIds(
                          [{ label: '---', value: '' }].concat(audiences)
                        )
                      }
                    }
                  )
                }
                modalClose()
              }
            )
          }}
          initialValues={{ name: '', description: '', adAccountId }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <FormGroup label="Ad Account Id" labelFor="adAccountId">
                <InputGroup
                  name="adAccountId"
                  id="adAccountId"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.adAccountId}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup label="Audience name" labelFor="name">
                <InputGroup
                  name="name"
                  id="name"
                  type="text"
                  onChange={props.handleChange}
                />
              </FormGroup>
              <FormGroup label="Audience description" labelFor="description">
                <InputGroup
                  name="description"
                  id="description"
                  type="text"
                  onChange={props.handleChange}
                />
              </FormGroup>

              <div className="bp-card-footer-actions">
                <Button text="Save" type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

AudienceModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
}

export default AudienceModal
