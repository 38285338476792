import { string, bool } from 'prop-types'
import { Menu, MenuItem } from '@blueprintjs/core'
import CHANGE_MENU_VISIBILITY from '@components/Restaurant/Menu/mutations/changeMenuVisibility.mutation'
import { errorToast, successToast } from '@utils/toast'
import { useMutation } from '@apollo/client'

const OutletSubMenu = ({ id, outletId, hidden }) => {
  const [changeMenuVisibility] = useMutation(CHANGE_MENU_VISIBILITY, {
    onCompleted: ({ changeMenuVisibility }) => {
      changeMenuVisibility
        ? successToast(`Successfully updated menu`)
        : errorToast('Something went wrong')
    },
  })

  return (
    <Menu>
      <MenuItem
        text={`${hidden ? 'Show' : 'Hide'} menu`}
        icon={hidden ? 'eye-open' : 'eye-off'}
        onClick={() => {
          changeMenuVisibility({
            variables: {
              id,
              outletId,
              hidden: !hidden,
            },
          })
        }}
      />
    </Menu>
  )
}
OutletSubMenu.propTypes = {
  id: string,
  outletId: string,
  hidden: bool,
}

export default OutletSubMenu
