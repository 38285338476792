import gql from 'graphql-tag'

const GET_SUB_MENUS = gql`
  query getSubMenus($id: String!, $menuId: ID!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        outlets {
          id
          soldOutUntilItems
          soldOutMenuItemIds
          hiddenMenuItemGroupIds
        }
        menuItemsGroups(where: { id: $menuId }) {
          id
          name
          fulfillmentMethods
          menuItems {
            id
          }
          childrenMenus {
            id
            name
            fulfillmentMethods
            menuItems {
              id
            }
            parentMenu {
              id
              name
              fulfillmentMethods
            }
          }
        }
      }
    }
  }
`
export default GET_SUB_MENUS
