import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { Mutation } from '@apollo/client/react/components'
import { Card, Intent, Switch, Callout } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_OUTLET_ENABLE_ORDER_TO_TABLE from './mutations/editOutletEnableOrderToTable.mutation'
import { shape, bool } from 'prop-types'
import { string } from 'yup'
import { useMutation } from '@apollo/client'

const EnableOrderToTableForm = ({ outlet }) => {
  const [enableOrderToTable] = useMutation(EDIT_OUTLET_ENABLE_ORDER_TO_TABLE, {
    onError: defaultErrorHandler,
    onCompleted: ({
      editOutlet: {
        outlet: { isOrderToTableEnabled },
      },
    }) => {
      return successToast(
        `Order To Table ${isOrderToTableEnabled ? 'Enabled' : 'Disabled'}`
      )
    },
  })

  return (
    <Formik
      initialValues={{
        outletId: outlet.id,
        isOrderToTableEnabled: outlet.isOrderToTableEnabled,
      }}
    >
      {({ values, handleChange }) => (
        <Card className="bp5-layout-col">
          <h4 className="bp5-heading">Table / Room Ordering</h4>

          {!values.isOrderToTableEnabled && (
            <Fragment>
              <Callout intent={Intent.WARNING}>
                This feature has not been enabled, this outlet will not allow
                ordering to table / room.
              </Callout>
              <br />
            </Fragment>
          )}

          <Switch
            label="Enable"
            large
            checked={values.isOrderToTableEnabled}
            onChange={e => {
              handleChange(e)
              enableOrderToTable({
                variables: {
                  ...values,
                  isOrderToTableEnabled: e.currentTarget.checked,
                },
              })
            }}
            name="isOrderToTableEnabled"
          />
        </Card>
      )}
    </Formik>
  )
}
EnableOrderToTableForm.propTypes = {
  outlet: shape({
    id: string.isRequired,
    isOrderToTableEnabled: bool.isRequired,
  }),
}

export default EnableOrderToTableForm
