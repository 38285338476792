import { createContext } from 'react'

export const MenuListContext = createContext({
  dragging: false,
  setDragging: _ => {},
  selectedMenuItemId: null,
  setViewMenuItem: _ => {},
  editMenuOrder: _ => {},
  saveMenuOrder: (_, __, ___) => {},
  refetchMenuList: () => {},
})
