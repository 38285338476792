import React, { useState } from 'react'
import { Card, H5, Button } from '@blueprintjs/core'
import AddLinkCardModal from '@components/Marketplace/LinkCards/AddLinkCardModal'
import GET_LINK_CARDS from '@components/Marketplace/LinkCards/queries/getLinkCards.query'
import { Fragment } from 'react'
import { LinkCardsList } from '../LinkCards/LinkCardsList'
import { FieldArray } from 'formik'
import EditLinkCardModal from '../LinkCards/EditLinkCardModal'
import Query from '@src/components/Query/Query'

export const LinkCardSection = ({
  showAddModal,
  setShowAddModal,
  marketplaceId,
  setFieldValue,
  values,
}) => {
  const [cardToEditId, setCardToEditId] = useState(null)

  return (
    <Card>
      <H5>Segment Cards</H5>
      <Button
        onClick={() => setShowAddModal(true)}
        style={{ marginBottom: '16px' }}
      >
        Create Link Card
      </Button>
      <Query query={GET_LINK_CARDS} variables={{ marketplaceId }}>
        {(data, refetch) => {
          return (
            <Fragment>
              <AddLinkCardModal
                marketplaceId={marketplaceId}
                modalOpen={showAddModal}
                modalClose={() => setShowAddModal(false)}
                onSuccess={data => {
                  if (!data || !data.addLinkCard.id) {
                    return
                  }

                  setFieldValue('linkCards', [
                    ...values.linkCards,
                    data.addLinkCard,
                  ])
                }}
              />
              <EditLinkCardModal
                id={cardToEditId}
                isOpen={!!cardToEditId}
                marketplaceId={marketplaceId}
                onClose={() => {
                  setCardToEditId(null)
                  refetch()
                }}
              />
              <FieldArray
                name="linkCards"
                render={() => (
                  <Fragment>
                    {data.getLinkCards && (
                      <LinkCardsList
                        allCards={data.getLinkCards}
                        selectedCards={values.linkCards} // currently selected />
                        setFieldValue={setFieldValue}
                        outletLimit={values.outletLimit}
                        setCardToEditId={setCardToEditId}
                        linkCardOrderBy={values.linkCardOrderBy}
                      />
                    )}
                  </Fragment>
                )}
              />
            </Fragment>
          )
        }}
      </Query>
    </Card>
  )
}
