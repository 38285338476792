import React, { Fragment } from 'react'
import Query from '@components/Query/Query'
import { arrayOf, shape, string } from 'prop-types'
import { Callout, FormGroup, HTMLSelect } from '@blueprintjs/core'
import GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET from '../queries/getAvailableDeliveryZonesForOutlet.query'

// get the delivery zones which can be added to the outlet
// ie those that are unused and don't conflict with the existing ones
const DeliveryZoneSelectData = formikProps => {
  return (
    <Query
      query={GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET}
      variables={{ outletId: formikProps.values.outletId }}
      loaderTitle="Loading Delivery Zones"
    >
      {({ getAvailableDeliveryZonesForOutlet: deliveryZones }) => (
        <DeliveryZoneSelectUI deliveryZones={deliveryZones} {...formikProps} />
      )}
    </Query>
  )
}
DeliveryZoneSelectData.protoTypes = {
  outletId: string.isRequired,
}

const DeliveryZoneSelectUI = ({ deliveryZones, values, setFieldValue }) => {
  const deliveryZoneOptions = [
    {
      label: 'Select delivery zone',
      value: null,
    },
    ...deliveryZones.map(deliveryZone => ({
      value: deliveryZone.id,
      label: deliveryZone.name,
    })),
  ]
  {
    return !deliveryZones.length ? (
      <Fragment>
        <Callout intent={'warning'}>
          No delivery zones are available for outlet.
        </Callout>
        <br />
      </Fragment>
    ) : (
      <FormGroup label="Delivery Zone">
        <HTMLSelect
          name="deliveryZone"
          options={deliveryZoneOptions}
          onChange={e => {
            const deliveryZone = deliveryZones.find(
              ({ id }) => id === e.target.value
            )
            setFieldValue('deliveryZone', deliveryZone)
            setFieldValue('deliveryZoneType', deliveryZone.deliveryZoneType)
          }}
          fill={true}
          value={values.deliveryZone ? values.deliveryZone.id : null}
        />
      </FormGroup>
    )
  }
}
DeliveryZoneSelectUI.protoTypes = {
  deliveryZones: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
}

export default DeliveryZoneSelectData
