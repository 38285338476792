import React from 'react'
import moment from 'moment'
import Day from './Day'
import { DayPickerLabel, DayTitle } from './DateTimePopover.styles'

const INPUT_DATE_FORMAT_STR = 'YYYY-MM-DD'

const DatePicker = ({ selectedDate, setSelectedDate, minDate, maxDate }) => {
  return (
    <Day>
      <DayTitle>
        {moment(selectedDate).format('dddd Do')}
        <label>
          <DayPickerLabel>Choose Date:</DayPickerLabel>
          <input
            type="date"
            max={maxDate.format(INPUT_DATE_FORMAT_STR)}
            min={minDate.format(INPUT_DATE_FORMAT_STR)}
            value={moment(selectedDate).format(INPUT_DATE_FORMAT_STR)}
            onChange={({ target: { value } }) => {
              if (value) {
                const newSelectedDate = new Date(value)
                newSelectedDate.setHours(0, 0, 0, 0)
                setSelectedDate(newSelectedDate)
              }
            }}
          />
        </label>
      </DayTitle>
    </Day>
  )
}

export default DatePicker
