import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const addSegmentStructure: IIterableInterface = {
  name: yup.string().required('Segment name is required'),
}

export const editSegmentStructure: IIterableInterface = {
  id: yup.string().required('Id is required'),
  name: yup.string().required('Segment name is required'),
  marketplaceId: yup.string().required('Marketplace Id is required'),
  filterGroupsType: yup.string().required('Group type is required'),
  filterGroups: yup
    .array(
      yup.object({
        filterGroupType: yup.string().required('Group type is required'),
        filters: yup.array(
          yup.object({
            attribute: yup.string().required('Filter attribute is required'),
            comparator: yup.string().required('Filter comparator is required'),
            value: yup.string().required('Filter value is required'),
          })
        ),
      })
    )
    .required(),
}
