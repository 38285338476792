import React from 'react'
import { Button } from '@blueprintjs/core'
import { nth } from 'lodash'

const BUTTON_ALIGN_STATES = ['left', 'justify', 'center', 'right']

const TextAlignButton = ({ disabled, content, index, setFieldValue }) => (
  <Button
    disabled={disabled}
    minimal={true}
    icon={`align-${content.align || 'left'}`}
    onClick={() =>
      setFieldValue(
        `emailContent[${index}].align`,
        nth(
          BUTTON_ALIGN_STATES,
          BUTTON_ALIGN_STATES.findIndex(
            item => item === (content.align || 'left')
          ) - 1
        )
      )
    }
  />
)

export default TextAlignButton
