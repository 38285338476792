import { Routes, Route, Navigate } from 'react-router-dom'
import Orders from './Orders' // Adjust the import based on your file structure

export const VirtualTerminal = () => {
  return (
    <Routes>
      {/* Redirect from /virtual-terminal to /virtual-terminal/active */}
      <Route
        path="/"
        element={<Navigate to="/virtual-terminal/active" replace />}
      />
      {/* Match the /:statusFilter path and render Orders */}
      <Route path="/:statusFilter" element={<Orders />} />
    </Routes>
  )
}

export default VirtualTerminal
