import gql from 'graphql-tag'

const ENABLE_CONFIRM_ADDRESS_AT_PAYMENT = gql`
  mutation enableConfirmAddressAtPayment(
    $id: String!
    $confirmAddressAtPayment: Boolean!
  ) {
    editMarketplace(
      id: $id
      confirmAddressAtPayment: $confirmAddressAtPayment
    ) {
      message
      marketplace {
        id
        confirmAddressAtPayment
      }
    }
  }
`

export default ENABLE_CONFIRM_ADDRESS_AT_PAYMENT
