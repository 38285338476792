import * as yup from 'yup'

const emailRegex = /.*@.*\.+.+/

export const memberDiscountValidation = yup.object().shape({
  discountName: yup
    .string()
    .required('Discount name is required')
    .min(3, 'Discount name too short'),
  discountPercentage: yup
    .number()
    .required('The discount percentage is required')
    .min(0.001, "The discount can't be 0%") // 0.001%
    .max(100, "The discount can't be greater than 100%"),
  customerEmail: yup
    .string()
    .required('A customer email is required')
    .matches(emailRegex, 'Must be a valid email address'),
})
