import gql from 'graphql-tag'

// The only reason why apps are in this query is that
// it breaks the page once when modal is closed on apps list
// this is probably some internal cache on graphql
// tried debugging this with the team but without luck
const GET_MARKETPLACE_IDS_BY_PARTNERID = gql`
  query getMarketPlaceIdsInMarketplaceByPartnerDropdown($partnerId: ID!) {
    getMarketplaces(partnerIds: [$partnerId], orderBy: name_ASC) {
      regions {
        id
        name
        customerAppBrandFolder
        app {
          id
          appBuilds {
            id
            device
            version
            releaseToAppcenter
            releaseToStore
            createdAt
            appBuildStatuses {
              id
              status
              createdAt
            }
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE_IDS_BY_PARTNERID
