import React from 'react'
import Form from './Form'
import { Formik } from 'formik'
import { func, object } from 'prop-types'

const RestaurantFinancialsForm = ({ onSubmit, initialValues = {} }) => (
  <Formik onSubmit={onSubmit} initialValues={initialValues}>
    {props => <Form {...props} />}
  </Formik>
)

RestaurantFinancialsForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default RestaurantFinancialsForm
