import gql from 'graphql-tag'

const EDIT_RESTAURANT_ADD_ON_MENUS = gql`
  mutation editRestaurantAddOnMenus(
    $id: String!
    $allowDefaultMinimumPurchasePercentage: Boolean
    $defaultMinimumPurchasePricePercentage: Int
    $minimumBasketTotalForAddOns: Int
  ) {
    editRestaurantFinancials(
      id: $id
      allowDefaultMinimumPurchasePercentage: $allowDefaultMinimumPurchasePercentage
      defaultMinimumPurchasePricePercentage: $defaultMinimumPurchasePricePercentage
      minimumBasketTotalForAddOns: $minimumBasketTotalForAddOns
    ) {
      message
      restaurant {
        id
        allowDefaultMinimumPurchasePercentage
        defaultMinimumPurchasePricePercentage
        minimumBasketTotalForAddOns
      }
    }
  }
`
export default EDIT_RESTAURANT_ADD_ON_MENUS
