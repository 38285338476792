import styled from 'styled-components'

export const IosNotificationItem = styled.li`
  position: relative;
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  backdrop-filter: saturate(130%) blur(20px);
  transition: 0.6s var(--ease-out-cubic);
`

export const IosNotificationItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IosNotificationItemTitle = styled.div`
  display: flex;
  & span {
    font-weight: bold;
    margin-right: 3px;
  }
  & .bp5-popover-wrapper {
    margin-right: 5px;
  }
`

export const IosNotifications = styled.ul`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 360px;
  position: relative;
  transform-style: preserve-3d;
  perspective: 800px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  list-style-type: none;
`
