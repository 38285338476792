import gql from 'graphql-tag'

const BLACKLISTED_ADDRESSES = gql`
  query blacklistedAddresses($marketplaceId: ID!) {
    blacklistedAddresses(marketplaceId: $marketplaceId) {
      blacklistedAddresses {
        id
        googleFormattedAddress
      }
    }
  }
`

export default BLACKLISTED_ADDRESSES
