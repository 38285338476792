import Currency from '@components/Currency/Currency'
import { get } from 'lodash'
import moment from 'moment'
import React, { Fragment } from 'react'
import { ANALYTICS_COLORS } from '../styles'

const CustomTooltip = ({
  payload,
  tooltipDateFormat,
  currency = false,
  symbol,
}) => {
  if (!payload.length) return null

  const { x, y, y2, y3, y4 } = payload[0].payload
  return (
    <div
      style={{
        fontSize: '11px',
        background: 'white',
        padding: '6px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
        borderRadius: '3px',
      }}
    >
      {x && <div>{moment(x).format(tooltipDateFormat)}</div>}
      <div>
        {currency ? (
          <Fragment>
            <strong style={{ color: ANALYTICS_COLORS.blue }}>
              <Currency amount={y || '0'} />
            </strong>
            {y2 && y2 > 0 ? (
              <Fragment>
                <br />
                <span style={{ color: ANALYTICS_COLORS.grey }}>
                  <Currency amount={y2 || '0'} />
                </span>
              </Fragment>
            ) : null}
            {y3 && y3 > 0 ? (
              <Fragment>
                <br />
                <strong style={{ color: ANALYTICS_COLORS.redDot }}>
                  <Currency amount={y3 || '0'} />
                </strong>
              </Fragment>
            ) : null}
            {y4 && y4 > 0 ? (
              <Fragment>
                <br />
                <span style={{ color: ANALYTICS_COLORS.grey }}>
                  <Currency amount={y4 || '0'} />
                </span>
              </Fragment>
            ) : null}
          </Fragment>
        ) : (
          (y || '0') + get(symbol, 'y', '')
        )}
      </div>
    </div>
  )
}

export default CustomTooltip
