import gql from 'graphql-tag'

const GET_DISCOUNT = gql`
  query getDiscount($id: String!) {
    getDiscount(id: $id) {
      discount {
        id
        name
        discountPercentage
        discountAmount
        startDate
        endDate
        daysOfWeek
        active
        minimumSubtotalGross
        allowedOutlets {
          id
        }
        restaurant {
          id
          outlets {
            id
            marketplace {
              id
              enableCustomerV2Client
            }
          }
        }
        marketplace {
          id
          enableCustomerV2Client
        }
      }
    }
  }
`

export default GET_DISCOUNT
