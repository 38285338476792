import { set, get } from 'lodash'

const defaultValues = {
  numberOfOrders: 0,
  subtotalGrossSum: 0,
  discountAmountSum: 0,
  customers: [],
  paymentMethods: {},
  fulfillmentMethods: {},
}

export const transformDiscountsRawData = rawOrdersData => {
  const intermediaryData = rawOrdersData.reduce(
    (acc, rawOrder) => {
      acc.subtotalGrossSum = acc.subtotalGrossSum + rawOrder.subtotalGross
      acc.discountAmountSum = acc.discountAmountSum + rawOrder.discountAmount
      acc.customers = new Set([...acc.customers, rawOrder.customerId])
      acc.outletIds = new Set([...acc.outletIds, rawOrder.outletId])
      set(
        acc.fulfillmentMethods,
        rawOrder.fulfillmentMethod,
        get(acc.fulfillmentMethods, rawOrder.fulfillmentMethod, 0) + 1
      )
      set(
        acc.paymentMethods,
        rawOrder.paymentMethod,
        get(acc.paymentMethods, rawOrder.paymentMethod, 0) + 1
      )

      return acc
    },
    {
      subtotalGrossSum: 0,
      discountAmountSum: 0,
      customers: [],
      outletIds: [],
      paymentMethods: {},
      fulfillmentMethods: {},
    }
  )

  const intermediaryDataSplitByOutlet = rawOrdersData.reduce(
    (acc, rawOrder) => {
      const key = rawOrder.outletId
      if (!acc[key]) {
        set(acc, key, {
          ...defaultValues,
          paymentMethods: {},
          fulfillmentMethods: {},
        })
      }
      const previousValues = get(acc, key)

      acc[key].numberOfOrders = previousValues.numberOfOrders + 1
      acc[key].subtotalGrossSum =
        previousValues.subtotalGrossSum + rawOrder.subtotalGross
      acc[key].discountAmountSum =
        previousValues.discountAmountSum + rawOrder.discountAmount
      acc[key].customers = new Set([
        ...previousValues.customers,
        rawOrder.customerId,
      ])
      set(
        acc[key].fulfillmentMethods,
        rawOrder.fulfillmentMethod,
        get(acc[key].fulfillmentMethods, rawOrder.fulfillmentMethod, 0) + 1
      )
      set(
        acc[key].paymentMethods,
        rawOrder.paymentMethod,
        get(acc[key].paymentMethods, rawOrder.paymentMethod, 0) + 1
      )

      return acc
    },
    {}
  )
  // transform the intermediary data into the final data grouped by outlet
  const totalsGroupedByOutlets = Object.keys(
    intermediaryDataSplitByOutlet
  ).reduce((acc, outletId) => {
    const {
      numberOfOrders,
      subtotalGrossSum,
      discountAmountSum,
      customers,
      paymentMethods,
      fulfillmentMethods,
    } = intermediaryDataSplitByOutlet[outletId]

    set(acc, outletId, {
      numberOfOrders,
      subtotalGrossSum,
      subtotalGrossAvg: subtotalGrossSum / numberOfOrders,
      discountAmountSum,
      discountAmountAvg: discountAmountSum / numberOfOrders,
      customers: Array.from(customers),
      paymentMethods,
      fulfillmentMethods,
    })

    return acc
  }, {})

  return {
    numberOfOrders: rawOrdersData.length,
    numberOfCustomers: intermediaryData.customers.size,
    numberOfOutlets: intermediaryData.outletIds.size,
    subtotalGrossSum: intermediaryData.subtotalGrossSum,
    subtotalGrossAvg: intermediaryData.subtotalGrossSum / rawOrdersData.length,
    discountAmountSum: intermediaryData.discountAmountSum,
    discountAmountAvg:
      intermediaryData.discountAmountSum / rawOrdersData.length,
    customers: Array.from(intermediaryData.customers),
    paymentMethods: intermediaryData.paymentMethods,
    fulfillmentMethods: intermediaryData.fulfillmentMethods,
    totalsGroupedByOutlets,
    outletIds: Array.from(intermediaryData.outletIds),
  }
}
