import gql from 'graphql-tag'

const ALLOW_OTTER_INTEGRATION = gql`
  mutation allowOtterIntegration(
    $id: String!
    $allowOtterIntegration: Boolean!
  ) {
    editMarketplace(id: $id, allowOtterIntegration: $allowOtterIntegration) {
      message
      marketplace {
        id
        allowOtterIntegration
      }
    }
  }
`

export default ALLOW_OTTER_INTEGRATION
