import gql from 'graphql-tag'

const GET_MARKETPLACE_BRAND_COLOURS = gql`
  query getMarketplaceBrandColors($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        brandColor
        brandSecondaryColor
      }
    }
  }
`

export default GET_MARKETPLACE_BRAND_COLOURS
