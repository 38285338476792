import React, { useCallback, useState } from 'react'
import {
  HTMLTable,
  Card,
  NonIdealState,
  Button,
  Spinner,
} from '@blueprintjs/core'
import alarmSound from '@assets/sounds/alarm.mp3'
import TableHeader from './TableHeader'
import TerminalTableRow from '@components/Restaurants/Terminals/TableRow'
import TERMINAL_LIST from './queries/terminalList.query'
import DebouncedApolloQuery from '@components/DebouncedQuery/DebouncedApolloQuery'
import TerminalsContentWrapper from './TerminalsContentWrapper'
import { useTerminalQueryParams } from './useTerminalQueryParams'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'
import { useBusinessPageLayout } from '../useBusinessPageLayout'
import Sound, { TWO_MINUTES_AS_MILISECONDS } from '../../Sound/Sound'

const PAGE_SIZE = 50

const Terminals = () => {
  useBusinessPageLayout()
  const { marketplaceIds, marketplaceOutletIds, resetMarketplaceFilters } =
    useRoleAwareBusinessFilterQueryParams()
  const {
    offlineOpenOnly,
    softwareVersion,
    orderBy,
    onChangeViewTerminal,
    onChangeOrderBy,
    resetTerminalFilters,
  } = useTerminalQueryParams()
  const {
    searchValue: terminalSearchTerm,
    onChangeSearch: onChangeTerminalSearchTerm,
  } = useSearchQueryParam()
  const [page, setPage] = useState(0)

  const resetFilters = useCallback(() => {
    resetMarketplaceFilters()
    resetTerminalFilters()
  }, [resetMarketplaceFilters, resetTerminalFilters])

  // filters can only be cleared if at least one of them is set
  const someFiltersSelected = Boolean(
    marketplaceIds.length ||
      marketplaceOutletIds.length ||
      offlineOpenOnly ||
      softwareVersion ||
      orderBy
  )

  // query variables contains:
  // * pagination variables
  // * the search term if it is > 2 characters
  // * the non-search filters if the search term is <= 2
  const queryVariables = {
    // search filter
    ...(terminalSearchTerm.length > 2 && {
      filterType: 'SEARCH',
      first: PAGE_SIZE,
      skip: page * PAGE_SIZE,
      searchTerm: terminalSearchTerm,
    }),
    // online-open status filter
    ...(offlineOpenOnly && {
      filterType: 'OFFLINE_OPEN_ONLY',
      ...(marketplaceIds.length && { marketplaceIds }),
      ...(marketplaceOutletIds.length && {
        outletIds: marketplaceOutletIds.map(
          marketplaceOutlet => marketplaceOutlet.split(':')[1]
        ),
      }),
    }),
    // other filters
    ...(!offlineOpenOnly &&
      terminalSearchTerm.length <= 2 && {
        filterType: 'FILTER',
        first: PAGE_SIZE,
        skip: page * PAGE_SIZE,
        ...(marketplaceIds.length && { marketplaceIds }),
        ...(marketplaceOutletIds.length && {
          outletIds: marketplaceOutletIds.map(
            marketplaceOutlet => marketplaceOutlet.split(':')[1]
          ),
        }),
        ...(softwareVersion && { softwareVersion }),
        ...(orderBy && { orderBy }),
      }),
  }

  return (
    <DebouncedApolloQuery
      query={TERMINAL_LIST}
      variables={queryVariables}
      pollInterval={offlineOpenOnly ? 60000 : undefined}
      fetchPolicy="network-only"
    >
      {({ data: queryResponseData, loading, error }) => {
        const data = queryResponseData ? queryResponseData.terminals : null

        const terminalsContentWrapperProps = {
          page,
          setPage,
          resetFilters,
          someFiltersSelected,
          queryVariables,
          terminalsQueryData: data,
        }

        if (loading || !data) {
          return (
            <TerminalsContentWrapper {...terminalsContentWrapperProps}>
              <NonIdealState
                title="Loading Terminals"
                description="Please wait..."
                icon={<Spinner size={60} />}
              />
            </TerminalsContentWrapper>
          )
        }

        if (error) {
          return (
            <TerminalsContentWrapper {...terminalsContentWrapperProps}>
              <NonIdealState
                icon="th-list"
                title="Loading Error"
                description="Unable to load terminal data, please retry."
              />
            </TerminalsContentWrapper>
          )
        }

        if (data.totalCount === 0) {
          return (
            <TerminalsContentWrapper {...terminalsContentWrapperProps}>
              <NonIdealState
                icon="th-list"
                title={`No ${
                  offlineOpenOnly ? `Offline & Open ` : ''
                }Terminals Found`}
                description={
                  'No terminals found, please try again with different filters.'
                }
                action={
                  <Button
                    onClick={
                      terminalSearchTerm.length > 0
                        ? () => {
                            onChangeTerminalSearchTerm(undefined)
                          }
                        : resetFilters
                    }
                    minimal
                    intent="primary"
                  >
                    Clear Filters
                  </Button>
                }
              />
            </TerminalsContentWrapper>
          )
        }

        return (
          <TerminalsContentWrapper {...terminalsContentWrapperProps}>
            <div className="bp5-table-container bp5-scrollable">
              <Card className={'bp5-nopad'}>
                {offlineOpenOnly &&
                  data.terminals.some(({ isMuted }) => !isMuted) && (
                    <Sound
                      src={alarmSound}
                      repeatInterval={TWO_MINUTES_AS_MILISECONDS}
                    />
                  )}
                <HTMLTable bordered={false} interactive={true}>
                  <TableHeader
                    onChangeOrderBy={(updatedOrderBy, orderDirection) =>
                      onChangeOrderBy(
                        orderDirection === 'NONE'
                          ? null
                          : `${updatedOrderBy}_${orderDirection}`
                      )
                    }
                    orderBy={orderBy}
                    canSort={queryVariables.filterType === 'FILTER'}
                  />
                  <tbody>
                    {data.terminals.map(terminal => (
                      <TerminalTableRow
                        terminal={terminal}
                        key={terminal.id}
                        editTerminal={() => {
                          onChangeViewTerminal(terminal.id)
                        }}
                      />
                    ))}
                  </tbody>
                </HTMLTable>
              </Card>
            </div>
          </TerminalsContentWrapper>
        )
      }}
    </DebouncedApolloQuery>
  )
}

export default Terminals
