import React from 'react'
import { object } from 'prop-types'
import get from 'lodash/get'

const outlet = 'outlet'
const customer = 'customer'

const tableHead = [
  {
    key: 'reviewNo',
    content: 'No.',
  },
  {
    key: 'reviewDate',
    content: 'Date',
  },
  {
    key: customer,
    content: 'Customer',
  },
  {
    key: 'order',
    content: 'Order',
  },
  {
    key: outlet,
    content: 'Outlet',
  },
  {
    key: 'food',
    content: 'Food Quality',
  },
  {
    key: 'restaurant',
    content: 'Restaurant Service',
  },
  {
    key: 'delivery',
    content: 'Delivery Time',
  },
  {
    key: 'reviewText',
    content: 'Review',
  },
  {
    key: 'approved',
    content: 'Approved',
  },
]

const ReviewsTableHead = ({ queryVars }) => {
  let hiddenKeys = []

  if(get(queryVars, 'hideCustomer')) {
    hiddenKeys.push(customer)
  }

  if(get(queryVars, 'hideOutlet')) {
    hiddenKeys.push(outlet)
  }

  const filteredHead = tableHead.filter(section => !!hiddenKeys.indexOf(section.key))

  return(
    <thead>
      <tr>
        {filteredHead.map(({ key, content }) => (
          <th key={key}>{content}</th>
        ))}
      </tr>
    </thead>
  )
}

ReviewsTableHead.propTypes = {
  queryVars: object
}

export default ReviewsTableHead
