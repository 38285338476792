import gql from 'graphql-tag'

const GET_OPENING_TIMES = gql`
  query getOutletOpeningTimes($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        active
        defaultDeliveryTime
        defaultCollectionTime
        openingTimes
        daysOfferedInAdvanceMin
        daysOfferedInAdvanceMax
        noPreordersBeforeOpening
        allowPreorders
      }
    }
  }
`

export default GET_OPENING_TIMES
