import { func, string } from 'prop-types'
import { ComponentProps } from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import { Position, Tooltip } from '@blueprintjs/core'
import colors from '@src/styles/colors'
import darkColors from '@src/styles/darkColors'
import { useMatch } from 'react-router-dom'

const IconContainer = styled.div<{
  stroke: string
  highlight: string
  invert: boolean
}>`
  padding: 12px 0px;
  cursor: pointer;
  width: 46px;
  display: flex;
  justify-content: center;

  svg {
    transition: color 0.2s;
    color: ${props => (props.invert ? props.highlight : props.stroke)};
    &:hover {
      color: ${props => props.highlight};
    }
  }
`

const NavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

interface Props {
  imagePath: string
  clickAction: () => void
  tooltipContent: ComponentProps<typeof Tooltip>['content']
  linkUrl: string
  dark: boolean
  dataTestId: string
}

export const NavIcon = ({
  imagePath,
  clickAction,
  tooltipContent,
  linkUrl,
  dark,
  dataTestId,
}: Props) => {
  let match: boolean = false
  if (linkUrl) match = Boolean(useMatch(linkUrl))
  let stroke: string
  let highlight: string
  if (dark) {
    stroke = darkColors.primaryNavColor
    highlight = darkColors.primaryNavActiveColor
  } else {
    stroke = colors.primaryNavColor
    highlight = colors.primaryNavActiveColor
  }
  return (
    <Tooltip content={tooltipContent} position={Position.RIGHT}>
      <NavContainer>
        <IconContainer
          onClick={() => clickAction()}
          stroke={stroke}
          highlight={highlight}
          invert={match}
        >
          <ReactSVG
            src={imagePath}
            beforeInjection={svg => {
              // wipes out all strokes in the file, so it can be assigned by the CSS in IconContainer
              svg.querySelectorAll('*').forEach(element => {
                element.setAttribute('stroke', 'currentColor')
              })
            }}
            data-test-id={dataTestId}
          />
        </IconContainer>
      </NavContainer>
    </Tooltip>
  )
}

NavIcon.propTypes = {
  imagePath: string,
  clickAction: func,
  pathNameToMatch: string,
  tooltipContent: string,
}

NavIcon.defaultProps = {
  clickAction: () => {},
}

export default NavIcon
