import gql from 'graphql-tag'

const GET_OUTLETS = gql`
  query getOutletsForMarketplace($marketplaceIds: [ID!]!) {
    getOutlets(marketplaceIds: $marketplaceIds) {
      count
      totalCount
      outlets {
        id
        name
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLETS
