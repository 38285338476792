import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import { replace } from 'lodash'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const OrderCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={replace(crumb, '-', ' ')}
          link={`/orders/${crumb}`}
        />
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={replace(crumb, '-', ' ')}
          link={`/orders/${crumbs[1]}/${crumb}`}
        />
      )
    }

    return <Crumb key={crumb} text={crumb} />
  })

OrderCrumbs.propTypes = {
  crumbs: (props, propName, componentName) => {
    const crumbs = props[propName]
    if (!Array.isArray(crumbs)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array recived ${typeof crumbs}`
      )
    }
    if (crumbs.length !== 2) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array of length 2`
      )
    }
  },
}

export default OrderCrumbs
