import gql from 'graphql-tag'

const TERMINAL_LIST = gql`
  query terminalList(
    $filterType: TerminalFilterType
    $first: Int
    $skip: Int
    $searchTerm: String
    $marketplaceIds: [ID!]
    $outletIds: [ID!]
    $softwareVersion: String
    $orderBy: TerminalsQueryOrderBy
  ) {
    terminals(
      filterType: $filterType
      first: $first
      skip: $skip
      searchTerm: $searchTerm
      marketplaceIds: $marketplaceIds
      outletIds: $outletIds
      softwareVersion: $softwareVersion
      orderBy: $orderBy
    ) {
      count
      totalCount
      terminals {
        id
        friendlyName
        softwareVersion
        serialNumber
        isOnline
        lastOnline
        isMuted
        mutedUntil
        outlets {
          id
          name
          openingTimes
          isOpen
          isOnline
          isOnlineOverride
          active
          marketplace {
            id
            name
          }
          restaurant {
            name
            id
            active
          }
        }
      }
    }
  }
`

export default TERMINAL_LIST
