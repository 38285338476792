import gql from 'graphql-tag'

const EDIT_MARKETPLACE_FINANCIALS = gql`
  mutation editMarketplaceFinancials(
    $id: String!
    $platformFee: Float
    $platformCharge: Int
    $partnerFee: Float
    $partnerCharge: Int!
    $partnerChargeDescription: String
    $partnerCommissionFee: Float
    $platformTableFee: Float
    $platformTableCharge: Int
    $partnerTableFee: Float
    $partnerTableCharge: Int!
    $partnerTableChargeDescription: String
    $partnerTableCommissionFee: Float
    $vatNumber: String
    $isVATregistered: Boolean
    $merchantCommissionCharge: Int
    $merchantTableCommissionCharge: Int
    $merchantComparisonDescription: String
    $merchantComparisonPercentage: Float
    $invoiceEnabled: Boolean
    $invoiceTextFooter: String
    $invoiceEmailCustomText: String
    $invoiceBcc: String
    $invoiceEmail: String
    $partnerChargeMerchantVat: Boolean
  ) {
    editMarketplace(
      id: $id
      platformFee: $platformFee
      platformCharge: $platformCharge
      partnerFee: $partnerFee
      partnerCharge: $partnerCharge
      partnerChargeDescription: $partnerChargeDescription
      partnerCommissionFee: $partnerCommissionFee
      platformTableFee: $platformTableFee
      platformTableCharge: $platformTableCharge
      partnerTableFee: $partnerTableFee
      partnerTableCharge: $partnerTableCharge
      partnerTableChargeDescription: $partnerTableChargeDescription
      partnerTableCommissionFee: $partnerTableCommissionFee
      vatNumber: $vatNumber
      isVATregistered: $isVATregistered
      merchantCommissionCharge: $merchantCommissionCharge
      merchantTableCommissionCharge: $merchantTableCommissionCharge
      merchantComparisonDescription: $merchantComparisonDescription
      merchantComparisonPercentage: $merchantComparisonPercentage
      invoiceEnabled: $invoiceEnabled
      invoiceTextFooter: $invoiceTextFooter
      invoiceEmailCustomText: $invoiceEmailCustomText
      partnerChargeMerchantVat: $partnerChargeMerchantVat
      invoiceBcc: $invoiceBcc
      invoiceEmail: $invoiceEmail
    ) {
      message
      marketplace {
        id
        name
        platformFee
        platformCharge
        partnerFee
        partnerCharge
        partnerChargeDescription
        partnerCommissionFee
        platformTableFee
        platformTableCharge
        partnerTableFee
        partnerTableCharge
        partnerTableChargeDescription
        partnerTableCommissionFee
        vatNumber
        isVATregistered
        merchantCommissionCharge
        merchantTableCommissionCharge
        merchantComparisonDescription
        merchantComparisonPercentage
        invoiceEnabled
        invoiceTextFooter
        invoiceEmailCustomText
        partnerChargeMerchantVat
        invoiceBcc
        invoiceEmail
      }
    }
  }
`

export default EDIT_MARKETPLACE_FINANCIALS
