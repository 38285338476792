import gql from 'graphql-tag'

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: String!, $marketplaceId: ID!) {
    deleteCampaign(id: $id, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export default DELETE_CAMPAIGN
