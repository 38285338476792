import { Callout } from '@blueprintjs/core'
import React from 'react'

// jsx component
export const KycCallout = () => {
  return (
    <Callout title="Why we need this information..." intent="primary">
      <p>
        We require this information to comply with Know Your Customer (KYC)
        regulations and to ensure that your business is properly verified. This
        helps prevent fraud and ensures that payments are processed securely.
        Once your account is connected and verified, you’ll be able to start
        accepting payments directly into your bank account.
      </p>
    </Callout>
  )
}
