import { ControlGroup } from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import { DeliveryNetworkProviderForPartnerSelect } from '@src/components/DeliveryNetworkProviderSelect/DeliveryNetworkProviderSelect'
import { useDeliveryNetworkProviderQueryParams } from '@src/components/DeliveryNetworkProviderSelect/useDeliveryNetworkProviderQueryParams'
import { tableProps } from '@src/components/MegaTable/Columns/column.types'
import { useMegaTableSort } from '@src/components/MegaTable/useMegaTableSort'
import PartnerSelect from '@src/components/PartnerSelect/PartnerSelect'
import { format, subDays } from 'date-fns'
import { StringParam, useQueryParam } from 'use-query-params'
import { MegaTable } from '../../../components/MegaTable/MegaTable'
import { DeliveryNetworkFailuresHeatmap } from './DeliveryNetworkFailuresHeatmap'
import { useGetDeliveryNetworkFailureReportByPartnerQuery } from './queries/deliveryNetworkFailuresByPartner.query.generated'

export const DeliveryNetworkFailuresByPartner: React.FC = () => {
  const [partnerId, setPartnerId] = useQueryParam('partnerId', StringParam)
  const { fullString } = useMegaTableSort()
  const [deliveryNetworkProvider] = useDeliveryNetworkProviderQueryParams()
  /* eslint-disable-next-line */
  const [_showingMarketplace, setShowingMarketplace] = useQueryParam(
    'showingMarketplace',
    StringParam
  )

  const { data, loading, error } =
    useGetDeliveryNetworkFailureReportByPartnerQuery({
      variables: {
        partnerId: String(partnerId),
        deliveryNetworkProvider: String(deliveryNetworkProvider),
        orderBy: fullString,
      },
      skip: !partnerId || !deliveryNetworkProvider,
    })

  // TODO handle loading and error states
  return (
    <div>
      <FilterRow>
        <ControlGroup>
          <PartnerSelect
            partnerId={partnerId}
            onChange={event => {
              setPartnerId(event.currentTarget.value)
            }}
          />
          {partnerId && (
            <DeliveryNetworkProviderForPartnerSelect partnerId={partnerId} />
          )}
        </ControlGroup>
      </FilterRow>
      {data && data.getDeliveryNetworkFailureReportByPartner && (
        <MegaTable
          {...tableProps({
            isLoading: loading,
            isError: !!error,
            sorting: 'SERVER',
            columnGroupHeaders: {
              '30days': 'Last 30 Days',
              '60days': 'Last 60 days',
              '90days': 'Last 90 days',
            },
            columns: [
              {
                name: 'marketplaceName',
                type: 'marketplace',
                header: 'Marketplace',
                isSortable: true,
              },
              {
                name: 'totalOrders30Days',
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                groupKey: '30days',
              },
              {
                name: 'totalValue30Days',
                type: 'currency',
                isSortable: true,
                header: 'Value',
                groupKey: '30days',
              },
              {
                name: 'totalFailedOrders30Days',
                type: 'numeric',
                isSortable: true,
                header: 'Failures',
                groupKey: '30days',
              },
              {
                name: 'totalFailedValue30Days',
                type: 'currency',
                isSortable: true,
                header: 'Failed Value',
                groupKey: '30days',
              },
              {
                name: 'failureRate30Days',
                type: 'percentage',
                isSortable: true,
                header: 'Performance',
                groupKey: '30days',
              },

              {
                name: 'totalOrders60Days',
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                groupKey: '60days',
              },
              {
                name: 'totalValue60Days',
                type: 'currency',
                isSortable: true,
                header: 'Value',
                groupKey: '60days',
              },
              {
                name: 'totalFailedOrders60Days',
                type: 'numeric',
                isSortable: true,
                header: 'Failures',
                groupKey: '60days',
              },
              {
                name: 'totalFailedValue60Days',
                type: 'currency',
                isSortable: true,
                header: 'Failed Value',
                groupKey: '60days',
              },
              {
                name: 'failureRate60Days',
                type: 'percentage',
                isSortable: true,
                header: 'Performance',
                groupKey: '60days',
              },

              {
                name: 'totalOrders90Days',
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                groupKey: '90days',
              },
              {
                name: 'totalValue90Days',
                type: 'currency',
                isSortable: true,
                header: 'Value',
                groupKey: '90days',
              },
              {
                name: 'totalFailedOrders90Days',
                type: 'numeric',
                isSortable: true,
                header: 'Failures',
                groupKey: '90days',
              },
              {
                name: 'totalFailedValue90Days',
                type: 'currency',
                isSortable: true,
                header: 'Failed Value',
                groupKey: '90days',
              },
              {
                name: 'failureRate90Days',
                type: 'percentage',
                isSortable: true,
                header: 'Performance',
                groupKey: '90days',
              },
            ] as const,
            data: data.getDeliveryNetworkFailureReportByPartner
              .filter(x => x !== null && x !== undefined)
              .map(
                ({
                  marketplace: { id: marketplaceId, name: marketplaceName },
                  totalOrders30Days,
                  totalValue30Days,
                  totalFailedOrders30Days,
                  totalFailedValue30Days,
                  failureRate30Days,

                  totalOrders60Days,
                  totalValue60Days,
                  totalFailedOrders60Days,
                  totalFailedValue60Days,
                  failureRate60Days,

                  totalOrders90Days,
                  totalValue90Days,
                  totalFailedOrders90Days,
                  totalFailedValue90Days,
                  failureRate90Days,
                }) => {
                  const lastDaysOrdersLink = (
                    days: number,
                    failsOnly: boolean
                  ): string =>
                    `/orders${
                      failsOnly ? '/failed' : ''
                    }?marketplaceIds=${marketplaceId}&dateFilterTypeKey=CUSTOM&startOfRangeDate=${format(
                      subDays(new Date(), days),
                      'yyyy-MM-dd'
                    )}&endOfRangeDate=${format(
                      new Date(),
                      'yyyy-MM-dd'
                    )}&fulfilment=NETWORK&deliveryNetworkProvider=${deliveryNetworkProvider}`
                  return [
                    {
                      marketplaceId,
                      marketplaceName,
                      onClick: () => {
                        setShowingMarketplace(marketplaceId)
                      },
                    },
                    {
                      value: totalOrders30Days,
                      link: lastDaysOrdersLink(30, false),
                    },
                    { amount: totalValue30Days },
                    {
                      value: totalFailedOrders30Days,
                      link: lastDaysOrdersLink(30, true),
                    },
                    { amount: totalFailedValue30Days },
                    { value: failureRate30Days },

                    { value: totalOrders60Days }, // can't link here as limited to 1 month
                    { amount: totalValue60Days },
                    { value: totalFailedOrders60Days },
                    { amount: totalFailedValue60Days },
                    { value: failureRate60Days },

                    { value: totalOrders90Days }, // can't link here as limited to 1 month
                    { amount: totalValue90Days },
                    { value: totalFailedOrders90Days },
                    { amount: totalFailedValue90Days },
                    { value: failureRate90Days },
                  ]
                }
              ),
          })}
        />
      )}
      <DeliveryNetworkFailuresHeatmap />
    </div>
  )
}
