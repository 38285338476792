import gql from 'graphql-tag'

const GET_OUTLET_ITEM_DETAILS = gql`
  query getOutletItemDetails($outletId: String!, $menuItemId: String!) {
    getOutletMenuItem(outletId: $outletId, menuItemId: $menuItemId) {
      id
      name
      outletSoldOut
      outletHidden
      spiceLevel
    }
  }
`

export default GET_OUTLET_ITEM_DETAILS
