import { Fragment } from 'react'
import {
  Callout,
  Card,
  HTMLTable,
  NonIdealState,
  Spinner,
  H5,
  Icon,
  AnchorButton,
  H4,
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import GET_MARKETPLACE_DNS from './queries/getMarketplaceDNS.query'
import Query from '@components/Query/Query'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import ACMDescribeCertificate from '@components/AWS/ACMDescribeCertificate'
import config from '@src/config'
import DNSTRVerify from './DNSTRVerify'
import DNSDKIM from './DNSDKIM'
import { getDomain } from '@utils/getDomain'
import { uniqBy } from 'lodash'
import CopyText from '@components/CopyText/CopyText'
import { useParams } from 'react-router-dom'

const DNS = () => {
  const { marketplace: marketplaceId } = useParams()
  const ARecordIP1 = '13.248.191.41'
  const ARecordIP2 = '99.83.194.175'

  // This is when we introduced marketplace automation 2021-05-03T23:00:00.000Z
  const MARKETPLACE_SETUP_AUTOMATION_TIMESTAMP = 1620082800000

  return (
    <Query query={GET_MARKETPLACE_DNS} variables={{ marketplaceId }}>
      {({ getMarketplaces: { regions }, loading }) => {
        const marketplace = regions[0]
        const pinpointAwsAccountId =
          marketplace.pinpointAwsAccountId === ''
            ? config.awsAccountId
            : marketplace.pinpointAwsAccountId

        if (loading) {
          return (
            <NonIdealState
              icon={<Spinner size={60} />}
              title={'Loading DNS'}
              description={'Please wait...'}
            />
          )
        }
        if (!marketplace.cname) {
          return (
            <NonIdealState
              icon="error"
              title="Not Available"
              description={`Marketplace Domain (cname) is not set. If you already own a website domain, you can set it up below`}
              action={
                <AnchorButton
                  minimal
                  intent="success"
                  icon="plus"
                  text="Setup Domain"
                  href={`/marketplaces/${marketplace.id}/details`}
                />
              }
            />
          )
        }
        const marketplaceCnameWithoutWWW = marketplace.cname
          .trim()
          .replace(/^www\./, '')
        return (
          <FetchQuery
            domain={`manage.${config.environmentDomain}`}
            path={`api/marketplace-setup/auth/aws-account-id/${pinpointAwsAccountId}`}
            fetchOptions={{ method: 'POST' }}
          >
            {({ loading, data: credentials }) => {
              if (loading) {
                return (
                  <NonIdealState
                    icon={<Spinner size={60} />}
                    title={'Loading DNS'}
                    description={'Please wait...'}
                  />
                )
              }
              const emailIdentity = `messaging.${marketplaceCnameWithoutWWW}`
              return (
                <Fragment>
                  <H4>
                    DNS Settings{' '}
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      position={Position.RIGHT_TOP}
                      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                      content={
                        <Fragment>
                          <H5>Help</H5>
                          <p>
                            <i>DNS</i> stands for the Domain Name System. DNS
                            stores the information necessary namely the IP
                            address, or a way to find an IP address to locate
                            and resolve the name to the associated content.
                          </p>
                          <p>
                            Marketplaces using their own domain names will need
                            to create these records in their DNS provider.
                          </p>
                          <AnchorButton
                            href="https://support.redbox.systems/docs/dns-configuration"
                            rightIcon="document-open"
                            outlined
                            target="_blank"
                          >
                            Read Documentation
                          </AnchorButton>
                        </Fragment>
                      }
                    >
                      <Icon icon="help" className="bp5-help" />
                    </Popover>
                  </H4>
                  <br />
                  <p>
                    Domain or sometimes called Hosted Zone should be{' '}
                    <b>{marketplaceCnameWithoutWWW}</b>
                  </p>
                  <br />
                  <H5>Website DNS</H5>
                  {marketplace.websiteCnameCertificateArn ? (
                    <ACMDescribeCertificate
                      credentials={credentials}
                      certificateArn={marketplace.websiteCnameCertificateArn}
                    >
                      {({ loading, data }) => {
                        if (loading) {
                          return (
                            <NonIdealState
                              icon={<Spinner size={60} />}
                              title={'Loading DNS'}
                              description={'Please wait...'}
                            />
                          )
                        }
                        if (!data) {
                          return <NonIdealState icon="error" title="Error" />
                        }

                        const ACMValidation = uniqBy(
                          data.Certificate.DomainValidationOptions,
                          'ResourceRecord.Name'
                        ).map(validationOption => (
                          <tr key={validationOption.ResourceRecord.Type}>
                            <td>{validationOption.ResourceRecord.Type}</td>
                            <td>
                              {validationOption.ResourceRecord.Name.replace(
                                `.${marketplaceCnameWithoutWWW}.`,
                                ''
                              )}
                            </td>
                            <td></td>
                            <td>
                              <CopyText
                                text={validationOption.ResourceRecord.Value}
                                mono
                              />
                            </td>
                            <td>
                              {
                                {
                                  FAILED: (
                                    <Icon icon="ban-circle" color="#DB0B0B" />
                                  ),
                                  SUCCESS: (
                                    <Icon icon="tick-circle" color="#5bb70d" />
                                  ),
                                  PENDING_VALIDATION: <Icon icon="time" />,
                                }[validationOption.ValidationStatus]
                              }
                            </td>
                          </tr>
                        ))
                        const isWWWRedirectRequired = /^www\./.test(
                          marketplace.cname
                        )

                        return (
                          <Card className="bp5-nopad bp5-scrollable">
                            <HTMLTable>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Host</th>
                                  <th></th>
                                  <th>Value</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ACMValidation}
                                {config.environmentName === 'prod' && (
                                  <DNSTRVerify
                                    nameUserFriendly={
                                      isWWWRedirectRequired ? `www` : '@'
                                    }
                                    name={`${
                                      isWWWRedirectRequired ? 'www.' : ''
                                    }${marketplaceCnameWithoutWWW}`}
                                    value={`${marketplaceCnameWithoutWWW.replace(
                                      /\W/g,
                                      ''
                                    )}.${getDomain(
                                      config.environmentName,
                                      marketplace.pinpointAwsAccountId
                                    )}`}
                                    type="CNAME"
                                  />
                                )}
                                {isWWWRedirectRequired && (
                                  <DNSTRVerify
                                    nameUserFriendly={`@`}
                                    name={marketplaceCnameWithoutWWW}
                                    value={ARecordIP1}
                                    type="A"
                                  />
                                )}
                                {isWWWRedirectRequired && (
                                  <DNSTRVerify
                                    nameUserFriendly={`@`}
                                    name={marketplaceCnameWithoutWWW}
                                    value={ARecordIP2}
                                    type="A"
                                  />
                                )}
                              </tbody>
                            </HTMLTable>
                          </Card>
                        )
                      }}
                    </ACMDescribeCertificate>
                  ) : Date.parse(marketplace.createdAt) >
                    MARKETPLACE_SETUP_AUTOMATION_TIMESTAMP ? (
                    <Card className="bp5-nopad">
                      <Callout title={'Building'} icon={'time'}>
                        Please wait, Redbox is generating the required DNS
                        records. This could take up to 1 hour.
                      </Callout>
                    </Card>
                  ) : (
                    <Card className="bp5-nopad">
                      <Callout title={'Not Available'} icon={'info-sign'}>
                        This Marketplace already exists. Please check your
                        website is working as expected. If not, check your DNS
                        settings with your domain provider.
                      </Callout>
                    </Card>
                  )}
                  <H5>Messaging DNS</H5>
                  {marketplace.sesMessaging ? (
                    <DNSDKIM
                      emailIdentity={emailIdentity}
                      credentials={credentials}
                      marketplaceCnameWithoutWWW={marketplaceCnameWithoutWWW}
                      includePriorityColumn={true}
                      handleIfNoData={
                        <Card className="bp5-nopad">
                          <Callout title={'Building'} icon={'time'}>
                            Please wait, Redbox is generating the required DNS
                            records. This could take up to 1 hour.
                          </Callout>
                        </Card>
                      }
                    >
                      <DNSTRVerify
                        nameUserFriendly={'messaging'}
                        name={emailIdentity}
                        priority={'10'}
                        value={`inbound-smtp.${config.awsDefaultRegion}.amazonaws.com.`}
                        type="MX"
                      />
                    </DNSDKIM>
                  ) : (
                    <Card className="bp5-nopad">
                      <Callout
                        title={'Warning'}
                        icon={'warning-sign'}
                        intent="warning"
                      >
                        <p>
                          In order to enable Messaging, please define your
                          Marketplace Message Sender.
                        </p>

                        <AnchorButton
                          intent="warning"
                          minimal
                          text="Define Messaging Sender"
                          outlined
                          href={`/marketplaces/${marketplace.id}/message-templates`}
                        />
                      </Callout>
                    </Card>
                  )}

                  <H5>Email DNS</H5>
                  <DNSDKIM
                    emailIdentity={
                      marketplace.sesSourceArn
                        ? marketplace.sesSourceArn.split('/')[1]
                        : marketplaceCnameWithoutWWW
                    }
                    credentials={credentials}
                    marketplaceCnameWithoutWWW={marketplaceCnameWithoutWWW}
                    handleIfNoData={
                      <Card className="bp5-nopad">
                        {Date.parse(marketplace.createdAt) >
                        MARKETPLACE_SETUP_AUTOMATION_TIMESTAMP ? (
                          <Callout title={'Building'} icon={'time'}>
                            Please wait, Redbox is generating the required DNS
                            records. This could take up to 1 hour.
                          </Callout>
                        ) : (
                          <Callout title={'Not Available'} icon={'info-sign'}>
                            This Marketplace already exists. Please check your
                            emails are working as expected. If not, check your
                            DNS settings with your domain provider.
                          </Callout>
                        )}
                      </Card>
                    }
                  />
                  <DNSDKIM
                    emailIdentity={`marketing.${marketplaceCnameWithoutWWW}`}
                    credentials={credentials}
                    marketplaceCnameWithoutWWW={`marketing.${marketplaceCnameWithoutWWW}`}
                  />
                </Fragment>
              )
            }}
          </FetchQuery>
        )
      }}
    </Query>
  )
}

export default DNS
