import gql from 'graphql-tag'

const GET_OPENING_TIMES = gql`
  query getOutletOpeningTimesForEarlyClose($outletId: String!) {
    getOutlets(id: $outletId) {
      outlets {
        isOpen
        openingTimes
        closedUntil
      }
    }
  }
`

export default GET_OPENING_TIMES
