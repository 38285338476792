import { defaultColors } from '../constants/defaultColors'

const SHADE_ALTERATION_PERCENTAGE = 50

const generateNewShade = (currentShade, darkenOrLighten) => {
  // Remove the '#' symbol
  const currentShadeAsRGB = currentShade.replace('#', '')

  // Convert the hex color to RGB
  const red = parseInt(currentShadeAsRGB.substring(0, 2), 16)
  const green = parseInt(currentShadeAsRGB.substring(2, 4), 16)
  const blue = parseInt(currentShadeAsRGB.substring(4, 6), 16)

  // Calculate the new RGB values for the shade lighter or darker
  const lightenAmount =
    darkenOrLighten === 'lighten'
      ? 1 + SHADE_ALTERATION_PERCENTAGE / 100
      : 1 - SHADE_ALTERATION_PERCENTAGE / 100
  const newRed = Math.min(255, Math.floor(red * lightenAmount))
  const newGreen = Math.min(255, Math.floor(green * lightenAmount))
  const newBlue = Math.min(255, Math.floor(blue * lightenAmount))

  // Convert the new RGB values back to hex format
  const newHexColor =
    '#' +
    ((1 << 24) + (newRed << 16) + (newGreen << 8) + newBlue)
      .toString(16)
      .slice(1)

  return newHexColor
}

export const generateNewTheme = (brandColor, brandSecondaryColor) => ({
  colors: {
    ...defaultColors,
    brand: brandColor ? brandColor : defaultColors.brand,
    brandHighlight: brandSecondaryColor
      ? brandSecondaryColor
      : defaultColors.brandHighlight,
    brandDark: brandColor
      ? generateNewShade(brandColor, 'darken')
      : defaultColors.brandDark,
    brandLight: brandColor
      ? generateNewShade(brandColor, 'lighten')
      : defaultColors.brandLight,
  },
})
