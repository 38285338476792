import gql from 'graphql-tag'

const ADD_OPTION = gql`
  mutation addOption(
    $name: String!
    $minOptions: Int
    $maxOptions: Int
    $optionListIds: [String]
    $optionItems: [OptionItemInput]
    $restaurantId: String!
  ) {
    addOption(
      name: $name
      minOptions: $minOptions
      maxOptions: $maxOptions
      optionListIds: $optionListIds
      restaurantId: $restaurantId
      optionItems: $optionItems
    ) {
      message
      option {
        id
        name
        restaurant {
          id
        }
        minOptions
        maxOptions
        optionItems {
          id
          name
          price
          soldOut
        }
      }
    }
  }
`

export default ADD_OPTION
