import { ComponentProps } from 'react'
import { Link, To } from 'react-router-dom'

interface Props extends Omit<ComponentProps<typeof Link>, 'to'> {
  to?: To
}

/** This looks like it duplicates a React-Router Link.
 * But actually, it only wraps if the `to` prop is present,
 * so it's a nice way of dealing with content that may have a link, but
 * you wouldn't know till runtime.
 */
export const WrapInLink: React.FC<Props> = ({ children, ...otherProps }) => {
  const { to } = otherProps
  if (to !== undefined)
    return (
      <Link {...otherProps} to={to}>
        {children}
      </Link>
    )
  return children
}
