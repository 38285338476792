import gql from 'graphql-tag'

const CONNECT_MARKETPLACE_LINK = gql`
  mutation connectMarketplace($marketplaceId: ID!) {
    connectMarketplace(marketplaceId: $marketplaceId) {
      redirectUrl
      message
    }
  }
`

export default CONNECT_MARKETPLACE_LINK
