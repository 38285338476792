import * as yup from 'yup'
const nutritionRef = /PER_(PORTION|SERVING|SLICE|100G)/

export const nutritionStructure = {
  calories: yup.number(),
  carbohydrates: yup.number(),
  sugar: yup.number(),
  fat: yup.number(),
  protein: yup.number(),
  description: yup.string().matches(nutritionRef),
}
