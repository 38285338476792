import { Fragment, useState } from 'react'
import { element, object, string } from 'prop-types'
import { Button, HTMLTable, Card, Icon, Spinner } from '@blueprintjs/core'
import SESSendCommand from '@components/AWS/SESSendCommand'
import {
  SESClient,
  VerifyDomainDkimCommand,
  SetIdentityMailFromDomainCommand,
} from '@aws-sdk/client-ses'
import { errorToast } from '@utils/toast'
import DNSTRVerify from './DNSTRVerify'

const DNSDKIM = ({
  emailIdentity,
  credentials,
  marketplaceCnameWithoutWWW,
  handleIfNoData = <Fragment></Fragment>,
  includePriorityColumn = false,
  children = <Fragment></Fragment>,
}) => {
  const [isSubmittingRetry, setSubmittingRetry] = useState(false)
  if (isSubmittingRetry) return <Spinner size={60} />
  return (
    <SESSendCommand credentials={credentials} emailIdentity={emailIdentity}>
      {({ loading, data }) => {
        if (loading) {
          return <Spinner size={16} />
        }
        if (!data) {
          return handleIfNoData
        }
        return (
          <Fragment>
            <p>
              Verification Status of CNAME records:{' '}
              {
                {
                  FAILED: (
                    <Fragment>
                      <Icon icon="ban-circle" color="#DB0B0B" />
                      <Button
                        icon="refresh"
                        intent="danger"
                        text="Retry?"
                        minimal
                        loading={isSubmittingRetry}
                        onClick={async () => {
                          setSubmittingRetry(true)
                          const ses = new SESClient({
                            region: process.env.AWS_DEFAULT_REGION,
                            credentials,
                          })
                          const command = new VerifyDomainDkimCommand({
                            Domain: emailIdentity,
                          })
                          try {
                            await ses.send(command)
                          } catch (e) {
                            errorToast('Server error')
                          } finally {
                            setSubmittingRetry(false)
                          }
                        }}
                      />
                    </Fragment>
                  ),
                  PENDING: (
                    <div
                      style={{
                        display: 'inline-block',
                        marginLeft: 0,
                      }}
                    >
                      <Spinner size={16} />
                    </div>
                  ),
                  SUCCESS: <Icon icon="tick-circle" color="#5bb70d" />,
                  TEMPORARY_FAILURE: <Icon icon="help" />,
                  NOT_STARTED: <Icon icon="circle" />,
                }[data.DkimAttributes.Status]
              }
            </p>
            {data.MailFromAttributes.MailFromDomain ? (
              <p>
                Verification Status of MX and TXT records:{' '}
                {
                  {
                    FAILED: (
                      <Fragment>
                        <Icon icon="ban-circle" color="#DB0B0B" />
                        <Button
                          icon="refresh"
                          intent="danger"
                          text="Retry?"
                          minimal
                          loading={isSubmittingRetry}
                          onClick={async () => {
                            setSubmittingRetry(true)
                            const ses = new SESClient({
                              region: process.env.AWS_DEFAULT_REGION,
                              credentials,
                            })
                            const command =
                              new SetIdentityMailFromDomainCommand({
                                Identity: emailIdentity,
                                BehaviorOnMXFailure: 'UseDefaultValue',
                                MailFromDomain: `mail.${emailIdentity}`,
                              })
                            try {
                              await ses.send(command)
                            } catch (e) {
                              errorToast('Server error')
                            } finally {
                              setSubmittingRetry(false)
                            }
                          }}
                        />
                      </Fragment>
                    ),
                    PENDING: (
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: 0,
                        }}
                      >
                        <Spinner size={16} />
                      </div>
                    ),
                    SUCCESS: <Icon icon="tick-circle" color="#5bb70d" />,
                    TEMPORARY_FAILURE: <Icon icon="help" />,
                    NOT_STARTED: <Icon icon="circle" />,
                  }[data.MailFromAttributes.MailFromDomainStatus]
                }
              </p>
            ) : (
              <Fragment></Fragment>
            )}
            <Card className="bp5-nopad">
              <HTMLTable>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Host</th>
                    <th>{includePriorityColumn ? 'Priority' : ''}</th>
                    <th>Value</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {children}
                  {data.IdentityType === 'DOMAIN' && (
                    <Fragment>
                      <DNSTRVerify
                        nameUserFriendly={`${data.DkimAttributes.Tokens[0]}._domainkey.${emailIdentity}`.replace(
                          `.${marketplaceCnameWithoutWWW}`,
                          ''
                        )}
                        name={`${data.DkimAttributes.Tokens[0]}._domainkey.${emailIdentity}`}
                        value={`${data.DkimAttributes.Tokens[0]}.dkim.amazonses.com.`}
                        type="CNAME"
                      />
                      <DNSTRVerify
                        nameUserFriendly={`${data.DkimAttributes.Tokens[1]}._domainkey.${emailIdentity}`.replace(
                          `.${marketplaceCnameWithoutWWW}`,
                          ''
                        )}
                        name={`${data.DkimAttributes.Tokens[1]}._domainkey.${emailIdentity}`}
                        value={`${data.DkimAttributes.Tokens[1]}.dkim.amazonses.com.`}
                        type="CNAME"
                      />
                      <DNSTRVerify
                        nameUserFriendly={`${data.DkimAttributes.Tokens[2]}._domainkey.${emailIdentity}`.replace(
                          `.${marketplaceCnameWithoutWWW}`,
                          ''
                        )}
                        name={`${data.DkimAttributes.Tokens[2]}._domainkey.${emailIdentity}`}
                        value={`${data.DkimAttributes.Tokens[2]}.dkim.amazonses.com.`}
                        type="CNAME"
                      />
                    </Fragment>
                  )}
                  <DNSTRVerify
                    nameUserFriendly={`_dmarc.${emailIdentity}`.replace(
                      `.${marketplaceCnameWithoutWWW}`,
                      ''
                    )}
                    name={`_dmarc.${emailIdentity}`}
                    value={`"v=DMARC1; p=quarantine; pct=100; rua=mailto:dmarc@korelogic.co.uk"`}
                    type="TXT"
                  />
                  {data.MailFromAttributes.MailFromDomain ? (
                    <Fragment>
                      <DNSTRVerify
                        nameUserFriendly={`${data.MailFromAttributes.MailFromDomain}`.replace(
                          `.${marketplaceCnameWithoutWWW}`,
                          ''
                        )}
                        name={`${data.MailFromAttributes.MailFromDomain}`}
                        value={`10 feedback-smtp.${process.env.AWS_DEFAULT_REGION}.amazonses.com`}
                        type="MX"
                      />
                      <DNSTRVerify
                        nameUserFriendly={`${data.MailFromAttributes.MailFromDomain}`.replace(
                          `.${marketplaceCnameWithoutWWW}`,
                          ''
                        )}
                        name={`${data.MailFromAttributes.MailFromDomain}`}
                        value={`"v=spf1 include:amazonses.com ~all"`}
                        type="TXT"
                      />
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </tbody>
              </HTMLTable>
            </Card>
          </Fragment>
        )
      }}
    </SESSendCommand>
  )
}

DNSDKIM.propTypes = {
  emailIdentity: string,
  marketplaceCnameWithoutWWW: string,
  credentials: object,
  children: object,
  handleIfNoData: element,
}

export default DNSDKIM
