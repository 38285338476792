import React, { useContext } from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { BusinessAnalyticsContext } from '../../Restaurant/Analytics/BusinessAnalyticsContext'
import { StringParam, useQueryParam } from 'use-query-params'

const OutletSelector = ({ minimal = true, outlets }) => {
  const [outletId = 'All', onChangeOutletId] = useQueryParam(
    'outletId',
    StringParam
  )
  const { outlets: outletsFromContext } = useContext(BusinessAnalyticsContext)
  if (!outlets || outlets.length === 0) {
    outlets = outletsFromContext
  }

  const outletRenderer = (option, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        key={option.id}
        onClick={handleClick}
        multiline={true}
        text={option.name}
        icon={option.id === outletId ? 'tick' : 'blank'}
        disabled={!option.active}
      />
    )
  }

  return (
    <Select
      filterable={false}
      activeItem={outletId}
      items={[{ id: 'All', name: 'All Outlets', active: true }, ...outlets]}
      itemRenderer={outletRenderer}
      onItemSelect={item => onChangeOutletId(item.id)}
    >
      <Button text={'Filter by Outlet'} icon="filter" minimal={minimal} />
    </Select>
  )
}

export default OutletSelector
