import gql from 'graphql-tag'

const MARK_OUTLET_MENU_ITEM_SOLD_OUT = gql`
  mutation outletMenuItemSoldOutStatus(
    $outletId: String!
    $menuItemIds: [String!]!
    $areSoldOut: Boolean!
    $areHidden: Boolean!
    $menuId: ID!
    $soldOutUntil: [SoldOutUntil]
  ) {
    outletMenuItemSoldOutStatus(
      outletId: $outletId
      menuItemIds: $menuItemIds
      areSoldOut: $areSoldOut
      areHidden: $areHidden
      soldOutUntil: $soldOutUntil
    ) {
      message
      outlet {
        id
        soldOutUntilItems
        menuItemsGroups(where: { id: $menuId }) {
          id
          menuItems {
            id
            name
          }
        }
      }
    }
  }
`

export default MARK_OUTLET_MENU_ITEM_SOLD_OUT
