import gql from 'graphql-tag'

const DELAY_NETWORK_ORDER = gql`
  mutation delayNetworkOrder($id: String!, $newCompletionDateTime: DateTime!) {
    delayNetworkOrder(
      orderId: $id
      newCompletionDateTime: $newCompletionDateTime
    ) {
      message
      order {
        id
        orderStatus
        estimatedDeliveryDate
        estimatedCompletionTime
      }
    }
  }
`

export default DELAY_NETWORK_ORDER
