import gql from 'graphql-tag'
import { MENU_ITEM_FIELDS_AND_OPTION_ID_FRAGMENT } from '../fragments/menuItem.fragment'

export const EDIT_MENU_ITEM = gql`
  ${MENU_ITEM_FIELDS_AND_OPTION_ID_FRAGMENT}
  mutation menuImportEditMenuItem(
    $id: String!
    $menuItemGroupIds: [String]!
    $name: String
    $price: Int
    $minimumPurchasePrice: Int
    $costPrice: Int
    $description: String
    $optionIds: [String]
    $isVegan: Boolean
    $isVegetarian: Boolean
    $isDairyFree: Boolean
    $isGlutenFree: Boolean
    $isKeto: Boolean
    $spiceLevel: Int
    $ageRestricted: Boolean
    $SKU: String
    $UPC: String
  ) {
    editMenuItem(
      id: $id
      menuItemGroupIds: $menuItemGroupIds
      name: $name
      price: $price
      minimumPurchasePrice: $minimumPurchasePrice
      costPrice: $costPrice
      description: $description
      optionIds: $optionIds
      isVegan: $isVegan
      isVegetarian: $isVegetarian
      isDairyFree: $isDairyFree
      isGlutenFree: $isGlutenFree
      spiceLevel: $spiceLevel
      isKeto: $isKeto
      ageRestricted: $ageRestricted
      SKU: $SKU
      UPC: $UPC
    ) {
      message
      menuItem {
        ...menuItemFieldsAndOptionId
      }
    }
  }
`
