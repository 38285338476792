import { Classes, Button, Intent } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import ADD_PARTNER from './mutations/addPartner'
import GET_PARTNERS from '@components/Partners/queries/getPartners.query'
import PartnerShortForm from '@components/Partner/Details/PartnerForm/PartnerShortForm'
import { validation } from './validation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../../providers/ModalProvider'
import { useMutation } from '@apollo/client'

const PartnerModal: React.FC<{
  modalOpen: boolean
  modalClose: () => void
}> = ({ modalOpen, modalClose }) => {
  const navigate = useNavigate()
  const { toggleModal } = useModal('partnerModal')

  const [addPartner] = useMutation(ADD_PARTNER, {
    onError: defaultErrorHandler,
    onCompleted: ({ addPartner: { partner } }) => {
      navigate(`/partners/${partner.id}`)
      toggleModal(false)
    },
    refetchQueries: [{ query: GET_PARTNERS }],
  })
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Partner">
      <Formik
        onSubmit={values => {
          addPartner({
            variables: {
              ...values,
            },
          })
        }}
        initialValues={{
          name: '',
          companyLegalName: '',
          companyNumber: '',
          contactAddress: {
            firstLine: '',
            secondLine: '',
            thirdLine: '',
            city: '',
            country: { code: 'GB', name: 'United Kingdom' },
            postcode: '',
          },
          contactPhone: '',
          contactEmail: '',
          contactName: '',
        }}
        validationSchema={validation}
      >
        {props => {
          const { handleSubmit } = props

          return (
            <form onSubmit={handleSubmit}>
              <div className={Classes.DIALOG_BODY}>
                <PartnerShortForm
                  disabled={false}
                  editable={false}
                  modal={true}
                  {...props}
                />
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Save" intent={Intent.NONE} type="submit" />
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default PartnerModal
