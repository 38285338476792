import gql from 'graphql-tag'

const ADD_LINK_CARD = gql`
  mutation addLinkCard(
    $marketplaceId: String!
    $name: String
    $description: String
    $image: String
    $url: String
    $type: LinkCardType
    $outletId: String
    $menuItemId: String
  ) {
    addLinkCard(
      marketplaceId: $marketplaceId
      name: $name
      description: $description
      image: $image
      url: $url
      type: $type
      outletId: $outletId
      menuItemId: $menuItemId
    ) {
      id
      name
      description
      type
      url
      outlet {
        id
        name
      }
      menuItem {
        id
        name
      }
      segments {
        id
        name
      }
      createdAt
      cardImage {
        id
        caption
        position
        image {
          id
          src
        }
      }
    }
  }
`

export default ADD_LINK_CARD
