import { Intent } from '@blueprintjs/core'
import { AppearanceOptions } from '@stripe/connect-js'

export const stripeAccountSessionAppearanceLight: AppearanceOptions = {
  overlays: 'drawer',
  variables: {
    colorBorder: '#d9dadb',
    borderRadius: '3px',
    buttonBorderRadius: '3px',
    colorPrimary: '#ff3b4c',
    colorText: '#182026',
    colorSecondaryText: '#5c7080',
    buttonPrimaryColorBackground: '#f5f8fa',
    buttonPrimaryColorBorder: '#b5b8bb',
    buttonPrimaryColorText: '#182026',
    overlayBorderRadius: '0px',
    offsetBackgroundColor: '#ecf0f3',
  },
}

export const stripeAccountSessionAppearanceDark: AppearanceOptions = {
  overlays: 'drawer',
  variables: {
    colorBorder: '#1f2a32',
    borderRadius: '3px',
    buttonBorderRadius: '3px',
    colorPrimary: '#ff5962', // Adjusted to pop against dark backgrounds - said chatgpt
    colorText: '#f5f8fa',
    colorSecondaryText: '#a7b6c2',
    buttonPrimaryColorBackground: '#425360',
    buttonPrimaryColorBorder: '#28363f',
    buttonPrimaryColorText: '#ffffff',
    overlayBorderRadius: '0px',
    offsetBackgroundColor: '#3e4f5d',
  },
}

// eslint-disable-next-line no-undef
export const WEBHOOK_STATUS_TO_INTENT: Record<string, Intent> = {
  CONNECTED: 'success',
  ENABLED: 'none',
  DISABLED: 'warning',
}
