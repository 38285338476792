import gql from 'graphql-tag'

const EDIT_MARKETPLACE = gql`
  mutation editMarketplaceDetails(
    $id: String!
    $companyType: CompanyType
    $companyLegalName: String
    $companyNumber: String
    $taxReference: String
    $contactName: String!
    $contactAddress: AddressCreateInput!
    $contactPhone: String!
    $contactEmail: String!
    $contactDoB: DateTime
    $supportPhone: String
    $supportEmail: String
    $partnerId: String!
    $defaultCancelText: String
    $defaultRejectText: String
    $storeURLApple: String
    $storeURLGooglePlay: String
    $trackingId: String
    $cname: String
    $brandColor: String
    $orderMode: OrderingMode!
    $merchantType: MerchantType!
    $ageRestrictionText: String
    $checkoutText: String
    $orderNoteText: String
    $deliveryNoteText: String
    $collectionNoteText: String
    $specialInstructionsText: String
    $tableNoteText: String
    $launchedAt: DateTime
    $emailReceiptOnOrder: Boolean
    $onboardingDescription: String
    $pinpointAwsAccountId: String
    $urlPath: String
    $ga4Id: String
    $outletOpenStatus: OutletOpenStatus!
  ) {
    editMarketplace(
      id: $id
      companyType: $companyType
      companyLegalName: $companyLegalName
      companyNumber: $companyNumber
      taxReference: $taxReference
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      contactDoB: $contactDoB
      supportPhone: $supportPhone
      supportEmail: $supportEmail
      partnerId: $partnerId
      defaultCancelText: $defaultCancelText
      defaultRejectText: $defaultRejectText
      storeURLApple: $storeURLApple
      storeURLGooglePlay: $storeURLGooglePlay
      trackingId: $trackingId
      cname: $cname
      brandColor: $brandColor
      orderMode: $orderMode
      merchantType: $merchantType
      ageRestrictionText: $ageRestrictionText
      checkoutText: $checkoutText
      orderNoteText: $orderNoteText
      deliveryNoteText: $deliveryNoteText
      specialInstructionsText: $specialInstructionsText
      collectionNoteText: $collectionNoteText
      tableNoteText: $tableNoteText
      launchedAt: $launchedAt
      emailReceiptOnOrder: $emailReceiptOnOrder
      onboardingDescription: $onboardingDescription
      pinpointAwsAccountId: $pinpointAwsAccountId
      urlPath: $urlPath
      ga4Id: $ga4Id
      outletOpenStatus: $outletOpenStatus
    ) {
      message
      marketplace {
        id
        image
        companyType
        companyLegalName
        companyNumber
        taxReference
        contactName
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        contactPhone
        contactEmail
        contactDoB
        supportPhone
        supportEmail
        partner {
          id
        }
        stripeId
        stripePublicId
        partnerFee
        partnerCharge
        partnerChargeDescription
        defaultCancelText
        defaultRejectText
        storeURLApple
        storeURLGooglePlay
        orderMode
        merchantType
        urlPath
        trackingId
        cname
        brandColor
        ageRestrictionText
        checkoutText
        orderNoteText
        deliveryNoteText
        collectionNoteText
        specialInstructionsText
        tableNoteText
        launchedAt
        emailReceiptOnOrder
        onboardingDescription
        pinpointAwsAccountId
        ga4Id
        outletOpenStatus
      }
    }
  }
`

export default EDIT_MARKETPLACE
