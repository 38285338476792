import { WrapInLink } from '@src/utils/wrapInLink'
import { Change } from './Change'
import {
  BaseColumn,
  ColumnRenderer,
  ColumnSorter,
  FALLBACK_PLACEHOLDER,
} from './column.types'
import { CSSProperties } from 'react'

export interface PercentageColumn<X extends string>
  extends BaseColumn<'percentage', X> {
  outOf100?: boolean
  decimalPlaces?: number
  biggerIsWorse?: boolean
}

export type PercentageColumnData = {
  value: number | null | undefined
  priorValue?: number | null
  link?: string
  style?: CSSProperties
}

export const sortPercentageColumn: ColumnSorter<'percentage'> = (
  a,
  b,
  order
) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * ((b.value ?? -Infinity) - (a.value ?? -Infinity))
}

export const PercentageColumnContent: ColumnRenderer<'percentage', string> = ({
  columnDefinition: {
    placeholder,
    decimalPlaces = 0,
    outOf100 = false,
    biggerIsWorse = false,
  },
  columnData: { link, value, priorValue, style },
}) => {
  if (
    (value === null || value === undefined) &&
    (priorValue === null || priorValue === undefined)
  )
    return placeholder ?? FALLBACK_PLACEHOLDER

  let displayOutput = placeholder ?? FALLBACK_PLACEHOLDER

  if (value !== null && value !== undefined) {
    displayOutput =
      (outOf100 ? value : value * 100).toFixed(decimalPlaces) + '%'
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span style={style}>
        <WrapInLink to={link}>{displayOutput}</WrapInLink>
      </span>
      <Change
        value={value}
        priorValue={priorValue}
        numberStyle="percentage"
        biggerIsWorse={biggerIsWorse}
        asPercentage={false}
      />
    </div>
  )
}
