import background from '@assets/background.jpg'
import { Card } from '@blueprintjs/core'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-image: url(${background});
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
`

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

export const LogoContainer = styled.img`
  height: 111px;
  width: 89px;
`

export const LoginFormCard = styled(Card)`
  width: 340px;
  margin-bottom: 20px;
`

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`
