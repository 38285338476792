import startCase from 'lodash/startCase'
import moment from 'moment'
import { Tag, Intent } from '@blueprintjs/core'
import React from 'react'

const nameMapping = {
  Recency: 'Customer',
  orderedFromRestaurant: 'Ordered From Business',
}

export const comparatorMapping = {
  ACTIVE: 'active in',
  INACTIVE: 'not active in',
  INCLUSIVE: 'is',
  EXCLUSIVE: 'is not',
  AFTER: 'is after',
  BEFORE: 'is before',
  BETWEEN: 'between',
  EQUAL: '=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  CONTAINS: 'contains',
}

/**
 * @param {string} value
 * @param {string} name
 */
const formatValue = (value, name) => {
  if (name === 'lastOrder') {
    return moment(value).format('dddd, MMM Do YYYY')
  }

  const nonStartCaseKeys = ['email', 'postcode']
  if (nonStartCaseKeys.includes(name)) {
    return value
  }
  const valueMapping = {
    HR_24: 'last 24 hours',
    DAY_7: 'last 7 days',
    DAY_14: 'last 14 days',
    DAY_30: 'last 30 days',
  }

  return valueMapping[value] || startCase(value)
}

const HumanReadableFilters = ({ filterGroups, filterGroupsType }) => {
  if (!filterGroups || !filterGroups.length) {
    return ''
  }

  const componentArray = filterGroups.map(filterGroup => {
    if (!filterGroup.filters.length) return ''

    return filterGroup.filters
      .map(filter => {
        const { attribute, comparator, value } = filter

        const [, name] = attribute.split('.')

        return (
          <span key={name + value}>
            <Tag minimal intent={Intent.PRIMARY}>
              {nameMapping[name] || startCase(name)}
            </Tag>{' '}
            {comparatorMapping[comparator]}{' '}
            <Tag minimal>{formatValue(value, name)}</Tag>
          </span>
        )
      })
      .reduce((components, component) => [
        components,
        filterGroup.filterGroupType === 'ALL' ? (
          <span> and </span>
        ) : (
          <span> or </span>
        ),
        component,
      ])
  })

  return componentArray.reduce((components, component) => [
    components,
    filterGroupsType === 'ALL' ? <span> and </span> : <span> or </span>,
    component,
  ])
}

export default HumanReadableFilters
