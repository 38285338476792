import { Button, Checkbox, Popover, Position, Tag } from '@blueprintjs/core'
import { array, bool, func } from 'prop-types'
import styled from 'styled-components'

const PopupStyled = styled.div`
  max-height: 500px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`

const CheckBoxStyled = styled(Checkbox)`
  margin: 0px 16px 10px;
`

const LoyaltyStatusFilter = ({ disabled, statusFilter, onChange }) => {
  const statuses = [
    { name: 'Active', key: 'ACTIVE' },
    { name: 'Deactivated', key: 'DEACTIVATED' },
    { name: 'Suspended', key: 'SUSPENDED' },
  ]

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      boundary="window"
      disabled={disabled}
      content={
        <PopupStyled>
          {statuses.map(({ name, key }) => (
            <CheckBoxStyled
              key={key}
              id={key}
              label={name}
              alignIndicator="left"
              onChange={onChange}
              checked={statusFilter.includes(key)}
            />
          ))}
        </PopupStyled>
      }
    >
      <Button
        rightIcon={
          statusFilter.length > 0 ? (
            <Tag intent="primary">{statusFilter.length}</Tag>
          ) : (
            'double-caret-vertical'
          )
        }
        disabled={disabled}
      >
        Status
      </Button>
    </Popover>
  )
}

LoyaltyStatusFilter.propTypes = {
  onChange: func,
  fulfilmentFilter: array,
  disabled: bool,
}

export default LoyaltyStatusFilter
