import gql from 'graphql-tag'
const ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_LOYALTY_CARD = gql`
  query marketplaceOrdersWithLoyaltyCard(
    $marketplaceId: String!
    $discountId: String!
  ) {
    marketplaceOrdersWithLoyaltyCard(
      marketplaceId: $marketplaceId
      discountId: $discountId
    ) {
      discountId
      outletId
      discountAmount
      fulfillmentMethod
      subtotalGross
      customerId
      paymentMethod
      id
      loyaltyCard
    }
  }
`

export default ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_LOYALTY_CARD
