import React, { Fragment } from 'react'
import Handlebars from 'handlebars'
import { Callout, Intent } from '@blueprintjs/core'

const HTMLPushPreview = ({ html, exampleData }) => {
  const template = Handlebars.compile(html)
  let substitutedTemplate

  try {
    substitutedTemplate = template(exampleData)
  } catch (_e) {
    /// do nothing
  }

  return (
    <Fragment>
      {substitutedTemplate ? null : (
        <Callout intent={Intent.WARNING}>
          There was an error substituting variables, check for any unclosed
          brackets
        </Callout>
      )}
      <div>{substitutedTemplate || html}</div>
    </Fragment>
  )
}

export default HTMLPushPreview
