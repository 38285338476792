import { Tree } from '@blueprintjs/core'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  .selected {
    background-color: ${({ theme }) => theme.colors.menuSelect};
    color: ${({ theme }) => theme.colors.menuSelectText};
    border: 0;

    & .bp5-icon {
      color: ${({ theme }) => theme.colors.menuSelectText};
    }
  }

  .hidden {
    opacity: 0.4;
  }
  .item {
    &:first-child {
      border-top: 0;
    }
  }
  .add {
    border-top: 1px solid ${({ theme }) => theme.colors.borderColor};

    & .bp5-icon {
      padding: 4px 1px 4px 7px;
    }

    & .bp5-tree-node-caret-none {
      min-width: 0;
    }
  }
`

export const StyledTree = styled(Tree)`
  border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 33%;
  min-width: 200px;

  & .bp5-tree-node-caret {
    color: ${({ theme }) => theme.colors.primaryNavActiveColor};
  }

  & .bp5-tree-node-caret-none {
    min-width: 10px;
  }

  & .bp5-tag {
    font-size: 11px;
  }
`

export const SecondaryTree = styled(StyledTree)`
  border-left: 0;
`

export const Field = styled.div`
  &:hover {
    border-left: 3px solid ${({ theme }) => theme.colors.primaryNavActiveColor};
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
`

export const BottomBorder = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const EditButton = styled.button`
  &:hover {
    color: ${({ theme }) => theme.colors.highlight};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.padding.small};
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.highlight};
`

export const MenuModal = styled.div`
  width: 600px;
`

export const SubMenu = styled.div`
  border-left: 0;
`
