import { StringParam, useQueryParam } from 'use-query-params'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'
import { Link } from 'react-router-dom'

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export interface OrderColumn<X extends string> extends BaseColumn<'order', X> {}

export interface OrderColumnData {
  id: string
  orderNumber: string
}

export const sortOrderColumn: ColumnSorter<'order'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return negator * collator.compare(b.orderNumber ?? '', a.orderNumber ?? '')
}

export const OrderColumnContent: ColumnRenderer<'order', string> = ({
  columnData,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_viewOrder, setViewOrder] = useQueryParam('viewOrder', StringParam)

  return (
    <Link
      to="#"
      onClick={event => {
        event.preventDefault()
        setViewOrder(columnData.id)
      }}
    >
      #{columnData.orderNumber}
    </Link>
  )
}
