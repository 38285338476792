import gql from 'graphql-tag'

const ADD_TABLE = gql`
  mutation addTable($outletId: String!, $friendlyName: String!) {
    addTable(outletId: $outletId, friendlyName: $friendlyName) {
      id
      friendlyName
      active
    }
  }
`

export default ADD_TABLE
