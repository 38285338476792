import gql from 'graphql-tag'

export const GET_OPTION_ITEMS_FOR_DOWNLOAD = gql`
  query getOptions($restaurantId: String) {
    getRestaurants(id: $restaurantId) {
      restaurants {
        id
        name
      }
    }
    getOptions(restaurantId: $restaurantId) {
      options {
        id
        name
        position
        minOptions
        maxOptions
        parentMenu {
          id
          name
        }
        optionItems {
          id
          name
          price
          position
          isVegan
          isDairyFree
          isVegetarian
          isGlutenFree
          ageRestricted
        }
      }
    }
  }
`
