import { useState } from 'react'
import { successToast, errorToast } from '@utils/toast'
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  Classes,
  Spinner,
  FormGroup,
  InputGroup,
  Button,
  Intent,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import CREATE_SEGMENT from '../../mutations/createSegment.mutation'
import { addSegmentStructure } from '@validation/segment'

import { string, bool, func } from 'prop-types'
import GET_SEGMENTS_FOR_MARKETPLACE from '@src/pages/Marketing/queries/getSegmentsForMarketplace.query'
import { useMutation } from '@apollo/client'

const CreateSegmentModal = ({ marketplaceId, modalOpen, closeModal }) => {
  const [loading, setLoading] = useState(false)

  const [createSegment] = useMutation(CREATE_SEGMENT, {
    onCompleted: ({ addSegment }) => {
      const { segment } = addSegment

      if (segment.id) {
        successToast(`Saved ${segment.name} segment`)
        closeModal(segment.id)
      } else {
        errorToast(`Error saving segment`)
      }
    },
    refetchQueries: [
      {
        query: GET_SEGMENTS_FOR_MARKETPLACE,
        variables: {
          marketplaceId,
          first: 20,
          skip: 0,
        },
      },
    ],
  })

  return (
    <Dialog isOpen={modalOpen} onClose={closeModal} title="Create Segment">
      {loading ? (
        <Spinner />
      ) : (
        <div className={Classes.DIALOG_BODY}>
          <Formik
            initialValues={{
              name: '',
            }}
            onSubmit={async values => {
              setLoading(true)
              await createSegment({
                variables: {
                  ...values,
                  marketplaceId,
                },
              })
            }}
            validationSchema={yup.object().shape(addSegmentStructure)}
          >
            {({ values, handleChange, handleSubmit, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <p className="bp5-text-muted">
                    A segment is a group of users that match certain filters.
                    You can use a segment as a target for a marketing campaign.
                  </p>
                  <br />
                  <FormGroup
                    label="Name"
                    labelInfo="(required)"
                    helperText={errors.name || ''}
                  >
                    <InputGroup
                      name="name"
                      type="text"
                      value={values.name}
                      placeholder="All Customers"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.NONE} text="Create" type="submit" />
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>
      )}
    </Dialog>
  )
}

CreateSegmentModal.propTypes = {
  marketplaceId: string.isRequired,
  modalOpen: bool.isRequired,
  closeModal: func.isRequired,
}

export default CreateSegmentModal
