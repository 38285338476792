import React, { useState } from 'react'
import {
  FormGroup,
  InputGroup,
  Button,
  Tooltip,
  Intent,
} from '@blueprintjs/core'
import PhoneInput from '@components/PhoneInput/PhoneInput'

const RegisterForm = ({
  handleChange,
  values,
  isSubmitting,
  mutation,
  data,
  errors,
  isValid,
  setFieldValue,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const lockButton = (
    <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
      <Button
        icon={showPassword ? 'unlock' : 'lock'}
        intent={Intent.WARNING}
        minimal={true}
        onClick={() => setShowPassword(!showPassword)}
      />
    </Tooltip>
  )
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        mutation({
          variables: { ...values },
        })
      }}
    >
      <FormGroup label="Email">
        <InputGroup
          value={data.email}
          name="email"
          type="text"
          disabled={true}
          large="true"
        />
      </FormGroup>
      <FormGroup label="First name">
        <InputGroup
          value={values.firstName}
          name="firstName"
          type="text"
          onChange={handleChange}
          large="true"
          intent={errors.firstName ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <FormGroup label="Last name">
        <InputGroup
          value={values.lastName}
          name="lastName"
          type="text"
          onChange={handleChange}
          intent={errors.lastName ? Intent.DANGER : Intent.NONE}
          large="true"
        />
      </FormGroup>
      <FormGroup
        label="Phone Number"
        helperText={errors.phoneNumber ? errors.phoneNumber : ''}
        intent={Intent.DANGER}
      >
        <PhoneInput
          value={values.phoneNumber}
          inputProps={{ name: 'phoneNumber' }}
          onChange={phone => {
            setFieldValue('phoneNumber', phone)
          }}
        />
      </FormGroup>
      <FormGroup label="Password">
        <InputGroup
          value={values.password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          rightElement={lockButton}
          name="password"
          large="true"
        />
      </FormGroup>
      <FormGroup label="Confirm password">
        <InputGroup
          value={values.confirmPassword}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          name="passwordConfirm"
          intent={errors.confirmPassword ? Intent.DANGER : Intent.NONE}
          large="true"
        />
      </FormGroup>
      <FormGroup>
        <Button
          type="submit"
          disabled={!isValid || isSubmitting}
          text="Register"
        />
      </FormGroup>
    </form>
  )
}

export default RegisterForm
