const fields = {
  id: {
    id: 'id',
    label: 'ID',
    type: 'text',
    visible: false,
    export: true,
    width: 100,
    drawer: false,
  },
  name: {
    id: 'name',
    label: 'Name',
    type: 'text',
    visible: true,
    export: true,
    width: 200,
    drawer: false,
  },
  minOptions: {
    id: 'minOptions',
    label: 'Min Options',
    type: 'number',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
    defaultValue: 0,
  },
  maxOptions: {
    id: 'maxOptions',
    label: 'Max Options',
    type: 'number',
    visible: true,
    export: true,
    width: 100,
    drawer: false,
    defaultValue: 1,
  },
}

const getHeader = () => Object.keys(fields)

const menuOptionsToCSV = options => {
  const columns = getHeader()
  const columnHeadingsAsCSV = columns.join(',')

  const optionsAsCSV = options.map(option => {
    const formatedOption = {
      id: `${option.id}`,
      name: `"${option.name}"`,
      minOptions: `${option.minOptions}`,
      maxOptions: `${option.maxOptions}`,
    }
    return columns.map(column => formatedOption[column]).join(',')
  })

  return [[columnHeadingsAsCSV, ...optionsAsCSV].join('\n')]
}

export const MenuOptionDefinition = {
  fields,
  getHeader,
  menuOptionsToCSV,
}
