import React, { Fragment } from 'react'
import times from 'lodash/times'
import { ReactSVG } from 'react-svg'
import star from '@assets/stars/star.svg'
import halfStar from '@assets/stars/star-half.svg'
import emptyStar from '@assets/stars/star-empty.svg'
import { object, string, number } from 'prop-types'
import styled from 'styled-components'

export const SvgStar = styled(ReactSVG)`
  width: 1rem;
  margin-right: 2px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StarsInRow = styled.div`
  display: flex;
  flex-direction: row;
`

const StarsRating = ({ title, starsNumber, maxStarsNumber = 6 }) => {
  // full stars
  let nmbFullStars = Math.floor(starsNumber / 1)

  // half stars
  let nmbHalfStars = 0
  const remainder = starsNumber % 1
  if (remainder > 0.2 && remainder <= 0.7) {
    // convert .4 into one half a star
    nmbHalfStars = 1
  } else if (remainder > 0.7) {
    // convert .9 into a full star
    nmbFullStars += 1
  }

  // empty stars
  const nmbEmptyStars = maxStarsNumber - nmbFullStars - nmbHalfStars

  return (
    <Container>
      {title}
      <StarsInRow>
        {times(nmbFullStars, index => (
          <SvgStar key={index} src={star} />
        ))}
        {times(nmbHalfStars, index => (
          <SvgStar key={index} src={halfStar} />
        ))}
        {times(nmbEmptyStars, index => (
          <SvgStar key={index} src={emptyStar} />
        ))}
      </StarsInRow>
    </Container>
  )
}

StarsRating.propTypes = {
  title: string,
  starsNumber: number,
  maxStarsNumber: number,
}

export default StarsRating
