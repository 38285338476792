import gql from 'graphql-tag'

const ADD_APP = gql`
  mutation addApp(
    $appcenterAppNameAndroid: String
    $appcenterAppNameiOS: String
    $appleTeamId: String
    $googleJsonS3KeyPath: String
    $marketplaceId: String!
  ) {
    addApp(
      appcenterAppNameAndroid: $appcenterAppNameAndroid
      appcenterAppNameiOS: $appcenterAppNameiOS
      appleTeamId: $appleTeamId
      googleJsonS3KeyPath: $googleJsonS3KeyPath
      marketplaceId: $marketplaceId
    ) {
      app {
        id
        appcenterAppNameAndroid
        appcenterAppNameiOS
        appleTeamId
        googleJsonS3KeyPath
        marketplace {
          id
        }
      }
    }
  }
`

export default ADD_APP
