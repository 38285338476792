import gql from 'graphql-tag'

const GET_PLATFORM = gql`
  query getPlatform {
    getPlatform {
      message
      platform {
        id
        name
        contactName
        contactPhone
        contactEmail
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        payboxId
        payboxAccessId
        payboxPublicId
        payboxClientId
        stripeId
        stripeAccessId
        stripePublicId
        stripeClientId
        platformVat
        platformCurrency
        orderHoldIntervalOverride
        vatNumber
        stripeFee
        stripeCharge
        stripePresentFee
        stripePresentCharge
        stripeConnectCharge
        stripeDebitFee
        stripeInstantPayoutFee
        stripePayoutFee
        stripePayoutCharge
        stripeChargebackCharge
        otterClientId
        otterClientSecret
        otterWebhookSecret
      }
    }
  }
`

export default GET_PLATFORM
