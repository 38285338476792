import gql from 'graphql-tag'

const ALLOW_MARKETING = gql`
  mutation allowMarketing($id: String!, $allowMarketing: Boolean!) {
    editMarketplace(id: $id, allowMarketing: $allowMarketing) {
      message
      marketplace {
        id
        allowMarketing
      }
    }
  }
`

export default ALLOW_MARKETING
