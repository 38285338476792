import React from 'react'
import Form from '@components/TerminalForm/Form'
import { Formik } from 'formik'
import { func, object, bool, string } from 'prop-types'

const TerminalForm = ({
  onSubmit,
  initialValues,
  validationSchema,
  edit,
  outlet,
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ ...initialValues, edit }}
    validationSchema={validationSchema}
  >
    {props => <Form {...props} edit={edit} outlet={outlet} />}
  </Formik>
)

TerminalForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
  edit: bool,
  restaurantId: string.isRequired,
  outlet: string,
}

export default TerminalForm
