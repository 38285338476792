import gql from 'graphql-tag'

const EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS = gql`
  mutation featureOrPromoteOutlet(
    $outletId: String!
    $feature: Boolean
    $promote: Boolean
  ) {
    featureOrPromoteOutlet(
      outletId: $outletId
      feature: $feature
      promote: $promote
    ) {
      message
      outlet {
        id
        featured
      }
    }
  }
`

export default EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS
