import { Icon, Spinner, Tooltip } from '@blueprintjs/core'
import { canView } from '@stores/userStore'
import React from 'react'

const MemberDiscountsRow = ({
  discount: {
    id,
    name,
    discountPercentage,
    customerEnrolled,
    customer,
    customerEmail,
  },
  onChangeDiscountId,
}) => (
  <tr>
    <td width="30" style={{ textAlign: 'center' }}>
      {customerEnrolled ? (
        <Icon icon="tick" iconSize={12} />
      ) : (
        <Tooltip content="Waiting for customer to accept invite">
          <Spinner size={14} />
        </Tooltip>
      )}
    </td>
    <td onClick={() => onChangeDiscountId(id)}>
      <a>{name}</a>
    </td>
    <td>{discountPercentage}%</td>
    <td>
      {customer ? (
        canView('customers') ? (
          <a
            href={`/customer/${customer.id}/details`}
          >{`${customer.firstName} ${customer.lastName}`}</a>
        ) : (
          `${customer.firstName} ${customer.lastName}`
        )
      ) : (
        '--'
      )}
    </td>
    <td>{(customer && customer.email) || customerEmail || '--'}</td>
  </tr>
)

export default MemberDiscountsRow
