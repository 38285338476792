import {
  Button,
  Card,
  FormGroup,
  H5,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  TextArea,
} from '@blueprintjs/core'
import { DateRangeInput3 } from '@blueprintjs/datetime2'
import { FieldArray } from 'formik'
import moment from 'moment'
import { Fragment, useState } from 'react'
import shortid from '@utils/shortid'
import { checkForBlankInterval } from '../../../utils/helpers'
import AvailabilityTimeSelect from '../../Restaurant/Menu/modals/AvailabilityTimeSelect'
import { LinkCardSection } from '@components/Marketplace/Segments/LinkCardSection'
import { OutletFilterSection } from '@components/Marketplace/Segments/OutletFiltersSection'

const EditSegmentForm = ({
  values,
  errors,
  isValid,
  dirty,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldError,
  marketplaceId,
  isSubmitting,
}) => {
  const [showAddModal, setShowAddModal] = useState(false)

  const BLANK_INTERVAL = {
    start: { day: '1', time: '00:00' },
    end: { day: '7', time: '23:59' },
    key: shortid.generate(),
  }

  const attributes = [
    {
      label: 'Category',
      value: 'category',
      comparators: [
        { label: 'contains', value: 'IS' },
        { label: 'is not', value: 'IS_NOT' },
      ],
    },
    {
      label: 'Outlet',
      value: 'outlet',
      comparators: [
        { label: 'is', value: 'IS' },
        { label: 'is not', value: 'IS_NOT' },
      ],
      values: [],
    },
    {
      label: 'Discounts',
      value: 'discounts',
      comparators: [
        { label: 'available', value: 'IS' },
        { label: 'unavailable', value: 'IS_NOT' },
      ],
    },
    {
      label: 'Featured',
      value: 'featured',
      comparators: [
        { label: 'is featured', value: 'IS' },
        { label: 'is not featured', value: 'IS_NOT' },
      ],
    },
  ]

  const sortByAttributes = [
    { label: 'Distance', value: 'DISTANCE' },
    { label: 'Time', value: 'TIME' },
    { label: 'Min. Order', value: 'MIN_ORDER' },
    { label: 'Open Status', value: 'OPEN_STATUS' },
    { label: 'Alphabetical', value: 'ALPHABETICAL' },
  ]

  const linkCardSortByAttributes = [
    { label: 'Random', value: 'RANDOM' },
    { label: 'Position', value: 'POSITION' },
  ]

  return (
    <form onSubmit={handleSubmit} className="bp5-drawer-form">
      <div className={'bp5-drawer-content'}>
        <H5>Displays</H5>
        <FormGroup
          label="Title"
          labelInfo="(required)"
          helperText={errors && errors.name}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="name"
            type="text"
            value={values && values.name}
            onChange={handleChange}
            large
          />
        </FormGroup>
        <FormGroup
          label="Description"
          helperText={
            (errors && errors.description) ||
            "Description shows underneath your segment's title."
          }
          labelInfo="200 Character Limit (Optional)"
          intent={errors && errors.description ? Intent.DANGER : Intent.NONE}
        >
          <TextArea
            onChange={handleChange}
            name="description"
            style={{ resize: 'none', height: '60px' }}
            fill={true}
            id="description"
            value={values.description || ''}
          />
        </FormGroup>

        <FormGroup
          label="Limit Cards"
          subLabel="Maximum outlets displayed at a time."
        >
          <Card>
            <Slider
              name="outletLimit"
              min={1}
              max={20}
              labelStepSize={1}
              stepSize={1}
              value={values.outletLimit}
              onChange={val => setFieldValue('outletLimit', val)}
            />
          </Card>
        </FormGroup>
        <FormGroup
          label="Sorting"
          helperText="Control display order of the Cards."
        >
          <HTMLSelect
            name={`orderBy`}
            options={[
              ...sortByAttributes.map(attribute => ({
                ...attribute,
                key: attribute.value,
              })),
            ]}
            value={values.orderBy}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup label="Link Card Sorting">
          <HTMLSelect
            name={`linkCardOrderBy`}
            options={[
              ...linkCardSortByAttributes.map(attribute => ({
                ...attribute,
                key: attribute.value,
              })),
            ]}
            value={values.linkCardOrderBy}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup
          label="Display Options"
          helperText="Choose how to display the Card."
        >
          <Switch
            label={'Show special offer flags'}
            checked={values.showFeaturedFlags}
            onChange={handleChange}
            name="showFeaturedFlags"
          />
          <Switch
            label={'Show discount labels'}
            checked={values.showDiscountLabels}
            onChange={handleChange}
            name="showDiscountLabels"
          />
          <Switch
            label={'Show segment in app'}
            checked={values.showOnApp}
            onChange={handleChange}
            name="showOnApp"
          />
          <Switch
            label={'Show segment on website'}
            checked={values.showOnWeb}
            onChange={handleChange}
            name="showOnWeb"
          />
          <Switch
            label={'Refine outlets by service component selection'}
            checked={values.filterSegment}
            onChange={handleChange}
            name="filterSegment"
          />
          <Switch
            label={'Link to segment index'}
            checked={values.linkToSegmentIndex}
            onChange={handleChange}
            name="linkToSegmentIndex"
          />
        </FormGroup>

        <LinkCardSection
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          marketplaceId={marketplaceId}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          handleChange={handleChange}
        />

        <br />
        <OutletFilterSection
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          attributes={attributes}
          marketplaceId={marketplaceId}
        />

        <H5>Schedule</H5>
        <RadioGroup
          name="limitedAvailability"
          label="Availability Date Range"
          inline={false}
          onChange={e => {
            setFieldValue('limitedAvailability', e.target.value)
          }}
          selectedValue={values.limitedAvailability}
        >
          <Radio label="Always Available" value="ALWAYS_AVAILABLE" />
          <Radio label="Scheduled" value="SCHEDULED" />
        </RadioGroup>
        {values.limitedAvailability === 'SCHEDULED' && (
          <FormGroup
            labelFor="availabilityDates"
            helperText={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? errors.availabilityStartDate || errors.availabilityEndDate
                : 'Only show this segment between certain dates.'
            }
            intent={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? Intent.DANGER
                : Intent.NONE
            }
          >
            <DateRangeInput3
              formatDate={date => moment(date).format('DD/MM/YYYY HH:mm')}
              allowSingleDayRange={true}
              closeOnSelection={true}
              shortcuts={false}
              onChange={dates => {
                setFieldValue(
                  'availabilityStartDate',
                  moment(dates[0]).startOf('day').toDate()
                )
                setFieldValue(
                  'availabilityEndDate',
                  moment(dates[1]).endOf('day').toDate()
                )
              }}
              value={[values.availabilityStartDate, values.availabilityEndDate]}
              parseDate={str => new Date(str)}
            />
          </FormGroup>
        )}
        <br />
        <FormGroup
          labelFor="availabilityTimes"
          label="Availability Times"
          helperText="Schedule your segment to be shown between certain times."
        />
        <FieldArray
          name="availabilityTimes"
          render={({ push, remove }) => (
            <Fragment>
              <FormGroup>
                <Card className="bp5-nopad">
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>Day</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.availabilityTimes &&
                        values.availabilityTimes.map((time, index) => (
                          <AvailabilityTimeSelect
                            key={time.key}
                            onChange={handleChange}
                            index={index}
                            availabilityTimes={values.availabilityTimes}
                            errors={errors}
                            remove={remove}
                            fieldName="availabilityTimes"
                            setFieldValue={setFieldValue}
                          />
                        ))}
                    </tbody>
                  </HTMLTable>
                </Card>
                <Button
                  text="Add New Time"
                  minimal={true}
                  icon="plus"
                  intent={Intent.SUCCESS}
                  onClick={() =>
                    checkForBlankInterval(
                      values.availabilityTimes,
                      setFieldError,
                      push,
                      BLANK_INTERVAL,
                      'availabilityTimes'
                    )
                  }
                />
              </FormGroup>
            </Fragment>
          )}
        />
      </div>
      <div className="bp5-drawer-footer-actions">
        <Button
          intent={Intent.NONE}
          text="Save"
          loading={isSubmitting}
          disabled={!isValid && dirty}
          type="button"
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default EditSegmentForm
