import gql from 'graphql-tag'

const EDIT_MENU_ITEM_OPTION_ORDER = gql`
  mutation editMenuItemOptionOrder($id: String!, $optionPositions: [ID]) {
    editMenuItemOptionOrder(id: $id, optionPositions: $optionPositions) {
      menuItem {
        id
        optionPositions
      }
      message
    }
  }
`
export default EDIT_MENU_ITEM_OPTION_ORDER
