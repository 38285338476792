import gql from 'graphql-tag'

const GET_OUTLETS_BY_NAME = gql`
  query getOutletsByName($searchName: String, $marketplaceIds: [ID!]) {
    getOutlets(
      first: 10
      nameContains: $searchName
      restaurantNameContains: $searchName
      # The relationship between outlets and marketplaces is not required
      # and outlets without marketplaces won't be able to receive orders
      # so we can prevent assigning terminals to these outlets as this allows us
      # to ensure we always have access to the allowMultiOutletTerminal flag
      # which is pulled in on the getOutletsByIds query after they're added to the typeahead
      hasMarketplace: true
      marketplaceIds: $marketplaceIds
    ) {
      outlets {
        id
        name
        marketplace {
          id
          name
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLETS_BY_NAME
