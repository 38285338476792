import {
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
  Icon,
} from '@blueprintjs/core'
import React, { Fragment } from 'react'
import { CheckerBackgroundImgPreview } from '@components/ImageUpload/CheckerBackgroundImgPreview'
import config from '@src/config'

export const ImagesPopover = ({ category: { name, emoji, imageSrc } }) => (
  <Fragment>
    {imageSrc ? (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.RIGHT_TOP}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={
          <CheckerBackgroundImgPreview
            imageSrc={`https://${config.environmentDomain}/${imageSrc}`}
          />
        }
      >
        {name}
      </Popover>
    ) : (
      name
    )}
    {!(emoji && imageSrc) && (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
        position={Position.RIGHT_TOP}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={`A category should have both an image and an emoji otherwise a default one will be assigned.`}
      >
        <Fragment>
          <Icon
            icon="warning-sign"
            iconSize={12}
            color="orange"
            style={{
              verticalAlign: '-1px',
              marginLeft: '6px',
            }}
          />
        </Fragment>
      </Popover>
    )}
  </Fragment>
)
