import { Formik } from 'formik'
import {
  InputGroup,
  FormGroup,
  Classes,
  Button,
  Intent,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { successToast } from '@utils/toast'

import GET_USER_INFO from '@components/User/Details/queries/getUserInfo.query'
import EDIT_MY_DETAILS from '@components/User/Details/mutations/editUserDetails.mutation'

import PhoneInput from '@components/PhoneInput/PhoneInput'
import { validation } from './validation'
import { useMutation } from '@apollo/client'

const Details = () => {
  const [editMyDetails] = useMutation(EDIT_MY_DETAILS, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Details saved.')
    },
  })

  return (
    <Query query={GET_USER_INFO} loaderTitle={'Loading User'}>
      {({ getMyDetails: { user } }) => (
        <Formik
          initialValues={{ ...user }}
          validationSchema={validation}
          onSubmit={values => {
            editMyDetails({
              variables: {
                ...values,
              },
            })
          }}
        >
          {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup label="Email" labelInfo="(required)">
                <InputGroup name="email" type="email" value={values.email} />
              </FormGroup>
              <FormGroup
                label="First Name"
                helperText={errors.firstName}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup
                label="Last Name"
                helperText={errors.lastName}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup
                label="Phone Number"
                helperText={errors.phoneNumber}
                intent={Intent.DANGER}
              >
                <PhoneInput
                  value={values.phoneNumber}
                  inputProps={{ name: 'phoneNumber' }}
                  onChange={phone => {
                    setFieldValue('phoneNumber', phone)
                  }}
                />
              </FormGroup>

              <FormGroup label="Role">
                <InputGroup
                  name="role"
                  type="string"
                  readOnly
                  disabled={true}
                  value={values.role.title}
                />
              </FormGroup>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button intent={Intent.PRIMARY} text="Save" type="submit" />
              </div>
            </form>
          )}
        </Formik>
      )}
    </Query>
  )
}
export default Details
