import gql from 'graphql-tag'

const TAX_REPORT = gql`
  query taxReport($range: DateRangeInput!, $ownerFilter: OwnerFilterInput) {
    taxReport(ownerFilter: $ownerFilter, range: $range) {
      outlet {
        id
        name
        restaurant {
          id
          name
        }
        marketplace {
          id
          name
        }
      }
      productsNet
      productsGross
      productsVAT
      fulfilmentNet
      fulfilmentGross
      fulfilmentVAT
      serviceChargeNet
      serviceChargeGross
      serviceChargeVAT
    }
  }
`

export default TAX_REPORT
