import gql from 'graphql-tag'

const DELETE_CUISINE = gql`
  mutation deleteCuisine($id: String!) {
    deleteCuisine(id: $id) {
      message
      cuisine {
        id
        name
        archived
      }
    }
  }
`

export default DELETE_CUISINE
