import { Link } from 'react-router-dom'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export interface CustomerColumn<X extends string>
  extends BaseColumn<'customer', X> {}

export interface CustomerColumnData {
  id: string
  firstName?: string | null
  lastName?: string | null
}

export const sortCustomerColumn: ColumnSorter<'customer'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1
  return (
    negator *
    collator.compare(
      (b.lastName ?? '') + (b.firstName ?? ''),
      (a.lastName ?? '') + (a.firstName ?? '')
    )
  )
}

export const CustomerColumnContent: ColumnRenderer<'customer', string> = ({
  columnData,
}) => {
  return (
    <Link to={`/customer/${columnData.id}`}>
      {columnData.firstName ?? ''} {columnData.lastName ?? ''}
    </Link>
  )
}
