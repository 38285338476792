import React from 'react'
import { ButtonGroup, Button, Position, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import {
  getMonth,
  getYear,
  setMonth,
  setYear,
  startOfMonth,
  startOfQuarter,
  subDays,
} from 'date-fns'
import MonthAndYearSelector from './MonthAndYearSelector'
import WeekSelector from './WeekSelector'
import DaySelector from './DaySelector'
import QuarterSelector from './QuarterSelector'
import CustomRangeSelector from './CustomRangeSelector'
import {
  DATE_FILTER_TYPES,
  startOfMostRecentMonday,
} from '../../../utils/datetime'
import { useDateRangeQueryParams } from './useDateRangeQueryParams'
import { DaysAgoSelector } from './DaysAgoSelector'

/**
 * @typedef {import('./useDateRangeQueryParams').DateRangeFilterOptions & {
 *   disabled?: boolean,
 *   minimal?: boolean
 *   filterTypes?: import('../../../utils/datetime').DateFilterType[]
 * }} ExtendedDateRangeFilterOptions
 */

/**
 * @param {ExtendedDateRangeFilterOptions} props
 */
const DateRangeFilter = ({
  filterTypes = Object.values(DATE_FILTER_TYPES),
  disabled = false,
  minimal = false,
  defaultFilterTypeKey = filterTypes[0].key,
  maxCustomRangeInterval = { months: 1 },
  defaultStartOfRangeDate,
  defaultEndOfRangeDate,
  shifter,
}) => {
  const {
    startOfRangeDateTime,
    shiftedStartOfRangeDateTime,
    endOfRangeDateTime,
    shiftedEndOfRangeDateTime,
    dateFilterTypeKey,
    setRange,
  } = useDateRangeQueryParams({
    defaultFilterTypeKey,
    maxCustomRangeInterval,
    defaultStartOfRangeDate,
    defaultEndOfRangeDate,
    shifter,
  })

  return (
    <ButtonGroup>
      {filterTypes.length > 1 && (
        <Select
          filterable={false}
          items={filterTypes}
          itemRenderer={(item, { handleClick }) => (
            <MenuItem
              key={item.key}
              onClick={handleClick}
              text={item.name}
              icon={item.key === dateFilterTypeKey ? 'tick' : 'blank'}
              shouldDismissPopover={false}
            />
          )}
          onItemSelect={selectedDateFilterType => {
            switch (selectedDateFilterType.key) {
              case DATE_FILTER_TYPES.DAY.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfRangeDateTime,
                })
                break
              }
              case DATE_FILTER_TYPES.WEEK.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate:
                    startOfMostRecentMonday(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.MONTH.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfMonth(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.QUARTER.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfQuarter(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.CUSTOM.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfRangeDateTime,
                })
                break
              }
              case DATE_FILTER_TYPES.ALL.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: undefined,
                })
                break
              }

              case DATE_FILTER_TYPES.WEEK.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate:
                    startOfMostRecentMonday(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.MONTH.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfMonth(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.QUARTER.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfQuarter(startOfRangeDateTime),
                })
                break
              }
              case DATE_FILTER_TYPES.CUSTOM.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: startOfRangeDateTime,
                })
                break
              }
              case DATE_FILTER_TYPES.ALL.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: undefined,
                })
                break
              }
              case DATE_FILTER_TYPES.DAYS_AGO.key: {
                setRange({
                  dateFilterTypeKey: selectedDateFilterType.key,
                  startOfRangeDate: subDays(new Date(), 30),
                })
              }
            }
          }}
          popoverProps={{
            minimal: false,
            position: Position.BOTTOM_LEFT,
            boundary: 'window',
          }}
        >
          <Button disabled={disabled} minimal={minimal} icon={'calendar'}>
            {DATE_FILTER_TYPES[dateFilterTypeKey].name}
          </Button>
        </Select>
      )}

      {dateFilterTypeKey === DATE_FILTER_TYPES.DAYS_AGO.key && (
        <DaysAgoSelector
          date={startOfRangeDateTime}
          dateToDisplay={shiftedStartOfRangeDateTime}
          onChangeDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.DAY.key && (
        <DaySelector
          date={startOfRangeDateTime}
          dateToDisplay={shiftedStartOfRangeDateTime}
          onChangeDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key && (
        <WeekSelector
          startOfWeekDate={startOfRangeDateTime}
          startOfWeekDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeStartOfWeekDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          endOfWeekDateToDisplay={shiftedEndOfRangeDateTime}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key && (
        <MonthAndYearSelector
          monthIndex={getMonth(startOfRangeDateTime)}
          onChangeMonth={monthIndex => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: setMonth(startOfRangeDateTime, monthIndex),
            })
          }}
          year={getYear(startOfRangeDateTime)}
          onChangeYear={year => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: setYear(startOfRangeDateTime, year),
            })
          }}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.QUARTER.key && (
        <QuarterSelector
          startOfQuarterDate={startOfRangeDateTime}
          startOfQuarterDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeStartOfQuarterDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          endOfQuarterDate={endOfRangeDateTime}
          endOfQuarterDateToDisplay={shiftedEndOfRangeDateTime}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && (
        <CustomRangeSelector
          startOfRangeDate={startOfRangeDateTime}
          startOfRangeDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeRange={({ start, end }) => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: start,
              endOfRangeDate: end,
            })
          }}
          endOfRangeDate={endOfRangeDateTime}
          endOfRangeDateToDisplay={shiftedEndOfRangeDateTime}
          maxCustomRangeInterval={maxCustomRangeInterval}
          disabled={disabled}
        />
      )}
    </ButtonGroup>
  )
}

export default DateRangeFilter
