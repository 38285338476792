import gql from 'graphql-tag'

const ADD_BILLING_SUBSCRIPTION = gql`
  mutation addBillingSubscription(
    $marketplaceId: ID!
    $product: BillingProductType!
  ) {
    addBillingSubscription(marketplaceId: $marketplaceId, product: $product) {
      message
    }
  }
`

export default ADD_BILLING_SUBSCRIPTION
