import gql from 'graphql-tag'

const EDIT_MARKETPLACE_WEBSITE = gql`
  mutation editMarketplaceWebsite(
    $id: String!
    $title: String
    $metaDescription: String
    $metaKeywords: String
    $brandColor: String
    $brandSecondaryColor: String
    $brandTertiaryColor: String
    $heroImage: String
    $heroText: String
    $heroTextSecondary: String
    $faviconImage: String
    $logoImage: String
    $appImage: String
    $appText: String
    $appTextSecondary: String
    $storeURLApple: String
    $storeURLGooglePlay: String
    $socialURLFacebook: String
    $socialURLTwitter: String
    $socialURLTikTok: String
    $headerLinks: [LinkInput]
    $footerLinks: [LinkInput]
    $socialURLInstagram: String
    $featureFAQ: Boolean
    $featureRegister: Boolean
    $featureLogin: Boolean
    $featureOrdering: Boolean
    $featureRecruit: Boolean
    $featurePromotions: Boolean
    $defaultSearch: SearchMethod
    $emailImageUrl: String
    $allowLocationSearch: Boolean
    $allOutletsText: String
    $defaultFulfilmentOption: DefaultFulfilmentOption
    $defaultDatepickerOption: DefaultDatepickerOption
    $defaultLocationOption: DefaultLocationOption
    $enableRedirectFromLandingPage: Boolean
    $facebookPixelId: String
    $nowFilter: OutletNowFilter
  ) {
    editMarketplaceWebsite(
      id: $id
      title: $title
      metaDescription: $metaDescription
      metaKeywords: $metaKeywords
      brandColor: $brandColor
      brandSecondaryColor: $brandSecondaryColor
      brandTertiaryColor: $brandTertiaryColor
      heroImage: $heroImage
      heroText: $heroText
      heroTextSecondary: $heroTextSecondary
      faviconImage: $faviconImage
      logoImage: $logoImage
      appImage: $appImage
      appText: $appText
      appTextSecondary: $appTextSecondary
      storeURLApple: $storeURLApple
      storeURLGooglePlay: $storeURLGooglePlay
      socialURLFacebook: $socialURLFacebook
      socialURLTwitter: $socialURLTwitter
      socialURLTikTok: $socialURLTikTok
      headerLinks: $headerLinks
      footerLinks: $footerLinks
      socialURLInstagram: $socialURLInstagram
      featureFAQ: $featureFAQ
      featureRegister: $featureRegister
      featureLogin: $featureLogin
      featureOrdering: $featureOrdering
      featureRecruit: $featureRecruit
      featurePromotions: $featurePromotions
      defaultSearch: $defaultSearch
      emailImageUrl: $emailImageUrl
      allowLocationSearch: $allowLocationSearch
      allOutletsText: $allOutletsText
      defaultFulfilmentOption: $defaultFulfilmentOption
      defaultDatepickerOption: $defaultDatepickerOption
      defaultLocationOption: $defaultLocationOption
      enableRedirectFromLandingPage: $enableRedirectFromLandingPage
      facebookPixelId: $facebookPixelId
      nowFilter: $nowFilter
    ) {
      message
      marketplace {
        id
        title
        metaDescription
        metaKeywords
        brandColor
        brandSecondaryColor
        brandTertiaryColor
        heroImage
        heroText
        heroTextSecondary
        faviconImage
        logoImage
        appImage
        appText
        appTextSecondary
        storeURLApple
        storeURLGooglePlay
        socialURLFacebook
        socialURLTwitter
        socialURLTikTok
        headerLinks {
          id
          title
          link
          position
        }
        footerLinks {
          id
          title
          link
          position
        }
        socialURLInstagram
        featureFAQ
        featureRegister
        featureLogin
        featureOrdering
        featureRecruit
        featurePromotions
        defaultSearch
        allowLocationSearch
        allOutletsText
        defaultFulfilmentOption
        defaultDatepickerOption
        defaultLocationOption
        enableRedirectFromLandingPage
        facebookPixelId
        nowFilter
      }
    }
  }
`

export default EDIT_MARKETPLACE_WEBSITE
