import gql from 'graphql-tag'

const EDIT_MARKETPLACE_APP_FEATURES = gql`
  mutation editMarketplaceAppFeatures(
    $id: String!
    $allowReorder: Boolean!
    $allowSearch: Boolean!
    $optionsListStyle: OptionsListStyle!
    $menuListItemStyle: MenuListItemStyle!
    $menuListGroupStyle: MenuListGroupStyle!
    $hideCategoriesListOnMobile: Boolean!
    $allowMenuItemOptionImageV5: Boolean!
    $allowMenuItemMainImageV5: Boolean!
    $appServiceComponentIcon: String!
  ) {
    editMarketplaceAppFeatures(
      id: $id
      allowReorder: $allowReorder
      allowSearch: $allowSearch
      optionsListStyle: $optionsListStyle
      menuListItemStyle: $menuListItemStyle
      menuListGroupStyle: $menuListGroupStyle
      hideCategoriesListOnMobile: $hideCategoriesListOnMobile
      allowMenuItemOptionImageV5: $allowMenuItemOptionImageV5
      allowMenuItemMainImageV5: $allowMenuItemMainImageV5
      appServiceComponentIcon: $appServiceComponentIcon
    ) {
      message
      marketplace {
        id
        allowReorder
        allowSearch
        optionsListStyle
        menuListItemStyle
        hideCategoriesListOnMobile
        allowMenuItemOptionImageV5
        allowMenuItemMainImageV5
        appServiceComponentIcon
      }
    }
  }
`

export default EDIT_MARKETPLACE_APP_FEATURES
