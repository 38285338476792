import styled from 'styled-components'

export const MaxMinContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  margin-right: 30px;
  justify-content: space-between;
`

export const PriceRow = styled.td`
  width: 300px;
`
