import React, { Fragment, useState } from 'react'
import {
  FormGroup,
  InputGroup,
  Card,
  Button,
  HTMLSelect,
  ButtonGroup,
  Divider,
  TextArea,
  Intent,
  ControlGroup,
  Tooltip,
} from '@blueprintjs/core'
import { FieldArray, FormikProps } from 'formik'
import { isPlatformLevelUser } from '@stores/userStore'
import EditAsJSON from '@components/EmailEditor/EditAsJSON'
import { get } from 'lodash'
import TextAlignButton from '@components/EmailEditor/TextAlignButton'
import EmojiPicker from '@components/Marketplace/MessageTemplates/EmojiPicker'
import { CollapsibleAvailableTemplateVariables } from '../../utils/dynamicTemplateVars'
import ImageUpload from '@components/ImageUpload/ImageUpload'
import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

export interface EmailFormValues {
  emailSubject: string
  emailPreviewText: string
  emailContent: Array<{
    type: string
    text?: string
    buttonText?: string
    buttonLink?: string
    image?: File
    textAlign?: string
  }>
  marketplaceEmail: string
}

const InnerFormFragment: React.FC<
  Omit<FormikProps<EmailFormValues>, 'handleSubmit'> & {
    disabled: boolean
  }
> = ({
  values,
  handleChange,
  setFieldValue,
  disabled,
  errors,
  status,
  setStatus,
}) => {
  const [viewTemplate] = useQueryParam('viewTemplate', StringParam)
  const selectOptionsNoImage = [
    { value: 'TEXT', label: 'Text' },
    { value: 'BUTTON', label: 'Button' },
    { value: 'HEADER', label: 'Header' },
    { value: 'DIVIDER', label: 'Divider' },
  ]

  const selectOptions = [
    ...selectOptionsNoImage,
    { value: 'IMAGE', label: 'Image' },
  ]

  const getEmailContentHelperText = () => {
    if (!errors.emailContent) {
      return
    }

    if (typeof errors.emailContent === 'string') {
      return errors.emailContent
    }

    // get first truthy value from list
    const errorObject = (
      errors.emailContent as Array<Record<string, string>>
    ).find(err => err)

    if (errorObject) {
      // get first value from object
      return Object.values(errorObject)[0]
    }
  }

  const [imgElementPresent, setImgElementPresent] = useState(false)
  useEffect(() => {
    setImgElementPresent(
      values.emailContent.find(content => content.type === 'IMAGE') !==
        undefined
    )
  }, [values.emailContent])
  return (
    <div>
      <FormGroup
        label="Subject"
        labelFor="emailSubject"
        labelInfo="(required)"
        helperText={errors.emailSubject ? errors.emailSubject : ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          disabled={disabled}
          name="emailSubject"
          value={values.emailSubject}
          onChange={handleChange}
          rightElement={
            <EmojiPicker
              setFieldValue={setFieldValue}
              values={values}
              name={'emailSubject'}
            />
          }
        />
      </FormGroup>
      <FormGroup
        label="Text Preview"
        labelFor="emailPreviewText"
        helperText={
          errors.emailPreviewText
            ? errors.emailPreviewText
            : 'This text will be visible before the user opens the email next to the rest of the content.'
        }
        intent={errors.emailPreviewText ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          disabled={disabled}
          name="emailPreviewText"
          value={values.emailPreviewText}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup
        label={
          <Fragment>
            Message Body{' '}
            <div
              className="bp5-helper-message"
              style={{ display: 'inline-block' }}
            >
              Only 1 image of no more than 700kb is allowed
            </div>
          </Fragment>
        }
        labelFor="emailContent"
        helperText={getEmailContentHelperText()}
        intent={getEmailContentHelperText() ? Intent.DANGER : Intent.NONE}
      >
        <FieldArray
          name="emailContent"
          render={arrayHelpers => (
            <Fragment>
              <Card style={{ padding: 6 }}>
                {values.emailContent.map((content, index) => {
                  if (content.type === '') {
                    content.type = 'TEXT'
                  }
                  return (
                    <Fragment key={`component-${index}`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <HTMLSelect
                          style={{ width: 100 }}
                          disabled={disabled}
                          value={content.type}
                          minimal
                          name={`emailContent[${index}].type`}
                          options={
                            !imgElementPresent || content.type === 'IMAGE'
                              ? selectOptions
                              : selectOptionsNoImage
                          }
                          onChange={handleChange}
                        />
                        {content.type === 'TEXT' ? (
                          <TextArea
                            fill
                            value={content.text}
                            growVertically
                            disabled={disabled}
                            name={`emailContent[${index}].text`}
                            onChange={handleChange}
                            style={{ padding: 6, height: 30 }}
                          />
                        ) : null}
                        {content.type === 'BUTTON' ? (
                          <Fragment>
                            <InputGroup
                              value={content.buttonText}
                              fill
                              disabled={disabled}
                              name={`emailContent[${index}].buttonText`}
                              onChange={handleChange}
                            />{' '}
                            <InputGroup
                              fill
                              leftIcon="link"
                              value={content.buttonLink}
                              rightElement={
                                get(
                                  errors,
                                  `emailContent[${index}].buttonLink`
                                ) ? (
                                  <Tooltip
                                    content="Link is not valid"
                                    disabled={disabled}
                                    intent={Intent.DANGER}
                                  >
                                    <Button
                                      disabled={disabled}
                                      minimal={true}
                                      icon="warning-sign"
                                      intent={Intent.DANGER}
                                    />
                                  </Tooltip>
                                ) : null
                              }
                              disabled={disabled}
                              name={`emailContent[${index}].buttonLink`}
                              onChange={handleChange}
                              onBlur={async e => {
                                // add http if not present
                                if (
                                  !e.target.value.match(/^(http|https):\/\//)
                                ) {
                                  await setFieldValue(
                                    `emailContent[${index}].buttonLink`,
                                    `https://${e.target.value}`
                                  )
                                }
                              }}
                            />
                          </Fragment>
                        ) : null}
                        {content.type === 'HEADER' ? (
                          <InputGroup
                            fill
                            disabled={disabled}
                            name={`emailContent[${index}].text`}
                            onChange={handleChange}
                            value={content.text}
                          />
                        ) : null}
                        {content.type === 'IMAGE' ? (
                          <Fragment>
                            <ImageUpload
                              values={values}
                              setFieldValue={setFieldValue}
                              imageName={`emailContent[${index}].image`}
                              imageLabel=""
                              status={status}
                              setStatus={setStatus}
                              sizeLimit={700000}
                            />{' '}
                          </Fragment>
                        ) : null}
                        <ButtonGroup>
                          {content.type !== 'DIVIDER' ? (
                            <TextAlignButton
                              content={content}
                              disabled={disabled}
                              index={index}
                              setFieldValue={setFieldValue}
                            />
                          ) : null}
                          <Button
                            disabled={index === 0 || disabled}
                            icon="chevron-up"
                            onClick={() => {
                              arrayHelpers.swap(index, index - 1)
                            }}
                            minimal
                          />
                          <Button
                            disabled={
                              index === values.emailContent.length - 1 ||
                              disabled
                            }
                            icon="chevron-down"
                            onClick={() => arrayHelpers.swap(index, index + 1)}
                            minimal
                          />
                          <Button
                            disabled={disabled}
                            icon="trash"
                            onClick={() => arrayHelpers.remove(index)}
                            minimal
                          />
                        </ButtonGroup>
                      </div>

                      <Divider />
                    </Fragment>
                  )
                })}
                <ControlGroup>
                  <Button
                    icon="add"
                    disabled={disabled}
                    onClick={() => arrayHelpers.push({ type: '', text: '' })}
                    intent={Intent.SUCCESS}
                  >
                    Add Component
                  </Button>
                  {isPlatformLevelUser() ? (
                    <EditAsJSON
                      emailContent={values.emailContent}
                      setFieldValue={setFieldValue}
                      disabled={disabled}
                    />
                  ) : null}
                </ControlGroup>
              </Card>
            </Fragment>
          )}
        />
      </FormGroup>
      {viewTemplate && (
        <FormGroup>
          <CollapsibleAvailableTemplateVariables viewTemplate={viewTemplate} />
        </FormGroup>
      )}
    </div>
  )
}
export default InnerFormFragment
