import gql from 'graphql-tag'

const GET_MARKETPLACE_DNS = gql`
  query getMarketplaceById($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        cname
        pinpointAwsAccountId
        sesMessaging
        sesSourceArn
        sesSource
        websiteCnameCertificateArn
        emailSenderUsername
        createdAt
      }
    }
  }
`

export default GET_MARKETPLACE_DNS
