import gql from 'graphql-tag'

const GET_SEGMENT_ATTRIBUTE_FILTERS = gql`
  query getSegmentAttributeFilters($marketplaceId: ID!) {
    getSegmentAttributeFilters(marketplaceId: $marketplaceId) {
      attributes {
        value
        label
        type
        comparators {
          value
          label
        }
      }
    }
  }
`

export default GET_SEGMENT_ATTRIBUTE_FILTERS
