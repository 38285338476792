import {
  Button,
  Callout,
  ControlGroup,
  Drawer,
  DrawerSize,
  Intent,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Fragment, useState } from 'react'
import { numberToPence, penceToPounds } from '@utils/helpers'
import { Formik } from 'formik'
import GET_LOYALTY_CARD from './Queries/getLoyaltyCard'
import LOYALTY_CARDS_BY_MARKETPLACE from './Queries/loyaltyCardsByMarketplace'
import LoyaltyCardInnerForm from './LoyaltyCardInnerForm'
import EDIT_LOYALTY_CARD from './Mutations/editLoyaltyCard'
import { omit } from 'lodash'
import UpdateLoyaltyCardActiveState from './UpdateLoyaltyCardActiveState'
import LoyaltyCardAnalytics from './LoyaltyCardAnalytics'
import SuspendLoyaltyCard from './SuspendLoyaltyCard'
import UsageReportDrawer from '../Marketplace/MarketplaceVouchers/UsageReportDrawer'
import { editLoyaltyCardValidation } from './validation'
import { useMutation } from '@apollo/client'
import { format } from 'date-fns'

const EditLoyaltyCardDrawer = ({
  isOpen,
  discountId,
  marketplaceId,
  drawerClose,
  refetchVars,
  enableAnalytics = true,
}) => {
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState(false)
  const [editLoyaltyCard] = useMutation(EDIT_LOYALTY_CARD, {
    onCompleted: () => {
      successToast('Successfully updated loyalty card')

      setTimeout(() => {
        drawerClose()
      }, 2000)
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      {
        query: LOYALTY_CARDS_BY_MARKETPLACE,
        variables: { ...refetchVars },
      },
    ],
  })

  return (
    <Drawer
      isOpen={isOpen}
      onClose={drawerClose}
      title="Loyalty Card Details"
      size={DrawerSize.LARGE}
    >
      <div className="bp5-drawer-form">
        <Query
          query={GET_LOYALTY_CARD}
          variables={{ discountId }}
          loaderTitle="Loading Loyalty Card"
        >
          {({ getDiscount: { discount } }) => {
            const { loyaltyCard, allowedOutlets } = discount

            const loyaltyCardIsAlreadyUsed = false
            // to-do: add this back in after testing has been completed
            // const loyaltyCardIsAlreadyUsed =
            //   loyaltyCard.loyaltyCardStamps.length > 0

            // This is for the usage report - so that we can see the orders that have been made to earn a stamp and the orders that a loyalty card has been claimed on
            const orderIdsCombined = [
              ...loyaltyCard.loyaltyCardStamps.map(stamp => stamp.order.id),
              ...loyaltyCard.loyaltyCardStamps
                .filter(stamp => stamp.stampClaimedOnOrder) // Ensure stampClaimedOnOrder is not null
                .map(stamp => stamp.stampClaimedOnOrder.id),
            ]

            const initialValues = {
              ...discount,
              allowedOutletIds: allowedOutlets.map(({ id }) => id),
              outletSelected: allowedOutlets.length > 0,
              discountAmount: penceToPounds(discount.discountAmount),
              minimumSubtotalGross: penceToPounds(
                discount.minimumSubtotalGross
              ),
              requiredStamps: loyaltyCard.requiredStamps,
              loyaltyCardIcon: loyaltyCard.loyaltyCardIcon
                ? loyaltyCard.loyaltyCardIcon
                : '',

              loyaltyCardColor: loyaltyCard.loyaltyCardColor || '',
              termsAndConditions: loyaltyCard.termsAndConditions || '',
              discountStrategy: loyaltyCard.discountStrategy,
              allowCashOrders: loyaltyCard.allowCashOrders,
            }

            return (
              <Fragment>
                <Formik
                  validationSchema={editLoyaltyCardValidation}
                  validateOnChange={true}
                  onSubmit={values => {
                    editLoyaltyCard({
                      variables: {
                        ...values,
                        ...omit(loyaltyCard),
                        discountId: values.id,
                        loyaltyCardId: values.loyaltyCard.id,
                        allowedOutletIds: values.outletSelected
                          ? values.allowedOutletIds
                          : [],
                        discountAmount: numberToPence(values.discountAmount),
                        minimumSubtotalGross: numberToPence(
                          values.minimumSubtotalGross
                        ),
                        requiredStamps: values.requiredStamps,
                        loyaltyCardColor: values.loyaltyCardColor,
                        termsAndConditions: values.termsAndConditions,
                        discountStrategy: values.discountStrategy,
                        allowCashOrders: values.allowCashOrders,
                        startDate: format(
                          new Date(values.startDate),
                          "yyyy-MM-dd'T00:00:00Z'"
                        ),
                        endDate: format(
                          new Date(values.endDate),
                          "yyyy-MM-dd'T23:59:59Z'"
                        ),
                      },
                    })
                  }}
                  initialValues={initialValues}
                >
                  {({
                    errors,
                    handleChange,
                    values,
                    handleSubmit,
                    setFieldValue,
                    status,
                    setStatus,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className={'bp5-drawer-content'}>
                        <Button
                          icon="chart"
                          text="Usage Report"
                          intent={Intent.NONE}
                          disabled={!orderIdsCombined.length}
                          onClick={() => setIsReportDrawerOpen(true)}
                          style={{ marginBottom: 16 }}
                        />
                        {enableAnalytics && (
                          <LoyaltyCardAnalytics
                            marketplaceId={marketplaceId}
                            discountId={discountId}
                          />
                        )}

                        {loyaltyCardIsAlreadyUsed ? (
                          <Callout
                            intent={Intent.WARNING}
                            style={{ margin: '0 0 20px' }}
                          >
                            {
                              'At least one stamp has been earned for this loyalty card, therefore it can no longer be modified. You can still activate or deactivate it.'
                            }
                          </Callout>
                        ) : null}
                        <LoyaltyCardInnerForm
                          errors={errors}
                          values={values}
                          isDisabled={loyaltyCardIsAlreadyUsed}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          marketplaceId={marketplaceId}
                          status={status}
                          setStatus={setStatus}
                          editing={true}
                        />
                      </div>
                      <div className="bp5-drawer-footer-actions">
                        <ControlGroup vertical={false}>
                          <SuspendLoyaltyCard
                            suspended={loyaltyCard.suspended}
                            loyaltyCardId={loyaltyCard.id}
                            marketplaceId={marketplaceId}
                          />
                          <UpdateLoyaltyCardActiveState
                            active={loyaltyCard.active}
                            loyaltyCardId={loyaltyCard.id}
                            marketplaceId={marketplaceId}
                          />
                        </ControlGroup>
                        {!loyaltyCardIsAlreadyUsed && (
                          <Button
                            text="Update"
                            intent={Intent.NONE}
                            type="submit"
                          />
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
                <UsageReportDrawer
                  orderIds={orderIdsCombined}
                  isOpen={isReportDrawerOpen}
                  setIsOpen={setIsReportDrawerOpen}
                  discountId={discountId}
                  isLoyaltyDrawer={true}
                />
              </Fragment>
            )
          }}
        </Query>
      </div>
    </Drawer>
  )
}

export default EditLoyaltyCardDrawer
