import { Switch } from '@blueprintjs/core'
import { Formik } from 'formik'
import React from 'react'
import * as PropTypes from 'prop-types'

const MarketplaceAllowMultiOutletTerminal = ({
  updateField,
  allowMultiOutletTerminal,
}) => {
  return (
    <Formik initialValues={{ allowMultiOutletTerminal }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Allow Multiple Outlets per Terminal'}
            checked={values.allowMultiOutletTerminal}
            onChange={e => {
              handleChange(e)
              updateField({
                allowMultiOutletTerminal: e.currentTarget.checked,
              })
            }}
            name="allowMultiOutletTerminal"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowMultiOutletTerminal.propTypes = {
  allowMultiOutletTerminal: PropTypes.bool,
  updateField: PropTypes.func,
}

export default MarketplaceAllowMultiOutletTerminal
