import gql from 'graphql-tag'

const ALLOW_PENDING_ORDER_ALERT = gql`
  mutation allowPendingOrderAlert(
    $id: String!
    $allowPendingOrderAlert: Boolean!
  ) {
    editMarketplace(id: $id, allowPendingOrderAlert: $allowPendingOrderAlert) {
      message
      marketplace {
        id
        allowPendingOrderAlert
      }
    }
  }
`

export default ALLOW_PENDING_ORDER_ALERT
