import gql from 'graphql-tag'

const GET_SEGMENT_ATTRIBUTE_VALUES = gql`
  query getSegmentAttributeValues($marketplaceId: ID!, $attribute: String!) {
    getSegmentAttributeValues(
      marketplaceId: $marketplaceId
      attribute: $attribute
    ) {
      attributeValues {
        id
        name
      }
    }
  }
`

export default GET_SEGMENT_ATTRIBUTE_VALUES
