import gql from 'graphql-tag'

const ENABLE_LOYALTY_CARD_SCHEME = gql`
  mutation enableLoyaltyCardScheme($id: String!, $enableLoyalty: Boolean!) {
    editMarketplace(id: $id, enableLoyalty: $enableLoyalty) {
      message
      marketplace {
        id
        enableCustomerV2Client
      }
    }
  }
`

export default ENABLE_LOYALTY_CARD_SCHEME
