import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_PARTNER_NAME from './queries/getPartnerName.query'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const PartnerCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_PARTNER_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getPartners }) => {
            if (!getPartners) {
              return null
            }
            const { partners } = getPartners
            return (
              <Crumb
                key={crumb}
                text={partners.length ? partners[0].name : crumb}
                link={`/partners/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/partners/${crumbs[1]}/${crumb}`}
        />
      )
    }

    return <Crumb key={crumb} text={crumb} />
  })

PartnerCrumbs.propTypes = {
  crumbs: (props, propName, componentName) => {
    const crumbs = props[propName]
    if (!Array.isArray(crumbs)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array recived ${typeof crumbs}`
      )
    }
    if (crumbs.length !== 2) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array of length 2`
      )
    }
  },
}

export default PartnerCrumbs
