import gql from 'graphql-tag'

const EDIT_MARKETPLACE_GEOFENCE = gql`
  mutation editMarketplaceGeoFence($id: String!, $geoFence: Json) {
    editMarketplace(id: $id, geoFence: $geoFence) {
      message
      marketplace {
        id
        geoFence
      }
    }
  }
`

export default EDIT_MARKETPLACE_GEOFENCE
