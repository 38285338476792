import config from '@src/config'
import { Fragment } from 'react'

const DisplayImage = ({ imageSrc }) => (
  <Fragment>
    <img
      height="15px"
      src={`https://${config.environmentDomain}/${imageSrc}`}
    />
    <span>&nbsp;</span>
  </Fragment>
)

// default to emojis
const EmojiImageLabel = ({ label, emoji, imageSrc, preferEmoji }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {preferEmoji ? (
      <Fragment>
        {emoji && `${emoji} `}
        {!emoji && imageSrc && <DisplayImage imageSrc={imageSrc} />}
      </Fragment>
    ) : (
      <Fragment>
        {imageSrc && <DisplayImage imageSrc={imageSrc} />}
        {!imageSrc && emoji && `${emoji} `}
      </Fragment>
    )}
    {label}
  </div>
)

export default EmojiImageLabel
