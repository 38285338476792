import React from 'react'
import Form from '@components/MarketplaceForm/Form'
import { Formik } from 'formik'
import { func, object } from 'prop-types'

const MarketplaceForm = ({
  onSubmit,
  initialValues = {},
  validationSchema,
  awsOptions = [],
  isCreate = false,
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    initialStatus={{ previewImageValue: null }}
    validationSchema={validationSchema}
    awsOptions={awsOptions}
  >
    {props => <Form {...props} awsOptions={awsOptions} isCreate={isCreate} />}
  </Formik>
)

MarketplaceForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default MarketplaceForm
