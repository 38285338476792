import { Fragment } from 'react'
import { Select } from '@blueprintjs/select'
import { MenuItem, Button, NonIdealState } from '@blueprintjs/core'
import findIndex from 'lodash/findIndex'
import { func, object } from 'prop-types'

const itemRenderer = (linkCard, { handleClick }) => {
  return (
    <MenuItem
      key={linkCard.id}
      onClick={handleClick}
      multiline={true}
      text={
        <Fragment>
          <strong>{linkCard.name}</strong>
          <br />
        </Fragment>
      }
    />
  )
}

const addItem = (linkCard, values, setCardsState) => {
  if (findIndex(values, linkCard)) {
    setCardsState([
      ...values,
      { ...linkCard, label: linkCard.name, position: values.length + 1 },
    ])
  } else {
    return
  }
}

const CardSelect = ({ values, optionList, setCardsState }) => (
  <Select
    id="linkCardsSelect"
    items={optionList}
    itemRenderer={itemRenderer}
    filterable
    onItemSelect={linkCard => {
      addItem(linkCard, values, setCardsState)
    }}
    noResults={
      <NonIdealState
        title="No available cards"
        description="Please add a card to choose from."
      />
    }
    popoverProps={{
      usePortal: false,
      // TODO this is styled in index.css
      popoverClassName: 'link-card-popover',
    }}
    resetOnClose
    resetOnQuery
    resetOnSelect
  >
    <Button text="Add Card" icon="add" />
  </Select>
)

CardSelect.propTypes = {
  values: object.isRequired,
  push: func.isRequired,
}

export default CardSelect
