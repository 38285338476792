import React from 'react'
import {
  FormGroup,
  Intent,
  ControlGroup,
  InputGroup,
  HTMLSelect,
} from '@blueprintjs/core'
import { countryList, selectionUpdate } from '@utils/helpers'
import { get } from 'lodash'
import { shape, string } from 'prop-types'

const PickupAddressFormPartial = ({
  errors,
  values,
  handleChange,
  setFieldValue,
}) => (
  <FormGroup
    label="PickUp Address"
    labelInfo="(Required)"
    labelFor="firstLine"
    subLabel="Used by Delivery Networks and Customer Collections."
    helperText={errors.outletAddress ? 'Highlighted fields are required' : ''}
    intent={errors.outletAddress ? Intent.DANGER : Intent.NONE}
  >
    <ControlGroup vertical={true}>
      <InputGroup
        name="outletAddress.firstLine"
        id="firstLine"
        type="text"
        placeholder="First Line"
        onChange={handleChange}
        intent={
          get(errors, 'outletAddress.firstLine') ? Intent.DANGER : Intent.NONE
        }
        value={get(values.outletAddress, 'firstLine')}
      />
      <InputGroup
        name="outletAddress.secondLine"
        type="text"
        placeholder="Second Line"
        onChange={handleChange}
        value={get(values.outletAddress, 'secondLine')}
      />
      <InputGroup
        name="outletAddress.thirdLine"
        type="text"
        placeholder="Third Line"
        onChange={handleChange}
        value={get(values.outletAddress, 'thirdLine')}
      />
      <InputGroup
        name="outletAddress.city"
        type="text"
        placeholder="City"
        onChange={handleChange}
        intent={get(errors, 'outletAddress.city') ? Intent.DANGER : Intent.NONE}
        value={get(values.outletAddress, 'city')}
      />

      <InputGroup
        name="outletAddress.postcode"
        type="text"
        placeholder="Postcode"
        onChange={e => {
          setFieldValue('outletAddress.postcode', e.target.value.toUpperCase())
        }}
        intent={
          get(errors, 'outletAddress.postcode') ? Intent.DANGER : Intent.NONE
        }
        value={get(values.outletAddress, 'postcode')}
      />

      <HTMLSelect
        name="country"
        options={countryList()}
        value={get(values.outletAddress, 'country.code') || 'GB'}
        onChange={event => {
          const selection = selectionUpdate(event)
          setFieldValue(
            'outletAddress.country',
            {
              name: selection.label,
              code: selection.value,
            },
            false
          )
        }}
      />
    </ControlGroup>
  </FormGroup>
)

PickupAddressFormPartial.propTypes = {
  values: shape({
    outletAddress: shape({
      firstLine: string.isRequired,
      secondLine: string.isRequired,
      thirdLine: string.isRequired,
      city: string.isRequired,
      postcode: string.isRequired,
      country: shape({ name: string.isRequired, code: string.isRequired }),
    }),
  }),
}

export default PickupAddressFormPartial
