import React from 'react'
import { func, bool, array } from 'prop-types'
import Filter from '@components/Toolbar/Filter'
import GET_DELIVERY_ZONES from '../Marketplace/DeliveryZones/queries/getDeliveryZones.query'

export const DeliveryZoneFilter = ({
  disabled,
  deliveryZoneFilter,
  marketplaceId,
  onChange,
}) => (
  <Filter
    name="Delivery Zones"
    disabled={disabled}
    filter={deliveryZoneFilter}
    onChange={onChange}
    query={GET_DELIVERY_ZONES}
    variables={{
      marketplaceId,
    }}
    dataPath="getDeliveryZones.deliveryZones"
    itemPath=""
  />
)

DeliveryZoneFilter.propTypes = {
  onChange: func,
  deliveryZoneFilter: array,
  disabled: bool,
}
