import {
  Button,
  Card,
  FormGroup,
  H5,
  HTMLTable,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Switch,
  TextArea,
} from '@blueprintjs/core'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import { FieldArray, Formik } from 'formik'
import React, { Fragment } from 'react'
import DELETE_LINK_CARD from './mutations/deleteLinkCard.mutation'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import GET_LINK_CARDS from './queries/getLinkCards.query'
import { editLinkCardValidation } from './validation'
import AvailabilityTimeSelect from '@components/Restaurant/Menu/modals/AvailabilityTimeSelect'
import { checkForBlankInterval, setKeys } from '@utils/helpers'
import shortid from '@utils/shortid'
import { DateRangeInput } from '@blueprintjs/datetime'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import OutletSelectByMarketplace from '@src/components/OutletSelect/OutletSelectByMarketplace'
import { MenuItemSelectByOutlet } from '@src/pages/Marketing/shared/MenuItemSelectByOutlet'

const EditLinkCardForm = ({
  marketplaceId,
  linkCard,
  editRequest,
  close,
  isDialog = false,
}) => {
  const [deleteLinkCard] = useMutation(DELETE_LINK_CARD, {
    refetchQueries: [
      {
        query: GET_LINK_CARDS,
        variables: {
          marketplaceId,
          first: 20,
          skip: 0,
        },
      },
    ],
  })

  const BLANK_INTERVAL = {
    start: { day: '1', time: '00:00' },
    end: { day: '7', time: '23:59' },
    key: shortid.generate(),
  }

  return (
    <Formik
      initialValues={{
        ...linkCard,
        limitedAvailability:
          linkCard.availabilityEndDate && linkCard.availabilityStartDate
            ? 'SCHEDULED'
            : 'ALWAYS_AVAILABLE',
        availabilityTimes:
          linkCard.availabilityTimes && linkCard.availabilityTimes.length
            ? setKeys(linkCard.availabilityTimes)
            : [BLANK_INTERVAL],
        availabilityStartDate: linkCard.availabilityStartDate
          ? new Date(linkCard.availabilityStartDate)
          : null,
        availabilityEndDate: linkCard.availabilityEndDate
          ? new Date(linkCard.availabilityEndDate)
          : null,
        cardImage:
          typeof linkCard.cardImage === 'string'
            ? linkCard.cardImage
            : linkCard.cardImage.image.src,
        outletId: linkCard.outlet && linkCard.outlet.id,
        menuItemId: linkCard.menuItem && linkCard.menuItem.id,
      }}
      onSubmit={async values => {
        let cardImageValue = undefined
        if (typeof values.cardImage === 'string') {
          cardImageValue = values.cardImage
        } else if (values.cardImage.image.src) {
          cardImageValue = values.cardImage.image.src
        }
        await editRequest({
          variables: {
            ...values,
            id: linkCard.id,
            cardImage: cardImageValue,
          },
        })
      }}
      validationSchema={editLinkCardValidation}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        setFieldValue,
        setFieldError,
      }) => (
        <Card className={'bp5-nopad'}>
          <form
            onSubmit={handleSubmit}
            className={`bp5-${isDialog ? 'dialog' : 'drawer'}drawer-form`}
          >
            <Card>
              <div className={`bp5-${isDialog ? 'dialog' : 'drawer'}-content`}>
                <FormGroup
                  label="Title"
                  helperText={(errors && errors.name) || ''}
                >
                  <InputGroup
                    name="name"
                    type="text"
                    value={values.name}
                    placeholder="e.g. Saturday Night Favourites"
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup
                  intent={errors.type ? Intent.DANGER : Intent.NONE}
                  label="Type"
                  helperText={(errors && errors.type) || ''}
                >
                  <RadioGroup
                    onChange={handleChange}
                    selectedValue={values.type}
                    name="type"
                  >
                    <Radio label="Link" value={'LINK'} />
                    <Radio label="Outlet" value={'OUTLET'} />
                    <Radio label="Menu Item" value={'MENU_ITEM'} />
                  </RadioGroup>
                </FormGroup>

                <Switch
                  label={'Show on app'}
                  checked={values.showOnApp}
                  onChange={handleChange}
                  name="showOnApp"
                />
                <Switch
                  label={'Show on website'}
                  checked={values.showOnWeb}
                  onChange={handleChange}
                  name="showOnWeb"
                />
                {values.type === 'LINK' && (
                  <FormGroup
                    label="Link"
                    labelFor="url"
                    labelInfo="(required)"
                    helperText={errors.url || ''}
                    intent={errors.url ? Intent.DANGER : Intent.NONE}
                  >
                    <InputGroup
                      name="url"
                      type="text"
                      placeholder="Enter the web address"
                      value={values.url}
                      onChange={handleChange}
                    />
                  </FormGroup>
                )}
                {(values.type === 'OUTLET' || values.type === 'MENU_ITEM') && (
                  <FormGroup
                    label="Outlet"
                    labelFor="outletId"
                    labelInfo="(required)"
                    helperText={errors.outletId || ''}
                    intent={errors.outletId ? Intent.DANGER : Intent.NONE}
                  >
                    <OutletSelectByMarketplace
                      outletId={values.outletId}
                      marketplaceId={marketplaceId}
                      setSelected={value => setFieldValue('outletId', value)}
                      disabled={false}
                    />
                  </FormGroup>
                )}
                {values.type === 'MENU_ITEM' && (
                  <FormGroup
                    label="Menu Item"
                    labelFor="push.menuItemId"
                    labelInfo="(required)"
                    helperText={errors.menuItemId || ''}
                    intent={errors.menuItemId ? Intent.DANGER : Intent.NONE}
                  >
                    <MenuItemSelectByOutlet
                      disabled={false}
                      outletId={values.outletId}
                      menuItemId={values.menuItemId}
                      setSelected={value => setFieldValue('menuItemId', value)}
                    />
                  </FormGroup>
                )}

                <FormGroup
                  helperText={(errors && errors.cardImage) || ''}
                  intent={errors.cardImage ? Intent.DANGER : Intent.NONE}
                >
                  <ImageUploadDirectSingle
                    imageName={'cardImage'}
                    values={values}
                    setFieldValue={setFieldValue}
                    imageLabel="Image"
                    sizeLimit={1000000}
                    replace={true}
                    helperText="(required)"
                    setStatus={undefined}
                    optionItemId={undefined}
                    marketplaceId={marketplaceId}
                  />
                </FormGroup>
                <FormGroup
                  label="Description"
                  helperText={
                    (errors && errors.description) ||
                    "Description shows underneath your link card's title."
                  }
                  labelInfo="400 Character Limit"
                  intent={
                    errors && errors.description ? Intent.DANGER : Intent.NONE
                  }
                >
                  <TextArea
                    onChange={handleChange}
                    name="description"
                    style={{ resize: 'none', height: '60px' }}
                    fill={true}
                    id="description"
                    value={values.description || ''}
                    maxLength={200}
                  />
                </FormGroup>
                <FormGroup
                  label="Button text"
                  labelFor="buttonText"
                  labelInfo=""
                  helperText={errors.buttonText || ''}
                >
                  <InputGroup
                    name="buttonText"
                    type="text"
                    placeholder="Find out more!"
                    value={values.buttonText}
                    onChange={handleChange}
                  />
                </FormGroup>

                <Card>
                  <H5>Schedule</H5>
                  <RadioGroup
                    name="limitedAvailability"
                    label="Availability Date Range"
                    inline={false}
                    onChange={e => {
                      setFieldValue('limitedAvailability', e.target.value)
                    }}
                    selectedValue={values.limitedAvailability}
                  >
                    <Radio label="Always Available" value="ALWAYS_AVAILABLE" />
                    <Radio label="Scheduled" value="SCHEDULED" />
                  </RadioGroup>
                  {values.limitedAvailability === 'SCHEDULED' && (
                    <FormGroup
                      labelFor="availabilityDates"
                      helperText={
                        errors.availabilityStartDate ||
                        errors.availabilityEndDate
                          ? errors.availabilityStartDate ||
                            errors.availabilityEndDate
                          : 'Only show this segment between certain dates.'
                      }
                      intent={
                        errors.availabilityStartDate ||
                        errors.availabilityEndDate
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                    >
                      <DateRangeInput
                        id="availabilityDates"
                        formatDate={date => {
                          return moment(date).format('DD/MM/YYYY HH:mm')
                        }}
                        allowSingleDayRange={true}
                        closeOnSelection={true}
                        shortcuts={false}
                        onChange={dates => {
                          setFieldValue(
                            'availabilityStartDate',
                            moment(dates[0]).startOf('day').toDate()
                          )
                          setFieldValue(
                            'availabilityEndDate',
                            moment(dates[1]).endOf('day').toDate()
                          )
                        }}
                        value={[
                          values.availabilityStartDate,
                          values.availabilityEndDate,
                        ]}
                        parseDate={str => new Date(str)}
                      />
                    </FormGroup>
                  )}
                  <br />
                  <FormGroup
                    labelFor="availabilityTimes"
                    label="Please select the availability times for this link card"
                    helperText="Schedule your link card to be shown between certain times."
                  />
                  <FieldArray
                    name="availabilityTimes"
                    render={({ push, remove }) => (
                      <Fragment>
                        <FormGroup>
                          <Card className="bp5-nopad">
                            <HTMLTable bordered={false} interactive={true}>
                              <thead>
                                <tr>
                                  <th>Day</th>
                                  <th>From</th>
                                  <th>Day</th>
                                  <th>To</th>
                                </tr>
                              </thead>
                              <tbody>
                                {values.availabilityTimes &&
                                  values.availabilityTimes.map(
                                    (time, index) => (
                                      <AvailabilityTimeSelect
                                        key={time.key}
                                        onChange={handleChange}
                                        index={index}
                                        availabilityTimes={
                                          values.availabilityTimes
                                        }
                                        errors={errors}
                                        remove={remove}
                                        fieldName="availabilityTimes"
                                        setFieldValue={setFieldValue}
                                      />
                                    )
                                  )}
                              </tbody>
                            </HTMLTable>
                          </Card>
                          <Button
                            text="Add New Time"
                            minimal={true}
                            icon="plus"
                            intent={Intent.SUCCESS}
                            onClick={() =>
                              checkForBlankInterval(
                                values.availabilityTimes,
                                setFieldError,
                                push,
                                BLANK_INTERVAL,
                                'availabilityTimes'
                              )
                            }
                          />
                        </FormGroup>
                      </Fragment>
                    )}
                  />
                </Card>
              </div>
            </Card>
            <div
              className={`${
                isDialog ? 'bp5-dialog-footer' : 'bp5-drawer-footer-actions'
              } bp5-${isDialog ? 'dialog' : 'drawer'}-footer-actions`}
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: 0,
              }}
            >
              <ConfirmationPopover
                remove={() => {
                  deleteLinkCard({
                    variables: {
                      id: linkCard.id,
                    },
                  })
                  close()
                }}
                confirmationText={`Are you sure you want to delete this link card?`}
              >
                <Button
                  icon="trash"
                  onClick={e => {
                    e.preventDefault()
                  }}
                  alignText="left"
                  intent="danger"
                  minimal
                  style={{ float: 'right' }}
                >
                  Delete
                </Button>
              </ConfirmationPopover>
              <Button
                style={{ marginRight: '16px' }}
                intent={Intent.NONE}
                text="Update"
                type="submit"
              />
            </div>
          </form>
        </Card>
      )}
    </Formik>
  )
}

export default EditLinkCardForm
