import React, { Fragment } from 'react'
import colors from '@styles/colors'
import MutedStatus from './MutedStatus'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip, Icon } from '@blueprintjs/core'

import OpenStatus from '@components/OpenStatus/OpenStatus'
import LinkStyledButton from '@components/LinkStyledButton/LinkStyledButton'
import {
  isAtLeastBusinessUser,
  isAtLeastMarketplaceUser,
  isAtLeastOutletUser,
} from '@stores/userStore'
import OutletLink from '@components/OpenStatus/OutletLink'

const TerminalTableRow = ({ terminal, editTerminal }) => {
  // return a row per outlet
  return (
    <Fragment>
      {terminal.outlets.map((outlet, index) => {
        const isFirst = index === 0

        return (
          <tr key={outlet.id}>
            <td>
              {isFirst ? (
                <Fragment>
                  {terminal.isOnline ? (
                    <Tooltip content="Terminal Online">
                      <Icon icon="symbol-circle" color={colors.onlineGreen} />
                    </Tooltip>
                  ) : (
                    <Tooltip content="Terminal Offline">
                      <Icon icon="symbol-circle" color={colors.offlineRed} />
                    </Tooltip>
                  )}
                  &nbsp;
                  <LinkStyledButton onClick={() => editTerminal(terminal.id)}>
                    {terminal.friendlyName}
                  </LinkStyledButton>
                </Fragment>
              ) : (
                <Tooltip content="Multi-outlet">
                  <Icon
                    icon="nest"
                    style={{ marginLeft: 6 }}
                    color={colors.inactiveGray}
                  />
                </Tooltip>
              )}
            </td>
            <td>
              {terminal.lastOnline
                ? moment(terminal.lastOnline).fromNow()
                : isFirst && '-'}
            </td>
            <td>
              {isFirst && (
                <MutedStatus
                  terminalId={terminal.id}
                  isMuted={terminal.isMuted}
                  friendlyName={terminal.friendlyName}
                />
              )}
            </td>
            <td>
              <OpenStatus
                key={outlet.id}
                openingTimes={outlet.openingTimes}
                restaurantId={outlet.restaurant.id}
                restaurantIsActive={outlet.restaurant.active}
                outletIsActive={outlet.active}
                outletId={outlet.id}
                outletIsOpen={outlet.isOpen}
                outletIsOnline={outlet.isOnline}
                outletIsOnlineOverride={outlet.isOnlineOverride}
              />
            </td>
            <td>
              <Tooltip
                content={
                  outlet.restaurant.active ? (
                    <strong>Active</strong>
                  ) : (
                    <Fragment>
                      <strong>Deactivated Business</strong> -{' '}
                      <em>Not visible to customers</em>
                    </Fragment>
                  )
                }
              >
                <Icon
                  icon="small-tick"
                  color={
                    outlet.restaurant.active
                      ? colors.onlineGreen
                      : colors.inactiveGray
                  }
                />
              </Tooltip>
              &nbsp;
              {isAtLeastBusinessUser() ? (
                <Link to={`/business/${outlet.restaurant.id}`}>
                  {outlet.restaurant.name}
                </Link>
              ) : (
                outlet.restaurant.name
              )}
            </td>
            <td>
              {isAtLeastOutletUser() ? (
                <OutletLink outlet={outlet} />
              ) : (
                outlet.name
              )}
            </td>
            <td>
              {outlet.marketplace &&
                (isAtLeastMarketplaceUser() ? (
                  <Link to={`/marketplaces/${outlet.marketplace.id}`}>
                    {outlet.marketplace.name}
                  </Link>
                ) : (
                  outlet.marketplace.name
                ))}
            </td>
            <td>{isFirst && terminal.serialNumber}</td>
            <td>{(isFirst && terminal.softwareVersion) || '-'}</td>
          </tr>
        )
      })}
    </Fragment>
  )
}

export default TerminalTableRow
