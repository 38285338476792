import gql from 'graphql-tag'

const GET_MARKETPLACE_FINANCIALS = gql`
  query getMarketplaceFinancials($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        stripeCustomerPayboxId
        partner {
          id
          stripeEnterpriseAccessId
          stripeShowDashboardMarketplace
        }
        cname
        companyLegalName
        contactName
        contactEmail
        contactAddress {
          id
          country
          firstLine
          city
          postcode
        }
        stripeId
        stripeEnterpriseId
        stripePublicId
        stripeLivemode
        stripeOnboarding
        stripeDirectPayment
        platformFee
        platformCharge
        partnerFee
        partnerCharge
        partnerChargeDescription
        partnerCommissionFee
        paymentMethods
        platformTableFee
        platformTableCharge
        partnerTableFee
        partnerTableCharge
        partnerTableChargeDescription
        partnerTableCommissionFee
        vatNumber
        isVATregistered
        partnerChargeMerchantVat
        merchantCommissionCharge
        merchantTableCommissionCharge
        merchantComparisonDescription
        merchantComparisonPercentage
        invoiceEnabled
        invoiceTextFooter
        invoiceEmailCustomText
        invoiceBcc
        invoiceEmail
        cashText
        cashCollectionText
        applePayVerification
        stripeConnect {
          payoutsEnabled
          accountType
          balance
          balancePending
          balanceCurrency
          dashboardLink
          enterprise
        }
      }
    }
  }
`

export default GET_MARKETPLACE_FINANCIALS
