import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const addCuisineStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Cuisine name is required')
    .min(3, 'Cuisine name must be longer than 3 characters'),
}

export const editCuisineStructure: IIterableInterface = {
  name: yup
    .string()
    .required('Cuisine name is required')
    .min(3, 'Cuisine name must be longer than 3 characters'),
}
