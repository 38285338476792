import React, { Fragment } from 'react'
import styled from 'styled-components'
import { string, object } from 'prop-types'

import { Divider } from '@blueprintjs/core'
import { lowerCase, startCase } from 'lodash'

const DeliveryNotesContainer = styled.div`
  width: 50%;
  flex-grow: 1;
`

const OrderNotesContainer = styled.div`
  width: 50%;
  flex-grow: 1;
`

const Note = styled.div`
  color: ${({ theme }) => theme.colors.orderHighlight};
`

const NoteTitle = styled.p`
  font-weight: bold;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0px;
  margin-top: 20px;
  flex-direction: row;
`

const Notes = ({
  deliveryNotes,
  customerOrderNotes,
  customerDeliveryNotes,
  cancellationNotes,
  fulfillmentMethod,
}) => (
  <Fragment>
    <Container>
      <OrderNotesContainer>
        <NoteTitle>Order Notes</NoteTitle>
        <Note>{customerOrderNotes || 'No Order Notes'}</Note>
      </OrderNotesContainer>

      <DeliveryNotesContainer>
        <NoteTitle>{startCase(lowerCase(fulfillmentMethod))} Notes</NoteTitle>
        <Note>
          {customerDeliveryNotes ||
            deliveryNotes ||
            `No ${startCase(lowerCase(fulfillmentMethod))} Notes`}
        </Note>
      </DeliveryNotesContainer>
    </Container>
    {cancellationNotes && (
      <Fragment>
        <Divider />
        <Container>
          <div>
            <NoteTitle>Cancellation Notes</NoteTitle>
            <Note>{cancellationNotes || 'No Cancellation Notes'}</Note>
          </div>
        </Container>
      </Fragment>
    )}
  </Fragment>
)

Notes.propTypes = {
  customerOrderNotes: string,
  customerDeliveryNotes: string,
  cancellationNotes: string,
}

export default Notes
