import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Dashboard from '@src/pages/Reports/Dashboard/Dashboard'
import Transactions from '@src/pages/Reports/Transaction/Transactions'
import ReportsRestricted from '@components/UI/Permission/ReportsRestricted'
import { isPlatformLevelUser } from '@stores/userStore'
import { isPeakTime } from '@utils/isPeakTime'
import { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import Billing from '@src/pages/Reports/Billing/Billing'
import ItemReport from '@src/pages/Reports/Item/Items'
import PayoutReport from '@src/pages/Reports/Payout/Payout'
import RevenueReport from '@src/pages/Reports/Revenue/Revenue'
import SalesReport from '@src/pages/Reports/Sale/Sales'
import TaxesReport from '@src/pages/Reports/Taxes/Taxes'
import productDictionary from '@utils/productDictionary'
import { RejectionsByOutlet } from './Performance/RejectionsByOutlet'
import { DeliveryNetworkFailuresByMarketplace } from './Performance/DeliveryNetworkFailuresByMarketplace'
import { DeliveryNetworkFailuresByPartner } from './Performance/DeliveryNetworkFailuresByPartner'
import { RefundsReport } from './Refunds/RefundsReport'
import config from '../../config'
import TransactionReconciliation from '../../components/Reports/Reconciliation/Reconciliation'

const restrictReports = isPeakTime() && config.isProd && !isPlatformLevelUser()

const Reports: React.FC = () => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  const tabs = [{ to: '/reports', name: 'Reports' }]
  if (isPlatformLevelUser()) {
    tabs.push({ to: '/reports/transactions', name: 'Transactions' })
    tabs.push({
      to: '/reports/reconciliation',
      name: 'Reconciliation',
    })
  }
  useEffect(() => {
    configurePageLayout({
      product: productDictionary.REPORTS,
      tabs,
    })
  }, [configurePageLayout])

  if (restrictReports) return <ReportsRestricted />

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="transactions" element={<Transactions />} />

      <Route // TODO permission 'sales'
        path="products"
        element={<ItemReport />}
      />
      <Route // TODO permission 'sales'
        path="sales"
        element={<SalesReport product={productDictionary.REPORTS} />}
      />
      <Route // TODO permission 'sales'
        path="taxes"
        element={<TaxesReport product={productDictionary.REPORTS} />}
      />
      <Route // TODO permission 'reports'
        path="revenue"
        element={<RevenueReport product={productDictionary.REPORTS} />}
      />
      <Route // TODO permission 'payouts'
        path="payments"
        element={<PayoutReport product={productDictionary.REPORTS} />}
      />
      <Route // TODO permission 'billing'
        path="invoicing"
        element={<Billing product={productDictionary.REPORTS} />}
      />

      <Route path="rejections-by-outlet" element={<RejectionsByOutlet />} />
      <Route
        path="/delivery-network-failures-by-marketplace/:marketplaceId"
        element={<DeliveryNetworkFailuresByMarketplace />}
      />
      <Route
        path="delivery-network-failures-by-partner"
        element={<DeliveryNetworkFailuresByPartner />}
      />
      <Route path="refunds" element={<RefundsReport />} />
      <Route
        path={`reconciliation`}
        exact
        render={() => <TransactionReconciliation product={undefined} />}
        // isPermissionAllowed={() => isPlatformLevelUser()}
      />
    </Routes>
  )
}

export default Reports
