import gql from 'graphql-tag'

// used in routes to navigate to one/list
const GET_PINPOINT_MARKETPLACES_SLIM = gql`
  query getPinpointMarketplacesSlim {
    getPinpointMarketplaces {
      regions {
        id
      }
    }
  }
`

export default GET_PINPOINT_MARKETPLACES_SLIM
