import React, { Fragment } from 'react'
import {
  FormGroup,
  Intent,
  Classes,
  Button,
  Card,
  TextArea,
  Checkbox,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import TypeaheadMulti from '@components/Typeahead/TypeaheadMulti'
import GET_RESTAURANT_NAMES_WITH_CONTACTS_FOR_MARKETPLACE from '@components/Restaurants/List/queries/getRestaurantNamesWithContactsForMarketplace.query'
import { Query } from '@apollo/client/react/components'

const SendEmailToOutletsForm = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
}) => (
  <Card className="bp5-layout-col">
    <form onSubmit={handleSubmit}>
      {values.marketplaceId ? (
        <Fragment>
          <Query
            query={GET_RESTAURANT_NAMES_WITH_CONTACTS_FOR_MARKETPLACE}
            variables={{ marketplaceIds: [values.marketplaceId] }}
          >
            {({ data }) => {
              if (
                data?.getRestaurantNamesWithContactsForMarketplace === undefined
              ) {
                return null
              }

              const { getRestaurantNamesWithContactsForMarketplace } = data

              const allIds = getRestaurantNamesWithContactsForMarketplace.map(
                restaurant => restaurant.id
              )

              return (
                <Fragment>
                  {getRestaurantNamesWithContactsForMarketplace !==
                  undefined ? (
                    <FormGroup
                      label="Businesses"
                      labelFor="restaurants"
                      helperText={
                        errors.restaurantIds ? errors.restaurantIds : ''
                      }
                      intent={
                        errors.restaurantIds ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <TypeaheadMulti
                        items={getRestaurantNamesWithContactsForMarketplace}
                        selected={values.restaurantIds}
                        setSelected={e => {
                          setFieldValue('restaurantIds', e)
                        }}
                        placeholder="Select businesses..."
                      />
                      <div>
                        <Button
                          onClick={() => {
                            setFieldValue('restaurantIds', allIds)
                          }}
                          minimal={true}
                          small={true}
                        >
                          Select All
                        </Button>
                        <Button
                          onClick={() => {
                            setFieldValue('restaurantIds', [])
                          }}
                          minimal={true}
                          small={true}
                        >
                          Remove All
                        </Button>
                      </div>
                    </FormGroup>
                  ) : null}
                </Fragment>
              )
            }}
          </Query>
        </Fragment>
      ) : null}
      <FormGroup
        label="Subject"
        labelFor="subject"
        helperText={errors.subject ? errors.subject : ''}
        intent={errors.subject ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          fill
          value={values.subject}
          growVertically
          name={`subject`}
          onChange={handleChange}
          style={{ padding: 6, height: 30 }}
        />
      </FormGroup>
      <FormGroup
        label="Body"
        labelFor="content"
        helperText={errors.content ? errors.content : ''}
        intent={errors.content ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          name="content"
          id="content"
          type="text"
          onChange={handleChange}
          fill={true}
          value={values.content}
          style={{ height: 100 }}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          defaultChecked={values.addMarketplaceTheme}
          onChange={handleChange}
          name="addMarketplaceTheme"
        >
          Add marketplace theme
        </Checkbox>
      </FormGroup>

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button text="Send" intent={Intent.NONE} type="submit" />
      </div>
    </form>
  </Card>
)

SendEmailToOutletsForm.propTypes = {
  errors: object,
  values: object,
  handleChange: func,
  setFieldValue: func,
  handleSubmit: func,
}

export default SendEmailToOutletsForm
