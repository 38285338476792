import gql from 'graphql-tag'
import { OPTION_ITEM_FRAGMENT } from '../fragments/optionItemFragment.fragment'

export const ADD_OPTION_ITEM = gql`
  mutation optionImportAddOptionItem(
    $restaurantId: String!
    $parentId: String
    $name: String!
    $price: Int!
    $description: String
    $isVegan: Boolean
    $isVegetarian: Boolean
    $isDairyFree: Boolean
    $isGlutenFree: Boolean
    $ageRestricted: Boolean
  ) {
    addOptionItem(
      restaurantId: $restaurantId
      parentId: $parentId
      name: $name
      price: $price
      description: $description
      isVegan: $isVegan
      isVegetarian: $isVegetarian
      isDairyFree: $isDairyFree
      isGlutenFree: $isGlutenFree
      ageRestricted: $ageRestricted
    ) {
      message
      optionItem {
        ...optionItemFieldsAndParentOption
      }
    }
  }

  ${OPTION_ITEM_FRAGMENT}
`
