import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceEnableAnalyticsForm = ({ updateStatus, enableAnalytics }) => {
  return (
    <Formik initialValues={{ enableAnalytics }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Advanced Analytics'}
            checked={values.enableAnalytics}
            onChange={e => {
              handleChange(e)
              updateStatus({
                enableAnalytics: e.currentTarget.checked,
              })
            }}
            name="enableAnalytics"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceEnableAnalyticsForm.propTypes = {
  enableAnalytics: bool,
  errors: object,
}

export default MarketplaceEnableAnalyticsForm
