import gql from 'graphql-tag'

const EDIT_MY_DETAILS = gql`
  mutation editUserDetails(
    $email: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
  ) {
    editMyDetails(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

export default EDIT_MY_DETAILS
