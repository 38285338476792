import gql from 'graphql-tag'

const GET_STATS = gql`
  query salesBy($startDate: DateTime!, $endDate: DateTime!, $period: String!) {
    salesBy(startDate: $startDate, endDate: $endDate, period: $period) {
      totalOrders
      totalOrdersValue
      averageOrdersValue
      sales {
        date
        totalOrders
        totalOrdersValue
        totalRefundsValue
        totalRefunds
      }
    }
  }
`

export default GET_STATS
