import gql from 'graphql-tag'

const GET_CATEGORIES = gql`
  query getCuisinesRestaurant {
    getCuisines {
      cuisines {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`
export default GET_CATEGORIES
