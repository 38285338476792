import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup, ControlGroup, Button } from '@blueprintjs/core'
import { Pager, Search } from '@components/Toolbar'
import FilterRow from '@components/FilterRow/FilterRow'
import Dropdown from '@components/Toolbar/RadioFilter'
import RoleAwareBusinessFilter from '../../Toolbar/RoleAwareBusinessFilter/RoleAwareBusinessFilter'
import SoftwareVersionDropdown from './SoftwareVersionDropdown'
import { useTerminalQueryParams } from './useTerminalQueryParams'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'

export const ONLINE_STATUS_ID_TO_NAME_MAP = {
  ONLINE_OPEN: 'Online - Open',
  OFFLINE_OPEN: 'Offline - Open',
}

const TerminalsFilterBar = ({
  counts,
  page,
  setPage,
  resetFilters,
  someFiltersSelected,
  queryVariables,
}) => {
  const {
    offlineOpenOnly,
    softwareVersion,
    onChangeOfflineOpenOnly,
    onChangeSoftwareVersion,
    onChangeOrderBy,
  } = useTerminalQueryParams()
  const { searchValue: terminalSearchTerm } = useSearchQueryParam()
  useEffect(() => {
    if (terminalSearchTerm.length) {
      resetFilters()
    }
  }, [terminalSearchTerm, resetFilters])

  const goToPreviousPage = e => {
    e.preventDefault()
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const goToNextPage = e => {
    e.preventDefault()
    setPage(page + 1)
  }

  const goToPage = pageNumber => {
    setPage(pageNumber)
  }

  return (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Search
            style={{ marginRight: '8px' }}
            autoFocus
            placeholder="Name, serial number..."
          />
          {/* Online Status Filter */}
          <Dropdown
            disabled={terminalSearchTerm.length > 0}
            selected={offlineOpenOnly}
            setSelected={({ id }) => {
              onChangeOfflineOpenOnly(id)
              if (id) {
                onChangeSoftwareVersion(undefined)
                onChangeOrderBy(undefined)
              }
            }}
            placeholder="Status"
            items={[{ name: 'All' }, { name: 'Offline - Open', id: true }]}
          />
          {/* Marketplace / Business Filter */}
          <RoleAwareBusinessFilter
            divider={false}
            disabled={terminalSearchTerm.length > 0}
          />
          {/* Software Version Number */}
          <SoftwareVersionDropdown
            disabled={terminalSearchTerm.length > 0 || offlineOpenOnly}
            softwareVersion={softwareVersion}
            onChangeSoftwareVersion={({ id }) => onChangeSoftwareVersion(id)}
          />
          <Button
            icon="filter-remove"
            onClick={resetFilters}
            disabled={!someFiltersSelected}
          />
        </ControlGroup>
      </ButtonGroup>
      <Pager
        goToPrevious={goToPreviousPage}
        goToNext={goToNextPage}
        goToPage={goToPage}
        defaultNmbRecords={queryVariables.first}
        skip={queryVariables.skip || 0}
        total={counts.totalRecords}
        totalCount={counts.currentPageRecords}
        dataName="Terminals"
        countOnly={offlineOpenOnly}
      />
    </FilterRow>
  )
}

TerminalsFilterBar.propTypes = {
  counts: PropTypes.shape({
    totalRecords: PropTypes.number,
    currentPageRecords: PropTypes.number,
  }).isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  someFiltersSelected: PropTypes.bool,
  queryVariables: PropTypes.object.isRequired,
}

export default TerminalsFilterBar
