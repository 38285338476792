import gql from 'graphql-tag'

const EDIT_MENUS_ORDER = gql`
  mutation editMenusOrder($input: [IPositionArgs!]!) {
    editMenusOrder(input: $input) {
      menuItemsGroups {
        id
        name
        position
        parentMenu {
          id
        }
        menuItems {
          id
          position
          parentMenus {
            id
          }
        }
        childrenMenus {
          id
          name
          position
          parentMenu {
            id
          }
          menuItems {
            id
            position
            parentMenus {
              id
            }
          }
        }
      }
    }
  }
`

export default EDIT_MENUS_ORDER
