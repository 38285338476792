import React, { Fragment } from 'react'
import {
  Intent,
  Switch,
  Callout,
  FormGroup,
  AnchorButton,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import { warningToast } from '@utils/toast'

const RestaurantActivationForm = ({ values, handleChange, handleSubmit }) => (
  <Fragment>
    <Callout
      intent={values.active ? Intent.SUCCESS : Intent.NONE}
      icon={values.active ? 'tick-circle' : 'help'}
      title={
        <Switch
          alignIndicator="right"
          label="Activate Business"
          name="active"
          checked={values.active}
          large
          onChange={e => {
            if (!e.currentTarget.checked) {
              warningToast(
                `Deactivating a Business will also deactivate all of its Outlets, save to confirm.`
              )
              handleChange(e)
            } else {
              handleChange(e)
              handleSubmit(e)
            }
          }}
        />
      }
    >
      <FormGroup
        style={{ marginBottom: 2 }}
        subLabel="Outlets to be activated separately."
        labelFor="active"
      >
        <AnchorButton
          alignText="left"
          href={`/business/${values.id}/outlets`}
          rightIcon="arrow-right"
          fill
          minimal
          outlined
          intent={values.active ? Intent.SUCCESS : Intent.PRIMARY}
        >
          View Outlets
        </AnchorButton>
      </FormGroup>
    </Callout>
  </Fragment>
)

RestaurantActivationForm.propTypes = {
  values: object,
  handleChange: func,
}

export default RestaurantActivationForm
