import gql from 'graphql-tag'

const GET_LOYALTY_CARD = gql`
  query getLoyaltyCard($discountId: String!) {
    getDiscount(id: $discountId) {
      discount {
        id
        name
        discountPercentage
        discountAmount
        allowedOutlets {
          id
        }
        startDate
        endDate
        daysOfWeek
        minimumSubtotalGross
        loyaltyCard {
          id
          active
          requiredStamps
          suspended
          loyaltyCardIcon
          termsAndConditions
          loyaltyCardColor
          allowCashOrders
          discountStrategy
          loyaltyCardStamps {
            id
            stampClaimedOnOrder {
              id
            }
            order {
              id
            }
          }
        }
      }
    }
  }
`

export default GET_LOYALTY_CARD
