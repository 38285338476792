import gql from 'graphql-tag'

const EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM = gql`
  mutation editMarketplace(
    $id: String!
    $outletCollectionVisibilityRadiusKM: Int!
  ) {
    editMarketplace(
      id: $id
      outletCollectionVisibilityRadiusKM: $outletCollectionVisibilityRadiusKM
    ) {
      message
      marketplace {
        id
        outletCollectionVisibilityRadiusKM
      }
    }
  }
`

export default EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM
