import gql from 'graphql-tag'

const GET_OUTLET_SUB_MENUS = gql`
  query getOutletSubMenus($id: String!, $menuId: ID!) {
    getOutlets(id: $id) {
      outlets {
        id
        name
        soldOutMenuItemIds
        soldOutUntilItems
        hiddenMenuItemGroupIds
        menuItemsGroups(where: { id: $menuId }) {
          id
          name
          menuItems {
            id
            outletSoldOut
            outletHidden
          }
          childrenMenus {
            id
            name
            menuItems {
              id
              outletSoldOut
              outletHidden
            }
            parentMenu {
              id
              name
            }
          }
        }
      }
    }
  }
`
export default GET_OUTLET_SUB_MENUS
