import gql from 'graphql-tag'

const EDIT_MARKETPLACE_LEGAL = gql`
  mutation editMarketplaceLegal(
    $id: String!
    $legalTerms: String
    $legalPrivacy: String
    $legalCookies: String
    $legalPolicy: String
    $legalAllergy: String
  ) {
    editMarketplace(
      id: $id
      legalTerms: $legalTerms
      legalPrivacy: $legalPrivacy
      legalCookies: $legalCookies
      legalPolicy: $legalPolicy
      legalAllergy: $legalAllergy
    ) {
      message
      marketplace {
        id
        name
        legalTerms
        legalPrivacy
        legalCookies
        legalPolicy
        legalAllergy
      }
    }
  }
`

export default EDIT_MARKETPLACE_LEGAL
