import gql from 'graphql-tag'

const CREATE_LOYALTY_CARD = gql`
  mutation createLoyaltyCard(
    $marketplaceId: String!
    $active: Boolean!
    $allowedOutletIds: [String!]!
    $name: String!
    $discountPercentage: Float
    $discountAmount: Int
    $startDate: DateTime
    $endDate: DateTime
    $daysOfWeek: [Int]
    $minimumSubtotalGross: Int
    $requiredStamps: Int!
    $loyaltyCardIcon: String
    $loyaltyCardColor: String
    $termsAndConditions: String
    $allowCashOrders: Boolean!
    $discountStrategy: LoyaltyCardDiscountStrategy!
  ) {
    createLoyaltyCard(
      marketplaceId: $marketplaceId
      active: $active
      allowedOutletIds: $allowedOutletIds
      name: $name
      discountPercentage: $discountPercentage
      discountAmount: $discountAmount
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
      minimumSubtotalGross: $minimumSubtotalGross
      requiredStamps: $requiredStamps
      loyaltyCardIcon: $loyaltyCardIcon
      loyaltyCardColor: $loyaltyCardColor
      termsAndConditions: $termsAndConditions
      allowCashOrders: $allowCashOrders
      discountStrategy: $discountStrategy
    ) {
      loyaltyCard {
        id
        loyaltyCardIcon
        requiredStamps
      }
    }
  }
`

export default CREATE_LOYALTY_CARD
