import gql from 'graphql-tag'

const EDIT_PLATFORM = gql`
  mutation editPlatform(
    $id: String!
    $name: String!
    $contactName: String!
    $contactAddress: AddressCreateInput!
    $contactPhone: String!
    $contactEmail: String!
    $payboxAccessId: String
    $payboxPublicId: String
    $payboxClientId: String
    $stripeAccessId: String
    $stripePublicId: String
    $stripeClientId: String
    $platformVat: Float
    $platformCurrency: Json
    $orderHoldIntervalOverride: Int
    $partnerIds: [String]
    $vatNumber: String
    $stripeFee: Float
    $stripeCharge: Int
    $stripePresentFee: Float
    $stripePresentCharge: Int
    $stripeConnectCharge: Int
    $stripeDebitFee: Float
    $stripeInstantPayoutFee: Float
    $stripePayoutFee: Float
    $stripePayoutCharge: Int
    $stripeChargebackCharge: Int
  ) {
    editPlatform(
      id: $id
      name: $name
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      payboxAccessId: $payboxAccessId
      payboxPublicId: $payboxPublicId
      payboxClientId: $payboxClientId
      stripeAccessId: $stripeAccessId
      stripePublicId: $stripePublicId
      stripeClientId: $stripeClientId
      platformVat: $platformVat
      platformCurrency: $platformCurrency
      orderHoldIntervalOverride: $orderHoldIntervalOverride
      partnerIds: $partnerIds
      vatNumber: $vatNumber
      stripeFee: $stripeFee
      stripeCharge: $stripeCharge
      stripePresentFee: $stripePresentFee
      stripePresentCharge: $stripePresentCharge
      stripeDebitFee: $stripeDebitFee
      stripeInstantPayoutFee: $stripeInstantPayoutFee
      stripePayoutFee: $stripePayoutFee
      stripeConnectCharge: $stripeConnectCharge
      stripePayoutCharge: $stripePayoutCharge
      stripeChargebackCharge: $stripeChargebackCharge
    ) {
      message
      platform {
        id
        name
        contactName
        contactPhone
        contactEmail
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        payboxId
        payboxPublicId
        payboxClientId
        stripeId
        stripePublicId
        stripeClientId
        platformVat
        platformCurrency
        orderHoldIntervalOverride
        vatNumber
        stripeFee
        stripeCharge
        stripePresentFee
        stripePresentCharge
        stripeDebitFee
        stripePayoutFee
        stripeConnectCharge
        stripeInstantPayoutFee
        stripePayoutCharge
        stripeChargebackCharge
      }
    }
  }
`

export default EDIT_PLATFORM
