import { func, bool } from 'prop-types'
import { FormGroup, InputGroup, Button, Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import REQUEST_PASSWORD_RESET from './mutations/requestPasswordReset.mutation'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { useMutation } from '@apollo/client'

const ForgotPasswordModal = ({ closeModal, modalOpen }) => {
  const [requestResetPassword] = useMutation(REQUEST_PASSWORD_RESET, {
    onError: defaultErrorHandler,
    onCompleted: ({ requestResetPassword }) => {
      successToast(requestResetPassword.message)
      closeModal()
    },
  })

  return (
    <Dialog isOpen={modalOpen} onClose={closeModal} title="Forgotten Password?">
      <div className={Classes.DIALOG_BODY}>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={values =>
            requestResetPassword({
              variables: {
                ...values,
              },
            })
          }
        >
          {({ values, handleChange, handleSubmit }) => (
            <form className={Classes.DIALOG_BODY} onSubmit={handleSubmit}>
              <p>
                No problem, we’ll email you instructions on how to reset it:
              </p>
              <FormGroup>
                <InputGroup
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button text="Recover Password" type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

ForgotPasswordModal.propTypes = {
  closeModal: func.isRequired,
  modalOpen: bool.isRequired,
}

export default ForgotPasswordModal
