import gql from 'graphql-tag'

const ALLOW_MULTI_OUTLET_TERMINAL = gql`
  mutation allowMultiOutletTerminal(
    $id: String!
    $allowMultiOutletTerminal: Boolean!
  ) {
    editMarketplace(
      id: $id
      allowMultiOutletTerminal: $allowMultiOutletTerminal
    ) {
      message
      marketplace {
        id
        allowMultiOutletTerminal
      }
    }
  }
`

export default ALLOW_MULTI_OUTLET_TERMINAL
