import { Fragment, FunctionComponent } from 'react'
import {
  FormGroup,
  InputGroup,
  Intent,
  ControlGroup,
  HTMLSelect,
  H5,
} from '@blueprintjs/core'
import { Row, Col } from '@components/_FlexGrid'
import get from 'lodash/get'

import { countryList, selectionUpdate } from '@utils/helpers'

const PartnerShortForm: FunctionComponent<{
  modal?: boolean
  editable?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  errors: Record<string, string>
  values: Record<string, string>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  disabled?: boolean
}> = ({
  modal = false,
  editable = true,
  handleChange,
  errors,
  values,
  setFieldValue,
  disabled = false,
}) => (
  <Fragment>
    <Row gutter={24}>
      <Col sm={12} md={modal || editable ? 12 : 6}>
        <FormGroup
          label="Partner Name"
          labelInfo="(required)"
          labelFor="name"
          helperText={errors.name ? errors.name : ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="name"
            id="name"
            type="text"
            onChange={handleChange}
            intent={errors.name ? Intent.DANGER : Intent.NONE}
            value={values.name}
            large
            fill
          />
        </FormGroup>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col sm={12} md={modal ? 12 : 6}>
        <br />
        <H5>Business</H5>
        <FormGroup label="Company Information" labelInfo="(required)">
          <ControlGroup vertical={true}>
            <InputGroup
              name="companyLegalName"
              id="companyLegalName"
              type="text"
              leftIcon="office"
              onChange={handleChange}
              placeholder="Legal Company Name (e.g. Ltd, Inc, etc.)"
              intent={errors.companyLegalName ? Intent.DANGER : Intent.NONE}
              value={values.companyLegalName}
              disabled={disabled}
            />

            <InputGroup
              name="companyNumber"
              id="companyNumber"
              type="text"
              leftIcon="document"
              placeholder="Company Number"
              onChange={handleChange}
              intent={errors.companyNumber ? Intent.DANGER : Intent.NONE}
              value={values.companyNumber}
              disabled={disabled}
            />
          </ControlGroup>
        </FormGroup>

        <FormGroup
          label="Company Address"
          labelInfo="(required)"
          labelFor="firstLine"
          helperText={
            errors.contactAddress ? 'Highlighted fields are required' : ''
          }
          intent={Intent.DANGER}
        >
          <ControlGroup vertical={true}>
            <InputGroup
              name="contactAddress.firstLine"
              id="firstLine"
              type="text"
              placeholder="First Line"
              onChange={handleChange}
              intent={
                get(errors, 'contactAddress.firstLine')
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={values.contactAddress.firstLine}
            />
            <InputGroup
              name="contactAddress.secondLine"
              type="text"
              placeholder="Second Line"
              onChange={handleChange}
              value={values.contactAddress.secondLine}
            />
            <InputGroup
              name="contactAddress.thirdLine"
              type="text"
              placeholder="Third Line"
              onChange={handleChange}
              value={values.contactAddress.thirdLine}
            />
            <InputGroup
              name="contactAddress.city"
              type="text"
              placeholder="City"
              onChange={handleChange}
              intent={
                get(errors, 'contactAddress.city') ? Intent.DANGER : Intent.NONE
              }
              value={values.contactAddress.city}
            />
            <InputGroup
              name="contactAddress.postcode"
              type="text"
              placeholder="Postcode"
              onChange={handleChange}
              intent={
                get(errors, 'contactAddress.postcode')
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={values.contactAddress.postcode}
            />

            <HTMLSelect
              name="country"
              options={countryList()}
              value={values.contactAddress.country.code}
              onChange={event => {
                const selection = selectionUpdate(event)
                setFieldValue(
                  'contactAddress.country',
                  {
                    name: selection.label,
                    code: selection.value,
                  },
                  false
                )
              }}
            />
          </ControlGroup>
        </FormGroup>

        <FormGroup
          label="Full Name"
          labelInfo="(required)"
          labelFor="contactName"
          helperText={errors.contactName ? errors.contactName : ''}
          intent={errors.contactName ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            name="contactName"
            id="contactName"
            type="text"
            onChange={handleChange}
            intent={errors.contactName ? Intent.DANGER : Intent.NONE}
            value={values.contactName}
          />
        </FormGroup>

        <FormGroup
          label="Email Address"
          labelInfo="(required)"
          labelFor="contactEmail"
          helperText={errors.contactEmail ? errors.contactEmail : ''}
          intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            name="contactEmail"
            id="contactEmail"
            type="text"
            onChange={handleChange}
            intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
            value={values.contactEmail}
          />
        </FormGroup>

        <FormGroup
          label="Phone Number"
          labelInfo="(required)"
          labelFor="contactPhone"
          helperText={errors.contactPhone ? errors.contactPhone : ''}
          intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            name="contactPhone"
            id="contactPhone"
            type="text"
            onChange={handleChange}
            intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
            value={values.contactPhone}
          />
        </FormGroup>
      </Col>
      {editable && (
        <Col xs={12} sm={12} md={6} lg={6}>
          <br />
          <H5>Branding</H5>
          <FormGroup
            label="Domain"
            labelInfo="(CNAME)"
            labelFor="cname"
            helperText={errors.cname ? errors.cname : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="cname"
              id="cname"
              type="text"
              onChange={handleChange}
              intent={errors.cname ? Intent.DANGER : Intent.NONE}
              value={values.cname}
            />
          </FormGroup>
          <FormGroup
            label="Brand Color"
            labelFor="brandColor"
            helperText={errors.brandColor ? errors.brandColor : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="brandColor"
              id="brandColor"
              type="text"
              onChange={handleChange}
              intent={errors.brandColor ? Intent.DANGER : Intent.NONE}
              value={values.brandColor}
            />
          </FormGroup>
          <br />
          <H5>Marketplace Support</H5>
          <FormGroup
            label="Name"
            labelFor="supportTitle"
            helperText={errors.supportTitle ? errors.supportTitle : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="supportTitle"
              id="supportTitle"
              type="text"
              onChange={handleChange}
              intent={errors.supportTitle ? Intent.DANGER : Intent.NONE}
              value={values.supportTitle}
            />
          </FormGroup>
          <FormGroup
            label="Support Desk URL"
            labelFor="supportUrl"
            helperText={errors.supportUrl ? errors.supportUrl : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="supportUrl"
              id="supportUrl"
              type="text"
              onChange={handleChange}
              intent={errors.supportUrl ? Intent.DANGER : Intent.NONE}
              value={values.supportUrl}
            />
          </FormGroup>
          <FormGroup
            label="Support Email"
            labelFor="supportEmail"
            helperText={errors.supportEmail ? errors.supportEmail : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="supportEmail"
              id="supportEmail"
              type="text"
              onChange={handleChange}
              intent={errors.supportEmail ? Intent.DANGER : Intent.NONE}
              value={values.supportEmail}
            />
          </FormGroup>
        </Col>
      )}
    </Row>
  </Fragment>
)

export default PartnerShortForm
