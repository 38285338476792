import gql from 'graphql-tag'

const RESET_PLATFORM_WEBHOOKS = gql`
  mutation setPlatformStripeWebhooks($paybox: Boolean!) {
    setPlatformStripeWebhooks(paybox: $paybox) {
      id
      message
    }
  }
`

export default RESET_PLATFORM_WEBHOOKS
