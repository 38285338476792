import gql from 'graphql-tag'

// this query must return all outlets associated with the terminal for proper cache updates
const ADD_TERMINAL = gql`
  mutation addTerminal(
    $friendlyName: String!
    $outletIds: [String!]!
    $serialNumber: String!
    $pin: String!
    $slave: Boolean
    $alarm: Alarm
  ) {
    addTerminal(
      friendlyName: $friendlyName
      outletIds: $outletIds
      serialNumber: $serialNumber
      pin: $pin
      slave: $slave
      alarm: $alarm
    ) {
      terminal {
        id
        friendlyName
        serialNumber
        slave
        alarm
        archived
        outlets {
          id
        }
      }
    }
  }
`

export default ADD_TERMINAL
