import React, { Fragment } from 'react'
import { string, bool } from 'prop-types'
import { upperFirst, toLower } from 'lodash'
import moment from 'moment'

const calendarFormat = {
  lastDay: '[due Yesterday at] HH:mm',
  sameDay: '[Today at] HH:mm',
  nextDay: '[Tomorrow at] HH:mm',
  lastWeek: '[due last] dddd [at] HH:mm',
  nextWeek: 'dddd [at] HH:mm',
  sameElse: '[at] HH:mm',
}

const calendarFormatCompleted = {
  lastDay: '[Yesterday at] HH:mm',
  sameDay: '[Today at] HH:mm',
  nextDay: '[Tomorrow at] HH:mm',
  lastWeek: '[on] dddd [at] HH:mm',
  nextWeek: 'dddd [at] HH:mm',
  sameElse: '[at] HH:mm',
}

const FulfilmentStatus = ({
  orderStatus,
  fulfillmentMethod,
  asap,
  createdAt,
  updatedAt,
  estimatedDeliveryDate,
  estimatedCompletionTime,
  tableFriendlyName = '',
  useExactTime = false,
  selectedDeliveryWindow,
}) => {
  const isFinished = ['COMPLETE', 'REJECTED', 'ORDER_FAILURE_REFUND'].includes(
    orderStatus
  )
  if (fulfillmentMethod === 'TABLE') {
    return isFinished
      ? `${tableFriendlyName} Completed ${
          useExactTime
            ? moment(updatedAt).format('DD/MM [at] HH:mm')
            : moment(updatedAt).fromNow()
        }`
      : `${tableFriendlyName} Ordered ${
          useExactTime
            ? moment(createdAt).format('DD/MM [at] HH:mm')
            : moment(createdAt).fromNow()
        }`
  }
  const fulfilmentAt =
    fulfillmentMethod === 'DELIVERY'
      ? estimatedDeliveryDate
      : estimatedCompletionTime

  const fulfillmentName = upperFirst(toLower(fulfillmentMethod))

  const isLate = moment(fulfilmentAt).isBefore(updatedAt)

  const isOverdue = moment(fulfilmentAt).isBefore()

  let notFinishedNotOverdueCompletionTime = ''

  if (
    fulfillmentMethod == 'NETWORK' &&
    estimatedCompletionTime &&
    estimatedDeliveryDate
  ) {
    notFinishedNotOverdueCompletionTime = `Pickup ${
      asap
        ? moment(estimatedCompletionTime).format('[at] HH:mm')
        : moment(estimatedCompletionTime).calendar(null, calendarFormat)
    }, Delivery ${moment(estimatedDeliveryDate).format('HH:mm')}`
  } else {
    notFinishedNotOverdueCompletionTime = `${fulfillmentName} ${
      asap && fulfilmentAt
        ? 'ASAP'
        : useExactTime
        ? moment(fulfilmentAt).format('DD/MM [at] HH:mm')
        : selectedDeliveryWindow &&
          selectedDeliveryWindow.start &&
          selectedDeliveryWindow.end
        ? `${moment(selectedDeliveryWindow.start).calendar(
            null,
            calendarFormat
          )} - ${moment(selectedDeliveryWindow.end).format('HH:mm')}`
        : moment(fulfilmentAt).calendar(null, calendarFormat)
    }`
  }

  return (
    <Fragment>
      {isFinished ? (
        <Fragment>
          {fulfillmentName}{' '}
          {isLate ? (
            <Fragment>
              <span style={{ color: 'red' }}>
                {moment(updatedAt).calendar(null, calendarFormatCompleted)}
              </span>
              {moment(fulfilmentAt).format('[, due] HH:mm')}
            </Fragment>
          ) : (
            <Fragment>
              <span>
                {selectedDeliveryWindow &&
                selectedDeliveryWindow.start &&
                selectedDeliveryWindow.end
                  ? `${moment(selectedDeliveryWindow.start).format(
                      'HH:mm'
                    )} - ${moment(selectedDeliveryWindow.end).format('HH:mm')}`
                  : moment(fulfilmentAt).calendar(
                      null,
                      calendarFormatCompleted
                    )}
              </span>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {notFinishedNotOverdueCompletionTime}
          {isOverdue ? (
            <span style={{ color: 'red' }}>
              {' '}
              ({moment(fulfilmentAt).fromNow(true)}
              {' late'})
            </span>
          ) : (
            <span style={{ color: 'green' }}>
              {' '}
              ({fulfilmentAt ? moment(fulfilmentAt).fromNow() : 'ASAP'})
            </span>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

FulfilmentStatus.propTypes = {
  orderStatus: string,
  fulfillmentMethod: string,
  createdAt: string,
  updatedAt: string,
  estimatedDeliveryDate: string,
  estimatedCompletionTime: string,
  asap: bool,
  tableFriendlyName: string,
}

export default FulfilmentStatus
