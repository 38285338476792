import gql from 'graphql-tag'

const GET_SOLD_OUT_AT_OUTLETS = gql`
  query getOutletSoldOutItemsByRestaurantId($restaurantId: String!) {
    getOutlets(restaurantId: $restaurantId) {
      outlets {
        openingTimes
        id
        name
        soldOutUntilItems
        soldOutMenuItemIds
        hiddenMenuItemIds
        nextOpen
      }
    }
  }
`

export default GET_SOLD_OUT_AT_OUTLETS
