import React, { Fragment } from 'react'
import { array, object } from 'prop-types'
import { HTMLTable } from '@blueprintjs/core'

import Currency from '@components/Currency/Currency'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'

const OrderLoyaltyCardStamps = ({ marketplaceId, loyaltyCardInfo }) => {
  const unredeemedStamps = loyaltyCardInfo.filter(
    stamp => !stamp.cardHasBeenClaimed
  )

  if (!unredeemedStamps.length) {
    return null
  }
  return (
    <Fragment>
      <div>
        <HTMLTable compact={true}>
          <thead>
            <tr>
              <th>Loyalty Card Stamps</th>
              <th style={{ textAlign: 'right' }}>Reward Value</th>
            </tr>
          </thead>
          <tbody>
            {unredeemedStamps.map(stamp => (
              <Fragment key={stamp.discountId}>
                <tr>
                  <td>
                    <Link
                      to={`/marketplaces/${marketplaceId}/loyalty-cards?discountId=${stamp.discountId}`}
                    >
                      {stamp.loyaltyCardName}
                    </Link>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Currency amount={stamp.rewardValue} />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </HTMLTable>
      </div>
    </Fragment>
  )
}

OrderLoyaltyCardStamps.propTypes = {
  marketplaceId: string,
  classes: object,
  loyaltyCardInfo: array,
}

export default OrderLoyaltyCardStamps
