import React, { useState } from 'react'

import { func, object, string } from 'prop-types'
import { FormGroup, FileInput, Button, InputGroup, H6 } from '@blueprintjs/core'
import { errorToast } from '@utils/toast'
import { Formik } from 'formik'
import uploadFileToS3 from './uploadFileToS3'
import { Buffer } from 'buffer'

const PartnerAppAppleKeyUpload = ({
  onSubmit,
  credentials,
  partnerId,
  initialValues,
}) => {
  const [fileName, setFilename] = useState(null)

  const handleFileChange = async (e, setFieldValue, credentials, partnerId) => {
    const file = e.target.files[0]
    // check size
    if (!file) {
      errorToast(`Empty, please choose a file.`)
    } else {
      setFilename(file.name)
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = async evt => {
        // attach extension to string
        if (evt.target.error) {
          if (evt.target.readyState != 2) return
          errorToast(`Error while reading file ${fileName}`)
          return
        }

        try {
          const appStoreConnectApiKeyPath = await uploadFileToS3(
            credentials,
            Buffer.from(reader.result.toString(), 'binary'),
            partnerId,
            'apple/appstoreconnectapi'
          )
          setFieldValue('appStoreConnectApiKeyPath', appStoreConnectApiKeyPath)
        } catch (err) {
          console.error(err)
          errorToast(`Can't upload file - ${err.message}`)
        }
      }
      reader.onerror = () => {
        errorToast("Can't upload file")
      }
    }
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        setFieldValue,
        isSubmitting,
        handleChange,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup label="Team ID" labelFor="text-input">
            <InputGroup
              name="appleTeamIdDefault"
              id="appleTeamIdDefault"
              type="text"
              onChange={handleChange}
              value={values.appleTeamIdDefault}
            />
          </FormGroup>
          <H6>Connect API</H6>
          <FormGroup label="Key ID" labelFor="text-input">
            <InputGroup
              name="appStoreConnectApiKeyId"
              id="appStoreConnectApiKeyId"
              type="text"
              onChange={handleChange}
              value={values.appStoreConnectApiKeyId}
            />
          </FormGroup>

          <FormGroup label="Issuer ID" labelFor="text-input">
            <InputGroup
              name="appStoreConnectApiIssuerId"
              id="appStoreConnectApiIssuerId"
              type="text"
              onChange={handleChange}
              value={values.appStoreConnectApiIssuerId}
            />
          </FormGroup>
          <FormGroup label="Key" labelFor="text-input">
            <FileInput
              text={fileName || 'Choose file...'}
              hasSelection={fileName}
              inputProps={{
                name: 'appStoreConnectApiKeyPath',
              }}
              onInputChange={e =>
                handleFileChange(e, setFieldValue, credentials, partnerId)
              }
              fill
            />
          </FormGroup>
          <div className="bp-card-footer-actions">
            <Button type="submit" loading={isSubmitting} disabled={!fileName}>
              Upload
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

PartnerAppAppleKeyUpload.propTypes = {
  onSubmit: func,
  credentials: object,
  partnerId: string,
  initialValues: object,
}

export default PartnerAppAppleKeyUpload
