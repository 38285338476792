import gql from 'graphql-tag'

const CHANGE_MENU_VISIBILITY = gql`
  mutation changeMenuVisibility(
    $id: String!
    $outletId: String!
    $hidden: Boolean!
  ) {
    changeMenuVisibility(id: $id, outletId: $outletId, hidden: $hidden)
  }
`

export default CHANGE_MENU_VISIBILITY
