import React, { useState } from 'react'
import { FormGroup, Slider, Intent, Button, Card } from '@blueprintjs/core'
import { KM_TO_MILES_FACTOR } from '../../../utils/constants'
import { successToast } from '../../../utils/toast'
import defaultErrorHandler from '../../../utils/defaultErrorHandler'
import EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM from './mutations/editOutletCollectionVisibilityRadiusKM'
import { useMutation } from '@apollo/client'

const CollectionCard: React.FC<{
  marketplaceId: string
  outletVisibilityRadiusKM: number
}> = ({ marketplaceId, outletVisibilityRadiusKM }) => {
  const [radiusInputValue, setRadiusInputValue] = useState(
    outletVisibilityRadiusKM
  )

  const [editMarketplace] = useMutation(
    EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM,
    {
      onCompleted: ({ editMarketplace }) =>
        successToast(editMarketplace.message),
      onError: defaultErrorHandler,
    }
  )

  return (
    <Card>
      <form
        onSubmit={e => {
          e.preventDefault()
          return editMarketplace({
            variables: {
              id: marketplaceId,
              outletCollectionVisibilityRadiusKM: radiusInputValue,
            },
          })
        }}
      >
        <h5 className="bp5-heading">Collection</h5>
        <FormGroup
          label="Outlet Visibility Radius"
          helperText="Only outlets within this radius of the customer will be listed for collection. This setting can take up to an hour to apply."
        >
          <Slider
            min={0}
            max={50}
            stepSize={5}
            labelStepSize={10}
            value={radiusInputValue}
            labelRenderer={(valInKM, { isHandleTooltip }) => {
              if (!isHandleTooltip) return valInKM.toString()
              const valInMiles = Math.round(valInKM * KM_TO_MILES_FACTOR)
              return (
                <span>
                  {valInKM}km&nbsp;/&nbsp;{valInMiles}mi
                </span>
              )
            }}
            onChange={setRadiusInputValue}
          />
        </FormGroup>
        <div className="bp-card-footer-actions">
          <Button text="Save" intent={Intent.NONE} type="submit" />
        </div>
      </form>
    </Card>
  )
}

export default CollectionCard
