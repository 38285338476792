import React from 'react'
import {
  InputGroup,
  FormGroup,
  Button,
  Intent,
  Classes,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'

const Form = ({ handleSubmit, handleChange, errors, values }) => (
  <form onSubmit={handleSubmit} style={{ maxWidth: 600 }}>
    <FormGroup
      label="Name / Number"
      labelFor="friendlyName"
      helperText={
        errors.friendlyName
          ? errors.friendlyName
          : 'Describe the table, customers will use this to select where they are ordering to.'
      }
      intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup
        type="text"
        id="friendlyName"
        name="friendlyName"
        value={values.friendlyName}
        onChange={handleChange}
        intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
      />
    </FormGroup>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button type="submit" text="Create" />
    </div>
  </form>
)

Form.propTypes = {
  handleSubmit: func,
  handleChange: func,
  errors: object,
  values: object,
}

export default Form
