import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_NAME from './queries/getMarketplaceName.query'
import GET_DELIVERYZONE_NAME from './queries/getDeliveryZoneName.query'
import replace from 'lodash/replace'

const isFirstPart = i => i === 0
const isSecondPart = i => i === 1
const isThirdPart = i => i === 2
const isFourthPart = i => i === 3

const extractCrumbs = crumbs => {
  const [marketplaces, marketplaceId, deliveryZones, deliveryZoneId] = crumbs
  return { marketplaceId, deliveryZoneId }
}

const DeliveryZonesCrumbs = ({ crumbs }) => {
  const { marketplaceId, deliveryZoneId } = extractCrumbs(crumbs)
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_MARKETPLACE_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getMarketplaces }) => {
            if (!getMarketplaces) {
              return null
            }
            const { regions } = getMarketplaces
            return (
              <Crumb
                key={crumb}
                text={regions.length ? regions[0].name : crumb}
                link={`/marketplaces/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/marketplaces/${marketplaceId}/delivery-zones`}
        />
      )
    }

    if (isFourthPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_DELIVERYZONE_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getDeliveryZones }) => {
            if (!getDeliveryZones) {
              return null
            }
            const { deliveryZones } = getDeliveryZones

            return (
              <Crumb
                key={crumb}
                text={deliveryZones.length ? deliveryZones[0].name : crumb}
                link={`/marketplaces/${marketplaceId}/delivery-zones/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    return <Crumb key={crumb} text={crumb} />
  })
}

export default DeliveryZonesCrumbs
