import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { bool, func, string } from 'prop-types'

import Query from '@components/Query/Query'
import GET_PARTNER_IDS from './queries/getPartnerIds.query'

const PartnerSelect = ({ partnerId, disabled, onChange }) => (
  <Query query={GET_PARTNER_IDS} showLoader={false}>
    {({ getPartners: { partners } }) => (
      <HTMLSelect
        name="partnerId"
        fill={true}
        options={[
          { value: '', label: 'Select Partner...' },
          ...map(partners, ({ id, name }) => ({
            value: id,
            label: name,
          })),
        ]}
        value={partnerId}
        disabled={disabled}
        onChange={event => onChange(event)}
      />
    )}
  </Query>
)

PartnerSelect.propTypes = {
  partnerId: string,
  disabled: bool,
  onChange: func,
}

export default PartnerSelect
