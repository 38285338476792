import gql from 'graphql-tag'

const DELETE_SEGMENT = gql`
  mutation deleteSegment($id: String!, $marketplaceId: ID!) {
    deleteSegment(id: $id, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export default DELETE_SEGMENT
