import gql from 'graphql-tag'

const GET_MARKETPLACE_MESSAGE_SETTINGS = gql`
  query getMarketplaceMessageSettings($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        emailSenderUsername
        sesMessaging
        cname
        pinpointApplicationId
        pinpointAwsAccountId
        pinpointNotificationStrategy
        pinpointGcmKey
        smsSenderId
        channels {
          setup
          androidEnabled
          iosEnabled
        }
      }
    }
  }
`
export default GET_MARKETPLACE_MESSAGE_SETTINGS
