import gql from 'graphql-tag'

const ARCHIVE_STRIPECONNECTACCOUNT = gql`
  mutation archiveStripeConnectAccount($id: String!) {
    archiveStripeConnectAccount(id: $id) {
      message
      id
    }
  }
`

export default ARCHIVE_STRIPECONNECTACCOUNT
