import { Button, Spinner, Tag } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import { useLazyOtterFetch } from './OtterFetch'

export const OtterConnectionMissing = ({ error, brandId, store }) => {
  const [createOtterConnection, { data, loading }] = useLazyOtterFetch({
    path: `/organization/brands/${brandId}/stores/${store.id}/connection`,
    method: 'POST',
  })

  return error.status !== 404 ? (
    <p>Error connecting to Otter</p>
  ) : (
    <Fragment>
      <Tag>Not connected</Tag>
      <Button
        onClick={() => createOtterConnection({ storeId: store.id })}
        disabled={loading || data}
      >
        {data && 'Connected'}
        {loading && (
          <Fragment>
            <span>Connecting</span> <Spinner size={16} />
          </Fragment>
        )}
        {!loading && !data && 'Create connection'}
      </Button>
    </Fragment>
  )
}

export const OtterConnectionFound = ({ brandId, storeId }) => {
  const [removeOtterConnection, { data: storeRemoved, error }] =
    useLazyOtterFetch({
      path: `/organization/brands/${brandId}/stores/${storeId}/connection`,
      method: 'DELETE',
    })

  if (error) {
    return (
      <OtterConnectionMissing
        error={error}
        brandId={brandId}
        store={{ id: storeId }}
      />
    )
  }

  return (
    <Fragment>
      <p>Connected with id {storeId}</p>

      {!storeRemoved ? (
        <Button
          intent="danger"
          onClick={() => removeOtterConnection({ storeId })}
        >
          Remove connection
        </Button>
      ) : (
        <p>Connection removed</p>
      )}
    </Fragment>
  )
}
