import { useState, Fragment } from 'react'
import { string } from 'prop-types'
import { Button, Tooltip, AnchorButton, Tag } from '@blueprintjs/core'
import momentTz from 'moment-timezone'
import { successToast } from '@utils/toast'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import GET_OPENING_TIMES from './queries/getOpeningTimes.query'
import OUTLET_CLOSE_EARLY from './mutations/closeEarly.mutation'
import ModalContent from './ModalContent'
import Query from '../Query/Query'
import { useMutation } from '@apollo/client'

const isOutletInOpeningTimes = openingTimes => {
  const now = momentTz()
  const openingTime =
    openingTimes.find(({ start, end }) => {
      const [openHour, openMins] = start.time.split(':')
      const [closeHour, closeMins] = end.time.split(':')

      const openTime = momentTz()
        .isoWeekday(start.day)
        .hour(openHour)
        .minutes(openMins)

      // account for open times past midnight
      const closeTime = momentTz()
        .isoWeekday(end.day)
        .hour(closeHour)
        .minutes(closeMins)

      if (closeTime.isBefore(openTime)) {
        closeTime.add(1, 'week')
      }
      // 3 weeks of opening times to make sure we don't miss a day
      return (
        now.isBetween(openTime, closeTime, null, '[]') ||
        now.isBetween(
          openTime.clone().subtract(1, 'week'),
          closeTime.clone().subtract(1, 'week'),
          null,
          '[]'
        ) ||
        now.isBetween(
          openTime.clone().add(1, 'week'),
          closeTime.clone().add(1, 'week'),
          null,
          '[]'
        )
      )
    }) !== undefined

  return openingTime
}

const CloseOrReopen = ({ outletId }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const [reopen] = useMutation(OUTLET_CLOSE_EARLY, {
    onCompleted: () => successToast(`Outlet is now open!`),
    refetchQueries: [{ query: GET_OPENING_TIMES, variables: { outletId } }],
  })

  return (
    <Query
      query={GET_OPENING_TIMES}
      variables={{ outletId }}
      fetchPolicy="network-only"
    >
      {({ getOutlets }) => {
        return (
          <Fragment>
            {getOutlets.outlets[0].isOpen ? (
              <Button onClick={() => setModalOpen(true)}>
                {' '}
                <Tag intent="success" round>
                  OPEN
                </Tag>{' '}
                Close
              </Button>
            ) : isOutletInOpeningTimes(getOutlets.outlets[0].openingTimes) ? (
              <Button onClick={() => reopen()}>
                {' '}
                <Tag intent="danger" round>
                  CLOSED
                </Tag>{' '}
                Reopen
              </Button>
            ) : (
              <Tooltip content="Outlet cannot be opened outside of opening hours">
                <AnchorButton disabled={true}>
                  <Tag intent="danger" round>
                    CLOSED
                  </Tag>
                </AnchorButton>
              </Tooltip>
            )}
            <Dialog
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              title={'Close Outlet'}
            >
              <div className={Classes.DIALOG_BODY}>
                <ModalContent
                  openingTimes={getOutlets.outlets[0].openingTimes}
                  closeModal={() => setModalOpen(false)}
                  outletId={outletId}
                />
              </div>
            </Dialog>
          </Fragment>
        )
      }}
    </Query>
  )
}

CloseOrReopen.propTypes = {
  outletId: string,
}

export default CloseOrReopen
