import { useQuery } from '@apollo/client'
import { StringParam, useQueryParam } from 'use-query-params'
import GET_PARTNER_NAME from './queries/getPartnerName.query'
import { useMatch } from 'react-router-dom'
import GET_MARKETPLACE_NAME from './queries/getMarketplaceName.query'
import Crumb from './Crumb'
import { replace } from 'lodash'
import Helmet from 'react-helmet'

export const ReportCrumbs = ({ crumbs }) => {
  const reportNameCrumb = crumbs[1]
  const [partnerId] = useQueryParam('partnerId', StringParam)
  const partner = useQuery(GET_PARTNER_NAME, {
    variables: { id: partnerId },
    skip: !partnerId,
  })

  const match = useMatch(
    '/reports/delivery-network-failures-by-marketplace/:marketplaceId'
  )
  const marketplaceId = match?.params.marketplaceId
  const marketplace = useQuery(GET_MARKETPLACE_NAME, {
    variables: { id: marketplaceId },
    skip: !marketplaceId,
  })

  return (
    <>
      <Helmet>
        <title>Reports | Redbox Platform</title>
      </Helmet>
      <Crumb text="reports" link={`/reports`} />
      <Crumb
        text={replace(reportNameCrumb, '-', ' ')}
        link={`/reports/${reportNameCrumb}`}
      />
      {reportNameCrumb === 'delivery-network-failures-by-partner' &&
        partner.data &&
        partner.data.getPartners.partners[0] && (
          <Crumb text={partner.data.getPartners.partners[0].name} />
        )}

      {reportNameCrumb === 'delivery-network-failures-by-marketplace' &&
        marketplace.data &&
        marketplace.data.getMarketplaces.regions[0].name && (
          <Crumb text={marketplace.data.getMarketplaces.regions[0].name} />
        )}
    </>
  )
}
