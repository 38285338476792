import gql from 'graphql-tag'

const GET_RESTAURANT = gql`
  query restaurantForDetailsTab($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        cname
        image
        contactName
        contactEmail
        contactPhone
        description
        partnerFee
        stripeId
        deliveryOptions
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        cuisines {
          id
          priority
          id
          name
          emoji
          imageSrc
        }
        partner {
          id
        }
        vatNumber
        isVATregistered
        active
        promoted
        deals
        dealsText
        deliveryDeal
        collectionDeal
        tableDeal
        allowAddOnItems
        displayAllOutlets
        enablePaginatedMenu
        enableStackedMenu
        enableAllergyInformation
        metaDescription
        metaKeywords
        outletOfferFlagOverride
        outletPromoteOverride
        outletCategoriesOverride
        outlets {
          id
          marketplace {
            id
            allowOtterIntegration
          }
        }
      }
    }
  }
`
export default GET_RESTAURANT
