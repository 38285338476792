import React, { useCallback } from 'react'
import { Dialog, PanelStack2 } from '@blueprintjs/core'
import { OtterOrganisations } from './OtterOrganisations'
import { useOtterModal } from './useOtterQueryParam'

export const OtterOnboardingDialog = ({ restaurantId }) => {
  const { close, isOpen } = useOtterModal()

  const [panelStack, setPanelStack] = React.useState(
    // < Array<Panel<{ restaurantId: string }>> >
    [
      {
        renderPanel: OtterOrganisations,
        props: { restaurantId },
        title: 'Otter Brands',
      },
    ]
  )

  const addPanel = useCallback(panel => {
    setPanelStack(stack => [...stack, panel])
  }, [])

  const removePanel = useCallback(() => {
    setPanelStack(stack => stack.slice(0, -1))
  }, [])

  return (
    <Dialog isOpen={isOpen} title="Otter Onboarding" onClose={close}>
      <div
        style={{
          height: '400px',
          display: 'grid', // cheating the panels height
        }}
      >
        <PanelStack2
          onOpen={addPanel}
          onClose={removePanel}
          renderActivePanelOnly={false}
          stack={panelStack}
        />
      </div>
    </Dialog>
  )
}
