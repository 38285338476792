import Currency from '@components/Currency/Currency'
import React from 'react'

export const renderCustomLabel = ({
  value,
  positionRight = false,
  currency = false,
  offset,
  additionalOffset = 0,
  viewBox: { x, y, width },
  textAnchor,
}) => {
  return (
    <text
      x={positionRight ? x + additionalOffset + width : x + offset}
      y={positionRight ? y + offset : y - offset}
      textAnchor={positionRight ? 'end' : textAnchor || 'middle'}
      fontSize="12"
      fontFamily="BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif"
      letterSpacing={0.4}
      fontWeight="400"
      fill="#222222"
      paintOrder="stroke"
      dominantBaseline="middle"
    >
      {currency ? <Currency amount={value} amountInPence /> : value}
    </text>
  )
}
