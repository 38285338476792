import * as yup from 'yup'

export const newTerminalStructure = {
  friendlyName: yup
    .string()
    .required('Please provide a friendly name for the terminal')
    .min(4, 'Terminal name should be longer than 4 chars'),
  serialNumber: yup
    .string()
    .required('Please provide a serial number for the terminal')
    .min(4, 'Please use a serial number longer than 4 chars'),
  outletIds: yup
    .array(yup.string().matches(/\w{25}/, 'The outlet id should have 25 chars'))
    .required('Outlets are required')
    .min(1, 'Please select at least one outlet'),
  pin: yup
    .string()
    .matches(/^[0-9]{4}$/, 'The pin should be 4 digit long and only numbers')
    .required('A pin is required'),
}

export const editTerminalStructure = {
  friendlyName: yup
    .string()
    .min(4, 'Terminal name should be longer than 4 chars'),
  serialNumber: yup
    .string()
    .min(4, 'Please use a serial number longer than 4 chars'),
  outletIds: yup
    .array(yup.string().matches(/\w{25}/, 'The outlet id should have 25 chars'))
    .required('Outlets are required')
    .min(1, 'Please select at least one outlet'),
}

export const editTerminalPinStructure = {
  pin: yup
    .string()
    .matches(/^[0-9]{4}$/, 'The pin should be 4 digit long and only numbers')
    .required('A pin is required'),
}
