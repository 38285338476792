import gql from 'graphql-tag'

const GET_FAQ = gql`
  query getFAQ($id: String!) {
    getFAQs(id: $id) {
      id
      answer
      question
      published
    }
  }
`

export default GET_FAQ
