import React from 'react'
import { Intent, Tag } from '@blueprintjs/core'
import { startCase } from 'lodash'

const comparatorMap = {
  IS: 'is',
  IS_NOT: 'is not',
}

const SingleFilterGroup = ({ filters, filterGroupType }) => {
  if (!filters || !filters.length) {
    return ''
  }

  return filters
    .map(filter => {
      const { attribute, comparator, value } = filter
      return (
        <span key={[attribute, comparator, value].join('.')}>
          <Tag minimal intent={Intent.PRIMARY}>
            {startCase(attribute)}
          </Tag>{' '}
          {comparatorMap[comparator]} <Tag minimal>{startCase(value)}</Tag>
        </span>
      )
    })
    .reduce((components, component) => [
      components,
      filterGroupType === 'ALL' ? <span> and </span> : <span> or </span>,
      component,
    ])
}

export default SingleFilterGroup
