import { useContext } from 'react'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { validation } from '@components/TerminalForm/validation'
import TerminalForm from '@components/TerminalForm/TerminalForm'
import ADD_TERMINAL from '@components/TerminalModal/mutations/addTerminal.mutation'
import GET_OUTLET_TERMINALS from '@components/Outlet/Terminals/queries/getTerminals.query'
import { ModalContext } from '@src/providers/ModalProvider'
import { useMutation } from '@apollo/client'

const TerminalModal = ({ modalOpen, modalClose, outlet: outletId }) => {
  const { setState: toggleModal } = useContext(ModalContext)

  const [addTerminal] = useMutation(ADD_TERMINAL, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Terminal successfully added')
      toggleModal({
        terminalModal: false,
      })
    },
    refetchQueries: [
      {
        query: GET_OUTLET_TERMINALS,
        variables: { outletId },
      },
    ],
  })

  return (
    <Drawer
      isOpen={modalOpen}
      onClose={modalClose}
      title="Add Terminal"
      size={DrawerSize.SMALL}
    >
      <TerminalForm
        onSubmit={values =>
          addTerminal({
            variables: {
              ...values,
            },
          })
        }
        initialValues={{
          friendlyName: '',
          serialNumber: '',
          pin: '',
          softwareVersion: '',
          outletIds: [outletId],
        }}
        validationSchema={validation}
        edit={false}
      />
    </Drawer>
  )
}

TerminalModal.propTypes = {
  ...modalType,
}

export default TerminalModal
