import {
  HTMLTable,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
  Tooltip,
} from '@blueprintjs/core'
import {
  isMarketplaceAdmin,
  isOutletFinancialUser,
  isOutletUser,
} from '@stores/userStore'

import colors from '@styles/colors'
import { Link, LinkProps } from 'react-router-dom'
import { Fragment } from 'react'

interface Props {
  displayBusinessName?: boolean
  outlet: {
    id: string
    name: string
    contactName?: null | string
    contactPhone?: null | string
    contactEmail?: null | string
    contactAddress?: null | {
      city: string
      postcode: string
    }
    promoted?: null | boolean
    isOpen?: null | boolean
    isOnline?: null | boolean
    active?: null | boolean
    restaurant: {
      id: string
      name: string
      active?: null | boolean
    }
    marketplace?: null | {
      id
      name
    }
  }
  overrideMainLink?: (() => void) | string
}

const OutletLink: React.FC<Props> = ({
  outlet,
  displayBusinessName = false,

  overrideMainLink,
}) => {
  let linkProps: LinkProps = { to: '#' }
  switch (typeof overrideMainLink) {
    case 'undefined':
      linkProps = {
        to: `/business/${outlet.restaurant.id}/outlets/${outlet.id}`,
      }
      break
    case 'string':
      linkProps = { to: overrideMainLink }
      break
    case 'function':
      linkProps = {
        to: '#',
        onClick: event => {
          event.preventDefault()
          overrideMainLink()
        },
      }
  }

  return outlet &&
    outlet.active !== undefined &&
    outlet.restaurant.active !== undefined ? (
    <Fragment>
      {outlet.active == false ||
      (outlet.restaurant && outlet.restaurant.active == false) ? (
        // Deactivated
        <Tooltip
          content={`
            ${outlet.active == false ? 'Outlet' : ''}
            ${
              outlet.active == false &&
              outlet.restaurant &&
              outlet.restaurant.active == false
                ? ' & '
                : ''
            }
            ${
              outlet.restaurant && outlet.restaurant.active == false
                ? 'Business'
                : ''
            } ${
            outlet.active == false ||
            (outlet.restaurant && outlet.restaurant.active == false)
              ? 'Deactivated'
              : ''
          }`}
        >
          <Icon icon="symbol-circle" color={colors.inactiveGray} />
        </Tooltip>
      ) : outlet.isOnline ? (
        // Online
        <Tooltip content={'Terminal Online'}>
          <Icon icon="symbol-circle" color={colors.onlineGreen} />
        </Tooltip>
      ) : (
        // Offline
        <Tooltip content={'Terminal Offline'}>
          <Icon icon="symbol-circle" color={colors.offlineRed} />
        </Tooltip>
      )}
      &nbsp;
      {outlet ? (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.RIGHT_BOTTOM}
          content={
            <Fragment>
              <HTMLTable>
                {isMarketplaceAdmin() &&
                  outlet.marketplace &&
                  outlet.marketplace.name && (
                    <thead>
                      <tr>
                        <th>
                          <Link
                            to={`/marketplace/${outlet.marketplace.id}/overview`}
                          >
                            {outlet.marketplace.name}
                          </Link>
                          {!outlet.active && outlet.active !== undefined && (
                            <Tag minimal intent="warning">
                              Deactivated
                            </Tag>
                          )}
                        </th>
                      </tr>
                    </thead>
                  )}
                <tbody>
                  {outlet.restaurant &&
                    outlet.restaurant.name &&
                    outlet.restaurant.id && (
                      <Fragment>
                        <tr>
                          <th>
                            {isOutletUser() || isOutletFinancialUser() ? (
                              outlet.restaurant.name
                            ) : (
                              <Link
                                to={`/business/${outlet.restaurant.id}/overview`}
                              >
                                {outlet.restaurant.name}
                              </Link>
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <Icon icon="nest" color={colors.inactiveGray} />{' '}
                            <Link
                              to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/overview`}
                            >
                              {outlet.name}
                            </Link>
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  {outlet.contactName && (
                    <tr>
                      <td>
                        <Icon icon="person" color={colors.inactiveGray} />{' '}
                        {outlet.contactName}
                      </td>
                    </tr>
                  )}
                  {outlet.contactPhone && (
                    <tr>
                      <td>
                        <Icon icon="phone" color={colors.inactiveGray} />{' '}
                        <a href={`tel:${outlet.contactPhone}`}>
                          {outlet.contactPhone}
                        </a>
                      </td>
                    </tr>
                  )}
                  {outlet.contactEmail && (
                    <tr>
                      <td>
                        <Icon icon="envelope" color={colors.inactiveGray} />{' '}
                        <a href={`mailto:${outlet.contactEmail}`}>
                          {outlet.contactEmail}
                        </a>
                      </td>
                    </tr>
                  )}
                  {outlet.contactAddress && (
                    <tr>
                      <td>
                        <Icon icon="map-marker" color={colors.inactiveGray} />{' '}
                        {outlet.contactAddress.city}
                        {', '}
                        {outlet.contactAddress.postcode}
                      </td>
                    </tr>
                  )}
                </tbody>
              </HTMLTable>
            </Fragment>
          }
        >
          <Link {...linkProps}>
            {displayBusinessName ? outlet.restaurant.name : outlet.name}
          </Link>
        </Popover>
      ) : (
        <span className="bp5-text-disabled">Unknown</span>
      )}
      {outlet.promoted && (
        <Tooltip
          content={
            'This business is promoted and will appear at the top of the search listings.'
          }
        >
          <Icon icon={'endorsed'} intent={Intent.SUCCESS} />
        </Tooltip>
      )}
    </Fragment>
  ) : (
    <span className="bp5-text-disabled">-</span>
  )
}

export default OutletLink
