import {
  Button,
  Callout,
  Card,
  Divider,
  H1,
  H3,
  H4,
  H5,
  Icon,
  Intent,
  NonIdealState,
  ProgressBar,
  Switch,
  Tag,
} from '@blueprintjs/core'
import { Col, Row } from '@components/_FlexGrid'
import { Formik } from 'formik'
import { first, get } from 'lodash'
import { Fragment } from 'react'
import { ReactSVG } from 'react-svg'

import Query from '@components/Query/Query'
import {
  canPerformAction,
  canView,
  isAtLeastMarketplaceOwner,
  isMenuEditor,
  isOutletFinancialUser,
  isPartner,
} from '@stores/userStore'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast, warningToast } from '@utils/toast'
import ACTIVATE_RESTAURANT from './mutations/activateRestaurant.mutation'
import OrdersForInterval from './OrdersForInterval'
import GET_RESTAURANT from './queries/getRestaurant.query'

import businessSVG from '@assets/ui/business.svg'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { Restaurant } from '../../../types.generated'
import { useMutation } from '@apollo/client'
import { RestaurantForDashboardQuery } from './queries/getRestaurant.query.generated'

const Dashboard = () => {
  const { restaurants: id } = useParams()
  const userIsOutletFinancialUser = isOutletFinancialUser()
  const navigate = useNavigate()

  const userIsMenuEditor = isMenuEditor()
  const userIsPartner = isPartner()
  const userIsAtLeastMarketplaceOwner = isAtLeastMarketplaceOwner()

  const [editRestaurant] = useMutation(ACTIVATE_RESTAURANT, {
    onCompleted: ({ editRestaurant }) => successToast(editRestaurant.message),
    onError: defaultErrorHandler,
  })

  return (
    <Query<RestaurantForDashboardQuery>
      query={GET_RESTAURANT}
      variables={{ id }}
      loaderTitle={'Loading Business'}
    >
      {({ getRestaurants: { restaurants } }) => {
        const restaurant = first(restaurants as Array<Restaurant>)
        if (!restaurant) return <NonIdealState title="Business not found" />

        const stats = {
          terminal: {
            online: 0,
            offline: 0,
            total: 0,
          },
          outlet: {
            deactivated: 0,
            active: 0,
            total: 0,
          },
          menu: {
            soldOut: 0,
            total: 0,
          },
          discounts: {
            total: 0,
          },
          memberDiscounts: {
            total: 0,
          },
          quickStart: {
            progress: 0,
            total: 0,
          },
        }

        const enableAnalytics = get(
          restaurant,
          'outlets[0].marketplace.enableAnalytics',
          false
        )

        restaurant.outlets.forEach(outlet => {
          stats.outlet.deactivated += outlet.active ? 0 : 1
          stats.outlet.active += outlet.active ? 1 : 0
          stats.outlet.total++
          outlet.terminals.forEach(terminal => {
            stats.terminal.online += terminal.isOnline ? 1 : 0
            stats.terminal.offline += terminal.isOnline ? 0 : 1
          })
          stats.terminal.total += outlet.terminals.length || 0
        })

        restaurant.menuItems.forEach(item => {
          stats.menu.soldOut += item.outletSoldOut ? 1 : 0
          stats.menu.total++
        })

        restaurant.discounts.forEach(discount => {
          if (!discount.customer && !discount.enrolmentKey) {
            stats.discounts.total++
          } else {
            stats.memberDiscounts.total++
          }
        })

        const tasks = [
          stats.terminal.total > 0,
          stats.menu.total > 0,
          stats.outlet.active > 0,
          stats.outlet.total > 0,
        ]

        stats.quickStart = {
          progress: tasks.filter(Boolean).length || 0,
          total: tasks.length || 1,
        }

        return (
          <Fragment>
            {!restaurant.active && (
              <Fragment>
                <Callout intent={Intent.DANGER} icon="ban-circle">
                  Deactivated Business, Outlets will not be visible to
                  Customers.
                </Callout>
                <br />
              </Fragment>
            )}
            <Row gutter={24}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Card>
                  <Row style={{ marginBottom: 12 }}>
                    <Col
                      span={10}
                      style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center',
                      }}
                    >
                      <ReactSVG
                        src={businessSVG}
                        style={{ margin: '-8px 18px 0 4px' }}
                      />
                      <div>
                        <H5 style={{ margin: '0 0 2 0' }}>Business</H5>
                        <H3 style={{ margin: '0 0 2 0', color: '#ff3b4c' }}>
                          {restaurant.name}
                        </H3>
                        <p>
                          {restaurant.contactAddress.firstLine},{' '}
                          {restaurant.contactAddress.city},{' '}
                          {restaurant.contactAddress.postcode}
                        </p>
                      </div>
                    </Col>
                    <Col span={2}>
                      {canPerformAction('editRestaurant') && (
                        <p
                          className={'bp5-text-muted'}
                          style={{ textAlign: 'right' }}
                        >
                          <Link to={`/business/${id}/details`}>
                            {restaurant.active ? 'Edit' : 'Activate / Edit'}
                          </Link>
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Divider />
                  <Row style={{ margin: '24 0 26 0' }}>
                    <Col span={6}>
                      <strong>{restaurant.contactName}</strong> (Owner)
                      <br />
                      {restaurant.contactEmail && (
                        <Fragment>
                          <a href={`mailto:${restaurant.contactEmail}`}>
                            {restaurant.contactEmail}
                          </a>
                          <br />
                        </Fragment>
                      )}
                      {restaurant.contactPhone && (
                        <a href={`tel:${restaurant.contactPhone}`}>
                          {restaurant.contactPhone}
                        </a>
                      )}
                    </Col>
                    {userIsPartner && (
                      <Col span={6}>
                        <H5>Partner</H5>

                        <strong>
                          <Link to={`/partners/${restaurant.partner.id}`}>
                            {restaurant.partner.name}
                          </Link>
                        </strong>

                        <br />
                        {restaurant.partner.contactAddress.city}
                        <br />
                        {restaurant.partner.contactAddress.postcode}
                      </Col>
                    )}
                  </Row>
                  {stats.outlet.active === 0 && stats.menu.total === 0 && (
                    <Row>
                      <Col span={12} md={12}>
                        <div>
                          <Row>
                            <Col span={12} md={5}>
                              <H4>{`Quick Start ${stats.quickStart.progress}/${stats.quickStart.total}`}</H4>
                            </Col>
                            <Col span={12} md={7} style={{ margin: '6 0 6 0' }}>
                              <ProgressBar
                                stripes={false}
                                value={
                                  stats.quickStart.progress /
                                  stats.quickStart.total
                                }
                                animate={false}
                                intent={'success'}
                              />
                            </Col>
                          </Row>

                          <p className="bp5-text-small">
                            This is your business dashboard, it will guide you
                            through creating your business and make sure it's
                            setup correctly before it goes live.
                          </p>

                          <br />

                          {stats.quickStart.progress ==
                            stats.quickStart.total && (
                            <Fragment>
                              <Callout
                                icon={
                                  restaurant.active ? 'tick-circle' : 'help'
                                }
                                intent={
                                  restaurant.active
                                    ? Intent.SUCCESS
                                    : Intent.PRIMARY
                                }
                                title={
                                  restaurant.active
                                    ? 'Business Live'
                                    : "Ready to 'Go Live'?"
                                }
                              >
                                {restaurant.active ? (
                                  <p>
                                    This business is activated and accepting
                                    orders.
                                  </p>
                                ) : (
                                  <p>
                                    Everything is ready, activate your business
                                    and start taking orders.
                                  </p>
                                )}

                                <Formik
                                  initialValues={{
                                    active: restaurant.active,
                                  }}
                                  onSubmit={async values => {
                                    await editRestaurant({
                                      variables: {
                                        active: values.active,
                                        partnerId: get(
                                          restaurant,
                                          'partner.id'
                                        ),
                                        id: restaurant.id,
                                      },
                                    })
                                  }}
                                >
                                  {({ values, handleChange, submitForm }) => (
                                    <Switch
                                      label="Activate Business"
                                      name="active"
                                      checked={values.active}
                                      large
                                      onChange={e => {
                                        if (!e.currentTarget.checked) {
                                          warningToast(
                                            `Deactivating a restaurant will also deactivate all of its outlets.`
                                          )
                                        }
                                        handleChange(e)
                                        setTimeout(submitForm, 0)
                                      }}
                                    />
                                  )}
                                </Formik>
                              </Callout>
                              <br />
                            </Fragment>
                          )}

                          <Callout
                            title="Build Product Menus"
                            icon={
                              stats.menu.total > 0 ? 'tick-circle' : 'circle'
                            }
                            intent={stats.menu.total > 0 ? 'success' : 'none'}
                          >
                            <p>
                              Menus and Discounts are controlled at business
                              level, which means that any discount implemented
                              will be applied to ALL of your outlets.
                            </p>
                            {stats.menu.total === 0 && (
                              <Button
                                outlined
                                rightIcon="circle-arrow-right"
                                onClick={() => {
                                  navigate(`/business/${id}/menu/tree`)
                                }}
                              >
                                Build Menu
                              </Button>
                            )}
                          </Callout>
                          <br />
                          <Callout
                            title="Create Outlets"
                            icon={
                              stats.outlet.total > 0 ? 'tick-circle' : 'circle'
                            }
                            intent={stats.outlet.total > 0 ? 'success' : 'none'}
                          >
                            <p>
                              Businesses are likened to being individual brands,
                              which can have multiple sites (Outlets) much in
                              the same way that you have a restaurant chain with
                              outlets up and down the country.
                            </p>
                            {stats.outlet.total === 0 && (
                              <Button
                                outlined
                                rightIcon="circle-arrow-right"
                                onClick={() => {
                                  navigate(`/business/${id}/outlets`)
                                }}
                              >
                                Create First Outlet
                              </Button>
                            )}
                          </Callout>
                          <br />
                          <Callout
                            title="Setup Terminals"
                            icon={
                              stats.terminal.total > 0
                                ? 'tick-circle'
                                : 'circle'
                            }
                            intent={
                              stats.terminal.total > 0 ? 'success' : 'none'
                            }
                          >
                            <p>
                              Terminals allow you to print receipts, accept and
                              manage orders. If you have a terminal it needs to
                              be assigned to an outlet.
                            </p>
                            {stats.terminal.total === 0 && (
                              <Button
                                outlined
                                rightIcon="circle-arrow-right"
                                onClick={() => {
                                  navigate(`/business/${id}/menu/tree`)
                                }}
                              >
                                Assign Terminals
                              </Button>
                            )}
                          </Callout>
                          <br />
                          <Callout
                            title="Activate Outlets"
                            icon={
                              stats.outlet.active > 0 ? 'tick-circle' : 'circle'
                            }
                            intent={
                              stats.outlet.active > 0 ? 'success' : 'none'
                            }
                          >
                            <p>
                              At least one outlet needs to be activated before
                              the business can be activated and go live on the
                              marketplace.
                            </p>
                            {stats.outlet.active === 0 && (
                              <Button
                                outlined
                                rightIcon="circle-arrow-right"
                                onClick={() => {
                                  navigate(`/business/${id}/outlets`)
                                }}
                              >
                                View Outlets
                              </Button>
                            )}
                          </Callout>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
              <Col xs={12} md={8}>
                <Row gutter={24}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Card
                      style={{ minHeight: 150 }}
                      interactive
                      onClick={() => {
                        stats.outlet.total === 1
                          ? navigate(
                              `/business/${id}/outlets/${
                                first(restaurant.outlets).id
                              }`
                            )
                          : navigate(`/business/${id}/outlets`)
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={6}>
                            <H5>Outlets</H5>
                          </Col>
                          <Col span={6}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              <Icon icon="arrow-right" />
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <H1>{stats.outlet.total}</H1>
                        </Row>
                        <Row>
                          {stats.outlet.total > 0 ? (
                            stats.outlet.active > 0 ? (
                              <Tag
                                intent={Intent.SUCCESS}
                                large
                                round
                                minimal
                                icon={'tick'}
                              >
                                OK
                              </Tag>
                            ) : (
                              stats.outlet.deactivated > 0 && (
                                <Tag
                                  minimal
                                  intent={Intent.DANGER}
                                  large
                                  round
                                  icon={'ban-circle'}
                                >
                                  {stats.outlet.deactivated} Deactivated
                                </Tag>
                              )
                            )
                          ) : (
                            <Tag icon="plus" minimal intent={Intent.NONE} large>
                              Add Outlet
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Card
                      interactive={canView('virtualTerminals')}
                      elevation={stats.terminal.offline > 0 ? 4 : 0}
                      style={
                        stats.terminal.offline > 0
                          ? { minHeight: 150, border: '2px solid red' }
                          : { minHeight: 150 }
                      }
                      onClick={() => {
                        canView('virtualTerminals') &&
                          navigate(`/business/${id}/outlets`)
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={6}>
                            <H5>Terminals</H5>
                          </Col>
                          <Col span={6}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {canView('virtualTerminals') && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Fragment>
                          <Row>
                            <Col span={3} md={3}>
                              <H1>{stats.terminal.total}</H1>
                            </Col>
                          </Row>
                          <Row>
                            {stats.terminal.total > 0
                              ? stats.terminal.offline > 0 && (
                                  <Tag
                                    intent={Intent.DANGER}
                                    large
                                    round
                                    icon="warning-sign"
                                  >
                                    {stats.terminal.offline} Offline
                                  </Tag>
                                )
                              : canView('virtualTerminals') && (
                                  <Tag
                                    icon="plus"
                                    minimal
                                    intent={Intent.NONE}
                                    large
                                  >
                                    {' '}
                                    Add Terminal
                                  </Tag>
                                )}
                          </Row>
                        </Fragment>
                      </Fragment>
                    </Card>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={4}>
                    <Card
                      interactive={
                        !userIsMenuEditor && !userIsOutletFinancialUser
                      }
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        !userIsMenuEditor &&
                          !userIsOutletFinancialUser &&
                          navigate(`/business/${id}/discounts`)
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={9}>
                            <H5>Discounts</H5>
                          </Col>
                          <Col span={3}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {!userIsMenuEditor &&
                                !userIsOutletFinancialUser && (
                                  <Icon icon="arrow-right" />
                                )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12} md={12}>
                            <H1>{stats.discounts.total}</H1>
                          </Col>
                        </Row>
                        {!userIsMenuEditor && !userIsOutletFinancialUser && (
                          <Row>
                            <Col span={12} md={12}>
                              <Tag
                                icon="plus"
                                minimal
                                intent={Intent.NONE}
                                large
                              >
                                Add Discount
                              </Tag>
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Card>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={4}>
                    <Card
                      interactive={
                        !userIsMenuEditor && !userIsOutletFinancialUser
                      }
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        !userIsMenuEditor &&
                          !userIsOutletFinancialUser &&
                          navigate(`/business/${id}/members`)
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={9}>
                            <H5>Members</H5>
                          </Col>
                          <Col span={3}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {!userIsMenuEditor &&
                                !userIsOutletFinancialUser && (
                                  <Icon icon="arrow-right" />
                                )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={12} md={12}>
                            <H1>{stats.memberDiscounts.total}</H1>
                          </Col>
                        </Row>

                        {canPerformAction('inviteUser') && (
                          <Row>
                            <Col span={12} md={12}>
                              <Tag
                                icon="plus"
                                minimal
                                intent={Intent.NONE}
                                large
                              >
                                Invite Member
                              </Tag>
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Card>
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={4}>
                    <Card
                      interactive={!userIsOutletFinancialUser}
                      style={{ minHeight: 150 }}
                      onClick={() =>
                        !userIsOutletFinancialUser &&
                        navigate(`/business/${id}/menu`)
                      }
                    >
                      <Fragment>
                        <Row>
                          <Col span={6}>
                            <H5>Menu</H5>
                          </Col>
                          <Col span={6}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {!userIsOutletFinancialUser && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{stats.menu.total}</H1>
                            <span className="statInfo">items</span>
                          </Col>
                        </Row>
                        <Row>
                          {stats.menu.soldOut > 0 ? (
                            <Tag intent={Intent.DANGER} large round minimal>
                              {stats.menu.soldOut} Sold Out
                            </Tag>
                          ) : (
                            !userIsOutletFinancialUser && (
                              <Tag
                                icon="plus"
                                minimal
                                intent={Intent.NONE}
                                large
                              >
                                Add Menu & Items
                              </Tag>
                            )
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            {restaurant.active &&
              enableAnalytics &&
              userIsAtLeastMarketplaceOwner && (
                <Row gutter={24}>
                  <Col span={12} md={12}>
                    <OrdersForInterval business={restaurant} />
                  </Col>
                </Row>
              )}
          </Fragment>
        )
      }}
    </Query>
  )
}

export default Dashboard
