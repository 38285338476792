import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowBilling = ({ updateStatus, allowBilling }) => {
  return (
    <Formik initialValues={{ allowBilling }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Marketplace Billing'}
            checked={values.allowBilling}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowBilling: e.currentTarget.checked,
              })
            }}
            name="allowBilling"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowBilling.propTypes = {
  allowBilling: bool,
  errors: object,
}

export default MarketplaceAllowBilling
