import gql from 'graphql-tag'

const GET_DISCOUNT_WITH_VOUCHERS = gql`
  query getDiscountAndVouchers($discountId: String!) {
    getDiscount(id: $discountId) {
      discount {
        id
        name
        discountPercentage
        discountAmount
        allowedOutlets {
          id
        }
        startDate
        endDate
        daysOfWeek
        minimumSubtotalGross
        vouchers {
          id
          key
          onePerCustomer
          customerIds
          orderIds
          transactionIds
          active
          usageLimit
          usageCount
          createdAt
          updatedAt
        }
      }
    }
  }
`

export default GET_DISCOUNT_WITH_VOUCHERS
