import { split } from 'lodash'
import React, { useState } from 'react'
import AppBuildModal from './AppBuild.modal'
import AppImportModal from './AppImport.modal'
import AppPageContext from './appPageContext'

const AppPageWrapper = ({ entityName, children, location }) => {
  const [modalOpen, setAppModalOpen] = useState(false)
  const [marketplaceId, setMarketplaceId] = useState(null)
  const [modalAppImportOpen, setAppImportModalOpen] = useState(false)
  const [appId, setAppId] = useState(split(location, '/')[4])

  return (
    <AppPageContext.Provider
      value={{
        modalOpen,
        setAppModalOpen,
        modalAppImportOpen,
        setAppImportModalOpen,
        entityName,
        appId,
        setAppId,
        marketplaceId,
        setMarketplaceId,
      }}
    >
      <AppBuildModal
        modalOpen={modalOpen}
        modalClose={() => setAppModalOpen(false)}
        appId={appId}
      />
      <AppImportModal
        modalOpen={modalAppImportOpen}
        modalClose={() => setAppImportModalOpen(false)}
        location={location}
      />

      {children}
    </AppPageContext.Provider>
  )
}

export default AppPageWrapper
