import { Fragment, useState } from 'react'
import first from 'lodash/first'
import get from 'lodash/get'
import { Alert, Card, H5, H6 } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Row, Col } from '@components/_FlexGrid'

import Query from '@components/Query/Query'

import { validation } from './validation'
import GET_MARKETPLACE from './queries/getMarketplace.query'
import EDIT_MARKETPLACE from './mutations/editMarketplace.mutation'
import EDIT_MARKETPLACE_TERMINAL from './mutations/editMarketplaceTerminal.mutation'
import ALLOW_REVIEWS from './mutations/allowReviews.mutation'
import ALLOW_ORDER_TO_TABLE from './mutations/allowOrderToTable.mutation'
import ALLOW_MARKETING from './mutations/allowMarketing.mutation'
import MarketplaceForm from '@components/MarketplaceForm/MarketplaceForm'

import { successToast } from '@utils/toast'
import MarketplaceAllowReviewsForm from '@components/MarketplaceForm/MarketplaceAllowReviewsForm'
import MarketplaceAllowOrderToTableForm from '@components/MarketplaceForm/MarketplaceAllowOrderToTableForm'
import MarketplaceAllowMarketingForm from '@components/MarketplaceForm/MarketplaceAllowMarketingForm'
import TerminalSettingsForm from '@components/MarketplaceForm/TerminalSettingsForm'

import {
  canPerformAction,
  isAtLeastMarketplaceAdmin,
  isPlatformLevelUser,
} from '@stores/userStore'
import ALLOW_SINGLE_ITEM_ORDER_NOTES from './mutations/allowSingleItemOrderNotes.mutation'
import MarketplaceAllowSingleItemOrderNotesForm from '@components/MarketplaceForm/MarketplaceAllowSingleItemOrderNotesForm'
import MarketplaceAllowExtendedPreorders from '@components/MarketplaceForm/MarketplaceAllowExtendedPreorders'
import ALLOW_EXTENDED_PREORDERS from './mutations/allowExtendedPreorders.mutation'
import ALLOW_ONBOARDING from './mutations/allowOnboarding.mutation'
import GET_MARKETPLACE_BILLING from '@components/Marketplace/Billing/queries/getMarketplaceBilling.query'
import MarketplaceAllowOnboardingForm from '@components/MarketplaceForm/MarketplaceAllowOnboardingForm'
import AllowedCategories from '@components/Marketplace/AllowedCategories/AllowedCategories'
import { createAwsOptions } from '@utils/createAwsOptions'
import { omit } from 'lodash'
import ALLOW_MULTI_OUTLET_TERMINAL from '@components/Marketplace/Details/mutations/allowMultiOutletTerminal.mutation'
import MarketplaceAllowMultiOutletTerminal from '@components/MarketplaceForm/MarketplaceAllowMultiOutletTerminal'
import ALLOW_SMS_MARKETING from './mutations/allowSMSMarketing.mutation'
import ALLOW_FACEBOOK_MARKETING from './mutations/allowFacebookMarketing.mutation'
import MarketplaceAllowSMSMarketing from '@components/MarketplaceForm/MarketplaceAllowSMSMarketing'
import MarketplaceAllowFacebookMarketing from '@components/MarketplaceForm/MarketplaceAllowFacebookMarketing'
import ENABLE_ANALYTICS from './mutations/enableAnalytics.mutation'
import ENABLE_CUSTOMER_V2_CLIENT from './mutations/enableCustomerV2Client.mutation'
import MarketplaceEnableAnalyticsForm from '@components/MarketplaceForm/MarketplaceEnableAnalyticsForm'
import MarketplaceEnableCustomerV2Client from '@components/MarketplaceForm/MarketplaceEnableCustomerV2Client'
import MarketplaceAllowBilling from '../../MarketplaceForm/MarketplaceAllowBilling'
import ALLOW_BILLING from './mutations/allowBilling.mutation'
import CollectionCard from './CollectionCard'
import GET_TABLE_SERVICE_OUTLETS from './queries/getTableServiceOutlets.query'
import ENABLE_CONFIRM_ADDRESS_AT_PAYMENT from '@components/Marketplace/Details/mutations/enableConfirmAddressAtPayment.mutation'
import MarketplaceEnableConfirmAddressAtPaymentForm from '@components/MarketplaceForm/MarketplaceEnableConfirmAddressAtPaymentForm'
import ENABLE_PAYBOX_ENTERPRISE from './mutations/enablePayboxEnterprise.mutation'
import MarketplaceEnablePayboxEnterprise from '../../MarketplaceForm/MarketplaceEnablePayboxEnterprise'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { RegionalMarketplace } from '@src/types.generated'
import MarketplaceEnableLoyaltyCardScheme from '../../MarketplaceForm/MarketplaceEnableLoyaltyCardScheme'
import ENABLE_LOYALTY_CARD_SCHEME from './mutations/allowLoyalty.mutation'
import MarketplaceAllowPendingOrderAlertSwitch from '../../MarketplaceForm/MarketplaceAllowPendingOrderAlertSwitch'
import ALLOW_PENDING_ORDER_ALERT from './mutations/allowPendingOrderAlert'
import ALLOW_OTTER_INTEGRATION from './mutations/allowOtterIntegration.mutation'
import MarketplaceAllowOtterIntegrationSwtich from '../../MarketplaceForm/MarketplaceAllowOtterIntegrationSwtich'
import ToggleMarketplaceStatus from '../ToggleMarketplaceStatus/ToggleMarketplaceStatus'

const awsOptions = createAwsOptions()
const Details = () => {
  const [showTableOrderingAlert, setShowTableOrderingAlert] = useState(false)
  const { marketplace: marketplaceId } = useParams()

  const [editMarketplace] = useMutation(EDIT_MARKETPLACE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowReviews] = useMutation(ALLOW_REVIEWS, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowMarketing] = useMutation(ALLOW_MARKETING, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [enableAnalytics] = useMutation(ENABLE_ANALYTICS, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [enableCustomerV2Client] = useMutation(ENABLE_CUSTOMER_V2_CLIENT, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [enablePayboxEnterprise] = useMutation(ENABLE_PAYBOX_ENTERPRISE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowBilling] = useMutation(ALLOW_BILLING, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
    refetchQueries: [
      {
        query: GET_MARKETPLACE_BILLING,
        variables: { id: marketplaceId },
      },
    ],
  })

  const [allowSMSMarketing] = useMutation(ALLOW_SMS_MARKETING, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowFacebookMarketing] = useMutation(ALLOW_FACEBOOK_MARKETING, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowOrderToTable] = useMutation(ALLOW_ORDER_TO_TABLE, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowOnboarding] = useMutation(ALLOW_ONBOARDING, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [enableConfirmAddressAtPayment] = useMutation(
    ENABLE_CONFIRM_ADDRESS_AT_PAYMENT,
    {
      onError: defaultErrorHandler,
      onCompleted: ({ editMarketplace }) =>
        successToast(editMarketplace.message),
    }
  )

  const [allowSingleItemOrderNotes] = useMutation(
    ALLOW_SINGLE_ITEM_ORDER_NOTES,
    {
      onError: defaultErrorHandler,
      onCompleted: ({ editMarketplace }) =>
        successToast(editMarketplace.message),
    }
  )

  const [allowExtendedPreorders] = useMutation(ALLOW_EXTENDED_PREORDERS, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowMultiOutletTerminal] = useMutation(ALLOW_MULTI_OUTLET_TERMINAL, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [editMarketplaceTerminal] = useMutation(EDIT_MARKETPLACE_TERMINAL, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [enableLoyalty] = useMutation(ENABLE_LOYALTY_CARD_SCHEME, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowPendingOrderAlert] = useMutation(ALLOW_PENDING_ORDER_ALERT, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [allowOtterIntegration] = useMutation(ALLOW_OTTER_INTEGRATION, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  return (
    <Query
      query={GET_MARKETPLACE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Marketplace'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace: RegionalMarketplace | undefined = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        const currentIdValue = awsOptions.filter(
          ({ value }) => value === marketplace.pinpointAwsAccountId
        )

        const initialValues = {
          ...omit(marketplace, ['contactAddress.id']),
          companyType: marketplace.companyType || 'COMPANY',
          partnerId: marketplace.partner?.id,
          pinpointAwsAccountId: currentIdValue.length
            ? currentIdValue[0].value
            : '',
        }

        return (
          <Row gutter={24}>
            <Col sm={12} lg={8}>
              <Card>
                <MarketplaceForm
                  onSubmit={values =>
                    editMarketplace({
                      variables: { ...values },
                    })
                  }
                  initialValues={initialValues}
                  awsOptions={awsOptions}
                  validationSchema={validation}
                />
              </Card>
            </Col>

            <Col sm={12} lg={4}>
              <Card>
                <H5>Features</H5>
                {isPlatformLevelUser() && (
                  <Fragment>
                    <H6>Platform Controlled</H6>

                    <MarketplaceAllowReviewsForm
                      allowReviews={!!get(marketplace, 'allowReviews')}
                      updateStatus={values =>
                        allowReviews({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowMarketingForm
                      allowMarketing={!!get(marketplace, 'allowMarketing')}
                      updateStatus={values =>
                        allowMarketing({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceEnableAnalyticsForm
                      enableAnalytics={!!get(marketplace, 'enableAnalytics')}
                      updateStatus={values =>
                        enableAnalytics({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceEnableCustomerV2Client
                      enableCustomerV2Client={
                        !!get(marketplace, 'enableCustomerV2Client')
                      }
                      updateStatus={values =>
                        enableCustomerV2Client({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceEnableLoyaltyCardScheme
                      enableLoyalty={!!get(marketplace, 'enableLoyalty')}
                      updateStatus={values =>
                        enableLoyalty({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <br />
                    <H6>Premium Features</H6>
                    <MarketplaceAllowBilling
                      allowBilling={!!get(marketplace, 'allowBilling')}
                      updateStatus={values =>
                        allowBilling({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowSMSMarketing
                      allowSMSMarketing={
                        !!get(marketplace, 'allowSMSMarketing')
                      }
                      disabled={!get(marketplace, 'allowBilling')}
                      updateStatus={values =>
                        allowSMSMarketing({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowFacebookMarketing
                      allowFacebookMarketing={
                        !!get(marketplace, 'allowFacebookMarketing')
                      }
                      disabled={!get(marketplace, 'allowBilling')}
                      updateStatus={values =>
                        allowFacebookMarketing({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowPendingOrderAlertSwitch
                      allowPendingOrderAlert={
                        !!get(marketplace, 'allowPendingOrderAlertSwitch')
                      }
                      updateStatus={values =>
                        allowPendingOrderAlert({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowOtterIntegrationSwtich
                      allowOtterIntegration={
                        !!get(marketplace, 'allowOtterIntegration')
                      }
                      updateStatus={values =>
                        allowOtterIntegration({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <br />
                    <H6>Marketplace Controlled</H6>
                  </Fragment>
                )}

                {canPerformAction('editMarketplace') ? (
                  <Fragment>
                    <Query
                      query={GET_TABLE_SERVICE_OUTLETS}
                      variables={{
                        marketplaceIds: [marketplaceId],
                      }}
                    >
                      {({ getOutlets }) => (
                        <Fragment>
                          <MarketplaceAllowOrderToTableForm
                            allowOrderToTable={
                              !!get(marketplace, 'allowOrderToTable')
                            }
                            allowedToChange={!getOutlets.outlets.length}
                            updateStatus={values =>
                              !getOutlets.outlets.length
                                ? allowOrderToTable({
                                    variables: {
                                      ...values,
                                      id: marketplaceId,
                                    },
                                  })
                                : setShowTableOrderingAlert(true)
                            }
                          />

                          <Alert
                            isOpen={showTableOrderingAlert}
                            onClose={() => setShowTableOrderingAlert(false)}
                          >
                            <h5 className="bp5-heading">Table ordering</h5>
                            <p>
                              Unable to deactivate table ordering while it is
                              enabled on the following outlets:
                            </p>

                            <ul>
                              {getOutlets.outlets.map(outlet => (
                                <li key={outlet.id}>
                                  <a
                                    href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/tables`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {outlet.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </Alert>
                        </Fragment>
                      )}
                    </Query>

                    <MarketplaceAllowOnboardingForm
                      allowOnboarding={!!get(marketplace, 'allowOnboarding')}
                      updateStatus={values =>
                        allowOnboarding({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />
                    <MarketplaceEnablePayboxEnterprise
                      disabled={!!marketplace.stripeEnterpriseId}
                      enablePayboxEnterprise={
                        !!get(marketplace, 'enablePayboxEnterprise')
                      }
                      updateStatus={values =>
                        enablePayboxEnterprise({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <br />
                    <H5>Settings</H5>

                    <MarketplaceEnableConfirmAddressAtPaymentForm
                      confirmAddressAtPayment={
                        !!get(marketplace, 'confirmAddressAtPayment')
                      }
                      updateStatus={values =>
                        enableConfirmAddressAtPayment({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowSingleItemOrderNotesForm
                      allowSingleItemOrderNotes={
                        !!get(marketplace, 'allowSingleItemOrderNotes')
                      }
                      updateStatus={values =>
                        allowSingleItemOrderNotes({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowExtendedPreorders
                      allowExtendedPreorders={
                        !!get(marketplace, 'allowExtendedPreorders')
                      }
                      updateStatus={values =>
                        allowExtendedPreorders({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />

                    <MarketplaceAllowMultiOutletTerminal
                      allowMultiOutletTerminal={get(
                        marketplace,
                        'allowMultiOutletTerminal'
                      )}
                      updateField={values =>
                        allowMultiOutletTerminal({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />
                  </Fragment>
                ) : null}
              </Card>
              <Card>
                <h5 className="bp5-heading">Categories</h5>
                <AllowedCategories
                  marketplaceId={marketplaceId}
                  initialCategories={get(marketplace, 'allowedCuisines', [])}
                  optInAllowedCategories={get(
                    marketplace,
                    'optInAllowedCuisines',
                    false
                  )}
                  categoryEmojiOrImage={marketplace.categoryEmojiOrImage}
                />
              </Card>
              {isAtLeastMarketplaceAdmin() && (
                <>
                  <Card>
                    <h5 className="bp5-heading">Receipt Settings</h5>

                    <TerminalSettingsForm
                      initialValues={marketplace}
                      onSubmit={values =>
                        editMarketplaceTerminal({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />
                  </Card>
                  <CollectionCard
                    marketplaceId={marketplaceId}
                    outletVisibilityRadiusKM={
                      marketplace.outletCollectionVisibilityRadiusKM
                    }
                  />
                </>
              )}
              {isPlatformLevelUser() ? (
                <ToggleMarketplaceStatus
                  id={marketplaceId}
                  name={marketplace.name}
                  isDeactivated={marketplace.isDeactivated}
                />
              ) : null}
            </Col>
          </Row>
        )
      }}
    </Query>
  )
}

export default Details
