import gql from 'graphql-tag'

const ACCEPT_NETWORK_ORDER = gql`
  mutation acceptNetworkOrder($id: String!, $newCompletionDateTime: DateTime) {
    acceptNetworkOrder(
      orderId: $id
      newCompletionDateTime: $newCompletionDateTime
    ) {
      message
      order {
        id
        orderStatus
        estimatedDeliveryDate
        estimatedCompletionTime
        fulfillmentMethod
      }
    }
  }
`

export default ACCEPT_NETWORK_ORDER
