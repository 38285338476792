import React, { Fragment, useEffect, useState } from 'react'
import {
  HTMLTable,
  Card,
  Icon,
  Tooltip,
  Popover,
  Classes,
  Position,
  PopoverInteractionKind,
  H5,
  NonIdealState,
  ButtonGroup,
  ControlGroup,
  Button,
} from '@blueprintjs/core'
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import { Link, Navigate } from 'react-router-dom'
import { union, without } from 'lodash'

import TableHeader from './tableHead'
import OpenStatus from '@components/OpenStatus/OpenStatus'
import {
  Pager,
  Search,
  MarketplaceFilter,
  RestaurantFilter,
  DeliveryZoneFilter,
} from '@components/Toolbar'
import GET_OUTLETS from './queries/getOutlets.query'
import FilterRow from '@components/FilterRow/FilterRow'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import Dropdown from '@components/Toolbar/RadioFilter'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import OutletLink from '@components/OpenStatus/OutletLink'
import {
  canView,
  isAtLeastBusinessUser,
  isAtLeastMarketplaceUser,
  isOutletFinancialUser,
  isOutletUser,
} from '@stores/userStore'
import colors from '@styles/colors'
import { useBusinessPageLayout } from '../useBusinessPageLayout'
import { useGetDeliveryZonesQuery } from '@src/components/Marketplace/DeliveryZones/queries/getDeliveryZones.query.generated'
import ConnectStatus from '@components/ConnectStatus/ConnectStatus'

const DEFAULT_RECORDS = 50
const DEFAULT_ORDER = 'name_ASC'
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const Outlets = () => {
  useBusinessPageLayout()

  const [
    {
      restaurantFilter = [],
      activeFilter,
      directPaymentsFilter,
      deliveryZoneFilter = [],
      orderBy = null,
    },
    setQueryParams,
  ] = useQueryParams({
    restaurantFilter: DelimitedArrayParam,
    activeFilter: StringParam,
    directPaymentsFilter: StringParam,
    deliveryZoneFilter: DelimitedArrayParam,
    orderBy: StringParam,
  })

  const {
    marketplaceIds: marketplaceFilter,
    onChangeMarketplaceIds: onChangeMarketplaceFilter,
  } = useRoleAwareBusinessFilterQueryParams()

  const [state, setState] = useState(DEFAULT_PAGINATION_STATE)
  const { searchValue: terminalSearch, resetSearch } = useSearchQueryParam()

  useEffect(() => {
    if (terminalSearch) {
      setState(DEFAULT_PAGINATION_STATE)
    }
  }, [terminalSearch])

  const { data: zoneData } = useGetDeliveryZonesQuery({
    variables: { marketplaceId: marketplaceFilter[0] },
    skip: !marketplaceFilter || marketplaceFilter.length !== 1,
  })

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({
        ...prevState,
        total: totalCount,
      }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({
        ...prevState,
        outcomeLength: returnCount,
      }))
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  const handleRestaurantFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      restaurantFilter: checked
        ? union(restaurantFilter, [id])
        : without(restaurantFilter, id),
    })
  }
  const handleDeliveryZoneFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      deliveryZoneFilter: checked
        ? union(deliveryZoneFilter, [id])
        : without(deliveryZoneFilter, id),
    })
  }

  const handleActiveFilter = ({ id }) => {
    setQueryParams({
      activeFilter: activeFilter === id ? undefined : id,
    })
  }

  const handleDirectPaymentsFilter = ({ id }) => {
    setQueryParams({
      directPaymentsFilter: directPaymentsFilter === id ? undefined : id,
    })
  }

  const setOrderBySelected = (key, order) => {
    setQueryParams({
      orderBy: order === 'NONE' ? undefined : `${key}_${order}`,
    })
  }

  const resetFilters = () => {
    setQueryParams({
      restaurantFilter: undefined,
      activeFilter: undefined,
      directPaymentsFilter: undefined,
      deliveryZoneFilter: undefined,
      orderBy: undefined,
    })
    onChangeMarketplaceFilter(undefined)
    resetSearch()
  }
  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Search autoFocus placeholder="Name, contact..." />
          {isAtLeastMarketplaceUser() && <MarketplaceFilter icon="filter" />}
          {marketplaceFilter?.length ? (
            <RestaurantFilter
              disabled={marketplaceFilter === null}
              restaurantFilter={restaurantFilter}
              marketplaceFilter={marketplaceFilter}
              onChange={handleRestaurantFilter}
            />
          ) : null}
          {marketplaceFilter?.length === 1 ? (
            /* delivery zone filtering currently set to be 
            available when filtering by 1 marketplace at a time */
            <DeliveryZoneFilter
              disabled={marketplaceFilter === null}
              marketplaceId={marketplaceFilter[0]}
              deliveryZoneFilter={deliveryZoneFilter}
              onChange={handleDeliveryZoneFilter}
            />
          ) : null}
          <Dropdown
            disabled={state.total === null}
            selected={activeFilter}
            setSelected={handleActiveFilter}
            placeholder="Active"
            items={[
              { name: 'All', id: null },
              { name: 'Active', id: 'true' },
              { name: 'Not Active', id: 'false' },
            ]}
          />

          <Dropdown
            disabled={state.total === null}
            selected={directPaymentsFilter}
            setSelected={handleDirectPaymentsFilter}
            placeholder="Payment"
            items={[
              { name: 'All', id: null },
              { name: 'Manual', id: 'false' },
              { name: 'Paybox', id: 'true' },
            ]}
          />
          <Button
            icon="filter-remove"
            onClick={resetFilters}
            disabled={
              marketplaceFilter.length === 0 &&
              restaurantFilter.length === 0 &&
              !activeFilter &&
              !terminalSearch
            }
          />
        </ControlGroup>
      </ButtonGroup>

      <Pager
        goToPrevious={goToPrevious}
        goToNext={goToNext}
        goToPage={goToPage}
        defaultNmbRecords={DEFAULT_RECORDS}
        skip={state.skip}
        total={state.total}
        outcomeLength={state.outcomeLength}
        totalCount={state.total}
        dataName="Outlets"
      />
    </FilterRow>
  )

  return (
    <div className="bp5-table-frame">
      {renderFilterBar()}
      <DebouncedQuery
        query={GET_OUTLETS}
        variables={{
          orderBy: orderBy || DEFAULT_ORDER,
          search: terminalSearch.length > 2 ? terminalSearch : '',
          marketplaceIds: marketplaceFilter,
          restaurantIds: restaurantFilter,
          isActive: activeFilter ? activeFilter === 'true' : undefined,
          isDirectPayment: directPaymentsFilter
            ? directPaymentsFilter === 'true'
            : undefined,
          ...state,
        }}
        loaderTitle="Loading Outlets"
        ErrorComponent={
          <NonIdealState
            icon="th-list"
            title="Loading Error"
            description="Unable to load outlet data, please retry."
          />
        }
      >
        {data => {
          if (data && data.getOutlets) {
            setTotalCount(data.getOutlets.totalCount, data.getOutlets.count)
          }
          const deliveryZones = zoneData?.getDeliveryZones?.deliveryZones || []
          let filteredOutlets = data?.getOutlets?.outlets || []

          if (deliveryZoneFilter?.length) {
            const selectedZones = deliveryZones.filter(zone =>
              deliveryZoneFilter.includes(zone.id)
            )
            if (selectedZones.length) {
              const excludedOutletIds = new Set(
                selectedZones.flatMap(zone =>
                  zone.outletDeliveryZoneCosts.map(cost => cost.outlet.id)
                )
              )
              filteredOutlets = filteredOutlets.filter(outlet =>
                excludedOutletIds.has(outlet.id)
              )
            }
          }

          if (
            // Redirect if you just have one outlet - likely for these user roles.
            data.getOutlets.outlets.length === 1 &&
            (isOutletFinancialUser() || isOutletUser())
          ) {
            return (
              <Navigate
                // from="/outlets"
                to={`/business/${data.getOutlets.outlets[0].restaurant.id}/outlets/${data.getOutlets.outlets[0].id}`}
                replace
              />
            )
          }

          return (
            <div className="bp5-table-container bp5-scrollable">
              {data.getOutlets && filteredOutlets.length === 0 ? (
                <div style={{ padding: '100px' }}>
                  <NonIdealState
                    icon="th-list"
                    title="No Outlets Found"
                    description="There are no outlets to display."
                    action={
                      <Button onClick={resetFilters} minimal intent="primary">
                        Clear Filters
                      </Button>
                    }
                  />
                </div>
              ) : (
                <Card className={'bp5-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <TableHeader
                      setSelected={setOrderBySelected}
                      selected={orderBy}
                    />
                    <tbody>
                      {data &&
                        data.getOutlets &&
                        filteredOutlets.map(outlet => {
                          return (
                            <tr key={outlet.id}>
                              {/* Outlet */}
                              <td>
                                <OutletLink outlet={outlet} />
                              </td>

                              {/* Ordering */}
                              <td>
                                <OpenStatus
                                  openingTimes={outlet.openingTimes}
                                  restaurantId={outlet.restaurant.id}
                                  restaurantIsActive={outlet.restaurant.active}
                                  outletIsActive={outlet.active}
                                  outletId={outlet.id}
                                  outletIsOpen={outlet.isOpen}
                                  outletIsOnline={outlet.isOnline}
                                  outletIsOnlineOverride={
                                    outlet.isOnlineOverride
                                  }
                                />
                              </td>

                              {/* Paybox */}
                              <td>
                                <ConnectStatus
                                  stripeConnect={outlet.stripeConnect}
                                  stripeOnboarding={
                                    outlet.marketplace.stripeOnboarding
                                  }
                                  stripeId={outlet.stripeId}
                                  link={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                                />
                              </td>

                              {/* Business */}
                              <td>
                                <Tooltip
                                  content={
                                    outlet.restaurant.active ? (
                                      <strong>Active</strong>
                                    ) : (
                                      <Fragment>
                                        <strong>Deactivated Business</strong> -{' '}
                                        <em>Not visible to customers</em>
                                      </Fragment>
                                    )
                                  }
                                >
                                  <div className="bp5-text-overflow-ellipsis">
                                    <Icon
                                      icon="small-tick"
                                      color={
                                        outlet.restaurant.active
                                          ? colors.onlineGreen
                                          : colors.inactiveGray
                                      }
                                    />{' '}
                                    {isAtLeastBusinessUser() ? (
                                      <Link
                                        to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                                      >
                                        Setup&nbsp;Paybox
                                      </Link>
                                    ) : (
                                      outlet.restaurant.name
                                    )}
                                  </div>
                                </Tooltip>
                              </td>

                              {/* Marketplace */}
                              <td>
                                {outlet.marketplace && (
                                  <Fragment>
                                    {isAtLeastMarketplaceUser() ? (
                                      <Link
                                        to={`/marketplaces/${outlet.marketplace.id}`}
                                      >
                                        {outlet.marketplace.name}
                                      </Link>
                                    ) : (
                                      outlet.marketplace.name
                                    )}
                                  </Fragment>
                                )}
                              </td>

                              {/* Contact */}
                              <td>
                                {outlet.contactName ? (
                                  <Popover
                                    interactionKind={
                                      PopoverInteractionKind.HOVER
                                    }
                                    position={Position.RIGHT_TOP}
                                    popoverClassName={
                                      Classes.POPOVER_CONTENT_SIZING
                                    }
                                    content={
                                      <Fragment>
                                        <H5>{outlet.contactName}</H5>
                                        <p>
                                          {outlet.contactPhone}
                                          <br />
                                          {outlet.contactEmail && (
                                            <a
                                              href={`mailto:${outlet.contactEmail}`}
                                            >
                                              {outlet.contactEmail}
                                            </a>
                                          )}
                                        </p>
                                        <p />
                                      </Fragment>
                                    }
                                    className={Classes.TOOLTIP_INDICATOR}
                                  >
                                    {outlet.contactName}
                                  </Popover>
                                ) : (
                                  <Fragment>
                                    <Tooltip
                                      content={
                                        'Required information must be provided.'
                                      }
                                    >
                                      <Icon
                                        icon="warning-sign"
                                        intent="warning"
                                      />
                                    </Tooltip>{' '}
                                    <Link
                                      to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                    >
                                      Add Contact
                                    </Link>
                                  </Fragment>
                                )}
                              </td>

                              {/* Delivery */}
                              <td>
                                {outlet.defaultDeliveryTime}{' '}
                                <span className="bp5-text-muted bp5-text-small">
                                  min
                                </span>
                              </td>

                              {/* Collection */}
                              <td>
                                {outlet.defaultCollectionTime}{' '}
                                <span className="bp5-text-muted bp5-text-small">
                                  min
                                </span>
                              </td>

                              {/* Terminals */}
                              <td>
                                {outlet.terminals.length > 0 ? (
                                  canView('virtualTerminals') ? (
                                    <Link
                                      to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/terminals`}
                                    >
                                      {outlet.terminals.length}
                                    </Link>
                                  ) : (
                                    <span>{outlet.terminals.length}</span>
                                  )
                                ) : (
                                  <span className="bp5-text-muted">0</span>
                                )}
                              </td>

                              {/* Zones */}
                              <td>
                                {outlet.deliveryZoneCosts.length > 0 ? (
                                  <Link
                                    to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`}
                                  >
                                    {outlet.deliveryZoneCosts.length}
                                  </Link>
                                ) : (
                                  <span className="bp5-text-muted">0</span>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </HTMLTable>
                </Card>
              )}
            </div>
          )
        }}
      </DebouncedQuery>
    </div>
  )
}

export default Outlets
