import {
  ButtonGroup,
  Callout,
  Card,
  Divider,
  HTMLTable,
  Intent,
  NonIdealState,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from '@blueprintjs/core'
import { Col, Row } from '@components/_FlexGrid'
import PrintContent from '@components/PrintContent/PrintContent'
import Query from '@components/Query/Query'
import { canView, isPlatformLevelUser } from '@stores/userStore'
import { get, startCase } from 'lodash'
import { Fragment } from 'react'
import OrderPrintout from './OrderPrintout/OrderPrintout'

import GET_ORDER from './queries/getOrder.query'

import Currency from '@components/Currency/Currency'
import Enum from '@components/Enum/Enum'
import OrderStatusEnum from '@components/Enum/OrderStatusEnum'
import OrderStatusTimeline from '@components/Orders/Order/OrderStatusTimeline'
import OrderLoyaltyCardStamps from './OrderLoyaltyCardStamps'
import Review from '@components/Review/Review'
import { PaymentIcon } from 'react-svg-credit-card-payment-icons'
import DeliveryAddress from './DeliveryAddress'
import DeliveryNetwork from './DeliveryNetwork'
import FulfilmentStatus from './FulfilmentStatus'
import Notes from './Notes'
import OrderItems from './OrderItems'
import OrderStatusInfo from './OrderStatusInfo'
import Totals from './Totals'

const Order = ({ id }) => (
  <Query query={GET_ORDER} variables={{ id: id }}>
    {({ order }) => {
      const review = get(order, 'review')
      if (!order || (order && !order.orderStatus))
        return (
          <NonIdealState
            icon="error"
            title="Order Not Found"
            description="Unable to load order data, please retry."
          />
        )

      const paymentMethod = order.paymentMethod.split('_')[0]

      return (
        <Fragment>
          <Row gutter={24}>
            <Col xs={12} md={4} data-test-id="order-details-status-tag">
              <OrderStatusEnum
                orderStatus={order.orderStatus}
                autoRejectAt={order.autoRejectAt}
                autoAcceptedAt={order.autoAcceptedAt}
                isAutoRejected={order.isAutoRejected}
                asap={order.asap}
                details={order.isAutoRejected && order.cancellationNotes}
                large
              />
            </Col>
            <Col xs={12} md={8}>
              <div className="bp5-scrollable">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    minWidth: 'max-content',
                  }}
                >
                  <ButtonGroup style={{ marginRight: '10px' }}>
                    <PrintContent>
                      <OrderPrintout order={order} />
                    </PrintContent>
                  </ButtonGroup>
                  <OrderStatusInfo order={order} minimal={false} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {!order.asap && order.orderStatus === 'PENDING' && (
                <Fragment>
                  <Callout
                    intent="warning"
                    title={
                      <FulfilmentStatus
                        orderStatus={order.orderStatus}
                        fulfillmentMethod={order.fulfillmentMethod}
                        createdAt={order.createdAt}
                        updatedAt={order.updatedAt}
                        estimatedDeliveryDate={
                          order.selectedDeliveryWindow
                            ? order.selectedDeliveryWindow.end
                            : order.estimatedDeliveryDate
                        }
                        estimatedCompletionTime={
                          order.selectedDeliveryWindow
                            ? order.selectedDeliveryWindow.start
                            : order.estimatedCompletionTime
                        }
                        asap={order.asap}
                        tableFriendlyName={get(
                          order.tableSnapshot,
                          'friendlyName',
                          'Table (Unknown)'
                        )}
                        selectedDeliveryWindow={order.selectedDeliveryWindow}
                      />
                    }
                  />
                  <br />
                </Fragment>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12} md={8}>
              <Card>
                <DeliveryAddress {...order} />
                <Divider />
                <Notes {...order} />
                <OrderLoyaltyCardStamps {...order} />
                <OrderItems {...order} />
                <Totals {...order} />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="bp5-nopad">
                <OrderStatusTimeline order={order} />
              </Card>
              <Card className="bp5-nopad">
                <HTMLTable bordered={true} compact={true}>
                  <thead>
                    <tr>
                      <td colSpan={2}>
                        <strong>Payment</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {order.stripeCharge && order.stripeCharge.source && (
                      <tr>
                        <td>
                          <Tooltip
                            content={`${startCase(
                              `${order.stripeCharge.source.brand} ${order.stripeCharge.source.funding}`
                            )} (${order.stripeCharge.source.country.toUpperCase()})`}
                          >
                            <PaymentIcon
                              type={order.stripeCharge.source.brand}
                              format="logo"
                              width={32}
                            />
                          </Tooltip>
                        </td>
                        <td>
                          <span className="bp5-monospace-text">
                            •••• {order.stripeCharge.source.last4} &nbsp;
                            {order.stripeCharge.source.exp_month}/
                            {order.stripeCharge.source.exp_year}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Amount</td>
                      <td>
                        {!order.paid &&
                        [
                          'AWAITING_PAYMENT',
                          'AUTH_ACTION_PENDING',
                          'PENDING',
                        ].includes(order.orderStatus) &&
                        paymentMethod === 'CARD' ? (
                          <Tag
                            intent={Intent.WARNING}
                            icon={<Spinner size={16} intent={Intent.WARNING} />}
                            minimal
                            data-test-id="payment-method-tag"
                          >
                            {'CARD AUTH '}
                            <Currency amount={order.grossTotal} />
                          </Tag>
                        ) : (
                          <Tag
                            intent={order.paid ? Intent.SUCCESS : Intent.DANGER}
                            icon={order.paid ? 'small-tick' : 'small-cross'}
                            minimal
                            data-test-id="payment-method-tag"
                          >
                            {paymentMethod}
                            {paymentMethod === 'CASH' && ' FEES'}
                            {order.paid ? ' PAID ' : ' NOT PAID '}
                            <Currency amount={order.grossTotal} />
                          </Tag>
                        )}
                      </td>
                    </tr>

                    {order.stripeCharge && order.stripeCharge.networkStatus && (
                      <tr>
                        <td>Status</td>
                        <td>
                          <Enum
                            tagName={order.stripeCharge.networkStatus}
                            minimal
                          />
                        </td>
                      </tr>
                    )}
                    {order.stripeCharge && order.stripeCharge.riskLevel && (
                      <tr>
                        <td>Risk</td>
                        <td>
                          <Enum
                            tagName={order.stripeCharge.riskLevel}
                            minimal
                            rightIcon={
                              <Text>{order.stripeCharge.riskScore || ''}</Text>
                            }
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </HTMLTable>
              </Card>
              {review && <Review review={review} />}
              {order.fulfillmentMethod === 'NETWORK' && (
                <DeliveryNetwork order={order} />
              )}
              {order.userAgent && (
                <Card className="bp5-nopad">
                  <HTMLTable bordered={true} compact={true}>
                    <thead>
                      <tr>
                        <td colSpan={2}>
                          <strong>Client</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <Fragment>
                        {order.userAgent.client &&
                          order.userAgent.client.name && (
                            <tr>
                              <td>Client</td>
                              <td>
                                {order.userAgent.client.name.indexOf('web') > -1
                                  ? 'Website'
                                  : 'App'}{' '}
                                <Tag round minimal style={{ float: 'right' }}>
                                  {order.userAgent.client.version}
                                </Tag>
                              </td>
                            </tr>
                          )}
                        {order.userAgent.os &&
                          order.userAgent.os.name &&
                          order.userAgent.os.version && (
                            <tr>
                              <td>OS</td>
                              <td>
                                {order.userAgent.os.name}{' '}
                                <Tag round minimal style={{ float: 'right' }}>
                                  {order.userAgent.os.version}
                                </Tag>
                              </td>
                            </tr>
                          )}
                        {order.userAgent.browser &&
                          order.userAgent.browser.name && (
                            <tr>
                              <td>Browser</td>
                              <td>
                                {order.userAgent.browser.name}{' '}
                                <Tag round minimal style={{ float: 'right' }}>
                                  {order.userAgent.browser.version}
                                </Tag>
                              </td>
                            </tr>
                          )}
                      </Fragment>
                    </tbody>
                  </HTMLTable>
                </Card>
              )}

              {canView('marketplaces') && (
                <Fragment>
                  <Card className="bp5-nopad">
                    <HTMLTable>
                      <thead>
                        <tr>
                          <td>
                            <strong>Commission</strong>
                          </td>
                          <td width="70">
                            <strong>Basket</strong>
                          </td>
                          <td width="70">
                            <strong>Charge</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="bp5-text-muted">Customer</td>
                          <td></td>
                          <td>
                            <Currency amount={order.partnerCharge || 0} />
                          </td>
                        </tr>
                        <tr>
                          <td>Business</td>
                          <td>{order.partnerFee} %</td>
                          <td>
                            <Currency
                              amount={order.merchantCommissionCharge || 0}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Marketplace</td>
                          <td>{order.partnerFee} %</td>
                          <td>
                            <Currency amount={order.partnerCharge || 0} />
                          </td>
                        </tr>
                        {isPlatformLevelUser() && order.platformFee != null && (
                          <tr>
                            <td>&#8618;&nbsp;Platform</td>
                            <td>{order.platformFee} %</td>
                            <td>
                              <Currency amount={order.platformCharge} />
                            </td>
                          </tr>
                        )}
                        {isPlatformLevelUser() &&
                          order.partnerCommissionFee !== null && (
                            <Fragment>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&#8618;&nbsp;Redbox
                                </td>
                                <td>
                                  {order.platformFee -
                                    (order.partnerCommissionFee || 0)}{' '}
                                  %
                                </td>
                                <td>
                                  <Currency
                                    amount={
                                      order.platformCharge -
                                      (order.partnerCommissionCharge || 0)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&#8618;&nbsp;Partner
                                </td>
                                <td>{order.partnerCommissionFee} %</td>
                                <td>
                                  <Currency
                                    amount={order.partnerCommissionCharge || 0}
                                  />
                                </td>
                              </tr>
                            </Fragment>
                          )}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </Fragment>
              )}
            </Col>
          </Row>
        </Fragment>
      )
    }}
  </Query>
)

export default Order
