export const deliveryZoneType = {
  COORDINATE_POLYGON: 'COORDINATE_POLYGON',
  RADIUS_AROUND_OUTLET: 'RADIUS_AROUND_OUTLET',
  STUART: 'STUART',
  WAYBOX: 'WAYBOX',
  UBER_DIRECT: 'UBER_DIRECT',
}
export const device = {
  IOS: 'ios',
  ANDROID: 'android',
}
export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const ONE_WEEK = ONE_DAY * 7
export const KM_TO_MILES_FACTOR = 0.621371

// how many minutes before auto reject do we alarm orders/virtual terminal
export const MINUTES_FOR_AUTO_REJECT_ALARM = 8
