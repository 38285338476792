import gql from 'graphql-tag'

const MENU_ITEM = gql`
  fragment getMenuTreeMenuItemFields on MenuItem {
    id
    name
    position
    price
    isVegan
    isVegetarian
    isGlutenFree
    isDairyFree
    spiceLevel
    isKeto
    ageRestricted
    parentMenus {
      id
    }
  }
`

const GET_MENU_TREE = gql`
  query getMenuTree($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        outlets {
          id
          soldOutMenuItemIds
          soldOutUntilItems
          hiddenMenuItemGroupIds
        }
        menuItemsGroups(
          where: { parentMenu: null, archived: false }
          orderBy: position_ASC
        ) {
          id
          name
          position
          fulfillmentMethods
          parentMenu {
            id
          }
          menuItems {
            ...getMenuTreeMenuItemFields
          }
          childrenMenus {
            id
            name
            position
            fulfillmentMethods
            parentMenu {
              id
            }
            menuItems {
              ...getMenuTreeMenuItemFields
            }
            childrenMenus {
              id
              name
              position
              fulfillmentMethods
              parentMenu {
                id
              }
              menuItems {
                ...getMenuTreeMenuItemFields
              }
            }
          }
        }
      }
    }
  }

  ${MENU_ITEM}
`

export default GET_MENU_TREE
