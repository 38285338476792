import React, { Fragment } from 'react'
import { Button, NonIdealState } from '@blueprintjs/core'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'

const AddressNonIdealState = ({ description }) => {
  return (
    <Fragment>
      <NonIdealState
        icon="map"
        title="No Pickup Address"
        description={description}
        action={
          <Link to="details">
            <Button>Update Address</Button>
          </Link>
        }
        className="bp5-surface-card-non-ideal-state"
      />
    </Fragment>
  )
}

AddressNonIdealState.propTypes = {
  description: string.isRequired,
}

export default AddressNonIdealState
