/* eslint-disable prettier/prettier */
import {
  Button,
  Classes,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { errorToast, successToast } from '@utils/toast'
import { Formik } from 'formik'
import { Fragment } from 'react'
import ADD_SEGMENT from './mutations/addSegment.mutation'
import GET_SEGMENTS from './queries/getSegments.query'
import * as yup from 'yup'
import { addBusinessSegmentStructure } from '../../../validation/business-segment'
import { BusinessSegmentType } from '../../../types.generated'
import { useMutation } from '@apollo/client'
import defaultErrorHandler from '@src/utils/defaultErrorHandler'

const AddSegmentModal = ({ modalOpen, modalClose, marketplaceId }) => {
  const segmentTypes = [
    { label: 'Outlets', value: BusinessSegmentType.Outlets },
    { label: 'Categories', value: BusinessSegmentType.Categories },
  ]

  const [addRequest] = useMutation(ADD_SEGMENT, {
    onCompleted: data => {
      const businessSegment = data.addBusinessSegment
      if (businessSegment.id) {
        successToast(`Created ${businessSegment.name} segment`)
        modalClose(businessSegment.id)
      } else {
        errorToast('Error creating segment')
      }
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      {
        query: GET_SEGMENTS,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Create Segment"
      style={{
        width: 740,
      }}
    >
      <Fragment>
        <Formik
          initialValues={{
            name: '',
            type: BusinessSegmentType.Outlets,
          }}
          onSubmit={async values => {
            await addRequest({
              variables: {
                ...values,
                marketplaceId,
              },
            })
          }}
          validationSchema={yup.object().shape(addBusinessSegmentStructure)}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className={Classes.DIALOG_BODY}>
                <p className="bp5-text-muted">
                  A segment is a group of outlets that match certain filters.
                  You can create customised collections of outlets to display on
                  the home page and outlet list.
                </p>
                <br />
                <FormGroup
                  label="Name"
                  labelInfo="(required)"
                  intent={errors.name ? Intent.DANGER : Intent.NONE}
                  helperText={errors.name || ''}
                >
                  <InputGroup
                    name="name"
                    type="text"
                    value={values.name}
                    intent={errors.name ? Intent.DANGER : Intent.NONE}
                    placeholder="e.g. Saturday Night Favourites"
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Segment type"
                  labelInfo="(required)"
                  helperText={errors.type || ''}
                  intent={errors.type ? Intent.DANGER : Intent.NONE}
                >
                  <HTMLSelect
                    name={`type`}
                    options={[
                      ...segmentTypes.map(attribute => ({
                        ...attribute,
                        key: attribute.value,
                      })),
                    ]}
                    value={values.type}
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Create" type="submit" />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Fragment>
    </Dialog>
  )
}

export default AddSegmentModal
