import gql from 'graphql-tag'

export const ARCHIVE_HEADER_LINK = gql`
  mutation archiveHeaderLink($id: String!, $marketplaceId: String!) {
    archiveHeaderLink(id: $id, marketplaceId: $marketplaceId) {
      message
    }
  }
`

export const ARCHIVE_FOOTER_LINK = gql`
  mutation archiveFooterLink($id: String!, $marketplaceId: String!) {
    archiveFooterLink(id: $id, marketplaceId: $marketplaceId) {
      message
    }
  }
`
