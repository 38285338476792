import { Button, Intent } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { useState } from 'react'
import ADD_PAYMENT_METHOD from './mutations/addBillingPaymentMethod.mutation'
import { object, string, bool } from 'prop-types'
import { useMutation } from '@apollo/client'

const AddPaymentMethodButton = ({
  id,
  level = 'MARKETPLACE',
  product = null,
  fill = false,
  disabled = false,
  style = {},
  minimal = false,
  intent = 'none',
}) => {
  const [loading, setLoading] = useState(false)

  const [addBillingPaymentMethod] = useMutation(ADD_PAYMENT_METHOD, {
    onCompleted: data => {
      window.location.href = data.addBillingPaymentMethod.paymentRedirectUrl
    },
    onError: defaultErrorHandler,
  })

  return (
    <Button
      text="Setup Direct Debit"
      loading={loading}
      rightIcon="share"
      fill={fill}
      disabled={disabled}
      style={style}
      minimal={minimal}
      intent={Intent[intent.toUpperCase()]}
      onClick={() => {
        setLoading(true)
        addBillingPaymentMethod({
          variables: {
            id,
            level,
            product,
          },
        })
      }}
    />
  )
}

AddPaymentMethodButton.propTypes = {
  id: string,
  level: string,
  product: string,
  fill: bool,
  style: object,
  minimal: bool,
  intent: string,
}

export default AddPaymentMethodButton
