import { Navigate, Outlet } from 'react-router-dom'

const RoleProtectedRoute = ({ hasPermission }) => {
  if (!hasPermission) {
    // Redirect to unauthorized page or show a message
    return <Navigate to="/unauthorised" replace />
  }

  return <Outlet />
}

export default RoleProtectedRoute
