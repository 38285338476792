import gql from 'graphql-tag'

const GET_DELIVERY_ZONE = gql`
  query getDeliveryZone($id: String!) {
    getDeliveryZones(id: $id) {
      deliveryZones {
        id
        key
        name
        deliveryZoneType
        geoFence
        marketplace {
          id
          geoFence
        }
        outletDeliveryZoneCosts {
          id
          outlet {
            id
            name
            restaurant {
              id
              name
            }
            deliveryZoneCosts {
              id
              deliveryZone {
                id
                name
              }
              radiusMiles
            }
            outletAddress {
              id
              firstLine
              secondLine
              city
              postcode
              geo
            }
          }
        }
      }
    }
  }
`

export default GET_DELIVERY_ZONE
