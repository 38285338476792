import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowReviewsForm = ({ updateStatus, allowReviews }) => {
  return (
    <Formik initialValues={{ allowReviews: allowReviews }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={
              <Fragment>
                Customer Reviews
                <span className="bp5-text-small bp5-text-muted">
                  <br />
                  Not recommended for production.
                </span>
              </Fragment>
            }
            checked={values.allowReviews}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowReviews: e.currentTarget.checked,
              })
            }}
            name="allowReviews"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowReviewsForm.propTypes = {
  allowReviews: bool,
  errors: object,
}

export default MarketplaceAllowReviewsForm
