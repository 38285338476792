import React, { useState } from 'react'
import { Button, FileInput, FormGroup } from '@blueprintjs/core'
import { useMutation } from '@apollo/client'
import { errorToast } from '@utils/toast'
import UPLOAD_IMAGES from './Mutations/UploadImages.mutation'
import { ImagePickerDialog } from '@components/Image/ImagePicker'
import { CheckerBackgroundImgPreview } from './CheckerBackgroundImgPreview'
import { isOutletFinancialUser } from '@stores/userStore'
import config from '@src/config'

interface HandleChangeProps {
  e: React.ChangeEvent<HTMLInputElement>
  setImages: React.Dispatch<React.SetStateAction<any[]>>
  sizeLimit?: number
  uploadImages: (options: any) => Promise<any>
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  imageId?: string
}

const handleChange = async ({
  e,
  setImages,
  sizeLimit = 1048576,
  uploadImages,
  setFieldValue,
  imageId,
}: HandleChangeProps) => {
  const files = e.target.files
  if (!files) return

  try {
    const base64Strings = await Promise.all(
      Array.from(files).map(
        file =>
          new Promise<string>((resolve, reject) => {
            if (file.size > sizeLimit) {
              errorToast(
                `File is bigger than ${
                  sizeLimit / 1000
                }kB. Please choose a smaller image.`
              )
              reject(new Error('File too large'))
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => {
                if (typeof reader.result === 'string') {
                  // Extract base64 data from DataURL
                  const base64String = reader.result.split(',')[1]
                  // glue the file extension to the end of the base64 string
                  resolve(
                    base64String +
                      file.name.substring(file.name.lastIndexOf('.'))
                  )
                } else {
                  errorToast('An error occurred while uploading image')
                  reject(new Error('Reader result is not a string'))
                }
              }
              reader.onerror = () => {
                errorToast('An error occurred while uploading image')
                reject(new Error('FileReader error'))
              }
            }
          })
      )
    )

    const uploadedImages = await Promise.all(
      base64Strings.map(base64Image =>
        uploadImages({
          variables: { images: base64Image },
        })
      )
    )

    setImages(prevState => [
      ...prevState,
      ...uploadedImages.map(mutationResponse => ({
        caption: '',
        src: `${mutationResponse.data.uploadImages.images[0]}`,
      })),
    ])

    if (setFieldValue && imageId) {
      setFieldValue(imageId, uploadedImages[0].data.uploadImages.images[0])
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading images:', error)
  }
}

interface ImageUploadDirectProps {
  setImages: React.Dispatch<React.SetStateAction<any[]>>
  disabled?: boolean
  labelInfo?: string
  sizeLimit?: number
  helperText?: string
}

const ImageUploadDirect: React.FC<ImageUploadDirectProps> = ({
  setImages,
  disabled = false,
  labelInfo = '(Max 1MB)',
  sizeLimit,
  helperText = 'Choose images to upload',
}) => {
  const [uploadImages] = useMutation(UPLOAD_IMAGES)

  return (
    <FormGroup
      className="bp5-form-wrapper"
      labelFor="images"
      labelInfo={labelInfo}
      helperText={helperText}
      style={{ width: '100%' }}
    >
      <label
        htmlFor="images"
        className="custom-file-upload-container bp5-button"
      >
        Browse Files...
        <input
          type="file"
          className="upload-input"
          name="images"
          accept="image/*"
          multiple
          onChange={e =>
            handleChange({
              e,
              setImages,
              sizeLimit,
              uploadImages,
            })
          }
          disabled={disabled}
        />
      </label>
      <ImagePickerDialog setImages={setImages} style={{ marginLeft: '15px' }} />
    </FormGroup>
  )
}

interface ImageUploadDirectSingleProps {
  imageLabel: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  values: any
  helperText?: string
  labelInfo?: string
  setStatus: (field: string, value: any) => void
  sizeLimit?: number
  imageName?: string
  imageType?: string
  disabled?: boolean
  replace?: boolean
  showImagePickerDialog?: boolean
  optionItemId?: string
  marketplaceId?: string
}

export const ImageUploadDirectSingle: React.FC<
  ImageUploadDirectSingleProps
> = ({
  imageLabel,
  setFieldValue,
  values,
  helperText,
  labelInfo = '(Max 1MB)',
  setStatus,
  sizeLimit,
  imageName,
  imageType = 'image/*',
  disabled = false,
  replace = false,
  showImagePickerDialog = true,
  optionItemId,
  marketplaceId,
}) => {
  const userIsOutletFinancialUser = isOutletFinancialUser()

  const imageId = imageName || 'image'
  const getImageSrc = () => {
    if (optionItemId) {
      const foundOptionItem = values.optionItems?.find(
        ({ id }) => optionItemId === id
      )
      return foundOptionItem?.imageImplementer?.src
        ? [foundOptionItem.imageImplementer.src]
        : []
    }

    const imageValue = values[imageId]
    if (!imageValue) return []

    return [typeof imageValue === 'object' ? imageValue.image.src : imageValue]
  }
  const [image, setImage] = useState<string[]>(getImageSrc())

  const handleRemove = () => {
    setFieldValue(imageId, undefined)
    if (setStatus) {
      setStatus(imageId, null)
    }
    setImage([])
  }

  const [uploadImages] = useMutation(UPLOAD_IMAGES)

  return (
    <FormGroup
      label={imageLabel}
      labelFor="image"
      labelInfo={labelInfo}
      helperText={helperText}
      style={{ maxWidth: '100%' }}
    >
      {image.length > 0 ? (
        <div>
          <CheckerBackgroundImgPreview
            imageSrc={`https://${config.environmentDomain}/${getImageSrc()}`}
          />
          {!userIsOutletFinancialUser && (
            <Button
              style={{ marginBottom: '-6px' }}
              onClick={handleRemove}
              disabled={disabled}
              intent={'danger'}
              minimal={true}
              icon={'trash'}
            >
              Remove Image
            </Button>
          )}
        </div>
      ) : (
        <FileInput
          fill
          inputProps={{
            accept: imageType,
          }}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({
              e,
              setImages: setImage,
              sizeLimit,
              uploadImages,
              setFieldValue,
              imageId,
            })
          }
          style={{ maxWidth: '250px', display: 'inline-block' }}
          disabled={disabled}
        />
      )}
      {!image.length && showImagePickerDialog ? (
        <ImagePickerDialog
          setImages={(val: string) => {
            setFieldValue(imageId, val)
            setImage([val])
          }}
          replace={replace}
          style={{ marginTop: '4px', marginLeft: '15px' }}
          optionItemId={optionItemId}
          marketplaceId={marketplaceId}
        />
      ) : null}
    </FormGroup>
  )
}

export default ImageUploadDirect
