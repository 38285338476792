export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: Date; output: string; }
  DateWithoutTime: { input: any; output: any; }
  Json: { input: unknown; output: Record<string, unknown>; }
};

export type ActiveUsersDataRow = {
  __typename?: 'ActiveUsersDataRow';
  date: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type AddPaymentMethodResponse = {
  __typename?: 'AddPaymentMethodResponse';
  message?: Maybe<Scalars['String']['output']>;
  paymentRedirectUrl?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  archived?: Maybe<Scalars['Boolean']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['Json']['output'];
  createdAt: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  firstLine: Scalars['String']['output'];
  geo?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  secondLine?: Maybe<Scalars['String']['output']>;
  thirdLine?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AddressCreateInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine: Scalars['String']['input'];
  geo?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  FirstLineAsc = 'firstLine_ASC',
  FirstLineDesc = 'firstLine_DESC',
  GeoAsc = 'geo_ASC',
  GeoDesc = 'geo_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PostcodeAsc = 'postcode_ASC',
  PostcodeDesc = 'postcode_DESC',
  SecondLineAsc = 'secondLine_ASC',
  SecondLineDesc = 'secondLine_DESC',
  ThirdLineAsc = 'thirdLine_ASC',
  ThirdLineDesc = 'thirdLine_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AddressResponse = {
  __typename?: 'AddressResponse';
  address?: Maybe<Address>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AddressUpdateDataInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<Scalars['Json']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};

export type AddressUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_contains?: InputMaybe<Scalars['String']['input']>;
  city_ends_with?: InputMaybe<Scalars['String']['input']>;
  city_gt?: InputMaybe<Scalars['String']['input']>;
  city_gte?: InputMaybe<Scalars['String']['input']>;
  city_in?: InputMaybe<Array<Scalars['String']['input']>>;
  city_lt?: InputMaybe<Scalars['String']['input']>;
  city_lte?: InputMaybe<Scalars['String']['input']>;
  city_not?: InputMaybe<Scalars['String']['input']>;
  city_not_contains?: InputMaybe<Scalars['String']['input']>;
  city_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  city_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  city_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  city_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  default_not?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  firstLine_contains?: InputMaybe<Scalars['String']['input']>;
  firstLine_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstLine_gt?: InputMaybe<Scalars['String']['input']>;
  firstLine_gte?: InputMaybe<Scalars['String']['input']>;
  firstLine_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstLine_lt?: InputMaybe<Scalars['String']['input']>;
  firstLine_lte?: InputMaybe<Scalars['String']['input']>;
  firstLine_not?: InputMaybe<Scalars['String']['input']>;
  firstLine_not_contains?: InputMaybe<Scalars['String']['input']>;
  firstLine_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstLine_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstLine_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstLine_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  postcode_contains?: InputMaybe<Scalars['String']['input']>;
  postcode_ends_with?: InputMaybe<Scalars['String']['input']>;
  postcode_gt?: InputMaybe<Scalars['String']['input']>;
  postcode_gte?: InputMaybe<Scalars['String']['input']>;
  postcode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  postcode_lt?: InputMaybe<Scalars['String']['input']>;
  postcode_lte?: InputMaybe<Scalars['String']['input']>;
  postcode_not?: InputMaybe<Scalars['String']['input']>;
  postcode_not_contains?: InputMaybe<Scalars['String']['input']>;
  postcode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  postcode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  postcode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  postcode_starts_with?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  secondLine_contains?: InputMaybe<Scalars['String']['input']>;
  secondLine_ends_with?: InputMaybe<Scalars['String']['input']>;
  secondLine_gt?: InputMaybe<Scalars['String']['input']>;
  secondLine_gte?: InputMaybe<Scalars['String']['input']>;
  secondLine_in?: InputMaybe<Array<Scalars['String']['input']>>;
  secondLine_lt?: InputMaybe<Scalars['String']['input']>;
  secondLine_lte?: InputMaybe<Scalars['String']['input']>;
  secondLine_not?: InputMaybe<Scalars['String']['input']>;
  secondLine_not_contains?: InputMaybe<Scalars['String']['input']>;
  secondLine_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  secondLine_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  secondLine_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  secondLine_starts_with?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine_contains?: InputMaybe<Scalars['String']['input']>;
  thirdLine_ends_with?: InputMaybe<Scalars['String']['input']>;
  thirdLine_gt?: InputMaybe<Scalars['String']['input']>;
  thirdLine_gte?: InputMaybe<Scalars['String']['input']>;
  thirdLine_in?: InputMaybe<Array<Scalars['String']['input']>>;
  thirdLine_lt?: InputMaybe<Scalars['String']['input']>;
  thirdLine_lte?: InputMaybe<Scalars['String']['input']>;
  thirdLine_not?: InputMaybe<Scalars['String']['input']>;
  thirdLine_not_contains?: InputMaybe<Scalars['String']['input']>;
  thirdLine_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  thirdLine_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  thirdLine_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  thirdLine_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum Alarm {
  Alarm1 = 'ALARM1',
  Alarm2 = 'ALARM2',
  Bells1 = 'BELLS1',
  Bells2 = 'BELLS2',
  Icecream = 'ICECREAM',
  Silent = 'SILENT'
}

export type AllRoleResponse = {
  __typename?: 'AllRoleResponse';
  role?: Maybe<Role>;
};

export type AllRolesResponse = {
  __typename?: 'AllRolesResponse';
  roles: Array<Role>;
};

export type AllowedPaymentMethods = {
  __typename?: 'AllowedPaymentMethods';
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  marketplace: RegionalMarketplace;
  scope: Array<ApiKeyScope>;
  updatedAt: Scalars['DateTime']['output'];
  webhooks: Array<Webhook>;
};

export enum ApiKeyScope {
  DeleteWebhook = 'DELETE_WEBHOOK',
  GetOrder = 'GET_ORDER',
  GetWebhooks = 'GET_WEBHOOKS',
  PatchFulfilmentProvider = 'PATCH_FULFILMENT_PROVIDER',
  PatchOrderStatus = 'PATCH_ORDER_STATUS',
  PatchWebhook = 'PATCH_WEBHOOK',
  PostWebhook = 'POST_WEBHOOK'
}

export type App = {
  __typename?: 'App';
  appBuilds: Array<AppBuild>;
  appcenterAppNameAndroid?: Maybe<Scalars['String']['output']>;
  appcenterAppNameiOS?: Maybe<Scalars['String']['output']>;
  appleTeamId?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  googleJsonS3KeyPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  updatedAt: Scalars['DateTime']['output'];
};

export type AppBuild = {
  __typename?: 'AppBuild';
  apigwRequestId?: Maybe<Scalars['String']['output']>;
  app: App;
  appBuildStatuses?: Maybe<Array<Maybe<AppBuildStatus>>>;
  createdAt: Scalars['DateTime']['output'];
  device?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logs?: Maybe<Scalars['String']['output']>;
  releaseToAppcenter?: Maybe<Scalars['Boolean']['output']>;
  releaseToStore?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type AppBuildStatus = {
  __typename?: 'AppBuildStatus';
  apigwRequestId?: Maybe<Scalars['String']['output']>;
  appBuild: AppBuild;
  circleCiBuildNum?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  logs?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AppResponse = {
  __typename?: 'AppResponse';
  app: App;
  appBuilds?: Maybe<Array<Maybe<AppBuild>>>;
};

export type AppsResponse = {
  __typename?: 'AppsResponse';
  apps: Array<App>;
};

export type Audit = {
  __typename?: 'Audit';
  body: Scalars['String']['output'];
  caller: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  method: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AuditsListResponse = {
  __typename?: 'AuditsListResponse';
  audits: Array<Audit>;
};

export type AvailabilityTime = {
  day?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type AvailabilityTimeInput = {
  end?: InputMaybe<AvailabilityTime>;
  key?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<AvailabilityTime>;
};

export type Billing = {
  __typename?: 'Billing';
  currency: Scalars['String']['output'];
  delinquent: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  livemode: Scalars['Boolean']['output'];
  paymentMethods?: Maybe<Scalars['Json']['output']>;
  products: Array<BillingProduct>;
  subscription?: Maybe<BillingSubscription>;
  upcomingInvoice?: Maybe<BillingInvoice>;
};

export enum BillingCustomerLevel {
  Marketplace = 'MARKETPLACE',
  Partner = 'PARTNER'
}

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  amountDue?: Maybe<Scalars['Int']['output']>;
  items: Array<BillingInvoiceItem>;
  periodEnd?: Maybe<Scalars['Int']['output']>;
  periodStart?: Maybe<Scalars['Int']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingInvoiceItem = {
  __typename?: 'BillingInvoiceItem';
  amount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type BillingInvoicePdf = {
  __typename?: 'BillingInvoicePDF';
  amountDue?: Maybe<Scalars['Int']['output']>;
  amountPaid?: Maybe<Scalars['Int']['output']>;
  amountRemaining?: Maybe<Scalars['Int']['output']>;
  dueDate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicePdf?: Maybe<Scalars['String']['output']>;
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  subtotal?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type BillingProduct = {
  __typename?: 'BillingProduct';
  description?: Maybe<Scalars['String']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  service?: Maybe<Scalars['String']['output']>;
  subscribed?: Maybe<Scalars['Boolean']['output']>;
};

export enum BillingProductType {
  Api = 'API',
  Marketing = 'MARKETING',
  Paybox = 'PAYBOX',
  Waybox = 'WAYBOX'
}

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  services: Array<Scalars['String']['output']>;
};

export type BlacklistAddressResponse = {
  __typename?: 'BlacklistAddressResponse';
  message: Scalars['String']['output'];
};

export type BlacklistAddressesResponse = {
  __typename?: 'BlacklistAddressesResponse';
  blacklistedAddresses: Array<BlacklistedAddress>;
};

export type BlacklistedAddress = {
  __typename?: 'BlacklistedAddress';
  googleFormattedAddress: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum BookingTrigger {
  OnAccept = 'ON_ACCEPT',
  OnCreate = 'ON_CREATE'
}

export type BusinessCategory = {
  __typename?: 'BusinessCategory';
  business: Restaurant;
  category: Cuisine;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BusinessSegment = {
  __typename?: 'BusinessSegment';
  availabilityEndDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityStartDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityTimes?: Maybe<Scalars['Json']['output']>;
  businessSegmentCategories: Array<BusinessSegmentCategory>;
  businessSegmentLinkCards: Array<BusinessSegmentLinkCard>;
  categoryCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filterSegment: Scalars['Boolean']['output'];
  groups: Array<BusinessSegmentsGroup>;
  id: Scalars['String']['output'];
  linkCardOrderBy?: Maybe<LinkCardOrderBy>;
  linkToSegmentIndex: Scalars['Boolean']['output'];
  marketplace: RegionalMarketplace;
  name: Scalars['String']['output'];
  openStatusFilter?: Maybe<OpenStatusFilter>;
  orderBy?: Maybe<SegmentOrderBy>;
  outletCount: Scalars['Int']['output'];
  outletLimit: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  showDiscountLabels: Scalars['Boolean']['output'];
  showFeaturedFlags: Scalars['Boolean']['output'];
  showName: Scalars['Boolean']['output'];
  showOnApp: Scalars['Boolean']['output'];
  showOnWeb: Scalars['Boolean']['output'];
  type: BusinessSegmentType;
  updatedAt: Scalars['DateTime']['output'];
};

export type BusinessSegmentCategory = {
  __typename?: 'BusinessSegmentCategory';
  businessSegment: BusinessSegment;
  category: Cuisine;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BusinessSegmentCategoryInput = {
  id: Scalars['ID']['input'];
};

export type BusinessSegmentFilterInput = {
  attribute: Scalars['String']['input'];
  comparator: Comparator;
  id?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BusinessSegmentLinkCard = {
  __typename?: 'BusinessSegmentLinkCard';
  businessSegment: BusinessSegment;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  linkCard: LinkCard;
  position?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type BusinessSegmentLinkInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type BusinessSegmentPositionResponse = {
  __typename?: 'BusinessSegmentPositionResponse';
  message: Scalars['String']['output'];
};

export enum BusinessSegmentType {
  Categories = 'CATEGORIES',
  Outlets = 'OUTLETS'
}

export type BusinessSegmentWithFilters = {
  __typename?: 'BusinessSegmentWithFilters';
  description?: Maybe<Scalars['String']['output']>;
  groups: Array<BusinessSegmentsGroup>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type BusinessSegmentsFilter = {
  __typename?: 'BusinessSegmentsFilter';
  attribute: Scalars['String']['output'];
  businessSegment: BusinessSegment;
  comparator: Comparator;
  id: Scalars['String']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type BusinessSegmentsGroup = {
  __typename?: 'BusinessSegmentsGroup';
  businessSegment: BusinessSegment;
  filterGroupType?: Maybe<FilterGroupType>;
  filters: Array<BusinessSegmentsFilter>;
  id: Scalars['String']['output'];
};

export type BusinessSegmentsGroupInput = {
  comparator?: InputMaybe<Comparator>;
  filterGroupType?: InputMaybe<FilterGroupType>;
  filters?: InputMaybe<Array<InputMaybe<BusinessSegmentFilterInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type BusinesssignificantDatesResponse = {
  __typename?: 'BusinesssignificantDatesResponse';
  significantDates?: Maybe<Scalars['Json']['output']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  bounceRate?: Maybe<Scalars['String']['output']>;
  clicks?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deliveries: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<MessageTemplate>;
  emailChannel: Scalars['Boolean']['output'];
  emailOpenRate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  marketplaceId: Scalars['String']['output'];
  monetisation?: Maybe<CampaignMonetisation>;
  name: Scalars['String']['output'];
  push?: Maybe<CampaignPushBody>;
  pushChannel?: Maybe<Scalars['Boolean']['output']>;
  pushOpenRate?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Segment>;
  sms?: Maybe<CampaignSmsBody>;
  smsChannel: Scalars['Boolean']['output'];
  startDateTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timeConfig: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CampaignMonetisation = {
  __typename?: 'CampaignMonetisation';
  total?: Maybe<Scalars['Int']['output']>;
};

export type CampaignPushBody = {
  __typename?: 'CampaignPushBody';
  action: Scalars['String']['output'];
  body: Scalars['String']['output'];
  menuItemId?: Maybe<Scalars['String']['output']>;
  outletId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CampaignPushInput = {
  action: Scalars['String']['input'];
  body: Scalars['String']['input'];
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CampaignResponse = {
  __typename?: 'CampaignResponse';
  campaign: Campaign;
};

export type CampaignSmsBody = {
  __typename?: 'CampaignSMSBody';
  body: Scalars['String']['output'];
};

export type CampaignSmsInput = {
  body: Scalars['String']['input'];
};

export type CampaignsResponse = {
  __typename?: 'CampaignsResponse';
  campaigns: Array<Campaign>;
  count?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CardImageInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  src?: InputMaybe<Scalars['String']['input']>;
};

export enum CategoryEmojiOrImage {
  Emoji = 'EMOJI',
  Image = 'IMAGE'
}

export type Channels = {
  __typename?: 'Channels';
  androidEnabled: Scalars['Boolean']['output'];
  iosEnabled: Scalars['Boolean']['output'];
  setup: Scalars['Boolean']['output'];
};

export type CheckUserCanRegisterResponse = {
  __typename?: 'CheckUserCanRegisterResponse';
  canRegister?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
};

export enum CompanyType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
  NonProfit = 'NON_PROFIT',
  Partnership = 'PARTNERSHIP'
}

export enum Comparator {
  Is = 'IS',
  IsNot = 'IS_NOT'
}

export type Country = {
  __typename?: 'Country';
  ISO3166Alpha2: Scalars['String']['output'];
  ISO3166Alpha3: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Currency;
  id: Scalars['ID']['output'];
  marketplaces?: Maybe<Array<RegionalMarketplace>>;
  name: Scalars['String']['output'];
  postCodeRegex: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CountryMarketplacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RegionalMarketplaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegionalMarketplaceWhereInput>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  ISO3166Alpha2?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_contains?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_ends_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_gt?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_gte?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ISO3166Alpha2_lt?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_lte?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_not?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_not_contains?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ISO3166Alpha2_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha2_starts_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_contains?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_ends_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_gt?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_gte?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ISO3166Alpha3_lt?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_lte?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_not?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_not_contains?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ISO3166Alpha3_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ISO3166Alpha3_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  marketplaces_every?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaces_none?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaces_some?: InputMaybe<RegionalMarketplaceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_contains?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_ends_with?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_gt?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_gte?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_in?: InputMaybe<Array<Scalars['String']['input']>>;
  postCodeRegex_lt?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_lte?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_not?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_not_contains?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  postCodeRegex_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  postCodeRegex_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Cuisine = {
  __typename?: 'Cuisine';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  emoji?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageSrc?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  restaurants?: Maybe<Array<Restaurant>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CuisineRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RestaurantOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RestaurantWhereInput>;
};

export enum CuisineOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CuisineWhereInput = {
  AND?: InputMaybe<Array<CuisineWhereInput>>;
  NOT?: InputMaybe<Array<CuisineWhereInput>>;
  OR?: InputMaybe<Array<CuisineWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  restaurants_every?: InputMaybe<RestaurantWhereInput>;
  restaurants_none?: InputMaybe<RestaurantWhereInput>;
  restaurants_some?: InputMaybe<RestaurantWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Currency = {
  __typename?: 'Currency';
  decimalMark?: Maybe<Scalars['String']['output']>;
  decimalPlaces?: Maybe<Scalars['Int']['output']>;
  iso4217: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
  subunit?: Maybe<Scalars['String']['output']>;
  subunitToUnit?: Maybe<Scalars['Int']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  symbol: Scalars['String']['output'];
  thousandsSeparator?: Maybe<Scalars['String']['output']>;
};

export type CurrencyResponse = {
  __typename?: 'CurrencyResponse';
  currency?: Maybe<Scalars['Json']['output']>;
};

export type CustomLink = {
  __typename?: 'CustomLink';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  marketplace?: Maybe<RegionalMarketplace>;
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CustomOrderSubscriptionPayload = {
  __typename?: 'CustomOrderSubscriptionPayload';
  id: Scalars['String']['output'];
  orderStatus: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  archived: Scalars['Boolean']['output'];
  averageOrderValue?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveryAddress?: Maybe<Array<Address>>;
  discounts?: Maybe<Array<Discount>>;
  email: Scalars['String']['output'];
  failedAttempts?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invalidateToken?: Maybe<Scalars['Boolean']['output']>;
  isOnboarding?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastOrderAt?: Maybe<Scalars['DateTime']['output']>;
  marketplace: RegionalMarketplace;
  marketplaceId?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Array<Order>>;
  paymentMethods?: Maybe<Scalars['Json']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  resetToken?: Maybe<Scalars['String']['output']>;
  reviews?: Maybe<Array<Review>>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  totalCompletedOrders?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalValue?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerDeliveryAddressArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AddressOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type CustomerDiscountsArgs = {
  where?: InputMaybe<DiscountWhereInput>;
};


export type CustomerOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};

export enum CustomerOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FailedAttemptsAsc = 'failedAttempts_ASC',
  FailedAttemptsDesc = 'failedAttempts_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsOnboardingAsc = 'isOnboarding_ASC',
  IsOnboardingDesc = 'isOnboarding_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LastOrderAtAsc = 'lastOrderAt_ASC',
  LastOrderAtDesc = 'lastOrderAt_DESC',
  MarketplaceIdAsc = 'marketplaceId_ASC',
  MarketplaceIdDesc = 'marketplaceId_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  ResetTokenAsc = 'resetToken_ASC',
  ResetTokenDesc = 'resetToken_DESC',
  StripeCustomerIdAsc = 'stripeCustomerId_ASC',
  StripeCustomerIdDesc = 'stripeCustomerId_DESC',
  TotalCompletedOrdersAsc = 'totalCompletedOrders_ASC',
  TotalCompletedOrdersDesc = 'totalCompletedOrders_DESC',
  TotalValueAsc = 'totalValue_ASC',
  TotalValueDesc = 'totalValue_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveryAddress_every?: InputMaybe<AddressWhereInput>;
  deliveryAddress_none?: InputMaybe<AddressWhereInput>;
  deliveryAddress_some?: InputMaybe<AddressWhereInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_gt?: InputMaybe<Scalars['String']['input']>;
  email_gte?: InputMaybe<Scalars['String']['input']>;
  email_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_lt?: InputMaybe<Scalars['String']['input']>;
  email_lte?: InputMaybe<Scalars['String']['input']>;
  email_not?: InputMaybe<Scalars['String']['input']>;
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  failedAttempts?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_gt?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_gte?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  failedAttempts_lt?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_lte?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_not?: InputMaybe<Scalars['Int']['input']>;
  failedAttempts_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstName_gt?: InputMaybe<Scalars['String']['input']>;
  firstName_gte?: InputMaybe<Scalars['String']['input']>;
  firstName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName_lt?: InputMaybe<Scalars['String']['input']>;
  firstName_lte?: InputMaybe<Scalars['String']['input']>;
  firstName_not?: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstName_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  isOnboarding_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_ends_with?: InputMaybe<Scalars['String']['input']>;
  lastName_gt?: InputMaybe<Scalars['String']['input']>;
  lastName_gte?: InputMaybe<Scalars['String']['input']>;
  lastName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName_lt?: InputMaybe<Scalars['String']['input']>;
  lastName_lte?: InputMaybe<Scalars['String']['input']>;
  lastName_not?: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lastName_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_contains?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_ends_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_gt?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_gte?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  marketplaceId_lt?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_lte?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_contains?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  marketplaceId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_starts_with?: InputMaybe<Scalars['String']['input']>;
  orders_every?: InputMaybe<OrderWhereInput>;
  orders_none?: InputMaybe<OrderWhereInput>;
  orders_some?: InputMaybe<OrderWhereInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_lt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_lte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  resetToken?: InputMaybe<Scalars['String']['input']>;
  resetToken_contains?: InputMaybe<Scalars['String']['input']>;
  resetToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_gt?: InputMaybe<Scalars['String']['input']>;
  resetToken_gte?: InputMaybe<Scalars['String']['input']>;
  resetToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resetToken_lt?: InputMaybe<Scalars['String']['input']>;
  resetToken_lte?: InputMaybe<Scalars['String']['input']>;
  resetToken_not?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resetToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  reviews_every?: InputMaybe<ReviewWhereInput>;
  reviews_none?: InputMaybe<ReviewWhereInput>;
  reviews_some?: InputMaybe<ReviewWhereInput>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeCustomerId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_not?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeCustomerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  totalCompletedOrders?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCompletedOrders_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_not?: InputMaybe<Scalars['Int']['input']>;
  totalCompletedOrders_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalValue?: InputMaybe<Scalars['Int']['input']>;
  totalValue_gt?: InputMaybe<Scalars['Int']['input']>;
  totalValue_gte?: InputMaybe<Scalars['Int']['input']>;
  totalValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalValue_lt?: InputMaybe<Scalars['Int']['input']>;
  totalValue_lte?: InputMaybe<Scalars['Int']['input']>;
  totalValue_not?: InputMaybe<Scalars['Int']['input']>;
  totalValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type CustomersResponse = {
  __typename?: 'CustomersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  customers?: Maybe<Array<Customer>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DateRangeInput = {
  from: Scalars['DateWithoutTime']['input'];
  to?: InputMaybe<Scalars['DateWithoutTime']['input']>;
  type: DateRangeType;
};

export enum DateRangeType {
  Custom = 'CUSTOM',
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export enum DefaultDatepickerOption {
  Anytime = 'ANYTIME',
  Asap = 'ASAP',
  Preorder = 'PREORDER'
}

export enum DefaultFulfilmentOption {
  All = 'ALL',
  Collection = 'COLLECTION',
  Delivery = 'DELIVERY',
  Table = 'TABLE'
}

export enum DefaultLocationOption {
  Everywhere = 'EVERYWHERE',
  Nearme = 'NEARME',
  Postcode = 'POSTCODE'
}

export type DeleteBusinessSegmentResponse = {
  __typename?: 'DeleteBusinessSegmentResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteCampaignResponse = {
  __typename?: 'DeleteCampaignResponse';
  message: Scalars['String']['output'];
};

export type DeleteImageResponse = {
  __typename?: 'DeleteImageResponse';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteMessageTemplateResponse = {
  __typename?: 'DeleteMessageTemplateResponse';
  success: Scalars['Boolean']['output'];
};

export type DeleteSegmentResponse = {
  __typename?: 'DeleteSegmentResponse';
  message: Scalars['String']['output'];
};

export enum DeliveryChargeRecipient {
  Marketplace = 'MARKETPLACE',
  Merchant = 'MERCHANT'
}

export type DeliveryNetwork = {
  __typename?: 'DeliveryNetwork';
  apiCredentials: Scalars['Json']['output'];
  bookingTrigger: BookingTrigger;
  createdAt: Scalars['DateTime']['output'];
  deliveryChargeRecipient?: Maybe<DeliveryChargeRecipient>;
  deliveryNetworkType: DeliveryNetworkType;
  deliveryProvider: DeliveryProvidersEnum;
  fixedSubsidy?: Maybe<Scalars['Int']['output']>;
  helpPhone?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  marketplace: RegionalMarketplace;
  name: Scalars['String']['output'];
  namePrefix?: Maybe<Scalars['String']['output']>;
  tariff?: Maybe<Scalars['Json']['output']>;
  uberDirectTestDeliveryId?: Maybe<Scalars['String']['output']>;
  uberDirectTestScenario?: Maybe<Scalars['String']['output']>;
  uberDirectWebhookStatus?: Maybe<UberDirectWebhookStatus>;
  updatedAt: Scalars['DateTime']['output'];
  webhookStatus: WebhookStatus;
};

export enum DeliveryNetworkBookingStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  EnrouteToCustomer = 'ENROUTE_TO_CUSTOMER',
  EnrouteToOutlet = 'ENROUTE_TO_OUTLET',
  NoShow = 'NO_SHOW',
  PickupBooked = 'PICKUP_BOOKED',
  Returned = 'RETURNED',
  Unknown = 'UNKNOWN',
  WaitingAtCustomer = 'WAITING_AT_CUSTOMER',
  WaitingAtOutlet = 'WAITING_AT_OUTLET'
}

export type DeliveryNetworkFailureReportByMarketplace = {
  __typename?: 'DeliveryNetworkFailureReportByMarketplace';
  deliveryNetwork: DeliveryNetwork;
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  marketplaceId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  totalDeliveryNetworkFailureOrders: Scalars['Int']['output'];
  totalDeliveryNetworkFailureRate: Scalars['Float']['output'];
  totalDeliveryNetworkFailureValue: Scalars['Int']['output'];
  totalOrderValue: Scalars['Int']['output'];
  totalOrders: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DeliveryNetworkFailureReportByPartner = {
  __typename?: 'DeliveryNetworkFailureReportByPartner';
  deliveryNetwork?: Maybe<DeliveryNetwork>;
  deliveryNetworkId?: Maybe<Scalars['ID']['output']>;
  failureRate30Days?: Maybe<Scalars['Float']['output']>;
  failureRate60Days?: Maybe<Scalars['Float']['output']>;
  failureRate90Days?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  marketplaceId: Scalars['ID']['output'];
  marketplaceName?: Maybe<Scalars['String']['output']>;
  totalFailedOrders30Days?: Maybe<Scalars['Int']['output']>;
  totalFailedOrders60Days?: Maybe<Scalars['Int']['output']>;
  totalFailedOrders90Days?: Maybe<Scalars['Int']['output']>;
  totalFailedValue30Days?: Maybe<Scalars['Int']['output']>;
  totalFailedValue60Days?: Maybe<Scalars['Int']['output']>;
  totalFailedValue90Days?: Maybe<Scalars['Int']['output']>;
  totalOrders30Days?: Maybe<Scalars['Int']['output']>;
  totalOrders60Days?: Maybe<Scalars['Int']['output']>;
  totalOrders90Days?: Maybe<Scalars['Int']['output']>;
  totalValue30Days?: Maybe<Scalars['Int']['output']>;
  totalValue60Days?: Maybe<Scalars['Int']['output']>;
  totalValue90Days?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryNetworkFailureReportHeatmap = {
  __typename?: 'DeliveryNetworkFailureReportHeatmap';
  day: Scalars['Int']['output'];
  failureRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  marketplace?: Maybe<RegionalMarketplace>;
  marketplaceId: Scalars['ID']['output'];
  totalFailedOrders: Scalars['Int']['output'];
  totalFailedValue: Scalars['Int']['output'];
  totalOrders: Scalars['Int']['output'];
};

export enum DeliveryNetworkOrderByInput {
  ApiCredentialsAsc = 'apiCredentials_ASC',
  ApiCredentialsDesc = 'apiCredentials_DESC',
  BookingTriggerAsc = 'bookingTrigger_ASC',
  BookingTriggerDesc = 'bookingTrigger_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeliveryProviderAsc = 'deliveryProvider_ASC',
  DeliveryProviderDesc = 'deliveryProvider_DESC',
  HelpPhoneAsc = 'helpPhone_ASC',
  HelpPhoneDesc = 'helpPhone_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NamePrefixAsc = 'namePrefix_ASC',
  NamePrefixDesc = 'namePrefix_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DeliveryNetworkResponse = {
  __typename?: 'DeliveryNetworkResponse';
  deliveryNetwork?: Maybe<DeliveryNetwork>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DeliveryNetworkType {
  Booking = 'BOOKING',
  Mapping = 'MAPPING'
}

export type DeliveryNetworkWhereInput = {
  AND?: InputMaybe<Array<DeliveryNetworkWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryNetworkWhereInput>>;
  OR?: InputMaybe<Array<DeliveryNetworkWhereInput>>;
  bookingTrigger?: InputMaybe<BookingTrigger>;
  bookingTrigger_in?: InputMaybe<Array<BookingTrigger>>;
  bookingTrigger_not?: InputMaybe<BookingTrigger>;
  bookingTrigger_not_in?: InputMaybe<Array<BookingTrigger>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveryProvider?: InputMaybe<DeliveryProvidersEnum>;
  deliveryProvider_in?: InputMaybe<Array<DeliveryProvidersEnum>>;
  deliveryProvider_not?: InputMaybe<DeliveryProvidersEnum>;
  deliveryProvider_not_in?: InputMaybe<Array<DeliveryProvidersEnum>>;
  helpPhone?: InputMaybe<Scalars['String']['input']>;
  helpPhone_contains?: InputMaybe<Scalars['String']['input']>;
  helpPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  helpPhone_gt?: InputMaybe<Scalars['String']['input']>;
  helpPhone_gte?: InputMaybe<Scalars['String']['input']>;
  helpPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  helpPhone_lt?: InputMaybe<Scalars['String']['input']>;
  helpPhone_lte?: InputMaybe<Scalars['String']['input']>;
  helpPhone_not?: InputMaybe<Scalars['String']['input']>;
  helpPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  helpPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  helpPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  helpPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  helpPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  namePrefix?: InputMaybe<Scalars['String']['input']>;
  namePrefix_contains?: InputMaybe<Scalars['String']['input']>;
  namePrefix_ends_with?: InputMaybe<Scalars['String']['input']>;
  namePrefix_gt?: InputMaybe<Scalars['String']['input']>;
  namePrefix_gte?: InputMaybe<Scalars['String']['input']>;
  namePrefix_in?: InputMaybe<Array<Scalars['String']['input']>>;
  namePrefix_lt?: InputMaybe<Scalars['String']['input']>;
  namePrefix_lte?: InputMaybe<Scalars['String']['input']>;
  namePrefix_not?: InputMaybe<Scalars['String']['input']>;
  namePrefix_not_contains?: InputMaybe<Scalars['String']['input']>;
  namePrefix_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  namePrefix_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  namePrefix_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  namePrefix_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum DeliveryProvidersEnum {
  Autocab = 'AUTOCAB',
  Icabbi = 'ICABBI',
  Stuart = 'STUART',
  UberDirect = 'UBER_DIRECT',
  Waybox = 'WAYBOX'
}

export type DeliveryZone = {
  __typename?: 'DeliveryZone';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryNetworkType?: Maybe<DeliveryNetworkType>;
  deliveryZoneType?: Maybe<DeliveryZoneType>;
  geoFence?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  marketplace: RegionalMarketplace;
  name: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  outletDeliveryZoneCosts?: Maybe<Array<OutletDeliveryZoneCost>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type DeliveryZoneOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type DeliveryZoneOutletDeliveryZoneCostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutletDeliveryZoneCostOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
};

export enum DeliveryZoneOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeliveryZoneTypeAsc = 'deliveryZoneType_ASC',
  DeliveryZoneTypeDesc = 'deliveryZoneType_DESC',
  GeoFenceAsc = 'geoFence_ASC',
  GeoFenceDesc = 'geoFence_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DeliveryZoneResponse = {
  __typename?: 'DeliveryZoneResponse';
  deliveryZone?: Maybe<DeliveryZone>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DeliveryZoneType {
  CoordinatePolygon = 'COORDINATE_POLYGON',
  RadiusAroundOutlet = 'RADIUS_AROUND_OUTLET',
  Stuart = 'STUART',
  UberDirect = 'UBER_DIRECT',
  Waybox = 'WAYBOX'
}

export type DeliveryZoneWhereInput = {
  AND?: InputMaybe<Array<DeliveryZoneWhereInput>>;
  NOT?: InputMaybe<Array<DeliveryZoneWhereInput>>;
  OR?: InputMaybe<Array<DeliveryZoneWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveryZoneType?: InputMaybe<DeliveryZoneType>;
  deliveryZoneType_in?: InputMaybe<Array<DeliveryZoneType>>;
  deliveryZoneType_not?: InputMaybe<DeliveryZoneType>;
  deliveryZoneType_not_in?: InputMaybe<Array<DeliveryZoneType>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orders_every?: InputMaybe<OrderWhereInput>;
  orders_none?: InputMaybe<OrderWhereInput>;
  orders_some?: InputMaybe<OrderWhereInput>;
  outletDeliveryZoneCosts_every?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  outletDeliveryZoneCosts_none?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  outletDeliveryZoneCosts_some?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Discount = {
  __typename?: 'Discount';
  active: Scalars['Boolean']['output'];
  allowedOutlets?: Maybe<Array<Maybe<Outlet>>>;
  cardsRedeemed?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerEnrolled: Scalars['Boolean']['output'];
  daysOfWeek: Array<Scalars['Int']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  enrolmentKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  loyaltyCard?: Maybe<LoyaltyCard>;
  marketplace?: Maybe<RegionalMarketplace>;
  minimumSubtotalGross?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  restaurant?: Maybe<Restaurant>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  uniqueCustomerCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vouchers?: Maybe<Array<Voucher>>;
};


export type DiscountVouchersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VoucherOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhereInput>;
};

export enum DiscountOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DiscountAmountAsc = 'discountAmount_ASC',
  DiscountAmountDesc = 'discountAmount_DESC',
  DiscountPercentageAsc = 'discountPercentage_ASC',
  DiscountPercentageDesc = 'discountPercentage_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  MinimumSubtotalGrossAsc = 'minimumSubtotalGross_ASC',
  MinimumSubtotalGrossDesc = 'minimumSubtotalGross_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DiscountResponse = {
  __typename?: 'DiscountResponse';
  discount?: Maybe<Discount>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DiscountWhereInput = {
  AND?: InputMaybe<Array<DiscountWhereInput>>;
  NOT?: InputMaybe<Array<DiscountWhereInput>>;
  OR?: InputMaybe<Array<DiscountWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  customerEnrolled_not?: InputMaybe<Scalars['Boolean']['input']>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  discountAmount_lt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_lte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_gt?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_gte?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  discountPercentage_lt?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_lte?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_not?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  endDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  enrolmentKey?: InputMaybe<Scalars['String']['input']>;
  enrolmentKey_not?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCard?: InputMaybe<LoyaltyCardWhereInput>;
  loyaltyCard_every?: InputMaybe<LoyaltyCardWhereInput>;
  loyaltyCard_none?: InputMaybe<LoyaltyCardWhereInput>;
  loyaltyCard_some?: InputMaybe<LoyaltyCardWhereInput>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_gt?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_gte?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minimumSubtotalGross_lt?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_lte?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_not?: InputMaybe<Scalars['Int']['input']>;
  minimumSubtotalGross_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  startDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vouchers_every?: InputMaybe<VoucherWhereInput>;
  vouchers_none?: InputMaybe<VoucherWhereInput>;
  vouchers_some?: InputMaybe<VoucherWhereInput>;
};

export type DiscountsResponse = {
  __typename?: 'DiscountsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  discounts: Array<Discount>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type EditBusinessSegmentResponse = {
  __typename?: 'EditBusinessSegmentResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type EditMinAppVersionOverrideResponse = {
  __typename?: 'EditMinAppVersionOverrideResponse';
  count?: Maybe<Scalars['Int']['output']>;
};

export type EmailMetricsDataRow = {
  __typename?: 'EmailMetricsDataRow';
  clicked: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  delivered: Scalars['Int']['output'];
  opened: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type EmailPreviewResponse = {
  __typename?: 'EmailPreviewResponse';
  html: Scalars['String']['output'];
};

export enum EmailStatus {
  Failed = 'FAILED',
  NeverQueued = 'NEVER_QUEUED',
  Queued = 'QUEUED',
  Sent = 'SENT'
}

export type EmailTheme = {
  emailBackgroundColor: Scalars['String']['input'];
  emailBodyColor: Scalars['String']['input'];
  emailButtonColor: Scalars['String']['input'];
  emailFontColor: Scalars['String']['input'];
  emailFooter: Scalars['String']['input'];
  emailFooterColor: Scalars['String']['input'];
  emailImageUrl: Scalars['String']['input'];
  emailShowCustomLogo?: InputMaybe<Scalars['Boolean']['input']>;
  emailShowImage: Scalars['Boolean']['input'];
  uploadInProgress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndpointsData = {
  __typename?: 'EndpointsData';
  ALL: Scalars['Int']['output'];
  EMAIL: Scalars['Int']['output'];
  PUSH: Scalars['Int']['output'];
  SMS: Scalars['Int']['output'];
};

export type EnrollCustomerDiscountResponse = {
  __typename?: 'EnrollCustomerDiscountResponse';
  discountId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Faq = {
  __typename?: 'FAQ';
  answer: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  position: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Filter = {
  __typename?: 'Filter';
  attribute: Scalars['String']['output'];
  comparator: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FilterGroup = {
  __typename?: 'FilterGroup';
  filterGroupType: Scalars['String']['output'];
  filters: Array<Filter>;
};

export type FilterGroupInput = {
  filterGroupType: Scalars['String']['input'];
  filters: Array<FilterInput>;
};

export enum FilterGroupType {
  All = 'ALL',
  Any = 'ANY'
}

export type FilterInput = {
  attribute: Scalars['String']['input'];
  comparator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum FulfillmentMethodType {
  Collection = 'COLLECTION',
  Courier = 'COURIER',
  Delivery = 'DELIVERY',
  Table = 'TABLE'
}

export enum FulfilmentTimeType {
  Asap = 'ASAP',
  Preorder = 'PREORDER'
}

export type GeneralCuisineResponse = {
  __typename?: 'GeneralCuisineResponse';
  cuisine?: Maybe<Cuisine>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GeneralMarketplaceResponse = {
  __typename?: 'GeneralMarketplaceResponse';
  marketplace?: Maybe<RegionalMarketplace>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GeneralPartnerResponse = {
  __typename?: 'GeneralPartnerResponse';
  message?: Maybe<Scalars['String']['output']>;
  partner?: Maybe<Partner>;
};

export type GenericDeleteResponse = {
  __typename?: 'GenericDeleteResponse';
  message: Scalars['String']['output'];
};

export type GetCuisinesResponse = {
  __typename?: 'GetCuisinesResponse';
  count: Scalars['Int']['output'];
  cuisines?: Maybe<Array<Maybe<Cuisine>>>;
  totalCount: Scalars['Int']['output'];
};

export type GetCuisinesResponseWithOptInFeature = {
  __typename?: 'GetCuisinesResponseWithOptInFeature';
  categoryEmojiOrImage: CategoryEmojiOrImage;
  count: Scalars['Int']['output'];
  cuisines?: Maybe<Array<Maybe<Cuisine>>>;
  optInAllowedCategories?: Maybe<Scalars['Boolean']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type GetOutletDeliveryWindowsResponse = {
  __typename?: 'GetOutletDeliveryWindowsResponse';
  outletDeliveryWindows: Array<Maybe<OutletDeliveryWindow>>;
};

export type GetPartnersResponse = {
  __typename?: 'GetPartnersResponse';
  partners?: Maybe<Array<Maybe<Partner>>>;
};

export type GetRegionsResponse = {
  __typename?: 'GetRegionsResponse';
  count: Scalars['Int']['output'];
  regions: Array<RegionalMarketplace>;
  totalCount: Scalars['Int']['output'];
};

export type GetSameWindowOrdersCountResponse = {
  __typename?: 'GetSameWindowOrdersCountResponse';
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

export type GetTransactionsWhereArgs = {
  OR?: InputMaybe<GetTransactionsWhereOrArgs>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  marketplace_id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  order_id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  partner_id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GetTransactionsWhereOrArgs = {
  customerStripeId_contains?: InputMaybe<Scalars['String']['input']>;
  orderNumber_contains?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_contains?: InputMaybe<Scalars['String']['input']>;
};

export type HygieneRatingResponse = {
  __typename?: 'HygieneRatingResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type IPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type ITestNotification = {
  body?: InputMaybe<Scalars['String']['input']>;
  emailContent?: InputMaybe<Array<Scalars['Json']['input']>>;
  previewText?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  substitutions?: InputMaybe<Scalars['Json']['input']>;
  templateType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
};

export type Image = {
  __typename?: 'Image';
  caption?: Maybe<Scalars['String']['output']>;
  cuisines?: Maybe<Array<Maybe<Cuisine>>>;
  id: Scalars['ID']['output'];
  menuItemThumbnails?: Maybe<Array<Maybe<MenuItem>>>;
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  optionItems?: Maybe<Array<Maybe<OptionItem>>>;
  position?: Maybe<Scalars['Int']['output']>;
  src: Scalars['String']['output'];
};

export type ImageImplementer = {
  __typename?: 'ImageImplementer';
  caption?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Image;
  position?: Maybe<Scalars['Int']['output']>;
  src: Scalars['String']['output'];
};

export type ImageImplementerInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image: ImageInput;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  src: Scalars['String']['input'];
};

export type ImagesResponse = {
  __typename?: 'ImagesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  images: Array<Image>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type InformationMessageResponse = {
  __typename?: 'InformationMessageResponse';
  message: Scalars['String']['output'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  amount: Scalars['Float']['output'];
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  preparation: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum IngredientOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type IngredientResponse = {
  __typename?: 'IngredientResponse';
  ingredient?: Maybe<Ingredient>;
  message?: Maybe<Scalars['String']['output']>;
};

export type IngredientWhereInput = {
  AND?: InputMaybe<Array<IngredientWhereInput>>;
  NOT?: InputMaybe<Array<IngredientWhereInput>>;
  OR?: InputMaybe<Array<IngredientWhereInput>>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amount_gt?: InputMaybe<Scalars['Float']['input']>;
  amount_gte?: InputMaybe<Scalars['Float']['input']>;
  amount_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  amount_lt?: InputMaybe<Scalars['Float']['input']>;
  amount_lte?: InputMaybe<Scalars['Float']['input']>;
  amount_not?: InputMaybe<Scalars['Float']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  emailStatus: EmailStatus;
  id: Scalars['ID']['output'];
  isDuplicate: Scalars['Boolean']['output'];
  isVoid: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type KioskCreationInput = {
  friendlyName: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  pin: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type KioskUpdateInput = {
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
};

export type LinkCard = {
  __typename?: 'LinkCard';
  availabilityEndDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityStartDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityTimes?: Maybe<Scalars['Json']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  cardImage?: Maybe<ImageImplementer>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  menuItem?: Maybe<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Outlet>;
  segments: Array<BusinessSegment>;
  showOnApp?: Maybe<Scalars['Boolean']['output']>;
  showOnWeb?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LinkCardInput = {
  buttonText?: InputMaybe<Scalars['String']['input']>;
  cardImage?: InputMaybe<ImageImplementerInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum LinkCardOrderBy {
  Position = 'POSITION',
  Random = 'RANDOM'
}

export enum LinkCardType {
  Link = 'LINK',
  MenuItem = 'MENU_ITEM',
  Outlet = 'OUTLET'
}

export type LinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  link: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type LiveEstimateResponse = {
  __typename?: 'LiveEstimateResponse';
  estimate: Scalars['String']['output'];
};

export type LoginUserResponse = {
  __typename?: 'LoginUserResponse';
  bypass?: Maybe<TwoFactorCheckResponse>;
  canUse2FA?: Maybe<Scalars['Boolean']['output']>;
  partialToken?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  blacklistedTokenId?: Maybe<Scalars['String']['output']>;
};

export type LoyaltyCard = {
  __typename?: 'LoyaltyCard';
  active: Scalars['Boolean']['output'];
  allowCashOrders: Scalars['Boolean']['output'];
  discount?: Maybe<Discount>;
  discountStrategy: LoyaltyCardDiscountStrategy;
  id: Scalars['ID']['output'];
  loyaltyCardColor: Scalars['String']['output'];
  loyaltyCardIcon: Scalars['String']['output'];
  loyaltyCardStamps?: Maybe<Array<LoyaltyCardStamp>>;
  requiredStamps: Scalars['Int']['output'];
  suspended: Scalars['Boolean']['output'];
  termsAndConditions: Scalars['String']['output'];
};

export enum LoyaltyCardDiscountStrategy {
  BasketSubtotalDiscount = 'BASKET_SUBTOTAL_DISCOUNT',
  WholeOrderDiscount = 'WHOLE_ORDER_DISCOUNT'
}

export type LoyaltyCardLiabilityForAnalyticsResponse = {
  __typename?: 'LoyaltyCardLiabilityForAnalyticsResponse';
  liabilityBreakdown: Scalars['Int']['output'];
  totalDiscountEarned: Scalars['Int']['output'];
};

export type LoyaltyCardResponse = {
  __typename?: 'LoyaltyCardResponse';
  loyaltyCard?: Maybe<LoyaltyCard>;
  message?: Maybe<Scalars['String']['output']>;
};

export type LoyaltyCardStamp = {
  __typename?: 'LoyaltyCardStamp';
  amountEarnedOnOrder?: Maybe<Scalars['Int']['output']>;
  customer: Customer;
  id: Scalars['ID']['output'];
  loyaltyCard: LoyaltyCard;
  order: Order;
  stampClaimedOnOrder?: Maybe<Order>;
};

export type LoyaltyCardUpdateInput = {
  active: Scalars['Boolean']['input'];
  discountStrategy: LoyaltyCardDiscountStrategy;
  id: Scalars['ID']['input'];
  loyaltyCardColor: Scalars['String']['input'];
  loyaltyCardIcon: Scalars['String']['input'];
  requiredStamps: Scalars['Int']['input'];
  termsAndConditions: Scalars['String']['input'];
};

export type LoyaltyCardWhereInput = {
  AND?: InputMaybe<Array<LoyaltyCardWhereInput>>;
  NOT?: InputMaybe<Array<LoyaltyCardWhereInput>>;
  OR?: InputMaybe<Array<LoyaltyCardWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  discount?: InputMaybe<DiscountWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  loyaltyCardColor?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_contains?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_ends_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loyaltyCardColor_not?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loyaltyCardColor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardColor_starts_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_contains?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_ends_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loyaltyCardIcon_not?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_not_contains?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loyaltyCardIcon_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon_starts_with?: InputMaybe<Scalars['String']['input']>;
  requiredStamps?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_gt?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_gte?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  requiredStamps_lt?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_lte?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_not?: InputMaybe<Scalars['Int']['input']>;
  requiredStamps_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_contains?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_ends_with?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_in?: InputMaybe<Array<Scalars['String']['input']>>;
  termsAndConditions_not?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_not_contains?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  termsAndConditions_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type ManyAddressesResponse = {
  __typename?: 'ManyAddressesResponse';
  addresses?: Maybe<Address>;
};

export type ManyDeliveryNetworksResponse = {
  __typename?: 'ManyDeliveryNetworksResponse';
  deliveryNetworks?: Maybe<Array<Maybe<DeliveryNetwork>>>;
};

export type ManyDeliveryZonesResponse = {
  __typename?: 'ManyDeliveryZonesResponse';
  deliveryZones?: Maybe<Array<Maybe<DeliveryZone>>>;
};

export type ManyIngredientsResponse = {
  __typename?: 'ManyIngredientsResponse';
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
};

export type ManyMenuItemsGroupsResponse = {
  __typename?: 'ManyMenuItemsGroupsResponse';
  menuItemsGroups?: Maybe<Array<Maybe<MenuItemsGroup>>>;
};

export type ManyOptionItemsResponse = {
  __typename?: 'ManyOptionItemsResponse';
  optionItems?: Maybe<Array<Maybe<OptionItem>>>;
};

export type ManyOptionsResponse = {
  __typename?: 'ManyOptionsResponse';
  options?: Maybe<Array<Maybe<Option>>>;
};

export type ManyOrdersResponse = {
  __typename?: 'ManyOrdersResponse';
  count: Scalars['Int']['output'];
  orders: Array<Order>;
  totalCompleted: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalDeliveryNetworkFailure: Scalars['Int']['output'];
  totalInprogress: Scalars['Int']['output'];
  totalOrders: Scalars['Int']['output'];
  totalPending: Scalars['Int']['output'];
  totalPreparing: Scalars['Int']['output'];
  totalReady: Scalars['Int']['output'];
  totalRefund: Scalars['Int']['output'];
  totalRejected: Scalars['Int']['output'];
};

export type ManyPayoutResponse = {
  __typename?: 'ManyPayoutResponse';
  outlets?: Maybe<Array<Maybe<PayoutResponse>>>;
};

export type ManyReviewsResponse = {
  __typename?: 'ManyReviewsResponse';
  reviews?: Maybe<Array<Maybe<Review>>>;
};

export type ManySalesByResponse = {
  __typename?: 'ManySalesByResponse';
  averageOrdersValue?: Maybe<Scalars['Int']['output']>;
  sales?: Maybe<Array<Maybe<SetSaleResponse>>>;
  totalCardOrders?: Maybe<Scalars['Int']['output']>;
  totalCardOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCashOrders?: Maybe<Scalars['Int']['output']>;
  totalCashOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrders?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrders?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalTableOrders?: Maybe<Scalars['Int']['output']>;
  totalTableOrdersValue?: Maybe<Scalars['Int']['output']>;
};

export type ManySalesItemsResponse = {
  __typename?: 'ManySalesItemsResponse';
  items: Array<SalesItem>;
  totalGross: Scalars['Int']['output'];
  totalNet: Scalars['Int']['output'];
  totalSales: Scalars['Int']['output'];
};

export type ManyTransactionsResponse = {
  __typename?: 'ManyTransactionsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type ManyUsersResponse = {
  __typename?: 'ManyUsersResponse';
  totalCount?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type MarketingMetrics = {
  __typename?: 'MarketingMetrics';
  activeEndpoints: EndpointsData;
  activeUsers: Array<ActiveUsersDataRow>;
  allEndpoints: EndpointsData;
  campaignsCount: Scalars['Int']['output'];
  emailMetrics: Array<EmailMetricsDataRow>;
  monthlyActiveUsers: Array<MonthlyActiveUsersDataRow>;
  newUsers: Array<NewUsersDataRow>;
  retentionRate: Array<RetentionRateDataRow>;
  segmentsCount: Scalars['Int']['output'];
};

export type MarketingMetricsResponse = {
  __typename?: 'MarketingMetricsResponse';
  metrics: MarketingMetrics;
};

export enum MarketplaceChargeVatPayer {
  Customer = 'CUSTOMER',
  Merchant = 'MERCHANT'
}

export type MarketplacesGroupedByCategoryIdResponse = {
  __typename?: 'MarketplacesGroupedByCategoryIdResponse';
  categoryId: Scalars['ID']['output'];
  marketplaces?: Maybe<Array<Maybe<RegionalMarketplacePartial>>>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  SKU?: Maybe<Scalars['String']['output']>;
  UPC?: Maybe<Scalars['String']['output']>;
  VATinclusive?: Maybe<Scalars['Boolean']['output']>;
  addOnMaxPrice?: Maybe<Scalars['Int']['output']>;
  ageRestricted?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  bulkyItem: Scalars['Boolean']['output'];
  categoryTag?: Maybe<Scalars['String']['output']>;
  costPrice?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<ImageImplementer>;
  ingredients?: Maybe<Array<Ingredient>>;
  inheritants?: Maybe<Array<MenuItem>>;
  inheritedFrom?: Maybe<MenuItem>;
  isDairyFree?: Maybe<Scalars['Boolean']['output']>;
  isGlutenFree?: Maybe<Scalars['Boolean']['output']>;
  isKeto: Scalars['Boolean']['output'];
  isPromoted?: Maybe<Scalars['Boolean']['output']>;
  isVegan?: Maybe<Scalars['Boolean']['output']>;
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  maxPurchaseQuantity?: Maybe<Scalars['Int']['output']>;
  minimumPurchasePrice?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nutrition?: Maybe<Nutrition>;
  optionPositions?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  options?: Maybe<Array<Option>>;
  outlet?: Maybe<Outlet>;
  outletHidden?: Maybe<Scalars['Boolean']['output']>;
  outletSoldOut?: Maybe<Scalars['Boolean']['output']>;
  parentMenus?: Maybe<Array<MenuItemsGroup>>;
  position?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  restaurant?: Maybe<Restaurant>;
  spiceLevel: Scalars['Int']['output'];
  thumbnail?: Maybe<ImageImplementer>;
  updatedAt: Scalars['DateTime']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};


export type MenuItemIngredientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IngredientOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IngredientWhereInput>;
};


export type MenuItemInheritantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemWhereInput>;
};


export type MenuItemParentMenusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemsGroupWhereInput>;
};

export enum MenuItemGroupFulfillmentMethod {
  Collection = 'COLLECTION',
  Delivery = 'DELIVERY',
  Table = 'TABLE'
}

export enum MenuItemOrderByInput {
  VaTinclusiveAsc = 'VATinclusive_ASC',
  VaTinclusiveDesc = 'VATinclusive_DESC',
  AgeRestrictedAsc = 'ageRestricted_ASC',
  AgeRestrictedDesc = 'ageRestricted_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CategoryTagAsc = 'categoryTag_ASC',
  CategoryTagDesc = 'categoryTag_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HiddenAsc = 'hidden_ASC',
  HiddenDesc = 'hidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  IsDairyFreeAsc = 'isDairyFree_ASC',
  IsDairyFreeDesc = 'isDairyFree_DESC',
  IsGlutenFreeAsc = 'isGlutenFree_ASC',
  IsGlutenFreeDesc = 'isGlutenFree_DESC',
  IsPromotedAsc = 'isPromoted_ASC',
  IsPromotedDesc = 'isPromoted_DESC',
  IsVeganAsc = 'isVegan_ASC',
  IsVeganDesc = 'isVegan_DESC',
  IsVegetarianAsc = 'isVegetarian_ASC',
  IsVegetarianDesc = 'isVegetarian_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OutletSoldOutAsc = 'outletSoldOut_ASC',
  OutletSoldOutDesc = 'outletSoldOut_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatRateAsc = 'vatRate_ASC',
  VatRateDesc = 'vatRate_DESC'
}

export type MenuItemPreviousValues = {
  __typename?: 'MenuItemPreviousValues';
  SKU?: Maybe<Scalars['String']['output']>;
  UPC?: Maybe<Scalars['String']['output']>;
  VATinclusive?: Maybe<Scalars['Boolean']['output']>;
  ageRestricted?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  bulkyItem: Scalars['Boolean']['output'];
  categoryTag?: Maybe<Scalars['String']['output']>;
  costPrice?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isDairyFree?: Maybe<Scalars['Boolean']['output']>;
  isGlutenFree?: Maybe<Scalars['Boolean']['output']>;
  isPromoted?: Maybe<Scalars['Boolean']['output']>;
  isVegan?: Maybe<Scalars['Boolean']['output']>;
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  minimumPurchasePrice?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  outletHidden?: Maybe<Scalars['Boolean']['output']>;
  outletSoldOut?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  soldOut?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type MenuItemResponse = {
  __typename?: 'MenuItemResponse';
  menuItem?: Maybe<MenuItem>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MenuItemSubscriptionPayload = {
  __typename?: 'MenuItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MenuItem>;
  previousValues?: Maybe<MenuItemPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']['output']>>;
};

export type MenuItemWhereInput = {
  AND?: InputMaybe<Array<MenuItemWhereInput>>;
  NOT?: InputMaybe<Array<MenuItemWhereInput>>;
  OR?: InputMaybe<Array<MenuItemWhereInput>>;
  VATinclusive?: InputMaybe<Scalars['Boolean']['input']>;
  VATinclusive_not?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted_not?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  categoryTag?: InputMaybe<Scalars['String']['input']>;
  categoryTag_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTag_ends_with?: InputMaybe<Scalars['String']['input']>;
  categoryTag_gt?: InputMaybe<Scalars['String']['input']>;
  categoryTag_gte?: InputMaybe<Scalars['String']['input']>;
  categoryTag_in?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryTag_lt?: InputMaybe<Scalars['String']['input']>;
  categoryTag_lte?: InputMaybe<Scalars['String']['input']>;
  categoryTag_not?: InputMaybe<Scalars['String']['input']>;
  categoryTag_not_contains?: InputMaybe<Scalars['String']['input']>;
  categoryTag_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  categoryTag_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryTag_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  categoryTag_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  image_contains?: InputMaybe<Scalars['String']['input']>;
  image_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_gt?: InputMaybe<Scalars['String']['input']>;
  image_gte?: InputMaybe<Scalars['String']['input']>;
  image_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_lt?: InputMaybe<Scalars['String']['input']>;
  image_lte?: InputMaybe<Scalars['String']['input']>;
  image_not?: InputMaybe<Scalars['String']['input']>;
  image_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  image_starts_with?: InputMaybe<Scalars['String']['input']>;
  ingredients_every?: InputMaybe<IngredientWhereInput>;
  ingredients_none?: InputMaybe<IngredientWhereInput>;
  ingredients_some?: InputMaybe<IngredientWhereInput>;
  inheritants_every?: InputMaybe<MenuItemWhereInput>;
  inheritants_none?: InputMaybe<MenuItemWhereInput>;
  inheritants_some?: InputMaybe<MenuItemWhereInput>;
  inheritedFrom?: InputMaybe<MenuItemWhereInput>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isDairyFree_not?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree_not?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionWhereInput>;
  options_every?: InputMaybe<OptionWhereInput>;
  options_none?: InputMaybe<OptionWhereInput>;
  options_some?: InputMaybe<OptionWhereInput>;
  outlet?: InputMaybe<OutletWhereInput>;
  outletHidden?: InputMaybe<Scalars['Boolean']['input']>;
  outletSoldOut?: InputMaybe<Scalars['Boolean']['input']>;
  outletSoldOut_not?: InputMaybe<Scalars['Boolean']['input']>;
  parentMenus_every?: InputMaybe<MenuItemsGroupWhereInput>;
  parentMenus_none?: InputMaybe<MenuItemsGroupWhereInput>;
  parentMenus_some?: InputMaybe<MenuItemsGroupWhereInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  position_not?: InputMaybe<Scalars['Int']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  price_gt?: InputMaybe<Scalars['Int']['input']>;
  price_gte?: InputMaybe<Scalars['Int']['input']>;
  price_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  price_lt?: InputMaybe<Scalars['Int']['input']>;
  price_lte?: InputMaybe<Scalars['Int']['input']>;
  price_not?: InputMaybe<Scalars['Int']['input']>;
  price_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  vatRate_gt?: InputMaybe<Scalars['Float']['input']>;
  vatRate_gte?: InputMaybe<Scalars['Float']['input']>;
  vatRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  vatRate_lt?: InputMaybe<Scalars['Float']['input']>;
  vatRate_lte?: InputMaybe<Scalars['Float']['input']>;
  vatRate_not?: InputMaybe<Scalars['Float']['input']>;
  vatRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type MenuItemsGroup = {
  __typename?: 'MenuItemsGroup';
  addOnItemsMenu: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  availabilityEndDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityStartDate?: Maybe<Scalars['DateTime']['output']>;
  availabilityTimes?: Maybe<Scalars['Json']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  childrenMenus?: Maybe<Array<MenuItemsGroup>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fulfillmentMethods: Array<MenuItemGroupFulfillmentMethod>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  menuItems?: Maybe<Array<MenuItem>>;
  name: Scalars['String']['output'];
  outlet?: Maybe<Outlet>;
  parentMenu?: Maybe<MenuItemsGroup>;
  position?: Maybe<Scalars['Int']['output']>;
  restaurant?: Maybe<Restaurant>;
  showMenuThumbnails: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum MenuItemsGroupOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  AvailabilityEndDateAsc = 'availabilityEndDate_ASC',
  AvailabilityEndDateDesc = 'availabilityEndDate_DESC',
  AvailabilityStartDateAsc = 'availabilityStartDate_ASC',
  AvailabilityStartDateDesc = 'availabilityStartDate_DESC',
  AvailabilityTimesAsc = 'availabilityTimes_ASC',
  AvailabilityTimesDesc = 'availabilityTimes_DESC',
  AvailableAsc = 'available_ASC',
  AvailableDesc = 'available_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type MenuItemsGroupResponse = {
  __typename?: 'MenuItemsGroupResponse';
  menuItemsGroup?: Maybe<MenuItemsGroup>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MenuItemsGroupWhereInput = {
  AND?: InputMaybe<Array<MenuItemsGroupWhereInput>>;
  NOT?: InputMaybe<Array<MenuItemsGroupWhereInput>>;
  OR?: InputMaybe<Array<MenuItemsGroupWhereInput>>;
  addOnItemsMenu?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  availabilityEndDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityEndDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  availabilityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  availabilityStartDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  available_not?: InputMaybe<Scalars['Boolean']['input']>;
  childrenMenus_every?: InputMaybe<MenuItemsGroupWhereInput>;
  childrenMenus_none?: InputMaybe<MenuItemsGroupWhereInput>;
  childrenMenus_some?: InputMaybe<MenuItemsGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  image_contains?: InputMaybe<Scalars['String']['input']>;
  image_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_gt?: InputMaybe<Scalars['String']['input']>;
  image_gte?: InputMaybe<Scalars['String']['input']>;
  image_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_lt?: InputMaybe<Scalars['String']['input']>;
  image_lte?: InputMaybe<Scalars['String']['input']>;
  image_not?: InputMaybe<Scalars['String']['input']>;
  image_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  image_starts_with?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  menuItems_every?: InputMaybe<MenuItemWhereInput>;
  menuItems_none?: InputMaybe<MenuItemWhereInput>;
  menuItems_some?: InputMaybe<MenuItemWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  outlet?: InputMaybe<OutletWhereInput>;
  parentMenu?: InputMaybe<MenuItemsGroupWhereInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  position_not?: InputMaybe<Scalars['Int']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type MenuItemsResponse = {
  __typename?: 'MenuItemsResponse';
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
};

export enum MenuListGroupStyle {
  ImageGrid = 'IMAGE_GRID',
  List = 'LIST'
}

export enum MenuListItemStyle {
  ImageGrid = 'IMAGE_GRID',
  ImageList = 'IMAGE_LIST',
  List = 'LIST'
}

export type MerchantCategory = {
  __typename?: 'MerchantCategory';
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  marketplaceId: Scalars['String']['output'];
  plural: Scalars['String']['output'];
  singular: Scalars['String']['output'];
};

export enum MerchantType {
  Restaurant = 'RESTAURANT',
  Retail = 'RETAIL'
}

export type Message = {
  __typename?: 'Message';
  date: Scalars['DateTime']['output'];
  from: Scalars['String']['output'];
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marketplace?: Maybe<RegionalMarketplace>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  subject: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textAsHtml?: Maybe<Scalars['String']['output']>;
  to: Array<Scalars['String']['output']>;
};

export type MessageDeliveryStatus = {
  __typename?: 'MessageDeliveryStatus';
  customerId?: Maybe<Scalars['String']['output']>;
  failureReason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MessageResponse = {
  __typename?: 'MessageResponse';
  message?: Maybe<Message>;
};

export type MessageSettingsResponse = {
  __typename?: 'MessageSettingsResponse';
  marketplace: RegionalMarketplace;
  message: Scalars['String']['output'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  emailContent: Array<Scalars['Json']['output']>;
  emailPreviewText: Scalars['String']['output'];
  emailSubject: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  pushBody: Scalars['String']['output'];
  pushTitle: Scalars['String']['output'];
  templateType: TemplateTypesEnum;
};

export type MessageTemplateResponse = {
  __typename?: 'MessageTemplateResponse';
  message: Scalars['String']['output'];
  messageTemplate?: Maybe<MessageTemplate>;
};

export type MessageTemplatesResponse = {
  __typename?: 'MessageTemplatesResponse';
  messageTemplates: Array<MessageTemplate>;
};

export enum MessageType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type MessagesResponse = {
  __typename?: 'MessagesResponse';
  customers?: Maybe<Array<Customer>>;
  messages: Array<Message>;
};

export type MonthlyActiveUsersDataRow = {
  __typename?: 'MonthlyActiveUsersDataRow';
  date: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type MultipleOutletDeliveryZoneCostsResponse = {
  __typename?: 'MultipleOutletDeliveryZoneCostsResponse';
  outletDeliveryZoneCosts?: Maybe<Array<OutletDeliveryZoneCost>>;
};

export type MultipleOutletsResponse = {
  __typename?: 'MultipleOutletsResponse';
  count: Scalars['Int']['output'];
  outlets: Array<Outlet>;
  totalCount: Scalars['Int']['output'];
};

export type MultiplePosDeviceResponse = {
  __typename?: 'MultiplePOSDeviceResponse';
  count: Scalars['Int']['output'];
  posDevices: Array<PosDevice>;
  totalCount: Scalars['Int']['output'];
};

export type MultipleRestaurantsResponse = {
  __typename?: 'MultipleRestaurantsResponse';
  count: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  restaurants?: Maybe<Array<Maybe<Restaurant>>>;
  totalCount: Scalars['Int']['output'];
};

export type MultipleTerminalResponse = {
  __typename?: 'MultipleTerminalResponse';
  count?: Maybe<Scalars['Int']['output']>;
  terminals: Array<Terminal>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptNetworkOrder: OrderResponse;
  addAddress: AddressResponse;
  addApp: AppResponse;
  addBillingPaymentMethod: AddPaymentMethodResponse;
  addBillingSubscription: GeneralMarketplaceResponse;
  addBusinessSegment: BusinessSegment;
  addCampaign: CampaignResponse;
  addCuisine: GeneralCuisineResponse;
  /**
   * ### Validation
   * Args are validated against the following schemas based on the value of deliveryProvider
   *
   * #### STUART
   * deliveryProvider: **STUART**\
   * marketplaceId: **String!**\
   * name: **String!**\
   * helpPhone: **String**\
   * namePrefix: **String**\
   * apiClientId: **String!**\
   * apiSecret: **String!**\
   * fixedSubsidy: **Int**\
   * deliveryChargeRecipient: **DeliveryChargeRecipient!**\
   * deliveryNetworkType: **DeliveryNetworkType!**
   *
   * #### WAYBOX
   * deliveryProvider: **WAYBOX**\
   * marketplaceId: **String!**\
   * name: **String!**\
   * helpPhone: **String**\
   * namePrefix: **String**\
   * apiURL: **String!**\
   * apiKey: **String!**\
   * fixedSubsidy: **Int**\
   * deliveryChargeRecipient: **DeliveryChargeRecipient!**
   */
  addDeliveryNetwork: DeliveryNetworkResponse;
  addDeliveryZone: DeliveryZoneResponse;
  addFAQ: Faq;
  addLinkCard: LinkCard;
  addMarketplace: GeneralMarketplaceResponse;
  addMarketplaceMessageTemplate: MessageTemplateResponse;
  addMenuItem: MenuItemResponse;
  addMenuItemsGroup: MenuItemsGroupResponse;
  addOption: OptionResponse;
  addOptionItem: OptionItemResponse;
  addOptionToMenuItem: MenuItemResponse;
  addOutlet: OutletResponse;
  /**
   * ### Validation
   * Args are validated against the following schemas based on the value of outletDeliveryZoneType
   *
   * #### RADIUS_AROUND_OUTLET
   * outletDeliveryZoneType: RADIUS_AROUND_OUTLET\
   * outletId: **String!**\
   * deliveryZoneId: **String!**\
   * radiusMiles: **Float!**\
   * cost: **Int!**
   *
   * #### COORDINATE_POLYGON
   * outletDeliveryZoneType: COORDINATE_POLYGON\
   * outletId: **String!**\
   * deliveryZoneId: **String!**\
   * cost: **Int!**
   *
   * #### STUART
   * outletDeliveryZoneType: STUART\
   * outletId: **String!**\
   * deliveryZoneId: **String!**\
   * radiusMiles: **Float!**\
   * apiClientId: **String**\
   * apiSecret: **String**\
   * fixedSubsidy: **Int**\
   * cost: **Int!**
   *
   * #### WAYBOX
   * outletDeliveryZoneType: WAYBOX\
   * outletId: **String!**\
   * deliveryZoneId: **String!**\
   * radiusMiles: **Float!**\
   * cost: **Int!**
   */
  addOutletDeliveryZone: OutletDeliveryZoneCost;
  addPOSDevice: PosDevice;
  addPartner: GeneralPartnerResponse;
  addPermission: PermissionResponse;
  addRestaurant: RestaurantResponse;
  addRole: RoleResponse;
  addSegment: SegmentResponse;
  addTable: Table;
  addTerminal: TerminalResponse;
  addWebhook: Webhook;
  approveReview: ReviewResponse;
  archiveCustomer?: Maybe<Scalars['Boolean']['output']>;
  archiveFooterLink: GeneralMarketplaceResponse;
  archiveHeaderLink: GeneralMarketplaceResponse;
  archiveMenuItem: MenuItemResponse;
  archiveMenuItemsGroup: MenuItemsGroupResponse;
  archiveOption: OptionResponse;
  archiveOptionItem: OptionItemResponse;
  archiveOutlet: OutletResponse;
  archiveOutletDeliveryZone: OutletDeliveryZoneCost;
  archivePartner: GeneralPartnerResponse;
  archiveRestaurant: RestaurantResponse;
  archiveStripeConnectAccount?: Maybe<SimpleMutationResponse>;
  archiveUser: UserResponse;
  blacklistAddress: BlacklistAddressResponse;
  cancelNetworkOrder: OrderResponse;
  changeMenuVisibility: Scalars['Boolean']['output'];
  clearRedisCache: InformationMessageResponse;
  closeEarly: OutletResponse;
  connectMarketplace?: Maybe<RedirectResponse>;
  connectOutlet?: Maybe<RedirectResponse>;
  createDiscount: DiscountResponse;
  createLoyaltyCard: LoyaltyCardResponse;
  createVouchers: VouchersResponse;
  delayNetworkOrder: OrderResponse;
  deleteAddress: AddressResponse;
  deleteBillingPaymentMethod: GeneralMarketplaceResponse;
  deleteBlacklistedAddress: BlacklistAddressResponse;
  deleteBusinessSegment: DeleteBusinessSegmentResponse;
  deleteCampaign: DeleteCampaignResponse;
  deleteCuisine: GeneralCuisineResponse;
  deleteDeliveryZone: GenericDeleteResponse;
  deleteDiscount: DiscountResponse;
  deleteFAQ: Faq;
  deleteImageBySrc: DeleteImageResponse;
  deleteLinkCard: GenericDeleteResponse;
  deleteLoyaltyCard: LoyaltyCardResponse;
  deleteMemberDiscount: DiscountResponse;
  deleteMessageHistory: GenericDeleteResponse;
  deletePOSDevice: Scalars['Boolean']['output'];
  deletePartnerPaymentWebhooks: SimpleMutationResponse;
  deletePlatformStripeWebhooks: SimpleMutationResponse;
  deleteSegment: DeleteSegmentResponse;
  deleteTerminal: GenericDeleteResponse;
  deleteWebhook: Scalars['Boolean']['output'];
  disconnectStripe: GeneralMarketplaceResponse;
  editAddress: AddressResponse;
  editAllowedCategories: GeneralMarketplaceResponse;
  editBusinessSegment: EditBusinessSegmentResponse;
  editBusinessSegmentOrder?: Maybe<BusinessSegmentPositionResponse>;
  editBusinessSignificantDates: BusinesssignificantDatesResponse;
  editCampaign: CampaignResponse;
  editCuisine: GeneralCuisineResponse;
  /**
   * ### Validation
   * Args are validated against the following schemas based on the value of deliveryProvider
   *
   * #### STUART
   * id: **String!**\
   * name: **String**\
   * helpPhone: **String**\
   * namePrefix: **String**\
   * apiClientId: **String**\
   * apiSecret: **String**\
   * fixedSubsidy: **Int**\
   * deliveryChargeRecipient: **DeliveryChargeRecipient**\
   * deliveryNetworkType: **DeliveryNetworkType**
   *
   * #### WAYBOX
   * id: **String!**\
   * name: **String**\
   * helpPhone: **String**\
   * namePrefix: **String**\
   * apiURL: **String**\
   * apiKey: **String**\
   * fixedSubsidy: **Int**\
   * deliveryChargeRecipient: **DeliveryChargeRecipient**
   */
  editDeliveryNetwork: DeliveryNetworkResponse;
  editDeliveryZone: DeliveryZoneResponse;
  editDiscount: DiscountResponse;
  editFAQ: Faq;
  editFooterLinksOrder: GeneralMarketplaceResponse;
  editHeaderLinksOrder: GeneralMarketplaceResponse;
  editLinkCard: LinkCard;
  editLoyaltyCard: LoyaltyCardResponse;
  editMarketplace: GeneralMarketplaceResponse;
  editMarketplaceAppFeatures: GeneralMarketplaceResponse;
  editMarketplaceFinancials: GeneralMarketplaceResponse;
  editMarketplaceKioskFeatures: GeneralMarketplaceResponse;
  editMarketplaceMessageSettings: MessageSettingsResponse;
  editMarketplaceMessageTemplate: MessageTemplateResponse;
  editMarketplaceWebsite: GeneralMarketplaceResponse;
  editMenuItem: MenuItemResponse;
  editMenuItemOptionOrder: MenuItemResponse;
  editMenuItemsGroup: MenuItemsGroupResponse;
  editMenuItemsGroupFulfillmentMethods?: Maybe<MenuItemsGroup>;
  editMenuItemsOrder: MenuItemsResponse;
  editMenusOrder: ManyMenuItemsGroupsResponse;
  editMinAppVersionOverride?: Maybe<EditMinAppVersionOverrideResponse>;
  editMinAppVersionSupported: GeneralMarketplaceResponse;
  editMyDetails: UserResponse;
  editOption: OptionResponse;
  editOptionItem: OptionItemResponse;
  editOptionItemsOrder: ManyOptionItemsResponse;
  editOptionsOrder: ManyOptionsResponse;
  editOutlet: OutletResponse;
  editOutletCategories: OutletResponse;
  editOutletDeliveryZone: OutletDeliveryZoneCost;
  editOutletOtterStoreId: Outlet;
  editOutletSpecialOfferFlags: OutletResponse;
  editOutletVAT: OutletResponse;
  editPOSDevice: PosDevice;
  editPartner: GeneralPartnerResponse;
  editPartnerPayments: GeneralPartnerResponse;
  editPermission: PermissionResponse;
  editPlatform: PlatformResponse;
  editPlatformIntegrations: PlatformResponse;
  editRestaurant: RestaurantResponse;
  editRestaurantFinancials: RestaurantResponse;
  editRestaurantVAT: Restaurant;
  editRole: RoleResponse;
  editSegment: SegmentResponse;
  editTableActivation: Table;
  editTableFriendlyName: Table;
  editTerminal: TerminalResponse;
  editTerminalPin: TerminalResponse;
  editUser: UserResponse;
  editWebhook: Webhook;
  enrollMemberToDiscount: EnrollCustomerDiscountResponse;
  featureOrPromoteOutlet: OutletResponse;
  forceCustomerLogout?: Maybe<Scalars['Boolean']['output']>;
  importManyCustomers: CustomersResponse;
  inviteUser: UserResponse;
  loginUser: LoginUserResponse;
  logoutUser: LogoutResponse;
  muteTerminal: TerminalResponse;
  onboardingApply?: Maybe<OnboardingApplyResponse>;
  onboardingApprove?: Maybe<RestaurantResponse>;
  onboardingDeny?: Maybe<RestaurantResponse>;
  outletMenuItemSoldOutStatus?: Maybe<OutletResponse>;
  populateStuartOperationalAreas: Array<OperationalArea>;
  /**
   * *Args*
   * If documentKey is provided, a single invoice will be queued for email
   * If outletId is provided, that outlet's most recent invoice queued for email
   * If dateFrom_gte (and optionally dateTo_lte) is provided invoices will queued for email, where they meet the following criteria:
   *   * emailStatus is NERVER_QUEUED
   *   * dateFrom is >= dateFrom_gte
   *   * dateTo is <= dateTo_gte
   *   * outlet has invoice emails enabled
   *   * marketplace has invoice emails enabled
   * *Returns*
   * Returns a count of the number of invoices queued for email.
   */
  queueInvoiceEmails: Scalars['Int']['output'];
  readyNetworkOrder: OrderResponse;
  refundOrder: InformationMessageResponse;
  regenerateStripeCustomer?: Maybe<Scalars['Boolean']['output']>;
  register: UserResponse;
  reinviteUser: UserResponse;
  relocateAddress?: Maybe<AddressResponse>;
  removeReview: ReviewResponse;
  replyToReview: ReviewResponse;
  requestResetPassword: RequestResetPasswordResponse;
  resetKioskPIN: Scalars['Boolean']['output'];
  resetPassword: RequestResetPasswordResponse;
  sendMessageReply: SendMessageToOutletsResponse;
  sendMessageToOutlets: SendMessageToOutletsResponse;
  sendTestNotification: SendTestNotificationResponse;
  setMarketplaceCountry: GeneralMarketplaceResponse;
  setMarketplaceFacebook: GeneralMarketplaceResponse;
  setPartnerPaymentWebhooks: SimpleMutationResponse;
  setPlatformStripeWebhooks: SimpleMutationResponse;
  suspendLoyaltyCard: LoyaltyCardResponse;
  testPlatformIntegrations: Scalars['Boolean']['output'];
  toggleDiscountActivation: DiscountResponse;
  toggleHygieneRating: HygieneRatingResponse;
  toggleMarketplaceStatus: GeneralMarketplaceResponse;
  toggleOutletActivation: OutletResponse;
  twoFactorCheck: TwoFactorCheckResponse;
  updateAndSynchroniseEmailTheme: GeneralMarketplaceResponse;
  updateDeliveryWindowsLimit: UpdateDeliveryWindowsResponse;
  updateLoyaltyCardActiveState: LoyaltyCardResponse;
  updateMyPassword: SelfUpdatePasswordResponse;
  updateOrderStatus: OrderResponse;
  updateVouchersActiveState: Scalars['Boolean']['output'];
  uploadImages: UploadImagesResponse;
  validatePhone: ValidatePhoneResponse;
  voidInvoice: Scalars['Boolean']['output'];
};


export type MutationAcceptNetworkOrderArgs = {
  newCompletionDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  orderId: Scalars['String']['input'];
};


export type MutationAddAddressArgs = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddAppArgs = {
  appcenterAppNameAndroid?: InputMaybe<Scalars['String']['input']>;
  appcenterAppNameiOS?: InputMaybe<Scalars['String']['input']>;
  appleTeamId?: InputMaybe<Scalars['String']['input']>;
  googleJsonS3KeyPath?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
};


export type MutationAddBillingPaymentMethodArgs = {
  id: Scalars['ID']['input'];
  level: BillingCustomerLevel;
  product?: InputMaybe<BillingProductType>;
};


export type MutationAddBillingSubscriptionArgs = {
  marketplaceId: Scalars['ID']['input'];
  product: BillingProductType;
};


export type MutationAddBusinessSegmentArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<BusinessSegmentFilterInput>>>;
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: BusinessSegmentType;
};


export type MutationAddCampaignArgs = {
  emailChannel: Scalars['Boolean']['input'];
  emailContent: Array<Scalars['Json']['input']>;
  emailPreviewText?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  push?: InputMaybe<CampaignPushInput>;
  pushChannel: Scalars['Boolean']['input'];
  segment: Scalars['String']['input'];
  sms?: InputMaybe<CampaignSmsInput>;
  smsChannel: Scalars['Boolean']['input'];
  startDateTime?: InputMaybe<Scalars['String']['input']>;
  timeConfig: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationAddCuisineArgs = {
  name: Scalars['String']['input'];
};


export type MutationAddDeliveryNetworkArgs = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiSecret?: InputMaybe<Scalars['String']['input']>;
  apiURL?: InputMaybe<Scalars['String']['input']>;
  deliveryChargeRecipient: DeliveryChargeRecipient;
  deliveryNetworkType?: InputMaybe<DeliveryNetworkType>;
  deliveryProvider: DeliveryProvidersEnum;
  fixedSubsidy?: InputMaybe<Scalars['Int']['input']>;
  helpPhone?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  namePrefix?: InputMaybe<Scalars['String']['input']>;
  uberDirectClientId?: InputMaybe<Scalars['String']['input']>;
  uberDirectClientSecret?: InputMaybe<Scalars['String']['input']>;
  uberDirectCustomerId?: InputMaybe<Scalars['String']['input']>;
  uberDirectSigningKey?: InputMaybe<Scalars['String']['input']>;
  uberDirectTestScenario?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddDeliveryZoneArgs = {
  deliveryZoneType: DeliveryZoneType;
  geoFence?: InputMaybe<Scalars['Json']['input']>;
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationAddFaqArgs = {
  answer: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
};


export type MutationAddLinkCardArgs = {
  availabilityEndDate?: InputMaybe<Scalars['String']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['String']['input']>;
  availabilityTimes?: InputMaybe<Scalars['Json']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
  showOnApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<LinkCardType>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddMarketplaceArgs = {
  ageRestrictionText?: InputMaybe<Scalars['String']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  contactAddress: AddressCreateInput;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  defaultCancelText: Scalars['String']['input'];
  defaultRejectText: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  merchantType: MerchantType;
  name: Scalars['String']['input'];
  orderMode: OrderingMode;
  partnerId: Scalars['String']['input'];
  pinpointAwsAccountId: Scalars['String']['input'];
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  urlPath: Scalars['String']['input'];
};


export type MutationAddMarketplaceMessageTemplateArgs = {
  emailContent: Array<Scalars['Json']['input']>;
  emailPreviewText: Scalars['String']['input'];
  emailSubject: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  pushBody: Scalars['String']['input'];
  pushTitle: Scalars['String']['input'];
  templateType: Scalars['String']['input'];
};


export type MutationAddMenuItemArgs = {
  SKU?: InputMaybe<Scalars['String']['input']>;
  UPC?: InputMaybe<Scalars['String']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  bulkyItem?: InputMaybe<Scalars['Boolean']['input']>;
  costPrice?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isKeto?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  maxPurchaseQuantity?: InputMaybe<Scalars['Int']['input']>;
  menuItemGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  minimumPurchasePrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  optionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  position?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Int']['input'];
  restaurantId: Scalars['String']['input'];
  spiceLevel?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAddMenuItemsGroupArgs = {
  addOnItemsMenu: Scalars['Boolean']['input'];
  availabilityEndDate?: InputMaybe<Scalars['String']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['String']['input']>;
  availabilityTimes?: InputMaybe<Scalars['Json']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fulfillmentMethods: Array<MenuItemGroupFulfillmentMethod>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentMenuId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  restaurantId: Scalars['String']['input'];
};


export type MutationAddOptionArgs = {
  maxOptions?: InputMaybe<Scalars['Int']['input']>;
  minOptions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  optionItems?: InputMaybe<Array<InputMaybe<OptionItemInput>>>;
  optionListIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parentMenuIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  restaurantId: Scalars['String']['input'];
};


export type MutationAddOptionItemArgs = {
  VATinclusive?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Int']['input'];
  restaurantId: Scalars['String']['input'];
  soldOut?: InputMaybe<Scalars['Boolean']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationAddOptionToMenuItemArgs = {
  id: Scalars['String']['input'];
  optionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationAddOutletArgs = {
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  outletAddress?: InputMaybe<AddressCreateInput>;
  outletEmail?: InputMaybe<Scalars['String']['input']>;
  outletPhone?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['String']['input'];
};


export type MutationAddOutletDeliveryZoneArgs = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  apiSecret?: InputMaybe<Scalars['String']['input']>;
  cost: Scalars['Int']['input'];
  deliveryZoneId: Scalars['String']['input'];
  fixedSubsidy?: InputMaybe<Scalars['Int']['input']>;
  outletDeliveryZoneType: OutletDeliveryZoneType;
  outletId: Scalars['String']['input'];
  pickupNotes?: InputMaybe<Scalars['String']['input']>;
  radiusMiles?: InputMaybe<Scalars['Float']['input']>;
  uberDirectUndeliverableAction?: InputMaybe<UberDirectUndeliverableAction>;
};


export type MutationAddPosDeviceArgs = {
  kioskData?: InputMaybe<KioskCreationInput>;
  marketplaceId: Scalars['String']['input'];
  outletIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationAddPartnerArgs = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  companyLegalName: Scalars['String']['input'];
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  contactAddress: AddressCreateInput;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  name: Scalars['String']['input'];
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportTitle?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddPermissionArgs = {
  mutations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  queries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
};


export type MutationAddRestaurantArgs = {
  contactAddress: AddressCreateInput;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  name: Scalars['String']['input'];
  outletMarketplaceId?: InputMaybe<Scalars['String']['input']>;
  outletName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['String']['input'];
};


export type MutationAddRoleArgs = {
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
};


export type MutationAddSegmentArgs = {
  marketplaceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationAddTableArgs = {
  friendlyName: Scalars['String']['input'];
  outletId: Scalars['String']['input'];
};


export type MutationAddTerminalArgs = {
  alarm?: InputMaybe<Alarm>;
  editableMessages?: InputMaybe<Scalars['Boolean']['input']>;
  friendlyName: Scalars['String']['input'];
  outletIds: Array<Scalars['String']['input']>;
  pin: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  slave?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAddWebhookArgs = {
  endpoint: Scalars['String']['input'];
  headerKey: Scalars['String']['input'];
  headerValue: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  webhookType: Array<WebhookType>;
};


export type MutationApproveReviewArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type MutationArchiveFooterLinkArgs = {
  id: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type MutationArchiveHeaderLinkArgs = {
  id: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type MutationArchiveMenuItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveMenuItemsGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveOptionArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveOptionItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveOutletArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveOutletDeliveryZoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchivePartnerArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveRestaurantArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveStripeConnectAccountArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationBlacklistAddressArgs = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['Json']['input']>;
  firstLine: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelNetworkOrderArgs = {
  orderId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type MutationChangeMenuVisibilityArgs = {
  hidden: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  outletId: Scalars['String']['input'];
};


export type MutationCloseEarlyArgs = {
  outletId: Scalars['String']['input'];
  reopenDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationConnectMarketplaceArgs = {
  marketplaceId: Scalars['ID']['input'];
};


export type MutationConnectOutletArgs = {
  outletId: Scalars['ID']['input'];
};


export type MutationCreateDiscountArgs = {
  allowedOutlets: Array<Scalars['String']['input']>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  restaurantId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationCreateLoyaltyCardArgs = {
  active: Scalars['Boolean']['input'];
  allowCashOrders: Scalars['Boolean']['input'];
  allowedOutletIds: Array<Scalars['String']['input']>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  discountStrategy: LoyaltyCardDiscountStrategy;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  loyaltyCardColor?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  requiredStamps: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateVouchersArgs = {
  active: Scalars['Boolean']['input'];
  allowedOutletIds: Array<Scalars['String']['input']>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountName: Scalars['String']['input'];
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  howMany: Scalars['Int']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  onePerCustomer: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  usageLimit: Scalars['Int']['input'];
};


export type MutationDelayNetworkOrderArgs = {
  newCompletionDateTime: Scalars['DateTime']['input'];
  orderId: Scalars['String']['input'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBillingPaymentMethodArgs = {
  id: Scalars['ID']['input'];
  level: BillingCustomerLevel;
  paymentMethodId: Scalars['String']['input'];
};


export type MutationDeleteBlacklistedAddressArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBusinessSegmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
};


export type MutationDeleteCuisineArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDeliveryZoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDiscountArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFaqArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteImageBySrcArgs = {
  src: Scalars['String']['input'];
};


export type MutationDeleteLinkCardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteLoyaltyCardArgs = {
  id: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type MutationDeleteMemberDiscountArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMessageHistoryArgs = {
  messageId: Scalars['ID']['input'];
};


export type MutationDeletePosDeviceArgs = {
  deviceId: Scalars['String']['input'];
};


export type MutationDeletePartnerPaymentWebhooksArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePlatformStripeWebhooksArgs = {
  paybox: Scalars['Boolean']['input'];
};


export type MutationDeleteSegmentArgs = {
  id: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
};


export type MutationDeleteTerminalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['String']['input'];
};


export type MutationDisconnectStripeArgs = {
  marketplaceId: Scalars['ID']['input'];
  stripeId: Scalars['String']['input'];
};


export type MutationEditAddressArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  thirdLine?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditAllowedCategoriesArgs = {
  allowedCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryEmojiOrImage: CategoryEmojiOrImage;
  marketplaceId: Scalars['String']['input'];
  optInAllowedCategories: Scalars['Boolean']['input'];
};


export type MutationEditBusinessSegmentArgs = {
  availabilityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityTimes?: InputMaybe<Array<AvailabilityTimeInput>>;
  categories?: InputMaybe<Array<BusinessSegmentCategoryInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterSegment?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<BusinessSegmentsGroupInput>>;
  id: Scalars['String']['input'];
  linkCardOrderBy?: InputMaybe<LinkCardOrderBy>;
  linkCards?: InputMaybe<Array<LinkCardInput>>;
  linkToSegmentIndex?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openStatusFilter?: InputMaybe<OpenStatusFilter>;
  orderBy?: InputMaybe<SegmentOrderBy>;
  outletLimit?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  showDiscountLabels?: InputMaybe<Scalars['Boolean']['input']>;
  showFeaturedFlags?: InputMaybe<Scalars['Boolean']['input']>;
  showName?: InputMaybe<Scalars['Boolean']['input']>;
  showOnApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditBusinessSegmentOrderArgs = {
  input: Array<IPositionArgs>;
};


export type MutationEditBusinessSignificantDatesArgs = {
  businessId: Scalars['String']['input'];
  significantDates: Scalars['Json']['input'];
};


export type MutationEditCampaignArgs = {
  emailChannel: Scalars['Boolean']['input'];
  emailContent: Array<Scalars['Json']['input']>;
  emailPreviewText?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  push?: InputMaybe<CampaignPushInput>;
  pushChannel: Scalars['Boolean']['input'];
  segment: Scalars['String']['input'];
  sms?: InputMaybe<CampaignSmsInput>;
  smsChannel: Scalars['Boolean']['input'];
  startDateTime?: InputMaybe<Scalars['String']['input']>;
  timeConfig: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationEditCuisineArgs = {
  emoji?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationEditDeliveryNetworkArgs = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiSecret?: InputMaybe<Scalars['String']['input']>;
  apiURL?: InputMaybe<Scalars['String']['input']>;
  deliveryChargeRecipient?: InputMaybe<DeliveryChargeRecipient>;
  deliveryNetworkType?: InputMaybe<DeliveryNetworkType>;
  fixedSubsidy?: InputMaybe<Scalars['Int']['input']>;
  helpPhone?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  namePrefix?: InputMaybe<Scalars['String']['input']>;
  uberDirectClientId?: InputMaybe<Scalars['String']['input']>;
  uberDirectClientSecret?: InputMaybe<Scalars['String']['input']>;
  uberDirectCustomerId?: InputMaybe<Scalars['String']['input']>;
  uberDirectSigningKey?: InputMaybe<Scalars['String']['input']>;
  uberDirectTestScenario?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditDeliveryZoneArgs = {
  geoFence?: InputMaybe<Scalars['Json']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditDiscountArgs = {
  allowedOutletIds?: InputMaybe<Array<Scalars['String']['input']>>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationEditFaqArgs = {
  answer?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditFooterLinksOrderArgs = {
  input: Array<IPositionArgs>;
  marketplaceId: Scalars['String']['input'];
};


export type MutationEditHeaderLinksOrderArgs = {
  input: Array<IPositionArgs>;
  marketplaceId: Scalars['String']['input'];
};


export type MutationEditLinkCardArgs = {
  availabilityEndDate?: InputMaybe<Scalars['String']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['String']['input']>;
  availabilityTimes?: InputMaybe<Scalars['Json']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  cardImage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  showOnApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditLoyaltyCardArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowCashOrders: Scalars['Boolean']['input'];
  allowedOutletIds?: InputMaybe<Array<Scalars['String']['input']>>;
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountId: Scalars['String']['input'];
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  discountStrategy: LoyaltyCardDiscountStrategy;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  loyaltyCardColor?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardIcon?: InputMaybe<Scalars['String']['input']>;
  loyaltyCardId: Scalars['String']['input'];
  minimumSubtotalGross?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredStamps: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditMarketplaceArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestrictionText?: InputMaybe<Scalars['String']['input']>;
  allowBilling?: InputMaybe<Scalars['Boolean']['input']>;
  allowExtendedPreorders?: InputMaybe<Scalars['Boolean']['input']>;
  allowFacebookMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  allowLocationSearch?: InputMaybe<Scalars['Boolean']['input']>;
  allowMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  allowMultiOutletTerminal?: InputMaybe<Scalars['Boolean']['input']>;
  allowOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  allowOrderToTable?: InputMaybe<Scalars['Boolean']['input']>;
  allowOtterIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  allowPendingOrderAlert?: InputMaybe<Scalars['Boolean']['input']>;
  allowReviews?: InputMaybe<Scalars['Boolean']['input']>;
  allowSMSMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  allowSingleItemOrderNotes?: InputMaybe<Scalars['Boolean']['input']>;
  androidVersion?: InputMaybe<Scalars['String']['input']>;
  appImage?: InputMaybe<Scalars['String']['input']>;
  appText?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandImage?: InputMaybe<Scalars['Boolean']['input']>;
  brandSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  cashCollectionText?: InputMaybe<Scalars['String']['input']>;
  cashText?: InputMaybe<Scalars['String']['input']>;
  checkoutText?: InputMaybe<Scalars['String']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  collectionMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  collectionNoteText?: InputMaybe<Scalars['String']['input']>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<CompanyType>;
  confirmAddressAtPayment?: InputMaybe<Scalars['Boolean']['input']>;
  contactAddress?: InputMaybe<AddressCreateInput>;
  contactDoB?: InputMaybe<Scalars['DateTime']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText?: InputMaybe<Scalars['String']['input']>;
  defaultSearch?: InputMaybe<SearchMethod>;
  deliveryMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  deliveryNoteText?: InputMaybe<Scalars['String']['input']>;
  deliveryZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailReceiptOnOrder?: InputMaybe<Scalars['Boolean']['input']>;
  emailSenderUsername?: InputMaybe<Scalars['String']['input']>;
  enableAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  enableCustomerV2Client?: InputMaybe<Scalars['Boolean']['input']>;
  enableLoyalty?: InputMaybe<Scalars['Boolean']['input']>;
  enablePayboxEnterprise?: InputMaybe<Scalars['Boolean']['input']>;
  facebookAdAccountId?: InputMaybe<Scalars['String']['input']>;
  facebookAudienceId?: InputMaybe<Scalars['String']['input']>;
  faviconImage?: InputMaybe<Scalars['String']['input']>;
  featureFAQ?: InputMaybe<Scalars['Boolean']['input']>;
  featureLogin?: InputMaybe<Scalars['Boolean']['input']>;
  featureOrdering?: InputMaybe<Scalars['Boolean']['input']>;
  featurePromotions?: InputMaybe<Scalars['Boolean']['input']>;
  featureRecruit?: InputMaybe<Scalars['Boolean']['input']>;
  featureRegister?: InputMaybe<Scalars['Boolean']['input']>;
  footerLinks?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  ga4Id?: InputMaybe<Scalars['String']['input']>;
  geoFence?: InputMaybe<Scalars['Json']['input']>;
  headerLinks?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  heroImage?: InputMaybe<Scalars['String']['input']>;
  heroText?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  invoiceBcc?: InputMaybe<Scalars['String']['input']>;
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceEmailCustomText?: InputMaybe<Scalars['String']['input']>;
  invoiceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceTextFooter?: InputMaybe<Scalars['String']['input']>;
  iosVersion?: InputMaybe<Scalars['String']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  launchedAt?: InputMaybe<Scalars['DateTime']['input']>;
  legalAllergy?: InputMaybe<Scalars['String']['input']>;
  legalCookies?: InputMaybe<Scalars['String']['input']>;
  legalPolicy?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy?: InputMaybe<Scalars['String']['input']>;
  legalTerms?: InputMaybe<Scalars['String']['input']>;
  logoImage?: InputMaybe<Scalars['String']['input']>;
  merchantCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  merchantComparisonDescription?: InputMaybe<Scalars['String']['input']>;
  merchantComparisonPercentage?: InputMaybe<Scalars['Float']['input']>;
  merchantTableCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  merchantType?: InputMaybe<MerchantType>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  onboardingDescription?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  orderMode?: InputMaybe<OrderingMode>;
  orderNoteText?: InputMaybe<Scalars['String']['input']>;
  outletCollectionVisibilityRadiusKM?: InputMaybe<Scalars['Int']['input']>;
  outletOpenStatus?: InputMaybe<OutletOpenStatus>;
  partnerCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerChargeDescription?: InputMaybe<Scalars['String']['input']>;
  partnerChargeMerchantVat?: InputMaybe<Scalars['Boolean']['input']>;
  partnerCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  partnerTableCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerTableChargeDescription?: InputMaybe<Scalars['String']['input']>;
  partnerTableCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerTableCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerTableFee?: InputMaybe<Scalars['Float']['input']>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  pinpointAwsAccountId?: InputMaybe<Scalars['String']['input']>;
  platformCharge?: InputMaybe<Scalars['Int']['input']>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformTableCharge?: InputMaybe<Scalars['Int']['input']>;
  platformTableFee?: InputMaybe<Scalars['Float']['input']>;
  pollInterval?: InputMaybe<Scalars['Int']['input']>;
  promptForUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideCustomerPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideOutlet?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeDeliveryNotes?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeOrderNotes?: InputMaybe<Scalars['Boolean']['input']>;
  receiptMessage?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram?: InputMaybe<Scalars['String']['input']>;
  socialURLTikTok?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter?: InputMaybe<Scalars['String']['input']>;
  specialInstructionsText?: InputMaybe<Scalars['String']['input']>;
  storeURLApple?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay?: InputMaybe<Scalars['String']['input']>;
  stripeDirectPayment?: InputMaybe<Scalars['Boolean']['input']>;
  stripeOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  tableMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  tableNoteText?: InputMaybe<Scalars['String']['input']>;
  taxReference?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditMarketplaceAppFeaturesArgs = {
  allowMenuItemMainImageV5: Scalars['Boolean']['input'];
  allowMenuItemOptionImageV5: Scalars['Boolean']['input'];
  allowReorder: Scalars['Boolean']['input'];
  allowSearch: Scalars['Boolean']['input'];
  appServiceComponentIcon: Scalars['String']['input'];
  hideCategoriesListOnMobile: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  menuListGroupStyle: MenuListGroupStyle;
  menuListItemStyle: MenuListItemStyle;
  optionsListStyle: OptionsListStyle;
};


export type MutationEditMarketplaceFinancialsArgs = {
  applePayVerification?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationEditMarketplaceKioskFeaturesArgs = {
  id: Scalars['String']['input'];
  kioskAllowSearch?: InputMaybe<Scalars['Boolean']['input']>;
  kioskMenuListItemStyle?: InputMaybe<MenuListItemStyle>;
  kioskOptionsListStyle?: InputMaybe<OptionsListStyle>;
};


export type MutationEditMarketplaceMessageSettingsArgs = {
  emailSenderUsername?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  pinpointAwsAccountId?: InputMaybe<Scalars['String']['input']>;
  pinpointNotificationStrategy?: InputMaybe<PinpointNotificationStrategy>;
  sesMessaging?: InputMaybe<Scalars['String']['input']>;
  smsSenderId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditMarketplaceMessageTemplateArgs = {
  emailContent: Array<Scalars['Json']['input']>;
  emailPreviewText: Scalars['String']['input'];
  emailSubject: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  marketplaceId: Scalars['String']['input'];
  pushBody: Scalars['String']['input'];
  pushTitle: Scalars['String']['input'];
  templateType: Scalars['String']['input'];
};


export type MutationEditMarketplaceWebsiteArgs = {
  allOutletsText?: InputMaybe<Scalars['String']['input']>;
  allowLocationSearch?: InputMaybe<Scalars['Boolean']['input']>;
  appImage?: InputMaybe<Scalars['String']['input']>;
  appText?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  defaultDatepickerOption?: InputMaybe<DefaultDatepickerOption>;
  defaultFulfilmentOption?: InputMaybe<DefaultFulfilmentOption>;
  defaultLocationOption?: InputMaybe<DefaultLocationOption>;
  defaultSearch?: InputMaybe<SearchMethod>;
  emailImageUrl?: InputMaybe<Scalars['String']['input']>;
  enableRedirectFromLandingPage?: InputMaybe<Scalars['Boolean']['input']>;
  facebookAdAccountId?: InputMaybe<Scalars['String']['input']>;
  facebookAudienceId?: InputMaybe<Scalars['String']['input']>;
  facebookPixelId?: InputMaybe<Scalars['String']['input']>;
  faviconImage?: InputMaybe<Scalars['String']['input']>;
  featureFAQ?: InputMaybe<Scalars['Boolean']['input']>;
  featureLogin?: InputMaybe<Scalars['Boolean']['input']>;
  featureOrdering?: InputMaybe<Scalars['Boolean']['input']>;
  featurePromotions?: InputMaybe<Scalars['Boolean']['input']>;
  featureRecruit?: InputMaybe<Scalars['Boolean']['input']>;
  featureRegister?: InputMaybe<Scalars['Boolean']['input']>;
  footerLinks?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  headerLinks?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  heroImage?: InputMaybe<Scalars['String']['input']>;
  heroText?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  logoImage?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  nowFilter?: InputMaybe<OutletNowFilter>;
  socialURLFacebook?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram?: InputMaybe<Scalars['String']['input']>;
  socialURLTikTok?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter?: InputMaybe<Scalars['String']['input']>;
  storeURLApple?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditMenuItemArgs = {
  SKU?: InputMaybe<Scalars['String']['input']>;
  UPC?: InputMaybe<Scalars['String']['input']>;
  VATinclusive?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  bulkyItem?: InputMaybe<Scalars['Boolean']['input']>;
  categoryTag?: InputMaybe<Scalars['String']['input']>;
  costPrice?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<ImageInput>>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isKeto?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  maxPurchaseQuantity?: InputMaybe<Scalars['Int']['input']>;
  menuItemGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  minimumPurchasePrice?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  spiceLevel?: InputMaybe<Scalars['Int']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationEditMenuItemOptionOrderArgs = {
  id: Scalars['String']['input'];
  optionPositions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationEditMenuItemsGroupArgs = {
  addOnItemsMenu?: InputMaybe<Scalars['Boolean']['input']>;
  availabilityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityTimes?: InputMaybe<Scalars['Json']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fulfillmentMethods?: InputMaybe<Array<MenuItemGroupFulfillmentMethod>>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  menuItemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentMenuId?: InputMaybe<Scalars['String']['input']>;
  showMenuThumbnails?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditMenuItemsGroupFulfillmentMethodsArgs = {
  fulfillmentMethods: Array<MenuItemGroupFulfillmentMethod>;
  id: Scalars['String']['input'];
};


export type MutationEditMenuItemsOrderArgs = {
  input?: InputMaybe<Array<InputMaybe<IPositionArgs>>>;
};


export type MutationEditMenusOrderArgs = {
  input?: InputMaybe<Array<InputMaybe<IPositionArgs>>>;
};


export type MutationEditMinAppVersionOverrideArgs = {
  id: Scalars['String']['input'];
  minAppVersionOverride: Scalars['String']['input'];
};


export type MutationEditMinAppVersionSupportedArgs = {
  id: Scalars['String']['input'];
  minAppVersionSupported: Scalars['String']['input'];
};


export type MutationEditMyDetailsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditOptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  maxOptions?: InputMaybe<Scalars['Int']['input']>;
  minOptions?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionItemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parentMenuIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationEditOptionItemArgs = {
  VATinclusive?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  ingredientIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentOption?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  soldOut?: InputMaybe<Scalars['Boolean']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationEditOptionItemsOrderArgs = {
  input?: InputMaybe<Array<InputMaybe<IPositionArgs>>>;
};


export type MutationEditOptionsOrderArgs = {
  input?: InputMaybe<Array<InputMaybe<IPositionArgs>>>;
};


export type MutationEditOutletArgs = {
  allowPreorders?: InputMaybe<Scalars['Boolean']['input']>;
  asapAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  asapCollectionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  asapDeliveryAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  autoCompleteAfter?: InputMaybe<Scalars['Int']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankSort?: InputMaybe<Scalars['String']['input']>;
  closeDate?: InputMaybe<Scalars['String']['input']>;
  closedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  collectionCharge?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<CompanyType>;
  contactAddress?: InputMaybe<AddressCreateInput>;
  contactDoB?: InputMaybe<Scalars['DateTime']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  coverImage?: InputMaybe<Scalars['String']['input']>;
  daysOfferedInAdvanceMax?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMaxCollection?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMaxDelivery?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMinCollection?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMinDelivery?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  emailOrderReceipt?: InputMaybe<Scalars['Boolean']['input']>;
  escalationMobile?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isAutoAccept?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlineOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderToTableEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noPreordersBeforeOpening?: InputMaybe<Scalars['Boolean']['input']>;
  openingTimes?: InputMaybe<Scalars['Json']['input']>;
  otterStoreId?: InputMaybe<Scalars['String']['input']>;
  outletAddress?: InputMaybe<AddressCreateInput>;
  outletEmail?: InputMaybe<Scalars['String']['input']>;
  outletLogoOverride?: InputMaybe<Scalars['String']['input']>;
  outletPhone?: InputMaybe<Scalars['String']['input']>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  preorderCollectionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  preorderDeliveryAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  restaurantId?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  taxReference?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditOutletCategoriesArgs = {
  categories: Array<Scalars['String']['input']>;
  outletId: Scalars['String']['input'];
};


export type MutationEditOutletDeliveryZoneArgs = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  fixedSubsidy?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  pickupNotes?: InputMaybe<Scalars['String']['input']>;
  radiusMiles?: InputMaybe<Scalars['Float']['input']>;
  uberDirectUndeliverableAction?: InputMaybe<UberDirectUndeliverableAction>;
};


export type MutationEditOutletOtterStoreIdArgs = {
  id: Scalars['String']['input'];
  otterStoreId: Scalars['String']['input'];
};


export type MutationEditOutletSpecialOfferFlagsArgs = {
  collectionDeal?: InputMaybe<Scalars['String']['input']>;
  deliveryDeal?: InputMaybe<Scalars['String']['input']>;
  outletId: Scalars['String']['input'];
  tableDeal?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditOutletVatArgs = {
  id: Scalars['String']['input'];
  isVATregistered: Scalars['Boolean']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditPosDeviceArgs = {
  deviceId: Scalars['String']['input'];
  kioskUpdateData?: InputMaybe<KioskUpdateInput>;
  outletIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationEditPartnerArgs = {
  allowSubBrands?: InputMaybe<Scalars['Boolean']['input']>;
  appStoreConnectApiIssuerId?: InputMaybe<Scalars['String']['input']>;
  appStoreConnectApiKeyId?: InputMaybe<Scalars['String']['input']>;
  appStoreConnectApiKeyPath?: InputMaybe<Scalars['String']['input']>;
  appleTeamIdDefault?: InputMaybe<Scalars['String']['input']>;
  billingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  billingProductAPIId?: InputMaybe<Scalars['String']['input']>;
  billingProductChargebackId?: InputMaybe<Scalars['String']['input']>;
  billingProductMarketingId?: InputMaybe<Scalars['String']['input']>;
  billingProductOutletId?: InputMaybe<Scalars['String']['input']>;
  billingProductSMSId?: InputMaybe<Scalars['String']['input']>;
  billingProductWayboxId?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandImage?: InputMaybe<Scalars['Boolean']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<AddressUpdateDataInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  googleJsonS3KeyPathDefault?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  marketingSMSFee?: InputMaybe<Scalars['Float']['input']>;
  minAppVersionOverride?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerTableCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerTableCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  platformCharge?: InputMaybe<Scalars['Int']['input']>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformTableCharge?: InputMaybe<Scalars['Int']['input']>;
  platformTableFee?: InputMaybe<Scalars['Float']['input']>;
  stripeCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeChargebackCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeClientId?: InputMaybe<Scalars['String']['input']>;
  stripeConnectCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeDebitFee?: InputMaybe<Scalars['Float']['input']>;
  stripeEnterpriseAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  stripeFee?: InputMaybe<Scalars['Float']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeInstantPayoutFee?: InputMaybe<Scalars['Float']['input']>;
  stripePayoutCharge?: InputMaybe<Scalars['Int']['input']>;
  stripePayoutFee?: InputMaybe<Scalars['Float']['input']>;
  stripePresentCharge?: InputMaybe<Scalars['Int']['input']>;
  stripePresentFee?: InputMaybe<Scalars['Float']['input']>;
  stripePublicId?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportTitle?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditPartnerPaymentsArgs = {
  id: Scalars['ID']['input'];
  stripeEnterpriseAccessId?: InputMaybe<Scalars['String']['input']>;
  stripeEnterpriseId?: InputMaybe<Scalars['String']['input']>;
  stripeEnterprisePayoutDay?: InputMaybe<Scalars['String']['input']>;
  stripeEnterprisePublicId?: InputMaybe<Scalars['String']['input']>;
  stripeShowDashboardMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  stripeShowDashboardOutlet?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditPermissionArgs = {
  id: Scalars['String']['input'];
  mutations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  queries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditPlatformArgs = {
  contactAddress?: InputMaybe<AddressCreateInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orderHoldIntervalOverride?: InputMaybe<Scalars['Int']['input']>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payboxAccessId?: InputMaybe<Scalars['String']['input']>;
  payboxClientId?: InputMaybe<Scalars['String']['input']>;
  payboxId?: InputMaybe<Scalars['String']['input']>;
  payboxPublicId?: InputMaybe<Scalars['String']['input']>;
  platformCurrency?: InputMaybe<Scalars['Json']['input']>;
  platformVat?: InputMaybe<Scalars['Float']['input']>;
  stripeAccessId?: InputMaybe<Scalars['String']['input']>;
  stripeCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeChargebackCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeClientId?: InputMaybe<Scalars['String']['input']>;
  stripeConnectCharge?: InputMaybe<Scalars['Int']['input']>;
  stripeDebitFee?: InputMaybe<Scalars['Float']['input']>;
  stripeFee?: InputMaybe<Scalars['Float']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeInstantPayoutFee?: InputMaybe<Scalars['Float']['input']>;
  stripePayoutCharge?: InputMaybe<Scalars['Int']['input']>;
  stripePayoutFee?: InputMaybe<Scalars['Float']['input']>;
  stripePresentCharge?: InputMaybe<Scalars['Int']['input']>;
  stripePresentFee?: InputMaybe<Scalars['Float']['input']>;
  stripePublicId?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditPlatformIntegrationsArgs = {
  id: Scalars['String']['input'];
  otterClientId?: InputMaybe<Scalars['String']['input']>;
  otterClientSecret?: InputMaybe<Scalars['String']['input']>;
  otterWebhookSecret?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditRestaurantArgs = {
  acceptPreorders?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowAddOnItems?: InputMaybe<Scalars['Boolean']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cname?: InputMaybe<Scalars['String']['input']>;
  collectionDeal?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<AddressUpdateInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  deliveryDeal?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayAllOutlets?: InputMaybe<Scalars['Boolean']['input']>;
  enableAllergyInformation?: InputMaybe<Scalars['Boolean']['input']>;
  enablePaginatedMenu?: InputMaybe<Scalars['Boolean']['input']>;
  enableStackedMenu?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  marketplace?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  outletCategoriesOverride?: InputMaybe<Scalars['Boolean']['input']>;
  outletOfferFlagOverride?: InputMaybe<Scalars['Boolean']['input']>;
  outletPromoteOverride?: InputMaybe<Scalars['Boolean']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  statusOnboarding?: InputMaybe<Scalars['String']['input']>;
  tableDeal?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditRestaurantFinancialsArgs = {
  allowDefaultMinimumPurchasePercentage?: InputMaybe<Scalars['Boolean']['input']>;
  defaultMinimumPurchasePricePercentage?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  merchantCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  merchantTableCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  minimumBasketTotalForAddOns?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerTableCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerTableFee?: InputMaybe<Scalars['Float']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditRestaurantVatArgs = {
  id: Scalars['String']['input'];
  isVATregistered: Scalars['Boolean']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditRoleArgs = {
  id: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditSegmentArgs = {
  filterGroups: Array<FilterGroupInput>;
  filterGroupsType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditTableActivationArgs = {
  isActive: Scalars['Boolean']['input'];
  tableId: Scalars['String']['input'];
};


export type MutationEditTableFriendlyNameArgs = {
  friendlyName: Scalars['String']['input'];
  tableId: Scalars['String']['input'];
};


export type MutationEditTerminalArgs = {
  alarm?: InputMaybe<Alarm>;
  editableMessages?: InputMaybe<Scalars['Boolean']['input']>;
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  outletIds?: InputMaybe<Array<Scalars['String']['input']>>;
  receiptGroupItems?: InputMaybe<Scalars['Boolean']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  slave?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditTerminalPinArgs = {
  id: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};


export type MutationEditUserArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownsMarketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsOutletIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsPartnerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsPlatformIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsRestaurantIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleKey?: InputMaybe<Scalars['String']['input']>;
  roleTitle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditWebhookArgs = {
  endpoint?: InputMaybe<Scalars['String']['input']>;
  headerKey?: InputMaybe<Scalars['String']['input']>;
  headerValue?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  webhookType?: InputMaybe<Array<WebhookType>>;
};


export type MutationEnrollMemberToDiscountArgs = {
  customerEmail: Scalars['String']['input'];
  customerFallbackName?: InputMaybe<Scalars['String']['input']>;
  discountName: Scalars['String']['input'];
  discountPercentage: Scalars['Float']['input'];
  restaurantId: Scalars['String']['input'];
};


export type MutationFeatureOrPromoteOutletArgs = {
  feature?: InputMaybe<Scalars['Boolean']['input']>;
  outletId: Scalars['String']['input'];
  promote?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationForceCustomerLogoutArgs = {
  customerId: Scalars['ID']['input'];
};


export type MutationImportManyCustomersArgs = {
  customerEmailAddresses: Array<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  ownMarketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsMarketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsOutletIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsPartnerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsPlatformIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownsRestaurantIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleKey: Scalars['String']['input'];
};


export type MutationLoginUserArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMuteTerminalArgs = {
  id: Scalars['String']['input'];
  mutedUntil?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationOnboardingApplyArgs = {
  contactAddress: AddressCreateInput;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  marketplaceKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationOnboardingApproveArgs = {
  id: Scalars['String']['input'];
};


export type MutationOnboardingDenyArgs = {
  denyReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationOutletMenuItemSoldOutStatusArgs = {
  areHidden: Scalars['Boolean']['input'];
  areSoldOut: Scalars['Boolean']['input'];
  menuItemIds: Array<Scalars['String']['input']>;
  outletId: Scalars['String']['input'];
  soldOutUntil?: InputMaybe<Array<InputMaybe<SoldOutUntil>>>;
};


export type MutationQueueInvoiceEmailsArgs = {
  dateFrom_gte?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo_lte?: InputMaybe<Scalars['DateTime']['input']>;
  documentKey?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReadyNetworkOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationRefundOrderArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  reason: StripeRefundReasons;
};


export type MutationRegenerateStripeCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type MutationRegisterArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirm: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationReinviteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationRelocateAddressArgs = {
  addressId: Scalars['String']['input'];
};


export type MutationRemoveReviewArgs = {
  id: Scalars['String']['input'];
};


export type MutationReplyToReviewArgs = {
  id: Scalars['String']['input'];
  reply: Scalars['String']['input'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetKioskPinArgs = {
  kioskId: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendMessageReplyArgs = {
  addMarketplaceTheme?: InputMaybe<Scalars['Boolean']['input']>;
  content: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationSendMessageToOutletsArgs = {
  addMarketplaceTheme?: InputMaybe<Scalars['Boolean']['input']>;
  content: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  messageId?: InputMaybe<Scalars['String']['input']>;
  restaurantIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subject: Scalars['String']['input'];
};


export type MutationSendTestNotificationArgs = {
  customerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<ITestNotification>;
  emailAddresses: Array<Scalars['String']['input']>;
  marketplaceId: Scalars['String']['input'];
  push?: InputMaybe<ITestNotification>;
};


export type MutationSetMarketplaceCountryArgs = {
  ISO3166Alpha2Code: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type MutationSetMarketplaceFacebookArgs = {
  facebookAdAccountId?: InputMaybe<Scalars['String']['input']>;
  facebookAdAccountName?: InputMaybe<Scalars['String']['input']>;
  facebookAudienceId?: InputMaybe<Scalars['String']['input']>;
  facebookAudienceName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetPartnerPaymentWebhooksArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetPlatformStripeWebhooksArgs = {
  paybox: Scalars['Boolean']['input'];
};


export type MutationSuspendLoyaltyCardArgs = {
  loyaltyCardId: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  suspended: Scalars['Boolean']['input'];
};


export type MutationTestPlatformIntegrationsArgs = {
  storeId: Scalars['String']['input'];
};


export type MutationToggleDiscountActivationArgs = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationToggleHygieneRatingArgs = {
  enableHygieneRating: Scalars['Boolean']['input'];
  hygieneRatingId?: InputMaybe<Scalars['Int']['input']>;
  outletId: Scalars['String']['input'];
};


export type MutationToggleMarketplaceStatusArgs = {
  id: Scalars['String']['input'];
  setDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationToggleOutletActivationArgs = {
  active: Scalars['Boolean']['input'];
  outletId: Scalars['ID']['input'];
};


export type MutationTwoFactorCheckArgs = {
  code: Scalars['String']['input'];
};


export type MutationUpdateAndSynchroniseEmailThemeArgs = {
  marketplaceId: Scalars['String']['input'];
  theme: EmailTheme;
};


export type MutationUpdateDeliveryWindowsLimitArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateLoyaltyCardActiveStateArgs = {
  active: Scalars['Boolean']['input'];
  loyaltyCardId: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type MutationUpdateMyPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationUpdateOrderStatusArgs = {
  cancellationNotes?: InputMaybe<Scalars['String']['input']>;
  estimatedCompletionTime?: InputMaybe<Scalars['String']['input']>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  orderStatus?: InputMaybe<OrderStatus>;
};


export type MutationUpdateVouchersActiveStateArgs = {
  active: Scalars['Boolean']['input'];
  voucherIds: Array<Scalars['String']['input']>;
};


export type MutationUploadImagesArgs = {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationVoidInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
};

export enum MutationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export type NewUsersDataRow = {
  __typename?: 'NewUsersDataRow';
  date: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum NotificationType {
  Raw = 'RAW',
  Template = 'TEMPLATE'
}

export type Nutrition = {
  __typename?: 'Nutrition';
  calories?: Maybe<Scalars['Int']['output']>;
  carbohydrates?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Nutrition_Ref>;
  fat?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  protein?: Maybe<Scalars['Float']['output']>;
  sugar?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NutritionWhereInput = {
  AND?: InputMaybe<Array<NutritionWhereInput>>;
  NOT?: InputMaybe<Array<NutritionWhereInput>>;
  OR?: InputMaybe<Array<NutritionWhereInput>>;
  calories?: InputMaybe<Scalars['Int']['input']>;
  calories_gt?: InputMaybe<Scalars['Int']['input']>;
  calories_gte?: InputMaybe<Scalars['Int']['input']>;
  calories_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  calories_lt?: InputMaybe<Scalars['Int']['input']>;
  calories_lte?: InputMaybe<Scalars['Int']['input']>;
  calories_not?: InputMaybe<Scalars['Int']['input']>;
  calories_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  carbohydrates?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_gt?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_gte?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  carbohydrates_lt?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_lte?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_not?: InputMaybe<Scalars['Float']['input']>;
  carbohydrates_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  description?: InputMaybe<Nutrition_Ref>;
  description_in?: InputMaybe<Array<Nutrition_Ref>>;
  description_not?: InputMaybe<Nutrition_Ref>;
  description_not_in?: InputMaybe<Array<Nutrition_Ref>>;
  fat?: InputMaybe<Scalars['Float']['input']>;
  fat_gt?: InputMaybe<Scalars['Float']['input']>;
  fat_gte?: InputMaybe<Scalars['Float']['input']>;
  fat_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  fat_lt?: InputMaybe<Scalars['Float']['input']>;
  fat_lte?: InputMaybe<Scalars['Float']['input']>;
  fat_not?: InputMaybe<Scalars['Float']['input']>;
  fat_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['Float']['input']>;
  protein_gt?: InputMaybe<Scalars['Float']['input']>;
  protein_gte?: InputMaybe<Scalars['Float']['input']>;
  protein_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  protein_lt?: InputMaybe<Scalars['Float']['input']>;
  protein_lte?: InputMaybe<Scalars['Float']['input']>;
  protein_not?: InputMaybe<Scalars['Float']['input']>;
  protein_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  sugar?: InputMaybe<Scalars['Float']['input']>;
  sugar_gt?: InputMaybe<Scalars['Float']['input']>;
  sugar_gte?: InputMaybe<Scalars['Float']['input']>;
  sugar_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  sugar_lt?: InputMaybe<Scalars['Float']['input']>;
  sugar_lte?: InputMaybe<Scalars['Float']['input']>;
  sugar_not?: InputMaybe<Scalars['Float']['input']>;
  sugar_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum Nutrition_Ref {
  Per_100G = 'PER_100G',
  PerPortion = 'PER_PORTION',
  PerServing = 'PER_SERVING',
  PerSlice = 'PER_SLICE'
}

export type OnboardingApplyResponse = {
  __typename?: 'OnboardingApplyResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type OnboardingInfoResponse = {
  __typename?: 'OnboardingInfoResponse';
  cname?: Maybe<Scalars['String']['output']>;
  enableCustomerV2Client: Scalars['Boolean']['output'];
  heroImage?: Maybe<Scalars['String']['output']>;
  logoImage?: Maybe<Scalars['String']['output']>;
  onboardingDescription?: Maybe<Scalars['String']['output']>;
};

export enum OpenStatusFilter {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type OperationalArea = {
  __typename?: 'OperationalArea';
  city: OperationalCity;
  createdAt: Scalars['DateTime']['output'];
  /**
   *  geoJSON is FeatureCollections-by-ZoneCoverageType (see @turf/helpers)
   * {
   *   picking: {
   *     "type": "FeatureCollection",
   *     "features": [
   *       ...
   *     ]
   *   },
   *   delivering: {
   *     "type": "FeatureCollection",
   *     "features": [
   *       ...
   *     ]
   *   }
   * }
   */
  geoJSON: Scalars['Json']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum OperationalCity {
  Basildon = 'BASILDON',
  Birmingham = 'BIRMINGHAM',
  Blackpool = 'BLACKPOOL',
  Bournemouth = 'BOURNEMOUTH',
  Brighton = 'BRIGHTON',
  Bristol = 'BRISTOL',
  Cambridge = 'CAMBRIDGE',
  Cardiff = 'CARDIFF',
  Darlington = 'DARLINGTON',
  Derby = 'DERBY',
  Exeter = 'EXETER',
  Gillingham = 'GILLINGHAM',
  Grimsby = 'GRIMSBY',
  Huddersfield = 'HUDDERSFIELD',
  Hull = 'HULL',
  Leeds = 'LEEDS',
  Leicester = 'LEICESTER',
  Liverpool = 'LIVERPOOL',
  London = 'LONDON',
  Manchester = 'MANCHESTER',
  MiltonKeynes = 'MILTON_KEYNES',
  NewcastleGateshead = 'NEWCASTLE_GATESHEAD',
  Northampton = 'NORTHAMPTON',
  Nottingham = 'NOTTINGHAM',
  Oxford = 'OXFORD',
  Plymouth = 'PLYMOUTH',
  Reading = 'READING',
  Sheffield = 'SHEFFIELD',
  Southampton = 'SOUTHAMPTON',
  SouthShields = 'SOUTH_SHIELDS',
  Sunderland = 'SUNDERLAND',
  Swansea = 'SWANSEA',
  Teesside = 'TEESSIDE',
  Wakefield = 'WAKEFIELD',
  Warrington = 'WARRINGTON'
}

export type Option = {
  __typename?: 'Option';
  archived?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  maxOptions: Scalars['Int']['output'];
  minOptions: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  optionItems?: Maybe<Array<OptionItem>>;
  outlet?: Maybe<Outlet>;
  parentMenu?: Maybe<Array<MenuItem>>;
  position?: Maybe<Scalars['Int']['output']>;
  restaurant?: Maybe<Restaurant>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OptionItem = {
  __typename?: 'OptionItem';
  VATinclusive?: Maybe<Scalars['Boolean']['output']>;
  ageRestricted?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageImplementer?: Maybe<ImageImplementer>;
  ingredients?: Maybe<Array<Ingredient>>;
  isDairyFree?: Maybe<Scalars['Boolean']['output']>;
  isGlutenFree?: Maybe<Scalars['Boolean']['output']>;
  isKeto?: Maybe<Scalars['Boolean']['output']>;
  isVegan?: Maybe<Scalars['Boolean']['output']>;
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nutrition?: Maybe<Nutrition>;
  outlet?: Maybe<Outlet>;
  parentOption?: Maybe<Option>;
  position?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  restaurant?: Maybe<Restaurant>;
  soldOut?: Maybe<Scalars['Boolean']['output']>;
  spiceLevel?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};


export type OptionItemIngredientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IngredientOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IngredientWhereInput>;
};

export type OptionItemInput = {
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  soldOut: Scalars['Boolean']['input'];
};

export enum OptionItemOrderByInput {
  VaTinclusiveAsc = 'VATinclusive_ASC',
  VaTinclusiveDesc = 'VATinclusive_DESC',
  AgeRestrictedAsc = 'ageRestricted_ASC',
  AgeRestrictedDesc = 'ageRestricted_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HiddenAsc = 'hidden_ASC',
  HiddenDesc = 'hidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsDairyFreeAsc = 'isDairyFree_ASC',
  IsDairyFreeDesc = 'isDairyFree_DESC',
  IsGlutenFreeAsc = 'isGlutenFree_ASC',
  IsGlutenFreeDesc = 'isGlutenFree_DESC',
  IsVeganAsc = 'isVegan_ASC',
  IsVeganDesc = 'isVegan_DESC',
  IsVegetarianAsc = 'isVegetarian_ASC',
  IsVegetarianDesc = 'isVegetarian_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SoldOutAsc = 'soldOut_ASC',
  SoldOutDesc = 'soldOut_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatRateAsc = 'vatRate_ASC',
  VatRateDesc = 'vatRate_DESC'
}

export type OptionItemResponse = {
  __typename?: 'OptionItemResponse';
  message?: Maybe<Scalars['String']['output']>;
  optionItem?: Maybe<OptionItem>;
};

export type OptionItemWhereInput = {
  AND?: InputMaybe<Array<OptionItemWhereInput>>;
  NOT?: InputMaybe<Array<OptionItemWhereInput>>;
  OR?: InputMaybe<Array<OptionItemWhereInput>>;
  VATinclusive?: InputMaybe<Scalars['Boolean']['input']>;
  VATinclusive_not?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestricted_not?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  ingredients_every?: InputMaybe<IngredientWhereInput>;
  ingredients_none?: InputMaybe<IngredientWhereInput>;
  ingredients_some?: InputMaybe<IngredientWhereInput>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isDairyFree_not?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionWhereInput>;
  outlet?: InputMaybe<OutletWhereInput>;
  parentOption?: InputMaybe<OptionWhereInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  position_not?: InputMaybe<Scalars['Int']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  price_gt?: InputMaybe<Scalars['Int']['input']>;
  price_gte?: InputMaybe<Scalars['Int']['input']>;
  price_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  price_lt?: InputMaybe<Scalars['Int']['input']>;
  price_lte?: InputMaybe<Scalars['Int']['input']>;
  price_not?: InputMaybe<Scalars['Int']['input']>;
  price_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  soldOut?: InputMaybe<Scalars['Boolean']['input']>;
  soldOut_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  vatRate_gt?: InputMaybe<Scalars['Float']['input']>;
  vatRate_gte?: InputMaybe<Scalars['Float']['input']>;
  vatRate_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  vatRate_lt?: InputMaybe<Scalars['Float']['input']>;
  vatRate_lte?: InputMaybe<Scalars['Float']['input']>;
  vatRate_not?: InputMaybe<Scalars['Float']['input']>;
  vatRate_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export enum OptionOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HiddenAsc = 'hidden_ASC',
  HiddenDesc = 'hidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  MaxOptionsAsc = 'maxOptions_ASC',
  MaxOptionsDesc = 'maxOptions_DESC',
  MinOptionsAsc = 'minOptions_ASC',
  MinOptionsDesc = 'minOptions_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OptionResponse = {
  __typename?: 'OptionResponse';
  message?: Maybe<Scalars['String']['output']>;
  option?: Maybe<Option>;
};

export type OptionWhereInput = {
  AND?: InputMaybe<Array<OptionWhereInput>>;
  NOT?: InputMaybe<Array<OptionWhereInput>>;
  OR?: InputMaybe<Array<OptionWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  maxOptions?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_gt?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_gte?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  maxOptions_lt?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_lte?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_not?: InputMaybe<Scalars['Int']['input']>;
  maxOptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minOptions?: InputMaybe<Scalars['Int']['input']>;
  minOptions_gt?: InputMaybe<Scalars['Int']['input']>;
  minOptions_gte?: InputMaybe<Scalars['Int']['input']>;
  minOptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  minOptions_lt?: InputMaybe<Scalars['Int']['input']>;
  minOptions_lte?: InputMaybe<Scalars['Int']['input']>;
  minOptions_not?: InputMaybe<Scalars['Int']['input']>;
  minOptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  optionItems_every?: InputMaybe<OptionItemWhereInput>;
  optionItems_none?: InputMaybe<OptionItemWhereInput>;
  optionItems_some?: InputMaybe<OptionItemWhereInput>;
  outlet?: InputMaybe<OutletWhereInput>;
  parentMenu_every?: InputMaybe<MenuItemWhereInput>;
  parentMenu_none?: InputMaybe<MenuItemWhereInput>;
  parentMenu_some?: InputMaybe<MenuItemWhereInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  position_not?: InputMaybe<Scalars['Int']['input']>;
  position_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum OptionsListStyle {
  List = 'LIST',
  Wizard = 'WIZARD',
  WizardImage = 'WIZARD_IMAGE'
}

export type Order = {
  __typename?: 'Order';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  active: Scalars['Boolean']['output'];
  addOnItemsGross?: Maybe<Scalars['Int']['output']>;
  addOnItemsNet?: Maybe<Scalars['Int']['output']>;
  asap?: Maybe<Scalars['Boolean']['output']>;
  autoAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  autoRejectAt?: Maybe<Scalars['DateTime']['output']>;
  cancellationNotes?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerBillingAddress?: Maybe<Scalars['Json']['output']>;
  customerDeliveryAddress?: Maybe<Scalars['Json']['output']>;
  customerDeliveryNotes?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerOrderNotes?: Maybe<Scalars['String']['output']>;
  customerTaxes: Scalars['Int']['output'];
  deliveredDate?: Maybe<Scalars['DateTime']['output']>;
  deliveryNetworkBookingId?: Maybe<Scalars['String']['output']>;
  deliveryNetworkBookingStatus?: Maybe<DeliveryNetworkBookingStatus>;
  deliveryNetworkDashboardURL?: Maybe<Scalars['String']['output']>;
  deliveryNetworkDriverName?: Maybe<Scalars['String']['output']>;
  deliveryNetworkEstimateSnapshot?: Maybe<Scalars['Json']['output']>;
  deliveryNetworkJobTrackerURL?: Maybe<Scalars['String']['output']>;
  deliveryNetworkKey?: Maybe<Scalars['String']['output']>;
  deliveryNetworkPickupEta?: Maybe<Scalars['DateTime']['output']>;
  deliveryNetworkProvider?: Maybe<DeliveryProvidersEnum>;
  deliveryNetworkTripResponses: Array<Scalars['Json']['output']>;
  deliveryNotes?: Maybe<Scalars['String']['output']>;
  deliveryZone?: Maybe<DeliveryZone>;
  discount?: Maybe<Scalars['Json']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  estimatedCompletionTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  expiryNotificationSent?: Maybe<Scalars['Boolean']['output']>;
  fulfillmentCharge?: Maybe<Scalars['Int']['output']>;
  fulfillmentChargeNet?: Maybe<Scalars['Int']['output']>;
  fulfillmentMethod: OrderFulfillmentMethods;
  grossTotal: Scalars['Int']['output'];
  hasSentPendingOrderAlert?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAutoRejected?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  loyaltyCardInfo: Array<OrderLoyaltyCardInfo>;
  marketplaceId?: Maybe<Scalars['String']['output']>;
  merchantCommissionCharge?: Maybe<Scalars['Int']['output']>;
  netTotal: Scalars['Int']['output'];
  orderItems: Array<OrderItem>;
  orderNumber: Scalars['String']['output'];
  orderStatus?: Maybe<OrderStatus>;
  outlet?: Maybe<Outlet>;
  outletId?: Maybe<Scalars['String']['output']>;
  outletNotes?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  partnerCharge?: Maybe<Scalars['Int']['output']>;
  partnerCommissionCharge?: Maybe<Scalars['Int']['output']>;
  partnerCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerFee?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['String']['output']>;
  paymentMethod: PaymentMethod;
  platformCharge?: Maybe<Scalars['Int']['output']>;
  platformFee?: Maybe<Scalars['Float']['output']>;
  platformId?: Maybe<Scalars['String']['output']>;
  platformNotes?: Maybe<Scalars['String']['output']>;
  restaurantId?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Review>;
  selectedDeliverySlot?: Maybe<Scalars['DateTime']['output']>;
  selectedDeliveryWindow?: Maybe<Scalars['Json']['output']>;
  stripeCharge?: Maybe<Scalars['Json']['output']>;
  subtotalGross?: Maybe<Scalars['Int']['output']>;
  subtotalNet?: Maybe<Scalars['Int']['output']>;
  tableId?: Maybe<Scalars['String']['output']>;
  tableSnapshot?: Maybe<Scalars['Json']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userAgent?: Maybe<Scalars['Json']['output']>;
  voucherKey?: Maybe<Scalars['String']['output']>;
};

export type OrderByBusinessForAnalyticsResponseByDiscount = {
  __typename?: 'OrderByBusinessForAnalyticsResponseByDiscount';
  customerId: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountId: Scalars['String']['output'];
  fulfillmentMethod: OrderFulfillmentMethods;
  outletId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  subtotalGross: Scalars['Int']['output'];
};

export type OrderByBusinessForAnalyticsWithDateResponse = {
  __typename?: 'OrderByBusinessForAnalyticsWithDateResponse';
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountJson?: Maybe<Scalars['Json']['output']>;
  fulfillmentMethod: OrderFulfillmentMethods;
  orderStatus: OrderStatus;
  outletId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  subtotalGross: Scalars['Int']['output'];
  userAgent?: Maybe<Scalars['Json']['output']>;
  voucherKey?: Maybe<Scalars['String']['output']>;
};

export type OrderByCustomerForAnalyticsResponse = {
  __typename?: 'OrderByCustomerForAnalyticsResponse';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  discountAmount: Scalars['Int']['output'];
  discountJson?: Maybe<Scalars['Json']['output']>;
  fulfillmentMethod: OrderFulfillmentMethods;
  orderStatus: OrderStatus;
  outletId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  subtotalGross: Scalars['Int']['output'];
  userAgent?: Maybe<Scalars['Json']['output']>;
  voucherKey?: Maybe<Scalars['String']['output']>;
};

export enum OrderFulfillmentMethods {
  Collection = 'COLLECTION',
  Courier = 'COURIER',
  Delivery = 'DELIVERY',
  Network = 'NETWORK',
  Table = 'TABLE'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt: Scalars['DateTime']['output'];
  customerLabel?: Maybe<Scalars['String']['output']>;
  grossTotal?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isAddOnItem?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  menuItem?: Maybe<Scalars['Json']['output']>;
  netTotal?: Maybe<Scalars['Int']['output']>;
  optionItems?: Maybe<Scalars['Json']['output']>;
  order?: Maybe<Order>;
  singleItemNotes?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum OrderItemOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerLabelAsc = 'customerLabel_ASC',
  CustomerLabelDesc = 'customerLabel_DESC',
  GrossTotalAsc = 'grossTotal_ASC',
  GrossTotalDesc = 'grossTotal_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  MenuItemAsc = 'menuItem_ASC',
  MenuItemDesc = 'menuItem_DESC',
  NetTotalAsc = 'netTotal_ASC',
  NetTotalDesc = 'netTotal_DESC',
  OptionItemsAsc = 'optionItems_ASC',
  OptionItemsDesc = 'optionItems_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OrderItemWhereInput = {
  AND?: InputMaybe<Array<OrderItemWhereInput>>;
  NOT?: InputMaybe<Array<OrderItemWhereInput>>;
  OR?: InputMaybe<Array<OrderItemWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customerLabel?: InputMaybe<Scalars['String']['input']>;
  customerLabel_contains?: InputMaybe<Scalars['String']['input']>;
  customerLabel_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerLabel_gt?: InputMaybe<Scalars['String']['input']>;
  customerLabel_gte?: InputMaybe<Scalars['String']['input']>;
  customerLabel_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerLabel_lt?: InputMaybe<Scalars['String']['input']>;
  customerLabel_lte?: InputMaybe<Scalars['String']['input']>;
  customerLabel_not?: InputMaybe<Scalars['String']['input']>;
  customerLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  customerLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerLabel_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerLabel_starts_with?: InputMaybe<Scalars['String']['input']>;
  grossTotal?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  grossTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_not?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  netTotal?: InputMaybe<Scalars['Int']['input']>;
  netTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  netTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  netTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  netTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  netTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  netTotal_not?: InputMaybe<Scalars['Int']['input']>;
  netTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  order?: InputMaybe<OrderWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OrderLoyaltyCardInfo = {
  __typename?: 'OrderLoyaltyCardInfo';
  cardHasBeenClaimed?: Maybe<Scalars['Boolean']['output']>;
  discountId?: Maybe<Scalars['String']['output']>;
  loyaltyCardName?: Maybe<Scalars['String']['output']>;
  rewardValue?: Maybe<Scalars['Int']['output']>;
};

export enum OrderOrderByInput {
  AcceptedAtAsc = 'acceptedAt_ASC',
  AcceptedAtDesc = 'acceptedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  AsapAsc = 'asap_ASC',
  AsapDesc = 'asap_DESC',
  CancellationNotesAsc = 'cancellationNotes_ASC',
  CancellationNotesDesc = 'cancellationNotes_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerBillingAddressAsc = 'customerBillingAddress_ASC',
  CustomerBillingAddressDesc = 'customerBillingAddress_DESC',
  CustomerDeliveryAddressAsc = 'customerDeliveryAddress_ASC',
  CustomerDeliveryAddressDesc = 'customerDeliveryAddress_DESC',
  CustomerDeliveryNotesAsc = 'customerDeliveryNotes_ASC',
  CustomerDeliveryNotesDesc = 'customerDeliveryNotes_DESC',
  CustomerIdAsc = 'customerId_ASC',
  CustomerIdDesc = 'customerId_DESC',
  CustomerOrderNotesAsc = 'customerOrderNotes_ASC',
  CustomerOrderNotesDesc = 'customerOrderNotes_DESC',
  DeliveredDateAsc = 'deliveredDate_ASC',
  DeliveredDateDesc = 'deliveredDate_DESC',
  DeliveryNetworkBookingIdAsc = 'deliveryNetworkBookingId_ASC',
  DeliveryNetworkBookingIdDesc = 'deliveryNetworkBookingId_DESC',
  DeliveryNetworkBookingStatusAsc = 'deliveryNetworkBookingStatus_ASC',
  DeliveryNetworkBookingStatusDesc = 'deliveryNetworkBookingStatus_DESC',
  DeliveryNetworkDriverNameAsc = 'deliveryNetworkDriverName_ASC',
  DeliveryNetworkDriverNameDesc = 'deliveryNetworkDriverName_DESC',
  DeliveryNetworkKeyAsc = 'deliveryNetworkKey_ASC',
  DeliveryNetworkKeyDesc = 'deliveryNetworkKey_DESC',
  DeliveryNotesAsc = 'deliveryNotes_ASC',
  DeliveryNotesDesc = 'deliveryNotes_DESC',
  DiscountAmountAsc = 'discountAmount_ASC',
  DiscountAmountDesc = 'discountAmount_DESC',
  DiscountAsc = 'discount_ASC',
  DiscountDesc = 'discount_DESC',
  EstimatedCompletionTimeAsc = 'estimatedCompletionTime_ASC',
  EstimatedCompletionTimeDesc = 'estimatedCompletionTime_DESC',
  EstimatedDeliveryDateAsc = 'estimatedDeliveryDate_ASC',
  EstimatedDeliveryDateDesc = 'estimatedDeliveryDate_DESC',
  FulfillmentChargeNetAsc = 'fulfillmentChargeNet_ASC',
  FulfillmentChargeNetDesc = 'fulfillmentChargeNet_DESC',
  FulfillmentChargeAsc = 'fulfillmentCharge_ASC',
  FulfillmentChargeDesc = 'fulfillmentCharge_DESC',
  FulfillmentMethodAsc = 'fulfillmentMethod_ASC',
  FulfillmentMethodDesc = 'fulfillmentMethod_DESC',
  GrossTotalAsc = 'grossTotal_ASC',
  GrossTotalDesc = 'grossTotal_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  MarketplaceIdAsc = 'marketplaceId_ASC',
  MarketplaceIdDesc = 'marketplaceId_DESC',
  NetTotalAsc = 'netTotal_ASC',
  NetTotalDesc = 'netTotal_DESC',
  OrderNumberAsc = 'orderNumber_ASC',
  OrderNumberDesc = 'orderNumber_DESC',
  OrderStatusAsc = 'orderStatus_ASC',
  OrderStatusDesc = 'orderStatus_DESC',
  OutletIdAsc = 'outletId_ASC',
  OutletIdDesc = 'outletId_DESC',
  OutletNotesAsc = 'outletNotes_ASC',
  OutletNotesDesc = 'outletNotes_DESC',
  PaidAsc = 'paid_ASC',
  PaidDesc = 'paid_DESC',
  PartnerChargeAsc = 'partnerCharge_ASC',
  PartnerChargeDesc = 'partnerCharge_DESC',
  PartnerCommissionFeeAsc = 'partnerCommissionFee_ASC',
  PartnerCommissionFeeDesc = 'partnerCommissionFee_DESC',
  PartnerFeeAsc = 'partnerFee_ASC',
  PartnerFeeDesc = 'partnerFee_DESC',
  PartnerIdAsc = 'partnerId_ASC',
  PartnerIdDesc = 'partnerId_DESC',
  PaymentMethodAsc = 'paymentMethod_ASC',
  PaymentMethodDesc = 'paymentMethod_DESC',
  PlatformChargeAsc = 'platformCharge_ASC',
  PlatformChargeDesc = 'platformCharge_DESC',
  PlatformFeeAsc = 'platformFee_ASC',
  PlatformFeeDesc = 'platformFee_DESC',
  PlatformIdAsc = 'platformId_ASC',
  PlatformIdDesc = 'platformId_DESC',
  PlatformNotesAsc = 'platformNotes_ASC',
  PlatformNotesDesc = 'platformNotes_DESC',
  RestaurantIdAsc = 'restaurantId_ASC',
  RestaurantIdDesc = 'restaurantId_DESC',
  SelectedDeliverySlotAsc = 'selectedDeliverySlot_ASC',
  SelectedDeliverySlotDesc = 'selectedDeliverySlot_DESC',
  StripeChargeAsc = 'stripeCharge_ASC',
  StripeChargeDesc = 'stripeCharge_DESC',
  SubtotalGrossAsc = 'subtotalGross_ASC',
  SubtotalGrossDesc = 'subtotalGross_DESC',
  SubtotalNetAsc = 'subtotalNet_ASC',
  SubtotalNetDesc = 'subtotalNet_DESC',
  TableIdAsc = 'tableId_ASC',
  TableIdDesc = 'tableId_DESC',
  TableSnapshotAsc = 'tableSnapshot_ASC',
  TableSnapshotDesc = 'tableSnapshot_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OrderPreviousValues = {
  __typename?: 'OrderPreviousValues';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  active: Scalars['Boolean']['output'];
  addOnItemsGross?: Maybe<Scalars['Int']['output']>;
  addOnItemsNet?: Maybe<Scalars['Int']['output']>;
  asap?: Maybe<Scalars['Boolean']['output']>;
  cancellationNotes?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerBillingAddress?: Maybe<Scalars['Json']['output']>;
  customerDeliveryAddress?: Maybe<Scalars['Json']['output']>;
  customerDeliveryNotes?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerOrderNotes?: Maybe<Scalars['String']['output']>;
  deliveredDate?: Maybe<Scalars['DateTime']['output']>;
  deliveryNetworkBookingId?: Maybe<Scalars['Int']['output']>;
  deliveryNetworkBookingStatus?: Maybe<DeliveryNetworkBookingStatus>;
  deliveryNetworkDriverName?: Maybe<Scalars['String']['output']>;
  deliveryNetworkEstimateSnapshot?: Maybe<Scalars['Json']['output']>;
  deliveryNetworkKey?: Maybe<Scalars['String']['output']>;
  deliveryNetworkTripResponses: Array<Scalars['Json']['output']>;
  deliveryNotes?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Json']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  estimatedCompletionTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  fulfillmentCharge?: Maybe<Scalars['Int']['output']>;
  fulfillmentChargeNet?: Maybe<Scalars['Int']['output']>;
  fulfillmentMethod: OrderFulfillmentMethods;
  grossTotal: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  marketplaceId?: Maybe<Scalars['String']['output']>;
  netTotal: Scalars['Int']['output'];
  orderNumber: Scalars['String']['output'];
  orderStatus?: Maybe<OrderStatus>;
  outletId?: Maybe<Scalars['String']['output']>;
  outletNotes?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  partnerCharge?: Maybe<Scalars['Int']['output']>;
  partnerCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerFee?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['String']['output']>;
  paymentMethod: PaymentMethod;
  platformCharge?: Maybe<Scalars['Int']['output']>;
  platformFee?: Maybe<Scalars['Float']['output']>;
  platformId?: Maybe<Scalars['String']['output']>;
  platformNotes?: Maybe<Scalars['String']['output']>;
  restaurantId?: Maybe<Scalars['String']['output']>;
  selectedDeliverySlot?: Maybe<Scalars['DateTime']['output']>;
  stripeCharge?: Maybe<Scalars['Json']['output']>;
  subtotalGross?: Maybe<Scalars['Int']['output']>;
  subtotalNet?: Maybe<Scalars['Int']['output']>;
  tableId?: Maybe<Scalars['String']['output']>;
  tableSnapshot?: Maybe<Scalars['Json']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
};

export enum OrderStatus {
  AuthActionFailure = 'AUTH_ACTION_FAILURE',
  AuthActionPending = 'AUTH_ACTION_PENDING',
  AwaitingDeliveryNetwork = 'AWAITING_DELIVERY_NETWORK',
  AwaitingFriendlyOrderNumberAssignment = 'AWAITING_FRIENDLY_ORDER_NUMBER_ASSIGNMENT',
  AwaitingPayment = 'AWAITING_PAYMENT',
  Complete = 'COMPLETE',
  DeliveryFailureRefund = 'DELIVERY_FAILURE_REFUND',
  DeliveryNetworkFailure = 'DELIVERY_NETWORK_FAILURE',
  OrderFailureRefund = 'ORDER_FAILURE_REFUND',
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  Ready = 'READY',
  RefundCancelled = 'REFUND_CANCELLED',
  RefundFailed = 'REFUND_FAILED',
  RefundReleased = 'REFUND_RELEASED',
  RefundRequested = 'REFUND_REQUESTED',
  Rejected = 'REJECTED'
}

export type OrderSubscriptionPayload = {
  __typename?: 'OrderSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Order>;
  previousValues?: Maybe<OrderPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']['output']>>;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  acceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  acceptedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  asap?: InputMaybe<Scalars['Boolean']['input']>;
  asap_not?: InputMaybe<Scalars['Boolean']['input']>;
  cancellationNotes?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_contains?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_gt?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_gte?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cancellationNotes_lt?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_lte?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_not?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cancellationNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cancellationNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerDeliveryNotes?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_contains?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_gt?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_gte?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerDeliveryNotes_lt?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_lte?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_not?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerDeliveryNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerDeliveryNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  customerId_contains?: InputMaybe<Scalars['String']['input']>;
  customerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerId_gt?: InputMaybe<Scalars['String']['input']>;
  customerId_gte?: InputMaybe<Scalars['String']['input']>;
  customerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerId_lt?: InputMaybe<Scalars['String']['input']>;
  customerId_lte?: InputMaybe<Scalars['String']['input']>;
  customerId_not?: InputMaybe<Scalars['String']['input']>;
  customerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  customerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_contains?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_gt?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_gte?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerOrderNotes_lt?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_lte?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_not?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerOrderNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerOrderNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveredDate?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveredDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  deliveredDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveryNetworkBookingId?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_gt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_gte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkBookingId_lt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_lte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_not?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkBookingId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkBookingStatus?: InputMaybe<DeliveryNetworkBookingStatus>;
  deliveryNetworkBookingStatus_in?: InputMaybe<Array<DeliveryNetworkBookingStatus>>;
  deliveryNetworkBookingStatus_not?: InputMaybe<DeliveryNetworkBookingStatus>;
  deliveryNetworkBookingStatus_not_in?: InputMaybe<Array<DeliveryNetworkBookingStatus>>;
  deliveryNetworkDriverName?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_gt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_gte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkDriverName_lt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_lte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_not?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_not_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkDriverName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkDriverName_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_gt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_gte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkKey_lt?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_lte?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_not?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNetworkKey_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNetworkKey_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_gt?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_gte?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNotes_lt?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_lte?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_not?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryZone?: InputMaybe<DeliveryZoneWhereInput>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  discountAmount_lt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_lte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  estimatedCompletionTime?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_gt?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_gte?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  estimatedCompletionTime_lt?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_lte?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_not?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCompletionTime_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  estimatedDeliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  estimatedDeliveryDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  fulfillmentCharge?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfillmentChargeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_not?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentChargeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfillmentCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfillmentCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentCharge_not?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfillmentMethod?: InputMaybe<OrderFulfillmentMethods>;
  fulfillmentMethod_in?: InputMaybe<Array<OrderFulfillmentMethods>>;
  fulfillmentMethod_not?: InputMaybe<OrderFulfillmentMethods>;
  fulfillmentMethod_not_in?: InputMaybe<Array<OrderFulfillmentMethods>>;
  grossTotal?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  grossTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_not?: InputMaybe<Scalars['Int']['input']>;
  grossTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_contains?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_ends_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_gt?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_gte?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  marketplaceId_lt?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_lte?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_contains?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  marketplaceId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplaceId_starts_with?: InputMaybe<Scalars['String']['input']>;
  netTotal?: InputMaybe<Scalars['Int']['input']>;
  netTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  netTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  netTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  netTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  netTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  netTotal_not?: InputMaybe<Scalars['Int']['input']>;
  netTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderItems_every?: InputMaybe<OrderItemWhereInput>;
  orderItems_none?: InputMaybe<OrderItemWhereInput>;
  orderItems_some?: InputMaybe<OrderItemWhereInput>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  orderNumber_contains?: InputMaybe<Scalars['String']['input']>;
  orderNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  orderNumber_gt?: InputMaybe<Scalars['String']['input']>;
  orderNumber_gte?: InputMaybe<Scalars['String']['input']>;
  orderNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  orderNumber_lt?: InputMaybe<Scalars['String']['input']>;
  orderNumber_lte?: InputMaybe<Scalars['String']['input']>;
  orderNumber_not?: InputMaybe<Scalars['String']['input']>;
  orderNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  orderNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  orderNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  orderNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderStatus?: InputMaybe<OrderStatus>;
  orderStatus_in?: InputMaybe<Array<OrderStatus>>;
  orderStatus_not?: InputMaybe<OrderStatus>;
  orderStatus_not_in?: InputMaybe<Array<OrderStatus>>;
  outlet?: InputMaybe<OutletWhereInput>;
  outletId?: InputMaybe<Scalars['String']['input']>;
  outletId_contains?: InputMaybe<Scalars['String']['input']>;
  outletId_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletId_gt?: InputMaybe<Scalars['String']['input']>;
  outletId_gte?: InputMaybe<Scalars['String']['input']>;
  outletId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletId_lt?: InputMaybe<Scalars['String']['input']>;
  outletId_lte?: InputMaybe<Scalars['String']['input']>;
  outletId_not?: InputMaybe<Scalars['String']['input']>;
  outletId_not_contains?: InputMaybe<Scalars['String']['input']>;
  outletId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletId_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletNotes?: InputMaybe<Scalars['String']['input']>;
  outletNotes_contains?: InputMaybe<Scalars['String']['input']>;
  outletNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletNotes_gt?: InputMaybe<Scalars['String']['input']>;
  outletNotes_gte?: InputMaybe<Scalars['String']['input']>;
  outletNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletNotes_lt?: InputMaybe<Scalars['String']['input']>;
  outletNotes_lte?: InputMaybe<Scalars['String']['input']>;
  outletNotes_not?: InputMaybe<Scalars['String']['input']>;
  outletNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  outletNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paid_not?: InputMaybe<Scalars['Boolean']['input']>;
  partnerCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_not?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerCommissionFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  partnerId_contains?: InputMaybe<Scalars['String']['input']>;
  partnerId_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerId_gt?: InputMaybe<Scalars['String']['input']>;
  partnerId_gte?: InputMaybe<Scalars['String']['input']>;
  partnerId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerId_lt?: InputMaybe<Scalars['String']['input']>;
  partnerId_lte?: InputMaybe<Scalars['String']['input']>;
  partnerId_not?: InputMaybe<Scalars['String']['input']>;
  partnerId_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnerId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  partnerId_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentMethod_in?: InputMaybe<Array<PaymentMethod>>;
  paymentMethod_not?: InputMaybe<PaymentMethod>;
  paymentMethod_not_in?: InputMaybe<Array<PaymentMethod>>;
  platformCharge?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFee_lt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_lte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformId?: InputMaybe<Scalars['String']['input']>;
  platformId_contains?: InputMaybe<Scalars['String']['input']>;
  platformId_ends_with?: InputMaybe<Scalars['String']['input']>;
  platformId_gt?: InputMaybe<Scalars['String']['input']>;
  platformId_gte?: InputMaybe<Scalars['String']['input']>;
  platformId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  platformId_lt?: InputMaybe<Scalars['String']['input']>;
  platformId_lte?: InputMaybe<Scalars['String']['input']>;
  platformId_not?: InputMaybe<Scalars['String']['input']>;
  platformId_not_contains?: InputMaybe<Scalars['String']['input']>;
  platformId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  platformId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  platformId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  platformId_starts_with?: InputMaybe<Scalars['String']['input']>;
  platformNotes?: InputMaybe<Scalars['String']['input']>;
  platformNotes_contains?: InputMaybe<Scalars['String']['input']>;
  platformNotes_ends_with?: InputMaybe<Scalars['String']['input']>;
  platformNotes_gt?: InputMaybe<Scalars['String']['input']>;
  platformNotes_gte?: InputMaybe<Scalars['String']['input']>;
  platformNotes_in?: InputMaybe<Array<Scalars['String']['input']>>;
  platformNotes_lt?: InputMaybe<Scalars['String']['input']>;
  platformNotes_lte?: InputMaybe<Scalars['String']['input']>;
  platformNotes_not?: InputMaybe<Scalars['String']['input']>;
  platformNotes_not_contains?: InputMaybe<Scalars['String']['input']>;
  platformNotes_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  platformNotes_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  platformNotes_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  platformNotes_starts_with?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['String']['input']>;
  restaurantId_contains?: InputMaybe<Scalars['String']['input']>;
  restaurantId_ends_with?: InputMaybe<Scalars['String']['input']>;
  restaurantId_gt?: InputMaybe<Scalars['String']['input']>;
  restaurantId_gte?: InputMaybe<Scalars['String']['input']>;
  restaurantId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  restaurantId_lt?: InputMaybe<Scalars['String']['input']>;
  restaurantId_lte?: InputMaybe<Scalars['String']['input']>;
  restaurantId_not?: InputMaybe<Scalars['String']['input']>;
  restaurantId_not_contains?: InputMaybe<Scalars['String']['input']>;
  restaurantId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  restaurantId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  restaurantId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  restaurantId_starts_with?: InputMaybe<Scalars['String']['input']>;
  review?: InputMaybe<ReviewWhereInput>;
  selectedDeliverySlot?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_gt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_gte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  selectedDeliverySlot_lt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_lte?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_not?: InputMaybe<Scalars['DateTime']['input']>;
  selectedDeliverySlot_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  subtotalGross?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_gt?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_gte?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalGross_lt?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_lte?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_not?: InputMaybe<Scalars['Int']['input']>;
  subtotalGross_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalNet?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_gt?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_gte?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalNet_lt?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_lte?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_not?: InputMaybe<Scalars['Int']['input']>;
  subtotalNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  tableId?: InputMaybe<Scalars['String']['input']>;
  tableId_contains?: InputMaybe<Scalars['String']['input']>;
  tableId_ends_with?: InputMaybe<Scalars['String']['input']>;
  tableId_gt?: InputMaybe<Scalars['String']['input']>;
  tableId_gte?: InputMaybe<Scalars['String']['input']>;
  tableId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tableId_lt?: InputMaybe<Scalars['String']['input']>;
  tableId_lte?: InputMaybe<Scalars['String']['input']>;
  tableId_not?: InputMaybe<Scalars['String']['input']>;
  tableId_not_contains?: InputMaybe<Scalars['String']['input']>;
  tableId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tableId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tableId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tableId_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type OrderWithLoyaltyCardByMarketplaceForAnalyticsResponse = {
  __typename?: 'OrderWithLoyaltyCardByMarketplaceForAnalyticsResponse';
  customerId: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountId: Scalars['String']['output'];
  fulfillmentMethod: OrderFulfillmentMethods;
  id: Scalars['String']['output'];
  loyaltyCard?: Maybe<Scalars['Json']['output']>;
  outletId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  subtotalGross: Scalars['Int']['output'];
};

export type OrderWithVoucherByMarketplaceForAnalyticsResponse = {
  __typename?: 'OrderWithVoucherByMarketplaceForAnalyticsResponse';
  customerId: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountId: Scalars['String']['output'];
  fulfillmentMethod: OrderFulfillmentMethods;
  id: Scalars['String']['output'];
  outletId: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  subtotalGross: Scalars['Int']['output'];
  voucherKey: Scalars['String']['output'];
};

export enum OrderingMode {
  List = 'LIST',
  Postcode = 'POSTCODE',
  Single = 'SINGLE'
}

export enum OrdersOrderBy {
  AcceptedAtAsc = 'acceptedAt_ASC',
  AcceptedAtDesc = 'acceptedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerIdAsc = 'customerId_ASC',
  CustomerIdDesc = 'customerId_DESC',
  EstimatedDeliveryDateAsc = 'estimatedDeliveryDate_ASC',
  EstimatedDeliveryDateDesc = 'estimatedDeliveryDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  MarketplaceIdAsc = 'marketplaceId_ASC',
  MarketplaceIdDesc = 'marketplaceId_DESC',
  OrderNumberAsc = 'orderNumber_ASC',
  OrderNumberDesc = 'orderNumber_DESC',
  OrderStatusAsc = 'orderStatus_ASC',
  OrderStatusDesc = 'orderStatus_DESC',
  OutletIdAsc = 'outletId_ASC',
  OutletIdDesc = 'outletId_DESC',
  PartnerIdAsc = 'partnerId_ASC',
  PartnerIdDesc = 'partnerId_DESC',
  RestaurantIdAsc = 'restaurantId_ASC',
  RestaurantIdDesc = 'restaurantId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OtterBrand = {
  __typename?: 'OtterBrand';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stores: Array<OtterStore>;
};

export type OtterOrganisation = {
  __typename?: 'OtterOrganisation';
  brands: Array<OtterBrand>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type OtterStore = {
  __typename?: 'OtterStore';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Outlet = {
  __typename?: 'Outlet';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  allowPreorders?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  asapAllowed: Scalars['Boolean']['output'];
  asapCollectionAllowed: Scalars['Boolean']['output'];
  asapDeliveryAllowed: Scalars['Boolean']['output'];
  autoCompleteAfter?: Maybe<Scalars['Int']['output']>;
  availableFulfilmentTimeTypes?: Maybe<Array<Maybe<FulfilmentTimeType>>>;
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankSort?: Maybe<Scalars['String']['output']>;
  closeDate?: Maybe<Scalars['DateTime']['output']>;
  closedUntil?: Maybe<Scalars['DateTime']['output']>;
  collectionCharge?: Maybe<Scalars['Float']['output']>;
  collectionDeal?: Maybe<Scalars['String']['output']>;
  collectionMinimumOrderValue?: Maybe<Scalars['Int']['output']>;
  companyLegalName?: Maybe<Scalars['String']['output']>;
  companyNumber?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<CompanyType>;
  contactAddress?: Maybe<Address>;
  contactDoB?: Maybe<Scalars['DateTime']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  coverImage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  daysOfferedInAdvanceMax: Scalars['Int']['output'];
  daysOfferedInAdvanceMaxCollection: Scalars['Int']['output'];
  daysOfferedInAdvanceMaxDelivery: Scalars['Int']['output'];
  daysOfferedInAdvanceMin: Scalars['Int']['output'];
  daysOfferedInAdvanceMinCollection: Scalars['Int']['output'];
  daysOfferedInAdvanceMinDelivery: Scalars['Int']['output'];
  defaultCollectionTime?: Maybe<Scalars['Int']['output']>;
  defaultDeliveryTime?: Maybe<Scalars['Int']['output']>;
  deliveryDeal?: Maybe<Scalars['String']['output']>;
  deliveryMinimumOrderValue?: Maybe<Scalars['Int']['output']>;
  deliveryZoneCosts?: Maybe<Array<OutletDeliveryZoneCost>>;
  description?: Maybe<Scalars['String']['output']>;
  emailInvoices?: Maybe<Scalars['Boolean']['output']>;
  emailOrderReceipt?: Maybe<Scalars['Boolean']['output']>;
  enableHygieneRating: Scalars['Boolean']['output'];
  escalationMobile: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  formattedCurrentOpeningTimeBracket: Scalars['String']['output'];
  hiddenMenuItemGroupIds: Array<Scalars['ID']['output']>;
  hiddenMenuItemIds: Array<Scalars['ID']['output']>;
  hygieneRatingId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isAutoAccept: Scalars['Boolean']['output'];
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  isOnlineOverride?: Maybe<Scalars['Boolean']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  isOrderToTableEnabled: Scalars['Boolean']['output'];
  isVATregistered?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  marketplace?: Maybe<RegionalMarketplace>;
  menuItems?: Maybe<Array<MenuItem>>;
  menuItemsGroups?: Maybe<Array<MenuItemsGroup>>;
  name: Scalars['String']['output'];
  nextOpen?: Maybe<Scalars['DateTime']['output']>;
  noPreordersBeforeOpening?: Maybe<Scalars['Boolean']['output']>;
  openingTimes?: Maybe<Scalars['Json']['output']>;
  orders?: Maybe<Array<Order>>;
  otterStoreId: Scalars['String']['output'];
  outletAddress?: Maybe<Address>;
  outletCategories: Array<OutletCategory>;
  outletDeliveryWindows?: Maybe<Array<OutletDeliveryWindow>>;
  outletEmail?: Maybe<Scalars['String']['output']>;
  outletLogoOverride?: Maybe<Scalars['String']['output']>;
  outletPhone?: Maybe<Scalars['String']['output']>;
  owners?: Maybe<Array<User>>;
  paymentMethods: Array<PaymentMethod>;
  preorderCollectionAllowed: Scalars['Boolean']['output'];
  preorderDeliveryAllowed: Scalars['Boolean']['output'];
  preorderStatus?: Maybe<Scalars['String']['output']>;
  promote?: Maybe<Scalars['Boolean']['output']>;
  restaurant: Restaurant;
  reviews?: Maybe<Array<Review>>;
  soldOutMenuItemIds: Array<Scalars['ID']['output']>;
  soldOutUntilItems?: Maybe<Scalars['Json']['output']>;
  stripeAccessId?: Maybe<Scalars['String']['output']>;
  stripeAccountSession?: Maybe<StripeAccountSession>;
  stripeConnect?: Maybe<StripeConnect>;
  stripeCustomerPayboxId?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  stripeLivemode?: Maybe<Scalars['Boolean']['output']>;
  stripePublicId?: Maybe<Scalars['String']['output']>;
  stripeTransactions?: Maybe<Array<Maybe<StripeTransaction>>>;
  stuartSubsidy?: Maybe<Scalars['Int']['output']>;
  tableDeal?: Maybe<Scalars['String']['output']>;
  tables?: Maybe<Array<Table>>;
  taxReference?: Maybe<Scalars['String']['output']>;
  terminals?: Maybe<Array<Terminal>>;
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['DateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
  visitors?: Maybe<Array<Visitor>>;
};


export type OutletAvailableFulfilmentTimeTypesArgs = {
  fulfilmentMethod?: InputMaybe<OrderFulfillmentMethods>;
};


export type OutletDeliveryZoneCostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutletDeliveryZoneCostOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
};


export type OutletMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemWhereInput>;
};


export type OutletMenuItemsGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemsGroupWhereInput>;
};


export type OutletOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type OutletOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type OutletReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type OutletTablesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TableOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TableWhereInput>;
};


export type OutletTerminalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TerminalOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TerminalWhereInput>;
};


export type OutletTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type OutletVisitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VisitorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VisitorWhereInput>;
};

export type OutletCategory = {
  __typename?: 'OutletCategory';
  category: Cuisine;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  outlet: Outlet;
  priority: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OutletDeliveryWindow = {
  __typename?: 'OutletDeliveryWindow';
  end_day: Scalars['Int']['output'];
  end_hour: Scalars['Int']['output'];
  end_minute: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  outlet: Scalars['ID']['output'];
  start_day: Scalars['Int']['output'];
  start_hour: Scalars['Int']['output'];
  start_minute: Scalars['Int']['output'];
};

export type OutletDeliveryZoneCost = {
  __typename?: 'OutletDeliveryZoneCost';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryCost?: Maybe<Scalars['Int']['output']>;
  deliveryNetwork?: Maybe<DeliveryNetwork>;
  deliveryZone: DeliveryZone;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  outlet: Outlet;
  pickupNotes?: Maybe<Scalars['String']['output']>;
  radiusMiles?: Maybe<Scalars['Float']['output']>;
  uberDirectUndeliverableAction?: Maybe<UberDirectUndeliverableAction>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum OutletDeliveryZoneCostOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeliveryCostAsc = 'deliveryCost_ASC',
  DeliveryCostDesc = 'deliveryCost_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  RadiusMilesAsc = 'radiusMiles_ASC',
  RadiusMilesDesc = 'radiusMiles_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OutletDeliveryZoneCostWhereInput = {
  AND?: InputMaybe<Array<OutletDeliveryZoneCostWhereInput>>;
  NOT?: InputMaybe<Array<OutletDeliveryZoneCostWhereInput>>;
  OR?: InputMaybe<Array<OutletDeliveryZoneCostWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliveryCost?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_gt?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_gte?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryCost_lt?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_lte?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_not?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryNetwork?: InputMaybe<DeliveryNetworkWhereInput>;
  deliveryZone?: InputMaybe<DeliveryZoneWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  outlet?: InputMaybe<OutletWhereInput>;
  radiusMiles?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_gt?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_gte?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  radiusMiles_lt?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_lte?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_not?: InputMaybe<Scalars['Float']['input']>;
  radiusMiles_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum OutletDeliveryZoneType {
  CoordinatePolygon = 'COORDINATE_POLYGON',
  RadiusAroundOutlet = 'RADIUS_AROUND_OUTLET',
  Stuart = 'STUART',
  UberDirect = 'UBER_DIRECT',
  Waybox = 'WAYBOX'
}

export enum OutletNowFilter {
  CurrentlyOpen = 'CURRENTLY_OPEN',
  Today = 'TODAY'
}

export enum OutletOpenStatus {
  OpenPreorderClosed = 'OPEN_PREORDER_CLOSED',
  OrderPreorderView = 'ORDER_PREORDER_VIEW'
}

export enum OutletOrderByInput {
  ActivationDateAsc = 'activationDate_ASC',
  ActivationDateDesc = 'activationDate_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  BankAccountAsc = 'bankAccount_ASC',
  BankAccountDesc = 'bankAccount_DESC',
  BankSortAsc = 'bankSort_ASC',
  BankSortDesc = 'bankSort_DESC',
  CloseDateAsc = 'closeDate_ASC',
  CloseDateDesc = 'closeDate_DESC',
  ClosedUntilAsc = 'closedUntil_ASC',
  ClosedUntilDesc = 'closedUntil_DESC',
  CollectionChargeAsc = 'collectionCharge_ASC',
  CollectionChargeDesc = 'collectionCharge_DESC',
  CollectionMinimumOrderValueAsc = 'collectionMinimumOrderValue_ASC',
  CollectionMinimumOrderValueDesc = 'collectionMinimumOrderValue_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DaysOfferedInAdvanceMaxAsc = 'daysOfferedInAdvanceMax_ASC',
  DaysOfferedInAdvanceMaxDesc = 'daysOfferedInAdvanceMax_DESC',
  DaysOfferedInAdvanceMinAsc = 'daysOfferedInAdvanceMin_ASC',
  DaysOfferedInAdvanceMinDesc = 'daysOfferedInAdvanceMin_DESC',
  DefaultCollectionTimeAsc = 'defaultCollectionTime_ASC',
  DefaultCollectionTimeDesc = 'defaultCollectionTime_DESC',
  DefaultDeliveryTimeAsc = 'defaultDeliveryTime_ASC',
  DefaultDeliveryTimeDesc = 'defaultDeliveryTime_DESC',
  DeliveryMinimumOrderValueAsc = 'deliveryMinimumOrderValue_ASC',
  DeliveryMinimumOrderValueDesc = 'deliveryMinimumOrderValue_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EmailOrderReceiptAsc = 'emailOrderReceipt_ASC',
  EmailOrderReceiptDesc = 'emailOrderReceipt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsOnlineOverrideAsc = 'isOnlineOverride_ASC',
  IsOnlineOverrideDesc = 'isOnlineOverride_DESC',
  IsOnlineAsc = 'isOnline_ASC',
  IsOnlineDesc = 'isOnline_DESC',
  IsOpenAsc = 'isOpen_ASC',
  IsOpenDesc = 'isOpen_DESC',
  IsOrderToTableEnabledAsc = 'isOrderToTableEnabled_ASC',
  IsOrderToTableEnabledDesc = 'isOrderToTableEnabled_DESC',
  IsVaTregisteredAsc = 'isVATregistered_ASC',
  IsVaTregisteredDesc = 'isVATregistered_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OpeningTimesAsc = 'openingTimes_ASC',
  OpeningTimesDesc = 'openingTimes_DESC',
  OutletEmailAsc = 'outletEmail_ASC',
  OutletEmailDesc = 'outletEmail_DESC',
  OutletPhoneAsc = 'outletPhone_ASC',
  OutletPhoneDesc = 'outletPhone_DESC',
  StripeIdAsc = 'stripeId_ASC',
  StripeIdDesc = 'stripeId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC'
}

export type OutletResponse = {
  __typename?: 'OutletResponse';
  message?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Outlet>;
};

export type OutletWhereInput = {
  AND?: InputMaybe<Array<OutletWhereInput>>;
  NOT?: InputMaybe<Array<OutletWhereInput>>;
  OR?: InputMaybe<Array<OutletWhereInput>>;
  activationDate?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  activationDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  activationDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankAccount_contains?: InputMaybe<Scalars['String']['input']>;
  bankAccount_ends_with?: InputMaybe<Scalars['String']['input']>;
  bankAccount_gt?: InputMaybe<Scalars['String']['input']>;
  bankAccount_gte?: InputMaybe<Scalars['String']['input']>;
  bankAccount_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bankAccount_lt?: InputMaybe<Scalars['String']['input']>;
  bankAccount_lte?: InputMaybe<Scalars['String']['input']>;
  bankAccount_not?: InputMaybe<Scalars['String']['input']>;
  bankAccount_not_contains?: InputMaybe<Scalars['String']['input']>;
  bankAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  bankAccount_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bankAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  bankAccount_starts_with?: InputMaybe<Scalars['String']['input']>;
  bankSort?: InputMaybe<Scalars['String']['input']>;
  bankSort_contains?: InputMaybe<Scalars['String']['input']>;
  bankSort_ends_with?: InputMaybe<Scalars['String']['input']>;
  bankSort_gt?: InputMaybe<Scalars['String']['input']>;
  bankSort_gte?: InputMaybe<Scalars['String']['input']>;
  bankSort_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bankSort_lt?: InputMaybe<Scalars['String']['input']>;
  bankSort_lte?: InputMaybe<Scalars['String']['input']>;
  bankSort_not?: InputMaybe<Scalars['String']['input']>;
  bankSort_not_contains?: InputMaybe<Scalars['String']['input']>;
  bankSort_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  bankSort_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  bankSort_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  bankSort_starts_with?: InputMaybe<Scalars['String']['input']>;
  closeDate?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  closeDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  closeDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  closedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_gt?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_gte?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  closedUntil_lt?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_lte?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_not?: InputMaybe<Scalars['DateTime']['input']>;
  closedUntil_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  collectionCharge?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_gt?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_gte?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  collectionCharge_lt?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_lte?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_not?: InputMaybe<Scalars['Float']['input']>;
  collectionCharge_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  collectionMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_gt?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_gte?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  collectionMinimumOrderValue_lt?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_lte?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_not?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  contactAddress?: InputMaybe<AddressWhereInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_lte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactName_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_gt?: InputMaybe<Scalars['String']['input']>;
  contactName_gte?: InputMaybe<Scalars['String']['input']>;
  contactName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_lt?: InputMaybe<Scalars['String']['input']>;
  contactName_lte?: InputMaybe<Scalars['String']['input']>;
  contactName_not?: InputMaybe<Scalars['String']['input']>;
  contactName_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactPhone_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_lte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  daysOfferedInAdvanceMax?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_gt?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_gte?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysOfferedInAdvanceMax_lt?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_lte?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_not?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMax_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysOfferedInAdvanceMin?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_gt?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_gte?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysOfferedInAdvanceMin_lt?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_lte?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_not?: InputMaybe<Scalars['Int']['input']>;
  daysOfferedInAdvanceMin_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  defaultCollectionTime?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_gt?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_gte?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  defaultCollectionTime_lt?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_lte?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_not?: InputMaybe<Scalars['Int']['input']>;
  defaultCollectionTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  defaultDeliveryTime?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_gt?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_gte?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  defaultDeliveryTime_lt?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_lte?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_not?: InputMaybe<Scalars['Int']['input']>;
  defaultDeliveryTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_gt?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_gte?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryMinimumOrderValue_lt?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_lte?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_not?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryZoneCosts_every?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  deliveryZoneCosts_none?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  deliveryZoneCosts_some?: InputMaybe<OutletDeliveryZoneCostWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  emailOrderReceipt?: InputMaybe<Scalars['Boolean']['input']>;
  emailOrderReceipt_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlineOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlineOverride_not?: InputMaybe<Scalars['Boolean']['input']>;
  isOnline_not?: InputMaybe<Scalars['Boolean']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  isOpen_not?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderToTableEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderToTableEnabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  menuItemsGroups_every?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItemsGroups_none?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItemsGroups_some?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItems_every?: InputMaybe<MenuItemWhereInput>;
  menuItems_none?: InputMaybe<MenuItemWhereInput>;
  menuItems_some?: InputMaybe<MenuItemWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orders_every?: InputMaybe<OrderWhereInput>;
  orders_none?: InputMaybe<OrderWhereInput>;
  orders_some?: InputMaybe<OrderWhereInput>;
  outletAddress?: InputMaybe<AddressWhereInput>;
  outletEmail?: InputMaybe<Scalars['String']['input']>;
  outletEmail_contains?: InputMaybe<Scalars['String']['input']>;
  outletEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletEmail_gt?: InputMaybe<Scalars['String']['input']>;
  outletEmail_gte?: InputMaybe<Scalars['String']['input']>;
  outletEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletEmail_lt?: InputMaybe<Scalars['String']['input']>;
  outletEmail_lte?: InputMaybe<Scalars['String']['input']>;
  outletEmail_not?: InputMaybe<Scalars['String']['input']>;
  outletEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  outletEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletPhone?: InputMaybe<Scalars['String']['input']>;
  outletPhone_contains?: InputMaybe<Scalars['String']['input']>;
  outletPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletPhone_gt?: InputMaybe<Scalars['String']['input']>;
  outletPhone_gte?: InputMaybe<Scalars['String']['input']>;
  outletPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletPhone_lt?: InputMaybe<Scalars['String']['input']>;
  outletPhone_lte?: InputMaybe<Scalars['String']['input']>;
  outletPhone_not?: InputMaybe<Scalars['String']['input']>;
  outletPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  outletPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  outletPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  outletPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  outletPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  owners_every?: InputMaybe<UserWhereInput>;
  owners_none?: InputMaybe<UserWhereInput>;
  owners_some?: InputMaybe<UserWhereInput>;
  restaurant?: InputMaybe<RestaurantWhereInput>;
  reviews_every?: InputMaybe<ReviewWhereInput>;
  reviews_none?: InputMaybe<ReviewWhereInput>;
  reviews_some?: InputMaybe<ReviewWhereInput>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeId_not?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  tables_every?: InputMaybe<TableWhereInput>;
  tables_none?: InputMaybe<TableWhereInput>;
  tables_some?: InputMaybe<TableWhereInput>;
  terminals_every?: InputMaybe<TerminalWhereInput>;
  terminals_none?: InputMaybe<TerminalWhereInput>;
  terminals_some?: InputMaybe<TerminalWhereInput>;
  transactions_every?: InputMaybe<TransactionWhereInput>;
  transactions_none?: InputMaybe<TransactionWhereInput>;
  transactions_some?: InputMaybe<TransactionWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_lt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_lte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  visitors_every?: InputMaybe<VisitorWhereInput>;
  visitors_none?: InputMaybe<VisitorWhereInput>;
  visitors_some?: InputMaybe<VisitorWhereInput>;
};

export type OwnerFilterInput = {
  ids: Array<Scalars['ID']['input']>;
  type: OwnerFilterType;
};

export enum OwnerFilterType {
  Marketplace = 'MARKETPLACE',
  Outlet = 'OUTLET',
  Partner = 'PARTNER',
  Restaurant = 'RESTAURANT'
}

export type OwnsIdResponse = {
  __typename?: 'OwnsIdResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type PosDevice = {
  __typename?: 'POSDevice';
  createdAt: Scalars['DateTime']['output'];
  friendlyName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['String']['output'];
  marketplace: RegionalMarketplace;
  outlets: Array<Outlet>;
  serialNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  allowSubBrands?: Maybe<Scalars['Boolean']['output']>;
  appStoreConnectApiIssuerId?: Maybe<Scalars['String']['output']>;
  appStoreConnectApiKeyId?: Maybe<Scalars['String']['output']>;
  appStoreConnectApiKeyPath?: Maybe<Scalars['String']['output']>;
  appleTeamIdDefault?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  billing: Billing;
  billingAllowed?: Maybe<Scalars['Boolean']['output']>;
  billingProductAPIId?: Maybe<Scalars['String']['output']>;
  billingProductChargebackId?: Maybe<Scalars['String']['output']>;
  billingProductMarketingId?: Maybe<Scalars['String']['output']>;
  billingProductOutletId?: Maybe<Scalars['String']['output']>;
  billingProductSMSId?: Maybe<Scalars['String']['output']>;
  billingProductWayboxId?: Maybe<Scalars['String']['output']>;
  brandColor?: Maybe<Scalars['String']['output']>;
  brandImage?: Maybe<Scalars['Boolean']['output']>;
  cname?: Maybe<Scalars['String']['output']>;
  companyLegalName: Scalars['String']['output'];
  companyNumber?: Maybe<Scalars['String']['output']>;
  contactAddress: Address;
  contactEmail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryNetworkProviders: Array<Scalars['String']['output']>;
  googleJsonS3KeyPathDefault?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<BillingInvoicePdf>;
  isVATregistered?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  marketingSMSFee?: Maybe<Scalars['Float']['output']>;
  marketplaces?: Maybe<Array<RegionalMarketplace>>;
  minAppVersionOverride?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderHoldInterval?: Maybe<Scalars['Int']['output']>;
  owners?: Maybe<Array<User>>;
  partnerCommissionCharge?: Maybe<Scalars['Int']['output']>;
  partnerCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerTableCommissionCharge?: Maybe<Scalars['Int']['output']>;
  partnerTableCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerVat?: Maybe<Scalars['Int']['output']>;
  platform: Platform;
  platformCharge?: Maybe<Scalars['Int']['output']>;
  platformFee?: Maybe<Scalars['Float']['output']>;
  platformTableCharge?: Maybe<Scalars['Int']['output']>;
  platformTableFee?: Maybe<Scalars['Float']['output']>;
  restaurants?: Maybe<Array<Restaurant>>;
  stripeAccessId?: Maybe<Scalars['String']['output']>;
  stripeCharge?: Maybe<Scalars['Int']['output']>;
  stripeChargebackCharge?: Maybe<Scalars['Int']['output']>;
  stripeConnect?: Maybe<StripeConnect>;
  stripeConnectCharge?: Maybe<Scalars['Int']['output']>;
  stripeDebitFee?: Maybe<Scalars['Float']['output']>;
  stripeEnterpriseAccessId?: Maybe<Scalars['String']['output']>;
  stripeEnterpriseAllowed: Scalars['Boolean']['output'];
  stripeEnterpriseId?: Maybe<Scalars['String']['output']>;
  stripeEnterprisePayoutDay?: Maybe<Scalars['String']['output']>;
  stripeEnterprisePublicId?: Maybe<Scalars['String']['output']>;
  stripeEnterpriseWebhooks?: Maybe<Array<StripeWebhooks>>;
  stripeFee?: Maybe<Scalars['Float']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  stripeInstantPayoutFee?: Maybe<Scalars['Float']['output']>;
  stripeLivemode?: Maybe<Scalars['Boolean']['output']>;
  stripePayoutCharge?: Maybe<Scalars['Int']['output']>;
  stripePayoutFee?: Maybe<Scalars['Float']['output']>;
  stripePresentCharge?: Maybe<Scalars['Int']['output']>;
  stripePresentFee?: Maybe<Scalars['Float']['output']>;
  stripePublicId?: Maybe<Scalars['String']['output']>;
  stripeRefreshToken?: Maybe<Scalars['String']['output']>;
  stripeShowDashboardMarketplace: Scalars['Boolean']['output'];
  stripeShowDashboardOutlet: Scalars['Boolean']['output'];
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportTitle?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};


export type PartnerMarketplacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RegionalMarketplaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegionalMarketplaceWhereInput>;
};


export type PartnerOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type PartnerRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RestaurantOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RestaurantWhereInput>;
};

export enum PartnerOrderByInput {
  AllowSubBrandsAsc = 'allowSubBrands_ASC',
  AllowSubBrandsDesc = 'allowSubBrands_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  BrandColorAsc = 'brandColor_ASC',
  BrandColorDesc = 'brandColor_DESC',
  BrandImageAsc = 'brandImage_ASC',
  BrandImageDesc = 'brandImage_DESC',
  CnameAsc = 'cname_ASC',
  CnameDesc = 'cname_DESC',
  CompanyLegalNameAsc = 'companyLegalName_ASC',
  CompanyLegalNameDesc = 'companyLegalName_DESC',
  CompanyNumberAsc = 'companyNumber_ASC',
  CompanyNumberDesc = 'companyNumber_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsVaTregisteredAsc = 'isVATregistered_ASC',
  IsVaTregisteredDesc = 'isVATregistered_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderHoldIntervalAsc = 'orderHoldInterval_ASC',
  OrderHoldIntervalDesc = 'orderHoldInterval_DESC',
  PartnerCommissionChargeAsc = 'partnerCommissionCharge_ASC',
  PartnerCommissionChargeDesc = 'partnerCommissionCharge_DESC',
  PartnerCommissionFeeAsc = 'partnerCommissionFee_ASC',
  PartnerCommissionFeeDesc = 'partnerCommissionFee_DESC',
  PartnerVatAsc = 'partnerVat_ASC',
  PartnerVatDesc = 'partnerVat_DESC',
  PlatformChargeAsc = 'platformCharge_ASC',
  PlatformChargeDesc = 'platformCharge_DESC',
  PlatformFeeAsc = 'platformFee_ASC',
  PlatformFeeDesc = 'platformFee_DESC',
  StripeAccessIdAsc = 'stripeAccessId_ASC',
  StripeAccessIdDesc = 'stripeAccessId_DESC',
  StripeIdAsc = 'stripeId_ASC',
  StripeIdDesc = 'stripeId_DESC',
  StripeLivemodeAsc = 'stripeLivemode_ASC',
  StripeLivemodeDesc = 'stripeLivemode_DESC',
  StripePublicIdAsc = 'stripePublicId_ASC',
  StripePublicIdDesc = 'stripePublicId_DESC',
  StripeRefreshTokenAsc = 'stripeRefreshToken_ASC',
  StripeRefreshTokenDesc = 'stripeRefreshToken_DESC',
  SupportEmailAsc = 'supportEmail_ASC',
  SupportEmailDesc = 'supportEmail_DESC',
  SupportTitleAsc = 'supportTitle_ASC',
  SupportTitleDesc = 'supportTitle_DESC',
  SupportUrlAsc = 'supportUrl_ASC',
  SupportUrlDesc = 'supportUrl_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC'
}

export type PartnerWhereInput = {
  AND?: InputMaybe<Array<PartnerWhereInput>>;
  NOT?: InputMaybe<Array<PartnerWhereInput>>;
  OR?: InputMaybe<Array<PartnerWhereInput>>;
  allowSubBrands?: InputMaybe<Scalars['Boolean']['input']>;
  allowSubBrands_not?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandColor_contains?: InputMaybe<Scalars['String']['input']>;
  brandColor_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_gt?: InputMaybe<Scalars['String']['input']>;
  brandColor_gte?: InputMaybe<Scalars['String']['input']>;
  brandColor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandColor_lt?: InputMaybe<Scalars['String']['input']>;
  brandColor_lte?: InputMaybe<Scalars['String']['input']>;
  brandColor_not?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandColor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandImage?: InputMaybe<Scalars['Boolean']['input']>;
  brandImage_not?: InputMaybe<Scalars['Boolean']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  cname_contains?: InputMaybe<Scalars['String']['input']>;
  cname_ends_with?: InputMaybe<Scalars['String']['input']>;
  cname_gt?: InputMaybe<Scalars['String']['input']>;
  cname_gte?: InputMaybe<Scalars['String']['input']>;
  cname_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cname_lt?: InputMaybe<Scalars['String']['input']>;
  cname_lte?: InputMaybe<Scalars['String']['input']>;
  cname_not?: InputMaybe<Scalars['String']['input']>;
  cname_not_contains?: InputMaybe<Scalars['String']['input']>;
  cname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cname_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cname_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_contains?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_gt?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_gte?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyLegalName_lt?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_lte?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyLegalName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  companyNumber_contains?: InputMaybe<Scalars['String']['input']>;
  companyNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_gt?: InputMaybe<Scalars['String']['input']>;
  companyNumber_gte?: InputMaybe<Scalars['String']['input']>;
  companyNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyNumber_lt?: InputMaybe<Scalars['String']['input']>;
  companyNumber_lte?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<AddressWhereInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_lte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactName_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_gt?: InputMaybe<Scalars['String']['input']>;
  contactName_gte?: InputMaybe<Scalars['String']['input']>;
  contactName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_lt?: InputMaybe<Scalars['String']['input']>;
  contactName_lte?: InputMaybe<Scalars['String']['input']>;
  contactName_not?: InputMaybe<Scalars['String']['input']>;
  contactName_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactPhone_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_lte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplaces_every?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaces_none?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaces_some?: InputMaybe<RegionalMarketplaceWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderHoldInterval_lt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_lte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  owners_every?: InputMaybe<UserWhereInput>;
  owners_none?: InputMaybe<UserWhereInput>;
  owners_some?: InputMaybe<UserWhereInput>;
  partnerCommissionCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCommissionCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_not?: InputMaybe<Scalars['Int']['input']>;
  partnerCommissionCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerCommissionFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerVat?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_gt?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_gte?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerVat_lt?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_lte?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_not?: InputMaybe<Scalars['Int']['input']>;
  partnerVat_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platform?: InputMaybe<PlatformWhereInput>;
  platformCharge?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFee_lt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_lte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  restaurants_every?: InputMaybe<RestaurantWhereInput>;
  restaurants_none?: InputMaybe<RestaurantWhereInput>;
  restaurants_some?: InputMaybe<RestaurantWhereInput>;
  stripeAccessId?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeAccessId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeAccessId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeId_not?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeLivemode?: InputMaybe<Scalars['Boolean']['input']>;
  stripeLivemode_not?: InputMaybe<Scalars['Boolean']['input']>;
  stripePublicId?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_lt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_lte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_contains?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_gt?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_gte?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeRefreshToken_lt?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_lte?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeRefreshToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportEmail_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_lt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_lte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle?: InputMaybe<Scalars['String']['input']>;
  supportTitle_contains?: InputMaybe<Scalars['String']['input']>;
  supportTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_gt?: InputMaybe<Scalars['String']['input']>;
  supportTitle_gte?: InputMaybe<Scalars['String']['input']>;
  supportTitle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportTitle_lt?: InputMaybe<Scalars['String']['input']>;
  supportTitle_lte?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
  supportUrl_contains?: InputMaybe<Scalars['String']['input']>;
  supportUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_gt?: InputMaybe<Scalars['String']['input']>;
  supportUrl_gte?: InputMaybe<Scalars['String']['input']>;
  supportUrl_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportUrl_lt?: InputMaybe<Scalars['String']['input']>;
  supportUrl_lte?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_lt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_lte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentMethod {
  CardOnCollection = 'CARD_ON_COLLECTION',
  CardOnDelivery = 'CARD_ON_DELIVERY',
  CardOnTable = 'CARD_ON_TABLE',
  CashOnCollection = 'CASH_ON_COLLECTION',
  CashOnDelivery = 'CASH_ON_DELIVERY'
}

export type PayoutResponse = {
  __typename?: 'PayoutResponse';
  orderGrossTotal?: Maybe<Scalars['Int']['output']>;
  orderNetTotal?: Maybe<Scalars['Int']['output']>;
  outlet?: Maybe<Outlet>;
  totalCustomerDeliveryValue?: Maybe<Scalars['Int']['output']>;
  totalDeliveryValue?: Maybe<Scalars['Int']['output']>;
  totalDiscountValue?: Maybe<Scalars['Int']['output']>;
  totalMarketplaceCharge?: Maybe<Scalars['Int']['output']>;
  totalMarketplaceFee?: Maybe<Scalars['Int']['output']>;
  totalMerchantCharge?: Maybe<Scalars['Int']['output']>;
  totalNetworkValue?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalPaidCashValue?: Maybe<Scalars['Int']['output']>;
  totalPayout?: Maybe<Scalars['Int']['output']>;
  totalRefunds?: Maybe<Scalars['Int']['output']>;
  totalRefundsValue?: Maybe<Scalars['Int']['output']>;
  totalSubsidyValue?: Maybe<Scalars['Int']['output']>;
};

export type PermissionCategory = {
  __typename?: 'PermissionCategory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  mutations: Array<Scalars['String']['output']>;
  queries: Array<Scalars['String']['output']>;
  subscriptions: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum PermissionCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PermissionCategoryWhereInput = {
  AND?: InputMaybe<Array<PermissionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<PermissionCategoryWhereInput>>;
  OR?: InputMaybe<Array<PermissionCategoryWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type PermissionResponse = {
  __typename?: 'PermissionResponse';
  message?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<PermissionCategory>;
};

export enum PinpointNotificationStrategy {
  EmailAndPush = 'EMAIL_AND_PUSH',
  JustEmail = 'JUST_EMAIL',
  JustPush = 'JUST_PUSH',
  PushFallingBackToEmail = 'PUSH_FALLING_BACK_TO_EMAIL'
}

export type PlaceHolder = {
  __typename?: 'PlaceHolder';
  placeholder?: Maybe<Scalars['Boolean']['output']>;
};

export type Platform = {
  __typename?: 'Platform';
  autoRejectOrdersConfig?: Maybe<Scalars['Json']['output']>;
  contactAddress: Address;
  contactEmail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderHoldIntervalOverride: Scalars['Int']['output'];
  otterClientId?: Maybe<Scalars['String']['output']>;
  otterClientSecret?: Maybe<Scalars['String']['output']>;
  otterWebhookSecret?: Maybe<Scalars['String']['output']>;
  owners?: Maybe<Array<User>>;
  partners?: Maybe<Array<Partner>>;
  payboxAccessId: Scalars['String']['output'];
  payboxClientId: Scalars['String']['output'];
  payboxId: Scalars['String']['output'];
  payboxPublicId: Scalars['String']['output'];
  platformCurrency: Scalars['Json']['output'];
  platformVat: Scalars['Float']['output'];
  products?: Maybe<Array<BillingProduct>>;
  stripeAccessId: Scalars['String']['output'];
  stripeCharge?: Maybe<Scalars['Int']['output']>;
  stripeChargebackCharge?: Maybe<Scalars['Int']['output']>;
  stripeClientId: Scalars['String']['output'];
  stripeConnectCharge?: Maybe<Scalars['Int']['output']>;
  stripeDebitFee?: Maybe<Scalars['Float']['output']>;
  stripeFee?: Maybe<Scalars['Float']['output']>;
  stripeId: Scalars['String']['output'];
  stripeInstantPayoutFee?: Maybe<Scalars['Float']['output']>;
  stripePayboxWebhooks?: Maybe<Array<StripeWebhooks>>;
  stripePayoutCharge?: Maybe<Scalars['Int']['output']>;
  stripePayoutFee?: Maybe<Scalars['Float']['output']>;
  stripePlatformWebhooks?: Maybe<Array<StripeWebhooks>>;
  stripePresentCharge?: Maybe<Scalars['Int']['output']>;
  stripePresentFee?: Maybe<Scalars['Float']['output']>;
  stripePublicId: Scalars['String']['output'];
  stripeRefreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};


export type PlatformOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type PlatformPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PartnerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};

export enum PlatformOrderByInput {
  AutoRejectOrdersConfigAsc = 'autoRejectOrdersConfig_ASC',
  AutoRejectOrdersConfigDesc = 'autoRejectOrdersConfig_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderHoldIntervalOverrideAsc = 'orderHoldIntervalOverride_ASC',
  OrderHoldIntervalOverrideDesc = 'orderHoldIntervalOverride_DESC',
  PlatformCurrencyAsc = 'platformCurrency_ASC',
  PlatformCurrencyDesc = 'platformCurrency_DESC',
  PlatformVatAsc = 'platformVat_ASC',
  PlatformVatDesc = 'platformVat_DESC',
  StripeClientIdAsc = 'stripeClientId_ASC',
  StripeClientIdDesc = 'stripeClientId_DESC',
  StripeIdAsc = 'stripeId_ASC',
  StripeIdDesc = 'stripeId_DESC',
  StripePublicIdAsc = 'stripePublicId_ASC',
  StripePublicIdDesc = 'stripePublicId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC'
}

export type PlatformResponse = {
  __typename?: 'PlatformResponse';
  message?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Platform>;
};

export type PlatformWhereInput = {
  AND?: InputMaybe<Array<PlatformWhereInput>>;
  NOT?: InputMaybe<Array<PlatformWhereInput>>;
  OR?: InputMaybe<Array<PlatformWhereInput>>;
  contactAddress?: InputMaybe<AddressWhereInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_lte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactName_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_gt?: InputMaybe<Scalars['String']['input']>;
  contactName_gte?: InputMaybe<Scalars['String']['input']>;
  contactName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_lt?: InputMaybe<Scalars['String']['input']>;
  contactName_lte?: InputMaybe<Scalars['String']['input']>;
  contactName_not?: InputMaybe<Scalars['String']['input']>;
  contactName_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactPhone_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_lte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderHoldIntervalOverride?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_gt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_gte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderHoldIntervalOverride_lt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_lte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_not?: InputMaybe<Scalars['Int']['input']>;
  orderHoldIntervalOverride_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  owners_every?: InputMaybe<UserWhereInput>;
  owners_none?: InputMaybe<UserWhereInput>;
  owners_some?: InputMaybe<UserWhereInput>;
  partners_every?: InputMaybe<PartnerWhereInput>;
  partners_none?: InputMaybe<PartnerWhereInput>;
  partners_some?: InputMaybe<PartnerWhereInput>;
  platformVat?: InputMaybe<Scalars['Float']['input']>;
  platformVat_gt?: InputMaybe<Scalars['Float']['input']>;
  platformVat_gte?: InputMaybe<Scalars['Float']['input']>;
  platformVat_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformVat_lt?: InputMaybe<Scalars['Float']['input']>;
  platformVat_lte?: InputMaybe<Scalars['Float']['input']>;
  platformVat_not?: InputMaybe<Scalars['Float']['input']>;
  platformVat_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  stripeClientId?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeClientId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_not?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeClientId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeClientId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeId_not?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_lt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_lte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_lt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_lte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type PreparationMethodsResponse = {
  __typename?: 'PreparationMethodsResponse';
  message?: Maybe<Scalars['String']['output']>;
  methods?: Maybe<Scalars['Json']['output']>;
};

export type Query = {
  __typename?: 'Query';
  allowedPaymentMethodsOnOutlet: AllowedPaymentMethods;
  blacklistedAddresses: BlacklistAddressesResponse;
  businessOrdersForAnalyticsByDiscount: Array<OrderByBusinessForAnalyticsResponseByDiscount>;
  businessOrdersForAnalyticsWithDate: Array<OrderByBusinessForAnalyticsWithDateResponse>;
  categoriesForRestaurant: GetCuisinesResponseWithOptInFeature;
  checkUserCanRegister: CheckUserCanRegisterResponse;
  customerOrdersForAnalytics: Array<OrderByCustomerForAnalyticsResponse>;
  customers: CustomersResponse;
  discountVouchersByMarketplace: DiscountsResponse;
  discountsWithLoyaltyCardByMarketplace: DiscountsResponse;
  generatePreviewHtmlForEmail: EmailPreviewResponse;
  getAllRoles: AllRolesResponse;
  getAllowedCategories?: Maybe<Array<Cuisine>>;
  getApp: AppResponse;
  getApps: AppsResponse;
  getAuditsList: AuditsListResponse;
  getAvailableDeliveryZonesForOutlet: Array<DeliveryZone>;
  getBusinessSegment?: Maybe<BusinessSegment>;
  getBusinessSegmentLinkCards: Array<BusinessSegmentLinkCard>;
  getBusinessSegmentsByMarketplaceId: Array<BusinessSegment>;
  getBusinessSegmentsFiltersByGroupId: Array<BusinessSegmentsFilter>;
  getCampaignById: CampaignResponse;
  getCampaigns: CampaignsResponse;
  getCuisines: GetCuisinesResponse;
  getCurrency: CurrencyResponse;
  getCustomerAddresses: ManyAddressesResponse;
  getDeliveryNetworkFailureReportByMarketplace?: Maybe<Array<DeliveryNetworkFailureReportByMarketplace>>;
  getDeliveryNetworkFailureReportByPartner?: Maybe<Array<DeliveryNetworkFailureReportByPartner>>;
  getDeliveryNetworkFailureReportHeatmap?: Maybe<Array<DeliveryNetworkFailureReportHeatmap>>;
  getDeliveryNetworks: ManyDeliveryNetworksResponse;
  getDeliveryZones: ManyDeliveryZonesResponse;
  getDiscount: DiscountResponse;
  getFAQs: Array<Faq>;
  getImages: ImagesResponse;
  getLinkCard: LinkCard;
  getLinkCards: Array<LinkCard>;
  getLiveEstimateForCampaign: LiveEstimateResponse;
  getLiveSegmentEstimate: LiveEstimateResponse;
  getMarketingMetrics: MarketingMetricsResponse;
  getMarketplaceMessageTemplates: MessageTemplatesResponse;
  getMarketplaces: GetRegionsResponse;
  getMarketplacesGroupedByCuisineIds: Array<MarketplacesGroupedByCategoryIdResponse>;
  getMemberDiscountsByRestaurant: DiscountsResponse;
  getMenuItems: MenuItemsResponse;
  getMenuItemsGroups: ManyMenuItemsGroupsResponse;
  getMessage?: Maybe<MessageResponse>;
  getMessageHistory?: Maybe<MessagesResponse>;
  getMessages?: Maybe<MessagesResponse>;
  getMyDetails: UserResponse;
  getOptionItems: ManyOptionItemsResponse;
  getOptions: ManyOptionsResponse;
  getOtterOAuthUrl: Scalars['String']['output'];
  getOutletByOtterStoreId?: Maybe<Outlet>;
  getOutletDeliveryWindows: GetOutletDeliveryWindowsResponse;
  getOutletDeliveryZoneCosts: MultipleOutletDeliveryZoneCostsResponse;
  getOutletMenuItem: MenuItem;
  getOutlets: MultipleOutletsResponse;
  getOutletsByIds: MultipleOutletsResponse;
  getPOSDevices: MultiplePosDeviceResponse;
  getPartners: GetPartnersResponse;
  getPinpointMarketplaces: GetRegionsResponse;
  getPlatform: PlatformResponse;
  getPlatformVAT: Scalars['Float']['output'];
  getRefundReport?: Maybe<Array<RefundReport>>;
  getRejectionsReportByOutlet?: Maybe<Array<RejectionsReportByOutlet>>;
  getRejectionsReportHeatmap?: Maybe<Array<RejectionsReportHeatmap>>;
  getRestaurantNamesWithContactsForMarketplace?: Maybe<Array<RestaurantNamesForMarketplaceResponse>>;
  getRestaurants: MultipleRestaurantsResponse;
  getRestaurantsByIds: MultipleRestaurantsResponse;
  getReviews: ManyReviewsResponse;
  getSameWindowOrdersCount?: Maybe<GetSameWindowOrdersCountResponse>;
  getSegment: SegmentResponse;
  getSegmentAttributeFilters: SegmentAttributeFiltersResponse;
  getSegmentAttributeValues: SegmentAttributeValuesResponse;
  getSegments: SegmentsResponse;
  getStripeClientId: StripeClientIdResponse;
  getStripeConnectAccounts: StripeConnectAccountsResponse;
  getTerminals: MultipleTerminalResponse;
  getTransactions: ManyTransactionsResponse;
  getUsers: ManyUsersResponse;
  getVisitors?: Maybe<VisitorResponse>;
  loyaltyCard?: Maybe<LoyaltyCard>;
  loyaltyCardLiabilityForAnalytics: Array<LoyaltyCardLiabilityForAnalyticsResponse>;
  marketplaceOrdersWithLoyaltyCard: Array<OrderWithLoyaltyCardByMarketplaceForAnalyticsResponse>;
  marketplaceOrdersWithVouchers: Array<OrderWithVoucherByMarketplaceForAnalyticsResponse>;
  onboardingInfo?: Maybe<OnboardingInfoResponse>;
  order: Order;
  orders: ManyOrdersResponse;
  outletsForPinpointApplicationId: MultipleOutletsResponse;
  payout: ManyPayoutResponse;
  permissionCategories?: Maybe<Array<Maybe<PermissionCategory>>>;
  /**
   * Returns the number of invoices which would be queued for email when sending the queueInvoiceEmails mutation.
   * The count is the number of invoices whice meet the following criteria:
   *   * emailStatus is NERVER_QUEUED
   *   * dateFrom is >= dateFrom_gte
   *   * dateTo is <= dateTo_gte
   *   * outlet has invoice emails enabled
   *   * marketplace has invoice emails enabled
   * *Returns*
   * Returns a count of the number of invoices queued for email.
   */
  queueInvoiceEmailCount: Scalars['Int']['output'];
  reconcile: Array<ReconciledResponse>;
  revenue: Array<RevenueResponse>;
  sales: Array<SaleResponse>;
  salesBy: ManySalesByResponse;
  salesItems: ManySalesItemsResponse;
  tables: Array<Table>;
  taxReport: Array<TaxRecord>;
  terminalSoftwareVersions: Array<Scalars['String']['output']>;
  /**
   * ### Validation
   * Args are validated against the following schemas based on the value of filterType
   *
   * #### SEARCH
   * filterType: TerminalFilterType!
   * first: Int!
   * skip: Int!
   * marketplaceIds: [ID!]
   * outletIds: [ID!]
   * searchTerm: String!
   * orderBy: TerminalsQueryOrderBy
   *
   * #### FILTER
   * filterType: TerminalFilterType!
   * first: Int!
   * skip: Int!
   * marketplaceIds: [ID!]
   * outletIds: [ID!]
   * softwareVersion: String
   * orderBy: TerminalsQueryOrderBy
   *
   * #### OFFLINE_OPEN_ONLY
   * filterType: TerminalFilterType!
   * marketplaceIds: [ID!]
   * outletIds: [ID!]
   */
  terminals: MultipleTerminalResponse;
  transactionAggregates: Array<TransactionAggregate>;
  voucher?: Maybe<Voucher>;
  vouchers: VouchersResponse;
  webhook: Webhook;
  webhooks: Array<Webhook>;
};


export type QueryAllowedPaymentMethodsOnOutletArgs = {
  id: Scalars['String']['input'];
};


export type QueryBlacklistedAddressesArgs = {
  marketplaceId: Scalars['ID']['input'];
};


export type QueryBusinessOrdersForAnalyticsByDiscountArgs = {
  businessId: Scalars['String']['input'];
  discountId: Scalars['String']['input'];
};


export type QueryBusinessOrdersForAnalyticsWithDateArgs = {
  businessId: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryCategoriesForRestaurantArgs = {
  restaurantId: Scalars['String']['input'];
};


export type QueryCustomerOrdersForAnalyticsArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<CustomerOrderByInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcodePartial?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryDiscountVouchersByMarketplaceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId: Scalars['String']['input'];
  orderBy?: InputMaybe<DiscountOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DiscountWhereInput>;
};


export type QueryDiscountsWithLoyaltyCardByMarketplaceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId: Scalars['String']['input'];
  orderBy?: InputMaybe<DiscountOrderByInput>;
  queryFilterVars?: InputMaybe<Array<LoyaltyCardWhereInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DiscountWhereInput>;
};


export type QueryGeneratePreviewHtmlForEmailArgs = {
  content: Array<Scalars['Json']['input']>;
  marketplaceId: Scalars['String']['input'];
  theme: EmailTheme;
};


export type QueryGetAllRolesArgs = {
  roleKey?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleTitle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetAllowedCategoriesArgs = {
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetAppArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAuditsListArgs = {
  caller?: InputMaybe<Scalars['String']['input']>;
  endingOn?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  startingFrom?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAvailableDeliveryZonesForOutletArgs = {
  outletId: Scalars['String']['input'];
};


export type QueryGetBusinessSegmentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBusinessSegmentLinkCardsArgs = {
  businessSegmentId: Scalars['String']['input'];
};


export type QueryGetBusinessSegmentsByMarketplaceIdArgs = {
  marketplaceId: Scalars['String']['input'];
};


export type QueryGetBusinessSegmentsFiltersByGroupIdArgs = {
  segmentId: Scalars['String']['input'];
};


export type QueryGetCampaignByIdArgs = {
  campaignId: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetCampaignsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId: Scalars['ID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCuisinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDeliveryNetworkFailureReportByMarketplaceArgs = {
  deliveryNetworkProvider: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type QueryGetDeliveryNetworkFailureReportByPartnerArgs = {
  deliveryNetworkProvider: Scalars['String']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['String']['input'];
};


export type QueryGetDeliveryNetworkFailureReportHeatmapArgs = {
  marketplaceId: Scalars['String']['input'];
  outletIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetDeliveryNetworksArgs = {
  excludeStuart?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDeliveryZonesArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetFaQsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetImagesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  optionItemId?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetLinkCardArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetLinkCardsArgs = {
  marketplaceId: Scalars['String']['input'];
};


export type QueryGetLiveEstimateForCampaignArgs = {
  channel: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
  segmentId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryGetLiveSegmentEstimateArgs = {
  filterGroups: Array<FilterGroupInput>;
  filterGroupsType: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetMarketingMetricsArgs = {
  endDate: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
};


export type QueryGetMarketplaceMessageTemplatesArgs = {
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetMarketplacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<RegionalMarketplaceOrderByInput>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pinpointApplicationId?: InputMaybe<Scalars['String']['input']>;
  sesMessagingRequired?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMarketplacesGroupedByCuisineIdsArgs = {
  cuisineIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryGetMemberDiscountsByRestaurantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DiscountOrderByInput>;
  restaurantId: Scalars['String']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMenuItemsArgs = {
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  categoryTag?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  parentMenu?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMenuItemsGroupsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMessageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetMessageHistoryArgs = {
  emailsFrom?: InputMaybe<Array<Scalars['String']['input']>>;
  emailsTo?: InputMaybe<Array<Scalars['String']['input']>>;
  marketplaceId: Scalars['ID']['input'];
  messageSubject: Scalars['String']['input'];
};


export type QueryGetMessagesArgs = {
  marketplaceId?: InputMaybe<Scalars['ID']['input']>;
  messageType?: InputMaybe<MessageType>;
};


export type QueryGetOptionItemsArgs = {
  ageRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDairyFree?: InputMaybe<Scalars['Boolean']['input']>;
  isGlutenFree?: InputMaybe<Scalars['Boolean']['input']>;
  isVegan?: InputMaybe<Scalars['Boolean']['input']>;
  isVegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetOptionsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOtterOAuthUrlArgs = {
  redirectPath: Scalars['String']['input'];
};


export type QueryGetOutletByOtterStoreIdArgs = {
  storeId: Scalars['String']['input'];
};


export type QueryGetOutletDeliveryWindowsArgs = {
  outletId: Scalars['ID']['input'];
};


export type QueryGetOutletDeliveryZoneCostsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryZoneId?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOutletMenuItemArgs = {
  menuItemId?: InputMaybe<Scalars['String']['input']>;
  outletId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOutletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactEmailContains?: InputMaybe<Scalars['String']['input']>;
  contactNameContains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  hasOpeningTimes?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectPayment?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlineOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderToTableEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSubsidised?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketplaceNameContains?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<OutletOrderByInput>;
  restaurantId?: InputMaybe<Scalars['String']['input']>;
  restaurantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  restaurantNameContains?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetOutletsByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryGetPosDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPartnersArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPinpointMarketplacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RegionalMarketplaceOrderByInput>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetRefundReportArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type QueryGetRejectionsReportByOutletArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  marketplaceId: Scalars['String']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetRejectionsReportHeatmapArgs = {
  outletId: Scalars['String']['input'];
  weeks?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetRestaurantNamesWithContactsForMarketplaceArgs = {
  marketplaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowDefaultMinimumPurchasePercentage?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactEmailContains?: InputMaybe<Scalars['String']['input']>;
  contactNameContains?: InputMaybe<Scalars['String']['input']>;
  cuisineIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defaultMinimumPurchasePricePercentage?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<RestaurantOrderByInput>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  statusOnboarding?: InputMaybe<Array<RestaurantStatusOnboarding>>;
};


export type QueryGetRestaurantsByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryGetReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReviewOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type QueryGetSameWindowOrdersCountArgs = {
  orderId: Scalars['String']['input'];
};


export type QueryGetSegmentArgs = {
  marketplaceId: Scalars['ID']['input'];
  segmentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSegmentAttributeFiltersArgs = {
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetSegmentAttributeValuesArgs = {
  attribute: Scalars['String']['input'];
  marketplaceId: Scalars['ID']['input'];
};


export type QueryGetSegmentsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId: Scalars['ID']['input'];
  nameContains?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetStripeClientIdArgs = {
  marketplaceId: Scalars['String']['input'];
};


export type QueryGetTerminalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyNameContains?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderBy?: InputMaybe<TerminalOrderByInput>;
  outletId?: InputMaybe<Scalars['ID']['input']>;
  serialNumberContains?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  softwareVersion?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransactionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GetTransactionsWhereArgs>;
};


export type QueryGetUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGetVisitorsArgs = {
  date: Scalars['String']['input'];
  outletId: Scalars['ID']['input'];
};


export type QueryLoyaltyCardArgs = {
  id: Scalars['String']['input'];
};


export type QueryLoyaltyCardLiabilityForAnalyticsArgs = {
  discountId: Scalars['String']['input'];
};


export type QueryMarketplaceOrdersWithLoyaltyCardArgs = {
  discountId: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type QueryMarketplaceOrdersWithVouchersArgs = {
  discountId: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};


export type QueryOnboardingInfoArgs = {
  marketplaceKey: Scalars['String']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  acceptedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryNetworkProvider_in?: InputMaybe<Array<DeliveryProvidersEnum>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentMethod_in?: InputMaybe<Array<OrderFulfillmentMethods>>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplaceId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<OrdersOrderBy>;
  orderNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderStatus_in?: InputMaybe<Array<OrderStatus>>;
  orderStatus_not_in?: InputMaybe<Array<OrderStatus>>;
  outletId?: InputMaybe<Scalars['ID']['input']>;
  outletId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  partnerId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethod_in?: InputMaybe<Array<PaymentMethod>>;
  restaurantId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOutletsForPinpointApplicationIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketplacePinpointApplicationId: Scalars['String']['input'];
  orderBy?: InputMaybe<OutletOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPayoutArgs = {
  createdAt_gte: Scalars['DateTime']['input'];
  createdAt_lte: Scalars['DateTime']['input'];
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryQueueInvoiceEmailCountArgs = {
  dateFrom_gte: Scalars['DateTime']['input'];
  dateTo_lte?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryReconcileArgs = {
  createdAt_gte: Scalars['DateTime']['input'];
  createdAt_lte: Scalars['DateTime']['input'];
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryRevenueArgs = {
  createdAt_gte: Scalars['DateTime']['input'];
  createdAt_lte: Scalars['DateTime']['input'];
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QuerySalesArgs = {
  createdAt_gte: Scalars['DateTime']['input'];
  createdAt_lte: Scalars['DateTime']['input'];
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QuerySalesByArgs = {
  endDate: Scalars['DateTime']['input'];
  marketplaceId?: InputMaybe<Scalars['String']['input']>;
  outlet?: InputMaybe<Scalars['String']['input']>;
  period: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QuerySalesItemsArgs = {
  endDate: Scalars['DateTime']['input'];
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  outletIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate: Scalars['DateTime']['input'];
};


export type QueryTablesArgs = {
  outletId: Scalars['ID']['input'];
};


export type QueryTaxReportArgs = {
  ownerFilter?: InputMaybe<OwnerFilterInput>;
  range: DateRangeInput;
};


export type QueryTerminalsArgs = {
  filterType?: InputMaybe<TerminalFilterType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<TerminalsQueryOrderBy>;
  outletIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  softwareVersion?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTransactionAggregatesArgs = {
  endDate: Scalars['DateTime']['input'];
  marketplaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  outletIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate: Scalars['DateTime']['input'];
};


export type QueryVoucherArgs = {
  key: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VoucherOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhereInput>;
};


export type QueryWebhookArgs = {
  webhookId: Scalars['String']['input'];
};


export type QueryWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WebhookOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookWhereInput>;
};

export type ReconciledResponse = {
  __typename?: 'ReconciledResponse';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  marketplace?: Maybe<RegionalMarketplace>;
  orderId?: Maybe<Scalars['ID']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  outlet?: Maybe<Outlet>;
  partner?: Maybe<Partner>;
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  restaurant?: Maybe<Restaurant>;
  totalPaidToMarketplace?: Maybe<Scalars['Int']['output']>;
  totalPaidToOutlet?: Maybe<Scalars['Int']['output']>;
  totalPaidToPartner?: Maybe<Scalars['Int']['output']>;
  totalPaidToPaybox?: Maybe<Scalars['Int']['output']>;
  totalPaidToRedbox?: Maybe<Scalars['Int']['output']>;
  totalToMarketplace?: Maybe<Scalars['Int']['output']>;
  totalToOutlet?: Maybe<Scalars['Int']['output']>;
  totalToPartner?: Maybe<Scalars['Int']['output']>;
  totalToPaybox?: Maybe<Scalars['Int']['output']>;
  totalToRedbox?: Maybe<Scalars['Int']['output']>;
  transaction?: Maybe<Transaction>;
  transactionStripeId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedirectResponse = {
  __typename?: 'RedirectResponse';
  message?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type RefundReport = {
  __typename?: 'RefundReport';
  acceptedAt: Scalars['DateTime']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  marketplaceId: Scalars['ID']['output'];
  order: Order;
  orderId: Scalars['ID']['output'];
  outlet: Outlet;
  outletId: Scalars['ID']['output'];
  refundAmount: Scalars['Int']['output'];
  refundedAt: Scalars['DateTime']['output'];
  refundedInDifferentBillingPeriod: Scalars['Boolean']['output'];
  totalRefundsForOutlet: Scalars['Int']['output'];
};

export type RegionalMarketplace = {
  __typename?: 'RegionalMarketplace';
  active?: Maybe<Scalars['Boolean']['output']>;
  ageRestrictionText?: Maybe<Scalars['String']['output']>;
  allOutletsText?: Maybe<Scalars['String']['output']>;
  allowBilling: Scalars['Boolean']['output'];
  allowExtendedPreorders: Scalars['Boolean']['output'];
  allowFacebookMarketing: Scalars['Boolean']['output'];
  allowLocationSearch: Scalars['Boolean']['output'];
  allowMarketing: Scalars['Boolean']['output'];
  allowMenuItemMainImageV5: Scalars['Boolean']['output'];
  allowMenuItemOptionImageV5: Scalars['Boolean']['output'];
  allowMultiOutletTerminal: Scalars['Boolean']['output'];
  allowOnboarding: Scalars['Boolean']['output'];
  allowOrderToTable: Scalars['Boolean']['output'];
  allowOtterIntegration: Scalars['Boolean']['output'];
  allowPendingOrderAlert: Scalars['Boolean']['output'];
  allowReorder: Scalars['Boolean']['output'];
  allowReviews?: Maybe<Scalars['Boolean']['output']>;
  allowSMSMarketing: Scalars['Boolean']['output'];
  allowSearch: Scalars['Boolean']['output'];
  allowSingleItemOrderNotes: Scalars['Boolean']['output'];
  allowedCuisines?: Maybe<Array<Scalars['String']['output']>>;
  androidVersion: Scalars['String']['output'];
  apiKeys: Array<ApiKey>;
  app?: Maybe<App>;
  appImage?: Maybe<Scalars['String']['output']>;
  appServiceComponentIcon: Scalars['String']['output'];
  appText?: Maybe<Scalars['String']['output']>;
  appTextSecondary?: Maybe<Scalars['String']['output']>;
  applePayVerification?: Maybe<Scalars['Boolean']['output']>;
  billing?: Maybe<Billing>;
  billingStatus?: Maybe<BillingSubscription>;
  brandColor?: Maybe<Scalars['String']['output']>;
  brandImage?: Maybe<Scalars['Boolean']['output']>;
  brandSecondaryColor?: Maybe<Scalars['String']['output']>;
  brandTertiaryColor?: Maybe<Scalars['String']['output']>;
  businessSegments: Array<BusinessSegment>;
  campaigns: Array<Campaign>;
  cashCollectionText?: Maybe<Scalars['String']['output']>;
  cashText?: Maybe<Scalars['String']['output']>;
  categoryEmojiOrImage: CategoryEmojiOrImage;
  channels: Channels;
  checkoutText?: Maybe<Scalars['String']['output']>;
  cname?: Maybe<Scalars['String']['output']>;
  collectionMinimumOrderValue?: Maybe<Scalars['Int']['output']>;
  collectionNoteText?: Maybe<Scalars['String']['output']>;
  companyLegalName?: Maybe<Scalars['String']['output']>;
  companyNumber?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<CompanyType>;
  confirmAddressAtPayment: Scalars['Boolean']['output'];
  contactAddress: Address;
  contactDoB?: Maybe<Scalars['DateTime']['output']>;
  contactEmail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  country: Country;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Array<Customer>>;
  customerAppBrandFolder: Scalars['String']['output'];
  customerCount?: Maybe<Scalars['Int']['output']>;
  defaultCancelText?: Maybe<Scalars['String']['output']>;
  defaultDatepickerOption: DefaultDatepickerOption;
  defaultFulfilmentOption: DefaultFulfilmentOption;
  defaultLocationOption: DefaultLocationOption;
  defaultRejectText?: Maybe<Scalars['String']['output']>;
  defaultSearch?: Maybe<SearchMethod>;
  deliveryMinimumOrderValue?: Maybe<Scalars['Int']['output']>;
  deliveryNetworks?: Maybe<Array<DeliveryNetwork>>;
  deliveryNoteText?: Maybe<Scalars['String']['output']>;
  deliveryZones?: Maybe<Array<DeliveryZone>>;
  discounts?: Maybe<Array<Discount>>;
  emailBackgroundColor: Scalars['String']['output'];
  emailBodyColor: Scalars['String']['output'];
  emailButtonColor: Scalars['String']['output'];
  emailFontColor: Scalars['String']['output'];
  emailFooter: Scalars['String']['output'];
  emailFooterColor: Scalars['String']['output'];
  emailImageUrl: Scalars['String']['output'];
  emailReceiptOnOrder?: Maybe<Scalars['Boolean']['output']>;
  emailSenderUsername?: Maybe<Scalars['String']['output']>;
  emailShowCustomLogo: Scalars['Boolean']['output'];
  emailShowImage: Scalars['Boolean']['output'];
  enableAnalytics?: Maybe<Scalars['Boolean']['output']>;
  enableCustomerV2Client?: Maybe<Scalars['Boolean']['output']>;
  enableLoyalty: Scalars['Boolean']['output'];
  enablePayboxEnterprise?: Maybe<Scalars['Boolean']['output']>;
  enableRedirectFromLandingPage?: Maybe<Scalars['Boolean']['output']>;
  facebookAdAccountId?: Maybe<Scalars['String']['output']>;
  facebookAdAccountName?: Maybe<Scalars['String']['output']>;
  facebookAudienceId?: Maybe<Scalars['String']['output']>;
  facebookAudienceName?: Maybe<Scalars['String']['output']>;
  facebookLastSyncDate?: Maybe<Scalars['DateTime']['output']>;
  facebookLastSyncTotalCustomers?: Maybe<Scalars['Int']['output']>;
  facebookPixelId: Scalars['String']['output'];
  faviconImage?: Maybe<Scalars['String']['output']>;
  featureFAQ?: Maybe<Scalars['Boolean']['output']>;
  featureLogin?: Maybe<Scalars['Boolean']['output']>;
  featureOrdering?: Maybe<Scalars['Boolean']['output']>;
  featurePromotions?: Maybe<Scalars['Boolean']['output']>;
  featureRecruit?: Maybe<Scalars['Boolean']['output']>;
  featureRegister?: Maybe<Scalars['Boolean']['output']>;
  footerLinks?: Maybe<Array<Maybe<CustomLink>>>;
  ga4Id: Scalars['String']['output'];
  geoFence?: Maybe<Scalars['Json']['output']>;
  headerLinks?: Maybe<Array<Maybe<CustomLink>>>;
  heroImage?: Maybe<Scalars['String']['output']>;
  heroText?: Maybe<Scalars['String']['output']>;
  heroTextSecondary?: Maybe<Scalars['String']['output']>;
  hideCategoriesListOnMobile: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  invoiceBcc: Scalars['String']['output'];
  invoiceEmail: Scalars['String']['output'];
  invoiceEmailCustomText?: Maybe<Scalars['String']['output']>;
  invoiceEnabled?: Maybe<Scalars['Boolean']['output']>;
  invoiceTextFooter?: Maybe<Scalars['String']['output']>;
  invoices: Array<BillingInvoicePdf>;
  iosVersion: Scalars['String']['output'];
  isDeactivated: Scalars['Boolean']['output'];
  isVATregistered?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  kioskAllowSearch: Scalars['Boolean']['output'];
  kioskMenuListItemStyle: MenuListItemStyle;
  kioskOptionsListStyle: OptionsListStyle;
  launchedAt?: Maybe<Scalars['DateTime']['output']>;
  legalAllergy?: Maybe<Scalars['String']['output']>;
  legalCookies?: Maybe<Scalars['String']['output']>;
  legalPolicy?: Maybe<Scalars['String']['output']>;
  legalPrivacy?: Maybe<Scalars['String']['output']>;
  legalTerms?: Maybe<Scalars['String']['output']>;
  logoImage?: Maybe<Scalars['String']['output']>;
  menuListGroupStyle: MenuListGroupStyle;
  menuListItemStyle: MenuListItemStyle;
  merchantCommissionCharge?: Maybe<Scalars['Int']['output']>;
  merchantComparisonDescription?: Maybe<Scalars['String']['output']>;
  merchantComparisonPercentage?: Maybe<Scalars['Float']['output']>;
  merchantTableCommissionCharge?: Maybe<Scalars['Int']['output']>;
  merchantType?: Maybe<MerchantType>;
  messages?: Maybe<Array<Message>>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  minAppVersionSupported: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nowFilter: OutletNowFilter;
  onboardingDescription?: Maybe<Scalars['String']['output']>;
  optInAllowedCuisines?: Maybe<Scalars['Boolean']['output']>;
  optionsListStyle: OptionsListStyle;
  orderHoldInterval?: Maybe<Scalars['Int']['output']>;
  orderMode?: Maybe<OrderingMode>;
  orderNoteText?: Maybe<Scalars['String']['output']>;
  outletCollectionVisibilityRadiusKM: Scalars['Int']['output'];
  outletOpenStatus: OutletOpenStatus;
  outlets?: Maybe<Array<Outlet>>;
  owners?: Maybe<Array<User>>;
  partner?: Maybe<Partner>;
  partnerCharge?: Maybe<Scalars['Int']['output']>;
  partnerChargeDescription?: Maybe<Scalars['String']['output']>;
  partnerChargeMerchantVat?: Maybe<Scalars['Boolean']['output']>;
  partnerCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerFee?: Maybe<Scalars['Float']['output']>;
  partnerTableCharge?: Maybe<Scalars['Int']['output']>;
  partnerTableChargeDescription?: Maybe<Scalars['String']['output']>;
  partnerTableCommissionFee?: Maybe<Scalars['Float']['output']>;
  partnerTableFee?: Maybe<Scalars['Float']['output']>;
  paymentMethods: Array<PaymentMethod>;
  pinpointApplicationId?: Maybe<Scalars['String']['output']>;
  pinpointAwsAccountId?: Maybe<Scalars['String']['output']>;
  pinpointGcmKey?: Maybe<Scalars['String']['output']>;
  pinpointNotificationStrategy: PinpointNotificationStrategy;
  platformCharge?: Maybe<Scalars['Int']['output']>;
  platformFee?: Maybe<Scalars['Float']['output']>;
  platformTableCharge?: Maybe<Scalars['Int']['output']>;
  platformTableFee?: Maybe<Scalars['Float']['output']>;
  pollInterval?: Maybe<Scalars['Int']['output']>;
  promptForUpdates: Scalars['Boolean']['output'];
  receiptHideCustomerPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  receiptHideMarketplace?: Maybe<Scalars['Boolean']['output']>;
  receiptHideOutlet?: Maybe<Scalars['Boolean']['output']>;
  receiptLargeDeliveryNotes?: Maybe<Scalars['Boolean']['output']>;
  receiptLargeOrderNotes?: Maybe<Scalars['Boolean']['output']>;
  receiptMessage?: Maybe<Scalars['String']['output']>;
  segments: Array<Segment>;
  sesMessaging?: Maybe<Scalars['String']['output']>;
  sesSource?: Maybe<Scalars['String']['output']>;
  sesSourceArn?: Maybe<Scalars['String']['output']>;
  smsSenderId?: Maybe<Scalars['String']['output']>;
  socialURLFacebook?: Maybe<Scalars['String']['output']>;
  socialURLInstagram?: Maybe<Scalars['String']['output']>;
  socialURLTikTok?: Maybe<Scalars['String']['output']>;
  socialURLTwitter?: Maybe<Scalars['String']['output']>;
  specialInstructionsText?: Maybe<Scalars['String']['output']>;
  storeURLApple?: Maybe<Scalars['String']['output']>;
  storeURLGooglePlay?: Maybe<Scalars['String']['output']>;
  stripeAccessId?: Maybe<Scalars['String']['output']>;
  stripeAccountSession?: Maybe<StripeAccountSession>;
  stripeConnect?: Maybe<StripeConnect>;
  stripeCustomerPayboxId?: Maybe<Scalars['String']['output']>;
  stripeDirectPayment?: Maybe<Scalars['Boolean']['output']>;
  stripeEnterpriseId?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  stripeLivemode?: Maybe<Scalars['Boolean']['output']>;
  stripeOnboarding?: Maybe<Scalars['Boolean']['output']>;
  stripePublicId?: Maybe<Scalars['String']['output']>;
  stripeRefreshToken?: Maybe<Scalars['String']['output']>;
  stripeTransactions?: Maybe<Array<Maybe<StripeTransaction>>>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  tableMinimumOrderValue?: Maybe<Scalars['Int']['output']>;
  tableNoteText?: Maybe<Scalars['String']['output']>;
  taxReference?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadInProgress?: Maybe<Scalars['Boolean']['output']>;
  urlPath: Scalars['String']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
  vouchers?: Maybe<Array<Voucher>>;
  webhooks: Array<Webhook>;
  websiteCnameCertificateArn?: Maybe<Scalars['String']['output']>;
};


export type RegionalMarketplaceCustomerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type RegionalMarketplaceDeliveryNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DeliveryNetworkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryNetworkWhereInput>;
};


export type RegionalMarketplaceDeliveryZonesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DeliveryZoneOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliveryZoneWhereInput>;
};


export type RegionalMarketplaceDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DiscountOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DiscountWhereInput>;
};


export type RegionalMarketplaceOutletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutletOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type RegionalMarketplaceOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type RegionalMarketplaceVouchersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<VoucherOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhereInput>;
};

export enum RegionalMarketplaceOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  AgeRestrictionTextAsc = 'ageRestrictionText_ASC',
  AgeRestrictionTextDesc = 'ageRestrictionText_DESC',
  AllowOrderToTableAsc = 'allowOrderToTable_ASC',
  AllowOrderToTableDesc = 'allowOrderToTable_DESC',
  AllowReviewsAsc = 'allowReviews_ASC',
  AllowReviewsDesc = 'allowReviews_DESC',
  AppImageAsc = 'appImage_ASC',
  AppImageDesc = 'appImage_DESC',
  AppTextSecondaryAsc = 'appTextSecondary_ASC',
  AppTextSecondaryDesc = 'appTextSecondary_DESC',
  AppTextAsc = 'appText_ASC',
  AppTextDesc = 'appText_DESC',
  BrandColorAsc = 'brandColor_ASC',
  BrandColorDesc = 'brandColor_DESC',
  BrandImageAsc = 'brandImage_ASC',
  BrandImageDesc = 'brandImage_DESC',
  BrandSecondaryColorAsc = 'brandSecondaryColor_ASC',
  BrandSecondaryColorDesc = 'brandSecondaryColor_DESC',
  BrandTertiaryColorAsc = 'brandTertiaryColor_ASC',
  BrandTertiaryColorDesc = 'brandTertiaryColor_DESC',
  CnameAsc = 'cname_ASC',
  CnameDesc = 'cname_DESC',
  CollectionMinimumOrderValueAsc = 'collectionMinimumOrderValue_ASC',
  CollectionMinimumOrderValueDesc = 'collectionMinimumOrderValue_DESC',
  CompanyLegalNameAsc = 'companyLegalName_ASC',
  CompanyLegalNameDesc = 'companyLegalName_DESC',
  CompanyNumberAsc = 'companyNumber_ASC',
  CompanyNumberDesc = 'companyNumber_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultCancelTextAsc = 'defaultCancelText_ASC',
  DefaultCancelTextDesc = 'defaultCancelText_DESC',
  DefaultRejectTextAsc = 'defaultRejectText_ASC',
  DefaultRejectTextDesc = 'defaultRejectText_DESC',
  DeliveryMinimumOrderValueAsc = 'deliveryMinimumOrderValue_ASC',
  DeliveryMinimumOrderValueDesc = 'deliveryMinimumOrderValue_DESC',
  EnableAnalyticsAsc = 'enableAnalytics_ASC',
  EnableAnalyticsDesc = 'enableAnalytics_DESC',
  EnableCustomerV2ClientAsc = 'enableCustomerV2Client_ASC',
  EnableCustomerV2ClientDesc = 'enableCustomerV2Client_DESC',
  FaviconImageAsc = 'faviconImage_ASC',
  FaviconImageDesc = 'faviconImage_DESC',
  FeatureFaqAsc = 'featureFAQ_ASC',
  FeatureFaqDesc = 'featureFAQ_DESC',
  FeatureLoginAsc = 'featureLogin_ASC',
  FeatureLoginDesc = 'featureLogin_DESC',
  FeatureOrderingAsc = 'featureOrdering_ASC',
  FeatureOrderingDesc = 'featureOrdering_DESC',
  FeaturePromotionsAsc = 'featurePromotions_ASC',
  FeaturePromotionsDesc = 'featurePromotions_DESC',
  FeatureRecruitAsc = 'featureRecruit_ASC',
  FeatureRecruitDesc = 'featureRecruit_DESC',
  FeatureRegisterAsc = 'featureRegister_ASC',
  FeatureRegisterDesc = 'featureRegister_DESC',
  GeoFenceAsc = 'geoFence_ASC',
  GeoFenceDesc = 'geoFence_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HeroTextSecondaryAsc = 'heroTextSecondary_ASC',
  HeroTextSecondaryDesc = 'heroTextSecondary_DESC',
  HeroTextAsc = 'heroText_ASC',
  HeroTextDesc = 'heroText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  IsVaTregisteredAsc = 'isVATregistered_ASC',
  IsVaTregisteredDesc = 'isVATregistered_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LegalAllergyAsc = 'legalAllergy_ASC',
  LegalAllergyDesc = 'legalAllergy_DESC',
  LegalCookiesAsc = 'legalCookies_ASC',
  LegalCookiesDesc = 'legalCookies_DESC',
  LegalPolicyAsc = 'legalPolicy_ASC',
  LegalPolicyDesc = 'legalPolicy_DESC',
  LegalPrivacyAsc = 'legalPrivacy_ASC',
  LegalPrivacyDesc = 'legalPrivacy_DESC',
  LegalTermsAsc = 'legalTerms_ASC',
  LegalTermsDesc = 'legalTerms_DESC',
  LogoImageAsc = 'logoImage_ASC',
  LogoImageDesc = 'logoImage_DESC',
  MerchantTypeAsc = 'merchantType_ASC',
  MerchantTypeDesc = 'merchantType_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaKeywordsAsc = 'metaKeywords_ASC',
  MetaKeywordsDesc = 'metaKeywords_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderHoldIntervalAsc = 'orderHoldInterval_ASC',
  OrderHoldIntervalDesc = 'orderHoldInterval_DESC',
  OrderModeAsc = 'orderMode_ASC',
  OrderModeDesc = 'orderMode_DESC',
  PartnerChargeDescriptionAsc = 'partnerChargeDescription_ASC',
  PartnerChargeDescriptionDesc = 'partnerChargeDescription_DESC',
  PartnerChargeAsc = 'partnerCharge_ASC',
  PartnerChargeDesc = 'partnerCharge_DESC',
  PartnerCommissionFeeAsc = 'partnerCommissionFee_ASC',
  PartnerCommissionFeeDesc = 'partnerCommissionFee_DESC',
  PartnerFeeAsc = 'partnerFee_ASC',
  PartnerFeeDesc = 'partnerFee_DESC',
  PlatformChargeAsc = 'platformCharge_ASC',
  PlatformChargeDesc = 'platformCharge_DESC',
  PlatformFeeAsc = 'platformFee_ASC',
  PlatformFeeDesc = 'platformFee_DESC',
  ReceiptHideCustomerPhoneNumberAsc = 'receiptHideCustomerPhoneNumber_ASC',
  ReceiptHideCustomerPhoneNumberDesc = 'receiptHideCustomerPhoneNumber_DESC',
  ReceiptHideMarketplaceAsc = 'receiptHideMarketplace_ASC',
  ReceiptHideMarketplaceDesc = 'receiptHideMarketplace_DESC',
  ReceiptHideOutletAsc = 'receiptHideOutlet_ASC',
  ReceiptHideOutletDesc = 'receiptHideOutlet_DESC',
  ReceiptLargeDeliveryNotesAsc = 'receiptLargeDeliveryNotes_ASC',
  ReceiptLargeDeliveryNotesDesc = 'receiptLargeDeliveryNotes_DESC',
  ReceiptLargeOrderNotesAsc = 'receiptLargeOrderNotes_ASC',
  ReceiptLargeOrderNotesDesc = 'receiptLargeOrderNotes_DESC',
  ReceiptMessageAsc = 'receiptMessage_ASC',
  ReceiptMessageDesc = 'receiptMessage_DESC',
  SocialUrlFacebookAsc = 'socialURLFacebook_ASC',
  SocialUrlFacebookDesc = 'socialURLFacebook_DESC',
  SocialUrlInstagramAsc = 'socialURLInstagram_ASC',
  SocialUrlInstagramDesc = 'socialURLInstagram_DESC',
  SocialUrlTwitterAsc = 'socialURLTwitter_ASC',
  SocialUrlTwitterDesc = 'socialURLTwitter_DESC',
  StoreUrlAppleAsc = 'storeURLApple_ASC',
  StoreUrlAppleDesc = 'storeURLApple_DESC',
  StoreUrlGooglePlayAsc = 'storeURLGooglePlay_ASC',
  StoreUrlGooglePlayDesc = 'storeURLGooglePlay_DESC',
  StripeAccessIdAsc = 'stripeAccessId_ASC',
  StripeAccessIdDesc = 'stripeAccessId_DESC',
  StripeIdAsc = 'stripeId_ASC',
  StripeIdDesc = 'stripeId_DESC',
  StripeLivemodeAsc = 'stripeLivemode_ASC',
  StripeLivemodeDesc = 'stripeLivemode_DESC',
  StripePublicIdAsc = 'stripePublicId_ASC',
  StripePublicIdDesc = 'stripePublicId_DESC',
  StripeRefreshTokenAsc = 'stripeRefreshToken_ASC',
  StripeRefreshTokenDesc = 'stripeRefreshToken_DESC',
  SupportEmailAsc = 'supportEmail_ASC',
  SupportEmailDesc = 'supportEmail_DESC',
  SupportPhoneAsc = 'supportPhone_ASC',
  SupportPhoneDesc = 'supportPhone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrackingIdAsc = 'trackingId_ASC',
  TrackingIdDesc = 'trackingId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC'
}

export type RegionalMarketplacePartial = {
  __typename?: 'RegionalMarketplacePartial';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optInAllowedCuisines?: Maybe<Scalars['Boolean']['output']>;
};

export type RegionalMarketplaceWhereInput = {
  AND?: InputMaybe<Array<RegionalMarketplaceWhereInput>>;
  NOT?: InputMaybe<Array<RegionalMarketplaceWhereInput>>;
  OR?: InputMaybe<Array<RegionalMarketplaceWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  ageRestrictionText?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_contains?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_ends_with?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_gt?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_gte?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ageRestrictionText_lt?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_lte?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_not?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_not_contains?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  ageRestrictionText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  ageRestrictionText_starts_with?: InputMaybe<Scalars['String']['input']>;
  allowOrderToTable?: InputMaybe<Scalars['Boolean']['input']>;
  allowOrderToTable_not?: InputMaybe<Scalars['Boolean']['input']>;
  allowReviews?: InputMaybe<Scalars['Boolean']['input']>;
  allowReviews_not?: InputMaybe<Scalars['Boolean']['input']>;
  appImage?: InputMaybe<Scalars['String']['input']>;
  appImage_contains?: InputMaybe<Scalars['String']['input']>;
  appImage_ends_with?: InputMaybe<Scalars['String']['input']>;
  appImage_gt?: InputMaybe<Scalars['String']['input']>;
  appImage_gte?: InputMaybe<Scalars['String']['input']>;
  appImage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appImage_lt?: InputMaybe<Scalars['String']['input']>;
  appImage_lte?: InputMaybe<Scalars['String']['input']>;
  appImage_not?: InputMaybe<Scalars['String']['input']>;
  appImage_not_contains?: InputMaybe<Scalars['String']['input']>;
  appImage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appImage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appImage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appImage_starts_with?: InputMaybe<Scalars['String']['input']>;
  appText?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_contains?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_ends_with?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_gt?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_gte?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appTextSecondary_lt?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_lte?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_not?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_not_contains?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appTextSecondary_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appTextSecondary_starts_with?: InputMaybe<Scalars['String']['input']>;
  appText_contains?: InputMaybe<Scalars['String']['input']>;
  appText_ends_with?: InputMaybe<Scalars['String']['input']>;
  appText_gt?: InputMaybe<Scalars['String']['input']>;
  appText_gte?: InputMaybe<Scalars['String']['input']>;
  appText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appText_lt?: InputMaybe<Scalars['String']['input']>;
  appText_lte?: InputMaybe<Scalars['String']['input']>;
  appText_not?: InputMaybe<Scalars['String']['input']>;
  appText_not_contains?: InputMaybe<Scalars['String']['input']>;
  appText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  appText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  appText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  appText_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandColor_contains?: InputMaybe<Scalars['String']['input']>;
  brandColor_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_gt?: InputMaybe<Scalars['String']['input']>;
  brandColor_gte?: InputMaybe<Scalars['String']['input']>;
  brandColor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandColor_lt?: InputMaybe<Scalars['String']['input']>;
  brandColor_lte?: InputMaybe<Scalars['String']['input']>;
  brandColor_not?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandColor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandColor_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandImage?: InputMaybe<Scalars['Boolean']['input']>;
  brandImage_not?: InputMaybe<Scalars['Boolean']['input']>;
  brandSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_contains?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_gt?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_gte?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandSecondaryColor_lt?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_lte?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_not?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandSecondaryColor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandSecondaryColor_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_contains?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_gt?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_gte?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandTertiaryColor_lt?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_lte?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_not?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  brandTertiaryColor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  brandTertiaryColor_starts_with?: InputMaybe<Scalars['String']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  cname_contains?: InputMaybe<Scalars['String']['input']>;
  cname_ends_with?: InputMaybe<Scalars['String']['input']>;
  cname_gt?: InputMaybe<Scalars['String']['input']>;
  cname_gte?: InputMaybe<Scalars['String']['input']>;
  cname_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cname_lt?: InputMaybe<Scalars['String']['input']>;
  cname_lte?: InputMaybe<Scalars['String']['input']>;
  cname_not?: InputMaybe<Scalars['String']['input']>;
  cname_not_contains?: InputMaybe<Scalars['String']['input']>;
  cname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  cname_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  cname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  cname_starts_with?: InputMaybe<Scalars['String']['input']>;
  collectionMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_gt?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_gte?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  collectionMinimumOrderValue_lt?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_lte?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_not?: InputMaybe<Scalars['Int']['input']>;
  collectionMinimumOrderValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  companyLegalName?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_contains?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_gt?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_gte?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyLegalName_lt?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_lte?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyLegalName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyLegalName_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  companyNumber_contains?: InputMaybe<Scalars['String']['input']>;
  companyNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_gt?: InputMaybe<Scalars['String']['input']>;
  companyNumber_gte?: InputMaybe<Scalars['String']['input']>;
  companyNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyNumber_lt?: InputMaybe<Scalars['String']['input']>;
  companyNumber_lte?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  companyNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  companyNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<AddressWhereInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_lte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactName_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_gt?: InputMaybe<Scalars['String']['input']>;
  contactName_gte?: InputMaybe<Scalars['String']['input']>;
  contactName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_lt?: InputMaybe<Scalars['String']['input']>;
  contactName_lte?: InputMaybe<Scalars['String']['input']>;
  contactName_not?: InputMaybe<Scalars['String']['input']>;
  contactName_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactPhone_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_lte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer_every?: InputMaybe<CustomerWhereInput>;
  customer_none?: InputMaybe<CustomerWhereInput>;
  customer_some?: InputMaybe<CustomerWhereInput>;
  defaultCancelText?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_contains?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_ends_with?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_gt?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_gte?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultCancelText_lt?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_lte?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_not?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_not_contains?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultCancelText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  defaultCancelText_starts_with?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_contains?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_ends_with?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_gt?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_gte?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultRejectText_lt?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_lte?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_not?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_not_contains?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultRejectText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  defaultRejectText_starts_with?: InputMaybe<Scalars['String']['input']>;
  deliveryMinimumOrderValue?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_gt?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_gte?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryMinimumOrderValue_lt?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_lte?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_not?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinimumOrderValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryNetworks_every?: InputMaybe<DeliveryNetworkWhereInput>;
  deliveryNetworks_none?: InputMaybe<DeliveryNetworkWhereInput>;
  deliveryNetworks_some?: InputMaybe<DeliveryNetworkWhereInput>;
  deliveryZones_every?: InputMaybe<DeliveryZoneWhereInput>;
  deliveryZones_none?: InputMaybe<DeliveryZoneWhereInput>;
  deliveryZones_some?: InputMaybe<DeliveryZoneWhereInput>;
  discounts_every?: InputMaybe<DiscountWhereInput>;
  discounts_none?: InputMaybe<DiscountWhereInput>;
  discounts_some?: InputMaybe<DiscountWhereInput>;
  emailReceiptOnOrder?: InputMaybe<Scalars['Boolean']['input']>;
  emailReceiptOnOrder_not?: InputMaybe<Scalars['Boolean']['input']>;
  enableAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  enableAnalytics_not?: InputMaybe<Scalars['Boolean']['input']>;
  enableCustomerV2Client?: InputMaybe<Scalars['Boolean']['input']>;
  enableCustomerV2Client_not?: InputMaybe<Scalars['Boolean']['input']>;
  faviconImage?: InputMaybe<Scalars['String']['input']>;
  faviconImage_contains?: InputMaybe<Scalars['String']['input']>;
  faviconImage_ends_with?: InputMaybe<Scalars['String']['input']>;
  faviconImage_gt?: InputMaybe<Scalars['String']['input']>;
  faviconImage_gte?: InputMaybe<Scalars['String']['input']>;
  faviconImage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  faviconImage_lt?: InputMaybe<Scalars['String']['input']>;
  faviconImage_lte?: InputMaybe<Scalars['String']['input']>;
  faviconImage_not?: InputMaybe<Scalars['String']['input']>;
  faviconImage_not_contains?: InputMaybe<Scalars['String']['input']>;
  faviconImage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  faviconImage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  faviconImage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  faviconImage_starts_with?: InputMaybe<Scalars['String']['input']>;
  featureFAQ?: InputMaybe<Scalars['Boolean']['input']>;
  featureFAQ_not?: InputMaybe<Scalars['Boolean']['input']>;
  featureLogin?: InputMaybe<Scalars['Boolean']['input']>;
  featureLogin_not?: InputMaybe<Scalars['Boolean']['input']>;
  featureOrdering?: InputMaybe<Scalars['Boolean']['input']>;
  featureOrdering_not?: InputMaybe<Scalars['Boolean']['input']>;
  featurePromotions?: InputMaybe<Scalars['Boolean']['input']>;
  featurePromotions_not?: InputMaybe<Scalars['Boolean']['input']>;
  featureRecruit?: InputMaybe<Scalars['Boolean']['input']>;
  featureRecruit_not?: InputMaybe<Scalars['Boolean']['input']>;
  featureRegister?: InputMaybe<Scalars['Boolean']['input']>;
  featureRegister_not?: InputMaybe<Scalars['Boolean']['input']>;
  heroImage?: InputMaybe<Scalars['String']['input']>;
  heroImage_contains?: InputMaybe<Scalars['String']['input']>;
  heroImage_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroImage_gt?: InputMaybe<Scalars['String']['input']>;
  heroImage_gte?: InputMaybe<Scalars['String']['input']>;
  heroImage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroImage_lt?: InputMaybe<Scalars['String']['input']>;
  heroImage_lte?: InputMaybe<Scalars['String']['input']>;
  heroImage_not?: InputMaybe<Scalars['String']['input']>;
  heroImage_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroImage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroImage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroImage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroImage_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroText?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_contains?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_gt?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_gte?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroTextSecondary_lt?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_lte?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_not?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroTextSecondary_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroTextSecondary_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroText_contains?: InputMaybe<Scalars['String']['input']>;
  heroText_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroText_gt?: InputMaybe<Scalars['String']['input']>;
  heroText_gte?: InputMaybe<Scalars['String']['input']>;
  heroText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroText_lt?: InputMaybe<Scalars['String']['input']>;
  heroText_lte?: InputMaybe<Scalars['String']['input']>;
  heroText_not?: InputMaybe<Scalars['String']['input']>;
  heroText_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  heroText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  heroText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroText_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  image_contains?: InputMaybe<Scalars['String']['input']>;
  image_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_gt?: InputMaybe<Scalars['String']['input']>;
  image_gte?: InputMaybe<Scalars['String']['input']>;
  image_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_lt?: InputMaybe<Scalars['String']['input']>;
  image_lte?: InputMaybe<Scalars['String']['input']>;
  image_not?: InputMaybe<Scalars['String']['input']>;
  image_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  image_starts_with?: InputMaybe<Scalars['String']['input']>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeactivated_not?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalAllergy?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_contains?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_gt?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_gte?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalAllergy_lt?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_lte?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_not?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_not_contains?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalAllergy_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalAllergy_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalCookies?: InputMaybe<Scalars['String']['input']>;
  legalCookies_contains?: InputMaybe<Scalars['String']['input']>;
  legalCookies_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalCookies_gt?: InputMaybe<Scalars['String']['input']>;
  legalCookies_gte?: InputMaybe<Scalars['String']['input']>;
  legalCookies_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalCookies_lt?: InputMaybe<Scalars['String']['input']>;
  legalCookies_lte?: InputMaybe<Scalars['String']['input']>;
  legalCookies_not?: InputMaybe<Scalars['String']['input']>;
  legalCookies_not_contains?: InputMaybe<Scalars['String']['input']>;
  legalCookies_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalCookies_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalCookies_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalCookies_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalPolicy?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_contains?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_gt?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_gte?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalPolicy_lt?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_lte?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_not?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_not_contains?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalPolicy_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalPolicy_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_contains?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_gt?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_gte?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalPrivacy_lt?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_lte?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_not?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_not_contains?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalPrivacy_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalPrivacy_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalTerms?: InputMaybe<Scalars['String']['input']>;
  legalTerms_contains?: InputMaybe<Scalars['String']['input']>;
  legalTerms_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalTerms_gt?: InputMaybe<Scalars['String']['input']>;
  legalTerms_gte?: InputMaybe<Scalars['String']['input']>;
  legalTerms_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalTerms_lt?: InputMaybe<Scalars['String']['input']>;
  legalTerms_lte?: InputMaybe<Scalars['String']['input']>;
  legalTerms_not?: InputMaybe<Scalars['String']['input']>;
  legalTerms_not_contains?: InputMaybe<Scalars['String']['input']>;
  legalTerms_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legalTerms_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  legalTerms_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalTerms_starts_with?: InputMaybe<Scalars['String']['input']>;
  logoImage?: InputMaybe<Scalars['String']['input']>;
  logoImage_contains?: InputMaybe<Scalars['String']['input']>;
  logoImage_ends_with?: InputMaybe<Scalars['String']['input']>;
  logoImage_gt?: InputMaybe<Scalars['String']['input']>;
  logoImage_gte?: InputMaybe<Scalars['String']['input']>;
  logoImage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  logoImage_lt?: InputMaybe<Scalars['String']['input']>;
  logoImage_lte?: InputMaybe<Scalars['String']['input']>;
  logoImage_not?: InputMaybe<Scalars['String']['input']>;
  logoImage_not_contains?: InputMaybe<Scalars['String']['input']>;
  logoImage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  logoImage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  logoImage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  logoImage_starts_with?: InputMaybe<Scalars['String']['input']>;
  merchantType?: InputMaybe<MerchantType>;
  merchantType_in?: InputMaybe<Array<MerchantType>>;
  merchantType_not?: InputMaybe<MerchantType>;
  merchantType_not_in?: InputMaybe<Array<MerchantType>>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaDescription_contains?: InputMaybe<Scalars['String']['input']>;
  metaDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  metaDescription_gt?: InputMaybe<Scalars['String']['input']>;
  metaDescription_gte?: InputMaybe<Scalars['String']['input']>;
  metaDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metaDescription_lt?: InputMaybe<Scalars['String']['input']>;
  metaDescription_lte?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  metaDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metaDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  metaDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_contains?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_ends_with?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_gt?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_gte?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metaKeywords_lt?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_lte?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_not?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_not_contains?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  metaKeywords_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  metaKeywords_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderHoldInterval_lt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_lte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderMode?: InputMaybe<OrderingMode>;
  orderMode_in?: InputMaybe<Array<OrderingMode>>;
  orderMode_not?: InputMaybe<OrderingMode>;
  orderMode_not_in?: InputMaybe<Array<OrderingMode>>;
  outlets_every?: InputMaybe<OutletWhereInput>;
  outlets_none?: InputMaybe<OutletWhereInput>;
  outlets_some?: InputMaybe<OutletWhereInput>;
  owners_every?: InputMaybe<UserWhereInput>;
  owners_none?: InputMaybe<UserWhereInput>;
  owners_some?: InputMaybe<UserWhereInput>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerCharge?: InputMaybe<Scalars['Int']['input']>;
  partnerChargeDescription?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_contains?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_gt?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_gte?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerChargeDescription_lt?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_lte?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_not?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerChargeDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  partnerChargeDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  partnerCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_not?: InputMaybe<Scalars['Int']['input']>;
  partnerCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerCommissionFee?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerCommissionFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerCommissionFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  pinpointApplicationId?: InputMaybe<Scalars['String']['input']>;
  platformCharge?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not?: InputMaybe<Scalars['Int']['input']>;
  platformCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFee_lt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_lte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  receiptHideCustomerPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideCustomerPhoneNumber_not?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideMarketplace?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideMarketplace_not?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideOutlet?: InputMaybe<Scalars['Boolean']['input']>;
  receiptHideOutlet_not?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeDeliveryNotes?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeDeliveryNotes_not?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeOrderNotes?: InputMaybe<Scalars['Boolean']['input']>;
  receiptLargeOrderNotes_not?: InputMaybe<Scalars['Boolean']['input']>;
  receiptMessage?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_contains?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_gt?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_gte?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiptMessage_lt?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_lte?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_not?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiptMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  receiptMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_gt?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_gte?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLFacebook_lt?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_lte?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_not?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_not_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLFacebook_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLFacebook_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_gt?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_gte?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLInstagram_lt?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_lte?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_not?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_not_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLInstagram_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLInstagram_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_gt?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_gte?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLTwitter_lt?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_lte?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_not?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_not_contains?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  socialURLTwitter_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  socialURLTwitter_starts_with?: InputMaybe<Scalars['String']['input']>;
  storeURLApple?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_contains?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_ends_with?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_gt?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_gte?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storeURLApple_lt?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_lte?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_not?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_not_contains?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storeURLApple_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  storeURLApple_starts_with?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_contains?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_ends_with?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_gt?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_gte?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storeURLGooglePlay_lt?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_lte?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_not?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_not_contains?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  storeURLGooglePlay_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  storeURLGooglePlay_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeAccessId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeAccessId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeAccessId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeId_not?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeLivemode?: InputMaybe<Scalars['Boolean']['input']>;
  stripeLivemode_not?: InputMaybe<Scalars['Boolean']['input']>;
  stripePublicId?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_gte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_lt?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_lte?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripePublicId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripePublicId_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_contains?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_gt?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_gte?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeRefreshToken_lt?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_lte?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeRefreshToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeRefreshToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportEmail_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_lt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_lte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  supportPhone_contains?: InputMaybe<Scalars['String']['input']>;
  supportPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportPhone_gt?: InputMaybe<Scalars['String']['input']>;
  supportPhone_gte?: InputMaybe<Scalars['String']['input']>;
  supportPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportPhone_lt?: InputMaybe<Scalars['String']['input']>;
  supportPhone_lte?: InputMaybe<Scalars['String']['input']>;
  supportPhone_not?: InputMaybe<Scalars['String']['input']>;
  supportPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  trackingId_contains?: InputMaybe<Scalars['String']['input']>;
  trackingId_ends_with?: InputMaybe<Scalars['String']['input']>;
  trackingId_gt?: InputMaybe<Scalars['String']['input']>;
  trackingId_gte?: InputMaybe<Scalars['String']['input']>;
  trackingId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trackingId_lt?: InputMaybe<Scalars['String']['input']>;
  trackingId_lte?: InputMaybe<Scalars['String']['input']>;
  trackingId_not?: InputMaybe<Scalars['String']['input']>;
  trackingId_not_contains?: InputMaybe<Scalars['String']['input']>;
  trackingId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  trackingId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  trackingId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  trackingId_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_lt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_lte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  vouchers_every?: InputMaybe<VoucherWhereInput>;
  vouchers_none?: InputMaybe<VoucherWhereInput>;
  vouchers_some?: InputMaybe<VoucherWhereInput>;
};

export type RejectionsReportByOutlet = {
  __typename?: 'RejectionsReportByOutlet';
  autoRejectedOrders?: Maybe<Scalars['Int']['output']>;
  autoRejectedValue?: Maybe<Scalars['Int']['output']>;
  autoRejectionRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  manualRejectedOrders?: Maybe<Scalars['Int']['output']>;
  manualRejectedValue?: Maybe<Scalars['Int']['output']>;
  manualRejectionRate?: Maybe<Scalars['Float']['output']>;
  orderBy?: Maybe<Scalars['String']['output']>;
  outlet: Outlet;
  outletId: Scalars['String']['output'];
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalRejectedOrders?: Maybe<Scalars['Int']['output']>;
  totalRejectedValue?: Maybe<Scalars['Int']['output']>;
  totalRejectionRate?: Maybe<Scalars['Float']['output']>;
  totalValue?: Maybe<Scalars['Int']['output']>;
};

export type RejectionsReportHeatmap = {
  __typename?: 'RejectionsReportHeatmap';
  autoRejectedOrders?: Maybe<Scalars['Int']['output']>;
  autoRejectedValue?: Maybe<Scalars['Int']['output']>;
  autoRejectionRate?: Maybe<Scalars['Float']['output']>;
  day: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  manualRejectedOrders?: Maybe<Scalars['Int']['output']>;
  manualRejectedValue?: Maybe<Scalars['Int']['output']>;
  manualRejectionRate?: Maybe<Scalars['Float']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalRejectedOrders?: Maybe<Scalars['Int']['output']>;
  totalRejectedValue?: Maybe<Scalars['Int']['output']>;
  totalRejectionRate?: Maybe<Scalars['Float']['output']>;
  totalValue?: Maybe<Scalars['Int']['output']>;
};

export type RequestResetPasswordResponse = {
  __typename?: 'RequestResetPasswordResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  acceptPreorders?: Maybe<Scalars['Boolean']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  allowAddOnItems?: Maybe<Scalars['Boolean']['output']>;
  allowDefaultMinimumPurchasePercentage?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  businessCategories: Array<BusinessCategory>;
  cname?: Maybe<Scalars['String']['output']>;
  collectionDeal?: Maybe<Scalars['String']['output']>;
  contactAddress: Address;
  contactEmail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  cuisines?: Maybe<Array<Cuisine>>;
  deals?: Maybe<Scalars['Boolean']['output']>;
  dealsText?: Maybe<Scalars['String']['output']>;
  defaultMinimumPurchasePricePercentage?: Maybe<Scalars['Int']['output']>;
  deliveryDeal?: Maybe<Scalars['String']['output']>;
  deliveryOptions: Array<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discounts?: Maybe<Array<Discount>>;
  displayAllOutlets?: Maybe<Scalars['Boolean']['output']>;
  enableAllergyInformation: Scalars['Boolean']['output'];
  enablePaginatedMenu: Scalars['Boolean']['output'];
  enableStackedMenu: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isVATregistered?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  menuItems?: Maybe<Array<MenuItem>>;
  menuItemsGroups?: Maybe<Array<MenuItemsGroup>>;
  merchantCommissionCharge?: Maybe<Scalars['Int']['output']>;
  merchantTableCommissionCharge?: Maybe<Scalars['Int']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  minimumBasketTotalForAddOns?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  orderHoldInterval?: Maybe<Scalars['Int']['output']>;
  outletCategoriesOverride?: Maybe<Scalars['Boolean']['output']>;
  outletOfferFlagOverride?: Maybe<Scalars['Boolean']['output']>;
  outletPromoteOverride?: Maybe<Scalars['Boolean']['output']>;
  outlets?: Maybe<Array<Outlet>>;
  owners?: Maybe<Array<User>>;
  partner?: Maybe<Partner>;
  partnerCharge?: Maybe<Scalars['Int']['output']>;
  partnerFee?: Maybe<Scalars['Float']['output']>;
  partnerTableCharge?: Maybe<Scalars['Int']['output']>;
  partnerTableFee?: Maybe<Scalars['Float']['output']>;
  promoted?: Maybe<Scalars['Boolean']['output']>;
  significantDates?: Maybe<Scalars['Json']['output']>;
  statusOnboarding?: Maybe<RestaurantStatusOnboarding>;
  stripeAccessId?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  stripeLivemode?: Maybe<Scalars['Boolean']['output']>;
  stripePublicId?: Maybe<Scalars['String']['output']>;
  stripeRefreshToken?: Maybe<Scalars['String']['output']>;
  tableDeal?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};


export type RestaurantCuisinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CuisineOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CuisineWhereInput>;
};


export type RestaurantDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DiscountOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DiscountWhereInput>;
};


export type RestaurantMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemWhereInput>;
};


export type RestaurantMenuItemsGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MenuItemsGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemsGroupWhereInput>;
};


export type RestaurantOutletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutletOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type RestaurantOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type RestaurantNamesForMarketplaceResponse = {
  __typename?: 'RestaurantNamesForMarketplaceResponse';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum RestaurantOrderByInput {
  AcceptPreordersAsc = 'acceptPreorders_ASC',
  AcceptPreordersDesc = 'acceptPreorders_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DealsTextAsc = 'dealsText_ASC',
  DealsTextDesc = 'dealsText_DESC',
  DealsAsc = 'deals_ASC',
  DealsDesc = 'deals_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  IsVaTregisteredAsc = 'isVATregistered_ASC',
  IsVaTregisteredDesc = 'isVATregistered_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderHoldIntervalAsc = 'orderHoldInterval_ASC',
  OrderHoldIntervalDesc = 'orderHoldInterval_DESC',
  PartnerFeeAsc = 'partnerFee_ASC',
  PartnerFeeDesc = 'partnerFee_DESC',
  PromotedAsc = 'promoted_ASC',
  PromotedDesc = 'promoted_DESC',
  StripeIdAsc = 'stripeId_ASC',
  StripeIdDesc = 'stripeId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VatNumberAsc = 'vatNumber_ASC',
  VatNumberDesc = 'vatNumber_DESC'
}

export type RestaurantResponse = {
  __typename?: 'RestaurantResponse';
  message?: Maybe<Scalars['String']['output']>;
  restaurant?: Maybe<Restaurant>;
};

export enum RestaurantStatusOnboarding {
  Denied = 'DENIED',
  Onboarding = 'ONBOARDING',
  Signup = 'SIGNUP'
}

export type RestaurantWhereInput = {
  AND?: InputMaybe<Array<RestaurantWhereInput>>;
  NOT?: InputMaybe<Array<RestaurantWhereInput>>;
  OR?: InputMaybe<Array<RestaurantWhereInput>>;
  acceptPreorders?: InputMaybe<Scalars['Boolean']['input']>;
  acceptPreorders_not?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  contactAddress?: InputMaybe<AddressWhereInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactEmail_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_gte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_lt?: InputMaybe<Scalars['String']['input']>;
  contactEmail_lte?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactName_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_gt?: InputMaybe<Scalars['String']['input']>;
  contactName_gte?: InputMaybe<Scalars['String']['input']>;
  contactName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_lt?: InputMaybe<Scalars['String']['input']>;
  contactName_lte?: InputMaybe<Scalars['String']['input']>;
  contactName_not?: InputMaybe<Scalars['String']['input']>;
  contactName_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactName_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  contactPhone_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_gte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_lt?: InputMaybe<Scalars['String']['input']>;
  contactPhone_lte?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  contactPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contactPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  cuisines_every?: InputMaybe<CuisineWhereInput>;
  cuisines_none?: InputMaybe<CuisineWhereInput>;
  cuisines_some?: InputMaybe<CuisineWhereInput>;
  deals?: InputMaybe<Scalars['Boolean']['input']>;
  dealsText?: InputMaybe<Scalars['String']['input']>;
  dealsText_contains?: InputMaybe<Scalars['String']['input']>;
  dealsText_ends_with?: InputMaybe<Scalars['String']['input']>;
  dealsText_gt?: InputMaybe<Scalars['String']['input']>;
  dealsText_gte?: InputMaybe<Scalars['String']['input']>;
  dealsText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dealsText_lt?: InputMaybe<Scalars['String']['input']>;
  dealsText_lte?: InputMaybe<Scalars['String']['input']>;
  dealsText_not?: InputMaybe<Scalars['String']['input']>;
  dealsText_not_contains?: InputMaybe<Scalars['String']['input']>;
  dealsText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  dealsText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  dealsText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  dealsText_starts_with?: InputMaybe<Scalars['String']['input']>;
  deals_not?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_gt?: InputMaybe<Scalars['String']['input']>;
  description_gte?: InputMaybe<Scalars['String']['input']>;
  description_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_lt?: InputMaybe<Scalars['String']['input']>;
  description_lte?: InputMaybe<Scalars['String']['input']>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  discounts_every?: InputMaybe<DiscountWhereInput>;
  discounts_none?: InputMaybe<DiscountWhereInput>;
  discounts_some?: InputMaybe<DiscountWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  image_contains?: InputMaybe<Scalars['String']['input']>;
  image_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_gt?: InputMaybe<Scalars['String']['input']>;
  image_gte?: InputMaybe<Scalars['String']['input']>;
  image_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_lt?: InputMaybe<Scalars['String']['input']>;
  image_lte?: InputMaybe<Scalars['String']['input']>;
  image_not?: InputMaybe<Scalars['String']['input']>;
  image_not_contains?: InputMaybe<Scalars['String']['input']>;
  image_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  image_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  image_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  image_starts_with?: InputMaybe<Scalars['String']['input']>;
  isVATregistered?: InputMaybe<Scalars['Boolean']['input']>;
  isVATregistered_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  menuItemsGroups_every?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItemsGroups_none?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItemsGroups_some?: InputMaybe<MenuItemsGroupWhereInput>;
  menuItems_every?: InputMaybe<MenuItemWhereInput>;
  menuItems_none?: InputMaybe<MenuItemWhereInput>;
  menuItems_some?: InputMaybe<MenuItemWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderHoldInterval?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_gte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderHoldInterval_lt?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_lte?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not?: InputMaybe<Scalars['Int']['input']>;
  orderHoldInterval_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  outlets_every?: InputMaybe<OutletWhereInput>;
  outlets_none?: InputMaybe<OutletWhereInput>;
  outlets_some?: InputMaybe<OutletWhereInput>;
  owners_every?: InputMaybe<UserWhereInput>;
  owners_none?: InputMaybe<UserWhereInput>;
  owners_some?: InputMaybe<UserWhereInput>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  promoted_not?: InputMaybe<Scalars['Boolean']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  stripeId_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_gt?: InputMaybe<Scalars['String']['input']>;
  stripeId_gte?: InputMaybe<Scalars['String']['input']>;
  stripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_lt?: InputMaybe<Scalars['String']['input']>;
  stripeId_lte?: InputMaybe<Scalars['String']['input']>;
  stripeId_not?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatNumber_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_gte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_lt?: InputMaybe<Scalars['String']['input']>;
  vatNumber_lte?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  vatNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  vatNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type RetentionRateDataRow = {
  __typename?: 'RetentionRateDataRow';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type RevenueResponse = {
  __typename?: 'RevenueResponse';
  marketplace?: Maybe<RegionalMarketplace>;
  totalCashCommission?: Maybe<Scalars['Int']['output']>;
  totalCollectionValue?: Maybe<Scalars['Int']['output']>;
  totalCommissionValue?: Maybe<Scalars['Int']['output']>;
  totalDeliveryValue?: Maybe<Scalars['Int']['output']>;
  totalDiscountValue?: Maybe<Scalars['Int']['output']>;
  totalNetworkValue?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalPaidCashValue?: Maybe<Scalars['Int']['output']>;
  totalPartnerValue?: Maybe<Scalars['Int']['output']>;
  totalPaymentProcessorValue?: Maybe<Scalars['Int']['output']>;
  totalPlatformValue?: Maybe<Scalars['Int']['output']>;
  totalRedboxValue?: Maybe<Scalars['Int']['output']>;
  totalRefunds?: Maybe<Scalars['Int']['output']>;
  totalRefundsValue?: Maybe<Scalars['Int']['output']>;
  totalRevenue?: Maybe<Scalars['Int']['output']>;
  totalTableValue?: Maybe<Scalars['Int']['output']>;
};

export type Review = {
  __typename?: 'Review';
  approved?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  deliveryTime?: Maybe<Scalars['Int']['output']>;
  foodQuality?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  order: Order;
  outlet: Outlet;
  repliedBy?: Maybe<User>;
  reply?: Maybe<Scalars['String']['output']>;
  replyDate?: Maybe<Scalars['DateTime']['output']>;
  restaurantService?: Maybe<Scalars['Int']['output']>;
  reviewText?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ReviewOrderByInput {
  ApprovedAsc = 'approved_ASC',
  ApprovedDesc = 'approved_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeliveryTimeAsc = 'deliveryTime_ASC',
  DeliveryTimeDesc = 'deliveryTime_DESC',
  FoodQualityAsc = 'foodQuality_ASC',
  FoodQualityDesc = 'foodQuality_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReplyDateAsc = 'replyDate_ASC',
  ReplyDateDesc = 'replyDate_DESC',
  ReplyAsc = 'reply_ASC',
  ReplyDesc = 'reply_DESC',
  RestaurantServiceAsc = 'restaurantService_ASC',
  RestaurantServiceDesc = 'restaurantService_DESC',
  ReviewTextAsc = 'reviewText_ASC',
  ReviewTextDesc = 'reviewText_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ReviewResponse = {
  __typename?: 'ReviewResponse';
  message?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Review>;
};

export type ReviewWhereInput = {
  AND?: InputMaybe<Array<ReviewWhereInput>>;
  NOT?: InputMaybe<Array<ReviewWhereInput>>;
  OR?: InputMaybe<Array<ReviewWhereInput>>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  approved_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer?: InputMaybe<CustomerWhereInput>;
  deliveryTime?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_gt?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_gte?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deliveryTime_lt?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_lte?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_not?: InputMaybe<Scalars['Int']['input']>;
  deliveryTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  foodQuality?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_gt?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_gte?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  foodQuality_lt?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_lte?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_not?: InputMaybe<Scalars['Int']['input']>;
  foodQuality_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<OrderWhereInput>;
  outlet?: InputMaybe<OutletWhereInput>;
  repliedBy?: InputMaybe<UserWhereInput>;
  reply?: InputMaybe<Scalars['String']['input']>;
  replyDate?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  replyDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  replyDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  reply_contains?: InputMaybe<Scalars['String']['input']>;
  reply_ends_with?: InputMaybe<Scalars['String']['input']>;
  reply_gt?: InputMaybe<Scalars['String']['input']>;
  reply_gte?: InputMaybe<Scalars['String']['input']>;
  reply_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reply_lt?: InputMaybe<Scalars['String']['input']>;
  reply_lte?: InputMaybe<Scalars['String']['input']>;
  reply_not?: InputMaybe<Scalars['String']['input']>;
  reply_not_contains?: InputMaybe<Scalars['String']['input']>;
  reply_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reply_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reply_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reply_starts_with?: InputMaybe<Scalars['String']['input']>;
  restaurantService?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_gt?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_gte?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurantService_lt?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_lte?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_not?: InputMaybe<Scalars['Int']['input']>;
  restaurantService_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  reviewText?: InputMaybe<Scalars['String']['input']>;
  reviewText_contains?: InputMaybe<Scalars['String']['input']>;
  reviewText_ends_with?: InputMaybe<Scalars['String']['input']>;
  reviewText_gt?: InputMaybe<Scalars['String']['input']>;
  reviewText_gte?: InputMaybe<Scalars['String']['input']>;
  reviewText_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewText_lt?: InputMaybe<Scalars['String']['input']>;
  reviewText_lte?: InputMaybe<Scalars['String']['input']>;
  reviewText_not?: InputMaybe<Scalars['String']['input']>;
  reviewText_not_contains?: InputMaybe<Scalars['String']['input']>;
  reviewText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  reviewText_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  reviewText_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Role = {
  __typename?: 'Role';
  canCreateRoles?: Maybe<Array<Role>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  permissionCategories?: Maybe<Array<PermissionCategory>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type RoleCanCreateRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type RolePermissionCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PermissionCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PermissionCategoryWhereInput>;
};

export enum RoleOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RoleResponse = {
  __typename?: 'RoleResponse';
  message?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  canCreateRoles_every?: InputMaybe<RoleWhereInput>;
  canCreateRoles_none?: InputMaybe<RoleWhereInput>;
  canCreateRoles_some?: InputMaybe<RoleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  permissionCategories_every?: InputMaybe<PermissionCategoryWhereInput>;
  permissionCategories_none?: InputMaybe<PermissionCategoryWhereInput>;
  permissionCategories_some?: InputMaybe<PermissionCategoryWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type SaleResponse = {
  __typename?: 'SaleResponse';
  outlet?: Maybe<Outlet>;
  totalAddOnItemsOrders?: Maybe<Scalars['Int']['output']>;
  totalAddOnItemsOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCardOrders?: Maybe<Scalars['Int']['output']>;
  totalCardOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCashOrders?: Maybe<Scalars['Int']['output']>;
  totalCashOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrders?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCourierOrders?: Maybe<Scalars['Int']['output']>;
  totalCourierOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrders?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalDiscountOrders?: Maybe<Scalars['Int']['output']>;
  totalDiscountOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalNetworkOrders?: Maybe<Scalars['Int']['output']>;
  totalNetworkOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalRefunds?: Maybe<Scalars['Int']['output']>;
  totalRefundsValue?: Maybe<Scalars['Int']['output']>;
  totalTableOrders?: Maybe<Scalars['Int']['output']>;
  totalTableOrdersValue?: Maybe<Scalars['Int']['output']>;
};

export type SalesItem = {
  __typename?: 'SalesItem';
  grossTotal: Scalars['Int']['output'];
  menuItem: Scalars['Json']['output'];
  menuItemId: Scalars['ID']['output'];
  netTotal: Scalars['Int']['output'];
  outlet: Outlet;
  outletMenuItemId: Scalars['ID']['output'];
  sales: Scalars['Int']['output'];
};

export enum SearchMethod {
  Coordinates = 'COORDINATES',
  List = 'LIST',
  Postcode = 'POSTCODE'
}

export type Segment = {
  __typename?: 'Segment';
  createdAt: Scalars['String']['output'];
  estimate: Scalars['String']['output'];
  filterGroups: Array<FilterGroup>;
  filterGroupsType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  marketplaceId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type SegmentAttributeFilter = {
  __typename?: 'SegmentAttributeFilter';
  comparators: Array<SegmentAttributeFilterComparator>;
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SegmentAttributeFilterComparator = {
  __typename?: 'SegmentAttributeFilterComparator';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SegmentAttributeFiltersResponse = {
  __typename?: 'SegmentAttributeFiltersResponse';
  attributes: Array<SegmentAttributeFilter>;
};

export type SegmentAttributeValue = {
  __typename?: 'SegmentAttributeValue';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SegmentAttributeValuesResponse = {
  __typename?: 'SegmentAttributeValuesResponse';
  attributeValues: Array<SegmentAttributeValue>;
};

export enum SegmentOrderBy {
  Alphabetical = 'ALPHABETICAL',
  Distance = 'DISTANCE',
  MinOrder = 'MIN_ORDER',
  OpenStatus = 'OPEN_STATUS',
  Random = 'RANDOM',
  Time = 'TIME'
}

export type SegmentResponse = {
  __typename?: 'SegmentResponse';
  segment?: Maybe<Segment>;
};

export type SegmentsResponse = {
  __typename?: 'SegmentsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  segments: Array<Segment>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SelfUpdatePasswordResponse = {
  __typename?: 'SelfUpdatePasswordResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type SendTestNotificationResponse = {
  __typename?: 'SendTestNotificationResponse';
  messageDeliveryStatuses: Array<MessageDeliveryStatus>;
};

export type SetSaleResponse = {
  __typename?: 'SetSaleResponse';
  averageOrderValue?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  totalCardOrders?: Maybe<Scalars['Int']['output']>;
  totalCardOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCashOrders?: Maybe<Scalars['Int']['output']>;
  totalCashOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrders?: Maybe<Scalars['Int']['output']>;
  totalCollectionOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrders?: Maybe<Scalars['Int']['output']>;
  totalDeliveryOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalOrdersValue?: Maybe<Scalars['Int']['output']>;
  totalRefunds?: Maybe<Scalars['Int']['output']>;
  totalRefundsValue?: Maybe<Scalars['Int']['output']>;
  totalTableOrders?: Maybe<Scalars['Int']['output']>;
  totalTableOrdersValue?: Maybe<Scalars['Int']['output']>;
};

export type SimpleMutationResponse = {
  __typename?: 'SimpleMutationResponse';
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SoldOutUntil = {
  id: Scalars['String']['input'];
  soldOutUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StripeAccountSession = {
  __typename?: 'StripeAccountSession';
  clientPublicKey?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
};

export type StripeClientIdResponse = {
  __typename?: 'StripeClientIdResponse';
  message?: Maybe<Scalars['String']['output']>;
  payboxClientId?: Maybe<Scalars['String']['output']>;
  stripeClientId?: Maybe<Scalars['String']['output']>;
};

export type StripeConnect = {
  __typename?: 'StripeConnect';
  accountType?: Maybe<Scalars['String']['output']>;
  accounts?: Maybe<Scalars['Json']['output']>;
  balance?: Maybe<Scalars['Int']['output']>;
  balanceCurrency?: Maybe<Scalars['String']['output']>;
  balancePending?: Maybe<Scalars['Int']['output']>;
  chargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  dashboardLink?: Maybe<Scalars['String']['output']>;
  detailsSubmitted?: Maybe<Scalars['Boolean']['output']>;
  enterprise?: Maybe<Scalars['Boolean']['output']>;
  payoutSchedule?: Maybe<Scalars['Json']['output']>;
  payoutsEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  accounts?: Maybe<Scalars['Json']['output']>;
  chargeEnabled?: Maybe<Scalars['Boolean']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dashboardLink?: Maybe<Scalars['String']['output']>;
  defaultCurrency?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  marketplace?: Maybe<RegionalMarketplace>;
  transferEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeConnectAccountsResponse = {
  __typename?: 'StripeConnectAccountsResponse';
  accounts?: Maybe<Array<Maybe<StripeConnectAccount>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum StripePayoutWeekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum StripeRefundReasons {
  Duplicate = 'DUPLICATE',
  Fraudulent = 'FRAUDULENT',
  RequestedByCustomer = 'REQUESTED_BY_CUSTOMER'
}

export type StripeTransaction = {
  __typename?: 'StripeTransaction';
  amount?: Maybe<Scalars['String']['output']>;
  available_on?: Maybe<Scalars['DateTime']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StripeWebhooks = {
  __typename?: 'StripeWebhooks';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  menuItem?: Maybe<MenuItemSubscriptionPayload>;
  oneOrderSubscription: CustomOrderSubscriptionPayload;
  ordersSubscription?: Maybe<OrderSubscriptionPayload>;
};


export type SubscriptionOneOrderSubscriptionArgs = {
  orderId: Scalars['String']['input'];
};

export type Table = {
  __typename?: 'Table';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  friendlyName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  outlet: Outlet;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TableOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FriendlyNameAsc = 'friendlyName_ASC',
  FriendlyNameDesc = 'friendlyName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TableWhereInput = {
  AND?: InputMaybe<Array<TableWhereInput>>;
  NOT?: InputMaybe<Array<TableWhereInput>>;
  OR?: InputMaybe<Array<TableWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  friendlyName_contains?: InputMaybe<Scalars['String']['input']>;
  friendlyName_ends_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_gt?: InputMaybe<Scalars['String']['input']>;
  friendlyName_gte?: InputMaybe<Scalars['String']['input']>;
  friendlyName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  friendlyName_lt?: InputMaybe<Scalars['String']['input']>;
  friendlyName_lte?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_contains?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  friendlyName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  outlet?: InputMaybe<OutletWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type TaxRecord = {
  __typename?: 'TaxRecord';
  fulfilmentGross: Scalars['Int']['output'];
  fulfilmentNet: Scalars['Int']['output'];
  fulfilmentVAT: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  outlet: Outlet;
  productsGross: Scalars['Int']['output'];
  productsNet: Scalars['Int']['output'];
  productsVAT: Scalars['Int']['output'];
  serviceChargeGross: Scalars['Int']['output'];
  serviceChargeNet: Scalars['Int']['output'];
  serviceChargeVAT: Scalars['Int']['output'];
};

export enum TemplateTypesEnum {
  AcceptCollection = 'ACCEPT_COLLECTION',
  AcceptDelivery = 'ACCEPT_DELIVERY',
  AcceptTable = 'ACCEPT_TABLE',
  Campaign = 'CAMPAIGN',
  Cancel = 'CANCEL',
  Complete = 'COMPLETE',
  Fail = 'FAIL',
  InviteToDiscount = 'INVITE_TO_DISCOUNT',
  ReadyCollection = 'READY_COLLECTION',
  ReadyDelivery = 'READY_DELIVERY',
  Registration = 'REGISTRATION',
  Reject = 'REJECT',
  Reset = 'RESET',
  UpdateTime = 'UPDATE_TIME'
}

export type Terminal = {
  __typename?: 'Terminal';
  alarm?: Maybe<Alarm>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deviceDescription?: Maybe<Scalars['String']['output']>;
  editableMessages?: Maybe<Scalars['Boolean']['output']>;
  friendlyName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMuted: Scalars['Boolean']['output'];
  isOnline: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  lastOffline?: Maybe<Scalars['DateTime']['output']>;
  lastOnline?: Maybe<Scalars['DateTime']['output']>;
  mutedUntil?: Maybe<Scalars['DateTime']['output']>;
  outlets: Array<Outlet>;
  pin: Scalars['String']['output'];
  receiptGroupItems: Scalars['Boolean']['output'];
  serialNumber: Scalars['String']['output'];
  slave?: Maybe<Scalars['Boolean']['output']>;
  softwareVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TerminalFilterType {
  Filter = 'FILTER',
  OfflineOpenOnly = 'OFFLINE_OPEN_ONLY',
  Search = 'SEARCH'
}

export enum TerminalOrderByInput {
  AlarmAsc = 'alarm_ASC',
  AlarmDesc = 'alarm_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeviceDescriptionAsc = 'deviceDescription_ASC',
  DeviceDescriptionDesc = 'deviceDescription_DESC',
  FriendlyNameAsc = 'friendlyName_ASC',
  FriendlyNameDesc = 'friendlyName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsOnlineAsc = 'isOnline_ASC',
  IsOnlineDesc = 'isOnline_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LastOfflineAsc = 'lastOffline_ASC',
  LastOfflineDesc = 'lastOffline_DESC',
  LastOnlineAsc = 'lastOnline_ASC',
  LastOnlineDesc = 'lastOnline_DESC',
  SerialNumberAsc = 'serialNumber_ASC',
  SerialNumberDesc = 'serialNumber_DESC',
  SlaveAsc = 'slave_ASC',
  SlaveDesc = 'slave_DESC',
  SoftwareVersionAsc = 'softwareVersion_ASC',
  SoftwareVersionDesc = 'softwareVersion_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TerminalResponse = {
  __typename?: 'TerminalResponse';
  message?: Maybe<Scalars['String']['output']>;
  terminal?: Maybe<Terminal>;
};

export type TerminalWhereInput = {
  AND?: InputMaybe<Array<TerminalWhereInput>>;
  NOT?: InputMaybe<Array<TerminalWhereInput>>;
  OR?: InputMaybe<Array<TerminalWhereInput>>;
  alarm?: InputMaybe<Alarm>;
  alarm_in?: InputMaybe<Array<Alarm>>;
  alarm_not?: InputMaybe<Alarm>;
  alarm_not_in?: InputMaybe<Array<Alarm>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deviceDescription?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_contains?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_ends_with?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_gt?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_gte?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceDescription_lt?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_lte?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_not?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  deviceDescription_starts_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  friendlyName_contains?: InputMaybe<Scalars['String']['input']>;
  friendlyName_ends_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_gt?: InputMaybe<Scalars['String']['input']>;
  friendlyName_gte?: InputMaybe<Scalars['String']['input']>;
  friendlyName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  friendlyName_lt?: InputMaybe<Scalars['String']['input']>;
  friendlyName_lte?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_contains?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  friendlyName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  friendlyName_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
  isOnline_not?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  lastOffline?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_gt?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_gte?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lastOffline_lt?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_lte?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_not?: InputMaybe<Scalars['DateTime']['input']>;
  lastOffline_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lastOnline?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_gt?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_gte?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lastOnline_lt?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_lte?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_not?: InputMaybe<Scalars['DateTime']['input']>;
  lastOnline_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  outlet?: InputMaybe<OutletWhereInput>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  serialNumber_contains?: InputMaybe<Scalars['String']['input']>;
  serialNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  serialNumber_gt?: InputMaybe<Scalars['String']['input']>;
  serialNumber_gte?: InputMaybe<Scalars['String']['input']>;
  serialNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  serialNumber_lt?: InputMaybe<Scalars['String']['input']>;
  serialNumber_lte?: InputMaybe<Scalars['String']['input']>;
  serialNumber_not?: InputMaybe<Scalars['String']['input']>;
  serialNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  serialNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  serialNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  serialNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  serialNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  slave?: InputMaybe<Scalars['Boolean']['input']>;
  slave_not?: InputMaybe<Scalars['Boolean']['input']>;
  softwareVersion?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_contains?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_gt?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_gte?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  softwareVersion_lt?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_lte?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_not?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  softwareVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  softwareVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export enum TerminalsQueryOrderBy {
  FriendlyNameAsc = 'friendlyName_ASC',
  FriendlyNameDesc = 'friendlyName_DESC'
}

export type ThumbnailInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<ImageInput>;
};

export type Transaction = {
  __typename?: 'Transaction';
  acceptedAt: Scalars['DateTime']['output'];
  addOnItemsGrossTotal?: Maybe<Scalars['Int']['output']>;
  addOnItemsNetTotal?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  customerDeliveryCharge: Scalars['Int']['output'];
  customerStripeId?: Maybe<Scalars['String']['output']>;
  customerTaxes: Scalars['Int']['output'];
  deliveryChargeRecipient?: Maybe<DeliveryChargeRecipient>;
  discount?: Maybe<Scalars['Json']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  fulfillmentType?: Maybe<OrderFulfillmentMethods>;
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  marketplaceChargeFromMerchantGross: Scalars['Int']['output'];
  marketplaceChargeFromMerchantNet: Scalars['Int']['output'];
  marketplaceChargeGross: Scalars['Int']['output'];
  marketplaceChargeNet: Scalars['Int']['output'];
  marketplaceChargeVATPaidByCustomer?: Maybe<Scalars['Int']['output']>;
  marketplaceChargeVATPaidByMerchant?: Maybe<Scalars['Int']['output']>;
  marketplaceChargeVATPayer?: Maybe<MarketplaceChargeVatPayer>;
  marketplaceDeliverySubsidy: Scalars['Int']['output'];
  marketplaceFee: Scalars['Float']['output'];
  marketplaceFeeNet: Scalars['Int']['output'];
  marketplaceFeePercentage: Scalars['Float']['output'];
  marketplaceNetworkDeliveryAllocation?: Maybe<Scalars['Int']['output']>;
  marketplaceServiceCharge: Scalars['Int']['output'];
  marketplaceTransferStripeId?: Maybe<Scalars['String']['output']>;
  merchantAndMarketplaceImprecision?: Maybe<Scalars['Int']['output']>;
  merchantCommissionCharge?: Maybe<Scalars['Int']['output']>;
  merchantCommissionChargeWithVat?: Maybe<Scalars['Int']['output']>;
  merchantDeliverySubsidy: Scalars['Int']['output'];
  merchantNetworkDeliveryAllocation?: Maybe<Scalars['Int']['output']>;
  networkDeliveryCharge: Scalars['Int']['output'];
  nonNetworkFulfilmentChargeGross: Scalars['Int']['output'];
  nonNetworkFulfilmentChargeNet: Scalars['Int']['output'];
  nonNetworkFulfilmentChargeVAT: Scalars['Int']['output'];
  order: Order;
  orderGrossTotal: Scalars['Int']['output'];
  orderId: Scalars['String']['output'];
  orderNetTotal: Scalars['Int']['output'];
  orderStatus?: Maybe<OrderStatus>;
  outlet?: Maybe<Outlet>;
  outletTransferStripeId?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerAndRedboxImprecision?: Maybe<Scalars['Int']['output']>;
  partnerCommissionCharge?: Maybe<Scalars['Int']['output']>;
  partnerFee: Scalars['Float']['output'];
  partnerFeeNet: Scalars['Int']['output'];
  partnerFeePercentageWithVat: Scalars['Float']['output'];
  partnerTransferStripe?: Maybe<Scalars['Json']['output']>;
  partnerTransferStripeId?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<PaymentMethod>;
  platformCardProcessingCharge?: Maybe<Scalars['Float']['output']>;
  platformCardProcessingFee?: Maybe<Scalars['Int']['output']>;
  platformFee: Scalars['Float']['output'];
  platformFeeNet: Scalars['Int']['output'];
  platformFeePercentageWithVat: Scalars['Float']['output'];
  redboxCardProcessingCostCharge?: Maybe<Scalars['Float']['output']>;
  redboxCardProcessingCostFee?: Maybe<Scalars['Int']['output']>;
  redboxCharge?: Maybe<Scalars['Int']['output']>;
  redboxChargeNet?: Maybe<Scalars['Int']['output']>;
  redboxFee: Scalars['Float']['output'];
  redboxFeeNet: Scalars['Int']['output'];
  redboxFeePercentageWithVat: Scalars['Float']['output'];
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  restaurantDeliveryCharge: Scalars['Int']['output'];
  subtotalGrossAfterDiscount?: Maybe<Scalars['Int']['output']>;
  subtotalMinusMarketplaceCharge?: Maybe<Scalars['Int']['output']>;
  subtotalMinusNetworkDeliveryCharge: Scalars['Int']['output'];
  subtotalMinusNetworkDeliveryMarketplaceCharge?: Maybe<Scalars['Int']['output']>;
  subtotalNetAfterDiscount?: Maybe<Scalars['Int']['output']>;
  subtotalVATAfterDiscount?: Maybe<Scalars['Int']['output']>;
  totalMarketplaceCharge: Scalars['Int']['output'];
  totalOrderValue: Scalars['Int']['output'];
  totalPaidCash: Scalars['Int']['output'];
  totalPaidToMarketplace?: Maybe<Scalars['Int']['output']>;
  totalPaidToOutlet?: Maybe<Scalars['Int']['output']>;
  totalPaidToPartner?: Maybe<Scalars['Int']['output']>;
  totalPaidToPaybox?: Maybe<Scalars['Int']['output']>;
  totalPaidToPlatform?: Maybe<Scalars['Int']['output']>;
  totalPaidToRedbox?: Maybe<Scalars['Int']['output']>;
  totalRefund?: Maybe<Scalars['Int']['output']>;
  totalToDeliveryNetwork: Scalars['Int']['output'];
  totalToMarketplace: Scalars['Int']['output'];
  totalToMarketplaceAfterFees: Scalars['Int']['output'];
  totalToOutlet: Scalars['Int']['output'];
  totalToOutletPreRefund?: Maybe<Scalars['Int']['output']>;
  totalToPartner: Scalars['Int']['output'];
  totalToPaybox: Scalars['Int']['output'];
  totalToPlatform: Scalars['Int']['output'];
  totalToRedbox: Scalars['Int']['output'];
  transactionStripe?: Maybe<Scalars['Json']['output']>;
  transactionStripeId?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<TransactionType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TransactionAggregate = {
  __typename?: 'TransactionAggregate';
  aggregateStart: Scalars['DateTime']['output'];
  from: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoices: Array<Invoice>;
  marketplaceFeeAndCommissionChargeAggregates: Scalars['Json']['output'];
  outlet: Outlet;
  pdfFilename: Scalars['String']['output'];
  serviceChargeAggregates: Scalars['Json']['output'];
  to: Scalars['DateTime']['output'];
  totalCardOrders: Scalars['Int']['output'];
  totalCardValueGrossExcludingServiceCharge: Scalars['Int']['output'];
  totalCashOrders: Scalars['Int']['output'];
  totalCashValueGrossExcludingServiceCharge: Scalars['Int']['output'];
  totalDiscounts: Scalars['Int']['output'];
  totalFeesAndChargesGross: Scalars['Int']['output'];
  totalFeesAndChargesNet: Scalars['Int']['output'];
  totalNetworkDeliveryCharges: Scalars['Int']['output'];
  totalOrdersGrossAfterDiscount: Scalars['Int']['output'];
  totalOrdersNetAfterDiscounts: Scalars['Int']['output'];
  totalOwnDeliveryCharges: Scalars['Int']['output'];
  totalToRestaurant: Scalars['Int']['output'];
  vat: Scalars['Int']['output'];
  vatAggregates: Scalars['Json']['output'];
};

export enum TransactionOrderByInput {
  AcceptedAtAsc = 'acceptedAt_ASC',
  AcceptedAtDesc = 'acceptedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerStripeIdAsc = 'customerStripeId_ASC',
  CustomerStripeIdDesc = 'customerStripeId_DESC',
  CustomerTaxesAsc = 'customerTaxes_ASC',
  CustomerTaxesDesc = 'customerTaxes_DESC',
  DiscountAmountAsc = 'discountAmount_ASC',
  DiscountAmountDesc = 'discountAmount_DESC',
  DiscountAsc = 'discount_ASC',
  DiscountDesc = 'discount_DESC',
  FulfillmentTypeAsc = 'fulfillmentType_ASC',
  FulfillmentTypeDesc = 'fulfillmentType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MarketplaceChargeNetAsc = 'marketplaceChargeNet_ASC',
  MarketplaceChargeNetDesc = 'marketplaceChargeNet_DESC',
  MarketplaceFeeNetAsc = 'marketplaceFeeNet_ASC',
  MarketplaceFeeNetDesc = 'marketplaceFeeNet_DESC',
  MarketplaceFeePercentageAsc = 'marketplaceFeePercentage_ASC',
  MarketplaceFeePercentageDesc = 'marketplaceFeePercentage_DESC',
  MarketplaceFeeAsc = 'marketplaceFee_ASC',
  MarketplaceFeeDesc = 'marketplaceFee_DESC',
  MarketplaceServiceChargeAsc = 'marketplaceServiceCharge_ASC',
  MarketplaceServiceChargeDesc = 'marketplaceServiceCharge_DESC',
  NetworkDeliveryChargeAsc = 'networkDeliveryCharge_ASC',
  NetworkDeliveryChargeDesc = 'networkDeliveryCharge_DESC',
  OrderGrossTotalAsc = 'orderGrossTotal_ASC',
  OrderGrossTotalDesc = 'orderGrossTotal_DESC',
  OrderIdAsc = 'orderId_ASC',
  OrderIdDesc = 'orderId_DESC',
  OrderNetTotalAsc = 'orderNetTotal_ASC',
  OrderNetTotalDesc = 'orderNetTotal_DESC',
  OrderStatusAsc = 'orderStatus_ASC',
  OrderStatusDesc = 'orderStatus_DESC',
  PartnerFeeNetAsc = 'partnerFeeNet_ASC',
  PartnerFeeNetDesc = 'partnerFeeNet_DESC',
  PartnerFeePercentageWithVatAsc = 'partnerFeePercentageWithVat_ASC',
  PartnerFeePercentageWithVatDesc = 'partnerFeePercentageWithVat_DESC',
  PartnerFeeAsc = 'partnerFee_ASC',
  PartnerFeeDesc = 'partnerFee_DESC',
  PartnerTransferStripeIdAsc = 'partnerTransferStripeId_ASC',
  PartnerTransferStripeIdDesc = 'partnerTransferStripeId_DESC',
  PartnerTransferStripeAsc = 'partnerTransferStripe_ASC',
  PartnerTransferStripeDesc = 'partnerTransferStripe_DESC',
  PaymentTypeAsc = 'paymentType_ASC',
  PaymentTypeDesc = 'paymentType_DESC',
  PlatformFeeNetAsc = 'platformFeeNet_ASC',
  PlatformFeeNetDesc = 'platformFeeNet_DESC',
  PlatformFeePercentageWithVatAsc = 'platformFeePercentageWithVat_ASC',
  PlatformFeePercentageWithVatDesc = 'platformFeePercentageWithVat_DESC',
  PlatformFeeAsc = 'platformFee_ASC',
  PlatformFeeDesc = 'platformFee_DESC',
  RedboxFeeNetAsc = 'redboxFeeNet_ASC',
  RedboxFeeNetDesc = 'redboxFeeNet_DESC',
  RedboxFeePercentageWithVatAsc = 'redboxFeePercentageWithVat_ASC',
  RedboxFeePercentageWithVatDesc = 'redboxFeePercentageWithVat_DESC',
  RedboxFeeAsc = 'redboxFee_ASC',
  RedboxFeeDesc = 'redboxFee_DESC',
  RestaurantDeliveryChargeAsc = 'restaurantDeliveryCharge_ASC',
  RestaurantDeliveryChargeDesc = 'restaurantDeliveryCharge_DESC',
  SubtotalMinusMarketplaceChargeAsc = 'subtotalMinusMarketplaceCharge_ASC',
  SubtotalMinusMarketplaceChargeDesc = 'subtotalMinusMarketplaceCharge_DESC',
  SubtotalMinusNetworkDeliveryChargeAsc = 'subtotalMinusNetworkDeliveryCharge_ASC',
  SubtotalMinusNetworkDeliveryChargeDesc = 'subtotalMinusNetworkDeliveryCharge_DESC',
  SubtotalMinusNetworkDeliveryMarketplaceChargeAsc = 'subtotalMinusNetworkDeliveryMarketplaceCharge_ASC',
  SubtotalMinusNetworkDeliveryMarketplaceChargeDesc = 'subtotalMinusNetworkDeliveryMarketplaceCharge_DESC',
  TotalMarketplaceChargeAsc = 'totalMarketplaceCharge_ASC',
  TotalMarketplaceChargeDesc = 'totalMarketplaceCharge_DESC',
  TotalOrderValueAsc = 'totalOrderValue_ASC',
  TotalOrderValueDesc = 'totalOrderValue_DESC',
  TotalPaidCashAsc = 'totalPaidCash_ASC',
  TotalPaidCashDesc = 'totalPaidCash_DESC',
  TotalPaidToPartnerAsc = 'totalPaidToPartner_ASC',
  TotalPaidToPartnerDesc = 'totalPaidToPartner_DESC',
  TotalPaidToPlatformAsc = 'totalPaidToPlatform_ASC',
  TotalPaidToPlatformDesc = 'totalPaidToPlatform_DESC',
  TotalRefundAsc = 'totalRefund_ASC',
  TotalRefundDesc = 'totalRefund_DESC',
  TotalToDeliveryNetworkAsc = 'totalToDeliveryNetwork_ASC',
  TotalToDeliveryNetworkDesc = 'totalToDeliveryNetwork_DESC',
  TotalToMarketplaceAfterPlatformFeesAsc = 'totalToMarketplaceAfterPlatformFees_ASC',
  TotalToMarketplaceAfterPlatformFeesDesc = 'totalToMarketplaceAfterPlatformFees_DESC',
  TotalToMarketplaceAsc = 'totalToMarketplace_ASC',
  TotalToMarketplaceDesc = 'totalToMarketplace_DESC',
  TotalToPartnerAsc = 'totalToPartner_ASC',
  TotalToPartnerDesc = 'totalToPartner_DESC',
  TotalToPlatformAsc = 'totalToPlatform_ASC',
  TotalToPlatformDesc = 'totalToPlatform_DESC',
  TotalToRedboxAsc = 'totalToRedbox_ASC',
  TotalToRedboxDesc = 'totalToRedbox_DESC',
  TotalToRestaurantAsc = 'totalToRestaurant_ASC',
  TotalToRestaurantDesc = 'totalToRestaurant_DESC',
  TransactionStripeIdAsc = 'transactionStripeId_ASC',
  TransactionStripeIdDesc = 'transactionStripeId_DESC',
  TransactionStripeAsc = 'transactionStripe_ASC',
  TransactionStripeDesc = 'transactionStripe_DESC',
  TransactionTypeAsc = 'transactionType_ASC',
  TransactionTypeDesc = 'transactionType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum TransactionType {
  Fee = 'FEE',
  PayboxEnterprisePaymentCash = 'PAYBOX_ENTERPRISE_PAYMENT_CASH',
  PayboxEnterprisePaymentStripe = 'PAYBOX_ENTERPRISE_PAYMENT_STRIPE',
  PayboxEnterprisePaymentStripeActionRequired = 'PAYBOX_ENTERPRISE_PAYMENT_STRIPE_ACTION_REQUIRED',
  PayboxEnterpriseRefund = 'PAYBOX_ENTERPRISE_REFUND',
  PayboxStandardPaymentStripe = 'PAYBOX_STANDARD_PAYMENT_STRIPE',
  PayboxStandardPaymentStripeActionRequired = 'PAYBOX_STANDARD_PAYMENT_STRIPE_ACTION_REQUIRED',
  PayboxStandardRefund = 'PAYBOX_STANDARD_REFUND',
  PaymentCash = 'PAYMENT_CASH',
  PaymentStripe = 'PAYMENT_STRIPE',
  PaymentStripeActionRequired = 'PAYMENT_STRIPE_ACTION_REQUIRED',
  PaymentStripeFailed = 'PAYMENT_STRIPE_FAILED',
  Refund = 'REFUND'
}

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  acceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  acceptedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerStripeId?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_contains?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_gt?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_gte?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerStripeId_lt?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_lte?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_not?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  customerStripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerStripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  customerTaxes?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_gt?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_gte?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerTaxes_lt?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_lte?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_not?: InputMaybe<Scalars['Int']['input']>;
  customerTaxes_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_gte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  discountAmount_lt?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_lte?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not?: InputMaybe<Scalars['Int']['input']>;
  discountAmount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfillmentType?: InputMaybe<OrderFulfillmentMethods>;
  fulfillmentType_in?: InputMaybe<Array<OrderFulfillmentMethods>>;
  fulfillmentType_not?: InputMaybe<OrderFulfillmentMethods>;
  fulfillmentType_not_in?: InputMaybe<Array<OrderFulfillmentMethods>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  marketplaceChargeNet?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  marketplaceChargeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_not?: InputMaybe<Scalars['Int']['input']>;
  marketplaceChargeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  marketplaceFee?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeeNet?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  marketplaceFeeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_not?: InputMaybe<Scalars['Int']['input']>;
  marketplaceFeeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  marketplaceFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_gt?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_gte?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  marketplaceFeePercentage_lt?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_lte?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_not?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFeePercentage_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  marketplaceFee_gt?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFee_gte?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  marketplaceFee_lt?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFee_lte?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFee_not?: InputMaybe<Scalars['Float']['input']>;
  marketplaceFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  marketplaceServiceCharge?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  marketplaceServiceCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_not?: InputMaybe<Scalars['Int']['input']>;
  marketplaceServiceCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  networkDeliveryCharge?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  networkDeliveryCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_not?: InputMaybe<Scalars['Int']['input']>;
  networkDeliveryCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  order?: InputMaybe<OrderWhereInput>;
  orderGrossTotal?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderGrossTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_not?: InputMaybe<Scalars['Int']['input']>;
  orderGrossTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderId_contains?: InputMaybe<Scalars['String']['input']>;
  orderId_ends_with?: InputMaybe<Scalars['String']['input']>;
  orderId_gt?: InputMaybe<Scalars['String']['input']>;
  orderId_gte?: InputMaybe<Scalars['String']['input']>;
  orderId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  orderId_lt?: InputMaybe<Scalars['String']['input']>;
  orderId_lte?: InputMaybe<Scalars['String']['input']>;
  orderId_not?: InputMaybe<Scalars['String']['input']>;
  orderId_not_contains?: InputMaybe<Scalars['String']['input']>;
  orderId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  orderId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  orderId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderId_starts_with?: InputMaybe<Scalars['String']['input']>;
  orderNetTotal?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderNetTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_not?: InputMaybe<Scalars['Int']['input']>;
  orderNetTotal_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderStatus?: InputMaybe<OrderStatus>;
  orderStatus_in?: InputMaybe<Array<OrderStatus>>;
  orderStatus_not?: InputMaybe<OrderStatus>;
  orderStatus_not_in?: InputMaybe<Array<OrderStatus>>;
  outlet?: InputMaybe<OutletWhereInput>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerFee?: InputMaybe<Scalars['Float']['input']>;
  partnerFeeNet?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerFeeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_not?: InputMaybe<Scalars['Int']['input']>;
  partnerFeeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerFeePercentageWithVat?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFeePercentageWithVat_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_not?: InputMaybe<Scalars['Float']['input']>;
  partnerFeePercentageWithVat_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee_gt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_gte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerFee_lt?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_lte?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not?: InputMaybe<Scalars['Float']['input']>;
  partnerFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  partnerTransferStripeId?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_contains?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_gt?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_gte?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerTransferStripeId_lt?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_lte?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_not?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  partnerTransferStripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  partnerTransferStripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<PaymentMethod>;
  paymentType_in?: InputMaybe<Array<PaymentMethod>>;
  paymentType_not?: InputMaybe<PaymentMethod>;
  paymentType_not_in?: InputMaybe<Array<PaymentMethod>>;
  platformFee?: InputMaybe<Scalars['Float']['input']>;
  platformFeeNet?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformFeeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_not?: InputMaybe<Scalars['Int']['input']>;
  platformFeeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  platformFeePercentageWithVat?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_gt?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_gte?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFeePercentageWithVat_lt?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_lte?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_not?: InputMaybe<Scalars['Float']['input']>;
  platformFeePercentageWithVat_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFee_gt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_gte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  platformFee_lt?: InputMaybe<Scalars['Float']['input']>;
  platformFee_lte?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not?: InputMaybe<Scalars['Float']['input']>;
  platformFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  redboxFee?: InputMaybe<Scalars['Float']['input']>;
  redboxFeeNet?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_gt?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_gte?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  redboxFeeNet_lt?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_lte?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_not?: InputMaybe<Scalars['Int']['input']>;
  redboxFeeNet_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  redboxFeePercentageWithVat?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_gt?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_gte?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  redboxFeePercentageWithVat_lt?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_lte?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_not?: InputMaybe<Scalars['Float']['input']>;
  redboxFeePercentageWithVat_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  redboxFee_gt?: InputMaybe<Scalars['Float']['input']>;
  redboxFee_gte?: InputMaybe<Scalars['Float']['input']>;
  redboxFee_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  redboxFee_lt?: InputMaybe<Scalars['Float']['input']>;
  redboxFee_lte?: InputMaybe<Scalars['Float']['input']>;
  redboxFee_not?: InputMaybe<Scalars['Float']['input']>;
  redboxFee_not_in?: InputMaybe<Array<Scalars['Float']['input']>>;
  restaurantDeliveryCharge?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  restaurantDeliveryCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_not?: InputMaybe<Scalars['Int']['input']>;
  restaurantDeliveryCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusMarketplaceCharge?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusMarketplaceCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_not?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusMarketplaceCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusNetworkDeliveryCharge?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusNetworkDeliveryCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_not?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusNetworkDeliveryMarketplaceCharge?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_not?: InputMaybe<Scalars['Int']['input']>;
  subtotalMinusNetworkDeliveryMarketplaceCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMarketplaceCharge?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMarketplaceCharge_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_not?: InputMaybe<Scalars['Int']['input']>;
  totalMarketplaceCharge_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalOrderValue?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_gt?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_gte?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalOrderValue_lt?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_lte?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_not?: InputMaybe<Scalars['Int']['input']>;
  totalOrderValue_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidCash?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_gt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_gte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidCash_lt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_lte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_not?: InputMaybe<Scalars['Int']['input']>;
  totalPaidCash_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidToPartner?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_gt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_gte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidToPartner_lt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_lte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_not?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPartner_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidToPlatform?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_gt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_gte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalPaidToPlatform_lt?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_lte?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_not?: InputMaybe<Scalars['Int']['input']>;
  totalPaidToPlatform_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalRefund?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_gt?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_gte?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalRefund_lt?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_lte?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_not?: InputMaybe<Scalars['Int']['input']>;
  totalRefund_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToDeliveryNetwork?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToDeliveryNetwork_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_not?: InputMaybe<Scalars['Int']['input']>;
  totalToDeliveryNetwork_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToMarketplace?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToMarketplaceAfterPlatformFees_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_not?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplaceAfterPlatformFees_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToMarketplace_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplace_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplace_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToMarketplace_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplace_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplace_not?: InputMaybe<Scalars['Int']['input']>;
  totalToMarketplace_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToPartner?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToPartner_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_not?: InputMaybe<Scalars['Int']['input']>;
  totalToPartner_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToPlatform?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToPlatform_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_not?: InputMaybe<Scalars['Int']['input']>;
  totalToPlatform_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToRedbox?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToRedbox_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_not?: InputMaybe<Scalars['Int']['input']>;
  totalToRedbox_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToRestaurant?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_gt?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_gte?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalToRestaurant_lt?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_lte?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_not?: InputMaybe<Scalars['Int']['input']>;
  totalToRestaurant_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  transactionStripeId?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_contains?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_ends_with?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_gt?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_gte?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionStripeId_lt?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_lte?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_not?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_not_contains?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionStripeId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  transactionStripeId_starts_with?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_in?: InputMaybe<Array<TransactionType>>;
  transactionType_not?: InputMaybe<TransactionType>;
  transactionType_not_in?: InputMaybe<Array<TransactionType>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type TriggerMessageTemplateResponse = {
  __typename?: 'TriggerMessageTemplateResponse';
  message: Scalars['String']['output'];
};

export type TwoFactorCheckResponse = {
  __typename?: 'TwoFactorCheckResponse';
  ownsMarketplaces?: Maybe<Array<Maybe<OwnsIdResponse>>>;
  ownsOutlets?: Maybe<Array<Maybe<OwnsIdResponse>>>;
  ownsPartners?: Maybe<Array<Maybe<OwnsIdResponse>>>;
  ownsPlatforms?: Maybe<Array<Maybe<OwnsIdResponse>>>;
  ownsRestaurants?: Maybe<Array<Maybe<OwnsIdResponse>>>;
  role?: Maybe<Role>;
  token?: Maybe<Scalars['String']['output']>;
};

export enum UberDirectUndeliverableAction {
  Discard = 'DISCARD',
  LeaveAtDoor = 'LEAVE_AT_DOOR',
  Return = 'RETURN'
}

export enum UberDirectWebhookStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS',
  WaitingForWebhook = 'WAITING_FOR_WEBHOOK'
}

export type UpdateDeliveryWindowsResponse = {
  __typename?: 'UpdateDeliveryWindowsResponse';
  message: Scalars['String']['output'];
};

export type UploadImagesResponse = {
  __typename?: 'UploadImagesResponse';
  images?: Maybe<Array<Scalars['String']['output']>>;
};

export type User = {
  __typename?: 'User';
  archived: Scalars['Boolean']['output'];
  authCode?: Maybe<Scalars['String']['output']>;
  canUse2FA: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hasRegistered: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ownsMarketplaces?: Maybe<Array<RegionalMarketplace>>;
  ownsOutlets?: Maybe<Array<Outlet>>;
  ownsPartners?: Maybe<Array<Partner>>;
  ownsPlatforms?: Maybe<Array<Platform>>;
  ownsRestaurants?: Maybe<Array<Restaurant>>;
  password?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  resetToken?: Maybe<Scalars['String']['output']>;
  role: Role;
  signature?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportTitle?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserOwnsMarketplacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RegionalMarketplaceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RegionalMarketplaceWhereInput>;
};


export type UserOwnsOutletsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OutletOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutletWhereInput>;
};


export type UserOwnsPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PartnerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type UserOwnsPlatformsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PlatformOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PlatformWhereInput>;
};


export type UserOwnsRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RestaurantOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RestaurantWhereInput>;
};

export enum UserOrderByInput {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  AuthCodeAsc = 'authCode_ASC',
  AuthCodeDesc = 'authCode_DESC',
  CanUse2FaAsc = 'canUse2FA_ASC',
  CanUse2FaDesc = 'canUse2FA_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  HasRegisteredAsc = 'hasRegistered_ASC',
  HasRegisteredDesc = 'hasRegistered_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  ResetTokenAsc = 'resetToken_ASC',
  ResetTokenDesc = 'resetToken_DESC',
  SignatureAsc = 'signature_ASC',
  SignatureDesc = 'signature_DESC',
  SupportEmailAsc = 'supportEmail_ASC',
  SupportEmailDesc = 'supportEmail_DESC',
  SupportTitleAsc = 'supportTitle_ASC',
  SupportTitleDesc = 'supportTitle_DESC',
  SupportUrlAsc = 'supportUrl_ASC',
  SupportUrlDesc = 'supportUrl_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserResponse = {
  __typename?: 'UserResponse';
  message?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archived_not?: InputMaybe<Scalars['Boolean']['input']>;
  authCode?: InputMaybe<Scalars['String']['input']>;
  authCode_contains?: InputMaybe<Scalars['String']['input']>;
  authCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  authCode_gt?: InputMaybe<Scalars['String']['input']>;
  authCode_gte?: InputMaybe<Scalars['String']['input']>;
  authCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authCode_lt?: InputMaybe<Scalars['String']['input']>;
  authCode_lte?: InputMaybe<Scalars['String']['input']>;
  authCode_not?: InputMaybe<Scalars['String']['input']>;
  authCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  authCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  authCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  authCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  authCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  canUse2FA?: InputMaybe<Scalars['Boolean']['input']>;
  canUse2FA_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_contains?: InputMaybe<Scalars['String']['input']>;
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_gt?: InputMaybe<Scalars['String']['input']>;
  email_gte?: InputMaybe<Scalars['String']['input']>;
  email_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_lt?: InputMaybe<Scalars['String']['input']>;
  email_lte?: InputMaybe<Scalars['String']['input']>;
  email_not?: InputMaybe<Scalars['String']['input']>;
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  email_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstName_gt?: InputMaybe<Scalars['String']['input']>;
  firstName_gte?: InputMaybe<Scalars['String']['input']>;
  firstName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName_lt?: InputMaybe<Scalars['String']['input']>;
  firstName_lte?: InputMaybe<Scalars['String']['input']>;
  firstName_not?: InputMaybe<Scalars['String']['input']>;
  firstName_not_contains?: InputMaybe<Scalars['String']['input']>;
  firstName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  firstName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstName_starts_with?: InputMaybe<Scalars['String']['input']>;
  hasRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegistered_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_ends_with?: InputMaybe<Scalars['String']['input']>;
  lastName_gt?: InputMaybe<Scalars['String']['input']>;
  lastName_gte?: InputMaybe<Scalars['String']['input']>;
  lastName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName_lt?: InputMaybe<Scalars['String']['input']>;
  lastName_lte?: InputMaybe<Scalars['String']['input']>;
  lastName_not?: InputMaybe<Scalars['String']['input']>;
  lastName_not_contains?: InputMaybe<Scalars['String']['input']>;
  lastName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  lastName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lastName_starts_with?: InputMaybe<Scalars['String']['input']>;
  ownsMarketplaces_every?: InputMaybe<RegionalMarketplaceWhereInput>;
  ownsMarketplaces_none?: InputMaybe<RegionalMarketplaceWhereInput>;
  ownsMarketplaces_some?: InputMaybe<RegionalMarketplaceWhereInput>;
  ownsOutlets_every?: InputMaybe<OutletWhereInput>;
  ownsOutlets_none?: InputMaybe<OutletWhereInput>;
  ownsOutlets_some?: InputMaybe<OutletWhereInput>;
  ownsPartners_every?: InputMaybe<PartnerWhereInput>;
  ownsPartners_none?: InputMaybe<PartnerWhereInput>;
  ownsPartners_some?: InputMaybe<PartnerWhereInput>;
  ownsPlatforms_every?: InputMaybe<PlatformWhereInput>;
  ownsPlatforms_none?: InputMaybe<PlatformWhereInput>;
  ownsPlatforms_some?: InputMaybe<PlatformWhereInput>;
  ownsRestaurants_every?: InputMaybe<RestaurantWhereInput>;
  ownsRestaurants_none?: InputMaybe<RestaurantWhereInput>;
  ownsRestaurants_some?: InputMaybe<RestaurantWhereInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  password_contains?: InputMaybe<Scalars['String']['input']>;
  password_ends_with?: InputMaybe<Scalars['String']['input']>;
  password_gt?: InputMaybe<Scalars['String']['input']>;
  password_gte?: InputMaybe<Scalars['String']['input']>;
  password_in?: InputMaybe<Array<Scalars['String']['input']>>;
  password_lt?: InputMaybe<Scalars['String']['input']>;
  password_lte?: InputMaybe<Scalars['String']['input']>;
  password_not?: InputMaybe<Scalars['String']['input']>;
  password_not_contains?: InputMaybe<Scalars['String']['input']>;
  password_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  password_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  password_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  password_starts_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_lt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_lte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  resetToken?: InputMaybe<Scalars['String']['input']>;
  resetToken_contains?: InputMaybe<Scalars['String']['input']>;
  resetToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_gt?: InputMaybe<Scalars['String']['input']>;
  resetToken_gte?: InputMaybe<Scalars['String']['input']>;
  resetToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resetToken_lt?: InputMaybe<Scalars['String']['input']>;
  resetToken_lte?: InputMaybe<Scalars['String']['input']>;
  resetToken_not?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resetToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resetToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<RoleWhereInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  signature_contains?: InputMaybe<Scalars['String']['input']>;
  signature_ends_with?: InputMaybe<Scalars['String']['input']>;
  signature_gt?: InputMaybe<Scalars['String']['input']>;
  signature_gte?: InputMaybe<Scalars['String']['input']>;
  signature_in?: InputMaybe<Array<Scalars['String']['input']>>;
  signature_lt?: InputMaybe<Scalars['String']['input']>;
  signature_lte?: InputMaybe<Scalars['String']['input']>;
  signature_not?: InputMaybe<Scalars['String']['input']>;
  signature_not_contains?: InputMaybe<Scalars['String']['input']>;
  signature_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  signature_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  signature_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  signature_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportEmail_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_gte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_lt?: InputMaybe<Scalars['String']['input']>;
  supportEmail_lte?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportEmail_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle?: InputMaybe<Scalars['String']['input']>;
  supportTitle_contains?: InputMaybe<Scalars['String']['input']>;
  supportTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_gt?: InputMaybe<Scalars['String']['input']>;
  supportTitle_gte?: InputMaybe<Scalars['String']['input']>;
  supportTitle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportTitle_lt?: InputMaybe<Scalars['String']['input']>;
  supportTitle_lte?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
  supportUrl_contains?: InputMaybe<Scalars['String']['input']>;
  supportUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_gt?: InputMaybe<Scalars['String']['input']>;
  supportUrl_gte?: InputMaybe<Scalars['String']['input']>;
  supportUrl_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportUrl_lt?: InputMaybe<Scalars['String']['input']>;
  supportUrl_lte?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  supportUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  supportUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type ValidatePhoneResponse = {
  __typename?: 'ValidatePhoneResponse';
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Visitor = {
  __typename?: 'Visitor';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outlet: Outlet;
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum VisitorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VisitorResponse = {
  __typename?: 'VisitorResponse';
  count: Scalars['Int']['output'];
  visitors: Array<Visitor>;
};

export type VisitorWhereInput = {
  AND?: InputMaybe<Array<VisitorWhereInput>>;
  NOT?: InputMaybe<Array<VisitorWhereInput>>;
  OR?: InputMaybe<Array<VisitorWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  outlet?: InputMaybe<OutletWhereInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_gte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_lt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_lte?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type Voucher = {
  __typename?: 'Voucher';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerIds?: Maybe<Array<Scalars['String']['output']>>;
  discount?: Maybe<Discount>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  marketplace?: Maybe<RegionalMarketplace>;
  onePerCustomer: Scalars['Boolean']['output'];
  orderIds?: Maybe<Array<Scalars['String']['output']>>;
  transactionIds?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  usageCount?: Maybe<Scalars['Int']['output']>;
  usageLimit?: Maybe<Scalars['Int']['output']>;
};

export enum VoucherOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  OnePerCustomerAsc = 'onePerCustomer_ASC',
  OnePerCustomerDesc = 'onePerCustomer_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UsageCountAsc = 'usageCount_ASC',
  UsageCountDesc = 'usageCount_DESC',
  UsageLimitAsc = 'usageLimit_ASC',
  UsageLimitDesc = 'usageLimit_DESC'
}

export type VoucherWhereInput = {
  AND?: InputMaybe<Array<VoucherWhereInput>>;
  NOT?: InputMaybe<Array<VoucherWhereInput>>;
  OR?: InputMaybe<Array<VoucherWhereInput>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  discount?: InputMaybe<DiscountWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['String']['input']>;
  key_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['String']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<RegionalMarketplaceWhereInput>;
  onePerCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  onePerCustomer_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  usageCount?: InputMaybe<Scalars['Int']['input']>;
  usageCount_gt?: InputMaybe<Scalars['Int']['input']>;
  usageCount_gte?: InputMaybe<Scalars['Int']['input']>;
  usageCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  usageCount_lt?: InputMaybe<Scalars['Int']['input']>;
  usageCount_lte?: InputMaybe<Scalars['Int']['input']>;
  usageCount_not?: InputMaybe<Scalars['Int']['input']>;
  usageCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_gt?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_gte?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  usageLimit_lt?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_lte?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_not?: InputMaybe<Scalars['Int']['input']>;
  usageLimit_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type VouchersResponse = {
  __typename?: 'VouchersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  vouchers: Array<Voucher>;
};

export type Webhook = {
  __typename?: 'Webhook';
  apiKey: ApiKey;
  createdAt: Scalars['DateTime']['output'];
  endpoint: Scalars['String']['output'];
  headerKey: Scalars['String']['output'];
  headerValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  marketplace: RegionalMarketplace;
  updatedAt: Scalars['DateTime']['output'];
  webhookType: Array<WebhookType>;
};

export enum WebhookOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndpointAsc = 'endpoint_ASC',
  EndpointDesc = 'endpoint_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export enum WebhookStatus {
  Error = 'ERROR',
  Failure = 'FAILURE',
  NotApplicable = 'NOT_APPLICABLE',
  Success = 'SUCCESS',
  Testing = 'TESTING'
}

export enum WebhookType {
  OrderCreated = 'ORDER_CREATED',
  OrderDropoffEtaUpdated = 'ORDER_DROPOFF_ETA_UPDATED',
  OrderEndOfPrepTimeUpdated = 'ORDER_END_OF_PREP_TIME_UPDATED',
  OrderFulfilmentStatusUpdated = 'ORDER_FULFILMENT_STATUS_UPDATED',
  OrderFulfilmentStatusUpdatedToArrivedAtDropoff = 'ORDER_FULFILMENT_STATUS_UPDATED_TO_ARRIVED_AT_DROPOFF',
  OrderFulfilmentStatusUpdatedToArrivedAtPickup = 'ORDER_FULFILMENT_STATUS_UPDATED_TO_ARRIVED_AT_PICKUP',
  OrderFulfilmentStatusUpdatedToEnrouteToDropoff = 'ORDER_FULFILMENT_STATUS_UPDATED_TO_ENROUTE_TO_DROPOFF',
  OrderFulfilmentStatusUpdatedToEnrouteToPickup = 'ORDER_FULFILMENT_STATUS_UPDATED_TO_ENROUTE_TO_PICKUP',
  OrderFulfilmentStatusUpdatedToSearchingForDrivers = 'ORDER_FULFILMENT_STATUS_UPDATED_TO_SEARCHING_FOR_DRIVERS',
  OrderStatusUpdated = 'ORDER_STATUS_UPDATED',
  OrderStatusUpdatedToCancelled = 'ORDER_STATUS_UPDATED_TO_CANCELLED',
  OrderStatusUpdatedToCompleted = 'ORDER_STATUS_UPDATED_TO_COMPLETED',
  OrderStatusUpdatedToPreparing = 'ORDER_STATUS_UPDATED_TO_PREPARING',
  OrderStatusUpdatedToReadyForPickup = 'ORDER_STATUS_UPDATED_TO_READY_FOR_PICKUP',
  OrderStatusUpdatedToRejected = 'ORDER_STATUS_UPDATED_TO_REJECTED',
  OrderTimesUpdated = 'ORDER_TIMES_UPDATED'
}

export type WebhookWhereInput = {
  AND?: InputMaybe<Array<WebhookWhereInput>>;
  NOT?: InputMaybe<Array<WebhookWhereInput>>;
  OR?: InputMaybe<Array<WebhookWhereInput>>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  endpoint_contains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SendMessageToOutletsResponse = {
  __typename?: 'sendMessageToOutletsResponse';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};
