import { Callout } from '@blueprintjs/core'
import React, { Fragment } from 'react'

const NonIdealStateSmaller = ({ isLoyaltyCard = false }) => (
  <Fragment>
    <Callout icon="outdated" title="Not Claimed">
      Because the {isLoyaltyCard ? 'loyalty card' : 'discount'} has not yet been
      used, there is no data available for analysis.
    </Callout>
    <br />
  </Fragment>
)

export default NonIdealStateSmaller
