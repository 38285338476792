import gql from 'graphql-tag'

export const EDIT_OPTION = gql`
  mutation optionImportEditOption(
    $id: String!
    $name: String!
    $minOptions: Int
    $maxOptions: Int
  ) {
    editOption(
      id: $id
      name: $name
      minOptions: $minOptions
      maxOptions: $maxOptions
    ) {
      message
      option {
        id
        name
        minOptions
        maxOptions
      }
    }
  }
`
