import gql from 'graphql-tag'

const MUTATION_REFUND_ORDER = gql`
  mutation refundOrder(
    $id: String!
    $reason: StripeRefundReasons!
    $description: String
  ) {
    refundOrder(id: $id, reason: $reason, description: $description) {
      message
    }
  }
`

export default MUTATION_REFUND_ORDER
