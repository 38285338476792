import gql from 'graphql-tag'

const ALLOW_REVIEWS = gql`
  mutation allowReviews($id: String!, $allowReviews: Boolean!) {
    editMarketplace(id: $id, allowReviews: $allowReviews) {
      message
      marketplace {
        id
        allowReviews
      }
    }
  }
`

export default ALLOW_REVIEWS
