import gql from 'graphql-tag'

const GET_PARTNER_PAYMENTS = gql`
  query getPartnerPayments($id: String!) {
    getPartners(id: $id) {
      partners {
        id
        name
        stripeId
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        contactName
        contactEmail
        contactPhone
        companyLegalName
        platform {
          id
          name
          stripeClientId
        }
        stripeEnterpriseId
        stripeEnterprisePublicId
        stripeEnterpriseAccessId
        stripeEnterprisePayoutDay
        stripeShowDashboardMarketplace
        stripeShowDashboardOutlet
        stripeEnterpriseAllowed
        stripeEnterpriseWebhooks {
          id
          description
          url
          status
        }
        stripeConnect {
          payoutsEnabled
          accountType
          balancePending
          balancePending
          balanceCurrency
          dashboardLink
          enterprise
        }
      }
    }
  }
`

export default GET_PARTNER_PAYMENTS
