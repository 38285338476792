import gql from 'graphql-tag'

const GET_OUTLETS = gql`
  query getOutletsByRestaurantId($restaurantId: String!) {
    getOutlets(restaurantId: $restaurantId) {
      outlets {
        id
        name
        isOpen
        isOnline
        isOnlineOverride
        active
        openingTimes
        defaultDeliveryTime
        defaultCollectionTime
        isVATregistered
        contactName
        contactPhone
        contactEmail
        stripeId
        contactAddress {
          id
          firstLine
          secondLine
          city
          postcode
        }
        outletAddress {
          id
          city
          postcode
        }
        restaurant {
          id
          name
          active
        }
        marketplace {
          id
          name
          cname
          stripeDirectPayment
          stripeOnboarding
        }
        terminals {
          id
        }
        deliveryZoneCosts {
          id
        }

        stripeConnect {
          balance
          balanceCurrency
          detailsSubmitted
          chargesEnabled
          payoutsEnabled
        }
      }
    }
  }
`
export default GET_OUTLETS
