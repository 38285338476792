import gql from 'graphql-tag'

const EDIT_RESTAURANT = gql`
  mutation editRestaurant(
    $id: String!
    $name: String
    $image: String
    $contactName: String
    $contactEmail: String
    $contactPhone: String
    $description: String
    $contactAddress: AddressUpdateInput
    $categories: [String]
    $partnerId: String
    $active: Boolean
    $deliveryDeal: String
    $collectionDeal: String
    $allowAddOnItems: Boolean
    $enableAllergyInformation: Boolean
    $displayAllOutlets: Boolean
    $tableDeal: String
    $promoted: Boolean
    $statusOnboarding: String
    $cname: String
    $metaDescription: String
    $metaKeywords: String
    $enablePaginatedMenu: Boolean
    $enableStackedMenu: Boolean
    $outletOfferFlagOverride: Boolean
    $outletPromoteOverride: Boolean
    $outletCategoriesOverride: Boolean
  ) {
    editRestaurant(
      id: $id
      name: $name
      image: $image
      contactName: $contactName
      contactEmail: $contactEmail
      contactPhone: $contactPhone
      description: $description
      contactAddress: $contactAddress
      categories: $categories
      partnerId: $partnerId
      active: $active
      deliveryDeal: $deliveryDeal
      collectionDeal: $collectionDeal
      allowAddOnItems: $allowAddOnItems
      enableAllergyInformation: $enableAllergyInformation
      displayAllOutlets: $displayAllOutlets
      tableDeal: $tableDeal
      promoted: $promoted
      statusOnboarding: $statusOnboarding
      cname: $cname
      metaDescription: $metaDescription
      metaKeywords: $metaKeywords
      enablePaginatedMenu: $enablePaginatedMenu
      enableStackedMenu: $enableStackedMenu
      outletOfferFlagOverride: $outletOfferFlagOverride
      outletPromoteOverride: $outletPromoteOverride
      outletCategoriesOverride: $outletCategoriesOverride
    ) {
      message
      restaurant {
        id
        name
        cname
        image
        contactName
        contactEmail
        contactPhone
        description
        stripeId
        displayAllOutlets
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        cuisines {
          name
          id
        }
        partner {
          id
        }
        active
        deliveryDeal
        collectionDeal
        tableDeal
        promoted
        allowAddOnItems
        enableAllergyInformation
        metaDescription
        metaKeywords
        enablePaginatedMenu
        enableStackedMenu
        outletOfferFlagOverride
        outletPromoteOverride
        outletCategoriesOverride
      }
    }
  }
`
export default EDIT_RESTAURANT
