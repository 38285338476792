import gql from 'graphql-tag'

const GET_RESTAURANTS_BY_IDS = gql`
  query getRestaurantsByIds($ids: [String!]!) {
    getRestaurantsByIds(ids: $ids) {
      restaurants {
        id
        name
        outlets {
          id
        }
      }
    }
  }
`

export default GET_RESTAURANTS_BY_IDS
