import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import ReviewsTable from '@components/Reviews/ReviewsTable'
import productDictionary from '@src/utils/productDictionary'
import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CustomersList from './CustomersList'

const Customers = () => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product: productDictionary.CUSTOMERS,
      tabs: [
        { to: `/list`, name: 'List' },
        { to: `/reviews`, name: 'Reviews' },
      ],
    })
  }, [configurePageLayout])

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/customers/list" />} />
      <Route path="list" element={<CustomersList />} />
      <Route path="reviews" element={<ReviewsTable />} />
    </Routes>
  )
}

export default Customers
