import { useContext, useState } from 'react'
import { find } from 'lodash'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { Formik } from 'formik'
import { formatEnum } from '@utils/helpers'
import { errorToast, successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_MESSAGE_TEMPLATES from './queries/getMarketplaceMessageTemplates.query'
import EDIT_MARKETPLACE_MESSAGE_TEMPLATE from './mutations/editMarketplaceMessageTemplate.mutation'
import ADD_MARKETPLACE_MESSAGE_TEMPLATE from './mutations/addMarketplaceMessageTemplate.mutation'
import Form from './Form'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { useMutation } from '@apollo/client'
import sanitizeHTML from 'sanitize-html-react'
import { sanitizeOptions } from '@src/components/EmailLivePreview/HTMLEmailPreview'
import { GetMarketplaceMessageTemplatesQuery } from './queries/getMarketplaceMessageTemplates.query.generated'

const MessageDrawer = ({
  templateType,
  marketplaceId,
  isOpen,
  drawerClose,
}) => {
  const { dark } = useContext(PageLayoutContext)

  const [templateVarErrors, setTemplateVarErrors] = useState([])

  const templateName = formatEnum(templateType)

  const [editMarketplaceMessageTemplate] = useMutation(
    EDIT_MARKETPLACE_MESSAGE_TEMPLATE,
    {
      onError: defaultErrorHandler,
      onCompleted: () => {
        drawerClose()
        successToast(`Saved ${templateName} Template`)
      },
    }
  )

  const [addMarketplaceMessageTemplate] = useMutation(
    ADD_MARKETPLACE_MESSAGE_TEMPLATE,
    {
      onError: defaultErrorHandler,
      onCompleted: () => {
        drawerClose()
        successToast(`Created ${templateName} Template`)
      },
      refetchQueries: [
        {
          query: GET_MARKETPLACE_MESSAGE_TEMPLATES,
          variables: {
            marketplaceId: marketplaceId,
          },
        },
      ],
    }
  )

  const sanitiseContent = values => {
    return {
      ...values,
      emailContent: values.emailContent.map(content => ({
        type: content.type,
        text: sanitizeHTML(content.text, sanitizeOptions),
      })),
    }
  }

  return templateType ? (
    <Query<GetMarketplaceMessageTemplatesQuery>
      query={GET_MARKETPLACE_MESSAGE_TEMPLATES}
      variables={{ marketplaceId }}
    >
      {({ getMarketplaceMessageTemplates, getMarketplaces }) => {
        // TODO refactor to a query for just one
        const template = find(getMarketplaceMessageTemplates.messageTemplates, {
          templateType,
        })
        const isEditing = template && template.id
        const [marketplace] = getMarketplaces.regions

        return (
          <Drawer
            isOpen={isOpen}
            onClose={drawerClose}
            title={`${templateName || 'Message'} Template`}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            className={dark ? 'bp5-dark' : ''}
            size={DrawerSize.LARGE}
          >
            {isEditing ? (
              <Formik
                onSubmit={values => {
                  if (templateVarErrors.length === 0) {
                    const sanitisedEmailContent = sanitiseContent(values)

                    return editMarketplaceMessageTemplate({
                      variables: {
                        id: values.id,
                        marketplaceId,
                        ...values,
                        ...sanitisedEmailContent,
                      },
                    })
                  } else {
                    errorToast(
                      `Please fix incorrect message variables before saving template`
                    )
                  }
                }}
                initialValues={{
                  ...template,
                  marketplaceName: marketplace.name,
                  marketplaceIcon: marketplace.faviconImage,
                  marketplaceEmail: `${
                    marketplace.emailSenderUsername
                  }@${marketplace.cname.trim().replace(/^www\./, '')}`,
                }}
                validateOnChange={false}
              >
                {props => (
                  <Form
                    {...props}
                    templateType={templateType}
                    isEditing={isEditing}
                    marketplaceId={marketplaceId}
                    setTemplateVarErrors={setTemplateVarErrors}
                    templateVarErrors={templateVarErrors}
                  />
                )}
              </Formik>
            ) : (
              <Formik
                onSubmit={values => {
                  if (templateVarErrors.length === 0) {
                    return addMarketplaceMessageTemplate({
                      variables: {
                        marketplaceId: marketplaceId,
                        templateType: templateType,
                        ...values,
                      },
                    })
                  } else {
                    errorToast(
                      `Please fix incorrect message variables before saving template`
                    )
                  }
                }}
                initialValues={{
                  emailSubject: '',
                  emailPreviewText: '',
                  emailContent: [],
                  pushTitle: '',
                  pushBody: '',
                  marketplaceName: marketplace.name,
                  marketplaceIcon: marketplace.faviconImage,
                  marketplaceEmail: `${
                    marketplace.emailSenderUsername
                  }@${marketplace.cname.trim().replace(/^www\./, '')}`,
                  ...template,
                }}
                refetchQueries={[
                  {
                    query: GET_MARKETPLACE_MESSAGE_TEMPLATES,
                    variables: {
                      marketplaceId: marketplaceId,
                    },
                  },
                ]}
                validateOnChange={false}
              >
                {props => (
                  <Form
                    {...props}
                    isEditing={isEditing}
                    templateType={templateType}
                    marketplaceId={marketplaceId}
                    setTemplateVarErrors={setTemplateVarErrors}
                    templateVarErrors={templateVarErrors}
                  />
                )}
              </Formik>
            )}
          </Drawer>
        )
      }}
    </Query>
  ) : null
}

export default MessageDrawer
