import React from 'react'
import Query from '@components/Query/Query'
import GET_MARKETPLACES_GROUPED_BY_CUISINE_IDS from '../queries/getMarketplacesGroupedByCuisineIds.query'
import Categories from './Categories'

const marketplacesMapByCuisineId = arrayOfRecords =>
  arrayOfRecords.reduce((acc, record) => {
    acc[`${record.categoryId}`] = record.marketplaces
    return acc
  }, {})

const CategoriesWrapper = () => (
  <Query query={GET_MARKETPLACES_GROUPED_BY_CUISINE_IDS}>
    {({ getMarketplacesGroupedByCuisineIds }) => {
      const marketplacesMap = marketplacesMapByCuisineId(
        getMarketplacesGroupedByCuisineIds
      )

      return <Categories marketplacesMap={marketplacesMap} />
    }}
  </Query>
)

export default CategoriesWrapper
