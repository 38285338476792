import gql from 'graphql-tag'

const TOGGLE_MARKETPLACE_STATUS = gql`
  mutation toggleMarketplaceStatus($id: String!, $setDeactivated: Boolean!) {
    toggleMarketplaceStatus(id: $id, setDeactivated: $setDeactivated) {
      message
      marketplace {
        id
        name
        isDeactivated
      }
    }
  }
`

export default TOGGLE_MARKETPLACE_STATUS
