import gql from 'graphql-tag'

const GET_MARKETPLACE_LEGAL = gql`
  query getMarketplaceLegal($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        name
        legalPrivacy
        legalTerms
        legalCookies
        legalPolicy
        legalAllergy
      }
    }
  }
`

export default GET_MARKETPLACE_LEGAL
