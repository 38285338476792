import { Navigate, Route, Routes } from 'react-router-dom'
import UsersPage from '@components/Users/UsersPage'
import Permissions from '@components/Permissions/Permissions'
import { isPlatformLevelUser } from '@src/stores/userStore'

export const Admin = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/admin/users" />} />
    <Route path="/users" element={<UsersPage />} />
    {isPlatformLevelUser() && (
      <Route path="/permissions" element={<Permissions />} />
    )}
  </Routes>
)
