import gql from 'graphql-tag'

const GET_OUTLET_MENU_ITEMS = gql`
  query getOutletMenuItems($outletId: String!, $menuItemGroupId: ID!) {
    getOutlets(id: $outletId) {
      outlets {
        id
        preorderStatus
        menuItemsGroups(where: { id: $menuItemGroupId }) {
          id
          menuItems {
            id
            name
            position
            outletSoldOut
            outletHidden
          }
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLET_MENU_ITEMS
