import gql from 'graphql-tag'

const GET_COLLECTION_DETAILS = gql`
  query getCollectionDetails($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        name
        collectionCharge
        collectionMinimumOrderValue
        deliveryMinimumOrderValue
        paymentMethods
      }
    }
  }
`

export default GET_COLLECTION_DETAILS
