import styled from 'styled-components'
import Color from 'color'
import { Icon } from '@blueprintjs/core'

const theme = {
  bc1: '',
  bc2: '',
  bc3: '#2576a8', // Blue
  bc4: '#f7f6f6', // Grey
  bc5: '#2f2f2f', // Charcoal
  bc6: '',
  positive: '#48C47A',
  negative: '#EC615B', // Red
  warning: '#FEA32D', // Yellow - Orange
  neutral: '#848484', // Darker Grey
  lightBorder: '#e4e4e4',
  error: '#e4423a',
}

export const AsapRow = styled.div`
  color: ${props => props.theme.bc3};
  cursor: pointer;
  padding: 9px 15px 0px 13px;
  display: block;
  font-size: 14px;
  padding-top: 18px;
  font-weight: 600;
  padding-bottom: 18px;
  border-bottom: 1px solid ${Color(theme.bc3).rgb().alpha(0.3).string()};
  margin-bottom: 2px;

  &:hover {
    background-color: ${props =>
      Color(props.theme.lightBorder).rgb().lighten(0.08).toString()};
  }
`

export const SubLbl = styled.span`
  float: right;
  font-style: italic;
  color: ${Color(theme.bc3).rgb().alpha(0.3).string()};
  font-size: 12px;
`

export const ScrollableArea = styled.div`
  height: 500px;
  overflow: scroll;
  padding: 0;
`

export const TimeLink = styled.a`
  display: flex;
  align-items: center;
  color: ${Color(theme.neutral).rgb().darken(0.4).toString()};
  cursor: pointer;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px dotted ${({ theme }) => theme.lightBorder};

  &:hover {
    background-color: ${Color(theme.lightBorder)
      .rgb()
      .lighten(0.08)
      .toString()};
  }
`

export const DayStyles = styled.li`
  overflow: hidden;
  padding: 0;
`

export const DayTitle = styled.div`
  color: ${({ theme }) => theme.bc3};
  background-color: #fff;
  padding: 9px 15px 10px 13px;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(29, 86, 163, 0.15);
`

// TODO this seems odd to flex an icon but it's the same as the original
export const ActiveIcon = styled(Icon)`
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: auto;
  fill: #108050;
`

export const DayPickerLabel = styled.span`
  margin-right: 6px;
  font-weight: 400;
`
