import React from 'react'
import { Card } from '@blueprintjs/core'
import { object } from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import styled from 'styled-components'

export const ReviewText = styled(Card)`
  margin-top: 1rem;
`

const ReviewBody = ({ review }) => {
  const { reviewText, reply, replyDate } = review
  const name = get(review, 'outlet.name', '')

  return (
    <ReviewText>
      {reviewText}
      {reply && (
        <blockquote>
          <p>
            {`On ${moment(replyDate).format('MMM Do')}`}
            <strong>{` ${name} `}</strong>replied:
          </p>
          <cite>{reply}</cite>
        </blockquote>
      )}
    </ReviewText>
  )
}

ReviewBody.propTypes = {
  review: object,
}

export default ReviewBody
