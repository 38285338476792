import React, { memo } from 'react'
import set from 'lodash/set'
import { Checkbox, Icon, Tooltip } from '@blueprintjs/core'
import { FieldSelector } from './FieldSelector'

const ImportRowStatus = ({ row, error }) => {
  const { importItem, redboxItem, saved, touched } = row
  if (importItem.id === null && !error) {
    return (
      <Tooltip content="Item will be added during import">
        <Icon icon="add" intent="success" />
      </Tooltip>
    )
  }

  if (saved && !touched && !error) {
    return (
      <Tooltip content="Item has been saved successfully">
        <Icon icon="tick-circle" intent="success" />
      </Tooltip>
    )
  }

  if (redboxItem && redboxItem.id === importItem.id) {
    return (
      <Tooltip content="Item has changes which need to be saved">
        <Icon icon="refresh" intent="primary" />
      </Tooltip>
    )
  }

  return (
    <Tooltip content="Item has errors which require attention">
      <Icon icon="error" intent="warning" />
    </Tooltip>
  )
}

export const ImportTableRow = memo(
  ({ row, error, firstError, index, setFieldValue, options, fields }) => {
    const handleChange = e => {
      const name = e.target.name
      const type = e.target.type
      const isCheckbox = type === 'checkbox'
      const value = isCheckbox ? e.target.checked : e.target.value

      const newRow = {
        ...row,
        touched: true,
        saved: false,
      }
      set(newRow, name, value)
      setFieldValue(`rows.${index}`, newRow, true)
    }

    const callbackRef = ref => {
      if (error && firstError && ref) {
        // Scroll the row into view smoothly and show the left side so user can see the error icon
        ref.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        })
      }
    }

    return (
      <tr ref={callbackRef}>
        <td>
          <Checkbox
            name={`upload`}
            checked={row.upload}
            onChange={handleChange}
          />
        </td>
        <td>
          <ImportRowStatus row={row} error={error} />
        </td>
        {Object.values(fields).map(field => {
          if (!field.visible) return false

          return (
            <td key={field.id}>
              <FieldSelector
                field={field}
                handleChange={handleChange}
                row={row}
                options={options[field.id] || []}
                error={error}
              />
            </td>
          )
        })}
      </tr>
    )
  }
)
