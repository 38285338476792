import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryZonesQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']['input'];
}>;


export type GetDeliveryZonesQuery = { __typename?: 'Query', getDeliveryZones: { __typename?: 'ManyDeliveryZonesResponse', deliveryZones?: Array<{ __typename?: 'DeliveryZone', id: string, name: string, deliveryZoneType?: Types.DeliveryZoneType | null, outletDeliveryZoneCosts?: Array<{ __typename?: 'OutletDeliveryZoneCost', id: string, outlet: { __typename?: 'Outlet', id: string, name: string } }> | null } | null> | null } };


export const GetDeliveryZonesDocument = gql`
    query getDeliveryZones($marketplaceId: String!) {
  getDeliveryZones(marketplaceId: $marketplaceId) {
    deliveryZones {
      id
      name
      deliveryZoneType
      outletDeliveryZoneCosts {
        id
        outlet {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeliveryZonesQuery__
 *
 * To run a query within a React component, call `useGetDeliveryZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryZonesQuery({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *   },
 * });
 */
export function useGetDeliveryZonesQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables> & ({ variables: GetDeliveryZonesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>(GetDeliveryZonesDocument, options);
      }
export function useGetDeliveryZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>(GetDeliveryZonesDocument, options);
        }
export function useGetDeliveryZonesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>(GetDeliveryZonesDocument, options);
        }
export type GetDeliveryZonesQueryHookResult = ReturnType<typeof useGetDeliveryZonesQuery>;
export type GetDeliveryZonesLazyQueryHookResult = ReturnType<typeof useGetDeliveryZonesLazyQuery>;
export type GetDeliveryZonesSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryZonesSuspenseQuery>;
export type GetDeliveryZonesQueryResult = Apollo.QueryResult<GetDeliveryZonesQuery, GetDeliveryZonesQueryVariables>;