import gql from 'graphql-tag'

const GET_SEGMENTS = gql`
  query getBusinessSegments($marketplaceId: String!) {
    getBusinessSegmentsByMarketplaceId(marketplaceId: $marketplaceId) {
      id
      name
      type
      showName
      position
      description
      createdAt
      updatedAt
      availabilityStartDate
      availabilityEndDate
      availabilityTimes
      outletCount
      categoryCount
      orderBy
      filterSegment
    }
  }
`

export default GET_SEGMENTS
