import GET_PARTNER_MARKETPLACES from '@components/MarketplaceMap/queries/getPartnerMarketplaces.query'
import { NonIdealState, Spinner } from '@blueprintjs/core'
import MarketplaceGeoFences from '@components/GeoJSON/MarketplaceGeoFences'
import { useParams } from 'react-router-dom'
import Query from '../Query/Query'

const MarketplaceMap = () => {
  const { partner: partnerId } = useParams()

  return (
    <Query
      query={GET_PARTNER_MARKETPLACES}
      variables={{ partnerIds: partnerId ? [partnerId] : undefined }}
    >
      {({ getMarketplaces, error = null, loading }) => {
        if (error) {
          return (
            <NonIdealState
              icon="error"
              title="Failed to load contractual maps"
              description="Please try again"
            />
          )
        }

        if (loading) {
          return (
            <NonIdealState
              icon={<Spinner />}
              title="Contractual Map"
              description="Loading, please wait..."
            />
          )
        }

        const { regions: marketplaces } = getMarketplaces

        return <MarketplaceGeoFences marketplaces={marketplaces} />
      }}
    </Query>
  )
}

export default MarketplaceMap
