import { NonIdealState } from '@blueprintjs/core'
import { isAtLeastOutletUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'
import Query from '@components/Query/Query'
import ReviewsTable from '@components/Reviews/ReviewsTable'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import GET_ALLOW_REVIEWS from './queries/getAllowReview.query'

const OutletReviews = () => {
  const { outlet } = useParams()
  const queryVars = {
    outletId: outlet,
    hideOutlet: true,
  }

  if (!isAtLeastOutletUser()) return <NoPermissions />

  return (
    <Query
      query={GET_ALLOW_REVIEWS}
      variables={{ id: outlet }}
      loaderTitle={'Loading Reviews'}
    >
      {({ getOutlets: { outlets } }) => {
        const allowReviews = get(outlets, '[0]marketplace.allowReviews')

        return allowReviews ? (
          <ReviewsTable queryVars={queryVars} />
        ) : (
          <NonIdealState
            icon="search"
            title="Reviews are disabled."
            description="Reviews are disabled at a marketplace level."
          />
        )
      }}
    </Query>
  )
}

export default OutletReviews
