import {
  Button,
  Card,
  Checkbox,
  Classes,
  FormGroup,
  H5,
  Icon,
  InputGroup,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Tab,
  Tabs,
  NumericInput,
  Spinner,
  NonIdealState,
  Divider,
} from '@blueprintjs/core'
import CreateDiscountInnerFormStruct from '@components/Discount/modals/CreateDiscountInnerFormStruct'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { string, func } from 'prop-types'
import { Fragment } from 'react'
import CREATE_VOUCHERS from './mutations/createVouchers.mutation'
import { createDiscountVouchersValidationSchema } from './validation/createDiscountVoucher'
import { numberToPence } from '@utils/helpers'
import { omit } from 'lodash'
import DISCOUNT_VOUCHERS_BY_MARKETPLACE from './queries/discountVouchersByMarketplace.query'
import { useState } from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

const CreateDiscountVoucher = ({ marketplaceId, drawerClose }) => {
  const [isCustomCode, setIsCustomCode] = useState(true)

  const [createVouchers, { loading }] = useMutation(CREATE_VOUCHERS, {
    onCompleted: ({ createVouchers }) => {
      const { count } = createVouchers
      successToast(
        `Successfully created discount with ${count} code${
          count > 1 ? 's' : ''
        }`
      )
      drawerClose()
    },
    onError: error => {
      defaultErrorHandler(error)
      drawerClose()
    },
    refetchQueries: [
      {
        query: DISCOUNT_VOUCHERS_BY_MARKETPLACE,
        variables: {
          marketplaceId,
          skip: 0,
          first: 25,
          searchText: '',
          active: true,
          orderBy: 'createdAt_DESC',
        },
      },
    ],
  })

  return (
    <div className={Classes.DRAWER_BODY}>
      {loading ? (
        <NonIdealState
          title="Creating Codes"
          description="Please wait while we create the codes ..."
          icon={<Spinner size={60} />}
        />
      ) : (
        <Formik
          validateOnChange={true}
          validationSchema={createDiscountVouchersValidationSchema}
          initialValues={{
            key: '',
            howMany: 1,
            usageLimit: 1,
            onePerCustomer: true,
            active: true,
            allowedOutletIds: [],
            name: '',
            discountPercentage: 0,
            discountAmount: 0,
            startDate: null,
            endDate: null,
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            minimumSubtotalGross: 0,
          }}
          onSubmit={async values => {
            await createVouchers({
              variables: {
                ...omit(values, ['name']),
                startDate: moment(values.startDate).toISOString(true),
                endDate: moment(values.endDate).toISOString(true),
                discountName: values.name,
                discountAmount: numberToPence(values.discountAmount),
                minimumSubtotalGross: numberToPence(
                  values.minimumSubtotalGross
                ),
                marketplaceId,
              },
            })
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <div className={Classes.DIALOG_BODY}>
              <form onSubmit={handleSubmit}>
                <CreateDiscountInnerFormStruct
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  firstLabelCaption="Discount Name"
                  marketplaceId={marketplaceId}
                  isDisabled={false}
                />
                <br />
                <Card>
                  <Tabs
                    animate
                    renderActiveTabPanelOnly={false}
                    onChange={async selectedTabId => {
                      if (selectedTabId === 'customCode') {
                        await setFieldValue('howMany', 1, false)
                        setIsCustomCode(true)
                      }
                      if (selectedTabId === 'randomCodes') {
                        await setFieldValue('usageLimit', 1, false)
                        await setFieldValue('key', '', false)
                        setIsCustomCode(false)
                      }
                    }}
                  >
                    <Tab
                      id="customCode"
                      title="Custom Code"
                      panel={
                        <Fragment>
                          <FormGroup
                            label="Code"
                            labelFor="key"
                            helperText={errors.key || ''}
                            intent={Intent.DANGER}
                            style={{ marginTop: '1rem' }}
                          >
                            <InputGroup
                              placeholder="e.g. - BOXINGDAY10"
                              name="key"
                              id="key"
                              value={values.key}
                              onChange={e =>
                                setFieldValue(
                                  'key',
                                  e.currentTarget.value.toUpperCase()
                                )
                              }
                              fill
                            />
                          </FormGroup>
                          <FormGroup
                            label="Usage Limit"
                            labelFor="usageLimit"
                            labelInfo=" (set to 0 for unlimited usage)"
                            helperText={errors.usageLimit || ''}
                            intent={Intent.DANGER}
                          >
                            <NumericInput
                              name="usageLimit"
                              id="usageLimit"
                              value={values.usageLimit}
                              min={0}
                              stepSize={1}
                              onValueChange={val =>
                                setFieldValue('usageLimit', val)
                              }
                              fill
                              buttonPosition="none"
                            />
                          </FormGroup>
                        </Fragment>
                      }
                    />
                    <Tab
                      id="randomCodes"
                      title="Random Codes"
                      panel={
                        <FormGroup
                          label="Number of Codes to Generate"
                          labelFor="howMany"
                          helperText={
                            errors.howMany ||
                            'Codes will be available to download as a CSV file after the discount is created.'
                          }
                          intent={errors.howMany ? Intent.DANGER : Intent.NONE}
                        >
                          <NumericInput
                            name="howMany"
                            id="howMany"
                            value={values.howMany}
                            min={1}
                            stepSize={1}
                            onValueChange={val => setFieldValue('howMany', val)}
                            fill
                            buttonPosition="none"
                          />
                        </FormGroup>
                      }
                    />
                    <Tabs.Expander />
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      position={Position.LEFT_TOP}
                      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                      content={
                        <Fragment>
                          <H5>Discount Code</H5>
                          <p>
                            This is the code that you can pass to customers.
                          </p>
                          <p>
                            You can use your own or we can generate random ones.
                            If you choose to use your custom code you'll also
                            have to set the number of times the code can be
                            used.
                          </p>
                        </Fragment>
                      }
                    >
                      <Icon icon="info-sign" color="#ced9e0" />
                    </Popover>
                  </Tabs>
                </Card>

                {isCustomCode && (
                  <Fragment>
                    <Checkbox
                      name="onePerCustomer"
                      labelElement={
                        <Fragment>
                          Single Use Per Customer <br />
                          <span className="bp5-text-small bp5-text-muted">
                            {errors.onePerCustomer ||
                              'Single use or allow this code to be reused.'}
                          </span>
                        </Fragment>
                      }
                      id="onePerCustomer"
                      checked={values.onePerCustomer}
                      onChange={handleChange}
                      inline
                    />
                    <br />
                    <Divider />
                    <br />
                  </Fragment>
                )}

                <Checkbox
                  name="active"
                  labelElement={
                    <Fragment>
                      Activated <br />
                      <span className="bp5-text-small bp5-text-muted">
                        {errors.onePerCustomer ||
                          'Discount can be used at checkout.'}
                      </span>
                    </Fragment>
                  }
                  id="active"
                  checked={values.active}
                  onChange={handleChange}
                  inline
                />
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Create" type="submit" />
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </div>
  )
}

CreateDiscountVoucher.propTypes = {
  marketplaceId: string.isRequired,
  drawerClose: func.isRequired,
}
export default CreateDiscountVoucher
