import { MINUTES_FOR_AUTO_REJECT_ALARM } from '@utils/constants'
import moment from 'moment'

export const isInAlarm = (status, autoRejectAt) => {
  return (
    status === 'PENDING' &&
    autoRejectAt &&
    moment().add(MINUTES_FOR_AUTO_REJECT_ALARM, 'minutes').isAfter(autoRejectAt)
  )
}
