import gql from 'graphql-tag'

const GET_CUISINES = gql`
  query getCuisinesPaginated(
    $skip: Int
    $first: Int
    $last: Int
    $nameContains: String
    $marketplaceIds: [ID!]
  ) {
    getCuisines(
      skip: $skip
      first: $first
      last: $last
      nameContains: $nameContains
      marketplaceIds: $marketplaceIds
    ) {
      count
      totalCount
      cuisines {
        id
        name
        emoji
        imageSrc
        archived
      }
    }
  }
`

export default GET_CUISINES
