import gql from 'graphql-tag'

const BLACKLIST_ADDRESS = gql`
  mutation blacklistAddress(
    $marketplaceId: String!
    $firstLine: String!
    $secondLine: String
    $thirdLine: String
    $city: String!
    $postcode: String!
    $country: Json
  ) {
    blacklistAddress(
      marketplaceId: $marketplaceId
      firstLine: $firstLine
      secondLine: $secondLine
      thirdLine: $thirdLine
      city: $city
      postcode: $postcode
      country: $country
    ) {
      message
    }
  }
`

export default BLACKLIST_ADDRESS
