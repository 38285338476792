export const PaymentStatus = {
  // CVC
  cvc_check_pass: {
    tag: 'CVC',
    minimal: true,
    icon: 'small-tick',
    title: 'CVC Check Passed',
    intent: 'success',
    details:
      'The provided CVC has passed the verification check. This means the CVC matched the one on file with the card issuer.',
  },
  cvc_check_fail: {
    tag: 'CVC',
    minimal: false,
    icon: 'small-cross',
    title: 'CVC Check Failed',
    intent: 'danger',
    details:
      'The provided CVC has failed the verification check. Possible reasons include an incorrect CVC entered or a mismatch with the card issuer’s records.',
  },
  cvc_check_unavailable: {
    tag: 'CVC',
    minimal: true,
    title: 'CVC Check Unavailable',
    details:
      'The verification check for the provided CVC is unavailable. This could be due to technical issues or the card issuer not supporting CVC checks.',
  },
  cvc_check_unchecked: {
    tag: 'CVC',
    title: 'CVC Check Unchecked',
    intent: 'warning',
    minimal: true,
    details:
      'The CVC was provided but has not been checked. This can occur if the transaction did not require a CVC check or if the check was bypassed.',
  },
  // postcode
  postcode_check_pass: {
    tag: 'POSTCODE',
    minimal: true,
    icon: 'small-tick',
    intent: 'success',
    title: 'Postcode Check Passed',
    details:
      'The provided postal code has passed the verification check. This indicates the postal code matched the one on file with the card issuer.',
  },
  postcode_check_fail: {
    tag: 'POSTCODE',
    minimal: false,
    intent: 'danger',
    title: 'Postcode Check Failed',
    icon: 'small-tick',
    details:
      'The provided postal code has failed the verification check. Possible reasons include an incorrect postal code entered or a mismatch with the card issuer’s records.',
  },
  postcode_check_unavailable: {
    tag: 'POSTCODE',
    minimal: true,
    title: 'Postcode Check Unavailable',
    details:
      'The verification check for the provided postal code is unavailable. This could be due to technical issues or the card issuer not supporting postal code checks.',
  },
  postcode_check_unchecked: {
    tag: 'POSTCODE',
    intent: 'warning',
    minimal: true,
    title: 'Postcode Check Unchecked',
    details:
      'The postal code was provided but has not been checked. This can occur if the transaction did not require a postal code check or if the check was bypassed.',
  },
}
