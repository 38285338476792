import gql from 'graphql-tag'

const SUSPEND_LOYALTY_CARD = gql`
  mutation suspendLoyaltyCard(
    $loyaltyCardId: String!
    $suspended: Boolean!
    $marketplaceId: String!
  ) {
    suspendLoyaltyCard(
      loyaltyCardId: $loyaltyCardId
      suspended: $suspended
      marketplaceId: $marketplaceId
    ) {
      loyaltyCard {
        id
        suspended
      }
      message
    }
  }
`

export default SUSPEND_LOYALTY_CARD
