import OutletLink from '@src/components/OpenStatus/OutletLink'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'
import { ComponentProps } from 'react'

const collator = new Intl.Collator(undefined, {
  sensitivity: 'base',
  ignorePunctuation: true,
})

export interface OutletColumn<X extends string>
  extends BaseColumn<'outlet', X> {}

export interface OutletColumnData {
  outlet: ComponentProps<typeof OutletLink>['outlet']
  onClick?: () => void
}

export const sortOutletColumn: ColumnSorter<'outlet'> = (a, b, order) => {
  const negator = order === 'ASC' ? 1 : -1
  return negator * collator.compare(b.outlet.name, a.outlet.name)
}

export const OutletColumnContent: ColumnRenderer<'outlet', string> = ({
  columnData,
}) => {
  return (
    <OutletLink
      outlet={columnData.outlet}
      displayBusinessName
      overrideMainLink={columnData.onClick}
    />
  )
}
