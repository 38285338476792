import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceEnableLoyaltyCardScheme = ({
  updateStatus,
  enableLoyalty,
}) => {
  return (
    <Formik initialValues={{ enableLoyalty }}>
      {({ values, setFieldValue }) => (
        <section>
          <Switch
            label={'Loyalty Cards'}
            checked={values.enableLoyalty}
            onChange={e => {
              const newValue = e.currentTarget.checked
              updateStatus({
                enableLoyalty: newValue,
              })
              setFieldValue('enableLoyalty', newValue)
            }}
            name="enableLoyalty"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceEnableLoyaltyCardScheme.propTypes = {
  enableLoyalty: bool,
  errors: object,
}

export default MarketplaceEnableLoyaltyCardScheme
