import Filter from '@components/Toolbar/Filter'
import React from 'react'

import GET_RESTAURANT_IDS from './queries/getRestaurantIds.query'
interface Props {
  disabled?: boolean
  restaurantFilter: string[]
  marketplaceFilter: string[]
  onChange: (string) => void
}
export const RestaurantFilter: React.FC<Props> = ({
  disabled,
  restaurantFilter,
  marketplaceFilter,
  onChange,
}) => (
  <Filter
    name="Business"
    disabled={disabled}
    filter={restaurantFilter}
    onChange={onChange}
    query={GET_RESTAURANT_IDS}
    variables={{ marketplaceIds: marketplaceFilter }}
    dataPath="getOutlets.outlets"
    itemPath="restaurant"
  />
)
