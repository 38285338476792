import React from 'react'
import { HTMLTable, Icon } from '@blueprintjs/core'
import { array } from 'prop-types'
import { Link } from 'react-router-dom'
import { canView } from '@stores/userStore'
import Currency from '@components/Currency/Currency'

const TaxesTable = ({ taxRecords }) => {
  return (
    <HTMLTable bordered={false} interactive={true}>
      <thead>
        <tr>
          <th colSpan={3} />
          <th colSpan={3} style={{ borderLeft: '1px solid #DCDCDD' }}>
            Products
          </th>
          <th colSpan={3} style={{ borderLeft: '1px solid #DCDCDD' }}>
            Fulfilment
          </th>
          <th colSpan={3} style={{ borderLeft: '1px solid #DCDCDD' }}>
            Service Charges
          </th>
        </tr>
        <tr>
          <th>Restaurant</th>
          <th>Outlet</th>
          <th>Marketplace</th>
          <th style={{ borderLeft: '1px solid #DCDCDD' }}>Net</th>
          <th>Gross</th>
          <th>VAT</th>
          <th style={{ borderLeft: '1px solid #DCDCDD' }}>Net</th>
          <th>Gross</th>
          <th>VAT</th>
          <th style={{ borderLeft: '1px solid #DCDCDD' }}>Net</th>
          <th>Gross</th>
          <th>VAT</th>
        </tr>
      </thead>
      <tbody>
        {taxRecords.map(taxRecord => (
          <tr key={taxRecord.outlet.id}>
            <td>
              {canView('restaurants') ? (
                <Link
                  className="bp5-text-overflow-ellipsis"
                  to={`/business/${taxRecord.outlet.restaurant.id}`}
                >
                  {taxRecord.outlet.restaurant.name}
                </Link>
              ) : (
                taxRecord.outlet.restaurant.name
              )}
            </td>
            <td>
              <span className="bp5-text-overflow-ellipsis">
                <Icon
                  icon="symbol-circle"
                  color={taxRecord.outlet.isOnline ? '#5bb70d' : '#CDD6DD'}
                />
                &nbsp;
                {canView('restaurants') ? (
                  <Link
                    to={`/business/${taxRecord.outlet.restaurant.id}/outlets/${taxRecord.outlet.id}`}
                  >
                    {taxRecord.outlet.name}
                  </Link>
                ) : (
                  taxRecord.outlet.name
                )}
              </span>
            </td>
            <td>
              {canView('marketplaces') ? (
                <Link to={`/marketplaces/${taxRecord.outlet.marketplace.id}`}>
                  {taxRecord.outlet.marketplace.name}
                </Link>
              ) : (
                taxRecord.outlet.marketplace.name
              )}
            </td>
            <td style={{ borderLeft: '1px solid #DCDCDD' }}>
              <Currency amount={taxRecord.productsNet} />
            </td>
            <td>
              <Currency amount={taxRecord.productsGross} />
            </td>
            <td>
              <Currency amount={taxRecord.productsVAT} />
            </td>
            <td style={{ borderLeft: '1px solid #DCDCDD' }}>
              <Currency amount={taxRecord.fulfilmentNet} />
            </td>
            <td>
              <Currency amount={taxRecord.fulfilmentGross} />
            </td>
            <td>
              <Currency amount={taxRecord.fulfilmentVAT} />
            </td>
            <td style={{ borderLeft: '1px solid #DCDCDD' }}>
              <Currency amount={taxRecord.serviceChargeNet} />
            </td>
            <td>
              <Currency amount={taxRecord.serviceChargeGross} />
            </td>
            <td>
              <Currency amount={taxRecord.serviceChargeVAT} />
            </td>
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  )
}

TaxesTable.propTypes = {
  taxRecords: array,
}

export default TaxesTable
