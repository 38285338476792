import gql from 'graphql-tag'

const GET_SEGMENTS_FOR_MARKETPLACE = gql`
  query getSegmentsForMarketplace(
    $marketplaceId: ID!
    $nameContains: String
    $skip: Int
    $first: Int
  ) {
    getSegments(
      marketplaceId: $marketplaceId
      skip: $skip
      first: $first
      nameContains: $nameContains
    ) {
      count
      totalCount
      segments {
        id
        name
        estimate
        updatedAt
        filterGroupsType
        filterGroups {
          filterGroupType
          filters {
            attribute
            comparator
            value
          }
        }
      }
    }
  }
`

export default GET_SEGMENTS_FOR_MARKETPLACE
