import { Fragment } from 'react'
import { MenuItem, MenuDivider } from '@blueprintjs/core'
import { string, array, number } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { orderList } from '@utils/orderList'
import { successToast } from '@utils/toast'
import GET_MARKETPLACE_WEBSITE from './queries/getMarketplaceWebsite.query'
import { useMutation } from '@apollo/client'

const moveItemUp = 'up'
const moveItemDown = 'down'

const MoveUpDown = ({
  mutationToExecute,
  index,
  initialList,
  marketplaceId,
}) => {
  const [editPosition] = useMutation(mutationToExecute, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Link position updated')
    },
    refetchQueries: [
      {
        query: GET_MARKETPLACE_WEBSITE,
        variables: { id: marketplaceId },
      },
    ],
  })

  return (
    <Fragment>
      <MenuDivider title="Sort" />

      <MenuItem
        disabled={index === 0}
        text="Move Up"
        icon="chevron-up"
        onClick={() => {
          const payload = orderList(initialList, index, moveItemUp)
          editPosition({
            variables: {
              input: payload,
              marketplaceId,
            },
          })
        }}
      />

      <MenuItem
        disabled={index === initialList.length - 1}
        text="Move Down"
        icon="chevron-down"
        onClick={() => {
          const payload = orderList(initialList, index, moveItemDown)
          editPosition({
            variables: {
              input: payload,
              marketplaceId,
            },
          })
        }}
      />
    </Fragment>
  )
}

MoveUpDown.propTypes = {
  mutationToExecute: string,
  refetchQueries: array,
  index: number,
  initialList: array,
}

export default MoveUpDown
