import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowMarketingForm = ({ updateStatus, allowMarketing }) => {
  return (
    <Formik initialValues={{ allowMarketing }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Marketing'}
            checked={values.allowMarketing}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowMarketing: e.currentTarget.checked,
              })
            }}
            name="allowMarketing"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowMarketingForm.propTypes = {
  allowMarketing: bool,
  errors: object,
}

export default MarketplaceAllowMarketingForm
