import gql from 'graphql-tag'

const GET_STATS = gql`
  query getMarketplaceDashboardSales(
    $startDate: DateTime!
    $endDate: DateTime!
    $period: String!
    $marketplaceId: String!
  ) {
    salesBy(
      startDate: $startDate
      endDate: $endDate
      period: $period
      marketplaceId: $marketplaceId
    ) {
      sales {
        date
        totalOrders
        totalOrdersValue
        totalRefundsValue
        totalRefunds
      }
    }
  }
`

export default GET_STATS
