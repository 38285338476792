import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { DateRangePicker3 as BPDateRangePicker } from '@blueprintjs/datetime2'
import { ISO_WEEK_DAY } from '../../../utils/datetime'
import { endOfDay, min } from 'date-fns'

// Applies default settings to the DateRangePicker component:
// - Sets the first day of the week to Monday
// - Limits the max date to the current date
export const DateRangePicker = props => (
  <BPDateRangePicker
    {...props}
    dayPickerProps={{
      firstDayOfWeek: ISO_WEEK_DAY.MONDAY,
    }}
    maxDate={
      props.maxDate
        ? min([props.maxDate, endOfDay(new Date())])
        : endOfDay(new Date())
    }
    shortcuts={false}
    highlightCurrentDay
  />
)
