import moment from 'moment'
import { Button, HTMLTable } from '@blueprintjs/core'

import HumanReadableFilters from '../shared/HumanReadableFilters'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import DELETE_SEGMENT from '../mutations/deleteSegment.mutation'
import { successToast } from '@utils/toast'
import { useMutation } from '@apollo/client'

const CustomerSegmentsTable = ({
  data,
  setEditSegmentId,
  marketplaceId,
  refetchSegments,
}) => {
  const [deleteRequest] = useMutation(DELETE_SEGMENT, {
    onCompleted: () => {
      refetchSegments()
      successToast('Successfully deleted segment')
    },
  })

  return (
    <HTMLTable bordered={false} interactive={true}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Created</th>
          <th>Customer Reach</th>
          <th>Dimensions</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(segment => {
          const {
            name,
            id,
            updatedAt,
            estimate,
            filterGroups,
            filterGroupsType,
          } = segment

          return (
            <tr key={id}>
              <td>
                <a onClick={() => setEditSegmentId(id)}>{name}</a>
              </td>
              <td>{moment(updatedAt).format('DD/MM/YYYY, HH:mm')}</td>
              <td>{estimate}</td>
              <td style={{ maxWidth: '50rem' }}>
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <HumanReadableFilters
                    filterGroups={filterGroups}
                    filterGroupsType={filterGroupsType}
                  />
                </div>
              </td>
              <td>
                {name !== 'AllCustomers' && (
                  <ConfirmationPopover
                    remove={() =>
                      deleteRequest({
                        variables: { id, marketplaceId },
                      })
                    }
                    confirmationText={`Are you sure you want to delete this segment?`}
                  >
                    <Button text="" minimal={true} icon="trash" />
                  </ConfirmationPopover>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </HTMLTable>
  )
}

export default CustomerSegmentsTable
