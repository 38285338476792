export const Disputes = {
  // Dispute statuses
  warning_needs_response: {
    intent: 'warning',
    title: 'Needs Response',
    details:
      'The dispute is in warning status and requires your response. Please review the dispute details and submit a response to avoid potential penalties. For assistance in crafting a dispute response, please refer to our Dispute Resolution Guide or contact our support team.',
  },
  warning_under_review: {
    title: 'Under Review',
    details:
      'The dispute is currently under review, and no action is required at this moment. Keep an eye on updates for any changes. For more information, contact support.',
  },
  warning_closed: {
    title: 'Closed',
    details:
      'This dispute has been closed and requires no further action. For details on why this dispute was closed or any follow-up actions, consult the resolution documentation or contact support.',
  },
  needs_response: {
    intent: 'warning',
    title: 'Needs Response',
    details:
      'This dispute requires a response. Act promptly to submit the necessary documentation and response to address the dispute. Refer to our step-by-step response submission guide, or contact customer support for help.',
  },
  response_disabled: {
    title: 'Response Disabled',
    details:
      'Responses to this dispute are disabled, possibly due to a processing error or an admin decision. Please contact support for further details and resolution options.',
  },
  under_review: {
    title: 'Under Review',
    details:
      'The dispute is under review. No action is needed from you at this moment. Wait for the review process to complete. Monitor your email notifications and dashboard for updates. Contact our support team if you have any questions.',
  },
  charge_refunded: {
    title: 'Charge Refunded',
    details:
      'The charge has been refunded. Ensure that the refund is processed correctly and reflected in your records. If there are issues with the refund processing, please reach out to our support team for assistance.',
  },
  won: {
    intent: 'success',
    title: 'Dispute Won',
    details:
      'The dispute has been won. No further action is needed. Ensure this resolution is documented for your records. If you have questions about maintaining good practices to prevent future disputes, contact our educational support.',
  },
  lost: {
    intent: 'danger',
    title: 'Dispute Lost',
    details:
      'The dispute has been lost. Review the reasons provided for the loss and apply the insights to prevent future occurrences. Consider consulting with our dispute management advisory team to understand more about this decision and how to improve.',
  },
  // Dispute reasons
  fraudulent: {
    title: 'Fraudulent',
    details:
      'The charge has been marked as fraudulent by the cardholder. It is essential to respond promptly with evidence that the transaction was legitimate. Consult our Fraud Prevention Guide or contact support for detailed advice on handling these disputes.',
  },
  duplicate: {
    title: 'Duplicate',
    details:
      'The cardholder claims that they were charged multiple times for the same transaction. Review transaction records and provide evidence showing each charge was for a separate purchase or refund any duplicate charges immediately. Contact customer support for assistance in resolving this matter.',
  },
  subscription_canceled: {
    title: 'Subscription Canceled',
    details:
      'The cardholder states that they canceled a subscription, but charges continued. Provide documentation of the subscription’s cancellation policy and any interactions with the cardholder regarding cancellation. Support is available to guide you through the resolution process.',
  },
  product_unacceptable: {
    title: 'Product Unacceptable',
    details:
      'The cardholder has indicated that the product or service received was not as described or was defective. Gather and submit proof of the product’s condition or the service provided, including any terms and conditions that clarify what was agreed upon.',
  },
  product_not_received: {
    title: 'Product Not Received',
    details:
      'The cardholder claims they did not receive the products they purchased. Provide proof of delivery or shipment records to show that the product was delivered to the cardholder’s address. This can include tracking numbers and delivery confirmations.',
  },
}
