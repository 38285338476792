import gql from 'graphql-tag'

const GET_RESTAURANT_IDS = gql`
  query getRestaurantIds($marketplaceIds: [ID]) {
    getOutlets(marketplaceIds: $marketplaceIds) {
      outlets {
        id
        name
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_RESTAURANT_IDS
