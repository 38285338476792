import gql from 'graphql-tag'

const EDIT_RESTAURANT_VAT = gql`
  mutation editRestaurantVAT(
    $id: String!
    $isVATregistered: Boolean!
    $vatNumber: String
  ) {
    editRestaurantVAT(
      id: $id
      isVATregistered: $isVATregistered
      vatNumber: $vatNumber
    ) {
      id
      vatNumber
      isVATregistered
    }
  }
`
export default EDIT_RESTAURANT_VAT
