import gql from 'graphql-tag'

const EDIT_DELIVERY_ZONE = gql`
  mutation editDeliveryZone($id: String!, $name: String) {
    editDeliveryZone(id: $id, name: $name) {
      message
      deliveryZone {
        id
        key
        name
        outletDeliveryZoneCosts {
          id
          outlet {
            id
            name
          }
        }
      }
    }
  }
`

export default EDIT_DELIVERY_ZONE
