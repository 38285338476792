import gql from 'graphql-tag'

const GET_REVENUE = gql`
  query revenue(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $partnerIds: [ID!]
  ) {
    revenue(
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
      partnerIds: $partnerIds
    ) {
      marketplace {
        id
        name
      }
      totalRefunds
      totalOrders
      totalOrdersValue
      totalNetworkValue
      totalDiscountValue
      totalPlatformValue
      totalPaymentProcessorValue
      totalCommissionValue
      totalPartnerValue
      totalRedboxValue
      totalPaidCashValue
      totalCashCommission
      totalRefundsValue
      totalRevenue
    }
  }
`

export default GET_REVENUE
