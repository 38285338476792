import { Fragment, useState } from 'react'
import { Button, Collapse, Icon } from '@blueprintjs/core'
import { successToast } from './toast'
import { TemplateTypesEnum } from '../types.generated'

const acceptVars = [
  'order.estimatedDeliveryDate',
  'order.estimatedCompletionTime',
  'order.orderNumber',
  'order.createdAt',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.contactPhone',
  'order.outlet.marketplace.name',
  'order.outlet.marketplace.cname',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.grossTotal',
]

const cancelDeliveryVars = [
  'order.orderNumber',
  'order.createdAt',
  'order.cancellationNotes',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.contactPhone',
  'order.outlet.marketplace.name',
  'order.outlet.marketplace.cname',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.customer.id',
]

const completeVars = [
  'order.orderNumber',
  'order.createdAt',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.contactPhone',
  'order.outlet.marketplace.name',
  'order.outlet.marketplace.cname',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.customer.id',
]

const failVars = [
  'customer.id',
  'customer.email',
  'customer.firstName',
  'customer.lastName',
  'customer.marketplace.id',
  'customer.marketplace.name',
  'customer.marketplace.cname',
  'outlet.name',
  'outlet.restaurant.name',
]

const readyVars = [
  'order.orderNumber',
  'order.createdAt',
  'order.estimatedCompletionTime',
  'order.estimatedDeliveryDate',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.marketplace.cname',
  'order.outlet.marketplace.name',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.customer.id',
]

const registrationVars = [
  'customer.id',
  'customer.email',
  'customer.firstName',
  'customer.lastName',
  'customer.marketplace.id',
  'customer.marketplace.cname',
  'customer.marketplace.name',
]

const rejectVars = [
  'order.orderNumber',
  'order.createdAt',
  'order.cancellationNotes',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.marketplace.name',
  'order.outlet.marketplace.cname',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.customer.id',
]

const resetVars = [
  'customer.id',
  'customer.email',
  'customer.firstName',
  'customer.lastName',
  'customer.resetToken',
  'customer.marketplace.id',
  'customer.marketplace.name',
]

const updateTimeVars = [
  'order.orderNumber',
  'order.createdAt',
  'order.estimatedDeliveryDate',
  'order.estimatedCompletionTime',
  'order.fulfillmentMethod',
  'order.deliveryNetworkJobTrackerURL',
  'order.deliveryNetworkProvider',
  'order.outlet.name',
  'order.outlet.contactAddress.firstLine',
  'order.outlet.contactAddress.secondLine',
  'order.outlet.contactAddress.city',
  'order.outlet.contactAddress.postcode',
  'order.outlet.marketplace.name',
  'order.outlet.marketplace.cname',
  'order.outlet.restaurant.name',
  'order.outlet.restaurant.contactAddress.firstLine',
  'order.outlet.restaurant.contactAddress.secondLine',
  'order.outlet.restaurant.contactAddress.city',
  'order.outlet.restaurant.contactAddress.postcode',
  'order.outlet.restaurant.contactPhone',
  'order.customer.firstName',
  'order.customer.email',
  'order.customer.id',
  'order.dateToShowUser',
  'order.orderType',
]

const inviteToDiscountVars = [
  'customerFirstName',
  'customerLastName',
  'businessName',
  'discountPercentage',
  'enrolmentKey',
  'acceptDiscountButtonLink',
]

export const templateVars = {
  [TemplateTypesEnum.AcceptCollection]: acceptVars,
  [TemplateTypesEnum.AcceptDelivery]: acceptVars,
  [TemplateTypesEnum.AcceptTable]: acceptVars,
  [TemplateTypesEnum.Cancel]: cancelDeliveryVars,
  [TemplateTypesEnum.Complete]: completeVars,
  [TemplateTypesEnum.Fail]: failVars,
  [TemplateTypesEnum.ReadyCollection]: readyVars,
  [TemplateTypesEnum.ReadyDelivery]: readyVars,
  [TemplateTypesEnum.Registration]: registrationVars,
  [TemplateTypesEnum.Reject]: rejectVars,
  [TemplateTypesEnum.Reset]: resetVars,
  [TemplateTypesEnum.UpdateTime]: updateTimeVars,
  [TemplateTypesEnum.InviteToDiscount]: inviteToDiscountVars,
}

const TemplateVarButton = ({ templateVar, varPlaceholder = undefined }) => (
  <Fragment>
    <button
      style={{ marginTop: '5px' }}
      className="bp5-button bp5-small"
      onClick={e => {
        e.preventDefault()
        void navigator.clipboard.writeText(`{{${templateVar}}}`)
        successToast(`Copied to clipboard!`)
      }}
    >
      {' '}
      {varPlaceholder || templateVar}
    </button>
    <br />
  </Fragment>
)

export const CollapsibleAvailableTemplateVariables = ({ viewTemplate }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      {viewTemplate && (
        <Fragment>
          <Button
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <Icon icon="help" />
            <span style={{ marginLeft: '10px' }}>
              Available template variables
            </span>
          </Button>
          <Collapse isOpen={isOpen}>
            <div style={{ marginTop: '20px' }}>
              <i>
                The following variables are available for use in this template.
                Click on the preferred one and paste it in the template
              </i>
              <div>
                {templateVars[viewTemplate]?.map((templateVar, idx) => (
                  <TemplateVarButton
                    templateVar={templateVar}
                    key={`templateVar${idx}`}
                  />
                ))}
              </div>
            </div>
          </Collapse>
        </Fragment>
      )}
    </Fragment>
  )
}
