import { useContext } from 'react'
import { string } from 'prop-types'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import ADD_MENU_ITEMS_GROUP from '@components/Restaurant/Menu/mutations/addMenuItemsGroup.mutation'
import { successToast } from '@utils/toast'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import MenuForm from './MenuForm'
import GET_MENU_TREE from '@components/Restaurant/Menu/queries/getMenuTree.query'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import get from 'lodash/get'
import { ModalContext } from '@src/providers/ModalProvider'
import { useMutation } from '@apollo/client'
import Query from '@src/components/Query/Query'

const AddMenuModal = ({
  modalOpen,
  modalClose,
  restaurantId,
  parentMenuId,
}) => {
  const { setState: toggleModal } = useContext(ModalContext)

  const [addMenuItemsGroup] = useMutation(ADD_MENU_ITEMS_GROUP, {
    onError: defaultErrorHandler,
    onCompleted: ({ addMenuItemsGroup }) => {
      successToast(addMenuItemsGroup.message)
      toggleModal({
        parentMenuModal: false,
      })
    },
    refetchQueries: [
      {
        query: GET_MENU_TREE,
        variables: {
          id: restaurantId,
        },
      },
      {
        query: GET_PARENT_MENUS,
        variables: {
          id: restaurantId,
        },
      },
      {
        query: GET_SUB_MENUS,
        variables: {
          id: restaurantId,
          menuId: parentMenuId,
        },
      },
    ],
  })
  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Add Menu"
      canOutsideClickClose={false}
    >
      <Query query={GET_PARENT_MENUS} variables={{ id: restaurantId }}>
        {({ data }) => {
          const position = get(
            data,
            'getRestaurants.restaurants[0].menuItemsGroups.length',
            0
          )

          return (
            <div className={Classes.DIALOG_BODY}>
              <MenuForm
                mutation={addMenuItemsGroup}
                restaurantId={restaurantId}
                menuId={null}
                position={position}
              />
            </div>
          )
        }}
      </Query>
    </Dialog>
  )
}

AddMenuModal.propTypes = {
  ...modalType,
  menuId: string,
  restaurantId: string,
}

export default AddMenuModal
