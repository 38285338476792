// returns an array of customer ids from all orders for a
// specific business in a set interval
export const allCustomersFromOrders = orders => {
  if (!orders) return []

  const customers = new Set()

  orders.reduce((acc, order) => {
    acc.add(order.customerId)

    return acc
  }, customers)

  return Array.from(customers)
}
