import gql from 'graphql-tag'

const ARCHIVE_OPTION_ITEM = gql`
  mutation archiveOptionItem($id: String!) {
    archiveOptionItem(id: $id) {
      message
      optionItem {
        id
        name
      }
    }
  }
`

export default ARCHIVE_OPTION_ITEM
