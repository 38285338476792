import { useState } from 'react'
import { Col, Row } from '@components/_FlexGrid'
import { Formik } from 'formik'
import { Spinner, NonIdealState, Card, Elevation } from '@blueprintjs/core'
import GENERATE_PREVIEW_HTML_FOR_EMAIL from '@components/Marketplace/EmailTheme/queries/generatePreviewHtmlForEmail.query'
import SanitizedHTML from '@components/EmailLivePreview/HTMLEmailPreview'
import GET_EMAIL_THEME from '@components/Marketplace/EmailTheme/queries/getEmailTheme.query'
import UPDATE_AND_SYNCHRONISE_EMAIL_THEME from '@components/Marketplace/EmailTheme/mutations/updateAndSynchroniseEmailTheme.mutaton'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import omit from 'lodash/omit'
import InnerForm from './InnerForm'
import debounce from 'lodash/debounce'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Query from '@src/components/Query/Query'

/**
 * @param {{
 *      id: string
 *      emailBackgroundColor: string
 *      emailBodyColor: string
 *      emailButtonColor: string
 *      emailFontColor: string
 *      emailFooter: string
 *      emailFooterColor: string
 *      emailImageUrl: string
 *      emailShowImage: boolean
 *      emailShowCustomLogo: boolean
 *      brandColor: string
 *      logoImage: string
 *      name: string
 *  }} theme
 */
const handleThemeDefaults = ({
  id: _id,
  name: _name,
  emailButtonColor,
  emailImageUrl,
  brandColor,
  logoImage,
  ...rest
}) => ({
  ...rest,
  emailButtonColor: emailButtonColor || brandColor,
  emailImageUrl: emailImageUrl || logoImage,
})

const exampleTemplate = [
  { text: 'This is some example text...', type: 'TEXT' },
  {
    text: 'You can set the text that would show here for each template type in the <b>Messaging</b> tab',
    type: 'TEXT',
  },
  { text: 'You can also add a button there', type: 'TEXT' },
  {
    type: 'BUTTON',
    buttonText: 'Click me!',
    buttonLink: 'https://redbox.systems/',
  },
  {
    type: 'TEXT',
    text: 'Emails sent out through marketing campaigns will also use this theme',
  },
]

const EmailTheme = () => {
  const { marketplace: marketplaceId } = useParams()
  const [loading, setLoading] = useState(false)

  const [updateAndSynchroniseEmailTheme] = useMutation(
    UPDATE_AND_SYNCHRONISE_EMAIL_THEME,
    {
      onCompleted: ({ updateAndSynchroniseEmailTheme: { message } }) => {
        setLoading(false)
        successToast(message)
      },
      onError: error => {
        setLoading(false)
        defaultErrorHandler(error)
      },
    }
  )

  return loading ? (
    <NonIdealState
      icon={<Spinner size={60} />}
      title="Updating all templates to match theme"
      description="Please wait..."
    />
  ) : (
    <Query
      query={GET_EMAIL_THEME}
      variables={{ marketplaceId }}
      loaderTitle="Loading Email Themes"
    >
      {({ getMarketplaces }) => {
        const themeData = getMarketplaces.regions[0]

        const defaults = handleThemeDefaults(themeData)

        return (
          <Query
            query={GENERATE_PREVIEW_HTML_FOR_EMAIL}
            variables={{
              ...defaults,
              content: exampleTemplate,
              marketplaceId,
            }}
          >
            {(data, refetch, previousData) => {
              const dataToDisplay = data.generatePreviewHtmlForEmail
                ? data.generatePreviewHtmlForEmail
                : previousData

              return (
                <Row>
                  <Col md={6}>
                    <Card>
                      <Formik
                        initialValues={{
                          ...defaults,
                          emailUploadImage: false,
                          logoImage: themeData.logoImage,
                        }}
                        onSubmit={values => {
                          setLoading(true)
                          return updateAndSynchroniseEmailTheme({
                            variables: {
                              theme: omit(
                                values,
                                'emailUploadImage',
                                'emailSenderUsername',
                                'cname',
                                'logoImage'
                              ),
                              marketplaceId,
                            },
                          })
                        }}
                      >
                        {props => {
                          return (
                            <InnerForm
                              {...props}
                              refetch={debounce(refetch, 250)}
                            />
                          )
                        }}
                      </Formik>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card
                      className="bp5-nopad"
                      style={{ overflow: 'hidden' }}
                      elevation={Elevation.TWO}
                    >
                      <Card style={{ marginBottom: 0 }}>
                        To:{' '}
                        <strong className="bp5-skeleton">
                          customer@gmail.com
                        </strong>
                        <br />
                        From:{' '}
                        <strong>
                          {themeData.emailSenderUsername}@
                          {themeData.cname?.trim().replace(/^www\./, '')}
                        </strong>
                        <br />
                        Subject:{' '}
                        <strong className="bp5-skeleton">
                          Your Email Subject
                        </strong>
                        <br />
                      </Card>
                      {!dataToDisplay ? (
                        <Spinner />
                      ) : (
                        <SanitizedHTML
                          exampleData={{}}
                          html={dataToDisplay.html}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default EmailTheme
