import gql from 'graphql-tag'

const CONNECT_OUTLET_LINK = gql`
  mutation connectOutlet($outletId: ID!) {
    connectOutlet(outletId: $outletId) {
      redirectUrl
      message
    }
  }
`

export default CONNECT_OUTLET_LINK
