import gql from 'graphql-tag'

const EDIT_STRIPE = gql`
  mutation editStripe(
    $id: String!
    $stripeOnboarding: Boolean
    $stripeDirectPayment: Boolean
  ) {
    editMarketplace(
      id: $id
      stripeOnboarding: $stripeOnboarding
      stripeDirectPayment: $stripeDirectPayment
    ) {
      message
      marketplace {
        id
        stripeOnboarding
        stripeDirectPayment
      }
    }
  }
`

export default EDIT_STRIPE
