import { Button, NonIdealState, Spinner } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import React, { useContext, useMemo, useState } from 'react'
import BUSINESS_ANALYTICS_DATA from '../Dashboard/queries/businessAnalyticsData.query'
import Chart from './Chart'
import { formatRawData } from './formatRawData'
import { BusinessAnalyticsContext } from './BusinessAnalyticsContext'
import moment from 'moment'
import { allCustomersFromOrders } from './allCustomersFromOrders'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

const BusinessAnalytics = ({ businessId }) => {
  const [
    {
      afterDate,
      beforeDate,
      showBlankEntries = true,
      outletId = 'All',
      customerId = 'All',
    },
  ] = useQueryParams({
    afterDate: StringParam,
    beforeDate: StringParam,
    showBlankEntries: BooleanParam,
    outletId: StringParam,
    customerId: StringParam,
  })
  const {
    urlDateFormat,
    initialAfterDateMoment,
    initialBeforeDateMoment,
    setCustomers,
  } = useContext(BusinessAnalyticsContext)

  const [rawData, setRawData] = useState(null)

  const isSingleDayShiftSelection = useMemo(
    () => Math.abs(moment(beforeDate).diff(moment(afterDate), 'days')) <= 1,
    [afterDate, beforeDate]
  )

  const filteredData = useMemo(
    () =>
      formatRawData(
        rawData,
        isSingleDayShiftSelection,
        afterDate,
        beforeDate,
        showBlankEntries,
        outletId,
        customerId
      ),
    [
      rawData,
      isSingleDayShiftSelection,
      afterDate,
      beforeDate,
      showBlankEntries,
      outletId,
      customerId,
    ]
  )

  const allCustomers = useMemo(() => allCustomersFromOrders(rawData), [rawData])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Query
        query={BUSINESS_ANALYTICS_DATA}
        variables={{
          businessId,
          startDate: afterDate
            ? moment(afterDate, urlDateFormat)
            : initialAfterDateMoment,
          endDate: beforeDate
            ? moment(beforeDate, urlDateFormat)
            : initialBeforeDateMoment,
        }}
        loaderTitle="Loading Business Data"
      >
        {({ businessOrdersForAnalyticsWithDate: data, loading, error }) => {
          if (loading) {
            return <Spinner size={Spinner.SIZE_STANDARD} />
          }
          if (error) {
            return (
              <div style={{ padding: '100px' }}>
                <NonIdealState icon="error" title="Unable to Load Analytics" />
              </div>
            )
          }

          if (!data || !data.length)
            return (
              <div style={{ padding: '100px' }}>
                <NonIdealState
                  icon="chart"
                  title="No Orders"
                  description="No data available for this range."
                  action={
                    <Button minimal intent={'primary'}>
                      Reset Filters
                    </Button>
                  }
                />
              </div>
            )

          setRawData(data)
          setCustomers(allCustomers)

          return (
            <Chart
              data={filteredData}
              isSingleDayShiftSelection={isSingleDayShiftSelection}
            />
          )
        }}
      </Query>
    </div>
  )
}

export default BusinessAnalytics
