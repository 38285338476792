import { Formik, Field } from 'formik'
import { Button, FormGroup, H5, H6, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import ColorPicker from '@components/ColorPicker/ColorPicker'

import config from '@src/config'
import { successToast, errorToast } from '@utils/toast'

import { isAtLeastPartner } from '@stores/userStore'
import ls from '@utils/localStorage'
import { themeSchema } from '@src/validation/theme/theme'
import { get } from 'lodash'
import { Col, Row } from '@components/_FlexGrid'
import LinkTag from '@components/LinkTag/LinkTag'

const Color = styled(FormGroup)`
  box-sizing: border-box;
  width: 58%;
`

const saveTheme = async ({ marketplaceId, theme, newTheme }) => {
  const managementJwt = ls.get('jwt')
  const options = {
    method: newTheme ? 'POST' : 'PATCH',
    body: JSON.stringify(theme),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${managementJwt}`,
    },
  }
  const response = await fetch(
    `${config.apiUrl}/api/marketplace/${marketplaceId}/theme`,
    options
  )
  return response.status === 200
}

const ColorField = ({ name, label, disabled = false, helperText = null }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <Color
          label={label}
          labelFor={name}
          helperText={get(form.errors, name, helperText)}
          intent={get(form.errors, name) ? Intent.DANGER : Intent.NONE}
        >
          <ColorPicker
            id={name}
            name={name}
            type="text"
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        </Color>
      )}
    </Field>
  )
}

export const ThemeBuilder = ({
  theme,
  marketplaceId,
  newTheme,
  onResetTheme,
}) => {
  const isDisabled = !isAtLeastPartner()
  return (
    <Formik
      initialValues={theme}
      enableReinitialize
      validateOnBlur
      validationSchema={toFormikValidationSchema(themeSchema)}
      onSubmit={async values => {
        const savedTheme = await saveTheme({
          marketplaceId,
          theme: { ...values, pages: null, components: null },
          newTheme,
        })
        if (savedTheme) {
          successToast(`Theme Updated.`)
        } else {
          errorToast('Failed to Update Theme. Please try again.')
        }
      }}
    >
      {({ handleSubmit, isValid, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={8}>
              <h5 className="bp5-heading">
                Theme <LinkTag type="web-v2" />
              </h5>
              <Row>
                <Col md={6}>
                  <ColorField
                    label="Site Background"
                    name="colors.siteBackground"
                    helperText={
                      'Main background, usually white or a pale colour works best.'
                    }
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={6}>
                  <ColorField
                    label="Foreground"
                    name="colors.mainText"
                    helperText={'Used as the main text colour.'}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ColorField
                    label="Header / Button"
                    name="colors.brand"
                    helperText={
                      'Main brand colour, used in the main header and on primary buttons.'
                    }
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={6}>
                  <ColorField
                    label="Foreground"
                    name="colors.brandForeground"
                    helperText={'Used for text over the brand colour.'}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ColorField
                    label="Side Bar"
                    name="colors.brandLight"
                    helperText={'Account section and secondary headers.'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={6}>
                  <ColorField
                    label="Foreground"
                    name="colors.brandDark"
                    helperText={'Used for text titles over side bar.'}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ColorField
                    label="Control Bar"
                    name="colors.grey"
                    helperText={'Background colour of the control bar.'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={6}>
                  <ColorField
                    label="Foreground"
                    name="colors.darkGrey"
                    helperText={'Used as main control colour.'}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
              <H6>Components</H6>
              <ColorField
                label="Sheet Text"
                name="colors.darkText"
                helperText={
                  'Used for text on all on white background on sheets.'
                }
                disabled={isDisabled}
              />
              <ColorField
                label="Danger Text"
                name="colors.danger"
                helperText={
                  'Applied to warnings in forms and in checkout, should be a shade of red.'
                }
                disabled={isDisabled}
              />
              <ColorField
                label="Price Text"
                name="colors.brandHighlight"
                helperText={'Colour of pricing on the menu / basket.'}
                disabled={isDisabled}
              />

              <ColorField
                label="Flag"
                name="colors.flag"
                helperText={
                  'Used for outlet flags on segments, outlet cards and on the outlet menu view. This colour needs to work as a background for white text and also as a stand alone text colour.'
                }
                disabled={isDisabled}
              />
              <ColorField
                label="Discount"
                name="colors.discount"
                helperText={
                  'Used for discounts on segments, outlet cards and on the outlet menu view. This colour needs to work as a background for white text and also as a stand alone text colour. To help customers identify which text is flags and which are discounts its worth making this colour different to the flag colour.'
                }
                disabled={isDisabled}
              />
              <H6>Order Status</H6>
              <ColorField
                label="Open / Order Status"
                name="colors.openStatus"
                helperText={
                  'Used for Open/Order status on Outlet cards and menu lozenge.'
                }
                disabled={isDisabled}
              />
              <ColorField
                label="Preorder Status"
                name="colors.preorderStatus"
                helperText={
                  'Used for Preorder status on Outlet cards and menu lozenge.'
                }
                disabled={isDisabled}
              />
            </Col>
            <Col md={4}>
              {
                //  I know this code is bad but its just a preview
              }
              <div style={{ marginLeft: 16 }}>
                <H5>Preview </H5>
                <div
                  style={{
                    color: values.colors.mainText,
                    background: values.colors.siteBackground,
                    width: '100%',
                    height: '280px',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'flex-start',
                    border: '3px solid #ccc',
                    fontSize: 11,
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      flex: '0 0 70%',
                    }}
                  >
                    <div
                      style={{
                        color: values.colors.brandForeground,
                        background: values.colors.brand,
                        height: 30,
                        width: '100%',
                        display: 'flex',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Header
                    </div>
                    <div
                      style={{
                        background: values.colors.grey,
                        height: 30,
                        width: '100%',
                        display: 'flex',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          padding: 2,
                          fontSize: 9,
                          display: 'flex',
                          color: values.colors.mainText,
                          background: '#fff',
                          height: 16,
                          borderRadius: 8,
                          border: '2px solid',
                          borderColor: values.colors.darkGrey,
                          width: '30%',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 4,
                        }}
                      >
                        Control
                      </div>
                    </div>
                    <div
                      style={{
                        padding: 8,
                      }}
                    >
                      <span
                        style={{
                          color: values.colors.brand,
                          fontWeight: 'bold',
                          fontSize: 12,
                        }}
                      >
                        Title
                      </span>
                      <br />
                      Foreground Text
                    </div>
                  </div>
                  <div
                    style={{
                      flex: '0 0 30%',
                    }}
                  >
                    <div
                      style={{
                        color: values.colors.brandDark,
                        background: values.colors.brandLight,
                        height: 30,

                        display: 'flex',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        alignItems: 'center',
                        // center text
                        justifyContent: 'center',
                      }}
                    >
                      Side Bar
                    </div>
                    <div
                      style={{
                        padding: 8,
                      }}
                    >
                      Foreground Text
                      <div
                        style={{
                          textAlign: 'center',
                          padding: 2,
                          fontSize: 9,
                          display: 'flex',
                          color: values.colors.brandForeground,
                          background: values.colors.brand,
                          height: 18,
                          borderRadius: 5,
                          width: '100%',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 8,
                        }}
                      >
                        Button
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {isAtLeastPartner() && (
            <div className="bp-card-footer-actions">
              <Button text="Save" type="submit" disabled={!isValid} />
              <Button
                text="Reset"
                type="button"
                intent={'danger'}
                minimal
                onClick={onResetTheme}
              />
            </div>
          )}
        </form>
      )}
    </Formik>
  )
}
