import gql from 'graphql-tag'

const ADD_MARKETPLACE = gql`
  mutation addMarketPlace(
    $name: String!
    $contactName: String!
    $contactAddress: AddressCreateInput!
    $contactPhone: String!
    $contactEmail: String!
    $partnerId: String!
    $defaultCancelText: String!
    $defaultRejectText: String!
    $ageRestrictionText: String
    $companyLegalName: String
    $companyNumber: String
    $supportPhone: String
    $supportEmail: String
    $cname: String
    $trackingId: String
    $orderMode: OrderingMode!
    $merchantType: MerchantType!
    $pinpointAwsAccountId: String!
    $urlPath: String!
  ) {
    addMarketplace(
      name: $name
      contactName: $contactName
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      partnerId: $partnerId
      defaultCancelText: $defaultCancelText
      defaultRejectText: $defaultRejectText
      ageRestrictionText: $ageRestrictionText
      companyLegalName: $companyLegalName
      companyNumber: $companyNumber
      supportPhone: $supportPhone
      supportEmail: $supportEmail
      cname: $cname
      trackingId: $trackingId
      orderMode: $orderMode
      merchantType: $merchantType
      pinpointAwsAccountId: $pinpointAwsAccountId
      urlPath: $urlPath
    ) {
      message
      marketplace {
        id
      }
    }
  }
`

export default ADD_MARKETPLACE
