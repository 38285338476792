import React, { Fragment } from 'react'
import sortBy from 'lodash/sortBy'

import MenuItem from './MenuItem'

const MenuItems = ({
  outlet,
  depth,
  menuItems,
  menuItemsGroupId,
  path,
  parentMenuId,
  outletsSoldOut,
  restaurantId,
  hiddenMenuItemIds,
}) => (
  <Fragment>
    {sortBy(menuItems, 'position').map((menuItem, index) => (
      <MenuItem
        key={menuItem.id}
        menuItem={menuItem}
        depth={depth}
        outlet={outlet}
        parentMenuId={parentMenuId}
        path={[...path, index]}
        outletsSoldOut={outletsSoldOut}
        price={menuItem.price}
        restaurantId={restaurantId}
        outletHidden={hiddenMenuItemIds.includes(menuItem.id)}
        menuItemsGroupId={menuItemsGroupId}
      />
    ))}
  </Fragment>
)

export default MenuItems
