import gql from 'graphql-tag'

const RESET_PARTNER_WEBHOOKS = gql`
  mutation setPartnerPaymentWebhooks($id: ID!) {
    setPartnerPaymentWebhooks(id: $id) {
      id
      message
    }
  }
`

export default RESET_PARTNER_WEBHOOKS
