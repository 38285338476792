import { NumericFormat } from 'react-number-format'
import cx from 'classnames'
import { number, func, bool, oneOf } from 'prop-types'
import { Intent } from '@blueprintjs/core'
import styled from 'styled-components'

// TODO can probably handle these intents better
const IntentToClassMap = {
  [Intent.NONE]: 'bp5-intent-none',
  [Intent.PRIMARY]: 'bp5-intent-primary',
  [Intent.SUCCESS]: 'bp5-intent-success',
  [Intent.WARNING]: 'bp5-intent-warning',
  [Intent.DANGER]: 'bp5-intent-danger',
}

const StyledNumberInput = styled(NumericFormat)`
  ${props => props.fill && 'width: 100%;'}
`

const PercentageInput = ({ value, handleChange, fill, disabled, intent }) => (
  <div
    className={cx('bp5-input-group', intent ? IntentToClassMap[intent] : '')}
  >
    <StyledNumberInput
      suffix="%"
      value={value}
      onValueChange={values => handleChange(values)}
      className="bp5-input"
      fill={fill}
      allowNegative={false}
      allowEmptyFormatting={true}
      isAllowed={values => {
        if (values.floatValue > 100) {
          return false
        } else if (values.floatValue < 0) {
          return false
        } else {
          return true
        }
      }}
      disabled={disabled}
      decimalScale={2}
    />
  </div>
)

PercentageInput.propTypes = {
  value: number,
  handleChange: func,
  fill: bool,
  disabled: bool,
  intent: oneOf([
    Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER,
  ]),
}

export default PercentageInput
