import styled from 'styled-components'

// Header Container
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// Menu Group Header
export const MenuGroupHeader = styled.h4`
  font-size: 20px;
  font-weight: bold;
  padding-left: 7px;
  margin-bottom: 0px;
`

export const GroupContainer = styled.section<{ first: boolean }>`
  padding: 15px 0px;
  page-break-inside: avoid;
  border-top: ${({ first }) => (first ? 'none' : 'solid 1px #e6e6e6')};
`

// Items Container
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 18px;
  margin: 0px;
  justify-content: space-between;
`

// Item Container
export const ItemContainer = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  padding: 10px 50px 10px 5px;
  justify-content: flex-start;
`

// Item Header Container
export const ItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`

// Item Header
export const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  width: 100%;
  justify-content: space-between;
  line-height: 0px;
`

// Item Name
export const ItemName = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
  margin-bottom: 0px;
  line-height: 18px;
  justify-content: flex-start;
  min-height: 20px;
`

// Item Description
export const ItemDescription = styled.div`
  width: 40vw;
  margin-left: 1px;
`

// Small Icon
export const SmlIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: 2px;
  border: 1px solid grey;
  border-radius: 200px;
  background-color: white;
`

// Options SVG
export const OptionsSvg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-bottom: 2px;
  color: grey;
`

// Restaurant Name
export const RestaurantName = styled.h2`
  font-size: 35px;
  margin: 0px;
  padding: 5px;
`

// Restaurant Logo
export const RestaurantLogo = styled.img`
  width: 175px;
  margin: 0px;
`

// Bottom Border
export const BottomBorder = styled.div`
  border-bottom: dotted 0.5px #e6e6e6;
  width: 40vw;
  padding: 10px 50px 10px 5px;
  position: fixed;
  z-index: -1;
`

// Item Price
export const ItemPrice = styled.p`
  padding: 5px;
  line-height: 18px;
`

// Footer
export const Footer = styled.div`
  position: fixed !important;
  bottom: -15px;
  page-break-inside: avoid;
  margin: 0px;
  border-top: solid 0.5px #e6e6e6;
  width: 100vw;
  padding: 0px;
`

// Footer Content
export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 0px;
  padding: 0px;
`

// Key Container
export const KeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 22vw;
  align-items: center;
  margin: 10px 0px 0px 0px;
`

// Key Paragraph
export const KeyP = styled.p`
  font-size: 11px;
  padding: 5px;
  margin-top: 6px;
  text-align: center;
`

// SVG Container
export const SvgContainer = styled.div`
  width: 20px;
  height: 20px;
  margin: 10px;
`
