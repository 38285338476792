import { Formik } from 'formik'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import {
  Button,
  Classes,
  Drawer,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import * as yup from 'yup'
import EDIT_POS_DEVICE from './mutations/editPOSDevice'
import GET_POS_DEVICES from './queries/getPOSDevices'
import { editPOSDeviceStructure } from '../../../validation/pos-device'
import DeletePOSDeviceButton from './DeletePOSDeviceButton'
import ResetKioskPINButton from './ResetKioskPINButton'
import OutletTypeahead from '../../OutletTypeahead/OutletTypeahead'
import { useMutation } from '@apollo/client'

const validationSchema = yup.object().shape(editPOSDeviceStructure)

const EditPOSDeviceDrawer = ({ isOpen, onClose, marketplaceId, posDevice }) => {
  const initialOutletIds = posDevice.outlets.map(({ id }) => id)

  const [editPOSDevice] = useMutation(EDIT_POS_DEVICE, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('POS device edited successfully')
      onClose()
    },
    refetchQueries: [
      {
        query: GET_POS_DEVICES,
        variables: { marketplaceIds: [marketplaceId] },
      },
    ],
  })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        friendlyName: posDevice.friendlyName,
        serialNumber: posDevice.serialNumber,
        locationId: posDevice.locationId,
        outletIds: initialOutletIds,
      }}
      enableReinitialize={true}
      onSubmit={async (
        { friendlyName, locationId, outletIds },
        { resetForm }
      ) => {
        const outletsHaveChanged =
          initialOutletIds.some(outletId => !outletIds.includes(outletId)) ||
          outletIds.some(outletId => !initialOutletIds.includes(outletId))
        await editPOSDevice({
          variables: {
            deviceId: posDevice.id,
            outletIds: outletsHaveChanged ? outletIds : undefined,
            kioskUpdateData: { friendlyName, locationId },
          },
        })
        resetForm()
      }}
    >
      {({ handleChange, handleSubmit, setFieldValue, errors, values }) => (
        <Drawer isOpen={isOpen} onClose={onClose} title="Edit POS Device">
          <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Serial Number"
              labelFor="serialNumber"
              helperText={errors.serialNumber ? errors.serialNumber : ''}
              intent={Intent.DANGER}
              disabled={true}
            >
              <InputGroup
                type="text"
                name="serialNumber"
                id="serialNumber"
                value={values.serialNumber}
                disabled={true}
              />
            </FormGroup>
            <FormGroup
              label="Friendly Name"
              labelFor="friendlyName"
              helperText={errors.friendlyName ? errors.friendlyName : ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                type="text"
                name="friendlyName"
                id="friendlyName"
                value={values.friendlyName}
                onChange={handleChange}
                intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>
            <FormGroup
              label="Location ID"
              labelFor="locationId"
              helperText={errors.locationId ? errors.locationId : ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                type="text"
                name="locationId"
                id="locationId"
                value={values.locationId}
                onChange={handleChange}
                intent={errors.locationId ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>
            <FormGroup label="Outlets">
              <OutletTypeahead
                placeholder="Select outlet(s)"
                showRestaurantName={true}
                showMarketplaceName={false}
                alwaysMultiSelect={true}
                onChange={outletIds => {
                  setFieldValue('outletIds', outletIds)
                }}
                outletIds={values.outletIds}
                marketplaceId={marketplaceId}
              />
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <ResetKioskPINButton kioskId={posDevice.id} onClose={onClose} />
              <DeletePOSDeviceButton
                posDeviceId={posDevice.id}
                marketplaceId={marketplaceId}
                onClose={onClose}
              />
              <Button type="submit" text="Save" />
            </div>
          </form>
        </Drawer>
      )}
    </Formik>
  )
}

export default EditPOSDeviceDrawer
