import { Fragment } from 'react'
import { keys, startCase, first } from 'lodash'
import {
  Card,
  Button,
  InputGroup,
  FormGroup,
  HTMLSelect,
  Icon,
  Intent,
  Tooltip,
  Tag,
} from '@blueprintjs/core'

import Query from '@components/Query/Query'
import GET_MARKETPLACE_MESSAGE_SETTINGS from './queries/getMarketplaceMessageSettings.query'
import EDIT_MARKETPLACE_MESSAGE_SETTINGS from './mutations/editMarketplaceMessageSettings.mutation'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'

import { canPerformAction } from '@stores/userStore'
import config from '@src/config'
import { useMutation } from '@apollo/client'

let marketingAccounts
try {
  marketingAccounts = JSON.parse(config.marketingAccounts)
} catch (error) {
  // eslint-disable-next-line no-console
  console.error(
    'Failed to parse marketing accounts from environment variable',
    error
  )
}

const editableField = () => {
  return !canPerformAction('editMarketplace')
}

const PUSH_STRATEGY_OPTIONS = [
  {
    value: 'JUST_EMAIL',
    label: 'Email Only',
  },
  {
    value: 'JUST_PUSH',
    label: 'Push Only',
  },
  {
    value: 'EMAIL_AND_PUSH',
    label: 'Both Push & Email',
  },
  {
    value: 'PUSH_FALLING_BACK_TO_EMAIL',
    label: 'Push, Fallback Email',
  },
]

export const MessageTemplateSettings = ({ marketplaceId }) => {
  const [editMarketplaceMessageTemplates] = useMutation(
    EDIT_MARKETPLACE_MESSAGE_SETTINGS,
    {
      onError: defaultErrorHandler,
      onCompleted: ({ editMarketplaceMessageSettings }) =>
        successToast(editMarketplaceMessageSettings.message),
    }
  )

  return (
    <Query
      query={GET_MARKETPLACE_MESSAGE_SETTINGS}
      variables={{ id: marketplaceId }}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <Formik
            onSubmit={values => {
              editMarketplaceMessageTemplates({
                variables: {
                  ...values,
                },
              })
            }}
            initialValues={marketplace}
          >
            {({ handleChange, values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <h5 className="bp5-heading">Email Configuration</h5>
                  <FormGroup
                    label="Transactional and marketing email sender"
                    labelFor="emailSenderUsername"
                    helperText="Check if setup is done on DNS tab"
                  >
                    <InputGroup
                      name="emailSenderUsername"
                      id="emailSenderUsername"
                      type="text"
                      rightElement={
                        <Tag minimal style={{ width: '210px' }}>
                          @{marketplace.cname.trim().replace(/^www\./, '')}
                        </Tag>
                      }
                      onChange={handleChange}
                      value={values.emailSenderUsername || ''}
                      disabled={editableField()}
                    />
                  </FormGroup>
                  <FormGroup
                    label="Marketplace messaging sender"
                    labelFor="sesMessaging"
                    helperText="Check if setup is done on DNS tab"
                  >
                    <InputGroup
                      name="sesMessaging"
                      id="sesMessaging"
                      type="text"
                      placeholder="hello"
                      rightElement={
                        <Tag minimal style={{ width: '210px' }}>
                          @messaging.
                          {marketplace.cname.trim().replace(/^www\./, '')}
                        </Tag>
                      }
                      onChange={handleChange}
                      value={values.sesMessaging || ''}
                      disabled={editableField()}
                    />
                  </FormGroup>

                  <FormGroup
                    label="AWS Account ID"
                    labelFor="pinpointAwsAccountId"
                  >
                    <HTMLSelect
                      name="pinpointAwsAccountId"
                      fill
                      options={[
                        { value: '', label: 'Default' },
                        ...keys(marketingAccounts).map(id => ({
                          value: marketingAccounts[id],
                          label: `${marketingAccounts[id]} (${startCase(id)})`,
                        })),
                      ]}
                      value={values.pinpointAwsAccountId || ''}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <br />
                  <h5 className="bp5-heading">SMS Configuration</h5>
                  <FormGroup
                    label="Sender ID"
                    labelFor="smsSenderId"
                    labelInfo="(Max 11 Characters)"
                    helperText="Name shown to SMS recipients."
                  >
                    <InputGroup
                      name="smsSenderId"
                      id="smsSenderId"
                      maxLength={11}
                      type="text"
                      onChange={handleChange}
                      value={values.smsSenderId || ''}
                      disabled={editableField()}
                    />
                  </FormGroup>
                  <br />
                  <h5 className="bp5-heading">Push Configuration</h5>
                  {marketplace.channels.setup ? (
                    <Fragment>
                      <p>
                        {marketplace.channels.iosEnabled ? (
                          <Icon icon="tick-circle" intent={Intent.SUCCESS} />
                        ) : (
                          <Tooltip content="Not configured, contact support.">
                            <Icon icon="warning-sign" intent={Intent.WARNING} />
                          </Tooltip>
                        )}{' '}
                        iOS Notifications
                      </p>
                      <p>
                        {marketplace.channels.androidEnabled ? (
                          <Icon icon="tick-circle" intent={Intent.SUCCESS} />
                        ) : (
                          <Tooltip content="Not configured, contact support.">
                            <Icon icon="warning-sign" intent={Intent.WARNING} />
                          </Tooltip>
                        )}{' '}
                        Android Notifications
                      </p>
                    </Fragment>
                  ) : (
                    <Tooltip content="This can take up to 20 mins">
                      <p>
                        <Icon icon="error" intent={Intent.WARNING} /> Building
                        marketplace
                      </p>
                    </Tooltip>
                  )}
                  <br />
                  <h5 className="bp5-heading">Settings</h5>
                  <FormGroup
                    label="Notification Strategy"
                    labelFor="pinpointNotificationStrategy"
                  >
                    <HTMLSelect
                      name="pinpointNotificationStrategy"
                      options={PUSH_STRATEGY_OPTIONS}
                      value={values.pinpointNotificationStrategy}
                      disabled={editableField()}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <br />
                  <Button type="submit">Save</Button>
                </Card>
              </form>
            )}
          </Formik>
        )
      }}
    </Query>
  )
}
