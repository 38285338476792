import gql from 'graphql-tag'

const GET_RESTAURANT_NAME = gql`
  query getRestaurantName($id: String!) {
    getRestaurants(id: $id) {
      message
      restaurants {
        id
        name
      }
    }
  }
`

export default GET_RESTAURANT_NAME
