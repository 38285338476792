import { modalType } from '@utils/types'
import {
  Classes,
  FormGroup,
  Intent,
  InputGroup,
  Button,
  Callout,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
import RESET_TERMINAL_PIN from './mutations/resetTerminalPin.mutation'
import { Formik } from 'formik'
import { validation } from './validation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { useModal } from '../../providers/ModalProvider'
import { useMutation } from '@apollo/client'

const ResetTerminalPinModal = ({ modalOpen, modalClose, terminalId }) => {
  const { toggleModal } = useModal('resetTerminalPinModal')

  const [resetTerminalPin] = useMutation(RESET_TERMINAL_PIN, {
    onError: defaultErrorHandler,
    onCompleted: ({ editTerminalPin }) => {
      toggleModal(false)
      successToast(editTerminalPin.message)
    },
  })

  return (
    <Dialog title="Reset Pin" isOpen={modalOpen} onClose={modalClose}>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent={Intent.DANGER}>
          Warning, resetting the PIN will logout this terminal and it will be
          unavailable for orders until reauthorised.
        </Callout>
        <br />

        <Formik
          validationSchema={validation}
          initialValues={{ pin: '' }}
          onSubmit={values =>
            resetTerminalPin({ variables: { id: terminalId, ...values } })
          }
          validateOnBlur={true}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup
                label="PIN"
                labelFor="pin"
                labelInfo="(4 digits long)"
                helperText={errors.pin ? errors.pin : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  type="text"
                  id="pin"
                  name="pin"
                  value={values.pin}
                  intent={errors.pin ? Intent.DANGER : Intent.NONE}
                  onChange={handleChange}
                />
              </FormGroup>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button text="Reset Pin" type="submit" intent={Intent.DANGER} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

ResetTerminalPinModal.propTypes = {
  ...modalType,
  terminalId: string,
}

export default ResetTerminalPinModal
