import React, { useContext } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Drawer, NonIdealState } from '@blueprintjs/core'
import { MenuItemImport } from './MenuItemImport'
import { MenuGroupImport } from './MenuGroupImport'
import { MenuGroupDefinition } from '../schema/menu-group'
import { MenuDefinition } from '../schema/menu'

const ImportContent = ({ importData, restaurantId, handleClose }) => {
  if (restaurantId && importData && importData.data.length) {
    const keys = Object.keys(importData.data[0]).join('-')
    if (keys === MenuDefinition.getHeader().join('-')) {
      return (
        <MenuItemImport
          restaurantId={restaurantId}
          importData={importData}
          handleClose={handleClose}
        />
      )
    }

    if (keys === MenuGroupDefinition.getHeader().join('-'))
      return (
        <MenuGroupImport
          restaurantId={restaurantId}
          importData={importData}
          handleClose={handleClose}
        />
      )
  }

  return (
    <NonIdealState
      icon="error"
      title="The imported file does not match any of the known formats"
    />
  )
}

export const MenuImport = ({
  show = false,
  onClose = () => {},
  importData,
  restaurantId,
}) => {
  const { dark } = useContext(PageLayoutContext)
  return (
    <Drawer
      isOpen={show}
      onClose={e => onClose(e)}
      title="Import Data"
      size={'100%'}
      className={dark ? 'bp5-dark' : ''}
      position="bottom"
    >
      <ImportContent
        restaurantId={restaurantId}
        importData={importData}
        handleClose={onClose}
      />
    </Drawer>
  )
}
