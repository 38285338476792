import gql from 'graphql-tag'

const EDIT_OUTLET_SPECIAL_OFFER_FLAGS = gql`
  mutation outletSpecialOfferFlags(
    $outletId: String!
    $tableDeal: String
    $collectionDeal: String
    $deliveryDeal: String
  ) {
    editOutletSpecialOfferFlags(
      outletId: $outletId
      tableDeal: $tableDeal
      collectionDeal: $collectionDeal
      deliveryDeal: $deliveryDeal
    ) {
      message
      outlet {
        id
        deliveryDeal
        tableDeal
        collectionDeal
      }
    }
  }
`

export default EDIT_OUTLET_SPECIAL_OFFER_FLAGS
