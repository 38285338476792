import React from 'react'
import { FieldArray } from 'formik'
import { Checkbox, FormGroup } from '@blueprintjs/core'
import { array, object, bool } from 'prop-types'

const PAYMENT_METHODS = [
  'CASH_ON_DELIVERY',
  'CARD_ON_DELIVERY',
  'CASH_ON_COLLECTION',
  'CARD_ON_COLLECTION',
]

const PAYMENT_METHODS_MAPPING = {
  CASH_ON_DELIVERY: 'Cash on Delivery',
  CASH_ON_COLLECTION: 'Cash on Collection',
  CARD_ON_DELIVERY: 'Card for Delivery',
  CARD_ON_COLLECTION: 'Card for Collection',
}

const disableCheckbox = (outlet, method, disableCash = false) => {
  if (disableCash) {
    return method.includes('CASH') ? true : false
  }

  return false
}

const PaymentMethodField = ({
  disableCash = false,
  paymentMethods = PAYMENT_METHODS,
  values,
  outlet,
}) => (
  <FormGroup subLabel="Payment methods determine fulfillment type.">
    <FieldArray
      name="paymentMethods"
      render={({ push, remove }) => {
        return paymentMethods.map(method => (
          <Checkbox
            key={method}
            label={PAYMENT_METHODS_MAPPING[method]}
            checked={values.paymentMethods.includes(method)}
            onChange={event => {
              if (event.target.checked) {
                push(method)
              } else {
                const index = values.paymentMethods.indexOf(method)
                remove(index)
              }
            }}
            disabled={
              values.paymentMethods.includes(method) //enable if checked.
                ? false
                : disableCheckbox(outlet, method, disableCash)
            }
          />
        ))
      }}
    />
  </FormGroup>
)

PaymentMethodField.propTypes = {
  disableCash: bool,
  paymentMethods: array,
  values: object,
  outlet: bool,
}

PaymentMethodField.defaultProps = {
  disableCash: false,
  outlet: false,
}

export default PaymentMethodField
