import { useGetCurrencyQuery } from './queries/getCurrency.query.generated'

const PLACEHOLDER = '-'

/**
 *
 * This gives you nice, platform aware currency formatting that uses browser native APIs
 * (and will add commas/decimal points as required in a locale-aware way).
 *
 * There's also a <Currency> component which could be updated to use this hook, but I haven't wanted to
 * scope creep too much. I needed a string based one for echarts usage.
 */
export const useCurrencyFormatter = (): {
  formatPounds: (amount: number | null | undefined) => string
  formatPence: (amount: number | null | undefined) => string
} => {
  const { data } = useGetCurrencyQuery()
  if (!data)
    return {
      formatPounds: () => PLACEHOLDER,
      formatPence: () => PLACEHOLDER,
    }
  const formatter = Intl.NumberFormat(navigator.language, {
    style: 'currency',
    // @ts-expect-error this comes thru as JSON from the DB so no types
    currency: data.getCurrency.currency.code,
  })

  return {
    formatPounds: addNullUndefinedHandling(formatter.format, PLACEHOLDER),
    formatPence: addNullUndefinedHandling(formatter.format, PLACEHOLDER, 0.01),
  }
}

function addNullUndefinedHandling(
  formatter: (x: number) => string,
  placeholder: string,
  multiplier: number = 1
): (x: number | undefined | null) => string {
  const outputFormatter = (possiblyNullishInput: number | undefined | null) => {
    if (possiblyNullishInput === undefined || possiblyNullishInput === null)
      return placeholder
    const numberInput = possiblyNullishInput
    return formatter(numberInput * multiplier)
  }

  return outputFormatter
}
