import { useState } from 'react'
import { Tag, Icon } from '@blueprintjs/core'
import QuickEdit from './QuickEdit'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 34px;
  align-items: center;
  padding: 2px 8px;
  position: relative;

  &:hover {
    background: rgba(191, 204, 214, 0.4);
  }

  ${props =>
    props.active &&
    css`
      & ${LeftIcon} svg,
      & ${SecondaryLabel} svg {
        fill: #fff;
      }
    `}

  ${props =>
    props.shallow &&
    css`
      width: 500px;
    `}

  ${props =>
    props.depth &&
    css`
      width: 600px;
    `}

  ${props =>
    props.hidden &&
    css`
      opacity: 0.4;
    `}
`

export const Shallow = styled.div`
  width: 500px;
`

export const Deep = styled.div`
  width: 600px;
`

export const LeftIcon = styled(Icon)`
  margin-right: 7px;
`

export const Label = styled.div`
  width: 100%;
`

export const QuickEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const SecondaryLabel = styled.div`
  margin-left: auto;
`

const MenuRow = ({
  onClick,
  icon,
  iconColor = '#5c7080',
  label,
  soldCount,
  secondaryLabel,
  cursor = 'move',
  isOpen = null,
  active,
  hidden,
  item,
  depth,
}) => {
  const [isHovered] = useState('')
  return (
    <Container
      style={{ cursor }}
      hidden={hidden}
      active={active || isHovered}
      depth={depth}
    >
      <QuickEditContainer onClick={onClick}>
        {isOpen ? (
          <LeftIcon
            icon={isOpen ? 'chevron-down' : 'chevron-right'}
            color={iconColor}
          />
        ) : null}
        {icon && <LeftIcon icon={icon} color={iconColor} />}
        {label && <Label>{label}</Label>}
        {!!item && <QuickEdit item={item} />}
        {!!soldCount && <Tag intent={'danger'}>{soldCount} Sold</Tag>}
        {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
      </QuickEditContainer>
    </Container>
  )
}

export default MenuRow
