// this is to replace the restful-react package for PCI compliance

import { useState, useEffect } from 'react'

interface FetchOptions extends RequestInit {
  disabled?: boolean
}

interface FetchResult<T> {
  data: T | null
  loading: boolean
  error: Error | null
}

const useFetch = <T>(
  url: string,
  options: FetchOptions = {}
): FetchResult<T> => {
  const { disabled = false } = options

  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (disabled) {
      setData(null)
      setError(null)
      setLoading(false)
      return
    }

    const fetchData = async () => {
      try {
        const response = await fetch(url, options)

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data: T = await response.json()
        setData(data)
      } catch (error) {
        setError(error as Error)
      } finally {
        setError(null)
        setLoading(false)
      }
    }

    void fetchData()
  }, [url, JSON.stringify(options), disabled])

  return { data, loading, error }
}

export default useFetch
