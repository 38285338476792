import { Card, HTMLTable, NonIdealState } from '@blueprintjs/core'
import GET_MARKETPLACE from '@components/Marketplace/Dashboard/queries/getMarketplace.query'
import React, { Fragment } from 'react'

import EditLinkCardDrawer from './EditLinkCardDrawer'
import GET_LINK_CARDS from '@components/Marketplace/LinkCards/queries/getLinkCards.query'
import { format } from 'date-fns'
import { StringParam, useQueryParam } from 'use-query-params'
import Query from '@src/components/Query/Query'
import { useParams } from 'react-router-dom'

const LinkCards = () => {
  const [viewEditLinkCard, setViewEditLinkCard] = useQueryParam(
    'viewTemplate',
    StringParam
  )
  const { marketplace: marketplaceId } = useParams()

  const linkType = linkTypeKey => {
    const linkTypeTranslations = {
      MENU_ITEM: 'Menu Item',
      OUTLET: 'Outlet',
      LINK: 'Link',
    }

    return linkTypeTranslations[linkTypeKey] || ''
  }

  return (
    <Fragment>
      {viewEditLinkCard && (
        <EditLinkCardDrawer
          id={viewEditLinkCard}
          isOpen={viewEditLinkCard}
          marketplaceId={marketplaceId}
          onClose={() => {
            setViewEditLinkCard(null)
          }}
        />
      )}
      <Query query={GET_MARKETPLACE} variables={{ id: marketplaceId }}>
        {({ getMarketplaces }) => {
          if (!getMarketplaces) {
            return null
          }

          return (
            <Fragment>
              <Query query={GET_LINK_CARDS} variables={{ marketplaceId }}>
                {({ getLinkCards }) => {
                  if (!getLinkCards || getLinkCards.length === 0) {
                    return (
                      <NonIdealState
                        icon="th-list"
                        title="No Link Cards Found"
                        description="There are no link cards to display."
                      />
                    )
                  }

                  return (
                    <div className="bp5-table-frame">
                      <div className="bp5-table-container bp5-scrollable">
                        <Card className={'bp5-nopad'}>
                          <HTMLTable bordered={false} interactive={true}>
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>Link To</th>
                                <th>Description</th>
                                <th>Segments</th>
                                <th>Date Created</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getLinkCards.map(
                                ({
                                  id,
                                  name,
                                  type,
                                  description,
                                  segments,
                                  createdAt,
                                }) => {
                                  return (
                                    <tr
                                      key={id}
                                      onClick={() => {
                                        setViewEditLinkCard(id)
                                      }}
                                    >
                                      <td>{name}</td>
                                      <td>{linkType(type)}</td>
                                      <td>{description}</td>
                                      <td>{segments.length} </td>
                                      <td>
                                        {format(
                                          new Date(createdAt),
                                          `dd/MM/yyyy HH:mm`
                                        )}
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </HTMLTable>
                        </Card>
                      </div>
                    </div>
                  )
                }}
              </Query>
            </Fragment>
          )
        }}
      </Query>
    </Fragment>
  )
}

export default LinkCards
