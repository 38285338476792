import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'
import { genericTimeBracketsSchema } from './time-brackets'

export const addMenuItemsGroupStructure: IIterableInterface = {
  name: yup
    .string()
    .required()
    .min(3, 'Menu name should be longer than 3 characters'),
  restaurantId: yup
    .string()
    .min(25, 'A restaurant id should have 25 characters')
    .max(25, 'A restaurant id should have 25 characters')
    .nullable(),
  availabilityTimes: genericTimeBracketsSchema.nullable(),
  availabilityStartDate: yup.date().nullable(),
  availabilityEndDate: yup.date().nullable(),
  parentMenuId: yup
    .string()
    .min(25, 'A Parent menu id should have 25 characters')
    .max(25, 'A parent menu id should have 25 characters')
    .nullable(),
  addOnItemsMenu: yup.boolean().required(),
}

export const editMenuItemsGroupStructure: IIterableInterface = {
  name: yup.string().min(2, 'Menu name should be longer than 2 characters'),
  availabilityTimes: genericTimeBracketsSchema.nullable(),
  availabilityStartDate: yup.date().nullable(),
  availabilityEndDate: yup.date().nullable(),
  parentMenuId: yup
    .string()
    .min(25, 'A Parent menu id should have 25 characters')
    .max(25, 'A parent menu id should have 25 characters')
    .nullable(),
  description: yup.string().nullable(),
  restaurantId: yup
    .string()
    .min(25, 'A restaurant id should have 25 characters')
    .max(25, 'A restaurant id should have 25 characters')
    .nullable(),
  outletId: yup
    .string()
    .min(25, 'An outlet id should have 25 characters')
    .max(25, 'An outlet id should have 25 characters')
    .nullable(),
  menuItemIds: yup
    .string()
    .min(25, 'A menu item id should have 25 characters')
    .max(25, 'A menu item id should have 25 characters')
    .nullable(),
  available: yup.boolean(),
  image: yup.string().nullable(),
}
