import * as yup from 'yup'

export const importItemSchema = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(),
  description: yup.string().nullable(),
  price: yup.number().required(),
  costPrice: yup.number().nullable(),
  minimumPurchasePrice: yup.number().optional(),
  isVegan: yup.boolean().nullable(),
  isVegetarian: yup.boolean().nullable(),
  isDairyFree: yup.boolean().nullable(),
  isGlutenFree: yup.boolean().nullable(),
  isKeto: yup.boolean().nullable(),
  spiceLevel: yup.number().max(3).nullable(),
  ageRestricted: yup.boolean().nullable(),
  SKU: yup.string().nullable(),
  UPC: yup
    .string()
    .nullable()
    .matches(/^[0-9]{12}$/, 'UPC must be exactly 12 numbers.'),
  menuId: yup.string().required().typeError('Menu is a required field'),
  optionIds: yup.string().nullable(),
})
