import gql from 'graphql-tag'

const GET_DELIVERYZONE_NAME = gql`
  query getDeliveryZoneName($id: String!) {
    getDeliveryZones(id: $id) {
      deliveryZones {
        id
        name
      }
    }
  }
`

export default GET_DELIVERYZONE_NAME
