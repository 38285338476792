import gql from 'graphql-tag'

const ARCHIVE_OPTION = gql`
  mutation archiveOption($id: String!) {
    archiveOption(id: $id) {
      message
      option {
        id
      }
    }
  }
`

export default ARCHIVE_OPTION
