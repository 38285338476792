import { unsubscribe } from '@services/client'
import ls from '@utils/localStorage'
import { first, memoize, snakeCase, some } from 'lodash'
import { navigate } from '../utils/navigate'
// TODO fix sentry
// import * as Sentry from '@sentry/react'

export const jwtLogin = () => {
  const stopRedirectWhiteList = [
    '/register',
    '/2fa',
    'validate-phone',
    '/login',
    '/onboarding',
  ]
  const localToken = ls.get('jwt')
  const permissionCategories = ls.get('permissionCategories')

  if (
    !stopRedirectWhiteList.includes(window.location.pathname) &&
    (!localToken || !permissionCategories)
  ) {
    console.log('redirecting to login')
    navigate(`/login${window.location.search}`)
  }
}

export const logout = () => {
  localStorage.clear()
  void unsubscribe() // clear cache and reset link state defaults
  // Sentry.setUser(null)
  // TODO NAVIGATION
  navigate('/login')
  location.reload()
}

export const canView = key => {
  const permissionCategories = ls.get('permissionCategories')
  return some(permissionCategories, permission =>
    permissionsByRoute[key].includes(permission.title)
  )
}

export const canPerformAction = key => {
  const permissionCategories = ls.get('permissionCategories')

  return some(permissionCategories, permission =>
    permissionsByAction[key].includes(permission.title)
  )
}

export const canPerformMutation = key => {
  const permissionCategories = ls.get('permissionCategories')

  return some(permissionCategories, permission =>
    permission.mutations.includes(key)
  )
}

// IMPROVEMENT
// added memoize() to all functions below to help improve performance by not using filestore for every call for role

export const isSuperUser = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'superadmin'
})

export const isDeveloper = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'developer'
})

export const isPlatformLevelUser = memoize(() => {
  const role = ls.get('role')

  return ['developer', 'superadmin'].includes(role.toLowerCase())
})

export const isOutletUser = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'outlet user'
})

export const isBusinessUser = memoize(() => {
  const role = ls.get('role')
  // business user is the same as restaurant user
  return role.toLowerCase() === 'restaurant user'
})

export const isPartner = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'partner'
})

export const isMarketplaceAdmin = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'marketplace admin'
})

export const isMarketplaceRole = memoize(() => {
  const role = ls.get('role')

  return [
    'marketplace owner',
    'marketplace admin',
    'marketplace user',
  ].includes(role.toLowerCase())
})

export const isMarketplaceOwner = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'marketplace owner'
})

export const isMarketplaceUser = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'marketplace user'
})

export const isMarketingUser = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'marketing user'
})

export const isMenuEditor = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'menu editor'
})

export const isOutletFinancialUser = memoize(() => {
  const role = ls.get('role')

  return role.toLowerCase() === 'outlet financial user'
})

export const ownsPartners = memoize(() => {
  const partners = ls.get('ownsPartners') as { id: string }[]

  if (partners.length === 1) {
    return first(partners).id
  } else return false
})

export const isAtLeastPartner = memoize(() => {
  const role = ls.get('role')

  return ['partner', 'partner user', 'developer', 'superadmin'].includes(
    role.toLowerCase()
  )
})

export const isAtLeastMarketplaceAdmin = memoize(() => {
  const role = ls.get('role')

  return [
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastMarketplaceOwner = memoize(() => {
  const role = ls.get('role')

  return [
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastMarketplaceUser = memoize(() => {
  const role = ls.get('role')

  return [
    'marketplace user',
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastBusinessUser = memoize(() => {
  const role = ls.get('role')
  // business user is the same as restaurant user
  return [
    'restaurant user',
    'marketplace user',
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastOutletUser = memoize(() => {
  const role = ls.get('role')

  return [
    'outlet user',
    'restaurant user',
    'marketplace user',
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastOutletFinancialUser = memoize(() => {
  const role = ls.get('role')

  return [
    'outlet financial user',
    'outlet user',
    'restaurant user',
    'marketplace user',
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const isAtLeastMenuEditor = memoize(() => {
  const role = ls.get('role')

  return [
    'menu editor',
    'outlet user',
    'restaurant user',
    'marketplace user',
    'marketplace owner',
    'marketplace admin',
    'partner',
    'partner user',
    'developer',
    'superadmin',
  ].includes(role.toLowerCase())
})

export const getRole = memoize(() => {
  const role = ls.get('role')

  return snakeCase(role).toUpperCase()
})

const permissionsByRoute = {
  admin: ['user-admin'],
  billing: ['invoice-admin'],
  customers: ['customer-user'],
  marketing: ['marketing-user'],
  marketplaces: ['region-admin', 'region-owner', 'region-editor-restricted'],
  messaging: ['messaging-user'],
  onboarding: ['onboarding'],
  orders: ['restaurant-user'],
  partners: ['partner-user'],
  payouts: ['payouts-user'],
  platform: ['platform-user'],
  reports: ['reports-user'],
  restaurants: ['restaurant-user'],
  sales: ['sales-user'],
  virtualTerminals: ['virtual-terminal-user'],
}

const permissionsByAction = {
  addDiscount: ['discount-editor'],
  addMarketplace: ['marketplace-creator'],
  addRestaurant: ['restaurant-editor'],
  addUberDeliveryNetwork: ['partner-user'],
  appBuilds: ['app-admin'],
  blacklistAddress: ['region-user'],
  deleteDiscount: ['platform-admin'],
  editDiscount: ['discount-editor'],
  editMarketplace: ['region-admin', 'partner-user', 'region-owner'],
  editOutletCategories: ['region-editor', 'region-owner'],
  editOutletSpecialOfferFlags: ['region-editor', 'region-owner'],
  editPlatform: ['platform-admin'],
  editPromotions: ['region-user'],
  editRestaurant: ['restaurant-editor'],
  editRestaurantFinancials: ['region-admin'],
  editRestaurantVAT: ['restaurant-admin'],
  editUser: ['user-editor'],
  enrollMemberToDiscount: ['member-discounts-editor'],
  featureOrPromoteOutlet: ['region-editor', 'region-owner'],
  filterByMarketplace: ['region-user', 'region-admin'],
  inviteUser: ['user-admin'],
  moderateReview: ['review-admin'],
  refund: ['region-admin', 'partner-user', 'region-owner'],
  resetTerminalPin: ['terminal-pin-editor'],
  toggleHygieneRating: ['outlet-editor'],
}
