import { Fragment, useContext } from 'react'
import { Card, HTMLTable, NonIdealState, Button } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import ls from '@utils/localStorage'
import GET_POS_DEVICES from './queries/getPOSDevices'
import EditPOSDeviceDrawer from './EditPOSDevice.drawer'
import { Link, useParams } from 'react-router-dom'
import {
  OVERLAYS,
  OuterLayoutOverlayContext,
} from '../OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import { useQueryParam, StringParam } from 'use-query-params'

const tableHead = [
  { key: 'friendlyName', content: 'Name' },
  { key: 'serialNumber', content: 'Serial Number' },
  { key: 'locationId', content: 'Location ID' },
  { key: 'outlets', content: 'Outlets' },
]

const POS = () => {
  const [posDeviceId, onChangePOSDeviceId] = useQueryParam(
    'posDeviceId',
    StringParam
  )
  const { marketplace: marketplaceId } = useParams()
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  return (
    <Query
      query={GET_POS_DEVICES}
      variables={{ marketplaceIds: [marketplaceId] }}
      loaderTitle={'Loading POS Devices'}
    >
      {({ getPOSDevices: { posDevices } }) => {
        const selectedPosDevice = posDeviceId
          ? posDevices.find(posDevice => posDevice.id === posDeviceId)
          : null
        return !posDevices || !posDevices.length ? (
          <NonIdealState
            icon="control"
            title="No POS Devices"
            description="Add POS Device."
            action={
              <Button
                icon="plus"
                text="Add POS Device"
                onClick={() => openOverlay(OVERLAYS.ADD_POS_DEVICE)}
                mininmal
              />
            }
          />
        ) : (
          <Fragment>
            <Card className="bp5-nopad">
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    {tableHead.map(({ key, content }) => (
                      <th key={key}>{content}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {posDevices.map(
                    ({
                      id,
                      friendlyName,
                      serialNumber,
                      locationId,
                      outlets,
                    }) => (
                      <tr key={id}>
                        <td
                          className={`bp5-text-overflow-ellipsis
                            bp5-${ls.get('darkMode') ? 'dark-' : ''}link-text
                          `}
                          onClick={() => {
                            onChangePOSDeviceId(id)
                          }}
                        >
                          {friendlyName}
                        </td>
                        <td>{serialNumber}</td>
                        <td>{locationId}</td>
                        <td>
                          {outlets.length ? (
                            <ul>
                              {outlets.map(outlet => (
                                <li
                                  key={outlet.id}
                                  style={{
                                    listStyleType: 'none',
                                    margin: '8px 0',
                                  }}
                                >
                                  <Link
                                    to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}`}
                                  >
                                    {outlet.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'No outlets'
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </HTMLTable>
            </Card>
            {selectedPosDevice && (
              <EditPOSDeviceDrawer
                posDevice={selectedPosDevice}
                marketplaceId={marketplaceId}
                isOpen={selectedPosDevice !== null}
                onClose={() => {
                  onChangePOSDeviceId(undefined)
                }}
              />
            )}
          </Fragment>
        )
      }}
    </Query>
  )
}

export default POS
