import React from 'react'
import Form from '@components/TableForm/Form'
import { Formik } from 'formik'
import { func, object } from 'prop-types'

const TableForm = ({ onSubmit, initialValues = {}, validationSchema }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ ...initialValues }}
    validationSchema={validationSchema}
  >
    {props => <Form {...props} />}
  </Formik>
)

TableForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default TableForm
