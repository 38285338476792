import gql from 'graphql-tag'

export const EDIT_PLATFORM_INTEGRATIONS = gql`
  mutation editPlatformIntegrations(
    $id: String!
    $otterClientId: String
    $otterClientSecret: String
    $otterWebhookSecret: String
  ) {
    editPlatformIntegrations(
      id: $id
      otterClientId: $otterClientId
      otterClientSecret: $otterClientSecret
      otterWebhookSecret: $otterWebhookSecret
    ) {
      platform {
        id
        otterClientId
        otterClientSecret
        otterWebhookSecret
      }
    }
  }
`
