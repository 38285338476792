import { useMutation } from '@apollo/client'
import { Button, Card, H5, Intent } from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import TOGGLE_MARKETPLACE_STATUS from '@components/Marketplace/ToggleMarketplaceStatus/toggleMarketplaceStatus.mutation'
import GET_MARKETPLACES from '@components/Marketplaces/queries/getMarketplaces.query'
import { successToast } from '@utils/toast'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

const ToggleMarketplaceStatus = ({ id, name, isDeactivated }) => {
  const navigate = useNavigate()

  const [toggleMarketplaceStatus, { loading }] = useMutation(
    TOGGLE_MARKETPLACE_STATUS,
    {
      onCompleted: ({ toggleMarketplaceStatus }) => {
        successToast(toggleMarketplaceStatus.message)
        navigate('/marketplaces')
      },
      refetchQueries: [
        {
          query: GET_MARKETPLACES,
        },
      ],
    }
  )

  return (
    <Card>
      <H5>
        {isDeactivated ? 'Activate Marketplace' : 'Deactivate Marketplace'}
      </H5>
      <p>
        {isDeactivated
          ? 'Activating this marketplace will also reactivate all of its outlets and terminals'
          : 'Deactivating this marketplace will also archive all of its outlets and terminals'}
      </p>
      <div className="bp-card-footer-actions">
        <ConfirmationPopover
          confirmationText={
            <Fragment>
              Are you sure you want to{' '}
              {isDeactivated ? 'activate' : 'deactivate'} the{' '}
              <strong>{name}</strong> marketplace and{' '}
              {isDeactivated ? 'reactivate' : 'archive'} all of its outlets and
              terminals?
            </Fragment>
          }
          buttonTitle={isDeactivated ? 'Activate' : 'Deactivate'}
          requiresWrittenConfirmation
          remove={() =>
            toggleMarketplaceStatus({
              variables: { id, setDeactivated: !isDeactivated },
            })
          }
        >
          <Button
            loading={loading}
            intent={isDeactivated ? Intent.SUCCESS : Intent.DANGER}
          >
            {isDeactivated ? 'Activate' : 'Deactivate'}
          </Button>
        </ConfirmationPopover>
      </div>
    </Card>
  )
}

export default ToggleMarketplaceStatus
