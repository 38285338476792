import gql from 'graphql-tag'

const GET_OPTIONS_DETAILS = gql`
  query getOptionsDetails($id: String!) {
    getOptions(id: $id) {
      options {
        id
        name
        position
        maxOptions
        minOptions
        optionItems {
          id
          name
          position
          price
          vatRate
          VATinclusive
          soldOut
          imageImplementer {
            id
            src
          }
        }
        restaurant {
          id
          isVATregistered
        }
      }
    }
  }
`

export default GET_OPTIONS_DETAILS
