import { get, set } from 'lodash'
import moment from 'moment'
import { statusAttributeMap } from '@components/Analytics/util/maps'
import { getUserAgentFromOrder } from '../../Analytics/util/getUserAgentFromOrder'

const singleDayKeyFormat = 'ddd, hA'
const multiDayKeyFormat = 'DD-MM-YYYY'

/**
 *
 * @param {object[]} rawData
 * @param {object} rawData[].discountJson
 * @param {string} rawData[].outletId
 * @param {number} rawData[].discountAmount
 * @param {number} rawData[].subtotalGross
 * @param {string} rawData[].fulfillmentMethod
 * @param {string} rawData[].customerId
 * @param {string} rawData[].paymentMethod
 * @param {?string} rawData[].voucherKey
 * @param {string} rawData[].orderStatus
 * @param {string} rawData[].createdAt
 * @param {object} rawData[].userAgent
 * @param {object} rawData[].userAgent.browser
 * @param {object} rawData[].userAgent.device
 * @param {object} rawData[].userAgent.os
 * @param {string} rawData[].userAgent.raw
 * @param {object} rawData[].userAgent.client
 * @param {boolean} isSingleDayShiftSelection
 * @param {string} afterDate
 * @param {string} beforeDate
 * @param {boolean} showBlankEntries
 * @param {string} outletId
 * @param {string} customerId
 * @returns {object}
 */
export const formatRawData = (
  rawData,
  isSingleDayShiftSelection,
  afterDate,
  beforeDate,
  showBlankEntries,
  outletId,
  customerId
) => {
  // filter orders by outlet
  if (rawData && outletId && outletId !== 'All') {
    rawData = rawData.filter(order => order.outletId === outletId)
  }

  // filter orders by customerId
  if (rawData && customerId && customerId !== 'All') {
    rawData = rawData.filter(order => order.customerId === customerId)
  }

  // if a single day is selected it makes more sense to group by hour
  const keyFormat = isSingleDayShiftSelection
    ? singleDayKeyFormat
    : multiDayKeyFormat

  // we need to maintain a dynamic array of user agents
  const userAgentEntries = new Set()

  const defaultValues = {
    name: '',
    numberOfOrders: 0,

    numberOfOrdersWithNoDiscount: 0,
    numberOfOrdersWithRegularDiscount: 0,
    numberOfOrdersWithMemberDiscount: 0,
    numberOfOrdersWithVoucherDiscount: 0,

    subTotalWithNoDiscounts: 0,
    subTotalWithRegularDiscounts: 0,
    subTotalWithMemberDiscounts: 0,
    subTotalWithVoucherDiscounts: 0,

    subTotalDiscountedRegularDiscounts: 0,
    subTotalDiscountedMemberDiscounts: 0,
    subTotalDiscountedVoucherDiscounts: 0,

    customers: [],
    outlets: [],

    statuses: {},
    paymentMethods: {},
    fulfillmentMethods: {},
    userAgents: {},
  }

  const accumulator = {}
  // fill with blanks if is a single day so we don't get
  // one single bar/point if orders are in only one interval
  if (isSingleDayShiftSelection && rawData && showBlankEntries) {
    // find the weekday of the first order
    const dayReference = moment(rawData[0].createdAt)

    // fill with blanks
    for (let i = 0; i < 24; i++) {
      const key = dayReference.clone().set('h', i).format(keyFormat)
      set(accumulator, key, {
        ...defaultValues,
        name: key,
        paymentMethods: {},
        fulfillmentMethods: {},
        statuses: {},
        userAgents: {},
      })
    }
  } else if (rawData && afterDate && beforeDate && showBlankEntries) {
    // fill with blanks for the interval
    const keyMoment = moment(afterDate)
    const endMoment = moment(beforeDate)
    for (
      keyMoment;
      keyMoment.isSameOrBefore(endMoment, 'day');
      keyMoment.add(1, 'day')
    ) {
      set(accumulator, keyMoment.format(keyFormat), {
        ...defaultValues,
        name: keyMoment.format(keyFormat),
        paymentMethods: {},
        fulfillmentMethods: {},
        statuses: {},
        userAgents: {},
      })
    }
  }

  const formattedData = rawData
    ? rawData.reduce((acc, rawOrder) => {
        const key = moment(rawOrder.createdAt).format(keyFormat)

        if (!get(acc, key)) {
          set(acc, key, {
            ...defaultValues,
            name: key,
            paymentMethods: {},
            fulfillmentMethods: {},
            statuses: {},
            userAgents: {},
          })
        }
        const previousValues = get(acc, key)

        // sum totals
        if (rawOrder.discountJson) {
          const appliedDiscount =
            typeof rawOrder.discountJson === 'string'
              ? JSON.parse(rawOrder.discountJson)
              : rawOrder.discountJson

          if (rawOrder.voucherKey) {
            // voucher discount
            acc[key].subTotalWithVoucherDiscounts =
              previousValues.subTotalWithVoucherDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedVoucherDiscounts =
              previousValues.subTotalDiscountedVoucherDiscounts +
              rawOrder.discountAmount
            rawOrder.discountAmount
            acc[key].numberOfOrdersWithVoucherDiscount =
              previousValues.numberOfOrdersWithVoucherDiscount + 1
          } else if (appliedDiscount.customerEnrolled) {
            // member discount
            acc[key].subTotalWithMemberDiscounts =
              previousValues.subTotalWithMemberDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedMemberDiscounts =
              previousValues.subTotalDiscountedMemberDiscounts +
              rawOrder.discountAmount
            acc[key].numberOfOrdersWithMemberDiscount =
              previousValues.numberOfOrdersWithMemberDiscount + 1
          } else {
            // regular discount
            acc[key].subTotalWithRegularDiscounts =
              previousValues.subTotalWithRegularDiscounts +
              rawOrder.subtotalGross
            acc[key].subTotalDiscountedRegularDiscounts =
              previousValues.subTotalDiscountedRegularDiscounts +
              rawOrder.discountAmount
            acc[key].numberOfOrdersWithRegularDiscount =
              previousValues.numberOfOrdersWithRegularDiscount + 1
          }
        } else {
          // no discount
          acc[key].subTotalWithNoDiscounts =
            previousValues.subTotalWithNoDiscounts + rawOrder.subtotalGross
          acc[key].numberOfOrdersWithNoDiscount =
            previousValues.numberOfOrdersWithNoDiscount + 1
        }

        // count orders
        acc[key].numberOfOrders = previousValues.numberOfOrders + 1

        // set customers
        acc[key].customers = new Set([
          ...previousValues.customers,
          rawOrder.customerId,
        ])

        // set outlets
        acc[key].outlets = new Set([
          ...previousValues.outlets,
          rawOrder.outletId,
        ])

        // set order status
        const attribute = get(
          statusAttributeMap,
          rawOrder.orderStatus,
          'UNCATEGORIZED'
        )

        set(
          acc[key].statuses,
          attribute,
          get(acc[key].statuses, attribute, 0) + 1
        )

        // fulfillment
        set(
          acc[key].fulfillmentMethods,
          rawOrder.fulfillmentMethod,
          get(acc[key].fulfillmentMethods, rawOrder.fulfillmentMethod, 0) + 1
        )

        // payment
        set(
          acc[key].paymentMethods,
          rawOrder.paymentMethod,
          get(acc[key].paymentMethods, rawOrder.paymentMethod, 0) + 1
        )

        // user agent
        if (rawOrder.userAgent) {
          const userAgentKey = getUserAgentFromOrder(rawOrder.userAgent)

          acc[key].userAgents[`${userAgentKey}`] =
            get(acc[key].userAgents, `${userAgentKey}`, 0) + 1

          // update the set
          userAgentEntries.add(userAgentKey)
        }

        return acc
      }, accumulator)
    : []

  // transform obj into array
  const formattedDataArray = Object.values(formattedData)

  // order by date
  formattedDataArray.sort((currentEntry, nextEntry) =>
    moment(currentEntry.name).diff(
      nextEntry.name,
      isSingleDayShiftSelection ? 'hour' : 'day'
    )
  )

  // transform sets into arrays and calculate averages
  const transformedData = formattedDataArray.map(entry => {
    return {
      ...entry,

      numberOfCustomers: entry.customers.size,
      customers: Array.from(entry.customers),

      numberOfOutlets: entry.outlets.size,
      outlets: Array.from(entry.outlets),

      // cant use penceToPounds because we need numbers
      // for graphs and instead of transforming in multiple
      // places it's better to transform in a single point, here
      subTotalWithNoDiscounts: entry.subTotalWithNoDiscounts / 100,
      subTotalWithRegularDiscounts: entry.subTotalWithRegularDiscounts / 100,
      subTotalWithMemberDiscounts: entry.subTotalWithMemberDiscounts / 100,
      subTotalWithVoucherDiscounts: entry.subTotalWithVoucherDiscounts / 100,

      subTotalDiscountedRegularDiscounts:
        entry.subTotalDiscountedRegularDiscounts / 100,
      subTotalDiscountedMemberDiscounts:
        entry.subTotalDiscountedMemberDiscounts / 100,
      subTotalDiscountedVoucherDiscounts:
        entry.subTotalDiscountedVoucherDiscounts / 100,

      dailyAverage:
        (entry.subTotalWithNoDiscounts +
          entry.subTotalWithRegularDiscounts +
          entry.subTotalWithMemberDiscounts +
          entry.subTotalWithVoucherDiscounts) /
          (entry.numberOfOrders * 100) || 0,
    }
  })

  return {
    transformedData,
    userAgentEntries: Array.from(userAgentEntries),
  }
}
