import gql from 'graphql-tag'

const EDIT_MENU_ITEMS_GROUP_FULFILLMENT_AVAILABILITY = gql`
  mutation editMenuItemsGroupFulfillmentMethods(
    $id: String!
    $fulfillmentMethods: [MenuItemGroupFulfillmentMethod!]!
  ) {
    editMenuItemsGroupFulfillmentMethods(
      id: $id
      fulfillmentMethods: $fulfillmentMethods
    ) {
      id
      name
      fulfillmentMethods
    }
  }
`

export default EDIT_MENU_ITEMS_GROUP_FULFILLMENT_AVAILABILITY
