import gql from 'graphql-tag'

const GET_MARKETPLACE_BILLING = gql`
  query getMarketplaceBilling($id: ID!) {
    getMarketplaces(id: $id) {
      regions {
        id
        allowBilling
        invoices {
          id
          invoiceNumber
          invoicePdf
          invoiceUrl
          dueDate
          subtotal
          amountDue
          amountRemaining
          status
        }
        partner {
          id
          name
        }
        billing {
          id
          livemode
          delinquent
          currency
          products {
            id
            name
            description
            service
            featured
            subscribed
            label
            icon
          }
          paymentMethods
          upcomingInvoice {
            periodStart
            periodEnd
            subtotal
            total
            tax
            items {
              description
              amount
            }
          }
          subscription {
            id
            active
            services
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE_BILLING
