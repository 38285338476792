import { addDays, isBefore, isEqual, set, setHours, startOfDay } from 'date-fns'
import config from '@src/config'
import { ONE_MINUTE } from './constants'

const { midnightReportingMigrationDate } = config

const isBeforeOrEqualToMigrationDate = date => {
  const startOfMigrationDate = startOfDay(midnightReportingMigrationDate)
  const startOfDateArg = startOfDay(date)
  return (
    isBefore(startOfDateArg, startOfMigrationDate) ||
    isEqual(startOfDateArg, startOfMigrationDate)
  )
}

/**
 * @typedef {Object} DateTimeRange
 * @property {Date} start - The start date of the range.
 * @property {Date} end - The end date of the range.
 */

/**
 * Shifts the dates in a datetime range so that the start and end times are based on days being from 4am - 3:59:59.999am
 * if the preMigrationUsesFourAM flag is true and the datetime in the range date is before the migration date.
 *
 * @param {Object} params - The parameters for the function.
 * @param {DateTimeRange} params.range - The date range to adjust.
 *
 * @returns {{range: DateTimeRange}} An object containing the adjusted start and end dates for the range. The dates are adjusted to start at either 4am or midnight based on the preMigrationUsesFourAM flag and the migration date. Each date is a Date object.
 */
export const shiftToRedboxPreMigration = ({
  range: { start: midnightBasedStartOfRange, end: midnightBasedEndOfRange },
}) => {
  // env var not set, so it's local dev
  // or
  // parent component wants to treat all historical dates as midnight to midnight (eg ordering, but not invoices)
  // Therefore all date ranges should be midnight to midnight
  if (!midnightReportingMigrationDate) {
    return {
      range: {
        start: midnightBasedStartOfRange,
        end: midnightBasedEndOfRange,
      },
    }
  }

  // parent component wants to treat all historical dates as 4am to 4am,
  // and env var is set, so we know when the migration happened
  // so shift the start, and potentially also the end of the range, to be 4am-based
  return {
    range: {
      start: isBeforeOrEqualToMigrationDate(midnightBasedStartOfRange)
        ? setHours(midnightBasedStartOfRange, 4)
        : midnightBasedStartOfRange,
      end: isBeforeOrEqualToMigrationDate(midnightBasedEndOfRange)
        ? set(addDays(midnightBasedEndOfRange, 1), {
            hours: 3,
            minutes: 59,
            seconds: 59,
            milliseconds: 999,
          })
        : midnightBasedEndOfRange,
    },
  }
}

const localToUtc = date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * -ONE_MINUTE)
}

export const shiftToRedboxPreMigrationUTC = ({ range }) => {
  const {
    range: { start: localStart, end: localEnd },
  } = shiftToRedboxPreMigration({
    range,
  })

  return {
    range: {
      start: isBeforeOrEqualToMigrationDate(localStart)
        ? localStart
        : localToUtc(localStart),
      end: isBeforeOrEqualToMigrationDate(localEnd)
        ? localEnd
        : localToUtc(localEnd),
    },
  }
}
