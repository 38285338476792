import gql from 'graphql-tag'

const GET_MARKETPLACE_BILLING_STATUS_BY_ID = gql`
  query getMarketplaceBillingStatusById($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        name
        allowSMSMarketing
        allowFacebookMarketing
        allowBilling
        billingStatus {
          active
          services
        }
      }
    }
  }
`

export default GET_MARKETPLACE_BILLING_STATUS_BY_ID
