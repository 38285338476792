import { Drawer } from '@blueprintjs/core'
import React from 'react'
import CreateLoyaltyCard from './CreateLoyaltyCard'

const CreateLoyaltyCardDrawer = ({ isOpen, marketplaceId, drawerClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={drawerClose} title="Loyalty Card">
      <CreateLoyaltyCard
        marketplaceId={marketplaceId}
        drawerClose={drawerClose}
      />
    </Drawer>
  )
}

export default CreateLoyaltyCardDrawer
