import * as yup from 'yup'
import { editRestaurantStructure } from '../../../validation/restaurant'
import { address } from '../../../validation/address'

const extendedEditRestaurantStructure = {
  ...editRestaurantStructure,
  dealsDeliveryText: yup
    .string()
    .max(85, `The delivery offer should not exceed 85 characters.`)
    .test(
      'no-collection-in-delivery',
      "Delivery offer should not contain words related to 'Collection' or 'Table Service'",
      value => {
        const forbiddenWords = [
          'collect',
          'collection',
          'collector',
          'collecting',
          'pickup',
          'pick-up',
          'table',
        ]
        return (
          !value ||
          !forbiddenWords.some(word => value.toLowerCase().includes(word))
        )
      }
    )
    .nullable(),
  dealsCollectionText: yup
    .string()
    .max(85, `The collection offer should not exceed 85 characters.`)
    .test(
      'no-delivery-in-collection',
      "Collection offer should not contain words related to 'Delivery' or 'Table Service'",
      value => {
        const forbiddenWords = [
          'delivery',
          'deliveries',
          'drop-off',
          'dropoff',
          'drop',
          'ship',
          'shipping',
          'table',
        ]
        return (
          !value ||
          !forbiddenWords.some(word => value.toLowerCase().includes(word))
        )
      }
    )
    .nullable(),
  dealsTableServiceText: yup
    .string()
    .max(85, `The table service offer should not exceed 85 characters.`)
    .test(
      'no-delivery-or-collection-in-table-service',
      "Table Service offer should not contain words related to 'Delivery' or 'Collection'",
      value => {
        const forbiddenWords = [
          'delivery',
          'deliveries',
          'drop-off',
          'dropoff',
          'drop',
          'ship',
          'shipping',
          'delivery',
          'deliveries',
          'drop-off',
          'dropoff',
          'drop',
          'ship',
          'shipping',
        ]
        return (
          !value ||
          !forbiddenWords.some(word => value.toLowerCase().includes(word))
        )
      }
    )
    .nullable(),
}

export const validation = yup
  .object()
  .shape(extendedEditRestaurantStructure)
  .concat(yup.object().shape({ contactAddress: yup.object().shape(address) }))
