import gql from 'graphql-tag'

const EDIT_APPLEPAY_VERIFICATION = gql`
  mutation editApplePayVerification(
    $id: String!
    $applePayVerification: String!
  ) {
    editMarketplaceFinancials(
      id: $id
      applePayVerification: $applePayVerification
    ) {
      message
      marketplace {
        id
      }
    }
  }
`

export default EDIT_APPLEPAY_VERIFICATION
