import React, { Fragment } from 'react'
import upperFirst from 'lodash/upperFirst'
import get from 'lodash/get'
import { BoldSection, Text } from '../OrderPrintout'

const formatNewLine = value =>
  value && value.length ? (
    <Fragment>
      {value} <br />
    </Fragment>
  ) : (
    value
  )

const CustomerInfo = ({ order }) => (
  <BoldSection>
    <Text>
      {`${upperFirst(order.customer.firstName)} ${upperFirst(
        order.customer.lastName
      )}`}
      <br />
      {order.customerDeliveryAddress && (
        <Fragment>
          {formatNewLine(order.customerDeliveryAddress.firstLine)}
          {formatNewLine(order.customerDeliveryAddress.secondLine)}
          {formatNewLine(order.customerDeliveryAddress.thirdLine)}
          {formatNewLine(order.customerDeliveryAddress.city)}
          {formatNewLine(order.customerDeliveryAddress.postcode)}
        </Fragment>
      )}

      {!get(
        order,
        'outlet.marketplace.receiptHideCustomerPhoneNumber',
        false
      ) && `Tel: ${order.customer.phoneNumber}`}
    </Text>
  </BoldSection>
)

export default CustomerInfo
