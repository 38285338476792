import React, { Fragment } from 'react'
import {
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
  Icon,
  Tag,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const commaRegex = /,/g

export const MarketplacesPopover = ({ marketplaces }) => (
  <Popover
    disabled={!marketplaces.length}
    interactionKind={PopoverInteractionKind.HOVER}
    position={Position.RIGHT_TOP}
    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    content={<MarketplaceTagsCloud marketplaces={marketplaces} />}
  >
    {marketplaces.length ? (
      <a
        href={`/marketplaces?marketplaceIds=${marketplaces
          .reduce((acc, marketplace) => acc.concat(marketplace.id), [])
          .toString()
          .replace(commaRegex, '_')}`}
      >
        {marketplaces.length}
      </a>
    ) : (
      0
    )}
  </Popover>
)

const MarketplaceTagsCloud = ({ marketplaces }) => (
  <Fragment>
    {marketplaces.map(marketplace => {
      const { id, name, optInAllowedCuisines } = marketplace
      return (
        <Tag
          key={id}
          minimal
          style={{
            marginRight: '5px',
            marginBottom: '5px',
          }}
        >
          {optInAllowedCuisines && <Icon icon="small-tick" size={12} />}
          <Link to={`/marketplaces/${id}`}>{name}</Link>
        </Tag>
      )
    })}
  </Fragment>
)
