import React, { Fragment } from 'react'
import { FormGroup, InputGroup } from '@blueprintjs/core'

const APICredentialsFormGroups = ({ values, handleChange }) => (
  <Fragment>
    <FormGroup label="API Client ID" labelFor="apiClientId">
      <InputGroup
        name="apiClientId"
        id="apiClientId"
        type="text"
        onChange={handleChange}
        value={values.apiClientId}
      />
    </FormGroup>
    <FormGroup label="API Secret" labelFor="apiSecret">
      <InputGroup
        name="apiSecret"
        id="apiSecret"
        type="text"
        onChange={handleChange}
        value={values.apiSecret}
      />
    </FormGroup>
  </Fragment>
)

export default APICredentialsFormGroups
