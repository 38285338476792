import React from 'react'
import { isMenuEditor, canView } from '@stores/userStore'

import SortBy from '@components/Toolbar/SortBy'

const TableHeader = ({ setSelected, selected }) => {
  const userIsMenuEditor = isMenuEditor()

  const cells = [
    {
      key: 'name',
      content: 'Name',
      sortable: true,
      visible: true,
    },
    {
      key: 'contactName',
      content: 'Contact',
      sortable: true,
      visible: true,
    },
    {
      key: 'partner',
      content: 'Partner',
      visible: true,
    },
    {
      key: 'cuisine',
      content: 'Categories',
      visible: true,
    },
    {
      key: 'fee',
      content: 'Fee',
      width: 80,
      visible: !userIsMenuEditor,
    },
    {
      key: 'outlets',
      content: 'Outlets',
      width: 100,
      visible: !userIsMenuEditor,
    },
    {
      key: 'terminals',
      content: 'Terminals',
      width: 110,
      visible: canView('virtualTerminals'),
    },
    {
      key: 'promote',
      content: 'Promote',
      width: 170,
      visible: !userIsMenuEditor,
    },
    {
      key: 'rating',
      content: 'Rating',
      width: 60,
      visible: !userIsMenuEditor,
    },
    {
      key: 'vat',
      content: 'VAT',
      width: 60,
      visible: !userIsMenuEditor,
    },
    {
      key: 'delete',
      content: '',
      width: 60,
      visible: !userIsMenuEditor,
    },
  ].filter(cell => cell.visible)

  return (
    <thead>
      <tr>
        {cells.map(({ key, width, sortable, content }) => (
          <th key={key} width={width}>
            {sortable ? (
              <SortBy
                title={content}
                orderBy={key}
                setSelected={setSelected}
                selected={selected}
              />
            ) : (
              content
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}
export default TableHeader
