import gql from 'graphql-tag'

// this mutation must always return the outlets array
const GET_OUTLET_TERMINALS = gql`
  query getOutletTerminals($outletId: ID!) {
    getTerminals(outletId: $outletId) {
      terminals {
        id
        friendlyName
        softwareVersion
        serialNumber
        deviceDescription
        archived
        slave
        alarm
        lastOnline
        isOnline
        outlets {
          id
          isOpen
          isOnline
          active
          restaurant {
            id
            active
          }
        }
      }
    }
  }
`

export default GET_OUTLET_TERMINALS
