import {
  createLoyaltyCardStructure,
  editLoyaltyCardStructure,
} from '@src/validation/loyalty-card'
import * as yup from 'yup'

export const createLoyaltyCardValidation = yup
  .object()
  .shape(createLoyaltyCardStructure)
export const editLoyaltyCardValidation = yup
  .object()
  .shape(editLoyaltyCardStructure)
