import first from 'lodash/first'
import { Tag, Card, Intent, H5 } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { penceToPounds, numberToPence } from '@utils/helpers'

import GET_MARKETPLACE from './queries/getMarketplaceFinancials.query'
import EDIT_MARKETPLACE_FINANCIALS from './mutations/editMarketplaceFinancials.mutation'
import EDIT_PAYMENT_METHODS from './mutations/editMarketplacePaymentMethods.mutation'
import EDIT_APPLEPAY_VERIFICATION from './mutations/editApplePayVerification.mutation'

import MarketplacePaymentMethodsForm from './MarketplacePaymentMethodsForm/MarketplacePaymentMethodsForm'
import FinancialsForm from './MarketplaceFinancialsForm/MarketplaceFinancialsForm'
import MarketplaceApplePayVerificationUpload from './MarketplacePaymentMethodsForm/MarketplaceApplePayVerificationUpload'

import { successToast } from '@utils/toast'
import { isPlatformLevelUser } from '@stores/userStore'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { RegionalMarketplace } from '@src/types.generated'

const Details = () => {
  const { marketplace: marketplaceId } = useParams()

  const [editMarketplaceFinancials] = useMutation(EDIT_MARKETPLACE_FINANCIALS, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [editPaymentMethods] = useMutation(EDIT_PAYMENT_METHODS, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  const [editApplePayVerification] = useMutation(EDIT_APPLEPAY_VERIFICATION, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMarketplace }) => successToast(editMarketplace.message),
  })

  return (
    <Query
      query={GET_MARKETPLACE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Financials'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace: RegionalMarketplace | undefined = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        const initialValues = {
          ...marketplace,
          platformCharge: penceToPounds(marketplace.platformCharge),
          platformTableCharge: penceToPounds(marketplace.platformTableCharge),
          partnerCharge: penceToPounds(marketplace.partnerCharge),
          partnerTableCharge: penceToPounds(marketplace.partnerTableCharge),
          merchantCommissionCharge: penceToPounds(
            marketplace.merchantCommissionCharge
          ),
          merchantTableCommissionCharge: penceToPounds(
            marketplace.merchantTableCommissionCharge
          ),
          partnerId: marketplace.partner?.id,
        }

        return (
          <div className="bp5-layout-row">
            <Card className="bp5-layout-col">
              <FinancialsForm
                onSubmit={values => {
                  editMarketplaceFinancials({
                    variables: {
                      ...values,
                      platformCharge: numberToPence(values.platformCharge),
                      platformTableCharge: numberToPence(
                        values.platformTableCharge
                      ),
                      partnerCharge: numberToPence(values.partnerCharge),
                      partnerTableCharge: numberToPence(
                        values.partnerTableCharge
                      ),
                      merchantCommissionCharge: numberToPence(
                        values.merchantCommissionCharge
                      ),
                      merchantTableCommissionCharge: numberToPence(
                        values.merchantTableCommissionCharge
                      ),
                    },
                  })
                }}
                initialValues={initialValues}
              />
              )
            </Card>

            <div className="bp5-layout-col-side">
              <Card>
                <H5>Payment Methods</H5>

                <MarketplacePaymentMethodsForm
                  stripeDirectPayment={!!marketplace.stripeDirectPayment}
                  paymentMethods={marketplace.paymentMethods}
                  cashText={marketplace.cashText}
                  cashCollectionText={marketplace.cashCollectionText}
                  onSubmit={values =>
                    editPaymentMethods({
                      variables: {
                        ...values,
                        id: marketplaceId,
                      },
                    })
                  }
                />
              </Card>
              {isPlatformLevelUser() && (
                <Card>
                  <h5 className="bp5-heading">
                    Apple Pay{' '}
                    {marketplace.applePayVerification && (
                      <Tag intent={Intent.SUCCESS} minimal>
                        VERIFIED
                      </Tag>
                    )}
                  </h5>

                  <MarketplaceApplePayVerificationUpload
                    onSubmit={values =>
                      editApplePayVerification({
                        variables: {
                          applePayVerification: values.applePayVerification,
                          id: marketplaceId,
                        },
                      })
                    }
                  />
                </Card>
              )}
            </div>
          </div>
        )
      }}
    </Query>
  )
}

export default Details
