import { Fragment } from 'react'
import { Select } from '@blueprintjs/select'
import { MenuItem, Button, NonIdealState } from '@blueprintjs/core'
import join from 'lodash/join'
import findIndex from 'lodash/findIndex'
import { func, object, array, string } from 'prop-types'
import ADD_OPTION_TO_MENU_ITEM from '../mutations/addOptionToMenuItem'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

export const PopoverClass = styled.div`
  .bp5-navigation-menu {
    max-height: 80vh;
    overflow-y: auto;
  }
`

const itemRenderer = (option, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }

  const optionList = option.optionItems.map(item => item.name)
  return (
    <MenuItem
      key={option.id}
      onClick={handleClick}
      multiline={true}
      text={
        <Fragment>
          <strong>{option.name}</strong>
          <br />
          {join(optionList, ', ')}
        </Fragment>
      }
    />
  )
}

const itemPredicate = (query, option) => {
  const match = option.name.toLowerCase().includes(query.toLowerCase())
  const optionItems = option.optionItems.map(item => item.name.toLowerCase())
  const matchOptionItems = optionItems.join(' ').includes(query.toLowerCase())
  if (query.length < 1 || match || matchOptionItems) {
    return true
  }
  return false
}

const addItem = (
  option,
  values,
  push,
  addOptionToMenuItem,
  menuItemId,
  selectedOptions
) => {
  if (findIndex(values.options, option)) {
    push(option)
    const optionIds = selectedOptions.map(({ id }) => id)
    addOptionToMenuItem({
      variables: { id: menuItemId, optionIds: [...optionIds, option.id] },
    })
  } else {
    return
  }
}

const OptionSelect = ({
  values,
  push,
  optionList,
  menuItemId,
  selectedOptions,
}) => {
  const [addOptionToMenuItem] = useMutation(ADD_OPTION_TO_MENU_ITEM, {
    onError: defaultErrorHandler,
    onCompleted: ({ addOptionToMenuItem }) => {
      successToast(addOptionToMenuItem.message)
    },
    refetchQueries: [
      {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ],
  })

  return (
    <Select
      id="options"
      items={optionList}
      itemRenderer={itemRenderer}
      itemPredicate={itemPredicate}
      filterable
      onItemSelect={option => {
        addItem(
          option,
          values,
          push,
          addOptionToMenuItem,
          menuItemId,
          selectedOptions
        )
      }}
      noResults={
        <NonIdealState
          title="No available options"
          description="Please add an option to choose from."
        />
      }
      popoverProps={{
        usePortal: false,
        popoverClassName: PopoverClass,
        placement: 'top-start',
      }}
      resetOnClose
      resetOnQuery
      resetOnSelect
    >
      <Button text="Add Option" icon="add" />
    </Select>
  )
}
OptionSelect.propTypes = {
  values: object.isRequired,
  push: func.isRequired,
  optionList: array.isRequired,
  menuItemId: string,
}

export default OptionSelect
