import { useContext } from 'react'
import GET_MARKETPLACE_APPS from '../Apps/queries/getMarketplaceApps.query'
import Apps from '../Apps/Apps'
import Query from '@components/Query/Query'
import { NonIdealState } from '@blueprintjs/core/lib/esm/components/non-ideal-state/nonIdealState'
import appPageContext from './appPageContext'
import { first } from 'lodash'
import { Card, AnchorButton } from '@blueprintjs/core'
import DisplayAppConfig from './DisplayAppConfig'
import { isPlatformLevelUser } from '@stores/userStore'
import { Row, Col } from '@components/_FlexGrid'
import { useParams } from 'react-router-dom'

const DisplayApp = () => {
  const { marketplace: marketplaceId } = useParams()
  const { setAppId } = useContext(appPageContext)
  const { appId } = useContext(appPageContext)

  return (
    <Query
      query={GET_MARKETPLACE_APPS}
      variables={{ marketplaceId }}
      fetchPolicy={'network-only'}
      loaderTitle={'Loading App'}
      onCompleted={data => {
        const {
          getMarketplaces: { regions },
        } = data

        const region = first(regions)

        if (region.app) {
          setAppId(region.app.id)
        }
      }}
    >
      {data => {
        const {
          getMarketplaces: { regions },
        } = data

        const region = first(regions)

        return (
          <Row gutter={24}>
            <Col sm={12} lg={8}>
              {appId ? (
                <Card className="bp5-nopad bp5-scrollable">
                  <Apps id={appId} />
                </Card>
              ) : (
                <Card>
                  <div style={{ padding: '100px' }}>
                    <NonIdealState
                      icon="list"
                      title="App Unavailable"
                      description="Allow customers to order from their iOS or Android device by downloading an app from the store."
                      action={
                        isPlatformLevelUser() && (
                          <AnchorButton
                            icon="plus"
                            text="Setup App"
                            href={`/partners/${region.partner.id}/apps`}
                          />
                        )
                      }
                    />
                  </div>
                </Card>
              )}
            </Col>
            <Col sm={12} lg={4}>
              <DisplayAppConfig marketplaceId={marketplaceId} />
            </Col>
          </Row>
        )
      }}
    </Query>
  )
}

export default DisplayApp
