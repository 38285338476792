import { StringParam, useQueryParam } from 'use-query-params'

/**
 *
 * A nice way of interacting with the `orderBy=autoRejectedOrders_DESC` part of the query string
 */
export const useMegaTableSort = () => {
  const [urlOrderBy, setUrlOrderBy] = useQueryParam('orderBy', StringParam)

  const match = urlOrderBy?.match(/(?<field>[A-z]+)_(?<order>ASC|DESC)/) ?? []
  const field = match?.[1]
  const order = match?.[2]

  return {
    field,
    order,
    setSort: (
      setSortArgs:
        | {
            field: string
            order: 'ASC' | 'DESC'
          }
        | 'NONE'
    ) => {
      if (setSortArgs === 'NONE') {
        setUrlOrderBy(undefined)
      } else {
        const { field, order } = setSortArgs
        setUrlOrderBy(`${field}_${order}`)
      }
    },
    fullString: urlOrderBy,
  }
}
