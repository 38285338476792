import React from 'react'
import get from 'lodash/get'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_CUSTOMER_NAME from './queries/getCustomerName.query'
import { replace } from 'lodash'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const OutletCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_CUSTOMER_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ customers }) => {
            const customer = get(customers, 'customers[0]')
            if (!customer) {
              return null
            }

            return (
              <Crumb
                key={crumb}
                text={
                  customer
                    ? `${customer.firstName} ${customer.lastName}`
                    : crumb
                }
                link={`/customer/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={replace(crumb, '-', ' ')}
          link={`/customer/${crumbs[1]}/${crumb}`}
        />
      )
    }

    return <Crumb key={crumb} text={crumb} />
  })

export default OutletCrumbs
