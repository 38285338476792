import { Tag } from '@blueprintjs/core'
import { useState, useEffect } from 'react'

const STATUS_PAGE_URL = 'https://w87zt0h66nqt.statuspage.io/api/v2/status.json'

export const StatusPageTag: React.FC = () => {
  const [status, setStatus] = useState<string | null>(null)

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await fetch(STATUS_PAGE_URL)
      const data = await response.json()
      setStatus(data.status.indicator)
    }

    fetchStatus().catch(console.error)
  }, [])

  if (!status) {
    return null
  } else if (status === 'none') {
    return <Tag intent="success">OK</Tag>
  } else if (status === 'minor') {
    return <Tag intent="warning">MINOR</Tag>
  } else if (status === 'major') {
    return <Tag intent="danger">INCIDENT</Tag>
  } else if (status === 'critical') {
    return <Tag intent="danger">CRITICAL</Tag>
  } else {
    return <Tag intent="none">{status}</Tag>
  }
}
