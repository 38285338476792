import gql from 'graphql-tag'

const EDIT_MARKETPLACE_TERMINAL = gql`
  mutation editMarketplaceTerminal(
    $id: String!
    $image: String
    $receiptHideMarketplace: Boolean
    $receiptHideOutlet: Boolean
    $receiptHideCustomerPhoneNumber: Boolean
    $receiptLargeOrderNotes: Boolean
    $receiptLargeDeliveryNotes: Boolean
    $emailReceiptOnOrder: Boolean
    $receiptMessage: String
    $pollInterval: Int
  ) {
    editMarketplace(
      id: $id
      image: $image
      receiptHideMarketplace: $receiptHideMarketplace
      receiptHideOutlet: $receiptHideOutlet
      receiptHideCustomerPhoneNumber: $receiptHideCustomerPhoneNumber
      receiptLargeOrderNotes: $receiptLargeOrderNotes
      receiptLargeDeliveryNotes: $receiptLargeDeliveryNotes
      emailReceiptOnOrder: $emailReceiptOnOrder
      receiptMessage: $receiptMessage
      pollInterval: $pollInterval
    ) {
      message
      marketplace {
        id
        name
        image
        receiptHideMarketplace
        receiptHideOutlet
        receiptHideCustomerPhoneNumber
        receiptLargeOrderNotes
        receiptLargeDeliveryNotes
        emailReceiptOnOrder
        receiptMessage
        pollInterval
      }
    }
  }
`

export default EDIT_MARKETPLACE_TERMINAL
