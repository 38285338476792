import gql from 'graphql-tag'

const EDIT_MARKETPLACE_MESSAGE_SETTINGS = gql`
  mutation editMarketplaceMessageSettings(
    $id: String!
    $emailSenderUsername: String
    $pinpointNotificationStrategy: PinpointNotificationStrategy
    $pinpointAwsAccountId: String
    $sesMessaging: String
    $smsSenderId: String
  ) {
    editMarketplaceMessageSettings(
      id: $id
      emailSenderUsername: $emailSenderUsername
      pinpointNotificationStrategy: $pinpointNotificationStrategy
      pinpointAwsAccountId: $pinpointAwsAccountId
      sesMessaging: $sesMessaging
      smsSenderId: $smsSenderId
    ) {
      message
      marketplace {
        id
        emailSenderUsername
        pinpointNotificationStrategy
        pinpointAwsAccountId
        sesMessaging
        smsSenderId
      }
    }
  }
`

export default EDIT_MARKETPLACE_MESSAGE_SETTINGS
