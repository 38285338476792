import { startCase } from 'lodash'
import { Breadcrumb, Classes } from '@blueprintjs/core'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const StyledBreadcrumb = styled<typeof Breadcrumb, { collapsed: boolean }>(
  Breadcrumb
)`
  color: ${({ theme: { colors } }) => `${colors.headerTextColor} !important`};
`

const Crumb: React.FC<CrumbProps> = ({ collapsed, text, link }) => {
  const href = link ? link : `/${text}`
  const navigate = useNavigate()
  return (
    <li>
      <StyledBreadcrumb
        collapsed={!!collapsed}
        className={collapsed ? Classes.BREADCRUMBS_COLLAPSED : ''}
        text={!collapsed && startCase(text)}
        onClick={() => {
          navigate(href)
        }}
      />
    </li>
  )
}

interface CrumbProps {
  collapsed?: boolean
  text: string
  link?: string
}

export default Crumb
