import gql from 'graphql-tag'

const EDIT_GOOGLE_JSON_S3_KEY_PATH = gql`
  mutation editGoogleJsonS3KeyPath(
    $id: ID!
    $googleJsonS3KeyPathDefault: String
  ) {
    editPartner(
      id: $id
      googleJsonS3KeyPathDefault: $googleJsonS3KeyPathDefault
    ) {
      partner {
        id
        name
        googleJsonS3KeyPathDefault
      }
    }
  }
`

export default EDIT_GOOGLE_JSON_S3_KEY_PATH
