import gql from 'graphql-tag'

const GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET = gql`
  query getAvailableDeliveryZonesForOutlet($outletId: String!) {
    getAvailableDeliveryZonesForOutlet(outletId: $outletId) {
      id
      name
      deliveryZoneType
      deliveryNetworkType
    }
  }
`

export default GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET
