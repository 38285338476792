import { createContext, useContext, useState, ReactNode } from 'react'

type ModalState = {
  restaurantModal: boolean
  marketplaceModal: boolean
  partnerModal: boolean
  itemModal: boolean
  subMenuModal: boolean
  optionModal: boolean
  outletModal: boolean
  addTableModal: boolean
  editTableFriendlyNameModal: boolean
  terminalModal: boolean
  profileModal: boolean
  userModal: boolean
  outletDeliveryZoneModal: boolean
  parentMenuModal: boolean
  editMenuModal: boolean
  resetTerminalPinModal: boolean
  addDeliveryZoneModal: boolean
  addDiscountModal: boolean
  editDiscountModal: boolean
  editReviewModal: boolean
  printOrderModal: string | false // Updated this line
}

type ModalContextType = {
  state: ModalState
  setState: (newState: Partial<ModalState>) => void
}

const defaultModalState: ModalState = {
  restaurantModal: false,
  marketplaceModal: false,
  partnerModal: false,
  itemModal: false,
  subMenuModal: false,
  optionModal: false,
  outletModal: false,
  addTableModal: false,
  editTableFriendlyNameModal: false,
  terminalModal: false,
  profileModal: false,
  userModal: false,
  outletDeliveryZoneModal: false,
  parentMenuModal: false,
  editMenuModal: false,
  resetTerminalPinModal: false,
  addDeliveryZoneModal: false,
  addDiscountModal: false,
  editDiscountModal: false,
  editReviewModal: false,
  printOrderModal: false, // Updated this line
}

export const ModalContext = createContext<ModalContextType>({
  state: defaultModalState,
  setState: () => {},
})

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, _setState] = useState<ModalState>(defaultModalState)

  const setState = (newState: Partial<ModalState>) => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }))
  }

  return (
    <ModalContext.Provider value={{ state, setState }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = <K extends keyof ModalState>(modalName: K) => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  const { state, setState } = context

  return {
    isOpen: state[modalName],
    toggleModal: (newState: ModalState[K]) =>
      setState({ [modalName]: newState }),
  }
}
