import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import ADD_DELIVERY_ZONE from '@components/DeliveryZone/mutations/addDeliveryZone.mutation'
import AddForm from '@components/DeliveryZone/AddForm'
import { newDZvalidation } from './validation'
import GET_DELIVERY_ZONES from '@components/Marketplace/DeliveryZones/queries/getDeliveryZones.query'
import { deliveryZoneType } from '@utils/constants'
import { useMutation } from '@apollo/client'

const AddDeliveryZoneDrawer = ({ modalOpen, modalClose, marketplaceId }) => {
  const [addDeliveryZone] = useMutation(ADD_DELIVERY_ZONE, {
    refetchQueries: [
      {
        query: GET_DELIVERY_ZONES,
        variables: {
          marketplaceId,
        },
      },
    ],
    onCompleted: data => {
      successToast(data.addDeliveryZone.message)
      modalClose()
    },
    onError: defaultErrorHandler,
  })

  return (
    <Formik
      validationSchema={newDZvalidation}
      initialValues={{
        marketplaceId,
        name: '',
        deliveryZoneType: deliveryZoneType.COORDINATE_POLYGON,
      }}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        await addDeliveryZone({
          variables: {
            ...values,
          },
        })
        resetForm()
      }}
    >
      {props => (
        <AddForm
          {...props}
          marketplaceId={marketplaceId}
          modalOpen={modalOpen}
          modalClose={modalClose}
        />
      )}
    </Formik>
  )
}

AddDeliveryZoneDrawer.propTypes = {
  ...modalType,
  marketplaceId: string,
}

export default AddDeliveryZoneDrawer
