import gql from 'graphql-tag'

const GET_TERMINAL_NAME = gql`
  query getTerminalName($ids: [ID]) {
    getTerminals(ids: $ids) {
      terminals {
        id
        friendlyName
      }
    }
  }
`

export default GET_TERMINAL_NAME
