import gql from 'graphql-tag'

const DISCOUNT_VOUCHERS_BY_MARKETPLACE = gql`
  query discountVouchersByMarketplace(
    $marketplaceId: String!
    $searchText: String
    $active: Boolean
    $orderBy: DiscountOrderByInput
    $skip: Int
    $first: Int
  ) {
    discountVouchersByMarketplace(
      marketplaceId: $marketplaceId
      where: { name_contains: $searchText, vouchers_some: { active: $active } }
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      count
      totalCount
      discounts {
        id
        name
        discountAmount
        discountPercentage
        minimumSubtotalGross
        createdAt
        vouchers {
          active
          id
          usageCount
        }
      }
    }
  }
`

export default DISCOUNT_VOUCHERS_BY_MARKETPLACE
