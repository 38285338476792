import React from 'react'
import { arrayOf, func } from 'prop-types'
import { cuisineType } from '@utils/types'
import TypeaheadMulti from '../../Typeahead/TypeaheadMulti'

const CuisineSelect = ({
  cuisineList,
  selectedCuisines,
  setSelected,
  placeholder = 'Select cuisines...',
  disabled = false,
  preferEmoji,
}) => (
  <TypeaheadMulti
    items={cuisineList}
    selected={selectedCuisines}
    setSelected={setSelected}
    placeholder={placeholder}
    showEmojis
    disabled={disabled}
    preferEmoji={preferEmoji}
  />
)

CuisineSelect.propTypes = {
  cuisineList: arrayOf(cuisineType).isRequired,
  selectedCuisines: arrayOf(cuisineType).isRequired,
  setSelected: func,
}

export default CuisineSelect
