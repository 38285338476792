import { Component } from 'react'
import { object, array, bool } from 'prop-types'
import isEqual from 'lodash/isEqual'
import { StyledTree } from './Menu.styles'

// blueprintjs tree component needs to be in class
class MenusList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nodes: this.props.menus,
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.menus, prevProps.menus)) {
      this.setState({
        nodes: this.props.menus,
      })
    }
  }

  handleNodeExpand = node => {
    node.isExpanded = true
    this.setState(this.state)
  }

  handleNodeCollapse = node => {
    node.isExpanded = false
    this.setState(this.state)
  }

  handleNodeClick = node => {
    if (node.onClick) {
      node.onClickItem()
    }
  }

  render() {
    return (
      // column view
      <StyledTree
        onNodeExpand={this.handleNodeExpand}
        onNodeCollapse={this.handleNodeCollapse}
        onNodeClick={node => {
          node.onClickItem()
        }}
        hasCaret={false}
        contents={this.state.nodes}
      />
    )
  }
}

MenusList.propTypes = {
  client: object,
  menus: array,
  subMenu: bool,
}

export default MenusList
