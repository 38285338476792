import { HTMLSelect } from '@blueprintjs/core'
import { startCase } from 'lodash'
import { useGetDeliveryNetworkProvidersForPartnerQuery } from './getDeliveryNetworkProvidersByPartner.query.generated'
import { useDeliveryNetworkProviderQueryParams } from './useDeliveryNetworkProviderQueryParams'

interface ByPartnerProps {
  partnerId: string
}
export const DeliveryNetworkProviderForPartnerSelect: React.FC<
  ByPartnerProps
> = ({ partnerId }) => {
  const { data } = useGetDeliveryNetworkProvidersForPartnerQuery({
    variables: { partnerId },
  })

  if (!data) return null

  const partner = data.getPartners.partners?.[0]
  if (!partner) {
    console.error(
      "no partner returned even though we got it's id from the database"
    ) // this _should_ never get called
    return null
  }

  return (
    <DeliveryNetworkProviderSelect
      options={partner.deliveryNetworkProviders.map(provider => ({
        value: provider,
        label: provider,
      }))}
    />
  )
}

export const DeliveryNetworkProviderSelectAll = () => (
  <DeliveryNetworkProviderSelect
    options={[
      { value: 'STUART', label: 'Stuart' },
      { value: 'UBER_DIRECT', label: 'Uber Direct' },
      { value: 'WAYBOX', label: 'Waybox' },
    ]}
  />
)

const DeliveryNetworkProviderSelect: React.FC<{
  options: { value: string; label: string }[]
}> = ({ options }) => {
  const [deliveryNetworkProvider, setDeliveryNetworkProvider] =
    useDeliveryNetworkProviderQueryParams()
  return (
    <HTMLSelect
      name="deliveryNetworkProvider"
      value={String(deliveryNetworkProvider)}
      onChange={event => {
        setDeliveryNetworkProvider(event.currentTarget.value)
      }}
    >
      <option disabled value="">
        Choose
      </option>
      {options.map(({ value, label }) => (
        <option value={value}>
          {startCase(label.toLowerCase().replaceAll('_', ' '))}
        </option>
      ))}
    </HTMLSelect>
  )
}
