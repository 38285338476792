import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRefundsReportQueryVariables = Types.Exact<{
  dateFrom: Types.Scalars['DateTime']['input'];
  dateTo: Types.Scalars['DateTime']['input'];
  marketplaceId: Types.Scalars['String']['input'];
}>;


export type GetRefundsReportQuery = { __typename?: 'Query', getRefundReport?: Array<{ __typename?: 'RefundReport', id: string, orderId: string, outletId: string, marketplaceId: string, refundAmount: number, refundedAt: string, acceptedAt: string, refundedInDifferentBillingPeriod: boolean, totalRefundsForOutlet: number, order: { __typename?: 'Order', id: string, paymentMethod: Types.PaymentMethod, grossTotal: number, orderNumber: string, orderStatus?: Types.OrderStatus | null, fulfillmentMethod: Types.OrderFulfillmentMethods, deliveryNetworkProvider?: Types.DeliveryProvidersEnum | null, customer: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null } }, outlet: { __typename?: 'Outlet', id: string, name: string, contactName?: string | null, contactEmail?: string | null, contactPhone?: string | null, isOpen?: boolean | null, isOnline?: boolean | null, active?: boolean | null, promoted?: boolean | null, contactAddress?: { __typename?: 'Address', city: string, postcode: string } | null, restaurant: { __typename?: 'Restaurant', id: string, name: string, active?: boolean | null }, marketplace?: { __typename?: 'RegionalMarketplace', id: string, name: string } | null } }> | null };


export const GetRefundsReportDocument = gql`
    query GetRefundsReport($dateFrom: DateTime!, $dateTo: DateTime!, $marketplaceId: String!) {
  getRefundReport(
    dateFrom: $dateFrom
    dateTo: $dateTo
    marketplaceId: $marketplaceId
  ) {
    id
    orderId
    outletId
    marketplaceId
    refundAmount
    refundedAt
    acceptedAt
    refundedInDifferentBillingPeriod
    totalRefundsForOutlet
    order {
      id
      paymentMethod
      grossTotal
      orderNumber
      orderStatus
      customer {
        id
        firstName
        lastName
      }
      fulfillmentMethod
      deliveryNetworkProvider
    }
    outlet {
      id
      name
      contactName
      contactEmail
      contactPhone
      contactAddress {
        city
        postcode
      }
      promoted: promote
      isOpen
      isOnline
      active
      restaurant {
        id
        name
        active
      }
      marketplace {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRefundsReportQuery__
 *
 * To run a query within a React component, call `useGetRefundsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundsReportQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      marketplaceId: // value for 'marketplaceId'
 *   },
 * });
 */
export function useGetRefundsReportQuery(baseOptions: Apollo.QueryHookOptions<GetRefundsReportQuery, GetRefundsReportQueryVariables> & ({ variables: GetRefundsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRefundsReportQuery, GetRefundsReportQueryVariables>(GetRefundsReportDocument, options);
      }
export function useGetRefundsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRefundsReportQuery, GetRefundsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRefundsReportQuery, GetRefundsReportQueryVariables>(GetRefundsReportDocument, options);
        }
export function useGetRefundsReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRefundsReportQuery, GetRefundsReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRefundsReportQuery, GetRefundsReportQueryVariables>(GetRefundsReportDocument, options);
        }
export type GetRefundsReportQueryHookResult = ReturnType<typeof useGetRefundsReportQuery>;
export type GetRefundsReportLazyQueryHookResult = ReturnType<typeof useGetRefundsReportLazyQuery>;
export type GetRefundsReportSuspenseQueryHookResult = ReturnType<typeof useGetRefundsReportSuspenseQuery>;
export type GetRefundsReportQueryResult = Apollo.QueryResult<GetRefundsReportQuery, GetRefundsReportQueryVariables>;