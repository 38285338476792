import React from 'react'
import { CenterSection, Title, Text } from '../OrderPrintout'

const OrderNotes = ({ order: { customerOrderNotes } }) =>
  customerOrderNotes && customerOrderNotes.length ? (
    <CenterSection>
      <Title as="h4">Order Notes</Title>
      <Text>{customerOrderNotes}</Text>
    </CenterSection>
  ) : null

export default OrderNotes
