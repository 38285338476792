import gql from 'graphql-tag'

const ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_VOUCHER = gql`
  query marketplaceOrdersWithVouchers(
    $marketplaceId: String!
    $discountId: String!
  ) {
    marketplaceOrdersWithVouchers(
      marketplaceId: $marketplaceId
      discountId: $discountId
    ) {
      discountId
      outletId
      discountAmount
      fulfillmentMethod
      subtotalGross
      customerId
      paymentMethod
      id
      voucherKey
    }
  }
`

export default ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_VOUCHER
