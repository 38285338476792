import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import productDictionary from '../../utils/productDictionary'
import Inbox from './Inbox'
import Marketplaces from './Marketplaces'
import Outbox from './Sent'

const Messaging = () => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    const tabs = [{ to: '/messaging', name: 'List' }]
    configurePageLayout({
      product: productDictionary.MESSAGING,
      tabs,
    })
  }, [configurePageLayout])

  return (
    <Routes>
      <Route path="/" element={<Marketplaces />} />
      <Route path="/inbox/:marketplaceId" element={<Inbox />} />
      <Route path="/sent/:marketplaceId" element={<Outbox />} />
    </Routes>
  )
}

export default Messaging
