import { useState, Fragment } from 'react'
import { bool, func, string, object } from 'prop-types'
import { Classes, Intent, Button } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { ARCHIVE_CUSTOMER } from './mutations/archiveCustomer.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const ArchiveCustomerModal = ({ isModalOpen, customerId, closeModal }) => {
  const [isMutating, setIsMutating] = useState(false)
  const navigate = useNavigate()

  const [archiveCustomer] = useMutation(ARCHIVE_CUSTOMER, {
    onError: (...args) => {
      setIsMutating(false)
      defaultErrorHandler(...args)
    },
    onCompleted: () => {
      successToast('Customer successfully archived')
      navigate('/customers')
    },
  })

  return (
    <Dialog title="Archive Customer" isOpen={isModalOpen} onClose={closeModal}>
      <Fragment>
        <div className={Classes.DIALOG_BODY}>
          <p>
            The customer will no longer be able to log in, and their data will
            no longer be visible within management.
          </p>
          <p>This action cannot be reverted.</p>
          <p>Are you sure you want to archive the customer?</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button minimal onClick={closeModal} disabled={isMutating}>
              Cancel
            </Button>
            <Button
              loading={isMutating}
              intent={Intent.DANGER}
              type="submit"
              onClick={() => {
                setIsMutating(true)
                archiveCustomer({
                  variables: {
                    customerId,
                  },
                })
              }}
            >
              Archive
            </Button>
          </div>
        </div>
      </Fragment>
    </Dialog>
  )
}

ArchiveCustomerModal.propTypes = {
  isModalOpen: bool.isRequired,
  customerId: string.isRequired,
  closeModal: func.isRequired,
}
export default ArchiveCustomerModal
