import { Fragment } from 'react'
import {
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
} from '@blueprintjs/core'
import { FieldArray } from 'formik'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { DateInput3 } from '@blueprintjs/datetime2'
import WeekDaysSelect from '../WeekDaysSelect'
import { func, object } from 'prop-types'
import OutletTypeahead from '@components/OutletTypeahead/OutletTypeahead'

/**
 * Simple component that enables reuse of the discount creation inner form.
 * This will be reused in quite a few places so worth keeping things consistent and with the same logic.
 */
const CreateDiscountInnerFormStruct = ({
  errors,
  values,
  handleChange,
  setFieldValue,
  firstLabelCaption = 'Discount',
  marketplaceId,
  isDisabled = false,
  allowOutletSelection = true,
  restaurantId = null,
}) => {
  let warnOnFixedDiscountAndZeroMinimumSubtotal = ''

  if (
    parseFloat(values.discountAmount) > 0 &&
    parseFloat(values.minimumSubtotalGross) <= parseFloat(values.discountAmount)
  ) {
    warnOnFixedDiscountAndZeroMinimumSubtotal =
      'Note that if the fixed discount is greater than the minimum subtotal, the remaining discount difference will be lost at checkout.'
  }

  return (
    <Fragment>
      <FormGroup
        label={firstLabelCaption}
        labelInfo="(required)"
        labelFor="name"
        helperText={
          errors.name || 'A friendly name for your discount, e.g. "10% off"'
        }
        intent={errors.name ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          name="name"
          id="name"
          type="text"
          onChange={handleChange}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
          value={values.name}
          disabled={isDisabled}
        />
      </FormGroup>
      {allowOutletSelection && (
        <FormGroup
          helperText={errors.allowedOutletIds}
          intent={errors.allowedOutletIds ? Intent.DANGER : Intent.NONE}
          label="Boundry"
        >
          <RadioGroup
            inline={true}
            disabled={isDisabled}
            onChange={e => {
              e.target.value === 'outlet'
                ? setFieldValue('outletSelected', true)
                : setFieldValue('outletSelected', false)
            }}
            selectedValue={values.outletSelected ? 'outlet' : 'marketplaceWide'}
          >
            <Radio
              label={restaurantId ? 'Business Wide' : 'Marketplace'}
              value="marketplaceWide"
            />
            <Radio label={`Outlet`} value="outlet" />
            {values.outletSelected && (
              <OutletTypeahead
                outletIds={values.allowedOutletIds}
                onChange={value => {
                  setFieldValue('allowedOutletIds', value)
                }}
                showMarketplaceName={false}
                showRestaurantName
                alwaysMultiSelect={true}
                marketplaceId={marketplaceId}
                disabled={isDisabled}
                intent={errors.allowedOutletIds ? Intent.DANGER : Intent.NONE}
                restaurantId={restaurantId}
              />
            )}
          </RadioGroup>
        </FormGroup>
      )}
      <FormGroup
        disabled={isDisabled}
        label="Percentage Discount"
        labelFor="discountPercentage"
        helperText={
          errors.discountPercentage ||
          'Percentage discount applied to the basket subtotal.'
        }
        intent={errors.discountPercentage ? Intent.DANGER : Intent.NONE}
      >
        <PercentageInput
          value={values.discountPercentage}
          disabled={isDisabled}
          handleChange={values =>
            setFieldValue('discountPercentage', values.floatValue)
          }
          fill
          intent={errors.discountPercentage ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Fixed Discount"
        labelFor="discountAmount"
        helperText={
          errors.discountAmount ||
          'Fixed discount applied to the basket subtotal.'
        }
        intent={errors.discountAmount ? Intent.DANGER : Intent.NONE}
      >
        <CurrencyInput
          name="discountAmount"
          id="discountAmount"
          disabled={isDisabled}
          intent={errors.discountAmount ? Intent.DANGER : Intent.NONE}
          defaultValue={values.discountAmount}
          onUpdate={amount => {
            setFieldValue('discountAmount', amount)
          }}
          fill={true}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Minimum Subtotal"
        labelFor="minimumSubtotalGross"
        helperText={
          errors.minimumSubtotalGross ||
          warnOnFixedDiscountAndZeroMinimumSubtotal ||
          'Basket subtotal must be greater than this amount for the discount to be applied.'
        }
        intent={
          errors.minimumSubtotalGross
            ? Intent.DANGER
            : warnOnFixedDiscountAndZeroMinimumSubtotal
            ? Intent.WARNING
            : Intent.NONE
        }
      >
        <CurrencyInput
          name="minimumSubtotalGross"
          id="minimumSubtotalGross"
          disabled={isDisabled}
          intent={errors.minimumSubtotalGross ? Intent.DANGER : Intent.NONE}
          defaultValue={values.minimumSubtotalGross}
          onUpdate={amount => {
            setFieldValue('minimumSubtotalGross', amount)
          }}
          fill={true}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Start Date"
        labelFor="startDate"
        helperText={
          errors.startDate || 'Discount will not apply before this date.'
        }
        intent={errors.startDate ? Intent.DANGER : Intent.NONE}
      >
        <DateInput3
          id="startDate"
          highlightCurrentDay
          dateFnsFormat="dd/MM/yyyy"
          value={values.startDate}
          // formatDate={date => date.toLocaleDateString()}
          disabled={isDisabled}
          onChange={date => setFieldValue('startDate', date)}
          className={errors.startDate && 'bp5-input-group bp5-intent-danger'}
          locale="en-GB" // locale is not timezone just date format
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="End Date"
        labelFor="endDate"
        helperText={
          errors.endDate || 'Discount will not apply after this date.'
        }
        intent={errors.endDate ? Intent.DANGER : Intent.NONE}
      >
        <DateInput3
          id="endDate"
          highlightCurrentDay
          dateFnsFormat="dd/MM/yyyy"
          minDate={new Date(values.startDate)}
          className={errors.endDate && 'bp5-input-group bp5-intent-danger'}
          disabled={isDisabled}
          value={values.endDate}
          onChange={date => setFieldValue('endDate', date)}
        />
      </FormGroup>

      <FormGroup
        disabled={isDisabled}
        label="Days Allowed"
        labelFor="daysOfWeek"
        style={{ marginBottom: 5 }}
        helperText={
          errors.daysOfWeek || 'Select the days the discount is applied.'
        }
        intent={errors.daysOfWeek ? Intent.DANGER : Intent.NONE}
      >
        <FieldArray
          name="daysOfWeek"
          render={({
            form: {
              values: { daysOfWeek },
            },
            push,
            remove,
          }) => (
            <WeekDaysSelect
              intent={errors.daysOfWeek ? Intent.DANGER : Intent.NONE}
              selectedDays={daysOfWeek}
              push={push}
              remove={remove}
              disabled={isDisabled}
            />
          )}
        />
      </FormGroup>
    </Fragment>
  )
}

CreateDiscountInnerFormStruct.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
}

export default CreateDiscountInnerFormStruct
