import gql from 'graphql-tag'

const ACTIVATE_RESTAURANT = gql`
  mutation activateRestaurant(
    $id: String!
    $active: Boolean
    $partnerId: String!
  ) {
    editRestaurant(id: $id, active: $active, partnerId: $partnerId) {
      message
      restaurant {
        id
        active
      }
    }
  }
`
export default ACTIVATE_RESTAURANT
