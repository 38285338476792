import { ControlGroup } from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import MarketplaceSelect from '@src/components/MarketplaceSelect/MarketplaceSelect'
import { tableProps } from '@src/components/MegaTable/Columns/column.types'
import { useMegaTableSort } from '@src/components/MegaTable/useMegaTableSort'
import DateRangeFilter from '@src/components/Toolbar/DateRangeFilter/DateRangeFilter'
import { useDateRangeQueryParams } from '@src/components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import { DATE_FILTER_TYPES } from '@src/utils/datetime'
import { subDays } from 'date-fns'
import { StringParam, useQueryParam } from 'use-query-params'
import { MegaTable } from '../../../components/MegaTable/MegaTable'
import { useGetRejectionsReportByOutletQuery } from './queries/rejectionsReportByOutlet.query.generated'
import { RejectionsHeatmap } from './RejectionsHeatmap'

export const RejectionsByOutlet: React.FC = () => {
  const [marketplaceId, setMarketplaceId] = useQueryParam(
    'marketplaceId',
    StringParam
  )

  const [showingOutlet, setShowingOutlet] = useQueryParam(
    'showingOutlet',
    StringParam
  )

  const {
    startOfRangeDateTime,
    endOfRangeDateTime,
    dateFilterTypeKey,
    startOfRangeDateStr,
    rangeLength: { days: rangeDays },
  } = useDateRangeQueryParams()

  const { fullString: orderBy } = useMegaTableSort()

  const { loading, error, data } = useGetRejectionsReportByOutletQuery({
    variables: {
      marketplaceId: String(marketplaceId),
      dateFrom: startOfRangeDateTime,
      dateTo: endOfRangeDateTime,
      orderBy,
    },
    skip: !marketplaceId,
  })
  const { data: priorData } = useGetRejectionsReportByOutletQuery({
    variables: {
      marketplaceId: String(marketplaceId),
      dateFrom: subDays(startOfRangeDateTime, rangeDays + 1),
      dateTo: subDays(endOfRangeDateTime, rangeDays + 1),
      orderBy,
    },
    skip: !marketplaceId,
  })

  // TODO handle loading and error states

  const showingOutletData = (data?.getRejectionsReportByOutlet ?? []).find(
    x => {
      return x.outlet.id === showingOutlet
    }
  )

  return (
    <div>
      <FilterRow>
        <ControlGroup>
          <MarketplaceSelect
            marketplaceId={marketplaceId}
            onChange={setMarketplaceId}
          />

          <DateRangeFilter filterTypes={[DATE_FILTER_TYPES.DAYS_AGO]} />
        </ControlGroup>
      </FilterRow>

      {
        <MegaTable
          {...tableProps({
            isLoading: loading,
            isError: !!error,
            sorting: 'SERVER',
            columnGroupHeaders: {
              total: 'Total Rejected',
              auto: 'Automatically Rejected',
              manual: 'Manually Rejected',
            },
            columns: [
              {
                type: 'outlet',
                header: 'Outlet',
                isSortable: true,
                name: 'outletName',
              },
              {
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                name: 'totalOrders',
              },

              {
                type: 'currency',
                isSortable: true,
                header: 'Value',
                name: 'totalValue',
              },

              {
                type: 'percentage',
                header: 'Rate',
                isSortable: true,
                name: 'totalRejectionRate',
                groupKey: 'total',
                showChanges: 'ACTUAL',
                biggerIsWorse: true,
              },
              {
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                name: 'totalRejectedOrders',
                groupKey: 'total',
              },

              {
                type: 'currency',
                header: 'Value',
                isSortable: true,
                name: 'totalRejectedValue',
                groupKey: 'total',
              },
              {
                type: 'percentage',
                isSortable: true,
                header: 'Rate',
                name: 'manualRejectionRate',
                groupKey: 'manual',
                showChanges: 'ACTUAL',
                biggerIsWorse: true,
              },
              {
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                name: 'manualRejectedOrders',
                groupKey: 'manual',
              },
              {
                type: 'currency',
                isSortable: true,
                header: 'Value',
                name: 'manualRejectedValue',
                groupKey: 'manual',
              },
              {
                type: 'percentage',
                header: 'Rate',
                name: 'autoRejectionRate',
                isSortable: true,
                groupKey: 'auto',
                showChanges: 'ACTUAL',
                biggerIsWorse: true,
              },
              {
                type: 'numeric',
                isSortable: true,
                header: 'Orders',
                name: 'autoRejectedOrders',
                groupKey: 'auto',
              },

              {
                type: 'currency',
                isSortable: true,
                header: 'Value',
                name: 'autoRejectedValue',
                groupKey: 'auto',
              },
            ] as const,
            data: (data?.getRejectionsReportByOutlet ?? []).map(
              ({
                outlet,
                totalOrders,
                totalValue,
                totalRejectionRate,
                totalRejectedOrders,
                totalRejectedValue,
                manualRejectionRate,
                manualRejectedOrders,
                manualRejectedValue,
                autoRejectionRate,
                autoRejectedOrders,
                autoRejectedValue,
              }) => {
                return [
                  {
                    outlet,
                    displayBusinessName: true,
                    onClick: () => setShowingOutlet(outlet.id),
                  },
                  {
                    value: totalOrders,
                    link: `/orders?dateFilterTypeKey=${dateFilterTypeKey}&startOfRangeDate=${startOfRangeDateStr}&marketplaceIds=${marketplaceId}&marketplaceBusinessIds=${outlet.restaurant.id}&marketplaceOutletIds=${marketplaceId}:${outlet.id}`,
                  },
                  { amount: totalValue },
                  {
                    value: totalRejectionRate,
                    priorValue: (
                      priorData?.getRejectionsReportByOutlet ?? []
                    ).find(x => x.outlet.id === outlet.id)?.totalRejectionRate,
                  },
                  {
                    value: totalRejectedOrders,
                    link: `/orders/rejected?dateFilterTypeKey=${dateFilterTypeKey}&startOfRangeDate=${startOfRangeDateStr}&marketplaceIds=${marketplaceId}&restaurantFilter=${outlet.restaurant.id}`,
                  },
                  { amount: totalRejectedValue },
                  {
                    value: manualRejectionRate,
                    priorValue: (
                      priorData?.getRejectionsReportByOutlet ?? []
                    ).find(x => x.outlet.id === outlet.id)?.manualRejectionRate,
                  },
                  {
                    value: manualRejectedOrders,
                  },
                  { amount: manualRejectedValue },
                  {
                    value: autoRejectionRate,
                    priorValue: (
                      priorData?.getRejectionsReportByOutlet ?? []
                    ).find(x => x.outlet.id === outlet.id)?.autoRejectionRate,
                  },
                  {
                    value: autoRejectedOrders,
                  },
                  { amount: autoRejectedValue },
                ]
              }
            ),
          })}
        />
      }
      {showingOutletData && (
        <RejectionsHeatmap
          outletName={showingOutletData.outlet.name}
          businessName={showingOutletData.outlet.restaurant.name}
        />
      )}
    </div>
  )
}
