import { Dialog } from '@blueprintjs/core'
import { Formik } from 'formik'
import InnerForm from '../InnerForm'
import BLACKLIST_ADDRESS from '../mutations/BlacklistAddress.mutation'
import GET_BLACKLISTED_ADDRESS_BY_MARKETPLACE_ID from '../queries/MarketplaceBlacklistedAddresses.query'
import blacklistedAddressValidation from '../validation/validation'
import { useMutation } from '@apollo/client'

const BlacklistAddressModal = ({
  marketplaceId,
  blacklistedAddressModalOpen,
  setBlacklistedAddressModalOpen,
  handleSubmit,
}) => {
  const [addRequest] = useMutation(BLACKLIST_ADDRESS, {
    refetchQueries: [
      {
        query: GET_BLACKLISTED_ADDRESS_BY_MARKETPLACE_ID,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  return (
    <Dialog
      title="Blacklist Address"
      isOpen={blacklistedAddressModalOpen}
      onClose={() => setBlacklistedAddressModalOpen(false)}
    >
      <Formik
        initialValues={{
          firstLine: '',
          secondLine: '',
          thirdLine: '',
          city: '',
          postcode: '',
          marketplaceId,
          country: '',
        }}
        validationSchema={blacklistedAddressValidation}
        validateOnChange={false}
        onSubmit={vars => handleSubmit(vars, addRequest)}
      >
        {props => (
          <InnerForm
            {...props}
            disabled={false}
            marketplaceId={marketplaceId}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default BlacklistAddressModal
