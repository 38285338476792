import gql from 'graphql-tag'

const GET_RESTAURANT_OUTLETS = gql`
  query restaurantFinancials($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        outlets {
          id
          name
          hiddenMenuItemGroupIds
          deliveryZoneCosts {
            id
            deliveryZone {
              id
              name
            }
          }
          marketplace {
            id
            cname
            enablePayboxEnterprise
            enableCustomerV2Client
            orderMode
            urlPath
          }
          menuItemsGroups {
            id
          }
        }
      }
    }
  }
`

export default GET_RESTAURANT_OUTLETS
