import { Fragment, useState } from 'react'
import { Formik } from 'formik'
import { Spinner, Classes } from '@blueprintjs/core'

import { successToast } from '@utils/toast'
import InnerForm from './InnerForm'
import GET_CAMPAIGN_BY_ID from '@src/pages/Marketing/queries/getCampaignById.query'
import moment from 'moment'
import EDIT_CAMPAIGN from '@src/pages/Marketing/mutations/editCampaign.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import * as Yup from 'yup'
import { editCampaignStructure } from '@src/validation/campaign'
import { useMutation } from '@apollo/client'
import Query from '@src/components/Query/Query'
import config from '@src/config'

const EditCampaign = ({ closeDraw, marketplaceId, id }) => {
  const [loading, setLoading] = useState(false)

  if (loading) return <Spinner />

  const [editCampaign] = useMutation(EDIT_CAMPAIGN, {
    onError: error => {
      setLoading(false)
      defaultErrorHandler(error)
    },
    onCompleted: ({ editCampaign: { campaign } }) => {
      setLoading(false)
      if (campaign.id) successToast(`Saved ${campaign.name}`)
      closeDraw()
    },
  })

  return (
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>
        <Query
          query={GET_CAMPAIGN_BY_ID}
          variables={{ marketplaceId, campaignId: id }}
        >
          {({ data, loading }) => {
            if (loading || !data || !data.getMarketplaces) {
              return <Spinner />
            }
            const campaign = data.getCampaignById.campaign

            campaign.email &&
              campaign.email.emailContent.map(content => {
                if (
                  content.type === 'IMAGE' &&
                  content.image !== undefined &&
                  !content.image.includes('https://')
                ) {
                  content.image = `https://${
                    config.environmentDomain
                  }/${content.image}`
                }
              })

            const marketplace = data.getMarketplaces.regions[0]
            return (
              <Fragment>
                <Formik
                  initialValues={{
                    ...campaign,
                    segment: campaign.segment ? campaign.segment.id : null,
                    startDateTime: moment(campaign.startDateTime).toDate(),
                    push: campaign.push || {
                      action: '',
                      outletId: '',
                      title: '',
                      body: '',
                    },
                    sms: {
                      body:
                        campaign.sms && campaign.sms.body
                          ? campaign.sms.body.split('\nSTOP: ')[0]
                          : '',
                    },
                    marketplaceName: marketplace.name,
                    marketplaceIcon: marketplace.faviconImage,
                    marketplaceEmail: `${
                      marketplace.emailSenderUsername
                    }@${marketplace.cname.trim().replace(/^www\./, '')}`,
                    emailSubject: campaign.email
                      ? campaign.email.emailSubject
                      : '',
                    emailPreviewText: campaign.email
                      ? campaign.email.emailPreviewText
                      : '',
                    emailContent: campaign.email
                      ? campaign.email.emailContent
                      : [],
                  }}
                  onSubmit={({
                    emailSubject,
                    emailPreviewText,
                    emailContent,
                    ...values
                  }) => {
                    setLoading(true)
                    editCampaign({
                      variables: {
                        ...values,
                        emailSubject,
                        emailPreviewText,
                        emailContent,
                        marketplaceId,
                      },
                    })
                  }}
                  validationSchema={Yup.object().shape(editCampaignStructure)}
                  validateOnChange={true}
                >
                  {props => (
                    <InnerForm
                      marketplaceId={marketplaceId}
                      disabled={campaign.status !== 'SCHEDULED'}
                      marketplaceCNAME={marketplace.cname}
                      allowSMSMarketing={marketplace.allowSMSMarketing}
                      {...props}
                    />
                  )}
                </Formik>
              </Fragment>
            )
          }}
        </Query>
      </div>
    </div>
  )
}

export default EditCampaign
