import {
  Checkbox,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from '@blueprintjs/core'
import React, { Fragment } from 'react'

export const ReplyMessageForm = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  customersList,
  isInbox,
  isReplying,
}) => {
  //   last message might be from management so double check if email is not from redbox - receiver is always the client
  const recipient = isInbox
    ? values.from.includes('@messaging')
      ? values.to
      : [values.from]
    : values.to.filter(toEmail => toEmail.includes('@messaging')).length
    ? [values.from]
    : values.to

  return (
    <Fragment>
      {isReplying && values !== null && (
        <form onSubmit={handleSubmit}>
          <FormGroup
            label="Recipient"
            labelFor="recipient"
            helperText={errors.recipient ? errors.recipient : ''}
            intent={errors.recipient ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="recipient"
              id="recipient"
              type="text"
              disabled={true}
              value={
                recipient &&
                recipient.length &&
                recipient.map(email => `${email} `)
              }
              hidden={true}
            />
            <div style={{ marginTop: '10px' }}>
              {recipient &&
                recipient
                  .map(toEmail => {
                    return customersList.find(
                      customer => customer.email === toEmail
                    )
                      ? customersList.find(
                          customer => customer.email === toEmail
                        )
                      : { email: toEmail }
                  })
                  .map(customer => {
                    return customer.id ? (
                      <a
                        href={`/customer/${customer.id}/details`}
                        key={`msg-${customer.id}-link`}
                      >
                        {customer.email}
                      </a>
                    ) : (
                      <span> {customer.email} </span>
                    )
                  })}
            </div>
          </FormGroup>
          <FormGroup
            label="Subject"
            labelFor="subject"
            helperText={errors.subject ? errors.subject : ''}
            intent={errors.subject ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="subject"
              id="subject"
              type="text"
              onChange={handleChange}
              intent={errors.subject ? Intent.DANGER : Intent.NONE}
              value={`RE: ${values.subject}`}
              disabled={true}
            />
          </FormGroup>
          <FormGroup
            label="Body"
            labelFor="content"
            helperText={errors.content ? errors.content : ''}
            intent={errors.content ? Intent.DANGER : Intent.NONE}
          >
            <TextArea
              name="content"
              id="content"
              onChange={handleChange}
              fill={true}
              value={values.content}
              style={{ height: 100 }}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              defaultChecked={values.addMarketplaceTheme}
              onChange={handleChange}
              name="addMarketplaceTheme"
            >
              Add marketplace theme
            </Checkbox>
          </FormGroup>
        </form>
      )}
    </Fragment>
  )
}
