import gql from 'graphql-tag'

const ENROLL_MEMBER_TO_DISCOUNT = gql`
  mutation enrollMemberToDiscount(
    $restaurantId: String!
    $customerEmail: String!
    $discountPercentage: Float!
    $discountName: String!
    $customerFallbackName: String
  ) {
    enrollMemberToDiscount(
      restaurantId: $restaurantId
      customerEmail: $customerEmail
      discountPercentage: $discountPercentage
      discountName: $discountName
      customerFallbackName: $customerFallbackName
    ) {
      message
      discountId
    }
  }
`

export default ENROLL_MEMBER_TO_DISCOUNT
