import React, { Fragment } from 'react'
import Query from '@components/Query/Query'
import { first } from 'lodash'
import { Icon, Switch, Tooltip } from '@blueprintjs/core'
import GET_RESTAURANT_OUTLETS_WITH_CATEGORIES from './queries/getRestaurantOutletsWithCategories.query'
import colors from '../../../styles/colors'

const CategoriesOverrideSwitch = ({ values, handleChange, restaurantId }) => (
  <Query
    query={GET_RESTAURANT_OUTLETS_WITH_CATEGORIES}
    variables={{ id: restaurantId }}
  >
    {({ getRestaurants: { restaurants } }) => {
      const restaurant = first(restaurants)
      const outletsWithNoCategories = restaurant.outlets.filter(
        outlet => outlet.outletCategories.length === 0 && outlet.active
      )

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 20,
            marginBottom: 10,
          }}
        >
          <Switch
            disabled={
              outletsWithNoCategories.length > 0 &&
              !values.outletCategoriesOverride
            }
            label={`Categories`}
            checked={values.outletCategoriesOverride}
            onChange={handleChange}
            name="outletCategoriesOverride"
            id="outletCategoriesOverride"
          />
          {outletsWithNoCategories.length > 0 &&
            !values.outletCategoriesOverride && (
              <Tooltip
                content={
                  <Fragment>
                    Please assign Categories to{' '}
                    {outletsWithNoCategories
                      .map(category => `"${category.name}"`)
                      .join(', ')}{' '}
                    before activating this override.
                  </Fragment>
                }
              >
                <Icon
                  icon="warning-sign"
                  style={{ marginLeft: 5 }}
                  color={colors.infoGrey}
                />
              </Tooltip>
            )}
        </div>
      )
    }}
  </Query>
)

export default CategoriesOverrideSwitch
