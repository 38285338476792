import { get } from 'lodash'

// user agent is not historically standardised hence the need for getters
export const getUserAgentFromOrder = orderUserAgent => {
  if (typeof orderUserAgent === 'string') {
    orderUserAgent = JSON.parse(orderUserAgent)
  }

  let userAgentKey = 'Unkown'
  switch (get(orderUserAgent, 'client.name')) {
    case 'customer-app':
      userAgentKey = get(orderUserAgent, 'os.name', '').concat(
        ' ',
        get(orderUserAgent, 'client.version', 'v.unknown')
      )
      break
    case 'customer-web':
      userAgentKey = 'Website'
      break
    default:
      break
  }

  return userAgentKey
}
