import { Button, ButtonGroup } from '@blueprintjs/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import AcceptNetworkOrderModal from '../AcceptNetworkOrder.modal'
import CancelNetworkOrderModal from '../CancelNetworkOrder.modal'
import DelayNetworkOrderModal from '../DelayNetworkOrder.modal'
import ReadyNetworkOrderModal from '../ReadyNetworkOrder.modal'
import UpdateOrderStatusModal from '../UpdateOrderStatus.modal'

import { canPerformAction } from '@stores/userStore'
import RefundOrderModal from './RefundOrderModal'

interface OrderStatusInfoProps {
  minimal?: boolean
  onReview?: () => void
  order: {
    id: string
    orderStatus?: string
    fulfillmentMethod: string
    grossTotal: number
  }
  refetchQueries?: any[]
  offerToPrintOnSuccess: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 10px;
`

interface StatusOptions {
  offerToPrintOnSuccess?: boolean
  updateFulfilmentTime?: boolean
}

const OrderStatusInfo: React.FC<OrderStatusInfoProps> = ({
  minimal = false,
  onReview,
  order,
  refetchQueries,
  offerToPrintOnSuccess = false,
}) => {
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [openModalName, setOpenModalName] = useState<string | null>(null)
  const [newStatus, setNewStatus] = useState<string | null>(null)
  const [updateOrderOptions, setUpdateOrderOptions] = useState<
    StatusOptions | undefined
  >({})

  const updateOrderStatus = (status: string, statusOptions?: StatusOptions) => {
    setOpenModalName('UPDATE_ORDER_STATUS')
    setNewStatus(status)
    setUpdateOrderOptions(statusOptions)
  }

  const { id, orderStatus, fulfillmentMethod } = order

  return (
    <Container>
      {['COMPLETE'].includes(orderStatus) ? (
        <ButtonGroup>
          {canPerformAction('refund') && (
            <Button
              icon="undo"
              intent="danger"
              onClick={() => setShowRefundModal(true)}
            >
              Refund
            </Button>
          )}
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          {['PENDING'].includes(orderStatus) ? (
            <Button
              icon="tick"
              intent="success"
              minimal={minimal}
              onClick={() =>
                fulfillmentMethod === 'NETWORK'
                  ? setOpenModalName('ACCEPT_NETWORK_ORDER')
                  : updateOrderStatus('PREPARING', {
                      offerToPrintOnSuccess,
                      updateFulfilmentTime: true,
                    })
              }
              data-test-id="order-details-accept-button"
            >
              Accept
            </Button>
          ) : (
            !minimal && <Button disabled={true}>Accept</Button>
          )}
          {['PENDING'].includes(orderStatus) ? (
            <Button
              icon="cross"
              intent="danger"
              minimal={minimal}
              onClick={() => updateOrderStatus('REJECTED')}
              data-test-id="order-details-reject-button"
            >
              Reject
            </Button>
          ) : (
            !minimal && <Button disabled={true}>Reject</Button>
          )}
          {fulfillmentMethod !== 'TABLE' &&
            ['PREPARING'].includes(orderStatus) && (
              <Button
                icon="arrow-right"
                minimal={minimal}
                disabled={fulfillmentMethod === 'NETWORK'}
                onClick={() =>
                  updateOrderStatus('PREPARING', {
                    updateFulfilmentTime: true,
                  })
                }
              >
                {!minimal && 'Add Time'}
              </Button>
            )}
          {['PREPARING', 'READY'].includes(orderStatus) ? (
            <Button
              icon="undo"
              intent="warning"
              minimal={minimal}
              onClick={() =>
                fulfillmentMethod === 'NETWORK'
                  ? setOpenModalName('CANCEL_NETWORK_ORDER')
                  : updateOrderStatus('ORDER_FAILURE_REFUND')
              }
              data-test-id="order-details-cancel-button"
            >
              Cancel
            </Button>
          ) : (
            !minimal && <Button disabled={true}>Cancel</Button>
          )}
          {['PREPARING'].includes(orderStatus) ? (
            <Button
              icon="tick"
              intent="primary"
              minimal={minimal}
              onClick={() =>
                fulfillmentMethod === 'NETWORK'
                  ? setOpenModalName('READY_NETWORK_ORDER')
                  : updateOrderStatus('READY')
              }
              data-test-id="order-details-ready-button"
            >
              Ready
            </Button>
          ) : (
            !minimal && <Button disabled={true}>Ready</Button>
          )}
          {['READY'].includes(orderStatus) ? (
            <Button
              icon="tick-circle"
              intent="success"
              minimal={minimal}
              onClick={() => updateOrderStatus('COMPLETE')}
              data-test-id="order-details-complete-button"
            >
              Complete
            </Button>
          ) : (
            !minimal && <Button disabled={true}>Complete</Button>
          )}
          {onReview && (
            <Button
              minimal
              icon="document-open"
              intent="none"
              onClick={onReview}
              data-test-id="order-details-view-button"
            >
              Review
            </Button>
          )}
        </ButtonGroup>
      )}

      <AcceptNetworkOrderModal
        modalOpen={openModalName === 'ACCEPT_NETWORK_ORDER'}
        modalClose={() => setOpenModalName(null)}
        orderId={id}
        refetchQueries={refetchQueries}
      />

      <DelayNetworkOrderModal
        modalOpen={openModalName === 'DELAY_NETWORK_ORDER'}
        modalClose={() => setOpenModalName(null)}
        orderId={id}
        refetchQueries={refetchQueries}
      />

      <CancelNetworkOrderModal
        modalOpen={openModalName === 'CANCEL_NETWORK_ORDER'}
        modalClose={() => setOpenModalName(null)}
        orderId={id}
        refetchQueries={refetchQueries}
      />

      <ReadyNetworkOrderModal
        modalOpen={openModalName === 'READY_NETWORK_ORDER'}
        modalClose={() => setOpenModalName(null)}
        orderId={id}
        refetchQueries={refetchQueries}
      />

      <UpdateOrderStatusModal
        modalOpen={openModalName === 'UPDATE_ORDER_STATUS'}
        modalClose={() => {
          setOpenModalName(null)
          setNewStatus(null)
          setUpdateOrderOptions({})
        }}
        newOrderStatus={newStatus}
        updateOrderOptions={updateOrderOptions}
        orderId={id}
        refetchQueries={refetchQueries}
      />

      <RefundOrderModal
        orderId={order.id}
        grossTotal={order.grossTotal}
        modalOpen={showRefundModal}
        modalClose={() => setShowRefundModal(false)}
      />
    </Container>
  )
}

export default OrderStatusInfo
