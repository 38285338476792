import React from 'react'
import { Formik } from 'formik'
import { array, bool, func } from 'prop-types'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import PaymentMethodField from '@components/PaymentMethodField/PaymentMethodField'
import { string } from 'prop-types'

const MarketplacePaymentMethodsForm = ({
  stripeDirectPayment = false,
  paymentMethods,
  cashText,
  cashCollectionText,
  onSubmit,
}) => (
  <Formik
    initialValues={{ paymentMethods, cashText, cashCollectionText }}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, handleChange, values }) => (
      <form onSubmit={handleSubmit}>
        <PaymentMethodField values={values} disableCash={stripeDirectPayment} />
        <FormGroup
          label="Cash Description"
          labelFor="cashText"
          helperText="Descriptive name shown to the customer at checkout."
        >
          <InputGroup
            name="cashText"
            id="cashText"
            type="text"
            value={values.cashText}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup
          label="Cash on Collection Description"
          labelFor="cashCollectionText"
          helperText="Descriptive name shown to the customer at checkout."
        >
          <InputGroup
            name="cashCollectionText"
            id="cashCollectionText"
            type="text"
            value={values.cashCollectionText}
            onChange={handleChange}
          />
        </FormGroup>
        <div className="bp-card-footer-actions">
          <Button text="Save" type="submit" />
        </div>
      </form>
    )}
  </Formik>
)

MarketplacePaymentMethodsForm.propTypes = {
  stripeDirectPayment: bool,
  paymentMethods: array,
  cashText: string,
  cashCollectionText: string,
  onSubmit: func,
}

export default MarketplacePaymentMethodsForm
