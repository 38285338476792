import gql from 'graphql-tag'

const GET_TRANSACTION_AGGREGATES = gql`
  query getTransactionAggregates(
    $startDate: DateTime!
    $endDate: DateTime!
    $marketplaceIds: [ID!]
  ) {
    transactionAggregates(
      startDate: $startDate
      endDate: $endDate
      marketplaceIds: $marketplaceIds
    ) {
      id
      aggregateStart
      totalCardOrders
      totalCardValueGrossExcludingServiceCharge
      totalCashOrders
      totalCashValueGrossExcludingServiceCharge
      totalDiscounts
      totalOrdersNetAfterDiscounts
      totalOrdersGrossAfterDiscount
      totalOwnDeliveryCharges
      totalNetworkDeliveryCharges
      totalToRestaurant
      serviceChargeAggregates
      vatAggregates
      marketplaceFeeAndCommissionChargeAggregates
      totalFeesAndChargesNet
      totalFeesAndChargesGross
      invoices {
        id
        key
        emailStatus
        isDuplicate
        isVoid
      }
      outlet {
        id
        isOnline
        name
        emailInvoices
        marketplace {
          name
        }
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_TRANSACTION_AGGREGATES
