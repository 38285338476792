import gql from 'graphql-tag'

const EDIT_MENU_ITEM_PARENT_ID = gql`
  mutation editMenuItemParentId($id: String!, $menuItemGroupIds: [String]) {
    editMenuItem(id: $id, menuItemGroupIds: $menuItemGroupIds) {
      message
      menuItem {
        id
        name
        position
        parentMenus {
          id
        }
      }
    }
  }
`

export default EDIT_MENU_ITEM_PARENT_ID
