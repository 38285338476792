import * as yup from 'yup'

export const addOptionStructure = {
  name: yup
    .string()
    .required('Please enter the name of the option group')
    .min(3, 'Option group name should be longer than 3 chars'),
  required: yup.string().oneOf(['optional', 'required']),
  optionItems: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter an option item'),
      price: yup.string().required('Please enter the price of the option item'),
      soldOut: yup.bool(),
    })
  ),
  optionListIds: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Please enter an option item'),
        price: yup
          .string()
          .required('Please enter the price of the option item'),
      })
    )
    .nullable()
    .notRequired(),
  minOptions: yup.number().when('required', ([req], schema) => {
    if (req === 'required') {
      return schema.min(
        1,
        'If item is required, minimum items must be 1 or greater'
      )
    }
  }),
  maxOptions: yup
    .number()
    .min(1, 'Maximum items must be 1 or greater')
    .required(
      'Please enter the maximum number of options a customer can select'
    ),
  parentMenuId: yup
    .string()
    .min(25, 'Parent menu id should be 25 chars long')
    .max(25, 'Parent menu id should be 25 chars long'),
}

export const editOptionStructure = {
  name: yup.string().min(3, 'Option group name should be longer than 3 chars'),
  required: yup.string().oneOf(['optional', 'required']),
  optionItems: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter an option item'),
      price: yup.string().required('Please enter the price of the option item'),
    })
  ),
  optionListIds: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Please enter an option item'),
        price: yup
          .string()
          .required('Please enter the price of the option item'),
      })
    )
    .nullable()
    .notRequired(),
  minOptions: yup.number().when('required', ([req], schema) => {
    if (req === 'required') {
      return schema.min(
        1,
        'If item is required, minimum items must be 1 or greater'
      )
    }
  }),
  maxOptions: yup
    .number()
    .required(
      'Please enter the maximum number of options a customer can select'
    ),
  parentMenuId: yup
    .string()
    .min(25, 'Parent menu id should be 25 chars long')
    .max(25, 'Parent menu id should be 25 chars long'),
}
