import React, { Fragment, useEffect, useState } from 'react'
import { Tree, Card, Icon, Callout, Button } from '@blueprintjs/core'
import { swapAdjacentArrayItems } from '@utils/swapAdjacentArrayItems'
import CardSelect from './CardSelect'
import { isEqual } from 'lodash'

export const LinkCardsList = ({
  selectedCards,
  allCards,
  setFieldValue,
  outletLimit,
  setCardToEditId,
  linkCardOrderBy,
}) => {
  const [cardsState, setCardsState] = useState(() => {
    return generateCardState(selectedCards)
  })

  useEffect(() => {
    const filteredCards = cardsState
      .filter(card => !card.disabled)
      .map(({ id, label, position }) => ({
        id,
        name: label,
        position,
      }))

    setFieldValue('linkCards', filteredCards)
  }, [cardsState, setFieldValue])

  useEffect(() => {
    const cardsWithOutletPlaceholders = generateCardState(selectedCards)

    if (!arraysEqual(cardsState, cardsWithOutletPlaceholders)) {
      setCardsState(cardsWithOutletPlaceholders)
    }
  }, [selectedCards, linkCardOrderBy, outletLimit])

  function generateCardState(cards) {
    if (!cards.length) return []

    return addDefaultOutletObjects(cards, outletLimit, linkCardOrderBy).map(
      (card, index) => ({
        id: card.id || `cardIndex${index}`,
        disabled: !card.id,
        label: card.name,
        position: card.position,
      })
    )
  }

  function arraysEqual(a, b) {
    if (a.length !== b.length) return false

    return a.every((element, index) => isEqual(element, b[index]))
  }

  function removeCard(index) {
    const newState = [...cardsState]
    newState.splice(index, 1)
    setCardsState(newState)
  }

  function addDefaultOutletObjects(linkCards, outletLimit, linkCardOrderBy) {
    if (linkCardOrderBy === 'RANDOM') {
      return linkCards
    }
    const defaultObject = { name: 'Outlet', disabled: true }

    const filteredLinkCards = linkCards
      .map(card => ({
        ...card,
        position: Number.isInteger(card.position) ? card.position : outletLimit,
      }))
      .filter(card => !card.disabled)

    const positions = new Set(filteredLinkCards.map(item => item.position))

    const linkCardsWithDefaultObjects = [...filteredLinkCards]

    for (let i = 0; linkCardsWithDefaultObjects.length < outletLimit; i++) {
      if (
        linkCardsWithDefaultObjects.length <= outletLimit &&
        !positions.has(i)
      ) {
        linkCardsWithDefaultObjects.push({ ...defaultObject, position: i })
      }
    }

    linkCardsWithDefaultObjects.sort((a, b) => a.position - b.position)

    //fix positions before returning
    return linkCardsWithDefaultObjects.map((linkCard, index) => ({
      ...linkCard,
      position: index,
    }))
  }

  const handleChangeIndex = (index, items, direction) => {
    const reorderedOptions = swapAdjacentArrayItems(
      items,
      index,
      direction
    ).map((item, index) => ({ ...item, position: index }))
    setCardsState(reorderedOptions)
  }

  return (
    <Fragment>
      {(cardsState && (
        <Card className="bp5-nopad">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 6 }}>
              <Tree contents={cardsState} />
            </div>
            <div style={{ flex: 2, textAlign: 'right' }}>
              {cardsState.map((selectedCard, index) => (
                <div key={selectedCard.id}>
                  <Button
                    disabled={selectedCard.disabled}
                    icon="edit"
                    minimal
                    onClick={() => {
                      setCardToEditId(selectedCard.id)
                    }}
                  />
                  <Button
                    disabled={
                      index === 0 ||
                      selectedCard.disabled ||
                      linkCardOrderBy === 'RANDOM'
                    }
                    icon="chevron-up"
                    onClick={() => {
                      handleChangeIndex(index, cardsState, 'up')
                    }}
                    minimal
                  />
                  <Button
                    disabled={
                      index === cardsState.length - 1 ||
                      selectedCard.disabled ||
                      linkCardOrderBy === 'RANDOM'
                    }
                    icon="chevron-down"
                    onClick={() => {
                      handleChangeIndex(index, cardsState, 'down')
                    }}
                    minimal
                  />
                  <Button
                    icon="trash"
                    onClick={() => {
                      removeCard(index)
                    }}
                    disabled={selectedCard.disabled}
                    minimal
                  />
                </div>
              ))}
            </div>
          </div>
        </Card>
      )) || (
        <Fragment>
          <Callout>
            <Icon icon={'properties'} /> Set up options for this item so the
            order can be customised.
          </Callout>
          <br />
        </Fragment>
      )}
      <CardSelect
        values={cardsState}
        optionList={
          // only show options not already selected
          (allCards || []).filter(
            linkCard =>
              !(cardsState || []).some(
                linkCardOption => linkCardOption.id === linkCard.id
              )
          )
        }
        setCardsState={setCardsState}
      />
    </Fragment>
  )
}
