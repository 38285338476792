import gql from 'graphql-tag'

const GET_SAME_WINDOW_ORDERS_COUNT = gql`
  query getSameWindowOrdersCount($orderId: String!) {
    getSameWindowOrdersCount(orderId: $orderId) {
      count
      limit
    }
  }
`

export default GET_SAME_WINDOW_ORDERS_COUNT
