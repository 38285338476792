import { Callout, Intent } from '@blueprintjs/core'
import { Fragment, useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { templateVars } from '../../utils/dynamicTemplateVars'
export const emailConditionalsRegex =
  /^and .*|^else.*|^\/.*|^eq .*|^#.*|^gt .*|^gte .*|^if .*|^lt .*|^lte .*|^neg .*|^not .*|^or .*/

const TemplateVariableValidator = ({
  emailContent,
  setTemplateVarErrors,
  templateVarErrors,
}) => {
  const [viewTemplate] = useQueryParam('viewTemplate', StringParam)

  useEffect(() => {
    const validateTemplateVariables = (emailContent, viewTemplate) => {
      if (viewTemplate && emailContent.length > 0) {
        const variablesUsed = []
        const regExp = /{{ *([^}]+) *}}/g
        emailContent.map(emailContent => {
          let result
          while ((result = regExp.exec(emailContent.text))) {
            variablesUsed.push(result[1])
          }
        })

        const doesntExist = []
        variablesUsed.map(varUsed => {
          const conditionalsFound = new RegExp(emailConditionalsRegex).test(
            varUsed.trim()
          )
          if (
            !templateVars[viewTemplate]?.includes(varUsed.trim()) &&
            !conditionalsFound
          ) {
            doesntExist.push(varUsed)
          }
        })
        setTemplateVarErrors(doesntExist)
      }
    }

    validateTemplateVariables(emailContent, viewTemplate)
  }, [emailContent, viewTemplate])

  return (
    <Fragment>
      {viewTemplate && templateVarErrors?.length > 0 && (
        <Callout intent={Intent.DANGER}>
          {templateVarErrors.length > 0 && (
            <Fragment>
              <h4>The following variables do not exist:</h4>
              {templateVarErrors.map((err, idx) => {
                return (
                  <p key={`varerr${idx}`}>
                    <strong>{err}</strong>
                  </p>
                )
              })}
            </Fragment>
          )}
        </Callout>
      )}
    </Fragment>
  )
}

export default TemplateVariableValidator
