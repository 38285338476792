import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMarketplaceNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetMarketplaceNameQuery = { __typename?: 'Query', getMarketplaces: { __typename?: 'GetRegionsResponse', regions: Array<{ __typename?: 'RegionalMarketplace', id: string, name: string }> } };


export const GetMarketplaceNameDocument = gql`
    query getMarketplaceName($id: ID!) {
  getMarketplaces(id: $id) {
    regions {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMarketplaceNameQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMarketplaceNameQuery(baseOptions: Apollo.QueryHookOptions<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables> & ({ variables: GetMarketplaceNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>(GetMarketplaceNameDocument, options);
      }
export function useGetMarketplaceNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>(GetMarketplaceNameDocument, options);
        }
export function useGetMarketplaceNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>(GetMarketplaceNameDocument, options);
        }
export type GetMarketplaceNameQueryHookResult = ReturnType<typeof useGetMarketplaceNameQuery>;
export type GetMarketplaceNameLazyQueryHookResult = ReturnType<typeof useGetMarketplaceNameLazyQuery>;
export type GetMarketplaceNameSuspenseQueryHookResult = ReturnType<typeof useGetMarketplaceNameSuspenseQuery>;
export type GetMarketplaceNameQueryResult = Apollo.QueryResult<GetMarketplaceNameQuery, GetMarketplaceNameQueryVariables>;