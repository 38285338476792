import gql from 'graphql-tag'

const CREATE_VOUCHERS = gql`
  mutation createVouchers(
    $marketplaceId: String!
    $key: String
    $howMany: Int!
    $usageLimit: Int!
    $onePerCustomer: Boolean!
    $active: Boolean!
    $allowedOutletIds: [String!]!
    $discountName: String!
    $discountPercentage: Float
    $discountAmount: Int
    $startDate: DateTime
    $endDate: DateTime
    $daysOfWeek: [Int]
    $minimumSubtotalGross: Int
  ) {
    createVouchers(
      marketplaceId: $marketplaceId
      key: $key
      howMany: $howMany
      usageLimit: $usageLimit
      onePerCustomer: $onePerCustomer
      active: $active
      allowedOutletIds: $allowedOutletIds
      discountName: $discountName
      discountPercentage: $discountPercentage
      discountAmount: $discountAmount
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
      minimumSubtotalGross: $minimumSubtotalGross
    ) {
      count
    }
  }
`

export default CREATE_VOUCHERS
