import React, { Fragment } from 'react'
import {
  Card,
  Button,
  HTMLTable,
  Icon,
  NonIdealState,
  H5,
  Tag,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'
import GET_MARKETPLACE from '@components/Marketplace/Details/queries/getMarketplace.query'
import GET_STRIPECONNECTACCOUNTS from '@components/Platform/queries/getStripeConnectAccounts.query'
import ARCHIVE_STRIPECONNECTACCOUNT from '@components/Platform/mutations/archiveStripeConnectAccount.mutation'

import { successToast } from '@utils/toast'
import get from 'lodash/get'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { canPerformMutation } from '@stores/userStore'
import CopyText from '@components/CopyText/CopyText'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import DELETE_PLATFORM_WEBHOOKS from '@components/Platform/mutations/deleteWebhooks.mutation'
import RESET_PLATFORM_WEBHOOKS from '@components/Platform/mutations/resetWebhooks.mutation'
import { WEBHOOK_STATUS_TO_INTENT } from '../../../utils/stripe'
import { useMutation } from '@apollo/client/react'

const FinancialSettings = () => {
  const [setPlatformPaymentWebhooks] = useMutation(RESET_PLATFORM_WEBHOOKS, {
    onCompleted: ({ setPlatformPaymentWebhooks }) => {
      successToast(setPlatformPaymentWebhooks.message)
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      {
        query: GET_STRIPECONNECTACCOUNTS,
      },
    ],
  })

  const [deletePlatformPaymentWebhooks] = useMutation(
    DELETE_PLATFORM_WEBHOOKS,
    {
      onCompleted: ({ deletePlatformPaymentWebhooks }) => {
        successToast(deletePlatformPaymentWebhooks.message)
      },
      onError: defaultErrorHandler,
      refetchQueries: [
        {
          query: GET_STRIPECONNECTACCOUNTS,
        },
      ],
    }
  )

  const [deleteStripeConnectAccount, { loading }] = useMutation(
    ARCHIVE_STRIPECONNECTACCOUNT,
    {
      awaitRefetchQueries: true,
      onError: defaultErrorHandler,
      onCompleted: ({ archiveStripeConnectAccount }) => {
        successToast(archiveStripeConnectAccount.message)
      },
    }
  )

  return (
    <Query
      query={GET_STRIPECONNECTACCOUNTS}
      loaderTitle="Loading Connected Accounts"
    >
      {({
        getStripeConnectAccounts: { accounts },
        getPlatform: { platform },
      }) => {
        if (accounts.length) {
          return (
            <Fragment>
              {platform.stripePlatformWebhooks === null ? (
                <Card>
                  <NonIdealState
                    title="Platform Webhook Setup Required"
                    icon="offline"
                    description="You must setup webhooks to process payments."
                    action={
                      <Button
                        type="button"
                        onClick={() =>
                          setPlatformPaymentWebhooks({
                            variables: { paybox: false },
                          })
                        }
                      >
                        Setup Webhooks
                      </Button>
                    }
                  />
                </Card>
              ) : (
                <Fragment>
                  <H5>Platform Incoming Webhooks</H5>
                  <Card className="bp5-nopad">
                    <HTMLTable>
                      <thead>
                        <tr>
                          <th style={{ width: 200 }}>Event</th>
                          <th>URL</th>
                          <th style={{ width: 10 }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {platform.stripePlatformWebhooks.map(
                          ({ id, description, url, status }) => (
                            <tr key={id}>
                              <td className="bp5-no-break">{description}</td>
                              <td>
                                <CopyText minimal mono text={url} fill />
                              </td>
                              <td>
                                <Tag
                                  minimal
                                  intent={WEBHOOK_STATUS_TO_INTENT[status]}
                                >
                                  {status}
                                </Tag>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                    <div className="bp-card-footer-actions">
                      <ConfirmationPopover
                        remove={() =>
                          setPlatformPaymentWebhooks({
                            variables: { paybox: false },
                          })
                        }
                        intent="warning"
                        buttonTitle="Reset Webhooks"
                        confirmationText="Are you sure you want to reset webhooks, this may cause temporary issues processing payments until they are recreated?"
                      >
                        <Button type="button">Reset Webhooks</Button>
                      </ConfirmationPopover>

                      <ConfirmationPopover
                        remove={() =>
                          deletePlatformPaymentWebhooks({
                            variables: { paybox: false },
                          })
                        }
                        buttonTitle="Delete All"
                        confirmationText="We will only delete Redbox webhooks, this will stop payments processing normally. Are you sure?"
                      >
                        <Button type="button" intent="danger" minimal>
                          Delete
                        </Button>
                      </ConfirmationPopover>
                    </div>
                  </Card>
                  <br />
                </Fragment>
              )}

              {platform.stripePayboxWebhooks === null ? (
                <Card>
                  <NonIdealState
                    title="Paybox Webhook Setup Required"
                    icon="offline"
                    description="You must setup webhooks to process payments."
                    action={
                      <Button
                        type="button"
                        onClick={() =>
                          setPlatformPaymentWebhooks({
                            variables: { paybox: true },
                          })
                        }
                      >
                        Setup Webhooks
                      </Button>
                    }
                  />
                </Card>
              ) : (
                <Fragment>
                  <H5>Paybox Incoming Webhooks</H5>
                  <Card className="bp5-nopad">
                    <HTMLTable>
                      <thead>
                        <tr>
                          <th style={{ width: 200 }}>Event</th>
                          <th>URL</th>
                          <th style={{ width: 10 }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {platform.stripePayboxWebhooks.map(
                          ({ id, description, url, status }) => (
                            <tr key={id}>
                              <td className="bp5-no-break">{description}</td>
                              <td>
                                <CopyText minimal mono text={url} />
                              </td>
                              <td>
                                <Tag
                                  minimal
                                  intent={WEBHOOK_STATUS_TO_INTENT[status]}
                                >
                                  {status}
                                </Tag>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                    <div className="bp-card-footer-actions">
                      <ConfirmationPopover
                        remove={() =>
                          setPlatformPaymentWebhooks({
                            variables: { paybox: true },
                          })
                        }
                        intent="warning"
                        buttonTitle="Reset Webhooks"
                        confirmationText="Are you sure you want to reset webhooks, this may cause temporary issues processing payments until they are recreated?"
                      >
                        <Button type="button">Reset Webhooks</Button>
                      </ConfirmationPopover>

                      <ConfirmationPopover
                        remove={() =>
                          deletePlatformPaymentWebhooks({
                            variables: { paybox: true },
                          })
                        }
                        buttonTitle="Delete All"
                        confirmationText="We will only delete Redbox webhooks, this will stop payments processing normally. Are you sure?"
                      >
                        <Button type="button" intent="danger" minimal>
                          Delete
                        </Button>
                      </ConfirmationPopover>
                    </div>
                  </Card>

                  <br />
                </Fragment>
              )}
              <H5>Standard Connect Accounts</H5>
              <Card className="bp5-nopad">
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      {[
                        { key: 'displayName', content: 'Stripe Connect' },
                        { key: 'defaultCurrency', content: 'Currency' },
                        { key: 'country', content: 'Country' },
                        { key: 'marketPlace', content: 'Marketplace' },
                        { key: 'chargeEnabled', content: 'Charges' },
                        { key: 'transferEnabled', content: 'Transfers' },
                      ].map(({ key, content }) => (
                        <th key={key}>{content}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.map(
                      ({
                        id,
                        chargeEnabled,
                        transferEnabled,
                        country,
                        displayName,
                        defaultCurrency,
                        marketplace,
                      }) => (
                        <tr key={id}>
                          <td>{displayName}</td>
                          <td>{defaultCurrency}</td>
                          <td>{country}</td>
                          <td>
                            {get(marketplace, 'name', <Icon icon="error" />)}
                          </td>
                          <td>
                            <Icon
                              icon={
                                chargeEnabled ? 'small-tick' : 'small-cross'
                              }
                            />
                          </td>
                          <td>
                            <Icon
                              icon={
                                transferEnabled ? 'small-tick' : 'small-cross'
                              }
                            />
                          </td>

                          <td>
                            {marketplace && (
                              <Button
                                icon="cross"
                                text="Disconnect"
                                small="true"
                                minimal="true"
                                intent="DANGER"
                                loading={loading}
                                disabled={
                                  !canPerformMutation(
                                    'archiveStripeConnectAccount'
                                  )
                                }
                                onClick={() => {
                                  deleteStripeConnectAccount({
                                    variables: { id },
                                    refetchQueries: [
                                      {
                                        query: GET_STRIPECONNECTACCOUNTS,
                                      },
                                      {
                                        query: GET_MARKETPLACE,
                                        variables: {
                                          id: get(marketplace, 'id', ''),
                                        },
                                      },
                                    ],
                                  })
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HTMLTable>
              </Card>
            </Fragment>
          )
        } else {
          return (
            <NonIdealState
              icon="search"
              title="No Stripe Connections"
              description="Connect markeplaces to stripe and they will show here."
            />
          )
        }
      }}
    </Query>
  )
}
export default FinancialSettings
