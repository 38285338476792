import React, { Fragment } from 'react'
import CostInput from './CostInput'
import RadiusInput from './RadiusInput'

const RadiusAroundOutletFormGroup = formikProps => (
  <Fragment>
    <RadiusInput {...formikProps} />
    <CostInput {...formikProps} />
  </Fragment>
)
RadiusAroundOutletFormGroup.propTypes = {}

export default RadiusAroundOutletFormGroup
