import gql from 'graphql-tag'

const GET_OUTLET_FINANCIALS = gql`
  query getOutletFinancials($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        marketplace {
          id
          stripeOnboarding
          name
          partner {
            id
            stripeShowDashboardOutlet
          }
        }
        restaurant {
          id
          name
        }
        contactName
        contactPhone
        contactEmail
        companyType
        companyLegalName
        companyNumber
        contactAddress {
          id
          firstLine
          secondLine
          postcode
          city
          country
        }
        stripeCustomerPayboxId
        stripeId
        stripeAccountSession {
          clientSecret
          clientPublicKey
        }
        stripeConnect {
          chargesEnabled
          payoutsEnabled
          payoutSchedule
          balancePending
          balance
          accounts
          dashboardLink
        }
        vatNumber
        isVATregistered
        stripeLivemode
      }
    }
  }
`
export default GET_OUTLET_FINANCIALS
