import React, { useState } from 'react'
import { MultiSelect } from '@blueprintjs/select'
import {
  Checkbox,
  Drawer,
  HTMLSelect,
  InputGroup,
  TextArea,
  FormGroup,
  MenuItem,
  Tag,
  Tooltip,
  Button,
  Intent,
} from '@blueprintjs/core'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import NumericInput from '@components/NumericInput/NumericInput'
import styled from 'styled-components'

const DrawerFormGroup = styled(FormGroup)`
  padding: 24px;
`

const DrawerFieldTextArea = styled(TextArea)`
  min-height: 400px;
`

const DrawerFooter = styled.div`
  left: 0;
`

const DrawerFooterSaveButton = styled(Button)`
  margin-left: auto;
`

const FieldError: React.FC<{
  children: React.ReactNode
  error: string
}> = ({ children, error }) => {
  return (
    <Tooltip content={error} intent="danger">
      <div style={{ border: '1px solid #db3737' }}>{children}</div>
    </Tooltip>
  )
}

const Field: React.FC<{
  row: any // for now
  name: string
  field: any // for now
  handleChange: (e: any) => void
  options: any // for now
}> = ({ row, name, field, handleChange, options }) => {
  const fieldValue = row.importItem[field.id]

  const [drawerOpen, setDrawerOpen] = useState(false)

  if (name === 'importItem.spiceLevel') {
    const spiceLevelOptions = [0, 1, 2, 3].map(level => ({
      value: level,
      label: String(level),
    }))

    return (
      <HTMLSelect
        name={name}
        value={fieldValue || ''}
        onChange={event => {
          handleChange({
            target: {
              name,
              type: 'number',
              value: Number(event.currentTarget.value),
            },
          })
        }}
        options={spiceLevelOptions}
        minimal
        fill
      />
    )
  }
  switch (field.type) {
    case 'number':
      return (
        <NumericInput
          name={name}
          defaultValue={fieldValue}
          minimal
          style={{ width: field.width }}
          onUpdate={(value: string) => {
            handleChange({
              target: { name, type: 'number', value: Number(value) },
            })
          }}
          fill
        />
      )
    case 'currency':
      return (
        <CurrencyInput
          name={name}
          defaultValue={fieldValue}
          minimal
          style={{ width: field.width }}
          onUpdate={(value: string) => {
            handleChange({
              target: { name, type: 'currency', value: Number(value) },
            })
          }}
          fill
        />
      )

    case 'text':
      return (
        <>
          <div
            onClick={() => {
              if (field.drawer) {
                setDrawerOpen(true)
              }
            }}
          >
            <InputGroup
              name={name}
              value={fieldValue || ''}
              onChange={handleChange}
              className={'bp5-minimal'}
              style={{ width: field.width }}
            />
          </div>

          <Drawer
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            size="640px"
            title="Edit menu item description"
          >
            <DrawerFormGroup label={field.label} labelFor={field.id}>
              <DrawerFieldTextArea
                id={field.id}
                name={name}
                value={fieldValue || ''}
                onChange={handleChange}
                fill
              />
            </DrawerFormGroup>

            <DrawerFooter className="bp5-drawer-footer-actions">
              <DrawerFooterSaveButton
                onClick={() => setDrawerOpen(false)}
                intent={Intent.NONE}
                text="Save"
              />
            </DrawerFooter>
          </Drawer>
        </>
      )
    case 'bool':
      return (
        <Checkbox name={name} checked={fieldValue} onChange={handleChange} />
      )
    case 'selectSingle':
      if (options && options.length > 0) {
        return (
          <HTMLSelect
            name={name}
            value={fieldValue || ''}
            onChange={handleChange}
            style={{ width: field.width }}
            minimal
            options={options}
          />
        )
      } else {
        return (
          <Tag intent="warning" minimal>
            No Options Available
          </Tag>
        )
      }
    case 'selectMultiple':
      if (options && options.length > 0) {
        const OptionRenderer = (option, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={option.id}
              text={option.label}
              active={modifiers.active}
              disabled={modifiers.disabled}
              onClick={handleClick}
            />
          )
        }

        const selectedItems = fieldValue
          ? fieldValue
              .split('|')
              .map(selectedItemId =>
                options.find(option => option.id === selectedItemId)
              )
              .filter(Boolean)
          : []

        return (
          <MultiSelect
            placeholder="No options"
            className="bp5-minimal"
            selectedItems={selectedItems}
            onItemSelect={item => {
              const value = [
                ...selectedItems.map(({ id }) => id),
                item.id,
              ].join('|')

              handleChange({
                target: {
                  name,
                  type: 'multi-select',
                  value,
                },
              })
            }}
            items={options}
            itemRenderer={OptionRenderer}
            itemDisabled={item => fieldValue && fieldValue.includes(item.id)}
            tagRenderer={item => item.label}
            tagInputProps={{
              tagProps: { minimal: true },
              onRemove: item => {
                const value = selectedItems
                  .filter(({ label }) => label !== item)
                  .map(({ id }) => id)
                  .join('|')

                handleChange({
                  target: {
                    name,
                    type: 'multi-select',
                    value,
                  },
                })
              },
            }}
            fill
          />
        )
      } else {
        return (
          <Tag intent="warning" minimal>
            No Options Available
          </Tag>
        )
      }

    default:
      return null
  }
}

export const FieldSelector = ({ field, handleChange, row, error, options }) => {
  const name = `importItem.${field.id}`

  const fieldProps = {
    row,
    error,
    name,
    field,
    handleChange,
    options,
  }

  const fieldError = error && error.importItem[field.id]
  if (fieldError) {
    return (
      <FieldError error={fieldError}>
        <Field {...fieldProps} />
      </FieldError>
    )
  }

  return <Field {...fieldProps} />
}
