const darkColors = {
  // ********************************
  // NavBar
  // ********************************
  headerBgColor: '#1C242B !important',
  primaryNavColor: '#A7B6C2 !important',
  primaryNavActiveColor: '#FF3B4C !important',
  primaryNavHoverColor: '#FF3B4C !important',
  inputBg: 'rgba(16, 22, 26, 0.3) !important',
  menuItemColor: '#394b59',
}

export default darkColors
