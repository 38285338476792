import React, { Fragment } from 'react'
import CostInput from './CostInput'

const CoordinatePolygonFormGroup = formikProps => (
  <Fragment>
    <CostInput {...formikProps} />
  </Fragment>
)
CoordinatePolygonFormGroup.propTypes = {}

export default CoordinatePolygonFormGroup
