export const OrderStatus = {
  // Awaiting Payment statuses
  awaiting_payment: {
    title: 'Awaiting Payment',
    details:
      'The order is awaiting payment from the customer. The payment has not yet been received, and the order cannot proceed until payment is confirmed.',
  },
  auth_action_pending: {
    tag: 'AUTHORISE',
    title: 'Payment Authorisation',
    details:
      'Payment requires Strong Customer Authentication (SCA), an enhanced security process that requires the customer to provide additional verification, biometric data or 2 factor authentication. \n\n This process is triggered for payments over £30 or when mandated by the bank as part of their fraud prevention measures. The SCA ensures that the person initiating the transaction is the legitimate cardholder, thereby enhancing the security of online payments.',
  },
  auth_action_failure: {
    tag: 'UNAUTHORISED',
    title: 'Payment Unauthorised',
    intent: 'danger',
    details:
      'The authorisation action for the payment has failed. This failure could be due to several reasons, incorrect payment details, or the failure to complete the required Strong Customer Authentication (SCA) process. Additionally, the failure might be caused by the bank declining the transaction due to suspected fraudulent activity or other security concerns. Customers should verify their payment information and try again or contact their bank for further assistance.',
  },
  awaiting_friendly_order_number_assignment: {
    tag: 'Processing',
    title: 'Awaiting Order Number Assignment',
    intent: 'info',
    details:
      'The order is waiting for a friendly order number to be assigned. This is typically a user-friendly identifier used for tracking and reference purposes.',
  },
  preorder: {
    // Client side derrived status based on an order not being ASAP.
    title: 'Preorder',
    intent: 'primary',
    details:
      'The order is waiting to be accepted. It will be automatically rejected after a set time period if no action is taken.',
  },
  // General statuses
  pending: {
    title: 'Pending',
    intent: 'warning',
    details:
      'The order is waiting to be accepted. It will be automatically rejected after a set time period if no action is taken.',
  },
  preparing: {
    title: 'Preparing',
    details:
      'The order is being prepared. The items are being gathered, assembled, or cooked, depending on the type of order.',
  },
  ready: {
    title: 'Ready',
    intent: 'success',
    details:
      'The order is ready for pickup or delivery. All preparation steps have been completed, and the order is awaiting the next action.',
  },
  awaiting_delivery_network: {
    title: 'Awaiting Delivery Network',
    intent: 'info',
    details:
      'The order is waiting to be assigned to a delivery network. This status indicates that the order is ready but has not yet been handed over to a delivery service.',
  },
  delivery_network_failure: {
    title: 'Delivery Network Failure',
    intent: 'danger',
    details:
      'There was a failure in the delivery network. The order could not be delivered due to issues within the delivery system.',
  },
  complete: {
    title: 'Complete',
    intent: 'success',
    details:
      'The order has been completed successfully. This status indicates that the order was fulfilled and delivered as expected.',
  },
  rejected: {
    title: 'Rejected',
    intent: 'danger',
    details:
      'This order was rejected by the outlet. The rejection could be due to various reasons such as unavailability of items or staff might be too busy to fulfil the order.',
  },
  delivery_failure_refund: {
    title: 'Delivery Failure Refund',
    intent: 'danger',
    details:
      'The order failed delivery and a refund is being processed. This status indicates that the order could not be delivered and the customer is being refunded.',
  },
  order_failure_refund: {
    title: 'Order Failure Refund',
    intent: 'danger',
    details:
      'The order failed and a refund is being processed. This could be due to various reasons such as item unavailability or processing errors.',
  },
  refund_requested: {
    title: 'Refund Requested',
    intent: 'danger',
    details:
      'A refund has been requested for the order. The customer has initiated a refund process.',
  },
  refund_cancelled: {
    title: 'Refund Cancelled',
    intent: 'danger',
    details:
      'The requested refund has been cancelled. The refund process has been stopped and the order will not be refunded.',
  },
  refund_released: {
    title: 'Refund Released',
    intent: 'danger',
    details:
      'The refund has been released to the customer. The refund process was successful and the customer will receive their money back in 1-3 business days.',
  },
  refund_failed: {
    title: 'Refund Failed',
    intent: 'danger',
    details:
      'The refund process has failed. There was an issue with processing the refund and it could not be completed. Please raise a support ticket if you require more information.',
  },
}
