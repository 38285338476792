import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import Enum from '@components/Enum/Enum'
import { isInAlarm } from '@utils/order/isInAlarm'
import { format } from 'date-fns'

const OrderStatusEnum = ({
  orderStatus,
  autoRejectAt = null,
  autoAcceptedAt = null,
  isAutoRejected = false,
  asap = null,
  onClick = null, // Default to a no-op function
  details = '',
  actions = null,
  large = false,
}) => {
  // This component has additional logic for correctly display the order status.

  const isAlarm = isInAlarm(orderStatus, autoRejectAt)

  const getOrderStatus = () => {
    if (orderStatus === 'PENDING' && !asap) {
      return 'PREORDER'
    }
    return orderStatus
  }

  const getStatusIcon = () => {
    if (isAutoRejected) return 'outdated'
    if (autoAcceptedAt) return 'fast-forward'
    if (isAlarm) return 'warning-sign'
  }

  const getStatusMinimal = () => {
    if (isAutoRejected) return false
    if (isAlarm) return false
    return true
  }

  const getStatusIntent = () => {
    if (isAlarm) return 'danger'
  }

  const getStatusInfo = () => {
    if (autoAcceptedAt)
      return `Auto-Accepted ${format(
        new Date(autoAcceptedAt),
        "LLLL d, yyyy 'at' HH:mm:ss"
      )}`
    if (isAutoRejected)
      return `Auto-Rejected ${format(
        new Date(autoRejectAt),
        "LLLL d, yyyy 'at' HH:mm:ss"
      )}`
  }

  return (
    <Enum
      tagName={getOrderStatus()}
      rightIcon={getStatusIcon()}
      minimal={getStatusMinimal()}
      intent={getStatusIntent()}
      title={getStatusInfo()}
      large={large}
      details={details}
      shortcut={
        onClick && <Button icon="document-share" minimal onClick={onClick} />
      }
      actions={actions}
    />
  )
}

OrderStatusEnum.propTypes = {
  orderStatus: PropTypes.string.isRequired, // Must be provided
  autoRejectAt: PropTypes.string,
  autoAcceptedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  isAutoRejected: PropTypes.bool,
  asap: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Date)]),
  onClick: PropTypes.func,
  details: PropTypes.string,
  actions: PropTypes.element,
  large: PropTypes.bool,
}

export default OrderStatusEnum
