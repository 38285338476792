import gql from 'graphql-tag'

const GET_MENU_DETAILS = gql`
  query getMenuDetails($id: String!) {
    getMenuItemsGroups(id: $id) {
      menuItemsGroups {
        id
        name
        description
        image
        showMenuThumbnails
        addOnItemsMenu
        fulfillmentMethods
        availabilityStartDate
        availabilityEndDate
        availabilityTimes
        position
      }
    }
  }
`

export default GET_MENU_DETAILS
