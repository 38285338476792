import React from 'react'
import Query from '@components/Query/Query'
import { InputGroup, Intent, Icon, HTMLSelect, Tag } from '@blueprintjs/core'
import { string, func, object, number, bool } from 'prop-types'
import get from 'lodash/get'

import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import Currency from '@components/Currency/Currency'

import GET_VATRATE from '../queries/getVatRate.query'

import { PriceRow } from './AddOption.modal.styles'

const NewOptionRow = ({
  setFieldValue,
  item,
  index,
  handleChange,
  errors,
  name,
  remove,
  isVATregistered,
}) => (
  <tr>
    <td>
      <InputGroup
        type="text"
        placeholder="Name"
        name={`${name}.${index}.name`}
        onChange={handleChange}
        value={item.name}
        intent={
          get(errors, `[${name}][${index}].name`) ? Intent.DANGER : Intent.NONE
        }
        autoFocus
      />
    </td>
    <PriceRow>
      <CurrencyInput
        id={`${name}-${index}-price`}
        name={`${name}.${index}.price`}
        fill={true}
        defaultValue={item.price}
        onUpdate={amount => {
          setFieldValue(`${name}.${index}.price`, amount)
        }}
        intent={
          get(errors, `[${name}][${index}].price`) ? Intent.DANGER : Intent.NONE
        }
        rightElement={
          item.vatRate > 0 && (
            <Tag minimal>
              <Currency />
              {parseFloat(
                item.price - item.price / (1 + 1 / (item.vatRate / 100))
              ).toFixed(2)}{' '}
              Net
            </Tag>
          )
        }
      >
        {isVATregistered && (
          <Query query={GET_VATRATE} showLoader={false}>
            {({ getPlatformVAT: platformVATRate }) => {
              return (
                <HTMLSelect
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  key={`${name}-${index}-vatRate`}
                  id={`${name}-${index}-vatRate`}
                  name={`${name}.${index}.vatRate`}
                  value={item.vatRate}
                  onChange={handleChange}
                  onBlur={event => {
                    const vatRate = event.currentTarget.value
                    setFieldValue(`${name}.${index}.vatRate`, vatRate)
                    setFieldValue(
                      `${name}.${index}.VATinclusive`,
                      event.currentTarget.value > 0
                    )
                  }}
                  options={[
                    { value: '0', label: 'VAT 0%' },
                    { value: '5', label: 'VAT 5%' },
                    {
                      value: '12.5',
                      label: 'VAT 12.5%',
                    },
                    {
                      value: platformVATRate,
                      label: `VAT ${platformVATRate}%`,
                    },
                  ]}
                />
              )
            }}
          </Query>
        )}
      </CurrencyInput>
    </PriceRow>
    <td colSpan="2"></td>
    <td className={'bp5-action-cell'}>
      <ConfirmationPopover
        remove={() => remove(index)}
        confirmationText="Are you sure you want to delete this option?"
      >
        <Icon icon="trash" />
      </ConfirmationPopover>
    </td>
  </tr>
)

NewOptionRow.propTypes = {
  setFieldValue: func,
  item: object,
  index: number,
  handleChange: func,
  errors: object,
  name: string,
  remove: func,
  restaurantIsVatRegistered: bool,
}

export default NewOptionRow
