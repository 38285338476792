import gql from 'graphql-tag'

const ALLOW_ONBOARDING = gql`
  mutation allowOnboarding($id: String!, $allowOnboarding: Boolean!) {
    editMarketplace(id: $id, allowOnboarding: $allowOnboarding) {
      message
      marketplace {
        id
        allowOnboarding
      }
    }
  }
`

export default ALLOW_ONBOARDING
