import { useState } from 'react'
import {
  FormGroup,
  InputGroup,
  Intent,
  Button,
  Classes,
} from '@blueprintjs/core'
import { func, object, bool } from 'prop-types'
import ShortDetailsForm from '@components/Restaurant/Restaurant.modal/ShortDetailsForm'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Formik } from 'formik'
import ONBOARDING_APPLY from './mutations/apply.mutation'
import { successToast } from '../../utils/toast'

import * as yup from 'yup'
import { address } from '@src/validation/address'
import { phoneRegExp } from '@src/validation/phone-regex'
import { useMutation } from '@apollo/client'

export const onboardingRestaurantStructure = {
  name: yup
    .string()
    .required('Business Name is required')
    .min(3, 'Business name too short'),
  contactEmail: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  contactName: yup.string().required('Contact Name is required'),
  contactPhone: yup
    .string()
    .required('Contact Phone is required')
    .transform(val => val.replace(/\s/g, ''))
    .matches(phoneRegExp, 'Must be a valid phone number'),
}

export const validation = yup
  .object()
  .shape(onboardingRestaurantStructure)
  .concat(yup.object().shape({ contactAddress: yup.object().shape(address) }))

const OnboardingForm = ({ marketplaceKey }) => {
  const [submitted, setSubmitted] = useState(false)

  const [onboardingApply] = useMutation(ONBOARDING_APPLY, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('Application submitted successfully')
      setSubmitted(true)
    },
  })

  return (
    <Formik
      onSubmit={values => {
        onboardingApply({
          variables: {
            ...values,
            outletName: values.contactAddress.firstLine,
          },
        })
      }}
      initialValues={{
        name: '',
        contactEmail: '',
        contactAddress: {
          firstLine: '',
          secondLine: '',
          thirdLine: '',
          city: '',
          country: { code: 'GB', name: 'United Kingdom' },
          postcode: '',
        },
        contactPhone: '',
        contactName: '',
        marketplaceKey,
      }}
      validationSchema={validation}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        handleChange,
        handleSubmit,
        errors,
        values,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(e)
            }}
            className={Classes.DIALOG_BODY}
          >
            <FormGroup
              label="Business Name"
              labelInfo="(required)"
              labelFor="name"
              helperText={errors.name ? errors.name : ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                name="name"
                id="name"
                type="text"
                onChange={handleChange}
                intent={errors.name ? Intent.DANGER : Intent.NONE}
                value={values.name}
              />
            </FormGroup>
            <ShortDetailsForm
              values={values}
              handleChange={handleChange}
              errors={errors}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
            />
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                loading={isSubmitting && !submitted}
                text="Submit"
                intent={Intent.PRIMARY}
                type="submit"
                disabled={submitted || isSubmitting}
              />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

OnboardingForm.propTypes = {
  handleChange: func,
  values: object,
  data: object,
  isSubmitting: bool,
  mutation: func,
}

export default OnboardingForm
