import { Card } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import React from 'react'
import { DataKeyMap } from '../util/maps'

export const DiscountedValuesContainer = ({
  discountedValues: { total, orders },
  title,
  resolution = false,
}) =>
  total > 0 ? (
    <Card>
      <p>{title}</p>
      <p>
        Discounted Total <Currency amount={total} amountInPence={false} />
        {resolution ? ` per ${resolution}` : ''}
      </p>
      {orders.map(({ value, color, dataKey }) => {
        return value ? (
          <div key={dataKey}>
            <small style={{ color }}>
              {DataKeyMap[dataKey]}{' '}
              <span style={{ color: 'black' }}>
                <Currency amount={value} />
              </span>
            </small>
          </div>
        ) : null
      })}
    </Card>
  ) : null
