import gql from 'graphql-tag'

const GET_CUISINES = gql`
  query getCuisinesMarketplace {
    getCuisines {
      cuisines {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`

export default GET_CUISINES
