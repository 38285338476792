import { Card, HTMLTable, NonIdealState, Tag } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import DateTime from '@components/DateTime/DateTime'
import FilterRow from '@components/FilterRow/FilterRow'
import OutletLink from '@components/OpenStatus/OutletLink'
import Query from '@components/Query/Query'
import StarsRating from '@components/Stars/Stars'
import { Pager } from '@components/Toolbar/Pager'
import { averageRatings } from '@utils/averageRatings'
import first from 'lodash/first'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import GET_CUSTOMER_ORDERS from './queries/getCustomerOrders.query'
import OrderStatusEnum from '@components/Enum/OrderStatusEnum'
import { get } from 'lodash'
import { DELIVERY_PROVIDER_TO_TAG_ICON } from '@components/Orders/helpers/helpers'
import { removeUnderscores } from '@utils/helpers'

const DEFAULT_RECORDS = 50

const Orders = () => {
  const params = useParams()
  const [ordersPagination, setOrdersPagination] = useState({
    total: null,
    skip: 0,
    first: DEFAULT_RECORDS,
    last: null,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
  })

  const setFilterState = (count, totalCount) => {
    if (ordersPagination.total !== totalCount) {
      setOrdersPagination({
        ...ordersPagination,
        total: totalCount,
      })
    }
    if (ordersPagination.outcomeLength !== count) {
      setOrdersPagination({
        ...ordersPagination,
        outcomeLength: count,
      })
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      ordersPagination.skip + ordersPagination.first <
      ordersPagination.total
    ) {
      setOrdersPagination({
        ...ordersPagination,
        skip: limitNext(ordersPagination.skip, ordersPagination.first, limit),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < ordersPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setOrdersPagination({
      ...ordersPagination,
      skip: limitPrevious(ordersPagination.skip, ordersPagination.first),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setOrdersPagination({
      ...ordersPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <Pager
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={ordersPagination.skip}
          total={ordersPagination.total}
          outcomeLength={ordersPagination.outcomeLength}
          totalCount={ordersPagination.total}
          dataName="Orders"
        />
      </FilterRow>

      <Query
        query={GET_CUSTOMER_ORDERS}
        variables={{
          id: params.customer,
          ...ordersPagination,
        }}
        loaderTitle={'Loading Orders'}
      >
        {({ customers }) => {
          const customer = customers.customers
          const customerDetails = first(customer)
          setFilterState(
            customerDetails.orders.length,
            customerDetails.totalOrders
          )

          if (customer.length) {
            if (customerDetails.orders.length) {
              return (
                <div className="bp5-table-container bp5-scrollable">
                  <Card className="bp5-nopad">
                    <HTMLTable>
                      <thead>
                        <tr>
                          <th>Order Number</th>
                          <th>Outlet</th>
                          <th>Order Date</th>
                          <th>Gross Total</th>
                          <th>Net Total</th>
                          <th>Status</th>
                          <th>Fulfillment</th>
                          <th>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerDetails.orders.map(
                          ({
                            id,
                            createdAt,
                            autoRejectAt,
                            autoAcceptedAt,
                            grossTotal,
                            netTotal,
                            outlet,
                            orderNumber,
                            review,
                            orderStatus,
                            fulfillmentMethod,
                            isAutoRejected,
                            cancellationNotes,
                            tableSnapshot,
                            deliveryNetworkBookingStatus,
                            deliveryNetworkProvider,
                            asap,
                          }) => (
                            <tr key={id}>
                              <td>
                                <Link to={{ search: `?viewOrder=${id}` }}>
                                  #{orderNumber}
                                </Link>
                              </td>
                              <td>
                                <OutletLink outlet={outlet} />
                              </td>
                              <td>
                                <DateTime dateTime={createdAt} />
                              </td>
                              <td>
                                <Currency amount={grossTotal} />
                              </td>
                              <td>
                                <Currency amount={netTotal} />
                              </td>
                              <td>
                                <OrderStatusEnum
                                  orderStatus={orderStatus}
                                  autoRejectAt={autoRejectAt}
                                  autoAcceptedAt={autoAcceptedAt}
                                  isAutoRejected={isAutoRejected}
                                  asap={asap}
                                  details={isAutoRejected && cancellationNotes}
                                />
                              </td>
                              <td
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {fulfillmentMethod === 'NETWORK' && (
                                  <Fragment>
                                    <Tag
                                      icon={
                                        DELIVERY_PROVIDER_TO_TAG_ICON[
                                          deliveryNetworkProvider
                                        ] || 'taxi'
                                      }
                                      intent={'primary'}
                                      minimal
                                    >
                                      {removeUnderscores(
                                        deliveryNetworkBookingStatus
                                      )}
                                    </Tag>
                                    &nbsp;
                                  </Fragment>
                                )}
                                {fulfillmentMethod === 'TABLE' ? (
                                  <Tag minimal>
                                    TABLE{' '}
                                    {`${get(
                                      tableSnapshot,
                                      'friendlyName',
                                      'unknown'
                                    ).replace(/^table\s/i, '')}`}
                                  </Tag>
                                ) : (
                                  <Tag minimal>{fulfillmentMethod}</Tag>
                                )}
                              </td>
                              <td>
                                {review ? (
                                  <StarsRating
                                    starsNumber={averageRatings(review)}
                                  />
                                ) : (
                                  'No review'
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              )
            } else {
              return (
                <NonIdealState
                  icon="th-list"
                  title="No Orders Found"
                  description="There are no orders from this customer."
                />
              )
            }
          } else
            return (
              <NonIdealState
                icon="mugshot"
                title="Customer Not Found"
                description="We were unable to find details for this customer."
              />
            )
        }}
      </Query>
    </div>
  )
}

export default Orders
