// @ts-nocheck
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Query } from '@apollo/client/react/components'

import {
  Alert,
  Button,
  Card,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Position,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import FilterRow from '@components/FilterRow/FilterRow'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import BillingTable from '@src/pages/Reports/Billing/BillingTable'
import QueueInvoiceEmails from '@src/pages/Reports/Billing/QueueInvoiceEmailsDialog'
import GET_TRANSACTION_AGGREGATES from '@src/pages/Reports/Billing/queries/getTransactionAggregates.query'
import { MarketplaceFilter } from '@components/Toolbar'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import { client } from '@services/client'
import { canView, isPlatformLevelUser } from '@stores/userStore'
import { penceToPounds } from '@utils/helpers'
import ls from '@utils/localStorage'
import { errorToast, toast } from '@utils/toast'
import { startCase, sumBy } from 'lodash'
import { shiftToRedboxPreMigrationUTC } from '@utils/dateTimeRangeShifters'
import config from '@src/config'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import { isBefore, format, subWeeks, startOfWeek } from 'date-fns'
import { ISO_WEEK_DAY } from '@utils/datetime'
import { useRoleAwareBusinessFilterQueryParams } from '../../../components/Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const errorCodeToHandler = {
  400: () => {
    errorToast('An unexpected error occurred')
  },
  403: () => {
    errorToast('You do not have permission to view this report')
  },
  404: () => {
    errorToast(
      'This report is currently being generated. Please try again later.'
    )
  },
  500: () => {
    errorToast('An unexpected error occurred')
  },
}
const defaultErrorHandler = () => errorToast('An unexpected error occurred')

const Billing = ({ product }) => {
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.WEEK.key,
    defaultStartOfRangeDate: subWeeks(
      startOfWeek(new Date(), { weekStartsOn: ISO_WEEK_DAY.MONDAY }),
      1
    ),
    filterTypes: isPlatformLevelUser()
      ? [
          DATE_FILTER_TYPES.DAY,
          DATE_FILTER_TYPES.WEEK,
          DATE_FILTER_TYPES.CUSTOM,
        ]
      : [DATE_FILTER_TYPES.WEEK],
    shifter: shiftToRedboxPreMigrationUTC,
  }
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams(dateRangeFilterArgs)

  const { configurePageLayout } = useContext(PageLayoutContext)

  const [isDownloading, setIsDownloading] = useState(false)
  const [isQueueInvoiceEmailsDialogOpen, setIsQueueInvoiceEmailsDialogOpen] =
    useState(false)

  const tabs = []

  if (canView('reports')) {
    tabs.push({ to: '/reports/payments', name: 'Payments' })
  }

  if (canView('billing')) {
    tabs.push({ to: '/reports/invoicing', name: 'Invoicing' })
  }

  useEffect(() => {
    const pageLayoutConfig = {
      product,
      tabs,
    }
    configurePageLayout(pageLayoutConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurePageLayout, product])

  const downloadZIP = async () => {
    setIsDownloading(true)

    const managementJwt = ls.get('jwt')

    const url = `${
      config.apiUrl
    }/api/reports/billing/zip?startDate=${shiftedStartOfRangeDateTime.toISOString()}&marketplaceIds=${marketplaceFilter.join(
      ','
    )}`
    const options = {
      headers: {
        Authorization: `Bearer ${managementJwt}`,
      },
    }
    const res = await fetch(url, options)
    if (res.status !== 200) {
      return (errorCodeToHandler[res.status.toString()] || defaultErrorHandler)(
        res
      )
    }
    const matches = res.headers
      .get('content-disposition')
      .match(/attachment;.+?filename=(.+\.zip)/)
    const filename = (matches && matches[1]) || 'billing-report.zip'
    const blob = await res.blob()
    const file = new File([blob], filename)
    const objectURL = window.URL.createObjectURL(file)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = objectURL
    a.download = filename
    a.setAttribute('target', '_blank')
    a.click()
    a.remove()

    window.URL.revokeObjectURL(objectURL)

    setIsDownloading(false)

    toast({
      message: 'Download ZIP Complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  const downloadCSV = async e => {
    e.preventDefault()
    setIsDownloading(true)

    const csvHeader = [
      'restaurantName',
      'outletName',
      'marketplaceName',
      'cardTotal',
      'cardTotalGross',
      'cashTotal',
      'cashTotalGross',
      'orderDiscount',
      'orderNet',
      'orderGross',
      'orderTotalToRestaurant',
      'totalFeeChargeNet',
      'totalFeeChargeGross',
      'vatTotal',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_TRANSACTION_AGGREGATES,
        variables: {
          startDate: shiftedStartOfRangeDateTime,
          endDate: shiftedEndOfRangeDateTime,
          marketplaceIds: marketplaceFilter,
        },
      })
      .then(result =>
        result.data.transactionAggregates.map(transaction => {
          csvData.push(
            csvHeader
              .map(column => {
                return {
                  restaurantName: `"${transaction.outlet.restaurant.name}"`,
                  outletName: `"${transaction.outlet.name}"`,
                  marketplaceName: `"${transaction.outlet.marketplace.name}"`,
                  cardTotal: transaction.totalCardOrders,
                  cardTotalGross: penceToPounds(
                    transaction.totalCardValueGrossExcludingServiceCharge
                  ),
                  cashTotal: transaction.totalCashOrders,
                  cashTotalGross: penceToPounds(
                    transaction.totalCashValueGrossExcludingServiceCharge
                  ),
                  orderDiscount: penceToPounds(transaction.totalDiscounts),
                  orderNet: penceToPounds(
                    transaction.totalOrdersNetAfterDiscounts
                  ),
                  orderGross: penceToPounds(
                    transaction.totalOrdersGrossAfterDiscount
                  ),
                  orderTotalToRestaurant: penceToPounds(
                    transaction.totalToRestaurant
                  ),
                  totalFeeChargeNet: penceToPounds(
                    transaction.totalFeesAndChargesNet
                  ),
                  totalFeeChargeGross: penceToPounds(
                    transaction.totalFeesAndChargesGross
                  ),
                  vatTotal: penceToPounds(
                    sumBy(transaction.vatAggregates, vatAggregate => {
                      return vatAggregate.combinedTotal || 0
                    })
                  ),
                }[column]
              })
              .join(',')
          )
        })
      )

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    //Add temp Link to page and start download.
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `invoices-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setIsDownloading(false)

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  const invoicesAvailable = isBefore(shiftedEndOfRangeDateTime, new Date())

  return (
    <div className="bp5-table-frame">
      <FilterRow>
        <div>
          <MarketplaceFilter divider />
          <DateRangeFilter
            {...dateRangeFilterArgs}
            disabled={marketplaceFilter.length === 0}
          />
        </div>

        {isPlatformLevelUser() && (
          <Fragment>
            <QueueInvoiceEmails
              modalOpen={isQueueInvoiceEmailsDialogOpen}
              modalClose={() => setIsQueueInvoiceEmailsDialogOpen(false)}
              dateFrom={shiftedStartOfRangeDateTime}
              dateTo={shiftedEndOfRangeDateTime}
            />
            <Button
              style={{ marginLeft: 'auto' }}
              icon={'send-message'}
              onClick={() => setIsQueueInvoiceEmailsDialogOpen(true)}
            >
              Queue Emails
            </Button>
          </Fragment>
        )}

        <Popover
          content={
            <Menu>
              <MenuItem
                text="Report"
                label={<Tag minimal>CSV</Tag>}
                onClick={downloadCSV}
              />
              <MenuItem
                text="Invoices"
                label={<Tag minimal>ZIP</Tag>}
                onClick={downloadZIP}
                disabled={!invoicesAvailable}
              />
            </Menu>
          }
          position={Position.BOTTOM}
        >
          <Button
            disabled={marketplaceFilter.length === 0}
            icon={'cloud-download'}
          >
            Download
          </Button>
        </Popover>
      </FilterRow>

      {marketplaceFilter.length === 0 ? (
        <NonIdealState
          icon="property"
          title="Select Marketplace"
          description="Please select a marketplace to load this report."
        />
      ) : (
        <Query
          query={GET_TRANSACTION_AGGREGATES}
          variables={{
            startDate: shiftedStartOfRangeDateTime,
            endDate: shiftedEndOfRangeDateTime,
            marketplaceIds: marketplaceFilter,
          }}
        >
          {({ data, error = null, loading }) => {
            if (loading) {
              return (
                <NonIdealState
                  icon={<Spinner />}
                  title="Loading billing report..."
                />
              )
            }

            if (error) {
              return (
                <NonIdealState
                  icon="error"
                  title="Error loading billing report"
                />
              )
            }

            const { transactionAggregates } = data

            return (
              <Fragment>
                {transactionAggregates.length ? (
                  <div className="bp5-table-container bp5-scrollable">
                    <Card className={'bp5-nopad'}>
                      <BillingTable
                        data={transactionAggregates.flatMap(
                          ({ invoices, ...transactionAggregate }) => {
                            if (!invoices.length) {
                              return transactionAggregate
                            }
                            return invoices.map(invoice => ({
                              ...transactionAggregate,
                              invoice,
                            }))
                          }
                        )}
                        fromDate={shiftedStartOfRangeDateTime}
                        toDate={shiftedEndOfRangeDateTime}
                        refetchQueries={[
                          {
                            query: GET_TRANSACTION_AGGREGATES,
                            variables: {
                              startDate: shiftedStartOfRangeDateTime,
                              endDate: shiftedEndOfRangeDateTime,
                              marketplaceIds: marketplaceFilter,
                            },
                          },
                        ]}
                      />
                    </Card>
                  </div>
                ) : (
                  <NonIdealState
                    icon="th-list"
                    title={'No Billing Data'}
                    description="There is no data for the selected filter."
                  />
                )}
              </Fragment>
            )
          }}
        </Query>
      )}
      <Alert
        isOpen={isDownloading}
        icon={<Spinner size="38" style={{ marginRight: 16 }} />}
        canEscapeKeyCancel={false}
        canOutsideClickCancel={false}
        confirmButtonText="Cancel"
        onConfirm={() => {
          location.reload()
        }}
      >
        <strong>Generating Invoices</strong>
        <p style={{ marginTop: 10 }}>
          Please wait, it may take a few seconds and your file will start
          downloading.
        </p>
      </Alert>
    </div>
  )
}

export default Billing
