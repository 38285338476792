export const Reconciliation = {
  on_transaction_create: {
    title: 'Transaction Creation Error',
    tag: 'TRANSACTION CREATE',
    minimal: true,
    details:
      'An error occurred during the initial transaction processing. Commission transfers or application fee processing failed.',
  },
  on_transaction_update: {
    title: 'Transaction Update Error',
    tag: 'TRANSACTION UPDATE',
    minimal: true,
    details:
      'Discrepancies detected during transaction update. This may occur during retry attempts or transaction state changes, such as order rejection scenarios.',
  },
  on_transaction_refund: {
    intent: 'warning',
    title: 'Refund Processing Error',
    tag: 'REFUND PROCESSING',
    details:
      'Issues encountered while processing transfer reversals during refund operation.',
  },
  backfill: {
    title: 'Historical Error',
    tag: 'HISTORICAL',
    minimal: true,
    details:
      'A historical transaction discrepancy was detected. The exact timing of the issue is unknown.',
  },
}
