import gql from 'graphql-tag'

const GET_ALL_CATEGORIES = gql`
  query getAllCuisines($marketplaceIds: [ID!]) {
    getCuisines(marketplaceIds: $marketplaceIds) {
      count
      totalCount
      cuisines {
        id
        name
        emoji
        imageSrc
      }
    }
  }
`

export default GET_ALL_CATEGORIES
