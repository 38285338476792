import React from 'react'
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core'

const PickupNotesInput = ({ values, errors, handleChange }) => (
  <FormGroup
    label="Pickup Notes"
    labelFor="pickupNotes"
    helperText={errors.pickupNotes || ''}
    intent={errors.pickupNotes ? Intent.DANGER : Intent.NONE}
  >
    <InputGroup
      type="text"
      name="pickupNotes"
      id="pickupNotes"
      value={values.pickupNotes}
      onChange={handleChange}
    />
  </FormGroup>
)

export default PickupNotesInput
