import gql from 'graphql-tag'

const GET_LIVE_ESTIMATE_FOR_CAMPAIGN = gql`
  query getLiveEstimateForCampaign(
    $marketplaceId: ID!
    $channel: String!
    $segmentId: String!
    $type: String!
  ) {
    getLiveEstimateForCampaign(
      marketplaceId: $marketplaceId
      channel: $channel
      segmentId: $segmentId
      type: $type
    ) {
      estimate
    }
  }
`

export default GET_LIVE_ESTIMATE_FOR_CAMPAIGN
