import gql from 'graphql-tag'

const GET_STRIPE_CLIENT_ID = gql`
  query stripeClientId($marketplaceId: String!) {
    getStripeClientId(marketplaceId: $marketplaceId) {
      message
      stripeClientId
    }
  }
`

export default GET_STRIPE_CLIENT_ID
