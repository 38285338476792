import React, { Fragment } from 'react'
import { func, object, string, shape } from 'prop-types'
import {
  FormGroup,
  InputGroup,
  Intent,
  ControlGroup,
  HTMLSelect,
  H5,
} from '@blueprintjs/core'
import get from 'lodash/get'
import { shortRestaurantType } from '@utils/types'
import { countryList, selectionUpdate } from '@utils/helpers'

const ShortDetailsForm = ({ handleChange, errors, values, setFieldValue }) => (
  <Fragment>
    <H5>Business Owner Details</H5>
    <FormGroup
      label="Contact Details"
      labelInfo="(required)"
      helperText={
        [errors.contactName, errors.contactEmail, errors.contactPhone].filter(
          Boolean
        ).length ? (
          <ul className="bp5-list-unstyled">
            {[errors.contactName, errors.contactEmail, errors.contactPhone]
              .filter(Boolean)
              .map((errorString, index) => (
                <li key={index}>{errorString}</li>
              ))}
          </ul>
        ) : null
      }
      intent={Intent.DANGER}
    >
      <ControlGroup vertical={true}>
        <InputGroup
          name="contactName"
          id="contactName"
          type="text"
          leftIcon="person"
          placeholder="Full Name"
          onChange={handleChange}
          intent={errors.contactName ? Intent.DANGER : Intent.NONE}
          value={values.contactName}
        />
        <InputGroup
          name="contactEmail"
          id="contactEmail"
          type="text"
          placeholder="Email Address"
          leftIcon="envelope"
          onChange={handleChange}
          intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
          value={values.contactEmail}
        />
        <InputGroup
          name="contactPhone"
          id="contactPhone"
          type="text"
          placeholder="Phone Number"
          leftIcon="phone"
          onChange={handleChange}
          intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
          value={values.contactPhone}
        />
      </ControlGroup>
    </FormGroup>
    <FormGroup
      label="Address"
      labelInfo="(required)"
      labelFor="firstLine"
      helperText={
        errors.contactAddress ? 'Highlighted fields are required' : ''
      }
      intent={Intent.DANGER}
    >
      <ControlGroup vertical={true}>
        <InputGroup
          name="contactAddress.firstLine"
          id="firstLine"
          type="text"
          placeholder="First Line"
          onChange={handleChange}
          intent={
            get(errors, 'contactAddress.firstLine')
              ? Intent.DANGER
              : Intent.NONE
          }
          value={values.contactAddress.firstLine}
        />
        <InputGroup
          name="contactAddress.secondLine"
          type="text"
          placeholder="Second Line"
          onChange={handleChange}
          value={values.contactAddress.secondLine}
        />
        <InputGroup
          name="contactAddress.thirdLine"
          type="text"
          placeholder="Third Line"
          onChange={handleChange}
          value={values.contactAddress.thirdLine}
        />
        <InputGroup
          name="contactAddress.city"
          type="text"
          placeholder="City"
          onChange={handleChange}
          intent={
            get(errors, 'contactAddress.city') ? Intent.DANGER : Intent.NONE
          }
          value={values.contactAddress.city}
        />

        <InputGroup
          name="contactAddress.postcode"
          type="text"
          placeholder="Postcode"
          onChange={handleChange}
          intent={
            get(errors, 'contactAddress.postcode') ? Intent.DANGER : Intent.NONE
          }
          value={values.contactAddress.postcode}
        />

        <HTMLSelect
          name="country"
          options={countryList()}
          value={values.contactAddress.country.code}
          onChange={event => {
            const selection = selectionUpdate(event)
            setFieldValue(
              'contactAddress.country',
              {
                name: selection.label,
                code: selection.value,
              },
              false
            )
          }}
        />
      </ControlGroup>
    </FormGroup>
  </Fragment>
)

ShortDetailsForm.propTypes = {
  handleChange: func,
  setFieldValue: func,
  errors: object,
  values: shape(shortRestaurantType),
  className: string,
}

export default ShortDetailsForm
