import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
import { numberToPence } from '@utils/helpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import ADD_DELIVERY_NETWORK from './mutations/addDeliveryNetwork.mutation'
import GET_DELIVERY_NETWORKS from '@components/Marketplace/DeliveryNetworks/queries/getDeliveryNetworks.query'
import DeliveryNetworkForm from './DeliveryNetworkForm'
import { DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT } from './shared'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { useMutation } from '@apollo/client'

const AddDeliveryNetworkModal = ({ modalOpen, modalClose, marketplaceId }) => {
  const [addDeliveryNetwork] = useMutation(ADD_DELIVERY_NETWORK, {
    onCompleted: ({ addDeliveryNetwork }) => {
      successToast(addDeliveryNetwork.message)
      modalClose()
    },
    onError: defaultErrorHandler,
    refetchQueries: [
      {
        query: GET_DELIVERY_NETWORKS,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  return (
    <Drawer
      isOpen={modalOpen}
      onClose={modalClose}
      title="New Delivery Network"
      size={DrawerSize.SMALL}
    >
      <Formik
        initialValues={{
          deliveryProvider: 'STUART',
          marketplaceId,
          name: '',
          helpPhone: '',
          namePrefix: '',
          subsidiseDelivery: false,
          fixedSubsidy: 0,
          apiClientId: '',
          apiSecret: '',
          apiURL: '',
          apiKey: '',
          deliveryChargeRecipient:
            DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT['STUART'],
          deliveryNetworkType: 'BOOKING',
          uberDirectCustomerId: '',
          uberDirectClientId: '',
          uberDirectClientSecret: '',
          uberDirectSigningKey: '',
        }}
        onSubmit={({
          helpPhone,
          namePrefix,
          fixedSubsidy,
          apiClientId,
          apiSecret,
          deliveryNetworkType,
          apiURL,
          apiKey,
          uberDirectCustomerId,
          uberDirectClientId,
          uberDirectClientSecret,
          uberDirectSigningKey,
          ...commonRequiredValues
        }) => {
          const addDeliveryNetworkPayload = {
            variables: {
              ...commonRequiredValues,
              ...(helpPhone && {
                helpPhone,
              }),
              ...(namePrefix && {
                namePrefix,
              }),
              ...(commonRequiredValues.subsidiseDelivery && {
                fixedSubsidy: numberToPence(fixedSubsidy),
              }),
              ...(commonRequiredValues.deliveryProvider === 'STUART' && {
                apiClientId,
                apiSecret,
                deliveryNetworkType,
                ...(deliveryNetworkType === 'BOOKING' && {}),
              }),
              ...(commonRequiredValues.deliveryProvider === 'WAYBOX' && {
                apiURL,
                apiKey,
                deliveryNetworkType: 'BOOKING',
              }),
              ...(commonRequiredValues.deliveryProvider === 'UBER_DIRECT' && {
                deliveryNetworkType: 'BOOKING',
                uberDirectClientId,
                uberDirectClientSecret,
                uberDirectCustomerId,
                uberDirectSigningKey,
              }),
            },
          }
          return addDeliveryNetwork(addDeliveryNetworkPayload)
        }}
      >
        {props => <DeliveryNetworkForm {...props} isEditing={false} />}
      </Formik>
    </Drawer>
  )
}

AddDeliveryNetworkModal.propTypes = {
  ...modalType,
  marketplaceId: string,
}

export default AddDeliveryNetworkModal
