import { Children, cloneElement, isValidElement } from 'react'
import { ColProps } from './Col'

const Row: React.FC<{
  gutter?: number
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}> = ({ gutter = 16, className = '', style = {}, children }) => {
  const gapClass = `-mx-${gutter / 8}`

  return (
    <div
      className={`row flex flex-wrap justify-start ${gapClass} ${className}`}
      style={style}
    >
      {Children.map(children, child => {
        if (isValidElement<ColProps>(child)) {
          return cloneElement(child, { gutter })
        }

        return child
      })}
    </div>
  )
}

export default Row
