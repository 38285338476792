import gql from 'graphql-tag'
// outlet id is required here
// because of this error - https://github.com/apollographql/apollo-client/issues/2510

const GET_CUSTOMER_ORDERS = gql`
  query getCustomerAndOrders(
    $id: ID!
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    customers(where: { id: $id }) {
      customers {
        id
        totalCompletedOrders
        totalOrders
        orders(
          orderBy: createdAt_DESC
          skip: $skip
          after: $after
          before: $before
          first: $first
          last: $last
        ) {
          id
          orderNumber
          orderStatus
          fulfillmentMethod
          isAutoRejected
          autoRejectAt
          autoAcceptedAt
          createdAt
          cancellationNotes
          tableSnapshot
          deliveryNetworkBookingStatus
          deliveryNetworkProvider
          asap
          outlet {
            id
            name
            active
            isOpen
            isOnline
            contactName
            contactEmail
            contactPhone
            restaurant {
              id
              name
              active
            }
          }
          grossTotal
          netTotal
          review {
            foodQuality
            restaurantService
            deliveryTime
          }
        }
      }
    }
  }
`

export default GET_CUSTOMER_ORDERS
