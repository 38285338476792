import React from 'react'
import moment from 'moment'
import { HTMLTable, Spinner, Tag } from '@blueprintjs/core'
import DeleteCampaignButton from './DeleteCampaignButton'
import Currency from '@components/Currency/Currency'

/**
 * @param {string} date
 */
const formatDate = date => moment(date).format('DD MMM, HH:mm')

const CampaignsTable = ({ data, setEditId, marketplaceId, refetch }) => (
  <HTMLTable bordered={false} interactive={true}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Deliveries</th>
        <th>Email / Push Open</th>
        <th>Bounce Rate</th>
        <th>Email Clicks</th>
        <th>Tracked Revenue</th>
        <th>Status</th>
        <th>Sent</th>
        <th>Modified</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {data.map(
        ({
          id,
          name,
          updatedAt,
          createdAt,
          deliveries,
          status,
          description,
          emailOpenRate,
          pushOpenRate,
          clicks,
          monetisation,
          bounceRate,
          timeConfig,
          startDateTime,
        }) => (
          <tr key={id}>
            <td>
              <a onClick={() => setEditId(id)}>{name}</a>
            </td>
            <td>{description}</td>
            <td>
              {deliveries}
              {status === 'EXECUTING' ? (
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <Spinner size={10} />
                </div>
              ) : null}
            </td>
            <td>
              {Number(emailOpenRate * 100).toFixed(2)}% /{' '}
              {Number(pushOpenRate * 100).toFixed(2)}%
            </td>
            <td>{Number(bounceRate * 100).toFixed(2)}%</td>
            <td>{Number(clicks)}</td>
            <td>
              {monetisation.total ? (
                <Currency amount={monetisation.total} />
              ) : (
                '-'
              )}
            </td>
            <td>
              <Tag minimal intent={status === 'COMPLETED' ? 'success' : 'none'}>
                {status}
              </Tag>
            </td>
            <td>
              {timeConfig === 'IMMEDIATELY'
                ? formatDate(createdAt)
                : formatDate(startDateTime)}
            </td>
            <td>{formatDate(updatedAt)}</td>
            <td>
              {status === 'SCHEDULED' && (
                <DeleteCampaignButton
                  campaignId={id}
                  marketplaceId={marketplaceId}
                  onCompleted={refetch}
                />
              )}
            </td>
          </tr>
        )
      )}
    </tbody>
  </HTMLTable>
)

export default CampaignsTable
