import { Button } from '@blueprintjs/core'
import React from 'react'

const DatePickerButton = ({ disabled, minimal, children }) => (
  <div style={{ minWidth: '170px' }}>
    <Button
      disabled={disabled}
      minimal={minimal}
      rightIcon="double-caret-vertical"
      fill={true}
    >
      {children}
    </Button>
  </div>
)

export default DatePickerButton
