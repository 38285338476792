import gql from 'graphql-tag'

const GET_MENU_FOR_PRINTING = gql`
  query getMenuForPrinting($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        image
        outlets {
          id
          marketplace {
            id
            name
          }
        }
        menuItemsGroups {
          id
          name
          description
          menuItems {
            id
            name
            isVegan
            isVegetarian
            isGlutenFree
            isKeto
            price
            options {
              id
            }
            archived
            description
          }
        }
      }
    }
  }
`

export default GET_MENU_FOR_PRINTING
