import gql from 'graphql-tag'

const GET_STANDARD_RESTAURANT_DISCOUNTS = gql`
  query getRestaurantsDiscounts($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        isVATregistered
        outlets {
          id
          name
          active
          marketplace {
            id
            enableCustomerV2Client
          }
        }
        discounts(where: { enrolmentKey: null }) {
          id
          key
          name
          discountPercentage
          discountAmount
          startDate
          endDate
          daysOfWeek
          minimumSubtotalGross
          allowedOutlets {
            id
          }
        }
      }
    }
  }
`

export default GET_STANDARD_RESTAURANT_DISCOUNTS
