import { Navigate, Route, Routes } from 'react-router'

import Query from '@components/Query/Query'
import GET_PINPOINT_MARKETPLACES_SLIM from './queries/getPinpointMarketplacesSlim.query'

import ListMarketplaces from './ListMarketplaces'

import CustomerSegmentsContainer from './Segments/CustomerSegmentsContainer'

import CampaignsContainer from './Campaigns/CampaignsContainer'
import FacebookContainer from './Campaigns/FacebookContainer'
import { MarketingIdRedirect } from './Campaigns/IdRedirect'
import Dashboard from './Dashboard'

const Marketing = () => (
  <Query query={GET_PINPOINT_MARKETPLACES_SLIM}>
    {({ getPinpointMarketplaces: { regions } }) => {
      return (
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={
                  regions.length === 1 && regions[0].id
                    ? `/marketing/${regions[0].id}/dashboard`
                    : '/marketing/list'
                }
              />
            }
          />

          <Route path="/:id" element={<MarketingIdRedirect />} />
          <Route path="/list" element={<ListMarketplaces />} />
          <Route path="/:id/segments" element={<CustomerSegmentsContainer />} />
          <Route path="/:id/dashboard" element={<Dashboard />} />
          <Route path="/:id/campaigns" element={<CampaignsContainer />} />
          <Route path="/:id/meta-business" element={<FacebookContainer />} />
        </Routes>
      )
    }}
  </Query>
)

export default Marketing
