import { flatMap } from 'lodash'
import moment from 'moment'

export const removeExpiredSoldOut = outlets => {
  return flatMap(outlets, ({ soldOutMenuItemIds, soldOutUntilItems }) => {
    const expiredSoldOut = soldOutUntilItems
      ? soldOutUntilItems
          .filter(
            ({ soldOutUntil }) =>
              soldOutUntil < moment.parseZone(Date()).utc().format()
          )
          .map(({ id }) => id)
      : []
    return soldOutMenuItemIds.filter(id => !expiredSoldOut.includes(id))
  })
}
