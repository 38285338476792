import {
  Classes,
  ControlGroup,
  Drawer,
  FormGroup,
  HTMLSelect,
  NumericInput,
} from '@blueprintjs/core'
import {
  createEnumParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import { useGetRejectionsHeatmapQuery } from './queries/rejectionsHeatmap.query.generated'
import { DayHourHeatmap } from '../../../components/DayHourHeatmap/DayHourHeatmap'

const fieldDataTypeMapping: Record<
  | 'totalOrders'
  | 'totalValue'
  | 'totalRejectionRate'
  | 'totalRejectedOrders'
  | 'totalRejectedValue'
  | 'manualRejectionRate'
  | 'manualRejectedOrders'
  | 'manualRejectedValue'
  | 'autoRejectionRate'
  | 'autoRejectedOrders'
  | 'autoRejectedValue',
  'number' | 'currency' | 'percentage'
> = {
  totalOrders: 'number',
  totalValue: 'currency',
  totalRejectionRate: 'percentage',
  totalRejectedOrders: 'number',
  totalRejectedValue: 'currency',
  manualRejectionRate: 'percentage',
  manualRejectedOrders: 'number',
  manualRejectedValue: 'currency',
  autoRejectionRate: 'percentage',
  autoRejectedOrders: 'number',
  autoRejectedValue: 'currency',
}

interface Props {
  outletName: string
  businessName: string
}

export const RejectionsHeatmap: React.FC<Props> = ({
  outletName,
  businessName,
}) => {
  const [heatmapField, setHeatmapField] = useQueryParam(
    'heatmapField',
    withDefault(
      createEnumParam([
        'totalOrders',
        'totalValue',
        'totalRejectionRate',
        'totalRejectedOrders',
        'totalRejectedValue',
        'manualRejectionRate',
        'manualRejectedOrders',
        'manualRejectedValue',
        'autoRejectionRate',
        'autoRejectedOrders',
        'autoRejectedValue',
      ] as const),
      'totalRejectionRate'
    )
  )

  const [showingOutlet, setShowingOutlet] = useQueryParam(
    'showingOutlet',
    StringParam
  )

  const [weeks, setWeeks] = useQueryParam(
    'weeks',
    withDefault(NumberParam, 4),
    { removeDefaultsFromUrl: true }
  )

  const { data } = useGetRejectionsHeatmapQuery({
    variables: { outletId: String(showingOutlet), weeks },
    skip: !showingOutlet,
  })

  return (
    <Drawer
      title={`${businessName} - ${outletName}`}
      isOpen={!!showingOutlet}
      onClose={() => {
        setWeeks(undefined)
        setShowingOutlet(undefined)
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <ControlGroup>
            <FormGroup label="Statistic">
              <HTMLSelect
                value={heatmapField ?? ''}
                onChange={event => {
                  // @ts-expect-error this could be any string, but createEnum param will protect us by casting to null/undefined if we don't want it
                  setHeatmapField(event.currentTarget.value)
                }}
              >
                <option disabled value="">
                  Choose
                </option>
                <optgroup label="Total">
                  <option value="totalOrders">Total Orders</option>
                  <option value="totalValue">Total Value</option>
                </optgroup>

                <optgroup label="Rejected">
                  <option value="totalRejectionRate">
                    Total Rejection Rate
                  </option>
                  <option value="totalRejectedOrders">
                    Total Rejected Orders
                  </option>
                  <option value="totalRejectedValue">
                    Total Rejected Value
                  </option>
                </optgroup>

                <optgroup label="Manually Rejected">
                  <option value="manualRejectionRate">
                    Manual Rejection Rate
                  </option>
                  <option value="manualRejectedOrders">
                    Manual Rejected Orders
                  </option>
                  <option value="manualRejectedValue">
                    Manual Rejected Value
                  </option>
                </optgroup>

                <optgroup label="Auto Rejected">
                  <option value="autoRejectionRate">Auto Rejection Rate</option>
                  <option value="autoRejectedOrders">
                    Auto Rejected Orders
                  </option>
                  <option value="autoRejectedValue">Auto Rejected Value</option>
                </optgroup>
              </HTMLSelect>
            </FormGroup>
            <FormGroup label="Weeks to go back">
              <NumericInput
                defaultValue={weeks}
                onValueChange={x => {
                  setWeeks(Number(x))
                }}
              />
            </FormGroup>
          </ControlGroup>
          {data && (
            <DayHourHeatmap
              data={(data.getRejectionsReportHeatmap ?? []).map(row => ({
                hour: row.hour,
                day: row.day,
                value: row[heatmapField],
              }))}
              dataType={fieldDataTypeMapping[heatmapField]}
            />
          )}
        </div>
      </div>
    </Drawer>
  )
}
