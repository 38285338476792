import React, { useState } from 'react'

import { func } from 'prop-types'
import { FormGroup, FileInput, Button } from '@blueprintjs/core'
import { errorToast } from '@utils/toast'
import { Formik } from 'formik'

const MarketplaceApplePayVerificationUpload = ({ onSubmit }) => {
  const [fileName, setFilename] = useState(null)

  const handleFileChange = async (e, setFieldValue) => {
    const file = e.target.files[0]
    // check size
    if (!file) {
      errorToast(`Empty, please choose a file.`)
    } else {
      setFilename(file.name)
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = evt => {
        // attach extension to string
        if (evt.target.error) {
          if (evt.target.readyState != 2) return
          errorToast(`Error while reading file ${fileName}`)
          return
        }
        setFieldValue('applePayVerification', reader.result)
      }
      reader.onerror = () => {
        errorToast("Can't upload file")
      }
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        applePayVerification: null,
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup
            label="Verification File"
            helperText="Upload a valid verification file obtained from Stripe for this domain."
            labelFor="text-input"
          >
            <FileInput
              text={fileName || 'Choose file...'}
              hasSelection={fileName}
              inputProps={{
                name: 'applePayVerification',
              }}
              onInputChange={e => handleFileChange(e, setFieldValue)}
              fill
            />
          </FormGroup>
          <div className="bp-card-footer-actions">
            <Button type="submit" loading={isSubmitting} disabled={!fileName}>
              Upload
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

MarketplaceApplePayVerificationUpload.propTypes = {
  onSubmit: func,
}

export default MarketplaceApplePayVerificationUpload
