import { Tag } from '@blueprintjs/core'
import { ComponentProps } from 'react'

interface Props extends Omit<ComponentProps<typeof Tag>, 'to'> {
  intent?: Intent
}

/** This looks like it duplicates a Blueprint Tag.
 * But actually, it only wraps in a Tag if the `intent` prop is present.
 * Useful in the niche case of not knowing if you want content to be in tags till runtime. (MegaTable)
 */
export const WrapInTag: React.FC<Props> = ({ children, ...otherProps }) => {
  const { intent } = otherProps
  if (intent !== undefined)
    return (
      <Tag {...otherProps} intent={intent}>
        {children}
      </Tag>
    )
  return children
}
