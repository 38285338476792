import React from 'react'
import { lowerCase, startCase } from 'lodash'
import { Title, CenterSection, Text } from '../OrderPrintout'

const DeliveryNotes = ({
  order: { customerDeliveryNotes, fulfillmentMethod },
}) =>
  customerDeliveryNotes && customerDeliveryNotes.length ? (
    <CenterSection>
      <Title as="h4">
        {fulfillmentMethod === 'NETWORK'
          ? 'Delivery'
          : startCase(lowerCase(fulfillmentMethod))}{' '}
        Notes
      </Title>
      <Text>{customerDeliveryNotes}</Text>
    </CenterSection>
  ) : null

export default DeliveryNotes
