import React from 'react'
import { Formik } from 'formik'
import { object, func } from 'prop-types'
import { isPlatformLevelUser } from '@stores/userStore'
import {
  Switch,
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Slider,
} from '@blueprintjs/core'
import ImageUpload from '@components/ImageUpload/ImageUpload'

const TerminalSettingsForm = ({ initialValues = {}, onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        setStatus,
        status,
      }) => (
        <form onSubmit={handleSubmit}>
          <Switch
            label={'Hide Customer Phone Number'}
            checked={values.receiptHideCustomerPhoneNumber}
            onChange={handleChange}
            name="receiptHideCustomerPhoneNumber"
          />
          <Switch
            label={'Hide Marketplace Name'}
            checked={values.receiptHideMarketplace}
            onChange={handleChange}
            name="receiptHideMarketplace"
          />
          <Switch
            label={'Hide Outlet Name'}
            checked={values.receiptHideOutlet}
            onChange={handleChange}
            name="receiptHideOutlet"
          />
          <Switch
            label={'Display Large Order Notes'}
            checked={values.receiptLargeOrderNotes}
            onChange={handleChange}
            name="receiptLargeOrderNotes"
          />
          <Switch
            label={'Display Large Delivery Notes'}
            checked={values.receiptLargeDeliveryNotes}
            onChange={handleChange}
            name="receiptLargeDeliveryNotes"
          />
          <br />

          {isPlatformLevelUser() && (
            <FormGroup
              label="Poll Interval"
              labelInfo="(seconds)"
              helperText="How often should the terminal check for new orders."
            >
              <Slider
                name="pollInterval"
                max={240}
                min={10}
                labelStepSize={40}
                stepSize={20}
                value={values.pollInterval}
                onChange={val => setFieldValue('pollInterval', val)}
              />
            </FormGroup>
          )}

          <FormGroup
            label={'Receipt Message'}
            labelfor="receiptMessage"
            helperText="A message to display at the bottom of a receipt."
          >
            <InputGroup
              id="receiptMessage"
              name="receiptMessage"
              type="text"
              value={values.receiptMessage}
              onChange={handleChange}
            />
          </FormGroup>

          <ImageUpload
            values={values}
            setFieldValue={setFieldValue}
            imageLabel="Receipt Logo"
            status={status}
            setStatus={setStatus}
          />
          <div className="bp-card-footer-actions">
            <Button text="Save" intent={Intent.NONE} type="submit" />
          </div>
        </form>
      )}
    </Formik>
  )
}

TerminalSettingsForm.propTypes = {
  onSubmit: func,
  initialValues: object,
}

export default TerminalSettingsForm
