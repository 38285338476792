import React, { Fragment } from 'react'
import sortBy from 'lodash/sortBy'

import MenuItemGroup from './MenuItemGroup'

interface MenuItemGroupsProps {
  queryId: string
  outlet: boolean
  menuItemsGroups: unknown
  depth: number
  itemType: string
  parentMenuId: string | null
  path: string[]
  outletsSoldOut: string[]
  openMenuGroups: string[]
  toggleOpenOrClosed: (menuId: string) => void
  restaurantId: string
  hiddenMenuItemGroupIds: string[]
  hiddenMenuItemIds: string[]
  outletId: string
}

// used in TREE view
const MenuItemGroups: React.FC<MenuItemGroupsProps> = ({
  queryId,
  outlet,
  menuItemsGroups,
  depth,
  itemType,
  parentMenuId,
  path,
  outletsSoldOut,
  openMenuGroups,
  toggleOpenOrClosed,
  restaurantId,
  hiddenMenuItemGroupIds,
  hiddenMenuItemIds,
  outletId,
}) => (
  <Fragment>
    {sortBy(menuItemsGroups, 'position').map((menu, index) => (
      <MenuItemGroup
        key={menu.id}
        index={index}
        menu={menu}
        menus={menuItemsGroups}
        queryId={queryId}
        parentMenuId={parentMenuId}
        outlet={outlet}
        depth={depth}
        itemType={itemType}
        path={[...path, index]}
        outletsSoldOut={outletsSoldOut}
        openMenuGroups={openMenuGroups}
        toggleOpenOrClosed={toggleOpenOrClosed}
        restaurantId={restaurantId}
        hiddenMenuItemGroupIds={hiddenMenuItemGroupIds}
        hiddenMenuItemIds={hiddenMenuItemIds}
        outletId={outletId}
      />
    ))}
  </Fragment>
)

export default MenuItemGroups
