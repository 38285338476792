import gql from 'graphql-tag'

const ADD_PARTNER = gql`
  mutation partnerAdder(
    $name: String!
    $contactAddress: AddressCreateInput!
    $contactName: String!
    $contactPhone: String!
    $contactEmail: String!
    $companyLegalName: String!
    $cname: String
    $brandColor: String
    $supportTitle: String
    $supportUrl: String
    $supportEmail: String
  ) {
    addPartner(
      name: $name
      contactAddress: $contactAddress
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      contactName: $contactName
      companyLegalName: $companyLegalName
      cname: $cname
      brandColor: $brandColor
      supportTitle: $supportTitle
      supportUrl: $supportUrl
      supportEmail: $supportEmail
    ) {
      message
      partner {
        id
      }
    }
  }
`

export default ADD_PARTNER
