import React from 'react'
import { Section, Text } from '../OrderPrintout'

const OrderInfo = ({ order, formatDateString }) => (
  <Section>
    <Text>Placed at: {formatDateString(order.createdAt)}</Text>
    <Text>
      Accepted at:{' '}
      {order.acceptedAt ? formatDateString(order.acceptedAt) : 'N/A'}
    </Text>
  </Section>
)

export default OrderInfo
