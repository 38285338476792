import gql from 'graphql-tag'

const UPDATE_LOYALTY_CARD_ACTIVE_STATE = gql`
  mutation updateLoyaltyCardActiveState(
    $loyaltyCardId: String!
    $active: Boolean!
    $marketplaceId: String!
  ) {
    updateLoyaltyCardActiveState(
      loyaltyCardId: $loyaltyCardId
      active: $active
      marketplaceId: $marketplaceId
    ) {
      loyaltyCard {
        id
        active
      }
      message
    }
  }
`

export default UPDATE_LOYALTY_CARD_ACTIVE_STATE
