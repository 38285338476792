import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryNetworkFailureReportByMarketplaceQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']['input'];
  deliveryNetworkProvider: Types.Scalars['String']['input'];
}>;


export type GetDeliveryNetworkFailureReportByMarketplaceQuery = { __typename?: 'Query', getDeliveryNetworkFailureReportByMarketplace?: Array<{ __typename?: 'DeliveryNetworkFailureReportByMarketplace', id: string, year: number, month: number, totalOrders: number, totalDeliveryNetworkFailureOrders: number, totalDeliveryNetworkFailureRate: number, totalDeliveryNetworkFailureValue: number, totalOrderValue: number }> | null };


export const GetDeliveryNetworkFailureReportByMarketplaceDocument = gql`
    query GetDeliveryNetworkFailureReportByMarketplace($marketplaceId: String!, $deliveryNetworkProvider: String!) {
  getDeliveryNetworkFailureReportByMarketplace(
    marketplaceId: $marketplaceId
    deliveryNetworkProvider: $deliveryNetworkProvider
  ) {
    id
    year
    month
    totalOrders
    totalDeliveryNetworkFailureOrders
    totalDeliveryNetworkFailureRate
    totalDeliveryNetworkFailureValue
    totalOrderValue
  }
}
    `;

/**
 * __useGetDeliveryNetworkFailureReportByMarketplaceQuery__
 *
 * To run a query within a React component, call `useGetDeliveryNetworkFailureReportByMarketplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryNetworkFailureReportByMarketplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryNetworkFailureReportByMarketplaceQuery({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *      deliveryNetworkProvider: // value for 'deliveryNetworkProvider'
 *   },
 * });
 */
export function useGetDeliveryNetworkFailureReportByMarketplaceQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables> & ({ variables: GetDeliveryNetworkFailureReportByMarketplaceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>(GetDeliveryNetworkFailureReportByMarketplaceDocument, options);
      }
export function useGetDeliveryNetworkFailureReportByMarketplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>(GetDeliveryNetworkFailureReportByMarketplaceDocument, options);
        }
export function useGetDeliveryNetworkFailureReportByMarketplaceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>(GetDeliveryNetworkFailureReportByMarketplaceDocument, options);
        }
export type GetDeliveryNetworkFailureReportByMarketplaceQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByMarketplaceQuery>;
export type GetDeliveryNetworkFailureReportByMarketplaceLazyQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByMarketplaceLazyQuery>;
export type GetDeliveryNetworkFailureReportByMarketplaceSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryNetworkFailureReportByMarketplaceSuspenseQuery>;
export type GetDeliveryNetworkFailureReportByMarketplaceQueryResult = Apollo.QueryResult<GetDeliveryNetworkFailureReportByMarketplaceQuery, GetDeliveryNetworkFailureReportByMarketplaceQueryVariables>;