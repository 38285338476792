import {
  Button,
  Checkbox,
  Classes,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { string, func } from 'prop-types'
import React, { Fragment } from 'react'
import { numberToPence } from '@utils/helpers'

import LoyaltyCardInnerForm from './LoyaltyCardInnerForm'

import LOYALTY_CARDS_BY_MARKETPLACE from './Queries/loyaltyCardsByMarketplace'
import CREATE_LOYALTY_CARD from './Mutations/createLoyaltyCard.mutation'
import { createLoyaltyCardValidation } from './validation'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'

const CreateLoyaltyCard = ({ marketplaceId, drawerClose }) => {
  const [createLoyaltyCard, { loading }] = useMutation(CREATE_LOYALTY_CARD, {
    onCompleted: () => {
      successToast(`Successfully created loyalty card`)
      drawerClose()
    },
    onError: error => {
      defaultErrorHandler(error)
      drawerClose()
    },
    refetchQueries: [
      {
        query: LOYALTY_CARDS_BY_MARKETPLACE,
        variables: {
          marketplaceId,
        },
      },
    ],
  })

  return (
    <div className={Classes.DRAWER_BODY}>
      {loading ? (
        <NonIdealState
          title="Creating Loyalty Card"
          description="Please wait while we create your loyalty card ..."
          icon={<Spinner size={60} />}
        />
      ) : (
        <Formik
          validateOnChange={true}
          validationSchema={createLoyaltyCardValidation}
          initialValues={{
            requiredStamps: 3,
            active: true,
            allowedOutletIds: [],
            name: '',
            discountPercentage: 0,
            discountAmount: 0,
            startDate: null,
            endDate: null,
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            minimumSubtotalGross: 0,
            loyaltyCardIcon: '',
            loyaltyCardColor: '#BCBCBC',
            termsAndConditions: '',
            discountStrategy: 'BASKET_SUBTOTAL_DISCOUNT',
            allowCashOrders: false,
          }}
          onSubmit={async values => {
            await createLoyaltyCard({
              variables: {
                ...values,
                discountName: values.name,
                discountAmount: numberToPence(values.discountAmount),
                minimumSubtotalGross: numberToPence(
                  values.minimumSubtotalGross
                ),
                marketplaceId,
                name: values.name,
                allowCashOrders: values.allowCashOrders,
                startDate: format(
                  new Date(values.startDate),
                  "yyyy-MM-dd'T00:00:00Z'"
                ),
                endDate: format(
                  new Date(values.endDate),
                  "yyyy-MM-dd'T23:59:59Z'"
                ),
              },
            })
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            status,
            setStatus,
          }) => {
            return (
              <div className={Classes.DIALOG_BODY}>
                <form onSubmit={handleSubmit}>
                  <LoyaltyCardInnerForm
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    marketplaceId={marketplaceId}
                    status={status}
                    setStatus={setStatus}
                  />
                  <br />
                  <Checkbox
                    name="active"
                    labelElement={
                      <Fragment>
                        Activated <br />
                        <span className="bp5-text-small bp5-text-muted">
                          {'Stamps can be earned at checkout'}
                        </span>
                      </Fragment>
                    }
                    id="active"
                    checked={values.active}
                    onChange={handleChange}
                    inline
                  />
                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text="Create" type="submit" />
                  </div>
                </form>
              </div>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

CreateLoyaltyCard.propTypes = {
  marketplaceId: string.isRequired,
  drawerClose: func.isRequired,
}
export default CreateLoyaltyCard
