import { Fragment, useContext, useState } from 'react'
import {
  Button,
  Card,
  HTMLTable,
  NonIdealState,
  Tag,
  Tooltip,
} from '@blueprintjs/core'
import Query from '@components/Query/Query'
import GET_DELIVERY_NETWORKS from './queries/getDeliveryNetworks.query'
import EditDeliveryNetworkModal from '@components/DeliveryNetwork/EditDeliveryNetwork.modal'
import {
  OVERLAYS,
  OuterLayoutOverlayContext,
} from '../OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import CopyText from '@components/CopyText/CopyText'
import { useParams } from 'react-router-dom'

const WEBHOOK_STATUS_TO_INTENT_MAP = {
  NOT_APPLICABLE: 'none',
  SUCCESS: 'success',
  FAILURE: 'danger',
  ERROR: 'danger',
}

const tableHead = [
  { key: 'name', content: 'Name' },
  { key: 'provider', content: 'Provider' },
  { key: 'webhookStatus', content: 'Webhook' },
  { key: 'trigger', content: 'Trigger' },
  { key: 'phone', content: 'Phone' },
  { key: 'prefix', content: 'Prefix' },
  { key: 'key', content: 'Key' },
]

const DeliveryNetworks = () => {
  const [modalEditDeliveryNetwork, setModalEditDeliveryNetwork] =
    useState(false)
  const [selectedDeliveryNetworkId, setSelectedDeliveryNetworkId] =
    useState(null)
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  const { marketplace: marketplaceId } = useParams()

  return (
    <Fragment>
      <Query
        query={GET_DELIVERY_NETWORKS}
        variables={{ marketplaceId }}
        loaderTitle={'Loading Delivery Networks'}
      >
        {({ getDeliveryNetworks: { deliveryNetworks } }) => {
          if (deliveryNetworks && deliveryNetworks.length) {
            return (
              <Card className="bp5-nopad bp5-scrollable">
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      {tableHead.map(({ key, content }) => (
                        <th key={key}>{content}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryNetworks.map(
                      ({
                        name,
                        key,
                        id,
                        deliveryProvider,
                        bookingTrigger,
                        helpPhone,
                        namePrefix,
                        webhookStatus,
                      }) => (
                        <tr key={id}>
                          <td width="20%">
                            <a
                              onClick={() => {
                                setModalEditDeliveryNetwork(true)
                                setSelectedDeliveryNetworkId(id)
                              }}
                            >
                              {name}
                            </a>
                          </td>
                          <td width="15%">
                            {deliveryProvider.replaceAll('_', ' ')}
                          </td>
                          <td>
                            <Tooltip
                              content={'An unexpected error occurred'}
                              disabled={webhookStatus !== 'ERROR'}
                            >
                              <Tag
                                minimal
                                intent={
                                  WEBHOOK_STATUS_TO_INTENT_MAP[webhookStatus]
                                }
                              >
                                {webhookStatus.replace(/_/g, ' ')}
                              </Tag>
                            </Tooltip>
                          </td>
                          <td width="10%">
                            <Tag
                              minimal
                              intent={
                                WEBHOOK_STATUS_TO_INTENT_MAP[webhookStatus]
                              }
                            >
                              {bookingTrigger.replace(/_/g, ' ')}
                            </Tag>
                          </td>
                          <td width="10%">
                            {helpPhone ? `+${helpPhone}` : ''}
                          </td>
                          <td width="15%">{namePrefix}</td>
                          <td width="30%">
                            <CopyText minimal mono text={key} />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HTMLTable>
              </Card>
            )
          } else {
            return (
              <NonIdealState
                icon="drive-time"
                title="No Delivery Networks"
                description="Setup how your orders will be fulfilled."
                action={
                  <Button
                    icon="plus"
                    text="Add Delivery Network"
                    onClick={() => openOverlay(OVERLAYS.ADD_DELIVERY_NETWORK)}
                  />
                }
              />
            )
          }
        }}
      </Query>
      {selectedDeliveryNetworkId && (
        <EditDeliveryNetworkModal
          modalOpen={modalEditDeliveryNetwork}
          modalClose={() => {
            setModalEditDeliveryNetwork(false)
            setSelectedDeliveryNetworkId(null)
          }}
          marketplaceId={marketplaceId}
          deliveryNetworkId={selectedDeliveryNetworkId}
        />
      )}
    </Fragment>
  )
}

export default DeliveryNetworks
