import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from '@blueprintjs/core'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import { addLinkCardValidation } from './validation'
import OutletSelectByMarketplace from '@src/components/OutletSelect/OutletSelectByMarketplace'
import { MenuItemSelectByOutlet } from '@src/pages/Marketing/shared/MenuItemSelectByOutlet'

const AddLinkCardForm = ({ marketplaceId, createRequest }) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          marketplaceId,
          name: '',
          url: null,
          image: null,
          type: null,
          menuItemId: null,
          outletId: null,
          description: '',
        }}
        onSubmit={async values => {
          let imageValue = undefined
          if (typeof values.image === 'string') {
            imageValue = values.image
          } else if (values.image.src) {
            imageValue = values.image.src
          }

          await createRequest({
            variables: {
              ...values,
              image: imageValue,
            },
          })
        }}
        validationSchema={addLinkCardValidation}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup label="Title" helperText={(errors && errors.name) || ''}>
              <InputGroup
                name="name"
                type="text"
                value={values.name}
                placeholder="e.g. Saturday Night Favourites"
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup
              intent={errors.type ? Intent.DANGER : Intent.NONE}
              label="Type"
              helperText={(errors && errors.type) || ''}
            >
              <RadioGroup
                onChange={handleChange}
                selectedValue={values.type}
                name="type"
              >
                <Radio label="Link" value={'LINK'} />
                <Radio label="Outlet" value={'OUTLET'} />
                <Radio label="Menu Item" value={'MENU_ITEM'} />
              </RadioGroup>
            </FormGroup>
            {values.type === 'LINK' && (
              <FormGroup
                label="Link"
                labelFor="url"
                labelInfo="(required)"
                helperText={errors.url || ''}
                intent={errors.url ? Intent.DANGER : Intent.NONE}
              >
                <InputGroup
                  name="url"
                  type="text"
                  placeholder="Enter the web address"
                  value={values.url}
                  onChange={handleChange}
                />
              </FormGroup>
            )}
            {(values.type === 'OUTLET' || values.type === 'MENU_ITEM') && (
              <FormGroup
                label="Outlet"
                labelFor="outletId"
                labelInfo="(required)"
                helperText={errors.outletId || ''}
                intent={errors.outletId ? Intent.DANGER : Intent.NONE}
              >
                <OutletSelectByMarketplace
                  outletId={values.outletId}
                  marketplaceId={marketplaceId}
                  setSelected={value => setFieldValue('outletId', value)}
                  disabled={false}
                />
              </FormGroup>
            )}
            {values.type === 'MENU_ITEM' && (
              <FormGroup
                label="Menu Item"
                labelFor="push.menuItemId"
                labelInfo="(required)"
                helperText={errors.menuItemId || ''}
                intent={errors.menuItemId ? Intent.DANGER : Intent.NONE}
              >
                <MenuItemSelectByOutlet
                  disabled={false}
                  outletId={values.outletId}
                  menuItemId={values.menuItemId}
                  setSelected={value => setFieldValue('menuItemId', value)}
                />
              </FormGroup>
            )}

            <FormGroup
              helperText={(errors && errors.image) || ''}
              intent={errors.image ? Intent.DANGER : Intent.NONE}
            >
              <ImageUploadDirectSingle
                imageName={'image'}
                values={values}
                setFieldValue={setFieldValue}
                imageLabel="Image"
                sizeLimit={1000000}
                replace={true}
                helperText="(required)"
                setStatus={undefined}
                optionItemId={undefined}
                marketplaceId={marketplaceId}
              />
            </FormGroup>
            <FormGroup
              label="Description"
              helperText={
                (errors && errors.description) ||
                "Description shows underneath your link card's title."
              }
              labelInfo="400 Character Limit"
              intent={
                errors && errors.description ? Intent.DANGER : Intent.NONE
              }
            >
              <TextArea
                onChange={handleChange}
                name="description"
                style={{ resize: 'none', height: '60px' }}
                fill={true}
                id="description"
                value={values.description || ''}
              />
            </FormGroup>

            <div>
              <div style={{ float: 'right' }}>
                <Button intent={Intent.PRIMARY} text="Create" type="submit" />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Fragment>
  )
}

export default AddLinkCardForm
