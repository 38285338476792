import gql from 'graphql-tag'

const DELETE_PARTNER_WEBHOOKS = gql`
  mutation deletePartnerPaymentWebhooks($id: ID!) {
    deletePartnerPaymentWebhooks(id: $id) {
      id
      message
    }
  }
`

export default DELETE_PARTNER_WEBHOOKS
