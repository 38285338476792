import styled from 'styled-components'

const ButtonStyled = styled.button`
  border: none;
  box-shadow: none;
  background: transparent;

  &:hover {
    cursor: 'pointer';
  }
`

const LinkStyledButton: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className = 'bp5-link-text', ...buttonProps }) => (
  <ButtonStyled className={className} {...buttonProps}>
    {children}
  </ButtonStyled>
)

export default LinkStyledButton
