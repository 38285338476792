import { object } from 'prop-types'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { successToast } from '@utils/toast'
import { modalType } from '@utils/types'
import get from 'lodash/get'
import castArray from 'lodash/castArray'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import UserForm from '@components/UserForm/UserForm'
import EDIT_USER from '@components/Users/mutations/editUser.mutation'
import INVITE_USER from '@components/Users/mutations/inviteUser.mutation'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'
import { useMutation } from '@apollo/client'

const UsersModal = ({ modalOpen, modalClose, user }) => {
  const [editUser] = useMutation(EDIT_USER, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('User successfully edited')
      modalClose()
    },
  })

  const [inviteUser] = useMutation(INVITE_USER, {
    onError: defaultErrorHandler,
    onCompleted: () => {
      successToast('User successfully invited')
      modalClose()
    },
    refetchQueries: [{ query: GET_ALL_USERS }],
  })

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title={user && user.id ? 'Edit User' : 'Invite User'}
    >
      <div className={Classes.DIALOG_BODY}>
        {user && user.id ? (
          <UserForm
            initialValues={{
              ...user,
              roleKey: user.role.key,
              ownsPlatformIds: castArray(get(user.ownsPlatforms, '[0].id', [])),
              ownsPartnerIds: get(user, 'ownsPartners', []).map(({ id }) => id),
              ownsRestaurantIds: get(user, 'ownsRestaurants', []).map(
                ({ id }) => id
              ),
              ownsOutletIds: get(user, 'ownsOutlets', []).map(({ id }) => id),
              ownsMarketplaceIds: get(user, 'ownsMarketplaces', []).map(
                ({ id }) => id
              ),
            }}
            onSubmit={values =>
              editUser({
                variables: {
                  ...values,
                  roleKey: values.role.key,
                  roleTitle: values.role.title,
                },
              })
            }
          />
        ) : (
          <UserForm
            initialValues={{
              email: '',
              role: {
                title: '',
                key: '',
                id: '',
              },
              ownsPlatformIds: [],
              ownsPartnerIds: [],
              ownsRestaurantIds: [],
              ownsOutletIds: [],
              ownsMarketplaceIds: [],
            }}
            onSubmit={values =>
              inviteUser({
                variables: {
                  ...values,
                  roleKey: values.role.key,
                },
              })
            }
          />
        )}
      </div>
    </Dialog>
  )
}

UsersModal.propTypes = {
  ...modalType,
  user: object,
}

export default UsersModal
