import gql from 'graphql-tag'

const GET_MARKETPLACE_META_BY_ID = gql`
  query getMarketplaceMetaById($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        faviconImage
        emailSenderUsername
        cname
        name
        allowSMSMarketing
        allowFacebookMarketing
        allowBilling
        smsSenderId
        facebookLastSyncTotalCustomers
        facebookLastSyncDate
        billingStatus {
          id
          active
          services
        }
        facebookAudienceId
        partner {
          id
          name
          marketingSMSFee
        }
      }
    }
  }
`

export default GET_MARKETPLACE_META_BY_ID
