import { AnchorButton, Callout, Intent } from '@blueprintjs/core'
import { GetMarketplacePaymentsQuery } from './queries/getMarketplacePayments.query.generated'

const MissingData = ({
  fieldName,
  url,
}: {
  fieldName: string
  url: string
}) => (
  <Callout intent={Intent.WARNING} title={`Missing ${fieldName}.`}>
    Please ensure your Business details are correct to proceed with setup.
    <AnchorButton
      text="Update Information"
      outlined
      minimal
      intent={Intent.WARNING}
      href={url}
      style={{ margin: '10px 0px 4px 0px' }}
    />
  </Callout>
)

export const MissingDataIndicator: React.FC<{
  marketplace: Pick<
    GetMarketplacePaymentsQuery['getMarketplaces']['regions'][0],
    'contactAddress' | 'companyLegalName' | 'contactEmail' | 'id'
  >
}> = ({ marketplace }) => {
  if (!marketplace.contactAddress)
    return (
      <MissingData
        fieldName="company address"
        url={`/marketplaces/${marketplace.id}/details`}
      />
    )
  if (!marketplace.companyLegalName)
    return (
      <MissingData
        fieldName="company name"
        url={`/marketplaces/${marketplace.id}/details`}
      />
    )

  if (!marketplace.contactEmail)
    return (
      <MissingData
        fieldName="company email"
        url={`/marketplaces/${marketplace.id}/details`}
      />
    )
}
