import { useEffect, useState } from 'react'
import { Tree, Card, Icon, Callout, Button } from '@blueprintjs/core'
import { array, string } from 'prop-types'
import { successToast } from '@utils/toast'
import EDIT_MENU_ITEM_OPTION_ORDER from '../mutations/editMenuItemPosition.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'
import { swapAdjacentArrayItems } from '@utils/swapAdjacentArrayItems'
import { useMutation } from '@apollo/client'

const OptionList = ({ options, menuItemId }) => {
  const [nodes, setNodes] = useState(options)

  useEffect(() => {
    setNodes(options)
  }, [options])

  const [editMenuItemOptionOrder] = useMutation(EDIT_MENU_ITEM_OPTION_ORDER, {
    onError: defaultErrorHandler,
    onCompleted: ({ editMenuItemOptionOrder }) => {
      successToast(editMenuItemOptionOrder.message)
    },
    refetchQueries: [
      {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ],
  })

  const handleNodeExpand = node => {
    node.isExpanded = true
    setNodes([...nodes])
  }

  const handleNodeCollapse = node => {
    node.isExpanded = false
    setNodes([...nodes])
  }

  const handleChangeIndex = (index, direction) => {
    const reorderedOptions = swapAdjacentArrayItems(options, index, direction)
    const reorderedOptionIds = reorderedOptions.map(({ id }) => id)
    editMenuItemOptionOrder({
      variables: {
        id: menuItemId,
        optionPositions: reorderedOptionIds,
      },
    })
  }

  return options.length > 0 ? (
    <Card className="bp5-nopad">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '92%' }}>
          <Tree
            contents={nodes}
            onNodeExpand={handleNodeExpand}
            onNodeCollapse={handleNodeCollapse}
          />
        </div>
        <div>
          {options.map((option, index) => (
            <div key={option.id}>
              <Button
                disabled={index === 0}
                icon="chevron-up"
                onClick={() => handleChangeIndex(index, 'up')}
                minimal
              />
              <Button
                disabled={index === options.length - 1}
                icon="chevron-down"
                onClick={() => handleChangeIndex(index, 'down')}
                minimal
              />
            </div>
          ))}
        </div>
      </div>
    </Card>
  ) : (
    <>
      <Callout>
        <Icon icon="properties" /> Set up options for this item so the order can
        be customized.
      </Callout>
      <br />
    </>
  )
}

OptionList.propTypes = {
  options: array,
  restaurantId: string,
  menuItemId: string,
}

export default OptionList
