import gql from 'graphql-tag'

const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($id: String!) {
    deleteDiscount(id: $id) {
      message
      discount {
        id
        name
      }
    }
  }
`

export default DELETE_DISCOUNT
