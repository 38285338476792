import * as yup from 'yup'
import { IIterableInterface } from './generic-validator'

export const userPasswordResetStructure: IIterableInterface = {
  email: yup.string().email('The email is not in a valid format'),
  token: yup.string().required('The reset token is required'),
  newPassword: yup
    .string()
    .required()
    .min(8, 'The password should be at least 8 characters long'),
  passwordConfirm: yup
    .string()
    .required()
    .test('passwordMatch', 'Passwords should match.', function () {
      const { newPassword, passwordConfirm } = this.parent

      return newPassword === passwordConfirm
    }),
}

export const customerPasswordResetStructure: IIterableInterface = {
  customerId: yup.string().required('The customerId is required'),
  token: yup.string().required('The reset token is required'),
  newPassword: yup
    .string()
    .required()
    .min(8, 'The password should be at least 8 characters long'),
}
