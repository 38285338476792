import gql from 'graphql-tag'

export default gql`
  query reconcile(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $partnerIds: [ID!]
  ) {
    reconcile(
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
      partnerIds: $partnerIds
    ) {
      id
      orderId
      orderNumber
      transactionStripeId
      marketplace {
        id
        name
      }
      outlet {
        id
        name
        active
        isOnline
      }
      restaurant {
        id
        name
        active
      }
      type
      refundedAt
      acceptedAt
      totalToOutlet
      totalToMarketplace
      totalToPartner
      totalToPaybox
      totalToRedbox
      totalPaidToOutlet
      totalPaidToMarketplace
      totalPaidToPartner
      totalPaidToPaybox
      totalPaidToRedbox
    }
  }
`
