import { Spinner, NonIdealState } from '@blueprintjs/core'
import React from 'react'
import Query from '@components/Query/Query'
import DiscountAnalytics from '../Analytics/DiscountAnalytics'
import NonIdealStateSmaller from '../Analytics/NonIdealStateSmaller'
import { transformDiscountsRawData } from '../Analytics/util/transformDiscountsRawData'
import ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_LOYALTY_CARD from './Queries/marketplaceOrdersWithLoyaltyCard'

const LoyaltyCardAnalytics = ({ marketplaceId, discountId }) => {
  return (
    <div>
      <Query
        query={ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_LOYALTY_CARD}
        variables={{
          marketplaceId,
          discountId,
        }}
        loaderTitle="Loading Analytics"
      >
        {({ marketplaceOrdersWithLoyaltyCard: data, loading, error }) => {
          if (loading && !data) {
            return <Spinner />
          }

          if (error) {
            return (
              <NonIdealState
                icon="error"
                title="Unable to load analytics data"
              />
            )
          }

          if (!data.length) return <NonIdealStateSmaller isLoyaltyCard={true} />

          const transformedData = transformDiscountsRawData(data)

          return (
            <DiscountAnalytics
              {...transformedData}
              discountId={discountId}
              showFilterRow={false}
              isLoyaltyCardDrawer={true}
            />
          )
        }}
      </Query>
    </div>
  )
}

export default LoyaltyCardAnalytics
