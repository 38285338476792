import gql from 'graphql-tag'

export const MENU_ITEM_FIELDS_AND_OPTION_ID_FRAGMENT = gql`
  fragment menuItemFieldsAndOptionId on MenuItem {
    id
    SKU
    UPC
    name
    isVegan
    isVegetarian
    isDairyFree
    isGlutenFree
    spiceLevel
    isKeto
    ageRestricted
    price
    costPrice
    minimumPurchasePrice
    options {
      id
    }
    description
  }
`
