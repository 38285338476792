import gql from 'graphql-tag'

const GET_LIVE_SEGMENT_ESTIMATE = gql`
  query getLiveSegmentEstimate(
    $marketplaceId: ID!
    $filterGroupsType: String!
    $filterGroups: [FilterGroupInput!]!
  ) {
    getLiveSegmentEstimate(
      marketplaceId: $marketplaceId
      filterGroupsType: $filterGroupsType
      filterGroups: $filterGroups
    ) {
      estimate
    }
  }
`

export default GET_LIVE_SEGMENT_ESTIMATE
