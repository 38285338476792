import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_RESTAURANT from './mutations/editRestaurant.mutation'
import DetailsForm from './DetailsForm'
import { useMutation } from '@apollo/client'

const RestaurantDetails = ({
  restaurant,
  cuisines,
  categoryEmojiOrImage,

  allowOtterIntegration,
}) => {
  const [editRestaurant] = useMutation(EDIT_RESTAURANT, {
    onCompleted: ({ editRestaurant }) => {
      if (editRestaurant.restaurant.active) {
        successToast(
          'Business has been successfully activated, please check your Outlets.'
        )
      } else {
        successToast(editRestaurant.message)
      }
    },
    onError: defaultErrorHandler,
  })
  return (
    <DetailsForm
      restaurant={restaurant}
      editRestaurantMutation={editRestaurant}
      cuisineList={cuisines}
      categoryEmojiOrImage={categoryEmojiOrImage}
      allowOtterIntegration={allowOtterIntegration}
    />
  )
}

export default RestaurantDetails
