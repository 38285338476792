import { Button, Intent } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { useState } from 'react'
import ConfirmationPopover from '../ConfirmationPopover/ConfirmationPopover'

import UPDATE_LOYALTY_CARD_ACTIVE_STATE from './Mutations/updateLoyaltyCardActiveState'
import { useMutation } from '@apollo/client'

const UpdateLoyaltyCardActiveState = ({
  active,
  loyaltyCardId,
  marketplaceId,
}) => {
  const [loyaltyCardActive, setLoyaltyCardActive] = useState(active)

  const [updateLoyaltyCardActiveState, { loading }] = useMutation(
    UPDATE_LOYALTY_CARD_ACTIVE_STATE,
    {
      onError: defaultErrorHandler,
      onCompleted: () => {
        successToast('Successfully updated the loyalty card active state.')
        setLoyaltyCardActive(!loyaltyCardActive)
      },
    }
  )

  return (
    <div>
      {loyaltyCardActive ? (
        <ConfirmationPopover
          buttonTitle="Deactivate"
          confirmationText={`Are you sure you want to deactivate this loyalty card? Once the loyalty card is deactivated, the loyalty card will no longer be visible and customers will not be able to earn stamps or redeem any outstanding rewards for this loyalty card.
               \n\n We suggest you contact your customers before deactivating this card.`}
          remove={() => {
            updateLoyaltyCardActiveState({
              variables: {
                loyaltyCardId: loyaltyCardId,
                marketplaceId: marketplaceId,
                active: false,
              },
            })
          }}
        >
          <Button
            text="Deactivate"
            type="button"
            disabled={loading}
            intent={Intent.DANGER}
            minimal
          />
        </ConfirmationPopover>
      ) : (
        <Button
          text="Activate"
          type="button"
          intent={Intent.SUCCESS}
          disabled={loading}
          onClick={() => {
            updateLoyaltyCardActiveState({
              variables: {
                loyaltyCardId: loyaltyCardId,
                marketplaceId: marketplaceId,
                active: true,
              },
            })
          }}
          minimal
        />
      )}
    </div>
  )
}

export default UpdateLoyaltyCardActiveState
