import gql from 'graphql-tag'

const ADD_OPTION_TO_MENU_ITEM = gql`
  mutation addOptionToMenuItem($id: String!, $optionIds: [String]) {
    addOptionToMenuItem(id: $id, optionIds: $optionIds) {
      message
      menuItem {
        options {
          id
          name
        }
      }
    }
  }
`

export default ADD_OPTION_TO_MENU_ITEM
