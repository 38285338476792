import React, { useCallback, useEffect, useContext, useState } from 'react'
import Nav from '@components/Nav/Nav'
import Header from '@components/Header/Header'
import Order from '@components/Orders/Order/Order'
import {
  object,
  func,
  string,
  bool,
  oneOfType,
  arrayOf,
  node,
  shape,
} from 'prop-types'
import { Drawer, Classes } from '@blueprintjs/core'
import config from '@src/config'
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import ls from '@utils/localStorage'
// import * as Sentry from '@sentry/react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Outlet } from 'react-router-dom'

const defaultLayoutArgs = {
  product: {},
  button: undefined,
  permissions: true,
  tabs: [],
  persistQueryParamsBetweenTabs: false,
  dark: false,
}
export const PageLayoutContext = React.createContext({
  ...defaultLayoutArgs,
  configurePageLayout: _args => {},
  switchDarkMode: _arg => {},
})

const PageLayoutContextProvider = ({ children }) => {
  const [pageLayoutArgs, setPageLayoutArgs] = useState(defaultLayoutArgs)

  const configurePageLayout = useCallback(
    args => {
      setPageLayoutArgs({
        ...defaultLayoutArgs,
        ...args,
        product: args.product || {},
        dark: pageLayoutArgs.dark,
      })
    },
    [pageLayoutArgs.dark]
  )

  const switchDarkMode = useCallback(dark => {
    // use ls as a backing to persist between hard reloads
    ls.set('darkMode', dark)

    document.body.className = dark ? 'bp5-dark' : ''

    setPageLayoutArgs(pageLayoutArgs => ({ ...pageLayoutArgs, dark }))
  }, [])

  return (
    <PageLayoutContext.Provider
      value={{ ...pageLayoutArgs, configurePageLayout, switchDarkMode }}
    >
      {children}
    </PageLayoutContext.Provider>
  )
}

const PageLayoutInner = ({ children }) => {
  const [viewOrder, onChangeViewOrder] = useQueryParam('viewOrder', StringParam)
  const {
    product,
    button,
    permissions,
    tabs,
    persistQueryParamsBetweenTabs,
    dark,
    switchDarkMode,
  } = useContext(PageLayoutContext)

  useEffect(() => {
    const lsDarkMode = ls.get('darkMode')
    if (lsDarkMode !== dark) {
      switchDarkMode(lsDarkMode)
    }
  }, [dark, switchDarkMode])

  useEffect(() => {
    // Log page events to Sentry.
    if (location && product && location.pathname && product.name) {
      try {
        // Sentry.configureScope(scope =>
        //   scope.setTransactionName(`${config.apiUrl}${location.pathname}`)
        // )
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Sentry Failed.')
      }
    }
  }, [location, location.pathname, product, product.name])

  return (
    <div className={dark ? 'bp5-dark' : ''}>
      <ErrorBoundary>
        <Header
          product={product}
          button={button}
          permissions={permissions}
          tabs={tabs}
          persistQueryParamsBetweenTabs={persistQueryParamsBetweenTabs}
        />
        <Nav dark={dark} />
        <Drawer
          isOpen={viewOrder}
          onClose={() => {
            onChangeViewOrder(null)
          }}
          title={`Order Details`}
          autoFocus={true}
          size={`${window.innerWidth > 1100 ? '1000px' : '90%'}`}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          className={dark ? 'bp5-dark' : ''}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
              <Order id={viewOrder} />
            </div>
          </div>
        </Drawer>
        <main>
          <section className="mainBodyContainer">
            <Outlet />
          </section>
        </main>
      </ErrorBoundary>
    </div>
  )
}

PageLayoutInner.propTypes = {
  children: oneOfType([arrayOf(node), node]),
}

export const PageLayout = () => (
  <PageLayoutContextProvider>
    <PageLayoutInner />
  </PageLayoutContextProvider>
)

PageLayout.propTypes = {
  button: shape({
    text: string,
    action: func,
  }),
  permissions: bool,
  tabs: arrayOf(object),
  persistQueryParamsBetweenTabs: bool,
  children: oneOfType([arrayOf(node), node]),
  viewOrder: string,
}
