import React, { Fragment, useContext } from 'react'
import { NonIdealState } from '@blueprintjs/core'
import { PageLayoutContext } from '@src/components/PageLayout/PageLayout'
import Nav from '@src/components/Nav/Nav'
import productDictionary from '@src/utils/productDictionary'
import Header from '@src/components/Header/Header'

export const NoPermissions = ({ inline = false }) => {
  const { dark } = useContext(PageLayoutContext)

  return (
    <div className={dark ? 'bp5-dark' : ''}>
      {!inline && (
        <>
          <Header product={productDictionary.UNAUTHORISED} />
          <Nav dark={dark} />
        </>
      )}

      <main>
        <section className="mainBodyContainer">
          <div
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NonIdealState
              icon="blocked-person"
              title="Unauthorised"
              description={
                <Fragment>
                  You do not have permission to access this page, please contact
                  your marketplace administrator or{' '}
                  <a href="mailto:support@redbox.systems">
                    support@redbox.systems
                  </a>
                </Fragment>
              }
            />
          </div>
        </section>
      </main>
    </div>
  )
}
