import { useContext, useEffect, Fragment, useState } from 'react'
import moment from 'moment'
import {
  Card,
  H1,
  H3,
  H5,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
  NonIdealState,
  Callout,
  Button,
} from '@blueprintjs/core'
import { Row, Col } from '@components/_FlexGrid'
import { last, takeRight, sumBy } from 'lodash'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import GET_MARKETING_METRICS from '@src/pages/Marketing/queries/getMarketingMetrics.query'
import LineGraph from '@components/Analytics/Graphs/LineGraph'
import MarketingDialog from '@components/Billing/dialogs/Marketing.dialog'
import { useNavigate, useParams } from 'react-router-dom'
import productDictionary from '../../utils/productDictionary'
import Query from '@src/components/Query/Query'
import {
  GetMarketingMetricsQuery,
  GetMarketingMetricsQueryVariables,
} from './queries/getMarketingMetrics.query.generated'

const PERIOD_DATE_FORMAT = 'Do MMM'
const TOOLTIP_DATE_FORMAT = 'ddd Do MMM'

const Dashboard = () => {
  const { id: marketplaceId } = useParams()
  const { configurePageLayout } = useContext(PageLayoutContext)
  const [paywallModalOpen, setPaywallModalOpen] = useState(false)
  const navigate = useNavigate()

  const startDate = moment().startOf('day').subtract(30, 'days')
  const endDate = moment()
  const startDateString = startDate.format(PERIOD_DATE_FORMAT)
  const endDateString = endDate.format(PERIOD_DATE_FORMAT)

  useEffect(() => {
    configurePageLayout({
      product: productDictionary.MARKETING,
      tabs: [
        { to: `/marketing/${marketplaceId}/dashboard`, name: 'Dashboard' },
        { to: `/marketing/${marketplaceId}/segments`, name: 'Segments' },
        { to: `/marketing/${marketplaceId}/campaigns`, name: 'Campaigns' },
        {
          to: `/marketing/${marketplaceId}/meta-business`,
          name: 'Meta Business',
        },
      ],
    })
  }, [configurePageLayout, marketplaceId])

  return (
    <Query<GetMarketingMetricsQuery, GetMarketingMetricsQueryVariables>
      query={GET_MARKETING_METRICS}
      variables={{
        marketplaceId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }}
      loaderTitle="Loading Dashboard"
      loaderSubTitle="Please wait..."
      ErrorComponent={
        <NonIdealState
          icon="error"
          title="Failed to connected to notification service"
        />
      }
    >
      {data => {
        const {
          getMarketingMetrics: { metrics },
          getMarketplaces: { regions },
        } = data

        const billingStatus = regions.find(
          r => r.id === marketplaceId
        ).billingStatus

        return (
          <div>
            <MarketingDialog
              isOpen={paywallModalOpen}
              marketplaceId={marketplaceId}
              usePortal={true}
              paywall={true}
            />
            <Row gutter={24}>
              {billingStatus &&
                metrics.allEndpoints.ALL > 1000 &&
                !billingStatus.services.includes('MARKETING') && (
                  <Col span={12}>
                    <Callout icon="crown">
                      Time to upgrade. Add a Marketing Plan as you now have over
                      1000+ Active Channels.
                      <Button
                        style={{ position: 'absolute', top: 5, right: 5 }}
                        intent="success"
                        minimal
                        icon="cube-add"
                        onClick={() => setPaywallModalOpen(true)}
                      >
                        Add Marketing Plan
                      </Button>
                    </Callout>
                    <br />
                  </Col>
                )}

              <Col xs={12} md={6}>
                <Card>
                  <Row>
                    <Col span={6}>
                      <H5>
                        Active Marketing Channels{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                A channel represents the method through which
                                you engage your audience messages. Customer may
                                have multiple channels, eg Push, Email and SMS.
                              </p>
                              <p>
                                At Redbox, we base Marketing billing on Active
                                Channels. This is because we maintain a
                                dedicated connection (or endpoint) for each
                                channel, and this results in associated costs.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={3} md={3}>
                      <H1 style={{ color: '#1968F6' }}>
                        {metrics.allEndpoints.ALL.toLocaleString()}
                      </H1>
                      <p>Total</p>
                    </Col>
                    <Col span={3} md={2}>
                      <H3 style={{ color: '#738694', marginTop: 15 }}>
                        {metrics.allEndpoints.EMAIL.toLocaleString()}
                      </H3>
                      Email
                    </Col>
                    <Col span={3} md={2}>
                      <H3 style={{ color: '#738694', marginTop: 15 }}>
                        {metrics.allEndpoints.PUSH.toLocaleString()}
                      </H3>
                      Push
                    </Col>
                    <Col span={3} md={2}>
                      <H3 style={{ color: '#738694', marginTop: 15 }}>
                        {metrics.allEndpoints.SMS.toLocaleString()}
                      </H3>
                      SMS
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card
                  interactive
                  onClick={() => {
                    navigate(`/marketing/${marketplaceId}/segments`)
                  }}
                >
                  <Fragment>
                    <Row>
                      <Col span={6}>
                        <H5>Segments</H5>
                      </Col>
                      <Col span={6}>
                        <p
                          className={'bp5-text-muted'}
                          style={{ textAlign: 'right' }}
                        >
                          <Icon icon="arrow-right" />
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={3} md={3}>
                        <H1 style={{ color: '#1968F6' }}>
                          {metrics.segmentsCount.toLocaleString()}
                        </H1>
                        <p>Total</p>
                      </Col>
                    </Row>
                  </Fragment>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card
                  interactive
                  onClick={() => {
                    navigate(`/marketing/${marketplaceId}/campaigns`)
                  }}
                >
                  <Fragment>
                    <Row>
                      <Col span={6}>
                        <H5>Campaigns</H5>
                      </Col>
                      <Col span={6}>
                        <p
                          className={'bp5-text-muted'}
                          style={{ textAlign: 'right' }}
                        >
                          <Icon icon="arrow-right" />
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={3} md={3}>
                        <H1 style={{ color: '#1968F6' }}>
                          {metrics.campaignsCount.toLocaleString()}
                        </H1>
                        <p>Total</p>
                      </Col>
                    </Row>
                  </Fragment>
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} md={6}>
                <Card
                  style={{
                    height: 308,
                    width: 'max-content',
                    minWidth: '100%',
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <H5>
                        Daily Active Users{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                The daily customer activity chart shows the
                                number of customers who opened your app at least
                                once during a 24-hour period for each day.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        {startDateString} - {endDateString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {last(metrics.activeUsers).value.toLocaleString()}
                      </H1>
                      <p>Today</p>
                    </Col>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {(
                          sumBy(takeRight(metrics.activeUsers, 7), 'value') / 7
                        ).toFixed(1)}
                      </H1>
                      7 Day Average
                    </Col>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {(
                          sumBy(takeRight(metrics.activeUsers, 30), 'value') /
                          30
                        ).toFixed(1)}
                      </H1>
                      30 Day Average
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <LineGraph
                        data={metrics.activeUsers.map(({ date, value }) => {
                          return {
                            x: date,
                            y: value,
                          }
                        })}
                        tooltipDateFormat={TOOLTIP_DATE_FORMAT}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card
                  style={{
                    height: 308,
                    width: 'max-content',
                    minWidth: '100%',
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <H5>
                        New Users{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                The new users chart shows the number of new
                                users that signed up each day.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        {startDateString} - {endDateString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {last(metrics.newUsers).value.toLocaleString()}
                      </H1>
                      <p>Today</p>
                    </Col>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {(
                          sumBy(takeRight(metrics.newUsers, 7), 'value') / 7
                        ).toFixed(1)}
                      </H1>
                      7 Day Average
                    </Col>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {(
                          sumBy(takeRight(metrics.newUsers, 30), 'value') / 30
                        ).toFixed(1)}
                      </H1>
                      30 Day Average
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <LineGraph
                        data={metrics.newUsers.map(({ date, value }) => {
                          return {
                            x: date,
                            y: value,
                          }
                        })}
                        tooltipDateFormat={TOOLTIP_DATE_FORMAT}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={12} md={6}>
                <Card
                  style={{
                    height: 308,
                    width: 'max-content',
                    minWidth: '100%',
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <H5>
                        Monthly Active Users{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                The monthly customer activity chart shows the
                                number of users that opened your app at some
                                point in the preceding 30 days for each day.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        {startDateString} - {endDateString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <H1 style={{ color: '#1968F6' }}>
                        {last(
                          metrics.monthlyActiveUsers
                        ).value.toLocaleString()}
                      </H1>
                      <p>Last 30 Days</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <LineGraph
                        data={metrics.monthlyActiveUsers.map(
                          ({ date, value }) => {
                            return {
                              x: date,
                              y: value,
                            }
                          }
                        )}
                        tooltipDateFormat={TOOLTIP_DATE_FORMAT}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card
                  style={{
                    height: 308,
                    width: 'max-content',
                    minWidth: '100%',
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <H5>
                        Customer Retention{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                The retention rate chart shows the percentage of
                                customers who open your app at least once a
                                week.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        {startDateString} - {endDateString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <H1 style={{ color: '#1968F6' }}>
                        {
                          metrics.retentionRate[
                            metrics.retentionRate.length - 2
                          ].value
                        }
                        %
                      </H1>
                      <p>Retained</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <LineGraph
                        symbol={{ y: '%' }}
                        tooltipDateFormat={TOOLTIP_DATE_FORMAT}
                        data={metrics.retentionRate.map(({ date, value }) => {
                          return {
                            x: date,
                            y: value,
                          }
                        })}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} md={6}>
                <Card
                  style={{
                    height: 308,
                    width: 'max-content',
                    minWidth: '100%',
                  }}
                >
                  <Row>
                    <Col span={6}>
                      <H5>
                        Emails Opened{' '}
                        <Popover
                          interactionKind={PopoverInteractionKind.HOVER}
                          position={Position.RIGHT_TOP}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          content={
                            <Fragment>
                              <H5>Information</H5>
                              <p>
                                The emails opened graph shows how many emails
                                have been opened by users, this includes
                                transactional emails.
                              </p>
                            </Fragment>
                          }
                        >
                          <Icon icon="help" color="#ced9e0" />
                        </Popover>
                      </H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        {startDateString} - {endDateString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <H1 style={{ color: '#1968F6' }}>
                        {last(metrics.emailMetrics).opened}
                      </H1>
                      <p>Today</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <LineGraph
                        data={metrics.emailMetrics.map(({ date, opened }) => {
                          return {
                            x: date,
                            y: opened,
                          }
                        })}
                        tooltipDateFormat={TOOLTIP_DATE_FORMAT}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        )
      }}
    </Query>
  )
}

export default Dashboard
