import gql from 'graphql-tag'

const GET_TERMINAL = gql`
  query getTerminalById($ids: [ID]) {
    getTerminals(ids: $ids) {
      terminals {
        id
        friendlyName
        serialNumber
        softwareVersion
        alarm
        slave
        editableMessages
        receiptGroupItems
        outlets {
          id
          restaurant {
            id
          }
        }
      }
    }
  }
`

export default GET_TERMINAL
