import get from 'lodash/get'
import { func, object } from 'prop-types'
import {
  Intent,
  Card,
  FormGroup,
  InputGroup,
  ControlGroup,
  Button,
  HTMLSelect,
  Tag,
  H4,
  H5,
  Icon,
} from '@blueprintjs/core'
import { countryList, selectionUpdate } from '@utils/helpers'
import { Formik } from 'formik'
import { validation } from './validation'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import { Row, Col } from '@components/_FlexGrid'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { penceToPounds, numberToPence } from '@utils/helpers'
import { omit } from 'lodash'
import { canPerformAction } from '@stores/userStore'
import CLEAR_REDIS_CACHE from '@components/Platform/mutations/clearRedisCache'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import CopyText from '@components/CopyText/CopyText'
import { useMutation } from '@apollo/client'

/**
 * GeneralSettings component for editing platform settings.
 *
 * @param {object} props
 * @param {function} props.editPlatform - Function to edit the platform.
 * @param {import('../../../../generated/graphql-types').Platform} props.platform - Current platform data.
 */
const GeneralSettings = ({ editPlatform, platform }) => {
  const [clearRedisCache] = useMutation(CLEAR_REDIS_CACHE, {
    onCompleted: ({ clearRedisCache: { message } }) => successToast(message),
  })

  return (
    <Formik
      onSubmit={values =>
        editPlatform({
          variables: {
            ...values,
            stripeChargebackCharge: numberToPence(
              values.stripeChargebackCharge || 0
            ),
            stripePayoutCharge: numberToPence(values.stripePayoutCharge || 0),
            stripeConnectCharge: numberToPence(values.stripeConnectCharge || 0),
            stripeCharge: numberToPence(values.stripeCharge || 0),
            stripePresentCharge: numberToPence(values.stripePresentCharge || 0),
          },
        })
      }
      initialValues={{
        ...omit(platform, ['contactAddress.id']),
        stripeChargebackCharge: penceToPounds(
          platform.stripeChargebackCharge || 0
        ),
        stripePayoutCharge: penceToPounds(platform.stripePayoutCharge || 0),
        stripeConnectCharge: penceToPounds(platform.stripeConnectCharge || 0),
        stripeCharge: penceToPounds(platform.stripeCharge || 0),
        stripePresentCharge: penceToPounds(platform.stripePresentCharge || 0),
      }}
      validationSchema={validation}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        values,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} style={{ minWidth: '300px' }}>
          <Card>
            <Row gutter={48}>
              <Col xs={12} lg={4}>
                <h4 className="bp5-heading">Platform Settings</h4>
                <FormGroup
                  label="Platform Name"
                  labelInfo="(required)"
                  labelFor="name"
                  helperText={errors.name ? errors.name : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    intent={errors.name ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="Owner Address"
                  labelInfo="(required)"
                  labelFor="firstLine"
                  helperText={
                    errors.contactAddress
                      ? 'Highlighted fields are required'
                      : ''
                  }
                  intent={Intent.DANGER}
                >
                  <ControlGroup vertical={true}>
                    <InputGroup
                      name="contactAddress.firstLine"
                      id="firstLine"
                      type="text"
                      placeholder="First Line"
                      onChange={handleChange}
                      intent={
                        get(errors, 'contactAddress.firstLine')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      value={values.contactAddress.firstLine}
                    />
                    <InputGroup
                      name="contactAddress.secondLine"
                      type="text"
                      placeholder="Second Line"
                      onChange={handleChange}
                      value={values.contactAddress.secondLine}
                    />
                    <InputGroup
                      name="contactAddress.thirdLine"
                      type="text"
                      placeholder="Third Line"
                      onChange={handleChange}
                      value={values.contactAddress.thirdLine}
                    />
                    <InputGroup
                      name="contactAddress.city"
                      type="text"
                      placeholder="City"
                      onChange={handleChange}
                      intent={
                        get(errors, 'contactAddress.city')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      value={values.contactAddress.city}
                    />

                    <InputGroup
                      name="contactAddress.postcode"
                      type="text"
                      placeholder="Postcode"
                      onChange={handleChange}
                      intent={
                        get(errors, 'contactAddress.postcode')
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      value={values.contactAddress.postcode}
                    />

                    <HTMLSelect
                      name="country"
                      options={countryList()}
                      value={get(values.contactAddress, 'country.code')}
                      onChange={event => {
                        const selection = selectionUpdate(event)
                        setFieldValue(
                          'contactAddress.country',
                          {
                            name: selection.label,
                            code: selection.value,
                          },
                          false
                        )
                      }}
                    />
                  </ControlGroup>
                </FormGroup>

                <FormGroup
                  label="Owner Name"
                  labelInfo="(required)"
                  labelFor="contactName"
                  helperText={errors.contactName ? errors.contactName : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="contactName"
                    id="contactName"
                    type="text"
                    onChange={handleChange}
                    intent={errors.contactName ? Intent.DANGER : Intent.NONE}
                    value={values.contactName}
                  />
                </FormGroup>

                <FormGroup
                  label="Owner Email Address"
                  labelInfo="(required)"
                  labelFor="contactEmail"
                  helperText={errors.contactEmail ? errors.contactEmail : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="contactEmail"
                    id="contactEmail"
                    type="text"
                    onChange={handleChange}
                    intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
                    value={values.contactEmail}
                  />
                </FormGroup>

                <FormGroup
                  label="Owner Phone Number"
                  labelInfo="(required)"
                  labelFor="contactPhone"
                  helperText={errors.contactPhone ? errors.contactPhone : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="contactPhone"
                    id="contactPhone"
                    type="text"
                    onChange={handleChange}
                    intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
                    value={values.contactPhone}
                  />
                </FormGroup>

                <br />

                <H5>Financial Settings</H5>

                <FormGroup
                  label="Owner VAT Number"
                  labelFor="vatNumber"
                  helperText={errors.vatNumber ? errors.vatNumber : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="vatNumber"
                    name="vatNumber"
                    value={values.vatNumber}
                    onChange={handleChange}
                    intent={errors.vatNumber ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="VAT Rate"
                  labelFor="platformVat"
                  helperText={errors.platformVat ? errors.platformVat : ''}
                  intent={errors.platformVat ? Intent.DANGER : Intent.NONE}
                >
                  <PercentageInput
                    value={values.platformVat}
                    handleChange={values =>
                      setFieldValue('platformVat', values.floatValue)
                    }
                  />
                </FormGroup>

                <FormGroup
                  label="Currency"
                  labelFor="platformCurrency"
                  helperText={
                    errors.platformCurrency ? errors.platformCurrency : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="platformCurrency"
                    name="platformCurrency"
                    value={values.platformCurrency.name}
                    leftElement={
                      <Tag minimal={true}>{values.platformCurrency.symbol}</Tag>
                    }
                    rightElement={
                      <Tag minimal={true}>{values.platformCurrency.code}</Tag>
                    }
                    disabled
                    intent={
                      errors.platformCurrency ? Intent.DANGER : Intent.NONE
                    }
                  />
                </FormGroup>
                <br />
              </Col>
              <Col xs={12} lg={4} className="bp5-col-divide">
                <H4>Paybox</H4>
                {platform.stripeId && (
                  <FormGroup
                    label="Stripe Account ID"
                    labelFor="stripeEnterpriseId"
                  >
                    <CopyText
                      text={platform.payboxId}
                      mono
                      leftIcon={
                        <Icon
                          icon={
                            platform.payboxId && platform.payboxAccessId
                              ? 'tick-circle'
                              : 'warning-sign'
                          }
                          intent={
                            platform.payboxId && platform.payboxAccessId
                              ? 'success'
                              : 'warning'
                          }
                        />
                      }
                    />
                  </FormGroup>
                )}
                <FormGroup
                  label="Stripe Secret Key"
                  labelFor="payboxAccessId"
                  helperText={
                    errors.payboxAccessId ? errors.payboxAccessId : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="payboxAccessId"
                    name="payboxAccessId"
                    value={values.payboxAccessId}
                    onChange={handleChange}
                    intent={errors.payboxAccessId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="Stripe Public Key"
                  labelFor="payboxPublicId"
                  helperText={
                    errors.payboxPublicId ? errors.payboxPublicId : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="payboxPublicId"
                    name="payboxPublicId"
                    value={values.payboxPublicId}
                    onChange={handleChange}
                    intent={errors.payboxPublicId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="Stripe Client ID"
                  labelFor="payboxClientId"
                  helperText={
                    errors.payboxClientId
                      ? errors.payboxClientId
                      : 'Available in stripe connect settings.'
                  }
                  intent={errors.payboxClientId ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    id="payboxClientId"
                    name="payboxClientId"
                    value={values.payboxClientId}
                    onChange={handleChange}
                    intent={errors.payboxClientId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <br />
                <H5>Charges</H5>

                <Row gutter={24}>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Connect Charge"
                      labelFor="stripeConnectCharge"
                      helperText={
                        errors.stripeConnectCharge
                          ? errors.stripeConnectCharge
                          : 'Cost per active account per month.'
                      }
                      intent={
                        errors.stripeConnectCharge ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <CurrencyInput
                        name="stripeConnectCharge"
                        id="stripeConnectCharge"
                        defaultValue={values.stripeConnectCharge}
                        onUpdate={amount => {
                          setFieldValue('stripeConnectCharge', amount)
                        }}
                        fill
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Chargeback Charge"
                      labelFor="stripeChargebackCharge"
                      helperText={
                        errors.stripeChargebackCharge
                          ? errors.stripeChargebackCharge
                          : 'Cost per charge back lost.'
                      }
                      intent={
                        errors.stripeChargebackCharge
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                    >
                      <CurrencyInput
                        name="stripeChargebackCharge"
                        id="stripeChargebackCharge"
                        defaultValue={values.stripeChargebackCharge}
                        onUpdate={amount => {
                          setFieldValue('stripeChargebackCharge', amount)
                        }}
                        fill
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Payout Fee"
                      labelFor="stripePayoutFee"
                      helperText={
                        errors.stripePayoutFee
                          ? errors.stripePayoutFee
                          : 'Cost for instant stripe payout.'
                      }
                      intent={
                        errors.stripePayoutFee ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <PercentageInput
                        value={values.stripePayoutFee}
                        handleChange={values =>
                          setFieldValue('stripePayoutFee', values.floatValue)
                        }
                        fill
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Payout Charge"
                      labelFor="stripePayoutCharge"
                      intent={Intent.DANGER}
                    >
                      <CurrencyInput
                        name="stripePayoutCharge"
                        id="stripePayoutCharge"
                        defaultValue={values.stripePayoutCharge}
                        onUpdate={amount => {
                          setFieldValue('stripePayoutCharge', amount)
                        }}
                        fill
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Instant Payout Fee"
                      labelFor="stripeInstantPayoutFee"
                      helperText={
                        errors.stripeInstantPayoutFee
                          ? errors.stripeInstantPayoutFee
                          : 'Cost for instant stripe payout.'
                      }
                      intent={
                        errors.stripeInstantPayoutFee
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                    >
                      <PercentageInput
                        value={values.stripeInstantPayoutFee}
                        handleChange={values =>
                          setFieldValue(
                            'stripeInstantPayoutFee',
                            values.floatValue
                          )
                        }
                        fill
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Account Debit Fee"
                      labelFor="stripeInstantPayoutFee"
                      helperText={
                        errors.stripeDebitFee
                          ? errors.stripeDebitFee
                          : 'Cost for directly debiting stripe account balance.'
                      }
                      intent={
                        errors.stripeDebitFee ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <PercentageInput
                        value={values.stripeDebitFee}
                        handleChange={values =>
                          setFieldValue('stripeDebitFee', values.floatValue)
                        }
                        fill
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Online Transaction Fee"
                      labelFor="stripeFee"
                      helperText={
                        errors.stripeFee
                          ? errors.stripeFee
                          : 'Cost to platform of each online transaction.'
                      }
                      intent={errors.stripeFee ? Intent.DANGER : Intent.NONE}
                    >
                      <PercentageInput
                        value={values.stripeFee}
                        handleChange={values =>
                          setFieldValue('stripeFee', values.floatValue)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Online Transaction Charge"
                      labelFor="stripeCharge"
                      intent={Intent.DANGER}
                    >
                      <CurrencyInput
                        name="stripeCharge"
                        id="stripeCharge"
                        defaultValue={values.stripeCharge}
                        onUpdate={amount => {
                          setFieldValue('stripeCharge', amount)
                        }}
                        fill
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Present Transaction Fee"
                      labelFor="stripePresentFee"
                      helperText={
                        errors.stripePresentFee
                          ? errors.stripePresentFee
                          : 'Cost to platform of each terminal transaction.'
                      }
                      intent={
                        errors.stripePresentFee ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <PercentageInput
                        value={values.stripePresentFee}
                        handleChange={values =>
                          setFieldValue('stripePresentFee', values.floatValue)
                        }
                        fill
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup
                      label="Present Transaction Charge"
                      labelFor="stripePresentCharge"
                      intent={Intent.DANGER}
                    >
                      <CurrencyInput
                        name="stripePresentCharge"
                        id="stripePresentCharge"
                        defaultValue={values.stripePresentCharge}
                        onUpdate={amount => {
                          setFieldValue('stripePresentCharge', amount)
                        }}
                        fill
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={4} className="bp5-col-divide">
                <H4>Redbox</H4>
                {platform.payboxId && (
                  <FormGroup
                    label="Stripe Account ID"
                    labelFor="stripeEnterpriseId"
                  >
                    <CopyText
                      text={platform.stripeId}
                      mono
                      leftIcon={
                        <Icon
                          icon={
                            platform.stripeId && platform.stripeAccessId
                              ? 'tick-circle'
                              : 'warning-sign'
                          }
                          intent={
                            platform.stripeId && platform.stripeAccessId
                              ? 'success'
                              : 'warning'
                          }
                        />
                      }
                    />
                  </FormGroup>
                )}
                <FormGroup
                  label="Stripe Secret Key"
                  labelFor="stripeAccessId"
                  helperText={
                    errors.stripeAccessId ? errors.stripeAccessId : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="stripeAccessId"
                    name="stripeAccessId"
                    value={values.stripeAccessId}
                    onChange={handleChange}
                    intent={errors.stripeAccessId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="Stripe Public Key"
                  labelFor="stripePublicId"
                  helperText={
                    errors.stripePublicId ? errors.stripePublicId : ''
                  }
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    id="stripePublicId"
                    name="stripePublicId"
                    value={values.stripePublicId}
                    onChange={handleChange}
                    intent={errors.stripePublicId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                <FormGroup
                  label="Stripe Client ID"
                  labelFor="stripeClientId"
                  helperText={
                    errors.stripeClientId
                      ? errors.stripeClientId
                      : 'Available in stripe connect settings.'
                  }
                  intent={errors.stripeClientId ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    id="stripeClientId"
                    name="stripeClientId"
                    value={values.stripeClientId}
                    onChange={handleChange}
                    intent={errors.stripeClientId ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="bp-card-footer-actions">
              <Button
                text="Save"
                type="submit"
                disabled={!canPerformAction('editPlatform')}
                loading={isSubmitting}
              />

              <ConfirmationPopover
                confirmationText="Are you sure you want to clear the redis cache?"
                buttonTitle="Confirm"
                remove={() => clearRedisCache()}
                requiresWrittenConfirmation
              >
                <Button intent={Intent.WARNING} minimal>
                  Clear Redis Cache
                </Button>
              </ConfirmationPopover>
            </div>
          </Card>
        </form>
      )}
    </Formik>
  )
}
GeneralSettings.propTypes = {
  editPlatform: func,
  platform: object,
}

export default GeneralSettings
