import gql from 'graphql-tag'

const EDIT_OUTLET_CATEGORIES = gql`
  mutation editOutletCategories($outletId: String!, $categories: [String!]!) {
    editOutletCategories(outletId: $outletId, categories: $categories) {
      message
      outlet {
        id
        outletCategories {
          category {
            id
            name
            emoji
            imageSrc
          }
        }
      }
    }
  }
`

export default EDIT_OUTLET_CATEGORIES
