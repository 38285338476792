import gql from 'graphql-tag'

const GET_OUTLET_NAME = gql`
  query getOutletName($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        name
      }
    }
  }
`
export default GET_OUTLET_NAME
