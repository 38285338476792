import React, { Fragment } from 'react'
import { FormGroup, InputGroup, Intent, Switch } from '@blueprintjs/core'

export const VATFormCore = ({
  values,
  setFieldValue,
  handleChange,
  errors,
}) => (
  <Fragment>
    <Switch
      label="VAT Registered"
      checked={values.isVATregistered}
      onChange={event => {
        setFieldValue('isVATregistered', event.target.checked ? true : false)
      }}
      name="isVATregistered"
    />

    {values.isVATregistered && (
      <FormGroup
        style={{ marginLeft: '38px' }}
        label=""
        labelFor="vatNumber"
        helperText={
          errors.vatNumber ? (
            errors.vatNumber
          ) : (
            <p>
              Identification Number.{' '}
              <a
                href="https://www.gov.uk/vat-registration"
                target="_blank"
                rel="noopener noreferrer"
              >
                How do i find this?
              </a>
            </p>
          )
        }
        intent={errors.vatNumber ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          name="vatNumber"
          id="vatNumber"
          type="text"
          onChange={handleChange}
          intent={errors.vatNumber ? Intent.DANGER : Intent.NONE}
          value={values.vatNumber}
        />
      </FormGroup>
    )}
  </Fragment>
)
