import React from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceEnableCustomerV2Client = ({
  updateStatus,
  enableCustomerV2Client,
}) => {
  return (
    <Formik initialValues={{ enableCustomerV2Client }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Customer Website v2.0'}
            checked={values.enableCustomerV2Client}
            onChange={e => {
              handleChange(e)
              updateStatus({
                enableCustomerV2Client: e.currentTarget.checked,
              })
            }}
            name="enableCustomerV2Client"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceEnableCustomerV2Client.propTypes = {
  enableCustomerV2Client: bool,
  errors: object,
}

export default MarketplaceEnableCustomerV2Client
