import gql from 'graphql-tag'

const GET_SALES = gql`
  query sales(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $marketplaceIds: [ID!]
    $partnerIds: [ID!]
  ) {
    sales(
      marketplaceIds: $marketplaceIds
      partnerIds: $partnerIds
      createdAt_gte: $afterDate
      createdAt_lte: $beforeDate
    ) {
      outlet {
        id
        name
        isOnline
        marketplace {
          id
          name
        }
        restaurant {
          id
          name
        }
      }
      totalOrders
      totalOrdersValue
      totalCardOrders
      totalCardOrdersValue
      totalCashOrders
      totalCashOrdersValue
      totalRefunds
      totalRefundsValue
      totalCollectionOrders
      totalCollectionOrdersValue
      totalTableOrders
      totalTableOrdersValue
      totalDeliveryOrders
      totalDeliveryOrdersValue
      totalNetworkOrders
      totalNetworkOrdersValue
      totalCourierOrders
      totalCourierOrdersValue
      totalAddOnItemsOrders
      totalAddOnItemsOrdersValue
      totalDiscountOrders
      totalDiscountOrdersValue
    }
  }
`

export default GET_SALES
