import gql from 'graphql-tag'

const GET_RESTAURANT_FINANCIALS = gql`
  query Restaurant($id: String!) {
    getRestaurants(id: $id) {
      restaurants {
        id
        name
        active
        partnerFee
        partnerCharge
        partnerTableFee
        partnerTableCharge
        merchantCommissionCharge
        merchantTableCommissionCharge
        allowDefaultMinimumPurchasePercentage
        defaultMinimumPurchasePricePercentage
        minimumBasketTotalForAddOns
        stripeId
        allowAddOnItems
        partner {
          id
        }
        vatNumber
        isVATregistered
        active
      }
    }
  }
`
export default GET_RESTAURANT_FINANCIALS
