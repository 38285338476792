import React, { Fragment } from 'react'
import { Card } from '@blueprintjs/core'
import StarsRating from '@components/Stars/Stars'
import moment from 'moment'
import ReviewStatus from './ReviewStatus'
import ReviewBody from './ReviewBody'
import styled from 'styled-components'

const reviewDateFormat = 'hh:mm, MMM Do YYYY'
const oneMinute = 1

export const JustifySpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ReviewHeader = styled(JustifySpaceBetween)`
  display: flex;
  align-items: center;
`

const Review = ({
  review,
  review: {
    id,
    createdAt,
    updatedAt,
    foodQuality,
    restaurantService,
    deliveryTime,
    approved,
    reviewText,
  },
  shouldDisplayHeader = true,
}) => {
  // since we'll be reusing those
  // it's worth calculating them just once
  const createdMoment = moment(createdAt)
  const updatedMoment = moment(updatedAt)
  const diffInMinutes = createdMoment.diff(updatedMoment, 'minutes')

  return (
    <Card>
      {shouldDisplayHeader && (
        <ReviewHeader>
          <h4 className="bp5-heading">Review</h4>
          <ReviewStatus approved={approved} />
        </ReviewHeader>
      )}
      <Fragment>
        <JustifySpaceBetween>
          <strong>Created: </strong>
          <p>{createdMoment.format(reviewDateFormat)}</p>
        </JustifySpaceBetween>
        {diffInMinutes > oneMinute && (
          <JustifySpaceBetween>
            <strong>Updated: </strong>
            <p>{updatedMoment.format(reviewDateFormat)}</p>
          </JustifySpaceBetween>
        )}
        <StarsRating title={'Food Rating'} starsNumber={foodQuality} />
        <StarsRating
          title={'Restaurant Service'}
          starsNumber={restaurantService}
        />
        <StarsRating title={'Delivery Time'} starsNumber={deliveryTime} />
        {reviewText.length && <ReviewBody review={review} />}
      </Fragment>
    </Card>
  )
}

export default Review
