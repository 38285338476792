import React, { Fragment, useState, useCallback } from 'react'
import {
  AnchorButton,
  Button,
  Tooltip,
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
  H5,
  Icon,
  RadioGroup,
  Radio,
  Callout,
} from '@blueprintjs/core'
import toast, { errorToast } from '@utils/toast'
import { isPeakTime } from '@utils/isPeakTime'

const downloadCSV = async (fileName, csvData) => {
  const blob = new Blob(csvData, { type: 'octet/stream' })
  const url = window.URL.createObjectURL(blob)

  let link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()

  window.URL.revokeObjectURL(url)

  toast({
    message: 'Download complete.',
    intent: 'success',
    icon: 'saved',
  })
}

export const ExportPopover = ({ options }) => {
  const [option, setOption] = useState(options[0])
  const [includeIds, setIncludeIds] = useState(true) // default is export with ids

  const handleChange = e => {
    const selectedOption = options.find(
      option => option.value === e.target.value
    )
    setOption(selectedOption)
  }

  const handleExport = useCallback(async () => {
    if (isPeakTime()) {
      errorToast('Export is not available during peak times')
      return
    }
    const csv = await option.generateCSV(includeIds)
    await downloadCSV(csv.fileName, csv.csvData)
  }, [option, includeIds])

  const peakTime = isPeakTime()

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_LEFT}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      disabled={peakTime}
      content={
        <Fragment>
          <H5>
            Export Data <Icon icon="data-lineage" />
          </H5>
          <p>Select what part of the menu to export.</p>

          <RadioGroup onChange={handleChange} selectedValue={option.value}>
            {options.map(option => (
              <Radio
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>

          <p>Select what the export will be used for.</p>
          <RadioGroup
            onChange={e => setIncludeIds(e.target.value === 'includeIds')}
            selectedValue={includeIds ? 'includeIds' : 'excludeIds'}
          >
            <Radio label="Update existing items" value="includeIds" />
            <Radio label="Creating new items" value="excludeIds" />
          </RadioGroup>

          <Callout intent="primary">
            {includeIds ? (
              <p>
                IDs will be retained on the export so that you can import your
                updated data back into this business.
              </p>
            ) : (
              <p>
                IDs will be removed on the export, which means you can use it to
                create new items on this business or another business.
              </p>
            )}
          </Callout>
          <br />
          <Button
            icon="export"
            onClick={handleExport}
            className={Classes.POPOVER_DISMISS}
          >
            Export
          </Button>
        </Fragment>
      }
    >
      <Tooltip
        disabled={!peakTime}
        content="Not available during peak times"
        position={Position.BOTTOM}
      >
        <AnchorButton icon="export" disabled={peakTime}>
          Export
        </AnchorButton>
      </Tooltip>
    </Popover>
  )
}
