import { Card } from '@blueprintjs/core'
import React from 'react'
import { DataKeyMap } from '../util/maps'

export const NumberOfOrdersContainer = ({
  numberOfOrders: { total, orders },
  title,
  resolution = false,
}) => {
  return total > 0 ? (
    <Card>
      <p>{title}</p>
      <p>
        Total {total} order{total !== 1 ? 's ' : ' '}
        {resolution ? `per ${resolution}` : ''}
      </p>
      {orders.map(({ value, color, dataKey }) => {
        return value ? (
          <div key={dataKey}>
            <small style={{ color }}>
              {DataKeyMap[dataKey]}{' '}
              <span style={{ color: 'black' }}>{value}</span>
            </small>
          </div>
        ) : null
      })}
    </Card>
  ) : null
}
