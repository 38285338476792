import gql from 'graphql-tag'

const SEND_MESSAGE_REPLY = gql`
  mutation sendMessageReply(
    $content: String!
    $subject: String!
    $messageId: String!
    $marketplaceId: String!
    $addMarketplaceTheme: Boolean
  ) {
    sendMessageReply(
      content: $content
      subject: $subject
      messageId: $messageId
      marketplaceId: $marketplaceId
      addMarketplaceTheme: $addMarketplaceTheme
    ) {
      message
    }
  }
`

export default SEND_MESSAGE_REPLY
