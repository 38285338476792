import React, { useState } from 'react'

export const CustomerAnalyticsContext = React.createContext({
  rawCustomerData: {},
  setRawCustomerData: _arg => {},
})

const CustomerAnalyticsContextProvider = ({ children }) => {
  const [rawCustomerData, setRawCustomerData] = useState(null)

  return (
    <CustomerAnalyticsContext.Provider
      value={{
        rawCustomerData,
        setRawCustomerData,
      }}
    >
      {children}
    </CustomerAnalyticsContext.Provider>
  )
}

export default CustomerAnalyticsContextProvider
