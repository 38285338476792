import * as yup from 'yup'
// validation specific to the FE
const newMessageValidation = yup.object().shape({
  content: yup
    .string()
    .min(2, 'Content must contain at least 2 characters')
    .required('Content cannot be empty'),
  subject: yup.string().required('Subject cannot be empty'),
  restaurantIds: yup
    .array()
    .min(1, 'Please choose businesses to send messages to')
    .required('Please choose businesses to send messages to'),
  marketplaceId: yup.string().required('Please choose a marketplace'),
})

export const replyMessageValidation = yup.object().shape({
  content: yup
    .string()
    .min(2, 'Content must contain at least 2 characters')
    .required('Content cannot be empty'),
  subject: yup.string().required('Subject cannot be empty'),
  to: yup.array().of(yup.string()),
})

export default newMessageValidation
