import gql from 'graphql-tag'

const GET_RESTAURANTS = gql`
  query getRestaurantsByNameOrContactNameEmail(
    $search: String
    $skip: Int
    $first: Int
    $last: Int
    $orderBy: RestaurantOrderByInput
    $partnerIds: [ID]
    $cuisineIds: [ID]
    $isVATregistered: Boolean
    $isActive: Boolean
    $isPromoted: Boolean
    $statusOnboarding: [RestaurantStatusOnboarding!]
    $marketplaceIds: [String!]
  ) {
    getRestaurants(
      nameContains: $search
      contactNameContains: $search
      contactEmailContains: $search
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
      partnerIds: $partnerIds
      cuisineIds: $cuisineIds
      isVATregistered: $isVATregistered
      isActive: $isActive
      isPromoted: $isPromoted
      statusOnboarding: $statusOnboarding
      marketplaceIds: $marketplaceIds
    ) {
      count
      totalCount
      restaurants {
        id
        name
        contactName
        contactPhone
        contactEmail
        isVATregistered
        active
        promoted
        allowAddOnItems
        cuisines {
          id
          name
          emoji
          imageSrc
        }
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        outlets {
          id
          daysOfferedInAdvanceMax
          marketplace {
            id
            name
            partnerFee
            allowReviews
            cname
            categoryEmojiOrImage
          }
          terminals {
            id
          }
          reviews {
            id
            foodQuality
            restaurantService
            deliveryTime
          }
        }
        discounts {
          id
        }
        partner {
          id
          name
        }
        partnerFee
        promoted
        deals
        dealsText
        tableDeal
        collectionDeal
        deliveryDeal
        statusOnboarding
      }
    }
  }
`

export default GET_RESTAURANTS
