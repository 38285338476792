import gql from 'graphql-tag'

const GET_MESSAGE_HISTORY = gql`
  query getMessageHistory(
    $marketplaceId: ID!
    $messageSubject: String!
    $emailsFrom: [String!]
    $emailsTo: [String!]
  ) {
    getMessageHistory(
      marketplaceId: $marketplaceId
      messageSubject: $messageSubject
      emailsFrom: $emailsFrom
      emailsTo: $emailsTo
    ) {
      messages {
        id
        date
        from
        to
        subject
        text
        textAsHtml
        html
        to
        marketplace {
          id
          name
        }
      }
    }
    customers(
      where: { OR: [{ email_in: $emailsFrom }, { email_in: $emailsTo }] }
    ) {
      customers {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export default GET_MESSAGE_HISTORY
