import { downloadCSV } from './downloadCSV'

/**
 *
 * @param {*} tableId - id of the table to be converted to CSV
 * @param {*} filename - name of the file to be downloaded
 * @returns void
 * Note that the table need to be a standard HTML table, with thead and tbody tags.
 */
export const htmlTableToCSV = (tableId, filename) => {
  const table = document.getElementById(tableId)

  const header = table.querySelector('thead').innerText.replace(/\t/g, ',')
  const rows = table.querySelector('tbody').querySelectorAll('tr')
  const rowsData = Array.from(rows)
    .map(tr => tr.innerText.replace(/\t/g, ','))
    .join('\n')

  return downloadCSV(header.concat(rowsData), filename)
}
