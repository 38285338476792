import gql from 'graphql-tag'

const GET_MEMBER_DISCOUNTS = gql`
  query getMemberDiscountsByRestaurant(
    $restaurantId: String!
    $searchText: String
    $orderBy: DiscountOrderByInput
    $skip: Int
    $first: Int
  ) {
    getMemberDiscountsByRestaurant(
      restaurantId: $restaurantId
      searchText: $searchText
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      count
      totalCount
      discounts {
        id
        name
        discountPercentage
        customerEmail
        enrolmentKey
        customerEnrolled
        active
        customer {
          id
          lastName
          firstName
          email
          phoneNumber
        }
      }
    }
  }
`

export default GET_MEMBER_DISCOUNTS
