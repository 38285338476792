import React, { Fragment } from 'react'
import { Switch, FormGroup, Intent } from '@blueprintjs/core'
import CurrencyInput from '../../CurrencyInput/CurrencyInput'

const SubsidyInput = ({ values, setFieldValue, errors }) => (
  <Fragment>
    <Switch
      checked={values.subsidiseDelivery}
      label="Subsidise Delivery"
      onChange={() =>
        setFieldValue('subsidiseDelivery', !values.subsidiseDelivery, false)
      }
    />
    {values.subsidiseDelivery && (
      <FormGroup
        label="Subsidise Deliveries By"
        labelFor="fixedSubsidy"
        helperText={errors.fixedSubsidy}
        intent={errors.fixedSubsidy ? Intent.DANGER : Intent.NONE}
      >
        <CurrencyInput
          name="fixedSubsidy"
          id="fixedSubsidy"
          defaultValue={values.fixedSubsidy}
          onUpdate={amount => {
            setFieldValue('fixedSubsidy', amount)
          }}
          fill={true}
        />
      </FormGroup>
    )}
  </Fragment>
)

export default SubsidyInput
