import gql from 'graphql-tag'

const GET_MARKETPLACES_GROUPED_BY_CUISINE_IDS = gql`
  query getMarketplacesGroupedByCuisineIds($cuisineIds: [ID!]) {
    getMarketplacesGroupedByCuisineIds(cuisineIds: $cuisineIds) {
      categoryId
      marketplaces {
        id
        name
        optInAllowedCuisines
      }
    }
  }
`

export default GET_MARKETPLACES_GROUPED_BY_CUISINE_IDS
