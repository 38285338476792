import gql from 'graphql-tag'

const EDIT_PAYMENT_METHODS = gql`
  mutation editPaymentMethod(
    $id: String!
    $paymentMethods: [PaymentMethod]
    $cashText: String!
    $cashCollectionText: String!
  ) {
    editMarketplace(
      id: $id
      paymentMethods: $paymentMethods
      cashText: $cashText
      cashCollectionText: $cashCollectionText
    ) {
      message
      marketplace {
        id
        paymentMethods
        cashText
        cashCollectionText
      }
    }
  }
`

export default EDIT_PAYMENT_METHODS
