import gql from 'graphql-tag'

const EDIT_MIN_APP_VERSION_OVERRIDE = gql`
  mutation editMinAppVersionOverride(
    $id: String!
    $minAppVersionOverride: String!
  ) {
    editMinAppVersionOverride(
      id: $id
      minAppVersionOverride: $minAppVersionOverride
    ) {
      count
    }
  }
`

export default EDIT_MIN_APP_VERSION_OVERRIDE
