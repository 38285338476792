import React from 'react'
import { func, bool, array } from 'prop-types'
import Filter from '@components/Toolbar/Filter'

import GET_CUISINES from './queries/getCuisines.query'

export const CuisineFilter = ({ disabled, tagsFilter, onChange }) => (
  <Filter
    name="Category"
    disabled={disabled}
    filter={tagsFilter}
    onChange={onChange}
    query={GET_CUISINES}
    dataPath="getCuisines.cuisines"
    showEmojis
  />
)

CuisineFilter.propTypes = {
  onChange: func,
  tagsFilter: array,
  disabled: bool,
}
