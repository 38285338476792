import gql from 'graphql-tag'

const ARCHIVE_MENU_ITEMS_GROUP = gql`
  mutation archiveMenuItemsGroup($id: String!) {
    archiveMenuItemsGroup(id: $id) {
      message
      menuItemsGroup {
        id
        name
        archived
        restaurant {
          id
        }
      }
    }
  }
`

export default ARCHIVE_MENU_ITEMS_GROUP
