import gql from 'graphql-tag'

const EDIT_OPTION_ITEMS_ORDER = gql`
  mutation editOptionItemsOrder($input: [IPositionArgs!]!) {
    editOptionItemsOrder(input: $input) {
      optionItems {
        id
        position
      }
    }
  }
`

export default EDIT_OPTION_ITEMS_ORDER
