import { useCallback } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

/**
 * useSearchQueryParam - Manages the state of the Search input, stores the search value in a query param
 */
export const useSearchQueryParam = () => {
  const [searchValue = '', onChangeSearch] = useQueryParam(
    'search',
    StringParam
  )

  const resetSearch = useCallback(() => {
    onChangeSearch(undefined, 'replaceIn')
  }, [onChangeSearch])

  return { searchValue, onChangeSearch, resetSearch }
}
