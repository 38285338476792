import React, { Fragment } from 'react'
import sanitizeHTML from 'sanitize-html-react'
import Handlebars from 'handlebars'
import { Callout, Intent } from '@blueprintjs/core'
import { emailConditionalsRegex } from '@components/EmailEditor/TemplateVariableValidator'

export const sanitizeOptions = {
  allowedTags: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'img',
    'span',
  ],

  allowedAttributes: {
    '*': ['href', 'name', 'target', 'src', 'style', 'height', 'width', 'align'],
  },
  allowedSchemesByTag: { img: ['data', 'http', 'https'] },
}

/**
 * @param {string} html
 */
export const sanitizer = html => ({
  __html: sanitizeHTML(html, sanitizeOptions),
})

const SanitizedHTML = ({ html, style = {}, exampleData }) => {
  const regExp = /{{ *([^}]+) *}}/g

  const variablesUsed = [...html.matchAll(regExp)].map(
    matchedString => matchedString[1]
  )

  const thereAreConditionals = variablesUsed.some(varUsed =>
    new RegExp(emailConditionalsRegex).test(varUsed)
  )

  const template = Handlebars.compile(html)

  let substitutedTemplate
  try {
    substitutedTemplate = template(exampleData)
  } catch (_e) {
    /// do nothing
  }

  return (
    <Fragment>
      {substitutedTemplate ? null : (
        <Callout intent={Intent.WARNING}>
          {thereAreConditionals ? (
            <span>
              Previews are unavailable for a template using conditional logic.
            </span>
          ) : (
            <span>
              There was an error substituting variables, check for any unclosed
              brackets
            </span>
          )}
        </Callout>
      )}
      <div
        dangerouslySetInnerHTML={sanitizer(substitutedTemplate || html)}
        style={style}
      />
    </Fragment>
  )
}

export const sanitizedHtmlWithoutSubstitute = html => {
  return <div dangerouslySetInnerHTML={sanitizer(html)} />
}

export default SanitizedHTML
