import React, { Fragment } from 'react'
import {
  isPlatformLevelUser,
  isPartner,
  isOutletUser,
  isBusinessUser,
} from '../../../stores/userStore'
import { BusinessOutletFilter } from '../BusinessOutletFilter'
import { MarketplaceFilter } from '../MarketplaceFilter'
import { useRoleAwareBusinessFilterQueryParams } from './useRoleAwareBusinessFilterQueryParams'
import { RestaurantFilter } from '../RestaurantFilter'

export enum RoleAwareBusinessFilterField {
  marketplace = 'MARKETPLACE',
  outlet = 'OUTLET',
  business = 'BUSINESS',
}

interface Props {
  divider?: boolean
  disabled?: boolean
  fields?: RoleAwareBusinessFilterField[]
}

const RoleAwareBusinessFilter: React.FC<Props> = ({
  divider = true,
  disabled = false,
  fields = [
    RoleAwareBusinessFilterField.marketplace,
    RoleAwareBusinessFilterField.outlet,
  ],
}) => {
  const {
    marketplaceIds,
    marketplaceOutletIds,
    marketplaceBusinessIds,
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
    onChangeMarketplaceBusinessIds,
  } = useRoleAwareBusinessFilterQueryParams()

  const showMarketplace = fields.includes(
    RoleAwareBusinessFilterField.marketplace
  )
  const showBusiness = fields.includes(RoleAwareBusinessFilterField.business)
  const showOutlets = fields.includes(RoleAwareBusinessFilterField.outlet)

  // superadmins and partners must filter by marketplace first
  const isMarketplaceFilterRequired =
    (isPlatformLevelUser() || isPartner()) && showMarketplace

  const isBusinessFilterRequired =
    (!isMarketplaceFilterRequired ||
      (marketplaceIds && marketplaceIds.length > 0)) &&
    showBusiness

  // business and outlet users don't have enough businesses to require a filter
  const isOutletFilterRequired =
    (!isMarketplaceFilterRequired ||
      (marketplaceIds && marketplaceIds.length > 0)) &&
    !isBusinessUser() &&
    !isOutletUser() &&
    showOutlets

  return (
    <Fragment>
      {isMarketplaceFilterRequired && (
        <div>
          <MarketplaceFilter
            marketplaceFilter={marketplaceIds}
            disabled={disabled}
            onChange={e => {
              const { checked, id: marketplaceId } = e.currentTarget
              if (checked) {
                onChangeMarketplaceIds([...marketplaceIds, marketplaceId])
              } else {
                onChangeMarketplaceIds(
                  marketplaceIds.filter(id => id !== marketplaceId)
                )
                onChangeMarketplaceOutletIds(
                  marketplaceOutletIds.filter(
                    id => !id.startsWith(`${marketplaceId}:`)
                  )
                )
              }
            }}
          />
        </div>
      )}

      {isBusinessFilterRequired && (
        <RestaurantFilter
          restaurantFilter={marketplaceBusinessIds}
          marketplaceFilter={marketplaceIds}
          onChange={event => {
            const { checked, id: marketplaceAndBusinessId } =
              event.currentTarget
            onChangeMarketplaceBusinessIds(
              checked
                ? [...marketplaceBusinessIds, marketplaceAndBusinessId]
                : marketplaceBusinessIds.filter(
                    id => id !== marketplaceAndBusinessId
                  )
            )
          }}
        />
      )}

      {isOutletFilterRequired && (
        <BusinessOutletFilter
          selectedBusinessOutlets={marketplaceOutletIds}
          divider={divider}
          disabled={disabled}
          mapItems={outlets =>
            outlets.map(outlet => ({
              id: `${outlet.marketplace.id}:${outlet.id}`,
              name: `${outlet.restaurant.name} - ${outlet.name}`,
            }))
          }
          onChange={e => {
            const { checked, id: marketplaceAndOutletId } = e.currentTarget
            onChangeMarketplaceOutletIds(
              checked
                ? [...marketplaceOutletIds, marketplaceAndOutletId]
                : marketplaceOutletIds.filter(
                    id => id !== marketplaceAndOutletId
                  )
            )
          }}
          marketplaceIds={marketplaceIds}
          restaurantIds={marketplaceBusinessIds}
        />
      )}
    </Fragment>
  )
}

export default RoleAwareBusinessFilter
