import {
  AnchorButton,
  Button,
  Callout,
  Card,
  H1,
  H3,
  H5,
  Icon,
  Intent,
  NonIdealState,
  Tag,
} from '@blueprintjs/core'
import { Col, Row } from '@components/_FlexGrid'
import { first } from 'lodash'
import { Fragment } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import outletSVG from '@assets/ui/outlet.svg'
import Currency from '@components/Currency/Currency'
import GeoPlot from '@components/GeoJSON/GeoFence'
import OpenStatus from '@components/OpenStatus/OpenStatus'
import Query from '@components/Query/Query'
import GET_OUTLET from './queries/getOutlet.query'

import {
  canView,
  isAtLeastMarketplaceOwner,
  isAtLeastPartner,
  isAtLeastOutletUser,
  isAtLeastOutletFinancialUser,
} from '@stores/userStore'
import AddressNonIdealState from '../Fulfilment/AddressNonIdealState'

const Dashboard = () => {
  const { outlet } = useParams()
  const navigate = useNavigate()
  return (
    <Query
      query={GET_OUTLET}
      variables={{ id: outlet }}
      loaderTitle={'Loading Outlet'}
    >
      {({ getOutlets: { outlets } }) => {
        const [outlet] = outlets
        if (!outlet) return 'Unable to find outlet'
        const { stripeOnboarding } = outlet.marketplace
        const stats = {
          terminal: {
            online: 0,
            offline: 0,
            total: 0,
          },
          delivery: {
            total: 0,
          },
          menu: {
            soldOut: 0,
            total: 0,
          },
        }

        stats.delivery.total = outlet.deliveryZoneCosts.length || 0

        outlet.terminals.map(terminal => {
          stats.terminal.online += terminal.isOnline ? 1 : 0
          stats.terminal.offline += terminal.isOnline ? 0 : 1
        })
        stats.terminal.total = outlet.terminals.length || 0

        outlet.restaurant.menuItems.map(item => {
          stats.menu.soldOut += item.soldOut
            ? 1
            : 0 + item.outletSoldOut
            ? 1
            : 0
          stats.menu.total++
        })

        return (
          <Fragment>
            {stripeOnboarding &&
              isAtLeastOutletFinancialUser() &&
              outlet.stripeConnect &&
              !outlet.stripeConnect.detailsSubmitted && (
                <Callout
                  title="Business Verification Required"
                  style={{ marginBottom: 24 }}
                >
                  <p>
                    Before you can start receiving payments, you'll need to
                    complete a quick business verification process. This ensures
                    that all transactions are secure and compliant with industry
                    standards.
                  </p>
                  <AnchorButton
                    href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                    intent={Intent.SUCCESS}
                    rightIcon="play"
                  >
                    Payments Setup
                  </AnchorButton>
                </Callout>
              )}

            {outlet.stripeConnect && !outlet.stripeConnect.payoutsEnabled && (
              <Fragment>
                <Callout
                  intent={Intent.DANGER}
                  icon={null}
                  title="Earnings Payments Suspended"
                >
                  Your account may need further verification to enable bank
                  payments.
                </Callout>
                <br />
              </Fragment>
            )}
            {outlet.stripeConnect && !outlet.stripeConnect.chargesEnabled && (
              <Fragment>
                <Callout
                  intent={Intent.DANGER}
                  icon={null}
                  title="Ordering Disabled"
                >
                  Your account may need further verification to process card
                  payments.
                </Callout>
                <br />
              </Fragment>
            )}

            <Row gutter={24}>
              {/* Outlet */}
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <Card style={{ paddingBottom: 0 }}>
                  <Row style={{ marginBottom: 12 }}>
                    <Col span={6}>
                      <H5>Outlet</H5>
                    </Col>
                    <Col span={6}>
                      <p
                        className={'bp5-text-muted'}
                        style={{ textAlign: 'right' }}
                      >
                        <Link
                          to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                        >
                          Edit {!outlet.active && '/ Activate'}
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 12 }}>
                    <Col
                      span={10}
                      style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center',
                      }}
                    >
                      <ReactSVG
                        src={outletSVG}
                        style={{ margin: '-8px 18px 0 4px' }}
                      />
                      <div>
                        <H3 style={{ margin: '0 0 2 0', color: '#ff3b4c' }}>
                          {outlet.name}
                        </H3>
                        {outlet.outletAddress && (
                          <p>
                            {outlet.outletAddress.city || ''},{' '}
                            {outlet.outletAddress.postcode || ''}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '24', paddingBottom: 24 }}>
                    <Col span={6}>
                      <span className="bp5-text-disabled bp5-text-small">
                        Business
                      </span>
                      <br />
                      <strong>
                        {isAtLeastPartner() ? (
                          <Link to={`/business/${outlet.restaurant.id}`}>
                            {outlet.restaurant.name}
                          </Link>
                        ) : (
                          outlet.restaurant.name
                        )}
                      </strong>
                      <br />
                      {outlet.restaurant.contactAddress.city},{' '}
                      {outlet.restaurant.contactAddress.postcode}
                    </Col>
                    <Col span={6}>
                      <span className="bp5-text-disabled bp5-text-small">
                        Marketplace
                      </span>
                      <br />
                      <strong>
                        {isAtLeastMarketplaceOwner() ? (
                          <Link to={`/marketplaces/${outlet.marketplace.id}`}>
                            {outlet.marketplace.companyLegalName ||
                              outlet.marketplace.name}
                          </Link>
                        ) : (
                          outlet.marketplace.companyLegalName ||
                          outlet.marketplace.name
                        )}
                      </strong>
                      <br />
                      {outlet.marketplace.contactAddress.city},{' '}
                      {outlet.marketplace.contactAddress.postcode}
                    </Col>
                  </Row>

                  {!outlet.outletAddress ? (
                    <AddressNonIdealState description="Outlet must have a pickup address to view this map." />
                  ) : (
                    <div
                      style={{
                        margin: '0 -20 0 -20',
                        borderTop: '1px solid #d9dada',
                        position: 'relative',
                      }}
                    >
                      {!outlet.outletAddress.geo && (
                        <div
                          style={{
                            position: 'absolute',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            zIndex: 3,
                            width: '100%',
                            background: '#f5f5f5cc',
                          }}
                        >
                          <NonIdealState
                            icon="warning-sign"
                            title="We couldn't find the outlet address on the map"
                            description="Please update the outlet address and try again."
                            action={
                              <Link to={'details'}>
                                <Button>Update Address</Button>
                              </Link>
                            }
                          />
                        </div>
                      )}
                      <GeoPlot
                        height="217px"
                        features={[[], outlet.outletAddress]}
                        showDelete={false}
                        disableEdit={true}
                      />
                    </div>
                  )}
                </Card>
              </Col>

              {/* Opening */}
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={stripeOnboarding || outlet.stripeConnect ? 4 : 8}
                xl={stripeOnboarding || outlet.stripeConnect ? 4 : 8}
                order={stripeOnboarding || outlet.stripeConnect ? 2 : 3}
              >
                <Row gutter={24}>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      interactive={!isAtLeastOutletFinancialUser()}
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        !isAtLeastOutletFinancialUser() &&
                          navigate(
                            `/business/${outlet.restaurant.id}/outlets/${outlet.id}/opening-times`
                          )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Ordering Times</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {!isAtLeastOutletFinancialUser() && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Row style={{ padding: '8 0 6 0' }}>
                          <p className="dashboard-data-value-medium">
                            {outlet.openingTimes
                              ? outlet.formattedCurrentOpeningTimeBracket
                              : 'Closed'}
                          </p>
                        </Row>
                        <Row>
                          {!outlet.active ? (
                            <Tag
                              icon="ban-circle"
                              minimal
                              intent={Intent.DANGER}
                              large
                              round
                            >
                              Deactivated
                            </Tag>
                          ) : outlet.openingTimes ? (
                            <OpenStatus
                              openingTimes={outlet.openingTimes}
                              restaurantId={outlet.restaurant.id}
                              restaurantIsActive={outlet.restaurant.active}
                              outletIsActive={outlet.active}
                              outletId={outlet.id}
                              outletIsOpen={outlet.isOpen}
                              outletIsOnline={outlet.isOnline}
                              outletIsOnlineOverride={outlet.isOnlineOverride}
                              large
                              round
                            />
                          ) : (
                            <Tag icon="plus" minimal intent={Intent.NONE} large>
                              Set Ordering Times
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>

                  {/* Delivery */}
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      interactive={isAtLeastOutletUser()}
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        isAtLeastOutletUser() &&
                          navigate(
                            `/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`
                          )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Delivery Zones</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {isAtLeastOutletUser() && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <p className="dashboard-data-value-large">
                              {stats.delivery.total}
                            </p>
                            <span className="statInfo">zones</span>
                          </Col>
                        </Row>
                        <Row>
                          {stats.delivery.total === 0 ? (
                            isAtLeastOutletUser() && (
                              <Tag
                                icon="plus"
                                minimal
                                intent={Intent.NONE}
                                large
                              >
                                Add Delivery Zone
                              </Tag>
                            )
                          ) : (
                            <Tag
                              icon="tick"
                              minimal
                              intent={Intent.SUCCESS}
                              large
                              round
                            >
                              OK
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      interactive={isAtLeastOutletUser()}
                      elevation={stats.terminal.offline > 0 ? 4 : 0}
                      style={
                        stats.terminal.offline > 0
                          ? {
                              minHeight: 150,
                              border: '2px solid red',
                            }
                          : { minHeight: 150 }
                      }
                      onClick={() => {
                        isAtLeastOutletUser() &&
                          navigate(
                            `/business/${outlet.restaurant.id}/outlets/${outlet.id}/terminals`
                          )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Terminals</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {isAtLeastOutletUser() && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Fragment>
                          <Row>
                            <Col span={3} md={3}>
                              <p className="dashboard-data-value-large">
                                {stats.terminal.total}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            {stats.terminal.total > 0 ? (
                              stats.terminal.offline > 0 && (
                                <Tag
                                  intent={Intent.DANGER}
                                  large
                                  round
                                  icon="warning-sign"
                                >
                                  {stats.terminal.offline} Offline
                                </Tag>
                              )
                            ) : outlet.isOnlineOverride === true ? (
                              <Tag
                                intent={Intent.SUCCESS}
                                large
                                round
                                minimal
                                icon={
                                  outlet.isAutoAccept ? 'fast-forward' : 'tick'
                                }
                              >
                                {' '}
                                Online Override
                              </Tag>
                            ) : (
                              canView('virtualTerminals') && (
                                <Tag
                                  icon="plus"
                                  minimal
                                  intent={Intent.NONE}
                                  large
                                >
                                  {' '}
                                  Add Terminal
                                </Tag>
                              )
                            )}
                          </Row>
                        </Fragment>
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      interactive={isAtLeastOutletUser()}
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        isAtLeastOutletUser() &&
                          navigate(
                            `/business/${outlet.restaurant.id}/outlets/${outlet.id}/stock/list`
                          )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Stock Control</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp5-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {isAtLeastOutletUser() && (
                                <Icon icon="arrow-right" />
                              )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <p className="dashboard-data-value-large">
                              {stats.menu.total}
                            </p>
                            <span className="statInfo">items</span>
                          </Col>
                        </Row>
                        <Row>
                          {stats.menu.soldOut > 0 ? (
                            <Tag intent={Intent.DANGER} large round minimal>
                              {stats.menu.soldOut} Sold Out
                            </Tag>
                          ) : null}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>
              </Col>

              {/* Balance */}
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                {outlet.stripeConnect && (
                  <Card style={{ minHeight: 150 }}>
                    <Fragment>
                      <Row>
                        <Col span={6}>
                          <H5>
                            Balance{' '}
                            <Tag minimal>
                              {outlet.stripeConnect.balanceCurrency.toUpperCase()}
                            </Tag>
                          </H5>
                        </Col>
                        <Col span={6}>
                          <Link
                            className={'bp5-text-muted'}
                            style={{ float: 'right' }}
                            to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                          >
                            Manage Payouts <Icon icon="arrow-right" />
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <H1 style={{ color: '#1968F6' }}>
                            <Currency
                              amount={outlet.stripeConnect.balance || 0}
                              currency={outlet.stripeConnect.balanceCurrency}
                            />
                          </H1>
                          {outlet.stripeConnect.payoutsEnabled ? (
                            'Available'
                          ) : (
                            <Tag intent="danger">PAYMENTS SUSPENDED</Tag>
                          )}
                        </Col>
                        <Col span={6}>
                          <H1 className="bp5-text-muted">
                            <Currency
                              amount={outlet.stripeConnect.balancePending || 0}
                              currency={outlet.stripeConnect.balanceCurrency}
                            />
                          </H1>
                          Next Payout
                        </Col>
                      </Row>
                    </Fragment>
                  </Card>
                )}
                {((stats.terminal.total === 0 && !outlet.isOnlineOverride) ||
                  stats.delivery.total === 0 ||
                  !outlet.openingTimes ||
                  !outlet.active ||
                  !(outlet.stripeConnect && outlet.stripeId)) && (
                  <Fragment>
                    <Card>
                      <Callout title="Getting Started" intent={Intent.PRIMARY}>
                        Here we will guide you through setting up your Outlet.
                      </Callout>
                      <br />
                      {stripeOnboarding && isAtLeastOutletFinancialUser() && (
                        <Fragment>
                          <H5>Payment Processing</H5>
                          <p>
                            Sales revenue with direct deposits into your bank
                            account. Our system ensures that your earnings are
                            transferred securely and promptly.
                          </p>
                          <AnchorButton
                            rightIcon={
                              <Icon
                                color={
                                  outlet.stripeConnect && outlet.stripeId
                                    ? 'green'
                                    : null
                                }
                                icon={
                                  outlet.stripeConnect && outlet.stripeId
                                    ? 'tick-circle'
                                    : 'circle-arrow-right'
                                }
                              />
                            }
                            href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/financial`}
                            outlined
                            intent={
                              outlet.stripeConnect && outlet.stripeId
                                ? Intent.SUCCESS
                                : Intent.NONE
                            }
                          >
                            Payments Setup
                          </AnchorButton>
                          {'  '}
                          <AnchorButton
                            href={`https://support.redbox.systems/docs/business-guide-to-direct-payouts-from-paybox`}
                            minimal
                            intent="primary"
                            rightIcon="help"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </AnchorButton>
                        </Fragment>
                      )}
                      {isAtLeastOutletUser() && (
                        <Fragment>
                          <hr />
                          <br />
                          <H5>Ordering Times</H5>
                          <p>
                            Add ordering times which allow your outlet to
                            separate breakfast, lunch and evening services. Your
                            ordering times and the status of your terminal in
                            your outlet will affect when you are available to
                            accept orders.
                          </p>

                          <AnchorButton
                            outlined
                            intent={
                              outlet.openingTimes ? Intent.SUCCESS : Intent.NONE
                            }
                            rightIcon={
                              <Icon
                                color={outlet.openingTimes ? 'green' : null}
                                icon={
                                  outlet.openingTimes
                                    ? 'tick-circle'
                                    : 'circle-arrow-right'
                                }
                              />
                            }
                            href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/opening-times`}
                          >
                            Ordering Times
                          </AnchorButton>
                          <hr />
                          <br />
                          <H5>Delivery Zones</H5>
                          <p>
                            Add zones to configure where your outlet will
                            deliver and setup cost by drawing a zone or setting
                            a radius. If you don't want to do your own delivery,
                            find out about our{' '}
                            <a
                              href="https://support.redbox.systems/docs/redbox-integrations"
                              target="blank"
                            >
                              Managed Delivery Integrations
                            </a>{' '}
                            which allows you to out source delivery to local
                            riders.
                          </p>

                          <AnchorButton
                            outlined
                            intent={
                              stats.delivery.total > 0
                                ? Intent.SUCCESS
                                : Intent.NONE
                            }
                            rightIcon={
                              <Icon
                                color={
                                  stats.delivery.total > 0 ? 'green' : null
                                }
                                icon={
                                  stats.delivery.total > 0
                                    ? 'tick-circle'
                                    : 'circle-arrow-right'
                                }
                              />
                            }
                            href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`}
                          >
                            Delivery Zones
                          </AnchorButton>

                          {!outlet.isOnlineOverride && (
                            <Fragment>
                              <hr />
                              <br />
                              <H5>Terminals</H5>
                              <p>
                                Terminals allow you to print receipts, accept
                                and manage orders.
                              </p>
                              <AnchorButton
                                outlined
                                intent={
                                  stats.terminal.total > 0
                                    ? Intent.SUCCESS
                                    : Intent.NONE
                                }
                                rightIcon={
                                  <Icon
                                    color={
                                      stats.terminal.total > 0 ? 'green' : null
                                    }
                                    icon={
                                      stats.terminal.total > 0
                                        ? 'tick-circle'
                                        : 'circle-arrow-right'
                                    }
                                  />
                                }
                                href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/terminals`}
                              >
                                Add Terminals
                              </AnchorButton>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Card>
                  </Fragment>
                )}
              </Col>
            </Row>
          </Fragment>
        )
      }}
    </Query>
  )
}

export default Dashboard
