import gql from 'graphql-tag'

const TOGGLE_HYGIENE_RATING = gql`
  mutation toggleHygieneRating(
    $outletId: String!
    $enableHygieneRating: Boolean!
    $hygieneRatingId: Int
  ) {
    toggleHygieneRating(
      outletId: $outletId
      enableHygieneRating: $enableHygieneRating
      hygieneRatingId: $hygieneRatingId
    ) {
      message
    }
  }
`

export default TOGGLE_HYGIENE_RATING
