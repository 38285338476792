import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import ls from '../../../../utils/localStorage'
import { jwtDecode } from 'jwt-decode'
import { errorToast } from '@utils/toast'
import config from '../../../../config'

export default async (credentials, Body, partnerId, folder) => {
  const decoded = jwtDecode<{ user_id: string }>(ls.get('jwt'))
  const client = new S3Client({
    credentials,
    region: 'eu-west-1',
  })

  const Bucket = config.isProd
    ? 'customer-app-brands'
    : `app-build-keys-test-only.${config.environmentDomain}`
  const filename =
    decoded.user_id + '-' + partnerId + '-credentials-' + Date.now()

  const command = new PutObjectCommand({
    Bucket,
    Key: 'credentials/' + folder + '/' + filename,
    Body,
  })

  try {
    await client.send(command)
    return filename
  } catch (e) {
    console.error(e)
    errorToast("Can't upload file - " + e.message)
    return null
  }
}
