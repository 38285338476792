import gql from 'graphql-tag'

const EDIT_POS_DEVICE = gql`
  mutation editPOSDevice(
    $deviceId: String!
    $outletIds: [String!]
    $kioskUpdateData: KioskUpdateInput
  ) {
    editPOSDevice(
      deviceId: $deviceId
      outletIds: $outletIds
      kioskUpdateData: $kioskUpdateData
    ) {
      id
      friendlyName
      outlets {
        id
        name
      }
    }
  }
`

export default EDIT_POS_DEVICE
