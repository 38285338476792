import { differenceInMilliseconds, format } from 'date-fns'
import { BaseColumn, ColumnRenderer, ColumnSorter } from './column.types'
import { CSSProperties } from 'react'

export interface DateTimeColumn<X extends string>
  extends BaseColumn<'datetime', X> {
  /**
   * A format string in date-fns format
   */
  formatString?: string
}

export interface DateTimeColumnData {
  style?: CSSProperties
  date: Date
}

export const sortDateTimeColumn: ColumnSorter<'datetime'> = (a, b, order) => {
  const negator = order === 'DESC' ? 1 : -1

  return negator * differenceInMilliseconds(a.date, b.date)
}

export const DateTimeColumnContent: ColumnRenderer<'datetime', string> = ({
  columnDefinition: { formatString = 'hh:mm dd/MM' },
  columnData,
}) => {
  return (
    <span style={columnData.style}>
      {format(columnData.date, formatString)}
    </span>
  )
}
