import gql from 'graphql-tag'

const GET_PUSH_SUBSTITUTIONS = gql`
  query getUserDetails {
    getMyDetails {
      user {
        firstName
        lastName
        email
      }
    }
  }
`

export default GET_PUSH_SUBSTITUTIONS
