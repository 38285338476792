import gql from 'graphql-tag'

const GET_OUTLET_TABLES = gql`
  query getOutletTables($outletId: String!) {
    getOutlets(id: $outletId) {
      outlets {
        id
        isOrderToTableEnabled
        tables {
          id
          friendlyName
          active
        }
      }
    }
  }
`

export default GET_OUTLET_TABLES
